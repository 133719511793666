import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import firebase from '../../firebase/init';
import Notification from "../notification.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../../utils.js';
let {enc_key,app_api,user_info} = require('../../library.js');
let _this;
export default class SettingsNotifications extends Component {
     constructor() {
      super();
      this.state = {
        push_notification : false,
      }
      this.handleChangePushNotification = this.handleChangePushNotification.bind(this);
      this.savePushNotification = this.savePushNotification.bind(this);
      _this = this;
    }
    
    handleChangePushNotification(e){
        let option_type = 0;
        if(e.currentTarget.checked){
          option_type = 1;
        }
        document.getElementById("push-notification").classList.add('hide');
        document.getElementById("save-pn-spin").classList.remove('hide');
        try{
            const messaging = firebase.messaging();
            messaging.requestPermission().then(() =>{
              return messaging.getToken();
            }).then(async (Token) =>{
                _this.savePushNotification(option_type,Token);
            }).catch((error) => {
              document.getElementById("push-notification").classList.remove('hide');
              document.getElementById("save-pn-spin").classList.add('hide');
              console.log(error);
                //_this.savePushNotification(option_type,'');
            });
        }catch(error){
          //_this.savePushNotification(option_type,'');
        }
    }

    savePushNotification(option_type,token){
      this.setState({push_notification:option_type});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          notif_token : token,
          name : 'push_notification',
          value : option_type,
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById("push-notification").classList.remove('hide');
          document.getElementById("save-pn-spin").classList.add('hide');
      }.bind(this)).catch(function(res){
          
      })
    }

    componentDidMount(){
       let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            key:'push_notification'
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            let is_push_notif = 0;
            if(resp.push_notification!==undefined){
              is_push_notif = resp.push_notification;
            }
            this.setState({push_notification:is_push_notif});
          
        }.bind(this)).catch(function(res){
            
        })
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Settings Notification</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                        <div className="row">
                            <div className="col-md-12">
                              <div className="box-container">
                                  <h4 style={{borderBottom: '1px solid #d7d7d7',paddingBottom: '10px'}}>Notification Setting</h4>
                                  <div style={{padding: '10px 0px'}}>
                                     <FontAwesomeIcon className="hide" icon={faSpinner} pulse id="save-pn-spin"/>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" id="push-notification" value="1" onChange={this.handleChangePushNotification} checked={this.state.push_notification}/>
                                      <label className="form-check-label" htmlFor="push-notification" style={{fontWeight:'bold'}}>Push Notification</label>
                                    </div>
                                    <p>If enable, you will receive instant notification if any operation occurs like Create/Update/Delete Billing etc...</p>
                                  </div>
                              </div>
                            </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}