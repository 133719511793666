import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import utils from '../utils.js';
import Link from "./link";
import axios from 'axios';
import DrawerLink from "./open-drawer-link";
import ChequePrint from "./cheque-print";
import EditCheque from "./edit-cheque";
import ViewBillOnFly from "./view-bill-on-fly";
import ViewPaymentOnFly from "./view-payment-on-fly";
import ViewPayment from "./view-payment.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class ViewCheque extends Component {
    constructor(props) {
        super();
        this.state = {
            is_cheque_print : false,
            is_cheque_edit : false,
            print_type : '',
            contact_info :'',
            random :Math.floor(100000 + Math.random() * 900000),
            cheque_data : []
        };
        this.isMobile = utils.isMobile.bind(this);      
    }

    handleCloseChequePrint = () =>{
        this.setState({is_cheque_print:false});
    }

    componentDidMount = ()=>{
        let contact_info = this.props.cheque_data.party_info;
        let contact_name = '';
        let contact_id = '';
        let address_1 =  '';
        let address_2 = '';
        let city = '';
        let credit_limit = '';
        let previous_balance = '';
        let email = '';
        let phone_1 = '';
        let phone_2 = '';
        let state = '';
        if(contact_info && contact_info.name){    
            contact_name = contact_info.name.trim();
            contact_id = contact_info.contact_id;
            localStorage.setItem("selected_contact",contact_id);
            address_1 = contact_info.address_1;
            address_2 = contact_info.address_2;
            city = contact_info.city;
            
            credit_limit = contact_info.credit_limit;
            email = contact_info.email;
            phone_1 = contact_info.phone_1;
            phone_2 = contact_info.phone_2;
            state = contact_info.state;
        }
        let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info.credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
        this.setState({contact_info:c_info});
        this.handleGetCheque();

    }

    handleGetCheque = () =>{
        const id = this.props.cheque_data.cheque_management_id;
        let body = {};
         body = {
             store_name : user_info.store_name,
             branch_id : user_info.login_id,
             user_id : user_info.user_id,
             uuid : utils.uuid(),
             role_id : user_info.access_level,
             token : user_info.token, 
             _schema : user_info._schema,
             id : parseInt(id),
             schema : user_info._schema,
          }
       let api = app_api+'/get_cheque_on_id';
       axios.post(api, body)
         .then(function (response) {
             
            let resp = response.data.data[0];
            this.setState({cheque_data:resp});
             
       }.bind(this)).catch(function(res){
           if(res.response){
               if(res.response.data){
               }
           }
       })
    }

    handlePrint = (e) =>{
        let id = e.currentTarget.id;
        this.setState({print_type:id});
        this.setState({is_cheque_print:true});
    }
    handleEditCheque = (e) =>{
        this.setState({is_cheque_edit:true});
    }
    render() {
        return (
                <>
                    <div className="view-on-fly" id="page-content-wrapper">
                        <span id="handleCloseChequePrint" onClick={this.handleCloseChequePrint}></span>
                        <nav className="navbar border-bottom">
                            <div className="row">
                                <div className="col-md-12 mrtop-15 action-area text-right">
                                    <div className="e-p-p-opt div-display" style={{marginRight:'25px'}}>
                                            <div  onClick={this.handleEditCheque} id="edit-cheque" className="eppopt">
                                            <a id="edit-cheque-lbl">Edit</a>
                                            </div>
                                            <div  onClick={this.handlePrint} id="preview" className="eppopt">
                                                <a id="preview-lbl">Preview</a>
                                            </div>
                                            <div  onClick={this.handlePrint} id="print" className="eppopt">
                                            <a id="print-lbl">Print</a>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid page-break">
                            <div className="content-area">
                                <div className="content-head">
                                 
                                    <div className="row">
                                       
                                        <div className="col-md-4 border-right">
                                            <label style={{fontSize: '15px',color: '#a7a7a7'}}>Cheque Sequence</label>
                                            <h4 style={{fontSize:'18px', color:'#333',display:'flex'}}>{this.props.cheque_data.cheque_sequence} <span style={{fontSize: '11px',
    background: 'gray',
    color: 'white',
    padding: '4px 5px',
    fontWeight: 'bold',
    marginLeft: '5px'}}>{this.state.cheque_data.cheque_status}</span>
                                            </h4>
                                        </div>
                                        <div className="col-md-4 border-right">
                                            <label style={{fontSize: '15px',color: '#a7a7a7'}}>Cheque No</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{this.props.cheque_data.cheque_no}</h4>
                                        </div>
                                       
                                        <div className="col-md-4">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Cheque Date</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.cheque_data && this.props.cheque_data.cheque_date)?this.props.cheque_data.cheque_date:''}</h4>
                                        </div>
                                       
                                    </div>
                                    <div className="row mrtop-20">
                                        <div className="col-md-4 border-right">
                                            <label style={{fontSize: '15px',color: '#a7a7a7'}}>Cheque Name</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{this.props.cheque_data.account_name}</h4>
                                        </div>
                                        <div className="col-md-4 border-right">
                                                <label style={{fontSize: '15px',color: '#a7a7a7'}}>Amount</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{this.props.cheque_data.amount}</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Party Name</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.cheque_data && this.props.cheque_data.party_info && this.props.cheque_data.party_info.name)?this.props.cheque_data.party_info.name:''}</h4>
                                        </div>
                                    </div>
                                    <div className="row mrtop-20">
                                    {(this.state.cheque_data && this.state.cheque_data.invoice_info && this.state.cheque_data.invoice_info[0]) &&
                                        <>
                                           
                                            {(this.state.cheque_data.invoice_info[0].target==='expenses') &&
                                                <div className="col-md-6">
                                                    <h3 className="border-bottom">INVOICE INFO</h3>
                                                    <Link link={this.state.cheque_data.invoice_info[0].resource_url} target="_blank" name={this.state.cheque_data.invoice_info[0].sequence} />
                                                </div>
                                            }
                                            {(this.state.cheque_data.invoice_info[0].target!=='payments' && this.state.cheque_data.invoice_info[0].target!=='expenses' && this.state.cheque_data.invoice_info[0].target!=='cheque') &&
                                                <div className="col-md-6">
                                                    <h3 className="border-bottom">INVOICE INFO</h3>
                                                    <ViewBillOnFly random={this.state.random} url={this.state.cheque_data.invoice_info[0].resource_url} schema={this.state.cheque_data.invoice_info[0].schema} target="direct-view"/>
                                                </div>
                                            }
                                        </>
                                    }
                                    {(this.state.cheque_data && this.state.cheque_data.payment_info  && this.state.cheque_data.payment_info[0]) &&
                                        <> 
                                        <div className="col-md-6">
                                            <h3 className="border-bottom">PAYMENT INFO</h3>
                                            <ViewPayment  random={this.state.random} url={this.state.cheque_data.payment_info[0].resource_url} schema={this.state.cheque_data.payment_info[0].schema} target="onfly-disable"/>
                                        </div>
                                        </>
                                    }
                                     {(this.state.cheque_data && this.state.cheque_data.payment_details  && this.state.cheque_data.payment_details.data) &&
                                        <>
                                            
                                        <div className="col-md-6">
                                            <h3 className="border-bottom">PAYMENT INFO</h3>
                                            <ViewPaymentOnFly random={this.state.random}  payment_data={this.state.cheque_data.payment_details} target="direct-view"/>
                                        </div>
                                        </>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {(this.state.is_cheque_print) &&
                        <ChequePrint print_type={this.state.print_type} print_data = {this.props.cheque_data}/>   
                    }
                    {(this.state.is_cheque_edit) &&
                        <EditCheque cheque_data = {this.props.cheque_data} handleCloseChequeModal={this.handleCloseChequeModal.bind(this)} contact_info={this.state.contact_info}/>
                    }
                </>
        );
    }
    handleCloseChequeModal = () =>{
        this.setState({is_cheque_edit:false});
    }
}