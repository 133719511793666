import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus  } from '@fortawesome/free-solid-svg-icons';
export default class ContactField extends Component {
    constructor() {
        super();
        this.state = {
          
        };
    }
    render() {
        return (
            <>
             <div className="row">
                  <div className="col-md-3">
                    <span className="fe-title">EMAIL</span>
                    <input type="text" className="form-control  f-field f-field" placeholder="EMAIL" id="email"  readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">PHONE 1</span>
                    <input type="text" className="form-control  f-field" placeholder="PHONE 1" id="phone_1" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">PHONE 2</span>
                    <input type="text" className="form-control  f-field" placeholder="PHONE 2" id="phone_2" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">ADDRESS 1</span>
                    <input type="text" className="form-control  f-field" placeholder="ADDRESS 1" id="address_1" readOnly/>
                  </div>
              </div>
              <div className="row mrtop-15">
                 <div className="col-md-3">
                    <span className="fe-title">CITY</span>
                    <input type="text" className="form-control  f-field" placeholder="CITY" id="city" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">STATE</span>
                    <input type="text" className="form-control  f-field" placeholder="STATE" id="state" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">PIN CODE</span>
                    <input type="text" className="form-control  f-field" placeholder="PIN CODE" id="pin" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">ADDRESS 2</span>
                    <input type="text" className="form-control  f-field" placeholder="ADDRESS 2" id="address_2" readOnly/>
                  </div>
              </div>
              <div className="row mrtop-15">
                  <div className="col-md-3">
                    <span className="fe-title">GST NUMBER</span>
                    <input type="text" className="form-control  f-field" placeholder="GST NUMBER" id="gst_number" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">PAN NUMBER</span>
                    <input type="text" className="form-control  f-field" placeholder="PAN NUMBER" id="pan_no" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">ACCOUNT NUMBER</span>
                    <input type="text" className="form-control  f-field" placeholder="ACCOUNT NUMBER" id="account_no" readOnly/>
                  </div> 
                  <div className="col-md-3">
                    <span className="fe-title">BANK NAME</span>
                    <input type="text" className="form-control  f-field" placeholder="BANK NAME" id="bank_name" readOnly/>
                  </div> 
              </div>
              <div className="row mrtop-15">
                <div className="col-md-3">
                  <span className="fe-title">IFSC CODE</span>
                  <input type="text" className="form-control  f-field" placeholder="IFSC CODE" id="ifsc_code" readOnly/>
                </div>
                <div className="col-md-3">
                  <span className="fe-title">BANK CITY</span>
                  <input type="text" className="form-control  f-field" placeholder="BANK CITY" id="bank_city" readOnly/>
                </div>
                <div className="col-md-3">
                  <span className="fe-title">BANK ADDRESS</span>
                  <input type="text" className="form-control  f-field" placeholder="BANK ADDRESS" id="bank_address" readOnly/>
                </div>
                <div className="col-md-3">
                  <span className="fe-title">CREDIT LIMITS</span>
                  <input type="text" className="form-control  f-field" placeholder="CREDIT LIMITS" id="credit_limit" readOnly/>
                </div>
              </div>
            </>
        );
    }
}