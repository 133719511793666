import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRupeeSign, faTimesCircle, faArrowAltCircleRight, faInfoCircle, faInfo, faExclamation, faSpinner} from '@fortawesome/free-solid-svg-icons';
import utils from "../utils";
import PaymentModes from "./payment-modes";
import check_access from './access-level.js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import CreditDebitNoteLi from "./credit-debit-note-li";
import ChequeInputInfo from "./cheque-input-info";
import ServiceLoader from "./service-loader";
let {enc_key,app_api,user_info} = require('../library.js');
function redirectToSettings(){
  window.location.href = '/settings/billings-and-payments';
}
export default class PaymentPopup extends Component {
    constructor() {
      super();
      this.state = {
        payment_modes : [],
        current_sequence : ''
      };
      this.is_access = check_access.is_access.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
    }
    
    componentDidMount(){
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_payment_mode';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            this.setState({ payment_modes: cursor }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })

        let body1 = {};
        body1 = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            }
        let api1= app_api+'/get_sequence_number';
        axios.post(api1, body1)
        .then(function (response) {
            let resp = response.data;
           
            let in_sequence_no = resp.payment_in_sequence;
            let out_sequence_no = resp.payment_out_sequence;
           
            let payment_in_sequence = "#PYI"+in_sequence_no;
            let payment_out_sequence = "#PYO"+out_sequence_no;
            if(this.props.payment_type==='IN'){
                this.setState({current_sequence:payment_in_sequence});
            }else if(this.props.payment_type==='OUT'){
                this.setState({current_sequence:payment_out_sequence});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }

    render() {
      
        return (
            <div id='payment-popup' className="modal" style={{overflowY:'scroll',display:'block',zIndex:'9999999999'}}>
                <div className="modal-dialog" style={{width:'unset',paddingBottom:'0px'}}>
                    <div className="modal-content" style={{paddingTop:'5px'}}>
                        
                        <div className="modal-body border-bottom" style={{padding:'15px 15px 0px 15px'}}>
                           
                            <div className="row">
                                <div className="col-md-6">
                                    <span style={{color: 'gray',fontWeight: 'bold',fontSize:'30px'}}>
                                    {this.state.current_sequence}
                                    </span>
                                </div>
                                <div className="col-md-6 text-right">
                                    <span id="bill-pay-pop-title" style={{color: 'gray',fontWeight: 'bold',fontSize:'30px'}}><FontAwesomeIcon icon={faRupeeSign} style={{color: 'gray',fontSize:'30px'}}/>{utils.currency_format(this.props.remaining_amount)}</span>
                                </div>
                                <div className="col-md-12"><hr /></div>
                                {(this.state.payment_modes.length === 0) &&
                                <div className="col-md-12 text-center"> 
                                    <FontAwesomeIcon icon={faSpinner} pulse/>
                                </div>
                                }
                                <div className="col-md-12 text-left border-right ">
                               
                                {(this.state.payment_modes.length > 0) &&
                                    <table width="100%" className="table inline-table bill-type-table">
                                        <tbody>
                                            {(this.state.payment_modes.length > 0) ?this.state.payment_modes.map( (value, index) => {
                                                return (
                                                <>
                                                {(value.status===1 && value.payment_mode_id!==2) &&
                                                    <>
                                                    <tr key={ index }>
                                                        <td>{value.payment_mode}:</td>
                                                        <td className="bttitem">
                                                            <input type="number" className="form-control total-bill-amount" placeholder="0" data-index={index} data-id={value.payment_mode}  data-target="pay-box" id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} defaultValue={(value.payment_mode_id===1)?this.props.remaining_amount:0}/>
                                                        </td>
                                                    </tr>
                                                    {(value.is_cheque===true || value.is_cheque===1 || value.is_cheque==='1') &&
                                                    <ChequeInputInfo  type="td" id={value.payment_mode}/>
                                                    }
                                                    </>
                                                }
                                                </>
                                                )
                                            }) : '' }
                                        </tbody>
                                    </table>
                                }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="row">
                              
                                <div className="col-md-4 text-left" style={{paddingLeft:'5px'}}>
                                     <button type="button" id="handleClosePopup" className="btn btn-secondary" onClick={this.handleClosePopup} style={{cursor:'pointer'}}>Close</button>
                                </div>
                                <div className="col-md-8 text-right" style={{paddingRight:'5px'}}>
                                  
                                    <button type="button" className="btn btn-success" onClick={this.handleCreateRemainingPayment}  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>Create Payment & Adjust</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
    handleClosePopup  = (e) =>{
        this.props.handleClosePaymentPopup();
    }
    handleCreateRemainingPayment = (e) =>{
        let pay_data = [];
        let payments_data = [];
        let tba = this.props.remaining_amount;
        let tpa = 0;
        let t_p_amts = document.querySelectorAll(".total-bill-amount");
        for(let i=0;i<t_p_amts.length;i++){
            let amt = t_p_amts[i].value;
            tpa = tpa + parseFloat(amt);
            let id = t_p_amts[i].id;
            let data_id = t_p_amts[i].getAttribute('data-id');
            let chq_bank_name = 'chq-bank-name-'+data_id.replaceAll(' ','-').toLowerCase();
            let chq_no = 'chq-no-'+data_id.replaceAll(' ','-').toLowerCase();
            let chq_date = 'chq-date-'+data_id.replaceAll(' ','-').toLowerCase();
            let send_for_recon = 'send-for-recon-'+data_id.replaceAll(' ','-').toLowerCase();
            let pay_chq_no = '';
            let pay_chq_date = '';
            let pay_chq_bank_name = '';
            let pay_is_recon = '';
            if(document.getElementById(chq_no)){
                pay_chq_bank_name = document.getElementById(chq_bank_name).value;
                pay_chq_no = document.getElementById(chq_no).value;
                pay_chq_date = document.getElementById(chq_date).value;
            }
            if(document.getElementById(send_for_recon)){
                pay_is_recon = document.getElementById(send_for_recon).checked;
            }
            if(amt>0){
                let pdata = {'id':id,value:amt,'cheque_bank_name':pay_chq_bank_name,'cheque_no':pay_chq_no,'cheque_date':pay_chq_date,'is_reconciliation':pay_is_recon};
                pay_data.push(pdata);
            }
        }
        
        let pd = {'bill_amount':tba,'paid_amount':tpa,'return_amount':0};
        payments_data = {bill_types:pay_data,data:pd};
        
        this.props.handleCreateRemainingPayment(payments_data);
    }
}