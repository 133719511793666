import React, {Component} from "react";
import axios from 'axios';
import ServiceLoader from './service-loader.js';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileDownload, faPrint, faSpinner} from '@fortawesome/free-solid-svg-icons';
import utils from '../utils.js';
import AccountGroupsSubGroups from "./account-groups-sub-groups.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class AddAccount extends Component {
    constructor() {
        super();
        this.state = {
            
        }
        this.isMobile = utils.isMobile.bind(this);
    }

    closeModel = () =>{
        if(document.getElementById("handleCloseAddAccount")){
            document.getElementById("handleCloseAddAccount").click();
        }
    }

    saveContact = (event) =>{
        let name = document.getElementById('new_name').value;
        if(name===""){
          document.getElementById('new_name').style.border='1px solid red';
          return false;
        }
        let id = event.currentTarget.id;
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");
      
        let type = this.props.contact_type_id;
        let contact_type_name = this.props.contact_type_name;
        let phone = document.getElementById('new_phone').value;
        let account_group = '';
        if(document.getElementById('account-group-select')){
          account_group = document.getElementById('account-group-select').value;
        }
        
        let info_body = {
                type : type,
                name : name,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                email : '',
                phone_1 : phone,
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : contact_type_name,
                account_group : account_group,
                discount : ''
            };

        let api = app_api+'/create_contact';
        axios.post(api, info_body)
          .then(function (response) {
            let data = response.data;
            if(data.success === 1){
                window.location.reload();
            }else{
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                document.getElementById("name-exist").innerHTML = data.message;
            }
        }.bind(this)).catch(function(res){
           
        })
    }
    render() {
        return (
            <div className="modal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Save Account</h5>
                </div>
                <div className="modal-body">
                
                  <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="new_name">NAME <span className="red">*</span></label>
                        <input type="text" className="form-control" placeholder="" id="new_name" autoFocus/>
                        <p className="error" id="name-exist"></p>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="new_phone">PHONE</label>
                        <input type="text" className="form-control" placeholder="" id="new_phone"/>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="new_phone">ACCOUNT GROUP</label>
                        <AccountGroupsSubGroups />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.closeModel} id="close-contact">Close</button>
                  <button type="button" data-id={this.props.id} className="btn btn-success pull-right" id="create-contact" onClick={this.saveContact}>Save Account</button>
                  <button type="button" id="create-contact-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                </div>
              </div>
            </div>
          </div>
        );
    }
}