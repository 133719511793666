import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import utils from '../utils.js';
export default class ViewTransport extends Component {
    constructor(props) {
        super();
        this.state = {
            
        };
        this.isMobile = utils.isMobile.bind(this);
      
    }

    componentDidMount(){
       
    }
    render() {
        return (
                <>
                    <div className="view-on-fly" id="page-content-wrapper">
                        <nav className="navbar border-bottom">
                            <div className="row">
                                <div className="col-md-12 mrtop-10">
                                    <h2 className="text-center">VIEW TRANSPORT - {this.props.transport_data.transport_number}</h2>
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid page-break">
                            <div className="content-area">
                                <div className="content-head">
                                 
                                    <div className="row">
                                        <div className="col-md-3 border-right">
                                            <label style={{fontSize: '15px',color: '#a7a7a7'}}>Transport Date</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{this.props.transport_data.transport_date}</h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Transport From</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.transport_from)?this.props.transport_data.transport_from:''}</h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Transport To</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.transport_to)?this.props.transport_data.transport_to:''}</h4>
                                        </div>
                                        <div className="col-md-3 ">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Party Name</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}> 
                                                {(this.props.transport_data && this.props.transport_data.party_name)?this.props.transport_data.party_name:''}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row mrtop-10">
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>LR. No</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.lr_no)?this.props.transport_data.lr_no:''} </h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Bale</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.bale)?this.props.transport_data.bale:''} </h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Transport</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.transport)?this.props.transport_data.transport:''}</h4>
                                        </div>
                                        <div className="col-md-3 ">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Weight</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.weight)?this.props.transport_data.weight:''} </h4>
                                        </div>
                                    </div>
                                    <div className="row mrtop-10">
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Amount</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.amount)?this.props.transport_data.amount:''} </h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Received Sign</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.received_sign)?this.props.transport_data.received_sign:''}</h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Received Date</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.received_date)?this.props.transport_data.received_date:''}</h4>
                                        </div>
                                        <div className="col-md-3">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Supplier's Reference No.</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.b_no)?this.props.transport_data.b_no:''}</h4>
                                        </div>
                                    </div>
                                    <div className="row mrtop-10">
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>No. of Parcel Received</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.no_of_parcel_received)?this.props.transport_data.no_of_parcel_received:''} </h4>
                                        </div>
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Purchase No.</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.p_no)?this.props.transport_data.p_no:''}</h4>
                                        </div>
                                    
                                        <div className="col-md-3  border-right">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Receipt</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.receipt)?this.props.transport_data.receipt:''} </h4>
                                        </div>
                                    
                                        <div className="col-md-3">
                                            <label  style={{fontSize: '15px',color: '#a7a7a7'}}>Status</label>
                                            <h4 style={{fontSize:'18px', color:'#333'}}>{(this.props.transport_data && this.props.transport_data.status)?this.props.transport_data.status:''} </h4>
                                        </div>
                                    </div>
                          
                                </div>
                            </div>
                        </div>
                    </div>    
                </>
        );
    }
}