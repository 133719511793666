import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes,faSearch,faTrash,faCaretLeft,faCaretRight,faSpinner,faPencilAlt,faExclamationTriangle, faPlus, faKey} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import Autocomplete from "./autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import ProductCategory from "./product-category";
import ProductUnits from "./product-units";
import ServiceLoader from './service-loader.js';
import ModalBox from "./modal-box";
import TagFields from "./tag-field";
import utils from '../utils.js';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;

export default class CreateProduct extends Component {
    constructor() {
      super();
      this.state = {
          otp:'',
          otp_error:'',
          is_validate_otp:false,
          show_loader : true,
          is_barcode_exists : false,
          existing_barcode_item : '',
          loading_process :'Loading...',
          active_tab:'add',
          variantOptions : [],
          variantList : [],
          v_opt_counter : 1,
          is_variant_checked:false,
          update_id:0,
          inventory_item:[],
          margin : 0,
          msp_margin : 0,
          msp_profit : 0,
          profit : 0,
          selectedFile:'',
          variation_list : [],
          options_head:[],
          product_image : 'https://app.business-booster.net/app/uploads/no-image.png',
          inventory_status : 'Active',
          item_options:[],
          variantPreviewList : [],
          lspm : [],
          sp_lower_than_pp:false,
          otp_while_update_product:false,
          current_barcode : '',
          new_barcode : '',
          barcode_error : '',
          product_info : [],
          comission_type : 'rs',
          custom_fields_json : [],
          show_inventory_custom_field : false,
          send_e_invoice : false,
          
      };
      _this = this;
      this.variantOptions = [];
      this.variantPreviewList = [];
      this.variantList = [];
      this.is_access = check_access.is_access.bind(this);
      this.handleVariantChange = this.handleVariantChange.bind(this);
      this.handleMoreVariantOpt = this.handleMoreVariantOpt.bind(this);
      this.autoGenerate = this.autoGenerate.bind(this);
      this.handleAutoGenerate = this.handleAutoGenerate.bind(this);
      this.handleCalculation = this.handleCalculation.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
      this.GENERATE_BARCODE = utils.GENERATE_BARCODE.bind(this);
      this.onFileClick = this.onFileClick.bind(this);
      this.onFileChange = this.onFileChange.bind(this);
      this.handleProductSave = this.handleProductSave.bind(this);
      this.handleStatusChange = this.handleStatusChange.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleCreateCategory = this.handleCreateCategory.bind(this);
      this.handleSKUCheck = this.handleSKUCheck.bind(this);
      this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
      this.handleAutoMSPCalculate = this.handleAutoMSPCalculate.bind(this);
      this.handleRegenerateBarcodeAlert = this.handleRegenerateBarcodeAlert.bind(this);
      this.handleRegenerateBarcode = this.handleRegenerateBarcode.bind(this);
      this.handleCloseBarcodeAlert = this.handleCloseBarcodeAlert.bind(this);
    }
    handleChangeOTP = (otp) =>{
      this.setState({otp:otp});
    }
    handleSendOTP = (product_data) =>{
      let product_info = this.state.product_info[0];
     
      let check_items = ['item_name','item_desc','category_id','barcode_name','hsn','part_name','purchase_price','quantity','product_unit_id','tax','pp_tax_type','discount','selling_price','lowest_selling_price','wholesale_price','sp_tax','sp_tax_type','sp_discount','bundle_quantity','custom_text','reorder_quantity','opening_stock','comission','comission_type'];
      let updated_data = [];
      for (let index = 0; index < check_items.length; index++) {
        const elem_key = check_items[index];
        let elem_label = elem_key.replaceAll('_',' ');
        let old_value =product_info[elem_key]; 
        let new_value =product_data[elem_key]; 
        if(elem_key==="product_unit_id"){
          old_value = parseInt(old_value);
          new_value = parseInt(new_value);
        }
        if(elem_key==="pp_tax_type"){
          if(old_value===null){
            old_value = 'including';
          }
        }
        if(old_value!==new_value){
            let up_data = {key:elem_key,label:elem_label,old_value:old_value,new_value:new_value};
            updated_data.push(up_data)
        }
      }
   
      let set_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        product_id : this.state.update_id,
        target:'product',
        uuid : utils.uuid(),
        updated_data : updated_data,
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
      }
      let opt_api = app_api+'/send_otp';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
      }.bind(this)).catch(function(res){
          
      })
    }
    handleChangeCommissionType = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({comission_type:thisvalue});
    }

    handleCloseBarcodeAlert(){
     
      var element = document.getElementById('regnerate-barcode');
      element.classList.add("fade");
      element.style.display="none";
      this.setState({barcode_error:''});
    }

    handleRegenerateBarcodeAlert(){
      this.setState({current_barcode:this.state.inventory_item.barcode_name});
      var element = document.getElementById('regnerate-barcode');
      element.classList.remove("fade");
      element.style.display="block";

      let set_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          product_id : this.state.update_id,
          category_id : this.state.inventory_item.category_id,
          is_auto_click : true,
          uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
      }
      let opt_api = app_api+'/generate_barcode_on_fly';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
          this.setState({new_barcode:resp.barcode_name})
      }.bind(this)).catch(function(res){
          
      })
    }

    handleRegenerateBarcode(e){
      let id = e.currentTarget.id;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let set_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          product_id : this.state.update_id,
          category_id : this.state.inventory_item.category_id,
          is_auto_click : false,
          uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
      }
      let opt_api = app_api+'/generate_barcode_on_fly';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.success===1){
            window.location.reload();
          }else if(resp.success===2){
            this.setState({barcode_error:resp.message});
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
          }
      }.bind(this)).catch(function(res){
          
      })
    }

    handleAutoMSPCalculate(e){
      let lowest_sp = document.getElementById("hid-minimum-selling-price").value;
      let auto = document.getElementById("auto");
      let purchase_price = document.getElementById("purchase-price").value;
      let selling_price = document.getElementById("selling-price").value;
      if(auto.checked===true){
        document.getElementById("minimum-selling-price").setAttribute('disabled','true');
        let lspm =  this.state.lspm;

        if(selling_price===""){
          selling_price = 0;
        }

        if(purchase_price===""){
          purchase_price = 0;
        }else{
          purchase_price = parseFloat(purchase_price);
        }

        let cal = purchase_price*lspm/100;
        lowest_sp = purchase_price+cal;

        if(selling_price<lowest_sp){
          lowest_sp = selling_price;
        }
      }else{
        document.getElementById("minimum-selling-price").removeAttribute('disabled');
      }
      if(lowest_sp===""){
         lowest_sp = 0;
      }
      document.getElementById("minimum-selling-price").value = this.floating_point(lowest_sp);
      let msp = lowest_sp;
      msp = parseFloat(msp);
      if(selling_price===""){
        selling_price = 0;
      }
      selling_price = parseFloat(selling_price);
      let msp_profit = selling_price-msp;
      let msp_margin = (msp_profit/selling_price)*100;
      if(!isNaN(msp_margin)){
        msp_margin = this.floating_point(msp_margin);
        this.setState({msp_margin:msp_margin,msp_profit:msp_profit});
      }
    }

    handleChangeQuantity(e){
      let quantity = e.currentTarget.value;
      let availability = document.getElementById("avail-quan-hidden").value;
      if(availability!=="" && quantity!==""){
          let total = parseInt(availability)+parseInt(quantity);
          let avl_msg = 'Available : '+availability+'+'+quantity+'='+total;
          document.getElementById("avail-quan").innerHTML = avl_msg;
      }else if(availability!==""){
          let avl_msg = 'Available : '+availability;
          document.getElementById("avail-quan").innerHTML = avl_msg;
      }else{
          document.getElementById("avail-quan").innerHTML = '';
      }
    }

    handleSKUCheck(e){
      var regex = new RegExp("^[a-zA-Z0-9]+$");
      console.log(e.which);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if ((regex.test(str) || e.which==8)) {
          return true;
      }

      e.preventDefault();
      return false;
    }

    handleCreateCategory(e){
      var element = document.getElementById('add_category');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("save-category-target-type").value = 'product';
      document.getElementById("category").focus();
    }

    deleteRow(event){
        var element = document.getElementById('delete_confirm');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value='product';
        document.getElementById("deleteIndex").value=this.state.update_id;
        document.getElementById("deleteTarget").value='delete_inventory';
      
    }
    handleStatusChange(e){
      this.setState({inventory_status:e.currentTarget.value});
    }
    onFileChange(e){
      this.setState({ selectedFile: e.target.files[0] });
      let output = URL.createObjectURL(e.target.files[0]);
      this.setState({product_image:output});
      //document.getElementById("uploaded_photo").setAttribute('src',output);
    }
    onFileClick(e){
      document.getElementById("upload_photo").click();
    }

    handleCalculation(e){
      let purchase_price = document.getElementById("purchase-price").value;
      let selling_price = document.getElementById("selling-price").value;
      let id = e.currentTarget.id;
      if(purchase_price!=="" && selling_price!==""){
        purchase_price = parseFloat(purchase_price);
        selling_price = parseFloat(selling_price);
        let profit = selling_price-purchase_price;
        let margin = (profit/selling_price)*100;
        this.setState({profit:profit,margin:margin});
      }
      if(id==='purchase-price'){
        this.handleAutoMSPCalculate(e);
      }
    }

    handleAutoGenerate(e){
      let id = e.currentTarget.id;
      if(e.currentTarget.checked===true){
        let str = document.getElementById('item-box-0').value;
        if(str===""){
          str = '0123456789';
        }else{
          str = str.toUpperCase();
        }
        let s = '';
        if(id==='auto-generate-sku'){
          s = this.autoGenerate(str,6);
          document.getElementById("product-sku").value = s;
        }else if(id==='auto-generate-bc'){
          this.setState({is_barcode_exists:false});
          this.setState({existing_barcode_item:''});
          let category_id = document.getElementById("tags-id-category").value;
          let old_barcode = document.querySelector('[id=product-barcode]').value;
          if(category_id===""){
            category_id = 1;
          }
          if(old_barcode===""){
            this.GENERATE_BARCODE(category_id,true);
            localStorage.setItem('is_auto_bc_clicked','yes');
            let aqs = document.querySelectorAll(".item-barcodes");
            for(let i=0;i<aqs.length;i++){
              aqs[i].value = 'Auto Generate';
            }
          }
          document.getElementById('product-barcode').setAttribute('disabled','true');
        }
      }else{
        if(id==='auto-generate-bc'){
          localStorage.removeItem('is_auto_bc_clicked');
          document.getElementById('product-barcode').removeAttribute('disabled');
        }
      }
    }

    autoGenerate(str,length) {
       var result           = '';
       var characters       = str;
       characters = characters.replace(/[^a-zA-Z0-9]/g, "");
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    updateVariationListState(varList){
      let var_list = this.state.variantList;
      let new_var_list = {};
      for (let index = 0; index < var_list.length; index++) {
        const element = var_list[index];
        let label = element.lbl;
        new_var_list[label] = element;
      }
      
      let newvarList = [];
      for (let index = 0; index < varList.length; index++) {
        const element = varList[index];
        let label = element.lbl;
        if(new_var_list[label]!==undefined){
          newvarList[index] = new_var_list[label];
        }else{
          varList[index].item_data.inventory_id = 0;
          newvarList[index] = varList[index];
        }
      }
      this.variantList = [];
      let _this = this;
      this.setState({variantList:[]});
      setTimeout(() => {
        this.variantList = newvarList;
        _this.setState({variantList:this.variantList});
      }, 50);
    }
    updateVariationOptionState(rm){
      this.variantOptions.splice(rm,1);
      this.setState({v_opt_counter:this.state.v_opt_counter-1,variantOptions:this.variantOptions});
    }
    handleMoreVariantOpt(e){
      let counter = this.state.v_opt_counter+1;
      let lbl = "Option "+counter;
      this.variantOptions.push(<VariantOptions label={lbl} option="" variation_list={this.state.variation_list}  index={counter} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)}/>);
      this.setState({v_opt_counter:counter,variantOptions:this.variantOptions});
    }

    handleVariantChange(e){
      this.setState({is_variant_checked:e.currentTarget.checked});
    }
    handleEditItem(id){

    }

    handleRemoveItem(id){
      let deleted_items = document.getElementById('deleted_items').value;
      let d_items = [];
      let item_id = document.getElementById("item-id-"+id).value;
      if(deleted_items!==""){
        let ids = deleted_items.split(",");
        for(let i=0;i<ids.length;i++){
          if(d_items.indexOf(ids[i])<0){
            d_items.push(ids[i]);
          }
        }
      }
      d_items.push(item_id);
      document.getElementById('deleted_items').value = d_items;

      let item_lbl = document.getElementById("item-lbl-"+id).innerHTML;
      for(let i=this.variantList.length-1;i>=0;i--){
        if(this.variantList[i].lbl===item_lbl){
            this.variantList.splice(i,1);
        }
      }
      //this.variantList.splice(id,1);
      this.updateVariationListState(this.variantList);
    }

    componentDidMount(){
      let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            keys:"'sp_lower_than_pp','show_inventory_custom_field','send_e_invoice','otp_while_update_product'"
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            
            if(resp.sp_lower_than_pp){
              let ps = resp.sp_lower_than_pp;
              this.setState({sp_lower_than_pp:ps});
            }
            
            if(resp.otp_while_update_product!==undefined){
              let ps = resp.otp_while_update_product;
              this.setState({otp_while_update_product:ps});
            }
            if(resp.send_e_invoice){
              let ps = resp.send_e_invoice;
              this.setState({send_e_invoice:ps});
            }
            if(resp.show_inventory_custom_field!==undefined){
              let show_inventory_custom_field = resp.show_inventory_custom_field;
              this.setState({show_inventory_custom_field:show_inventory_custom_field});
            }
            //this.setState({settings:resp});
        }.bind(this)).catch(function(res){
            
        })



      this.variantOptions.push(<VariantOptions label="Option 1" option="Size" index={1} updateState={this.updateVariationListState.bind(this)} updateOptionState={this.updateVariationOptionState.bind(this)}/>);
      this.variantPreviewList.push(<VariationPreviewList/>);

      this.setState({variantOptions:this.variantOptions,variantPreviewList:this.variantPreviewList});

      let curl = window.location.href;
      let url = curl.split("/"); 
      let current_url = url[url.length - 1];
      let prev_url = url[url.length - 2];
      this.setState({current_url:current_url});
      this.setState({prev_url:prev_url});
      let cpage;
      let current_page;
      let body = {};
  
      const product_add = curl.match(/products\/add$/i);
      const product_edit = curl.match(/products\/edit\/(\d+$)/i);
   
      let _t = this;
      setTimeout(function(){
        if(localStorage.getItem('is_auto_generate_barcode')=="true"){
          if(product_add){
            _t.GENERATE_BARCODE(1);
            document.getElementById('auto-generate-bc').checked = true;
            document.getElementById('product-barcode').setAttribute('disabled','true');
          }
        }
      },800);
     

      if(product_edit){
        let update_id = parseInt(product_edit[1]);
        this.setState({active_tab:'edit',update_id:update_id});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              id : update_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              active_tab : 'edit'
           }
        let api = app_api+'/get_product_on_id';
      
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;
              this.setState({product_info:resp});
              setTimeout(function(){
                if(resp[0].is_auto_barcode==="1"){
                  document.getElementById('auto-generate-bc').checked = true;
                  document.getElementById('product-barcode').setAttribute('disabled','true');
                }
              },800);
              let varient = resp[0].varient;
              let inventory_status = resp[0].inventory_status;

              let variation_list = resp.inventory_meta;
              let additional_data = resp.additional_data;
              this.setState({variation_list:variation_list});
              if(additional_data.custom_fields!==undefined){
                this.setState({custom_fields_json:additional_data.custom_fields});
              }

              let options_head = resp.option_head;
              let lspm = resp.lspm;
              this.setState({product_image:resp.inventory_image,inventory_status:inventory_status,lspm:lspm});
              if(variation_list.length===0){
                this.setState({multiple_variant:false});
              }else{
                this.setState({multiple_variant:true});
              }
              this.setState({show_loader:false});
              if(variation_list.length>0){
                this.variantOptions = [];
                let value1 = [];
                let value2 = [];
                let value3 = [];
                for(let i=0;i<variation_list.length;i++){
                  let v1 = variation_list[i].value1;
                  let v2 = variation_list[i].value2;
                  let v3 = variation_list[i].value3;
                  if(v1!==null && value1.indexOf(v1)<0){
                    value1.push(v1);
                  }
                  if(v2!==null && value2.indexOf(v2)<0){
                    value2.push(v2);
                  }
                  if(v3!==null && value3.indexOf(v3)<0){
                    value3.push(v3);
                  }
                  let item_name = variation_list[i].item_name;
                  let item_data = variation_list[i];
                  item_data.update_id = this.state.update_id;

                  let varitem = {item_data:item_data,option_data:{},lbl:item_name};
                  this.variantList.push(varitem);
                  //this.variantList.push(<VariationList item_data={item_data} label={item_name} index={i} editItem={this.handleEditItem.bind(this)} removeItem={this.handleRemoveItem.bind(this)}/>);
                }
                this.setState({variantList:this.variantList});

                for (let index = 0; index < options_head.length; index++) {
                  const element = options_head[index];
                  let tag_value = [];
                  if(index===0){
                    tag_value = value1;
                  }else if(index===1){
                    tag_value = value2;
                  }else if(index===2){
                    tag_value = value3;
                  }
                  this.variantOptions.push(<VariantOptions label={'Option '+(index+1)} variation_list={variation_list} option={element} index={index+1} updateState={this.updateVariationListState.bind(this)} value={tag_value} updateOptionState={this.updateVariationOptionState.bind(this)}/>);
                }
                this.setState({v_opt_counter:(options_head.length),variantOptions:this.variantOptions,is_variant_checked:true});
              }

             if(resp[0]!==undefined){
              let selling_price = (resp[0].selling_price==='')?0:resp[0].selling_price;
              let purchase_price = (resp[0].purchase_price==='')?0:resp[0].purchase_price;
              let lowest_sp = (resp[0].lowest_selling_price==='')?0:resp[0].lowest_selling_price;

              purchase_price = parseFloat(purchase_price);
              selling_price = parseFloat(selling_price);

              let profit = selling_price-purchase_price;

              let margin = (profit/selling_price)*100;
              if(isNaN(margin)){
                margin = 0;
              }
              this.setState({profit:profit,margin:margin});

              if(lowest_sp!==''){
                let msp = lowest_sp;
                msp = parseFloat(msp);
                let msp_profit = selling_price-msp;
                let msp_margin = (msp_profit/selling_price)*100;
                msp_margin = this.floating_point(msp_margin);
                this.setState({msp_margin:msp_margin,msp_profit:msp_profit});
              }

              if(variation_list.length>0){
                this.setState({is_variant_checked:true});
              }
              let item_sku = resp[0].sku;
              let item_name = resp[0].item_name;
              if(item_sku===null){
                let sku_auto = '';
                let iname = item_name.replace(" ",'');
                let n = iname.toUpperCase();
                resp[0].sku = this.autoGenerate(n,6);
              }
              this.setState({ 'inventory_item':  resp[0]}); 
              
              this.setState({comission_type:resp[0].comission_type});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }else{
        this.setState({show_loader:false});
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:"'inventory_custom_fields'"
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.inventory_custom_fields!==undefined){
              let inventory_custom_fields = resp.inventory_custom_fields;
              this.setState({custom_fields_json:inventory_custom_fields});
            }
            //this.setState({settings:resp});
        }.bind(this)).catch(function(res){
            
        })

      }
    } 

    handleSetCategory = () =>{
      let category_id = localStorage.getItem('category_id');
      let category_name = localStorage.getItem('category_name');
      let product_info = this.state.product_info;
      let inventory_item = this.state.inventory_item;
      product_info.category_name =  category_name;
      inventory_item.category_id =  category_id;
      this.setState({inventory_item:inventory_item,product_info:product_info});
    }

    handleProductSave(e){
      let target = e.target.id;
      let id = target;
      if(this.state.is_barcode_exists===true){
        document.getElementById("product-barcode").focus();
        return false;
      }
      let deleted_items = document.getElementById("deleted_items").value;
      let p_name = document.getElementById("item-box-0").value;
      let p_id = document.getElementById("item-name-0").value;
      let p_description = document.getElementById("product-description").value;
      let p_category = document.getElementById("tags-id-category").value;
      let product_quantity = document.getElementById("product-quantity").value;

      let items = document.querySelectorAll(".variation-item");

      if(p_name===""){
        document.querySelector("[id=item-box-0]").style.border = '1px solid red';
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return false;
      }else{
        document.querySelector("[id=item-box-0]").style.border = '1px solid #ced4da';
      }

      if(p_category===""){
        document.getElementById("tag-box-category").style.border = '1px solid red';
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return false;
      }else{
        document.getElementById("tag-box-category").style.border = 'none';
      }

      if(product_quantity==="" && items.length===0){
        document.getElementById("product-quantity").style.border = '1px solid red';
        window.scrollTo({ top: 0, behavior: 'smooth' });
        product_quantity = 0;
        return false;
      }else{
        document.getElementById("product-quantity").style.border = '1px solid #ced4da';
      }

      let p_sku = document.getElementById("product-sku").value;
      p_sku = p_sku.replace(/[^A-Z0-9]/ig, "");
      let p_barcode = document.getElementById("product-barcode").value;
      let is_auto_barcode = false;
      if(document.getElementById("auto-generate-bc") && document.getElementById("auto-generate-bc").checked){
         is_auto_barcode = true;
      }
      if(this.state.active_tab==='edit' && localStorage.getItem('is_auto_bc_clicked')==='yes'){
        localStorage.removeItem('is_auto_bc_clicked');
        if(document.getElementById("auto-generate-bc") && document.getElementById("auto-generate-bc").checked){
          p_barcode = 'Auto Generate';
        }
      }
      if(this.state.active_tab==='add' && (p_id==="" || p_id==="0")){
        if(document.getElementById("auto-generate-bc") && document.getElementById("auto-generate-bc").checked){
          p_barcode = 'Auto Generate';
          is_auto_barcode = true;
        }
      }
      let alias_barcode = document.getElementById("alias-barcode").value;
      let p_hsn = document.getElementById("product-hsn").value;
      if(p_hsn!==""){
        let is_hsn  = this.handleCheckHSN();
        if(is_hsn===false){
          return false;
        }
      }
      let p_partname = document.getElementById("product-partname").value;
      let purchase_price = document.getElementById("purchase-price").value;
      let pp_tax = document.getElementById("pp_tax_dd").value;
      let sp_tax = document.getElementById("sp_tax_dd").value;

      if(this.state.send_e_invoice===true && (user_info.gst_type==='composite' || user_info.gst_type==='regular')){
        if(pp_tax==="" || pp_tax==="0" || sp_tax==="" || sp_tax==="0"){
          toast("Please add GST for the item.");
          if(pp_tax==="" || pp_tax==="0"){
            if(document.getElementById("pp_tax_dd")){
              document.getElementById("pp_tax_dd").focus();
            }
          }else if(sp_tax==="" || sp_tax==="0"){
            if(document.getElementById("sp_tax_dd")){
              document.getElementById("sp_tax_dd").focus();
            }
          }
          return false;
        }
      }
      let pp_tax_type = document.getElementById("pp-tax-type").value;
      
      let product_unit = document.getElementById("product-unit").value;
      let pp_discount = document.getElementById("pp-discount").value;
      let selling_price = document.getElementById("selling-price").value;
      let wholesale_price = document.getElementById("wholesale-price").value;
      let comission = document.getElementById("comission").value;
      let comission_type = this.state.comission_type;
     

      let min_price = document.getElementById("minimum-selling-price").value;


      if(purchase_price!=="" && selling_price!=="" && items.length===0 && this.state.sp_lower_than_pp===false){
          if(parseFloat(purchase_price)>parseFloat(selling_price)){
            document.querySelector("[id=purchase-price]").style.border = '1px solid red';
            window.scrollTo({ top: 500, behavior: 'smooth' });
            document.getElementById("pp-error").innerHTML = 'Purchase price should not be more than SP';
            return false;
          }else{
            document.getElementById("pp-error").innerHTML = '';
            document.querySelector("[id=purchase-price]").style.border = '1px solid #ced4da';
          }
      }

      if(min_price!=="" && selling_price!=="" && items.length===0){
          if(parseFloat(min_price)>parseFloat(selling_price)){
            document.querySelector("[id=minimum-selling-price]").style.border = '1px solid red';
            window.scrollTo({ top: 500, behavior: 'smooth' });
            document.getElementById("msp-error").innerHTML = 'MSP should not be more than SP';
            return false;
          }else{
            document.getElementById("msp-error").innerHTML = '';
            document.querySelector("[id=minimum-selling-price]").style.border = '1px solid #ced4da';
          }
      }

      let is_auto_msp = false;
      if(document.getElementById("auto") && document.getElementById("auto").checked===true){
        is_auto_msp = true;
      }

      let sp_tax_type = document.getElementById("sp-tax-type").value;
      let sp_discount = document.getElementById("sp-discount").value;
      let bundle_quantity = document.getElementById("bundle-quantity").value;
      let reorder_quantity = document.getElementById("reorder-quantity").value;
      let opening_stock = document.getElementById("opening-stock").value;
      let custom_text = document.getElementById("custom-text").value;
      let inventory_status = document.getElementById("product-status").value;
      if(pp_discount===""){
        pp_discount = 0;
      }
      if(sp_discount===""){
        sp_discount = 0;
      }
      

      let items_arr = [];

      let cfields = this.state.custom_fields_json;
      for(let i=0;i<items.length;i++){
          
          let did = items[i].getAttribute('data-id');
          let v_item_id = document.getElementById("item-id-"+did).value;
          let v_item_name = document.getElementById("item-lbl-"+did).innerHTML;
          let v_purchase_price = document.getElementById("item-price-"+did).value;
          let v_selling_price = document.getElementById("item-selling-price-"+did).value;
          let v_quantity = document.getElementById("item-quantity-"+did).value;
          let v_alias_barcode = document.getElementById("item-alias-barcode-"+did).value;
          let v_commission = document.getElementById("item-commission-"+did).value;
          let v_comission_type = document.querySelector('[name=var-item-comission-type-'+did+']:checked').value;
          product_quantity = parseInt(product_quantity)+parseInt(v_quantity);
          let custom_field_data = [];
          if(document.getElementById("item-custom-fields-json-"+did)){
            let item_custom_fields = document.getElementById("item-custom-fields-json-"+did).value;
            if(item_custom_fields!==''){
              item_custom_fields = JSON.parse(item_custom_fields);
              for (let cfidx = 0; cfidx < item_custom_fields.length; cfidx++) {
                let element = item_custom_fields[cfidx];
                let cfelem = element;
                let cfid = element.id;
                if(document.getElementById("item-custom-fields-"+did+'-'+cfid)){
                  let cfvalue = document.getElementById("item-custom-fields-"+did+'-'+cfid).value;
                  element.value = cfvalue;
                }
                custom_field_data.push(cfelem);
              }
            }
          }
          let v_sku = document.getElementById("item-sku-"+did).value;
          v_sku = v_sku.replace(/[^A-Z0-9]/ig, "");
          let v_barcode = document.getElementById("item-barcode-"+did).value;
          if(v_quantity===""){
            v_quantity = 0;
          }
          
          let option1 = '';
          let value1 = '';
          
          if(document.getElementById("item-option1-"+did)){
            let v_option1 = document.getElementById("item-option1-"+did);
             option1 = v_option1.getAttribute('data-id');
             value1 = v_option1.value;
          }

          let option2 = '';
          let value2 = '';
          
          if(document.getElementById("item-option2-"+did)){
            let v_option2 = document.getElementById("item-option2-"+did);
             option2 = v_option2.getAttribute('data-id');
             value2 = v_option2.value;
          }

          let option3 = '';let value3 = '';
          if(document.getElementById("item-option3-"+did)){
            let v_option3 = document.getElementById("item-option3-"+did);
             option3 = v_option3.getAttribute('data-id');
             value3 = v_option3.value;
          }
          v_item_name = value1+'/'+value2+'/'+value3;
          v_item_name = v_item_name.replace(/\/+$/g, '');

          let v_data = {
            branch_id : user_info.login_id,
            item_name : v_item_name,
            parent_name : p_name,
            inventory_id : v_item_id,
            item_desc : p_description,
            category_id : p_category,
            sku : v_sku,
            barcode_name : v_barcode,
            hsn : p_hsn,
            tax : pp_tax,
            sp_tax : sp_tax,
            part_name : p_partname,
            purchase_price : v_purchase_price,
            selling_price : v_selling_price,
            quantity : v_quantity,
            product_unit_id : product_unit,
            discount : pp_discount,
            lowest_selling_price : min_price,
            pp_tax_type : pp_tax_type,
            sp_tax_type : sp_tax_type,
            sp_discount : sp_discount,
            bundle_quantity : bundle_quantity,
            custom_text : custom_text,
            reorder_quantity : reorder_quantity,
            opening_stock : opening_stock,
            option1 : option1,
            value1 : value1,
            option2 : option2,
            value2 : value2,
            option3 : option3,
            value3 : value3,
            inventory_status :inventory_status,
            is_auto_barcode :is_auto_barcode,
            alias_barcode : v_alias_barcode,
            comission : v_commission,
            comission_type : v_comission_type,
            custom_fields : custom_field_data
        }
        items_arr.push(v_data);
      }
    
      let product_data = {
          branch_id : user_info.login_id,
          item_name : p_name,
          inventory_id : p_id,
          parent_name : p_name,
          item_desc : p_description,
          category_id : p_category,
          sku : p_sku,
          barcode_name : p_barcode,
          alias_barcode : alias_barcode,
          hsn : p_hsn,
          tax : pp_tax,
          sp_tax : sp_tax,
          part_name : p_partname,
          purchase_price : purchase_price,
          quantity : product_quantity, 
          product_unit_id : product_unit,
          discount : pp_discount,
          selling_price : selling_price,
          wholesale_price : wholesale_price,
          comission : comission,
          comission_type : comission_type,
          lowest_selling_price : min_price,
          pp_tax_type : pp_tax_type,
          sp_tax_type : sp_tax_type,
          sp_discount : sp_discount,
          bundle_quantity : bundle_quantity,
          custom_text : custom_text,
          reorder_quantity : reorder_quantity,
          opening_stock : opening_stock,
          inventory_status :inventory_status,
          is_auto_msp :is_auto_msp,
          is_auto_barcode :is_auto_barcode,
          otp:this.state.otp,
          active_tab : this.state.active_tab
      }
      
      if(this.state.active_tab==='edit' && target=='save-product' && user_info.email!=="" && this.state.otp_while_update_product===true){
          this.setState({is_validate_otp:true});
          this.handleSendOTP(product_data);
          return false;
      }else if(this.state.active_tab==='edit' && target=='validate-product-otp'){
          if(this.state.otp==="" || this.state.otp.length<3){
            document.getElementById('otp').focus();
            return false;
          }
      }
      
      let formData = new FormData();
      let upload_photo = document.getElementById("upload_photo").files[0];
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);
      formData.append('photo', upload_photo);
      formData.append('id', parseInt(this.state.update_id));
      formData.append('product_data', JSON.stringify(product_data));
      formData.append('multiple_variant', items_arr.length);
      formData.append('variations', JSON.stringify(items_arr));
      formData.append('deleted_items', deleted_items);
      formData.append('custom_fields', JSON.stringify(this.state.custom_fields_json));
      document.getElementById(target).classList.add("hide");
      document.getElementById(target+'-btn-spinner').classList.remove("hide");
      let api = app_api+'/create_product';
      axios.post(api, formData)
        .then(function (response) {
           let resp = response.data;

           
           if(resp.success===1){
             document.getElementById(target).classList.remove("hide");
             document.getElementById(target+'-btn-spinner').classList.add("hide");
              let expires = resp.expire;
              let product_id = resp.id;
              localStorage.setItem('fetch_inv',expires);
             this.setState({is_validate_otp:false});
             if(product_id>0){
                window.location.href = '/products/view/'+product_id;
             }else{
                window.location.href = "/products";
             }
           }else if(resp.success===2){
              document.getElementById('validate-product-otp').classList.remove("hide");
              document.getElementById('validate-product-otp-btn-spinner').classList.add("hide");
              let msg = resp.message;
              this.setState({otp_error:msg});
           }
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }
    handleCheckHSN = () =>{
      let is_hsn = true;
    
      if(document.getElementById("product-hsn")){
        let thisval = document.getElementById("product-hsn").value;
        if(thisval!="" && thisval.length<6){
          is_hsn = false;
          toast("Oops! HSN must be 6 character or more.");
          setTimeout(() => {
            document.getElementById("product-hsn").focus();
          }, 100);
        }
      }
    
      return is_hsn;
    }
    handleSetCustomField = (e) =>{
        let thisvalue = e.currentTarget.value;
        let target = e.currentTarget.getAttribute('data-target');
        let idx = e.currentTarget.getAttribute('data-id');
        let custom_fields_json =  this.state.custom_fields_json;
        custom_fields_json[idx][target] = thisvalue;
        this.setState({custom_fields_json:custom_fields_json});
    }
    
    handleCheckBarcode = (e) =>{
      let thisval = e.currentTarget.value;
      if(thisval!==""){

        let set_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          barcode : thisval,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        let opt_api = app_api+'/inventory/check_barcode_existance';
        axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
          this.setState({is_barcode_exists:resp.is_exists});
          this.setState({existing_barcode_item:resp.item_name});
        }.bind(this)).catch(function(res){
          
        })
      }else{
        this.setState({is_barcode_exists:false});
        this.setState({existing_barcode_item:''});
      }
    }
    handleCloseOTP = () =>{
      this.setState({is_validate_otp:false});
    }
    render() {

        let is_variant_checked = this.state.is_variant_checked;
        let hide_pricing = '';
        let mrbtm = '0px';
        if(is_variant_checked && this.state.variantList.length>0){
          hide_pricing = 'hide';
          mrbtm = '';
        }

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                  <nav className="navbar border-bottom">
                    <div className="row">
                      <div className="col-md-12">
                        <Breadcrumbs link="/products" title="Products" sub_title={this.state.active_tab+" Product"}/>
                      </div>
                    </div>
                  </nav>
                  <div className="row" style={{position:'relative'}}>
                    <span id="handleSetCategory" onClick={this.handleSetCategory}></span>
                  <ServiceLoader show={this.state.show_loader}/>
                  <input type="hidden" id="deleted_items" />
                    <div className="col-md-8">
                      <div className={"container-fluid"} style={{marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Product Info</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Product Name</label>
                                </div>
                                <div className="col-md-5">
                                  <Autocomplete
                                    options={this.state.item_options}
                                   
                                    data_target="add-direct-product"
                                    title="Start typing SKU or name..."  
                                    id={"item-name-0"}
                                    className="auto-comp-opt"
                                    data_id={0}
                                    active_tab="inventory"
                                    input_id={"item-box-0"}
                                    target="item-box"
                                    clsName = ""
                                    item_info={this.state.inventory_item}
                                  />
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Description</label>
                                </div>
                                <div className="col-md-5">
                                  <textarea id="product-description" className="form-control"  defaultValue={(this.state.inventory_item.item_desc)?this.state.inventory_item.item_desc:''}></textarea>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Category</label>
                                </div>
                                <div className="col-md-5">

                                 <TagFields target="category" name="product-category" data_id="product-category" default={(this.state.product_info.category_name)?this.state.product_info.category_name:''} default_id={(this.state.inventory_item.category_id)?this.state.inventory_item.category_id:""} className="add-product-cat"/>
                                
                              
                                </div> 
                                <div className="col-md-4">
                                  <div id="create-category-spinner" className="hide">
                                   <FontAwesomeIcon icon={faSpinner} pulse/>
                                  </div>
                                  {/* <div id="create-category-button">
                                  or <a className="new_contact_a" onClick={this.handleCreateCategory}>create a new category</a>
                                  </div> */}
                                </div>
                              </div>
                              <div className="row mrtop-10 hide">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>SKU</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="product-sku" defaultValue={(this.state.inventory_item.sku)?this.state.inventory_item.sku:''} onKeyDown={this.handleSKUCheck}/>
                                </div> 
                                <div className="col-md-4">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" defaultChecked={false} id="auto-generate-sku" onChange={this.handleAutoGenerate} />
                                    <label className="form-check-label" htmlFor="auto-generate-sku">Auto Generate</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="product-barcode"  defaultValue={(this.state.inventory_item.barcode_name)?this.state.inventory_item.barcode_name:''} onChange={this.handleCheckBarcode}/>
                                  <input type="text" className="form-control hide" id="barcode-sequence"/>
                                  {(this.state.is_barcode_exists===true) &&
                                    <p className="error" style={{margin:'0px',fontSize:'12px'}}>This barcode is associated with {this.state.existing_barcode_item},<br/>Please try another barcode</p>
                                  }
                                </div> 
                                <div className="col-md-4">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" defaultChecked={false} id="auto-generate-bc"  onChange={this.handleAutoGenerate}/>
                                    <label className="form-check-label" htmlFor="auto-generate-bc">Auto Generate</label>
                                  </div>
                                  {(this.state.update_id>0) &&
                                    <button type="button" className="btn btn-success hide" onClick={this.handleRegenerateBarcodeAlert}>Regenerate Barcode</button>
                                  }
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Alias Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="alias-barcode" className="form-control"  defaultValue={(this.state.inventory_item.alias_barcode)?this.state.inventory_item.alias_barcode:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>HSN Code</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-hsn" className="form-control"  defaultValue={(this.state.inventory_item.hsn)?this.state.inventory_item.hsn:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Part Name</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-partname" className="form-control"  defaultValue={(this.state.inventory_item.part_name)?this.state.inventory_item.part_name:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className={"container-fluid "+hide_pricing} style={{marginTop:'0px',marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Pricing</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Purchase Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="purchase-price"  onChange={this.handleCalculation} defaultValue={(this.state.inventory_item.purchase_price)?this.state.inventory_item.purchase_price:''}/>
                                </div> 
                                <div className="col-md-4">
                                  <p className="error" id="pp-error" style={{fontSize:'15px'}}></p>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Quantity</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-quantity" className="form-control" defaultValue={(this.state.inventory_item.availability)?this.state.inventory_item.availability:''} onChange={this.handleChangeQuantity}/>
                                </div> 
                                <div className="col-md-4">
                                   <span id="avail-quan"></span>
                                   <input type="hidden" id="avail-quan-hidden" />
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Unit</label>
                                </div>
                                <div className="col-md-5">
                                  <ProductUnits id="product-unit" show="dropdown" default={(this.state.inventory_item && this.state.inventory_item.product_unit_id)?this.state.inventory_item.product_unit_id:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Tax</label>
                                </div>
                                <div className="col-md-5">
                                  <input list="pp_tax" className="form-control" name="pp_tax" id="pp_tax_dd" defaultValue={(this.state.inventory_item.tax)?this.state.inventory_item.tax:''}/>
                                  <datalist id="pp_tax">
                                  
                                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                       return (
                                        <option>{value.value}</option>
                                      )
                                     }) : '' }

                                  </datalist>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Tax Type</label>
                                </div>
                                <div className="col-md-5">
                                  <select className="form-control" id="pp-tax-type">
                                    <option value="including">Including Tax</option>
                                    <option value="excluding">Excluding Tax</option>
                                  </select>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Discount</label>
                                </div>
                                <div className="col-md-5">
                                  <div className="input-group">
                                    <input type="text" className="form-control no-border-right" id="pp-discount" defaultValue={(this.state.inventory_item.discount)?this.state.inventory_item.discount:''}/>
                                    <div className="input-group-append">
                                      <span className="input-group-text pad-left-none">%</span>
                                    </div>
                                  </div>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div style={{marginTop:'10px',borderBottom:'2px dotted #d7d7d7'}}></div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Selling Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="selling-price" onChange={this.handleCalculation} defaultValue={(this.state.inventory_item.selling_price)?this.state.inventory_item.selling_price:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Min. Selling Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="minimum-selling-price" className="form-control" defaultValue={(this.state.inventory_item.lowest_selling_price)?this.state.inventory_item.lowest_selling_price:''}/>

                                  <input type="hidden" id="hid-minimum-selling-price" className="form-control" defaultValue={(this.state.inventory_item.lowest_selling_price)?this.state.inventory_item.lowest_selling_price:''}/>
                                </div> 
                                <div className="col-md-4" style={{marginTop:'-5px'}}>
                                  <div className="form-group" style={{marginBottom:'0px'}}>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" name={"auto"} id={"auto"} value="auto"  onChange={this.handleAutoMSPCalculate} defaultChecked={this.state.inventory_item.is_auto_msp}/>
                                      <label className="form-check-label" htmlFor={"auto"}>
                                      Auto Calculate
                                      </label>
                                    </div>
                                  </div>
                                    <p style={{fontSize:'15px'}}>Margin : {this.state.msp_margin}%</p>
                                    <p className="error" id="msp-error" style={{fontSize:'15px'}}></p>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Wholesale Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="wholesale-price"  defaultValue={(this.state.inventory_item.wholesale_price>0)?this.state.inventory_item.wholesale_price:''}/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Tax</label>
                                </div>
                                <div className="col-md-5">
                                   <input list="pp_tax" className="form-control" name="sp_tax" id="sp_tax_dd"  defaultValue={(this.state.inventory_item.sp_tax)?this.state.inventory_item.sp_tax:''}/>
                                  <datalist id="sp_tax">
                                  
                                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                       return (
                                        <option>{value.value}</option>
                                      )
                                     }) : '' }

                                  </datalist>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Tax Type</label>
                                </div>
                                <div className="col-md-5">
                                  <select className="form-control" id="sp-tax-type">
                                    <option value="including">Including Tax</option>
                                    <option value="excluding">Excluding Tax</option>
                                  </select>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px'}}>Discount</label>
                                </div>
                                <div className="col-md-5">
                                  <div className="input-group">
                                    <input type="text" id="sp-discount" className="form-control no-border-right" defaultValue={(this.state.inventory_item.sp_discount)?this.state.inventory_item.sp_discount:''}/>
                                    <div className="input-group-append">
                                      <span className="input-group-text pad-left-none">%</span>
                                    </div>
                                  </div>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row" style={{marginTop:'20px'}}>
                                <div className="col-md-6">
                                  <span>Margin : </span><span id="product-margin">{this.currency_format(this.state.margin)}%</span>
                                </div> 
                                <div className="col-md-6">
                                  <span>Profit : </span><span id="product-profit">₹{this.currency_format(this.state.profit)}</span>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>

                      <div className="container-fluid" style={{marginTop:mrbtm,marginBottom:'10px'}}>
                        <div className="content-area">
                        {(this.variantOptions.length>0) &&
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Variants</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-12">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" checked={(this.state.is_variant_checked===true)?true:false} id="check-variants" onChange={this.handleVariantChange}/>
                                    <label className="form-check-label" htmlFor="check-variants">
                                      This product has multiple options, like different sizes or colors
                                    </label>
                                  </div>
                                </div>
                              </div>
                           </div>
                         }
                        {(this.state.is_variant_checked) &&
                          <>
                          {(this.variantOptions.length>0) &&
                           <div className="main-content" style={{borderTop:'1px solid #d7d7d7',padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                    <label style={{fontWeight:'bold'}}>OPTIONS</label>
                                </div>
                              </div>
                              <div>
                                {this.state.variantOptions}
                              </div>
                              {(this.variantOptions.length<3) &&
                              <div className="row mrtop-10">
                                  <div className="col-md-12">
                                    <button type="button" className="btn btn-light" style={{border:'1px solid #d7d7d7'}} onClick={this.handleMoreVariantOpt}>Add another option</button>
                                  </div>
                              </div>
                              }
                           </div>
                         }
                          
                           {(this.state.variantList.length>0) &&
                       
                           <div className="main-content" style={{borderTop:'1px solid #d7d7d7',padding:'20px'}}>
                              <div className="row">
                              <div className="col-md-12">
                                  <label style={{fontWeight:'bold'}}>
                                    BULK ACTION
                                  </label>
                              </div>
                            </div>
                            <div className="row" style={{paddingTop:'5px',paddingBottom: '15px'}}>
                              <div className="col-md-12">
                                <table className="table" style={{display: 'block',overflowX: 'auto',whiteSpace:'nowrap'}}>
                                  <thead>
                                    <tr>  
                                      <th>Purchase. Price</th>
                                      <th>Increment</th>
                                      <th>Selling Price</th>
                                      <th>Increment</th>
                                      <th>Quantity</th>
                                      <th>Alias Barcode</th>
                                      <th>Commission</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.variantPreviewList}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                              <div className="row">
                                <div className="col-md-12">
                                    <label style={{fontWeight:'bold'}}>
                                    {(this.variantOptions.length>0)?'PREVIEW':'Variants'
                                    }
                                    </label>
                                    {(this.variantOptions.length===0) && 
                                      <a href={"/variant/edit/"+this.state.update_id+"/0"} style={{float:'right'}}>Add Variant</a>
                                    }
                                </div>
                              </div>
                              
                              <div className="row variation-options" style={{borderBottom: '2px solid #d7d7d7',paddingTop:'5px',paddingBottom: '15px'}}>
                                <div className="col-md-12">
                                  <table className="table" style={{display: 'block',overflowX: 'auto',whiteSpace:'nowrap'}}>
                                    <thead>
                                      <tr>  
                                        <th></th>
                                        {(this.state.options_head && this.state.options_head.length===0) &&
                                          <th>Variant</th>
                                        }
                                        
                                        {(this.state.options_head && this.state.options_head.length > 0) ?this.state.options_head.map( (value, index) => {
                                           return (
                                            <>
                                            {(value!=="") &&
                                              <th>{value}</th>
                                            }
                                            </>
                                          )
                                         }) : '' }
                                      
                                        <th>Purchase Price</th>
                                        <th>Selling Price</th>
                                        <th>Quantity</th>
                                        <th className="hide">SKU</th>
                                        <th>Barcode</th>
                                        <th>Alias Barcode</th>
                                        <th>Commission</th>
                                        {(this.state.show_inventory_custom_field===true && this.state.custom_fields_json.length>0) &&
                                        <>
                                          {(this.state.custom_fields_json.map((value,index)=>{
                                            return(
                                              <th>{value.name}</th>
                                            )
                                          }))}
                                        </>
                                      }
                                        <th></th>
                                        {(this.state.options_head && this.state.options_head.length > 0) &&
                                        <th></th>
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {this.state.variantList} */}
                                      {(this.state.variantList.map((value,index)=>{
                                        if(this.state.show_inventory_custom_field===true && value.item_data.custom_fileds===undefined &&  this.state.custom_fields_json.length>0){
                                          value.item_data.custom_fileds = [];
                                          value.item_data.custom_fileds.custom_fields = this.state.custom_fields_json;
                                        }
                                        return(
                                          <VariationList item_data={value.item_data} option_data={value.option_data} label={value.lbl} index={index} removeItem={this.handleRemoveItem.bind(this)} editItem={this.handleEditItem.bind(this)}/>
                                        )
                                      }))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                           </div>
                          
                         }
                          </>
                         }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-4">
                                  <label style={{fontSize:'14px'}}>Status</label>
                                </div>
                                <div className="col-md-8">
                                  <select className="form-control" id="product-status" value={(this.state.inventory_status)?this.state.inventory_status:''} onChange={this.handleStatusChange} style={{width:'100%'}}>
                                    <option value="Draft">Inactive</option>
                                    <option value="Active">Active</option>
                                  </select>
                                </div> 
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12 text-center">
                                    <div style={{width: '150px',height: '150px',border: '1px solid #d7d7d7',margin: '0 auto'}}>
                                      <div id="uploaded_photo" style={{backgroundImage:'url('+this.state.product_image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height:'100%'}}></div>
                                    </div>
                                </div>
                                <div className="col-md-12 mrtop-10">
                                    <input type="file" onChange={this.onFileChange} id="upload_photo" className="hide"/> 
                                    <button type="button" onClick={this.onFileClick}  className="btn btn-secondary" style={{width:'100%'}}>Add Image</button>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Additional Info</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Bundle Quantity</div>
                                <div className="col-md-8">
                                  <input type="text" id="bundle-quantity" className="form-control" defaultValue={(this.state.inventory_item.bundle_quantity)?this.state.inventory_item.bundle_quantity:''}/>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Custom Text</div>
                                <div className="col-md-8">
                                  <input type="text" id="custom-text" className="form-control"  defaultValue={(this.state.inventory_item.custom_text)?this.state.inventory_item.custom_text:''}/>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Other</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Reorder Quantity</div>
                                <div className="col-md-8">
                                  <input type="text" id="reorder-quantity" className="form-control"  defaultValue={(this.state.inventory_item.reorder_quantity)?this.state.inventory_item.reorder_quantity:''}/>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Opening Stock</div>
                                <div className="col-md-8">
                                  <input type="text" id="opening-stock" className="form-control"  defaultValue={(this.state.inventory_item.opening_stock)?this.state.inventory_item.opening_stock:''}/>
                                </div>
                              </div>
                              
                              <div className="row mrtop-10">
                                <div className="col-md-4">Commission</div>
                                <div className="col-md-8">
                               
                                  <input type="text" className="form-control pull-right" defaultValue={this.state.inventory_item.comission} id="comission" style={{width:'85%'}}/>
                                    <div className="form-group round-blk">
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="comission" id="comission-rs" value="rs" checked={(this.state.comission_type==='rs')?true:false} onClick={this.handleChangeCommissionType}/>
                                      <label className="form-check-label rs-sym" htmlFor="comission-rs">
                                        ₹
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="comission" id="comission-per" value="per"  onClick={this.handleChangeCommissionType} checked={(this.state.comission_type==='per')?true:false} />
                                      <label className="form-check-label" htmlFor="comission-per">
                                        %
                                      </label>
                                    </div>
                                      
                                    </div>
                              
                                </div>
                              </div>
                              
                           </div>
                        </div>
                      </div>
                      {(this.state.show_inventory_custom_field===true && this.state.custom_fields_json.length>0) &&
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Custom Fields</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <table className="table cftbl" style={{width:'100%'}}>
                               
                                      <thead>
                                        <tr>  
                                          <th>Name</th>
                                          <th>Value</th>
                                        </tr>
                                      </thead>
                                 
                                  <tbody>
                               
                                    {this.state.custom_fields_json.map((value,index) => {
                                      return (
                                        <>
                                        <tr key={'cfp-'+index}>
                                          <td>{value.name}</td>
                                          <td><input type="text" className="form-control" value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField}/></td>
                                        </tr>
                                        </>
                                      )
                                    })}
                                  
                                 
                                  </tbody>
                                </table>
                              </div>
                           </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                  <div className="row" style={{position:'sticky',bottom:'0',zIndex:'9'}}>
                    <div className="col-md-12">
                      <div className="container-fluid" style={{width:'97.5%',marginBottom:'0px'}}>
                        <div className="content-area">
                          <div className="main-content" style={{padding:'20px'}}>
                            <div className="row">
                              <div className="col-md-12"> 
                                {(this.is_access('inventory-delete') && this.state.active_tab==='edit') &&
                                  <button type="button"  className="btn btn-danger" onClick={this.deleteRow} >Delete</button>
                                }
                                <button type="button" onClick={this.handleProductSave} id="save-product" className="btn btn-success pull-right">Save Changes</button>
                                <button type="button" id="save-product-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalBox id="delete_confirm" title="Delete" data_id="Delete"/>
                <ModalBox id="add_category" title="ADD CATEGORY"  data_id="Category"/>

                {(this.state.update_id>0) &&

                <div id="regnerate-barcode" className="modal fade">
                <div className="modal-dialog modal-confirm">
                  <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box icon-print">
                        <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                      </div>
                      {(this.state.barcode_error==='') &&
                        <h5 className="modal-title w-100">The barcode <b>{this.state.current_barcode}</b> will be overwritten to this barcode <b>{this.state.new_barcode}</b> from all associated places including purchase bill. Are you sure you want to change the barcode sequence to <b>{this.state.new_barcode}</b>?</h5>  
                      }
                      {(this.state.barcode_error!=='') &&
                        <div className="alert alert-danger" role="alert">
                          {this.state.barcode_error}
                        </div>
                      }
                    </div>
                    <div className="modal-footer justify-content-center" style={{padding:'0px'}}>
                      <div className="row">
                        <div className="col-md-6 mrtop-10">
                          <button type="button" className="btn btn-secondary" onClick={this.handleCloseBarcodeAlert}>
                          {(this.state.barcode_error==='') &&
                            <span>No</span>
                          }
                          {(this.state.barcode_error!=='') &&
                            <span>Close</span>
                          }
                          </button>
                        </div>
                        {(this.state.barcode_error==='') &&
                          <div className="col-md-6 mrtop-10">
                            <button type="button" id="re-gen-bc" onClick={this.handleRegenerateBarcode} className="btn btn-primary">Yes</button>
                            <button type="button" id="re-gen-bc-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {(this.state.is_validate_otp===true) &&
                <div id="validate-otp" className="modal" style={{display:'block'}}>
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box icon-print">
                          <span className="material-icons"><FontAwesomeIcon icon={faKey}/></span>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Enter OTP</label>
                            <input type="text" className="form-control" placeholder="Enter OTP to Validate" onChange={(e)=>this.handleChangeOTP(e.target.value)} id="otp"/>
                          </div>
                          {(this.state.otp_error!=="") &&
                          <div className="col-md-12">
                            <p className="error">{this.state.otp_error}</p>
                          </div>
                          }
                        </div>
                      </div>
                      <div className="modal-footer" style={{padding:'0px'}}>
                        <div className="row">
                          <div className="col-md-6 mrtop-10">
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseOTP}>Close</button>
                          </div>
                          
                          <div className="col-md-6 mrtop-10">
                            <button type="button" id="validate-product-otp" onClick={this.handleProductSave} className="btn btn-primary">Validate</button>
                            <button type="button" id="validate-product-otp-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
            </div>
        );
    }
}

class VariantOptions extends Component {
  constructor(props) {
      super(props)
      this.state = {
        tags: [],
        tag_error : '',
        default_image : 'https://app.business-booster.net/app/uploads/no-image.png',
      }
      this.variantList=[];
      this.handleVariantTag = this.handleVariantTag.bind(this);
      this.removeTag = this.removeTag.bind(this);
      this.removeTagList = this.removeTagList.bind(this);
      this.handleVariantList = this.handleVariantList.bind(this);
      this.handleAddRemoveRow = this.handleAddRemoveRow.bind(this);
      this.autoGenerate = this.autoGenerate.bind(this);
      this.handleTextBoxFocus = this.handleTextBoxFocus.bind(this);
  }
  removeTagList(e){

    let id = e.currentTarget.id;
    this.setState({tags:[]});
    let rm = parseInt(id)-1;
    this.props.updateOptionState(rm);
    let this_ = this;
    setTimeout(function(){
      this_.handleAddRemoveRow();
      let rm_items = document.querySelectorAll(".var-opt-row");
      let rm_cls = document.querySelectorAll(".remove-var-opt");
      if(rm_items.length===1){
        for(let i=0;i<rm_cls.length;i++){
            rm_cls[i].classList.add('hide');
        }
      }
    },50);
  }
  removeTag(e){
    let id = e.currentTarget.id;
    let tags = this.state.tags;
    tags.splice(id, 1);
    this.setState({tags:tags});
    let this_ = this;
    setTimeout(function(){
      this_.handleAddRemoveRow();
    },50);
  }
  handleVariantTag(e){
    let data_id = e.currentTarget.getAttribute('data-id');
    let value = e.currentTarget.value;

    if(e.keyCode===8){
     
      if(value===""){
        let tags = this.state.tags;
        let l = tags.length-1;
        if(l>=0){
          tags.splice(l, 1);
          this.setState({tags:tags});
        }
      }
    }else{
      let thisvalue = e.currentTarget.value;
      if((e.keyCode===13 || e.keyCode===9) && thisvalue!==''){
          let tags = this.state.tags;
          let t_arr =thisvalue;
          if(tags.indexOf(thisvalue)<0){
            tags.push(t_arr);
            this.setState({tags:tags});
            document.getElementById("var-tag-box-"+data_id).focus();
            e.currentTarget.value = '';
            e.currentTarget.placeholder = '';
            this.setState({tag_error:''});
          }else{
            let error = thisvalue+" variant already exists";
            this.setState({tag_error:error});
          }
      }else if(e.keyCode===13 && thisvalue===''){
        if(document.getElementById("prev-item-price")){
          document.getElementById("prev-item-price").focus();
        }
      }
    }
  }
  autoGenerate(str,length) {
       var result           = '';
       var characters       = str;
       characters = characters.replace(/[^a-zA-Z0-9]/g, "");
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result.toUpperCase();
    }
  handleAddRemoveRow(){
    this.variantList = [];
    let opt_row = document.querySelectorAll(".var-opt-row");
    let total_tag = 1;
    for(let r = 0; r < opt_row.length; r++){
      let idx = opt_row[r].getAttribute('data-id');
      if( document.getElementById("var-item-value-"+idx)){

        let var_tags = document.getElementById("var-item-value-"+idx).value;
        
        if(var_tags!==""){
          let v_tags = var_tags.split(',');
          let tag_len = v_tags.length;
          total_tag = total_tag*tag_len;
        }
      }
    }
    let lbls = [];
    let first_opt = document.querySelectorAll(".var-item-tags-hidden");
    let option_names = document.querySelectorAll(".option-names");
    
    if(opt_row.length===1 || ((first_opt[1]!==undefined && first_opt[1].value==="") && (first_opt[2]!==undefined && first_opt[2].value===""))){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
          v_tags = v_tags.split(',');
          let t_len = v_tags.length;
          for(let k=0;k<t_len;k++){
            let opt = {option1:option1,value1:v_tags[k],label:v_tags[k]};
            lbls.push(opt);
          }
      }
    }else if(opt_row.length===2 && ((first_opt[1]!==undefined && first_opt[1].value===""))){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
          v_tags = v_tags.split(',');
          let t_len = v_tags.length;
          for(let k=0;k<t_len;k++){
            let opt = {option1:option1,value1:v_tags[k],label:v_tags[k]};
            lbls.push(opt);
            
          }
      }
    }else if(opt_row.length===2 || (first_opt[2]!==undefined && first_opt[2].value==="")){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
        v_tags = v_tags.split(',');
        let t_len = v_tags.length;
        for(let k=0;k<t_len;k++){
          let tn = v_tags[k];
          let sencond_tag = first_opt[1].value;
          let option2 = option_names[1].value;
          if(sencond_tag!==""){
            sencond_tag = sencond_tag.split(",");
            for(let n=0;n<sencond_tag.length;n++){
              let c_tag = sencond_tag[n];
              let lb = tn+"/"+c_tag;

              let opt = {option1:option1,value1:tn,option2:option2,value2:c_tag,label:lb};
              lbls.push(opt);
              
            }
          }
        }
      }
    }else if(opt_row.length===3){
      let v_tags = first_opt[0].value;
      let option1 = option_names[0].value;
      if(v_tags!==""){
        v_tags = v_tags.split(',');
        let t_len = v_tags.length;
        for(let k=0;k<t_len;k++){
          let tn = v_tags[k];
          let sencond_tag = first_opt[1].value;
          let option2 = option_names[1].value;
          if(sencond_tag!==""){
            sencond_tag = sencond_tag.split(",");
            for(let n=0;n<sencond_tag.length;n++){
              let c_tag = sencond_tag[n];
              let lb = tn+"/"+c_tag;
              let third_tag = first_opt[2].value;
              let option3 = option_names[2].value;
              if(third_tag!==""){
                third_tag = third_tag.split(",");
                for(let m=0;m<third_tag.length;m++){
                  let t_tag = third_tag[m];
                  let ttag = lb+"/"+t_tag;

                  let opt = {option1:option1,value1:tn,option2:option2,value2:c_tag,option3:option3,value3:t_tag,label:ttag};
                  lbls.push(opt);
                }
              }
            }
          }
        }
      }
    }

    let purchase_price = document.getElementById("purchase-price").value;
    let selling_price = document.getElementById("selling-price").value;
    let quantity = document.getElementById("product-quantity").value;
    let item_data = {};

    this.variantList = [];
    let varListTmp = [];
    for(let k = 0; k<total_tag; k++){
      let option_data = lbls[k];
      let lbl = '';
      let inv_id = 0;
      if(this.props.variation_list!==undefined && this.props.variation_list[k]!==undefined && this.props.variation_list[k].inventory_id){
        inv_id =  this.props.variation_list[k].inventory_id;
      }
      if(lbls[k]!==undefined && lbls[k].label!==undefined){
        lbl = lbls[k].label;
      }
      let sku = this.autoGenerate(lbl,6);
      let barcode = this.autoGenerate(lbl,10);
      if(document.getElementById("auto-generate-bc") && document.getElementById("auto-generate-bc").checked){
        barcode = 'Auto Generate';
      }
      if(k>0){
        item_data = {image:this.state.default_image,inventory_id:inv_id,purchase_price:purchase_price,selling_price:selling_price,availability:0,barcode_name:barcode,sku:sku};
        if(option_data!==undefined){
          if(option_data.option1){
            item_data.option1 = option_data.option1;
            item_data.value1 = option_data.value1;
          }
          if(option_data.option2){
            item_data.option2 = option_data.option2;
            item_data.value2 = option_data.value2;
          }
          if(option_data.option3){
            item_data.option3 = option_data.option3;
            item_data.value3 = option_data.value3;
          }
        }
      }else{
        item_data = {image:this.state.default_image,inventory_id:inv_id,purchase_price:purchase_price,selling_price:selling_price,availability:quantity,barcode_name:barcode,sku:sku};
        if(option_data!==undefined){
          if(option_data.option1){
            item_data.option1 = option_data.option1;
            item_data.value1 = option_data.value1;
          }
          if(option_data.option2){
            item_data.option2 = option_data.option2;
            item_data.value2 = option_data.value2;
          }
          if(option_data.option3){
            item_data.option3 = option_data.option3;
            item_data.value3 = option_data.value3;
          }
        }
      }
      let varitem = {item_data:item_data,option_data:option_data,lbl:lbl};
      //varListTmp.push(varitem);
      //this.variantList.push(<VariationList item_data={item_data} option_data={option_data} label={lbl} index={k} removeItem={this.handleRemoveItem.bind(this)}/>);
      this.variantList.push(varitem);
    }

    let hidden_tags = document.querySelectorAll(".var-item-tags-hidden");
    let is_empty = true;
    for(let ht=0;ht<hidden_tags.length;ht++){
       if(hidden_tags[ht].value!==""){
          is_empty = false;
          break;
       }
    }
    if(is_empty){
      this.variantList=[];
    }
    this.props.updateState(this.variantList);   
  }

  handleRemoveItem(id){
    let deleted_items = document.getElementById('deleted_items').value;
    let d_items = [];
    let item_id = document.getElementById("item-id-"+id).value;
    if(deleted_items!==""){
      let ids = deleted_items.split(",");
      for(let i=0;i<ids.length;i++){
        if(d_items.indexOf(ids[i])<0){
          d_items.push(ids[i]);
        }
      }
    }
    d_items.push(item_id);
    document.getElementById('deleted_items').value = d_items;   
    //this.variantList.splice(id,1);
     let item_lbl = document.getElementById("item-lbl-"+id).innerHTML;
      for(let i=this.variantList.length-1;i>=0;i--){
        if(this.variantList[i].props.label===item_lbl){
            this.variantList.splice(i,1);
        }
      }
    this.props.updateState(this.variantList);
  }

  handleVariantList(e){
    let target = e.currentTarget.getAttribute('data-id');
    let value = e.currentTarget.value;

    if(e.keyCode===13 || e.keyCode===9 || e.keyCode===8){
        this.handleAddRemoveRow();
    }
  }
  componentDidMount(){
    setTimeout(function(){
      let rm_items = document.querySelectorAll(".var-opt-row");
      let rm_cls = document.querySelectorAll(".remove-var-opt");
      if(rm_items.length>1){
        for(let i=0;i<rm_cls.length;i++){
            rm_cls[i].classList.remove('hide');
        }
      }
    },100);
    if(this.props.value){
      this.setState({tags:this.props.value})
    }
  }
   handleTextBoxFocus(e){
    let data_id = e.currentTarget.getAttribute('data-id');
    document.getElementById("var-tag-box-"+data_id).focus();
  }
  render() {

  return <div className="row variation-options var-opt-row" style={{borderBottom: '2px solid #d7d7d7',paddingTop:'5px',paddingBottom: '15px'}} id={"var-opt-row-"+this.props.index} data-id={this.props.index}>
            <div className="col-md-4">
              <label style={{fontWeight:'bold'}}>{this.props.label}</label>
              <input type="text" id={"option-name-"+this.props.index} className="form-control option-names" defaultValue={this.props.option}/>
            </div>
            <div className="col-md-8">
              <div>
              <label style={{fontWeight:'bold'}}>
                Variation Name
              </label>
              <span style={{cursor:'pointer',float: 'right',color: 'cornflowerblue'}} id={this.props.index} onClick={this.removeTagList} className="remove-var-opt hide">Remove</span>
              </div>
                <div className="set-tax-box" data-id={this.props.index}  onClick={this.handleTextBoxFocus}>
                  
                     {(this.state.tags.length > 0) ? this.state.tags.map( (value, index) => {
                         return (
                         <>
                            <span key={"t-"+index} className="v-tag" id={"v-tag-"+index}>
                              {value}
                              <span key={"tc-"+index} className="t-close" id={index} onClick={this.removeTag}><FontAwesomeIcon icon={faTimes} /></span>
                            </span>
                          </>
                          )
                        }):''}
                 
                  <input type="text" id={"var-tag-box-"+this.props.index} data-id={this.props.index} className="form-control var-tag-box" onKeyDown={this.handleVariantTag}  onKeyUp={this.handleVariantList}/>
                  <input type="hidden" className="var-item-tags-hidden" id={"var-item-value-"+this.props.index} data-id={this.props.index} defaultValue={this.state.tags}/>
                
              </div>
              <div><span className="error">{this.state.tag_error}</span></div>
            </div>
        </div>;
  }
}
class VariationList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  handleRemoveItem(e){
    let id=e.currentTarget.id;
    let deleted_items = document.getElementById('deleted_items').value;
    let d_items = [];
    let item_id = document.getElementById("item-id-"+id).value;
    if(deleted_items!==""){
      let ids = deleted_items.split(",");
      for(let i=0;i<ids.length;i++){
        if(d_items.indexOf(ids[i])<0){
          d_items.push(ids[i]);
        }
      }
    }
    d_items.push(item_id);
    document.getElementById('deleted_items').value = d_items;
    this.props.removeItem(id);
    //document.getElementById("variation-item-"+id).remove();
  }

  render() {
  return  <tr className="variation-item" id={"variation-item-"+this.props.index} data-id={this.props.index} style={{position:'relative'}}>
            <td>
              <div style={{backgroundImage:'url('+this.props.item_data.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width:'40px',border: '1px solid #d7d7d7'}}></div>
            </td>
           
            <td id={"item-lbl-"+this.props.index}>
                {this.props.label}
            </td>
            
           {/*  {(this.props.item_data.inventory_id>0) &&
              <>
                {(this.props.item_data.option1!=="" && this.props.item_data.option1!==null) &&
                  <td style={{minWidth:'110px'}}>
                    <input type="text" className="form-control" id={"item-option1-"+this.props.index} defaultValue={(this.props.item_data.value1)?this.props.item_data.value1:''} data-id={(this.props.item_data.option1)?this.props.item_data.option1:''} tabindex={'111'+this.props.index}/>
                  </td>
                }
                {(this.props.item_data.option2!=="" && this.props.item_data.option2!==null) &&
                  <td style={{minWidth:'110px'}}>
                    <input type="text" className="form-control" id={"item-option2-"+this.props.index} defaultValue={(this.props.item_data.value2)?this.props.item_data.value2:''} data-id={(this.props.item_data.option2)?this.props.item_data.option2:''}  tabindex={'112'+this.props.index}/>
                  </td>
                }
                {(this.props.item_data.option3!=="" && this.props.item_data.option3!==null) &&
                  <td style={{minWidth:'110px'}}>
                    <input type="text" className="form-control" id={"item-option3-"+this.props.index} defaultValue={(this.props.item_data.value3)?this.props.item_data.value3:''} data-id={(this.props.item_data.option3)?this.props.item_data.option3:''}  tabindex={'113'+this.props.index}/>
                  </td>
                }
              </>
            } */}
            <td style={{minWidth:'110px'}}>
              <input type="hidden" className="form-control" defaultValue={this.props.item_data.inventory_id} id={"item-id-"+this.props.index}/>
              <input id={"item-price-"+this.props.index} type="text" className="form-control" defaultValue={this.props.item_data.purchase_price} data-target="var-item-price"  tabindex={'114'+this.props.index}/>
              
           
              <input type="hidden" id={"item-option1-"+this.props.index} defaultValue={(this.props.item_data.value1)?this.props.item_data.value1:''} data-id={(this.props.item_data.option1)?this.props.item_data.option1:''} />
              <input type="hidden" id={"item-option2-"+this.props.index} defaultValue={(this.props.item_data.value2)?this.props.item_data.value2:''} data-id={(this.props.item_data.option2)?this.props.item_data.option2:''} />
              <input type="hidden" id={"item-option3-"+this.props.index} defaultValue={(this.props.item_data.value3)?this.props.item_data.value3:''} data-id={(this.props.item_data.option3)?this.props.item_data.option3:''}/ >
              
            </td>
            <td style={{minWidth:'110px'}}>
             <input id={"item-selling-price-"+this.props.index} type="text" className="form-control" defaultValue={this.props.item_data.selling_price} data-target="var-item-selling-price"  tabindex={'115'+this.props.index}/>
            </td>
            
            <td style={{minWidth:'110px'}}>
              <input id={"item-quantity-"+this.props.index} type="text" className="form-control"  defaultValue={this.props.item_data.availability} data-target="var-item-quantity"  tabindex={'116'+this.props.index}/>
            </td>
            
            <td style={{minWidth:'110px'}} className="hide"><input id={"item-sku-"+this.props.index} type="text" className="form-control" defaultValue={this.props.item_data.sku} /></td>

            <td style={{minWidth:'110px'}}><input id={"item-barcode-"+this.props.index} type="text" className="form-control item-barcodes" defaultValue={this.props.item_data.barcode_name}  tabindex={'118'+this.props.index}/></td>
            <td style={{minWidth:'110px'}}>
              <input id={"item-alias-barcode-"+this.props.index} type="text" className="form-control"  defaultValue={this.props.item_data.alias_barcode} data-target="var-item-alias-barcode"  tabindex={'119'+this.props.index}/>
            </td>
            <td style={{minWidth:'110px'}}>
              <div style={{display:'flex'}}>
                <input id={"item-commission-"+this.props.index} type="text" className="form-control"  defaultValue={this.props.item_data.comission} data-target="var-item-commission" style={{marginRight:'10px'}}  tabindex={'120'+this.props.index}/>
                <div className="form-group round-blk">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name={"var-item-comission-type-"+this.props.index} id={"var-item-commission-rs-"+this.props.index} data-id={1}  data-target="var-item-commission-rs" defaultChecked={(this.props.item_data.comission_type==='rs' || this.props.item_data.comission_type==null || this.props.item_data.comission_type=="")?true:false} value={'rs'}/>
                    <label className="form-check-label rs-sym" htmlFor={"var-item-commission-rs-"+this.props.index}>
                      ₹
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name={"var-item-comission-type-"+this.props.index} id={"var-item-commission-per-"+this.props.index} data-id={1}  data-target="var-item-commission-per" target="var-item-commission-per"  defaultChecked={(this.props.item_data.comission_type==='per')?true:false} value={'per'}/>
                    <label className="form-check-label" htmlFor={"var-item-commission-per-"+this.props.index}>
                      %
                    </label>
                  </div>
                </div>
              </div>
              <input id={"item-custom-fields-json-"+this.props.index} type="hidden" className="form-control"  defaultValue={(this.props.item_data.custom_fileds!==undefined && this.props.item_data.custom_fileds.custom_fields!==undefined && this.props.item_data.custom_fileds.custom_fields.length>0)?JSON.stringify(this.props.item_data.custom_fileds.custom_fields):[]} data-target="item-custom-fields-json"/>
            </td>
            {(this.props.item_data.custom_fileds!==undefined && this.props.item_data.custom_fileds.custom_fields!==undefined && this.props.item_data.custom_fileds.custom_fields.length>0) &&
              <>
                {(this.props.item_data.custom_fileds.custom_fields.map((value,index)=>{
                  return (
                    <td>
                      <input id={"item-custom-fields-"+this.props.index+'-'+value.id} type="text" className="form-control"  defaultValue={value.value} data-target="var-item-custom-fields"  tabindex={'120'+this.props.index}/>
                    </td>
                  )
                }))}
              </>
            }
            
            {(this.props.editItem) &&
              <td><a href={"/variant/edit/"+this.props.item_data.update_id+"/"+this.props.item_data.inventory_id}><FontAwesomeIcon icon={faPencilAlt} id={this.props.index} data-id={this.props.index}/></a></td>
            }
            <td><FontAwesomeIcon icon={faTrash} id={this.props.index} data-id={this.props.index} onClick={this.handleRemoveItem}/></td>
           
            
          </tr>
  }
}
class VariationPreviewList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.handlePreviewEvent = this.handlePreviewEvent.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
  }
  handleChangeItemCommisssionType = (is_checked,value) =>{
    let dt = document.querySelectorAll('[data-target=var-item-commission-'+value+']');
    for(let i=0;i<dt.length;i++){
      dt[i].checked = true;
    }
  }
  handlePreviewEvent(e){
      let tval = e.currentTarget.value;
      let data_target = e.currentTarget.getAttribute('data-target');
      data_target = data_target.replace('prev','var');
      
      let dt = document.querySelectorAll('[data-target='+data_target+']');
      let pp_increment = document.getElementById('prev-item-price-increment').value;
      let sp_increment = document.getElementById('prev-item-sp-increment').value;
      if(pp_increment==='' || isNaN(pp_increment)){
        pp_increment = 0;
      }
      if(sp_increment==='' || isNaN(sp_increment)){
        sp_increment = 0;
      }
      if(data_target==='var-item-price-increment'){
        dt = document.querySelectorAll('[data-target=var-item-price]');
      }else if(data_target==='var-item-sp-increment'){
        dt = document.querySelectorAll('[data-target=var-item-selling-price]');
      }
    
      for(let i=0;i<dt.length;i++){
        if((data_target==='var-item-price' || data_target==='var-item-price-increment') && i===0){
          if(data_target==='var-item-price-increment'){
            tval = document.getElementById("item-price-"+i).value;
          }
          document.getElementById("item-price-"+i).value = tval;
        }else if((data_target==='var-item-price' || data_target==='var-item-price-increment') && i>0){
          tval = document.getElementById("item-price-"+(i-1)).value;
          if(tval!==""){
             document.getElementById("item-price-"+i).value = this.floating_point(parseFloat(tval)+parseFloat(pp_increment));
          }else{
             document.getElementById("item-price-"+i).value = '';
          }
        }else if((data_target==='var-item-selling-price' || data_target==='var-item-sp-increment') && i===0){
          if(data_target==='var-item-sp-increment'){
            tval = document.getElementById("item-selling-price-"+i).value;
          }
          document.getElementById("item-selling-price-"+i).value = tval;

        }else if((data_target==='var-item-selling-price' || data_target==='var-item-sp-increment') && i>0){
          tval = document.getElementById("item-selling-price-"+(i-1)).value;
          if(tval!==""){
             document.getElementById("item-selling-price-"+i).value = this.floating_point(parseFloat(tval)+parseFloat(sp_increment));
          }else{
             document.getElementById("item-selling-price-"+i).value = '';
          }
        }else if(data_target==='var-item-quantity'){
          document.getElementById("item-quantity-"+i).value = tval;
        }else{
          dt[i].value = tval;
        }
      }
  }
  render() {
    return  <tr className="variation-item1">
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-price"} type="text" className="form-control"  onKeyUp={this.handlePreviewEvent} data-id={1} data-target="prev-item-price" target="prev-item-price"  tabindex={'111'}/>
            </td>
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-price-increment"} type="text" className="form-control" data-id={1} data-target="prev-item-price-increment" target="prev-item-price-increment"  tabindex={'112'} onKeyUp={this.handlePreviewEvent}/>
            </td>
        
            <td style={{minWidth:'110px'}}>
             <input id={"prev-item-selling-price"} data-target="prev-item-selling-price" type="text" className="form-control" tabindex='116' onKeyUp={this.handlePreviewEvent}/>
            </td>
             <td style={{minWidth:'110px'}}>
              <input id={"prev-item-sp-increment"} type="text" className="form-control" data-id={1} data-target="prev-item-sp-increment"  tabindex={'117'} onKeyUp={this.handlePreviewEvent}/>
            </td>
        
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-quantity"} type="text" className="form-control" onKeyUp={this.handlePreviewEvent} data-id={1}  data-target="prev-item-quantity" target="prev-item-quantity" tabindex={'121'}/>
            </td>
            <td style={{minWidth:'110px'}}>
              <input id={"prev-item-alias-barcode"} type="text" className="form-control" onKeyUp={this.handlePreviewEvent} data-id={1}  data-target="prev-item-alias-barcode" target="prev-item-alias-barcode" tabindex={'122'}/>
            </td>
            <td style={{minWidth:'110px'}}>
            <div style={{display:'flex'}}>
                <input id={"prev-item-commission"} type="text" className="form-control" onKeyUp={this.handlePreviewEvent} data-id={1}  data-target="prev-item-commission" target="prev-item-commission" tabindex={'123'} style={{marginRight:'10px'}}/>
                <div className="form-group round-blk">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="prev-item-comission-type" id={"prev-item-commission-rs"} onChange={(e)=>this.handleChangeItemCommisssionType(e.target.checked,'rs')} data-id={1}  data-target="prev-item-commission-rs" target="prev-item-commission-rs" defaultChecked={true}/>
                  <label className="form-check-label rs-sym" htmlFor="prev-item-commission-rs">
                    ₹
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="prev-item-comission-type" id={"prev-item-commission-per"}  onChange={(e)=>this.handleChangeItemCommisssionType(e.target.checked,'per')} data-id={1}  data-target="prev-item-commission-per" target="prev-item-commission-per" />
                  <label className="form-check-label" htmlFor="prev-item-commission-per">
                    %
                  </label>
                </div>
              </div>
            </div>
            </td>
          </tr>
  }
}