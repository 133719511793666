import React, {Component} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
let {app_api} = require('../../library.js');
export default class AdminLogin extends Component {

    constructor() {
        super();
        this.state = {
          Username: '',    
          Password: '',
          
          isLoggedIn: false
        }
        
        this.Username = this.Username.bind(this);
        this.Password = this.Password.bind(this);
        
        this.Login = this.Login.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
      
      Username(event) {
        this.setState({ Username: event.target.value })
      }
      Password(event) {
        this.setState({ Password: event.target.value })
      }
      

    Login(event) {
        let thisid = event.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        var body = {
          username: this.state.Username,
          password: this.state.Password,
          
        };

        axios.post(app_api+'/admin/login', body)
          .then(function (response) {
            let success = response.data.success;
            if(success===1){
              localStorage.setItem('HFnmKMOHHO', JSON.stringify(response.data));
              window.location = "/admin-area/stores";
            }else if(success===2){
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
              document.getElementById("password").innerHTML = 'Please enter a valid password';
            }else if(success===3){
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
              document.getElementById("username").innerHTML = 'Please enter a valid username';
            }
        }).catch(function(res){
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            if(res.response){
                
                if(res.response.data){
                    let error  = res.response.data;
                    for(var key in error) {
                        document.getElementById(key).innerHTML = error[key][0];
                    } 
                }
            }
        })
        
    }
    handleKeyDown(e){
      if (e.keyCode === 13) {
        document.getElementById("login").click();
        
      }
    }
    render() {
        return (
            <form>
                <h3>Sign In</h3>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text"  onKeyDown={this.handleKeyDown} onChange={this.Username}  className="form-control" placeholder="Enter Username" autoFocus/>
                    <p className="error" id="username"></p>
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password"  onKeyDown={this.handleKeyDown} onChange={this.Password}  className="form-control" placeholder="Enter Password" />
                    <p className="error" id="password"></p>
                </div>
                <button type="button" id="login-btn-spinner" className="hide btn btn-primary btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                <button type="button" id="login" onClick={this.Login} className="btn btn-primary btn-block">Login</button>
                
            </form>
        );
    }
}