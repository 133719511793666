import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch, faTrash,faCaretRight,faCaretDown, faShoppingCart,faBell  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Pager from "./pager";
import Tooltip from "./tooltip";
import BulkAction from "./bulk-action";
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import utils from '../utils.js';
import DrawerLink from "./open-drawer-link";
import CreateCreditDebitNote from "./create-credit-debit-note";
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class CreditDebitNote extends Component {
    constructor() {
        super();
        this.state = {
          FromDate:  new Date(),
          ToDate: new Date(),
          active_tab:'credit',
          loading_process :'Loading...',
          credit_debit_list : [],
          total_page : '',
          is_fy_closed :false,
          is_open_add_note :false,
          cr_dr_edit_data : [],
        }
        _this = this;
        
        this.is_access = check_access.is_access.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };


  componentDidMount(){
    let curl = window.location.href;
    const cdhome = curl.match(/credit-debit-note$/i);
    const active_tab = curl.match(/credit-debit-note\/([a-z_A-Z]+)/i);
    const page_tab = curl.match(/credit-debit-note\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);
    
    let current_page = 1;
    let current_tab = 'credit';
    if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[3];
      current_tab = page_tab[1];
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(cdhome){
     this.setState({active_tab:'credit'});
    }else{
      this.setState({active_tab:'credit'});
    }
    
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              target : current_tab,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        let api = app_api+'/get_debit_credit_note';
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ credit_debit_list: response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'is_financial_year_closed'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      })
  }
  handleOpenEditModel = (e) =>{
    let credit_debit_list = this.state.credit_debit_list;
    let idx = e.currentTarget.getAttribute('index');
    this.setState({cr_dr_edit_data:credit_debit_list[idx]});
    this.setState({is_open_add_note:true})
  }
    render() {
        let data_value = 'billing';
        if(this.state.top_option==='hold'){
          data_value = 'hold_bill';
        }
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">{this.state.active_tab.replace('_',' ')} Note List</b>
                        </div>
                        
                    </div>
                    
                    <div className="col-md-8">
                    <div className="inv-opt-block bl4">
                        
                        <Notification />
                      
                    </div>
                      <div className="inv-opt-block bl5 inv-bl5">
                      <div className="inven-btn">
                        
                        <button type="button" className="btn btn-success nav-btn" onClick={this.handleAddNote}>Add Note</button>
                      </div>
                    </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">                  
                  <div className="nav-container">
                    <ul className="nav nav-pills">                    
                        <li className={this.state.active_tab==='credit'?'active':''}><a href="/credit-debit-note/credit">Credit Note</a></li>                   
                        <li className={this.state.active_tab==='debit'?'active':''}><a href="/credit-debit-note/debit">Debit Note</a></li>
                    </ul>
                  </div>
                  
                  <div className="content-area">                   
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NOTE #</th>
                            <th scope="col">
                              {(this.state.active_tab==='credit') &&
                                <span>CUSTOMER</span>
                              }
                              {(this.state.active_tab==='debit') &&
                                <span>SUPPLIER</span>
                              }
                            </th>
                            <th scope="col">DATE</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">REDEEMED ON</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                         <tbody>
                           {(this.state.credit_debit_list && this.state.credit_debit_list.length > 0) ?this.state.credit_debit_list.map( (value, index) => {
                           return (
                            <>
                            <tr key={ index } id={"billing-row-"+value.id} className="contact-row">
                              
                              <td>
                              <Link link={"/view-credit-debit/"+this.state.active_tab+"/"+value.id} name={value.sequence_number} />
                              
                              {(value.bill_info.length>0) &&
                                <span style={{fontSize:'12px'}}>
                                  &nbsp;(
                                    {
                                      value.bill_info.map((pmv) => (
                                        <>
                                          &nbsp;
                                          <DrawerLink target="billing" url={"/view-billing/"+pmv.target+"/"+pmv.id} schema={pmv.schema} name={pmv.sequence} />
                                          &nbsp;
                                        </>
                                      ))
                                    }
                                  )
                                </span>
                              }
                              
                              </td>
                              <td>
                              <Link link={"/view-contact/"+value.contact_info.contact_id} name={value.contact_info.name} />
                              </td>
                              <td>{value.created_date}</td>
                              <td>₹{this.currency_format(value.amount)}</td>
                              <td>{value.status}</td>
                              <td>{value.redeem_date}</td>
                              <td>
                                  <Tooltip id={value.id} index={index} handleOpenEditModel={this.handleOpenEditModel} edit={value.id} view={"/view-credit-debit/"+value.id}  delete={{active_tab:'debit_credit_note',id:value.id,data_value:'debit_credit_note'}} target={this.state.active_tab} item_count={_this.state.credit_debit_list.length} is_fy_closed={this.state.is_fy_closed}/>
                              </td>
                            </tr>
                          </>
                          )
                         }) : <tr><td colSpan={this.state.top_option==='trash'?9:8} className="text-center">{_this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {(this.state.credit_debit_list && this.state.credit_debit_list.length > 0) &&
                        <ListCount list={this.state.credit_debit_list}/>
                      }
                      <Pager total_page={this.state.total_page}/>
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.is_open_add_note===true) &&
                <CreateCreditDebitNote handleCloseNoteModal = {this.handleCloseNoteModal.bind(this)} data={this.state.cr_dr_edit_data}/>
              }
            </div>
        );
    }
    handleCloseNoteModal = () =>{
      this.setState({cr_dr_edit_data:[]})
      this.setState({is_open_add_note:false})
    }
    handleAddNote = () =>{
      this.setState({cr_dr_edit_data:[]})
      this.setState({is_open_add_note:true})
    }
}