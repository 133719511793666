import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp, faCaretRight, faPowerOff} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
let {admin_info} = require('../../library.js');
export default class Leftnav extends Component {
  constructor() {
        super();
        this.state = {
          current_route : '',
        }
    }
    componentDidMount(){
      let curl = window.location.href;
      const stores_home = curl.match(/admin-area/i);
      const stores = curl.match(/stores/i);


      const query_builder = curl.match(/query-builder/i);
      const settings = curl.match(/settings/i);
      let active_url = '';
      if(stores || stores_home){
        active_url = 'stores';
      }
      if(query_builder){
        active_url = 'query-builder';
      }
      if(settings){
        active_url = 'settings';
      }
      this.setState({current_route:active_url});
    }
   
    render() {
        let active_url = this.state.current_route;
        return (
            <div className="bg-light border-right" id="sidebar-wrapper">
                <div className="sidebar-heading">
                  <a className="navbar-brand" href="/">
                    <span id="Company_Name">{admin_info.display_name}</span>
                  </a>
                </div>
                <div className="list-group list-group-flush">
                  <a href="/admin-area/stores" className={"list-group-item list-group-item-action "+((active_url==='stores')?'active':'')}>Stores</a>
               
                </div> 
                <div className="list-group list-group-flush">
                  <a href="/admin-area/query-builder" className={"list-group-item list-group-item-action "+((active_url==='query-builder')?'active':'')}>Query Builder</a>
               
                </div>
                <div className="list-group list-group-flush">
                  <a href="/admin-area/settings" className={"list-group-item list-group-item-action "+((active_url==='settings')?'active':'')}>Settings</a>
               
                </div>
                <div className="list-group list-group-flush menu-logout">
                    <a href={"/admin-area/login"} className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faPowerOff} /> Logout</a>
                </div>
            </div>

        );
    }
}