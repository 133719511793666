import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import Tooltip from "./tooltip";
import Link from './link.js';
import check_access from './access-level.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
export default class VariationsList extends Component {
    constructor() {
      super();
      this.state = {
       
      };
      this.is_access = check_access.is_access.bind(this);
      this.handlePreviewImage = this.handlePreviewImage.bind(this);
    }
    handlePreviewImage(e){
      let this_img = e.currentTarget.getAttribute('data-src');
      var element = document.getElementById('preview-image');
        element.classList.remove("fade");
        element.style.display="block";
      document.getElementById('preview-img-src').setAttribute('src',this_img);

    }
    componentDidMount(){

    }
    render() {
        let items = this.props.var_items;
        let variations = [];
        let vars = [];
        let info = [];
        let product_sku = this.props.inventory_item.sku;
        let product_hsn = this.props.inventory_item.hsn;
        let product_name = this.props.inventory_item.item_name;
        let purchase_price = this.props.inventory_item.purchase_price;
        let selling_price = this.props.inventory_item.selling_price;
        let lowest_selling_price = this.props.inventory_item.lowest_selling_price;
        let quantity = this.props.inventory_item.quantity;
        let item_desc = this.props.inventory_item.item_desc;
       
        info = this.props.variation_item;
        

        items.map((value, index) => {
            let val_arr = value.value.split(",");
            if(index===0){
              for(let i=0;i<val_arr.length;i++){
                  vars.push(val_arr[i]);
              }
            }
            
            for(let i=0;i<val_arr.length;i++){
                let item_name = product_name+" "+val_arr[i];
                let iname = val_arr[i].replace(" ",'');
                let n = iname.substring(0,2).toUpperCase();
                let item_sku = product_sku+"-"+i+""+n;
                let old_item = (this.props.variation_item[i]?this.props.variation_item[i]:'');
                if(old_item.length===0){
                info.push({insert_id:0,item_name:item_name,item_desc:item_desc,sku:item_sku,hsn:product_hsn,purchase_price:purchase_price,selling_price:selling_price,lowest_selling_price:lowest_selling_price,quantity:quantity});
                }
               
            }
            variations.push(val_arr);
        });
        
        let var_len = 0;
        if(variations[0]){
          var_len = variations[0].length;
        }
     
        
        return (
            <div className="mrtop-15">
              {this.props.target==='edit' &&
                <table className="table">
                  <thead  className="thead-light">
                    <tr>
                      <th></th>
                      <th>NAME</th>
                      <th>SKU</th>
                      <th>DESC</th>
                      <th>HSN</th>
                      <th>QUANTITY</th>
                      <th className={this.is_access('inventory-show-purchase-price')?'':'hide'} >PURCHASE PRICE</th>
                      <th>SELLING PRICE</th>
                      <th>LOWEST SELLING PRICE</th>
                        {(this.props.var_items.map( (value, index) => {
                         return (
                            <th key={index}>
                              {value.key.toUpperCase()}
                             </th>
                          )
                        }))}
                     
                    </tr>
                  </thead>
                  <tbody>
                   {(vars.length > 0) ?vars.map( (value, index) => {
                    let var_id = parseInt(info[index].insert_id);

                   return (
                    <tr key={ index } className="var-list" id={index} data-id={"var-list-"+index}>
                      
                      <td width="100">
                          <div className="row">
                            {(this.props.inventory_images && this.props.inventory_images[var_id] && this.props.inventory_images[var_id].length>0) &&
                              <>
                               {this.props.inventory_images[var_id].map( (value, index) => {
                                return (
                                <div className="col-md-4 var-img-blk"  onClick={this.handlePreviewImage} data-src={value.image}>
                                  <img className="var-img" src={value.image} height="25" width="25"/>
                                </div>
                                )})}
                              </>
                            }
                          </div>
                      </td>
                  
                      <td><input type="text"  id={"var-name-"+index} className="form-control" defaultValue={info[index].item_name} />
                        <input type="hidden"  id={"var-item-id-"+index} className="form-control" defaultValue={info[index].insert_id} /></td>
                      <td><input type="text"  id={"var-sku-"+index} className="form-control" defaultValue={info[index].sku} /></td>
                      <td><input type="text"  id={"var-desc-"+index} className="form-control" defaultValue={info[index].item_desc} /></td>
                      <td><input type="text"  id={"var-hsn-"+index} className="form-control" defaultValue={info[index].hsn} /></td>
                      <td><input type="text"  id={"var-quantity-"+index} className="form-control" defaultValue={info[index].quantity} /></td>
                      <td  className={this.is_access('inventory-show-purchase-price')?'':'hide'}><input type="text"  id={"var-purchase-price-"+index} className="form-control" defaultValue={info[index].purchase_price} /></td>
                      <td><input type="text"  id={"var-selling-price-"+index} className="form-control" defaultValue={info[index].selling_price} /></td>
                      <td><input type="text"  id={"var-lowest-selling-price-"+index} className="form-control" defaultValue={info[index].lowest_selling_price} /></td>
                      {(this.props.var_items.map( (v, i) => {
                         return (
                            <td key={index+''+i} id={"var-opt-td-"+index+i} className="var-opt-td" data-id={index+''+i}>
                              {variations[i][index]}
                              <input type="hidden" id={"var-opt-name-"+index+i} defaultValue={v.key} />
                              <input type="hidden" id={"var-opt-value-"+index+i} defaultValue={variations[i][index]} />
                             </td>
                          )
                        }))}
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
                  </tbody>
                </table>
              }
              {this.props.target==='view' &&
              <table className="table">
                  <thead  className="thead-light">
                    <tr>
                      <th></th>
                      <th>NAME</th>
                      <th>SKU</th>
                     
                      <th>HSN</th>
                      <th>QUANTITY</th>
                      {this.is_access('inventory-show-purchase-price') &&
                        <th>PURCHASE PRICE</th>
                      }
                      <th>SELLING PRICE</th>
                      <th>LOWEST SELLING PRICE</th>
                        {(this.props.var_items.map( (value, index) => {
                         return (
                            <th key={index}>
                              {value.key.toUpperCase()}
                             </th>
                          )
                        }))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                   {(vars.length > 0) ?vars.map( (value, index) => {
                    let var_id = parseInt(info[index].insert_id);
                   return (
                    <tr key={ index } className="contact-row var-list" id={"contact-row-"+info[index].insert_id} data-id={"var-list-"+index}>
                      <td width="100">
                          <div className="row">
                            {(this.props.inventory_images && this.props.inventory_images[var_id] && this.props.inventory_images[var_id].length>0) &&
                              <>
                               {this.props.inventory_images[var_id].map( (value, index) => {
                                return (
                                <div className="col-md-4 var-img-blk"  onClick={this.handlePreviewImage} data-src={value.image}>
                                  <img className="var-img" src={value.image} height="25" width="25"/>
                                </div>
                                )})}
                              </>
                            }
                          </div>
                      </td>
                      <td>
                        <Link link={'/view-inventory/'+info[index].insert_id} name={info[index].item_name} />
                        
                        <p className="i-desc">{info[index].item_desc}</p>
                      </td>
                      <td>{info[index].sku}</td>
                      <td>{info[index].hsn} </td>
                      <td>{info[index].quantity}</td>
                      {this.is_access('inventory-show-purchase-price') &&
                      <td>{info[index].purchase_price}</td>
                      }
                      <td>{info[index].selling_price}</td>
                      <td>{info[index].lowest_selling_price}</td>
                      {(this.props.var_items.map( (v, i) => {
                         return (
                            <td key={index+''+i} id={"var-opt-td-"+index+i} className="var-opt-td" data-id={index+''+i}>
                              {variations[i][index]}
                             </td>
                          )
                        }))}
                      <td>
                          <Tooltip item_count={vars.length} opt="regular" id={info[index].insert_id} index={index} view={"/view-inventory/"+info[index].insert_id+"/var"} edit={"/edit-inventory/"+info[index].insert_id+"/var"} delete={{active_tab:'inventory',id:info[index].insert_id,data_value:'variation'}}/>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
                  </tbody>
                </table>
              }
            </div>
           
        );
    }
}