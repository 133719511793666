import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import check_access from './access-level.js';
import ServiceLoader from "./service-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
export default class WayBill extends Component {
    constructor(props) {
        super();
        this.state = {
            is_way_bill : (props.is_way_bill!==undefined) ? props.is_way_bill : false,
            invoices : (props.way_bill_data && props.way_bill_data.data && props.way_bill_data.data.number)?[{invoice:props.way_bill_data.data.number,id:props.way_bill_data.data.id,amount:props.way_bill_data.data.total}]:[],
            way_bill_data : (props.way_bill_data && props.way_bill_data.data)?props.way_bill_data.data:false,
            dispatched_info : (props.way_bill_data && props.way_bill_data.dispatched_info)?props.way_bill_dispatched_info:[],
            todayDate : new Date().toLocaleDateString(),
            Way_bills_list : [],
            temp_Way_bills_list : [],
            active_Way :0,
            show_loader : true,
            search_text : '',
            total_amount : (props.way_bill_data && props.way_bill_data.data &&  props.way_bill_data.data.total)?props.way_bill_data.data.total:0,
            inv_id : (props.way_bill_data && props.way_bill_data.data && props.way_bill_data.data.id)?props.way_bill_data.data.id:0,
            item_code : '',
            item_commodity : '',
            transport_address : '',
            transport_name : (props.way_bill_data && props.way_bill_data.dispatched_info)?props.way_bill_data.dispatched_info.dispatched_through:'',
        };
        this.isMobile = utils.isMobile.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        
    }
   


    handleWayBillShortcut = (e)=>{
        let key_code = e.which;
        if(key_code===27){
          var element = document.getElementById('Way-bill-list');
          if(element.style.display==="block"){
            this.handleCloseWayBillsPopup();
          }
        }
        
        if(key_code===40){
          var element = document.getElementById('Way-bill-list');
          var Way_list = document.querySelectorAll(".Way-list");
          if(element.style.display==="block"){
            let active_Way = this.state.active_Way;
            active_Way++;
            if(Way_list.length>=(active_Way+1)){
              this.setState({active_Way:active_Way});
            }
          }
        }
        
        if(key_code===38){
          var element = document.getElementById('Way-bill-list');
          var Way_list = document.querySelectorAll(".Way-list");
          if(element.style.display==="block"){
            let active_Way = this.state.active_Way;
            active_Way--;
            if(active_Way>=0){
              this.setState({active_Way:active_Way});
            }
          }
        }
        
        if(key_code===13){
          var element = document.getElementById('Way-bill-list');
          var Way_list = document.querySelectorAll(".Way-list");
          if(element.style.display==="block"){
            let active_Way = this.state.active_Way;
            Way_list[active_Way].click();
          }
        }
    }
        handleSelectWayBillInvoice = (e) => {
            
            let wbcb = document.querySelectorAll('.waybill-inv-cb');
            
            let invoices = (this.props.way_bill_data && this.props.way_bill_data.data && this.props.way_bill_data.data.number)?[{invoice:this.props.way_bill_data.data.number,id:this.props.way_bill_data.data.id,amount:this.props.way_bill_data.data.total}]:[];

            let total_amount = (this.props.way_bill_data && this.props.way_bill_data.data &&  this.props.way_bill_data.data.total)?parseFloat(this.props.way_bill_data.data.total):0;
            for (let index = 0; index < wbcb.length; index++) {
                const element = wbcb[index];
                if(element.checked===false){
                    continue;
                }

                
                let data_id = element.getAttribute('data-id');
                
                let invoice = this.state.Way_bills_list[data_id];
                let id = invoice.id;
                if(id===this.state.inv_id){
                    continue;
                }
                let name = invoice.invoice_number;
                let amount = invoice.grand_total;
                total_amount = total_amount+parseFloat(amount);

                let inv_info = {invoice:name,id:id,amount:amount}

                invoices.push(inv_info);
            }
            this.setState({total_amount:total_amount,invoices:invoices});
            this.handleCloseWayBillsPopup();
        }

      handleCloseWayBillsPopup = ()  =>{
        var element = document.getElementById('Way-bill-list');
        element.classList.add("fade");
        element.style.display="none"; 
      }
      handleGetWayBills = () =>{
        var element = document.getElementById('Way-bill-list');
        element.classList.remove("fade");
        element.style.display="block"; 
        if(this.state.Way_bills_list.length===0){
            this.hanldeLoadList();
        }
      }

      hanldeLoadList = (search_text='') =>{
        if(search_text===''){
            this.setState({show_loader:true});
        }
        let body = {};
        body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                contact_id : this.state.way_bill_data.contact_id,
                search_text :search_text
            }
        let api = app_api+'/get_contact_specific_bills';
        axios.post(api, body)
        .then(function (response) {
           
            this.setState({ Way_bills_list: response.data.invoice_list });
            this.setState({ temp_Way_bills_list: response.data.invoice_list });
            this.setState({show_loader:false});
            document.getElementById("Way-bill-search").focus();
        }.bind(this)).catch(function(res){
            if(res.response){
            
            }
        })
      }

      handleSearch = (e) =>{
        let userInput = e.currentTarget.value;
        if(userInput!==""){
            this.hanldeLoadList(userInput);
        }else{
            this.hanldeLoadList();
        }
      }
      handleShowWayBill = () =>{
        this.handleGetWayBills();
      }


    handleNewInvoice = (e) =>{
        let thisvalue = e.currentTarget.value;
        if(e.which===13){
            console.log(thisvalue);
        }
    }

    componentDidMount(){
        let way_bill_data =  this.state.way_bill_data;
        let no_of_parcels = way_bill_data.no_of_parcels;
        if(no_of_parcels!=="0" && no_of_parcels!=="" && no_of_parcels!==null){
            this.setState({item_code:way_bill_data.no_of_parcels});
            let msg = this.convert_number_to_words(no_of_parcels)+'Fashion Dress';
            this.setState({item_commodity : msg})
        }

        let transport_address = '';

        if(user_info.city!==""){
            transport_address = user_info.city;
        }

        
        if(user_info.city!=="" && this.props.way_bill_data.data.contact_info!==undefined && this.props.way_bill_data.data.contact_info[0]!==undefined && this.props.way_bill_data.data.contact_info[0].city!==""){
            transport_address += ' To '+this.props.way_bill_data.data.contact_info[0].city;
        }
        this.setState({transport_address:transport_address})
       
    }

    handlePrint = (e) =>{
        var isMobile = this.isMobile();
        if(document.getElementById("waybill-main-container")){
            var divContents = document.getElementById("waybill-main-container").innerHTML; 

            let page_size = 'A4';

            try{
                var a = window.open('', '', 'height=1000,location=no,left=250px'); 
                a.document.open(); 
                a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
                a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

                a.document.write('<style type="text/css">@page{size:'+page_size+'}.table{font-size:12px;width:100%;margin-bottom:1rem;color:#212529}.div-display,.no-print-block{display:none}.table td,.table th{padding:5px;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px}.col-md-12{flex:0 0 100%;max-width:100%}.powered-by{position:fixed;bottom:20}.page-break{page-break-after:always}.border-none{border:none}.ad-margin{margin-top:-9px}.signature{font-family:"Dancing Script", cursive;text-transform:capitalize;font-weight:bold;font-size:15px} #transport-name,#transport-address{font-size: 16px;margin-top: -5px;}</style>'); 
                a.document.write('</head>'); 
                
                a.document.write('<body onload="window.print();">'); 
                 
                a.document.write(divContents); 
                a.document.write('</body></html>');
                a.document.body.style.fontFamily="Calibri, sans-serif";  
                a.document.close();

            }catch{}
      }
    } 

    handleChangeCode = (e) =>{
        let thisvalue = e.currentTarget.value;
        if(thisvalue!==""){
            thisvalue = parseInt(thisvalue);
            let msg = this.convert_number_to_words(thisvalue)+'Fashion Dress';
            this.setState({item_commodity : msg})
        }else{
            this.setState({item_commodity : ''})
        }
        this.setState({item_code:thisvalue});
    }
    handleChangeCommodity = (e) =>{
        let thisvalue = e.currentTarget.value;
        this.setState({item_commodity : thisvalue})
    }
    handleChangeTrName = (e) =>{
        let thisvalue = e.currentTarget.value;
        this.setState({transport_name : thisvalue})
    }
    handleChangeTrAddress = (e) =>{
        let thisvalue = e.currentTarget.value;
        this.setState({transport_address : thisvalue})
    }
    handleRemoveInvoice =(e)=>{
        let _this = this;
        let data_id = e.currentTarget.getAttribute('data-id');
        let invoices = this.state.invoices;
        invoices.splice(data_id, 1);
        this.setState({invoices:invoices});

        let total_amount = 0;

    
        setTimeout(() => {
            for (let index = 0; index < this.state.invoices.length; index++) {
                let amount = _this.state.invoices[index].amount;
                total_amount = total_amount+parseFloat(amount);
            }
            _this.setState({total_amount:total_amount});
        }, 100);
     
    }

    handleChangeAmount = (e) =>{
        let thisvalue = e.currentTarget.value;
        if(thisvalue!==""){
            thisvalue = parseFloat(thisvalue);
            this.setState({total_amount:thisvalue});
        }else{
            this.setState({total_amount:0});
        }
    }

    render() {
        return (
                <>
                    {(this.state.is_way_bill===true) &&
                        <div className="view-on-fly" id="page-content-wrapper">
                            <nav className="navbar border-bottom">
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn btn-success mob-btn" onClick={this.handlePrint}>Print</button>
                                    </div>
                                </div>
                            </nav>
                            <div id="waybill-main-container">
                                <div className="container-fluid page-break">
                                    <div className="content-area">
                                        <div className="content-head border-bottom">
                                            <div className="row mrbtm-12">
                                                <div className="col-md-12">
                                                    <table className="table" style={{tableLayout:'fixed',fontSize:'12px'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:'70%',fontSize:'18px'}}><b>FORM X-FORM FOR WAYBILL</b></td>
                                                                <td>
                                                                    <div style={{display:'inline-flex'}}>
                                                                        <label style={{marginTop:'10px'}}>S.NO. </label> 
                                                                        <input type="text" className="form-control" style={{marginLeft:'5px'}}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><p style={{margin:'0px'}}>1. OFFICE OF ISSUE</p></td>
                                                                <td><p style={{margin:'0px',whiteSpace: 'break-spaces'}}>{(this.state.way_bill_data && this.state.way_bill_data.company_info[0].address)}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p style={{margin:'0px'}}>2. DATE OF ISSUE OF WAY BILL CONSIGNOR</p></td>
                                                                <td>
                                                                    <p style={{margin:'0px'}}>{this.state.todayDate}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px',whiteSpace: 'break-spaces'}}>3. NAME AND ADDRESS OF THE DEALERS/PERSON CONSIGNING OF THE</p></td>
                                                            
                                                            </tr>
                                                            <tr>
                                                            <td style={{borderTop:'none',fontWeight:'bold',paddingLeft:'30px'}}>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'14px', fontStyle:'bold',textTransform:'uppercase'}}>
                                                                        {user_info.company_name}
                                                                    </p>
                                                                    {(user_info.address!=="") &&
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{user_info.address}</p>
                                                                    }
                                                                    {(user_info.city!=="") &&
                                                                        <b  style={{fontWeight:'bold', fontSize:'12px'}}>{user_info.city}</b>
                                                                    }
                                                                    {(user_info.pin_code!=="") &&
                                                                        <b  style={{fontWeight:'bold', fontSize:'12px'}}>{-user_info.pin_code}</b>
                                                                    }
                                                                    {(user_info.state!=="") &&
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{user_info.state}</p>
                                                                    }
                                                                  
                                                                    {(user_info.phone!=="") &&
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{user_info.phone}</p>
                                                                    }
                                                                  
                                                                    {(user_info.gst_number!=="" && user_info.gst_number!==null) &&
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>GSTIN : {user_info.gst_number}</p>
                                                                    }
                                                                </td>
                                                               
                                                                <td style={{borderTop:'none'}}>
                                                                    <table className="table" style={{marginBottom:'0px',borderLeft:'1px solid #dee2e6',borderRight:'1px solid #dee2e6',borderBottom:'1px solid #dee2e6'}}>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td>APGST</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>C.S.T</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>STATE</td>
                                                                            <td>
                                                                                {(user_info  && user_info.state!==""  && user_info.state!==null) && 
                                                                                    <span>{user_info.state.replace(/[-0-9]/g, '')}</span>
                                                                                }
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>4. FULL ADDRESS OF PLACE</p></td>
                                                            
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingLeft:'30px'}}>
                                                                    <p style={{margin:'0px'}}>a. FROM WHICH CONSIGNED</p>
                                                                    {(user_info.city!=="") &&
                                                                        <span  style={{fontWeight:'bold'}}>{user_info.city}</span>
                                                                    }
                                                                    
                                                                </td>
                                                                <td style={{borderTop:'none'}}>
                                                                    <p style={{margin:'0px'}}>b. TO WHICH CONSIGNED</p>
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].city) && 
                                                                        <span style={{fontWeight:'bold'}}>{this.state.way_bill_data.contact_info[0].city}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>5. IF THE CONSIGNOR IS TRANSPORTING THE</p></td>
                                                            
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none' ,paddingLeft:'30px'}}>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(A). In persuance of a sale for purpose of delivery to the buyer, or</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(B). After purchasing them, or</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(C). From one of the shops or godown to an agent for sale</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(D). From shop or godown to another shop or godown for the purpose of storage or sales, or</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(E). To his principle, having purchased them on his behalf, or</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(F). To his agent for sale on consignment basis</p>
                                                                </td>
                                                                <td style={{borderTop:'none'}}>
                                                                    <div>
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>(Whichever is applicable and write the corresponding aplphabet in the box)</p>
                                                                        <textarea className="form-control"></textarea>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} style={{borderTop:'none',paddingLeft:'30px'}}><p style={{margin:'0px',whiteSpace: 'break-spaces'}}>THE NAME AND THE ADDRESS OF THE DEALER/PERSON TO WHOM THE GOODS ARE CONSIGNED OR FROM WHOME GOODS WERE PURCHASED (BUYER OR SELF OR AGENT OF PRINCIPAL)</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',fontWeight:'bold',paddingLeft:'30px'}}>
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'14px', fontStyle:'bold',textTransform:'uppercase'}}>
                                                                            {this.state.way_bill_data.contact_info[0].name}
                                                                        </p>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].address_1) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].address_1}</p>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].address_2) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].address_2}</p>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].city) && 
                                                                        <b style={{fontWeight:'bold', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].city}</b>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].pin) && 
                                                                        <b style={{fontWeight:'bold', fontSize:'12px'}}>-{this.state.way_bill_data.contact_info[0].pin}</b>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].display_state) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].display_state}</p>
                                                                    }
                                                                   
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].phone_1) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].phone_1}</p>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].phone_2) && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces', fontSize:'12px'}}>{this.state.way_bill_data.contact_info[0].phone_2}</p>
                                                                    }
                                                                    {(this.state.way_bill_data && this.state.way_bill_data.contact_info[0].gst_number && this.state.way_bill_data.contact_info[0].gst_number!==null && this.state.way_bill_data.contact_info[0].gst_number!=="") && 
                                                                        <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>GSTIN : {this.state.way_bill_data.contact_info[0].gst_number}</p>
                                                                    }
                                                                </td>
                                                                <td style={{borderTop:'none'}}>
                                                                    <span>REGISTRATION NUMBERS</span>
                                                                    <table className="table" style={{marginBottom:'0px',borderLeft:'1px solid #dee2e6',borderRight:'1px solid #dee2e6',borderBottom:'1px solid #dee2e6'}}>
                                                                        <tbody>
                                                                      
                                                                        <tr>
                                                                            <td>TIN</td>
                                                                            <td>
                                                                            {(this.state.way_bill_data  && this.state.way_bill_data.contact_info && this.state.way_bill_data.contact_info[0].gst_number!=="" && this.state.way_bill_data.contact_info[0].gst_number!==null && user_info.gst_number!=="" && user_info.gst_number!==null) && 
                                                                                <b>B2B</b>
                                                                            }
                                                                            {(this.state.way_bill_data  && this.state.way_bill_data.contact_info && (this.state.way_bill_data.contact_info[0].gst_number==="" || this.state.way_bill_data.contact_info[0].gst_number===null)) && 
                                                                                <b>B2C</b>
                                                                            }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>C.S.T</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>STATE</td>
                                                                            <td>
                                                                                {(this.state.way_bill_data  && this.state.way_bill_data.contact_info && this.state.way_bill_data.contact_info[0].display_state!=="") && 
                                                                                    <span>{this.state.way_bill_data.contact_info[0].display_state}</span>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px',whiteSpace: 'break-spaces'}}>6. DESCRIPTION, QUANTITY AND VALUE OF GOODS</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2} style={{borderTop:'none',paddingBottom:'0px'}}>
                                                                    <table className="table" style={{marginBottom:'0px'}}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>S.NO.</th>
                                                                                <th>CODE</th>
                                                                                <th>COMMODITY</th>
                                                                                <th>INV. NO.</th>
                                                                                <th>INV. DATE</th>
                                                                                <th>VALUE OF GOODS</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>1</td>
                                                                                <td>
                                                                                    <input type="number" className="form-control border-none ad-margin" style={{width:'100px'}} onChange={this.handleChangeCode} id="item-code" value={this.state.item_code}/>
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className="form-control border-none ad-margin" id="item-commodity" value={this.state.item_commodity} onChange={this.handleChangeCommodity}/>
                                                                                </td>
                                                                                <td>
                                                                                    {this.state.invoices.map((item,i) => 
                                                                                        <>
                                                                                            <span>
                                                                                                {item.invoice}
                                                                                                {(this.state.inv_id!==item.id) &&
                                                                                                    <span className="no-print-block" style={{marginLeft:'5px',cursor:'pointer'}} onClick={this.handleRemoveInvoice} data-id={i}>
                                                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                                                    </span>
                                                                                                }
                                                                                                {(this.state.invoices.length > (i+1))&&
                                                                                                    <> / </>
                                                                                                }
                                                                                            </span>
                                                                                           
                                                                                        </>
                                                                                    )}
                                                                                    <p className="no-print-block" style={{margin: '0px',color: '#007bff',cursor: 'pointer'}} onClick={this.handleGetWayBills}>Add more</p>
                                                                                </td>
                                                                                <td>{(this.state.way_bill_data && this.state.way_bill_data.bill_date)}</td>
                                                                                <td>
                                                                                    <input type="text" value={this.state.total_amount} onChange={this.handleChangeAmount} className="form-control border-none ad-margin"/>
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            {(this.state.total_amount>0) &&
                                                                <tr>
                                                                    <td colSpan={2} style={{borderTop:'none',paddingTop:'0px'}}><p style={{margin:'0px',whiteSpace: 'break-spaces',fontSize:'16px',fontWeight:'bold',textTransform:'uppercase'}}>
                                                                        Rupee {this.convert_number_to_words(this.state.total_amount)}Only
                                                                    </p></td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px',whiteSpace: 'break-spaces'}}>7. NAME AND ADDRESS OF THE GOODS VEHICLE OR BOAT BY WHICH THE GOODS ARE CONSIGNED </p></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingLeft:'30px'}}>
                                                                    <div style={{display:'flex'}}>
                                                                        <label style={{marginTop:'6px'}}>NAME  </label>
                                                                        <input type="text" className="form-control border-none" style={{marginLeft:'10px'}} id="transport-name" value={this.state.transport_name}  onChange={this.handleChangeTrName}/>
                                                                    </div>
                                                                    <div style={{display:'flex',marginTop:'5px'}}>
                                                                        <label style={{marginTop:'6px'}}>ADDRESS  </label>
                                                                        <input type="text" className="form-control border-none" style={{marginLeft:'10px'}} id="transport-address"  value={this.state.transport_address} onChange={this.handleChangeTrAddress}/>
                                                                    </div>
                                                                </td>
                                                                <td style={{borderTop:'none',textAlign:'center'}}>
                                                                    <p className="signature" style={{border: '1px solid #d7d7d7',padding: '15px',fontSize: '20px'}}>
                                                                        {user_info.name}
                                                                    </p>
                                                                    <b>Authorized Signature</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>DECLARATION</p>
                                                                    <p style={{margin:'0px',whiteSpace: 'break-spaces'}}>I/We certify that the best of my knowledge the purticulars furnished here</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="Way-bill-list" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title hbtitle">Invoice List</h5>
                                    </div>
                                    <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                                        <div className="row">
                                        <div className="col-md-12 text-center">
                                        {(this.state.show_loader==true) &&
                                            <ServiceLoader show={this.state.show_loader}/>
                                        } 
                                        {(this.state.show_loader===false) &&
                                            <>
                                            <input type="text" className="form-control" placeholder="Type to search" id="Way-bill-search" onChange={this.handleSearch}/>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th className="text-left">Invoice #</th>
                                                    <th className="text-left">Date</th>
                                                    <th className="text-right">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(this.state.Way_bills_list.length > 0) ?this.state.Way_bills_list.map( (value, index) => {
                                                return (
                                                    <>
                                                    {(this.state.inv_id!==value.id) &&
                                                    <tr key={index} className={(this.state.active_Way===index)?' Way-list':'Way-list'} target={value.target} id={value.id}>
                                                        <td className="text-left">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input waybill-inv-cb" type="checkbox" id={"inv-"+value.id} data-id={index} value={value.invoice_number} />
                                                            <label className="form-check-label" for={"inv-"+value.id}>{value.invoice_number}</label>
                                                        </div>
                                                        </td>
                                                        <td className="text-left">{value.date}</td>
                                                        <td className="text-right">₹{value.grand_total}</td>
                                                    </tr>
                                                      }
                                                      </>
                                                    )
                                                }) :  <tr><td colSpan={4} style={{textTransform:'capitalize'}}>No Invoice(s) found.</td></tr>
                                                }
                                                </tbody>
                                            </table>
                                            </>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseWayBillsPopup}>Close</button>
                                        <button type="button" className="btn btn-success pull-right" onClick={this.handleSelectWayBillInvoice}>Done</button>
                                    </div>
                                    </div>
                                </div>
                            </div> 
                        </div>     
                    }
                </>
        );
    }
}