import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faEnvelope,faPrint,faEye,faWrench,faTrash  } from '@fortawesome/free-solid-svg-icons';
import ModalBox from "./modal-box";
import ProductUnits from "./product-units";
import DrawerLink from './open-drawer-link.js';
import ServiceLoader from './service-loader.js';
import utils from '../utils.js';
import check_access from './access-level.js';
import PaymentPrint from "./payment-print";
import ChequePrint from "./cheque-print";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}

export default class ViewPayment extends Component {
    constructor() {
        super();
        this.state = {
            is_print : false,
            is_cheque_print : false,
            is_cheque_payment : false,
            is_order_pay: false,
            show_loader:true,
            currrent_sequence:'',
            cheque_data : [],
            payment_info : [],
            note_info : [],
            contact_info : [],
            payment_date : '',
            issued_by:'',
            update_id:'',
            view_branch_id:'',
            total_balance_amt :'',
            total_grand :'',
            on_load : true,
            round_off_opt : true,
            round_off_opt2 : false,
            round_off : '',
            round_off_sym :'',
            paid_amount :'',
            payment_type:'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            //payment_data:[]
        };
     
        
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.delteBilling = this.delteBilling.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    handleCloseChequePrint = () =>{
      this.setState({is_cheque_print:false});
  }
    handlePrint = () =>{
      this.setState({is_print:true});
    }
    handleChequePrint = () =>{
      this.setState({is_cheque_print:true});
    }
    handleClosePrint = () =>{
      this.setState({is_print:false});
    }
    delteBilling(event){
      let del_option = (this.state.payment_data && this.state.payment_data.is_current===true)?'Delete':'Cancel';
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      var element = document.getElementById('delete_confirm');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("deleteValue").value='payment-view';
      document.getElementById("deleteIndex").value=id;
      document.getElementById("deleteTarget").value=data_id;
      let deleteScreen = 'billing';
      if(this.state.is_order_pay===true){
         deleteScreen = 'order';
      }
      document.getElementById("deleteScreen").value=deleteScreen;
      del_option = del_option.toLowerCase();
      document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';
    }
    componentDidMount(){
     
      let curl = window.location.href;
      let schema = user_info._schema;
      if(this.props.url){
        curl =this.props.url;
        if(this.props.schema){
          schema = this.props.schema;
        }
      }
      const is_order_pay = curl.match(/order\/view-payment\/(\d+$)/i);
      let pay_screen = 'billing';
      if(is_order_pay){
        pay_screen = 'order';
        this.setState({is_order_pay:true});
      }
      const view_payment = curl.match(/view-payment\/(\d+$)/i);
      if(view_payment){ // EDIT MODE
          const id = view_payment[1];
          this.setState({update_id:id});
           let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, 
                _schema : schema,
                id : parseInt(id),
                screen : pay_screen
             }
          let api = app_api+'/get_payment_on_id';
          axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
                let data = resp.data[0];
                
                if(data===undefined){
                  window.location.href = '/404';
                  
                }
                if(resp.data.cheque_info && resp.data.cheque_info[0]){
                  this.setState({cheque_data:resp.data.cheque_info})
                }
                if(resp.data.dr_cr_info_arr && resp.data.dr_cr_info_arr[0]){
                  this.setState({note_info:resp.data.dr_cr_info_arr})
                  data.dr_cr_info_arr = resp.data.dr_cr_info_arr;
                }
                if(data.user_info && data.user_info[0]){
                  this.setState({issued_by:data.user_info[0].username});
                }

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
              
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
             if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.payable_amount.replace(/,/g , ""));
                let disc = 0;
                if(gdisc!==undefined){
                  if(isNaN(gdisc)){
                    disc =parseFloat(gdisc.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(gdisc);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = sub_total*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(data.discount_amount)});
              }
              
              
              let paid_amount = data.paid_amount.replace(/,/g , "");
              
              this.setState({paid_amount:this.currency_format(paid_amount)});
              let pending_amount = data.pending_amount.replace(/,/g , "");
              let total_amount = parseFloat(paid_amount)+parseFloat(pending_amount);
              
              this.setState({total_balance_amt:this.currency_format(pending_amount)});
              this.setState({total_grand:this.currency_format(total_amount)});

                let payment_type = data.payment_type;
                let payment_meta = data.payment_meta;
                for (let index = 0; index < payment_meta.length; index++) {
                  const element = payment_meta[index];
                  if(element.cheque_no!==""){
                      this.setState({is_cheque_payment:true});
                  }
                  
              }
                this.setState({payment_data:data,payment_type:payment_type});
                this.setState({view_branch_id:data.branch_id});
                let payment_date = data.payment_date;
                
                this.setState({payment_date:payment_date});
                this.setState({show_loader:false});
                

                let contact_info = data.contact_info;
                let credit_info = data.credit_data;
                let contact_name = '';
                let contact_id = '';
                let address_1 =  '';
                let address_2 = '';
                let city = '';
                let credit_limit = '';
                let previous_balance = '';
                let email = '';
                let phone_1 = '';
                let phone_2 = '';
                let state = '';
                if(Object.keys(contact_info).length > 0) {
                   contact_name = contact_info[0].name;
                   contact_id = contact_info[0].contact_id;
                   address_1 = contact_info[0].address_1;
                   address_2 = contact_info[0].address_2;
                   city = contact_info[0].city;
                   /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                   address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                   city = this.decrypt_sanitize(contact_info[0].city);*/
                   credit_limit = contact_info[0].credit_limit;
                   email = contact_info[0].email;
                   phone_1 = contact_info[0].phone_1;
                   phone_2 = contact_info[0].phone_2;
                   state = contact_info[0].state;
                   /*email = this.decrypt_sanitize(contact_info[0].email);
                   phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                   phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                   state = this.decrypt_sanitize(contact_info[0].state);*/
                }
                let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
              this.setState({contact_info:c_info});
                let sequence = data.payment_number;
                this.setState({currrent_sequence:sequence});
                let list_items = data.payment_meta;
                if(pay_screen==='order'){
                  if(document.getElementById("orders-sale-menu")){
                    document.getElementById("orders-sale-menu").classList.remove('current');
                    if(payment_type==='IN'){
                      document.querySelector('[href="/order/payments/in"]').classList.add('current');
                    }else if(payment_type==='OUT'){
                      document.querySelector('a[href="/order/payments/out"]').classList.add('current');
                    }
                  }
                }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
      }
    }

   
   
    render() {
      let total_return_amount = 0;
      let note_amount = 0;
        let view = true;
        let edit = true;
        let del = true;
        let is_payment = false;
        if(this.is_access('payments-out-create')){
          is_payment = true;
        }
        if(this.is_access('payments-in-create')){
          is_payment = true;
        }
        let round_off_sym = '-';
        if(this.state.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.state.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let total_bill_amount = 0;
        let total_paid_amount = 0;
        return (
            <div className="d-flex" id="wrapper" onClick={this.handleDivClick}>
              {(this.props.target!=='onfly' && this.props.target!=='onfly-disable') &&
              <Leftnav />
              }
              <div className="view-on-fly"  id="page-content-wrapper">
              {(this.props.target!=='onfly-disable') &&
                 <nav className="navbar border-bottom">
                  <div className="row">
                    {(utils.isMobile()===false) &&
                    <>
                    <div className="col-md-5">
                      <div className="mod-title">
                        <a href="/payments"><span>Payments</span></a> / <a href={"/payments/"+this.state.payment_type.toLowerCase()}>
                          <span>{this.state.payment_type}</span>
                        </a>
                      </div>
                    </div>
                     <div className="col-md-2 text-right  mrtop-15">
                       
                    </div>
                    </>
                    }
                    <div className="col-md-5 mrtop-15 action-area">
                    {(user_info.login_id===this.state.view_branch_id && this.props.target!=='onfly-disable') &&

                      <div className="e-p-p-opt  div-display" style={{float:'right'}}>
                          {/*<div className="eppopt">Email</div>*/}
                          <div className="eppopt" onClick={this.handlePrint} id="print"><a>Print</a></div>
                          {(this.state.payment_type==='OUT' && this.state.cheque_data && this.state.cheque_data[0] && this.state.cheque_data[0].amount) &&
                            <div className="eppopt" onClick={this.handleChequePrint} id="cheque-print"><a>Cheque Print</a></div>
                          }
                          {/* <div  onClick={this.handlePrint} id="preview" className="eppopt"><a>Preview</a></div> */}
                          {(edit || del) &&
                            <>
                            {(this.state.payment_data && this.state.payment_data.payment_mode && this.state.payment_data.status!==6) &&
                              <>
                                {edit &&
                                  <>
                                    {(this.state.is_order_pay===false) &&
                                    <div className="eppopt"><a href={"/new-payment/edit/"+this.state.update_id} target="_blank">Edit</a></div>
                                    }
                                    {(this.state.is_order_pay===true) &&
                                    <div className="eppopt"><a href={"/new-payment/order/edit/"+this.state.update_id} target="_blank">Edit</a></div>
                                    }
                                  </>
                                }
                                {del &&
                                  <div className="eppopt no-border-right" id={this.state.update_id} onClick={this.delteBilling} data_target="delete_confirm" data_value="payment" data_id={"delete_payment"}><a>{(this.state.payment_data.is_current===true)?'Delete':'Cancel'}</a></div>
                                }
                              </>
                            }
                            </>
                        }

                      </div>
                    }
                    </div>
                   
                  </div>
                </nav>
              }
                <div  id="main-container">
                <ServiceLoader show={this.state.show_loader}/>
                <div className="container-fluid">
                  <div className="content-area">
                     
                     <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        {(this.props.target!=='onfly-disable') &&
                        <div className="col-md-4 mrtop-5 mob-content">
                            <label className="bil_no">Invoice {this.state.currrent_sequence}</label>
                        </div>
                        }
                        {(this.props.target==='onfly-disable') &&
                        <div className="col-md-3 mrtop-5 mob-content">
                            <h3 className="bil_no" style={{margin:'0px'}}>
                              <a href={"/view-payment/"+this.state.update_id} target="_blank">{this.state.currrent_sequence}</a>
                            </h3>
                            
                            <span style={{fontSize: '11px',
    background: (this.state.payment_type==='IN')?'#218838':'red',
    color: 'white',
    padding: '4px 5px',
    fontWeight: 'bold'}}>{this.state.payment_type}</span>
                            
                        </div>
    }
                        <div className="col-md-4 mrtop-5 text-center mob-content">
                            <label className="bill-amount">Paid Amount : {(this.state.payment_data && this.state.payment_data.paid_amount)?this.currency_format(this.state.payment_data.paid_amount.replace(/,/g , "")):0}</label>
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right mob-content">
                            <label className="bil_no" style={{textTransform:'capitalize'}}>
                              {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.status!==6)?this.state.payment_data.payment_option:(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.status===6)?'Cancelled':'-'}
                            </label>
                        </div> 
                       </div>
                     </div>
                   
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bill-to-c text-right">
                                  <span className="fe-title">BILL TO</span>
                                </div>
                                <div className="bill-to-info">
                                  <p className="title">{this.state.contact_info!=''?this.state.contact_info.item_name:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.address_1:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.city:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.phone_1:''}</p>
                                </div> 
                                 <div className="issued-on">
                                  
                                  <div className="input-group">
                                    
                                    <span className="fe-title">Issued On : {this.state.payment_date}</span>
                                    
                                  </div>
                                  
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Issued By : {this.state.issued_by}</span>
                                    
                                  </div>
                                </div>
                              </div> 
                          </div>
                        </div>
                     </div>
                     
                  </div>
                </div>


                 <div className="container-fluid">
                  <div className="content-area">
                    {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.payment_option!=='billwise') &&
                    <>
                    <div className="content-head border-bottom border-top">
                        <div className="row mrbtm-12">
                          <div className="col-md-4 mrtop-5">
                              <label className="bil_no">Payments</label>
                          </div>
                          <div className="col-md-4 mrtop-5 text-center">
                              
                          </div> 
                          <div className="col-md-4 mrtop-5 text-right">
                              
                          </div> 
                        </div>
                        </div>
                        <div style={{overflowX:'scroll'}}>
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">BILL NO</th>
                              <th scope="col">REFRENCE NO.</th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th scope="col">CHQ NO.</th>
                                      <th scope="col">CHQ DATE</th>
                                      <th scope="col">CHQ BANK NAME</th>
                                  </>
                              }
                              <th scope="col">PAID</th>
                              <th scope="col">BALANCE</th>
                            </tr>
                          </thead>
                          <tbody id="items-body">
                            {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                             
                                total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                               
                                  return (
                                    <>
                                   
                                    <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-name">
                                        {(value.bill_invoice_id!==0 && value.is_deleted!=='yes') &&
                                          <DrawerLink  target="billing" url={"/"+value.resource_url+"/"+value.target+"/"+value.bill_invoice_id} schema={value.schema} name={value.bill_invoice_no} />

                                        } 
                                        {(value.is_deleted==='yes') &&
                                          <>
                                            {value.bill_invoice_no}
                                          </>
                                        }
                                        {(value.bill_invoice_id===0) &&
                                          <>
                                          {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                          </>
                                        } 
                                        
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                        }
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.invoice_info.supplier_ref_no}</span>
                                        }
                                        {(value.invoice_info && value.invoice_info.grand_total) &&
                                          <p style={{margin: '0px',color: '#a9a9a9'}}>
                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                          </p>
                                        }
                                      </td>
                                      <td>
                                        {value.refrence_no}
                                      </td> 
                                      {(this.state.is_cheque_payment) &&
                                          <>
                                          {(value.cheque_no!=="") &&
                                          <>
                                              <td>
                                                  {value.cheque_no}
                                              </td> 
                                              <td>
                                                  {value.cheque_date}
                                              </td> 
                                              <td>
                                                  {value.cheque_bank_name}
                                              </td> 
                                          </>
                                          }
                                          {(value.cheque_no==="") &&
                                            <td colSpan={3}></td>
                                          }
                                          </>
                                      }
                                      <td>
                                        ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                      </td> 
                                      <td>
                                        ₹{(value.pending_amount!==null)?
                                            this.currency_format(value.pending_amount.replace(/,/g , ""))
                                            :0
                                          }
                                      </td>
                                    </tr>
                                    
                                    </>
                                    )
                                    }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                          </tbody>
                          <tfoot>
                            <tr>
                              
                              <th></th>
                              <th></th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </>
                              }
                              <th scope="col">₹{utils.currency_format(total_paid_amount)}</th>
                              <th scope="col"></th>
                            </tr>
                          </tfoot>
                        </table>
                        </div>
                        </>
                    }
                    {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.payment_option==='billwise') &&
                      <>
                     <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">Bills</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center">
                            
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right">
                            
                        </div> 
                       </div>
                     </div>
                     <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">BILL NO</th>
                            <th scope="col">REFRENCE NO.</th>
                           {/*  {(this.state.is_cheque_payment) &&
                                <>
                                    <th scope="col">CHQ NO.</th>
                                    <th scope="col">CHQ DATE</th>
                                    <th scope="col">CHQ BANK NAME</th>
                                </>
                            } */}
                            <th scope="col">PAYABLE</th>
                            <th scope="col">BALANCE</th>
                          </tr>
                        </thead>
                        <tbody id="items-body">
                           {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                                 if(value.target!=='payment' && value.status!==9 && value.status!==10 && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')){
                                  total_bill_amount = total_bill_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                 }
                                 return (
                                  <>
                                  {(value.target!=='payment' && value.status!==9 && value.status!==10 && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')) &&
                                   <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                    <td className="item-name">
                                      {(value.bill_invoice_id!==0 && value.is_deleted!=='yes') &&
                                        <DrawerLink  target="billing" url={"/"+value.resource_url+"/"+value.target+"/"+value.bill_invoice_id} schema={value.schema} name={value.bill_invoice_no} />

                                      } 
                                      {(value.is_deleted==='yes') &&
                                        <>
                                          {value.bill_invoice_no}
                                        </>
                                      }
                                      {(value.bill_invoice_id===0) &&
                                        <>
                                        {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                        </>
                                      } 
                                      
                                      {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                          <span style={{color: '#a9a9a9'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                      }
                                      {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                          <span style={{color: '#a9a9a9'}}> | {value.invoice_info.supplier_ref_no}</span>
                                      }
                                      {(value.invoice_info && value.invoice_info.grand_total) &&
                                        <p style={{margin: '0px',color: '#a9a9a9'}}>
                                           ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                        </p>
                                      }
                                    </td>
                                    <td>
                                       {value.refrence_no}
                                    </td> 
                                   {/*  {(this.state.is_cheque_payment) &&
                                        <>
                                        {(value.cheque_no!=="") &&
                                        <>
                                            <td>
                                                {value.cheque_no}
                                            </td> 
                                            <td>
                                                {value.cheque_date}
                                            </td> 
                                            <td>
                                                {value.cheque_bank_name}
                                            </td> 
                                        </>
                                        }
                                        {(value.cheque_no==="") &&
                                          <td colSpan={3}></td>
                                        }
                                        </>
                                    } */}
                                    <td>
                                       ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                    </td> 
                                    <td>
                                       ₹{(value.pending_amount!==null)?
                                          this.currency_format(value.pending_amount.replace(/,/g , ""))
                                          :0
                                        }
                                    </td>
                                  </tr>
                                  }
                                  </>
                                  )
                                  }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                        </tbody>
                        <tfoot>
                          <tr>
                          <th></th>
                          <th></th>
                            <th>₹{utils.currency_format(total_bill_amount)}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                                            
                    {(this.state.note_info.length>0) &&
                      <>
                        <div className="content-head border-bottom  border-top">
                        <div className="row mrbtm-12">
                          <div className="col-md-4 mrtop-5">
                              <label className="bil_no">Note Information</label>
                          </div>
                          <div className="col-md-4 mrtop-5 text-center">
                              
                          </div> 
                          <div className="col-md-4 mrtop-5 text-right">
                              
                          </div> 
                        </div>
                      </div>
                      
                        <table style={{width:'100%'}} className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">NOTE NO</th>
                                    <th scope="col">REASON</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col" style={{textAlign:'right'}}>AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                              {(this.state.note_info && this.state.note_info.map((value,idx)=>{
                                    note_amount = note_amount + parseFloat(value.amount);
                                    return (
                                    <tr>
                                        <td>
                                          <a href={value.url} target="_blank">{value.sequence}</a>
                                          
                                          {(value.bill_info && value.bill_info.bill_no) &&
                                            <>
                                              <span style={{color: '#a9a9a9'}}> (</span>
                                              <DrawerLink  target="billing" url={value.bill_info.url} schema={value.bill_info.schema_name} name={value.bill_info.bill_no} />

                                              {(value.bill_info.dispatched_details && value.bill_info.dispatched_details.supplier_ref_no!=="") &&
                                                  <span style={{color: '#a9a9a9'}}> | {value.bill_info.dispatched_details.supplier_ref_no}</span>
                                              }
                                            <span style={{color: '#a9a9a9'}}>) </span>
                                            </>
                                          } 
                                          
                                        </td>
                                        <td>{value.reason}</td>
                                        <td>{value.created_date}</td>
                                        <td style={{textAlign:'right'}}>₹{this.currency_format(value.amount)}</td>
                                    </tr>
                                    )
                                }))}
                            </tbody>
                            {(note_amount>0) &&
                            <tfoot>
                                <tr>
                                    <th colSpan={4} style={{textAlign:'right'}}>₹{this.currency_format(note_amount)}</th>
                                </tr>
                            </tfoot>
                            }
                        </table>
                      </>
                    }
                      <div className="content-head border-bottom  border-top">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">Return Information</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center">
                            
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right">
                            
                        </div> 
                       </div>
                     </div>
                    
                        <table style={{width:'100%'}} className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">BILL NO</th>
                                    <th scope="col">DATE</th>
                                    <th scope="col" style={{textAlign:'right'}}>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                              {(this.state.payment_data && this.state.payment_data.return_bill_data && this.state.payment_data.return_bill_data.map((value,idx)=>{
                                    total_return_amount = total_return_amount + parseFloat(value.paid_amount);
                                    return (
                                    <tr>
                                        <td>
                                          <span>{value.return_goods}</span>
                                          
                                          {(value.bill_info && value.bill_info.bill_no) &&
                                            <>
                                              <span style={{color: '#a9a9a9'}}> (</span>
                                              <DrawerLink  target="billing" url={value.bill_info.url} schema={value.bill_info.schema_name} name={value.bill_info.bill_no} />

                                              {(value.bill_info.dispatched_info && value.bill_info.dispatched_info.supplier_ref_no!=="") &&
                                                  <span style={{color: '#a9a9a9'}}> | {value.bill_info.dispatched_info.supplier_ref_no}</span>
                                              }
                                            <span style={{color: '#a9a9a9'}}>) </span>
                                            </>
                                          } 
                                          
                                        </td>
                                        <td>{value.created_on}</td>
                                        <td style={{textAlign:'right'}}>₹{this.currency_format(value.paid_amount)}</td>
                                    </tr>
                                    )
                                }))}
                                {(this.state.payment_data && this.state.payment_data.payment_meta && this.state.payment_data.payment_meta.map((value,idx)=>{
                                    if(value.status===9 || value.status===10){
                                      total_return_amount = total_return_amount +  parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                    }
                                    return (
                                      <>
                                      {(value.status===9 || value.status===10) &&
                                        <tr>
                                            <td>{value.bill_invoice_no}</td>
                                            <td>{this.state.payment_date}</td>
                                            <td style={{textAlign:'right'}}>₹{this.currency_format(value.total_paid_amount)}</td>
                                        </tr>
                                      }
                                      </>
                                    )
                                }))}
                                {(total_return_amount===0) &&
                            
                                <tr>
                                    <th colSpan={3} style={{textAlign:'center'}}>No Return Information Found</th>
                                </tr>
                              }
                            </tbody>
                            {(total_return_amount>0) &&
                            <tfoot>
                                <tr>
                                    <th colSpan={3} style={{textAlign:'right'}}>₹{this.currency_format(total_return_amount)}</th>
                                </tr>
                            </tfoot>
                            }
                        </table>
                        
                        <div className="content-head border-bottom border-top">
                        <div className="row mrbtm-12">
                          <div className="col-md-4 mrtop-5">
                              <label className="bil_no">Payments</label>
                          </div>
                          <div className="col-md-4 mrtop-5 text-center">
                              
                          </div> 
                          <div className="col-md-4 mrtop-5 text-right">
                              
                          </div> 
                        </div>
                        </div>
                        <div style={{overflowX:'scroll'}}>
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">BILL NO</th>
                              <th scope="col">REFRENCE NO.</th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th scope="col">CHQ NO.</th>
                                      <th scope="col">CHQ DATE</th>
                                      <th scope="col">CHQ BANK NAME</th>
                                  </>
                              }
                              <th scope="col">PAID</th>
                              <th scope="col">BALANCE</th>
                            </tr>
                          </thead>
                          <tbody id="items-body">
                            {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                              if(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance' && value.status!==9 && value.status!==10)){
                                total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                               }
                                  return (
                                    <>
                                    {(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance'  && value.is_old_balance!=='Opening Balance' && value.status!==9 && value.status!==10)) &&
                                    <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-name">
                                        {(value.bill_invoice_id!==0 && value.is_deleted!=='yes' && value.target==='payment') &&
                                          <DrawerLink  target="payment" url={"/view-payment/"+value.bill_invoice_id} schema={value.schema} name={value.bill_invoice_no} />

                                        } 
                                        {(value.is_deleted==='yes' || value.bill_invoice_id>0) &&
                                          <>
                                            {value.bill_invoice_no}
                                          </>
                                        }
                                        {(value.bill_invoice_id===0 && value.is_old_balance!=='Adjusted Payment') &&
                                            <>
                                            {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                            </>
                                        }
                                        {(value.bill_invoice_id===0 && value.is_old_balance==='Adjusted Payment') &&
                                            <>
                                            {this.state.currrent_sequence}
                                            </>
                                        }
                                        
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                        }
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.invoice_info.supplier_ref_no}</span>
                                        }
                                        {(value.invoice_info && value.invoice_info.grand_total) &&
                                          <p style={{margin: '0px',color: '#a9a9a9'}}>
                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                          </p>
                                        }
                                      </td>
                                      <td>
                                        {value.refrence_no}
                                      </td> 
                                      {(this.state.is_cheque_payment) &&
                                          <>
                                          {(value.cheque_no!=="") &&
                                          <>
                                              <td>
                                                  {value.cheque_no}
                                              </td> 
                                              <td>
                                                  {value.cheque_date}
                                              </td> 
                                              <td>
                                                  {value.cheque_bank_name}
                                              </td> 
                                          </>
                                          }
                                          {(value.cheque_no==="") &&
                                            <td colSpan={3}></td>
                                          }
                                          </>
                                      }
                                      <td>
                                        ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                      </td> 
                                      <td>
                                        ₹{(value.pending_amount!==null)?
                                            this.currency_format(value.pending_amount.replace(/,/g , ""))
                                            :0
                                          }
                                      </td>
                                    </tr>
                                    }
                                    </>
                                    )
                                    }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                          </tbody>
                          <tfoot>
                            <tr>
                              
                              <th></th>
                              <th></th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </>
                              }
                              <th scope="col">₹{utils.currency_format(total_paid_amount)}</th>
                              <th scope="col"></th>
                            </tr>
                          </tfoot>
                        </table>
                        </div>
                      </>
                      }
                      <div className="content-head">
                       <div className="row">
                          <div className="col-md-5 div-display">
                              {(this.state.payment_data && this.state.payment_data.notes)?this.state.payment_data.notes:''}
                          </div>
                          <div className="col-md-2">
                          </div> 
                          <div className="col-md-5">
                            <table className="table billing-total">
                              <tbody>
                                  <tr>
                                      <td><span className="sp-normal">Payabel Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-balance">₹{(this.state.payment_data && this.state.payment_data.payable_amount)?this.currency_format(this.state.payment_data.payable_amount):0}</span>
                                       
                                      </td>
                                  </tr>
                                  <tr className="hide">
                                      <td><span className="sp-normal">Balance Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-balance">₹{(this.state.total_balance_amt!=='')?this.state.total_balance_amt:0}</span>
                                       
                                      </td>
                                  </tr>
                                  {(this.state.payment_data && this.state.payment_data.global_discount!==0 && this.state.payment_data.global_discount!=="0" && this.state.payment_data.global_discount!=="") &&
                                    <tr>
                                        <td>
                                          <span className="sp-normal">
                                          Discount
                                          {(this.state.discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                               </span>
                                            </>
                                          }
                                          </span>
                                        </td>
                                        <td className="text-right">
                                           
                                          <span className="sp-normal">
                                            {(this.state.payment_data && this.state.payment_data.global_discount) &&
                                              <>
                                                {(this.state.discount_sym==='rs') &&
                                                <>
                                                <span className="rs-sym">₹</span>{this.state.payment_data.global_discount}
                                                </>
                                                }
                                                {(this.state.discount_sym==='per') &&
                                                <>
                                                 {this.state.payment_data.global_discount}%
                                                 </>
                                                }
                                              </>
                                            }
                                          </span>

                                        </td>
                                    </tr>
                                  }
                                  <tr>
                                      <td><span className="sp-normal">Round Off</span></td>
                                      <td className="text-right">
                                        <> 
                                        {(this.state.round_off>0) &&
                                          <>
                                          {round_off_sym}{this.state.round_off}
                                          </>
                                        }
                                        {(this.state.round_off===0) &&
                                          0
                                        }
                                        </>
                                       </td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Paid Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-amount">
                                          ₹{
                                            (this.state.paid_amount!=='')?this.state.paid_amount:(this.state.total_grand!=='')?this.state.total_grand:0
                                          }
                                        </span>
                                      
                                      </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                       </div>
                     </div>
                     <div className="content-head">
                       <div className="row">
                          <div className="col-md-12 mrtop-5">
                              <p className="msg-to-cust print-block">{(this.state.payment_data && this.state.payment_data.notes)?this.state.payment_data.notes:''}</p>
                          </div>
                       </div>
                     </div>
                  </div>
                </div>
                {(this.state.payment_data && this.state.payment_data.adjusted_data && this.state.payment_data.adjusted_data.length > 0) &&
                    <>
                <div className="container-fluid no-print-block">
                  <div className="content-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom payment-head">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">
                              Adjusted in Bill
                            </label>
                        </div>
                       </div>
                     </div>
                   
                     </form>  
                     
                  </div>
                 
                       <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">INVOICE #</th>
                                  <th scope="col">ADJUSTED DATE</th>
                                  <th scope="col">AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody id="items-body">
                                {(this.state.payment_data.adjusted_data.length > 0) ?this.state.payment_data.adjusted_data.map( (value, index) => {
                                 
                                 return (
                                   <tr className="contact-row border-bottom" key={index} id={"item-row-"+index} data-id={index}>
                                    <td className="item-name">
                                      <DrawerLink  target="billing" url={'/view-billing/'+value.target+'/'+value.id} schema={value.schema} name={value.sequence} />
                                    </td>
                                    <td>
                                       {value.adjusted_date}
                                    </td> 
                                    <td>
                                       ₹{this.currency_format(value.amount)}
                                    </td>
                                  </tr>
                                  )
                                  }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                              </tbody>
                            </table>
                          </div>
                       </div>
                  
                </div>
                    </>
                }
              </div>
              </div>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
              {(this.state.is_print===true) &&
                <PaymentPrint print_data={this.state.payment_data} handleClosePrint={this.handleClosePrint.bind(this)} payment_type={this.state.payment_type} issued_by={this.state.issued_by} global_discount_amount={this.state.global_discount_amount}/>
              }
              {(this.state.is_cheque_print) &&
                  <ChequePrint print_type='print' print_data = {this.state.cheque_data}/>   
              }
              <span id="handleCloseChequePrint" onClick={this.handleCloseChequePrint}></span>
            </div>
        );
    }
}