import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import ModalBox from "./modal-box";
import Breadcrumbs from "./breadcrumbs";
import ContactTypes from "./contact-types";
import PaymentModes from "./payment-modes";

import ContactGroups from "./contact-groups";

import ContactSelect from "./contact-select-on-type";
import TagFields from "./tag-field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';

import KeyShortcut from "./key-shortcut.js";
import ShortcutHelp from "./shortcut-help.js";

import {handleCreateContactToGoogle,GoogleAPIBaazarERP} from "./google-api";
import AccountGroupsSubGroups from "./account-groups-sub-groups";
let {enc_key,app_api,user_info,states} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}


export default class AddContact extends Component {
    constructor() {
        super();
        this.state = {
            referer :'contacts',
            info_body : {
                type : 1,
                agent_name:'',
                name : '',
                status : 1,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                email : '',
                phone_1 : '',
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                account_name : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : '',
                discount : '',
            },
            custom_fields_json : [],
            openingBalanceDate:  new Date(),
            ordersOpeningBalanceDate:  new Date(),
            contacts_list : [],
            agent_toggle : '',
        };
        this.openModal = this.openModal.bind(this);
        this.saveContact = this.saveContact.bind(this);
        this.openingBalanceDate = this.openingBalanceDate.bind(this);
        this.resetAgentToggle = this.resetAgentToggle.bind(this);
    }
    handleSetCustomField = (e) =>{
      let thisvalue = e.currentTarget.value;
      let target = e.currentTarget.getAttribute('data-target');
      let idx = e.currentTarget.getAttribute('data-id');
      let custom_fields_json =  this.state.custom_fields_json;
      custom_fields_json[idx][target] = thisvalue;
      this.setState({custom_fields_json:custom_fields_json});
  }
    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        if(document.getElementById("save-target-type")){
          document.getElementById("save-target-type").value = 'add_contact';
        }
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }
    resetAgentToggle(){
      /* let agent_toggle = localStorage.getItem('agent_toggle');
      this.setState({agent_toggle:agent_toggle});
      localStorage.removeItem('agent_toggle'); */
    }
    openingBalanceDate = date => {
      this.setState({
        openingBalanceDate: date
      });
    }; 
    ordersOpeningBalanceDate = date => {
      this.setState({
        ordersOpeningBalanceDate: date
      });
    }; 


    componentDidMount(){
       
        localStorage.removeItem('p_mode');
        let curl = window.location.href;
        const active_tab = curl.match(/add-contact\/billing\/(\d+$)/i);
        if(active_tab){
          //if(active_tab[1]==='billing'){
            let referer = 'new-billing/'+active_tab[1];
            this.setState({referer:referer});
          //}
        }

        
        let keys = "'contact_custom_fields'";
          let set_data = {
              store_name:user_info.store_name,
              branch_id:user_info.login_id,
              user_id : user_info.user_id,
              keys:keys,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let opt_api = app_api+'/get_settings';
          axios.post(opt_api, set_data)
            .then(function (response) {
              let resp = response.data;
              if(resp.contact_custom_fields!==undefined){
                let contact_custom_fields = resp.contact_custom_fields;
                this.setState({custom_fields_json:contact_custom_fields});
              }
             
          }.bind(this)).catch(function(res){
              
          })
      
    }

    saveContact(event) {
        let id = event.currentTarget.id;
        
        var e = document.getElementById("contact-type-select");
        var contact_type_name = e.options[e.selectedIndex].text;
        let classes = document.getElementsByClassName('validate');
        let is_valid = true;
        for(let i=0;i<classes.length;i++){
            let value = classes[i].value;
            if(value===''){
                classes[i].style.border = '1px solid red';
                is_valid = false;
            }
        } 
        const { info_body } = { ...this.state };
        const currentState = info_body;
        let form_fields = document.getElementsByClassName('form-field');
        currentState['contact_type'] = contact_type_name;
        for(let i=0;i<form_fields.length;i++){
            let value = form_fields[i].value;
            let key = form_fields[i].getAttribute('name');
            if(key==='credit_limit'){
              value = value.replace(/,/g , "");
            }
            currentState[key] = value;
        }
        if(document.getElementById("tags-value-groups")){
          currentState['groups'] = document.getElementById("tags-value-groups").value;
        }
        currentState['opening_balance_type'] = document.querySelector('input[name="opening_balance_type"]:checked').value;
        currentState['opening_balance_date'] = this.state.openingBalanceDate;
        currentState['orders_opening_balance_type'] = document.querySelector('input[name="orders_opening_balance_type"]:checked').value;
        currentState['orders_opening_balance_date'] = this.state.ordersOpeningBalanceDate;

        currentState['custom_data'] = this.state.custom_fields_json;
        currentState['uuid'] = utils.uuid();
        currentState['role_id'] = user_info.access_level;
        currentState['token'] = user_info.token;
        currentState['_schema'] = user_info._schema;

        let account_group = '';
        if(document.getElementById('account-group-select')){
          account_group = document.getElementById('account-group-select').value;
        }
        currentState['account_group'] = account_group;
        
        //handleCreateContactToGoogle(currentState);
        //return false;
        this.setState({ info_body: currentState });
        let contact_type_id = document.getElementById("contact-type-select").value;
        if(is_valid){
          document.getElementById(id).classList.add("hide");
          document.getElementById(id+'-btn-spinner').classList.remove("hide");
          let api = app_api+'/create_contact';
          let list_data = this.state.info_body;
          axios.post(api, list_data)
            .then(function (response) {
              let data = response.data;

               if(data.success === 1){
                  let resp = response.data.response;
                  let insert_id = parseInt(resp.insert_id);
                  //handleCreateContactToGoogle(currentState)
                 if(data.message===1){
                   try{
                     let transaction = db.transaction("contacts", "readwrite");
                     let contacts = transaction.objectStore("contacts");
                     let contact = CryptoJS.AES.encrypt(JSON.stringify(list_data), enc_key).toString();
                     let contact_type = list_data.type;
                     let contact_data = {};
                     contact_data['id'] = parseInt(insert_id);
                     contact_data['type'] = parseInt(contact_type);
                     contact_data['contact_type'] = contact_type_name;
                     contact_data['data'] = contact;
                     contacts.add(contact_data);
                   }catch(e){}
                 }
                 if(this.props.is_on_fly===undefined || this.props.is_on_fly===false){
                    window.location.href = "/"+this.state.referer+'/'+contact_type_id;
                 }else if(this.props.is_on_fly===true){
                    this.handleCloseContact();
                    if(document.getElementById("contact_box")){
                      document.getElementById("contact_box").value = list_data.name;
                    }
                    if(document.getElementById("customer-dropdown")){
                      document.getElementById("customer-dropdown").value = insert_id;
                    }
                    if(document.getElementById("close-contact")){
                      document.getElementById("close-contact").click();
                    }
                    if(document.getElementById("contact_box")){
                      document.getElementById("contact_box").focus();
                    }
                 }
              }else if(data.success===2){
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                document.getElementById("name-exist").innerHTML = data.message;
              }
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
      }
    }

    handleChangeContactType = (e) =>{
        let thisvalue = e.currentTarget.value;
        let info_body = this.state.info_body;
        info_body.type = thisvalue;
        this.setState({info_body:info_body});
    }

    handleCloseContact = () =>{
      this.props.handleCloseContact();
    }

    render() {
        return (
            <div className={(this.props.is_on_fly===undefined || this.props.is_on_fly===false)?"d-flex":'on-fly-create-contact'} id={(this.props.is_on_fly===undefined || this.props.is_on_fly===false)?"wrapper":''}>
              <GoogleAPIBaazarERP />
              {(this.props.is_on_fly===undefined || this.props.is_on_fly===false) &&
                <Leftnav />
              }
              <div id={(this.props.is_on_fly===undefined || this.props.is_on_fly===false)?"page-content-wrapper":''}>
              {(this.props.is_on_fly===undefined || this.props.is_on_fly===false) &&
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12">
                        <Breadcrumbs link="/contacts" title="Contacts" sub_title="New Contact"/>
                    </div>
                  </div>
                </nav>
              }
                <div className={(this.props.is_on_fly===undefined || this.props.is_on_fly===false)?"container-fluid":''}>
                  <div className={(this.props.is_on_fly===undefined || this.props.is_on_fly===false)?"content-area":''}>
                     
                     <div className="content-head border-bottom">
                        <b>Basic Profile</b>
                        <p className="info">Basic information about contact.</p>
                     </div>
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CONTACT TYPE <span className="red">*</span></label>
                                  </div>
                                  <div className="col-md-8">
                                    <ContactTypes show="dropdown" name="type" target="add-contact" tabindex="1" onChange={this.handleChangeContactType} default={(this.props.info!==undefined)?this.props.info.type:'1'}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3">
                                      <label className="lbl">ACCOUNT GROUP</label>
                                    </div>
                                    <div className="col-md-8">
                                      <AccountGroupsSubGroups />
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                              </div>
                            
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">NAME <span className="red">*</span></label>
                                  </div>
                                  <div className="col-md-8">     
                                   <input type="text" tabindex="3" name="name" className="form-field form-control validate"  placeholder="Enter name" defaultValue={(this.props.info!==undefined)?this.props.info.name:''}/>
                                   <p className="error" id="name-exist"></p>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                {(this.state.info_body.type===2 || this.state.info_body.type==='2') &&
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label className="lbl">SHORT NAME</label>
                                    </div>
                                    <div className="col-md-8">     
                                      <input type="text" name="short_name" className="form-field form-control"  placeholder="Enter Short Name"/>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                }
                                {(this.state.info_body.type===4 || this.state.info_body.type==='4') &&
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label className="lbl">LOCATION</label>
                                    </div>
                                    <div className="col-md-8">     
                                      <input type="text" name="location" className="form-field form-control"  placeholder="Enter Location"/>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                }
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ADDRESS LINE 1</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="2" name="address_1" className="form-field form-control" placeholder="Enter address line 1"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                          
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PHONE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" tabindex="5" name="phone_1" className="form-field form-control"  placeholder="+1-000-000-0000"  defaultValue={(this.props.info!==undefined)?this.props.info.phone_1:''}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ADDRESS LINE 2</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="4" name="address_2" className="form-field form-control"  placeholder="Enter address line 2"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              
                              </div>
                           
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PHONE 2</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" tabindex="7" name="phone_2" className="form-field form-control" placeholder="+1-000-000-0000"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CITY</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="6" name="city" className="form-field form-control" placeholder="Enter city name"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              
                              </div>
                           
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">EMAIL</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" tabindex="9" name="email" className="form-field form-control" placeholder="Enter email id"  defaultValue={(this.props.info!==undefined)?this.props.info.email:''}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">STATE</label>
                                  </div>
                                   <div className="col-md-8">

                                    <select tabindex="8" name="state" className="form-field form-control" placeholder="Enter state name" >
                                      <option key="select_state" value="" >Select State</option>
                                    {(states.length > 0) ? states.map( (value, index) => {
                                     return (
                                        <option key={index} value={value}>{value}</option>
                                      )
                                    }) : ''}
                                    </select>
                                    
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                           
                              <span id="resetAgentToggle" onClick={this.resetAgentToggle}></span>
                              <div className={" mrtop-10 col-md-6 "+this.state.agent_toggle} id="agent-name-blk">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">AGENT NAME</label>
                                  </div>
                                  <div className="col-md-8">
                                   {/*  <input type="text" className="form-field form-control" placeholder="Enter Agent Name" name="agent_name"/> */}
                                    <ContactSelect className="form-field" name="agent_name" defaultValue='' tabindex={7} label="Select Agent" type="5"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PIN CODE</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="10" name="pin" className="form-field form-control" placeholder="Enter pin code"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                          
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">STATUS</label>
                                  </div>
                                  <div className="col-md-8">
                                    <select className="form-field form-control" name="status">
                                      <option value="1">Active</option>
                                      <option value="10">Inactive</option>
                                    </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              
                              
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className='col-md-3'>
                                    <label className="lbl">GROUP</label>
                                  </div>
                                  <div className='col-md-8'>
                                    <TagFields target="groups" />
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              
                              {(this.state.info_body.type==='1' || this.state.info_body.type===1) &&
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CUSTOMER TYPE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <select className="form-field form-control" name="customer_type">
                                      <option value="retail">Retail</option>
                                      <option value="wholesale">Wholesale</option>
                                    </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              }

                            </div>
                        </div>
                     </div>
                     <div className="content-head border-bottom">
                        <b>Additional Information</b>
                        <p className="info">Additional information about contact.</p>
                     </div>
                     <div className="main-content  border-bottom">
                        <div className="inner-content">
                              
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">GST NUMBER</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" tabindex="11" name="gst_number" className="form-field form-control" placeholder="Enter GST number"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">BANK NAME</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="12" name="bank_name" className="form-field form-control" placeholder="Enter bank name"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PAN NUMBER</label>
                                  </div>
                                  <div className="col-md-8">
                                   <input type="text" tabindex="13" name="pan_no" className="form-field form-control"  placeholder="Enter PAN number"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">A/C NAME</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="14" name="account_name" className="form-field form-control"  placeholder="Enter account name"/>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">MODE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <PaymentModes show="dropdown" name="payment_mode" tabindex="15" target="contact"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">A/C NUMBER</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="16" name="account_no" className="form-field form-control"  placeholder="Enter account number"/>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">OPENING BALANCE</label>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group" style={{margin: '-5px 0px 0px 0px'}}>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="opening_balance_type" id="opening_balance_credit" value="credit" defaultChecked={true}/>
                                        <label className="form-check-label" htmlFor="opening_balance_credit">
                                          Payable (-)
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="opening_balance_type" id="opening_balance_debit" value="debit" />
                                        <label className="form-check-label" htmlFor="opening_balance_debit">
                                          Receivable (+)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <input type="text" tabindex="17" name="opening_balance" className="form-field form-control" placeholder="100,000,00"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">IFSC CODE</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="18" name="ifsc_code" className="form-field form-control"  placeholder="Enter ifsc code"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">OPENING BALANCE DATE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text no-border-right">
                                          <FontAwesomeIcon icon={faCalendarAlt} />
                                        </span>
                                        <DatePicker className="form-control form-field no-border-left" selected={this.state.openingBalanceDate} onChange={this.openingBalanceDate} tabindex="19" dateFormat="dd/MM/yyyy" name="opening_balance_date"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">

                                  <div className="col-md-4">
                                    <label className="lbl">BRANCH ADDRESS</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" tabindex="20" name="bank_address" className="form-field form-control"   placeholder="Enter branch address"/>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="lbl">ORDERS OPENING BALANCE</label>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group" style={{margin: '-5px 0px 0px 0px'}}>
                                        <div className="form-check">
                                          <input className="form-check-input" type="radio" name="orders_opening_balance_type" id="orders_opening_balance_credit" value="credit" defaultChecked={true}/>
                                          <label className="form-check-label" htmlFor="orders_opening_balance_credit">
                                            Payable (-)
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input className="form-check-input" type="radio" name="orders_opening_balance_type" id="orders_opening_balance_debit" value="debit" />
                                          <label className="form-check-label" htmlFor="orders_opening_balance_debit">
                                            Receivable (+)
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <input type="text" tabindex="17" name="orders_opening_balance" className="form-field form-control" placeholder="100,000,00"/>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="lbl">LIMIT</label>
                                    </div>
                                    <div className="col-md-8">
                                      <input type="text" tabindex="21" name="credit_limit" className="form-field form-control" placeholder="100,000,00"/>
                                    </div>
                                  </div>
                                </div>
                            </div>

                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ORDERD OPENING BALANCE DATE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text no-border-right">
                                          <FontAwesomeIcon icon={faCalendarAlt} />
                                        </span>
                                        <DatePicker className="form-control form-field no-border-left" selected={this.state.ordersOpeningBalanceDate} onChange={this.ordersOpeningBalanceDate} tabindex="19" dateFormat="dd/MM/yyyy" name="orders_opening_balance_date"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">CITY</label>
                                  </div>
                                    <div className="col-md-8">
                                    <input type="text" tabindex="22" name="bank_city" className="form-field form-control"   placeholder="Enter city"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CREDIT DATE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" tabindex="23" name="credit_days" className="form-field form-control" placeholder="45 Days"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">DISCOUNT</label>
                                  </div>
                                  <div className="col-md-8">
                                     <div className="input-group">
                                      <input type="text" tabindex="24" name="discount" className="no-border-right form-field form-control" placeholder="Enter Discount"  defaultValue={(this.props.info!==undefined)?this.props.info.discount:''}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">NOTIFICATION</label>
                                  </div>
                                  <div className="col-md-8">
                                    <select className="form-control form-field" tabindex="25" name="notification">
                                     <option value="1">Yes</option>
                                     <option value="0">No</option>
                                   </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">NOTES</label>
                                  </div>
                                   <div className="col-md-8">
                                    <textarea tabindex="26" name="notes" className="form-field form-control"></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                           
                        </div>
                     </div>
                     {(this.state.custom_fields_json.length>0) &&
                       <>
                        <div className="content-head border-bottom">
                          <b>Custom Data</b>
                          <p className="info">Custom Data added from settings.</p>
                        </div>
                        <div className={"main-content border-bottom ofly "+this.props.is_on_fly}>
                            <div className="inner-content">
                                    <div className="row">
                                        {this.state.custom_fields_json.map((value,index) => {
                                            return (
                                                  <>
                                                    {((index+1)%2!==0) &&
                                                        <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                <label className="lbl" style={{textTransform:'uppercase'}}>{value.name}</label>
                                                                </div>
                                                                <div className="col-md-8">
                                                                <input type="text" tabindex={27+index} value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField} className="form-control" placeholder={"Enter "+value.name}/>
                                                                </div>
                                                                <div className="col-md-1"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {((index+1)%2===0) &&
                                                      <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                          <div className="row">
                                                              <div className="col-md-4">
                                                              <label className="lbl" style={{textTransform:'uppercase'}}>{value.name}</label>
                                                              </div>
                                                              <div className="col-md-8">
                                                              <input type="text" tabindex={27+index} value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField} className="form-control" placeholder={"Enter "+value.name}/>
                                                              </div>
                                                          </div>
                                                      </div>
                                                    }
                                                  </>
                                                )
                                        })}
                                    </div>
                            </div>
                        </div>
                      </>
                    }
                     
                    <div className={"content-head on-fly-cc-but "+this.props.is_on_fly}>
                      <div className="row">
                        
                        <div className="col-md-12">
                          {(this.props.is_on_fly===true) &&
                            <button type="button" className="btn btn-secondary" id="handleCloseContact" onClick={this.handleCloseContact}>Close</button>
                          }
                          <button type="button" id="add-contact-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          <button type="button" id="add-contact" onClick={this.saveContact} className="btn btn-success btn-save-action  pull-right">Save Contact <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <KeyShortcut/>
              <ModalBox id="add_contact_group" title="ADD CONTACT GROUPS" data_id="Contact Croups"/>
            </div>
        );
    }
}