import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import axios from 'axios';
let {enc_key,app_api,user_info,states} = require('../../library.js');

export default class PrintTemplateDesing extends Component {
    constructor() {
        super();
        this.state = {
            desing_data : {design_name:''},
            is_show_desing_area : false,
        };
    }
    handleChangeDesignValue = (type,value) =>{
      let ddata = this.state.desing_data;
      ddata[type] = value;
      this.setState({desing_data:ddata});
    }
    handleProceedForDesing = () =>{
      this.setState({is_show_desing_area:true});
    }
    render() {
   
        return (
            <div className="modal" style={{display:'block'}}>
            <div className="modal-dialog modal-xl" style={{width:'unset'}}>
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <h5 className="modal-title w-100" id="re-gen-bc-msg" style={{fontSize:'16px'}}>
                    Design Print Template
                  </h5>  
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-3" style={{borderRight:'1px solid #d7d7d7'}}>
                            <div className="row">
                                <div className="col-md-9">
                                    <input type="text" className="form-control" placeholder="Enter Template Name" onChange={(e)=>this.handleChangeDesignValue('design_name',e.target.value)} defaultValue={this.state.desing_data.design_name}/>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-success btn-small" onClick={this.handleProceedForDesing}>Go</button>
                                </div>
                                <div className="col-md-12"><hr /></div>
                            </div>
                        </div>
                        {(this.state.is_show_desing_area===true) &&
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-12">
                                <h4 style={{textAlign:'center'}}>{this.state.desing_data.design_name} Print Design</h4>
                                <hr />
                              </div>
                              <div className="col-md-3">
                                <label htmlFor="page_width">Page Width</label><br />
                                <div className="input-group">
                                  <input type="text" className="form-control" placeholder="Width" onChange={(e)=>this.handleChangeDesignValue('page_width',e.target.value)} defaultValue={this.state.desing_data.page_width} id="page_width"/>
                                  <div className="input-group-append">
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <label htmlFor="page_height">Page Height</label><br />
                                <div className="input-group">
                                  <input type="text" className="form-control" placeholder="Height" onChange={(e)=>this.handleChangeDesignValue('page_height',e.target.value)} defaultValue={this.state.desing_data.page_height} id="page_height"/>
                                  <div className="input-group-append">
                                    <span className="input-group-text">cm</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(this.state.desing_data.page_height!==undefined && this.state.desing_data.page_height!=="" && this.state.desing_data.page_width!=="") &&
                            <div className="row">
                              <div className="col-md-12">
                                  <div style={{height:this.state.desing_data.page_height+'cm',width:this.state.desing_data.page_width+'cm',border:'1px solid black'}}></div>
                              </div>
                            </div>
                            }
                          </div>
                        }
                    </div>
                </div>
                <div className="modal-footer justify-content-center" style={{padding:'0px'}}>
                  <div className="row">
                    <div className="col-md-6 mrtop-10">
                      <button type="button" className="btn btn-secondary">Close</button>
                    </div>
                  
                    <div className="col-md-6 mrtop-10 text-right">
                      <button type="button" className="btn btn-primary" id="handleRegenerateBarcode">Save Template</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}