import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import Notification from "./notification.js";
import check_access from './access-level.js';
import DrawerLink from "./open-drawer-link";
import Link from './link.js';
import CreateTransport from "./create-transport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilter, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
export default class Transport extends Component {
    constructor() {
        super();
        this.state = {
          active_tab : 'transport',
          FromDate:  new Date(),
          ToDate: new Date(),
          transports_list:[],
          transports_list_temp:[],
          trash_count : 0,
          loading_process:'Loading...',
          is_search:false,
          is_create_transport:false,
          is_advance_filter:false,
          transport_data : [],
          advance_filter_options : [],
          is_adv_search : false
        }

        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
  


  componentDidMount(){
    localStorage.removeItem('is_edit_transport');
    localStorage.removeItem('selected_contact');
    let curl = window.location.href;
    this.getTransportList();
    let body =  {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
           }
        let api = app_api+'/get_transport_filter_options';
        axios.post(api, body)
        .then(function (response) {
          let status = response.data;
          this.setState({advance_filter_options:status});
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }

  getTransportList = ()=>{
    let curl = window.location.href;

    let ptype = 'all';

    const page_tab = curl.match(/transports\/page\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[1];
    }


    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              page : current_page,
              show : ptype,
           }
        let api = app_api+'/get_transports';
        axios.post(api, body)
        .then(function (response) {
          let page_count = response.data.number_of_page;
    
          if(response.data.data.length==0){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ 'transports_list': response.data.data });
            this.setState({ 'transports_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
  

    handleNewTransport = ()=>{
       this.setState({is_create_transport:true,transport_data:[]})
        /* var element = document.getElementById('create-transport-pop');
        element.classList.remove("fade");
        element.style.display="block"; */ 
        if(document.getElementById("tr-date")){
            document.getElementById("tr-date").focus();
        }
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").value = '';
          document.getElementById("customer-dropdown").value = '';
          document.getElementById("old-customer-dropdown").value = '';
        }
        if(document.getElementById("received_date")){
          document.getElementById("received_date").value='';
        }
    }

    handleOpenEditModel = (e)=>{
        
        let index = e.currentTarget.getAttribute('index');
        let transports_list = this.state.transports_list;
        let transport_data = transports_list[index];
        
        let contact_info = transport_data.contact_info;
        let contact_name = '';
        let contact_id = '';
        let address_1 =  '';
        let address_2 = '';
        let city = '';
        let credit_limit = '';
        let previous_balance = '';
        let email = '';
        let phone_1 = '';
        let phone_2 = '';
        let state = '';
        if(Object.keys(contact_info).length > 0 && contact_info[0].name!==undefined) {
          contact_name = contact_info[0].name;
          contact_id = contact_info[0].contact_id;
          localStorage.setItem("selected_contact",contact_id);
          address_1 = contact_info[0].address_1;
          address_2 = contact_info[0].address_2;
          city = contact_info[0].city;
         
          credit_limit = contact_info[0].credit_limit;
          email = contact_info[0].email;
          phone_1 = contact_info[0].phone_1;
          phone_2 = contact_info[0].phone_2;
          state = contact_info[0].state;
       }
        let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
        transport_data.c_info = c_info;
        this.setState({is_create_transport:true,transport_data:transport_data})
        /* var element = document.getElementById('create-transport-pop');
        element.classList.remove("fade");
        element.style.display="block";  */
        localStorage.setItem('is_edit_transport','yes');
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").value = contact_name;
          document.getElementById("customer-dropdown").value = contact_id;
          localStorage.setItem('selected_contact',contact_id);
          
          document.getElementById("old-customer-dropdown").value = contact_id;
        }

        let p_info = {item_name:transport_data.p_no, item_id:transport_data.purchase_id};
        transport_data.purchase_info = p_info;
        setTimeout(() => {
          if(document.getElementById("handleGetPartyInvoice")){
            document.getElementById("handleGetPartyInvoice").click();
            /* if(document.getElementById("p_no")){
              document.getElementById("p_no").value = transport_data.purchase_id;
            } */
          }
         /*  if(document.getElementById("bill_search_list_box")){
            //document.getElementById("bill_search_list_box").value = transport_data.p_no;
            document.getElementById("bill_search_list").value =  transport_data.purchase_id;
            document.getElementById("old-bill_search_list").value =  transport_data.purchase_id;
          } */
        }, 150);
    }
    hanldeCloseCreateTransPop = ()=>{
      this.setState({is_create_transport:false});
    }
    handleTransportSearch = (e) =>{
        let _this = this;
        let item_name = document.getElementById("transport-search-box").value;
        this.setState({loading_process:'Loading...'});
        if(item_name!==""){
          this.setState({ transports_list: [],is_search:true });
          clearTimeout(this.timeout);
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                search : item_name,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
            }
          let api = app_api+'/get_transports';
          _this.timeout = setTimeout(function () {
          axios.post(api, body)
            .then(function (response) {
                  if(response.data.data.length==0){
                    _this.setState({loading_process:'Record(s) not found'});
                  }else{
                    _this.setState({ 'transports_list': response.data.data });
                  }
          }.bind(_this)).catch(function(res){
              if(res.response){
                _this.setState({loading_process:'Record(s) not found'});
              }
          })
          }, 500);
        }else{
          clearTimeout(this.timeout);
          if(this.state.is_search===true){
            _this.setState({ 'transports_list': _this.state.transports_list_temp });
            _this.setState({ is_search:false });
          }
        }
    }

    handleOpenSearchDialog = () =>{
      this.setState({is_advance_filter:true})
    }

    handleCloseSearchDialog = () =>{
      this.setState({is_advance_filter:false})
    }
    handleApplyAdvanceFilter = (e) =>{
      this.setState({ transports_list: [],is_search:true,is_adv_search:true });
      let search_qs = document.querySelectorAll('.filter-opt');
      let filter_option = {};
      filter_option['status'] = [];
      for (let index = 0; index < search_qs.length; index++) {
        const element = search_qs[index];
        let data_id = element.getAttribute('data-id');
        let value = element.value;
        let type = element.type;
        if(type==='checkbox' && element.checked){
          filter_option['status'].push(value);
        }
      }
      if (filter_option['status'].length>0) {
        let body =  {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              search : filter_option
          }
        let api = app_api+'/apply_tranport_advance_filter';
        axios.post(api, body)
        .then(function (response) {
          this.setState({ 'transports_list': response.data.data });
          //this.setState({ 'transports_list_temp': response.data.data });
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
        })
      }else{
        this.setState({ 'transports_list': this.state.transports_list_temp });
        this.setState({ is_search:false,is_adv_search:false });
      }
    }
    handlePrintSearchList = ()=>{
        var divContents = document.getElementById("transport-list-block").innerHTML; 

        var a = window.open('', '', 'height=1000,location=no,left=250px'); 
        a.document.write('<html><head>'); 
        a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
        a.document.write('<style type="text/css">@page{size:A4,margin:0}.table .thead-light th{font-size:12px}.table .thead-light th{color:#9a9494}.table .thead-light th{background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table th,.table tr td{white-space:nowrap}.table td,.table th{padding:.75rem;border-top:1px solid #dee2e6}.no-print{display:none}.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{font-size:12px !important}</style>'); 
        a.document.write('</head>'); 

        a.document.write('<body onload="window.print();">'); 
        a.document.write(divContents); 
        a.document.write('</body></html>');
        a.document.close();
        a.focus();
      
    }
    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <span id="hanldeCloseCreateTransPop" onClick={this.hanldeCloseCreateTransPop}></span>
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Transport List</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <div className="input-group">
                              <input type="text" onChange={this.handleTransportSearch} id="transport-search-box" className="form-control no-border-right" placeholder="Type to search..."/>
                              <div className="input-group-append" id="is_searching_item">
                                <span className="input-group-text" > <FontAwesomeIcon style={{cursor:'pointer'}} icon={faFilter} onClick={this.handleOpenSearchDialog}/></span>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                          <Notification /> 
                          {(this.is_access('transport-create') || this.is_access('transport-create')) &&
                            <>
                              <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleNewTransport}>New Transport</button>
                            </>
                          }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                    {(this.state.is_adv_search===true) &&
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-success pull-right" style={{marginTop
                        :'10px',marginBottom:'10px', marginRight:'10px'}} onClick={this.handlePrintSearchList}>Print</button>
                      </div>
                    </div>
                    }
                     <div className="main-content" style={{overflow:'auto'}} id="transport-list-block">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">TRANSPORT #</th>
                            <th scope="col">TRANSPORT DATE</th>
                            <th scope="col">PARTY NAME</th>
                            <th scope="col">LR. NO</th>
                     
                            <th scope="col">TRANSPORT</th>
                            <th scope="col">BALE</th>
                            <th scope="col" className="no-print">WEIGHT</th>
                            <th scope="col" className="no-print">AMOUNT</th>
                            <th scope="col" className="no-print">RECEIVED DATE</th>
                            <th scope="col" className="no-print">SUPPLIER'S REF. NO.</th>
                            <th scope="col">PURCHASE NO.</th>
                            <th scope="col" className="no-print">RECEIPT</th>
                            <th scope="col">STATUS</th>
                            <th scope="col" className="no-print"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.transports_list.length > 0) ?this.state.transports_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } id={"billing-row-"+value.transport_id} className={"contact-row"}>
                              <td>
                                <DrawerLink  target="transport" url={''} name={value.transport_number} info={value}/>
                              </td>
                              <td>{value.transport_date}</td>
                              <td>
                                {(value.party_id>0) &&
                                  <Link link={'/view-contact/'+value.party_id} name={value.party_name} />
                                }
                                {(value.party_id===0) &&
                                  <>
                                    {value.party_name}
                                  </>
                                }
                              </td>
                              <td>{value.lr_no}</td>
                              <td>
                                {(value.transport_id>0) &&
                                  <Link link={'/view-contact/'+value.transport_id} name={value.transport} />
                                }
                                {(value.transport_id===0) &&
                                  <>
                                    {value.transport}
                                  </>
                                }
                              </td>
                              <td>{value.bale}</td>
                              <td className="no-print">{value.weight}</td>
                              <td className="no-print">{value.amount}</td>
                              <td className="no-print">{value.received_date}</td>
                              <td className="no-print">{value.b_no}</td>
                              <td>
                                {(value.purchase_id>0) &&
                                <DrawerLink target="billing" url={'/view-billing/purchase/'+value.purchase_id} name={value.p_no} schema={value.schema}/>
                                }
                                {(value.purchase_id===0) &&
                                  <>
                                    {value.p_no}
                                  </>
                                }
                              </td>
                              <td className="no-print">{value.receipt}</td>
                              <td>{value.status}</td>
                              <td className="no-print">
                             
                                  <Tooltip opt={''} id={value.transport_id} index={index} handleOpenEditModel={this.handleOpenEditModel} edit={value.transport_id} delete={{active_tab:'transport',id:value.transport_id,data_value:'transport',delete_label:'Delete'}} target="transport"/>
                            
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={14} className="text-center">{this.state.loading_process}</td></tr> }
                           
                         
                        </tbody>
                      </table>
                      {(this.state.transports_list && this.state.transports_list.length > 0) &&
                        <ListCount list={this.state.transports_list}/>
                      }
{(this.state.is_search===false) &&
                     <Pager total_page={this.state.total_page}/>
}
                      
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.is_create_transport===true) &&
                <CreateTransport transport_data={this.state.transport_data}/>
              }
              {(this.state.is_advance_filter) &&
                <div id="advance-filter-box" className="modal" style={{display:'block'}}>
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div style={{position: 'absolute',right: '5px', top: '0',fontSize: '20px',cursor:'pointer'}} onClick={this.handleCloseSearchDialog}><FontAwesomeIcon icon={faTimesCircle}/></div>
                      <div className="modal-header flex-column" style={{textAlign:'left'}}>  
                        <h5>Transport Status</h5>       
                        
                          {(this.state.advance_filter_options.status) &&
                          <>
                            {(this.state.advance_filter_options.status.map((value)=>{
                              return (
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input filter-opt" type="checkbox" value={value} id={value} data-id="status" onChange={this.handleApplyAdvanceFilter}/>
                                      <label className="form-check-label" htmlFor={value}>
                                      {value}
                                      </label>
                                    </div>
                                  )
                            }))}
                          </>
                          }
                        
                      </div>
                    </div>
                  </div>
                </div> 
              }
            </div>
        );
    }
}