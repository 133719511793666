import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let TypeList = [];
let _this;
let db;
export default class AccountGroupLinkTypes extends Component {
    constructor(props) {
      super(props);
      this.state = {
          type_list : [],
          account_types : [],
          delete_info : [],
          typeDefault : 0,
          
      };
      _this = this;
      this.handleChange = this.handleChange.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    handleChange(e){
        let contact_type = e.currentTarget.value;
        this.setState({typeDefault:contact_type});
    }
    componentDidMount(){
      
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_contact_type';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            cursor.map( (value, key) => {
                let id = value.contact_type_id;
                let type = value.type;
                TypeList[id] = type;
            });
            this.setState({ 'type_list': TypeList });
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })

    
        /* body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        api = app_api+'/get_account_types';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            this.setState({ 'account_types': cursor });
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        }) */
    }

 
    render() {
        
        return (
          <div>
              <select onChange={this.handleChange} className="form-control validate form-field" id="link-type-select" name={this.props.name} value={this.state.typeDefault||this.props.default}>
                <option value='' >Select Account Type</option>
                <option value={0}>None</option>
                {/* <optgroup label="Account Types"> */}
                  {(this.state.type_list.length > 0) ? this.state.type_list.map( (value, index) => {
                   return (
                      <option value={index} key={index}>{value}</option>
                    )
                  }) : ''}
                {/* </optgroup> */}
              {/*   <optgroup label="More">
                    <option value={'bank_account'} key={'bank_account'}>{'Bank Account'}</option>
                    <option value={'stock_in_hand'} key={'stock_in_hand'}>{'Stock In Hand'}</option>
                </optgroup> */}
              </select>
            </div>
        );
    }
}