import React, { Component } from "react";
import axios from 'axios';
import utils from '../../utils.js';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
let {enc_key,app_api,user_info} = require('../../library.js');
export default class ChequePrintSetting extends Component {
    constructor() {
        super();
        this.state = {
            cheque_print_settings : {}
        };
        
    }
    componentDidMount = () =>{
        
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            account_id:this.props.account_data.actid
        }
        let opt_api = app_api+'/get_cheque_print_setting';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            this.setState({cheque_print_settings:resp});
          
        }.bind(this)).catch(function(res){
            
        })
    }

    render() {
        return (
            <div className="modal" id="reset-store-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflowY:'auto',display:'block'}}>
                <div className="modal-dialog" style={{width:'auto'}}>
                    <div className="modal-content">
                        <div className="modal-header flex-column">
                            CHEQUE PRINT SETTINGS
                        </div>
                        <div className="modal-body">
                            
                           
                            <div className="row border-bottom">
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <p style={{margin:'0px'}}>CHEQUE NAME MARGIN</p>
                                </div>
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>TOP</label>
                                            <div className="input-group w-100px">
                                                <input id="name_top" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.name_top}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>LEFT</label>
                                            <div className="input-group w-100px">
                                                <input id="name_left" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.name_left}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <p style={{margin:'0px'}}>CHEQUE AMOUNT IN WORDS MARGIN</p>
                                </div>
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>TOP</label>
                                            <div className="input-group w-100px">
                                                <input id="amount_words_top" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.amount_words_top}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mrbtm-10">
                                            <label>LEFT</label>
                                            <div className="input-group w-100px">
                                                <input id="amount_words_left" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.amount_words_left}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <p style={{margin:'0px'}}>CHEQUE DATE MARGIN</p>
                                </div>
                                <div className="col-md-6 mrbtm-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>TOP</label>
                                            <div className="input-group w-100px">
                                                <input id="date_top" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.date_top}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIGHT</label>
                                            <div className="input-group w-100px">
                                                <input id="date_right" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.date_right}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <p style={{margin:'0px'}}>CHEQUE AMOUNT IN NUMERIC MARGIN</p>
                                </div>
                                <div className="col-md-6 mrbtm-10 mrtop-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>TOP</label>
                                            <div className="input-group w-100px">
                                                <input id="amount_numeric_top" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.amount_numeric_top}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>RIGHT</label>
                                            <div className="input-group w-100px">
                                                <input id="amount_numeric_right" type="text" className="form-control no-border-right cheque-settings" defaultValue={this.state.cheque_print_settings.amount_numeric_right}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">px</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                        <div className="modal-footer justify-content-center" >
                        
                            <button type="button" className="btn btn-secondary pull-left" onClick={this.handleClosePopup}>Close</button>
                    
                    
                            <button type="button" id="save-chq-setting" className="btn btn-success pull-right" onClick={this.handleSaveSetting}>Save</button>
                            <button type="button" id="save-chq-setting-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            
                        </div>
                    </div>
                </div> 
            </div> 
        );
    }
    handleSaveSetting = (e) =>{
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let account_id = this.props.account_data.actid;
        let qs = document.querySelectorAll('.cheque-settings');
        let cheque_print_settings = {};
        for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            let id = element.id;
            let value = element.value;
            cheque_print_settings[id] = value;
        }
        
        //this.handleClosePopup();

        let body = {};
        body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                account_id : account_id,
                data : cheque_print_settings,
           }
        let api = app_api+'/save_cheque_settings';
        axios.post(api, body)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            this.handleClosePopup();
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    handleClosePopup = () =>{
        if(document.getElementById("handleCloseChequeSettings")){
            document.getElementById("handleCloseChequeSettings").click();
        }
    }
}