import React, { Component } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import utils from '../utils.js';
let {app_api} = require('../library.js');
export default class SignUp extends Component {
	 constructor() {
	    super();
	    this.state = {
	      Name: '',
	      Email: '',
	      Username: '',
	      Password: '',
	      Storename: ''
	    }
		this.Name = this.Name.bind(this);
		this.Email = this.Email.bind(this);
		this.Username = this.Username.bind(this);
	    this.Password = this.Password.bind(this);
	    this.Storename = this.Storename.bind(this);
		this.Register = this.Register.bind(this);
    this.uuid = utils.uuid.bind(this);
	}

  Name(event) {
    this.setState({ Name: event.target.value })
  }
  Email(event) {
    this.setState({ Email: event.target.value })
  }
  Username(event) {
    this.setState({ Username: event.target.value })
  }
  Password(event) {
    this.setState({ Password: event.target.value })
  }
  Storename(event) {
    this.setState({ Storename: event.target.value })
  }

	Register(event) {
    let thisid = event.currentTarget.id;
    document.getElementById(thisid).classList.add("hide");
    document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    let uuid = this.uuid();
    var body = {
      name: this.state.Name,
      email: this.state.Email,
      username: this.state.Username,
      password: this.state.Password,
      store_name : this.state.Storename,
      uuid :uuid
    };

    axios.post(app_api+'/store', body)
      .then(function (response) {
       localStorage.setItem('user', JSON.stringify(response.data));
       window.location = "/billings";
    }).catch(function(res){
        let error  = res.response.data;
        for(var key in error) {
          if(document.getElementById(key)){
            document.getElementById(key).innerHTML = error[key][0];
          }
        } 
        document.getElementById(thisid).classList.remove("hide");
        document.getElementById(thisid+'-btn-spinner').classList.add("hide");
    })
}


    render() {
        return (
            <form>
                <h3>Sign Up</h3>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" onChange={this.Name} className="form-control" placeholder="Enter your name" />
                    <p className="error" id="name"></p>
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input type="email" onChange={this.Email} className="form-control" placeholder="Enter email" />
                    <p className="error" id="email"></p>
                </div>

                <div className="form-group">
                    <label>Username</label>
                    <input type="text" onChange={this.Username} className="form-control" placeholder="Enter username" />
                     <p className="error"  id="username"></p>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" onChange={this.Password} className="form-control" placeholder="Enter password" />
                    <p className="error" id="password"></p>
                </div>

                <div className="form-group">
                    <label>Store Name</label>
                    <input type="text" onChange={this.Storename} className="form-control" placeholder="Enter store name" />
                    <p className="error"  id="store_name"></p>
                </div>
                <button type="button" id="register-btn-spinner" className="hide btn btn-primary btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                <button id="register" onClick={this.Register} type="button" className="btn btn-primary btn-block">Sign Up</button>
                <p className="forgot-password text-right">
                    Already registered <a href="/login">sign in?</a>
                </p>
            </form>
        );
    }
}