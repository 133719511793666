import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Tooltip from "./tooltip";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch,faCaretLeft,faCaretRight,faSpinner} from '@fortawesome/free-solid-svg-icons';
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import SearchInventoryItems from './search-inventory-items.js';
import Autocomplete from "./autocomplete";
import BarcodeTemplate from "./settings/barcode-template";

let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
let productList = [];
export default class Inventory extends Component {
    constructor() {
      super();
      this.state = {
          loading_process :'Loading...',
          number_of_row :2,
          prev_link :'',
          next_link: '',
          current_page : '',
          total_page : '',
          page_msg : '',
          current_url : '',
          prev_url : '',
          inventory_list : [],
          is_barcode_tab : false,
          item_options:[],
          barcode_template_data:[],
          barcode_template:[],
          active_tab:'active',
          inventory_search_items : [],
          search_count:0,
          active_count : 0,
          draft_count : 0,
      };
      _this = this;
      this.inventory_search_items = [];
      this.searched_ids=[];
      this.barcode_template = [];
      this.is_access = check_access.is_access.bind(this);
      this.closeModel = this.closeModel.bind(this);
      this.handleInventorySearch = this.handleInventorySearch.bind(this);
      this.openPrintPreview = this.openPrintPreview.bind(this);
      this.handlePrint = this.handlePrint.bind(this);
    }

    handleInventorySearch(e){
      let item_name = document.getElementById("inventory-search-box").value;
      this.inventory_search_items = [];
      this.setState({inventory_search_items:this.inventory_search_items});
      this.setState({search_count:1});
      this.setState({loading_process:'Loading...'});
      this.searched_ids = [];
      if(item_name!==""){
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              item_name : item_name,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/inventory/search_item';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            
            if(cursor.length>0){
              for(let i=0;i<cursor.length;i++){
                  let item = cursor[i];
                  
                  if(this.searched_ids.indexOf(item.inventory_id)<0){
                    this.searched_ids.push(item.inventory_id);
                    this.inventory_search_items.push(<SearchInventoryItems inventory={item} count={cursor.length}/>);
                  }
              }
            }else{
              this.setState({loading_process:'No Inventory found.'});
            }
            this.setState({inventory_search_items:this.inventory_search_items});
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }else{
        this.setState({search_count:0});
      }
    }


    handlePrint(e){
            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page {margin: 0;}.blbl{text-transform: uppercase;font-size: 11px;}.blbl.company_name{font-size:12px;font-weight:bold}</style>'); 
            a.document.write('</head>'); 
            
            a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
            
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 
    closeModel(event) {
      var element = document.getElementById('barcode-print-preview');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    openPrintPreview(event) {
      let thisid = event.currentTarget.id;
      /*document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      */
      let barcode_template_data = this.state.barcode_template_data;
      let height = barcode_template_data[0].height;
      let width = barcode_template_data[0].width;
      let values = barcode_template_data[0].value;

      let name = this.state.barcode_template_data[0].name;

      let prows = document.querySelectorAll(".barcode-list");
      let template = '';
      this.barcode_template = [];
      for(let r=0 ; r<prows.length; r++){
        let id = prows[r].getAttribute("data-id");
        let billing_data = this.state.item_options[id];
        let quantity = document.getElementById("no-of-print-"+id).value;
        if(quantity!==""){
          quantity = parseInt(quantity);
          for(let q=0;q<quantity;q++){
            this.barcode_template.push(<BarcodeTemplate  barcode_template_data={barcode_template_data} id={q} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width}/>);
          } 
          this.setState({barcode_template:this.barcode_template});
        }
      }

      var element = document.getElementById('barcode-print-preview');
      element.classList.remove("fade");
      element.style.display="block"; 
      
     /* document.getElementById(thisid+'-spinner').classList.hide("hide");
      document.getElementById(thisid).classList.remove("hide");*/
    }

    componentDidMount(){
      let curl = window.location.href;
      let url = curl.split("/"); 
      let current_url = url[url.length - 1];
      let prev_url = url[url.length - 2];
      this.setState({current_url:current_url});
      this.setState({prev_url:prev_url});
      let cpage;
      let current_page;
      let body = {};
      const inventory = curl.match(/inventory$/i);
      const active_draft = curl.match(/inventory\/draft$/i);
      const print_barcode = curl.match(/inventory\/print-barcode$/i);
      const inventory_page = curl.match(/inventory\/([active|draft]+)\/page\/(\d+$)/i);
      
      let act_tab = 'active';
      if(active_draft){
        this.setState({active_tab:'draft'});
        act_tab = 'draft';
      }else if(inventory_page){
        this.setState({active_tab:inventory_page[1]});
        act_tab = inventory_page[1];
      }

      if(print_barcode){
        this.setState({is_barcode_tab:true});
        
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
             }
          let api = app_api+'/inventory/get_barcode_products';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              let item_options = [];
              
              cursor.map( (value, key) => {
                  let id = value.inventory_id;
                  let status = value.inventory_status;
                  let stat_txt = '';
                  if(status==='Draft'){
                    stat_txt = ' (Invactive)';
                  }
                  let item_name = value.item_name+" - Qty:"+value.availability+stat_txt;
                  let sku   = value.sku; 
                  let category = value.category;
                
                  if(sku===null){
                    sku = "none";
                  }
                  let barcode_name = value.barcode_name;
                  if(barcode_name===null){
                      barcode_name = value.item_name;
                  }

                  item_options[id] = {value1:sku,value2:item_name,item_name:value.item_name,sku:sku,category:category,availability:value.availability,varient:value.varient,inventory_status:value.inventory_status,item_desc:value.item_desc,purchase_price:value.purchase_price,selling_price:value.selling_price,custom_text:value.custom_text,barcode_name:barcode_name};
              });
              
              this.setState({ 'item_options': item_options}); 
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
          api = app_api+'/get_default_barcode_template';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              
              this.setState({ 'barcode_template_data': cursor}); 
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
         
      }


      if(inventory || active_draft){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/inventory/'+act_tab+'/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            type : act_tab,
            target : 'product',
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      }else if(inventory_page){
        
        cpage = parseInt(inventory_page[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/inventory/'+act_tab+'/page/'+next;
        let prev_link = '/inventory/'+act_tab+'/page/'+prev;
        if(prev===1){
          prev_link = '/inventory';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
        body = {
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            type : act_tab,
            target : 'product',
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      }
      if(print_barcode===null){
        let api = app_api+'/inventory/page';
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
            if(response.data.is_empty){
              _this.setState({loading_process:'Record(s) not found'});
              _this.setState({current_page:0});
              _this.setState({total_page:0});
            }else{
              _this.setState({total_page:page_count});
              _this.setState({active_count:response.data.active_count});
              _this.setState({draft_count:response.data.draft_count});
              _this.setState({ 'inventory_list': response.data.data });
              if(cpage===page_count){
                _this.setState({next_link:'#'});
              }
            }
        }).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
      }
    } 
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-3">
                    {(this.state.is_barcode_tab===false) && 
                        <div className="mod-title"><span>Products</span></div>
                    }
                    {(this.state.is_barcode_tab===true) && 
                        <div className="mod-title"><span>Barcode Print</span></div>
                    }
                    </div>
                    <div className="col-md-4 inventory-search">
                      {(this.state.is_barcode_tab===false) && 
                        <div className="mrtop-10">
                          <input type="text" onKeyUp={this.handleInventorySearch} id="inventory-search-box" className="form-control" placeholder="Type to search..."/>
                        
                        </div>
                      }
                      {(this.state.is_barcode_tab===true) && 
                        <div className="input-group mrtop-10">
                          <Autocomplete
                            options={this.state.item_options}
                            
                            data_target="barcode"
                            title="Start typing SKU or name..."  
                            id={"item-name-0"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab="inventory"
                            input_id={"item-box-0"}
                            target="item-box"
                            clsName = ""
                          />
                         
                        </div>
                      }
                    </div>
                    <div className="col-md-5 inven-btn">
                      <div className="pull-right">
                          <Notification />
                          {(this.state.is_barcode_tab===false) && 
                            <>
                              {this.is_access('inventory-create') &&
                                <a href="/new-billing/2"><button type="button" className="btn btn-success pull-right nav-btn">Add product</button></a>
                              }
                              <a href="/inventory/print-barcode"><button type="button" className="btn btn-success nav-btn">Print Barcode</button></a>
                            </>
                          }
                          {(this.state.is_barcode_tab===true) && 
                            <>
                            <button type="button" onClick={this.openPrintPreview} id="show-template-btn" className="btn btn-success nav-btn hide">Print Barcode</button>
                            <button type="button" id="show-template-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </>
                          }
                      </div>
                    </div>
                   
                  </div>
                </nav>
                <div className="container-fluid">
                {(this.state.is_barcode_tab===false && this.state.search_count===0) && 
                <div className="nav-container">
                    <ul className="nav nav-pills">
                    
                      <li className={this.state.active_tab==='active'?'active':''}><a href="/inventory">Active ({this.state.active_count})</a></li>
                     
                      <li className={this.state.active_tab==='draft'?'active':''}><a href="/inventory/draft">Invactive ({this.state.draft_count})</a></li>
                    
                    </ul>
                    
                  </div>
                }
                  <div className="content-area">
                     <div className="main-content">
                        {(this.state.is_barcode_tab===false && this.state.search_count===0) && 
                        <>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">SKU</th>
                            <th scope="col">STOCK</th>
                            <th scope="col">VARIENT</th>
                            
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                           {(_this.state.inventory_list.length > 0) ?_this.state.inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                              
                              <td><Link link={'/view-inventory/'+value.inventory_id} name={value.item_name} /></td>
                              <td>{value.category}</td>
                              <td>{value.sku}</td>
                              <td>{value.availability}</td>
                              <td>{value.varient}</td>
                              
                              <td>

                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/view-inventory/"+value.inventory_id} edit={"/edit-inventory/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'inventory'}}  item_count={_this.state.inventory_list.length}/>

                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan="7" className="text-center">{_this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      <div className="page-nav">
                        <div className="navd">
                          <a href={_this.state.prev_link}>
                            <div className="navd">
                              <FontAwesomeIcon className="cont" icon={faCaretLeft}/>
                            </div>
                          </a>
                        </div>
                        <div className="navd center"><p className="msg">{_this.state.current_page} of {_this.state.total_page}</p></div>
                        <div className="navd">
                          <a href={_this.state.next_link}>
                            <div className="navd">
                              <FontAwesomeIcon  className="cont" icon={faCaretRight}/>
                            </div>
                          </a>
                        </div>
                      </div>
                      </>
                    }
                    {(this.state.search_count>0) &&
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">SKU 1</th>
                            <th scope="col">STOCK</th>
                            <th scope="col">VARIENT</th>
                            <th scope="col">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                            {(this.state.inventory_search_items.length>0) &&
                              <>
                                {this.state.inventory_search_items}
                              </>
                            }
                            {(this.state.inventory_search_items.length===0) &&
                              <tr>
                                <td className="text-center" colSpan="7">{this.state.loading_process}</td>
                              </tr>
                            }

                        </tbody>
                      </table>
                    }
                    {(this.state.is_barcode_tab===true) && 
                      <>
                     
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">SKU</th>
                            <th scope="col">STOCK</th>
                            <th scope="col">VARIENT</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">Qty</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody id="barcode-print-list">
                          <tr><td colSpan="7" className="text-center">Select Item form above box to generate barcode</td></tr>
                        </tbody>
                        <tfoot className="hide" id="total-bp-foot">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th colSpan="2">Total: <label id="barcode-print-total"></label></th>
                          
                        </tfoot>
                      </table>

                      <div id="barcode-print-preview" className="modal fade"  style={{overflowY:'scroll'}}>
                        <div className="modal-dialog" style={{textAlign:'center'}} >
                          <div className="modal-content">
                           <div className="modal-header bg-none">
                              <div className="row">
                                <div className="col-md-4 mrtop-5">
                                  <h4 style={{color:'black',textTransform:'uppercase'}}>
                                  {(this.state.barcode_template_data && this.state.barcode_template_data[0] && this.state.barcode_template_data[0].name) &&
                                    this.state.barcode_template_data[0].name
                                  }
                                  </h4>
                                </div>
                             
                                <div className="col-md-8">
                                  <button type="button" onClick={this.handlePrint} className="btn btn-success pull-right mrleft-5">Print</button>
                                  <button type="button" onClick={this.closeModel} className="btn btn-secondary pull-right">Cancel</button>
                                </div>
                              </div>
                            </div>
                         
                            <div className="modal-body" style={{padding:'15px',background:'#dedede'}} id="main-container" >
                                {this.barcode_template}
                            </div>
                          </div>
                        </div>
                      </div>  
                      </>
                    }
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}