import React, { Component } from "react";
import {Document, Page, View,Text,StyleSheet  } from '@react-pdf/renderer';
export default class PDFPoweredBy extends Component {
    constructor() {
      super();
      this.state = {
      };
    }
    render() {

        return (
            
            <View style={{textAlign:'center',paddingTop:'10pt'}}>
                <Text style={{fontStyle:'italic',fontSize:'10pt'}}>
                    Powered by BaazarERP.com
                </Text>
            </View>
		  
        );
    }
}