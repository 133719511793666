import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ClosedFYButton from "./closed-fy-button";
import axios from 'axios';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class BulkAction extends Component {
    constructor() {
      super();
      this.state = {
        active_type : 'regular',
        is_fy_closed : false,
      };
      
      this.handleBulkOption = this.handleBulkOption.bind(this);
      _this = this;
    }
    componentDidMount(){
      let curl = window.location.href;
      const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);

      let type = 'regular';
      if(trash_hold){
         type = trash_hold[2];
      }else if(trash_hold_page){
         type = trash_hold_page[2];
      }
      this.setState({active_type:type});
      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          keys:"'is_financial_year_closed'",
          uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      })
    }
    handleBulkOption(e){
      let selected = document.getElementById("bulk-option").value;
      var cb = document.querySelectorAll('.sub-checkbox'); 
      let bulk_ids = [];
      let count = 0;
      for (var i = 0; i < cb.length; i++) { 
          let checked = cb[i].checked; 
          let value = cb[i].value; 
          if(checked){
            count++;
            bulk_ids[i] = value;

            let element = document.getElementById("billing-row-"+value);
            let col_span = element.getAttribute('data-id');
            let str = '';
            if(selected==='restore'){
              str = '<td class="row-restore" colspan="'+col_span+'">Restored Successful</td>';
            }else if(selected==='delete'){
              str = '<td class="row-trash" colspan="'+col_span+'">Deletion Successful</td>';
            }
            element.innerHTML = str;
          }
      }
      let data_target = this.props.data_target;
      data_target = selected+'_'+data_target;

      if(count>0){
        let body = {  
            id : bulk_ids,
            value : 'billing-'+this.state.active_type,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
         
        let api = app_api+'/'+data_target;
        axios.post(api, body)
          .then(function (response) {
           
            window.location.reload();
            
        }).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
      }
    }
    render() {
        return (
           <>
            <div className="bulk-option">
              <div className="row">
                <div className="col-md-8">
                  <select className="form-control" id="bulk-option">
                    <option value="">Select</option>
                    <option value="restore">Restore</option>
                    <option value="delete">Delete</option>
                  </select>
                </div>
                <div className="col-md-4">
                {(this.state.is_fy_closed===false) &&
                  <button type="button" className="btn btn-success" onClick={this.handleBulkOption}>Done</button>
                }
                {(this.state.is_fy_closed===true) &&
                  <ClosedFYButton className=""  label="Done" type="button"/>
                }
                </div>
              </div>
            </div>
          </>
        );
    }
}