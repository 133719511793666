import React, { Component } from "react";
import axios from 'axios';
import utils from '../utils.js';
let {app_api,user_info} = require('../library.js');
export default class ContactSelect extends Component {
    constructor(props) {
      super();
      this.state = {
        transport_list : [],
        default:0,
      };
    }
    handleContactChange = (e)=>{
        let def_con = e.currentTarget.value;
        if(def_con===""){
            def_con = 'none';
        }
        this.setState({default:def_con});
    }
    componentDidMount(){
        let body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            contact_type : this.props.type,
        }
        let api = app_api+'/get_contact';
        axios.post(api, body)
            .then(function (response) {
                let resp_data = response.data;
                this.setState({transport_list:resp_data})
        }.bind(this)).catch(function(res){
            if(res.response){
                //this.setState({loading_process:'Record(s) not found'});
            }
        })
      }
    render() {
        return (
           <select className={"form-control "+this.props.className} name={(this.props.name)?this.props.name:''} target={this.props.target} value={(this.state.default)?this.state.default:this.props.defaultValue} tabindex={this.props.tabindex} id={this.props.id} onChange={this.handleContactChange}>
               <option value="">{this.props.label}</option>
               {(this.state.transport_list!==undefined && this.state.transport_list.length>0) &&
                    <>
                        {
                        this.state.transport_list.map((v,i)=>(
                            <option value={v.contact_id}>{v.name}</option>
                        ))
                        }
                    </>
               }
           </select>
        );
    }
}