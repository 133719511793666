import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBell,faClock} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../utils.js';
import check_access from './access-level.js';
import StockTransferNotification from "./stock-transfer-notification.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class Notification extends Component {
    constructor() {
        super();
        this.state = {
            notifications : [],
            notif_count : 0,
        };
        this.handleReadNotification = this.handleReadNotification.bind(this);
        this.ToggleNotification = this.ToggleNotification.bind(this);
        this.handleNotifRefresh = this.handleNotifRefresh.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    ToggleNotification(e){
          document.getElementById("notif-list").classList.toggle("hide");
    }
    handleReadNotification(e){
        let thisid = e.currentTarget.id;
        let data_url = e.currentTarget.getAttribute('data-url');
        if(data_url.indexOf('http://')>=0){
          data_url = data_url.replace('http://','');
          data_url = data_url.replace('//','/');
          data_url = 'http://'+data_url;
        }else{
          data_url = data_url.replace('https://','');
          data_url = data_url.replace('//','/');
          data_url = 'https://'+data_url;
        }
        let notif_count = document.getElementById("notif-count").innerHTML;
        let is_unread = false;
        if(e.currentTarget.classList.contains('unread')){
          is_unread = true;
          if(notif_count!==""){
            notif_count = parseInt(notif_count);
            if(notif_count>0){
              notif_count = notif_count-1;
              document.getElementById("notif-count").innerHTML=notif_count;
            }
          }
          e.currentTarget.classList.remove('unread');
        }

        document.getElementById("notif-list").classList.add("hide");
        if(is_unread){
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                id : thisid,
          }
          let api = app_api+'/read_notification';
          axios.post(api, body)
            .then(function (response) {
               
          }.bind(this)).catch(function(res){
             
          })
          window.open(data_url, "_blank");
        }else{
          window.open(data_url, "_blank"); 
        }
    }
    handleCloseNotification(){
      let element = document.getElementById('notif-list');
      let is_hide = element.classList.contains('hide');
      if(is_hide){
        
        //document.getElementById('notif-list').classList.add("hide");
      }
    }
    componentDidMount(){
        //document.addEventListener("click", this.handleCloseNotification);
         let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_notification';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let notif = resp.notifications;
              let count =0;
              for(let i=0;i<notif.length;i++){
                if(notif[i].status===0){
                    count++;
                }
              }
              this.setState({notifications:resp.notifications});
              this.setState({notif_count:count});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
        
    }
    handleNotifRefresh(e){
       let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_notification';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let notif = resp.notifications;
              let count =0;
              for(let i=0;i<notif.length;i++){
                if(notif[i].status===0){
                    count++;
                }
              }
              this.setState({notifications:resp.notifications});
              this.setState({notif_count:count});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }
    render() {
      return (
        <>
          {(this.state.notifications.length>0 && this.is_access('notifications')) &&
            <>
                <div id="notif-click" onClick={this.handleNotifRefresh}></div>
                <a className="app-notif">
                    <div onClick={this.ToggleNotification} className="notif-div">
                    <FontAwesomeIcon  icon={faBell} className=""/>
                    <span className="notif-count" id="notif-count">{this.state.notif_count}</span>
                    </div>
                    <ul className="list-group notif-list hide" id="notif-list">
                    {this.state.notifications.map( (value, index) => {
                      return (
                          <li onClick={this.handleReadNotification} id={value.notification_id} className={value.status===0?'list-group-item unread':'list-group-item'} data-url={value.url}>
                          
                            <p className="notif-msg">{value.message}</p>
                            <FontAwesomeIcon  icon={faClock}  className=""/><span className="time">{value.time_ago}</span>
                          
                          </li>
                        )
                      })}
                    </ul>
                </a>
            </>
          }
          <StockTransferNotification />
        </>
        );
    }
}