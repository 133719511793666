import React, {Component} from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import utils from '../utils.js';
let {app_api,enc_key,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
export default class Login extends Component {

    constructor() {
        super();
        this.state = {
          Username: '',    
          Password: '',
          Storename: '',
          isLoggedIn: false
        }
        
        this.Username = this.Username.bind(this);
        this.Password = this.Password.bind(this);
        this.Storename = this.Storename.bind(this);
        this.Login = this.Login.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.uuid = utils.uuid.bind(this);
    }
      componentDidMount(){

        /* store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        store_state : user_info.state,
        type : t,
        id : parseInt(id),
        action : 'view',
        uuid : utils.uuid(),
        role_id : user_info.access_level */

        if(user_info.store_name){
          let uuid = this.uuid();
          var body = {
            store_name : user_info.store_name,
            user_id : user_info.user_id,
            branch_id : user_info.login_id,
            role_id : user_info.access_level,
            uuid :uuid
          };
          axios.post(app_api+'/logout', body)
          .then(function (response) {
          }.bind(this)).catch(function(res){
            
          })
        }

        if(localStorage.getItem("store_name")){
          let store = JSON.parse(localStorage.getItem("store_name"));
          let bytes = CryptoJS.AES.decrypt(store, enc_key);
          let cval = bytes.toString(CryptoJS.enc.Utf8);
          let store_data = JSON.parse(cval);
          this.setState({Storename:store_data.store_name});
        }
       /* var DBDeleteReq = window.indexedDB.deleteDatabase("ERP"); 
        DBDeleteReq.onsuccess = function(event) { 
          console.log("Database deleted successfully"); 
        } 
        DBDeleteReq.onblocked = function(event) { 
          console.log("Database blocked"); 
        }   */
      }
      Username(event) {
        this.setState({ Username: event.target.value })
      }
      Password(event) {
        this.setState({ Password: event.target.value })
      }
      Storename(event) {
        this.setState({ Storename: event.target.value })
      }

    Login(event) {
        localStorage.removeItem('bc_log');
        let thisid = event.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let uuid = this.uuid();
        var body = {
          username: this.state.Username,
          password: this.state.Password,
          store_name : this.state.Storename,
          uuid :uuid
        };

        axios.post(app_api+'/login', body)
          .then(function (response) {
            let success = response.data.success;
            if(success===1){
              let store_name = {store_name:this.state.Storename};
              store_name = CryptoJS.AES.encrypt(JSON.stringify(store_name), enc_key).toString();
              localStorage.setItem('store_name',JSON.stringify(store_name));
              localStorage.setItem('user', JSON.stringify(response.data));
              window.location = "/billings";
            }else if(success===2){
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
              document.getElementById("password").innerHTML = 'Please enter a valid password';
            }else if(success===3){
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
              document.getElementById("username").innerHTML = 'Please enter a valid username';
            }else{
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
              document.getElementById("other-error").innerHTML = response.data.message;
            }
        }.bind(this)).catch(function(res){
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            if(res.response.status!==undefined && res.response.status===500){
              document.getElementById('store_name').innerHTML = 'Please enter a valid store name';
            }
            if(res.response){
                if(res.response.data){
                    let error  = res.response.data;
                    for(var key in error) {
                        if(document.getElementById(key)){
                          document.getElementById(key).innerHTML = error[key][0];
                        }
                    } 
                } 
            }
        })
        
    }
    handleKeyDown(e){
      if (e.keyCode === 13) {
        document.getElementById("login").click();
        
      }
    }
    render() {
        return (
            <form>
                <h3>Sign In</h3>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text"  onChange={this.Username}  onKeyDown={this.handleKeyDown} className="form-control" placeholder="Enter Username" autoFocus/>
                    <p className="error" id="username"></p>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password"  onChange={this.Password}  onKeyDown={this.handleKeyDown} className="form-control" placeholder="Enter Password" />
                    <p className="error" id="password"></p>
                </div>

                <div className="form-group">
                    <label>Store Name</label>
                    <input type="text"  onChange={this.Storename} onKeyDown={this.handleKeyDown} className="form-control" placeholder="Enter Store Name" defaultValue={this.state.Storename}/>
                    <p className="error" id="store_name"></p>
                </div>
                <p className="error" id="other-error"></p>
                <button type="button" id="login-btn-spinner" className="hide btn btn-primary btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                <button type="button" id="login" onClick={this.Login} className="btn btn-primary btn-block">Login</button>
                <p className="forgot-password text-right">
                    Forgot <a href="/forgot-pwd">password?</a>
                </p>
            </form>
        );
    }
}