import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faEnvelope,faPrint,faEye,faWrench,faTrash  } from '@fortawesome/free-solid-svg-icons';
import ModalBox from "./modal-box";
import ProductUnits from "./product-units";
import ServiceLoader from './service-loader.js';
import utils from '../utils.js';
import check_access from './access-level.js';
import CRDRPrint from "./cr-dr-print";
let {enc_key,app_api,user_info} = require('../library.js');
export default class ViewCreditDebit extends Component {
    constructor() {
        super();
        this.state = {
            is_print : false,
            show_loader : true,
            currrent_sequence:'',
            payment_info : [],
            contact_info : [],
            payment_date : '',
            issued_by:'',
            update_id:'',
            paid_amount :'',
            type : '',
            data_info : '',
            invoice_info : '',
            return_info : '',
            redeem_meta : '',
            discount_sym:'',
            target:'',
            return_type :'',
            bill_type :'',
        };
        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    
    
    componentDidMount(){
     
      let curl = window.location.href;
     
      const view_credit_debit = curl.match(/view-credit-debit\/([a-z_A-Z]+)\/(\d+$)/i);
      if(view_credit_debit){ // EDIT MODE
          const id = view_credit_debit[2];
          this.setState({update_id:id});
           let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                id : parseInt(id),
             }
          let api = app_api+'/get_debit_credit_note_on_id';
          axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
                let data = resp.data;
                let return_data = resp.return_data;
                let invoice_data = resp.invoice_data;
                let redeem_meta = resp.redeem_meta;
                
                if(data===undefined){
                  window.location.href = '/404';
                }
                this.setState({data_info:data});
                this.setState({show_loader:false});
                if(return_data.global_discount_sym && return_data.global_discount_sym==='per'){
                  let gdisc = return_data.global_discount;
                  let gdisc_sym = return_data.global_discount_sym;
                  let sub_total = parseFloat(return_data.sub_total.replace(/,/g , ""));
                  let disc = 0;
                  if(return_data.discount!==undefined){
                    parseFloat(return_data.discount.replace(/,/g , ""));
                  }
                  let stotal = sub_total-disc;
                  let gamount = stotal*gdisc/100;
                  return_data.global_discount_amount = gamount;
                }
                if(invoice_data.global_discount_sym && invoice_data.global_discount_sym==='per'){
                  let gdisc = invoice_data.global_discount;
                  let gdisc_sym = invoice_data.global_discount_sym;
                  let sub_total = parseFloat(invoice_data.sub_total.replace(/,/g , ""));
                  let disc = 0;
                  if(invoice_data.discount!==undefined){
                    parseFloat(invoice_data.discount.replace(/,/g , ""));
                  }
                  let stotal = sub_total-disc;
                  let gamount = stotal*gdisc/100;
                  invoice_data.global_discount_amount = gamount;
                }

                this.setState({return_info:return_data});
                this.setState({invoice_info:invoice_data});
                this.setState({redeem_meta:redeem_meta});
                let sequence_number = data.sequence_number;
                this.setState({currrent_sequence:sequence_number});
                let type = data.type;
                if(type==='sales_return_credit'){
                  this.setState({type:'Credit Note'});
                  this.setState({target:'Sales Return'});
                  this.setState({return_type:'sales_return'});
                  this.setState({bill_type:'sales'});
                }else if(type==='sales_credit'){
                  this.setState({type:'Credit Note'});
                  this.setState({target:'Sales'});
                  this.setState({return_type:'sales'});
                  this.setState({bill_type:'sales'});
                }else if(type==='purchase_debit'){
                  this.setState({type:'Debit Note'});
                  this.setState({target:'Purchase'});
                  this.setState({return_type:'purchase'});
                  this.setState({bill_type:'purchase'});
                }else{
                  this.setState({type:'Debit Note'});
                  this.setState({target:'Purchase Return'});
                  this.setState({return_type:'purchase_return'});
                  this.setState({bill_type:'purchase'});
                }

          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
      }
    }

   
   
    render() {
        
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-5">
                        <div className="mod-title">
                          <a href="/credit-debit-note"><span>Credit Debit Note List</span></a> / <a href={"/credit-debit-note/"+this.state.type.replace(' Note','').toLowerCase()}>
                            <span>{this.state.type}</span>
                          </a> / <b id="breadcrumbs_subtitle">View {this.state.type}</b>
                        </div>

                        
                    </div>
                     <div className="col-md-2 text-right  mrtop-15">
                       
                    </div>
                    <div className="col-md-5 mrtop-15 action-area text-right">
                      <div className="e-p-p-opt div-display">
                        <div className="eppopt">
                          <div className="share-opt-blk">
                              <div className="share-dd">
                                  <div className="share-dd-clk" id='view-print' onClick={this.handlePrint}>Print</div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </nav>
                <div  id="main-container">
                <ServiceLoader show={this.state.show_loader}/>
                <div className="container-fluid">
                  <div className="content-area">
                     
                     <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5 mob-content">
                            <label className="bil_no">{this.state.type} {this.state.currrent_sequence}</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center mob-content">
                            <label className="bill-amount">{this.state.type} Amount : {(this.state.data_info && this.state.data_info.amount)?'₹'+this.currency_format(this.state.data_info.amount.replace(/,/g , "")):0}</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-right mob-content">
                            <label className="bil_no" style={{color:(this.state.data_info.status==='Active')?'#12d712':'red',margin:'0px'}}>{(this.state.data_info && this.state.data_info.status)?this.state.data_info.status:'-'}</label>
                            {(this.state.data_info && this.state.data_info.redeem_date && this.state.data_info.redeem_date!=="") &&
                              <p style={{margin:'0px',fontSize:'14px'}}>On : {this.state.data_info.redeem_date}</p>
                            }
                        </div> 
                       </div>
                       {(this.state.data_info.contact_info && this.state.data_info.contact_info[0]!==undefined && this.state.data_info.contact_info[0].name) && 
                       <div className="row mrbtm-12">
                          <div className="col-md-12">
                          
                              <h4 className="title">{(this.state.data_info.contact_info && this.state.data_info.contact_info[0]!==undefined && this.state.data_info.contact_info[0].name)?this.state.data_info.contact_info[0].name:''}</h4>

                              {(this.state.data_info.contact_info &&  this.state.data_info.contact_info[0]!==undefined && this.state.data_info.contact_info[0].address_1!==undefined && this.state.data_info.contact_info[0].address_1!=='')?<p style={{margin:'0px'}}>{this.state.data_info.contact_info[0].address_1}</p>:''}
                              <p style={{margin:'0px'}}>
                                {(this.state.data_info.contact_info &&  this.state.data_info.contact_info[0]!==undefined &&  this.state.data_info.contact_info[0].city && this.state.data_info.contact_info[0].city!=='')?this.state.data_info.contact_info[0].city:''}
                                {(this.state.data_info.contact_info &&  this.state.data_info.contact_info[0]!==undefined && this.state.data_info.contact_info[0].pin && this.state.data_info.contact_info[0].pin!=='')?'-'+this.state.data_info.contact_info[0].pin:''}
                              </p>
                              <p style={{margin:'0px'}}>
                                {(this.state.data_info.contact_info && this.state.data_info.contact_info[0]!==undefined &&  this.state.data_info.contact_info[0].phone_1 && this.state.data_info.contact_info[0].phone_1!=='')?this.state.data_info.contact_info[0].phone_1:''}
                              </p>
                              <p style={{margin:'0px'}}>
                                {(this.state.data_info.contact_info && this.state.data_info.contact_info[0]!==undefined &&  this.state.data_info.contact_info[0].email && this.state.data_info.contact_info[0].email!=='')?this.state.data_info.contact_info[0].email:''}
                              </p>
                              <p style={{margin:'0px'}}>{(this.state.data_info.contact_info &&  this.state.data_info.contact_info[0]!==undefined && this.state.data_info.contact_info[0].gst_number && this.state.data_info.contact_info[0].gst_number!=='')?"GSTIN :"+this.state.data_info.contact_info[0].gst_number:''}</p>
                            
                          </div>
                       </div>
                     }
                     </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="content-area">
                    <div className="border-bottom">
                      <div className="row" style={{padding:'20px 35px'}}>
                        <div className="col-md-4 mrtop-5 mob-content">
                              <label className="bil_no">Invoice Number : <a href={"/view-billing/"+this.state.return_type+"/"+this.state.return_info.id} target="_blank">{(this.state.return_info && this.state.return_info.number)?this.state.return_info.number:''}</a></label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center mob-content">
                            <label className="bill-amount">Grand Total : ₹{(this.state.return_info && this.state.return_info.total)?this.state.return_info.total:0}</label>
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right mob-content">
                            <label className="bil_no" style={{color:(this.state.return_info.bill_status==='Paid')?'#12d712':'red',margin:'0px'}}>{(this.state.return_info && this.state.return_info.bill_status)?this.state.return_info.bill_status:''}</label>
                            {(this.state.return_info && this.state.return_info.created_date && this.state.return_info.created_date!=="") &&
                              <p style={{margin:'0px',fontSize:'14px'}}>On : {this.state.return_info.created_date}</p>
                            }
                        </div>
                      </div>
                    </div>
                    {(this.state.redeem_meta && this.state.redeem_meta.length>0) &&
                      <div className="content-head border-bottom">
                        <div className="row mrbtm-12">
                          <div className="col-md-12 mrtop-5">
                              <label className="bil_no">Redeem Details</label>
                          </div>
                        </div>
                      </div>
                    }
                      <div className="row" style={{padding:'5px 35px'}}>
                          {(this.state.redeem_meta && this.state.redeem_meta.length===0) &&
                            <>
                            {(this.state.invoice_info  && this.state.invoice_info.number) &&
                              <>
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                            <table className="table billing-total">
                              <thead className="thead-light">
                                <tr>
                                  <th colSpan="2" style={{textTransform:'uppercase',background: '#03a9f4',color: 'white',fontSize: '16px'}}>Redeemed {this.state.bill_type} Overview</th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>
                                        <h5 style={{textTransform:'capitalize'}}>
                                          Invoice Number
                                        </h5>
                                      </td>
                                      <td className="text-right">
                                        <a className="sp-normal all-out" href={"/view-billing/"+this.state.bill_type+"/"+this.state.invoice_info.id} target="_blank">
                                        <h5 style={{lineHeight:'normal',marginBottom:'0px'}}>
                                          {(this.state.invoice_info && this.state.invoice_info.number)?this.state.invoice_info.number:''}
                                        </h5>
                                        </a>
                                        <b style={{color:(this.state.invoice_info.bill_status==='Paid')?'#12d712':'red'}}>{(this.state.invoice_info && this.state.invoice_info.bill_status)?this.state.invoice_info.bill_status:''}</b>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Total Units</span></td>
                                      <td className="text-right"><span className="sp-normal all-out" id="total-unit">{(this.state.invoice_info && this.state.invoice_info.total_unit)?this.state.invoice_info.total_unit:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal" style={{fontWeight:'bold'}}>Subtotal</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total" style={{fontWeight:'bold'}}>{(this.state.invoice_info && this.state.invoice_info.sub_total)?this.state.invoice_info.sub_total:0}</span></td>
                                  </tr>
                                 
                                  <tr>
                                      <td><span className="sp-normal">Discount on Bill 
                                        {(this.state.invoice_info.global_discount_sym==='per') &&
                                          <>
                                             <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.state.invoice_info.global_discount_amount}
                                             </span>
                                          </>
                                        }
                                        </span>
                                        </td>
                                      <td className="text-right">
                                         
                                        <span className="sp-normal">
                                          {(this.state.invoice_info && this.state.invoice_info.global_discount) &&
                                            <>
                                              {(this.state.invoice_info.global_discount_sym==='rs') &&
                                              <>
                                              <span className="rs-sym">₹</span>{this.state.invoice_info.global_discount}
                                              </>
                                              }
                                              {(this.state.invoice_info.global_discount_sym==='per') &&
                                              <>
                                               {this.state.invoice_info.global_discount}%
                                               </>
                                              }
                                            </>
                                          }
                                        </span>

                                      </td>
                                  </tr>
                                  <tr>
                                    <td><span className="sp-normal">Total Before Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-tax">{(this.state.invoice_info && this.state.invoice_info.total_before_tax)?this.currency_format(this.state.invoice_info.total_before_tax):0}</span></td>
                                  </tr>
                                  <tr>
                                    <td><span className="sp-normal">Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-after-tax">{(this.state.invoice_info && this.state.invoice_info.tax)?this.state.invoice_info.tax:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" style={{fontWeight:'bold'}}>{(this.state.invoice_info && this.state.invoice_info.calculate_total)?this.currency_format(this.state.invoice_info.calculate_total):0}</span></td>
                                  </tr>
                                  
                                  <tr>
                                      <td><span className="sp-normal">Round Off</span></td>
                                      <td className="text-right"><span className="sp-normal">{(this.state.invoice_info && this.state.invoice_info.round_off)?this.state.invoice_info.round_off_sym+''+this.state.invoice_info.round_off:''}</span></td>
                                  </tr>
                                  <tr style={{color:'red'}}>
                                      <td><span className="sp-normal">Total Before Redeem</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="before-redeem-total">{(this.state.invoice_info && this.state.invoice_info.before_payment_note_redeem_total)?this.currency_format(this.state.invoice_info.before_payment_note_redeem_total):''}</span>
                                      </td>
                                  </tr>
                                  <tr style={{color:'red'}}>
                                      <td><span className="sp-normal">Redeem Amount</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="redeem-total">{(this.state.invoice_info && this.state.invoice_info.payment_note_redeem_total)?this.currency_format(this.state.invoice_info.payment_note_redeem_total):''}</span>
                                      </td>
                                  </tr>
                                 
                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Grand Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="final-total"  style={{fontWeight:'bold'}}>{(this.state.invoice_info && this.state.invoice_info.total)?this.state.invoice_info.total:0}</span></td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-3"></div>
                          </>
                          }
                          </>
                          }

                    </div>
                    {(this.state.redeem_meta && this.state.redeem_meta.length>0) &&
                      <div className="" style={{padding:'5px 35px'}}>
                        {(this.state.redeem_meta && this.state.redeem_meta.length > 0) ?this.state.redeem_meta.map( (value, index) => {
                          return (
                              <div className="ret-meta">
                                  <a className="sp-normal all-out" href={"/view-billing/"+this.state.bill_type+"/"+value.invoice_id} target="_blank">
                                  <h5 style={{lineHeight:'normal',marginBottom:'0px'}}>
                                    {value.sequence}
                                  </h5>
                                  </a>
                                  <b style={{color:(value.bill_status===4)?'#12d712':'red'}}>{(value && value.bill_status)?'Paid':'Pending'}</b>
                                  <p style={{margin:'0px',fontSize:'14px'}}>Amount : ₹{(value.redeem_amount)}</p>
                                  {(value && value.redeem_date && value.redeem_date!=="") &&
                                    <p style={{margin:'0px',fontSize:'14px'}}>On : {value.redeem_date}</p>
                                  }
                              </div>
                          )
                        }) :  ''}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {(this.state.is_print===true) &&
              <CRDRPrint print_data={this.state.data_info} handleClosePrint={this.handleClosePrint.bind(this)} type={this.state.type} bill_type={this.state.bill_type}/>
            }
          </div>
        );
    }
    handlePrint = () =>{
      this.setState({is_print:true});
    }
    handleClosePrint = () =>{
      this.setState({is_print:false});
    }
}