import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';
import ContactSelect from "./contact-select-on-type";
import ContactsDropdown from "./contacts-dropdown";
import Autocomplete from "./autocomplete";
import DrawerLink from "./open-drawer-link";
let {enc_key,app_api,user_info,states} = require('../library.js');
export default class CreateTransport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referer :'transport',
            transportDate:  new Date(),
            receivedDate: null,
            party_list : [],
            party_invoice_list : [],
            lr_error : '',
            lr_error_info : {},
            purchase_id : '',
            transport_to : user_info.city,
            dispatched_data : [],
            is_loading_invoice : false
        };
        let tr_data = {};
    }
    handleChangePurchaseNo = (e)=>{
        let thisval = e.currentTarget.value;
        this.setState({purchase_id:thisval});
        if(this.state.dispatched_data[thisval]){
        let dispatched_info = this.state.dispatched_data[thisval];
        let lr_no = dispatched_info.dispatched_document;
        let no_of_bale = dispatched_info.no_of_parcels;
        let no_of_parcels = dispatched_info.pieces_in_parcel;
        let supplier_ref_no = dispatched_info.supplier_ref_no;

        if(document.getElementById("lr_no")){
            document.getElementById("lr_no").value = lr_no;
        }
        if(document.getElementById("bale")){
            document.getElementById("bale").value = no_of_bale;
        }
        if(document.getElementById("no_of_parcel_received")){
            document.getElementById("no_of_parcel_received").value = no_of_parcels;
        }
        if(document.getElementById("b_no")){
            document.getElementById("b_no").value = supplier_ref_no;
        }
        }
    }
    handelChangeTransportDate = date => {
        this.setState({
            transportDate: date
        });
        document.getElementById("transport_from").focus();
      }; 
      handelChangeReceivedDate = date => {
        this.setState({
            receivedDate: date
        });
        document.getElementById("b_no").focus();
      };
      handleCloseSaveTransport = ()  =>{
        /* var element = document.getElementById('create-transport-pop');
        element.classList.add("fade");
        element.style.display="none";  */
        if(document.getElementById("hanldeCloseCreateTransPop")){
            document.getElementById("hanldeCloseCreateTransPop").click();
        }
        this.setState({lr_error:''});
        this.setState({lr_error_info:''});
      }
      handleSaveTransport = ()=>{
        if(this.state.lr_error!==""){
            document.getElementById("lr_no").focus();
            return false;
        }
        this.tr_data = {};
        let tr_data = document.querySelectorAll('.tr-fields');

        let party_name = document.getElementById("customer-dropdown").value;
        if(party_name===""){
            document.getElementById("contact_box").focus();
            return false;
        }
        let status = document.getElementById("tr-status").value;

        for (let index = 0; index < tr_data.length; index++) {
            const element = tr_data[index];
            let key = element.id;
            let value = element.value;
            this.tr_data[key] = value;
        }
        this.tr_data['party_name'] = party_name;
        this.tr_data['status'] = status;
        if(document.getElementById("p_no").value!==""){
            this.tr_data['is_purchase_bill'] = true;
        }
        let body = {};
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            transport_data : this.tr_data,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
        let api = app_api+'/create_transport';
        axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
                window.location.reload();
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
        this.handleCloseSaveTransport();
      }

      handleGetPartyInvoice = () =>{
        this.setState({is_loading_invoice:true});
        let curl = window.location.href;
        const is_order = curl.match(/order/i);
        let contact_id = localStorage.getItem('selected_contact');
        let is_edit_transport = localStorage.getItem('is_edit_transport');
        if(is_edit_transport!=='yes'){
            is_edit_transport = 'no';
        }
        let body = {};
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            contact_id : parseInt(contact_id),
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            is_edit_transport : is_edit_transport,
        }
        let api = app_api+'/get_transport_party_invoice';
        axios.post(api, body)
            .then(function (response) {
                this.setState({is_loading_invoice:false});
                let resp = response.data;
                let data = resp.data;
                this.setState({party_invoice_list:data});
                this.setState({dispatched_data:resp.dispatched_data});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }

      componentDidMount(){
        if(this.props.transport_data && this.props.transport_data.transport_to){
            this.setState({transport_to:this.props.transport_data.transport_to});
        }
        if(this.props.transport_data.transport_date){
            this.setState({transportDate:new Date(this.props.transport_data.transport_date)});
        }
        if(this.props.transport_data && this.props.transport_data.purchase_id){
            this.setState({purchase_id:this.props.transport_data.purchase_id});
        }
        if(this.props.transport_data.received_date){
            this.setState({receivedDate:new Date(this.props.transport_data.received_date)});
        }
      }

      handleCheckLRNo = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({lr_error:''});
        this.setState({lr_error_info:''});
        if(thisval!==""){
            if(this.props.transport_data.lr_no===thisval){
                return false;
            }
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                search : thisval,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            }
            let api = app_api+'/search_lr_no';
            axios.post(api, body)
                .then(function (response) {
                    let resp = response.data;
                    if(resp.is_exist===true){
                        let transport_info = resp.transport;
                        this.setState({lr_error_info:transport_info});
                      this.setState({lr_error:thisval+' is already taken, it is associated with transport '});
                    }else{
                        this.setState({lr_error:''});
                        this.setState({lr_error_info:''});
                    }
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                    }
                }
            })
        }else{
            this.setState({lr_error:''});
        }
      }

    render() {
        return (
            <div className="modal" id="create-transport-pop" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflow:'auto',display:'block'}}>
                <span id="handleGetPartyInvoice" onClick={this.handleGetPartyInvoice}></span>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title hbtitle">Save Transport</h5>
                        </div>
                        <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
            
                            <div className="content-head border-bottom">
                                <b>Transport Data</b>
                                <p className="info">Enter transport basic data here.</p>
                                <input type="hidden" className="form-control tr-fields" target="tr-id"   id="transport_id" defaultValue={(this.props.transport_data && this.props.transport_data.transport_id)?this.props.transport_data.transport_id:0}/>
                            </div>
                            <div className="main-content">
                            <div className="inner-content">
                                <div className="row">
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-date">Transport Date</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text no-border-right">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                </span>
                                                <DatePicker className="form-control tr-fields no-border-left" selected={this.state.transportDate} tabIndex={1}  onChange={this.handelChangeTransportDate} dateFormat="dd/MM/yyyy" target="tr-date" id="transport_date"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-party">Party Name</label>
                                        
                                        <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={(this.props.transport_data.c_info)?this.props.transport_data.c_info:[]} index="transport" contact_type="2" tabindex={4}/>

                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-p-no">Purchase No.</label>
                                        {(this.state.is_loading_invoice===false) &&
                                            <select className="form-control tr-fields" target="tr-p-no" id="p_no" value={this.state.purchase_id} onChange={this.handleChangePurchaseNo}>
                                                <option value="">SELECT PURCHASE NO</option>
                                                {(this.state.party_invoice_list.map((val)=>{
                                                    return (
                                                        <option value={val.id}>{val.sequence}</option>
                                                    )
                                                }))}
                                            </select>
                                        }
                                        {(this.state.is_loading_invoice===true) &&
                                            <div style={{border: '1px solid #d7d7d7',padding: '5px',borderRadius: '5px',textAlign: 'center'}}><FontAwesomeIcon icon={faSpinner} pulse/></div>
                                        }
                                        {/* <input type="text" className="form-control tr-fields" target="tr-p-no"  id="p_no"  defaultValue={(this.props.transport_data && this.props.transport_data.p_no)?this.props.transport_data.p_no:''} tabindex={14}/> */}
                                        {/* <Autocomplete
                                            options={[]}
                                            title="Type to search purchase no"
                                            data_target="bill_search_list"
                                            id="bill_search_list"
                                            className=" auto-comp-opt"
                                            clsName=""
                                            data_id='New Purchase Return'
                                            target='New Purchase Return'
                                            input_id='bill_search_list_box'
                                            item_info={(this.props.transport_data.purchase_info)?this.props.transport_data.purchase_info:[]}
                                            tabindex={14}
                                        />   */}
                                        
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-from">Transport From</label>
                                        <input type="text" className="form-control tr-fields" target="tr-from" id="transport_from" tabindex={2}  defaultValue={(this.props.transport_data && this.props.transport_data.transport_from)?this.props.transport_data.transport_from:''}/>
                                    </div>
                                    
                                   
                                </div>
                                <div className="row mrtop-10">
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-to">Transport To</label>
                                        <input type="text" className="form-control tr-fields" id="transport_to" target="tr-to" tabindex={3}  defaultValue={this.state.transport_to}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-lr-no">LR. No</label>
                                        <input type="text" className="form-control tr-fields" id="lr_no" target="tr-lr-no"  defaultValue={(this.props.transport_data && this.props.transport_data.lr_no)?this.props.transport_data.lr_no:''} tabindex={5} onChange={this.handleCheckLRNo}/>
                                        <p className="error" style={{margin:'0px',fontSize:'12px'}}>{this.state.lr_error} 
                                            {(this.state.lr_error!=="") &&
                                                <DrawerLink  target="transport" url={''} name={this.state.lr_error_info.transport_number} info={this.state.lr_error_info}/>
                                            }
                                        </p>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-bale">Bale</label>
                                        <input type="text" id="bale" className="form-control tr-fields" target="tr-bale"  defaultValue={(this.props.transport_data && this.props.transport_data.bale)?this.props.transport_data.bale:''} tabindex={6}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-name">Transport</label>
                                        <ContactSelect className="tr-fields" id="transport" target="tr-name" defaultValue={(this.props.transport_data && this.props.transport_data.transport_contact_id)?this.props.transport_data.transport_contact_id:''} tabindex={7} label="Select Transport" type="3"/>
                                        
                                    </div>
                                    
                                </div>
                                <div className="row mrtop-10">
                                <div className="col-md-3 w-50">
                                        <label htmlFor="tr-weight">Weight</label>
                                        <input type="text" className="form-control tr-fields" id="weight" target="tr-weight"  defaultValue={(this.props.transport_data && this.props.transport_data.weight)?this.props.transport_data.weight:''} tabindex={8}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-amount">Amount</label>
                                        <input type="text" className="form-control tr-fields" id="amount" target="tr-amount"  defaultValue={(this.props.transport_data && this.props.transport_data.amount)?this.props.transport_data.amount:''}  tabindex={9}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-rcvd-sign">Received Sign</label>
                                        <input type="text" className="form-control tr-fields" id="received_sign"  defaultValue={(this.props.transport_data && this.props.transport_data.received_sign)?this.props.transport_data.received_sign:''} target="tr-rcvd-sign" tabindex={10}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-rcvd-date">Received Date</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text no-border-right">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                </span>
                                                <DatePicker className="form-control tr-fields  no-border-left" selected={this.state.receivedDate}  onChange={this.handelChangeReceivedDate} dateFormat="dd/MM/yyyy" target="tr-rcvd-date" id="received_date" tabindex={11}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row mrtop-10 ">
                                <div className="col-md-3 w-50">
                                        <label htmlFor="tr-b-no">Supplier Reference No.</label>
                                        <input type="text" className="form-control tr-fields" target="tr-b-no"  defaultValue={(this.props.transport_data && this.props.transport_data.b_no)?this.props.transport_data.b_no:''} id="b_no" tabindex={12}/>
                                    </div>
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-no-of-parcel-rcvd">No. of Parcel Received</label>
                                        <input type="text" className="form-control tr-fields" target="tr-no-of-parcel-rcvd"  defaultValue={(this.props.transport_data && this.props.transport_data.no_of_parcel_received)?this.props.transport_data.no_of_parcel_received:''}  id="no_of_parcel_received" tabindex={13}/>
                                    </div>
                                    
                                
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-receipt">Receipt</label>
                                        <input type="text" id="receipt" className="form-control tr-fields" target="tr-receipt"  defaultValue={(this.props.transport_data && this.props.transport_data.receipt)?this.props.transport_data.receipt:''} tabindex={15}/>
                                    </div>
                                
                                    <div className="col-md-3 w-50">
                                        <label htmlFor="tr-status">Status</label>
                                        <input type="text" id="tr-status" className="form-control" target="tr-status"  defaultValue={(this.props.transport_data && this.props.transport_data.status)?this.props.transport_data.status:''} tabindex={16}/>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-md-6 w-50">
                                <button type="button" className="btn btn-secondary btn-save-action" onClick={this.handleCloseSaveTransport}>Close</button>
                                </div>
                                <div className="col-md-6 w-50">
                                    <button type="button" id="save-transport-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    <button type="button" id="save-transport" onClick={this.handleSaveTransport} className="btn btn-success btn-save-action pull-right">Save Transport</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}