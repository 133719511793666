import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from "../utils";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ServiceLoader from "./service-loader.js";
let {enc_key,app_api,user_info, gst_options} = require('../library.js');
export default class BillingPOSCloseCounter extends Component {
    constructor() {
        super();
        this.state = {
            total_in : 0,
            total_out : 0,
            opening_balance : 0,
            is_account_closing : false,
            is_loading : true,
            transaction_list : [],
        };
       
    }
    componentDidMount(){
        this.handleGetTransaction();
    }
    handleGetTransaction = () =>{
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema
        }
        let sapi = app_api+'/show_today_user_transaction';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            this.setState({transaction_list:resp.response});
            this.setState({opening_balance:resp.opening_balance});
            this.setState({total_in:resp.total_in});
            this.setState({total_out:resp.total_out});
            this.setState({closing_amount:resp.closing_amount});
            this.setState({is_loading:false});
        }.bind(this)).catch(function(res){
            
        })
      }
    handleCloseAccount = ()=>{
        this.setState({is_account_closing:true});
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            closing_amount : this.state.closing_amount
        }
        let sapi = app_api+'/close_account_for_today';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            this.setState({is_account_closing:false});
            this.handleClosePopup();
        }.bind(this)).catch(function(res){
            
        })
    }
    handleClosePopup = ()=>{
        this.props.handleCloseCounter();
    }
    render() {
        return (
            <>
                <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title hbtitle">Close counter for today</h5>
                            </div>
                            <div className="modal-body" style={{overflow: 'auto',maxHeight: '400px'}}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Today Transaction History</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                    <h2>OB : ₹{utils.currency_format(this.state.opening_balance)}</h2>
                                    </div>
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>PAYMENT #</th>
                                                    <th>IN(+)</th>
                                                    <th>OUT(-)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.transaction_list.map((value,index)=>{
                                                    return(
                                                        <tr>
                                                            <td>
                                                                {value.invoice_number}
                                                            </td>
                                                            <td>
                                                                {(value.type=='IN') &&
                                                                    <span style={{color:'green'}}>₹{utils.currency_format(value.amount)}</span>
                                                                }
                                                                {(value.type=='OUT') &&
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {(value.type=='OUT') &&
                                                                    <span style={{color:'red'}}>₹{utils.currency_format(value.amount)}</span>
                                                                }
                                                                 {(value.type=='IN') &&
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }))}
                                                {(this.state.transaction_list.length===0 && this.state.is_loading===true) &&
                                                    <tr>
                                                        <td colSpan={3} style={{textAlign:'center'}}>
                                                            <FontAwesomeIcon icon={faSpinner} pulse/>
                                                        </td>
                                                    </tr>
                                                }
                                                {(this.state.transaction_list.length===0 && this.state.is_loading===false) &&
                                                    <tr>
                                                        <td colSpan={3} style={{textAlign:'center'}}>
                                                            Oops ! No transaction(s) found yet
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        {(this.state.total_in>0) &&
                                                            <h4 style={{color:'green'}}>₹{utils.currency_format(this.state.total_in)}</h4>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(this.state.total_out>0) &&
                                                            <h4 style={{color:'red'}}>₹{utils.currency_format(this.state.total_out)}</h4>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6">

                                <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>Close</button>
                                    </div>
                                    <div className="col-md-6  text-right">

                                        {(this.state.is_account_closing===false) &&
                                            <button type="button" className="btn btn-success" onClick={this.handleCloseAccount}>Close Counter</button>
                                        }
                                        {(this.state.is_account_closing===true) &&
                                            <button type="button" className="btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}