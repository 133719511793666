import React, { Component } from "react";
import {Helmet} from "react-helmet";
import axios from 'axios';
import utils from '../utils.js';
import { gapi } from 'gapi-script';
let {app_api,user_info} = require('../library.js');
let global_google_api_settings = {api_key : '',client_id:''}

const GoogleAPIBaazarERP = () =>{
    const [google_api_settings,setGoogleAPISettings] = React.useState({api_key : '',client_id:''});
    React.useEffect(()=>{
        let keys = "'google_api_settings'";
          let set_data = {
              store_name:user_info.store_name,
              branch_id:user_info.login_id,
              user_id : user_info.user_id,
              keys:keys,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let opt_api = app_api+'/get_settings';
          axios.post(opt_api, set_data)
            .then(function (response) {
              let resp = response.data;
              if(resp.google_api_settings!==undefined){
                let google_api_settings = resp.google_api_settings;
                global_google_api_settings = google_api_settings;
                setGoogleAPISettings(google_api_settings);
              }
          }.bind()).catch(function(res){
              
          })
    },[])
    return (
        <>
           {/*  {(google_api_settings && google_api_settings.api_key!=="" && google_api_settings.client_id!=="") && */}
            {/* <Helmet>
              <script async defer src="https://apis.google.com/js/api.js"></script>
              <script async defer src="https://accounts.google.com/gsi/client"></script>
            </Helmet> */}
          {/* } */}
        </>
    );
}

const handleCreateContactToGoogle = (contactInfo) =>{
  console.log(global_google_api_settings.api_key);
    if(global_google_api_settings.api_key==="" || global_google_api_settings.client_id===""){
      return false;
    }
    
     // 2. Initialize the JavaScript client library.
     gapi.load('client', () => {
      gapi.client.init({
        /* 'apiKey': global_google_api_settings.api_key, */
        'accessToken' :'ya29.a0AbVbY6MI0d0ZM9O57arFX4xwG3lt5Mx3h41_2_kyBUWIDfB6P5Jk6lFHbrsfLlFFPqHpyd_B4CBKuIn-w7C6I4eAsh-ffp95pvt40s5AAxG-Ddv4WCOIgDgb-biQpd7qk2QCaW1BAVHPlWyirbq-i5p_J4sPaCgYKAdoSARMSFQFWKvPlPEPpRgPvuCy7Vl9cWqM0_A0163',
        // clientId and scope are optional if auth is not required.
         'clientId': global_google_api_settings.client_id,
        'scope': 'https://www.googleapis.com/auth/contacts',
      }).then(function () {
          // 3. Initialize and make the API request.
          return gapi.client.request({
              'method': "POST",
              'path': 'https://people.googleapis.com/v1/people:createContact',
              'datatype': 'jsonp',
              'accessToken' :'ya29.a0AbVbY6MI0d0ZM9O57arFX4xwG3lt5Mx3h41_2_kyBUWIDfB6P5Jk6lFHbrsfLlFFPqHpyd_B4CBKuIn-w7C6I4eAsh-ffp95pvt40s5AAxG-Ddv4WCOIgDgb-biQpd7qk2QCaW1BAVHPlWyirbq-i5p_J4sPaCgYKAdoSARMSFQFWKvPlPEPpRgPvuCy7Vl9cWqM0_A0163',
              'parent': "MY CONTACT",
              'body': {
                  "names": [
                      {
                          "givenName": contactInfo.name
                      }
                  ],
                  "emailAddresses": [
                      {
                          "value": contactInfo.email
                      }
                  ],
                  "phoneNumbers": [
                      {
                          "value": contactInfo.phone_1
                      }
                  ]
              }
          })
      }).then(function (response) {
          console.log('successfuly created');
          console.log(response.result);
      }, function (reason) {
          console.log('Error: ' +JSON.stringify(reason));
      });
    });
  }
export  {handleCreateContactToGoogle,GoogleAPIBaazarERP}
  