import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import 'animate.css';
import "react-datepicker/dist/react-datepicker.css";
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class ChequePrint extends Component {
    constructor(props) {
      super();
      this.state = {
        is_print : false,
        cheque_name : (props.print_data && props.print_data.party_info && props.print_data.party_info.name!==undefined)?props.print_data.party_info.name.trim():props.print_data[0].party_info.name.trim(),
        cheque_print_settings : {},
        date_top: 20,
        date_right: 35,
        name_top: 70,
        name_left: 70,
        amount_words_top: 140,
        amount_words_left: 70,
        amount_numeric_top: 190,
        amount_numeric_right: 100
      };
      this.is_access = check_access.is_access.bind(this);
      this.isMobile = utils.isMobile.bind(this);
    }
    
    componentDidMount = () =>{
        this.setState({is_print:false});
        /* let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            account_id:this.props.account_id
        }
        let opt_api = app_api+'/get_cheque_print_setting';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.cheque_print_settings){
              let ps = resp.cheque_print_settings;
              this.setState({cheque_print_settings:ps});


                this.setState({date_top: ps.date_top+'px'});
                this.setState({date_right: ps.date_right+'px'});
                this.setState({name_top: ps.name_top+'px'});
                this.setState({name_left: ps.name_left+'px'});
                this.setState({amount_words_top: ps.amount_words_top+'px'});
                this.setState({amount_words_left: ps.amount_words_left+'px'});
                this.setState({amount_numeric_top: ps.amount_numeric_top+'px'});
                this.setState({amount_numeric_right: ps.amount_numeric_right+'px'});
                
            }
        }.bind(this)).catch(function(res){
            
        }) */
        
        if(this.props.print_data.cheque_print_settings){
            let ps = this.props.print_data.cheque_print_settings;
            this.setState({cheque_print_settings:ps});


              this.setState({date_top: ps.date_top+'px'});
              this.setState({date_right: ps.date_right+'px'});
              this.setState({name_top: ps.name_top+'px'});
              this.setState({name_left: ps.name_left+'px'});
              this.setState({amount_words_top: ps.amount_words_top+'px'});
              this.setState({amount_words_left: ps.amount_words_left+'px'});
              this.setState({amount_numeric_top: ps.amount_numeric_top+'px'});
              this.setState({amount_numeric_right: ps.amount_numeric_right+'px'});
              
          }
    }
    componentDidUpdate = ()=>{
        //this.handlePrintCheque();
    }

    handlePrintCheque = ()=>{
        this.setState({is_print:true});
        let _this = this;
        setTimeout(() => {
            _this.handlePrint();
        }, 100);
    }

    handlePrint = () =>{
        var isMobile = this.isMobile();
        if(document.getElementById("cheque-print-block")){
            var divContents = document.getElementById("cheque-print-block").innerHTML; 

            let page_size = 'A4';

            try{
                var a = window.open('', '', 'height=450,width=950,location=no,left=250px'); 
                a.document.open(); 
                a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
                a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

                a.document.write('<style type="text/css">@page{size:'+page_size+'}.page-break{page-break-after: always;}</style>'); 
                a.document.write('</head>'); 
                
                if(this.props.print_type==='print'){
                    //if(isMobile){
                      a.document.write('<body onload="window.print();">'); 
                    /* }else{
                      a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
                      a.document.write('<script>function afterPrint() {localStorage.setItem("open_new_bill","yes");if(localStorage.getItem("response_id")!==null){localStorage.setItem("open_list_view","yes");} if(localStorage.getItem("save_print_new")!==null){localStorage.setItem("create_new_bill","yes")} window.close();}</script>'); 
                    } */
                  }else{
                    a.document.write('<body>'); 
                  }
                 
                a.document.write(divContents); 
                a.document.write('</body></html>');
                a.document.body.style.fontFamily="Calibri, sans-serif";  
                a.document.close();

            }catch{}
        }
        if(document.getElementById("handleCloseChequePrint")){
            document.getElementById("handleCloseChequePrint").click();
        }
    }
    handleCheckSelf = (e) =>{
        if(e.currentTarget.checked){
            let default_str = 'Yourself NEFT/RTGS';
            this.setState({cheque_name:default_str});
        }
    }
    handleChangeChequeName = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({cheque_name:thisval});
    }
    handleCloseRTGS = () =>{
        this.setState({is_print:null});
        if(document.getElementById("handleCloseChequePrint")){
            document.getElementById("handleCloseChequePrint").click();
        }
    }
    render() {
        return (
            <>
             {(this.state.is_print===false) && 
                <div className="modal" id="cheque-name-opt" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">YOURSELF/RTGS</h5>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-12">
                                <label>YOURSELF NEFT/RTGS</label>
                               <input type="text" className="form-control" placeholder="RTGS" value={this.state.cheque_name} onChange={this.handleChangeChequeName}/>
                            </div>
                            {/* <div className="col-md-12">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="youself" value="youself" onChange={this.handleCheckSelf}/>
                                    <label className="form-check-label chklbl" htmlFor="youself" id="lbl-youself">YOURSELF</label>
                                </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseRTGS}>Close</button>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button type="button" className="btn btn-success" onClick={this.handlePrintCheque}>Print</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                }
                {(this.state.is_print===true) &&
                    <div id="cheque-print-block" style={{display:'none'}}>

                        {(this.props.print_data && this.props.print_data.length===undefined) &&
                        <div style={{height:'4.25in',width:'9.5in',padding:'30px',position:'relative'}} className="page-break">
                        <div style={{position:'relative'}}>
                            <div style={{position:'absolute',top:this.state.date_top,right:this.state.date_right}}>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[0]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[1]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[2]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[3]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[4]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[5]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[6]}</span>
                                <span style={{padding:'0px 8px'}}>{this.props.print_data.cheque_print_date[7]}</span>
                            </div>
                            <div  style={{position:'absolute',top:this.state.name_top,left:this.state.name_left}}>
                                <h2 style={{fontSize:'22px',marginTop:'22px', fontWeight:'bold'}}>
                                    **{this.state.cheque_name}**
                                </h2>
                            </div>
                        
                            <div  style={{position:'absolute',top:this.state.amount_words_top,left:this.state.amount_words_left}}>
                                <span style={{fontSize:'18px',marginTop:'15px',fontWeight:'bold'}}>
                                    **{utils.convert_number_to_words(this.props.print_data.amount)}**
                                </span>
                            </div>
                            <div style={{position:'absolute',top:this.state.amount_numeric_top,right:this.state.amount_numeric_right}}>
                                <span style={{padding: '4px 10px',fontSize:'18px',fontWeight:'bold'}}>
                                    **{utils.currency_format(this.props.print_data.amount)}**
                                </span>
                            </div>
                        </div>
                    </div>
                        }

                        {(this.props.print_data && this.props.print_data.length>0) &&
                            <>
                                {this.props.print_data.map((value,index)=>{
                                    return(
                                        <div style={{height:'4.25in',width:'9.5in',padding:'30px',position:'relative'}} className="page-break">
                                            <div>
                                                <div style={{position:'absolute',top:value.cheque_print_settings.date_top+'px',right:value.cheque_print_settings.date_right+'px'}}>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[0]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[1]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[2]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[3]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[4]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[5]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[6]}</span>
                                                    <span style={{padding:'0px 8px'}}>{value.cheque_print_date[7]}</span>
                                                </div>
                                                <div  style={{position:'absolute',top:value.cheque_print_settings.name_top+'px',left:value.cheque_print_settings.name_left+'px'}}>
                                                    <h2 style={{fontSize:'22px',marginTop:'22px', fontWeight:'bold'}}>
                                                        **{value.cheque_print_settings.cheque_name}**
                                                    </h2>
                                                </div>
                                                
                                                <div style={{position:'absolute',top:value.cheque_print_settings.amount_words_top+'px',left:value.cheque_print_settings.amount_words_left+'px'}}>
                                                    <span style={{fontSize:'18px',marginTop:'15px',fontWeight:'bold'}}>
                                                        **{utils.convert_number_to_words(value.amount)}**
                                                    </span>
                                                </div>
                                                <div style={{position:'absolute',top:value.cheque_print_settings.amount_numeric_top+'px',right:value.cheque_print_settings.amount_numeric_right+'px'}}>
                                                    <span style={{padding: '4px 10px',fontSize:'18px',fontWeight:'bold'}}>
                                                        **{utils.currency_format(value.amount)}**
                                                    </span>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                }
            </>
        );
    }
}