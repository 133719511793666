import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import utils from '../utils.js';
export default class ParcelSticker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billing_data : [],
            contact_info : [],
        };
        this.isMobile = utils.isMobile.bind(this);
    }   

    handlePrintParcelSticker = (e) =>{
        var isMobile = this.isMobile();
        if(document.getElementById("parcel-sticker-print-container")){
            var divContents = document.getElementById("parcel-sticker-print-container").innerHTML; 
            this.setState({is_print:true});
        try{
            var a = window.open('', '', 'height=1000,location=no,left=230px'); 
            a.document.open(); 
            a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page{size:A4}.table{font-size:12px;}.table{width:100%;margin-bottom:1rem;color:#212529}.table .thead-light th{font-size:11px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table td,.table th{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.border-bottom{border-bottom:1px solid #d7d7d7}.table .thead-light th{white-space: nowrap;}h4{margin-top: 0px;margin-bottom: 5px;}.table-responsive{margin:5px 0px 0px 0px !important;overflow-x: unset;overflow-y: unset;border: none;}form{margin-bottom:0px !important}.t-bottom-area .col-md-6 {flex: 0 0 50%;max-width: 50%;} .col-md-12 {flex: 0 0 100%;max-width: 100%;}</style>'); 
            a.document.write('</head>'); 
        
          
            if(isMobile){
                a.document.write('<body onload="window.print();">'); 
            }else{
                a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
                a.document.write('<script>function afterPrint() {window.close();}</script>'); 
            }
           
        
            a.document.write(divContents); 
            a.document.write('</body></html>');
            a.document.close();
            
        }catch{}
        }
    }

    componentDidMount(){
        let resp = this.props.bill_view_response;
        let data = resp.data;
        let contact_info = data.contact_info;
        let contact_name = '';
        let contact_id = '';
        let address_1 =  '';
        let address_2 = '';
        let city = '';
        let credit_limit = '';
        let email = '';
        let phone_1 = '';
        let phone_2 = '';
        let state = '';
        let display_state = '';
        let gst_number = '';
        let pin = '';
        if(Object.keys(contact_info).length > 0) {
           contact_name = contact_info[0].name;
           contact_id = contact_info[0].contact_id;
           address_1 = contact_info[0].address_1;
           address_2 = contact_info[0].address_2;
           city = contact_info[0].city;
           credit_limit = contact_info[0].credit_limit;
           email = contact_info[0].email;
           phone_1 = contact_info[0].phone_1;
           phone_2 = contact_info[0].phone_2;
           state = contact_info[0].state;
           display_state = contact_info[0].display_state;
           gst_number = contact_info[0].gst_number;
           pin = contact_info[0].pin;
        }
        let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,display_state:display_state,gst_number:gst_number,pin:pin};
        this.setState({contact_info:c_info});
        this.setState({billing_data:data});
    }

    render() {
      
        return (
            <>
                 <li  className="list-group-item" onClick={this.handlePrintParcelSticker}>
                    <span>Parcel Sticker</span>
                </li>
                <div id="parcel-sticker-print-container" className="hide">
                    <div style={{padding:'20px'}}>
                        <h2 style={{margin:'0px',fontSize:'22px',textAlign:'center'}}>
                            BILL NO : {(this.state.billing_data && this.state.billing_data)?this.state.billing_data.number:''}
                        </h2>   
                        <h2 style={{margin:'0px',fontSize:'22px',textAlign:'center'}}>
                            NO OF BALES : {(this.state.billing_data && this.state.billing_data && this.state.billing_data!==undefined)?this.state.billing_data.no_of_parcels:''}
                        </h2>   
                    <div>
                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'30px'}}>To</h2>  
                    <div style={{marginLeft:'30px'}}>
                        <h4 style={{fontSize:'30px',fontWeight:'bold'}}>{this.state.contact_info!=''?this.state.contact_info.item_name:''}</h4>
                        <p style={{margin:'0px',fontSize:'22px'}}>
                            {(this.state.contact_info!=='' && this.state.contact_info.address_1!=='' && this.state.contact_info.address_1!==null)?this.state.contact_info.address_1:''}
                        </p>
                        <p style={{margin:'0px',fontSize:'22px'}}>
                            {(this.state.contact_info!=='' && this.state.contact_info.city!=='' && this.state.contact_info.city!==null)?this.state.contact_info.city:''}

                            {(this.state.contact_info!=='' && this.state.contact_info.pin!=='' && this.state.contact_info.pin!==null)?'-'+this.state.contact_info.pin:''}

                            {(this.state.contact_info!=='' && this.state.contact_info.pin!=='' && this.state.contact_info.pin!==null && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?', ':''}

                            {(this.state.contact_info!=='' && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?this.state.contact_info.display_state:''}

                            {(this.state.contact_info!=='' && this.state.contact_info.phone_1!=='' && this.state.contact_info.phone_1!==null)?', '+this.state.contact_info.phone_1:''}
                        </p>
                        <h4 style={{fontSize:'22px',fontWeight:'bold'}}>
                        {(this.state.contact_info!=='' && this.state.contact_info.gst_number!=='' && this.state.contact_info.gst_number!==null)?"GSTIN :"+this.state.contact_info.gst_number:''}
                        </h4>
                        </div> 
                    </div>
                    <div style={{marginLeft:'50px',marginTop:'20px'}}>
                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'30px'}}>From</h2>
                        <div style={{marginLeft:'30px'}}>
                            <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'30px'}}>
                                {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0]!==undefined)?this.state.billing_data.company_info[0].company_name:''
                                }
                            </h2>
                            {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0]!==undefined && this.state.billing_data.company_info[0].company_sub_title!=="" && this.state.billing_data.company_info[0].company_sub_title!==null) &&
                                <>
                                    <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.state.billing_data.company_info[0].company_sub_title}</p>
                                </>
                            }
                            
                            {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0]!==undefined) &&
                                <>
                                <p style={{textTransform:'uppercase',margin:'0px',fontSize:'22px'}}>
                                {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].address!=='' && this.state.billing_data.company_info[0].address!==null)?this.state.billing_data.company_info[0].address:''
                                }
                                </p>
                                <p style={{textTransform:'uppercase',margin:'0px',fontSize:'22px'}}>
                                {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].city!=='' && this.state.billing_data.company_info[0].city!==null)?this.state.billing_data.company_info[0].city:''
                                }
                                {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].pin_code!=='' && this.state.billing_data.company_info[0].pin_code!==null )?'-'+this.state.billing_data.company_info[0].pin_code:''
                                }
                                </p>
                                {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].phone!=="" && this.state.billing_data.company_info[0].phone!==null) &&
                                    <p style={{textTransform:'uppercase',margin:'0px',fontSize:'22px'}}>
                                        {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].phone!=="" && this.state.billing_data.company_info[0].phone!==null)?"PHONE : "+this.state.billing_data.company_info[0].phone:''
                                        }
                                        {(this.state.billing_data && this.state.billing_data.company_info && this.state.billing_data.company_info[0].phone_2!=="" && this.state.billing_data.company_info[0].phone_2!==null)?', '+this.state.billing_data.company_info[0].phone_2:''
                                        }
                                    </p>
                                }
                            
                                {(this.state.billing_data && this.state.billing_data.company_info[0].gst_number!=='' && this.state.billing_data.company_info[0].gst_number!==null) &&
                                <>
                                    <h4 style={{fontSize:'22px',fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.company_info[0].gst_number)?'GSTIN. '+this.state.billing_data.company_info[0].gst_number:''}
                                    </h4>
                                </>
                                }
                            </>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}