import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import Notification from "./notification.js";
import check_access from './access-level.js';
import DrawerLink from "./open-drawer-link";
import Link from './link.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faFilter, faPencilAlt, faSpinner, faTimes, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from "./autocomplete";
let {enc_key,app_api,user_info} = require('../library.js');
export default class PurchaseReturnRequest extends Component {
    constructor() {
        super();
        this.state = {
          active_tab : 'stock_transfer',
          FromDate:  new Date(),
          ToDate: new Date(),
          purchase_return_request:[],
          purchase_return_request_temp:[],
          trash_count : 0,
          loading_process:'Loading...',
          is_search:false,
          is_transfer_stock:false,
          is_view:false,
          transfer_data : [],
          linked_stores : [],
          stock_transfer : [],
          is_item_edit : '',
          stock_edit_id : '',
        }

        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
  


  componentDidMount(){
    
    this.getTransferedList();
  }

  getTransferedList = ()=>{
    let curl = window.location.href;
    let ptype = 'all';

    const page_tab = curl.match(/stock-transfer\/page\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[1];
    }


    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
           }
        let api = app_api+'/get_purchase_return_request';
        axios.post(api, body)
        .then(function (response) {
            this.setState({ 'purchase_return_request': response.data.response });
            this.setState({ 'purchase_return_request_temp': response.data.response });
            if(response.data.response.length===0){
              this.setState({loading_process:'No Record(s) Found'})
            }
        }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
  
   
   

    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Purchase Return Request</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                          <Notification /> 
                         
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content" style={{overflow:'auto'}} id="transport-list-block">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">TRANSFER ID</th>
                            <th scope="col">TRANSFER DATE</th>
                            <th scope="col">FROM</th>
                            <th scope="col" className="no-print"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.purchase_return_request.length > 0) ?this.state.purchase_return_request.map( (value, index) => {
                          
                           return (
                            <tr key={ index } id={"billing-row-"+value.transport_id} className={"contact-row"}>
                              <td>
                                <a style={{color:'#9999ff'}} index={index} onClick={this.handleOpenViewModel} id={value.request_id}>#{value.request_id}</a>
                              </td>
                              <td>{value.request_date}</td>
                              <td>{value.store_name}</td>
                              <td className="no-print">
                                <a style={{color:'#9999ff'}} onClick={this.handleViewPopup} id={value.request_id}>View</a>
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={9} className="text-center">{this.state.loading_process}</td></tr> }
                           
                         
                        </tbody>
                      </table>
                     
                      
                     </div>
                  </div>
                </div>
              </div>
                            
            </div>
        );
    }
    handleViewPopup = (e) =>{
      let id= e.currentTarget.id;
      localStorage.setItem('request_id',id);
      document.getElementById("handleOpenPurchaseReturnRequestReqeust").click();
    }
}