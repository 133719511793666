import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ProductUnits from "./product-units";
import utils from '../utils.js';
import Autocomplete from "./autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faPencilAlt  } from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
let _this;
let remove_items = [];
let auto_generate_barcode = '';

export default class QuotationItemList extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            item_options : [],
            purchase_item_options : [],
            bundle_text : '',
            is_tax_inc : 'false',
        };
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        this.listItemRemove = this.listItemRemove.bind(this);
        this.confirmItemDelete = this.confirmItemDelete.bind(this);
        this.closeConfirmItemDel = this.closeConfirmItemDel.bind(this);
        this.listItemEdit = this.listItemEdit.bind(this);
        _this = this;
    }
    confirmItemDelete(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-litem-"+data_id).classList.remove('hide');
    }
    closeConfirmItemDel(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-litem-"+data_id).classList.add('hide');
    }
    listItemRemove(e){
      this.props.listItemRemove(e);
      setTimeout(function(){
        _this.ADJUST_BILL_SNO();
      },100);
    }

    listItemEdit(e){
      this.props.listItemEdit(e);
    }
    
    componentDidMount(){
      if(localStorage.getItem('is_auto_generate_barcode')=="true"){
        auto_generate_barcode = 'Auto Generate';
      }
       let more_row_len = document.getElementsByClassName("items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
       }
        
    }
    
    render() {
        let is_variant = '';
        if(this.props.items && this.props.items.is_variation){
          is_variant = 'is_variant';
        }

        if(this.props.billing_type!=='New Purchase'){
            auto_generate_barcode = '';
        }

        let barcode_rep = '';
        if(this.props.items.barcode && this.props.items.barcode!==""){
          barcode_rep = this.props.items.barcode.replace("/",'');
        }
        return (
          <tr className={this.props.items.product_key+'_'+this.props.items.p_rand_key+" items-row billing-item-row "+is_variant+" birow-barcode-"+barcode_rep} key={this.props.index} id={"item-row-"+this.props.index} data-id={this.props.index}>
            <td className="item-serial">
              <span className="b-item-sno" data-id={this.props.index} data-target={this.props.items.product_key+'_'+this.props.items.p_rand_key}>
                {(this.props.serial!==undefined)?this.props.serial:this.props.index}
              </span>
            </td>
            <td className="item-name">
               <span>{(this.props.items && this.props.items.item_name)?this.props.items.item_name:''}</span>
               <input
                type="hidden"
                className="search-box form-control"
                placeholder=""
                data_id={this.props.index}
                id={"item-box-"+this.props.index}
                target="item-box"
                autoFocus
                defaultValue={(this.props.items && this.props.items.item_name)?this.props.items.item_name:''}
              />
              {(this.props.items.barcode!=="" && this.props.items.barcode!==null && this.props.items.barcode!=='none') &&
                <>
                <span className="bcode-name bblk"> 
                  <span>{this.props.items.barcode}</span>
                  {(this.props.items.alias_barcode) &&
                    <span>{this.props.items.alias_barcode}</span>
                  }
                </span>
                </>
              }
              <br />
              <input type="hidden" id={"vitem-option1-"+this.props.index} defaultValue={(this.props.items.value1)?this.props.items.value1:''} data-id={(this.props.items.option1)?this.props.items.option1:''} />
              <input type="hidden" id={"vitem-option2-"+this.props.index} defaultValue={(this.props.items.value2)?this.props.items.value2:''} data-id={(this.props.items.option2)?this.props.items.option2:''} />
              <input type="hidden" id={"vitem-option3-"+this.props.index} defaultValue={(this.props.items.value3)?this.props.items.value3:''} data-id={(this.props.items.option3)?this.props.items.option3:''}/ >

              <input className="" type="hidden" id={"vitem-barcode-name-"+this.props.index} defaultValue={(this.props.items.barcode)?this.props.items.barcode:auto_generate_barcode}/ >

              <input className="" type="hidden" id={"vitem-item-key-"+this.props.index} defaultValue={(this.props.items.p_rand_key)?this.props.items.p_rand_key:''}/ >

              <input className="" type="hidden" id={"vitem-alias-barcode-name-"+this.props.index} defaultValue={(this.props.items.alias_barcode)?this.props.items.alias_barcode:''}/ >

              <input type="hidden" data-id={this.props.index} id={"item-type-"+this.props.index} defaultValue={(this.props.items && this.props.items.iitem_type)?this.props.items.iitem_type:''} className=""/>

              <input type="hidden" data-id={this.props.index} id={"item-has-variant-"+this.props.index} defaultValue={(this.props.items && this.props.items.has_variation)?this.props.items.has_variation:''} className=""/>

              <input type="hidden" data-id={this.props.index} id={"item-is-variant-"+this.props.index} defaultValue={(this.props.items && this.props.items.is_variation)?this.props.items.is_variation:''} className=""/>
             
              <input type="hidden" data-id={this.props.index} id={"item-list-id-"+this.props.index} defaultValue={(this.props.items && this.props.items.list_item_id)?this.props.items.list_item_id:''}/>
              <input type="hidden" className="iitem-ids" data-id={this.props.index} id={"item-name-"+this.props.index} defaultValue={(this.props.items && this.props.items.item_id)?this.props.items.item_id:''}/>
              <input type="hidden" id={"old-item-name-"+this.props.index} defaultValue={(this.props.items && this.props.items.item_id)?this.props.items.item_id:''}/>
              {(this.props.items && this.props.items.is_variation!==true) &&
               <i className="item-desc-txt" id={"iitem-desc-txt-"+this.props.index}>{(this.props.items && this.props.items.desc)?this.props.items.desc:''}</i>
              }
              {(this.props.items && this.props.items.is_variation===true) &&
               <i className="item-desc-txt hide" id={"iitem-desc-txt-"+this.props.index}>{(this.props.items && this.props.items.desc)?this.props.items.desc:''}</i>
              }

            </td>
            <td className="hide">
                <input type="hidden" className="form-control" placeholder="" data-id={this.props.index} id={"item-desc-"+this.props.index} defaultValue={(this.props.items && this.props.items.desc)?this.props.items.desc:''} target="item-desc"/>
            </td> 
            {this.props.billing_type==='New Purchase' &&
              <>
                {(this.props.items && this.props.items.is_variation!==true) &&
                  <td className="text-center">
                      <span>{(this.props.items && this.props.items.hsn)?this.props.items.hsn:''}</span>
                      <input type="hidden" className="form-control mob-item" placeholder="" id={"item-hsn-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.items && this.props.items.hsn)?this.props.items.hsn:''} target="item-hsn"/>
                  </td>
                }
                {(this.props.items && this.props.items.is_variation===true) &&
                  <td className="text-center">
                      <span></span>
                      <input type="hidden" className="form-control mob-item" placeholder="" id={"item-hsn-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.items && this.props.items.hsn)?this.props.items.hsn:''} target="item-hsn"/>
                  </td>
                }
              </>
            }
            {this.props.billing_type!=='New Purchase' &&
              <td className="text-center hide">
                  <span>{(this.props.items && this.props.items.hsn)?this.props.items.hsn:''}</span>
                  <input type="hidden" className="form-control mob-item" placeholder="" id={"item-hsn-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.items && this.props.items.hsn)?this.props.items.hsn:''} target="item-hsn"/>
              </td>
            }
            <td className="text-center">
                {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                <span></span>
                :
                <span>{(this.props.items && this.props.items.quantity)?this.props.items.quantity:''}</span>
                }
                <input type="text" className="hide" id={"price-hidden-"+this.props.index} data-id={this.props.index} data-target="price-hidden" defaultValue={(this.props.items && this.props.items.price_hidden)?this.props.items.price_hidden:''} target="price-hidden"/>
                <input type="hidden" data-id={this.props.index} id={"item-bundle-quantity-"+this.props.index} data-target="item-bundle-quantity" target="item-bundle-quantity" defaultValue={(this.props.items && this.props.items.bundle)?this.props.items.bundle:''}/>

                <input type="hidden" data-id={this.props.index} id={"item-bundle-text-"+this.props.index} data-target="item-bundle-text" target="item-bundle-text" defaultValue={(this.props.items && this.props.items.bundle_text)?this.props.items.bundle_text:''}/>

                <input type="hidden" className="form-control item-quantity w-55  mob-item" id={"item-quantity-"+this.props.index} placeholder=""   data-id={this.props.index} data-target="item-quantity" defaultValue={(this.props.items && this.props.items.quantity)?this.props.items.quantity:''} target="item-quantity" />
                <input type="hidden" id={"current-available-"+this.props.index} defaultValue={(this.props.items && this.props.items.availability)?this.props.items.availability:0} />
                {(this.props.items && this.props.items.bundle_quantity>0 && this.props.items.bundle_desc!=='')?
                <><br /><span className="bundle_quantity" id={"bundle_quantity-"+this.props.index} style={{fontStyle:'italic'}}>({this.props.items.bundle_desc})</span></>
                :<><br /><span className="bundle_quantity" id={"bundle_quantity-"+this.props.index} style={{fontStyle:'italic'}}></span></>
                }
            </td> 
            <td className="units-dd text-center">
                <input type="hidden" className="form-control item-unit-name" placeholder=""  id={"item-unit-name-"+this.props.index} data-id={this.props.index} data-target="item-unit-name" defaultValue={(this.props.items && this.props.items.unit_name)?this.props.items.unit_name:''}/>

                <input type="hidden" className="form-control item-unit" placeholder=""  id={"item-unit-"+this.props.index} data-id={this.props.index} data-target="item-unit" defaultValue={(this.props.items && this.props.items.unit_id)?this.props.items.unit_id:''}/>
                {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                  <span></span>
                  :
                  <span>{(this.props.items && this.props.items.unit_name)?this.props.items.unit_name:''}</span>
                }
            </td>
           {this.props.billing_type==='New Sale' &&
            <>
              <td style={{display:'none'}}>
                <span className="item-availability"  id={"item-availability-"+this.props.index} data-id={this.props.index} data-target="item-availability">{(this.props.items && this.props.items.availability)?this.props.items.availability:'---'}</span>
              </td> 
              <td  className="text-center">
                  <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                  <input type="hidden" className="form-control item-lowest-selling-price" placeholder=""  id={"item-lowest-selling-price-"+this.props.index} data-id={this.props.index} data-target="item-lowest-selling-price" defaultValue={(this.props.items && this.props.items.lowest_selling_price)?this.props.items.lowest_selling_price:''}/>
                  <input type="hidden" className="form-control item-sub-total  mob-item" placeholder=""  id={"price-"+this.props.index} data-id={this.props.index} data-target="price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="price" />
              </td>
            </>
          }
          {this.props.billing_type==='New Purchase' &&
            <>
              <td className="text-center">
                  {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                  <span></span>
                  :
                  <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                  }
                  <input type="hidden" className="total-avl-out-hidden" id={"total-avl-out-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_avl)?this.props.items.total_avl:0}/>
                  <input type="hidden" className="form-control  item-sub-total  mob-item" placeholder=""  id={"price-"+this.props.index} data-id={this.props.index} data-target="price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="price"/>
              </td> 
            </>
          }
           {this.props.billing_type==='New Sales Return' &&
              <td className="text-center">
                  <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                  <input type="hidden" className="form-control item-sub-total  mob-item" placeholder=""  id={"price-"+this.props.index} data-id={this.props.index} data-target="price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="price"/>

                  <input type="hidden" className="form-control item-lowest-selling-price" placeholder=""  id={"item-lowest-selling-price-"+this.props.index} data-id={this.props.index} data-target="item-lowest-selling-price" defaultValue={(this.props.items && this.props.items.lowest_selling_price)?this.props.items.lowest_selling_price:''}/>
                  <input type="hidden" className="mob-item-tax-type"  data-target="item-tax-inc" data-id={this.props.index} id={"item-tax-inc-"+this.props.index} target="tax-inc" defaultValue={(this.props.items && this.props.items.pp_tax_type)?(this.props.items.pp_tax_type==='including' || this.props.items.pp_tax_type==='Including GST')?'including':'excluding':''} />
              </td>
            }
            {this.props.billing_type==='New Purchase Return' &&
               <td className="text-center">
                  <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                  <input type="hidden" className="total-avl-out-hidden" id={"total-avl-out-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_avl)?this.props.items.total_avl:0}/>
                  <input type="hidden" className="form-control item-sub-total  mob-item" placeholder=""  id={"price-"+this.props.index} data-id={this.props.index} data-target="price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="price"/>
              </td> 
            }
            {(this.props.billing_type==='New Purchase'||this.props.billing_type==='New Sale'||this.props.billing_type==='New Sales Return') && 
              <td className="text-center">
                  {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                  <span></span>
                  :
                  <span>{(this.props.items && this.props.items.discount)?this.props.items.discount:''}</span>
                  }
                  <input type="hidden" className="form-control item-discount w-55 mob-item" placeholder="%"  id={"discount-"+this.props.index} data-id={this.props.index} data-target="discount" defaultValue={(this.props.items && this.props.items.discount)?this.props.items.discount:0} target="discount"/>
                 
                  <input type="hidden" className="item-discount-hidden" data-id={this.props.index} id={"discount-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_discount)?this.props.items.total_discount:''}/>
                
              </td>
            }
            
            <td className="text-center">
                {(this.props.billing_type==='New Purchase Return' ) && 
          
                    <>  
                  <input type="hidden" className="mob-item-tax-type"  data-target="item-tax-inc" data-id={this.props.index} id={"item-tax-inc-"+this.props.index} target="tax-inc" defaultValue={(this.props.items && this.props.items.pp_tax_type)?(this.props.items.pp_tax_type==='including' || this.props.items.pp_tax_type==='Including GST')?'including':'excluding':''} />
                <input type="hidden" className="form-control item-discount w-55 mob-item" placeholder="%"  id={"discount-"+this.props.index} data-id={this.props.index} data-target="discount" defaultValue={(this.props.items && this.props.items.discount)?this.props.items.discount:0} target="discount"/>
               
                <input type="hidden" className="item-discount-hidden" data-id={this.props.index} id={"discount-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_discount)?this.props.items.total_discount:''}/>
                    </>
                }
              {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
              <span></span>
              :
              <span>{(this.props.items && this.props.items.tax)?this.props.items.tax:''}</span>
              }
              <input type="hidden" className="form-control item-gst mob-item" placeholder="%" id={"item-gst-"+this.props.index} data-id={this.props.index}  data-target="item-gst" defaultValue={(this.props.items && this.props.items.tax)?this.props.items.tax:''}  target="gst"/>
              
              <input type="hidden" className="form-control item-cgst" placeholder="%" id={"item-cgst-"+this.props.index} data-id={this.props.index}  data-target="item-cgst" defaultValue={(this.props.items && this.props.items.cgst)?this.props.items.cgst:''}  target="cgst" />
              <input type="hidden" id={"item-cgst-hidden-"+this.props.index} data-id={this.props.index} className="item-cgst-hidden" data-target="item-cgst-hidden" defaultValue={(this.props.items && this.props.items.hidden_cgst)?this.props.items.hidden_cgst:0}/>

              <input type="hidden" id={"item-cgst-total-"+this.props.index} data-id={this.props.index} className="item-cgst-total" data-target="item-cgst-total" defaultValue={(this.props.items && this.props.items.total_cgst)?this.props.items.total_cgst:0}/>
           
              <input type="hidden" className="form-control item-sgst" placeholder="%" id={"item-sgst-"+this.props.index} data-id={this.props.index}  data-target="item-sgst" defaultValue={(this.props.items && this.props.items.sgst)?this.props.items.sgst:''}  target="sgst" />
              <input type="hidden" className="item-sgst-hidden" id={"item-sgst-hidden-"+this.props.index} data-id={this.props.index}  data-target="item-sgst-hidden" defaultValue={(this.props.items && this.props.items.hidden_sgst)?this.props.items.hidden_sgst:0}/>

              <input type="hidden" className="item-sgst-total" id={"item-sgst-total-"+this.props.index} data-id={this.props.index}  data-target="item-sgst-total" defaultValue={(this.props.items && this.props.items.total_sgst)?this.props.items.total_sgst:0}/>
          
              <input type="hidden" className="form-control item-igst" placeholder="%" id={"item-igst-"+this.props.index} data-id={this.props.index}  data-target="item-igst" defaultValue={(this.props.items && this.props.items.igst)?this.props.items.igst:''}  target="igst" />
              <input type="hidden" className="item-igst-hidden" id={"item-igst-hidden-"+this.props.index} data-id={this.props.index}  data-target="item-igst-hidden" defaultValue={(this.props.items && this.props.items.hidden_igst)?this.props.items.hidden_igst:0}/>

              <input type="hidden" className="item-igst-total" id={"item-igst-total-"+this.props.index} data-id={this.props.index}  data-target="item-igst-total" defaultValue={(this.props.items && this.props.items.total_igst)?this.props.items.total_igst:0}/>

             
            </td>
            {(this.props.billing_type==="New Purchase" || this.props.billing_type==="New Sale") &&
              <td className="text-center">
              {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
              <span></span>
              :
              <span>
                {(this.props.items && this.props.items.pp_tax_type)?(this.props.items.pp_tax_type==='including' || this.props.items.pp_tax_type==='Including GST')?'Inc.GST':'Exc.GST':''}
              </span>
              }
              <input type="hidden" className="mob-item-tax-type"  data-target="item-tax-inc" data-id={this.props.index} id={"item-tax-inc-"+this.props.index} target="tax-inc" defaultValue={(this.props.items && this.props.items.pp_tax_type)?(this.props.items.pp_tax_type==='including' || this.props.items.pp_tax_type==='Including GST')?'including':'excluding':''} />
              </td>
            }
            {this.props.billing_type==='New Purchase' &&
              <>
                <td className="text-center">
                  {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span></span>
                    :
                    <span>₹{(this.props.items && this.props.items.price)?this.props.items.price2:''}</span>
                  }
                    <input type="hidden" className="form-control item-selling-price mob-item" placeholder=""  id={"item-selling-price-"+this.props.index} data-id={this.props.index} data-target="item-selling-price" defaultValue={(this.props.items && this.props.items.price2)?this.props.items.price2:''} target="item-selling-price"/>

                    <input type="hidden" className="form-control item-lowest-selling-price" placeholder=""  id={"item-lowest-selling-price-"+this.props.index} data-id={this.props.index} data-target="item-lowest-selling-price" defaultValue={(this.props.items && this.props.items.lowest_selling_price)?this.props.items.lowest_selling_price:''}/>
                  
                </td>
                <td className="text-center">
                  {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span></span>
                    :
                  <span>{(this.props.items && this.props.items.sp_discount)?this.props.items.sp_discount:''}</span>
                }
                  <input type="hidden" className="form-control item-sp-discount w-55 mob-item" placeholder="%"  id={"sp-discount-"+this.props.index} data-id={this.props.index} data-target="sp-discount" defaultValue={(this.props.items && this.props.items.sp_discount)?this.props.items.sp_discount:0} target="sp-discount"/>
                 
                  <input type="hidden" className="item-sp-discount-hidden" id={"sp-discount-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.sp_total_discount)?this.props.items.sp_total_discount:''}/>
                
              </td>
              <td className="text-center">
              {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span></span>
                    :
                <span>{(this.props.items && this.props.items.sp_tax)?this.props.items.sp_tax:''}</span>
              }
                <input type="hidden" className="form-control item-sp-gst mob-item" placeholder="%" id={"item-sp-gst-"+this.props.index} data-id={this.props.index}  data-target="item-sp-gst" defaultValue={(this.props.items && this.props.items.sp_tax)?this.props.items.sp_tax:''}  target="sp-gst"/>
              </td>
              <td className="text-center">
              {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span></span>
                    :
                <span>
                  {(this.props.items && this.props.items.sp_tax_type)?(this.props.items.sp_tax_type==='including')?'Inc.GST':'Exc.GST':''}
                </span>
              }
                <input type="hidden" className="mob-item-tax-type" data-target="sp-item-tax-inc" data-id={this.props.index} id={"sp-item-tax-inc-"+this.props.index} target="sp-tax-inc" defaultValue={(this.props.items && this.props.items.sp_tax_type)?(this.props.items.sp_tax_type==='including')?'including':'excluding':''} />
              
              </td>
              <td>
                {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span></span>
                    :
                  <span id={"item-category-name-"+this.props.index}>{(this.props.items && this.props.items.iitem_category_name)?this.props.items.iitem_category_name:''}</span>
                }
                <input type="text" id={"item-category-"+this.props.index} className="hide" defaultValue={(this.props.items && this.props.items.iitem_category)?this.props.items.iitem_category:''} />
              </td>
              </>
            }
            <td>
            {(this.props.items.has_variation===true && (this.props.items.is_variation===false || this.props.items.is_variation===''))?
                    <span id={this.props.items.product_key+'_'+this.props.items.p_rand_key} style={{fontWeight:'bold'}}></span>
                    :
                <span className="item-total" id={"item-total-"+this.props.index} data-id={this.props.index}  data-target="item-total">₹{(this.props.items && this.props.items.total)?this.currency_format(this.props.items.total):0}</span>
              }
                <input type="hidden" className="form-control item-total-txt mob-item" id={"item-total-txt-"+this.props.index} data-id={this.props.index}  data-target="item-total-txt" defaultValue={(this.props.items && this.props.items.total)?this.props.items.total:0} />
            </td>
            <td className="item-rm">
              {(this.props.items.is_variation!==true) &&
                  <div className="item-mod-options" style={{display:'flex'}}>
                    <span className={(this.props.index==1 && this.props.count<=1)?'item-remove ':'item-remove'} data-id={this.props.index} onClick={this.confirmItemDelete}><FontAwesomeIcon icon={faTimes} />
                    </span>
                    <span className="rm-litem hide" id={"rm-litem-"+this.props.index}>
                      <span className="rm-yes" data-id={this.props.index} onClick={this.listItemRemove}>
                        Yes
                      </span>
                      <span className="rm-no" data-id={this.props.index} onClick={this.closeConfirmItemDel}>
                        No
                      </span>
                    </span>
                    <span data-id={this.props.index} onClick={this.listItemEdit}><FontAwesomeIcon icon={faPencilAlt} /></span>
                  </div>
              }
            </td>
          </tr>
        );
    }
}