import React,{Component} from 'react';
import firebase from './init';
import { ToastContainer, toast } from 'react-toastify';
let _this;
export default class FirebaseEvent extends Component {
    constructor() {
        super();
        this.state = {
         	data_url : '#',
        }
        _this = this;
    }
  componentDidMount(){
    try{
       const messaging = firebase.messaging();
    	messaging.onMessage((payload)=>{
    		let data = payload.data;
    		let message = data.message;
    		let data_url = data.url;
    		_this.setState({data_url:data_url});
    		toast(message);
    		if(document.getElementById("notif-click")){
    			document.getElementById("notif-click").click();
    		}
    		//console.log(payload);
    	})
    }catch(error){
      
    }
  }
     
    render() {
        return (
            <a href={this.state.data_url}>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName='toast-error'
                />
            </a>
        );
    }
}