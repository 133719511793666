import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
let db;
export default class TermsAndConditions extends Component {
    constructor() {
      super();
      this.state = {
          payment_modes : [],
          
      };
      _this = this;
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
      this.handleEnter = this.handleEnter.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    
    handleEnter(e){
      if(e.which===13){
        document.getElementById("add_more_terms").click();
        let com_cls = document.querySelectorAll(".t-and-c-box");
        let index = com_cls.length;
        setTimeout(function(){
          document.getElementById("terms-box-"+index).focus();
        },100);
      }
    }

    handleRemoveItem(e){
      let id = e.currentTarget.id;
      document.getElementById("terms-container-"+id).remove();
      let com_cls = document.getElementsByClassName("terms-container");
      let index = com_cls.length;
      if(index===1){
          let rmcls = document.querySelectorAll(".rm-item");
          for(let i=0;i<rmcls.length;i++){
              rmcls[i].classList.add('hide');
          }
      }
    }

    componentDidMount(){

      let com_cls = document.getElementsByClassName("terms-container");
      let index = com_cls.length;
      if(index>1){
          let rmcls = document.querySelectorAll(".rm-item");
          for(let i=0;i<rmcls.length;i++){
              rmcls[i].classList.remove('hide');
          }
      }

      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
       /* let api = app_api+'/get_payment_mode';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                PaymentMode[id] = payment_mode;
            });
           
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })*/

    }
  
   
    render() {
        let cls = '';
        if(this.props.index===0){
          cls = 'hide';
        }
        return (
          <div style={{padding:'10px'}} className="terms-container" id={"terms-container-"+this.props.index}>
           
            {this.props.show==='box' &&
              <li>
                <div className="row">
                  <div className="col-md-11">
                    <input type="text" className="form-control t-and-c-box" placeholder="Enter you terms and condition here." id={"terms-box-"+this.props.index} onKeyDown={this.handleEnter} defaultValue={this.props.terms} />
                  </div>
                  <div className="col-md-1 mrtop-10">
                    <FontAwesomeIcon icon={faTimes} onClick={this.handleRemoveItem} id={this.props.index} className={"rm-item pull-right fa-icon "+cls}/>
                  </div>
                </div>
              </li>
            }      
          </div>
        );
    }
}