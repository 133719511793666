import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
firebase.initializeApp({
  apiKey: "AIzaSyCZIpljwKP099e1a29DyG7UbZUbz367rw8",
  authDomain: "erp-solutions-m3.firebaseapp.com",
  projectId: "erp-solutions-m3",
  storageBucket: "erp-solutions-m3.appspot.com",
  messagingSenderId: "824068139968",
  appId: "1:824068139968:web:54ed4044d0975ed8397625",
  measurementId: "G-YZF56YK0Z4"
});
export default firebase