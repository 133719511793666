var CryptoJS = require("crypto-js");
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJSAesJson = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = jsonStr;
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}
const encrypt = (value) => {
	var encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), enc_key, {format: CryptoJSAesJson}).toString();
	return encrypted;
}
const decrypt = (value) => {
	let data = CryptoJSAesJson.parse(value);
	let decrypted = (CryptoJS.AES.decrypt(data, enc_key, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
	return decrypted;
}
const decrypt_sanitize = (value) => {
    try{
    if(value!==undefined){
        value = JSON.parse(window.atob(value));
        let data = CryptoJSAesJson.parse(value);
        let decrypted = (CryptoJS.AES.decrypt(data, enc_key, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
        return decrypted.replace(/^"(.*)"$/, '$1');
    }
    }catch(err){
        return false;
    }
}
export default {encrypt,decrypt,decrypt_sanitize}