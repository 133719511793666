import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let TypeList = [];
let _this;
let db;
export default class AccountGroups extends Component {
    constructor(props) {
      super(props);
      this.state = {
          group_list : [],
          delete_info : [],
          typeDefault : 0,
      };
      _this = this;
      
      this.is_access = check_access.is_access.bind(this);
    }
    handleChange = (e) =>{
        let contact_type = e.currentTarget.value;
        this.setState({typeDefault:contact_type});
    }
    componentDidMount(){
      
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              type : 'group'
           }
        let api = app_api+'/get_account_groups';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data.response;
            this.setState({ 'group_list': cursor });
        }.bind(this)).catch(function(res){
           
        })
    }



    editMode = (event) =>{ 
      event.preventDefault();
      let id = event.currentTarget.id;
      let idx = event.currentTarget.getAttribute('data-index');
      
      let group_list = this.state.group_list[idx];
      let link_id = group_list.link_id;
      let group_type = group_list.group_type;
      localStorage.setItem('link_id',link_id);
      var name = document.getElementById('agrp-txt-'+id).value;
      if(document.getElementById("openAddGroupModal")){
        document.getElementById("openAddGroupModal").click();
        setTimeout(() => {
          if(document.getElementById("handleSetLinkedID")){
            document.getElementById("handleSetLinkedID").click();
          }
          if(document.getElementById("account_group_id")){
            document.getElementById("account_group_id").value = id;
          }
          if(document.getElementById("account_group_name")){
            document.getElementById("account_group_name").value = name;
          }
          if(document.getElementById("link-type-select")){
            document.getElementById("link-type-select").value = link_id;
          }
          if(document.getElementById("group_type")){
            document.getElementById("group_type").value = group_type;
          }
        }, 100);
      }
    }

    deleteRow = (event) =>{
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
 
    render() {
        
        return (
          <div>
            {this.props.show==='list' &&
            
            <table className="table">
              <tbody>
                  {(this.state.group_list.length > 0) ?this.state.group_list.map( (value, index) => {
                   return (
                    <tr key={ value.id } id={'delete_account_group_'+value.id}>
                      <td>
                        <p id={'agrp-span-'+value.id } className="show l-span" style={{margin:'0px'}}>
                          { value.name }
                        </p>
                        <span style={{fontSize: '12px'}}>{value.link_name}</span>
                        <input type="text" defaultValue={value.name} className="form-control hide" id={'agrp-txt-'+value.id}/>
                        
                      </td>
                      <td>
                        <div id={'agrp-normal-mode-'+value.id} className="show">
                          <span  style={{textTransform: 'capitalize',fontSize: '11px',color: 'gray'}}>
                            {value.group_type}
                          </span>
                          <FontAwesomeIcon id={value.id} data-index={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                        
                       {(value.id>25) &&
                        <FontAwesomeIcon id={value.id} onClick={this.deleteRow} data_target="delete_confirm" data_value={value.name} data_id="delete_account_group" icon={faTrash} className="pull-right fa-icon"/>
                       }
                       
                        </div>
                       
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody>
            </table>
           
            }       

            {this.props.show==='dropdown' &&  
             
              <select onChange={this.handleChange} className="form-control validate form-field" id="account-group-select" name={this.props.name} value={this.state.typeDefault || this.props.default} tabindex={this.props.tabindex}>
                    <option value=''>Select Account Group</option>
                  {(this.state.group_list.length > 0) ? this.state.group_list.map( (value, index) => {
                   return (
                      <option value={value.id} key={value.id}>{value.name}</option>
                    )
                  }) : ''}
              </select>
             
            }   
            
            </div>
        );
    }
}