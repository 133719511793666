import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';
export default class Pager extends Component {
    constructor() {
      super();
      this.state = {
          prev_link :'',
          current_page : '',
          total_page : '',
          next_link:''
      };
     
    }
    componentDidMount(){
      let curl = window.location.href;
      let cpage;
    
      const store_tab = curl.match(/admin-area\/stores\/([a-z_A-Z]+$)/i);
      const store_page_tab = curl.match(/admin-area\/stores\/([a-z_A-Z]+)\/(\d+$)/i);

      const stores_home = curl.match(/admin-area$/i);
      const stores_home_slash = curl.match(/admin-area\/$/i);
      const stores = curl.match(/admin-area\/stores$/i);
      const stores_slash = curl.match(/admin-area\/stores\/$/i);
      
      
      if(stores_home || stores_home_slash || stores || stores_slash){
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/admin-area/stores/active/2'});
      }else if(store_tab){
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/admin-area/stores/'+store_tab[1]+'/2'});
      }else if(store_page_tab){
        cpage = parseInt(store_page_tab[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/admin-area/stores/'+store_page_tab[1]+'/'+next;
        let prev_link = '/admin-area/stores/'+store_page_tab[1]+'/'+prev;
        if(prev===1){
          prev_link = '/admin-area/stores/'+store_page_tab[1];
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
      }
    } 



    render() {
        return (
             <div className="page-nav">
                <div className="navd"><a href={this.state.prev_link}><div className="navd"><FontAwesomeIcon className="cont" icon={faCaretLeft}/></div></a></div>
                <div className="navd center"><p className="msg">{this.state.current_page} of {this.props.total_page}</p></div>
                <div className="navd"><a href={(this.state.current_page===this.props.total_page?'#':this.state.next_link)}><div className="navd"><FontAwesomeIcon  className="cont" icon={faCaretRight}/></div></a></div>
              </div>
        );
    }
}