import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../../utils.js';
let {enc_key,app_api,user_info} = require('../../library.js');
export default class UserRoleChecks extends Component {
     constructor() {
      super();
      this.state = {
    
      }
      this.handleRoleExpand = this.handleRoleExpand.bind(this);     
      this.handleCheck = this.handleCheck.bind(this);     
      this.handleSaveRole = this.handleSaveRole.bind(this);     
      this.handleValueCheck = this.handleValueCheck.bind(this);     
       
    }

    handleValueCheck(value){
        if(this.props.user_role_info){
          let data = this.props.user_role_info.roles;
          if(data.indexOf(value)>=0){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
    }

    handleSaveRole(e){
      let id = e.currentTarget.id;
      let user_role_name = document.getElementById("user-role-name").value;
      let user_role_id = document.getElementById("user-role-id").value;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let items = document.querySelectorAll(".roles-ul li input");
      let selected_roles = [];
      for(var i=0;i<items.length;i++){
        if(items[i].checked===true){
            selected_roles.push(items[i].value);
        }
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          role_name:user_role_name,
          roles:selected_roles,
          id:user_role_id,
      }
      let api = app_api+'/create_role';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    handleRoleExpand(e) {
        let target = e.currentTarget.getAttribute('data-id');
        let elem = document.getElementById(target);
        elem.classList.toggle("hide");
    }
    handleCheck(e){
      let target = e.currentTarget.getAttribute('data-id');
      let is_check = e.currentTarget.checked;
      let items = document.querySelectorAll('#'+target+" li input");
      
      for(let i=0;i<items.length;i++){
        items[i].checked = is_check;
        
      }
    }

    render() {
        return (
            <div className="box-container mrtop-50" id="users-role-container">
              <div className="box-head">
                <label>SET USER ROLE FOR {this.props.role_name.toUpperCase()}</label> 
                <input type="hidden" defaultValue={this.props.role_name} id="user-role-name" />
                <input type="hidden" defaultValue={this.props.role_id} id="user-role-id" />
              </div>
              <div className="roles-assign-block">
                  <ul key="0" className="roles-ul">
                      <li key="1" className="parent">
                       <input type="checkbox" onChange={this.handleCheck} data-id="cb-billings" value="billing" defaultChecked={this.handleValueCheck('billing')}/>
                       <div onClick={this.handleRoleExpand} data-id="cb-billings">
                         Billings 
                         <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                        </div>
                      </li>
                      <ul key="2" id="cb-billings" className="hide">
                          <li key="3" className="parent">
                            <input type="checkbox" onChange={this.handleCheck} data-id="cb-sale" value="billing-sale"  defaultChecked={this.handleValueCheck('billing-sale')}/> Sales</li>
                          <ul key="4" id="cb-sale">
                            <li key="5"><input type="checkbox" value="billing-sale-view"   defaultChecked={this.handleValueCheck('billing-sale-view')}/> View</li>
                            <li key="6"><input defaultChecked={this.handleValueCheck('billing-sale-create')} type="checkbox" value="billing-sale-create"/> Create</li>
                            <li key="7"><input  defaultChecked={this.handleValueCheck('billing-sale-modify')} type="checkbox" value="billing-sale-modify"/> Modify</li>
                            <li key="8"><input defaultChecked={this.handleValueCheck('billing-sale-delete')} type="checkbox" value="billing-sale-delete"/> Delete</li>
                          </ul>
                          <li key="10" className="parent"><input defaultChecked={this.handleValueCheck('billing-purchase')} type="checkbox" onChange={this.handleCheck} data-id="cb-purchase"  value="billing-purchase"/> Purchase</li>
                          <ul key="11" id="cb-purchase">
                            <li key="12"><input defaultChecked={this.handleValueCheck('billing-purchase-view')} type="checkbox" value="billing-purchase-view"/> View</li>
                            <li key="13"><input defaultChecked={this.handleValueCheck('billing-purchase-create')} type="checkbox" value="billing-purchase-create"/> Create</li>
                            <li key="14"><input defaultChecked={this.handleValueCheck('billing-purchase-modify')} type="checkbox" value="billing-purchase-modify"/> Modify</li>
                            <li key="15"><input defaultChecked={this.handleValueCheck('billing-purchase-delete')} type="checkbox" value="billing-purchase-delete"/> Delete</li>
                          </ul>
                          <li key="17" className="parent"><input defaultChecked={this.handleValueCheck('billing-sales-return')} type="checkbox"  onChange={this.handleCheck} data-id="cb-sale-return" value="billing-sales-return"/> Sales Retrun</li>
                          <ul key="18" id="cb-sale-return">
                            <li key="19"><input defaultChecked={this.handleValueCheck('billing-sales-return-view')} type="checkbox" value="billing-sales-return-view"/> View</li>
                            <li key="20"><input defaultChecked={this.handleValueCheck('billing-sales-return-create')} type="checkbox" value="billing-sales-return-create"/> Create</li>
                            <li key="21"><input defaultChecked={this.handleValueCheck('billing-sales-return-modify')} type="checkbox" value="billing-sales-return-modify"/> Modify</li>
                            <li key="22"><input defaultChecked={this.handleValueCheck('billing-sales-return-delete')} type="checkbox" value="billing-sales-return-delete"/> Delete</li>
                          </ul> 
                          <li key="23" className="parent"><input defaultChecked={this.handleValueCheck('billing-purchase-return')} type="checkbox"  onChange={this.handleCheck} data-id="cb-purchase-return" value="billing-purchase-return"/> Purchase Retrun</li>
                          <ul key="24" id="cb-purchase-return">
                            <li key="25"><input defaultChecked={this.handleValueCheck('billing-purchase-return-view')} type="checkbox" value="billing-purchase-return-view"/> View</li>
                            <li key="26"><input defaultChecked={this.handleValueCheck('billing-purchase-return-create')} type="checkbox" value="billing-purchase-return-create"/> Create</li>
                            <li key="27"><input defaultChecked={this.handleValueCheck('billing-purchase-return-modify')} type="checkbox" value="billing-purchase-return-modify"/> Modify</li>
                            <li key="28"><input defaultChecked={this.handleValueCheck('billing-purchase-return-delete')} type="checkbox" value="billing-purchase-return-delete"/> Delete</li>
                          </ul>
                          <li key="2324" className="parent"><input defaultChecked={this.handleValueCheck('billing-quotation')} type="checkbox"  onChange={this.handleCheck} data-id="cb-quotation" value="billing-quotation"/> Quotation</li>
                          <ul key="24" id="cb-quotation">
                            <li key="25"><input defaultChecked={this.handleValueCheck('billing-quotation-view')} type="checkbox" value="billing-quotation-view"/> View</li>
                            <li key="26"><input defaultChecked={this.handleValueCheck('billing-quotation-create')} type="checkbox" value="billing-quotation-create"/> Create</li>
                            <li key="27"><input defaultChecked={this.handleValueCheck('billing-quotation-modify')} type="checkbox" value="billing-quotation-modify"/> Modify</li>
                            <li key="28"><input defaultChecked={this.handleValueCheck('billing-quotation-delete')} type="checkbox" value="billing-quotation-delete"/> Delete</li>
                          </ul>
                          <li key="2323" className="parent"><input defaultChecked={this.handleValueCheck('billing-stop-editing-date')} type="checkbox"  onChange={this.handleCheck} data-id="cb-stop-editing-date" value="billing-stop-editing-date"/> Stop Editing of Date</li>
                          <li key="2333" className="parent"><input defaultChecked={this.handleValueCheck('billing-stop-editing-price')} type="checkbox"  onChange={this.handleCheck} data-id="cb-stop-editing-price" value="billing-stop-editing-price"/> Stop Edit of Price</li>
                          <li key="2343" className="parent"><input defaultChecked={this.handleValueCheck('billing-bgdiscount')} type="checkbox"  onChange={this.handleCheck} data-id="cb-bgdiscount" value="billing-bgdiscount"/> Discount</li>
                          <li key="2353" className="parent"><input defaultChecked={this.handleValueCheck('billing-broundoff')} type="checkbox"  onChange={this.handleCheck} data-id="cb-broundoff" value="billing-broundoff"/> Round Off</li>
                      </ul>
                      <li key="29" className="parent">
                          <input defaultChecked={this.handleValueCheck('payments')} type="checkbox" onChange={this.handleCheck} data-id="cb-payments" value="payments"/>
                          <div  onClick={this.handleRoleExpand} data-id="cb-payments" >
                          Payments
                          <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                          </div>
                      </li>
                      <ul key="30" id="cb-payments" className="hide">
                        <li key="333" className="parent">
                            <input defaultChecked={this.handleValueCheck('payments-in')} type="checkbox" onChange={this.handleCheck} data-id="cb-paymnets-in" value="payments-in"/> Payments IN(+)</li>
                          <ul key="444" id="cb-paymnets-in">
                            <li key="555"><input defaultChecked={this.handleValueCheck('payments-in-view')} type="checkbox" value="payments-in-view"/> View</li>
                            <li key="666"><input defaultChecked={this.handleValueCheck('payments-in-create')} type="checkbox" value="payments-in-create"/> Create</li>
                            <li key="777"><input defaultChecked={this.handleValueCheck('payments-in-modify')} type="checkbox" value="payments-in-modify"/> Modify</li>
                            <li key="888"><input defaultChecked={this.handleValueCheck('payments-in-delete')} type="checkbox" value="payments-in-delete"/> Delete</li>
                          </ul>
                          <li key="101" className="parent"><input defaultChecked={this.handleValueCheck('payments-out')} type="checkbox" onChange={this.handleCheck} data-id="cb-paymnets-out"  value="payments-out"/> Payments OUT(-)</li>
                          <ul key="111" id="cb-paymnets-out">
                            <li key="122"><input defaultChecked={this.handleValueCheck('payments-out-view')} type="checkbox" value="payments-out-view"/> View</li>
                            <li key="133"><input defaultChecked={this.handleValueCheck('payments-out-create')} type="checkbox" value="payments-out-create"/> Create</li>
                            <li key="144"><input defaultChecked={this.handleValueCheck('payments-out-modify')} type="checkbox" value="payments-out-modify"/> Modify</li>
                            <li key="155"><input defaultChecked={this.handleValueCheck('payments-out-delete')} type="checkbox" value="payments-out-delete"/> Delete</li>
                          </ul>
                      </ul>
                      <li key="29" className="parent">
                          <input defaultChecked={this.handleValueCheck('debit_credit_note')} type="checkbox" onChange={this.handleCheck} data-id="cb-debit_credit_note" value="debit_credit_note"/>
                          <div data-id="cb-debit_credit_note" >
                            Credit Debit Notes
                          </div>
                      </li>
                      <li key="35" className="parent">
                        <input defaultChecked={this.handleValueCheck('inventory')} type="checkbox" onChange={this.handleCheck} data-id="cb-inventory" value="inventory"/>
                        <div onClick={this.handleRoleExpand} data-id="cb-inventory">
                          Inventory  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                        </div>
                      </li>
                      <ul key="36" id="cb-inventory" className="hide">
                        <li key="37"><input defaultChecked={this.handleValueCheck('inventory-view')} type="checkbox" value="inventory-view"/> View</li>
                        <li key="38"><input defaultChecked={this.handleValueCheck('inventory-create')} type="checkbox" value="inventory-create"/> Create</li>
                        <li key="39"><input defaultChecked={this.handleValueCheck('inventory-modify')} type="checkbox" value="inventory-modify"/> Modify</li>
                        <li key="40"><input defaultChecked={this.handleValueCheck('inventory-delete')} type="checkbox" value="inventory-delete"/> Delete</li>
                        <li key="401"><input defaultChecked={this.handleValueCheck('inventory-show-supplier')} type="checkbox" value="inventory-show-supplier"/> Show Supplier</li>
                        <li key="402"><input defaultChecked={this.handleValueCheck('inventory-show-purchase-price')} type="checkbox" value="inventory-show-purchase-price"/> Purchase Price</li>
                      </ul>
                      <li key="404" className="parent" >
                        <input defaultChecked={this.handleValueCheck('expense')} type="checkbox" onChange={this.handleCheck} data-id="cb-expense" value="expense"/>
                        <div onClick={this.handleRoleExpand} data-id="cb-expense">
                          Expenses  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                         </div>
                      </li>
                      <ul key="420" id="cb-expense" className="hide">
                        <li key="430"><input defaultChecked={this.handleValueCheck('expense-view')} type="checkbox" value="expense-view"/> View</li>
                        <li key="440"><input defaultChecked={this.handleValueCheck('expense-create')} type="checkbox" value="expense-create"/> Create</li>
                        <li key="450"><input defaultChecked={this.handleValueCheck('expense-modify')} type="checkbox" value="expense-modify"/> Modify</li>
                        <li key="460"><input defaultChecked={this.handleValueCheck('expense-delete')} type="checkbox" value="expense-delete"/> Delete</li>
                      </ul>
                      <li key="470" className="parent" >
                        <input defaultChecked={this.handleValueCheck('transport')} type="checkbox" onChange={this.handleCheck} data-id="cb-transport" value="transport"/>
                        <div onClick={this.handleRoleExpand} data-id="cb-transport">
                          Transport  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                         </div>
                      </li>
                      <ul key="480" id="cb-transport" className="hide">
                        <li key="490"><input defaultChecked={this.handleValueCheck('transport-view')} type="checkbox" value="transport-view"/> View</li>
                        <li key="500"><input defaultChecked={this.handleValueCheck('transport-create')} type="checkbox" value="transport-create"/> Create</li>
                        <li key="501"><input defaultChecked={this.handleValueCheck('transport-modify')} type="checkbox" value="transport-modify"/> Modify</li>
                        <li key="502"><input defaultChecked={this.handleValueCheck('transport-delete')} type="checkbox" value="transport-delete"/> Delete</li>
                      </ul>
                      <li key="41" className="parent" >
                          <input defaultChecked={this.handleValueCheck('contacts')} type="checkbox" onChange={this.handleCheck} data-id="cb-contacts" value="contacts"/>
                          <div onClick={this.handleRoleExpand} data-id="cb-contacts">
                            Contacts  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                           </div>
                      </li>
                     
                      <ul key="42" id="cb-contacts" className="hide">
                        <li key="43"><input defaultChecked={this.handleValueCheck('contacts-view')} type="checkbox" value="contacts-view"/> View</li>
                        <li key="44"><input defaultChecked={this.handleValueCheck('contacts-create')} type="checkbox" value="contacts-create"/> Create</li>
                        <li key="45"><input defaultChecked={this.handleValueCheck('contacts-modify')} type="checkbox" value="contacts-modify"/> Modify</li>
                        <li key="46"><input defaultChecked={this.handleValueCheck('contacts-delete')} type="checkbox" value="contacts-delete"/> Delete</li>
                      </ul>
                      <li key="403" className="parent" >
                          <input defaultChecked={this.handleValueCheck('reports')} type="checkbox" onChange={this.handleCheck} data-id="cb-reports" value="reports"/>
                          <div onClick={this.handleRoleExpand} data-id="cb-reports">
                            Reports  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                           </div>
                      </li>
                     
                      <ul key="403" id="cb-reports" className="hide">
                        <li key="404"><input defaultChecked={this.handleValueCheck('reports-sales-dashboard')} type="checkbox" value="reports-sales-dashboard"/> Sales Dasboard</li>
                        <li key="405"><input defaultChecked={this.handleValueCheck('reports-ledger')} type="checkbox" value="reports-ledger"/> Ledger</li>
                        <li key="406"><input defaultChecked={this.handleValueCheck('reports-daily-sales')} type="checkbox" value="reports-daily-sales"/> Sales Report</li>
                        <li key="407"><input defaultChecked={this.handleValueCheck('reports-transactions')} type="checkbox" value="reports-transactions"/> Transactions</li>
                        <li key="408"><input defaultChecked={this.handleValueCheck('reports-inventory')} type="checkbox" value="reports-inventory"/> Inventories</li>
                        <li key="409"><input defaultChecked={this.handleValueCheck('reports-stock-ledger')} type="checkbox" value="reports-stock-ledger"/> Stock Ledger</li>
                        <li key="410"><input defaultChecked={this.handleValueCheck('reports-expenses')} type="checkbox" value="reports-expenses"/> Expenses</li>
                        <li key="411"><input defaultChecked={this.handleValueCheck('reports-profit-loss')} type="checkbox" value="reports-profit-loss"/> Profit Loss</li>
                        <li key="412"><input defaultChecked={this.handleValueCheck('reports-tax')} type="checkbox" value="reports-tax"/> Tax</li>
                        <li key="413"><input defaultChecked={this.handleValueCheck('reports-tcs-tax')} type="checkbox" value="reports-tcs-tax"/> TCS Tax</li>
                        <li key="414"><input defaultChecked={this.handleValueCheck('reports-pending-invoices')} type="checkbox" value="reports-pending-invoices"/> Pending Invoices</li>
                        <li key="415"><input defaultChecked={this.handleValueCheck('reports-salesman')} type="checkbox" value="reports-salesman"/> Salesman Report</li>
                        <li key="415"><input defaultChecked={this.handleValueCheck('reports-audit-trial')} type="checkbox" value="reports-audit-trial"/> Audit Trial</li>
                        <li key="415"><input defaultChecked={this.handleValueCheck('reports-trial-balance')} type="checkbox" value="reports-audit-trial"/> Trial Balance</li>
                      </ul>
                      
                      <li key="403" className="parent">
                       <input type="checkbox" onChange={this.handleCheck} data-id="cb-reports" value="reports" defaultChecked={this.handleValueCheck('reports')}/> 
                        <div>
                          Reports 
                         </div> 
                      </li>
                      <li key="47" className="parent">
                      <input defaultChecked={this.handleValueCheck('settings')} type="checkbox" onChange={this.handleCheck} data-id="cb-settings" value="settings"/>
                      <div  onClick={this.handleRoleExpand} data-id="cb-settings">
                      Settings  <FontAwesomeIcon icon={faChevronRight} className="pull-right margin-5 fa-icon"/>
                        </div>
                       </li>
                      <ul key="48" id="cb-settings" className="hide">
                        <li key="49" className="parent"><input defaultChecked={this.handleValueCheck('settings-company-details')} type="checkbox"  onChange={this.handleCheck} data-id="cb-company-details" value="settings-company-details"/> Company Details</li>
                        <ul key="50" id="cb-company-details">
                          <li key="51"><input defaultChecked={this.handleValueCheck('settings-company-details-view')} type="checkbox" value="settings-company-details-view"/> View</li>
                          <li key="52"><input defaultChecked={this.handleValueCheck('settings-company-details-create')} type="checkbox" value="settings-company-details-create"/> Create</li>
                          <li key="53"><input defaultChecked={this.handleValueCheck('settings-company-details-modify')} type="checkbox" value="settings-company-details-modify"/> Modify</li>
                          <li key="54"><input defaultChecked={this.handleValueCheck('settings-company-details-delete')} type="checkbox" value="settings-company-details-delete"/> Delete</li>
                        </ul>
                        <li key="55" className="parent"><input defaultChecked={this.handleValueCheck('settings-bill-payment')} type="checkbox" onChange={this.handleCheck} data-id="cb-bil-pay" value="settings-bill-payment"/> Billings & Payments</li>
                        <ul key="56" id="cb-bil-pay">
                          <li key="57"><input defaultChecked={this.handleValueCheck('settings-bill-payment-view')} type="checkbox" value="settings-bill-payment-view"/> View</li>
                          <li key="58"><input defaultChecked={this.handleValueCheck('settings-bill-payment-create')} type="checkbox" value="settings-bill-payment-create"/> Create</li>
                          <li key="59"><input defaultChecked={this.handleValueCheck('settings-bill-payment-modify')} type="checkbox" value="settings-bill-payment-modify"/> Modify</li>
                          <li key="60"><input defaultChecked={this.handleValueCheck('settings-bill-payment-delete')} type="checkbox" value="settings-bill-payment-delete"/> Delete</li>
                        </ul>
                        <li key="61" className="parent"><input defaultChecked={this.handleValueCheck('settings-inventory')} type="checkbox" onChange={this.handleCheck} data-id="cb-set-inven" value="settings-inventory"/> Inventory</li>
                        <ul key="62" id="cb-set-inven">
                          <li key="63"><input defaultChecked={this.handleValueCheck('settings-inventory-view')} type="checkbox" value="settings-inventory-view"/> View</li>
                          <li key="64"><input defaultChecked={this.handleValueCheck('settings-inventory-create')} type="checkbox" value="settings-inventory-create"/> Create</li>
                          <li key="65"><input defaultChecked={this.handleValueCheck('settings-inventory-modify')} type="checkbox" value="settings-inventory-modify"/> Modify</li>
                          <li key="66"><input defaultChecked={this.handleValueCheck('settings-inventory-delete')} type="checkbox" value="settings-inventory-delete"/> Delete</li>
                        </ul>
                        <li key="67" className="parent"><input defaultChecked={this.handleValueCheck('settings-contacts')} type="checkbox" onChange={this.handleCheck} data-id="cb-set-contact" value="settings-contacts"/> Contacts</li>
                        <ul key="68" id="cb-set-contact">
                          <li key="69"><input defaultChecked={this.handleValueCheck('settings-contacts-view')} type="checkbox" value="settings-contacts-view"/> View</li>
                          <li key="70"><input defaultChecked={this.handleValueCheck('settings-contacts-create')} type="checkbox" value="settings-contacts-create"/> Create</li>
                          <li key="71"><input defaultChecked={this.handleValueCheck('settings-contacts-modify')} type="checkbox" value="settings-contacts-modify"/> Modify</li>
                          <li key="72"><input defaultChecked={this.handleValueCheck('settings-contacts-delete')} type="checkbox" value="settings-contacts-delete"/> Delete</li>
                        </ul>
                      
                        <li key="73" className="parent"><input defaultChecked={this.handleValueCheck('settings-user-management')} type="checkbox"  onChange={this.handleCheck} data-id="cb-set-user-management" value="settings-user-management"/> User Management</li>
                        <ul key="74" id="cb-set-user-management">
                          <li key="75"><input defaultChecked={this.handleValueCheck('settings-user-management-view')} type="checkbox" value="settings-user-management-view"/> View</li>
                          <li key="76"><input defaultChecked={this.handleValueCheck('settings-user-management-create')} type="checkbox" value="settings-user-management-create"/> Create</li>
                          <li key="78"><input defaultChecked={this.handleValueCheck('settings-user-management-modify')} type="checkbox" value="settings-user-management-modify"/> Modify</li>
                          <li key="79"><input defaultChecked={this.handleValueCheck('settings-user-management-delete')} type="checkbox" value="settings-user-management-delete"/> Delete</li>
                        </ul>
                        <li key="80" className="parent"><input defaultChecked={this.handleValueCheck('settings-expense')} type="checkbox"  onChange={this.handleCheck} data-id="cb-set-expense" value="settings-expense"/> Expense</li>
                        <ul key="81" id="cb-set-expense">
                          <li key="82"><input defaultChecked={this.handleValueCheck('settings-expense-view')} type="checkbox" value="settings-expense-view"/> View</li>
                          <li key="83"><input defaultChecked={this.handleValueCheck('settings-expense-create')} type="checkbox" value="settings-expense-create"/> Create</li>
                          <li key="84"><input defaultChecked={this.handleValueCheck('settings-expense-modify')} type="checkbox" value="settings-expense-modify"/> Modify</li>
                          <li key="85"><input defaultChecked={this.handleValueCheck('settings-expense-delete')} type="checkbox" value="settings-expense-delete"/> Delete</li>
                        </ul>
                        <li key="86" className="parent"><input defaultChecked={this.handleValueCheck('settings-barcode')} type="checkbox"  onChange={this.handleCheck} data-id="cb-set-barcode" value="settings-barcode"/> Barcode</li>
                      </ul>
                      <li key="87" className="parent">
                       <input type="checkbox" onChange={this.handleCheck} data-id="cb-notifications" value="notifications" defaultChecked={this.handleValueCheck('notifications')}/>
                       <div>
                         Notifications 
                      </div>
                      </li>
                      <li key="88" className="parent">
                       <input type="checkbox" onChange={this.handleCheck} data-id="cb-encprofit" value="encprofit" defaultChecked={this.handleValueCheck('encprofit')}/>
                       <div>
                         View Encrypted Profit 
                      </div>
                      </li>
                  </ul>
              </div>
              <div className="save-role-blk">
                <button type="button" className="btn btn-success" onClick={this.handleSaveRole} id="add-role">Save Role</button>
                <button type="button" id="add-role-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
              </div>
            </div>
        );
    }
}

