import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faSpinner,faPrint,faDownload,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");

export default class ToggleOrderPopup extends Component {
     constructor() {
      super();
      this.state = {
         is_open_popup : false,
         enable_orders:false
      }
    }

    componentDidMount(){
        let curl = window.location.href;
        var isMobile = utils.isMobile();
        let _this = this;
        const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
        const create_quotation = curl.match(/create-quotation\/(\d+$)/i);
        if(add_billing_type===null && create_quotation===null){
          if(isMobile){
            document.addEventListener("contextmenu", (e) => {
              _this.setState({is_open_popup:true});
            });
          }
        }

      let _send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        keys:"'enable_orders'",
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
    }
    let bc_api = app_api+'/get_settings';
    axios.post(bc_api, _send_data)
      .then(function (response) {
        let resp = response.data;
        
        if(resp.enable_orders){
          let ps = resp.enable_orders;
          this.setState({enable_orders:ps});
        }
        
        
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
    }
    handleClosePopup = () =>{
        this.setState({is_open_popup:false});
    }
    handleToggleOrders =()=>{
        let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            store_state : user_info.state,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
        }
        let api = app_api+'/toggle_enable_orders';
        axios.post(api, body)
        .then(function (response) {
            window.location.reload();
          }.bind(this)).catch(function(res){
            
        })
    }
    render() {
        return (
          <>
          {(this.state.is_open_popup) &&
            <div className="modal" style={{display:'block'}}>
                <div className="modal-dialog modal-confirm" style={{width:'auto'}}>
                <div className="modal-content">
                    <div className="modal-header flex-column">
                    <div className="icon-box" style={{border:'none'}}>
                        <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                    </div>            
                    <h5 className="modal-title w-100" style={{marginTop:'0px',fontSize:'16px'}}>ARE YOU SURE YOU WANT TO {(this.state.enable_orders!==true)?'ENABLE':'DISABLE'} ORDERS?</h5> 
                    </div>
                    <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>No</button>
                    <button type="button" className="btn btn-danger" onClick={this.handleToggleOrders}>Yes</button>
                    </div>
                </div>
                </div>
            </div>   
            }
          </>
        );
    }
}