import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import ModalBox from "./modal-box";
import BarcodeTemplate from "./settings/barcode-template";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import ReactWhatsapp from 'react-whatsapp';
import ClosedFYButton from "./closed-fy-button";
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import DrawerLink from './open-drawer-link.js';
import utils from '../utils.js';
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
export default class ViewQuotation extends Component {
    constructor() {
        super();
        this.state = {
            print_target:'print',
            show_loader : true,
            is_reedeem : false,
            is_pending : false,
            is_payment : false,
            payment_status : '',
            update_id : 0,
            response_id : 0,
            referer : 1,
            more_rows : '',
            return_more_rows : '',
            billing_type : 'New Sale',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  '',
            invoiceDate:  '',
            contact_info : '',
            billing_data : '',
            target : 'sale',
            issued_by : '',
            agent_name : '',
            assisted_by : '',
            round_off_opt : '',
            round_off_opt2 : '',
            round_off : '',
            round_off_sym :'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            sale_info : [],
            total_quantity:'',
            view_branch_id:'',
            print_settings:[],
            page_size:'A4',
            invoice_title:'',
            terms_and_conditions : [],
            bill_status : '',
            payment_modes:[],
            barcode_name_on_the_bill_view:false,
            item_name_on_the_bill_view:false,
            is_print : false,
            is_on_account_return : false,
            return_goods_bills : [],
            barcode_template_data:[],
            barcode_template:[],
            barcode_print_data:[],
            is_direct_pay : false,
            bill_view_response:[],
            whatsapp_msg : '',
            whatsapp_phone : '',
            dispatched_info : '',
            advance_sale_data : false,
            is_fy_closed : false,
            is_items_visible : true,
        };
        this.barcode_template  = [];
        this.tax_slab  = [];
        this.csgsttaxable =[];
        this.igsttaxable =[];

        this.csgsttaxnet  = [];
        this.igsttaxnet  = [];

        this.igst_slab_key  = [];
        this.sgst_slab_key  = [];
        this.cgst_slab_key  = [];
     
        this.more_rows = [];
        this.return_more_rows = [];
        this.types = {sales:"New Sale",purchase:"New Purchase",sales_return:"New Sales Return",purchase_return:"New Purchase Return"};
        this.referer = {sales:1,purchase:2,sales_return:3,purchase_return:4};
        this.sequence = [];
        this.send_data_items = [];
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.delteBilling = this.delteBilling.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.closeBarcodeModel = this.closeBarcodeModel.bind(this);
        this.openBarcodePrintPreview = this.openBarcodePrintPreview.bind(this);
        this.handleBarcodePrint = this.handleBarcodePrint.bind(this);
        this.handleShare = this.handleShare.bind(this);
        this.shareMessage = this.shareMessage.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }

    shareMessage(){
      let data = {
                    target:'view',
                    title:this.state.billing_type,
                    contact_name:this.state.contact_info.item_name,
                    contact_phone:this.state.contact_info.phone_1,
                    currrent_sequence:this.state.currrent_sequence,
                    bill_date:this.state.billing_data.bill_date,
                    bill_time:this.state.billing_data.bill_time,
                    more_rows:this.state.more_rows,
                    bill_total:this.state.billing_data.total,
                }
      let txt_message =  utils.shareBillMessage(data);
      this.setState({whatsapp_msg:txt_message});
      let mobile = this.state.contact_info.phone_1;
      mobile = utils.check_mobile_number(mobile);
      this.setState({whatsapp_phone:mobile});
    }

    handleShare(e){
      
    //console.log(txt_message);
    
      /*let data = {
        title: title,
        text: txt_message
      }*/
      //utils.handleShare(data);
    }
    closeBarcodeModel(event) {
      var element = document.getElementById('barcode-print-preview');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    openBarcodePrintPreview(event) {
      let thisid = event.currentTarget.id;
     
      let barcode_template_data = this.state.barcode_template_data;
      let height = barcode_template_data[0].height;
      let width = barcode_template_data[0].width;
      let values = barcode_template_data[0].value;

      let name = this.state.barcode_template_data[0].name;

      let prows = this.state.barcode_print_data;
      let template = '';
      this.barcode_template = [];
      for(let r=0 ; r<prows.length; r++){
        let billing_data = prows[r];
        let quantity = prows[r].availability;
        if(quantity!==""){
          quantity = parseInt(quantity);
          for(let q=0;q<quantity;q++){
            this.barcode_template.push(<BarcodeTemplate barcode_template_data={barcode_template_data} id={q} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width}/>);
          } 
          this.setState({barcode_template:this.barcode_template});
        }
      }

      var element = document.getElementById('barcode-print-preview');
      element.classList.remove("fade");
      element.style.display="block"; 
      
    }

     handleBarcodePrint(e){
            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-barcode-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page {margin: 0;}.bcode-temp{text-transform:uppercase;}.react-draggable {position: absolute !important;line-height: 0;white-space: nowrap;}.react-draggable.center{position: relative !important;}.react-draggable.left{left: 0;}.react-draggable.right{right: 0;}.drag-inner.center{text-align:center;}.temp-elem-name{margin: 0;}</style>'); 
            
            a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
            
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 

    handlePrint(e){ 
      let type = e.currentTarget.id;
      document.getElementById(type+'-lbl').classList.add("hide");
      document.getElementById(type+'-btn-spinner').classList.remove("hide");
      this.setState({print_target:type,response_id:this.state.update_id});
      setTimeout(function(){
        document.getElementById(type+'-lbl').classList.remove("hide");
        document.getElementById(type+'-btn-spinner').classList.add("hide");
      },3000);
    } 
    delteBilling(event){
      let del_option = (this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel';
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      var element = document.getElementById('delete_confirm');
      element.classList.remove("fade");
      element.style.display="block";
      let delete_value = 'billing_view';
      if(this.state.bill_status==="On Hold"){
        delete_value = 'hold_view';
      }
      document.getElementById("deleteValue").value=delete_value;
      document.getElementById("deleteIndex").value=id;
      document.getElementById("deleteTarget").value=data_id;
      del_option = del_option.toLowerCase();
      document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';   
    }
    componentDidMount(){

      let curl = window.location.href;
      let view_type = '';
      const view_billing = curl.match(/view-quotation\/(\d+$)/i);
    
      let print_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          keys:"'barcode_name_on_the_bill_view','item_name_on_the_bill_view','advance_sale_data','is_financial_year_closed'"
      }
      let set_api = app_api+'/get_settings';
      axios.post(set_api, print_settings)
        .then(function (response) {
          let resp = response.data;
   
          if(resp.barcode_name_on_the_bill_view){
            let ps = resp.barcode_name_on_the_bill_view;
            this.setState({barcode_name_on_the_bill_view:ps});
          }
          if(resp.item_name_on_the_bill_view){
            let ps = resp.item_name_on_the_bill_view;
            this.setState({item_name_on_the_bill_view:ps});
          }
          if(resp.advance_sale_data){
            let ps = resp.advance_sale_data;
            this.setState({advance_sale_data:ps});
          }
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      })


      if(view_billing){ // EDIT MODE
          let id ='';
          let type = '';
        
        id = view_billing[1];
        type ='sales';
          

          let t = this.types[type];
          let referer = this.referer[type];
          this.setState({referer:referer});

          this.setState({update_id:id});
          this.setState({billing_type:t});
          this.setState({target:type});
         
         
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              store_state : user_info.state,
              type : t,
              id : parseInt(id),
              action : 'view',
           }
        let api = app_api+'/billings/get_quotation';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              
              if(data.total===undefined){
                window.location.href = '/';
              }
              this.setState({bill_view_response:resp})
              let assisted_by = data.assisted_by;
              let agent_name = data.agent_name;
              this.setState({sale_info:data.sale_info});
              this.setState({view_branch_id:data.branch_id});

              if(t==='New Purchase'){
                this.setState({barcode_print_data:resp.barcode_print_data});
              }

              if(data.total_unit<data.total_unit_count){
                this.setState({is_items_visible:false});
              }

              this.setState({billing_data:data,dispatched_info:resp.dispatched_info,bill_status:data.bill_status});
              
              let is_direct_pay = data.is_direct_pay;
              this.setState({is_direct_pay:is_direct_pay});
              this.setState({show_loader:false});

              if(data.user_info && data.user_info[0]){
                this.setState({issued_by:data.user_info[0].username});
              }
              if(assisted_by>0 && assisted_by!==""){
                let _this = this;
                let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
                dbReq.onupgradeneeded = function(event) {
                  db = event.target.result;
                }
               
                dbReq.onsuccess = function(event) {
                  db = event.target.result;
                  try{
                    let ContactList = [];  
                    let ContactOptions = [];  
                    let transaction = db.transaction("contacts", "readwrite");
                    let contacts = transaction.objectStore("contacts");
                    let request = contacts.get(assisted_by);
                    request.onsuccess = function(e) {
                      let cursor =  e.target.result;

                      //cursor.map( (value, key) => {
                          let id = cursor.id;
                          let type = cursor.type;
                          let data = cursor.data;
                          let bytes = CryptoJS.AES.decrypt(data, enc_key);
                          let cval = bytes.toString(CryptoJS.enc.Utf8);
                          let con =   JSON.parse(cval);
                          let mobile = con.phone_1.toString();
                          let name = con.name;
                          
                          _this.setState({ 'assisted_by': name }); 
                      //});
                      db.close();
                    }; 
                  }catch{}
                }
              }
              if(agent_name!==""){
                agent_name = parseInt(agent_name);
              }
              if(agent_name>0 && agent_name!==""){
                let _this = this;
                let version = localStorage.getItem('IdbVersion');
                version = parseInt(version);
                let dbReq = window.indexedDB.open('BaazarERP', version);
                dbReq.onupgradeneeded = function(event) {
                  db = event.target.result;
                }
               
                dbReq.onsuccess = function(event) {
                  db = event.target.result;
                  try{
                    let ContactList = [];  
                    let ContactOptions = [];  
                    let transaction = db.transaction("contacts", "readwrite");
                    let contacts = transaction.objectStore("contacts");
                    let request = contacts.get(agent_name);
                    request.onsuccess = function(e) {
                      let cursor =  e.target.result;

                      //cursor.map( (value, key) => {
                          let id = cursor.id;
                          let type = cursor.type;
                          let data = cursor.data;
                          let bytes = CryptoJS.AES.decrypt(data, enc_key);
                          let cval = bytes.toString(CryptoJS.enc.Utf8);
                          let con =   JSON.parse(cval);
                          let mobile = con.phone_1.toString();
                          let name = con.name;
                         
                          _this.setState({ 'agent_name': name }); 
                      //});
                      db.close();
                    }; 
                  }catch{}
                }
              }

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
             
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                let disc = 0;
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:gamount});
              }
              

              let billing_date = data.created_date;
              let invoice_date = data.invoice_date;
              this.setState({billingDate:billing_date});
              this.setState({invoiceDate:invoice_date});
              let contact_info = data.contact_info;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              let display_state = '';
              let gst_number = '';
              let pin = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name;
                 contact_id = contact_info[0].contact_id;
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 display_state = contact_info[0].display_state;
                 gst_number = contact_info[0].gst_number;
                 pin = contact_info[0].pin;
              }
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,display_state:display_state,gst_number:gst_number,pin:pin};
              this.setState({contact_info:c_info});

              let pending_amount = parseInt(data.pending_amount);
              if(pending_amount>0){
                this.setState({is_pending:true});
              }
              let sequence = data.number;
              let sequence_no = data.number_sequence;
              this.sequence[t] = sequence;
              this.sequence[t+" No"] = sequence_no;
              this.setState({currrent_sequence:sequence});
              this.setState({currrent_sequence_no:sequence_no});

              
              let list_items = data.list_items;

              

              let total_quantity = 0;
              let slab_igst = [];
              let slab_cgst = [];
              let slab_sgst = [];


              let slab_igst_kp = [];
              let slab_cgst_kp = [];
              let slab_sgst_kp = [];

              if(data.return_data!==undefined){
                let return_data = data.return_data;
                for(let i=0;i<return_data.length;i++){
                  let items = return_data[i];
                  this.return_more_rows.push(items);
                }
                this.setState({return_more_rows:this.return_more_rows});
              }
           
              for(let i=0;i<list_items.length;i++){

                let quantity = list_items[i].quantity;

                let price = parseFloat(list_items[i].price.replace(/,/g , ""));
                let total = parseFloat(list_items[i].total.replace(/,/g , ""));
                let ssgst = list_items[i].sgst;

                let ssgst_total = list_items[i].total_sgst;
               
                let scgst = list_items[i].cgst;
                let scgst_total = list_items[i].total_cgst;
                let sigst = list_items[i].igst;
                let sigst_total = list_items[i].total_igst;
                

                if(slab_igst[sigst]===undefined){
                  slab_igst[sigst] = [];
                  this.igsttaxable[sigst]=[];
                  this.igsttaxnet[sigst]=[];
                  if(sigst>0){
                    this.igst_slab_key.push(sigst);
                  }
                }

                if(slab_igst[sigst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(sigst)/100;
                  let total_sigst = (slab_igst[sigst].length===undefined)?parseFloat(slab_igst[sigst])+tot_tax:tot_tax;

                  slab_igst[sigst]=total_sigst//total_sigst;

                  let total_taxable = (this.igsttaxable[sigst].length===undefined)?parseFloat(this.igsttaxable[sigst])+(quantity*price):(quantity*parseFloat(price));
                  this.igsttaxable[sigst] = total_taxable;

                  let total_net = (this.igsttaxnet[sigst].length===undefined)?parseFloat(this.igsttaxnet[sigst])+total:parseFloat(total);
                  this.igsttaxnet[sigst] = total_net;
                }

                if(slab_cgst[scgst]===undefined){
                  slab_cgst[scgst] = [];
                  this.csgsttaxable[scgst]=[];
                  this.csgsttaxnet[scgst]=[];
                  if(scgst>0){
                    this.cgst_slab_key.push(scgst);
                  }
                }
                if(slab_cgst[scgst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(scgst)/100;
                  let total_scgst = (slab_cgst[scgst].length===undefined)?parseFloat(slab_cgst[scgst])+tot_tax:tot_tax;

                  slab_cgst[scgst] = total_scgst;


                  let tot_taxable = (this.csgsttaxable[scgst].length===undefined)?parseFloat(this.csgsttaxable[scgst])+(quantity*price):(quantity*price);
                  this.csgsttaxable[scgst] = tot_taxable;

                  let total_net = (this.csgsttaxnet[scgst].length===undefined)?parseFloat(this.csgsttaxnet[scgst])+total:total;
                  this.csgsttaxnet[scgst] = total_net;
                }

                if(slab_sgst[ssgst]===undefined){
                  slab_sgst[ssgst] = [];
                  if(ssgst>0){
                    this.sgst_slab_key.push(ssgst);
                  }
                }
                if(slab_sgst[ssgst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(scgst)/100;
                  let total_ssgst = (slab_sgst[ssgst].length===undefined)?parseFloat(slab_sgst[ssgst])+tot_tax:tot_tax;
                
                  slab_sgst[ssgst] = total_ssgst;;
                }

                total_quantity = total_quantity+parseInt(list_items[i].quantity);
                let items = list_items[i];
                let key = i+1;
                this.more_rows.push(items);
              }
              

              this.tax_slab['sgst'] = slab_sgst;
              this.tax_slab['cgst'] = slab_cgst;
              this.tax_slab['igst'] = slab_igst;

              

              this.setState({more_rows:this.more_rows,total_quantity:total_quantity});

              let _this = this;
              setTimeout(function(){
                _this.shareMessage();
              },100);


          
              this.setState({payment_status:data.bill_status});
              if(data.bill_status==="Trashed"){
                  this.setState({is_pending:false})
                  this.setState({payment_status:'Trashed'});
              }
              if(data.bill_status==="Cancelled"){
                this.setState({is_pending:false})
                this.setState({payment_status:'Cancelled'});
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }

        
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            key:'terms_and_conditions'
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.terms_and_conditions){
              let terms = resp.terms_and_conditions;
              this.setState({terms_and_conditions:terms});
            }
        }.bind(this)).catch(function(res){
            
        })
    }

    render() {
        let view = true;
        let edit = true;
        let del = true;
        let is_payment = false;
        let target = this.state.target;
        let is_cd_note = false;
        let cd_type = 'Credit';
        if(this.state.target){
            if(target==='sales'){
              if(!this.is_access('billing-sale-view')){
                view = false;
              }
              if(!this.is_access('billing-sale-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sale-delete')){
                del = false;
              }
              if(this.is_access('payments-in-create')){

                is_payment = true;
              }
            }else if(target==='purchase'){
              if(!this.is_access('billing-purchase-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-delete')){
                del = false;
              }
              if(this.is_access('payments-out-create')){
                is_payment = true;
              }
            }else if(target==='sales_return'){
              if(!this.is_access('billing-sales-return-view')){
                view = false;
              }
              if(!this.is_access('billing-sales-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sales-return-delete')){
                del = false;
              }
              if(this.is_access('payments-out-create')){
                is_payment = true;
              }
            }else if(target==='purchase_return'){
              if(!this.is_access('billing-purchase-return-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-return-delete')){
                del = false;
              }
              if(this.is_access('payments-in-create')){
                is_payment = true;
              }
            }
            
        }

      
        if(this.state.billing_type==='New Sale'){
          is_cd_note = true;
          cd_type = 'Credit';
        }
        if(this.state.billing_type==='New Purchase'){
          is_cd_note = true;
          cd_type = 'Debit';
        }
      
        let total_payment_paid = 0;
        let total_payment_balance = 0;
        let total_amount = 0;
        let is_variant = '';
        let s_no = 0;
        return (
            <div className="d-flex billing-details" id="wrapper" onClick={this.handleDivClick}>
              <Leftnav />
              <div   className="view-on-fly" id="page-content-wrapper">
              <nav className="navbar border-bottom">
                  <div className="row">
                    {(utils.isMobile()===false) &&
                    <div className="col-md-3">
                        <div className="mod-title">
                          <a href="/quotationn"><span>Quotation</span></a> / <b id="breadcrumbs_subtitle">View Quotation</b>
                        </div>
                    </div>
                    }
                    {(this.state.payment_status==='Cancelled'  && utils.isMobile()===false) &&
                      <div className="col-md-9 text-right mrtop-15">
                        <div>
                        {(this.state.is_fy_closed===false) &&
                          <>
                            <a href={"/create-quotation/resume/"+this.state.update_id} className="div-display"><button type="button" className="btn btn-success btn-sm">Restart</button></a>
                          </>
                        }
                        {(this.state.is_fy_closed===true) &&
                          <ClosedFYButton className="btn-sm" label="Restart" type="button"/>
                        }
                        </div>
                      </div>
                    }


                    {(this.state.payment_status!=='Cancelled') &&
                    <>
                    {(utils.isMobile()===false) &&
                     <div className="col-md-5  mrtop-15 text-right">
                       
                        {(this.state.bill_status==="On Hold") &&
                        <div>
                           {(this.state.is_fy_closed===false) &&
                            <>
                              <a href={"/create-quotation/edit/"+this.state.update_id} className="div-display"><button type="button" className="btn btn-success btn-sm">Resume</button></a>
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <ClosedFYButton className="btn-sm" label="Resume" type="button"/>
                          }
                        </div>
                        }
                    
                    </div>
                  }
                    {(this.state.billing_data.is_converted!==4 && this.state.billing_data.is_converted!==7) &&
                      <div className={(utils.isMobile()===false)?"col-md-4 mrtop-15 action-area text-right":"col-md-12 mrtop-15 action-area text-right"}>
                      {(parseInt(user_info.login_id)===parseInt(this.state.view_branch_id)) &&
                      <div className="e-p-p-opt div-display">
                          {(this.state.payment_status==='Cancelled' && utils.isMobile()===true) &&
                            <>
                              {(this.state.is_fy_closed===false) &&
                              <div className="eppopt">
                                  <a href={"/create-quotation/resume/"+this.state.update_id}>Restart</a>
                              </div>
                              }
                              {(this.state.is_fy_closed===true) &&
                                <ClosedFYButton className="eppopt" label='Restart' type="div"/>
                              }
                            </>
                          }
                       
                          {(this.state.bill_status==="On Hold"  && utils.isMobile()===true) &&
                          <>
                            {(this.state.is_fy_closed===false) &&
                              <div className="eppopt">
                                  <a href={"/create-quotation/edit/"+this.state.update_id} >Resume</a>
                              </div>
                            }
                            {(this.state.is_fy_closed===true) &&
                              <ClosedFYButton className="eppopt" label='Resume' type="div"/>
                            }
                            </>
                          }
                       
                        {/* <div className="eppopt" onClick={this.handlePrint} id="print">
                            <a id="print-lbl">Print</a>
                            <a id="print-btn-spinner" className="hide"><FontAwesomeIcon icon={faSpinner} pulse/></a>
                          </div> */}
                          {(this.state.billing_type==="New Purchase") &&
                            <div  onClick={this.openBarcodePrintPreview} id="preview" className="eppopt"><a>Generate Barcode</a></div>
                          }
                       {/*    {(this.state.billing_type!=="New Purchase" && utils.isMobile()===false) &&
                            <div  onClick={this.handlePrint} id="preview" className="eppopt">
                              <a id="preview-lbl">Preview</a>
                              <a id="preview-btn-spinner" className="hide"><FontAwesomeIcon icon={faSpinner} pulse/></a>
                            </div>
                          } */}
                          {(edit || del) &&
                            <>
                                {edit &&
                                  <>
                                    {(this.state.is_fy_closed===false) &&
                                      <div className="eppopt"><a href={"/create-quotation/edit/"+this.state.update_id}>Edit</a></div>
                                    }
                                    {(this.state.is_fy_closed===true) &&
                                      <ClosedFYButton className="eppopt" label='Edit' type="div"/>
                                    }

                                    {(this.state.is_fy_closed===false) &&
                                      <div className="eppopt"><a href={"/new-billing/convert-to-sale/"+this.state.update_id}>Convert to Sale</a></div>
                                    }
                                    {(this.state.is_fy_closed===true) &&
                                      <ClosedFYButton className="eppopt" label='Convert to Sale' type="div"/>
                                    }
                                  </>
                                }
                                {del &&
                                  <>
                                  {(this.state.is_fy_closed===false) &&
                                    <>
                                      <div className="eppopt no-border-right" id={this.state.update_id} onClick={this.delteBilling} data_target="delete_confirm" data_value={this.state.target} data_id={"delete_quotation"}><a>{(this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel'}</a></div>
                                    </>
                                  }
                                  {(this.state.is_fy_closed===true) &&
                                    <ClosedFYButton className="eppopt no-border-right" label={(this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel'} type="div"/>
                                  }
                                  </>
                                }
                            </>
                        }

                      </div>
                    }
                    </div>
                    }
                    </>
                   }
                  </div>
                </nav>
                <div id="main-container">
                <div className="container-fluid">
                <ServiceLoader show={this.state.show_loader}/>
                  <div className="content-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom no-print-block">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5 mob-content">
                            <label className="bil_no">Invoice {this.state.currrent_sequence}</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center mob-content">
                            <label className="bill-amount">Amount : {(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</label>
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right mob-content">
                            <label className="bil_no">Status : {this.state.payment_status}</label>
                        </div> 
                       </div>
                     </div>
                    
                 
                     <div className="main-content border-bottom no-print-block">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bill-to-c text-right">
                                  <span className="fe-title">BILL TO</span>
                                </div>
                                <div className="bill-to-info">
                                  <p className="title">{this.state.contact_info!=''?this.state.contact_info.item_name:''}</p>
                                  {(this.state.contact_info!=='' && this.state.contact_info.address_1!=='' && this.state.contact_info.address_1!==null)?<p>{this.state.contact_info.address_1}</p>:''}
                                  <p>
                                    {(this.state.contact_info!=='' && this.state.contact_info.city!=='' && this.state.contact_info.city!==null)?this.state.contact_info.city:''}
                                    {(this.state.contact_info!=='' && this.state.contact_info.pin!=='' && this.state.contact_info.pin!==null)?'-'+this.state.contact_info.pin:''}
                                    {(this.state.contact_info!=='' && this.state.contact_info.city!=='' && this.state.contact_info.city!==null && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?', ':''}

                                    {(this.state.contact_info!=='' && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?this.state.contact_info.display_state:''}
                                    </p>
                                  <p>
                                    {(this.state.contact_info!=='' && this.state.contact_info.phone_1!=='' && this.state.contact_info.phone_1!==null)?this.state.contact_info.phone_1:''}
                                  </p>
                                  <p>{(this.state.contact_info!=='' && this.state.contact_info!=='' && this.state.contact_info.gst_number!==null && this.state.contact_info.gst_number!=='')?"GSTIN :"+this.state.contact_info.gst_number:''}</p>
                                </div>  
                                <div className="issued-on">
                                  
                                  <div className="input-group">
                                    
                                    <span className="fe-title no-print-block">Issued On : {this.state.billingDate}</span>
                                    
                                  </div>
                                 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Issued By : {this.state.issued_by}</span>
                                    
                                  </div>
                                  {(this.state.assisted_by!=="") && 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Assisted By : {this.state.assisted_by}</span>
                                    
                                  </div>
                                  }
                                  {(this.state.billing_data.agent_name!=="") && 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Agent Name : {this.state.billing_data.agent_name}</span>
                                    
                                  </div>
                                  }
                                  {(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return') && 
                                  
                                    <div className="input-group">
                                      <>
                                        {(this.state.billing_data && this.state.billing_data.cd_sequence!==undefined && this.state.billing_data.cd_sequence!=="") &&
                                           <span className="fe-title">{(this.state.billing_type==='New Sales Return')?'Credit Note No.':'Debit Note No.'} : {(this.state.billing_data && this.state.billing_data.cd_sequence)?this.state.billing_data.cd_sequence:'-'}</span>
                                        }
                                        {(this.state.billing_data && this.state.billing_data.cd_sequence==="") &&
                                           <span className="fe-title">{(this.state.billing_type==='New Sales Return')?'Credit Note Type.':'Debit Note Type.'} : Cash</span>
                                        }
                                        </>
                                    </div>
                                  }
                                </div>
                              </div> 
                          </div>
                         
                       
                        </div>
                     </div>
                     </form>  
                     
                     <div className="table-responsive">
                     <table className="table">
                        <thead className="thead-light">
                          <tr>
                            
                            <th scope="col" className="item-serial">SN</th>
                            
                            <th scope="col" className="item-name">ITEM NAME</th>
                            
                            <th scope="col" className="not-thermal-print hsn-th">HSN/SAC</th>
                            <th scope="col" className="pr-quantity">
                                <span className="no-print-block">QUANTITY</span>
                                <span className="print-block">QTY.</span>
                            </th>
                            <th scope="col" className="not-thermal-print no-print-block">UNIT</th>
                            {this.state.billing_type==='New Sale' &&
                            <>
                              <th scope="col"  className="item-price">PRICE</th>
                            </>
                            }
                            {this.state.billing_type==='New Purchase' &&
                            <>
                              <th scope="col" className="not-thermal-print">PURCHASE PRICE</th>
                              <th scope="col" className="not-thermal-print">SELLING PRICE</th>
                             </>
                            }
                            {this.state.billing_type==='New Sales Return' &&
                              <>
                                <th scope="col" className="not-thermal-print">SELLING PRICE</th>
                              </>
                            }
                            {this.state.billing_type==='New Purchase Return' &&
                              <>
                                <th scope="col" className="not-thermal-print">PURCHASE PRICE</th>
                              </>
                            }
                            {(this.state.billing_type==='New Purchase'||this.state.billing_type==='New Sale' ) && 
                            <>
                              <th scope="col" className="not-thermal-print no-print-block">DISCOUNT%</th>
                              <th scope="col" className="print-block item-discount">DIS%</th>
                            </>
                            }
                            <th scope="col" className="not-thermal-print gst-th">GST %</th>
                           
                            {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') && 
                            <>
                              <th scope="col" className="not-thermal-print gst-th">TAX TYPE</th>
                            </>
                            }
                            {(this.state.billing_type==='New Purchase') && 
                            <>
                              <th scope="col" className="not-thermal-print gst-th">CATEGORY</th>
                            </>
                            }
                            <th scope="col" style={{textAlign:'right'}}>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody id="items-body">
                          {(this.state.more_rows.length > 0) ?this.state.more_rows.map( (value, index) => {
                            total_amount = total_amount+parseFloat((value.total!=='-')?value.total:0);   

                            if(value.is_variation){
                              is_variant = 'is_variant';
                            }else{
                              s_no++;
                            }       
                           return (
                             <tr className={"pitem-row items-row border-bottom "+is_variant} key={index} id={"item-row-"+index} data-id={index}>
                             
                              <td className="item-serial">
                              {(value.is_variation!==true && value.is_variation!=='true') &&
                              <span className="li-item">
                                {s_no} 
                              </span>
                              }
                              </td>
                            
                              <td className="item-name">
                              <>
                                <div className="item-area">
                                <span className="item-name-print">
                                {(value.parent_id!==0 && value.parent_id!=='0') &&
                                  <span style={{margin:'0px',lineHeight:'1.2'}}>
                                    {value.product_name} <span style={{fontWeight:'bold'}}>{value.item_name}</span>
                                  </span>
                                }
                                {(value.parent_id===0 || value.parent_id==='0') &&
                                  <>
                                    <span className="li-item">{value.item_name}</span>
                                  </>
                                }
                                </span>
                                {(value.barcode!=="" && value.barcode!==null) &&
                                    <>
                                      <span className="bcode-name bblk not-thermal-print"> 
                                        <p style={{margin:'0px',marginTop:'3px'}}>{value.barcode}</p>
                                        {(value.alias_barcode) &&
                                          <p className="alias-bc">{value.alias_barcode}</p>
                                        }
                                      </span>
                                    </>
                                }
                              </div>
                              {(value.barcode!=="" && value.barcode!==null) &&
                                <div className="print-block">
                                  <span className="bcode-name bblk thermal_display"> 
                                    <p style={{margin:'0px',marginTop:'3px'}}>{value.barcode}</p>
                                    {(value.alias_barcode) &&
                                      <p className="alias-bc">{value.alias_barcode}</p>
                                    }
                                  </span>
                                </div>
                              }
                              {(value.is_variation!==true) &&
                                <span className="i-desc">{(value && value.desc)?value.desc:''}</span>
                              }
                              </>
                              </td>
                              
                              <td className="not-thermal-print  hsn-td">
                                {(value.is_variation!==true) &&
                                  <span className="li-item">{(value && value.hsn)?value.hsn:''}</span>
                                }
                              </td>
                              <td className="td-quan">
                                  <p style={{display:'inline-flex',lineHeight:'1.2'}}>{(value && value.quantity)?value.quantity:''} 
                                  <span className="print-block">
                                    {(value && value.unit_name)?value.unit_name:''}
                                  </span>
                                  </p>
                                  {(value && value.bundle_quantity>0) &&
                                    <i>  ({value.bundle_quantity} {this.state.billing_data.bundle_text})</i>
                                  }
                              </td> 
                              <td className="units-dd no-print-block">
                                  <span className="li-item">{(value && value.unit_name)?value.unit_name:''}</span>
                              </td>
                             {this.state.billing_type==='New Sale' &&
                              <>
                               
                                <td className="item-price">
                                    <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td>
                              </>
                            }
                            {this.state.billing_type==='New Purchase' &&
                              <>
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td> 
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price2)?value.price2:''}</span>
                                </td>
                              </>
                            }
                             {this.state.billing_type==='New Sales Return' &&
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td>
                              }
                              {this.state.billing_type==='New Purchase Return' &&
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td> 
                              }
                              {(this.state.billing_type==='New Purchase'||this.state.billing_type==='New Sale' ) && 
                                <td className="item-discount">
                                  <div className="input-group">
                                    <span className="li-item">{(value && value.discount)?value.discount:0}</span>
                                  </div>
                                </td>
                              }
                            
                              <td className="not-thermal-print gst-td">
                                <div className="input-group">
                                  <span className="li-item">{(value && value.tax)?value.tax:0}</span>
                                </div>
                              </td>
                            
                            {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') && 
                              <>
                                <td className="not-thermal-print gst-td">
                                  <span className="li-item">
                                    {(value.is_tax_inc==='including' || value.is_tax_inc==='Including GST')?'Inc.GST':'Exc.GST'}
                                  </span>
                                </td>
                              </>
                            }
                            {(this.state.billing_type==='New Purchase') && 
                              <>
                                <td className="not-thermal-print gst-td">
                                  <span className="li-item">
                                    {value.iitem_category_name}
                                  </span>
                                </td>
                              </>
                            }
                              <td className="item-rm text-right">
                                {(value && value.total && value.total!=='-') &&
                                  <span className="li-item">₹</span>
                                }
                                <span className="item-total li-item" id={"item-total-"+index} data-id={index}  data-target="item-total">
                                    {(value && value.total && value.total!=='-')?this.currency_format(value.total):'-'}
                                  </span>
                              </td>
                            </tr>
                            )
                            }) : <tr><td colSpan="8" className="text-center">{this.state.loading_process}</td></tr> }
                        
                        </tbody>
                       
                      </table>
                      </div>
                    

                     <div className="content-head no-print-block">
                       <div className="row">
                          <div className="col-md-6 mrtop-5">
                              <p className="msg-to-cust">{(this.state.billing_data && this.state.billing_data.note)?this.state.billing_data.note:''}</p>

                              <table width="100%" className="table inline-table" style={{border:'1px solid #dee2e6'}}>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">CGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-cgst">{(this.state.billing_data && this.state.billing_data.total_cgst)?this.state.billing_data.total_cgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">SGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-sgst">{(this.state.billing_data && this.state.billing_data.total_sgst)?this.state.billing_data.total_sgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">IGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-igst">{(this.state.billing_data && this.state.billing_data.total_igst)?this.state.billing_data.total_igst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th> <span className="sp-normal" style={{fontWeight:'bold'}}>TOTAL TAX</span></th>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="total-tax"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr> 
                            </table>
                           
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-5 mrtop-40">
                            <table className="table billing-total">
                              <tbody>
                                  <tr>
                                      <td><span className="sp-normal">Total Units</span></td>
                                      <td className="text-right"><span className="sp-normal all-out" id="total-unit">{(this.state.billing_data && this.state.billing_data.total_unit)?this.state.billing_data.total_unit:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Total Quantity</span></td>
                                      <td className="text-right"><span className="sp-normal all-out" id="total-quantity">{this.state.total_quantity}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal" style={{fontWeight:'bold'}}>Subtotal</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total" style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.sub_total)?this.state.billing_data.sub_total:0}</span></td>
                                  </tr>
                                  {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') &&
                                    <>
                                      <tr>
                                          <td><span className="sp-normal">Discount</span></td>
                                          <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                      </tr> 
                                    </>
                                  }
                                  {((this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return') && (this.state.billing_data.discount!=="" && this.state.billing_data.discount!=="0" && this.state.billing_data.discount!==0) ) &&
                                    <>
                                      <tr style={{display:(this.state.billing_data && this.state.billing_data.discount && this.state.billing_data.discount>0)?'':'none'}} id="nsr-npr-discount">
                                          <td><span className="sp-normal">Discount</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                      </tr> 
                                    </>
                                  }
                                  {(this.state.billing_data && this.state.billing_data.global_discount!=='' && this.state.billing_data.global_discount!==0 && this.state.billing_data.global_discount!=='0') &&
                                  <tr>
                                      <td>
                                        <span className="sp-normal">
                                        Discount on Bill 
                                        {(this.state.discount_sym==='per') &&
                                          <>
                                             <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                             </span>
                                          </>
                                        }
                                        </span>
                                      </td>
                                      <td className="text-right">
                                         
                                        <span className="sp-normal">
                                          {(this.state.billing_data && this.state.billing_data.global_discount!=='' && this.state.billing_data.global_discount!==0 && this.state.billing_data.global_discount!=='0') &&
                                            <>
                                              {(this.state.discount_sym==='rs') &&
                                              <>
                                              <span className="rs-sym">₹</span>{this.state.billing_data.global_discount}
                                              </>
                                              }
                                              {(this.state.discount_sym==='per') &&
                                              <>
                                               {this.state.billing_data.global_discount}%
                                               </>
                                              }
                                            </>
                                          }
                                        </span>

                                      </td>
                                  </tr>
                                }
                                  <tr>
                                    <td><span className="sp-normal">Total Before Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-tax">{(this.state.billing_data && this.state.billing_data.total_before_tax)?this.currency_format(this.state.billing_data.total_before_tax):0}</span></td>
                                  </tr>
                                  <tr>
                                    <td><span className="sp-normal">Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-after-tax">{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.calculate_total)?this.currency_format(this.state.billing_data.calculate_total):0}</span></td>
                                  </tr>
                                  {(this.state.billing_data.round_off!=="" && this.state.billing_data.round_off!=="0" && this.state.billing_data.round_off!==0) &&
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="text-right"><span className="sp-normal">{(this.state.billing_data && this.state.billing_data.round_off)?this.state.round_off_sym+''+this.state.billing_data.round_off:''}</span></td>
                                    </tr>
                                  }
                               

                               

                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Grand Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="final-total"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span></td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                       </div>
                     </div>
                  </div>
                </div>
                {(this.state.sale_info && this.state.sale_info.length > 0) &&
                    <>
                <div className="container-fluid no-print-block">
                  <div className="content-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom payment-head">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">
                              Converted Sale
                            </label>
                        </div>
                       </div>
                     </div>
                   
                     </form>  
                     
                  </div>
                 
                       <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">DATE</th>
                                  <th scope="col">AMOUNT</th>
                               
                                </tr>
                              </thead>
                              <tbody id="items-body">
                                {(this.state.sale_info.length > 0) ?this.state.sale_info.map( (value, index) => {
                                 return (
                                   <tr className="contact-row border-bottom" key={index} id={"item-row-"+index} data-id={index}>
                                    <td className="item-name">
                                      {(value.invoice_number) &&
                                      <DrawerLink  target="billing" url={"/view-billing/sales/"+value.invoice_id} schema={value.schema} name={value.invoice_number} />
                                      }
                                    </td>
                                    <td>
                                       {value.created_on}
                                    </td> 
                                    <td>
                                       ₹{this.currency_format(value.paid_amount.replace(/,/g , ""))}
                                    </td>
                                  </tr>
                                  )
                                  }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                              </tbody>
                            </table>
                          </div>
                       </div>
                  
                </div>
                    </>
                }                  
                
                  <div className="content-head print-block" style={{paddingLeft:'2px',marginTop:'10px'}}>
                    <div className="row">
                      <div className="col-md-12 terms-and-conditions">
                      {(this.state.print_settings && this.state.print_settings.indexOf("terms-and-conditions")<0) &&
                      <>
                      {(this.state.terms_and_conditions.length > 0) &&
                          <div>
                              <h4 style={{color:'gray',fontSize:'12px'}}>TERMS & CONDITIONS</h4>
                              <ol style={{color:'#909090',marginLeft:'-15px'}}>
                                {(this.state.terms_and_conditions.length > 0) ?this.state.terms_and_conditions.map( (value, index) => {
                                 return (
                                   <li style={{fontSize:'12px'}}>
                                     {value}
                                   </li>
                                  )
                                  }) : ''}
                              </ol>
                          </div>
                      }
                      </>
                    }
                    
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete" delete_message={'Do you really want to delete this bill?'}/>
              <PrintBill type={this.state.referer} id={this.state.response_id} target={this.state.print_target} bill_view_response={this.state.bill_view_response}/>
            </div>
        );
    }
}