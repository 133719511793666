import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import ModalBox from "../modal-box";
import ContactTypes from "../contact-types";
import PaymentModes from "../payment-modes";
import ProductCategory from "../product-category";
import ProductUnits from "../product-units";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner,faPencilAlt,faKey, faCopy  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import axios from 'axios';
import utils from '../../utils.js';
let {enc_key,app_api,user_info,states} = require('../../library.js');
export default class SettingsProfile extends Component {
     constructor() {
      super();
      this.state = {
        profileInfo : [],
        is_set : false,
        is_edit_mode : false,
        key_success : false,
        error_message : '',
        success_message : '',
        private_access_key : '',
        generate_error : '',
        key_gen_type : '',
      }
      this.handleEditProfile = this.handleEditProfile.bind(this);
      this.handleCancelEdit = this.handleCancelEdit.bind(this);
      this.handleSaveProfile = this.handleSaveProfile.bind(this);
    }
    handleCancelEdit(e){
      this.setState({is_edit_mode:false});
    }
    handleEditProfile(e){
      this.setState({is_edit_mode:true});
    }


    handleSaveProfile(e){
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let qs = document.querySelectorAll(".form-edit-mode");
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
        }
        let update_data = {};
        for(let i=0;i<qs.length;i++){
          let did = qs[i].getAttribute('data-id');
          update_data[did] = qs[i].value;
        }

        if(document.getElementById("display_name")){
          let display_name  = document.getElementById("display_name").value;
          if(display_name!==""){
            let user_info = JSON.parse(localStorage.getItem('user'));
            user_info.response.name = display_name;
            user_info = JSON.stringify(user_info);
            localStorage.setItem('user', user_info);
          }
        }

        let oldpassword = document.getElementById("old-password").value;
        let newpassword = document.getElementById("new-password").value;
        let confirmpassword = document.getElementById("confirm-password").value;
        if(oldpassword!=="" && newpassword!=="" && confirmpassword!==""){
          update_data['oldpassword'] = oldpassword;
          update_data['newpassword'] = newpassword;
          update_data['confirmpassword'] = confirmpassword;
        }
        send_data['update_data'] = update_data;
        
        let api = app_api+'/update_profile';
        axios.post(api, send_data)
          .then(function (response) {
            let success = response.data.success;
            let message = response.data.message;
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            let _this = this;
            if(success===2){
              this.setState({error_message:message});
              setTimeout(function(){
                _this.setState({error_message:''});
              },2000);
            }else{
              if(success===3){
                setTimeout(function(){
                  window.location.href = '/login';
                },2500);
              }
              this.setState({error_message:''});
              this.setState({success_message:message});
              setTimeout(function(){
                _this.setState({success_message:''});
              },3000);
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    
    componentDidMount(){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          u_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
      }
      let api = app_api+'/get_user_on_id';
      axios.post(api, send_data)
        .then(function (response) {
          let resp= response.data;
          this.setState({profileInfo:resp[0]});
      }.bind(this)).catch(function(res){
          
      })

      let keys = "'private_access_key'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.private_access_key){
              let ps = resp.private_access_key;
              this.setState({private_access_key:ps.ct});
            }
        }.bind(this)).catch(function(res){
            
        })

    }

    handleOpenGenerateAccessKey = e =>{
        let data_id = e.currentTarget.getAttribute('data-id');
        this.setState({key_gen_type:data_id});
        let elem = document.getElementById("generate-private-access-key");
        elem.classList.remove('fade');
        elem.style.display = 'block';
    }

    handleCloseKeyPopup = e =>{
        let elem = document.getElementById("generate-private-access-key");
        elem.classList.add('fade');
        setTimeout(() => {
          elem.style.display = 'none';
        }, 100);
    }

    handleGeneratePrivateKey = e =>{
      let thisid = e.currentTarget.id;
      let store_name = document.getElementById("store-name").value;
      let store_pwd = document.getElementById("store-pwd").value;
      
      if(store_name===""){
        document.getElementById("store-name").focus();
        return false;
      }

      if(store_pwd===""){
        document.getElementById("store-pwd").focus();
        return false;
      }
      this.setState({key_success:true});

      let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        u_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token, _schema : user_info._schema,
        s : store_name,
        p : store_pwd,
      }
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let api = app_api+'/generate_private_access_key';
      axios.post(api, send_data)
        .then(function (response) {
         if(response.data.success===1){
          document.getElementById("success-circle-loader").classList.add('load-complete');
          document.getElementById("checkmark-success").style.display ='block';
          setTimeout(() => {
            window.location.reload();
          }, 1000);
         }else{
          this.setState({key_success:false});
          setTimeout(() => {
            if(document.getElementById(thisid)){
              document.getElementById(thisid).classList.remove("hide");
              document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            }
          }, 1000);
          this.setState({generate_error:response.data.msg});
         }
      }.bind(this)).catch(function(res){
          
      })
    }
    handleCopyKey = e =>{
      var copyText = document.getElementById("access-key-field");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      
      var tooltip = document.getElementById("my-copy-tooltip");
      tooltip.innerHTML = "Copied";
    }
    handleKeyCopied = e =>{
      var tooltip = document.getElementById("my-copy-tooltip");
      tooltip.innerHTML = "Copy";
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Profile</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
          
                <div className={"container-fluid"} style={{marginBottom:'10px'}}>
                 <div className="row">
                   <div className="col-md-9 border-right">

                   
                  <div className="content-area">
                      <div className="main-content" style={{padding:'20px'}}>
                        <div className="row">
                          <div className="col-md-12 border-bottom">
                            <h4 style={{fontSize:'20px'}}>
                              Profile Info
                              {(this.state.is_edit_mode===false) &&
                                <button type="button" className="btn btn-secondary pull-right" id="edit-profile" onClick={this.handleEditProfile}><FontAwesomeIcon icon={faPencilAlt}/></button>
                              }
                            </h4>
                          </div>
                        </div>
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Name:</label>
                          </div>
                          <div className="col-md-5">
                            <h5>{(this.state.profileInfo.username!==undefined && this.state.profileInfo.username!=="")?this.state.profileInfo.username:''}</h5>
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Role Name:</label>
                          </div>
                          <div className="col-md-5">
                            <h5>{(this.state.profileInfo.role_name!==undefined && this.state.profileInfo.role_name!=="")?this.state.profileInfo.role_name:'-'}</h5>
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Display Name:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.display_name!==undefined && this.state.profileInfo.display_name!=="")?this.state.profileInfo.display_name:'-'}
                            </h5>
                          }
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.display_name!==undefined && this.state.profileInfo.display_name!=="")?this.state.profileInfo.display_name:''} data-id='display_name' id="display_name"/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Phone:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.phone!==undefined && this.state.profileInfo.phone!=="")?this.state.profileInfo.phone:'-'}
                            </h5>
                          }
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.phone!==undefined && this.state.profileInfo.phone!=="")?this.state.profileInfo.phone:''} data-id='phone'/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Email:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.email!==undefined && this.state.profileInfo.email!=="")?this.state.profileInfo.email:'-'}
                            </h5>
                          } 
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.email!==undefined && this.state.profileInfo.email!=="")?this.state.profileInfo.email:''} data-id='email'/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>City:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.city!==undefined && this.state.profileInfo.city!=="")?this.state.profileInfo.city:'-'}
                            </h5>
                          }
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.city!==undefined && this.state.profileInfo.city!=="")?this.state.profileInfo.city:''} data-id='city'/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Pincode:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.pin_code!==undefined && this.state.profileInfo.pin_code!=="")?this.state.profileInfo.pin_code:'-'}
                            </h5>
                          }
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.pin_code!==undefined && this.state.profileInfo.pin_code!=="")?this.state.profileInfo.pin_code:''} data-id='pin_code'/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                        <div className="row mrtop-10">
                          <div className="col-md-3">
                            <label style={{fontSize:'14px'}}>Address:</label>
                          </div>
                          <div className="col-md-5">
                          {(this.state.is_edit_mode===false) &&
                            <h5>
                              {(this.state.profileInfo.address!==undefined && this.state.profileInfo.address!=="")?this.state.profileInfo.address:'-'}
                            </h5>
                          }
                          {(this.state.is_edit_mode===true) &&
                            <input type="text" className="form-control form-edit-mode" defaultValue={(this.state.profileInfo.address!==undefined && this.state.profileInfo.address!=="")?this.state.profileInfo.address:''} data-id='address'/>
                          }
                          </div> 
                          <div className="col-md-4">
                            
                          </div>
                        </div>
                        
                      </div>
                  </div>
                  {(this.state.is_edit_mode===true) &&
                    <>
                  <div className="content-area" style={{borderTop:'1px solid #d7d7d7'}}>
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Change Password</h4>
                                </div>
                              </div>
                          
                              <div className="row mrtop-10">
                                <div className="col-md-3">Old Password</div>
                                <div className="col-md-5">
                                  <input type="password" id="old-password" className="form-control"/>
                                </div>
                                <div className="col-md-4"></div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">New Password</div>
                                <div className="col-md-5">
                                  <input type="password" id="new-password" className="form-control"/>
                                </div>
                                <div className="col-md-4"></div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">Confirm Password</div>
                                <div className="col-md-5">
                                  <input type="password" id="confirm-password" className="form-control"/>
                                </div>
                                <div className="col-md-4"></div>
                              </div> 
                              {(this.state.error_message!=='') &&
                                <div className="row mrtop-10">
                                  <div className="col-md-8">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error_message}
                                    </div>
                                  </div>
                                  <div className="col-md-4"></div>
                                </div>
                              }
                              {(this.state.success_message!=='') &&
                                <div className="row mrtop-10">
                                  <div className="col-md-8">
                                    <div className="alert alert-success" role="alert">
                                        {this.state.success_message}
                                    </div>
                                  </div>
                                  <div className="col-md-4"></div>
                                </div>
                              }
                     </div>
                  </div>
                  <div className="content-area" style={{borderTop:'1px solid #d7d7d7'}}>
                    <div className="main-content" style={{padding:'20px'}}>
                      <div className="row">
                        <div className="col-md-12">
                          <button type="button" className="btn btn-secondary" onClick={this.handleCancelEdit}>Cancel</button>
                          <button type="button" className="btn btn-success pull-right" id="save-profile" onClick={this.handleSaveProfile}>Save Profile</button>
                          <button type="button" id="save-profile-btn-spinner" className=" pull-right hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                  }
                  </div>
                  <div className="col-md-3">
                      <div className="content-area">
                        <div className="main-content">
                      <div className="col-md-12 border-bottom pad-0">
                          <h4 style={{fontSize:'20px',margin: '0',padding: '14px 0px'}}>
                              Other Settings
                          </h4>
                      </div>
                      <div className="col-md-12 mrtop-10">
                      {(this.state.private_access_key==="") &&
                        <>
                          {(user_info.access_level==="1") &&
                            <lable className="gen-pk-l" onClick={this.handleOpenGenerateAccessKey}  data-id="generate">Generate Private Access Key</lable>
                          }
                          {(user_info.access_level!=="1") &&
                            <lable style={{color:'gray'}}>Generate Private Access Key</lable>
                          }
                        </>
                      }
                      {(this.state.private_access_key!=="") &&
                        <>
                          <label htmlFor="access-key-field">Private Access Key</label><span className="copy-key pull-right" onClick={this.handleCopyKey} onMouseOut={this.handleKeyCopied}><span class="copy-key-text" id="my-copy-tooltip">Copy</span><FontAwesomeIcon icon={faCopy} /></span>
                          <textarea className="form-control" rows={5} id="access-key-field" disabled>{this.state.private_access_key}</textarea>

                          {(user_info.access_level==="1") &&
                             <lable className="gen-pk-l" onClick={this.handleOpenGenerateAccessKey} data-id="regenerate" style={{fontSize:'12px',float:'right'}}>Re-Generate Private Access Key</lable>
                          }
                          {(user_info.access_level!=="1") &&
                            <lable style={{color:'gray',fontSize:'12px',float:'right'}}>Re-Generate Private Access Key</lable>
                          }
                        </>
                      }
                      </div>
                      </div>
                      </div>
                  </div>
                 </div>
                </div>
              </div>

              <div className="modal fade" id="generate-private-access-key" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-confirm" style={{width:'auto'}}>
                  <div className="modal-content" style={{paddingTop:'5px'}}>
                    <div className="modal-header flex-column">
                      {(this.state.key_success===false) &&
                      <>
                        <div className="icon-box icon-print">
                          <span className="material-icons"><FontAwesomeIcon icon={faKey}/></span>
                        </div>    
                        {(this.state.key_gen_type==='generate') &&        
                        <h5 className="modal-title w-100 mrtop-10" style={{fontSize:'18px'}}>GENERATE PRIVATE ACCESS KEY</h5>
                        }
                        {(this.state.key_gen_type==='regenerate')&&        
                          <>
                          <h5 className="modal-title w-100 mrtop-10" style={{fontSize:'18px'}}>RE GENERATE PRIVATE ACCESS KEY</h5>
                          <p className="error text-center">Once you re-generate the key. You need to change it in all the places where you have used the current access key.</p>
                          </>
                        }
                        <div className="row text-left mrtop-10">
                          <div className="col-md-12">
                            <label htmlFor="store-name">Enter Store Name<span className="error">*</span></label>
                            <input type="text" id="store-name" className="form-control" placeholder="Please enter store name" autoComplete="off"/>
                          </div>
                          <div className="col-md-12 mrtop-10">
                            <label htmlFor="store-pwd">Enter Store Password<span className="error">*</span></label>
                            <input type="password" id="store-pwd" className="form-control" placeholder="Please enter store password" autoComplete="off"/>
                          </div>
                        </div>
                        <p className="error">{this.state.generate_error}</p>
                        </>
                      }
                      {(this.state.key_success===true) &&
                        <div className="success-circle-loader" id="success-circle-loader">
                          <div className="checkmark-success draw" id="checkmark-success"></div>
                        </div>
                      }
                  
                    </div>
                    {(this.state.key_success===false) &&
                      <div className="modal-footer justify-content-center" style={{padding: '10px 5px 25px'}}>
                        <button type="button" className="btn btn-secondary pull-left" onClick={this.handleCloseKeyPopup}>Close</button>
                        <button type="button" id="generate-key" className="btn btn-success pull-right" onClick={this.handleGeneratePrivateKey}>Generate</button>
                        <button type="button" id="generate-key-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                     </div>
                    }
                  </div>
                </div>
              </div> 

            </div>
        );
    }
}