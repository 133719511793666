import React, { Component } from "react";
import utils from '../utils.js';
import {Document, Page, View,Text,StyleSheet,Font  } from '@react-pdf/renderer';
import PDFPoweredBy from "./pdf-powered-by.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class ReportPDFFormat1 extends Component {
    constructor() {
      super();
      this.state = {
        
      };
      this.currency_format = utils.currency_format.bind(this);
    }
    render() {
    	
        return (
           <Document>
		    <Page size="A4" wrap>
			    <View>
				    	<View style={{textAlign:'center',paddingTop:'10pt'}}>
				    		<Text>{user_info.company_name}</Text>
				    	</View>
				    	{(this.props.contact_info && this.props.contact_info.name!==undefined) &&
					    	<View style={{textAlign:'center',paddingTop:'5pt'}}>
					    		<Text style={{fontSize:'12pt'}}>{this.props.contact_info.name}</Text>
					    		{(this.props.contact_info.address_1!=="")&&
					    			<Text style={{fontSize:'12pt'}}>{this.props.contact_info.address_1}</Text>
					    		}
					    		<Text style={{fontSize:'12pt'}}>
					    			{(this.props.contact_info.email!=="")?'Email : '+this.props.contact_info.email:''}
					    			{(this.props.contact_info.phone_1!=="")?' Mobile : '+this.props.contact_info.phone_1+" ":' '}
					    		</Text>
					    		
					    	</View>
				    	}
				    	
				    	<View style={{textAlign:'center',paddingBottom:'10pt'}}>
				    		<Text style={{fontSize:'12pt'}}>
				    			Ledger Account
				    		</Text>
				    		<Text style={{fontSize:'12pt'}}>
				    			{this.props.ledger_from_date} - {this.props.ledger_to_date}
				    		</Text>
				    	</View>
			    	
			       	
			        	<View style={styles.heading}>
			        		<Text style={styles.headTxt1}>Date</Text>
			        		<Text style={styles.headTxt1}>Invoice #</Text>
			        		<Text style={styles.headTxt1}>Type</Text>
			        		<Text style={styles.headTxt1}>Reference</Text>
			        		<Text style={styles.headDC}>Debit(-)</Text>
			        		<Text style={styles.headDC}>Credit(+)</Text>
			        		<Text style={styles.headDC}>Balance</Text>
			        	</View>
				      
			          	
			            {this.props.report_data.map( (value, index) => (
			                <View style={styles.view} break={(index===19 || (index>22 && index%22===0))?true:false}>
			                	<Text style={styles.hfirst}>{value.created_on}</Text>
			                	<Text style={styles.hother}>{value.invoice_number}</Text>
			                	<Text style={styles.hother}>{(value.target)?value.target.replace("_", " "):''}</Text>
			                	<Text style={styles.hother}> {(value.refrence_no!=='')?value.refrence_no:'-'}</Text>
			                	<Text style={styles.hdc}>{(value.debit!==0)?this.currency_format(value.debit):'-'}</Text>
			                	<Text style={styles.hdc}>{(value.credit!==0)?this.currency_format(value.credit):'-'}</Text>
			                	<Text style={styles.hdc}>{(value.balance!==0)?this.currency_format(value.balance):'-'}</Text>
			                </View>
			            	))
			        	}
			        	<View style={{marginTop:'10pt',fontSize:'12pt',flexDirection:'row',border:'1pt solid #d7d7d7',backgroundColor:'#e9ecef'}}>
			        		<Text style={styles.hfirst}>Grand Total</Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		
			        		<Text style={styles.hdc}>{this.currency_format(this.props.total_pdf_debit)}</Text>
			        		<Text style={styles.hdc}>{this.currency_format(this.props.total_pdf_credit)}</Text>
			        		<Text style={styles.hdc}></Text>
			        		
			        	</View>
			        	<View style={{fontSize:'12pt',flexDirection:'row',border:'1pt solid #d7d7d7'}}>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}>By Closing Balance</Text>
			        		
			        		<Text style={styles.hdc}>{this.currency_format(this.props.closing_debit)}</Text>
			        		<Text style={styles.hdc}>{this.currency_format(this.props.closing_credit)}</Text>
			        		<Text style={styles.hdc}></Text>
			        		
			        	</View>
			        	<View style={{fontSize:'12pt',flexDirection:'row',border:'1pt solid #d7d7d7',backgroundColor:'#e9ecef'}}>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hfirst}></Text>
			        		<Text style={styles.hdc}>{this.currency_format(this.props.total_closing_debit)}</Text>
			        		<Text style={styles.hdc}>{this.currency_format(this.props.total_closing_credit)}</Text>
			        		<Text style={styles.hdc}></Text>
			        		
			        	</View>
			        
					<PDFPoweredBy />
		        </View>
		    </Page>
		  </Document>
        );
    }
}
const styles = StyleSheet.create({
	container: {margin:'20pt'},
	view: {flexDirection:'row',borderBottom:'1pt solid #d7d7d7'},
	month: {fontSize:'10pt',flexDirection:'row',padding:'6pt'},
	block: {fontSize:'10pt',borderLeft:'1pt solid #d7d7d7',borderTop:'1pt solid #d7d7d7',borderRight:'1pt solid #d7d7d7'},
	heading: {fontSize:'10pt',flexDirection:'row',border:'1pt solid #d7d7d7',backgroundColor:'#e9ecef'},
	credit : {backgroundColor:'#e4ffe5',borderLeft:'1pt solid #d7d7d7',width:'100pt',padding:'5pt',fontSize:'10pt'},
	balance : {borderLeft:'1pt solid #d7d7d7',width:'100pt',padding:'5pt'},
	debit : {backgroundColor:'#ffefef',borderLeft:'1pt solid #d7d7d7',width:'100pt',padding:'5pt',fontSize:'10pt'},
	hfirst : {padding:'5pt',width:'100pt',fontSize:'10pt'},
	hother : {textTransform:"capitalize", borderLeft:'1pt solid #d7d7d7',padding:'5pt',width:'100pt',fontSize:'10pt'},
	hdc : {textTransform:"capitalize", borderLeft:'1pt solid #d7d7d7',padding:'5pt',width:'60pt',fontSize:'10pt'},

	headTxt : {borderLeft:'1pt solid #d7d7d7',width:'100pt',padding:'5pt',fontSize:'10pt'},
	headTxt1 : {padding:'5pt',width:'100pt',fontSize:'10pt'},
	headDC : {padding:'5pt',width:'60pt',fontSize:'10pt'},

});