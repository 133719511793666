import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faChevronRight, faKey, faLockOpen, faExclamationCircle  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../../utils.js';
import ServiceLoader from "../service-loader";
let {enc_key,app_api,user_info} = require('../../library.js');
export default class ModuleAccess extends Component {
     constructor() {
      super();
      this.state = {
        show_loader:false
      }
      this.module_access = ['accounts','link store','stock transfer'];
    }

    handleCloseModule = () =>{
        this.props.handleCloseModule();
    }
    handleSaveModule = (e) =>{
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");

        let module_access_check = document.querySelectorAll('.module-access-check');
        let module_access = [];
        for (let index = 0; index < module_access_check.length; index++) {
            const element = module_access_check[index];
            const key = element.id;
            if(element.checked){
                module_access.push(key);
            }
        }
        var body = {
            store_name : this.props.store_info.store_name,
            module_access : module_access
        };
        axios.post(app_api+'/admin/save_module_access', body)
        .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            this.props.handleCloseModule();
            window.location.reload()
        }.bind(this)).catch(function(res){
        })
    }

    render() {
        return (
            <div className="modal" id="module-access-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                    <div className="row">
                      <div className="col-md-12">
                        <h1 style={{
    textAlign: 'center',
    fontSize:'65px',
    color: '#28a745'
}}><FontAwesomeIcon icon={faLockOpen} /></h1>
                    
                      {(this.state.show_loader==true) &&
                        <ServiceLoader show={this.state.show_loader}/>
                      } 
                      {(this.state.show_loader===false) &&
                        <>
                            <div  style={{
    border: '1px solid #ed6c02',
    padding: '10px',
    display: 'flex'
    }}>
        <FontAwesomeIcon icon={faExclamationCircle} style={{fontSize: '25px',
    marginTop: '8px',
    color: '#ed6c02'}}/> 
        <p  style={{color: '#3c3c3c',
    textTransform: 'uppercase',
    fontSize: '14px',
    margin: 0,
    textAlign: 'center',
    fontFamily: 'monospace'}}>Allow/Prevent <b><i>{this.props.store_info.company_name}'s</i></b> Module access by checking/unchecking the following checkbox(es)</p></div>
                            <div className="box-container" id="module-access-container">
                                
                                    <ul key="0" className="roles-ul list-group  list-group-flush" style={{listStyleType:'none',paddingLeft:'0px'}}>

                                        {(this.module_access.map((element,index) =>
                                        <>
                                     
                                            <li key="1" className="list-group-item">
                                            <div className="form-check form-check-inline">
                                            <input className="form-check-input module-access-check" type="checkbox" id={element} defaultChecked={(this.props.module_access && this.props.module_access[element]!==undefined)?true:false} onChange={this.handleOptChange}/>
                                            <label className="form-check-label chklbl" htmlFor={element} id={"lbl-"+element}>{element.replace('_',' ')}</label>
                                            </div>
                                            </li>
                                            
                                            </>
                                        ))}
                                    </ul>
                                
                            </div>
                        </>
                      }
                      
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row">
                        <div className="col-md-6">
                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseModule}>Close</button>
                        </div>
                        <div className="col-md-6">
                        <button type="button" className="btn btn-success pull-right" onClick={this.handleSaveModule} id="save-module">Save Access</button>
                        <button type="button" id="save-module-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

           
        );
    }
}