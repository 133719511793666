import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch, faTrash,faCaretRight,faCaretDown, faShoppingCart,faBell, faSort, faUpload, faCloud, faCloudUploadAlt  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import ClosedFYButton from "./closed-fy-button";
import Pager from "./pager";
import Tooltip from "./tooltip";
import BulkAction from "./bulk-action";
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import {QRCodeSVG} from 'qrcode.react';
import Link from './link.js';
import utils from '../utils.js';
import ModalBox from "./modal-box";
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class Orders extends Component {
    constructor() {
        super();
        this.state = {
          FromDate:  new Date(),
          ToDate: new Date(),
          active_tab:'sales',
          loading_process :'Loading...',
          billing_list : [],
          billing_list_temp : [],
          total_page : '',
          trash_count : 0,
          hold_count : 0,
          top_option:'',
          create_button : 'New Sale',
          button_ref : 1,
          is_search : false,
          sort_type : '',
          is_fy_closed : false,
          is_create_quotation : false,
          delete_cancelled_bill : false,
          send_e_invoice : false,
          not_env_count :0,
          advance_sale_data : [
            {key:'solagent',value:'AGENT NAME'},
            {key:'soltransport',value:'TRANSPORT'},
            {key:'sollrno',value:'LR NO'},
            {key:'solsuprefno',value:'SUPPLIER\'S REFRENCE NO'},
            {key:'soldelivery-note',value:'DELIVERY NOTE'},
            {key:'soloth-note-from-sup',value:'OTHER NOTE FROM SUPPLIER'},
            {key:'solother-ref',value:'OTHER REFERENCE'},
            {key:'solterms-of-delivery',value:'TERMS OF DELIVERY'},
            {key:'solpieces-in-parcel',value:'PIECES IN PARCEL'},
            {key:'solno-of-bales',value:'NO. OF PARCELS/BALES'},
            {key:'solpacked-by',value:'PACKED BY'},
            {key:'solchecked-by',value:'CHECKED BY'},
          ]
        }
        _this = this;
        this.timeout = null;
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleBillSearch = this.handleBillSearch.bind(this);
        this.handleShowPayment = this.handleShowPayment.bind(this);
        this.handleSortList = this.handleSortList.bind(this);
        this.refreshBillList = this.refreshBillList.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };
  openSendEInvoicePopup = ()=>{
    var element = document.getElementById('generate_e_invoice_popup');
    element.classList.remove("fade");
    element.style.display="block"; 
}
  handleSortList(e){
    let sort_on = e.currentTarget.getAttribute("data-id");
    this.setState({sort_type:sort_on});
    let curl = window.location.href;
    const billings = curl.match(/orders$/i);
    const active_tab = curl.match(/orders\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/orders\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(active_tab && active_tab[1]){
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }
    const trash_hold = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold[2];
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
      let a_tab = trash_hold_page[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold_page[2];
      current_page = trash_hold_page[3];
    }
    this.setState({loading_process:'Loading...'});
    this.setState({ billing_list: [] });
    this.setState({ billing_list_temp: [] });
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              sort_on : sort_on,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/orders/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
    
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }


  handleGeteInvoiceCheck = (current_tab) =>{
    
    let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              target : current_tab,
              action : 'check',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/check_bulk_e_invoice';
        axios.post(api, body)
        .then(function (response) {
            this.setState({ not_env_count: response.data.count });
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }

  handleBillSearch(e){
      let item_name = e.currentTarget.value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ billing_list: [],is_search:true });
        clearTimeout(this.timeout);
        let api = app_api+'/orders/search_bill';
        _this.timeout = setTimeout(function () {
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : item_name,
              search_item : amount_item,
              active_tab : _this.state.active_tab+'_order',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
              let trash_count = response.data.trash_count;
              let hold_count = response.data.hold_count;
            
            if(response.data.is_empty){
              _this.setState({loading_process:'Record(s) not found'});
            }else{
              _this.setState({total_page:page_count});
              _this.setState({ billing_list: response.data.data });
            }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          this.refreshBillList();
          this.setState({is_search:false });
        }
      }
  }

  handleShowPayment(e){
    let id = e.currentTarget.id;
    if(document.querySelectorAll(".payment-row-"+id)){
      let elem = document.querySelectorAll(".payment-row-"+id);
      for(let i=0;i<elem.length;i++){
        elem[i].classList.toggle("hide");
      }
    }
    if(document.getElementById("caret-right-"+id)){
      document.getElementById("caret-right-"+id).classList.toggle("hide");
      document.getElementById("caret-down-"+id).classList.toggle("hide");
    }
  }

  handleCheckAll(e){
      let checked = e.currentTarget.checked;
      var cb = document.querySelectorAll('.sub-checkbox'); 
      for (var i = 0; i < cb.length; i++) { 
          cb[i].checked = checked; 
      }
  }

  componentDidMount(){
    localStorage.removeItem('response_id');
    localStorage.removeItem('open_list_view');
    localStorage.removeItem('save_print');
    localStorage.removeItem('open_new_bill');
    let curl = window.location.href;
    const billings = curl.match(/orders$/i);
    const active_tab = curl.match(/orders\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/orders\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(billings){
     this.setState({active_tab:'sales'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'sales'});
    }
    const trash_hold = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[2];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
        let a_tab = trash_hold_page[1];
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[2];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[3];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Sale Order';
      let button_ref = 1;
      if(current_tab==='purchase'){
          button ='New Purchase Order';
          button_ref = 2;
      }else if(current_tab==='purchase_return'){
        button = 'New Purchase Return Order';
        button_ref = 4;
      }else if(current_tab==='sales_return'){
        button = 'New Sales Return Order';
        button_ref = 3;
      }
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/orders/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;
            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:page_count});
              this.setState({ billing_list: response.data.data });
              this.setState({ billing_list_temp: response.data.data });
            }
            this.setState({ is_fy_closed: response.data.is_fy_closed });
      }.bind(this)).catch(function(res){
          if(res.response){
             //this.setState({loading_process:'Record(s) not found'});
          }
      })

      if(current_tab==='sales' || current_tab==='sales_return' || current_tab==='purchase' || current_tab==='purchase_return'){
        
          let qs = document.querySelectorAll(".default-hide");
          
          for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            element.classList.add('hide');
          }
      }

      let bc_send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        keys:"'is_create_quotation','delete_cancelled_bill','send_e_invoice','sales_order_list_opt_visibility','sales_order_return_list_opt_visibility','purchase_order_list_opt_visibility','purchase_order_return_list_opt_visibility'",
        uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
    }
    let bc_api = app_api+'/get_settings';
    axios.post(bc_api, bc_send_data)
      .then(function (response) {
        let resp = response.data;
        
        if(resp.is_create_quotation){
          let ps = resp.is_create_quotation;
          this.setState({is_create_quotation:ps});
        }
        if(resp.send_e_invoice){
          let ps = resp.send_e_invoice;
          this.setState({send_e_invoice:ps});
        }
        if(resp.delete_cancelled_bill){
          let ps = resp.delete_cancelled_bill;
          this.setState({delete_cancelled_bill:ps});
        }
        if(current_tab==='sales'){
          if(resp.sales_order_list_opt_visibility){
            let ps = resp.sales_order_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const tid = qs[index].id;
              if(ps.indexOf(tid)<0){
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='sales_return'){
          if(resp.sales_order_return_list_opt_visibility){
            let ps = resp.sales_order_return_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              tid = tid.replace('sol','sorl');
              tid = tid.replace('pl','sorl');
              if(ps.indexOf(tid)<0){
                tid = tid.replace('sorl','sol');
                tid = tid.replace('sorl','pl');
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='purchase'){
          if(resp.purchase_order_list_opt_visibility){
            let ps = resp.purchase_order_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              tid = tid.replace('sol','pol');
              tid = tid.replace('pl','pol');
             
              if(ps.indexOf(tid)<0){
                tid = tid.replace('pol','sol');
                tid = tid.replace('pol','pl');
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='purchase_return'){
          if(resp.purchase_order_return_list_opt_visibility){
            let ps = resp.purchase_order_return_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              
              tid = tid.replace('sol','porl');
              
              if(ps.indexOf(tid)<0){
                tid = tid.replace('porl','sol');
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
    if(current_tab==='sales' || current_tab==='purchase'){
      this.handleGeteInvoiceCheck(current_tab);
    }
  }

  refreshBillList(){
    let curl = window.location.href;
    const billings = curl.match(/orders$/i);
    const active_tab = curl.match(/orders\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/orders\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(billings){
     this.setState({active_tab:'sales'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'sales'});
    }
    const trash_hold = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[2];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
        let a_tab = trash_hold_page[1];
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[2];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[3];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Sale';
      let button_ref = 1;
      if(current_tab==='purchase'){
          button ='New Purchase';
          button_ref = 2;
      }else if(current_tab==='purchase_return'){
        button = 'New Purchase Return';
        button_ref = 4;
      }else if(current_tab==='sales_return'){
        button = 'New Sales Return';
        button_ref = 3;
      }
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/orders/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;
            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }
  handleShowHoldBill = (e) =>{
      if(document.getElementById("handleShowHoldBill")){
        document.getElementById("handleShowHoldBill").click();
      }
  }
    render() {
        let data_value = 'billing';
        if(this.state.top_option==='hold'){
          data_value = 'hold_bill';
        }
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">{this.state.active_tab.replace('_',' ')} List</b>
                        </div>
                    </div>
                    
                    <div className="col-md-8">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onChange={this.handleBillSearch} id="bill-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                        {(this.is_access('billing-sale-create') || this.is_access('billing-purchase-create') || this.is_access('billing-sales-return-create') || this.is_access('billing-purchase-return-create')) &&
                        <div className="pull-right">

                          {(this.state.is_fy_closed===false) &&
                          <>
                            <a href={"/new-order/"+this.state.button_ref}><button type="button" className="btn btn-success pull-right nav-btn">{this.state.create_button}</button></a>
                            <ShortcutHelp  arrow="top" target="new" shortcuts={['Ctrl+Alt+N']}/>
                          </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <ClosedFYButton className="pull-right nav-btn" label={this.state.create_button} type="button"/>
                          }
                        </div>
                        }
                        <div className="pull-right">

                         <Notification /> 

                        {this.state.trash_count>0 &&
                          <a href={"/orders/"+this.state.active_tab+"/trash"} className="btrash">
                            <FontAwesomeIcon  icon={faTrash}  className=""/><span className="notif-count">{this.state.trash_count}</span>
                          </a>
                        }
                        {(this.state.hold_count>0 && utils.isMobile()===true) &&
                          <a href={"/orders/"+this.state.active_tab+'/hold'} className="bcart">
                            <FontAwesomeIcon  icon={faShoppingCart} className=""/><span className="notif-count">{this.state.hold_count}</span>
                          </a>
                        }
                        </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  {/* {(this.state.not_env_count>0 && this.state.send_e_invoice===true) &&
                    <div className="alert alert-warning text-center" role="alert" style={{paddingTop: '0px',paddingBottom: '3px'}}>
                      <p style={{margin:'0px'}}>You have {this.state.not_env_count} UnSynced {this.state.active_tab} e-Invoice. Please <button className="btn btn-warning btn-sm" onClick={this.openSendEInvoicePopup}  style={{paddingBottom:'0px',paddingTop:'0px'}}>Click Here</button> to Sync it.</p>
                    </div>
                  } */}
                  {this.state.is_search===false && this.state.top_option==='' &&
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      {this.is_access('billing-sale') &&
                        <li className={this.state.active_tab==='sales'?'active':''}><a href="/orders">Sales</a></li>
                      }
                      {this.is_access('billing-purchase') &&
                        <li className={this.state.active_tab==='purchase'?'active':''}><a href="/orders/purchase">Purchase</a></li>
                      }
                      {this.is_access('billing-sales-return') &&
                        <li className={this.state.active_tab==='sales_return'?'active':''}><a href="/orders/sales_return">Sales Return</a></li>
                      }
                      {this.is_access('billing-purchase-return') &&
                        <li className={this.state.active_tab==='purchase_return'?'active':''}><a href="/orders/purchase_return">Purchase Return</a></li>
                      }
                       {this.is_access('payments-in') &&
                        <li className={this.state.active_tab==='in'?'active':''}><a href="/order/payments/in">Payment Received</a></li>
                      }
                      {this.is_access('payments-out') &&
                        <li className={this.state.active_tab==='out'?'active':''}><a href="/order/payments/out">Payment Sent</a></li>
                      }
                      {(this.state.hold_count>0 && utils.isMobile()===false) &&
                        <li title="Alt + H" className={this.state.active_tab==='hold'?'active hold_title':'hold_title'}>
                          <a href={"/orders/"+this.state.active_tab+'/hold'}><FontAwesomeIcon  icon={faShoppingCart} className=""/> On Hold {this.state.active_tab.replace("_"," ")}({this.state.hold_count})</a>
                        </li>
                      }
                    </ul>
                  <ShortcutHelp target="tabs"  arrow="top" shortcuts={['Ctrl+Alt+1','Ctrl+Alt+2','Ctrl+Alt+3','Ctrl+Alt+4']}/>

                  </div>
                  }
                  <div className="content-area">
                   {this.state.top_option==='' &&
                     <div className="content-filter hide">
                        <div className="row">
                          <div className="col-md-4">
                              <label className="lbl">SHOW SALES</label>
                              <select className="form-control">
                                  <option value="">All</option>
                              </select>
                          </div>
                          <div className="col-md-4">
                            <div className="input-group mrtop-32">
                              <input type="text" className="form-control no-border-right" placeholder="Enter Sales #, Amount #, Balance #, Status #" />
                              <div className="input-group-append">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker  className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                   }
                     <div className="main-content" style={{overflow:'auto'}}>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                          {this.state.top_option==='trash' &&
                            <th scope="col" width="54">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" onChange={this.handleCheckAll}/>
                              </div>
                            </th>
                          } 
                          {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">SALES # <span><FontAwesomeIcon icon={faSort} className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='sales_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">SALES RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.is_search===true) &&
                            <th>INVOICE #</th>
                          }
                           <th scope="col" onClick={this.handleSortList} data-id="date" className="sort-head default-hide" id="soldate">DATE <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='date')?'sort-on-date':''}/></span></th>
                            <th scope="col" className="default-hide" id="solname">NAME</th>
                            {(this.state.top_option==='hold') &&
                              <th scope="col">STAFF</th>
                            }
                            <th scope="col" className="default-hide" id="solamount">AMOUNT</th>
                            {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                              <th scope="col" className="default-hide" id="solpending-amt">PENDING AMOUNT</th>
                            }
                            {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                              <th scope="col" className="default-hide" id="plpayment-mode">MODE OF PAYMENT</th>
                            }
                            {(this.state.active_tab==='sales' || this.state.active_tab==='sales_return' || this.state.active_tab==='purchase' || this.state.active_tab==='purchase_return') &&
                            <>
                              {(this.state.advance_sale_data.map((value)=>{
                                return(
                                  <th scope="col" className="default-hide" id={value.key}>{value.value}</th>
                                )
                              }))}
                            </>
                            }
                            <th scope="col" className="default-hide" id="solstatus">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                         <tbody>
                           {(this.state.billing_list && this.state.billing_list.length > 0) ?this.state.billing_list.map( (value, index) => {
                           
                            
                           return (
                            <>
                            <tr key={ index } id={"billing-row-"+value.id} className="contact-row" data-id={this.state.top_option==='trash'?10:9}>
                              {this.state.top_option==='trash' &&
                                 <td>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input sub-checkbox" type="checkbox" value={value.id}/>
                                  </div>
                                </td>
                              }
                              <td onClick={this.handleShowPayment} id={value.id}>
                              <Link link={"/view-order/"+value.data_target+"/"+value.id} name={value.number} />
                              {(value.is_online===true) &&
                                <span className="online-strip">Online</span>
                              }
                             {/*  {(this.state.send_e_invoice===true && value.is_e_invoice_generated===false) &&
                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{color:'#b3b3b3',marginLeft:'5px'}} title="Generate eInvoice"/>
                              } */}
                              
                              </td>
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('soldate')>=0 || value.sales_list_opt_visibility.indexOf('sorldate')>=0 || value.sales_list_opt_visibility.indexOf('poldate')>=0 || value.sales_list_opt_visibility.indexOf('porldate')>=0))?'default-hide hide':''}>{value.created_date}</td>
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('solname')>=0 || value.sales_list_opt_visibility.indexOf('sorlname')>=0 || value.sales_list_opt_visibility.indexOf('polname')>=0 || value.sales_list_opt_visibility.indexOf('porlname')>=0))?'default-hide hide':''}><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} /></td>
                              {(this.state.top_option==='hold') &&
                                <td>{value.staff_name}</td>
                              }
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('solamount')>=0 || value.sales_list_opt_visibility.indexOf('sorlamount')>=0 || value.sales_list_opt_visibility.indexOf('polamount')>=0 || value.sales_list_opt_visibility.indexOf('porlamount')>=0))?'default-hide hide':''}>{value.total}</td>
                              {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                              <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solpending-amt')>=0)?'default-hide hide':''}>{value.pending_amount}</td>
                              }
                              {(this.state.active_tab==='purchase'  && this.state.is_search===false) &&
                                <td  className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polpayment-mode')>=0)?'default-hide hide':''}>{value.payment_mode}</td>
                              }
                              {(this.state.active_tab==='sales' && value.data_target==='sales') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('soltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sollrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('soldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('soloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('solchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              {(this.state.active_tab==='purchase' && value.data_target==='purchase') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polinvdate')>=0)?'default-hide hide':''}>{value.dispatched_info.invoice_date}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('poltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('pollrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('poldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('poloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('polchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              
                              {(this.state.active_tab==='sales_return' && value.data_target==='sales_return') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sorlchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              {(this.state.active_tab==='purchase_return' && value.data_target==='purchase_return') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlinvdate')>=0)?'default-hide hide':''}>{value.dispatched_info.invoice_date}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('porlchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('solstatus')>=0 || value.sales_list_opt_visibility.indexOf('sorlstatus')>=0 || value.sales_list_opt_visibility.indexOf('polstatus')>=0 || value.sales_list_opt_visibility.indexOf('porlstatus')>=0))?'hide default-hide':''}>{value.bill_status}</td>
                             
                                <td>
                                    {(value.bill_status==='Cancelled')?
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-order/"+this.state.active_tab+"/"+value.id} resume={"/new-order/resume/"+this.state.active_tab+"/"+value.id} target={this.state.active_tab+'_order'} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} is_delete_cancelled_bill={{is_delete_cancelled_bill:this.state.delete_cancelled_bill,active_tab:this.state.active_tab+'_order',id:value.id,data_value:data_value,delete_label:'Delete'}} />
                                      :
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-order/"+this.state.active_tab+"/"+value.id} edit={(value.bill_created_type!==undefined && value.bill_created_type==='pos')?"/new-pos/order/"+value.id:"/new-order/edit/"+this.state.active_tab+"/"+value.id} delete={{active_tab:this.state.active_tab+'_order',id:value.id,data_value:data_value,delete_label:(value.is_current?'Delete':'Cancel')}} target={this.state.active_tab} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                    }
                                </td>
                              
                            </tr>
                          </>
                          )
                         }) : <tr><td colSpan={this.state.top_option==='trash'?9:8} className="text-center">{_this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {this.state.top_option==='trash' &&
                        <BulkAction data_target={this.state.active_tab} />
                      }
                      {(this.state.billing_list && this.state.billing_list.length > 0) &&
                        <ListCount list={this.state.billing_list}/>
                      }
                      {(this.state.is_search===false) &&
                        <Pager total_page={this.state.total_page}/>
                      }
                     </div>
                  </div>
                </div>
              </div>
              <HoldBillPopup data={{screen:'order',billing_type:this.state.active_tab,update_id:0}}/>
              {(this.state.send_e_invoice===true) &&
                <ModalBox id="generate_e_invoice_popup" title="Generate e-Invoice" target={this.state.active_tab} />
              }
            </div>
        );
    }
}