import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import ContactsDropdown from "./contacts-dropdown";
import Autocomplete from "./autocomplete";
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
let _this;
let db;
export default class CreateCreditDebitNote extends Component {
    constructor() {
      super();
      this.state = {
        note_symbol : '+',
        is_show_additinal_field : false,
        row_count : 1,
        note_type : 'credit',
        is_show_add_more: true,
        note_list_item : [{desc:'',invoice_no:'',amount:'',tax:'',invoice_id:'',symbol:'+'}]
      };
      _this = this;
      this.is_access = check_access.is_access.bind(this);
    }
    handleChangeNoteType = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({note_type:thisval})
    }
    componentDidMount = () =>{
        if(this.props.data && this.props.data.bill_info){
            this.setState({is_show_additinal_field:true});
            this.setState({is_show_add_more:false});
            if(this.props.data.list_data){
               this.setState({note_list_item:[this.props.data.list_data]});
            }
        }
    }

    handSaveNote = (e) =>{
        let thisid = e.currentTarget.id;
       
        let note_type = document.getElementById("note-type").value;
        let note_reason = document.getElementById("note-reason").value;
        let customer = document.getElementById("customer-dropdown").value;
        let item_data = this.state.note_list_item;
        /* for (let index = 0; index < this.state.row_count; index++) {
           let desc = document.getElementById("note-desc-"+index).value;
           let invoice_no = document.getElementById("bill_search_list_box-"+index).value;
           if(invoice_no===""){
            document.getElementById("bill_search_list_box-"+index).focus();
            return false;
           }
           let invoice_id = document.getElementById("bill_search_list-"+index).value;
           let amount = document.getElementById("note-amount-"+index).value;
           let tax = '';
           if(document.getElementById("note_tax_"+index)){
            tax = document.getElementById("note_tax_"+index).value;
           }
           if(tax!==""){
            tax = parseFloat(tax.match(/[\d\.]+/));
           }
           let symbol =  document.querySelector('input[name="note-opt-'+index+'"]:checked').value;
           let meta_data = {
            desc : desc,
            invoice_no : invoice_no,
            invoice_id : invoice_id,
            amount : amount,
            symbol : symbol,
            tax : tax,
           }
           item_data.push(meta_data);
        } */
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let update_id = 0;
        if(this.props.data && this.props.data.id){
            update_id = this.props.data.id;
        }
        let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, 
                _schema : user_info._schema,
                item_data : item_data,
                note_type : note_type,
                note_reason : note_reason,
                customer : customer,
                update_id : update_id,
            }
          let api = app_api+'/create_cr_dr_note';
          axios.post(api, body)
            .then(function (response) {
               window.location.reload();
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
    }
    handleCloseNoteModal = () =>{
        this.props.handleCloseNoteModal();
    }
    handleChangeSymOpt = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({note_symbol:thisval})
    }
    render() {
        return (
            <div className="modal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {(this.props.data && this.props.data.sequence_number)?
                            'Update '+this.props.data.sequence_number:'Create Note'
                        }
                    </h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-3">
                                <label>NOTE TYPE</label>
                                <select className="form-control" id="note-type" defaultValue={(this.props.data && this.props.data.note_target_type)?this.props.data.note_target_type:this.state.note_type} key={(this.props.data && this.props.data.note_target_type)?this.props.data.note_target_type:this.state.note_type} onChange={this.handleChangeNoteType}>
                                    <option value="credit">Credit Note</option>
                                    <option value="debit">Debit Note</option>
                                    <option value="cash">Cash</option>
                                    <option value="onaccount">On Account</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>REASON</label>
                                <select className="form-control" id='note-reason' defaultValue={(this.props.data && this.props.data.note_reason)?this.props.data.note_reason:'rate'} key={(this.props.data && this.props.data.note_reason)?this.props.data.note_reason:'rate'}>
                                    <option value="rate">Rate Difference</option>
                                    <option value="discount">Discount</option>
                                    <option value="item_short">Item Short</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label>CONTACT <span className="red">*</span></label>
                                <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={(this.props.data && this.props.data.contact_info && this.props.data.contact_info.contact_id)?{item_id:this.props.data.contact_info.contact_id,item_name:this.props.data.contact_info.name}:[]} index="note"/>
                                <span id="showNoteAdditionalField" onClick={this.handleShowAdditionalField}></span>
                            </div>
                        </div>
                        {(this.state.is_show_additinal_field === true) &&
                            <>
                                <hr />
                                {(this.state.note_list_item.map((value,i)=>{
                                    return(
                                        <>
                                            <div className="row">
                                            <div className="col-md-2">
                                                <label htmlFor={"note-desc-"+i}>Description</label>
                                                <input type="text" className="form-control items-field" id={"note-desc-"+i} tabIndex={1} value={value.desc} onChange={(e)=>this.handleChangeItemValue('desc',i,e.target.value)}/>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="note-note">Invoice No.</label>
                                                <Autocomplete
                                                    options={[]}
                                                    title="Type to search invoice"
                                                    data_target={"bill_search_list-"+i}
                                                    id={"bill_search_list-"+i}
                                                    className=" auto-comp-opt"
                                                    clsName="items-field"
                                                    data_id={i}
                                                    target='note'
                                                    input_id={"bill_search_list_box-"+i}
                                                    item_info={{item_id:value.invoice_id,item_name:value.invoice_no}}
                                                    handleChangeInvoice={this.handleChangeInvoice}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label htmlFor={"note-amount-"+i}>Amount</label>
                                                <input type="text" className="form-control items-field" id={"note-amount-"+i} data_id={i}  value={value.amount}  onChange={(e)=>this.handleChangeItemValue('amount',i,e.target.value)}/>
                                            </div>
                                            {(this.state.note_type==='debit' || this.state.note_type==='credit') &&
                                                <div className="col-md-2">
                                                <label htmlFor={"note_tax_"+i}>GST%</label>
                                                    <input list={"note_tax_li_"+i}  className="form-control" placeholder="%" id={"note_tax_"+i} data-id={i}  data-target="note_tax" target="note_tax" value={value.tax}  onChange={(e)=>this.handleChangeItemValue('tax',i,e.target.value)}/>
                                                    <datalist id={"note_tax_li_"+i}>
                                                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                                        return (
                                                        <option key={index}>{value.value}</option>
                                                        )
                                                    }) : '' }
                                                    </datalist>
                                                </div>
                                            }
                                            <div className="col-md-1">
                                                <div className="form-check" style={{marginTop:'25px'}}>
                                                    <input className="form-check-input  items-field" type="radio" name={"note-opt-"+i} id={"note-opt1-"+i}  value='+' data_id={i} checked={(value.symbol==='+')?true:false}  onChange={(e)=>this.handleChangeItemValue('symbol',i,e.target.value)}/>
                                                    <label className="form-check-label" for={"note-opt1-"+i}>
                                                        + 
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input  items-field" type="radio" name={"note-opt-"+i} id={"note-opt2-"+i} value='-' data_id={i}  checked={(value.symbol==='-')?true:false} onChange={(e)=>this.handleChangeItemValue('symbol',i,e.target.value)}/>
                                                    <label className="form-check-label" for={"note-opt2-"+i}>
                                                        - 
                                                    </label>
                                                </div>
                                            </div>
                                            {(this.state.is_show_add_more===true) &&
                                            <div className="col-md-2" style={{marginTop:'40px',textAlign:'right'}}>
                                                {(i===(this.state.note_list_item.length-1)) &&
                                                    <span onClick={this.handleAddMoreItem} style={{cursor: 'pointer',marginRight:'20px'}}><FontAwesomeIcon icon={faPlusCircle}/> </span>
                                                }
                                                <span onClick={()=>this.handleRemoveItem(i)} style={{cursor: 'pointer'}}><FontAwesomeIcon icon={faTimes} style={{color:'#333'}}/> </span>
                                            </div>
                                            }
                                            </div>
                                        </>
                                    )
                                }))}
                            </>
                        }
                    </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseNoteModal} id="close-note">Close</button>
                        {(this.state.is_show_additinal_field === true) &&
                            <>
                                <button type="button" data-id="create-note" className="btn btn-success pull-right" id="create-note" onClick={this.handSaveNote}>Save Note</button>
                                <button type="button" id="create-note-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </>
                        }
                        </div>
                </div>
                </div>
            </div>
        );
    }
    handleChangeInvoice = (index,invoice_no,invoice_id) =>{
        let note_li = this.state.note_list_item;
        note_li[index]['invoice_no'] =invoice_no;
        note_li[index]['invoice_id'] =invoice_id;
        this.setState({note_list_item:note_li});
    }
    handleChangeItemValue = (key,index,value) =>{
        let note_li = this.state.note_list_item;
        note_li[index][key] =value;
        this.setState({note_list_item:note_li});
    }
    handleShowAdditionalField = () =>{
        this.setState({is_show_additinal_field:true})
    }
    handleRemoveItem = (i) =>{
        let note_li = this.state.note_list_item;
        if(note_li.length===1){
            note_li = [{desc:'',invoice_no:'',amount:'',tax:'',invoice_id:'',symbol:'+'}];
            this.setState({note_list_item:note_li});
        }else{
            note_li.splice(i,1);
            this.setState({note_list_item:note_li});
            this.setState({is_show_additinal_field:false});
            let _this = this;
            setTimeout(() => {
                _this.setState({is_show_additinal_field:true});
            }, 10);
        }
    }
    handleAddMoreItem = () =>{
        let note_li = this.state.note_list_item;
        let nitem = {desc:'',invoice_no:'',amount:'',gst:'',invoice_id:'',symbol:'+'};
        note_li.push(nitem);
        this.setState({note_list_item:note_li});
/* 
        let row_count = this.state.row_count+1;
        this.setState({row_count:row_count}) */
    }
}