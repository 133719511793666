import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import Breadcrumbs from "../breadcrumbs";
import CompanyDetailsInfo from "./company-details-info";
import ResetStoreData from "./reset-store-popup.js";
import ClosedFYButton from "../closed-fy-button";
import axios from 'axios';
import utils from '../../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSpinner  } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import '../../../node_modules/react-toastify/dist/ReactToastify.css';

let {enc_key,app_api,user_info} = require('../../library.js');
export default class CompanyDetails extends Component {
    constructor() {
        super();
        this.state = {
           more_info : '',
           is_reset : false,
           is_fy_closed : false,
        };
        this.more_info = [];
        this.handleAddMore = this.handleAddMore.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    saveCompany(e){
      let id = e.currentTarget.id;
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let com_cls = document.getElementsByClassName("comp-detail-info");
      let companies = [];
      
      let photoData = new FormData();
      for(let i=0;i<com_cls.length;i++){
          let id = com_cls[i].getAttribute('data-id');
          let branch_id = document.getElementById("branch_id-"+id).value;
          let upload_photo = document.getElementById("upload_photo-"+id).files[0];
          photoData.append(`photo[${i}]`, upload_photo);

          let company_name = document.getElementById("company_name-"+id).value;
          let company_sub_title = document.getElementById("company_sub_title-"+id).value;
          let phone_1 = document.getElementById("phone_1-"+id).value;
          let address_1 = document.getElementById("address_1-"+id).value;
          let phone_2 = document.getElementById("phone_2-"+id).value;
          let address_2 = document.getElementById("address_2-"+id).value;
          let pincode = document.getElementById("pincode-"+id).value;
          let city = document.getElementById("city-"+id).value;
          let email = document.getElementById("email-"+id).value;
          let gst_type = document.getElementById("gst_type-"+id).value;
          let gst_number = document.getElementById("gst_number-"+id).value;
          let state = document.getElementById("state-"+id).value;
          let pan_number = document.getElementById("pan_number-"+id).value;
          let country = document.getElementById("country-"+id).value;
          let tax_label = document.getElementById("tax_label-"+id).value;
          let business_type = document.getElementById("business_type-"+id).value;

          let company_data = {branch_id:branch_id,company_name:company_name,company_sub_title:company_sub_title,phone_1:phone_1,address_1:address_1,phone_2:phone_2,address_2:address_2,pincode:pincode,city:city,gst_type:gst_type,gst_number:gst_number,state:state,pan_number:pan_number,country:country,tax_label:tax_label,business_type:business_type,email:email};
          companies.push(company_data);
          
      }
      photoData.append('store_name', user_info.store_name);
      photoData.append('branch_id', user_info.login_id);
      photoData.append('user_id', user_info.user_id);
      photoData.append('uuid', utils.uuid());
      photoData.append('role_id', user_info.access_level);
      photoData.append('token', user_info.token);
      photoData.append('_schema', user_info._schema);
      photoData.append('companies', JSON.stringify(companies));
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id:user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            companies:companies,
        }
        let api = app_api+'/create_company';
        axios.post(api, photoData)
          .then(function (response) {
            let resp = response.data;
            toast("Saved successfully");
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
        }.bind(this)).catch(function(res){
            
        })
    }
    componentDidMount(){
        let send_data = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
        }
        let api = app_api+'/get_branch_info';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            for(let i=0;i<resp.length;i++){
              this.more_info.push(<CompanyDetailsInfo key={i} index={i} company_info={resp[i]}/>);
            }
            this.setState({more_info:this.more_info});
              
        }.bind(this)).catch(function(res){
            
        })

        let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'is_financial_year_closed'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      }) 
    }

    handleAddMore(e){
        let com_cls = document.getElementsByClassName("comp-detail-info");
        let index = com_cls.length;
        this.more_info.push(<CompanyDetailsInfo index={index}/>);
        this.setState({more_info:this.more_info});
    }
    handleClickResetBtn = (e) =>{
        this.setState({is_reset:true});
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <Breadcrumbs link="/settings/company-details" title="Company" sub_title="Overview"/>
                    </div>
                    <div className="col-md-4">
                        {(user_info.access_level==="1" || user_info.access_level===1) &&
                          <>
                          <div className="pull-right" style={{margin:'15px 10px'}}>
                            {(this.state.is_fy_closed===false) &&
                              <button className="btn btn-secondary" onClick={this.handleClickResetBtn}>Reset store data</button>
                            }
                            {(this.state.is_fy_closed===true) &&
                              <ClosedFYButton className="btn btn-secondary" label="Reset store data" type="button"/>
                            }
                          </div>
                          </>
                        }
                        <div className="pull-right">
                          <Notification />
                        </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     
                     <div className="content-head border-bottom">
                        <b>Company Overview</b>
                        <p className="info">Information related to the Business</p>
                     </div>
                     <div>
                    {this.state.more_info}
                    </div>
                    <div className="content-head border-bottom company-action-blk">
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                        {this.is_access('settings-company-details-create') &&
                          <button type="button" onClick={this.handleAddMore} className="btn btn-primary btn-save-action hide">Add More Company/Branch</button>
                        }  
                        </div>
                        {(this.is_access('settings-company-details-create') || this.is_access('settings-company-details-modify')) && 
                        <div className="col-md-4 comp-save-blk text-right">
                          <button type="button" id="add-store-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          <button type="button" onClick={this.saveCompany} id="add-store" className="btn btn-success btn-save-action">Save Company</button>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
            
              </div>
              <ResetStoreData is_reset={this.state.is_reset}/>
            </div>
        );
    }
}