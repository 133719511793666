import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import PaymentModes from "./payment-modes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "./autocomplete";
import PaymentItemRow from "./payment-item-row";
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faCheckCircle, faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';

let remove_items = [];
let _this;
export default class AddPaymentItem extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            payment_type : 'general',
            col_md : 3,
            more_rows:[],
            edit_item_selected : 'none',
            edit_item_active_index : 'none',
            item_add_count : 0,
            is_new_item:0,
            is_pay_edit:'hide',
            on_account_price:0,
            on_account_price_fixed:0,
            refrence_no:'',
            is_cheque_payment : false,
            chequeDate:  new Date(),
        };
        _this = this;
        this.more_rows = [];
        this.handleCalculation = this.handleCalculation.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.handleResetField = this.handleResetField.bind(this);
        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
        this.addMoreItem = this.addMoreItem.bind(this);
        this.handleListItemEdit = this.handleListItemEdit.bind(this);
        this.handleListItemRemove = this.handleListItemRemove.bind(this);
        this.forceChangeState = this.forceChangeState.bind(this);
        this.resetEditSelectedState = this.resetEditSelectedState.bind(this);
        this.resetListItem = this.resetListItem.bind(this);
        this.openOnAccountPayment = this.openOnAccountPayment.bind(this);
        this.hideOnAccountPayment = this.hideOnAccountPayment.bind(this);
        this.addOnAccountItems = this.addOnAccountItems.bind(this);
        this.handleChangeRefNo = this.handleChangeRefNo.bind(this);
        
        
    }
    chequeDate = date => {
      this.setState({
        chequeDate: date
      });
    };
    handleChangeRefNo(e){
      this.setState({refrence_no:e.currentTarget.value});
    }
    resetListItem(e){
      this.more_rows = [];
      this.setState({more_rows:this.more_rows});
    }

    openOnAccountPayment(e){
      this.setState({is_pay_edit:''});
    }
    hideOnAccountPayment(e){
      this.setState({is_pay_edit:'hide'});
    }



    handleListItemEdit(e){
      this.setState({is_pay_edit:''});
      let index = e.currentTarget.getAttribute('data-id');
      index = parseInt(index);
      let ikey = 0;
      for(let k=0;k<this.more_rows.length;k++){
        if(this.more_rows[k]!==undefined){
          let ik = this.more_rows[k].index;
          if(ik===index){
            ikey = k;
          }
        }
      }
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'none';
      }
      document.getElementById("item-row-"+index).classList.add('item-edit-mode');
      this.setState({edit_item_selected:ikey,edit_item_active_index:index});

      let payment_type = document.getElementById("payment-type-"+index).innerHTML;
      this.setState({payment_type:payment_type});
      let col_md = 3;
      if(payment_type==='invoice'){
          col_md = 2;
      }
      this.setState({col_md:col_md});
      document.getElementById("payment-type").value = payment_type;
      setTimeout(function(){
      let item_name = (document.getElementById("item-box-"+index))?document.getElementById("item-box-"+index).value:'';

      if(document.getElementById("iitem-box")){document.getElementById("iitem-box").value = item_name};

      let item_id= (document.getElementById("item-name-"+index))?document.getElementById("item-name-"+index).value:0;

      localStorage.setItem('item-row-edit',item_id);


      if(document.getElementById("iitem-name")){document.getElementById("iitem-name").value = item_id};

      let list_item_id = (document.getElementById("item-list-id-"+index))?document.getElementById("item-list-id-"+index).value:0;
      if(document.getElementById("iitem-list-id")){document.getElementById("iitem-list-id").value = list_item_id};


      let refrence_no= (document.getElementById("item-refrence-no-"+index))?document.getElementById("item-refrence-no-"+index).value:0;
      if(document.getElementById("iitem-refrence-no")){document.getElementById("iitem-refrence-no").value = refrence_no};

      let item_total= (document.getElementById("item-total-"+index))?document.getElementById("item-total-"+index).value:0;
      if(document.getElementById("iitem-total")){document.getElementById("iitem-total").value = item_total};

      let item_total_old= (document.getElementById("item-total-old-"+index))?document.getElementById("item-total-old-"+index).value:0;
      if(document.getElementById("iitem-total-old")){document.getElementById("iitem-total-old").value = item_total_old};

      let item_target= (document.getElementById("item-target-"+index))?document.getElementById("item-target-"+index).value:0;
      if(document.getElementById("iitem-target")){document.getElementById("iitem-target").value = item_target};

      let iitem_id= (document.getElementById("item-id-"+index))?document.getElementById("item-id-"+index).value:0;
      if(document.getElementById("iitem-id")){document.getElementById("iitem-id").value = iitem_id};
      },100);
      document.getElementById("payment-type").focus();
    }

    handleListItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');
    
      let invoice_number = document.getElementById("item-box-"+index).value;
      if(document.getElementById("pending-bill-"+invoice_number)){
        document.getElementById("pending-bill-"+invoice_number).classList.remove('hide');
      }

      let item_id = document.getElementById("item-id-"+index).value;
      if(item_id===""){
        item_id = 0;
      } 
      
      if(parseInt(item_id)>0){
        let item = {item_name:item_id};
        remove_items.push(item);
        document.getElementById("delete-row").value = JSON.stringify(remove_items);
      }
      index = parseInt(index);
      let ikey = 0;
      for(let k=0;k<this.more_rows.length;k++){
        if(this.more_rows[k]!==undefined){
          let ik = this.more_rows[k].index;
          if(ik===index){
            ikey = k;
          }
        }
      }
      this.more_rows.splice(ikey,1);
      this.setState({more_rows:this.more_rows});
      setTimeout(function(){
        _this.calculateTotal();
        let rm = document.querySelectorAll(".item-remove");
        if(rm.length===1){
          for(let i=0;i<rm.length;i++){
            //rm[i].style.display = 'none';
          }
        }else{
          for(let i=0;i<rm.length;i++){
            rm[i].style.display = 'block';
          }
        }
        if(document.getElementById("handleCalulateAdjustedAmount")){
          document.getElementById("handleCalulateAdjustedAmount").click();
        }
      },100);
      if(document.getElementById("hanlde-change-force-state")){
        document.getElementById("hanlde-change-force-state").click();
      }
      
    }

    forceChangeState(e){
      this.setState({payment_type:'invoice'});
      this.setState({col_md:2});
    }

    resetEditSelectedState(e){
        this.setState({edit_item_selected:'none',edit_item_active_index:'none'});
    }

    addOnAccountItems(items){
      
      this.setState({payment_type:'invoice'});
      if(document.getElementById("payment-type")){
        document.getElementById("payment-type").value = 'invoice';
      }
      let item_total = document.getElementById("iitem-total").value;
    
      let pb_list = document.querySelectorAll('.pb-list:not(.hide)');
      
      if(pb_list.length>0 && items.payment_type==='invoice' && (this.state.on_account_price==="" || this.state.on_account_price===0)){
        document.getElementById("payment-type").value = 'general';
        document.getElementById("new-item-form").reset();
        this.setState({payment_type:'general',col_md:3});

        let show_pb_list = document.querySelectorAll('.pb-list');
        let prow = document.querySelectorAll(".payment-item-row");
        for(let i=0;i<show_pb_list.length;i++){
          show_pb_list[i].classList.remove('hide');
          for(let j=0;j<prow.length;j++){
            let tdid = prow[j].getAttribute('data_id');
            if(document.getElementById("item-box-"+tdid)){
              let ono = document.getElementById("item-box-"+tdid).value;
              if(document.getElementById("pending-bill-"+ono)){
                document.getElementById("pending-bill-"+ono).classList.add('hide');
              }
            }
          }
        }
        return false;
      }

      let pending_amounts = 0;
      for(let i=0;i<pb_list.length;i++){
        let idx = pb_list[i].getAttribute('index');
        if(document.getElementById("pending-amt-"+idx)){
          let pamt = document.getElementById("pending-amt-"+idx).value;
          pending_amounts = pending_amounts+parseFloat(pamt);
        }
      }

      if(pb_list.length>0){
        let is_i_exist = false;
        if(is_i_exist===false){
            pb_list[0].click();
        }
      }
      
      items.refrence_no = this.state.refrence_no;
      if(items.payment_type==='invoice'){
        localStorage.setItem('is_invoice','yes');
        let paid_amount = items.item_total;
        let pending_amount = items.pending_amount;

        if(this.state.on_account_price>=paid_amount){
          
          let on_account_price = this.state.on_account_price - paid_amount;
          this.setState({on_account_price:''});
          this.setState({on_account_price:on_account_price});
        }else if(this.state.on_account_price>=0 && paid_amount>this.state.on_account_price){

          pending_amount = items.item_total-this.state.on_account_price;
          paid_amount = this.state.on_account_price;
          items.item_total = paid_amount;
          items.pending_amount = pending_amount;
          this.setState({on_account_price:''});
          if(paid_amount===0 || paid_amount===""){
            document.getElementById("payment-type").value = 'general';
            document.getElementById("new-item-form").reset();
            this.setState({payment_type:'general',col_md:3});
            
              let show_pb_list = document.querySelectorAll('.pb-list');
              let prow = document.querySelectorAll(".payment-item-row");
              for(let i=0;i<show_pb_list.length;i++){
                show_pb_list[i].classList.remove('hide');
                for(let j=0;j<prow.length;j++){
                  let tdid = prow[j].getAttribute('data_id');
                  if(document.getElementById("item-box-"+tdid)){
                    let ono = document.getElementById("item-box-"+tdid).value;
                    if(document.getElementById("pending-bill-"+ono)){
                      document.getElementById("pending-bill-"+ono).classList.add('hide');
                    }
                  }
                }
              }
           
            return false;
          }
        }
      }


      if(pb_list.length===0){
        if(document.getElementById("payment-type")){
          document.getElementById("payment-type").value = 'general';
          document.getElementById("new-item-form").reset();
          this.setState({payment_type:'general',col_md:3});
         
          //document.getElementById("iitem-total").value = this.state.on_account_price;
        }
      }

      let birow = document.querySelectorAll(".payment-item-row");
      if(pb_list.length>0 && items.payment_type==='general'){
          return false;
      }
      let next_row = 0;
      let temp_row = -1;
      let iitot = 0;
      for(let i=0;i<birow.length;i++){
          let tdid = birow[i].getAttribute('data_id');
          let itot = document.getElementById("item-total-"+tdid).value;
          iitot = iitot+parseFloat(itot);
          if(parseInt(tdid)>=temp_row){
            temp_row = parseInt(tdid);
          }
      }
     /* if(parseFloat(this.state.on_account_price_fixed) > iitot){
          iitot = this.state.on_account_price_fixed - iitot;
          if(iitot===0){
            iitot = '';
          }
          document.getElementById("iitem-total").value = iitot;
      }*/

      temp_row =  temp_row+1;
      if(this.more_rows[temp_row]===undefined){
        this.more_rows[temp_row] = [];
      }
      let more_data = {
        count : temp_row,
        type :'more',
        key : temp_row,
        index : temp_row,
        billing_type : this.props.billing_type,
        invoice_info : items,
        items : []
      }
      
      this.more_rows[temp_row] = more_data;
      
      /* this.more_rows[temp_row] = <PaymentItemRow count={temp_row} type={"more"} key={temp_row}  index={temp_row} billing_type={this.props.billing_type} invoice_info={items} items={[]} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/> */
      
      this.setState({more_rows:this.more_rows});
      let rm = document.querySelectorAll(".item-remove");
      for(let i=0;i<rm.length;i++){
        rm[i].style.display = 'block';
      }
      //document.getElementById("new-item-form").reset();
      setTimeout(function(){
        //document.getElementById("payment-type").focus();
        _this.calculateTotal();
        
        
        let birow = document.querySelectorAll(".payment-item-row");
        let iitot = 0;
        for(let i=0;i<birow.length;i++){
            let tdid = birow[i].getAttribute('data_id');
            let itot = document.getElementById("item-total-"+tdid).value;
            iitot = iitot+parseFloat(itot);
        }

        if(parseFloat(_this.state.on_account_price_fixed) >= iitot){
            iitot = _this.state.on_account_price_fixed - iitot;
            document.getElementById("iitem-total").value = iitot;
            if(iitot>0){
                let ptype = document.getElementById("payment-type").value;
                if(ptype==='general'){
                  if(_this.props.payment_type!=='onaccount'){
                    document.getElementById("add-item-to-list").click();
                  }
                }
            }
        }else{
          if(_this.state.on_account_price>0 && localStorage.getItem('is_invoice')==='yes'){
            localStorage.removeItem('is_invoice');
            document.getElementById("iitem-total").value = _this.state.on_account_price;
            _this.setState({on_account_price:0});
            if(_this.props.payment_type!=='onaccount'){
              document.getElementById("add-item-to-list").click();
            }
            return false;
          }
        }
      },100);
      //this.setState({payment_type:'general',col_md:3});
      let item_name = items.item_name;
      if(document.getElementById("pending-bill-"+item_name)){
        document.getElementById("pending-bill-"+item_name).classList.add('hide');
      }
      if(document.getElementById("hanlde-change-force-state")){
        document.getElementById("hanlde-change-force-state").click();
      }
      this.handleResetPaymentBar();
    }

    addMoreItem(event){
      
      this.setState({is_pay_edit:''});
      if(document.getElementById("handleChangeHasItems")){
        document.getElementById("handleChangeHasItems").click();
      }

      localStorage.removeItem('item-row-edit');
      let total= (document.getElementById("iitem-total"))?document.getElementById("iitem-total").value:0;
      total = total.replace(/,/g , "");
      total = parseFloat(total);
      
      if(total>0){
      let type = event.currentTarget.id;
      let item_len = this.state.item_add_count;//document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.setState({item_add_count:key});
      let payment_type = (document.getElementById("payment-type"))?document.getElementById("payment-type").value:'';
     
      let item_id= (document.getElementById("iitem-name"))?document.getElementById("iitem-name").value:0;
      let item_name= (document.getElementById("iitem-box"))?document.getElementById("iitem-box").value:'';
      if(payment_type==='invoice' && (item_id==='' || item_id==='0')) {
          document.getElementById("iitem-box").focus();
          return false;
      }

      let target= (document.getElementById("iitem-target"))?document.getElementById("iitem-target").value:'';
      let action= (document.getElementById("iitem-action"))?document.getElementById("iitem-action").value:'';

      let chq_bank_name = (document.getElementById("iitem-bank-name"))?document.getElementById("iitem-bank-name").value:'';
      let chq_no = (document.getElementById("iitem-cheque-no"))?document.getElementById("iitem-cheque-no").value:'';
      let chq_date = (document.getElementById("iitem-cheque-date"))?document.getElementById("iitem-cheque-date").value:'';
      let chq_is_recon = (document.getElementById("iitem-cheque-is-recon"))?document.getElementById("iitem-cheque-is-recon").checked:false;
      
      if(chq_no!==""){
        this.setState({is_cheque_payment:true});
      }

      let refrence_no= (document.getElementById("iitem-refrence-no"))?document.getElementById("iitem-refrence-no").value:'';

      
      let iitem_total_old= (document.getElementById("iitem-total-old"))?document.getElementById("iitem-total-old").value:0;

      let item_pk_id= (document.getElementById("iitem-id"))?document.getElementById("iitem-id").value:0;


      let pm_element = document.getElementById("payment-mode");
      let payment_mode = pm_element.value;
      let payment_mode_txt = pm_element.options[pm_element.selectedIndex].text;
      let pending_amount = 0;
      if(payment_type==='invoice' || item_id==='balance_amount' || item_id==='advance_payment'  || item_id==='payment_due'){
          pending_amount = parseFloat(iitem_total_old) - parseFloat(total);
      }else{
          iitem_total_old = total;
      }
      let is_old_balance = false;

      if(item_id==='balance_amount'){
        is_old_balance = item_name;
        payment_type = 'invoice';
      }
      if(item_id!=='balance_amount' && item_id!=='advance_payment' && item_id!=='payment_due'){
        item_id = parseInt(item_id);
      }

      if(item_id==='advance_payment' || item_id==='payment_due'){
        is_old_balance = item_name;
      }
      
      if(this.props.payment_type==='billwise' && (payment_type==='general' || target==='payment')){
        is_old_balance = 'Adjusted Payment';
        target = 'payment';
      }

      let schema = document.getElementById("iitem-schema").value;
      
      let items = {
          schema:schema,
          payment_type:payment_type,
          item_pk_id:item_pk_id,
          target:target,
          action:action,
          item_name:item_name,
          item_id:item_id,
          old_amount:iitem_total_old,
          item_total:total,
          pending_amount:pending_amount,
          payment_mode:payment_mode,
          payment_mode_txt:payment_mode_txt,
          refrence_no:refrence_no,
          is_old_balance : is_old_balance,
          cheque_bank_name : chq_bank_name,
          cheque_no : chq_no,
          cheque_date : chq_date,
          is_reconcile : chq_is_recon,
      }
       let selected_item = this.state.edit_item_selected;
        let active_index = this.state.edit_item_active_index;
        if(selected_item!=='none'){
          this.setState({is_pay_edit:''});
          let more_data = {
            count : item_len,
            type :'more',
            key : selected_item,
            index : selected_item,
            billing_type : this.props.billing_type,
            invoice_info : items,
            items : []
          }
    
          this.more_rows[selected_item] = more_data;

          this.setState({more_rows:this.more_rows});
          let sli = document.querySelectorAll(".payment-item-row");
          for(let i=0;i<sli.length;i++){
             sli[i].classList.remove('item-edit-mode');
          }
          this.setState({edit_item_selected:'none'});
        }else{
          
          let is_pay_edit = document.querySelector('input[name="add-payemnt-option"]:checked');
          let on_list_click = localStorage.getItem('on_list_click');
          if(on_list_click==="true"){
            localStorage.removeItem('on_list_click');
          }
          if(is_pay_edit && (is_pay_edit.id==='autobillwise' || is_pay_edit.id==='onaccount')){
              this.addOnAccountItems(items);
              return false;
          }
          
          if(is_pay_edit.id==='autobillwise'){
            items.is_old_balance = 'Direct / Advance Payment';
          }

          let birow = document.querySelectorAll(".payment-item-row");
          let next_row = 0;
          let temp_row = -1;

          for(let i=0;i<birow.length;i++){
              let tdid = birow[i].getAttribute('data_id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(this.more_rows[temp_row]===undefined){
            this.more_rows[temp_row] = [];
          }

          
          let more_data = {
            count : temp_row,
            type :'more',
            key : temp_row,
            index : temp_row,
            billing_type : this.props.billing_type,
            invoice_info : items,
            items : []
          }

          this.more_rows[temp_row] = more_data;
          this.setState({more_rows:this.more_rows});
        }

        
        let rm = document.querySelectorAll(".item-remove");
        for(let i=0;i<rm.length;i++){
          rm[i].style.display = 'block';
        }
        

        document.getElementById("new-item-form").reset();
        setTimeout(function(){
          document.getElementById("payment-type").focus();
          _this.calculateTotal();
          /* if(document.getElementById("is_advance_pay")){
            document.getElementById("is_advance_pay").checked=is_advance_pay;
          } */
        },100);
        this.setState({payment_type:'general',col_md:3});
        if(document.getElementById("pending-bill-"+item_name)){
          document.getElementById("pending-bill-"+item_name).classList.add('hide');
        }
        if(document.getElementById("hanlde-change-force-state")){
          document.getElementById("hanlde-change-force-state").click();
        }
      }
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }
      this.handleResetPaymentBar();
      if(document.getElementById("handleCalulateAdjustedAmount")){
        //document.getElementById("handleCalulateAdjustedAmount").click();
      }
    }

    handleResetPaymentBar = () =>{
      if(document.getElementById("reset_mode_of_payment")){
        document.getElementById("reset_mode_of_payment").click();
        localStorage.removeItem("p_mode")
      }
      let qs = document.querySelectorAll('.pay-cheque-info');
      for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        element.classList.add('hide')
      }
      if(document.getElementById("handleDisableChequePayment")){
        document.getElementById("handleDisableChequePayment").click();
      }
      if(document.getElementById("DisableChequePayment")){
        document.getElementById("DisableChequePayment").click();
      }
    }

    handlePaymentTypeChange(e){
      let col_md = 3;
      if(e.currentTarget.value==='invoice'){
          col_md = 2;
      }
      this.setState({payment_type:e.currentTarget.value,col_md:col_md});
    }
    handleResetField(e){
      let selected_item = this.state.edit_item_selected;
      this.setState({payment_type:'general'});
      document.getElementById("new-item-form").reset();
      if(document.getElementById("item-row-"+selected_item)){    
        let sli = document.querySelectorAll(".payment-item-row");
        for(let i=0;i<sli.length;i++){
           sli[i].classList.remove('item-edit-mode');
        }  
        this.setState({edit_item_selected:'none'});
      }
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }

      let is_pay_edit = document.querySelector('input[name="add-payemnt-option"]:checked');
      if(is_pay_edit){
        let pid = is_pay_edit.id;
        if(pid==='billwise'){
          //this.setState({is_pay_edit:'hide'});
        }
      }
    }
    handleRemoveRow(e){
      let id=e.currentTarget.id;
      let item_id = document.getElementById("item-name-"+id).value;
      let item_name = document.getElementById("item-box-"+id).value;
      let item = {id:item_id,item_name:item_name};
      remove_items.push(item);
      document.getElementById("delete-row").value = JSON.stringify(remove_items);
      document.getElementById("item-row-"+id).remove();
      let row = document.querySelectorAll(".items-row");
      if(row.length==1){
          let rm = document.querySelectorAll(".item-remove");
          for(let i=0;i<rm.length;i++){
            rm[i].style.display = 'none';
          }
      }
      this.calculateTotal();
    }
    calculateTotal(){
      if(this.props.payment_type==='billwise'){
        let total_paid_amount = 0;
        let total_payable_amount = 0;
        let total_return_amount = 0;
        let paid_rows = document.getElementsByClassName("item-total");
        let tot_item_paid_amount = 0;
        for(let i=0;i<paid_rows.length;i++){
          let did = paid_rows[i].getAttribute('data-id');
          if(document.getElementById("item-target-"+did)){
            let item_target =  document.getElementById("item-target-"+did).value;
            let item_action =  document.getElementById("item-action-"+did).value;
            let item_lbl =  document.getElementById("is-item-old-balance-"+did).value;
            let val = paid_rows[i].value.replace(/,/g , "");
            if(val!==""){
              if(item_action==='payment' || item_target==='payment' || (item_target==='general' && item_lbl!=='Opening Balance') || item_target==='credit_note' || item_target==='debit_note'){
                if(item_target!=='sale_return' && item_target!=='sales_return' && item_target!=='credit_note' && item_target!=='debit_note' && item_target!=='purchase_return' ){
                  total_paid_amount= total_paid_amount + parseFloat(val);
                  tot_item_paid_amount= tot_item_paid_amount + parseFloat(val);
                }else{
                  total_return_amount= total_return_amount + parseFloat(val);
                }
              }else{
                total_payable_amount= total_payable_amount + parseFloat(val);
              }
            }
          }
        }

        let itemwise_total = total_return_amount; 
        if(tot_item_paid_amount>0){
          itemwise_total = tot_item_paid_amount;
        }

       /*  if(total_return_amount>0){
          total_return_amount = total_payable_amount - total_return_amount;
        } */
        total_payable_amount = this.handleGlobalDiscountCalculate(total_payable_amount);
        total_payable_amount = this.handleRoundOffCalculation(total_payable_amount);
        let tot_paid_tmp = total_paid_amount;
        if(total_return_amount>0){
          total_paid_amount = total_payable_amount - total_return_amount;
        }
        if(document.getElementById("bill-item-total")){
          document.getElementById("bill-item-total").innerHTML = utils.currency_format(total_payable_amount);
        }
         if(document.getElementById("pay-item-total")){
          document.getElementById("pay-item-total").innerHTML = utils.currency_format(itemwise_total);
        }
        let total_balance_amount = total_payable_amount - (tot_paid_tmp + total_return_amount);
        document.getElementById("total-grand").innerHTML = utils.currency_format(total_payable_amount);
        document.getElementById("total-balance").innerHTML = utils.currency_format(total_balance_amount);
        document.getElementById("total-amount").innerHTML = utils.currency_format(total_paid_amount);
        document.getElementById("total-amount-txt").value = total_paid_amount;
        if(total_balance_amount<0){
         total_balance_amount = 0;
        }
        document.getElementById("iitem-total").value = total_balance_amount;
      }else{
        let total_paid_amount = 0;
        let paid_rows = document.getElementsByClassName("item-total");
        for(let i=0;i<paid_rows.length;i++){
          let did = paid_rows[i].getAttribute('data-id');
          if(document.getElementById("item-target-"+did)){
            let item_target =  document.getElementById("item-target-"+did).value;
            let val = paid_rows[i].value.replace(/,/g , "");
            
            if(val!==""){
              if(item_target==='payment' || item_target==='credit_note' || item_target==='debit_note'){
                total_paid_amount= total_paid_amount-parseFloat(val);
              }else{
                total_paid_amount= total_paid_amount+parseFloat(val);
              }
            }
          }
        }
      
        let total_payable_amount = 0;
        let prows = document.getElementsByClassName("item-total-old");
        
        let remaining_amt = 0;
        for(let i=0;i<prows.length;i++){
          let pval = prows[i].value.replace(/,/g , "");
          if(document.getElementById("item-target-"+i)){
            let payment_type =  document.getElementById("item-target-"+i).value;
            if(pval!==""){
                  total_payable_amount= total_payable_amount+parseFloat(pval);
                
              }
          }
        }
        let grand_total = 0  
        if(document.getElementById("total-grand")){
            document.getElementById("total-grand").innerHTML = this.currency_format(total_payable_amount);

            grand_total = document.getElementById("total-grand").innerHTML.replace(/,/g , "");
            
            document.getElementById("iitem-target").value = '';
            total_paid_amount = this.handleGlobalDiscountCalculate(total_paid_amount);
            total_paid_amount = this.handleRoundOffCalculation(total_paid_amount);
        
            document.getElementById("total-amount").innerHTML =this.currency_format(total_paid_amount);
            document.getElementById("total-amount-txt").value = total_paid_amount;
            let total_balance_amount = total_payable_amount-total_paid_amount;
            document.getElementById("total-balance").innerHTML =this.currency_format(total_balance_amount);
        }
      }
    }

    handleRoundOffCalculation = (total_payable_amount) =>{
      let round_off = document.getElementById('round_off').value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
       
      let item_total = total_payable_amount;
      if(round_off!=="" && round_off>0){
          if(round_off_opt==='-'){
              item_total = item_total-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = item_total+parseFloat(round_off);
          }
      }
      return item_total;
    }

    handleGlobalDiscountCalculate = (total_payable_amount) =>{
      let global_discount = document.getElementById("global_discount").value;
      if(global_discount==""){
        global_discount = 0;
      }
       global_discount = parseFloat(global_discount);
       let item_total = total_payable_amount;
       let cont_disc = 0;

       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;

       let gdisc = global_discount;
       if(discount_opt==="rs"){
          item_total = parseFloat(item_total)-global_discount;
          document.getElementById("disc_on_payment").innerHTML = '';
       }else if(discount_opt==="per"){
          gdisc = (parseFloat(item_total)*global_discount)/100;
          gdisc = utils.floating_point(gdisc);
          item_total = parseFloat(item_total)-gdisc;
          document.getElementById("disc_on_payment").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
       }
       return item_total;
    }

    /* calculateTotal(){
      let total_paid_amount = 0;
      let paid_rows = document.getElementsByClassName("item-total");
      for(let i=0;i<paid_rows.length;i++){
        let did = paid_rows[i].getAttribute('data-id');
        if(document.getElementById("item-target-"+did)){
          let item_target =  document.getElementById("item-target-"+did).value;
          let val = paid_rows[i].value.replace(/,/g , "");
          
          if(val!==""){
            if(item_target==='payment' || item_target==='credit_note' || item_target==='debit_note'){
              total_paid_amount= total_paid_amount-parseFloat(val);
            }else{
              total_paid_amount= total_paid_amount+parseFloat(val);
            }
          }
        }
      }
      
      let total_payable_amount = 0;
      let prows = document.getElementsByClassName("item-total-old");
      
      let remaining_amt = 0;
      for(let i=0;i<prows.length;i++){
        let pval = prows[i].value.replace(/,/g , "");
        if(document.getElementById("item-target-"+i)){

           let payment_type =  document.getElementById("item-target-"+i).value;
           let is_remaining =  document.getElementById("is-item-old-balance-"+i).value;
           
           if(pval!==""){
               if(is_remaining==='Adjusted Payment'){
                 remaining_amt = remaining_amt+parseFloat(pval);
               }
              if(payment_type==='payment' || payment_type==='credit_note' || payment_type==='debit_note'){
                 total_payable_amount= total_payable_amount-parseFloat(pval);
              }else{
                total_payable_amount= total_payable_amount+parseFloat(pval);
              }
            }
        }
      }
      let grand_total = 0  
      if(document.getElementById("total-grand")){
      document.getElementById("total-grand").innerHTML = this.currency_format(total_payable_amount+remaining_amt);

       grand_total = document.getElementById("total-grand").innerHTML.replace(/,/g , "");
          
      let pending = parseFloat(grand_total)-total_paid_amount;
      let after_round = Math.round(total_paid_amount)-total_paid_amount;
      
      if(after_round>=0){
        after_round = Math.ceil(total_paid_amount)-total_paid_amount;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = total_paid_amount-Math.floor(total_paid_amount);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
      if(round_off!==""){
          if(round_off_opt==='-'){
              total_paid_amount = parseFloat(total_paid_amount)-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              total_paid_amount = parseFloat(total_paid_amount)+parseFloat(round_off);
          }
      }
      document.getElementById("iitem-target").value = '';
      if(this.props.payment_type==='billwise'){
        if(document.getElementById("payment-cr-dr-list")){
          this.setState({is_pay_edit:''});
          if(document.getElementById("iitem-total") && total_payable_amount>0){
            document.getElementById("iitem-target").value = 'payment';
            document.getElementById("iitem-total").value = total_payable_amount;
          }
        }
      }

      document.getElementById("total-amount").innerHTML =this.currency_format(total_paid_amount+remaining_amt);
      document.getElementById("total-amount-txt").value = total_paid_amount+remaining_amt;
      document.getElementById("total-balance").innerHTML =this.currency_format(total_payable_amount);
    }
    } */

    componentDidMount(){
       let more_row_len = document.getElementsByClassName("items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
      }
      if(this.props.invoice_info){
          this.more_rows = [];
          let iitems = this.props.invoice_info;
          let item_len = iitems.length;
          for(let i=0;i<item_len;i++){
            let items = iitems[i];
            
            if(items.cheque_no!==""){
              this.setState({is_cheque_payment:true});
              if(document.getElementById("handleEnableChequePayment")){
                document.getElementById("handleEnableChequePayment").click();
              }
            }
            let key = i+1;
            let more_data = {
              count : i,
              type :'more',
              key : i,
              index : i,
              billing_type : this.props.billing_type,
              invoice_info : items,
              items : []
            }
  
            this.more_rows.push(more_data);
          }
          this.setState({more_rows:this.more_rows});
        }
    }
    handleCalculation(e){
      let thisval = e.currentTarget.value;
      this.setState({on_account_price:thisval});
      this.setState({on_account_price_fixed:thisval});
      if(document.getElementById("calculateTotal")){
        //document.getElementById("calculateTotal").click();
      }
      /* if(thisval!=''){
        let total_amount = 0;
        let rows = document.getElementsByClassName("item-total");
        for(let i=0;i<rows.length;i++){
           let val = rows[i].value.replace(/,/g , "");
           if(val!==""){
              total_amount= total_amount+parseFloat(val);
           }
        }
        
      let after_round = Math.round(total_amount)-total_amount;
      
      if(after_round>=0){
        after_round = Math.ceil(total_amount)-total_amount;
        after_round = this.currency_format(after_round);
        if(document.getElementById("plus")){
          document.getElementById("plus").click();
        }
      }else{
        after_round = total_amount-Math.floor(total_amount);
        after_round = this.currency_format(after_round);
        if(document.getElementById("minus")){
          document.getElementById("minus").click();
        }
      }
      if(document.getElementById("round_off")){
        document.getElementById("round_off").value = after_round;
          let round_off = document.getElementById("round_off").value;
          let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

        
        if(round_off!==""){
            if(round_off_opt==='-'){
                total_amount = parseFloat(total_amount)-parseFloat(round_off);
            }else if(round_off_opt==='+'){
                total_amount = parseFloat(total_amount)+parseFloat(round_off);
            }
        }

          document.getElementById("total-amount").innerHTML =this.currency_format(total_amount);
          document.getElementById("total-amount-txt").value = total_amount;

          let grand_total = document.getElementById("total-grand").innerHTML.replace(/,/g , "");

          let pending = parseFloat(grand_total)-total_amount;
          document.getElementById("total-balance").innerHTML =this.currency_format(pending);
        }
      } */
    }
  
    render() {
      let total_bill_amount = 0;
      let total_payment_amount = 0;
      let has_payment = false;
        return (
          <>
          <label onClick={this.addMoreItem} id="add-item-to-list" className="hide"></label>
          <label onClick={this.forceChangeState} id="forceChangeState" className="hide"></label>
          <label onClick={this.resetEditSelectedState} id="resetEditSelectedState" className="hide"></label>
          <label onClick={this.resetListItem} id="resetListItem" className="hide"></label>
          <label onClick={this.openOnAccountPayment} id="openOnAccountPayment" className="hide"></label>
          <label onClick={this.hideOnAccountPayment} id="hideOnAccountPayment" className="hide"></label>
          <label onClick={this.calculateTotal} id="calculateTotal" className="hide"></label>
          
          <form id="new-item-form">
           
          {(this.more_rows.length>0) &&
          <div className="content-head" style={{padding:'0px'}}>
          <div className="table-responsive">
            {(this.props.payment_type==='billwise') &&
            <>
             <table className="table item-table border-bottom">
                <thead className="thead-light">
                {(this.props.payment_type==='billwise') &&
                  <tr>
                    <th colSpan={(this.state.is_cheque_payment===true)?9:6}  style={{background:'none'}}>BILLS</th>
                  </tr>
                }
                  <tr>
                    {(this.props.payment_type!=='billwise') &&
                      <th scope="col" className="hide-block">PAYMENT MODE</th>
                    }
                    <th scope="col">PAYMENT</th>
                    {/* {(this.state.is_cheque_payment===true) &&
                      <>
                      <th scope="col">BANK NAME.</th>
                      <th scope="col">CHEQUE NO.</th>
                      <th scope="col">CHEQUE DATE.</th>
                      </>
                    } */}
                    <th scope="col">REFERENCE NO.</th>
                    <th scope="col">BILL AMOUNT</th>
                    <th scope="col">TOTAL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody id="items-body">
                   
                    {this.state.more_rows.map((value,index)=>{
                      if(value.invoice_info.target!=='payment' && value.invoice_info.action!=='payment' && value.invoice_info.target!=='credit_note' && value.invoice_info.target!=='debit_note' && (value.invoice_info.target!=='general' || value.invoice_info.is_old_balance==='Opening Balance')){
                        total_bill_amount = total_bill_amount + parseFloat(value.invoice_info.item_total);
                      }
                      if(value.invoice_info.target==='payment' || value.invoice_info.target==='credit_note' || value.invoice_info.action==='payment' || value.invoice_info.target==='debit_note' || (value.invoice_info.target==='general' && value.invoice_info.is_old_balance!=='Opening Balance')){
                        has_payment = true;
                      }
                      return(
                        <>
                        {(value.invoice_info.target!=='payment' && value.invoice_info.action!=='payment' && value.invoice_info.target!=='credit_note' && value.invoice_info.target!=='debit_note' && (value.invoice_info.target!=='general' || value.invoice_info.is_old_balance==='Opening Balance')) &&
                          <PaymentItemRow is_cheque_payment={false} count={value.count} type={value.type} key={value.key}  index={value.index} billing_type={value.billing_type} invoice_info={value.invoice_info} items={value.items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)} payment_type={this.props.payment_type}/>
                        }
                        </>
                      )
                    })}

                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={(this.props.payment_type!=='billwise')?4:3} className="text-right" style={{background:'none'}}></th>
                    <th className="text-left" style={{background:'none',fontSize:'20px'}}>₹<span id="bill-item-total">{utils.currency_format(total_bill_amount)}</span></th>
                    <th className="text-right" style={{background:'none'}}></th>
                  </tr>
                </tfoot>
              </table>
              {(has_payment===true) &&
                <table className="table item-table border-bottom" id="payment-cr-dr-list">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={(this.state.is_cheque_payment===true)?9:6} style={{background:'none'}}>PAYMENTS/CR-DR NOTE</th>
                    </tr>
                    <tr>
                      <th scope="col">PAYMENT MODE</th>
                      <th scope="col">PAYMENT</th>
                      {(this.state.is_cheque_payment===true) &&
                        <>
                        <th scope="col">BANK NAME.</th>
                        <th scope="col">CHEQUE NO.</th>
                        <th scope="col">CHEQUE DATE.</th>
                        </>
                      }
                      <th scope="col">REFERENCE NO.</th>
                      <th scope="col">BILL AMOUNT</th>
                      <th scope="col">TOTAL</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody id="items-body">
                      {/* {this.more_rows} */}

                      {this.state.more_rows.map((value,index)=>{
                        if(value.invoice_info.target==='payment' || value.invoice_info.action==='payment' || value.invoice_info.target==='credit_note' || value.invoice_info.target==='debit_note' || (value.invoice_info.target==='general' && value.invoice_info.is_old_balance!=='Opening Balance')){
                          total_payment_amount = total_payment_amount + parseFloat(value.invoice_info.item_total);
                        }
                        return(
                          <>
                          {(value.invoice_info.target==='payment' || value.invoice_info.action==='payment' || value.invoice_info.target==='credit_note' || value.invoice_info.target==='debit_note' || (value.invoice_info.target==='general' && value.invoice_info.is_old_balance!=='Opening Balance')) &&
                            <PaymentItemRow is_cheque_payment={this.state.is_cheque_payment} count={value.count} type={value.type} key={value.key}  index={value.index} billing_type={value.billing_type} invoice_info={value.invoice_info} items={value.items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)} payment_type={this.props.payment_type}/>
                          }
                          </>
                        )
                      })}

                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={(this.state.is_cheque_payment===true)?7:4} className="text-right" style={{background:'none'}}></th>
                      <th className="text-left" style={{background:'none',fontSize:'20px'}}>₹<span id="pay-item-total">{utils.currency_format(total_payment_amount)}</span></th>
                      <th className="text-right" style={{background:'none'}}></th>
                    </tr>
                  </tfoot>
                </table>
              }
            </>
            }
              {(this.props.payment_type!=='billwise') &&
              <table className="table item-table border-bottom" id="payment-cr-dr-list">
              <thead className="thead-light">
                <tr>
                  <th colSpan={(this.state.is_cheque_payment===true)?9:6} style={{background:'none'}}>PAYMENTS</th>
                </tr>
                <tr>
                  <th scope="col">PAYMENT MODE</th>
                  <th scope="col">PAYMENT</th>
                  {(this.state.is_cheque_payment===true) &&
                    <>
                    <th scope="col">BANK NAME.</th>
                    <th scope="col">CHEQUE NO.</th>
                    <th scope="col">CHEQUE DATE.</th>
                    </>
                  }
                  <th scope="col">REFERENCE NO.</th>
                  <th scope="col">BILL AMOUNT</th>
                  <th scope="col">TOTAL</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody id="items-body">
                  {/* {this.more_rows} */}

                  {this.state.more_rows.map((value,index)=>{
                    
                      total_payment_amount = total_payment_amount + parseFloat(value.invoice_info.item_total);
                    
                    return(
                      <>
                     
                        <PaymentItemRow is_cheque_payment={this.state.is_cheque_payment} count={value.count} type={value.type} key={value.key}  index={value.index} billing_type={value.billing_type} invoice_info={value.invoice_info} items={value.items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)} payment_type={this.props.payment_type}/>
                      
                      </>
                    )
                  })}

              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={(this.state.is_cheque_payment===true)?7:4} className="text-right" style={{background:'none'}}></th>
                  <th className="text-left" style={{background:'none',fontSize:'20px'}}>₹<span id="pay-item-total">{utils.currency_format(total_payment_amount)}</span></th>
                  <th className="text-right" style={{background:'none'}}></th>
                </tr>
              </tfoot>
            </table>
              }
             </div>
             </div>
           }
           
              <div className={(utils.isMobile()!==true)?"main-content border-bottom  sticky-add-item-panel "+this.state.is_pay_edit:"main-content border-bottom "+this.state.is_pay_edit}>
                <div className="inner-content" style={{padding:'15px 45px'}}>
                  <div className="row">
                    <div className={"hide mrbtm-10"} style={{padding:'5px'}}>
                      <span className="fe-title">PAYMENT TYPE</span>
                      <select className="form-control" tabindex='2' id="payment-type" target="payment-type" onChange={this.handlePaymentTypeChange}>
                        <option value="general">General</option>
                        <option value="invoice">Invoice</option>
                      </select>
                    </div>
                    {(this.state.payment_type==='invoice') &&
                    <div className="mrbtm-10" style={{padding:'5px'}}>
                      <span className="fe-title">INVOICE NO.</span>
                      <Autocomplete
                      options = {[]}
                    
                      data_target="payment"
                      title="Start typing invoice ID..."  
                      id={"iitem-name"}
                      className="auto-comp-opt"
                      data_id={this.props.index}
                      active_tab={this.props.billing_type}
                      input_id="iitem-box"
                      item_info={this.props.invoice_info}
                      tabindex='3'
                      target="iitem-box"
                    />
                    </div>
                  }
                    <div className="col-md-2 mob-6" style={{padding:'5px'}}>
                      <span className="fe-title">PAYMENT MODE</span>
                      <PaymentModes show="dropdown" name="payment_mode" data_id='0' id="payment-mode" target="payments" tabindex='0'/>
                    </div>
                    <div className="col-md-2 mob-6" style={{padding:'5px'}}>
                      <span className="fe-title">REFERENCE NO</span>
                      <input type="text" className="form-control iitem-refrence-no" placeholder="" id={"iitem-refrence-no"} data-id={this.props.index}  data-target="iitem-refrence-no" tabindex='4' target="refrence-no" onChange={this.handleChangeRefNo} />
                    </div>
                    <div className="col-md-2 mob-6 pay-cheque-info hide" style={{padding:'5px'}}>
                      <span className="fe-title">BANK NAME</span>
                      <input type="text" className="form-control iitem-bank-name" placeholder="" id={"iitem-bank-name"} data-id={this.props.index}  data-target="iitem-bank-name"/>
                    </div>
                    <div className="col-md-2 mob-6 pay-cheque-info hide" style={{padding:'5px'}}>
                      <span className="fe-title">CHEQUE NO</span>
                      <input type="text" className="form-control iitem-cheque-no" placeholder="" id={"iitem-cheque-no"} data-id={this.props.index}  data-target="iitem-cheque-no"/>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input iitem-cheque-is-recon" type="checkbox" data-id={this.props.index} id={'iitem-cheque-is-recon'}/>
                        <label className="form-check-label" htmlFor={'iitem-cheque-is-recon'} style={{color:'#fff'}}>
                          Is Reconcile?
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 mob-6 pay-cheque-info hide" style={{padding:'5px'}}>
                      <span className="fe-title">CHEQUE DATE</span>
                    {/*  <input type="text" className="form-control iitem-cheque-date" placeholder="dd/mm/yyyy" id={"iitem-cheque-date"} data-id={this.props.index}  data-target="iitem-cheque-date"/> */}
                    
                      <DatePicker className="form-control  iitem-cheque-date"  dateFormat="dd/MM/yyyy" id={"iitem-cheque-date"} data-id={this.props.index}  data-target="iitem-cheque-date" selected={this.state.chequeDate} onChange={this.chequeDate}/>
                      
                    
                    </div>

                    <div className={"mrbtm-10 col-md-1 mob-6"} style={{padding:'5px'}}>
                    <span className="fe-title">TOTAL</span>
                    <input type="text" onChange={this.handleCalculation} className="form-control iitem-total" placeholder="" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total" tabindex='5' target="iitem-total"/>
                    <span id="remaining-error" style={{color:'#fff'}}></span>
                    <input type="text" className="hide form-control iitem-total-old" placeholder="" id={"iitem-total-old"} data-id={this.props.index}  data-target="iitem-total-old"/>
                  

                    <input type="text" className="hide form-control iitem-target" placeholder="" id={"iitem-target"} data-id={this.props.index}  data-target="iitem-target" defaultValue="general"/>
                    <input type="text" className="hide form-control iitem-action" placeholder="" id={"iitem-action"} data-id={this.props.index}  data-target="iitem-action" defaultValue="general"/>

                    <input type="text" className="hide form-control iitem-schema" placeholder="" id={"iitem-schema"} data-id={this.props.index}  data-target="iitem-schema" defaultValue=""/>

                    <input type="text" className="hide form-control" placeholder="" id={"iitem-id"} data-id={this.props.index}  data-target="iitem-id"/>

                    </div>
                    <div className={"col-md-2 mob-6"} style={{marginTop:'22px',padding:'5px'}}>
                        <button type="button" className="btn btn-success" onClick={this.addMoreItem} style={{marginRight:'10px'}}>
                          <FontAwesomeIcon icon={faCheckCircle} style={{color:'white'}}/>
                        </button>
                        <button type="button" className="btn btn-danger" onClick={this.handleResetField}>
                          <FontAwesomeIcon icon={faTimes} style={{color:'white',margin:'0px'}}/>
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            
          </form>
          </>
        );
    }
}