import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faSpinner} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import ContactsDropdown from "./contacts-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccountTypes from "./account-types";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let PaymentMode = [];
let _this;
let db;
export default class CreateCashEntry extends Component {
    constructor() {
      super();
      this.state = {
        entry_symbol : '+',
        transactionDate:  new Date(),
      };
      _this = this;
      this.is_access = check_access.is_access.bind(this);
    }
    handleChangeTransactionDate = date => {
        this.setState({
          transactionDate: date
        });
      };
    
    componentDidMount = () =>{
        localStorage.removeItem('is_bank_account');
    }

    handSaveCashEntry = (e) =>{
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let desc = document.getElementById("cash-desc").value;
        let note = document.getElementById("cash-note").value;
        let amount = document.getElementById("cash-amount").value;
        let entry_type = document.getElementById("entry-type").value;
        let from_account = document.getElementById("from-account").value;
        if(from_account===""){
            document.getElementById("from-account").focus();
            return false;
        }
        let account_id = this.props.account_id;
        let account_name = this.props.account_name;
        let is_bank_account = (localStorage.getItem("is_bank_account"))?localStorage.getItem("is_bank_account"):'false';
        let symboll = this.state.entry_symbol;
        let meta_data = {
            desc : desc,
            note : note,
            amount : amount,
            symboll : symboll
        }
        let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                data : meta_data,
                account_id : account_id,
                account_name : account_name,
                entry_type : entry_type,
                from_account : from_account,
                is_bank_account : is_bank_account,
                transactionDate : this.state.transactionDate,
             }
          let api = app_api+'/create_cash_entry';
          axios.post(api, body)
            .then(function (response) {
                window.location.reload();
                /* if(is_bank_account==='true'){
                    window.location.href = '/view-accounts/'+account_id;
                }else{
                   window.location.href = '/view-contact/'+account_id;
                } */
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
    }
    handleCloseCashEntryModal = () =>{
        this.props.handleCloseCashEntryModal();
    }
    handleChangeSymOpt = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({entry_symbol:thisval})
    }
    render() {
        return (
            <div className="modal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create Entry</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Entry Type <span className="red">*</span></label>
                                <select className="form-control" id="entry-type">
                                    <option value="1">Cash</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label>From Account <span className="red">*</span></label>
                                <AccountTypes show="dropdown" id="from-account" target='cash_entry' exlude={this.props.account_id}/>
                            </div>
                            <div className="col-md-4">
                                <label>Date</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text no-border-right">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    <DatePicker className="form-control  no-border-left" selected={this.state.transactionDate} onChange={this.handleChangeTransactionDate} dateFormat="dd/MM/yyyy"/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="cash-desc">Description</label>
                                <input type="text" className="form-control" id="cash-desc" tabIndex={1}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="cash-note">Note</label>
                                <input type="text" className="form-control" id="cash-note" tabIndex={2}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="cash-amount">Amount</label>
                                <input type="text" className="form-control" id="cash-amount"  tabIndex={3}/>
                            </div>
                            <div className="col-md-3">
                                <div className="form-check" style={{marginTop:'25px'}}>
                                    <input className="form-check-input" type="radio" name="cash-opt" id="cash-opt1" checked value='+' onChange={this.handleChangeSymOpt}  tabIndex={4}/>
                                    <label className="form-check-label" for="cash-opt1">
                                        Credit ( + )
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="cash-opt" id="cash-opt2" value='-' onChange={this.handleChangeSymOpt}  tabIndex={5}/>
                                    <label className="form-check-label" for="cash-opt2">
                                        Debit ( - )
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseCashEntryModal} id="close-cash-entry">Close</button>
                    <button type="button" data-id="create-cash-entry" className="btn btn-success pull-right" id="create-cash-entry" onClick={this.handSaveCashEntry}>Save Entry</button>
                    <button type="button" id="create-cash-entry-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}