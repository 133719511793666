import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');

export default class TagFields extends Component {
    constructor(props) {
        super(props)
        this.state = {
          tags: [],
          tags_id: [],
          default : '',
          default_id : '',
          active_option : 0,
          options: [],
          options_temp: [],
          tag_error : '',
          default_image : 'https://app.business-booster.net/app/uploads/no-image.png',
          is_visible : true,
        }
        this.variantList=[];
        this.handleTag = this.handleTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.handleTextBoxFocus = this.handleTextBoxFocus.bind(this);
    }
    
    removeTag(e){
      let id = e.currentTarget.id;
      let tags = this.state.tags;
      let tags_id = this.state.tags_id;
      
      tags.splice(id, 1);
      this.setState({tags:tags});

      tags_id.splice(id, 1);
      this.setState({tags_id:tags_id});

      let this_ = this;
      setTimeout(function(){
        this_.handleSearchTags('','remove');
      },50);
    }

    handleCheckCategoryForMobile = (e) =>{
        let data_id = e.currentTarget.getAttribute('data-id');
        let thisvalue = e.currentTarget.value;
        if(this.isMobile() && thisvalue!==''){
            let target = this.props.target;
            if(document.querySelector(".option-active[data-target="+target+"]")){
                document.querySelector(".option-active[data-target="+target+"]").click();

                if(target==='category'){
                    this.setState({is_visible:false});
                }

                return false;
            }
            let tags    = this.state.tags;
            let tags_id    = this.state.tags_id;
            let t_arr   = thisvalue;
            if(tags.indexOf(thisvalue)<0){
                if(this.props.data_id==='iitem-category' || this.props.data_id==='product-category'){
                    var element = document.getElementById('add_category');
                    element.classList.remove("fade");
                    element.style.display="block";
                    document.getElementById("add-category-title").innerHTML = 'ADD CATEGORY';
                    document.getElementById("update_category_id").value = "";
                    document.getElementById("save-category-target-type").value = "product";
                    document.getElementById("category").value = t_arr;
                    setTimeout(() => {
                        document.getElementById("save-cat-btn").focus();
                    }, 500);
                    document.getElementById("parent_category").value = 0;
                    return false;
                }
                tags.push(t_arr);
                this.setState({tags:tags});
                tags_id.push(data_id);
                this.setState({tags_id:tags_id});
                document.getElementById("tag-box-"+data_id).focus();
                e.currentTarget.value = '';
                e.currentTarget.placeholder = '';
                this.setState({tag_error:''});
            }else{
                let error = thisvalue+" already exists";
                this.setState({tag_error:error});
            }
        
        }

        if(this.isMobile()===false && thisvalue!==''){
            let target = this.props.target;
            if(document.querySelector(".option-active[data-target="+target+"]")){
                return false;
            }
            let tags    = this.state.tags;
            let tags_id    = this.state.tags_id;
            let t_arr   = thisvalue;
            if(tags.indexOf(thisvalue)<0){
                if(this.props.data_id==='iitem-category' || this.props.data_id==='product-category'){
                    var element = document.getElementById('add_category');
                    element.classList.remove("fade");
                    element.style.display="block";
                    document.getElementById("add-category-title").innerHTML = 'ADD CATEGORY';
                    document.getElementById("update_category_id").value = "";
                    document.getElementById("save-category-target-type").value = "product";
                    document.getElementById("category").value = t_arr;
                    setTimeout(() => {
                        document.getElementById("save-cat-btn").focus();
                    }, 500);
                    document.getElementById("parent_category").value = 0;
                    return false;
                }
                tags.push(t_arr);
                this.setState({tags:tags});
                tags_id.push(data_id);
                this.setState({tags_id:tags_id});
                document.getElementById("tag-box-"+data_id).focus();
                e.currentTarget.value = '';
                e.currentTarget.placeholder = '';
                this.setState({tag_error:''});
            }
        }
    }


    handleTag(e){
        
      let data_id = e.currentTarget.getAttribute('data-id');
      let value = e.currentTarget.value;
      if(e.keyCode===38){
          let active_option = this.state.active_option;
          if(active_option>0){
              active_option--;
              this.setState({active_option:active_option});
              if(document.getElementById('tags-ul-list')){
                document.getElementById('tags-ul-list').scrollTop -= 46.6;
              }
          }
         
          if(this.state.is_visible===false || this.state.options_temp.length===0){
            this.handleSearchTags('','search');
            this.setState({is_visible:true});
          }

      }else if(e.keyCode===40){
          let active_option = this.state.active_option;
          if(this.state.options_temp.length-1>active_option){
              active_option++;
              this.setState({active_option:active_option});
              if(document.getElementById('tags-ul-list')){
                document.getElementById('tags-ul-list').scrollTop += 46.6;
                }
          }
          if(this.state.is_visible===false || this.state.options_temp.length===0){
            this.handleSearchTags('','search');
            this.setState({is_visible:true});
          }
      }
      if(e.keyCode===8){
       
        if(value===""){
          let tags = this.state.tags;
          let tags_id = this.state.tags_id;
          let l = tags.length-1;
          if(l>=0){
            tags.splice(l, 1);
            this.setState({tags:tags});

            tags_id.splice(l, 1);
            this.setState({tags_id:tags_id});
            let this_ = this;
            setTimeout(function(){
                this_.handleSearchTags('','remove');
            },50);
          }
        }
      }else{
        let thisvalue = e.currentTarget.value;
        if(e.keyCode===13){
            let target = this.props.target;
            
            if(document.querySelector(".option-active[data-target="+target+"]")){
                document.querySelector(".option-active[data-target="+target+"]").click();

                if(target==='category'){
                    this.setState({is_visible:false});
                }

                return false;
            }
        }
        if((e.keyCode===13 || e.keyCode===9) && thisvalue!==''){
            let tags    = this.state.tags;
            let tags_id    = this.state.tags_id;
            let t_arr   = thisvalue;
            if(tags.indexOf(thisvalue)<0){
                if(this.props.data_id==='iitem-category' || this.props.data_id==='product-category'){
                    var element = document.getElementById('add_category');
                    element.classList.remove("fade");
                    element.style.display="block";
                    document.getElementById("add-category-title").innerHTML = 'ADD CATEGORY';
                    document.getElementById("update_category_id").value = "";
                    document.getElementById("save-category-target-type").value = "product";
                    document.getElementById("category").value = t_arr;
                    setTimeout(() => {
                        document.getElementById("save-cat-btn").focus();
                    }, 500);
                    document.getElementById("parent_category").value = 0;
                    return false;
                }
                tags.push(t_arr);
                this.setState({tags:tags});
                tags_id.push(data_id);
                this.setState({tags_id:tags_id});
                document.getElementById("tag-box-"+data_id).focus();
                e.currentTarget.value = '';
                e.currentTarget.placeholder = '';
                this.setState({tag_error:''});
            }else{
              let error = thisvalue+" already exists";
              this.setState({tag_error:error});
            }
        }else if(e.keyCode===13){
        }
      }
    }
  
   componentWillReceiveProps(nextProps){
       if(nextProps.default){
           this.setState({default:nextProps.default})
           const tags_default = nextProps.default.split(",");
           let tags = [];
           for(let i=0;i<tags_default.length;i++){
               let tag_name = tags_default[i];
               tags.push(tag_name);
               this.setState({tags:tags});
           }
       }
       if(nextProps.default_id){
            let default_id = nextProps.default_id;
            default_id = default_id.toString();
           this.setState({default_id:default_id})
           const tags_id_default = default_id.split(",");
           let tags_id = [];
           for(let i=0;i<tags_id_default.length;i++){
               let tag_name = tags_id_default[i];
               tags_id.push(tag_name);
               this.setState({tags_id:tags_id});
           }
       }
   }
 
    handleKeyShortcut = (e) =>{
        e = e || window.event;
        var target = e.target || e.srcElement;
        if(e.which===27 && target.id==='tag-box-category'){
            this.setState({is_visible:false});
            if(document.getElementById("iitem-alias-barcode")){
                document.getElementById("iitem-alias-barcode").focus();
            }
        }
    }

    componentDidMount(){
    document.addEventListener("keydown", this.handleKeyShortcut);
      setTimeout(function(){
        let rm_items = document.querySelectorAll(".v-opt-row");
        let rm_cls = document.querySelectorAll(".remove-var-opt");
        if(rm_items.length>1){
          for(let i=0;i<rm_cls.length;i++){
              rm_cls[i].classList.remove('hide');
          }
        }
      },100);

      if(this.props.target==='groups'){
        let body = {};
            body = {
                    store_name : user_info.store_name,
                    branch_id : user_info.login_id,
                    user_id : user_info.user_id,
                    uuid : utils.uuid(),
                    role_id : user_info.access_level,
                    token : user_info.token,
                    _schema : user_info._schema,
                    target : 'tags',
                }
            let api = app_api+'/get_contact_groups';
            axios.post(api, body)
                .then(function (response) {
                let cursor = response.data;
                this.setState({ 'options': cursor });
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                        
                    }
                }
            })
        }

        if(this.props.target==='category'){
            this.handleLoadCategories();
        }
    }

    handleLoadCategories = () =>{
        let body = {};
        body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
                target : 'tags',
            }
        let api = app_api+'/get_categories';
        axios.post(api, body)
            .then(function (response) {
            let cursor = response.data;
            this.setState({ 'options': cursor });
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }

     handleTextBoxFocus(e){
      let did = e.currentTarget.getAttribute("data-id");
      let tval = document.getElementById("tag-box-"+did).value;
      document.getElementById("tag-box-"+did).focus();
      if(tval===""){
        let options = this.state.options;
        if(this.props.data_id!=='iitem-category' && this.props.data_id!=='product-category'){
            this.handleSearchTags('','focus');
        }
        //this.setState({options_temp:options});
      }
    }

    handleSearchTags = (userInput, type) =>{
        this.setState({active_option:0});
        this.setState({is_visible:true});
        let _this = this;
        let options = this.state.options;
        
        let lcount = 0;
        let filteredOptions = [];
        options.map( (value) => {
            let value2 = value.value;
            if(this.state.tags.indexOf(value2)<0){
                lcount++;
                if(type==='search'){
                    if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                        filteredOptions.push(value);
                    }
                }else{
                    filteredOptions.push(value);
                }
            }
        });
        _this.setState({
            options_temp : filteredOptions,
        });
    }

    onTagChange = (e) =>{
        let userInput = e.currentTarget.value;
        this.handleSearchTags(userInput,'search');
    }

    onTagFocus = (e) =>{
        let userInput = e.currentTarget.value;
        if(this.props.data_id!=='iitem-category' && this.props.data_id!=='product-category'){
            this.handleSearchTags(userInput,'search');
            this.setState({is_visible:true});
        }
    }
    handleTagListClick = (e) =>{
        let target = e.currentTarget.getAttribute("data-target");
        let data_id = e.currentTarget.getAttribute("data-id");

        let tval = e.currentTarget.innerHTML;
        let tags    = this.state.tags;
        tags.push(tval);
        this.setState({tags:tags});

        let tags_id = this.state.tags_id;
        tags_id.push(data_id);
        this.setState({tags_id:tags_id});
        if(document.getElementById("tag-box-"+target)){
            document.getElementById("tag-box-"+target).value = "";
        }
        this.handleSearchTags('','click');
    }

    handleEmptyTagList = e =>{
        let _this = this;
        setTimeout(() => {
            _this.setState({tags:[]});
            _this.setState({tags_id:[]});
        }, 100);
    }

    handleResetTagList = e =>{

        const tags_default = document.getElementById("tags-value-category").value;
        let t_default = tags_default.split(",");
        let tags = [];
        for(let i=0;i<t_default.length;i++){
            let tag_name = t_default[i];
            tags.push(tag_name);
            this.setState({tags:tags});
        }

        const tags_id_default = document.getElementById("tags-id-category").value;
        let t_id_default = tags_id_default.split(",");
        let tags_id = [];
        for(let i=0;i<t_id_default.length;i++){
            let tag_name = t_id_default[i];
            tags_id.push(tag_name);
            this.setState({tags_id:tags_id});
        }
    }
    handleCloseTagList = (e) =>{
        this.setState({is_visible:false});
    }
    render() {
  
    return <div className="row variation-options v-opt-row" id={'tags-'+this.props.target}>
            
              <div className="col-md-12">
                  {(this.state.options_temp.length>0 && this.state.is_visible===true)&&
                    <div className='isearch-overlay' onClick={this.handleCloseTagList}></div>
                  }
                  <span id="handleResetTagList" onClick={this.handleResetTagList}></span>
                  <span id="handleEmptyTagList" onClick={this.handleEmptyTagList}></span>
                  <span id="handleLoadCategories" onClick={this.handleLoadCategories}></span>
                  <div className="set-tax-box" onClick={this.handleTextBoxFocus} data-id={this.props.target}>
                    {(this.state.tags.length > 0) ? this.state.tags.map( (value, index) => {
                    return (
                        <>
                            <span key={"t-"+index} className="v-tag" id={"v-tag-"+index}>
                            {value}
                            <span key={"tc-"+index} className="t-close" id={index} onClick={this.removeTag}><FontAwesomeIcon icon={faTimes} /></span>
                            </span>
                        </>
                    )
                    }):''}
                    <input type="text" list={"tag-data-"+this.props.target} id={"tag-box-"+this.props.target} className={"form-control tag-box "+this.props.className} onBlur={this.handleCheckCategoryForMobile} onKeyDown={this.handleTag} data-id={this.props.target} onChange={this.onTagChange} onFocus={this.onTagFocus} autoComplete="off" name={this.props.name} tabindex={this.props.tabindex}/>
                    {(this.state.options_temp.length>0 && this.state.is_visible===true)&&
                        
                        <ul className="auto-comp-opt list-group tags-ul-list" id="tags-ul-list">
                            {this.state.options_temp.map((options,index) => (
                                <>
                                    <li key={index} className={(this.state.active_option===index)?"list-group-item option-active":"list-group-item"} data-id={options.id} onClick={this.handleTagListClick} data-target={this.props.target}>{options.value}</li>
                                </>
                            ))}
                        </ul>
                    }
                    <input type="hidden" className="tags-hidden" data-id={this.props.data_id} id={"tags-value-"+this.props.target} defaultValue={this.state.tags}/>

                    <input type="hidden" className="tags-id-hidden" data-id={this.props.data_id} id={"tags-id-"+this.props.target} defaultValue={this.state.tags_id}/>
                </div>
                <div><span className="error">{this.state.tag_error}</span></div>
              </div>
          </div>;
    }
  }
  