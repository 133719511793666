import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
export default class Breadcrumbs extends Component {
    constructor() {
        super();
        this.state = {
         
        };
       
    }
    componentDidMount(){
      
    }
   
    render() {
        return (
            <div className="mod-title"><a href={this.props.link}><span>{this.props.title}</span></a> / <b id="breadcrumbs_subtitle">{this.props.sub_title}</b></div>
        );
    }
}