import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import check_access from './access-level.js';
import ServiceLoader from "./service-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import ContactSelect from "./contact-select-on-type";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { QRCodeSVG } from "qrcode.react";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
export default class EWayBill extends Component {
    constructor(props) {
        super();
        this.state = {
            is_way_bill : (props.is_way_bill!==undefined) ? props.is_way_bill : false,
            eway_data : [],
            irn_data : [],
            transDocDate:  '',
            einv_error_list : []
        };
        this.isMobile = utils.isMobile.bind(this);
    }
    transDocDate = date => {
        this.setState({
          transDocDate: date
        });
    };

    handleGenerateEwayBill = () =>{
        document.getElementById('generate-e-waybill').classList.add("hide");
        document.getElementById('generate-e-waybill-btn-spinner').classList.remove("hide");

        let qs = document.querySelectorAll('.eway-field');
        let eway_data = {};
        for (let index = 0; index < qs.length; index++) {
            let key = qs[index].id;
            let value = qs[index].value;
            if(key==='transporter-name'){
                let pm_element = document.getElementById('transporter-name');
                value = pm_element.options[pm_element.selectedIndex].text;
            }
            eway_data[key] =  value;
        }
        
        eway_data['transDocDate'] = this.state.transDocDate;
        let body = {  
            invoice_id : this.props.way_bill_data.data.id,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            store_state : user_info.state,
            target : this.props.way_bill_data.type,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            eway_data : eway_data
        }
        let api = app_api+'/generate_e_waybill';
        axios.post(api, body)
        .then(function (response) {
            document.getElementById('generate-e-waybill').classList.remove("hide");
            document.getElementById('generate-e-waybill-btn-spinner').classList.add("hide");
            let resp = response.data.response;
            if(resp.status==1){
                if(document.getElementById('drawer-close')){
                    document.getElementById('drawer-close').click();
                }
                window.location.reload();
            }else{
                this.setState({einv_error_list:resp.error});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }

    componentDidMount(){
        let resp = this.props.way_bill_data;
        if(resp.data.eway_data.request){
            this.setState({is_way_bill:false});
            this.setState({eway_data:resp.data.eway_data});
            this.setState({irn_data:resp.data.irn_data});
        }



    }
    handePrintEwayBill = (e) =>{
        var isMobile = this.isMobile();
        if(document.getElementById("waybill-main-container")){
            var divContents = document.getElementById("waybill-main-container").innerHTML; 

            let page_size = 'A4';

            try{
                var a = window.open('', '', 'height=1000,location=no,left=250px'); 
                a.document.open(); 
                a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
                a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

                a.document.write('<style type="text/css">@page{size:'+page_size+'}.table{font-size:12px;width:100%;margin-bottom:1rem;color:#212529}.div-display,.no-print-block{display:none}.table td,.table th{padding:5px;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.row{display:flex;flex-wrap:wrap;margin-right:-15px;margin-left:-15px}.col-md-12{flex:0 0 100%;max-width:100%}.powered-by{position:fixed;bottom:20}.page-break{page-break-after:always}.border-none{border:none}.ad-margin{margin-top:-9px}.signature{font-family:"Dancing Script", cursive;text-transform:capitalize;font-weight:bold;font-size:15px} #transport-name,#transport-address{font-size: 16px;margin-top: -5px;}</style>'); 
                a.document.write('</head>'); 
                
                a.document.write('<body onload="window.print();">'); 
                 
                a.document.write(divContents); 
                a.document.write('</body></html>');
                a.document.body.style.fontFamily="Calibri, sans-serif";  
                a.document.close();

            }catch{}
      }
    } 
    render() {
        return (
                <>
                    {(this.state.is_way_bill===true) &&
                        <div className="view-on-fly" id="page-content-wrapper">
                            <nav className="navbar border-bottom">
                                <div className="row">
                                    <div className="col-md-12 mrtop-10">
                                        <button type="button" className="btn btn-success mob-btn" onClick={this.handleGenerateEwayBill} id="generate-e-waybill">GENERATE E-WAYBILL</button>
                                        <button type="button" id="generate-e-waybill-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    </div>
                                </div>
                            </nav>
                            <div id="waybill-main-container">
                                <div className="container-fluid page-break">
                                    <div className="content-area">
                                        <div className="content-head border-bottom">
                                            {(this.state.einv_error_list.length>0) &&
                                                <div className="row mrbtm-12">
                                                    <ul>
                                                        {this.state.einv_error_list.map( (value, index) => {
                                                        return (
                                                            <li className="red">
                                                            {value.errorCode} - {value.errorMessage}
                                                            </li>
                                                        )
                                                        })}
                                                    </ul>
                                                </div>
                                            }
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                   <label htmlFor="supply_type" className="fe-title" style={{margin:'0px'}}>SUPPLY TYPE</label>
                                                   <select className="form-control eway-field" id="supply_type">
                                                        <option value="O">OUTWARD</option>
                                                        <option value="I">INWARD</option>
                                                   </select>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="sub_invoice_type" className="fe-title" style={{margin:'0px'}}>SUB SUPPLY TYPE</label>
                                                   <select className="form-control eway-field" id="sub_invoice_type">
                                                        
                                                        <option value="1">SUPPLY</option>
                                                        <option value="2">IMPORT</option>
                                                        <option value="3">EXPORT</option>
                                                        <option value="4">JOB WORK</option>
                                                        <option value="5">FOR OWN USE</option>
                                                        <option value="6">JOB WORK RETURNS</option>
                                                        <option value="7">SALES RETURN</option>
                                                        <option value="8">OTHERS</option>
                                                        <option value="9">SKD/CKD/LOTS</option>
                                                        <option value="10">LINE SALES</option>
                                                        <option value="11">RECIPIENT NOT KNOWN</option>
                                                        <option value="12">EXHIBITION OR FAIRS</option>
                                                   </select>
                                                </div>
                                            </div>
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                   <label htmlFor="doc_type" className="fe-title" style={{margin:'0px'}}>DOC TYPE</label>
                                                   <select className="form-control eway-field" id="doc_type">
                                                        
                                                        <option value="INV">TAX INVOICE</option>
                                                        <option value="BIL">BILL OF SUPPLY</option>
                                                        <option value="BOE">BILL OF ENTRY</option>
                                                        <option value="CHL">DELIVERY CHALLAN</option>
                                                        <option value="OTH">OTHERS</option>
                                                   </select>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="transaction_type" className="fe-title" style={{margin:'0px'}}>TRANSCATION TYPE</label>
                                                   <select className="form-control eway-field" id="transaction_type">
                                                        
                                                        <option value="1">REGULAR</option>
                                                        <option value="2">BILL TO - SHIP TO</option>
                                                        <option value="3">BILL FROM - DISPATCH FROM</option>
                                                        <option value="4">COMBINATION OF 2 AND 3</option>
                                                   </select>
                                                </div>
                                            </div>
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                   <label htmlFor="transport_mode" className="fe-title" style={{margin:'0px'}}>TRANSPORT MODE</label>
                                                   <select className="form-control eway-field" id="transport_mode">
                                                        <option value="">SELECT</option>
                                                        <option value="1">ROAD</option>
                                                        <option value="2">RAIL</option>
                                                        <option value="3">AIR</option>
                                                        <option value="4">SHIP</option>
                                                   </select>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="vehicle_type" className="fe-title" style={{margin:'0px'}}>VEHICLE TYPE</label>
                                                   <select className="form-control eway-field" id="vehicle_type">
                                                        <option value="">SELECT</option>
                                                        <option value="R">REGULAR</option>
                                                        <option value="O">ODC</option>
                                                   </select>
                                                </div>
                                            </div>
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                   <label htmlFor="transporter-id" className="fe-title" style={{margin:'0px'}}>TRANSPORTER ID</label>
                                                   <input type="text" className="form-control eway-field"  id="transporter-id"/>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="transporter-name" className="fe-title" style={{margin:'0px'}}>TRANSPORTER NAME</label>
                                                   <ContactSelect className="eway-field" id="transporter-name" target="tr-name" label="Select Transporter" type="3"/>
                                                   
                                                </div>
                                            </div>
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                   <label htmlFor="trans-doc-no" className="fe-title" style={{margin:'0px'}}>TRANS DOC NO.</label>
                                                   <input type="text" className="form-control eway-field"  id="trans-doc-no"/>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="transport-distance" className="fe-title" style={{margin:'0px'}}>TRANSPORT DISTANCE</label>
                                                   <input type="text" className="form-control eway-field"  id="transport-distance"/>
                                                </div>
                                            </div>
                                            <div className="row mrbtm-12">
                                                <div className="col-md-6">
                                                <span className="fe-title" style={{margin:'0px'}}>TRANS DOC DATE</span>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </span>
                                                    <DatePicker className="form-control  no-border-left" selected={this.state.transDocDate} onChange={this.transDocDate} dateFormat="dd/MM/yyyy"/>
                                                    
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="col-md-6">
                                                   <label htmlFor="vehicle-no" className="fe-title" style={{margin:'0px'}}>VEHICLE NO</label>
                                                   <input type="text" className="form-control  eway-field"  id="vehicle-no"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>     
                    }
                    {(this.state.is_way_bill===false) &&
                        <div className="view-on-fly" id="page-content-wrapper">
                            <nav className="navbar border-bottom">
                                <div className="row">
                                    <div className="col-md-12 mrtop-10">
                                        <button type="button" className="btn btn-success mob-btn" onClick={this.handePrintEwayBill} id="generate-e-waybill">PRINT</button>
                                        <button type="button" id="generate-e-waybill-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    </div>
                                </div>
                            </nav>
                            <div id="waybill-main-container">
                                <div className="container-fluid page-break">
                                    <div className="content-area">
                                        <div className="content-head border-bottom">
                                            <div className="row mrbtm-12">
                                                <div className="col-md-12">
                                                    <table className="table" style={{tableLayout:'fixed',fontSize:'12px'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{fontSize:'18px',textAlign:'center',borderTop:'none',paddingTop:'0'}} colSpan={2}><b>e-WAY BILL</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingBottom:'0px'}}>
                                                                    <p style={{margin:'0px'}}>
                                                                        <span style={{color:'gray'}}>DOC NO : </span>
                                                                        <span>
                                                                            {(this.state.eway_data.request.data.docType && this.state.eway_data.request.data.docType==='INV') &&
                                                                                <>TAX INVOICE</>
                                                                            }
                                                                            {(this.state.eway_data.request.data.docType && this.state.eway_data.request.data.docType==='BIL') &&
                                                                                <>BILL OF SUPPLY</>
                                                                            }
                                                                            {(this.state.eway_data.request.data.docType && this.state.eway_data.request.data.docType==='BOE') &&
                                                                                <>BILL OF SUPPLY</>
                                                                            }
                                                                            {(this.state.eway_data.request.data.docType && this.state.eway_data.request.data.docType==='CHL') &&
                                                                                <>DELIVERY CHALLAN</>
                                                                            }
                                                                            {' | '+this.state.eway_data.request.data.docNo}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                {(this.state.irn_data && this.state.irn_data.all && this.state.irn_data && this.state.irn_data.all.response_data && this.state.irn_data.all.response_data.Irn) &&
                                                                    <td rowSpan={4} style={{textAlign:'right',paddingBottom:'0px'}}>
                                                                        <QRCodeSVG value={JSON.stringify(this.state.irn_data.qr)} />
                                                                    </td>
                                                                }
                                                            </tr>
                                                           
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingTop:'0px',paddingBottom:'0px'}}>
                                                                    <p style={{margin:'0px'}}>
                                                                        <span style={{color:'gray'}}>DATE : </span>
                                                                        <span>{this.state.eway_data.created_date}</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        
                                                            {(this.state.irn_data && this.state.irn_data.all && this.state.irn_data && this.state.irn_data.all.response_data && this.state.irn_data.all.response_data.Irn) &&
                                                            <>
                                                                <tr>
                                                                     <td style={{borderTop:'none',paddingTop:'0px',paddingBottom:'0px'}}>
                                                                        <p style={{margin:'0px'}}>
                                                                            <span style={{color:'gray'}}>IRN : </span>
                                                                            <span>{this.state.irn_data.all.response_data.Irn}</span>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                     <td style={{borderTop:'none',paddingTop:'0px',paddingBottom:'0px'}}>
                                                                        <p style={{margin:'0px'}}>
                                                                            <span style={{color:'gray'}}>ACK. NO : </span>
                                                                            <span>{this.state.irn_data.all.response_data.AckNo}</span>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                     <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                        <p style={{margin:'0px'}}>
                                                                            <span style={{color:'gray'}}>ACK. DATE : </span>
                                                                            <span>{this.state.irn_data.all.response_data.AckDt}</span>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            }
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>1. eWAY BILL DETAILS</p></td>
                                                            </tr>
                                                            <tr>
                                                               <td colSpan={2} style={{paddingBottom:'0px'}}>
                                                                    <table className="table">
                                                                        <tr>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>e-Way Bill No. : </span>
                                                                                <span>{this.state.eway_data.response.data.ewayBillNo}</span>
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Mode : </span>
                                                                                    {(this.state.eway_data.request.data.transMode==='1') &&
                                                                                        <span>ROAD</span>
                                                                                    }
                                                                                    {(this.state.eway_data.request.data.transMode==='2') &&
                                                                                        <span>RAIL</span>
                                                                                    }
                                                                                    {(this.state.eway_data.request.data.transMode==='3') &&
                                                                                        <span>AIR</span>
                                                                                    }
                                                                                    {(this.state.eway_data.request.data.transMode==='4') &&
                                                                                        <span>SHIP</span>
                                                                                    }
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Generated Date : </span>
                                                                                <span>{this.state.eway_data.response.data.ewayBillDate}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Generated By : </span>
                                                                                <span>{this.state.eway_data.response.data.fromGstin}</span>
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Approx Distance : </span>
                                                                                <span>{(this.state.eway_data.request.data.transDistance!=='')?this.state.eway_data.request.data.transDistance+'KM':''}</span>
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Valid Upto : </span>
                                                                                <span>{this.state.eway_data.response.data.validUpto}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Supply Type : </span>
                                                                                <span>{(this.state.eway_data.request.data.supplyType==='O')?'OUTWARD':'INWARD'}</span>
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                                <span style={{color:'gray'}}>Transaction Type : </span>
                                                                                <span></span>
                                                                                {(this.state.eway_data.request.data.transactionType==='1') &&
                                                                                    <span>REGULAR</span>
                                                                                }
                                                                                {(this.state.eway_data.request.data.transactionType==='2') &&
                                                                                    <span>BILL TO - SHIP TO</span>
                                                                                }
                                                                                {(this.state.eway_data.request.data.transactionType==='3') &&
                                                                                    <span>BILL FROM - DISPATCH FROM</span>
                                                                                }
                                                                                {(this.state.eway_data.request.data.transactionType==='4') &&
                                                                                    <span>COMBINATION OF 2 AND 3</span>
                                                                                }
                                                                            </td>
                                                                            <td style={{borderTop:'none',paddingTop:'0px'}}></td>
                                                                        </tr>
                                                                    </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>2. ADDRESS</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>FROM</b></td>
                                                                <td><b>TO</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                    <p style={{margin:'0px'}}>{this.props.way_bill_data.data.company_info[0].company_name}</p>
                                                                    <p style={{margin:'0px'}}>GSTIN : {this.props.way_bill_data.data.company_info[0].gst_number}</p>
                                                                    <p style={{margin:'0px'}}>{this.props.way_bill_data.data.company_info[0].city}</p>
                                                                </td>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                    <p style={{margin:'0px'}}>{this.props.way_bill_data.data.contact_info[0].name}</p>
                                                                    <p style={{margin:'0px'}}>GSTIN : {this.props.way_bill_data.data.contact_info[0].gst_number}</p>
                                                                    <p style={{margin:'0px'}}>{this.props.way_bill_data.data.contact_info[0].city}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}><b>DISPATCH FROM</b></td>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}><b>SHIP TO</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                <p style={{margin:'0px'}}>
                                                                    {this.props.way_bill_data.data.company_info[0].address+' '}
                                                                    {this.props.way_bill_data.data.company_info[0].city+' '}
                                                                    {this.props.way_bill_data.data.company_info[0].state+' '}
                                                                    {this.props.way_bill_data.data.company_info[0].pin_code+' '}
                                                                    {this.props.way_bill_data.data.company_info[0].pan_number+' '}
                                                                   
                                                                    {(this.props.way_bill_data.data.company_info[0].phone!=="") &&
                                                                        <>
                                                                        {'PH '+this.props.way_bill_data.data.company_info[0].phone+' '}
                                                                        </>
                                                                    }
                                                                    {(this.props.way_bill_data.data.company_info[0].phone_2!=="") &&
                                                                        <>
                                                                        {'PH '+this.props.way_bill_data.data.company_info[0].phone_2+' '}
                                                                        </>
                                                                    }
                                                                </p>
                                                                </td>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                <p style={{margin:'0px'}}>
                                                                    {this.props.way_bill_data.data.contact_info[0].address_1+' '}
                                                                    {this.props.way_bill_data.data.contact_info[0].city+' '}
                                                                    {this.props.way_bill_data.data.contact_info[0].state+' '}
                                                                    {this.props.way_bill_data.data.contact_info[0].pin+' '}
                                                                    {(this.props.way_bill_data.data.contact_info[0].phone_1!=="") &&
                                                                        
                                                                        <>
                                                                        {'PH '+this.props.way_bill_data.data.contact_info[0].phone_1+' '}
                                                                        </>
                                                                    }
                                                                    {(this.props.way_bill_data.data.contact_info[0].phone_2!=="") &&
                                                                        <>
                                                                        {'PH '+this.props.way_bill_data.data.contact_info[0].phone_2+' '}
                                                                        </>
                                                                    }
                                                                </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>3. GOODS DETAILS</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <table className="table">
                                                                       {/*  <thead>
                                                                            <tr>
                                                                                <th>HSN</th>
                                                                                <th>PRODUCT</th>
                                                                                <th>QUAN.</th>
                                                                                <th>TAXABLE AMOUNT</th>
                                                                                <th>TAX RATE</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {(this.state.eway_data.request.data.itemList && this.state.eway_data.request.data.itemList.length>0) &&
                                                                            <>
                                                                            {this.state.eway_data.request.data.itemList.map((value, index)=>{
                                                                               return( <tr key={'itemlist-'+index}>
                                                                                    <td>{value.hsnCode}</td>
                                                                                    <td>
                                                                                        <p style={{margin:'0px'}}>
                                                                                            {value.productName}
                                                                                        </p>
                                                                                        <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>
                                                                                            {value.productDesc}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        {value.quantity}
                                                                                    </td>
                                                                                    <td>
                                                                                        {value.taxableAmount}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(value.igstRate) &&
                                                                                            <span>{value.igstRate}</span>
                                                                                        }
                                                                                        {(value.cgstRate) &&
                                                                                            <>
                                                                                                <span>
                                                                                                    {value.cgstRate}(c)
                                                                                                </span>
                                                                                                <span style={{color:'gray'}}> | </span>
                                                                                            </>
                                                                                        }
                                                                                        {(value.sgstRate) &&
                                                                                            <span>{value.sgstRate}(s)</span>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                               )
                                                                            })}
                                                                            </>
                                                                            }
                                                                        </tbody> */}
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <p style={{color:'gray',margin:'0px'}}>Total Taxable Amount</p>
                                                                                    <span style={{fontSize:'18px'}}>{this.props.way_bill_data.data.total_before_tax}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <p style={{color:'gray',margin:'0px'}}>Other Amount</p>
                                                                                    <span style={{fontSize:'18px'}}>{this.props.way_bill_data.data.round_off}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <p style={{color:'gray',margin:'0px'}}>Total Tax</p>
                                                                                    <span style={{fontSize:'18px'}}>{this.props.way_bill_data.data.tax}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <p style={{color:'gray',margin:'0px'}}>Total Inv Amount</p>
                                                                                    <span style={{fontSize:'18px'}}>{this.props.way_bill_data.data.total}</span>
                                                                                </td>
                                                                                <td>
                                                                                   
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>4. TRANSPORTATION DETAILS</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <span style={{color:'gray'}}>TRANSPORTER ID : </span>
                                                                    <span>{this.state.eway_data.request.data.transporterId} </span>
                                                                </td>
                                                                <td>
                                                                    <span style={{color:'gray'}}>DOC NO : </span>
                                                                    <span>{this.state.eway_data.request.data.transDocNo}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                    <span style={{color:'gray'}}>NAME : </span>
                                                                    <span>{this.state.eway_data.request.data.transporterName}</span>
                                                                </td>
                                                                <td style={{borderTop:'none',paddingTop:'0px'}}>
                                                                    <span style={{color:'gray'}}>DATE : </span>
                                                                    <span>{this.state.eway_data.request.data.transDocDate}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><p style={{margin:'0px'}}>5. VEHICLE DETAILS</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <span style={{color:'gray'}}>Vehicle No : </span>
                                                                    <span>{this.state.eway_data.request.data.vehicleNo} </span>
                                                                </td>
                                                                <td>
                                                                    <span style={{color:'gray'}}>Vehicle Type : </span>
                                                                    <span>
                                                                        {(this.state.eway_data.request.data.vehicleType=='R')?'Regular':''}
                                                                        {(this.state.eway_data.request.data.vehicleType=='O')?'ODC':''}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>     
                    }
                </>
        );
    }
}