import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let BusinessTypes = [];
let _this;
let db;
export default class ContactGroups extends Component {
    constructor() {
      super();
      this.state = {
          contact_groups : [],
          modeDefault : 0,
      };
      _this = this;
      
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleResetContactGroup = this.handleResetContactGroup.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }

    editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('cgrp-span-'+id);
      var text = document.getElementById('cgrp-txt-'+id);
      var normal = document.getElementById('cgrp-normal-mode-'+id);
      var edit = document.getElementById('cgrp-edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show");
    }

    updateRow(event){
      
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('cgrp-span-'+id);
      var text = document.getElementById('cgrp-txt-'+id);
      var normal = document.getElementById('cgrp-normal-mode-'+id);
      var edit = document.getElementById('cgrp-edit-mode-'+id);

      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            group : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      let api = app_api+'/update_contact_group';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('cgrp-span-'+id);
      var text = document.getElementById('cgrp-txt-'+id);
      var normal = document.getElementById('cgrp-normal-mode-'+id);
      var edit = document.getElementById('cgrp-edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
    
    deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }


    handleChange(e){
        let target = this.props.target;
        this.setState({modeDefault:e.target.value});
    }
    componentDidMount(){
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_contact_groups';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            this.setState({ 'contact_groups': cursor }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    handleResetContactGroup(){
      //this.setState({ 'contact_groups': [] }); 
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_contact_groups';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;

            this.setState({ 'contact_groups': cursor }); 
           
            if(document.getElementById("inserted_group")){
              let v = document.getElementById("inserted_group").value;
              this.setState({modeDefault:v});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    
    render() {
        return (
          <div>  
            {this.props.show==='list' &&
            <table className="table">
              <tbody>
                  {(this.state.contact_groups.length > 0) ?this.state.contact_groups.map( (value, index) => {
                   return (
                    <tr key={ value.group_id } id={'delete_contact_group_'+value.group_id}>
                      <td>
                        <span id={'cgrp-span-'+value.group_id } className="show l-span">
                          {value.name}
                        </span>
                        <input type="text" defaultValue={value.name} className="form-control hide" id={'cgrp-txt-'+value.group_id}/>
                      </td>
                      <td>
                        <div id={'cgrp-normal-mode-'+value.group_id} className="show">
                        {this.is_access('settings-contacts-modify') &&
                        <>
                   
                          <FontAwesomeIcon id={value.group_id} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                   
                        </>
                        }
                        {this.is_access('settings-contacts-delete') &&
                        <>
                          
                          <FontAwesomeIcon id={value.group_id} onClick={this.deleteRow} data_target="delete_confirm" data_value={value.name} data_id="delete_contact_group" icon={faTrash} className="pull-right fa-icon"/>
                          
                        </>
                        }
                        </div>
                        <div id={'cgrp-edit-mode-'+value.group_id} className="hide mrtop-10">
                          {this.is_access('settings-contacts-modify') &&
                          <>
                          <FontAwesomeIcon id={value.group_id} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                          <FontAwesomeIcon id={value.group_id} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                          </>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody> 
            </table>
            }          
            {this.props.show==='dropdown' &&   
            <>
              <input type="text" className="hide" id="inserted_group"/ >
              <span id="handleResetContactGroup" onClick={this.handleResetContactGroup}></span>
              <select target={this.props.target} tabindex={this.props.tabindex} data-id={this.props.data_id} className="form-control form-field" name={this.props.name}  value={this.state.modeDefault||this.props.default} onChange={this.handleChange} id={this.props.id}>
                  {(this.props.target==='reports') &&
                  <>
                    <option value="all" key="all">All</option>
                    <option value="none" key="none">None</option>
                  </>
                  }
                  {(this.props.target!=='reports') &&
                    <option value="" key="none">Select Group</option>
                  }
                  {(this.state.contact_groups.length > 0) ? this.state.contact_groups.map( (value, index) => {
                   return (
                      <option value={value.group_id} key={value.group_id}>{value.name}</option>
                    )
                  }) : ''}
              </select>
              </>
            }
            </div>
        );
    }
}