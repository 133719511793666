import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ProductUnits from "./product-units";
import utils from '../utils.js';
import Autocomplete from "./autocomplete";
import ReturnBillingItemList from './return-billing-item-list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import { faTimes,faCheckCircle,faPencilAlt,faTrash,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import DifferentBarcodeInventoryItems from "./different-barcode-inventory-items";
import QuotationItemList from "./quotation-item-list";
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
let _this;
let remove_items = [];
let auto_generate_barcode = '';


export default class AddQuotationItem extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            item_options : [],
            purchase_item_options : [],
            variable_purchase_item_options : [],
            bundle_text : '',
            is_tax_inc : 'false',
            more_rows : '',
            return_more_rows : '',
            is_new_item:0,
            active_tab : '',
            edit_item_selected : 'none',
            item_id_selected : 'none',
            selected_inventory_id : 'none',
            selected_barcode : 'none',
            selected_alias_barcode : 'none',
            edit_item_active_index : 'none',
            item_add_count : 0,
            allow_negative_inventory:false,
            is_checked_lsp :false,
            list_item_type :'',
            product_variation_pop_title :'',
            selected_product :'',
            selected_item_name :'',
            selected_item_desc :'',
            selected_item_hsn :'',
            selected_old_item :'',
            is_variant_checked : true,
            variantOptions : [],
            variantList : [],
            variantPreviewList : [],
            v_opt_counter : 1,
            inventory_item:[],
            variation_list : [],
            options_head:[],
            edit_product_variant : 0,
            is_open_popup : false,
            isMobile : false,
            barcode_name_on_the_bill_view:false,
            item_name_on_the_bill_view:false,
            is_auto_generate_barcode : false,
            total_item_amount : 0,
            empty_var_error : '',
            scrollPos : 60,
            regenerate_barcode : {regenerate_barcode:false},
            regenerate_barcode_opt : {regenerate_barcode:false},
            regen_bc_flag : false,
            p_rand_key : '',
            defaultCategory : '',
            defaultCategoryID : 0,
        };
        this.rm_var_li_ids = [];
        this.item_to_remove = [];
        this.variant_data = [];
        this.variantOptions = [];
        this.variantList = [];
        this.variantPreviewList = [];
        this.handleCalculation = this.handleCalculation.bind(this);
        this.handleTaxInclude = this.handleTaxInclude.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleChangeGST = this.handleChangeGST.bind(this);
        this.handleIncludeTaxCalculation = this.handleIncludeTaxCalculation.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        this.CHECK_CREDIT_LIMIT = utils.CHECK_CREDIT_LIMIT.bind(this);
        this.handleCustomItemName = this.handleCustomItemName.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.calculateReturnTotal = this.calculateReturnTotal.bind(this);
        this.checkLowestSellingPrice = this.checkLowestSellingPrice.bind(this);
        this.handleLSP = this.handleLSP.bind(this);
        this.handleFinalCalculation = this.handleFinalCalculation.bind(this);
        this.handleCreateCategory = this.handleCreateCategory.bind(this);
        
        this.handleSmoothAnimation = this.handleSmoothAnimation.bind(this);
        this.addMoreItem = this.addMoreItem.bind(this);
        
        this.handleResetField = this.handleResetField.bind(this);
        this.handleUpdateInventoryQuantity = this.handleUpdateInventoryQuantity.bind(this);
        this.handleUnsetState = this.handleUnsetState.bind(this);
        
        this.handleChangeListTypeOnFly = this.handleChangeListTypeOnFly.bind(this);
    
      
        
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
       
        this.closeModel = this.closeModel.bind(this);
      
        this.handleCalculationOnClick = this.handleCalculationOnClick.bind(this);
        this.handleCalculationOnFly = this.handleCalculationOnFly.bind(this);
        this.handleVarIncludeTaxCalculation = this.handleVarIncludeTaxCalculation.bind(this);
        this.handleChangeItemInfo = this.handleChangeItemInfo.bind(this);
        this.handleChangeBarcodeAuto = this.handleChangeBarcodeAuto.bind(this);
        this.updateItemQuantity = this.updateItemQuantity.bind(this);
        this.handleCloseBarcodeAlert = this.handleCloseBarcodeAlert.bind(this);
        this.handleOpenBarcodeAlert = this.handleOpenBarcodeAlert.bind(this);
        this.handleRegenerateBarcode = this.handleRegenerateBarcode.bind(this);

        this.more_rows = [];
        this.return_more_rows = [];
        _this = this;
    }

    handleOpenBarcodeAlert(){
      var element = document.getElementById('re-generate-barcode');
      element.classList.remove("fade");
      element.style.display="block";
      setTimeout(function(){
        document.getElementById("handleRegenerateBarcode").focus();
      },200)
    }

    closeBarcodeAlert = () =>{
      var element = document.getElementById('re-generate-barcode');
      element.classList.add("fade");
      element.style.display="none";
    }

    handleCloseBarcodeAlert(){
      this.setState({regen_bc_flag:null});
      this.closeBarcodeAlert();
      setTimeout(function(){
        document.getElementById("handleRegenerateBarcode").focus();
        document.getElementById("add-item-to-list").click();
      },200)
    }

    handleRegenerateBarcode(e){
      this.setState({regen_bc_flag:true});
      setTimeout(function(){
        document.getElementById("add-item-to-list").click();
      },100)
      this.closeBarcodeAlert();
    }

    handleCreateCategory(e){
      let thisid = e.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
         document.getElementById("save-target-type").value = 'billing-category';
      }
    }
    handleChangeBarcodeAuto(e){
      let is_checked = e.currentTarget.checked;
      this.setState({is_auto_generate_barcode:is_checked});
      if(is_checked){
        document.getElementById("iitem-barcode").setAttribute('disabled',true);
        document.getElementById("iitem-barcode").removeAttribute('tabindex');
      }else{
        document.getElementById("iitem-barcode").removeAttribute('disabled');
        document.getElementById("iitem-barcode").setAttribute('tabindex',191);
      }
    }
    handleChangeItemInfo(e){
      let target = e.currentTarget.getAttribute('data-target');
      let thisval = e.currentTarget.value;
      if(target==='item_name'){
        let product_name = 'pk_'+thisval.replace(/\s+/g, "___");
        this.setState({selected_item_name:thisval});
        this.setState({selected_product:product_name});
        let product_variation_pop_title = "VARIATION FOR PRODUCT "+thisval;
        this.setState({product_variation_pop_title:product_variation_pop_title});
      }else if(target==='desc'){
        this.setState({selected_item_desc:thisval});
      }else if(target==='hsn'){
        this.setState({selected_item_hsn:thisval});
      }

      if(e.keyCode===13){
         if(target==='item_name'){
            document.getElementById("selected_item_desc").focus();
         }else if(target==='desc'){
            document.getElementById("selected_item_hsn").focus();
         }else if(target==='hsn'){
          if(document.getElementById("prev-item-price")){
            document.getElementById("prev-item-price").focus();
          }
         }
      }
    }
    handleCalculationOnClick(e){
        let data_target = localStorage.getItem('ctarget');
        data_target = data_target.replace('prev','var');
        let dt = document.querySelectorAll('[data-target='+data_target+']');
        for(let i=0;i<dt.length;i++){
          let thisval = dt[i].value;
          let data_id = dt[i].getAttribute('data-id');
          let id = dt[i].id;
          this.handleCalculationOnFly(thisval,data_target,data_id,id);
        }
    }


  handleCalculationOnFly(thisval,data_target,data_id,id){
      let contact_stat = localStorage.getItem('state');

      let quantity = 1;
      if(document.getElementById("var-item-quantity-"+data_id)){
        quantity = document.getElementById("var-item-quantity-"+data_id).value;
        quantity = (quantity!==''?parseFloat(quantity):1);
        
      }
     
      let price = 0;
      if(document.getElementById("var-item-price-"+data_id)){
        price = document.getElementById("var-item-price-"+data_id).value;
        price = (price!==''?parseFloat(price):0);
      }
      let hidden_price = 0;
      if(data_target==="var-item-price"){
        document.getElementById("var-item-price-hidden-"+data_id).value = thisval;
      }
      if(document.getElementById("var-item-price-hidden-"+data_id)){
        hidden_price = document.getElementById("var-item-price-hidden-"+data_id).value
      }

    

      let discount = 0;
      if(document.getElementById("var-item-pp-disc-"+data_id)){
        discount = document.getElementById("var-item-pp-disc-"+data_id).value;
        if(discount===""){
          discount = 0;
        }
      }
      
      let pp_tax = 0;
      if(document.getElementById("var-item-pp-tax-"+data_id)){
        pp_tax = document.getElementById("var-item-pp-tax-"+data_id).value.replace('GST','');
        pp_tax = pp_tax.replace('%','');
       
        pp_tax = parseFloat(pp_tax);
      }
     
       let igst = 0;
       let sgst = 0;
       let cgst = 0;
      if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }

    if(document.getElementById("var-item-sgst-"+data_id)){
      document.getElementById("var-item-sgst-"+data_id).value = sgst;
      document.getElementById("var-item-sgst-hidden-"+data_id).value = sgst;
    }
    if(document.getElementById("var-item-cgst-"+data_id)){
      document.getElementById("var-item-cgst-"+data_id).value = cgst;
      document.getElementById("var-item-cgst-hidden-"+data_id).value = cgst;
    }
    if(document.getElementById("var-item-igst-"+data_id)){
      document.getElementById("var-item-igst-"+data_id).value = igst;
      document.getElementById("var-item-igst-hidden-"+data_id).value = igst;
    }

      if(document.getElementById("var-item-igst-"+data_id)){
        igst = document.getElementById("var-item-igst-"+data_id).value;
        igst = (igst!=='')?parseInt(igst):0;
      }      

      
      if(document.getElementById("var-item-sgst-"+data_id)){
        sgst = document.getElementById("var-item-sgst-"+data_id).value;
        sgst = (sgst!=='')?parseInt(sgst):0;
      }      

      if(document.getElementById("var-item-cgst-"+data_id)){
        cgst = document.getElementById("var-item-cgst-"+data_id).value;
        cgst = (cgst!=='')?parseInt(cgst):0;
      }      
      let tax = cgst+sgst+igst;
      
      let cal_discount = ((hidden_price*quantity)*discount)/100;
      let item_grand_total = (price*quantity)-cal_discount;
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      document.getElementById("var-item-igst-total-"+data_id).value = this.floating_point(igst_tax);
      document.getElementById("var-item-sgst-total-"+data_id).value = this.floating_point(sgst_tax);
      document.getElementById("var-item-cgst-total-"+data_id).value = this.floating_point(cgst_tax);

      if(document.getElementById("var-pp-discount-hidden-"+data_id)){
        document.getElementById("var-pp-discount-hidden-"+data_id).value = this.floating_point(cal_discount);
      }
      
      let final_amount = item_grand_total+cal_tax;
     
      document.getElementById("var-item-total-txt-"+data_id).value = this.floating_point(final_amount);
       
      //if(data_target==='var-item-total-txt' || data_target==='var-item-pp-tax' ||data_target==='var-item-pp-tax-type' || data_target==='var-item-pp-disc' || data_target==='var-item-quantity'){
        this.handleVarIncludeTaxCalculation(data_id);
      //}
    }

    handleVarIncludeTaxCalculation = (id)=>{
        let is_checked = document.getElementById("var-item-pp-tax-type-"+id).value;
        
        let cgst = document.getElementById("var-item-cgst-"+id).value;
        let sgst = document.getElementById("var-item-sgst-"+id).value;
        let igst = document.getElementById("var-item-igst-"+id).value;
        let quantity = document.getElementById("var-item-quantity-"+id).value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("var-item-price-hidden-"+id).value;
        if(price===""){
          price = document.getElementById("var-item-price-"+id).value;
          document.getElementById("var-item-price-hidden-"+id).value = price;
        }
        
        let discount = 0;
        if(document.getElementById("var-pp-discount-hidden-"+id)){
          discount = document.getElementById("var-pp-discount-hidden-"+id).value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }
         
        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("var-item-cgst-total-"+id).value = this.floating_point(total_cgst);
          document.getElementById("var-item-sgst-total-"+id).value = this.floating_point(total_sgst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("var-item-igst-total-"+id).value = this.floating_point(total_igst);
          document.getElementById("var-item-price-"+id).value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("var-item-total-txt-"+id).value =this.floating_point(price);
        } else{
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }else{
          
          if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("var-item-price-hidden-"+id).value;
          if(price===""){
            price = document.getElementById("var-item-price-"+id).value;
            document.getElementById("var-item-price-hidden-"+id).value = price;
          }
          price = price.replace(/,/g , "");
          if(price==""){
            price = 0;
          }
          document.getElementById("var-item-price-"+id).value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("var-item-pp-disc-"+id)){
            disc = document.getElementById("var-item-pp-disc-"+id).value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden-"+id).value = total_discount;
          }

          price = (quantity*price)-total_discount;
          
          let cgst = document.getElementById("var-item-cgst-"+id).value;
          let sgst = document.getElementById("var-item-sgst-"+id).value;
          let igst = document.getElementById("var-item-igst-"+id).value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("var-item-cgst-total-"+id).value = t_cgst;
          document.getElementById("var-item-sgst-total-"+id).value = t_sgst;
          document.getElementById("var-item-igst-total-"+id).value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          
          document.getElementById("var-item-total-txt-"+id).value = this.floating_point(price);
        }
      }
    }

    closeModel(event) {
      let id = event.currentTarget.id;
      var element = document.getElementById('product-variation-add');
      element.classList.add("fade");
      element.style.display="none"; 
      let v_list = [];
      this.updateVariationListState(v_list);
      this.variantOptions = [];
     
      this.setState({variantOptions:this.variantOptions,edit_product_variant:0});
      this.setState({edit_item_selected:'none'});

      this.setState({product_variation_pop_title:'',selected_product:'',selected_item_desc:'',selected_item_hsn:'',selected_item_name:'',selected_old_item:'',is_open_popup:false});

      let rm_var_li_ids = this.rm_var_li_ids;
      let del_row = document.getElementById("delete-row").value;
      if(del_row!==""){
          del_row = JSON.parse(del_row);
          for(let i=0;i<del_row.length;i++){
            let id = del_row[i].id;
            if(rm_var_li_ids.indexOf(id)>=0){
                del_row.splice(i,1);
            }
          }
      }
    }
    updateVariationListState(varList){
      this.variantList = varList;
      this.setState({variantList:this.variantList});
    }

    removeVariationList(tag_name,rm_type){
      let variationList = this.state.variantList;
      for(let i=variationList.length-1;i>=0;i--){
        if(variationList[i]!==undefined){
          let label = variationList[i].props.label;
          let tags = label.split("/");
          for(let j=0;j<tags.length;j++){
            let tname = tags[j];
            if(tname===tag_name){
              if(variationList[i].props.item_data.list_item_id!==undefined){
                let liid = variationList[i].props.item_data.list_item_id;
                let liquan = variationList[i].props.item_data.quantity;
                if(liid!==""){
                  let rm_var_li = {id:liid,quantity:liquan};
                  this.rm_var_li_ids.push(liid);
                  remove_items.push(rm_var_li);
                  document.getElementById("delete-row").value = JSON.stringify(remove_items);
                }
              }
              if(rm_type==='remove'){
                variationList.splice(i,1);
              }
            }
          }
        }
      }
      if(rm_type==='remove'){
        this.variantList = [];
        this.setState({variantList:[]});
        this.variantList = variationList;
        let _this = this;
        setTimeout(function(){
          _this.setState({variantList:_this.variantList});
        },5);
      }
    }


    

    handleRemoveItem(id){
      let item_lbl = document.getElementById("item-lbl-"+id).innerHTML;
      
      let item_to_remove = {item_lbl:item_lbl,selected_product:this.state.selected_product};
      this.item_to_remove.push(item_to_remove);
      for(let i=this.variantList.length-1;i>=0;i--){
        if(this.variantList[i].props.label===item_lbl){
            this.variantList.splice(i,1);
        }
      }
      this.updateVariationListState(this.variantList);

      let item_id = document.getElementById("var-item-id-"+id).value;
      let quantity = document.getElementById("var-item-quantity-"+id).value;
      let item = {id:item_id,quantity:quantity};
      remove_items.push(item);
      document.getElementById("delete-row").value = JSON.stringify(remove_items);

    }


    handleChangeListTypeOnFly(e){
      this.setState({list_item_type:'single'});
    }

    handleUnsetState(e){
      this.setState({is_checked_lsp:false});
    }
    handleResetField(e){
      let selected_item = this.state.edit_item_selected;
      document.getElementById("new-item-form").reset();
      if(this.props.billing_type==="New Purchase"){
        this.setState({list_item_type:'single'});
      }else{
        this.setState({list_item_type:'product'});
      }
      //if(document.getElementById("item-row-"+selected_item)){
        let sli = document.querySelectorAll(".billing-item-row");
        for(let i=0;i<sli.length;i++){
           sli[i].classList.remove('item-edit-mode');
        }
        let slir = document.querySelectorAll(".return-billing-item-row");
        for(let i=0;i<slir.length;i++){
           slir[i].classList.remove('item-edit-mode');
        }
        this.setState({edit_item_selected:'none'});
      //}
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }
      let ret_item_edit_mode = document.querySelectorAll(".ret-item-mod-options");
      for(let i=0;i<ret_item_edit_mode.length;i++){
        ret_item_edit_mode[i].style.display = 'flex';
      }
      let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
      for(let i=0;i<inputs.length;i++){
        inputs[i].value = '';
      }
     /*  if(document.getElementById("iitem-category")){
        localStorage.setItem('selected_product',1);
        if(document.getElementById("setProductState")){
          document.getElementById("setProductState").click();
        }
      } */
      if(document.querySelector(".bill-ret-type")){
        let cls_ret_type = document.querySelector(".bill-ret-type").getAttribute("data-id");

        if(cls_ret_type==='invoice'){
          let cls = this.props.billing_type.replaceAll(" ",'-');
          if(document.querySelector(".sticky-add-item-panel."+cls)){
            document.querySelector(".sticky-add-item-panel."+cls).style.display = "none";
          }
          if(document.querySelector(".list-item-add-blk."+cls)){
            document.querySelector(".list-item-add-blk."+cls).style.display = "none";
          }
          if(document.querySelector(".add-item-mob."+cls)){
            document.querySelector(".add-item-mob."+cls).style.display = "none";
          }
        }

        //let cls_ret_opt = document.querySelector(".bill-ret-option").getAttribute("data-id");
        
      }
    }

    handleChangeGST(e){
      let sp_gst = document.getElementById("iitem-sp-gst").value.replace('GST','');
      sp_gst = sp_gst.replace('%','');
      document.getElementById("iitem-sp-gst").value = sp_gst.trim();

    }
    handleChangeText(e){
       let thisval = e.currentTarget.value;
       let data_target = e.currentTarget.getAttribute('target');
       if(thisval===""){
          thisval = 0;
       }else{
          thisval = parseInt(thisval);
       }
       if(this.props.billing_type==="New Purchase"){
        if(document.getElementById("price-hidden") && data_target==='price'){
          document.getElementById("price-hidden").value = thisval;
        }
       }

       if(data_target==='price'){
          this.setState({is_checked_lsp:false});
          this.handleCalculation(e);
       }

    }

    updateItemQuantity(item_id){
      let option_available_storage = JSON.parse(localStorage.getItem("item_options"));
      let purchase_option_available_storage = JSON.parse(localStorage.getItem("purchase_item_options"));

      let item_options = this.state.item_options;
      let purchase_item_options = this.state.purchase_item_options;
 
      if(item_options[item_id]!==undefined){
        item_options[item_id] = option_available_storage[item_id];
        this.setState({ 'item_options': item_options});
      }
      if(purchase_item_options[item_id]!==undefined){
        purchase_item_options[item_id] = purchase_option_available_storage[item_id];
        this.setState({ 'purchase_item_options': purchase_item_options});
      }
    }

    handleUpdateInventoryQuantity(type,no_of_item,item_id){
        let option_available = JSON.parse(localStorage.getItem("item_options"));
        let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
        if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
          let avl = purchase_option_available[item_id].availability;
          let av = purchase_option_available[item_id].old_available;

          let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
          if(get_diff_barcode_inv_availability>=0){
            avl = get_diff_barcode_inv_availability;
          }

          let availability = 0;
          if(type==='add'){
            availability = avl+no_of_item;
          }else{
            availability = avl-no_of_item;
          }
          if(option_available!==null && option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].availability!==undefined && option_available[item_id].availability!==null){
            option_available[item_id].availability = availability;
          }
          purchase_option_available[item_id].availability = availability;
          let static_item_name = purchase_option_available[item_id].static_item_name;
          let inventory_status = purchase_option_available[item_id].inventory_status;
          let stat_lbl = '';
          if(inventory_status==='Draft'){
            stat_lbl=' (Inactive)';
          }
          let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
          purchase_option_available[item_id].value2 = new_item_name;
          if(option_available[item_id]!==undefined && option_available[item_id]!==null){
            option_available[item_id].value2 = new_item_name;
            localStorage.setItem("item_options",JSON.stringify(option_available));
            //this.setState({ 'item_options': option_available});
          }
          localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
          //this.setState({ 'purchase_item_options': purchase_option_available}); 

          this.updateItemQuantity(item_id);
        }
    }

    addMoreItem(event){
      let list_item_type = '';
      let item_name = (document.getElementById("iitem-box"))?document.getElementById("iitem-box").value:'';

      if(item_name===""){
          document.getElementById("iitem-box").focus();
          return false;
      }

      let desc= (document.getElementById("iitem-desc"))?document.getElementById("iitem-desc").value:'';
      let hsn= (document.getElementById("iitem-hsn"))?document.getElementById("iitem-hsn").value:'';

      if((this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sales Return') && (this.state.is_checked_lsp===false && list_item_type!=='variable')){
        let is_lowest = this.checkLowestSellingPrice(event);
        if(is_lowest===false){
            localStorage.setItem("save_row",'yes')
            //document.getElementById("target_index").value = 'save-row';
            return false;
        }
      }
      this.setState({is_checked_lsp:false});
      let type = event.currentTarget.id;
      let item_len = this.state.item_add_count;//document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.setState({item_add_count:key});
      
      
      let list_item_id = (document.getElementById("iitem-list-id"))?document.getElementById("iitem-list-id").value:'';
      let item_id= (document.getElementById("iitem-name"))?document.getElementById("iitem-name").value:'';
      item_id = parseInt(item_id);
      let bundle= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_text= (document.getElementById("iitem-bundle-text"))?document.getElementById("iitem-bundle-text").value:'';
      let quantity= (document.getElementById("iitem-quantity"))?document.getElementById("iitem-quantity").value:0;
      let availability= (document.getElementById("iitem-availability"))?document.getElementById("iitem-availability").innerHTML:'';
      let bundle_quantity= (document.getElementById("iitem-bundle-quantity"))?document.getElementById("iitem-bundle-quantity").value:'';
      let bundle_desc= (document.getElementById("bundle_quantity"))?document.getElementById("bundle_quantity").innerHTML:'';
     
      let unit_id = (document.getElementById("iitem-unit"))?document.getElementById("iitem-unit").value:1;

      let unit_name = 'Pcs';
      if(document.getElementById("iitem-unit")){
        let iiunit = document.getElementById("iitem-unit");
        if(iiunit.selectedIndex>=0){
          if(iiunit.options[iiunit.selectedIndex]!==undefined && iiunit.options[iiunit.selectedIndex]!==null){
            unit_name = iiunit.options[iiunit.selectedIndex].text;
          }else{
            unit_id = 1;
            unit_name = 'Pcs';
          }
        }else{
          unit_id = 1;
          unit_name = 'Pcs';
        }
      }

      let price= (document.getElementById("price"))?document.getElementById("price").value:'';
      let lowest_selling_price= (document.getElementById("iitem-lowest-selling-price"))?document.getElementById("iitem-lowest-selling-price").value:'';
      let total_avl= availability;
      let discount= (document.getElementById("discount"))?document.getElementById("discount").value:0;
      let total_discount= (document.getElementById("discount-hidden"))?document.getElementById("discount-hidden").value:0;
      let tax= (document.getElementById("iitem-gst"))?document.getElementById("iitem-gst").value:0;
      let cgst= (document.getElementById("iitem-cgst"))?document.getElementById("iitem-cgst").value:0;
      let hidden_cgst= (document.getElementById("iitem-cgst-hidden"))?document.getElementById("iitem-cgst-hidden").value:0;
      let total_cgst= (document.getElementById("iitem-cgst-total"))?document.getElementById("iitem-cgst-total").value:0;
      let sgst= (document.getElementById("iitem-sgst"))?document.getElementById("iitem-sgst").value:0;
      let hidden_sgst= (document.getElementById("iitem-sgst-hidden"))?document.getElementById("iitem-sgst-hidden").value:0;
      let total_sgst= (document.getElementById("iitem-sgst-total"))?document.getElementById("iitem-sgst-total").value:0;
      let igst= (document.getElementById("iitem-igst"))?document.getElementById("iitem-igst").value:0;
      let hidden_igst= (document.getElementById("iitem-igst-hidden"))?document.getElementById("iitem-igst-hidden").value:0;
      let total_igst= (document.getElementById("iitem-igst-total"))?document.getElementById("iitem-igst-total").value:0;
      let pp_tax_type= (document.getElementById("iitem-tax-inc"))?document.getElementById("iitem-tax-inc").value:0;
      let price_hidden= (document.getElementById("price-hidden"))?document.getElementById("price-hidden").value:0;
      let price2= (document.getElementById("iitem-selling-price"))?document.getElementById("iitem-selling-price").value:'';

      let sp_discount= (document.getElementById("sp-discount"))?document.getElementById("sp-discount").value:0;
      let sp_total_discount= (document.getElementById("sp-discount-hidden"))?document.getElementById("sp-discount-hidden").value:0;
      let sp_tax= (document.getElementById("iitem-sp-gst"))?document.getElementById("iitem-sp-gst").value:0;
    
      let sp_tax_type= (document.getElementById("sp-tax-type"))?document.getElementById("sp-tax-type").value:0;
      let iitem_barcode= (document.getElementById("iitem-barcode"))?document.getElementById("iitem-barcode").value:this.state.selected_barcode;
      let alias_barcode= (document.getElementById("iitem-alias-barcode"))?document.getElementById("iitem-alias-barcode").value:this.state.selected_alias_barcode;
      let iitem_category= (document.getElementById("tags-id-category"))?document.getElementById("tags-id-category").value:"";
      let iitem_category_name="";
      if(iitem_category!==""){
        iitem_category_name = document.getElementById("tags-value-category").value;
      }

      let total= (document.getElementById("iitem-total-txt"))?document.getElementById("iitem-total-txt").value:0;

      if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase'){
          let selected_item = this.state.edit_item_selected;
          let is_credit_limit = this.CHECK_CREDIT_LIMIT('item',selected_item);
          if(is_credit_limit===false){
            return false;
          }
      }
      if(this.state.regen_bc_flag===true){
        iitem_barcode = 'Re Generate';
      }

      let items = {
          iitem_type : list_item_type,
          list_item_id : list_item_id,
          item_name : item_name,
          item_id : item_id,
          desc : desc,
          hsn : hsn,
          bundle : bundle,
          bundle_text : bundle_text,
          quantity : quantity,
          availability : availability,
          bundle_quantity : bundle_quantity,
          bundle_desc : bundle_desc,
          unit_name : unit_name,
          unit_id : unit_id,
          price : price,
          lowest_selling_price : lowest_selling_price,
          total_avl : total_avl,
          price2 : price2,
          discount : discount,
          total_discount : total_discount,
          tax : tax,
          cgst : cgst,
          hidden_cgst : hidden_cgst,
          total_cgst : total_cgst,
          sgst : sgst,
          hidden_sgst : hidden_sgst,
          total_sgst :  total_sgst,
          igst : igst,
          hidden_igst : hidden_igst,
          total_igst : total_igst,
          pp_tax_type : pp_tax_type,
          is_tax_inc : pp_tax_type,
          price_hidden : price_hidden,

          sp_discount : sp_discount,
          sp_total_discount : sp_total_discount,
          sp_tax : sp_tax,
          sp_tax_type : sp_tax_type,
          barcode : iitem_barcode,
          alias_barcode : alias_barcode,
          barcode_name_on_bill_view:this.state.barcode_name_on_the_bill_view,
          item_name_on_bill_view:this.state.item_name_on_the_bill_view,
          iitem_category:iitem_category,
          iitem_category_name:iitem_category_name,
          total : total
      }

      let itotal = parseFloat(total);
      if(itotal<=0 && list_item_type!=='variable' && list_item_type!=='general'){
        if(document.getElementById("price")){
          document.getElementById("price").focus();
        }
        return false;
      }
      quantity = parseInt(quantity);
      let is_valid = true;
      if(item_id>0){
        let option_available = JSON.parse(localStorage.getItem("item_options"));
        let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
        if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
          
          let avl = purchase_option_available[item_id].availability;

          let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
          
          if(get_diff_barcode_inv_availability>=0){
            avl = get_diff_barcode_inv_availability;
            let i_id = utils.get_diff_barcode_latest_id(this.props.billing_type, purchase_option_available[item_id]);
            if(i_id>0){
              item_id = i_id;
            }
          }
          
          let av = purchase_option_available[item_id].old_available;
          let inventory_status = purchase_option_available[item_id].inventory_status;
          if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Purchase Return'){
            availability = avl-quantity;
          }else if(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sales Return'){
            availability = avl+quantity;
          }
          
          if(inventory_status==='Active'){
            if(option_available!==null && option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].availability!==undefined && option_available[item_id].availability!==null){
              option_available[item_id].availability = availability;
            }
          }
          purchase_option_available[item_id].availability = availability;
          let static_item_name = purchase_option_available[item_id].static_item_name;
          let stat_lbl = '';
          if(inventory_status==='Draft'){
            stat_lbl=' (Inactive)';
          }
          av = parseInt(avl);
         
          if(quantity>av  && this.state.allow_negative_inventory===false){
            if(this.props.billing_type==='New Sale' && list_item_type!=='sales_return'){
              var element = document.getElementById('notice_box');
              let msg = "You are entering quantity more than availability, total availability is "+av;
              document.getElementById("notice_message").innerHTML = msg;
              element.classList.remove("fade");
              element.style.display="block";
              document.getElementById("item_availabe_hidden").value = av;
              document.getElementById("item_index_hidden").value = 0;
              document.getElementById("close-notice").focus();
              is_valid = false;
            }
          }else{

            let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
            purchase_option_available[item_id].value2 = new_item_name;
            //if(inventory_status==='Active'){
              if(option_available[item_id]!==undefined && option_available[item_id]!==null && option_available[item_id].value2!==undefined && option_available[item_id].value2!==null){
                option_available[item_id].value2 = new_item_name;
                localStorage.setItem("item_options",JSON.stringify(option_available));
                //this.setState({ 'item_options': option_available});
              }
            //}
            localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
            //this.setState({ 'purchase_item_options': purchase_option_available}); 
            this.updateItemQuantity(item_id);
          }
        }
      }
      
      if((is_valid && quantity>0)){
        let selected_item = this.state.edit_item_selected;
        let edit_item_active_index = this.state.edit_item_active_index;
        let active_index = this.state.edit_item_active_index;
        if(selected_item!=='none'){
          this.more_rows[selected_item] = <QuotationItemList count={item_len} type="more" key={edit_item_active_index} index={edit_item_active_index} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;
          //this.setState({more_rows:this.more_rows});
          let sli = document.querySelectorAll(".billing-item-row");
          for(let i=0;i<sli.length;i++){
             sli[i].classList.remove('item-edit-mode');
          }
          this.setState({edit_item_selected:'none'});
        }else{

          let birow = document.querySelectorAll(".billing-item-row");
          let next_row = 0;
          let temp_row = -1;

          for(let i=0;i<birow.length;i++){
              let tdid = birow[i].getAttribute('data-id');
              if(parseInt(tdid)>=temp_row){
                temp_row = parseInt(tdid);
              }
          }
          temp_row =  temp_row+1;
          if(localStorage.getItem('return_bill_count')!==null){
            temp_row = localStorage.getItem('return_bill_count');
            localStorage.removeItem('return_bill_count')
          }
          if(this.more_rows[temp_row]===undefined){
            this.more_rows[temp_row] = [];
          }

          //let row_len = this.more_rows.length;
          this.more_rows[temp_row] = <QuotationItemList count={temp_row} type="more" key={temp_row} index={temp_row} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>;

          //console.log(this.more_rows);
        }

        this.setState({more_rows:this.more_rows});
        let rm = document.querySelectorAll(".item-remove");
        for(let i=0;i<rm.length;i++){
          rm[i].style.display = 'block';
        }
        document.getElementById("new-item-form").reset();
        if(this.props.billing_type==='New Sale'){
          this.setState({list_item_type:'product'});
        }
        if(this.props.billing_type==='New Purchase'){
          this.setState({list_item_type:'single'});
        }

        document.getElementById("iitem-box").value = '';
        document.getElementById("iitem-desc-txt").innerHTML = '';
        if(document.getElementById("iitem-barcode")){
          let bcode_name = document.getElementById("iitem-barcode").value;
          if(bcode_name!=="Auto Generate"){
            document.getElementById("iitem-barcode").value = '';
          }
        }
       
        this.setState({is_new_item:0});
        setTimeout(function(){
          document.getElementById("iitem-box").focus();
          document.getElementById("iitem-unit").selectedIndex = "0";
          _this.calculateTotal();
        },100);
      }else{
        document.getElementById("iitem-quantity").focus();
      }
      let item_edit_mode = document.querySelectorAll(".item-mod-options");
      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'flex';
      }

      setTimeout(function(){
        document.getElementById("iitem-box").focus();
        document.getElementById("iitem-unit").selectedIndex = "0";
        _this.ADJUST_BILL_SNO('regular');
      },100);

      this.handleSmoothAnimation();
    }

    handleSmoothAnimation(){
      if(document.getElementById("list-item-table")){
        document.getElementById("list-item-table").classList.remove('animate__animated');
        document.getElementById("list-item-table").classList.remove('animate__fadeIn');
      }
      setTimeout(function(){
        let qs = document.querySelectorAll(".billing-item-row");
        for(let i=qs.length-1;i>0;i--){
          let did = qs[i].getAttribute('data-id');
          document.getElementById("item-row-"+did).classList.add('animate__animated');
          document.getElementById("item-row-"+did).classList.add('animate__fadeIn');
          return;
        }
      },8);
      let _this = this;
      setTimeout(function(){
        let bill_row = document.querySelectorAll(".billing-item-row");
        let cls_len = bill_row.length;
        if(cls_len>5){
          let scrollPos = _this.state.scrollPos;
          window.scrollTo({top: scrollPos, behavior: 'smooth'});
          scrollPos = scrollPos+60;
          _this.setState({scrollPos:scrollPos});
        }
      },100);
    }
    
    handleListItemEdit(e){

      let index = e.currentTarget.getAttribute('data-id');
      index = parseInt(index);
      let has_variation = false;
      if(document.getElementById("item-has-variant-"+index)){
        has_variation = document.getElementById("item-has-variant-"+index).value;
      }
      let item_name = (document.getElementById("item-box-"+index))?document.getElementById("item-box-"+index).value:'';
      let sitem_id = (document.getElementById("item-list-id-"+index))?document.getElementById("item-list-id-"+index).value:'';
      let selected_inventory_id = (document.getElementById("item-name-"+index))?document.getElementById("item-name-"+index).value:'';
      let selected_barcode = (document.getElementById("vitem-barcode-name-"+index))?document.getElementById("vitem-barcode-name-"+index).value:'';

      let item_key = (document.getElementById("vitem-item-key-"+index))?document.getElementById("vitem-item-key-"+index).value:'';

      let selected_alias_barcode = (document.getElementById("vitem-alias-barcode-name-"+index))?document.getElementById("vitem-alias-barcode-name-"+index).value:'';
       let desc= (document.getElementById("iitem-desc-txt-"+index))?document.getElementById("iitem-desc-txt-"+index).innerHTML:'';
      let hsn= (document.getElementById("item-hsn-"+index))?document.getElementById("item-hsn-"+index).value:'';

      let item_category= (document.getElementById("item-category-"+index))?document.getElementById("item-category-"+index).value:1; 

      let item_category_name= (document.getElementById("item-category-name-"+index))?document.getElementById("item-category-name-"+index).innerHTML:''; 

      this.setState({defaultCategory:item_category_name});
      this.setState({defaultCategoryID:item_category});
      localStorage.setItem('selected_product',item_category);
      if(document.getElementById("setProductState")){
        //document.getElementById("setProductState").click();
      }

      let ikey = 0;
      
      for(let k=0;k<this.more_rows.length;k++){
        if(this.more_rows[k]!==undefined){
          if(this.more_rows[k].props!==undefined){
            let ik = this.more_rows[k].props.index;
            if(ik===index){
              ikey = k;
            }
          }
        }
      }
      this.setState({edit_item_selected:ikey,edit_item_active_index:index,item_id_selected:sitem_id,selected_inventory_id:selected_inventory_id,selected_barcode:selected_barcode,selected_alias_barcode:selected_alias_barcode,p_rand_key:item_key});
      
      let list_item_type = '';
      if(document.getElementById("item-type-"+index)){
        list_item_type = document.getElementById("item-type-"+index).value;
      }
      this.setState({list_item_type:list_item_type});
     
      let item_edit_mode = document.querySelectorAll(".item-mod-options");

      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'none';
      }

      document.getElementById("item-row-"+index).classList.add('item-edit-mode');
      
     
      
      let list_item_id = (document.getElementById("item-list-id-"+index))?document.getElementById("item-list-id-"+index).value:'';
      let item_id= (document.getElementById("item-name-"+index))?document.getElementById("item-name-"+index).value:'';
     
      let bundle= (document.getElementById("item-bundle-quantity-"+index))?document.getElementById("item-bundle-quantity-"+index).value:'';
      let bundle_text= (document.getElementById("item-bundle-text-"+index))?document.getElementById("item-bundle-text-"+index).value:'';
      let quantity= (document.getElementById("item-quantity-"+index))?document.getElementById("item-quantity-"+index).value:0;



      let availability= (document.getElementById("item-availability-"+index))?document.getElementById("item-availability-"+index).innerHTML:'';
      let bundle_quantity= (document.getElementById("item-bundle-quantity-"+index))?document.getElementById("item-bundle-quantity-"+index).value:'';
      let unit_name= (document.getElementById("item-unit-name-"+index))?document.getElementById("item-unit-name-"+index).value:'';
      let unit_id = (document.getElementById("item-unit-"+index))?document.getElementById("item-unit-"+index).value:'';
      let price= (document.getElementById("price-"+index))?document.getElementById("price-"+index).value:'';
      let lowest_selling_price= (document.getElementById("item-selling-price-"+index))?document.getElementById("item-selling-price-"+index).value:'';
      let total_avl= availability;
      let price2= (document.getElementById("item-selling-price-"+index))?document.getElementById("item-selling-price-"+index).value:'';
      let discount= (document.getElementById("discount-"+index))?document.getElementById("discount-"+index).value:0;
     
      let total_discount= (document.getElementById("discount-hidden-"+index))?document.getElementById("discount-hidden-"+index).value:0;
      let tax= (document.getElementById("item-gst-"+index))?document.getElementById("item-gst-"+index).value:0;
      let cgst= (document.getElementById("item-cgst-"+index))?document.getElementById("item-cgst-"+index).value:0;
      let hidden_cgst= (document.getElementById("item-cgst-hidden-"+index))?document.getElementById("item-cgst-hidden-"+index).value:0;
      let total_cgst= (document.getElementById("item-cgst-total-"+index))?document.getElementById("item-cgst-total-"+index).value:0;
      let sgst= (document.getElementById("item-sgst-"+index))?document.getElementById("item-sgst-"+index).value:0;
      let hidden_sgst= (document.getElementById("item-sgst-hidden-"+index))?document.getElementById("item-sgst-hidden-"+index).value:0;
      let total_sgst= (document.getElementById("item-sgst-total-"+index))?document.getElementById("item-sgst-total-"+index).value:0;
      let igst= (document.getElementById("item-igst-"+index))?document.getElementById("item-igst-"+index).value:0;
      let hidden_igst= (document.getElementById("item-igst-hidden-"+index))?document.getElementById("item-igst-hidden-"+index).value:0;
      let total_igst= (document.getElementById("item-igst-total-"+index))?document.getElementById("item-igst-total-"+index).value:0;
      let pp_tax_type= (document.getElementById("item-tax-inc-"+index))?document.getElementById("item-tax-inc-"+index).value:0;
      let price_hidden= (document.getElementById("price-hidden-"+index))?document.getElementById("price-hidden-"+index).value:0;
      let total= (document.getElementById("item-total-txt-"+index))?document.getElementById("item-total-txt-"+index).value:0;

       let sp_discount= (document.getElementById("sp-discount-"+index))?document.getElementById("sp-discount-"+index).value:0;
      let sp_discount_hidden= (document.getElementById("sp-discount-hidden-"+index))?document.getElementById("sp-discount-hidden-"+index).value:0;
      let sp_gst= (document.getElementById("item-sp-gst-"+index))?document.getElementById("item-sp-gst-"+index).value:0;

      let sp_tax_type= (document.getElementById("sp-item-tax-inc-"+index))?document.getElementById("sp-item-tax-inc-"+index).value:0; 

      
      let item_barcode= (document.getElementById("vitem-barcode-name-"+index))?document.getElementById("vitem-barcode-name-"+index).value:1; 
      let alias_barcode= (document.getElementById("vitem-alias-barcode-name-"+index))?document.getElementById("vitem-alias-barcode-name-"+index).value:1; 

      

       setTimeout(function(){
      if(document.getElementById("iitem-list-id")){document.getElementById("iitem-list-id").value = list_item_id};
      if(document.getElementById("iitem-name")){document.getElementById("iitem-name").value = item_id};
     
      if(document.getElementById("iitem-desc")){document.getElementById("iitem-desc").value = desc};
      if(document.getElementById("iitem-desc-txt")){document.getElementById("iitem-desc-txt").innerHTML = desc};
      if(document.getElementById("iitem-hsn")){document.getElementById("iitem-hsn").value= hsn};
      if(document.getElementById("iitem-bundle-quantity")){document.getElementById("iitem-bundle-quantity").value = bundle};
      if(document.getElementById("iitem-bundle-text")){document.getElementById("iitem-bundle-text").value = bundle_text};
      if(document.getElementById("iitem-quantity")){document.getElementById("iitem-quantity").value=quantity};
      if(document.getElementById("iitem-availability")){document.getElementById("iitem-availability").innerHTML = availability};
      if(document.getElementById("iitem-bundle-quantity")){document.getElementById("iitem-bundle-quantity").value = bundle_quantity};
      if(document.getElementById("iitem-unit-name")){document.getElementById("iitem-unit-name").value = unit_name};
     
        if(document.getElementById("iitem-unit")){document.getElementById("iitem-unit").value = unit_id};
        if(document.getElementById("iitem-box")){document.getElementById("iitem-box").value = item_name};
         document.getElementById("iitem-box").focus();
      
      if(document.getElementById("price")){document.getElementById("price").value = price};
      if(document.getElementById("iitem-selling-price")){document.getElementById("iitem-selling-price").value = price2};
//      let total_avl= availability;
     
      if(document.getElementById("discount")){document.getElementById("discount").value = discount};
      if(document.getElementById("discount-hidden")){document.getElementById("discount-hidden").value = total_discount};
      if(document.getElementById("iitem-gst")){document.getElementById("iitem-gst").value = tax};
      if(document.getElementById("iitem-cgst")){document.getElementById("iitem-cgst").value = cgst};
      if(document.getElementById("iitem-cgst-hidden")){document.getElementById("iitem-cgst-hidden").value = hidden_cgst};
      if(document.getElementById("iitem-cgst-total")){document.getElementById("iitem-cgst-total").value = total_cgst};
      if(document.getElementById("iitem-sgst")){document.getElementById("iitem-sgst").value =sgst};
      if(document.getElementById("iitem-sgst-hidden")){document.getElementById("iitem-sgst-hidden").value = hidden_sgst};
      if(document.getElementById("iitem-sgst-total")){document.getElementById("iitem-sgst-total").value = total_sgst};
      if(document.getElementById("iitem-igst")){document.getElementById("iitem-igst").value = igst};
      if(document.getElementById("iitem-igst-hidden")){document.getElementById("iitem-igst-hidden").value = hidden_igst};
      if(document.getElementById("iitem-igst-total")){document.getElementById("iitem-igst-total").value =  total_igst};
      if(document.getElementById("iitem-tax-inc")){document.getElementById("iitem-tax-inc").value = pp_tax_type};
      if(document.getElementById("price-hidden")){document.getElementById("price-hidden").value  = price_hidden};
      if(document.getElementById("sp-discount")){document.getElementById("sp-discount").value = sp_discount};
      if(document.getElementById("sp-discount-hidden")){document.getElementById("sp-discount-hidden").value = sp_discount_hidden};
      if(document.getElementById("iitem-sp-gst")){document.getElementById("iitem-sp-gst").value = sp_gst};
      if(document.getElementById("sp-tax-type")){document.getElementById("sp-tax-type").value = sp_tax_type};
      if(document.getElementById("iitem-total-txt")){document.getElementById("iitem-total-txt").value = total};
      //if(document.getElementById("iitem-category")){document.getElementById("iitem-category").value = item_category};
      if(document.getElementById("iitem-barcode")){document.getElementById("iitem-barcode").value = item_barcode};
      if(document.getElementById("iitem-alias-barcode")){document.getElementById("iitem-alias-barcode").value = alias_barcode};
      },100);
      quantity = parseInt(quantity);
      this.handleUpdateInventoryQuantity('add',quantity,item_id);

      if(document.querySelector(".bill-ret-type")){
        let cls_ret_type = document.querySelector(".bill-ret-type").getAttribute("data-id");

        if(cls_ret_type==='invoice'){
          let cls = this.props.billing_type.replaceAll(" ",'-');
          if(document.querySelector(".sticky-add-item-panel."+cls)){
            document.querySelector(".sticky-add-item-panel."+cls).style.display = "block";
          }
          if(document.querySelector(".list-item-add-blk."+cls)){
            document.querySelector(".list-item-add-blk."+cls).style.display = "block";
          }
          if(document.querySelector(".add-item-mob."+cls)){
            document.querySelector(".add-item-mob."+cls).style.display = "block";
          }
          document.getElementById("iitem-box").setAttribute('disabled',true);
          document.getElementById("iitem-box").removeAttribute('tabindex');
          document.getElementById("iitem-quantity").focus();
        }

        //let cls_ret_opt = document.querySelector(".bill-ret-option").getAttribute("data-id");
        
      }

    }

    handleListItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');
      
      let total_out_avl = 0;
      if(this.props.billing_type==='New Purchase'){
        total_out_avl = parseInt(document.getElementById("total-avl-out-hidden-"+index).value);

        if(total_out_avl>0){
            var element = document.getElementById('notice_box');
            let msg = "Oops! You can't delete this item";
            document.getElementById("notice_message").innerHTML = msg;
          
            element.classList.remove("fade");
            element.style.display="block";
          
        }
      }
      if(total_out_avl===0){
        let item_id = document.getElementById("item-name-"+index).value;
        let remove_item_id = document.getElementById("item-list-id-"+index).value;
        let remove_item_key = document.getElementById("vitem-item-key-"+index).value;
        let item_name = document.getElementById("item-box-"+index).value;
        let product_key = 'pk_'+item_name.replace(/\s+/g, "___");
        let pro_key = product_key+'_'+remove_item_key;
        if(item_id===""){
          item_id = 0;
        }
        if(remove_item_id===""){
          remove_item_id = 0;
        }
        let quantity = 0;
      
        quantity = document.getElementById("item-quantity-"+index).value;
        if(remove_item_id!=="" && remove_item_id!=="0"){
          let item = {id:remove_item_id,quantity:quantity};
          remove_items.push(item);
        }
        

        document.getElementById("delete-row").value = JSON.stringify(remove_items);
        index = parseInt(index);
        let ikey = 0;
        for(let k=this.more_rows.length-1;k>=0;k--){
          if(this.more_rows[k]!==undefined){
            let ik = parseInt(this.more_rows[k].key);
            if(ik===index){
              ikey = ik;
            }
          }
        }

        this.more_rows[ikey] = [];
        this.setState({more_rows:this.more_rows});
        setTimeout(function(){
          _this.calculateTotal();
          //_this.calculateReturnTotal();
          let rm = document.querySelectorAll(".item-remove");
          if(rm.length===1){
            for(let i=0;i<rm.length;i++){
              //rm[i].style.display = 'none';
            }
          }else{
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'block';
            }
          }
        },100);
        quantity = parseInt(quantity);
        this.handleUpdateInventoryQuantity('add',quantity,item_id);


        setTimeout(function(){
          _this.ADJUST_BILL_SNO('regular');
        },150);
      }
    }

    handleReturnListItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');
      
      let total_out_avl = 0;
      if(total_out_avl===0){
        let item_id = document.getElementById("ret-item-list-id-"+index).value;
        let item_name = document.getElementById("ret-item-box-"+index).value;
        let product_key = 'pk_'+item_name.replace(/\s+/g, "___");
        if(item_id===""){
          item_id = 0;
        }
        let quantity = document.getElementById("ret-item-quantity-"+index).value;
        let item = {id:item_id,quantity:quantity};
        remove_items.push(item);
        document.getElementById("delete-return-row").value = JSON.stringify(remove_items);
        index = parseInt(index);
        let ikey = 0;
        for(let k=this.return_more_rows.length-1;k>0;k--){
          if(this.return_more_rows[k]!==undefined){
            let ik = this.return_more_rows[k].props.index;
            if(ik===index){
              ikey = k;
            }
          }
          let p_product = '';
          p_product = (this.return_more_rows[k]!==undefined && this.return_more_rows[k].props!==undefined && this.return_more_rows[k].props.items.product_key!==undefined)?this.return_more_rows[k].props.items.product_key:'';
          let iname = (this.return_more_rows[k]!==undefined && this.return_more_rows[k].props!==undefined && this.return_more_rows[k].props.items.item_name!==undefined)?this.return_more_rows[k].props.items.item_name:'';
          if(p_product===product_key){
            //this.return_more_rows.splice(k,1);
            this.return_more_rows[k] = [];
          }
        }
        this.return_more_rows[ikey] = [];
        //this.return_more_rows.splice(ikey,1);

        if(document.getElementById("handleEnableReturnInfo")){
          document.getElementById("handleEnableReturnInfo").click();
        }
        this.setState({return_more_rows:this.return_more_rows});
        setTimeout(function(){
          _this.calculateReturnTotal();
          let rm = document.querySelectorAll(".ret-item-remove");
          if(rm.length===1){
            for(let i=0;i<rm.length;i++){
              //rm[i].style.display = 'none';
            }
          }else{
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'block';
            }
          }
        },100);
        quantity = parseInt(quantity);
        this.handleUpdateInventoryQuantity('add',quantity,item_id);
      }
    }

    handleFinalCalculation(e){
        let id = e.currentTarget.getAttribute('data-id');
        let is_checked = 'excluding';
        if(document.getElementById("iitem-tax-inc")){
          is_checked = document.getElementById("iitem-tax-inc").value;
        }

        let cgst = document.getElementById("iitem-cgst").value;
        let sgst = document.getElementById("iitem-sgst").value;
        let igst = document.getElementById("iitem-igst").value;
        let quantity = document.getElementById("iitem-quantity").value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        let price = e.currentTarget.value;
        if(price===""){
          price = 0;
        }else{
          price = parseFloat(price);
          price = price/quantity;
        }
        if(is_checked==='including'){  
        //let price = document.getElementById("price-hidden").value;

       /* if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }*/
        let discount = 0;
        if(document.getElementById("discount-hidden")){
          discount = document.getElementById("discount-hidden").value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }

        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("iitem-cgst-total").value = this.floating_point(total_cgst);
          document.getElementById("iitem-sgst-total").value = this.floating_point(total_sgst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          //price = (quantity*price)-discount;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          //document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
        
          let gross_amount = GST-(discount/quantity);
         

          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("iitem-igst-total").value = this.floating_point(total_igst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          //price = (quantity*price)-discount;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          //document.getElementById("iitem-total-txt").value =this.floating_point(price);
        } 
      }else{
          
         /* if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = parseFloat(document.getElementById("discount").value);
            total_discount = (quantity*price)*disc/100;
            document.getElementById("discount-hidden").value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
        }*/
      }

      if(this.props.billing_type==="New Purchase" && id!=="price"){
        this.handleLSP(e);
      }
      //this.calculateTotal();
    }
   
    handleIncludeTaxCalculation = (e,id)=>{
        let is_checked = document.getElementById("iitem-tax-inc").value;

        let cgst = document.getElementById("iitem-cgst").value;
        let sgst = document.getElementById("iitem-sgst").value;
        let igst = document.getElementById("iitem-igst").value;
        let quantity = document.getElementById("iitem-quantity").value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("price-hidden").value;
        if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }
        if(this.state.edit_item_selected!=='none'){
          price = document.getElementById("price-hidden").value;
          price = (price!==''?parseFloat(price):0);
        }
        let discount = 0;
        if(document.getElementById("discount-hidden")){
          discount = document.getElementById("discount-hidden").value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }
         
        
        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          
          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);

          total_cgst = (gross_amount*cgst/100)*quantity;
          total_sgst = (gross_amount*sgst/100)*quantity;

          document.getElementById("iitem-cgst-total").value = this.floating_point(total_cgst);
          document.getElementById("iitem-sgst-total").value = this.floating_point(total_sgst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          price = (quantity*gross_amount)+(total_cgst+total_sgst);
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);

          total_igst = (gross_amount*igst/100)*quantity;

          document.getElementById("iitem-igst-total").value = this.floating_point(total_igst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*gross_amount)+total_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else{
          let price = document.getElementById("price-hidden").value;
          if(price===""){
            price = document.getElementById("price").value;
            document.getElementById("price-hidden").value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = document.getElementById("discount").value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden").value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value = this.floating_point(price);
        } 
      }else{
          
        if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("price-hidden").value;
          if(price===""){
            price = document.getElementById("price").value;
            document.getElementById("price-hidden").value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = document.getElementById("discount").value;
            if(disc===""){
              disc = 0;
            }else{
              disc = parseFloat(disc);
            }
            total_discount = (quantity*price)*disc/100;
            //document.getElementById("discount-hidden").value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;

          //price = price+t_cgst+t_sgst+t_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value = this.floating_point(price);
        }
      }
    }

    handleTaxInclude(e){
      let id = e.currentTarget.getAttribute('data-id');
      let is_checked = e.currentTarget.value;
      this.handleIncludeTaxCalculation(e,id);
    }

    handleLSP(e){
      
      let losp = document.getElementById("iitem-lowest-selling-price").value;
     
      let item_name = document.getElementById("iitem-name").value;
      
      if(item_name==="" || item_name==="0"){
        let lospercent = parseInt(document.getElementById("lsp-percent").value);
        if(lospercent!==""){
          let pp = parseFloat(document.getElementById("price").value);
          losp = pp*lospercent/100;
          losp = pp+losp;
          document.getElementById("iitem-lowest-selling-price").value = this.floating_point(losp);
        }
      }
    }

    checkLowestSellingPrice(e){
      let id='';
      let thisid ='';
      let thisval='';

      if(this.props.billing_type==='New Purchase'){
        thisval = document.getElementById("iitem-selling-price").value;
        thisid = 'iitem-selling-price';
        id = document.getElementById("iitem-selling-price").getAttribute('data-id');
      }else if(this.props.billing_type==='New Sale' || this.props.billing_type==='New Sales Return'){
        thisval = document.getElementById("price").value;
        thisid = 'price';
        id = document.getElementById("price").getAttribute('data-id');
      }

      if(thisval!==""){
        thisval = parseFloat(thisval);
        let lsp = document.getElementById("iitem-lowest-selling-price").value;
        
        if(lsp===""){
          if(document.getElementById("iitem-name")){
            let item_id = document.getElementById("iitem-name").value;
            if(item_id!==""){
              item_id = parseInt(item_id);
              let i_opt = JSON.parse(localStorage.getItem('item_options'));
              lsp = (i_opt!==null && i_opt[item_id]!==null && i_opt[item_id]!==undefined && i_opt[item_id].lowest_selling_price!==undefined)?i_opt[item_id].lowest_selling_price:'';
            }
          }
        }

        lsp = parseFloat(lsp);
       
        if(thisval<lsp && this.state.is_checked_lsp===false){
          this.setState({is_checked_lsp:true});
          var element = document.getElementById('ok_cancel_poup');
          let msg = "You are entering selling price lesser than Lowest selling price. The Lowest selling price is "+lsp;
          document.getElementById("message_body").innerHTML = msg;
            
          localStorage.setItem("ok_cancel_txt",lsp);
          localStorage.setItem("target_id",thisid);
          localStorage.setItem("target_index",id);
          localStorage.setItem("target_referer",'check_lsp');

          document.getElementById("ok_cancel_txt").value = lsp;
          document.getElementById("target_id").value = thisid;
          document.getElementById("target_index").value = id;
          document.getElementById("target_referer").value = 'check_lsp';
          element.classList.remove("fade");
          element.style.display="block";
          document.getElementById("keep-button").innerHTML = "Keep "+lsp;
          document.getElementById("close-popup").innerHTML = "Keep "+thisval;
          setTimeout(function(){
            document.getElementById("close-popup").focus();
          },100);
          return false;
          //e.currentTarget.value = lsp;
        }else{
          return true;
        }
      }else{
        return true;
      }
      //this.handleCalculation(e);
    }
    handleRemoveRow(e){
      let id=e.currentTarget.id;
      let total_out_avl = 0;
      if(this.props.billing_type==='New Purchase'){
        total_out_avl = parseInt(document.getElementById("total-avl-out-hidden-"+id).value);

        if(total_out_avl>0){
            var element = document.getElementById('notice_box');
            let msg = "Oops! You can't delete this item";
            document.getElementById("notice_message").innerHTML = msg;
          
            element.classList.remove("fade");
            element.style.display="block";
          
        }
      }
      if(total_out_avl===0){
        
        let item_id = document.getElementById("iitem-name-"+id).value;
        let quantity = document.getElementById("iitem-quantity-"+id).value;
        let item = {id:item_id,quantity:quantity};
        remove_items.push(item);
        document.getElementById("delete-row").value = JSON.stringify(remove_items);
        document.getElementById("iitem-row-"+id).remove();
        let row = document.querySelectorAll(".items-row");
        if(row.length==1){
            let rm = document.querySelectorAll(".item-remove");
            for(let i=0;i<rm.length;i++){
              rm[i].style.display = 'none';
            }
        }
        this.calculateTotal();
        //this.calculateReturnTotal();
      }
    }
    handleCustomItemName(e){
      let idx = e.currentTarget.getAttribute('data_id');
      let thisval = e.currentTarget.value;
      document.getElementById("iitem-box").value = thisval;
    }
    componentWillReceiveProps = (nextProps) =>{

      if(this.state.active_tab!==nextProps.billing_type){
          this.setState({active_tab:nextProps.billing_type});
          this.more_rows = [];
          this.setState({more_rows:this.more_rows});
          let rm = document.querySelectorAll(".item-remove");
          for(let i=0;i<rm.length;i++){
            rm[i].style.display = 'block';
          }
          this.setState({is_new_item:0});
          setTimeout(function(){
            _this.calculateTotal();
            //_this.calculateReturnTotal();
          },100);
      }
    }

    componentDidMount(){
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
      }
        setTimeout(function(){
          if(localStorage.getItem('is_auto_generate_barcode')=="true"){
            auto_generate_barcode = 'Auto Generate';
            _this.setState({is_auto_generate_barcode:true})
              if(document.getElementById("iitem-barcode")){
                document.getElementById("iitem-barcode").setAttribute('disabled',true);
                document.getElementById("iitem-barcode").removeAttribute('tabindex');
              }
          } 
        },1500);
       
       let more_row_len = document.getElementsByClassName("items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
       }
        let i_opt = localStorage.getItem('item_options');
        let purchase_i_opt = localStorage.getItem('purchase_item_options');
        
        //if(i_opt===null){
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              active_tab :this.props.billing_type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/inventory/get_inventory_items';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            let item_options = [];
            let purchase_item_options = [];
            let store_item_options = [];
            let store_purchase_item_options = [];
            let variable_purchase_item_options = [];
            cursor.map( (value, key) => {
                let id = value.inventory_id;
                let stat_lbl = '';
                if(value.inventory_status==='Draft'){
                  stat_lbl=' (Inactive)';
                }
                let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                let static_item_name = value.item_name;

                let sku   = value.sku; 
                let total = value.total;
                let bundle_text= value.bundle_text;
                let barcode_name= value.barcode_name;
                let alias_barcode= value.alias_barcode;
                let btext = this.state.bundle_text;
                if(btext===''){
                  this.setState({bundle_text:bundle_text});
                }
                if(sku===null){
                  sku = "none";
                }
                if(barcode_name===null){
                  barcode_name = "none";
                }

                if(value.inventory_status==='Active'){
                  store_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id};
                }
                store_purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id};

                if(value.has_variation===false){
                  if(value.inventory_status==='Active'){
                    item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id};
                  }
                  purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id};
                }

                if(value.is_variation===false){
                  variable_purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id};
                }
            });
            
            localStorage.setItem('item_options',JSON.stringify(store_item_options));
            localStorage.setItem('purchase_item_options',JSON.stringify(store_purchase_item_options));
            this.setState({ 'item_options': item_options}); 
            this.setState({ 'purchase_item_options': purchase_item_options}); 
            this.setState({ 'variable_purchase_item_options': variable_purchase_item_options}); 
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      /* }else{  
          this.setState({ 'item_options': JSON.parse(i_opt) }); 
          this.setState({ 'purchase_item_options': JSON.parse(purchase_i_opt) }); 
       }*/
        if(this.props.list_items){
          this.more_rows = [];
          let iitems = this.props.list_items;
          let item_len = iitems.length;
          for(let i=0;i<item_len;i++){
            let items = iitems[i];

            let item_name = items.item_name;
            let product_name = items.product_name;
            let iiname = item_name;
            if(items.parent_id===0 || items.parent_id===null || items.parent_id===""){
              iiname = item_name;
            }
            items.item_name= iiname;
            items.pp_tax_type = items.is_tax_inc;
            let key = i;
            this.more_rows.push(<QuotationItemList count={item_len} type="more" key={key} index={key} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleListItemEdit.bind(this)} listItemRemove={this.handleListItemRemove.bind(this)}/>);
          }
          this.setState({more_rows:this.more_rows});
        }

        if(this.props.return_list_items){
          this.return_more_rows = [];
          let iitems = this.props.return_list_items;
          let item_len = iitems.length;
          for(let i=0;i<item_len;i++){
            let items = iitems[i];

            let item_name = items.item_name;
            let product_name = items.product_name;
            let iiname = item_name;
            if(items.parent_id===0 || items.parent_id===null || items.parent_id===""){
              iiname = item_name;
            }
            items.item_name= iiname;
            items.pp_tax_type = items.is_tax_inc;
            let key = i+1;
            this.return_more_rows.push(<ReturnBillingItemList count={item_len} type="more" key={key} index={key} billing_type={this.props.billing_type} items={items} listItemEdit={this.handleReturnListItemEdit.bind(this)} listItemRemove={this.handleReturnListItemRemove.bind(this)}/>);
          }
          this.setState({return_more_rows:this.return_more_rows});
        }
        
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:"'allow_negative_inventory','barcode_name_on_the_bill_view','item_name_on_the_bill_view','regenerate_barcode_opt'",
        }
        let sapi = app_api+'/get_settings';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.allow_negative_inventory!==undefined){
              let ani = resp.allow_negative_inventory;
              this.setState({allow_negative_inventory:ani});
            }
            if(resp.barcode_name_on_the_bill_view){
              let ps = resp.barcode_name_on_the_bill_view;
              this.setState({barcode_name_on_the_bill_view:ps});
            }
            if(resp.item_name_on_the_bill_view){
              let ps = resp.item_name_on_the_bill_view;
              this.setState({item_name_on_the_bill_view:ps});
            } 
            if(resp.regenerate_barcode_opt){
              let ps = resp.regenerate_barcode_opt;
              this.setState({regenerate_barcode_opt:ps});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    calculateTotal(){
      var item_sub_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 

      let sub_total = 0;
      for (var i = 0; i < item_sub_total.length; i++) { 
          if(item_sub_total[i].value!=='' && item_sub_total[i].value!=="0"){
            let quan = 0;
            if(item_quantity[i].value!==''){
              quan = parseInt(item_quantity[i].value);
            }
            let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
            sub_total = sub_total + (quan*parseFloat(i_s_total));
          }
      } 

      let quantity = parseInt(document.getElementById("total-quantity").innerHTML.replace(/,/g , ""));
      document.getElementById("sub-total").innerHTML = this.currency_format(sub_total); 

      let item_tax = 0;
      let cgst_total =0;
      let sgst_total =0;
      let igst_total =0;
      var item_total_tax = document.querySelectorAll('.item-cgst-total'); 
      for (var i = 0; i < item_total_tax.length; i++) { 
           let did = item_total_tax[i].getAttribute('data-id');
           let cgst = item_total_tax[i].value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
           cgst_total = cgst_total+cgst;
           sgst_total = sgst_total+sgst;
           igst_total = igst_total+igst;

           item_tax = item_tax + (cgst+sgst+igst);
      } 
      document.getElementById("total-cgst").innerHTML = this.currency_format(cgst_total);
      document.getElementById("total-sgst").innerHTML = this.currency_format(sgst_total);
      document.getElementById("total-igst").innerHTML = this.currency_format(igst_total);
      document.getElementById("total-tax").innerHTML = this.currency_format(item_tax);
      document.getElementById("total-after-tax").innerHTML = this.currency_format(item_tax);

      var item_final_total = document.querySelectorAll('.item-total-txt'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!=='' && item_final_total[i].value!=='0'){
              total_unit++;
              item_total = item_total +item_final_total[i].value;
            }
      } 
      //document.getElementById("s-total").innerHTML = this.currency_format(item_total);
      
       let cont_disc = localStorage.getItem('discount');
       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }


      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
      let item_discount = 0;
      let i_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           let did = item_total_discount[i].getAttribute('data-id');
        
           let cgst = document.getElementById("item-cgst-total-"+did).value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
        
          let titax = (cgst+sgst+igst);

          item_discount = item_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
          if(titax===0){
            i_discount = i_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
          }
           
      } 
      if(document.getElementById("total-discount")){
        document.getElementById("total-discount").innerHTML = this.currency_format(item_discount);
       
        if((this.props.billing_type==='New Purchase Return' || this.props.billing_type==='New Sales Return') && item_discount>0){
            document.getElementById("nsr-npr-discount").style.display = '';
        }
      }

       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
       
       let discount_on_bill = (sub_total)-i_discount;
       
        let gdisc =0;
       if(discount_opt==="rs"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("global_discount").value = this.floating_point(gdisc);
          document.getElementById("global_discount_lbl").innerHTML = this.floating_point(gdisc);
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
         // item_total = parseFloat(discount_on_bill)-gdisc;
       }else if(discount_opt==="per"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+this.currency_format(gdisc)+'</span>';
          document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+this.currency_format(gdisc)+'</span>';
          document.getElementById("global_discount").value = this.floating_point(cont_disc);
          document.getElementById("global_discount_lbl").innerHTML = this.floating_point(cont_disc);
       }
      
       let total_before_tax = discount_on_bill-gdisc;
        if(this.props.billing_type==='New Purchase'){
          //total_before_tax = total_before_tax-item_discount;
        }
       document.getElementById("total-before-tax").innerHTML = this.currency_format(total_before_tax);

       let total_after_tax = total_before_tax+item_tax;
      document.getElementById("s-total").innerHTML = this.currency_format(total_after_tax);

      let after_round = Math.round(total_after_tax)-total_after_tax;
      
      if(after_round>=0){
        after_round = Math.ceil(total_after_tax)-total_after_tax;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = total_after_tax-Math.floor(total_after_tax);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      if(round_off!==""){
          if(round_off_opt==='-'){
              item_total = parseFloat(total_after_tax)-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = parseFloat(total_after_tax)+parseFloat(round_off);
          }
      }

      
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("redeem_original_amount")){
        let redeem_original_amount = document.getElementById("redeem_original_amount").value;
        if(redeem_original_amount!=="" && redeem_original_amount!=="0"){
          redeem_original_amount = parseFloat(redeem_original_amount)
          if(redeem_original_amount>item_total){
            redeem_original_amount = item_total;
          }
          if(document.getElementById("redeem-total")){
            document.getElementById("redeem-total").innerHTML = this.currency_format(redeem_original_amount);
          }
        }
      }

      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.props.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.props.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }

      

      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      document.getElementById("total-unit").innerHTML = this.currency_format(total_unit);
     /* if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      let tba = document.querySelectorAll('.total-bill-amount');
      for(let i=0;i<tba.length;i++){
        tba[i].value = '';
      }

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      } 
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = 0;
      }
      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }
      
      if(document.getElementById("handleChangePayBtnStatus")){
        document.getElementById("handleChangePayBtnStatus").click();
      }
       let quant_field = document.querySelectorAll(".item-quantity");
      let total_quantity = 0;
      
      for(let i=0;i<quant_field.length;i++){
          if(quant_field[i].value!==''){
            total_quantity = total_quantity+parseInt(quant_field[i].value);
          }
      }
      
      document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
      document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
        
      document.querySelector('input[name="global_discount"]:checked').click();
    }
    calculateReturnTotal(){
      var item_sub_total = document.querySelectorAll('.ret-item-sub-total'); 
      var item_quantity = document.querySelectorAll('.ret-item-quantity'); 
      if(item_sub_total.length>0){
        let sub_total = 0;
        for (var i = 0; i < item_sub_total.length; i++) { 
            if(item_sub_total[i].value!==''){
              let quan = 0;
              if(item_quantity[i].value!==''){
                quan = parseInt(item_quantity[i].value);
              }
              let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
              sub_total = sub_total + (quan*parseFloat(i_s_total));
            }
        } 
        document.getElementById("return-subtotal").innerHTML = this.currency_format(sub_total); 

        let item_tax = 0;
        let cgst_total =0;
        let sgst_total =0;
        let igst_total =0;
        var item_total_tax = document.querySelectorAll('.ret-item-cgst-total'); 
        for (var i = 0; i < item_total_tax.length; i++) { 
             let did = item_total_tax[i].getAttribute('data-id');
             let cgst = item_total_tax[i].value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
             cgst_total = cgst_total+cgst;
             sgst_total = sgst_total+sgst;
             igst_total = igst_total+igst;

             item_tax = item_tax + (cgst+sgst+igst);
        } 

        var item_final_total = document.querySelectorAll('.ret-item-total-txt'); 
        let item_total = 0;
        let total_unit = 0;
        for (var i = 0; i < item_final_total.length; i++) { 
              if(item_final_total[i].value!=='' && item_final_total[i].value!=='0'){
                total_unit++;
                item_total = item_total+parseFloat(item_final_total[i].value);
              }
        } 
    
        var item_total_discount = document.querySelectorAll('.ret-item-discount-hidden'); 
        let item_discount = 0;
        let i_discount = 0;
        for (var i = 0; i < item_total_discount.length; i++) { 
             let did = item_total_discount[i].getAttribute('data-id');
          
             let cgst = document.getElementById("ret-item-cgst-total-"+did).value;
             let sgst = document.getElementById("ret-item-sgst-total-"+did).value;
             let igst = document.getElementById("ret-item-igst-total-"+did).value;
            
             cgst = parseFloat((cgst!=''?(cgst):0));
             sgst = parseFloat((sgst!=''?(sgst):0));
             igst = parseFloat((igst!=''?(igst):0));
             let iigst = document.getElementById("ret-item-igst-"+did).value;
             let isgst = document.getElementById("ret-item-sgst-"+did).value;
             let icgst = document.getElementById("ret-item-cgst-"+did).value;

             if(iigst==="" || iigst===0 || iigst==='0'){
              igst = 0;
             }
             if(isgst==="" || isgst===0 || isgst==='0'){
              sgst = 0;
             }
             if(icgst==="" || icgst===0 || icgst==='0'){
              cgst = 0;
             }
          
            let titax = (cgst+sgst+igst);
        } 

       
        let grand_total = document.getElementById("final-total-text").value;
        let final_total = document.getElementById("total-before-return-txt").value;
        let birow = document.querySelectorAll(".billing-item-row");
        if(birow.length>0 && (final_total==="" || final_total==="0")){
          document.getElementById("total-before-return-txt").value = this.floating_point(grand_total);
          document.getElementById("total-before-return").innerHTML = this.currency_format(grand_total);
          final_total = grand_total;
        }

        if(final_total!=="" && final_total!=="0"){
          final_total = parseFloat(final_total)-item_total;
        }else{
          final_total = item_total;
          document.getElementById("total-before-return-txt").value = 0;
        }

        document.getElementById("return-total").innerHTML = this.currency_format(item_total);
        document.getElementById("final-return-total-text").value = this.floating_point(item_total);

        document.getElementById("top-final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total").innerHTML = this.currency_format(final_total);
        document.getElementById("final-total-text").value = this.floating_point(final_total);
        if(document.getElementById("total-bill-amount")){
          document.getElementById("total-bill-amount").innerHTML = this.currency_format(final_total);
        }
        if(document.getElementById("bill-pay-pop-title")){
          document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(final_total);
        }
        document.getElementById("return-total-unit").innerHTML = this.currency_format(total_unit);
    

        let quant_field = document.querySelectorAll(".ret-item-quantity");
        let total_quantity = 0;
        
        for(let i=0;i<quant_field.length;i++){
            if(quant_field[i].value!==''){
              total_quantity = total_quantity+parseInt(quant_field[i].value);
            }
        }
        document.getElementById("return-total-quantity").innerHTML = this.currency_format(total_quantity);
      }else{
        if(document.getElementById("handleEnableReturnInfo")){
          document.getElementById("handleEnableReturnInfo").click();
        }
        document.querySelector('input[name="global_discount"]:checked').click();
      }
    }
    handleCalculation(e){
      
      let thisval = e.currentTarget.value;

      let data_target = e.currentTarget.getAttribute("data-target");
      let data_id = e.currentTarget.getAttribute('data-id');
      let id = e.currentTarget.id;
      let contact_stat = localStorage.getItem('state');

      let item_id = document.getElementById("iitem-name").value;
      let avl =0;
      let av =0;
      let i_total_quan =parseInt(thisval);

      if(data_target==='iitem-quantity' && (item_id!=="" && item_id!=="0")){
          item_id = parseInt(item_id);
          let option_available = JSON.parse(localStorage.getItem("item_options"));
          let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
          
          if(purchase_option_available!==null && purchase_option_available[item_id]!==undefined && purchase_option_available[item_id]!==null){
             avl = purchase_option_available[item_id].availability;
             av = purchase_option_available[item_id].old_available;

             let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.billing_type, purchase_option_available[item_id]);
            if(get_diff_barcode_inv_availability>=0){
              avl = get_diff_barcode_inv_availability;
            }

            let old_available = av;
            let li_item_ids = [];
            let item_ids = document.querySelectorAll(".billing-item-row .iitem-ids");
            if(li_item_ids[item_id]===undefined){
              li_item_ids[item_id] = [];
              li_item_ids[item_id].push(parseInt(thisval));
            }
            for(let i=0;i<item_ids.length;i++){
                let iid = item_ids[i].value;
                let did = item_ids[i].getAttribute('data-id');
                
                let i_quantity = document.getElementById("item-quantity-"+did).value;

                if(li_item_ids[iid]===undefined){
                  li_item_ids[iid] = [];
                }
                if(i_quantity!==""){
                  i_quantity = parseInt(i_quantity);
                  li_item_ids[iid].push(i_quantity);
                }
            }
            

            let item_available = li_item_ids[item_id];
            
            av = parseInt(av);
            
            document.getElementById("current-available").value = av;
            thisval = parseInt(thisval);
            if(item_available!==undefined){
              for(let i=0;i<item_available.length;i++){
                //i_total_quan = i_total_quan+item_available[i];
              }
             /* if(this.state.is_new_item!==thisval){
                this.setState({is_new_item:thisval});
                let availability = old_available-i_total_quan;
               
                if(option_available!==null && option_available[item_id]!==undefined){
                  option_available[item_id].availability = availability;
                }
                purchase_option_available[item_id].availability = availability;
                let static_item_name = purchase_option_available[item_id].static_item_name;
                let inventory_status = purchase_option_available[item_id].inventory_status;
                let stat_lbl = '';
                if(inventory_status==='Draft'){
                  stat_lbl=' (Inactive)';
                }
                let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
                purchase_option_available[item_id].value2 = new_item_name;
                if(option_available[item_id]!==undefined){
                  option_available[item_id].value2 = new_item_name;
                  localStorage.setItem("item_options",JSON.stringify(option_available));
                  this.setState({ 'item_options': option_available});
                }
                localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
                this.setState({ 'purchase_item_options': purchase_option_available}); 
              }*/
            }
          }
          av = document.getElementById("current-available").value;
          av = parseInt(avl);
          let item_type = '';
         
          if(i_total_quan>av && this.state.allow_negative_inventory===false){
            if(this.props.billing_type==='New Sale' && item_type!=='sales_return'){
              var element = document.getElementById('notice_box');
              let msg = "You are entering quantity more than availability, total availability is "+avl;
              document.getElementById("notice_message").innerHTML = msg;
              //document.getElementById("iitem-quantity").value = 0;
              element.classList.remove("fade");
              element.style.display="block";
              document.getElementById("item_availabe_hidden").value = avl;
              document.getElementById("item_index_hidden").value = data_id;
              document.getElementById("close-notice").focus();
            }
          }
      }


      let quantity = 1;
      if(document.getElementById("iitem-quantity")){
        quantity = document.getElementById("iitem-quantity").value;
        quantity = (quantity!==''?parseFloat(quantity):1);
        
      }
      
      let bundle = document.getElementById("iitem-bundle-quantity").value;

      let quan = quantity;
      let unitname = '';
      if(document.getElementById("iitem-unit-name")){
        unitname = document.getElementById("iitem-unit-name").value;
        unitname = unitname.toLowerCase();
      }
      let unit_elem = '';
      let uval = '';
      if(document.getElementById("iitem-unit")){
        unit_elem = document.getElementById("iitem-unit")
        uval = unit_elem.value;
      }
      let un = '';
      if(uval!==""){
          let unit_name = unit_elem.options[unit_elem.selectedIndex].text;
          un = unit_name.toLowerCase();
      }else{
          un = 'pcs';
      }
      if(unitname===""){
        unitname = 'dzn';
      }

      if(unitname==='pcs' && un==='dzn'){
        quan = quan*12;
      }

      if(unitname==='dzn' && un==='pcs'){
        if(bundle>0){
           bundle = bundle*12;
        }
      }

      let bundle_quantity = 0;
      
      if(bundle<=quan && bundle>0){
          bundle_quantity = quan/bundle;
          if(!Number.isInteger(bundle_quantity)){
              bundle_quantity = 0;
          }
      }

      let bundle_text = document.getElementById("iitem-bundle-text").value;

      if(bundle_quantity>0){
        document.getElementById('bundle_quantity').innerHTML = bundle_quantity+' '+bundle_text;
      }else{
        document.getElementById('bundle_quantity').innerHTML = '';
      }
      let price = 0;
      if(document.getElementById("price")){
        price = document.getElementById("price").value;
        price = (price!==''?parseFloat(price):0);
      }

      if(e.currentTarget.id==="price"){
        document.getElementById("price-hidden").value = e.currentTarget.value;
      }

      if(this.state.edit_item_selected!=='none'){
        if(document.getElementById("price-hidden")){
          price = document.getElementById("price-hidden").value;
          price = (price!==''?parseFloat(price):0);
        }
      }
      let hidden_price = document.getElementById("price-hidden").value;

      let discount = 0;
      if(document.getElementById("discount")){
        discount = document.getElementById("discount").value;
        if(discount===""){
          discount = 0;
        }
      }
      
      let pp_tax = 0;
      if(document.getElementById("iitem-gst")){
        pp_tax = document.getElementById("iitem-gst").value.replace('GST','');
        pp_tax = pp_tax.replace('%','');
        document.getElementById("iitem-gst").value = pp_tax.trim();
        pp_tax = parseFloat(pp_tax);
      }
      
       let igst = 0;
       let sgst = 0;
       let cgst = 0;
      if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }
    if(document.getElementById("iitem-sgst")){
      document.getElementById("iitem-sgst").value = sgst;
      document.getElementById("iitem-sgst-hidden").value = sgst;
    }
    if(document.getElementById("iitem-cgst")){
      document.getElementById("iitem-cgst").value = cgst;
      document.getElementById("iitem-cgst-hidden").value = cgst;
    }
    if(document.getElementById("iitem-igst")){
      document.getElementById("iitem-igst").value = igst;
      document.getElementById("iitem-igst-hidden").value = igst;
    }

      if(document.getElementById("iitem-igst")){
        igst = document.getElementById("iitem-igst").value;
        igst = (igst!=='')?parseInt(igst):0;
      }      

      
      if(document.getElementById("iitem-sgst")){
        sgst = document.getElementById("iitem-sgst").value;
        sgst = (sgst!=='')?parseInt(sgst):0;
      }      

      if(document.getElementById("iitem-cgst")){
        cgst = document.getElementById("iitem-cgst").value;
        cgst = (cgst!=='')?parseInt(cgst):0;
      }      
      let tax = cgst+sgst+igst;
      
      let cal_discount = ((hidden_price*quantity)*discount)/100;
      let item_grand_total = (price*quantity)-cal_discount;
      
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      document.getElementById("iitem-igst-total").value = this.floating_point(igst_tax);
      document.getElementById("iitem-sgst-total").value = this.floating_point(sgst_tax);
      document.getElementById("iitem-cgst-total").value = this.floating_point(cgst_tax);

      if(document.getElementById("discount")){
        document.getElementById("discount-hidden").value = this.floating_point(cal_discount);
      }
      
      let final_amount = item_grand_total+cal_tax;

      document.getElementById("iitem-total").innerHTML = this.currency_format(final_amount);
      document.getElementById("iitem-total-txt").value = this.floating_point(final_amount);

      if(data_target==='discount'){
        let lsp = document.getElementById("iitem-lowest-selling-price").value;
      }

      let item_name = document.getElementById("iitem-name").value;
      if((e.currentTarget.id==='iitem-total-txt' || e.currentTarget.id==='iitem-gst' || e.currentTarget.id==='discount' || e.currentTarget.id==='iitem-quantity') && (this.props.billing_type==="New Purchase" || this.props.billing_type==="New Sale" || this.props.billing_type==="New Sales Return" || this.props.billing_type==="New Purchase Return")){
        //if(this.state.list_item_type!=='sales_return' && this.state.list_item_type!=='purchase_return'){
          this.handleIncludeTaxCalculation(e,data_id);
        //}
      }

      if(this.props.billing_type==="New Purchase" && id!=="price"){
        this.handleLSP(e);
      }
    }
    handleCount(e){
      var item_rows = document.querySelectorAll('.item-name-field'); 
        let number_of_item = 0;
        for (var i = 0; i < item_rows.length; i++) { 
            let i_val = item_rows[i].value;
            if(i_val!=''){
              number_of_item++;
            }
        } 
        document.getElementById("total-unit").innerHTML = number_of_item;

        let quant_field = document.querySelectorAll(".item-quantity");
        let total_quantity = 0;
        for(let i=0;i<quant_field.length;i++){
            if(quant_field[i].value!==''){
              total_quantity = total_quantity+parseInt(quant_field[i].value);
            }
        }

        document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
        document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
    }

    handleBarcodeFocus = (e) =>{
      e.currentTarget.select();
    }

    handleSetDefaultCategory = e =>{
      let item_category_name = localStorage.getItem('item_category_name');
      let item_category = localStorage.getItem('item_category');
      this.setState({defaultCategory:item_category_name});
      this.setState({defaultCategoryID:item_category});
      localStorage.removeItem('item_category_name');
      localStorage.removeItem('item_category');
    }

    render() {
        let isSticky = '';
        let isActionButton = '';
        let abfs = '';
        if(this.state.isMobile===false){
            isSticky = 'sticky-add-item-panel '+this.props.billing_type.replaceAll(" ",'-')+" "+this.state.list_item_type;
            isActionButton = 'action-button';
            abfs = 'abfs';
        }
        if(localStorage.getItem('is_auto_generate_barcode')=="true"){
          auto_generate_barcode = 'Auto Generate';
        }
        return (
          <>
          <input className="hide" type="text" id="deleted_var_items" />
          <label onClick={this.addMoreItem} id="add-item-to-list" className="hide"></label>
          <label onClick={this.handleSetDefaultCategory} id="handleSetDefaultCategory" className="hide"></label>
          <form id="new-item-form">
          <div className={"main-content border-bottom "+isSticky}>
            <div className={"inner-content list-item-add-blk "+this.props.billing_type.replaceAll(" ",'-')} id="list-item-add-blk">
              <div className="row">
                  <div className={"col-md-2 "+abfs}>
                    <span className="fe-title">ITEM NAME</span>
                    <Autocomplete
                      options={this.state.item_options}
                      data_target="billing"
                      title="Start typing SKU or name..."  
                      id={"iitem-name"}
                      className="auto-comp-opt"
                      data_id={this.props.index}
                      active_tab={this.props.billing_type}
                      input_id={"iitem-box"}
                      item_info={this.props.items}
                      target="iitem-box"
                      tabindex={'1'+this.props.index}
                      position='top'
                    />
                    <p className="error" id="no-item-found-error"></p>
                   
                    <i className="iitem-desc-txt hide" id={"iitem-desc-txt"}></i>
                    
                  </div>
                  {(this.state.list_item_type!=='sales_return' && this.state.list_item_type!=='purchase_return') &&
                  <>
                  <div className={(this.props.billing_type==='New Purchase')?"col-md-1 w-50 mrbtm-10 "+abfs:"mrbtm-10 hide"}>
                    <span className="fe-title">DESCRIPTION</span>
                    {(this.props.billing_type==='New Purchase') &&
                      <input type="text" className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc" tabindex={'4'+this.props.index}/>
                    }
                    {(this.props.billing_type!=='New Purchase') &&
                      <input type="text" className="form-control" placeholder="" data-id={this.props.index} id={"iitem-desc"}  target="iitem-desc"/>
                    }
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-list-id"}  target="iitem-list-id"/>
                  
                  </div> 
                  
                  <div className={(this.props.billing_type==='New Purchase')?"col-md-1 w-50 mrbtm-10 "+abfs:"mrbtm-10 hide"}>
                    <span className="fe-title">HSN/SAC</span>
                    <input type="text" className="form-control" placeholder="" id={"iitem-hsn"} data-id={this.props.index}  target="iitem-hsn"/>
                  </div> 
                  <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">QTY.</span>
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-quantity"} data-target="iitem-bundle-quantity" target="iitem-bundle-quantity"/>
                    <input type="text" className="hide form-control" id={"price-hidden"} data-id={this.props.index} data-target="iprice-hidden"target="price-hidden"/>
                    
                    <input type="text" className="hide" data-id={this.props.index} id={"iitem-bundle-text"} data-target="iitem-bundle-text" target="iitem-bundle-text" />

                    <input type="number" className="form-control iitem-quantity " id={"iitem-quantity"} placeholder="" onKeyUp={this.handleCalculation}  data-id={this.props.index} data-target="iitem-quantity"  target="iitem-quantity" tabindex={'6'+this.props.index}/>
                    <input type="text" className="hide" id={"current-available"}/>
                    {(this.props.items && this.props.items.bundle_quantity>0)?
                      <p className="bundle_quantity" id={"bundle_quantity"} >{this.props.items.bundle_quantity} {this.state.bundle_text}</p>
                    :<p className="bundle_quantity" id={"bundle_quantity"}></p>
                    }
                  </div>
                  <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">UNIT</span>
                    <input type="text" className="hide form-control iitem-unit-name" placeholder=""  id={"iitem-unit-name"} data-id={this.props.index} data-target="iitem-unit-name" />
                    <ProductUnits show="dropdown" data_id={this.props.index} id={'iitem-unit'} default={(this.props.items && this.props.items.unit_id)?this.props.items.unit_id:''} target="iitem-unit" tabindex={'7'+this.props.index}/ >
                  </div>
                  
                  
                <div className={"col-md-1 w-50 "+abfs}>
                <span className="fe-title">PRICE</span>
                
                <div style={{display:'none'}}>
                    <span className="iitem-availability"  id={"iitem-availability"} data-id={this.props.index} data-target="iitem-availability">{(this.props.items && this.props.items.availability)?this.props.items.availability:'---'}</span>
                </div> 
                
                <input type="number" className="form-control iitem-sub-total " placeholder=""  id={"price"} data-id={this.props.index} data-target="price" target="price" tabindex={'8'+this.props.index} onBlur={this.checkLowestSellingPrice} onChange={this.handleChangeText}/>
                <input type="text" className="hide form-control iitem-lowest-selling-price" placeholder=""  id={"iitem-lowest-selling-price"} data-target="iitem-lowest-selling-price"/>
                </div>
                
                  
                  
                <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">DISC%</span>
                    <input type="number" className="form-control iitem-discount" placeholder="%" onKeyUp={this.handleCalculation} id={"discount"} data-id={this.props.index} data-target="idiscount" target="idiscount" tabindex={'12'+this.props.index}/>
                    
                    <input type="text" className="hide iitem-discount-hidden" id={"discount-hidden"}/>
                </div>
                 
                 
                  <div className={"col-md-1 w-50 "+abfs}>
                      <span className="fe-title">GST %</span>
                      <input list={"pp_tax_"+this.props.index}  className="form-control iitem-gst  " placeholder="%" id={"iitem-gst"} data-id={this.props.index}  data-target="iitem-gst" onChange={this.handleCalculation} target="igst" tabindex={'13'+this.props.index}/>
                    <datalist id={"pp_tax_"+this.props.index}>
                      {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                         return (
                          <option key={index}>{value.value}</option>
                        )
                       }) : '' }
                    </datalist>
                    <input type="text" className="hide form-control iitem-cgst" placeholder="%" id={"iitem-cgst"} data-id={this.props.index}  data-target="iitem-cgst" onKeyUp={this.handleCalculation} target="icgst" />
                    <input type="text" id={"iitem-cgst-hidden"} data-id={this.props.index} className="iitem-cgst-hidden hide" data-target="iitem-cgst-hidden"/>

                    <input type="text" id={"iitem-cgst-total"} data-id={this.props.index} className="iitem-cgst-total hide" data-target="iitem-cgst-total"/>
                 
                    <input type="text" className="hide form-control iitem-sgst" placeholder="%" id={"iitem-sgst"} data-id={this.props.index}  data-target="iitem-sgst" onKeyUp={this.handleCalculation} target="isgst" />
                    <input type="text" className="hide iitem-sgst-hidden" id={"iitem-sgst-hidden"} data-id={this.props.index}  data-target="iitem-sgst-hidden"/>

                    <input type="text" className="hide iitem-sgst-total" id={"iitem-sgst-total"} data-id={this.props.index}  data-target="iitem-sgst-total"/>
                
                    <input type="text" className="hide form-control iitem-igst" placeholder="%" id={"iitem-igst"} data-id={this.props.index}  data-target="iitem-igst"  onKeyUp={this.handleCalculation} target="iigst" />
                    <input type="text" className="hide iitem-igst-hidden" id={"iitem-igst-hidden"} data-id={this.props.index}  data-target="iitem-igst-hidden"/>

                    <input type="text" className="hide iitem-igst-total" id={"iitem-igst-total"} data-id={this.props.index}  data-target="iitem-igst-total"/>
                  </div>
                
                    <div className={"col-md-1 w-50 "+abfs}>
                    <span className="fe-title">TAX TYPE</span>
                    
                    <select className="form-control" data-target="iitem-tax-inc" data-id={this.props.index} id={"iitem-tax-inc"} onChange={this.handleTaxInclude} tabindex={'14'+this.props.index} target="itax-inc">
                    
                    
                        <option value="including">Inc GST</option>
                        <option value="excluding">Exc GST</option>
                    
                    </select>
                    </div>
                

                  <div className={"col-md-1 "+abfs}>
                    <span className="fe-title">TOTAL</span>
                  
                   
                      <span className="iitem-total hide" id={"iitem-total"} data-id={this.props.index}  data-target="iitem-total"></span>
                      <input type="number" className="form-control iitem-total-txt " id={"iitem-total-txt"} data-id={this.props.index}  data-target="iitem-total-txt" disabled/>
                   
                  </div>
                  </>
                  }
                  <div className={"col-md-1 add-item-action-but "+abfs+" "+isActionButton} style={{marginTop:'9px'}}>
                    <button type="button" className="btn btn-primary more-item-btn" onClick={this.addMoreItem}>
                      <FontAwesomeIcon icon={faCheckCircle} style={{color:'white'}}/>
                    </button>
                    <button type="button" className="btn btn-danger" id="handleResetField" onClick={this.handleResetField}>
                      <FontAwesomeIcon icon={faTimes} style={{color:'white',margin:'0px'}}/>
                    </button>
                    <span id="handleChangeListTypeOnFly" onClick={this.handleChangeListTypeOnFly}></span>
                  </div>
                  {(this.props.billing_type==='New Purchase Return' || this.props.billing_type==='New Sales Return') && 
                  <div className="col-md-2"></div>
                  }
                
              </div>
            </div>
          </div>
          {(this.more_rows.length>0) &&
          <div className="table-responsive">
             <table id="list-item-table" className="table item-table border-bottom bilist pen-in-li">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="item-serial">S.NO.</th>
                    <th scope="col">ITEM NAME</th>
                    <th scope="col" className="hide">DESC</th>
                    {this.props.billing_type==='New Purchase' &&
                      <th scope="col" className="text-center">HSN/SAC</th>
                    }
                    {this.props.billing_type!=='New Purchase' &&
                      <th scope="col" className="text-center hide">HSN/SAC</th>
                    }
                    <th scope="col" className="text-center">QTY</th>
                    <th scope="col" className="text-center">UNIT</th>
                    {this.props.billing_type==='New Sale' &&
                    <>
                      <th scope="col" style={{display:'none'}}>AVAIL.</th>
                      <th scope="col" className="text-center">PRICE</th>
                    </>
                    }
                    {this.props.billing_type==='New Purchase' &&
                    <>
                      <th scope="col" className="text-center">P. PRICE</th>
                     </>
                    }
                    {this.props.billing_type==='New Sales Return' &&
                      <>
                        <th scope="col" className="text-center">S. PRICE</th>
                      </>
                    }
                    {this.props.billing_type==='New Purchase Return' &&
                      <>
                        <th scope="col" className="text-center">P. PRICE</th>
                      </>
                    }
                    {(this.props.billing_type==='New Purchase'||this.props.billing_type==='New Sale'||this.props.billing_type==='New Sales Return' ) && 
                    <>
                      <th scope="col" className="text-center">DISC%</th>
                    </>
                    }
                    <th scope="col" className="text-center">GST%</th>
                    {(this.props.billing_type==='New Purchase' || this.props.billing_type==='New Sale') && 
                      <>
                        <th scope="col" className="text-center">TAX TYPE</th>
                      </>
                    }
                    {this.props.billing_type==='New Purchase' &&
                    <>
                      <th scope="col" className="text-center">S. PRICE</th>
                      <th scope="col" className="text-center">DISC%</th>
                      <th scope="col" className="text-center">GST%</th>
                      <th scope="col" className="text-center">TAX TYPE</th>
                      <th scope="col" className="text-center">CATEGORY</th>
                     </>
                    }
                    <th scope="col">TOTAL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody id="items-body">
                    {this.more_rows}
                </tbody>
              </table>
             </div>
           }
             </form>
           <DifferentBarcodeInventoryItems active_tab={this.props.billing_type}/>
          </>
        );
    }
}