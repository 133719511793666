import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import axios from 'axios';
import ModalBox from "../modal-box";
import ExpenseTypes from "../expense-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLink, faPlusSquare,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import utils from "../../utils";
let {enc_key,app_api,user_info,states} = require('../../library.js');
export default class LinkStores extends Component {
     constructor() {
      super();
      this.state = {
        error_msg : '',
        loading : 'Loading...',
        linked_success : false,
        is_link_store : false,
        linked_stores : []
      }
      this.is_access = check_access.is_access.bind(this);
    }
    
    openModal = (event) =>{
      this.setState({is_link_store:true});
    }

    handleClosePopup = ()=>{
        this.setState({is_link_store:false});
    }

    handleLinkStore = () =>{
        document.getElementById('link-store-btn').classList.add("hide");
        document.getElementById('link-store-btn-spinner').classList.remove("hide");
        let storename       = document.getElementById('store-name').value;
        let storeusername   = document.getElementById('store-user-name').value;
        let storeuserpwd    = document.getElementById('store-pwd').value;
        
        let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            store_state : user_info.state,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            ls : storename,
            lsun : storeusername,
            lsp : storeuserpwd
        }
        let api = app_api+'/link_store';
        axios.post(api, body)
        .then(function (response) {
            document.getElementById('link-store-btn').classList.remove("hide");
            document.getElementById('link-store-btn-spinner').classList.add("hide");
            let resp = response.data;
            if(resp.success==1){
                this.setState({linked_success:true});
                document.getElementById("success-circle-loader").classList.add('load-complete');
                document.getElementById("checkmark-success").style.display ='block';
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }else{
                this.setState({error_msg:resp.msg});
            }
        }.bind(this)).catch(function(res){
           
        })
    }
    getLinkedStroes = () =>{
       
        let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            store_state : user_info.state,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
        }
        let api = app_api+'/get_linked_stores';
        axios.post(api, body)
        .then(function (response) {
            let resp = response.data;
            this.setState({linked_stores:resp});
            if(resp.length===0){
              this.setState({loading:'No linked store found'})
            }
        }.bind(this)).catch(function(res){
           
        })
    }
    componentDidMount(){
      this.getLinkedStroes();
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Link Stores</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                        <div className="row">
                             <div className="col-md-6">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>Link Store</label>
                                  {this.is_access('settings-bill-payment-create') &&
                                    <button className="btn btn-success pull-right" onClick={this.openModal}  style={{padding:'0px 5px'}}>
                                      <FontAwesomeIcon icon={faLink}/> Linked Store 
                                    </button>
                                  }
                                </div>
                                <div className="box-body">
                                    <table className="table">
                                      <tbody>
                                        {this.state.linked_stores.map((value)=>{
                                          return (
                                            <tr><td>{value.linked_store_name}</td></tr>
                                          )
                                        })}
                                        {(this.state.linked_stores.length===0) &&
                                          <tr><td>{this.state.loading}</td></tr>
                                        }
                                      </tbody>
                                    </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                             
                            </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.is_link_store===true) &&
                <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflowY:'auto',display:'block'}}>
              <div className="modal-dialog modal-confirm" style={{width:'auto'}}>
              <div className="modal-content" style={{paddingTop:'5px'}}>
                <div className="modal-header flex-column">
            
                    {(this.state.linked_success===false) &&
                        <>
                            <div className="icon-box icon-print">
                                <span className="material-icons"><FontAwesomeIcon icon={faLink}/></span>
                            </div>            
                            <p>Link store to switch/login linked store directly in single click.</p>
                            <div className="row text-left">
                                <div className="col-md-12">
                                    <label htmlFor="store-name">Store Name</label>
                                    <input type="text" id="store-name" className="form-control" placeholder="Store Name"/>
                                </div>
                                <div className="col-md-12 mrtop-10">
                                    <label htmlFor="store-user-name">Store Username</label>
                                    <input type="text" id="store-user-name" className="form-control" placeholder="Store User Name"/>
                                </div>
                                <div className="col-md-12 mrtop-10">
                                    <label htmlFor="store-pwd">Store Password</label>
                                    <input type="text" id="store-pwd" className="form-control" placeholder="Store Password"/>
                                </div>
                                {(this.state.error_msg!=='') &&
                                <div className="col-md-12 mrtop-10">
                                <p className="error">{this.state.error_msg}</p>
                                </div>
                                }
                            </div>
                        </>
                    }
                    
                    {(this.state.linked_success===true) &&
                        <div className="success-circle-loader" id="success-circle-loader">
                        <div className="checkmark-success draw" id="checkmark-success"></div>
                    </div>
                    }
                </div>
                    
                {(this.state.linked_success===false) &&
                    <div className="modal-footer justify-content-center" style={{padding: '10px 5px 25px'}}>
                        <button type="button" className="btn btn-secondary pull-left" onClick={this.handleClosePopup}>Close</button>
                        <button type="button" id="link-store-btn" className="btn btn-success pull-right" onClick={this.handleLinkStore}>Link</button>
                        <button type="button" id="link-store-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                }
                </div>
              </div>
                </div>
              }
            </div>
        );
    }
}