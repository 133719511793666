import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import axios from 'axios';
import utils from '../../utils.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCarAlt, faCartPlus, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
let {enc_key,app_api,user_info, gst_options} = require('../../library.js');
var CryptoJS = require("crypto-js");
export default class POSSettings extends Component {
    constructor(props) {
        super();
        this.state = {
            is_saving_oc : false,
           other_charges : [{'name':'','price':'','tax':'','tax_type':'','qty':1}]
        };
        this.isMobile = utils.isMobile.bind(this);
    }
    componentDidMount(){
        this.handleGetSettings();
    }
    handleGetSettings = ()=>{
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:"'other_charges'",
        }
        let sapi = app_api+'/get_settings';
        axios.post(sapi, ssend_data)
        .then(function (response) {
            let resp = response.data;
            if(resp.other_charges!==undefined){
            let other_charges = resp.other_charges;
            this.setState({other_charges:other_charges});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    handleAddMoreOC = ()=>{
        let other_charges = this.state.other_charges;
        let oc = {'name':'','price':'','tax':'','tax_type':'','qty':1};
        other_charges.push(oc);
        this.setState({other_charges:other_charges});
    }
    handleRemoveOCItem = (index) =>{
        let other_charges = this.state.other_charges;
        if(other_charges.length===1){
            other_charges = [{'name':'','price':'','tax':'','tax_type':'','qty':1}];
        }else{
            other_charges.splice(index,1);
        }
        this.setState({other_charges:other_charges});
    }
    handleChangeOCItem = (value,index,name)=>{
        let other_charges = this.state.other_charges;
        other_charges[index][name] = value;
        this.setState({other_charges:other_charges});
    }
    handleSaveOC = () =>{
        this.setState({is_saving_oc:true});
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            name : 'other_charges',
            value : this.state.other_charges
        }
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
            this.setState({is_saving_oc:false});
        }.bind(this)).catch(function(res){
            
        })
    }
    render() {
        return (
            <div id="page-content-wrapper">
                <nav className="navbar border-bottom" style={{paddingLeft:'20px'}}>
                    <div className="row">
                        <div className="col-md-12 mrtop-10">
                            <h1>POS Settings</h1>
                        </div>
                    </div>
                </nav>
            
                <div className="container-fluid page-break">
                    <div className="content-area">
                        <div className="content-head border-bottom" style={{padding:'5px'}}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="box-container">
                                        <div className="box-head">
                                        <label>Other Charges</label>
                                        </div>
                                        <div className="box-body" style={{padding:'15px'}}>
                                            <table className="table">
                                                {(this.state.other_charges.map((value,index)=>{
                                                    return(
                                                        <tr>
                                                            <td style={{width:'150px'}}>
                                                                <input type="text" className="form-control" placeholder="Enter Name" value={value.name} onChange={(e)=>this.handleChangeOCItem(e.target.value,index,'name')}/>
                                                            </td>
                                                            <td style={{width:'100px'}}>
                                                                <input type="text" className="form-control" placeholder="Enter Price" value={value.price}  onChange={(e)=>this.handleChangeOCItem(e.target.value,index,'price')}/>
                                                            </td>
                                                            {/* <td style={{width:'100px'}}>
                                                                <input list={"oc_tax_"+index}  className="form-control" placeholder="%" onChange={(e)=>this.handleChangeOCItem(e.target.value,index,'tax')}/>
                                                                <datalist id={"oc_tax_"+index}>
                                                                {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                                                    return (
                                                                    <option key={index}>{value.value}</option>
                                                                    )
                                                                }) : '' }
                                                                </datalist>
                                                            </td>
                                                            <td style={{width:'150px'}}>
                                                                <select className="form-control"  onChange={(e)=>this.handleChangeOCItem(e.target.value,index,'tax_type')}>
                                                                    <option value="including">Inc GST</option>
                                                                    <option value="excluding">Exc GST</option>
                                                                </select>
                                                            </td> */}
                                                            <td>
                                                                <FontAwesomeIcon icon={faTimes} style={{cursor:'#333',cursor:'pointer'}} onClick={()=>this.handleRemoveOCItem(index)}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                }))}
                                               
                                            </table>
                                            <p style={{textAlign:'right',color:'blue',cursor:'pointer',margin:'0px'}} onClick={this.handleAddMoreOC}>Add More</p>
                                        </div>
                                        <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                            {(this.state.is_saving_oc===false) &&
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSaveOC}>Save Settings</button>
                                            }
                                            {(this.state.is_saving_oc===true) &&
                                                <button type="button" className="btn btn-success btn-save-action  btn-sm"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}