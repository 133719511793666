import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ViewBillOnFly from "./view-bill-on-fly";
import ViewPaymentOnFly from "./view-payment-on-fly";
import DifferentBarcodeItems from "./different-barcode-items";
import Drawer from 'rc-drawer';
import '../../node_modules/rc-drawer/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import InventoryComissionList from "./inventory-comission-list";
import ContactPendingInvoiceList from "./contact-pending-invoice-list";
import WayBill from "./waybill";
import ViewTransport from "./view-transport";
import EWayBill from "./e-waybill";
import VeiwNoHSNData from "./view-no-hsn-data";
import ViewCheque from "./view-cheque";
import ViewAjustedInfo from "./view-adjusted-info";
import ViewCashEntry from "./view-cash-entry";
import ViewPayment from "./view-payment";
import ViewBilling from "./view-billing.js";
import ViewOrder from "./view-order.js";
import POSItemView from "./pos-item-view.js";
import POSSettings from "./settings/pos-settings.js";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
export default class DrawerPopup extends Component {
   constructor() {
      super();
      this.state = {
         is_drawer_open : false,
         is_order : false,
         data : [],
         random :Math.floor(100000 + Math.random() * 900000),
      };
     _this = this;
     
    }
  
    componentWillReceiveProps = (nextProps) =>{
      if(nextProps.data && nextProps.data.url!==null && nextProps.data.url!==undefined && nextProps.data.url!=="" && nextProps.data.url.indexOf('/')>=0){
          if(nextProps.data.url.indexOf('view-order')>=0){
            this.setState({is_order:true});
          }
          this.setState({data:nextProps.data});
          this.setState({is_drawer_open:true});
      }else if(nextProps.data.info!==undefined){
        this.setState({is_drawer_open:true});
        this.setState({data:nextProps.data});
      }
    }
    
    handleUnsetDrawerData = ()=>{  
      this.props.unsetDrawerData();
    }
    onTouchEnd = e =>{
      this.setState({is_drawer_open:false})
    }
    afterVisibleChange = (boolean, e) =>{
      if(boolean===true){
        if(document.getElementById("handleViewBillOnFly"+this.state.random)){
          document.getElementById("handleViewBillOnFly"+this.state.random).click();
        }
      }else{
        this.setState({data:[]});
        this.handleUnsetDrawerData();
        if(document.getElementById("handleCloseBillOnfly"+this.state.random)){
          document.getElementById("handleCloseBillOnfly"+this.state.random).click();
        }
      }
    }
    render() {
        return (
            
               <Drawer
                onChange={this.onChange}
                open={this.state.is_drawer_open}
                onClose={this.onTouchEnd}
                handler={false}
                level={null}
                afterVisibleChange={this.afterVisibleChange}
                width={this.props.width}
                placement={this.props.position}
              >
              <div className="drawer-close" onClick={this.onTouchEnd} id="drawer-close">
                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
              </div>
              {(this.state.data && this.state.data.target && this.state.data.target==='billing' && this.state.is_order===false) &&
               /*  <ViewBillOnFly random={this.state.random} url={(this.state.data && this.state.data.url)?this.state.data.url:''} schema={(this.state.data && this.state.data.schema)?this.state.data.schema:''}/> */
                <ViewBilling  random={this.state.random} url={(this.state.data && this.state.data.url)?this.state.data.url:''} schema={(this.state.data && this.state.data.schema)?this.state.data.schema:''}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='billing' && this.state.is_order===true) &&
               /*  <ViewBillOnFly random={this.state.random} url={(this.state.data && this.state.data.url)?this.state.data.url:''} schema={(this.state.data && this.state.data.schema)?this.state.data.schema:''}/> */
                <ViewOrder  random={this.state.random} url={(this.state.data && this.state.data.url)?this.state.data.url:''} schema={(this.state.data && this.state.data.schema)?this.state.data.schema:''}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='no_hsn_found') &&
                <VeiwNoHSNData data={this.props.data.info}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='payment') &&
                <ViewPayment  random={this.state.random} url={(this.state.data && this.state.data.url)?this.state.data.url:''} schema={(this.state.data && this.state.data.schema)?this.state.data.schema:''} target="onfly"/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='different_barcode') &&
                <DifferentBarcodeItems inventory_list={this.state.data.info} page_title={this.state.data.page_title}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='comission') &&
                <InventoryComissionList inventory_list={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='customer-pending') &&
                <ContactPendingInvoiceList pending_invoice_list={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='waybill') &&
                <WayBill way_bill_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} is_way_bill={true}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='ewaybill') &&
                <EWayBill way_bill_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} is_way_bill={true}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='transport') &&
                <ViewTransport transport_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='cheque') &&
                 <ViewCheque cheque_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='adjusted') &&
                 <ViewAjustedInfo adjusted_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='cash_entry') &&
                 <ViewCashEntry entry_data={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='product_list_info') &&
                 <POSItemView item_info={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} target={this.state.data.target}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='bill_summary') &&
                 <POSItemView item_info={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} target={this.state.data.target}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='product_list_full_info') &&
                 <POSItemView item_info={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} target={this.state.data.target}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='exchange_list_full_info') &&
                 <POSItemView item_info={this.state.data.info} page_title={this.state.data.page_title} width={this.props.width} target={this.state.data.target}/>
              }
              {(this.state.data && this.state.data.target && this.state.data.target==='pos_settings') &&
                 <POSSettings page_title={this.state.data.page_title} width={this.props.width} target={this.state.data.target}/>
              }
              </Drawer>    
           
        );
    }
}