import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ModalBox from "./modal-box";
import ServiceLoader from './service-loader.js';
import ClosedFYButton from "./closed-fy-button";
import utils from '../utils.js';
import check_access from './access-level.js';
import DrawerLink from "./open-drawer-link";
let {enc_key,app_api,user_info} = require('../library.js');
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}

export default class ViewPaymentOnFly extends Component {
    constructor() {
        super();
        this.state = {
            is_cheque_payment : false,
            is_order_pay : false,
            show_loader:true,
            currrent_sequence:'',
            payment_info : [],
            contact_info : [],
            payment_date : '',
            issued_by:'',
            update_id:0,
            view_branch_id:'',
            total_balance_amt :'',
            total_grand :'',
            on_load : true,
            round_off_opt : true,
            round_off_opt2 : false,
            round_off : '',
            round_off_sym :'',
            paid_amount :'',
            payment_type:'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            is_fy_closed : false,
        };
     
        
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.delteBilling = this.delteBilling.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    handlePrint(e){
            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">.div-display{display:none}.bill-to-info{float:left;margin-left:20px;padding-left:20px;border-left:1px solid #d7d7d7}.bill-to-info .title{font-weight:700;text-transform:uppercase}.bill-to-c{float: left;}.bill-to-info p{margin-bottom:0;}.table{width:100%;margin-bottom:1rem;color:#212529}.table .thead-light th{font-size:12px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table td,.table th{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.issued-on{float:right}.bil_no{color:#9d9999 !important;font-size:25px}.content-head{padding:16px 30px 4px 35px}.mrbtm-12{margin-bottom:12px;}.mrtop-5{margin-top:5px;}.inner-content{padding:30px 50px}.border-bottom{border-bottom:1px solid #d7d7d7}.msg-to-cust{text-align:justify;}.mrtop-40{margin-top:40px}</style>'); 
            a.document.write('</head>'); 
            if(type==='print'){
              a.document.write('<body onload="window.print();">'); 
            }else{
               //Print and cancel button
               /*a.document.write('<input type="button" id="btnPrint" value="Print" class="no-print" style="width:100px" onclick="window.print()" />');
               a.document.write('<input type="button" id="btnCancel" value="Cancel" class="no-print"  style="width:100px" onclick="window.close()" />');*/
            }
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 
    delteBilling(event){
      let del_option = (this.state.payment_data && this.state.payment_data.is_current===true)?'Delete':'Cancel';
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      var element = document.getElementById('delete_confirm');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("deleteValue").value='payment-view';
      document.getElementById("deleteIndex").value=id;
      document.getElementById("deleteTarget").value=data_id;
      let deleteScreen = 'billing';
      if(this.state.is_order_pay===true){
        deleteScreen = 'order'; 
      }
      document.getElementById("deleteScreen").value=deleteScreen;
      del_option = del_option.toLowerCase();
      document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';
    }
    handleViewBillOnFly = () =>{
      let curl =(this.props.url!==undefined)?this.props.url:'';
      let screen = 'billing';
      let view_payment = curl.match(/view-payment\/(\d+$)/i);
      let order_view_payment = curl.match(/order\/view-payment\/(\d+$)/i);
      
      if(order_view_payment){
        screen = 'order';
        this.setState({is_order_pay:true});
      }
      if(this.props.payment_data){
                this.setState({update_id:null});
                let resp = this.props.payment_data;
                this.setState({show_loader:false})
                let data = resp.data[0];
                
                if(data===undefined){
                 // window.location.href = '/404';
                  
                }
                if(data.user_info && data.user_info[0]){
                  this.setState({issued_by:data.user_info[0].username});
                }

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
              
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
             if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.payable_amount.replace(/,/g , ""));
                let disc = 0;
                if(gdisc!==undefined){
                  if(isNaN(gdisc)){
                    disc =parseFloat(gdisc.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(gdisc);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = sub_total*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(data.discount_amount)});
              }
                            
              
              let paid_amount = data.paid_amount.replace(/,/g , "");
              
              this.setState({paid_amount:this.currency_format(paid_amount)});
              let pending_amount = data.pending_amount.replace(/,/g , "");
              let total_amount = parseFloat(paid_amount)+parseFloat(pending_amount);
              
              this.setState({total_balance_amt:this.currency_format(pending_amount)});
              this.setState({total_grand:this.currency_format(total_amount)});

                let payment_type = data.payment_type;
                let payment_meta = data.payment_meta;
                for (let index = 0; index < payment_meta.length; index++) {
                  const element = payment_meta[index];
                  if(element.cheque_no!==""){
                      this.setState({is_cheque_payment:true});
                  }  
                }
                this.setState({payment_data:data,payment_type:payment_type});
                this.setState({view_branch_id:data.branch_id});
                let payment_date = data.payment_date;
                
                this.setState({payment_date:payment_date});
                this.setState({show_loader:false});
                

                let contact_info = data.contact_info;
                let credit_info = data.credit_data;
                let contact_name = '';
                let contact_id = '';
                let address_1 =  '';
                let address_2 = '';
                let city = '';
                let credit_limit = '';
                let previous_balance = '';
                let email = '';
                let phone_1 = '';
                let phone_2 = '';
                let state = '';
                if(Object.keys(contact_info).length > 0) {
                   contact_name = contact_info[0].name;
                   contact_id = contact_info[0].contact_id;
                   address_1 = contact_info[0].address_1;
                   address_2 = contact_info[0].address_2;
                   city = contact_info[0].city;
                   /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                   address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                   city = this.decrypt_sanitize(contact_info[0].city);*/
                   credit_limit = contact_info[0].credit_limit;
                   email = contact_info[0].email;
                   phone_1 = contact_info[0].phone_1;
                   phone_2 = contact_info[0].phone_2;
                   state = contact_info[0].state;
                   /*email = this.decrypt_sanitize(contact_info[0].email);
                   phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                   phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                   state = this.decrypt_sanitize(contact_info[0].state);*/
                }
                let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
                this.setState({contact_info:c_info});
                let sequence = data.payment_number;
                this.setState({currrent_sequence:sequence});
                let list_items = data.payment_meta;
                
      }else if(view_payment){ // EDIT MODE
        
          const id = view_payment[1];
          
           let body = {};
            body = {
                screen : screen,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                id : parseInt(id),
                schema : this.props.schema,
             }
          let api = app_api+'/get_payment_on_id';
          axios.post(api, body)
            .then(function (response) {
                this.setState({update_id:id});
                let resp = response.data;
                let data = resp.data[0];
                
                if(data===undefined){
                  window.location.href = '/404';
                  
                }
                if(data.user_info && data.user_info[0]){
                  this.setState({issued_by:data.user_info[0].username});
                }

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
              
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
             if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.payable_amount.replace(/,/g , ""));
                let disc = 0;
                if(gdisc!==undefined){
                  if(isNaN(gdisc)){
                    disc =parseFloat(gdisc.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(gdisc);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = sub_total*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(data.discount_amount)});
              }
              
              
              let paid_amount = data.paid_amount.replace(/,/g , "");
              
              this.setState({paid_amount:this.currency_format(paid_amount)});
              let pending_amount = data.pending_amount.replace(/,/g , "");
              let total_amount = parseFloat(paid_amount)+parseFloat(pending_amount);
              
              this.setState({total_balance_amt:this.currency_format(pending_amount)});
              this.setState({total_grand:this.currency_format(total_amount)});

                let payment_type = data.payment_type;

                this.setState({payment_data:data,payment_type:payment_type});
                this.setState({view_branch_id:data.branch_id});
                let payment_date = data.payment_date;
                let payment_meta = data.payment_meta;
                for (let index = 0; index < payment_meta.length; index++) {
                  const element = payment_meta[index];
                  if(element.cheque_no!==""){
                      this.setState({is_cheque_payment:true});
                  }  
                }
                this.setState({payment_date:payment_date});
                this.setState({show_loader:false});
                

                let contact_info = data.contact_info;
                let credit_info = data.credit_data;
                let contact_name = '';
                let contact_id = '';
                let address_1 =  '';
                let address_2 = '';
                let city = '';
                let credit_limit = '';
                let previous_balance = '';
                let email = '';
                let phone_1 = '';
                let phone_2 = '';
                let state = '';
                if(Object.keys(contact_info).length > 0) {
                   contact_name = contact_info[0].name;
                   contact_id = contact_info[0].contact_id;
                   address_1 = contact_info[0].address_1;
                   address_2 = contact_info[0].address_2;
                   city = contact_info[0].city;
                   /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                   address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                   city = this.decrypt_sanitize(contact_info[0].city);*/
                   credit_limit = contact_info[0].credit_limit;
                   email = contact_info[0].email;
                   phone_1 = contact_info[0].phone_1;
                   phone_2 = contact_info[0].phone_2;
                   state = contact_info[0].state;
                   /*email = this.decrypt_sanitize(contact_info[0].email);
                   phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                   phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                   state = this.decrypt_sanitize(contact_info[0].state);*/
                }
                let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
              this.setState({contact_info:c_info});
                let sequence = data.payment_number;
                this.setState({currrent_sequence:sequence});
                let list_items = data.payment_meta;
                
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
      }
    }

   
    handleCloseBillOnfly = () =>{
      this.setState({
        show_loader:true,
        currrent_sequence:'',
        payment_info : [],
        contact_info : [],
        payment_date : '',
        issued_by:'',
        update_id:0,
        view_branch_id:'',
        total_balance_amt :'',
        total_grand :'',
        on_load : true,
        round_off_opt : true,
        round_off_opt2 : false,
        round_off : '',
        round_off_sym :'',
        paid_amount :'',
        payment_type:'',
        global_discount : '',
        global_discount_amount : '',
        discount_sym :'',
      });
    }

    componentDidMount(){
      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          keys:"'is_financial_year_closed'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
          if(this.props.target && this.props.target==='direct-view'){
            this.handleViewBillOnFly();
          }
      }.bind(this)).catch(function(res){
          
      })
    }
   
    render() {
        let view = true;
        let edit = true;
        let del = true;
        let is_payment = false;
        if(this.is_access('payments-out-create')){
          is_payment = true;
        }
        if(this.is_access('payments-in-create')){
          is_payment = true;
        }
        let round_off_sym = '-';
        if(this.state.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.state.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let total_bill_amount = 0;
        let total_paid_amount = 0;
        return (
          <>
            <span onClick={this.handleViewBillOnFly} id={"handleViewBillOnFly"+this.props.random}></span>
            <span onClick={this.handleCloseBillOnfly} id={"handleCloseBillOnfly"+this.props.random}></span>
            {(this.state.update_id===0) &&
              <ServiceLoader show={true}/>
            }
            {(this.state.update_id>0 || this.state.update_id===null) &&
            <div>
              
              <div id="page-content-wrapper" className="view-on-fly">
                {(this.props.target!=='direct-view') &&
                  <nav className="navbar border-bottom">
                  <div className="row">
                  
                    <div className="col-md-12 mrtop-15 action-area text-center">
                    {(user_info.login_id===this.state.view_branch_id) &&

                      <div className="e-p-p-opt  div-display">
                          {/*<div className="eppopt">Email</div>*/}
                          <div className="eppopt" onClick={this.handlePrint} id="print"><a>Print</a></div>
                          <div  onClick={this.handlePrint} id="preview" className="eppopt"><a>Preview</a></div>
                          {(localStorage.getItem('_current')===this.props.schema && (edit || del)) &&
                            <>
                            {(this.state.payment_data && this.state.payment_data.payment_mode && this.state.payment_data.status!==6) &&
                              <>
                                {edit &&
                                  <>
                                    {(this.state.is_fy_closed===false) &&
                                      <div className="eppopt"><a href={"/new-payment/edit/"+this.state.update_id}>Edit</a></div>
                                    }  
                                    {(this.state.is_fy_closed===true) &&
                                     
                                        <ClosedFYButton className="eppopt" label="Edit" type="div"/>
                                     
                                    }
                                  </>
                                }
                                {del &&
                                  <>
                                  {(this.state.is_fy_closed===false) &&
                                    <div className="eppopt no-border-right" id={this.state.update_id} onClick={this.delteBilling} data_target="delete_confirm" data_value="payment" data_id={"delete_payment"}><a>{(this.state.payment_data.is_current===true)?'Delete':'Cancel'}</a></div>
                                  }
                                  {(this.state.is_fy_closed===true) &&
                                      <ClosedFYButton className="eppopt no-border-right" label="Edit" type="div"/>
                                  }
                                  </>
                                }
                              </>
                            }
                            </>
                        }

                      </div>
                    }
                    </div>
                   
                  </div>
                  </nav>
                }
                <div  id="main-container">
                <ServiceLoader show={this.state.show_loader}/>
                <div className="container-fluid">
                  <div className="content-area">
                     
                     <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        <div className="col-md-3 mrtop-5 mob-content">
                            <h3 className="bil_no" style={{margin:'0px'}}>
                              {this.state.currrent_sequence}
                            </h3>
                            {(this.state.payment_data && this.state.payment_data.payment_type) &&
                            <span style={{fontSize: '11px',
    background: (this.state.payment_data.payment_type==='IN')?'#218838':'red',
    color: 'white',
    padding: '4px 5px',
    fontWeight: 'bold'}}>{this.state.payment_data.payment_type}</span>
                            }
                        </div>
                        <div className="col-md-6 mrtop-5 text-center mob-content">
                            <label className="bill-amount">Paid Amount : {(this.state.payment_data && this.state.payment_data.paid_amount)?this.currency_format(this.state.payment_data.paid_amount.replace(/,/g , "")):0}</label>
                        </div> 
                        <div className="col-md-3 mrtop-5 text-right mob-content">
                            <label className="bil_no" style={{textTransform:'capitalize'}}>
                              {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.status!==6)?this.state.payment_data.payment_option:(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.status===6)?'Cancelled':'-'}
                            </label>
                        </div> 
                       </div>
                     </div>
                   
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bill-to-c text-right">
                                  <span className="fe-title">BILL TO</span>
                                </div>
                                <div className="bill-to-info">
                                  <p className="title">{this.state.contact_info!=''?this.state.contact_info.item_name:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.address_1:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.city:''}</p>
                                  <p>{this.state.contact_info!=''?this.state.contact_info.phone_1:''}</p>
                                </div> 
                                 <div className="issued-on">
                                  
                                  <div className="input-group">
                                    
                                    <span className="fe-title">Issued On : {this.state.payment_date}</span>
                                    
                                  </div>
                                  
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Issued By : {this.state.issued_by}</span>
                                    
                                  </div>
                                </div>
                              </div> 
                          </div>
                        </div>
                     </div>
                     
                  </div>
                </div>


                 <div className="container-fluid">

                  <div className="content-area">
                  {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.payment_option==='billwise') &&
                    <>
                     <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">Bills</label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center">
                            
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right">
                            
                        </div> 
                       </div>
                     </div>
                     <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">BILL NO</th>
                            <th scope="col">REFRENCE NO.</th>
                            <th scope="col">PAYABLE</th>
                            <th scope="col">BALANCE</th>
                          </tr>
                        </thead>
                        <tbody id="items-body">
                           {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                                 if(value.target!=='payment' && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')){
                                  total_bill_amount = total_bill_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                 }
                                 return (
                                  <>
                                    {(value.target!=='payment' && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')) &&
                                    <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-name">
                                        {(value.bill_invoice_id!==0 && value.is_deleted!=='yes') &&
                                          <>
                                        {value.bill_invoice_no}
                                        </>
                                        } 
                                        {(value.is_deleted==='yes') &&
                                          <>
                                            {value.bill_invoice_no}
                                          </>
                                        }
                                        {(value.bill_invoice_id===0) &&
                                          <>
                                          {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                          </>
                                        }
                                      </td>
                                      <td>
                                        {value.refrence_no}
                                      </td> 
                                      <td>
                                        ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                      </td> 
                                      <td>
                                        ₹{(value.pending_amount!==null)?
                                            this.currency_format(value.pending_amount.replace(/,/g , ""))
                                            :0
                                          }
                                      </td>
                                    </tr>
                                    }
                                  </>
                                  )
                                  }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                        </tbody>
                        <tfoot>
                          <tr>
                          <th></th>
                          <th></th>
                            <th>₹{utils.currency_format(total_bill_amount)}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                      {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.payment_option==='billwise') &&
                      <>
                        <div className="content-head border-bottom border-top">
                        <div className="row mrbtm-12">
                          <div className="col-md-4 mrtop-5">
                              <label className="bil_no">Payments</label>
                          </div>
                          <div className="col-md-4 mrtop-5 text-center">
                              
                          </div> 
                          <div className="col-md-4 mrtop-5 text-right">
                              
                          </div> 
                        </div>
                        </div>
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">BILL NO</th>
                              <th scope="col">REFRENCE NO.</th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th scope="col">CHQ NO.</th>
                                      <th scope="col">CHQ DATE</th>
                                      <th scope="col">CHQ BANK NAME</th>
                                  </>
                              }
                              <th scope="col">PAID</th>
                              <th scope="col">BALANCE</th>
                            </tr>
                          </thead>
                          <tbody id="items-body">
                            {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                              if(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance')){
                                total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                               }
                                  return (
                                    <>
                                    {(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance')) &&
                                    <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-name">
                                        {(value.bill_invoice_id!==0 && value.is_deleted!=='yes') &&
                                          <DrawerLink  target="billing" url={"/"+value.resource_url+"/"+value.target+"/"+value.bill_invoice_id} schema={value.schema} name={value.bill_invoice_no} />

                                        } 
                                        {(value.is_deleted==='yes') &&
                                          <>
                                            {value.bill_invoice_no}
                                          </>
                                        }
                                        {(value.bill_invoice_id===0) &&
                                          <>
                                          {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                          </>
                                        } 
                                        
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                        }
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.invoice_info.supplier_ref_no}</span>
                                        }
                                        {(value.invoice_info && value.invoice_info.grand_total) &&
                                          <p style={{margin: '0px',color: '#a9a9a9'}}>
                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                          </p>
                                        }
                                      </td>
                                      <td>
                                        {value.refrence_no}
                                      </td> 
                                      {(this.state.is_cheque_payment) &&
                                          <>
                                          {(value.cheque_no!=="") &&
                                          <>
                                              <td>
                                                  {value.cheque_no}
                                              </td> 
                                              <td>
                                                  {value.cheque_date}
                                              </td> 
                                              <td>
                                                  {value.cheque_bank_name}
                                              </td> 
                                          </>
                                          }
                                          {(value.cheque_no==="") &&
                                            <td colSpan={3}></td>
                                          }
                                          </>
                                      }
                                      <td>
                                        ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                      </td> 
                                      <td>
                                        ₹{(value.pending_amount!==null)?
                                            this.currency_format(value.pending_amount.replace(/,/g , ""))
                                            :0
                                          }
                                      </td>
                                    </tr>
                                    }
                                    </>
                                    )
                                    }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                          </tbody>
                          <tfoot>
                            <tr>
                              
                              <th></th>
                              <th></th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </>
                              }
                              <th scope="col">₹{utils.currency_format(total_paid_amount)}</th>
                              <th scope="col"></th>
                            </tr>
                          </tfoot>
                        </table>
                      </>
                      }
                      </>
                    }
                      {(this.state.payment_data && this.state.payment_data.payment_option && this.state.payment_data.payment_option!=='billwise') &&
                      <>
                        <div className="content-head border-bottom border-top">
                        <div className="row mrbtm-12">
                          <div className="col-md-4 mrtop-5">
                              <label className="bil_no">Payments</label>
                          </div>
                          <div className="col-md-4 mrtop-5 text-center">
                              
                          </div> 
                          <div className="col-md-4 mrtop-5 text-right">
                              
                          </div> 
                        </div>
                        </div>
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">BILL NO</th>
                              <th scope="col">REFRENCE NO.</th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th scope="col">CHQ NO.</th>
                                      <th scope="col">CHQ DATE</th>
                                      <th scope="col">CHQ BANK NAME</th>
                                  </>
                              }
                              <th scope="col">PAID</th>
                              <th scope="col">BALANCE</th>
                            </tr>
                          </thead>
                          <tbody id="items-body">
                            {(this.state.payment_data && this.state.payment_data.payment_meta.length > 0) ?this.state.payment_data.payment_meta.map( (value, index) => {
                            
                                total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                               
                                  return (
                                    
                                    <tr className={(value.is_deleted==='yes')?"contact-row strikeout":"contact-row"} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-name">
                                        {(value.bill_invoice_id!==0 && value.is_deleted!=='yes') &&
                                          <DrawerLink  target="billing" url={"/"+value.resource_url+"/"+value.target+"/"+value.bill_invoice_id} schema={value.schema} name={value.bill_invoice_no} />

                                        } 
                                        {(value.is_deleted==='yes') &&
                                          <>
                                            {value.bill_invoice_no}
                                          </>
                                        }
                                        {(value.bill_invoice_id===0) &&
                                          <>
                                          {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                          </>
                                        } 
                                        
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.dispatched_info.supplier_ref_no}</span>
                                        }
                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                            <span style={{color: '#a9a9a9'}}> | {value.invoice_info.supplier_ref_no}</span>
                                        }
                                        {(value.invoice_info && value.invoice_info.grand_total) &&
                                          <p style={{margin: '0px',color: '#a9a9a9'}}>
                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                          </p>
                                        }
                                      </td>
                                      <td>
                                        {value.refrence_no}
                                      </td> 
                                      {(this.state.is_cheque_payment) &&
                                          <>
                                          {(value.cheque_no!=="") &&
                                          <>
                                              <td>
                                                  {value.cheque_no}
                                              </td> 
                                              <td>
                                                  {value.cheque_date}
                                              </td> 
                                              <td>
                                                  {value.cheque_bank_name}
                                              </td> 
                                          </>
                                          }
                                          {(value.cheque_no==="") &&
                                            <td colSpan={3}></td>
                                          }
                                          </>
                                      }
                                      <td>
                                        ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                      </td> 
                                      <td>
                                        ₹{(value.pending_amount!==null)?
                                            this.currency_format(value.pending_amount.replace(/,/g , ""))
                                            :0
                                          }
                                      </td>
                                    </tr>
                                  
                                    )
                                    }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                          </tbody>
                          <tfoot>
                            <tr>
                              
                              <th></th>
                              <th></th>
                              {(this.state.is_cheque_payment) &&
                                  <>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </>
                              }
                              <th scope="col">₹{utils.currency_format(total_paid_amount)}</th>
                              <th scope="col"></th>
                            </tr>
                          </tfoot>
                        </table>
                      </>
                      }
                      <div className="content-head">
                       <div className="row">
                          <div className="col-md-5 div-display">
                              {(this.state.payment_data && this.state.payment_data.notes)?this.state.payment_data.notes:''}
                          </div>
                          <div className="col-md-2">
                          </div> 
                          <div className="col-md-5">
                            <table className="table billing-total">
                              <tbody>
                                  <tr>
                                      <td><span className="sp-normal">Payabel Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-balance">₹{(this.state.payment_data && this.state.payment_data.payable_amount)?this.currency_format(this.state.payment_data.payable_amount):0}</span>
                                       
                                      </td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Balance Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-balance">₹{(this.state.total_balance_amt!=='')?this.state.total_balance_amt:0}</span>
                                       
                                      </td>
                                  </tr>
                                  {(this.state.payment_data && this.state.payment_data.global_discount!==0 && this.state.payment_data.global_discount!=="0" && this.state.payment_data.global_discount!=="") &&
                                    <tr>
                                        <td>
                                          <span className="sp-normal">
                                          Discount
                                          {(this.state.discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                               </span>
                                            </>
                                          }
                                          </span>
                                        </td>
                                        <td className="text-right">
                                           
                                          <span className="sp-normal">
                                            {(this.state.payment_data && this.state.payment_data.global_discount) &&
                                              <>
                                                {(this.state.discount_sym==='rs') &&
                                                <>
                                                <span className="rs-sym">₹</span>{this.state.payment_data.global_discount}
                                                </>
                                                }
                                                {(this.state.discount_sym==='per') &&
                                                <>
                                                 {this.state.payment_data.global_discount}%
                                                 </>
                                                }
                                              </>
                                            }
                                          </span>

                                        </td>
                                    </tr>
                                  }
                                  <tr>
                                      <td><span className="sp-normal">Round Off</span></td>
                                      <td className="text-right">
                                        <>
                                        {(this.state.round_off>0) &&
                                          <>
                                          {round_off_sym}{this.state.round_off}
                                          </>
                                        }
                                        {(this.state.round_off===0) &&
                                          0
                                        }
                                        </>
                                       </td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Paid Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-amount">
                                          ₹{
                                            (this.state.paid_amount!=='')?this.state.paid_amount:(this.state.total_grand!=='')?this.state.total_grand:0
                                          }
                                        </span>
                                      
                                      </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                       </div>
                     </div>
                     <div className="content-head">
                       <div className="row">
                          <div className="col-md-12 mrtop-5">
                              <p className="msg-to-cust print-block">{(this.state.payment_data && this.state.payment_data.notes)?this.state.payment_data.notes:''}</p>
                          </div>
                       </div>
                     </div>
                  </div>
                </div>
              </div>
              </div>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
            </div>
          }
          </>
        );
    }
}