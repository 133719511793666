import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import Breadcrumbs from "../breadcrumbs";
import UserRoleChecks from "./user-roles-check";
import axios from 'axios';
import utils from '../../utils.js';
import Tooltip from "../tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faInfoCircle,faPencilAlt,faCheckCircle,faTrash,faSpinner,faPlusSquare,faAngleDoubleRight  } from '@fortawesome/free-solid-svg-icons';
import ModalBox from "../modal-box";
import Notification from "../notification.js";
import check_access from '../access-level.js';
let {enc_key,app_api,user_info} = require('../../library.js');
let _this;
export default class Billings extends Component {
    constructor() {
        super();
        this.state = {
          active_tab:'users',
          create_button : 'Add User',
          button_ref : 1,
          users_info : '',
          action : '',
          update_id : '',
          user_role_check:'',
          info_box_title:'',
          roles_info:'',
          view_click : false,
          role_name :'',
          user_roles :'',
          
        }
        this.user_role_check = [];
        this.saveUser = this.saveUser.bind(this);
        this.handleNewRole = this.handleNewRole.bind(this);
        this.handleViewRole = this.handleViewRole.bind(this);
        this.handleCancelSave = this.handleCancelSave.bind(this);
        this.editMode = this.editMode.bind(this);
        this.updateRow = this.updateRow.bind(this);
        this.normalMode = this.normalMode.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.showAccessLevel = this.showAccessLevel.bind(this);
        this.changeUserRole = this.changeUserRole.bind(this);
        this.resendLink = this.resendLink.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    changeUserRole(e){
      this.setState({role_name:e.currentTarget.value});
    }
    showAccessLevel(e){
      let index = e.currentTarget.id;
      let parents = ['expense','transport','billing', 'payments', 'inventory', 'contacts', 'settings', 'notifications','reports','debit credit note']
      let roles = JSON.parse(this.state.users_info[index].roles);
      let role_name = this.state.users_info[index].role_name;
      let msg = role_name.toUpperCase()+ " ACCESS LEVEL";
      this.setState({info_box_title:msg});
      var element = document.getElementById('info_box');
      element.classList.remove("fade");
      element.style.display="block";
      let str = '<table class="table"><tbody>';
      for(let i=0;i<roles.length;i++){
          let role_n = roles[i];
          role_n = role_n.replaceAll("_"," ");
          let cls = (parents.indexOf(role_n)>-1?'parent':'child');
          
            role_n = role_n.replace('-', "->");
            role_n = role_n.replace('view','->view');
            role_n = role_n.replace('create','->create');
            role_n = role_n.replace('modify','->modify');
            role_n = role_n.replace('delete','->delete');
            role_n = role_n.replaceAll("-"," ");
            role_n = role_n.replaceAll(">","->");
            role_n = role_n.replaceAll("-> ->","->");
         
          str += '<tr><td><i class="'+cls+'">'+role_n+'</i></td></tr>';
      }
      str += '</tbody></table>';
      document.getElementById("data-info").innerHTML = str;
    }
    handleCancelSave(e){
      this.user_role_check = [];
      this.setState({user_role_check:''});
    }

  handleViewRole(e){
    this.setState({view_click:true});
    let id = e.currentTarget.id;
    let user_role = document.getElementById('role-name-'+id).innerHTML;
    this.user_role_check = [];
    this.setState({user_role_check:''});
    let send_data = {
        id:id,
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token, _schema : user_info._schema,
    }
    let api = app_api+'/get_roles_on_id';
    axios.post(api, send_data)
      .then(function (response) {
        this.setState({view_click:false});
        let resp = response.data;
       
        let user_role_info = resp;
        
        this.user_role_check.push(<UserRoleChecks role_name={user_role} role_id={id} user_role_info={user_role_info}/>);
        this.setState({user_role_check:this.user_role_check});
    }.bind(this)).catch(function(res){
        
    })


    
    
  }
  handleNewRole(e){
    let user_role = document.getElementById('role-name').value;
   
    if(user_role!==''){
      this.setState({view_click:true});
      this.user_role_check = [];
      this.setState({user_role_check:''});
      setTimeout(function(){
        this.user_role_check.push(<UserRoleChecks role_name={user_role}  role_id=""/>);
        this.setState({user_role_check:this.user_role_check});
        this.setState({view_click:false});
      }.bind(this),500);
    }
  }
  saveUser(e){
      let id = e.currentTarget.id;
      let user_id = document.getElementById("user_id").value;
      let username = document.getElementById("user_name").value;
      let user_role = document.getElementById("user_role").value;
      let phone = document.getElementById("phone").value;
      let email = document.getElementById("email").value;
      let pincode = document.getElementById("pincode").value;
      let city = document.getElementById("city").value;
      let address = document.getElementById("address").value;
      console.log(email);
     
      if(email!=="" && username!==""){
       
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          u_id:user_id,
          username:username,
          user_role:user_role,
          phone:phone,
          email:email,
          pincode:pincode,
          city:city,
          address:address,
      }
      let api = app_api+'/create_user';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
          window.location.href = '/settings/user-management';
      }.bind(this)).catch(function(res){
       
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
          if(res.response){
              
              if(res.response.data){
                  let error  = res.response.data;
                  for(var key in error) {
                      if(document.getElementById(key+"-error")){
                        document.getElementById(key+"-error").innerHTML = error[key][0];
                      }
                  } 
              }
          }
      })
    }else{
      if(username===""){
        document.getElementById("username-error").innerHTML = "The username field is required.";
      }
      if(email===""){
        document.getElementById("email-error").innerHTML = "The email field is required.";
      }
    }
  }
  componentDidMount(){
    let curl = window.location.href;
    const settings = curl.match(/settings\/user-management$/i);
    const tabs = curl.match(/settings\/user-management\/([a-z_A-Z]+$)/i);
    const active_tab = curl.match(/settings\/user-management\/([a-z_A-Z]+)\/([a-z_A-Z]+$)/i);
    const actions = curl.match(/settings\/user-management\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);
    console.log(actions);
    let active = 'users';
    let action = '';
    let update_id = '';
    if(settings){
      this.setState({active_tab:'users'});
    }else if(active_tab){
      if(active_tab[1]==='roles'){
        active = 'roles';
      }
      this.setState({active_tab:active_tab[1]});
      this.setState({action:active_tab[2]});
      action = active_tab[2];
    }else if(tabs){
      if(tabs[1]==='roles'){
        active = 'roles';
      }
      this.setState({active_tab:tabs[1]});
     
    }else if(actions){
      this.setState({active_tab:actions[1]});
      this.setState({action:actions[2]});
      action = actions[2];
      this.setState({update_id:actions[3]});
      update_id = actions[3];
    }



    if(active==='roles'){
      this.setState({create_button:'Add Role'});
    }
    if(active==='users' && (action!=='edit' && action!=='add' && action!=='view')){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
      }
      let api = app_api+'/get_users';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          this.setState({users_info:resp});
      }.bind(this)).catch(function(res){
          
      })
    }else if(active==='users' && (action==='edit' || action==='view')){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          u_id:update_id,
      }
      let api = app_api+'/get_user_on_id';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          
          this.setState({users_info:resp[0], role_name:resp[0].access_level,user_roles:resp[0].roles});
      }.bind(this)).catch(function(res){
          
      })
    }else if(active==='roles' || (action==='edit' && action==='add') ){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
      }
      let api = app_api+'/get_roles';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          console.log(resp);
          this.setState({users_info:resp});
      }.bind(this)).catch(function(res){
          
      })
    }
    if(active==='users' && (action==='edit' || action==='add') ){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
      }
      let api = app_api+'/get_roles';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          this.setState({roles_info:resp});
      }.bind(this)).catch(function(res){
          
      })
    }
  }

  editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('role-name-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show");
    }

    updateRow(event){
      
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('role-name-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);

      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            role_name : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
         }
      let api = app_api+'/update_user_role';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('role-name-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
    
    deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
    resendLink(e){
        let id = e.currentTarget.id;
        let email = e.currentTarget.getAttribute('email');
        let username = e.currentTarget.getAttribute('username');
        var element = document.getElementById('resend-verify-link');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("verify-id").value = id;
        document.getElementById("verify-email").value = email;
        document.getElementById("verify-username").value = username;
          
    }
    render() {
        let parents = ['billing', 'payments', 'inventory', 'contacts', 'settings', 'notifications'];
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              {this.state.action==='' &&
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <Breadcrumbs link="/settings/user-management" title="Settings" sub_title="User Management"/>
                
                    </div>
                    
                    <div className="col-md-8">
                        <div className="pull-right">
                        <Notification />
                        {(this.state.active_tab==='users' && this.is_access('settings-user-management-create')) &&
                          <a href={"/settings/user-management/"+this.state.active_tab+"/add"}><button type="button" className="btn btn-success pull-right nav-btn">{this.state.create_button}</button></a>
                        }
                        </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      <li className={this.state.active_tab==='users'?'active':''}><a href="/settings/user-management">Users</a></li>
                      <li className={this.state.active_tab==='roles'?'active':''}><a href="/settings/user-management/roles">Roles</a></li>
                    </ul>
                  </div>
                 
                  <div className="content-area">
                     <div className={"main-content "+(this.state.active_tab==='users'?'pad-50':'')}>
                        {this.state.active_tab==='users' &&
                        <div className="row">

                            {(this.state.users_info.length > 0) ?this.state.users_info.map( (value, index) => {
                             return (
                              <div className="col-md-4 mrbtm-30" key={index}>
                                  <div className="user-block contact-row users-list">
                                      <Tooltip target="users" id={value.branch_id} index={index} view={"/settings/user-management/users/view/"+value.branch_id} edit={"/settings/user-management/users/edit/"+value.branch_id} delete={value.parent_id!==0?{active_tab:this.state.active_tab,id:value.branch_id,data_value:'user'}:''}  item_count={this.state.users_info.length}/>
                                      <p className="title">{value.username}  <span className={(value.type===3)?'not-verified':'verified'}>{(value.type===3)?'Not Verified':'Verified'}</span></p>
                                      <p>{value.phone}</p>
                                      <p>{value.role_name}</p>
                                     
                                      {value.type===3 &&
                                        <p className="resend-v-link" id={value.branch_id} email={value.email} username={value.username} onClick={this.resendLink}>Resend Link</p>
                                      }
                                  </div>
                              </div>
                            )
                           }) : '' }

                        </div>
                        }
                        {this.state.active_tab==='roles' &&
                        <>
                          <div className="row">
                              <div className="col-md-3"></div>
                              <div className="col-md-6">
                              <div className="box-container">
                                  {this.is_access('settings-user-management-create') &&
                                  <div className="row">
                                    <div className="col-md-9">
                                    <input type="text" className="form-control" placeholder="Enter User Role" id="role-name"/>
                                    </div>
                                    <div className="col-md-3 text-right">
                                    <button type="button" onClick={this.handleNewRole}  className="btn btn-primary">Go<FontAwesomeIcon icon={faAngleDoubleRight} className="pull-right margin-5 fa-icon"/>
                                    </button>
                                    </div>
                                  </div>
                                  }
                                  <div className="box-head mrtop-10">
                                    <label>USER ROLES</label> 
                                  </div>
                                  <div className="box-body">
                                      <table className="table">
                                        <tbody>
                                          {(this.state.users_info.length > 0) ?this.state.users_info.map( (value, index) => {
                                             return (
                                                <tr key={index}>
                                                  <td width="40">
                                                    {(value.user_role_id!==1) &&
                                                      <FontAwesomeIcon id={index} onClick={this.showAccessLevel} icon={faInfoCircle} className="fa-icon"/>
                                                    }
                                                    {(value.user_role_id===1) &&
                                                      <FontAwesomeIcon id={index} title="All Access" icon={faInfoCircle} className="fa-icon"/>
                                                    }
                                                  </td>
                                                  <td>
                                                    <span id={'role-name-'+value.user_role_id } className="show l-span">
                                                      { value.role_name }
                                                    </span>
                                                    <input type="text" defaultValue={value.role_name} className="form-control hide" id={'ct-txt-'+value.user_role_id}/>
                                                  </td>

                                                  <td>

                                                    <div id={'ct-normal-mode-'+value.user_role_id} className="show">
                                                    {(this.is_access('settings-user-management-view') && value.user_role_id!==1) &&
                                                      <FontAwesomeIcon icon={faAngleDoubleRight} className="pull-right margin-5 fa-icon" id={value.user_role_id} onClick={this.handleViewRole}/>
                                                    }
                                                    {this.is_access('settings-user-management-modify') &&
                                                      <FontAwesomeIcon id={value.user_role_id} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                                                    }
                                                    {(this.is_access('settings-user-management-delete') && value.user_role_id!==1) &&
                                                      <FontAwesomeIcon id={value.user_role_id} onClick={this.deleteRow} data_target="delete_confirm" data_value="user_role" data_id="delete_user_role" icon={faTrash} className="pull-right fa-icon"/>
                                                    }
                                                    </div>
                                                    <div id={'ct-edit-mode-'+value.user_role_id} className="hide mrtop-10">
                                                    {this.is_access('settings-user-management-modify') &&
                                                    <>
                                                      <FontAwesomeIcon id={value.user_role_id} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                                                      <FontAwesomeIcon id={value.user_role_id} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                                                    </>
                                                    }
                                                    </div>

                                                  </td>
                                                </tr>
                                            )
                                           }) : '' }
                                        </tbody>
                                      </table>
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 users-roles-cb">
                              {this.state.view_click===true &&
                                <div className="text-center"><FontAwesomeIcon className="role-spinner" icon={faSpinner} pulse/></div>
                              }
                              {this.state.user_role_check}
                              {this.state.user_role_check!=='' &&
                              <button type="button" className="btn btn-secondary" onClick={this.handleCancelSave}>Cancel</button>
                              }
                            </div>
                          </div>
                          </>
                        }
                     </div>
                  </div>
                </div>
              </div>
            }
            {(this.state.active_tab==="users" && (this.state.action==='add' || this.state.action==='edit')) &&

              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12">
                        <Breadcrumbs link="/settings/user-management" title="Settings" sub_title="User Management"/>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content border-bottom">
                      <div className="inner-content">
                          <input type="hidden" id="user_id" defaultValue={this.state.update_id}/>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <label className="lbl">USER NAME  <span className="error">*</span></label>
                                <input type="text" defaultValue={(this.state.users_info && this.state.users_info.username)?this.state.users_info.username:''} name="user_name" className="form-field form-control" placeholder="User Name" id="user_name"/>
                                <p className="error" id="username-error"></p>
                            </div>
                            <div className="col-md-4">
                                <label className="lbl">USER ROLE</label>
                                <select className="form-control" id="user_role" onChange={this.changeUserRole} value={this.state.role_name}>
                                      {(this.state.roles_info.length > 0) ? this.state.roles_info.map( (value, index) => {
                                       return (
                                          <option value={value.user_role_id} key={index}>{value.role_name}</option>
                                        )
                                      }) : ''}
                                </select>
                            </div>
                            
                            <div className="col-md-2"></div>
                          </div>
                          <div className="row mrtop-30">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <label className="lbl">PHONE NUMBER</label>
                                <input type="text" defaultValue={(this.state.users_info && this.state.users_info.phone)?this.state.users_info.phone:''} name="phone_number" className="form-field form-control" placeholder="Phone Number"  id="phone"/>
                            </div>
                            <div className="col-md-4">
                                <label className="lbl">EMAIL <span className="error">*</span></label>
                                <input type="text" defaultValue={(this.state.users_info && this.state.users_info.email)?this.state.users_info.email:''}  name="email" className="form-field form-control" placeholder="Email"  id="email"/>
                                <p className="error" id="email-error"></p>
                            </div>
                            
                            <div className="col-md-2"></div>
                          </div>
                          <div className="row mrtop-30">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <label className="lbl">CITY</label>
                                <input type="text" defaultValue={(this.state.users_info && this.state.users_info.city)?this.state.users_info.city:''}  name="city" className="form-field form-control" placeholder="City"  id="city"/>
                            </div>
                            <div className="col-md-4">
                                <label className="lbl">PIN CODE</label>
                                <input type="text" defaultValue={(this.state.users_info && this.state.users_info.pin_code)?this.state.users_info.pin_code:''} name="pincode" className="form-field form-control" placeholder="Pin Code"  id="pincode"/>
                            </div>
                             
                            <div className="col-md-2"></div>
                          </div>
                          <div className="row mrtop-30">
                            <div className="col-md-2"></div>
                           <div className="col-md-8">
                                <label className="lbl">ADDRESS</label>
                                <textarea defaultValue={(this.state.users_info && this.state.users_info.address)?this.state.users_info.address:''}  name="address" className="form-field form-control" placeholder="Addess"  id="address"></textarea>
                            </div>
                             
                            <div className="col-md-2"></div>
                          </div>
                        </div>
                    </div> 
                    <div className="content-head border-bottom">
                      <div className="row save-user-foot">
                        <div className="col-md-6">
                        <a href="/settings/user-management">
                          <button type="button" className="btn btn-secondary btn-save-action">Cancel</button>
                          </a>
                        </div>
                        <div className="col-md-6 text-right">
                          <button type="button" id="add-user-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            <button type="button" id="add-user" onClick={this.saveUser} className="btn btn-success btn-save-action">Save User</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            }
            {(this.state.active_tab==="users" && this.state.action==='view') &&
             
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12">
                        <Breadcrumbs link="/settings/user-management" title="Settings" sub_title="User Management"/>
                    </div>
                  </div>
                </nav>
              <div className="container-fluid">
                    <div className="content-area">
                      <div className="main-content pad-50">
                          <div className="row">
                            <div className="col-md-4 border-right">
                                <div className="col-md-12">
                                    <label> Name : </label> <span className="vc-info-spn">{this.state.users_info.username}</span>
                                </div>
                                <div className="col-md-12 mrtop-5">
                                    <label> Role : </label> <span className="vc-info-spn">{this.state.users_info.role_name}</span>
                                </div>
                            </div>
                            <div className="col-md-4 border-right">
                                <div className="col-md-12 mrtop-5">
                                    <label> Phone : </label> <span className="vc-info-spn">{this.state.users_info.phone}</span>
                                </div>
                                <div className="col-md-12 mrtop-5">
                                    <label> City : </label> <span className="vc-info-spn">{this.state.users_info.city}</span>
                                </div> 
                                
                                
                            </div>
                            <div className="col-md-4">
                                <div className="col-md-12 mrtop-5">
                                    <label> Email : </label> <span className="vc-info-spn">{this.state.users_info.email}</span>
                                </div> 
                                <div className="col-md-12 mrtop-5">
                                    <label> Address : </label> <span className="vc-info-spn">{this.state.users_info.address}</span>
                                </div> 
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     <div className="box-container">
                          <div className="box-head">
                            <label>USER ROLES</label>
                          </div>
                      </div>
                     <div className="box-body" id="data-info">
                      <table className="table">
                        <tbody>
                            {(this.state.user_roles.length > 0) ?this.state.user_roles.map( (value, index) => {
                             return (
                              <tr key={ index } id={'delete_contact_type_'+index}>
                                <td>
                                  <i class={(parents.indexOf(value)>-1?'parent':'child')}>{value.replaceAll("-"," ")}</i>
                                </td>
                              </tr>
                            )
                           }) : <tr><td>No record(s) found</td></tr> }
                        </tbody>
                      </table>
                     </div>
                     </div>
                  </div>
                </div>
              </div>
            }
            <ModalBox id="delete_confirm" title="Delete" data_id="Delete"/>
            <ModalBox id="resend-verify-link" title="Resend Verification Link" data_id="ResendLink"/>
            <ModalBox id="info_box" title={this.state.info_box_title}  data_id="info-box"/>
            </div>
        );
    }
}