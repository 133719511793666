import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import ContactsDropdown from "./contacts-dropdown";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faArrowLeft,faArrowRight,faCheckCircle,faTimes,faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import ContactField from './contact-field';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import PaymentModes from "./payment-modes";
import ModalBox from "./modal-box";
import ExpenseTypes from "./expense-types";
import encrypt_decrypt from './encrypt-decrypt.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class ViewExpense extends Component {
    constructor() {
        super();
        this.state = {
            save_button:'Save',
            items_list : [],
            edit_item_index : 'none',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  new Date(),
            contact_info : '',
            send_type:'save',
            update_id:0,
            expenses_data :[],
            on_load : true,
            isMobile : false,
            is_recurring : false,
            is_chq_in_list : false,
            final_total : 0,
            view_branch_id:''
        };
        _this = this;
        this.items_list = [];
        this.delteExpense = this.delteExpense.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.decrypt_sanitize = encrypt_decrypt.decrypt_sanitize.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }
    handlePrint(e){
        let type = e.currentTarget.id;
        var divContents = document.getElementById("main-container").innerHTML; 
        var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
        a.document.write('<html><head>'); 
        a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
        a.document.write('<style type="text/css">.div-display{display:none}.exp-to-info{float:left;}.exp-to-info .title{font-weight:700;text-transform:uppercase}.exp-to-info p{margin-bottom:0;}.table{width:100%;margin-bottom:1rem;color:#212529}.table .thead-light th{font-size:12px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table td,.table th{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.issued-on{float:right}.bil_no{color:#6b6a6a !important;font-size:20px}.content-head{padding:16px 30px 4px 35px}.mrbtm-12{margin-bottom:12px;}.mrtop-5{margin-top:5px;}.inner-content{padding:30px 50px}.border-bottom{border-bottom:1px solid #d7d7d7}.msg-to-cust{text-align:justify;}.mrtop-40{margin-top:40px}.exp-head .sequence_number{width:50%;float:left}.exp-head .amount{width:50%;float:right;color: #6b6a6a !important;font-size: 20px;}</style>'); 
        a.document.write('</head>'); 
        if(type==='print'){
          a.document.write('<body onload="window.print();">'); 
        }else{
           
        }
        a.document.write(divContents); 
       
         a.document.write('</body></html>');
         a.document.close();
         a.focus();
      } 
     delteExpense(event){
      let del_option = (this.state.expenses_data && this.state.expenses_data.is_current===true)?'Delete':'Cancel';
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      var element = document.getElementById('delete_confirm');
      element.classList.remove("fade");
      element.style.display="block";
      let delete_value = data_value;
      document.getElementById("deleteValue").value=delete_value;
      document.getElementById("deleteIndex").value=id;
      document.getElementById("deleteTarget").value=data_id;
      del_option = del_option.toLowerCase();
      document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';   
    }
   
  
      billingDate = date => {
        this.setState({
          billingDate: date
        });
      };
       
    componentDidMount(){
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
      }
      
      let curl = window.location.href;
      localStorage.removeItem("p_mode");
      const view_expense = curl.match(/view-expense\/(\d+$)/i);
      console.log(view_expense);
      if(view_expense){ // EDIT MODE
          const id = view_expense[1];
       
          this.setState({update_id:id});
       
       
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              id : parseInt(id),
           }
        let api = app_api+'/get_expense_on_id';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let data = resp.data[0];
              if(data===undefined){
                window.location.href = '/404';
              }
              this.setState({expenses_data:data});
              this.setState({view_branch_id:data.branch_id});
              this.setState({final_total:data.grand_total});
              this.setState({currrent_sequence:data.expense_number});
              this.setState({billingDate:new Date(data.created_date)});

              this.items_list = data.expense_meta;
              this.setState({items_list:this.items_list});
              let exp_li_items = this.items_list;
              for (let exi = 0; exi < exp_li_items.length; exi++) {
                const element = exp_li_items[exi];
                let is_cheque = element.is_cheque;
                if(is_cheque===true){
                  this.setState({is_chq_in_list:true});
                }
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }
    }
    handleRestore(e){
      let body = {  
          id : this.state.update_id,
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
       }
      let api = app_api+'/restore_expense';
      axios.post(api, body)
        .then(function (response) {
          window.location.href = '/expenses/trash';
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }
    render() {
        let isMobile = "row";
        if(this.state.isMobile){
          isMobile = "mobile";
        }
        let isSticky = '';
        let abfs = '';
        let isActionButton = '';
        if(this.state.isMobile===false){
            isSticky = 'sticky-add-item-panel';
            abfs = 'abfs';
            isActionButton = 'action-button';
        }

        let view = false;
        let edit = false;
        let del = false;
        let restore = false;
    
        if(this.is_access('expense-modify')){
          edit = true;
        }
        if(this.is_access('expense-delete')){
          del = true;
        }

        if(this.state.expenses_data.current_status==='Cancelled'){
           edit = false;
        }else if(this.state.expenses_data.current_status==='Trashed'){
           edit = false;
           restore = true;
        }

        return (
            <div className="d-flex expenses-wrapper" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className={"navbar border-bottom"}>
                  <div className="row">
                    <div className="col-md-7">
                      <Breadcrumbs link="/expenses" title="Expenses" sub_title="View Expense"/>
                    </div>
                    <div className="col-md-5 mrtop-15 action-area">
                    {(user_info.login_id===this.state.view_branch_id) &&

                      <>
                        {(this.state.expenses_data.current_status!=='Cancelled') &&
                        <div className="e-p-p-opt div-display" style={{float:'right'}}>
                            {/*
                              <div className="eppopt">Email</div>
                            */
                            }
                            <div className="eppopt" onClick={this.handlePrint} id="print"><a>Print</a></div>
                            <div  onClick={this.handlePrint} id="preview" className="eppopt"><a>Preview</a></div>

                            {(restore) &&
                              <div className="eppopt"><a id={this.state.update_id} onClick={this.handleRestore}>Restore</a></div>
                            }

                            {(edit || del) &&
                              <>
                                {edit &&
                                  <div className="eppopt"><a href={"/new-expense/edit/"+this.state.update_id}>Edit</a></div>
                                }
                                {del &&
                                  <div className="eppopt no-border-right" id={this.state.update_id} onClick={this.delteExpense} data_target="delete_confirm" data_value={(this.state.expenses_data && this.state.expenses_data.current_status!=='Trashed')?"expense-view":'expense-view-trash'} data_id={"delete_expense"}><a>{(this.state.expenses_data.is_current===true)?'Delete':'Cancel'}</a></div>
                                }

                              </>
                            }

                        </div>
                      }
                      </>
                    }
                    </div>
                  </div>
                </nav>

              <div className="container-fluid" style={{marginBottom:'100px'}} id="main-container">
                <div className={isMobile} style={{marginRight:'0px'}}>
                  <div className={(isMobile==='row')?"pleftblk col-md-12":(isMobile==='row')?"pleftblk col-md-12":''}>
                    <input type="text" className="hide" id="delete-row" defaultValue='[]'/>
                    <div className="content-area">
                       <div className="content-head border-bottom" style={{paddingBottom:'0px'}}>
                         <div className="row mrbtm-12 exp-head">
                          <div className="col-md-4 mrtop-5 bill-view sequence_number">
                              <label className="bil_no">{this.state.currrent_sequence}</label>
                          </div>
                          
                          <div className="col-md-4 mrtop-5 text-center amount">
                            <label className="bill-amount">₹{this.currency_format(this.state.final_total)}</label>
                          </div>
                          <div className="col-md-4 mrtop-5 bill-view sequence_number text-right">
                              <span className="fe-title">Created On :
                                <span style={{color:'#3d3d3d'}}>
                                  {(this.state.expenses_data && this.state.expenses_data && this.state.expenses_data.created_on)?this.state.expenses_data.created_on:''}
                                </span>
                              </span>
                          </div>
                        </div>
                       </div>
                        <div className="">
                         
                            <div className="row">
                              <div className="col-md-12">
                                <>
                                {(this.state.items_list.length>0) &&
                                  <div className="content-head">
                                  
                                    <div className="table-responsive">
                                        <table className="table item-table">
                                          <thead className="thead-light">
                                            <tr>
                                            <th scope="col">S.NO</th>
                                      
                                            <th scope="col">PAYEE</th>
                                            <th scope="col">EXP. TYPE</th>
                                            <th scope="col">PAYMENT MODE</th>
                                            
                                            {(this.state.is_chq_in_list===true) &&
                                              <>
                                                <th scope="col">BANK NAME</th>
                                                <th scope="col">CHEQUE NO</th>
                                                <th scope="col">CHEQUE DATE</th>
                                              </>
                                            }
                                            <th scope="col">DESC.</th>
                                            <th scope="col">REFERENCE NO.</th>
                                            <th scope="col">AMOUNT</th>
                                            <th scope="col">TAX</th>
                                            <th scope="col">TOTAL</th>
                                            <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody id="items-body">
                                             {(this.state.items_list.length > 0) ?this.state.items_list.map( (value, index) => {
                                               return (
                                                <tr key={ index }  id={'expense_list_item_'+index} className="exp-list-item">
                                                  <td>
                                                    {index+1}
                                                  </td>
                                                 
                                                  <td>
                                                      {value.customer_txt}
                                                     
                                                    </td>
                                                    <td>
                                                      {value.expense_type_txt}
                                                     
                                                    </td>
                                                    <td>
                                                      {value.payment_mode_txt}
                                                      
                                                    </td>
                                                  
                                                    {(this.state.is_chq_in_list) &&
                                                    <>
                                                      <td>
                                                        {value.bank_name}
                                                       
                                                      </td>
                                                      <td>
                                                        <p style={{margin:'0px'}}>{value.cheque_no}</p>
                                                       
                                                      </td>
                                                      <td>
                                                        {value.cheque_date}
                                                       
                                                      </td>
                                                    </>
                                                    }
                                                    <td>
                                                      {value.description}
                                                    
                                                    </td>
                                                    <td>
                                                      {value.refrence_no}
                                                    
                                                    </td>
                                                    <td>
                                                      ₹{this.currency_format(value.amount)}
                                                     
                                                    </td>
                                                    <td>
                                                      {value.tax}
                                                     
                                                    </td>
                                                    <td>
                                                      ₹{this.currency_format(value.total)}
                                                     
                                                    </td>
                                               
                                                </tr>
                                              )
                                             }) : <tr><td>No record(s) found</td></tr> }
                                          </tbody>
                                        </table>
                                      </div>
                                      
                                    </div>
                                 }
                                
                                </>
                              </div>
                            </div> 
                           
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete" delete_message={'Do you really want to delete this bill?'}/>
            </div>
        );
    }
}