import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faSpinner,faTrash  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import ClosedFYButton from "./closed-fy-button";
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import DrawerLink from "./open-drawer-link";
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
export default class Cheques extends Component {
    constructor() {
        super();
        this.state = {
          filter_option:'this_month',
          FromDate:  new Date(),
          ToDate: new Date(),
          is_order_pay : false,
          active_tab : 'cheques',
          notesDate:  new Date(),
          ToDate: new Date(),
          cheques_list:[],
          cheques_list_temp:[],
          loading_process:'Loading...',
          is_search:false,
          current_page:1,
          current_filter_payment:'all',
          is_fy_closed : false,
          cheque_action_status : '',
          cheque_action_type : '',
          cheque_action_place_holder : '',
          cheque_action_msg : '',
          cheque_action_id : 0,
          total_pdc_report_count : 0,
          is_loading_pdc_report : false,
          progress : 0,
          cheque_dates : [],
          dates_amount : [],
          in_or_out : 'in',
        }

        this.refreshChequesList = this.refreshChequesList.bind(this);
        this.handleChequesSearch = this.handleChequesSearch.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    handleCalendarClick =(e)=>{
      let id = e.currentTarget.id;
      document.getElementById(id+'-date').focus();
    }
    FromDate = date => {
    
      if(date!==null){
        this.setState({
          FromDate: date
        });
      }
    };
    ToDate = date => {
      if(date!==null){
      this.setState({
        ToDate: date
      });
    }
    };
  notesDate = date => {
    this.setState({
      notesDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };
 
  handleChequesSearch(e){
      let _this = this;
      let item_name = document.getElementById("payment-search-box").value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ cheques_list: [],is_search:true });
        clearTimeout(this.timeout);
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              search : item_name,
              search_item : amount_item,
           }
        let api = app_api+'/search_cheques';
        _this.timeout = setTimeout(function () {
        axios.post(api, body)
          .then(function (response) {
               let page_count = response.data.number_of_page;
                
                if(response.data.is_empty){
                  _this.setState({loading_process:'Record(s) not found'});
                  _this.setState({total_page:0});
                  
                }else{
                  _this.setState({total_page:page_count});
                  
                  _this.setState({ 'cheques_list': response.data.data });
                }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          _this.refreshChequesList();
          _this.setState({ is_search:false });
        }
      }
  }


  componentDidMount(){
    let curl = window.location.href;
    let screen = '';
    let ptype = 'cheques';
    let subtype = 'in';
    const cheque_type = curl.match(/cheques\/([a-z_A-Z-]+)\/([all|in|out]+$)/i);
    if(cheque_type){
        ptype = cheque_type[1];
        subtype = cheque_type[2];
    }else{
      const cheque_type = curl.match(/cheques\/([received|sent]+$)/i);
      if(cheque_type){
          ptype = cheque_type[1];
      }
    }
    this.setState({active_tab:ptype});
    this.setState({in_or_out:subtype});
    const page_tab = curl.match(/cheques\/([a-z_A-Z]+)\/([all|in|out]+)\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      subtype = page_tab[2];
      current_page = page_tab[3];
      ptype = page_tab[1];
      this.setState({active_tab: ptype,current_page:current_page,in_or_out:subtype});
    }else{
      const page_tab = curl.match(/cheques\/([received|sent]+)\/(\d+$)/i);
      if(page_tab && page_tab[1]){
        current_page = page_tab[2];
        ptype = page_tab[1];
        this.setState({active_tab: ptype,current_page:current_page});
      }
    }
    let general_contact_bill_settings = {
      store_name:user_info.store_name,
      branch_id:user_info.login_id,
      user_id : user_info.user_id,
      uuid : utils.uuid(),
      role_id : user_info.access_level,
      token : user_info.token,
      _schema : user_info._schema,
      keys:"'is_financial_year_closed'"
  }
  let gc_api = app_api+'/get_settings';
  axios.post(gc_api, general_contact_bill_settings)
    .then(function (response) {
      let resp = response.data;
      if(resp.is_fy_closed){
        let is_fy_closed = resp.is_fy_closed;
        this.setState({is_fy_closed:is_fy_closed});
      }
  }.bind(this)).catch(function(res){
      
  })
   if(ptype==='pdc-report'){
    this.setState({loading_process:'Please adjust filter to show pdc report'});
    //this.setState({is_loading_pdc_report:true});
    //this.handleLoadPDCReport(1,ptype,subtype);
    return false;
   }
    let body = {};
        body = {
              screen :screen,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
              in_or_out : subtype
           }
        let api = app_api+'/get_cheques';
        axios.post(api, body)
        .then(function (response) {
          
          let page_count = response.data.number_of_page;
          
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            
          }else{
            this.setState({total_page:page_count});
            if(response.data.dates){
              this.setState({ 'cheque_dates': response.data.dates });
            }
            if(response.data.dates_amount){
              this.setState({ 'dates_amount': response.data.dates_amount });
            }
            this.setState({ 'cheques_list': response.data.data });
            this.setState({ 'cheques_list_temp': response.data.data });
          }
          
          let drawer_click = localStorage.getItem('drawer-click');
          if(drawer_click){
            localStorage.removeItem('drawer-click')
            if(document.getElementById(drawer_click)){
              document.getElementById(drawer_click).click()
            }
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })

       
  }

  handleLoadPDCReport = (npage,ptype,subtype) =>{
    let body = {};
        body = {
              filter_option : this.state.filter_option,
              FromDate : this.state.FromDate,
              ToDate : this.state.ToDate,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              total_record:this.state.total_pdc_report_count,
              page : npage,
              show : ptype,
              in_or_out : subtype,
           }
        let api = app_api+'/get_cheques';
        axios.post(api, body)
        .then(function (response) {
          
          let page_count = response.data.number_of_page;
          
          if(response.data.is_empty){
            this.setState({is_loading_pdc_report:false});
            this.setState({progress:0});
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            this.setState({total_pdc_report_count:0});
            document.getElementById('view-report').classList.remove("hide");
            document.getElementById('view-report-btn-spinner').classList.add("hide");
          }else{
            this.setState({total_page:page_count});
            let cheque_data = response.data.data;
            let total_record = response.data.total_record;
            this.setState({total_pdc_report_count:total_record})
            let cheque_dates = this.state.cheque_dates;
            let dates_amount = this.state.dates_amount;
            let cheques_list = this.state.cheques_list;
            for (let index = 0; index < cheque_data.length; index++) {
              const element = cheque_data[index];
              let cheque_date = element.cheque_date_visible;
              let amount = element.amount;
              if(cheque_dates.indexOf(cheque_date)<0){
                cheque_dates.push(cheque_date);
              }
              if(dates_amount[cheque_date]===undefined){
                dates_amount[cheque_date] = [];
                dates_amount[cheque_date] = 0;
              }
              dates_amount[cheque_date] = parseFloat(dates_amount[cheque_date]) + parseFloat(amount);
              
              if(cheques_list[cheque_date]===undefined){
                cheques_list[cheque_date] = [];
              }
              cheques_list[cheque_date].push(element);
            }
            let progress = (npage / page_count) * 100;
            progress = utils.currency_format(progress);
            progress = Math.round(progress);
            
            let next_page = npage+1;
            this.handleLoadPDCReport(next_page,ptype,subtype);
            this.setState({progress});
            this.setState({dates_amount});
            this.setState({cheque_dates});
            this.setState({cheques_list});
            this.setState({cheques_list_temp:cheques_list});
            this.setState({loading_process:'Loading...'});
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }

  refreshChequesList(){
    let curl = window.location.href;
    
    let screen = '';
    
    let ptype = 'cheques';
    let subtype = 'in';
    const cheque_type = curl.match(/cheques\/([a-z_A-Z-]+)\/([all|in|out]+$)/i);
    if(cheque_type){
        ptype = cheque_type[1];
        subtype = cheque_type[2];
    }
    this.setState({active_tab:ptype});
    this.setState({in_or_out:subtype});
    const page_tab = curl.match(/cheques\/([a-z_A-Z]+)\/([all|in|out]+)\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[3];
      subtype = cheque_type[2];
      ptype = page_tab[1];
      this.setState({active_tab: ptype,current_page:current_page,in_or_out:subtype});
    }

    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
              screen : screen,
              in_or_out : subtype
           }
        let api = app_api+'/get_cheques';
        axios.post(api, body)
        .then(function (response) {
          
          let page_count = response.data.number_of_page;
          
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            
          }else{
            this.setState({total_page:page_count});
            
            if(response.data.dates){
              this.setState({ 'cheque_dates': response.data.dates });
            }
            if(response.data.dates_amount){
              this.setState({ 'dates_amount': response.data.dates_amount });
            }
            this.setState({ 'cheques_list': response.data.data });
            this.setState({ 'cheques_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
    handlePDCReportFilter = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({filter_option:thisvalue});
    }
    handleShowPDCReport = (e) =>{
      let id = e.currentTarget.id;
      if(document.getElementById(id)){
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");
      }
      let ptype = this.state.active_tab;
      let subtype = this.state.in_or_out;
      this.setState({is_loading_pdc_report:true});
      this.setState({progress:0});
      this.setState({total_pdc_report_count:0});
      this.setState({page_count:0});
      this.setState({dates_amount:[]});
      this.setState({cheque_dates:[]});
      this.setState({cheques_list:[]});
      this.setState({cheques_list_temp:[]});
      this.handleLoadPDCReport(1,ptype,subtype);
    }
    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">{this.state.active_tab} List</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onKeyUp={this.handleChequesSearch} id="payment-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                          <Notification /> 
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                {(this.state.is_search===false) && 
                <>
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      <>
                        <li className={this.state.active_tab==='pending'?'active':''}><a href="/payments/cheques/pending/all">Pending Cheques</a></li>
                        <li className={this.state.active_tab==='reconciliation'?'active':''}><a href="/payments/cheques/reconciliation/all">Sent for reconciliation</a></li>
                        <li className={this.state.active_tab==='rejected'?'active':''}><a href="/payments/cheques/rejected/all">Rejected</a></li>
                        <li className={this.state.active_tab==='received'?'active':''}><a href="/payments/cheques/received">Payment Received</a></li>
                        <li className={this.state.active_tab==='sent'?'active':''}><a href="/payments/cheques/sent">Payment Sent</a></li>
                        {(this.is_access('payments-in') && this.is_access('payments-out')) &&
                          <li className={this.state.active_tab==='all'?'active':''}><a href="/payments">All Payments</a></li>
                        }
                        <li className={this.state.active_tab==='pdc-report'?'active':''}><a href="/payments/cheques/pdc-report/all">PDC Report</a></li>
                      </>
                    </ul>
                    <ShortcutHelp target="tabs"  arrow="top" shortcuts={['Ctrl+Alt+1','Ctrl+Alt+2','Ctrl+Alt+3']}/>
                  </div>
                  {(this.state.active_tab==='pending' || this.state.active_tab==='reconciliation' || this.state.active_tab==='rejected' || this.state.active_tab==='pdc-report') &&
                   <div className="nav-container">
                   <ul className="nav nav-pills">
                     <>
                       <li className={this.state.in_or_out==='all'?'active':''}><a href={"/payments/cheques/"+this.state.active_tab+'/all'}>ALL</a></li>
                       <li className={this.state.in_or_out==='in'?'active':''}><a href={"/payments/cheques/"+this.state.active_tab+'/in'}>IN</a></li>
                       <li className={this.state.in_or_out==='out'?'active':''}><a href={"/payments/cheques/"+this.state.active_tab+'/out'}>OUT</a></li>
                     </>
                   </ul>
                   </div>
                  }
                 </>
                }
                  <div className="content-area">
                     <div className="main-content">
                     
                      {(this.state.active_tab!=='pdc-report') &&
                        <>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">CHEQUE#</th>
                            <th scope="col">CHEQUE NAME</th>
                            <th scope="col">CHEQUE NO</th>
                            <th scope="col">CHEQUE DATE</th>
                            <th scope="col">CONTACT</th>
                            <th scope="col">INVOICE #</th>
                            {(this.state.active_tab==='received' || this.state.active_tab==='sent') &&
                              <th scope="col">PAYMENT #</th>
                            }
                            <th scope="col">AMOUNT</th>
                            <th scope="col" colSpan={2}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.cheques_list.length > 0) ?this.state.cheques_list.map( (value, index) => {
                            let cheque_management_id = value.cheque_management_id;
                           return (
                            <tr key={ index } id={"billing-row-"+cheque_management_id} className="contact-row" data-id={index}>
                              <td>
                                
                                
                                    <DrawerLink target="cheque" url={''} name={value.cheque_sequence} info={value} width="85%"/>
                                  
                              </td>
                              <td>{value.payment_mode_info.payment_mode}</td>
                              <td>{value.cheque_no}</td>
                              <td>{value.cheque_date}</td>
                              <td><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} target="_blank"/></td>
                              <td>
                              {(value.invoice_info.length>0 && value.invoice_type!=='expenses') &&                                
                                <span style={{fontSize:'12px'}}>
                                  &nbsp;
                                    {
                                      value.invoice_info.map((pmv) => (
                                        <>
                                        &nbsp;
                                        <DrawerLink target="billing" url={pmv.resource_url} schema={pmv.schema} name={pmv.sequence} />
                                        &nbsp;
                                        </>
                                      ))
                                    }
                                  
                                </span>


                                }
                                {(value.invoice_id==='0') &&
                                    <span>
                                      {value.alt_invoice_no}
                                    </span>
                                }
                                {(value.invoice_info.length>0 && value.invoice_type==='expenses') &&                                
                                <span style={{fontSize:'12px'}}>
                                  &nbsp;
                                    {
                                      value.invoice_info.map((pmv) => (
                                        <>
                                        &nbsp;
                                          <Link link={pmv.resource_url} name={pmv.sequence} target="_blank"/>
                                        &nbsp;
                                        </>
                                      ))
                                    }
                                </span>
                                }
                              </td>
                              {(this.state.active_tab==='received' || this.state.active_tab==='sent') &&
                                <td>
                                  <Link link={'/view-payment/'+value.payment_info.payment_id} name={value.payment_info.payment_number} target="_blank"/>
                                  
                                  {(value.payment_info.length>0) &&                                
                                <span style={{fontSize:'12px'}}>
                                  &nbsp;
                                    {
                                      value.payment_info.map((pmv) => (
                                        <>
                                        &nbsp;
                                        <DrawerLink target="payment" url={pmv.resource_url} schema={pmv.schema} name={pmv.sequence} />
                                        &nbsp;
                                        </>
                                      ))
                                    }
                                </span>
                                }
                                  
                                  </td>
                              }
                              <td>{utils.currency_format(value.amount)}</td>
                                {(value.cheque_status==0) &&
                              <td style={{width:'100px'}}>
                                  <button type="button" className="btn btn-success" onClick={this.handleChequeAction} data-target="reconciliation" data-status={value.cheque_status} data-id={cheque_management_id}>Reconcile</button>
                              </td>
                                }
                                {(value.cheque_status==1) &&
                                <td style={{width:'120px'}}>
                                    <button type="button" className="btn btn-success" onClick={this.handleChequeAction} data-target="payment-received" data-status={value.cheque_status} data-id={cheque_management_id}>Payment Received</button>
                                </td>
                                }
                                {(value.cheque_status==0 || value.cheque_status==1) &&
                              <td style={{width:'95px'}}>
                                  <button type="button" className="btn btn-danger" onClick={this.handleChequeAction} data-target="reject" data-status={value.cheque_status} data-id={cheque_management_id}>Reject</button>
                              </td>
                                }
                               
                            </tr>
                          )
                         }) : <tr><td colSpan={9} className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        </table>
                        {(this.state.cheques_list && this.state.cheques_list.length > 0) &&
                          <ListCount list={this.state.cheques_list}/>
                        }
                        {(this.state.is_search===false) &&
                          <Pager total_page={this.state.total_page}/>
                        }
                        </>
                      }
                      {(this.state.active_tab==='pdc-report') &&
                        <>
                        <div style={{padding:'20px 10px'}}>
                        <div className="row">
                          <div className="col-md-3">
                            <label className="lbl">FOR</label>
                            <select className="form-control" onChange={this.handlePDCReportFilter} id="pdc-report-filter">
                              <option value="this_month">This Month</option>
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="this_week">This Week</option>
                              <option value="last_week">Last Week</option>
                              <option value="last_30_days">Last 30 Days</option>
                              <option value="last_month">Last Month</option>
                              <option value="this_quarter">This Quarter</option>
                              <option value="last_quarter">Last Quarter</option>
                              <option value="this_year">This Year</option>
                              <option value="last_year">Last Year</option>
                              <option value="other">Custom</option>
                            </select>
                          </div>
                          {(this.state.filter_option==='other') &&
                          <>
                            <div className="col-md-3">
                              <label className="lbl">FROM</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <label className="lbl">TO</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                            </div>
                            </>
                          }
                          
                          <div className="col-md-1" style={{marginTop:'29px'}}>
                            <button type="button" className="btn btn-success" onClick={this.handleShowPDCReport} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                          
                        </div>
                        </div>
                        {(this.state.is_loading_pdc_report===true && this.state.progress<=100) &&
                        <div className="row">
                          <div className="col-md-12">
                              <div className="csv-load-blk" style={{paddingTop:'10px',paddingBottom:'0px'}}>
                                <div className="parent-bar">
                                  <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                </div>
                                <p>Please wait while generating pdc report ({this.state.progress}%)</p>
                              </div>
                          </div>
                        </div>
                      }
                        <table className="table">
                          <thead>
                            <tr>
                              <th>DATE</th>
                              <th style={{textAlign:'center'}}>NO. OF CHEQUES</th>
                            </tr>
                          </thead>
                          <tbody>
                              {this.state.cheque_dates.map((value, index)=>{
                                return(
                                  <>
                                  <tr style={{background:'#f7ffff'}}>
                                    <th>{value}</th>
                                    <th style={{textAlign:'center'}}>
                                      {(this.state.cheques_list && this.state.cheques_list[value])?this.state.cheques_list[value].length:0}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td colSpan={3}>
                                      <table className="table">
                                        <thead className="thead-light">
                                        <tr>
                                          <th scope="col">CHEQUE#</th>
                                          <th scope="col">CHEQUE NAME</th>
                                          <th scope="col">CHEQUE NO</th>
                                          {/* <th scope="col">CHEQUE DATE</th> */}
                                          <th scope="col">CONTACT</th>
                                          <th scope="col">INVOICE #</th>
                                          {(this.state.active_tab==='received' || this.state.active_tab==='sent') &&
                                            <th scope="col">PAYMENT #</th>
                                          }
                                          <th scope="col">AMOUNT</th>
                                          <th scope="col" colSpan={2}></th>
                                        </tr>
                                          </thead>
                                          <tbody>
                                        {(this.state.cheques_list && this.state.cheques_list[value]) ?this.state.cheques_list[value].map( (value, index) => {
                                          let cheque_management_id = value.cheque_management_id;
                                          return (
                                              <tr key={ index } id={"billing-row-"+cheque_management_id} className="contact-row" data-id={index}>
                                                <td>
                                                  
                                                  
                                                      <DrawerLink target="cheque" url={''} name={value.cheque_sequence} info={value}/>
                                                    
                                                </td>
                                                <td>{value.payment_mode_info.payment_mode}</td>
                                                <td>{value.cheque_no}</td>
                                               {/*  <td>{value.cheque_date}</td> */}
                                                <td><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} target="_blank"/></td>
                                                <td>
                                                {(value.invoice_info.length>0 && value.invoice_type!=='expenses') &&                                
                                                  <span style={{fontSize:'12px'}}>
                                                    &nbsp;
                                                      {
                                                        value.invoice_info.map((pmv) => (
                                                          <>
                                                          &nbsp;
                                                          <DrawerLink target="billing" url={pmv.resource_url} schema={pmv.schema} name={pmv.sequence} />
                                                          &nbsp;
                                                          </>
                                                        ))
                                                      }
                                                    
                                                  </span>


                                                  }
                                                  {(value.invoice_id==='0') &&
                                                      <span>
                                                        {value.alt_invoice_no}
                                                      </span>
                                                  }
                                                  {(value.invoice_info.length>0 && value.invoice_type==='expenses') &&                                
                                                  <span style={{fontSize:'12px'}}>
                                                    &nbsp;
                                                      {
                                                        value.invoice_info.map((pmv) => (
                                                          <>
                                                          &nbsp;
                                                            <Link link={pmv.resource_url} name={pmv.sequence} target="_blank"/>
                                                          &nbsp;
                                                          </>
                                                        ))
                                                      }
                                                  </span>
                                                  }
                                                </td>
                                                {(this.state.active_tab==='received' || this.state.active_tab==='sent') &&
                                                  <td><Link link={'/view-payment/'+value.payment_info.payment_id} name={value.payment_info.payment_number} target="_blank"/></td>
                                                }
                                                <td>₹{utils.currency_format(value.amount)}</td>
                                                  {(value.cheque_status==0) &&
                                                <td style={{width:'100px'}}>
                                                    <button type="button" className="btn btn-success" onClick={this.handleChequeAction} data-target="reconciliation" data-status={value.cheque_status} data-id={cheque_management_id}>Reconcile</button>
                                                </td>
                                                  }
                                                  {(value.cheque_status==1) &&
                                                  <td style={{width:'120px'}}>
                                                      <button type="button" className="btn btn-success" onClick={this.handleChequeAction} data-target="payment-received" data-status={value.cheque_status} data-id={cheque_management_id}>Payment Received</button>
                                                  </td>
                                                  }
                                                  {(value.cheque_status==0 || value.cheque_status==1) &&
                                                <td style={{width:'95px'}}>
                                                    <button type="button" className="btn btn-danger" onClick={this.handleChequeAction} data-target="reject" data-status={value.cheque_status} data-id={cheque_management_id}>Reject</button>
                                                </td>
                                                  }
                                                
                                              </tr>
                                            )
                                          }) : <tr><td colSpan={8} className="text-center">{this.state.loading_process}</td></tr> }
                                          <tr>
                                            <th colSpan={5}></th>
                                            <th>₹{utils.currency_format(this.state.dates_amount[value])}</th>
                                            <th colSpan={2}></th>
                                          </tr>
                                          </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </>
                                )
                              })}
                              {(this.state.cheque_dates.length===0) &&
                                <tr>
                                  <td colSpan={2} style={{textAlign:'center'}}>{this.state.loading_process}</td>
                                </tr>
                              }
                          </tbody>
                        </table>
                        </>
                      }
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.cheque_action_type==='reject') &&
                <div id='reject-cheque-popup' className="modal" style={{display:'block'}}>
                <div className="modal-dialog modal-confirm">
                  <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box">
                        <span className="material-icons">&times;</span>
                      </div>            
                      <h4 className="modal-title w-100">{this.state.cheque_action_msg}</h4>  
                    </div>
                    <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                          <textarea className="form-control" id="cheque_notes" cols={10} placeholder={this.state.cheque_action_place_holder}></textarea>
                        </div>
                      
                        <div className="col-md-12 text-left mrtop-10">
                          <label className="lbl">REJECTED DATE</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text no-border-right">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </span>
                              <DatePicker className="form-control  no-border-left" selected={this.state.notesDate} onChange={this.notesDate} dateFormat="dd/MM/yyyy"/>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>No</button>
                      <button type="button" className="btn btn-danger" onClick={this.handleProceedChequeAction} id="cheque-action">Yes</button>
                      <button type="button" id="cheque-action-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                  </div>
                </div>
              </div> 
              }
                {(this.state.cheque_action_type==='reconciliation' || this.state.cheque_action_type==='payment-received') &&
                <div id='reject-cheque-popup' className="modal" style={{display:'block'}}>
                <div className={"modal-dialog modal-confirm "+this.state.cheque_action_type}>
                  <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box">
                        <span className="material-icons">&#10003;</span>
                      </div>            
                      <h4 className="modal-title w-100">{this.state.cheque_action_msg}</h4>  
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <textarea className="form-control" id="cheque_notes" cols={10} placeholder={this.state.cheque_action_place_holder}></textarea>
                        </div>
                        
                          <div className="col-md-12 text-left">
                            <label className="lbl">ACTION DATE</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.notesDate} onChange={this.notesDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>No</button>
                      <button type="button" className={(this.state.cheque_action_type==='reject')?"btn btn-danger":"btn btn-success"} onClick={this.handleProceedChequeAction} id="cheque-action">Yes</button>
                      <button type="button" id="cheque-action-btn-spinner" className={(this.state.cheque_action_type==='reject')?"hide btn btn-danger":"hide btn btn-success"}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                  </div>
                </div>
              </div> 
              }
            </div>
        );
    }

    handleClosePopup = (e)=>{
      this.setState({cheque_action_type:''});
    }
    handleProceedChequeAction = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let notes = '';
      if(document.getElementById("cheque_notes")){
        notes = document.getElementById("cheque_notes").value;
      }
        let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              id : this.state.cheque_action_id,
              action : this.state.cheque_action_type,
              notes : notes,
              date : this.state.notesDate,
          }
        let api = app_api+'/proceed_cheque_action';
        axios.post(api, body)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
          window.location.reload();
      }.bind(this)).catch(function(res){
        document.getElementById('cheque-action').classList.remove("hide");
        document.getElementById('cheque-action-btn-spinner').classList.add("hide");
      })
    }

    handleChequeAction = (e)=>{
      let id = e.currentTarget.getAttribute('data-id');
      let status = e.currentTarget.getAttribute('data-status');
      this.setState({cheque_action_id:id});
      let target = e.currentTarget.getAttribute('data-target');
      this.setState({cheque_action_type:target});
      this.setState({cheque_action_status:status});

      let placeholder = '';
      if(target==='reconciliation'){
        placeholder = 'Please enter reconciliation notes if any';
      }else if(target==='payment-received'){
        placeholder = 'Please enter payment received note if any';
      }else if(target==='reject'){
        placeholder = 'Please enter reject note if any';
      }
      this.setState({cheque_action_place_holder:placeholder});
      let msg = '';
      if(target==='reconciliation'){
        msg = 'Do you want to send this to bank for reconciliation?';
      }else if(target==='payment-received'){
        msg = 'Did you receive the payment?';
      }else if(target==='reject'){
        msg = 'Do you want to reject this cheque?';
      }
      this.setState({cheque_action_msg:msg});
    }
}