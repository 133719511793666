import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import check_access from './access-level.js';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');

var CryptoJS = require("crypto-js");
let ProductCategories = [];
let _this;
let db;
export default class ProductCategory extends Component {
    constructor() {
      super();
      this.state = {
          product_categories : [],
          product_categories_drop : [],
          modeDefault : 0,
      };
      _this = this;
      this.editMode = this.editMode.bind(this);
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleProductCategoryRefresh = this.handleProductCategoryRefresh.bind(this);
      this.setProductState = this.setProductState.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    handleChange(e){
        this.setState({modeDefault:e.target.value});
        if(this.props.target==='inventory-bulk-edit'){
          localStorage.setItem('selected_category',e.target.value);
          document.getElementById("loadItemOnCategory").click();
        }
    }
    setProductState(e){
        let pc = localStorage.getItem('selected_product');
        this.setState({modeDefault:pc});
        localStorage.removeItem('selected_product');
    }
    componentDidMount(){

      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_categories';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            cursor.map( (value, key) => {
                let id = value.category_id;
                let category = value.category;
                ProductCategories[id] = {'name':category,'parent':value.parent};
            });
            this.setState({ 'product_categories': cursor }); 
            this.setState({ 'product_categories_drop': cursor }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    handleProductCategoryRefresh(e){
      ProductCategories = [];
      let body = {};
      body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      let api = app_api+'/get_categories';
      axios.post(api, body)
        .then(function (response) {
            let default_id = localStorage.getItem("new-category-id");
          if(document.getElementById("create-category-button")){
            document.getElementById("create-category-button").classList.remove("hide");
            document.getElementById("create-category-spinner").classList.add("hide");
            this.setState({modeDefault:default_id});
            localStorage.removeItem("new-category-id");
          }else if(default_id!==null && default_id!==""){
            this.setState({modeDefault:default_id});
            localStorage.removeItem("new-category-id");
          }
          let cursor = response.data;
          cursor.map( (value, key) => {
              let id = value.category_id;
              let category = value.category;
              ProductCategories[id] = {'name':category,'parent':value.parent};
          });
          this.setState({ 'product_categories': cursor }); 
          this.setState({ 'product_categories_drop': cursor }); 
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }
    editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      let parent_id = event.currentTarget.getAttribute('data-id');
      
      if(parent_id===undefined || parent_id===null || parent_id===""){
        parent_id = 0;
      }
      var text = document.getElementById('pc-txt-'+id).value;
      var short_name = document.getElementById('pc-short-name-'+id).value;
      var sequence = document.getElementById('pc-bc-sequence-'+id).value;
      var element = document.getElementById('add_category');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("add-category-title").innerHTML = 'UPDATE CATEGORY';
      
      document.getElementById("update_category_id").value = id;
      document.getElementById("category").value = text;
      document.getElementById("parent_category").value = parent_id;
      document.getElementById("category_short_name").value = short_name;
      document.getElementById("category_sequence").value = sequence;
      
/* 
      var span = document.getElementById('pc-span-'+id);
      var normal = document.getElementById('pc-normal-mode-'+id);
      var edit = document.getElementById('pc-edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show"); */
    }

    updateRow(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pc-span-'+id);
      var text = document.getElementById('pc-txt-'+id);
      var normal = document.getElementById('pc-normal-mode-'+id);
      var edit = document.getElementById('pc-edit-mode-'+id);
      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            category : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      let api = app_api+'/update_category';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
      
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pc-span-'+id);
      var text = document.getElementById('pc-txt-'+id);
      var normal = document.getElementById('pc-normal-mode-'+id);
      var edit = document.getElementById('pc-edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
     deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
   
    render() {
        return (
          <div>
            <span onClick={this.handleProductCategoryRefresh} id="product-category-refresh"></span>
            <span onClick={this.setProductState} id="setProductState"></span>
            {this.props.show==='list' &&
            <table className="table">
              <tbody>
                  {(this.state.product_categories.length > 0) ?this.state.product_categories.map( (value, index) => {
                   return (
                    <tr key={ index }  id={'delete_category_'+value.category_id}>
                      <td>
                        <span id={'pc-span-'+value.category_id } className="show l-span" style={{marginLeft:value.pixel}}>
                          { value.category } ({value.inventory_count})
                        </span>
                        <input type="text" defaultValue={value.category} className="form-control hide" id={'pc-txt-'+value.category_id}/>
                        <input type="hidden" defaultValue={value.short_name} id={'pc-short-name-'+value.category_id}/>
                        <input type="hidden" defaultValue={value.current_sequence} id={'pc-bc-sequence-'+value.category_id}/>
                      </td>
                      <td>
                        <div id={'pc-normal-mode-'+value.category_id} className="show">
                        <span>{value.current_sequence}</span>
                        {this.is_access('settings-inventory-modify') &&
                          <FontAwesomeIcon id={value.category_id} data-id={value.parent} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                        }
                        {(this.is_access('settings-inventory-delete') && value.category_id>1) &&
                          <FontAwesomeIcon id={value.category_id} onClick={this.deleteRow} data_target="delete_confirm" data_value={value.category} data_id="delete_category" icon={faTrash} className="pull-right fa-icon"/>
                        }
                        </div>
                        <div id={'pc-edit-mode-'+value.category_id} className="hide mrtop-10">
                        {this.is_access('settings-inventory-modify') &&
                          <>
                            <FontAwesomeIcon id={value.category_id} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                            <FontAwesomeIcon id={value.category_id} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                          </>
                        }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody> 
            </table>
            }       
            {this.props.show==='dropdown' &&   
              <select className="form-control form-field" name={this.props.name}  value={this.state.modeDefault||this.props.default} onChange={this.handleChange} tabindex={this.props.tabindex} id={this.props.id} multiple={this.props.multiple}>
                  <>
                    {(this.props.target==='create_category') &&
                      <option value="0">Parent Category</option>
                    }
                    {(this.props.target!=='create_category') &&
                      <option value="">Select Category</option>
                    }
                  </>
                  {(this.state.product_categories_drop.length > 0) ? this.state.product_categories_drop.map( (value, index) => {
                   return (
                      <option value={value.category_id} key={value.category_id} dangerouslySetInnerHTML={{__html: value.option_name}}></option>
                    )
                  }) : ''}
              </select>
            }     
            </div>
        );
    }
}