import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import utils from '../utils.js';
export default class AdjustablePrint extends Component {
    constructor(props) {
        super();
        this.state = {
           
        };
        this.isMobile = utils.isMobile.bind(this);      
    }

    componentDidMount(){
        
        if(document.getElementById("adj-print-main-container")){
          var divContents = document.getElementById("adj-print-main-container").innerHTML; 
        try{
            var a = window.open('', '', 'height=1000,location=no,left=250px'); 
            a.document.open(); 
            a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

            a.document.write('<style type="text/css">@page{size:A4,margin:5cm}.table{font-size:12px;}.table .thead-light th{font-size:11px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table td,.table th{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}th{text-align:inherit}.row {display: flex;flex-wrap: wrap;margin-right: -15px;margin-left: -15px;}.row .col-md-3 {flex: 0 0 25%;max-width: 25%;}h4{margin-top: 0px;margin-bottom: 5px;}.items-row td{line-height: 0.9 !important;}.items-row .bcode-sep{margin:0px 5px;}.table-responsive{margin:5px 0px 0px 0px !important;overflow-x: unset;overflow-y: unset;border: none;}form{margin-bottom:0px !important}.t-bottom-area .col-md-6 {flex: 0 0 50%;max-width: 50%;} .col-md-12 {flex: 0 0 100%;max-width: 100%;}.t-bottom-area p{margin-bottom:2px}.table.cell-border>tbody>tr>td, .table.cell-border>tbody>tr>th, .table.cell-border>tfoot>tr>td, .table.cell-border>tfoot>tr>th, .table.cell-border>thead>tr>td, .table.cell-border>thead>tr>th{border-left:1px solid #333}.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{border-top: 1px solid #333;}.table>thead>tr>th {border-bottom: 1px solid #333;}.signature{font-family:"Dancing Script", cursive;text-transform:capitalize;font-weight:bold;font-size:15px}h4{margin-bottom:0px}.table{margin:0px}.col-md-4 { flex: 0 0 33.333333%;max-width: 33.333333%;}</style>'); 
          
            a.document.write('</head>'); 
      
            a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
           // a.document.write('<script>function afterPrint() {window.close();}</script>'); 
            
          a.document.write(divContents); 
          a.document.write('</body></html>');
          a.document.close();
          
        }catch{}
      }
} 
    render() {
        let total_pending = 0;
        let total_payments = 0;
        let total_others =0;
        return (
                <>
                    <div className="view-on-fly" id="adj-print-main-container">

                        <table className="table">
                            <thead>
                                <tr>
                                <th colSpan={2} style={{textTransform:'uppercase',textAlign:'center'}}>
                                    <h3 style={{textTransform:'capitalize'}}><u>Payment Voucher</u></h3>
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'25px'}}>
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info)?this.props.adjusted_data.company_info[0].company_name:''
                                        }
                                    </h2>
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0]!==undefined && this.props.adjusted_data.company_info[0].company_sub_title!=="" && this.props.adjusted_data.company_info[0].company_sub_title!==null) &&
                                        <>
                                        <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.props.adjusted_data.company_info[0].company_sub_title}</p>
                                        </>
                                        }
                                    
                                  
                                        <p style={{margin:'0px'}}>
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].address!=='' && this.props.adjusted_data.company_info[0].address!==null)?this.props.adjusted_data.company_info[0].address:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].city!=='' && this.props.adjusted_data.company_info[0].city!==null)?this.props.adjusted_data.company_info[0].city:''
                                        }
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].pin_code!=='' && this.props.adjusted_data.company_info[0].pin_code!==null )?'-'+this.props.adjusted_data.company_info[0].pin_code:''
                                        }
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].state!=='' && this.props.adjusted_data.company_info[0].state!==null )?' '+this.props.adjusted_data.company_info[0].state:''
                                        }
                                        </p>
                                        {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].phone!=='') &&
                                        <p style={{margin:'0px'}}>
                                       
                                            {(this.props.adjusted_data && this.props.adjusted_data.company_info && this.props.adjusted_data.company_info[0].phone!=="" && this.props.adjusted_data.company_info[0].phone!==null)?this.props.adjusted_data.company_info[0].phone:''
                                            }
                                            
                                        </p>
                                        }
                                        
                                </th>
                            </tr>
                                <tr>
                                    <th>
                                        <h3 style={{margin:'0px'}}>{this.props.adjusted_data.party_info.name}</h3>
                                        {(this.props.adjusted_data.party_info.phone_1!=="") &&
                                            <p style={{margin:'0px',color:'gray',fontSize:'16px',fontWeight:'normal'}}>
                                                {this.props.adjusted_data.party_info.phone_1}
                                                {(this.props.adjusted_data.party_info.phone_1!=="" && this.props.adjusted_data.party_info.phone_2!=="" && this.props.adjusted_data.party_info.phone_2!==null) &&
                                                    <> | </>
                                                }
                                                {this.props.adjusted_data.party_info.phone_2}
                                            </p>
                                        }
                                        {(this.props.adjusted_data.party_info.email!=="") &&
                                            <p style={{margin:'0px',color:'gray',fontSize:'16px',fontWeight:'normal'}}>
                                                {this.props.adjusted_data.party_info.email}
                                            </p>
                                        }
                                        {(this.props.adjusted_data.party_info.address_1!=="") &&
                                            <p style={{margin:'0px',color:'gray',fontSize:'16px',fontWeight:'normal'}}>
                                                {this.props.adjusted_data.party_info.address_1}
                                            </p>
                                        }
                                        {(this.props.adjusted_data.party_info.address_2!=="") &&
                                            <p style={{margin:'0px',color:'gray',fontSize:'16px',fontWeight:'normal'}}>
                                                {this.props.adjusted_data.party_info.address_2}
                                            </p>
                                        }
                                    </th>
                                    <th className="text-right">
                                        <h3 style={{color:'gray',margin:'0px'}}>{this.props.adjusted_data.adjusted_sequence}</h3>
                                        <p style={{margin:'0px',color:'gray',fontSize:'16px',fontWeight:'normal'}}>{this.props.adjusted_data.created_date}</p>
                                    </th>
                                </tr>
                                {/* <tr>
                                    <td colSpan={2}>
                                    <table className="table">
                                        <thead style={{textAlign:'center'}}>
                                            <tr>
                                                <th>
                                                    <b>PENDING AMOUNT</b>
                                                    <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.pending_amount)}</h4>
                                                </th>
                                           
                                                <th>
                                                    <b>( - ) PAYMENTS</b>
                                                    <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.payment_amount)}</h4>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <b>( - ) RTGS/CR-DR NOTE</b>
                                                    <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.rtgs)}</h4>
                                                </th>
                                                <th>
                                                    <b>( - ) OTHER CHARGES</b>
                                                    <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.other_charges)}</h4>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    </td>
                                </tr> */}
                            </thead>
                           
                        </table>
                        <h3 style={{marginBottom:'10px',borderBottom:'1px solid #333'}}>BILL SUMMARY</h3>
                        <div className="row">
                            <div className="col-md-4" style={{borderRight:'1px solid #333',paddingRight:'5px'}}>
                         
                        <table className="table"  style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th colSpan={2}><b>BILLS</b></th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th style={{textAlign:'left'}}>Invoice#</th>
                                    <th style={{textAlign:'right'}}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(this.props.adjusted_data.pending_bills.map((value,idx)=>{
                                total_pending = total_pending + parseFloat(value.adjusted_amount);
                                    return (
                                        <tr>
                                            <td style={{textAlign:'left'}}>
                                            <p style={{margin:'0px'}}>{value.sequence}</p>
                                            {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                            }
                                            </td>
                                            <td style={{textAlign:'right'}}>₹{utils.currency_format(value.adjusted_amount)}</td>
                                        </tr>
                                    )
                            }))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th></th>
                                    <th style={{textAlign:'right'}}>₹{utils.currency_format(total_pending)}</th>
                                </tr>
                            </tfoot>
                        </table>
                        
                            </div>
                           
                            <div className="col-md-4" style={{paddingRight:'5px',paddingLeft:'5px'}}>
                           
                                <table className="table"  style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>
                                                <b>RETURNS/DR-CR NOTES</b>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'left'}}>Invoice#</th>
                                            <th style={{textAlign:'right'}}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.others && this.props.adjusted_data.adjusted_bills.others.map((value,idx)=>{
                                        total_others = total_others + parseFloat(value.adjusted_amount);
                                            return (
                                            
                                                    <tr>
                                                        <td style={{textAlign:'left'}}>
                                                        <p style={{margin:'0px'}}>{value.sequence}</p>
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                            <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                        }
                                                        </td>
                                                        <td style={{textAlign:'right'}}>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                    </tr>
                                            
                                            )
                                    }))}
                                    {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.others && this.props.adjusted_data.adjusted_bills.others.length===0) &&
                                    <tr>
                                            <td colSpan={2}>No Items found</td>
                                        </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th style={{textAlign:'right'}}>₹{utils.currency_format(total_others)}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                
                            </div>
                            <div className="col-md-4"  style={{borderLeft:'1px solid #333',paddingLeft:'5px'}}>
                           
                           <table className="table" style={{width:'100%'}}>
                               <thead>
                                   <tr>
                                       <th colSpan={2}>PAYMENTS</th>
                                   </tr>
                                   <tr>
                                       <th style={{textAlign:'left'}}>Payment#</th>
                                       <th style={{textAlign:'right'}}>Amount</th>
                                   </tr>
                               </thead>
                               <tbody>
                               {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.payments && this.props.adjusted_data.adjusted_bills.payments.map((value,idx)=>{
                                   total_payments = total_payments + parseFloat(value.adjusted_amount);
                                       return (
                                       
                                               <tr>
                                                   <td style={{textAlign:'left'}}>
                                                   <p style={{margin:'0px'}}>{value.sequence}</p>
                                                    <span style={{color:'gray',fontSize:'14px'}}>{value.payment_mode}</span>
                                                    </td>
                                                   <td style={{textAlign:'right'}}>₹{utils.currency_format(value.adjusted_amount)}</td>
                                               </tr>
                                       
                                       )
                               }))}
                                   {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.payments && this.props.adjusted_data.adjusted_bills.payments.length===0) &&
                               <tr>
                                       <td colSpan={2}>No Items found</td>
                                   </tr>
                                   }
                               </tbody>
                               <tfoot>
                                   <tr>
                                       <th></th>
                                       <th style={{textAlign:'right'}}>₹{utils.currency_format(total_payments)}</th>
                                   </tr>
                               </tfoot>
                           </table>
                           
                         </div>
                        </div>
                        <div className="row" style={{marginTop:'10px'}}>
                            <div className="col-md-12">
                                <table className="table"  style={{width:'100%',borderBottom:'1px solid #333'}}>
                                    <tfoot>
                                        <tr style={{fontSize:'18px'}}>
                                            <th style={{textAlign:'left'}}>
                                                {utils.convert_number_to_words(this.props.adjusted_data.meta_value.pending_amount)}
                                            </th>
                                            <th style={{textAlign:'right'}}>
                                            ₹{utils.currency_format(this.props.adjusted_data.meta_value.payment_amount)}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div> 
                   
                </>
        );
    }
   
}