import React, { Component } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class DownloadProducts extends Component {
    constructor() {
        super();
        this.state = {
            filter_option : 'all',
            progress :0,
            number_of_page :0,
            is_downloading :false,
            download_inventory_list :[],
            FromDate:  new Date(),
          ToDate: new Date(),
        };   
    }
    

    FromDate = date => {
    
      if(date!==null){
        this.setState({
          FromDate: date
        });
      }
    }
    ToDate = date => {
      if(date!==null){
        this.setState({
          ToDate: date
        });
      }
    }
    handleCalendarClick = (e) =>{
      let id = e.currentTarget.id;
      document.getElementById(id+'-date').focus();
    }

    componentDidMount(){
      
    }
    handleDownloadXLS = () =>{
    
        this.setState({progress:0});
        this.setState({number_of_page:0});
        this.setState({download_inventory_list:[]});
        this.setState({is_downloading:true});
        this.handleProcessDownloadXLS(1);
      }
  
      handleProcessDownloadXLS = (cpage) =>{
        let download_inventory_list = this.state.download_inventory_list;
         let body = {
              page : cpage,
              target:'product',
              number_of_page : this.state.number_of_page,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              type : 'Active',
              filter_option : this.state.filter_option,
              FromDate : this.state.FromDate,
              ToDate : this.state.ToDate,
          }
        
          let api = app_api+'/download_products';
          axios.post(api, body)
            .then(function (response) {
                let number_of_page = response.data.number_of_page;
                let progress = (cpage / number_of_page) * 100;
                progress = utils.currency_format(progress);
                progress = Math.round(progress);
                this.setState({number_of_page:number_of_page});
                if(progress>100){
                    progress =100;
                }
                this.setState({progress:progress});
                if(response.data.is_empty){
                  document.getElementById('donwload-pro-xls-button').click();
                  this.setState({is_downloading:false});
                  let _this = this;
                  setTimeout(() => {
                    
                    _this.setState({progress:0});
                    _this.setState({number_of_page:0});
                    _this.setState({download_inventory_list:[]});
                    }, 1000);
                }else{
                  let nextp = cpage+1;
                  let res_data =  response.data.data;
                  for (let index = 0; index < res_data.length; index++) {
                    const element = res_data[index];
                    download_inventory_list.push(element);
                  }
                  this.setState({download_inventory_list: download_inventory_list });
                  this.handleProcessDownloadXLS(nextp);
                }
          }.bind(this)).catch(function(res){
            this.setState({is_downloading:false});
          }.bind(this))
          
      }
      handleReportFilter=(e)=>{
        let thisvalue = e.currentTarget.value;
        this.setState({filter_option:thisvalue});
      }
    render() {
        return (
          <div className="row">
            <div className="col-md-3">

                <select className="form-control" onChange={this.handleReportFilter} style={{marginRight:'10px'}}>
                  <option value="all">All</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="last_week">Last Week</option>
                  <option value="last_30_days">Last 30 Days</option>
                  <option value="this_month">This Month</option>
                  <option value="last_month">Last Month</option>
                  {/* <option value="this_quarter">This Quarter</option>
                  <option value="last_quarter">Last Quarter</option>
                  <option value="this_year">This Year</option>
                  <option value="last_year">Last Year</option> */}
                  <option value="other">Custom</option>
                </select>
            </div>
                {(this.state.filter_option==='other') &&
            <>
              
              <div className="col-md-3">   
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                    <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="cal-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                    <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                  </div>
                </div>
              </div>
              </>
            }
            <div className="col-md-3">
                {(this.state.is_downloading===false) &&
                    <button className="btn btn-success" onClick={this.handleDownloadXLS}>Download</button>
                }
                {(this.state.is_downloading===true) &&
                    <button className="btn btn-success" onClick={this.handleDownloadXLS}>
                        <FontAwesomeIcon icon={faSpinner} pulse/> <span>({this.state.progress}%)</span>
                    </button>
                }
            </div>
                <ReactHTMLTableToExcel
                id="donwload-pro-xls-button"
                className="btn btn-success hide"
                table="download-pro-tbl"
                filename="Products"
                sheet="Products"
                buttonText="Download as XLS"/>
                <table className="table hide" id="download-pro-tbl">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">PRODUCT NAME</th>
                            <th scope="col">PRODUCT DESC</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">AVAILABILITY</th>
                            <th scope="col">PURCHASE PRICE</th>
                            <th scope="col">SELLING PRICE</th>
                            <th scope="col">MARGIN</th>
                            <th scope="col">TOTAL STOCK AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.download_inventory_list!=='' && this.state.download_inventory_list.length > 0) ?this.state.download_inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                             
                              <td>{value.item_name}</td>
                              <td>{value.item_desc}</td>

                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category} style={{cursor:'pointer'}} dangerouslySetInnerHTML={{ __html: value.category}}>
                              </td>
                           
                              <td> 
                                {(value.varient>0) &&
                                <>
                                  {(value.child_barcode_info.length>1) &&
                                    <>{value.availability+' in stock for '+value.varient + ' variants'}</>
                                  }
                                  {(value.child_barcode_info.length===1) &&
                                    <span>{value.availability+' in stock for '+value.varient + ' variants'}</span>
                                  }
                                </>
                                }
                                {(value.varient===0) &&
                                <>
                                {(value.child_barcode_info.length>1) &&
                                  <>{value.availability+' in stock'} </>
                                }
                                {(value.child_barcode_info.length===1) &&
                                    <span>{value.availability+' in stock'}</span>
                                }
                                </>
                                }
                              </td>
                              <td>{value.purchase_price}</td>
                              <td>{value.selling_price}</td>
                              <td>{value.margin}%</td>
                              <td>{value.stock_total_amount}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan="11" className="text-center"></td></tr> }
                        </tbody>
                        </table>
            </div>
            
        
        );
    }
}