import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import ContactsDropdown from "./contacts-dropdown";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faArrowLeft,faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import AddPaymentItem from './add-payment-item';
import ContactField from './contact-field';
import ClosedFYButton from "./closed-fy-button";
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import PaymentModes from "./payment-modes";
import ModalBox from "./modal-box";
import ServiceLoader from './service-loader.js';
import encrypt_decrypt from './encrypt-decrypt.js';
import ChequeInputInfo from "./cheque-input-info";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class NewPayment extends Component {
    constructor() {
        super();
        this.state = {
            is_invoice_payment : false,
            payment_target : 'billing',
            invoice_payment : '',
            show_loader : true,
            save_button:'Create Payment',
            add_item_area : '',
            billing_type : 'IN',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  new Date(),
            contact_info : '',
            send_type:'save',
            update_id:0,
            payment_data :'',
            total_balance_amt :'',
            total_grand :'',
            payable_amount :'',
            on_load : true,
            round_off_opt : true,
            round_off_opt2 : false,
            discount_opt : true,
            discount_opt2 : false,
            round_off : '',
            round_off_sym :'',
            paid_amount :'',
            pending_bills : [],
            pending_payments_bills : [],
            pending_loader:'No record(s) found.',
            is_empty_item : false,
            is_contact_selected : 'hide',
            is_payment_opt : false,
            toggle_pending_invoice : false,
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            pay_type_opt :'',
            has_payment_items : false,
            isMobile : false,
            is_fy_closed : false,
            is_cheque_payment : false,
            is_bill_clicked : false,
            clicked_index : '',
            maxDate : null,
            cheque_sequence : '',
            is_show_payment_option : true,
            show_previous_amount : false,
            previous_amount : 0,
            previous_amount_tmp : 0,
        };
        _this = this;
        this.add_item_area = [];
        this.sequence = [];
        this.send_data_items = [];
        this.createPayment = this.createPayment.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.operateCheck = this.operateCheck.bind(this);
        
        this.handleRoundOff = this.handleRoundOff.bind(this);
        this.handleCreateContact = this.handleCreateContact.bind(this);
        this.handleRoundOffChange = this.handleRoundOffChange.bind(this);
        this.handleRefreshPendingBill = this.handleRefreshPendingBill.bind(this);
        this.handlePendingBillSelect = this.handlePendingBillSelect.bind(this);
        
        this.checkEmptyInvoiceList = this.checkEmptyInvoiceList.bind(this);
        this.handeEmptyInvoiceList = this.handeEmptyInvoiceList.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.decrypt_sanitize = encrypt_decrypt.decrypt_sanitize.bind(this);
        this.handleGlobalDiscountChange = this.handleGlobalDiscountChange.bind(this);
        this.handleGlobalDiscount = this.handleGlobalDiscount.bind(this);
        this.handleContactChangeStatus = this.handleContactChangeStatus.bind(this);
        this.handleChangePaymentOption = this.handleChangePaymentOption.bind(this);
        this.handleChangeHasItems = this.handleChangeHasItems.bind(this);
        this.handleTogglePendingInvoice = this.handleTogglePendingInvoice.bind(this);
        this.handleCheckIfMouse = this.handleCheckIfMouse.bind(this);
        this.resetPayment = this.resetPayment.bind(this);
        this.resetPaymentOnContactChange = this.resetPaymentOnContactChange.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }

    handleShowPaymentOption = () =>{
      this.setState({is_show_payment_option:true});
    }
    handleHidePaymentOption = () =>{
      this.setState({is_show_payment_option:false});
    }

    handleEnableChequePayment = () =>{
      //this.setState({is_cheque_payment:true});
    }
    handleDisableChequePayment = () =>{
      //this.setState({is_cheque_payment:false});
    }

    resetPayment(e){
      document.getElementById("contact_box").value = "";
      document.getElementById("customer-dropdown").value = "";
      document.getElementById("old-customer-dropdown").value = "";
      this.setState({is_payment_opt:false});
      this.setState({pay_type_opt:''});
      this.setState({toggle_pending_invoice:false});
      if(document.getElementById("ContactRemoved")){
        document.getElementById("ContactRemoved").click();
      }
      if(document.getElementById("hanlde-force-empty-invoice-list")){
        document.getElementById("hanlde-force-empty-invoice-list").click();
      }
    }
    resetPaymentOnContactChange(e){
      if(this.state.send_type!=='update'){
        this.setState({is_payment_opt:false});
        this.setState({pay_type_opt:''});
        this.setState({toggle_pending_invoice:false});
        if(document.getElementById("hanlde-force-empty-invoice-list")){
          document.getElementById("hanlde-force-empty-invoice-list").click();
        }
        
        let paytypes = document.querySelectorAll('input[name="add-payemnt-option"]');
        for(var i=0;i<paytypes.length;i++){
          paytypes[i].checked = false;
        }
      }
    }

    handleCheckIfMouse(e){
      localStorage.setItem('on_list_click','true');
    }
    handleChangeHasItems(e){
      this.setState({has_payment_items:true});
    }
    handleTogglePendingInvoice(e){
      let toggle_pending_invoice = this.state.toggle_pending_invoice;
      if(toggle_pending_invoice===true){
        toggle_pending_invoice = false;
      }else{
        toggle_pending_invoice = true;
      }
     
      this.setState({toggle_pending_invoice:toggle_pending_invoice});
      if(toggle_pending_invoice===true){
        setTimeout(function(){
          let show_pb_list = document.querySelectorAll('.pb-list');
          let prow = document.querySelectorAll(".payment-item-row");
          for(let i=0;i<show_pb_list.length;i++){
            show_pb_list[i].classList.remove('hide');
            for(let j=0;j<prow.length;j++){
              let tdid = prow[j].getAttribute('data_id');
              if(document.getElementById("item-box-"+tdid)){
                let ono = document.getElementById("item-box-"+tdid).value;
                if(document.getElementById("pending-bill-"+ono)){
                  document.getElementById("pending-bill-"+ono).classList.add('hide');
                }
              }
            }
          }
        },50);
      }
    }
    handleChangePaymentOption(e){
      let thisid = e.currentTarget.id;
      this.setState({is_payment_opt:true});
      if(thisid!=='onaccount' && thisid!=='other'){
        this.setState({toggle_pending_invoice:true});
      }else{
        this.setState({toggle_pending_invoice:false});
      }
      if(this.state.update_id>0){
        let payment_data = this.state.payment_data;
        let paid_amount = parseFloat(payment_data.paid_amount.replace(/,/g , ""));
        let payment_option = payment_data.payment_option;
        if(payment_option!==thisid){
          this.setState({show_previous_amount:true});
          this.setState({previous_amount:paid_amount});
          this.setState({previous_amount_tmp:paid_amount});
        }else{
          this.setState({show_previous_amount:false});
          //this.setState({previous_amount:0});
        }
      }
      this.setState({pay_type_opt:e.currentTarget.id});
      this.setState({has_payment_items:false});
      this.add_item_area=[];
      this.add_item_area.push(<AddPaymentItem payment_type={e.currentTarget.id} count={1} key={1} type="more" index="1" billing_type={this.state.billing_type}/>);
      this.setState({add_item_area:this.add_item_area});
      //this.handleRefreshPendingBill(e);
      let pb_list = document.querySelectorAll(".pb-list");
      let is_empty = true;
      for(let i=0;i<pb_list.length;i++){
          let elem = pb_list[i];
          if(elem.classList.contains('hide')){
            elem.classList.remove('hide');
          }
      }
      if(pb_list.length>0){
        this.setState({is_empty_item:false});
      }
      if(document.getElementById("resetListItem")){
        document.getElementById("resetListItem").click();
      }

      if(thisid==='billwise'){
        if(document.getElementById("hideOnAccountPayment")){
          document.getElementById("hideOnAccountPayment").click();
        }
      }


      let _this =this;
      setTimeout(function(){
        if(thisid==='onaccount' || thisid==='autobillwise' || thisid==='other'){
          if(document.getElementById("openOnAccountPayment")){
            document.getElementById("openOnAccountPayment").click();
          }
        }
        if(document.getElementById("refresh_pending_bill")){
          //_this.handleRefreshPendingBill(e);
          document.getElementById("refresh_pending_bill").click();
        }
        let is_cheque = localStorage.getItem('is_cheque');
        if(is_cheque==='true'){
          let qs = document.querySelectorAll('.pay-cheque-info');
          for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            element.classList.remove('hide')
          }
        }
      },100);
    }
    handleContactChangeStatus(e){
      let id = e.currentTarget.id;
      if(id==='ContactSelected'){
        this.setState({is_contact_selected:''});
      }else{
        this.setState({is_contact_selected:'hide'});
        this.setState({is_payment_opt:false});
        this.setState({pay_type_opt:''});
        this.setState({toggle_pending_invoice:false});
      }
    }

    handleGlobalDiscount(e){
      if(document.getElementById("calculateTotal")){
        document.getElementById("calculateTotal").click();
      }
      /* let global_discount = e.currentTarget.value;
      var item_final_total = document.querySelectorAll('.item-total'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
           item_total = item_total + parseFloat(item_final_total[i].value.replace(/,/g , ""));
      }
    
      let cont_disc = localStorage.getItem('discount');

       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }
       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;

       let gdisc = global_discount;
       if(discount_opt==="rs"){
          item_total = parseFloat(item_total)-global_discount;
          document.getElementById("disc_on_payment").innerHTML = '';
       }else if(discount_opt==="per"){
          gdisc = (parseFloat(item_total)*global_discount)/100;
          gdisc = this.floating_point(gdisc);
          item_total = parseFloat(item_total)-gdisc;
          document.getElementById("disc_on_payment").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
       }
      

       let after_round = Math.round(item_total)-item_total;
      
      if(after_round>=0){
        after_round = Math.ceil(item_total)-item_total;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = item_total-Math.floor(item_total);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      if(round_off!=="" && round_off>0){
          item_total = parseFloat(item_total.toString().replace(/,/g , ""));
          if(round_off_opt==='-'){
              item_total = item_total-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = item_total+parseFloat(round_off);
          }
      }
      document.getElementById("total-amount").innerHTML = this.currency_format(item_total);
      document.getElementById("total-amount-txt").value = item_total; */
    }
    handleGlobalDiscountChange(e){
      if(document.getElementById("calculateTotal")){
        document.getElementById("calculateTotal").click();
      }
    }
    handeEmptyInvoiceList(e){
      this.setState({pending_bills:[],pending_loader:'No record(s) found.'});
    }
    handlePendingBillSelect(e){
      let index = e.currentTarget.getAttribute('index');
      
      this.setState({clicked_index:index});
      let payment_option = document.querySelector('input[name="add-payemnt-option"]:checked').value;
      if(payment_option==='billwise' && this.state.is_cheque_payment===true && this.state.is_bill_clicked===false){
        this.setState({is_bill_clicked:true});
        return false;
      }
      let cheque_date = (document.getElementById("cheque_date"))?document.getElementById("cheque_date").value:'';
      let cheque_bank_name = (document.getElementById("cheque_bank_name"))?document.getElementById("cheque_bank_name").value:'';
      let cheque_no = (document.getElementById("cheque_no"))?document.getElementById("cheque_no").value:'';
      let is_reconciliation = (document.getElementById("send-for-recon"))?document.getElementById("send-for-recon").checked:'';
      if(cheque_date===""){
        cheque_date = (document.getElementById("iitem-cheque-date"))?document.getElementById("iitem-cheque-date").value:'';
      }
      if(is_reconciliation===""){
        is_reconciliation = (document.getElementById("iitem-cheque-is-recon"))?document.getElementById("iitem-cheque-is-recon").value:false;
      }
      if(cheque_no===""){
        cheque_no = (document.getElementById("iitem-cheque-no"))?document.getElementById("iitem-cheque-no").value:'';
      }
      if(cheque_bank_name===""){
        cheque_bank_name = (document.getElementById("iitem-bank-name"))?document.getElementById("iitem-bank-name").value:'';
      }
      if((payment_option==='onaccount' || payment_option==='other') && this.state.is_cheque_payment===true && this.state.is_bill_clicked===false && cheque_no===""){
        this.setState({is_bill_clicked:true});
        return false;
      }
      e.currentTarget.classList.add('hide');
      document.getElementById("forceChangeState").click();
      document.getElementById("resetEditSelectedState").click();
      let pending_bills = this.state.pending_bills[index];
      document.getElementById("payment-type").value  = 'invoice';
      let sli = document.querySelectorAll(".pdayment-item-row");
      for(let i=0;i<sli.length;i++){
        sli[i].classList.remove('item-edit-mode');
      }
      let _this = this;
      setTimeout(function(){
        let item_id= pending_bills.id;
        if(item_id==='balance_amount'){
          if(document.getElementById("payment-type")){
            document.getElementById("payment-type").value = 'general';
          }
        }
        if(document.getElementById("iitem-name")){
          document.getElementById("iitem-name").value = item_id;
          let item_name= pending_bills.value2;
          document.getElementById("iitem-box").value = item_name;
          item_id = parseInt(item_id);
      
          document.getElementById("iitem-refrence-no").value = '';

          let total= pending_bills.grand_total;
          let schema= pending_bills.schema;
          let target= pending_bills.target;
          
          if(pending_bills.payment_mode_txt){
            let payment_mode_id = pending_bills.payment_mode_id;
            document.getElementById("payment-mode").value = payment_mode_id;
          }
          if(pending_bills.action){
            document.getElementById("iitem-action").value = pending_bills.action;
          }
          document.getElementById("iitem-bank-name").value = cheque_bank_name;
          document.getElementById("iitem-cheque-no").value = cheque_no;
          document.getElementById("iitem-cheque-date").value = cheque_date;
          document.getElementById("iitem-cheque-is-recon").value = is_reconciliation;
          document.getElementById("iitem-total").value = total;
          document.getElementById("iitem-total-old").value = total;
          document.getElementById("old-iitem-name").value = item_id;
          document.getElementById("iitem-schema").value = schema;
          document.getElementById("iitem-target").value = target;
          document.getElementById("iitem-id").value = 0;
          document.getElementById("add-item-to-list").click();
          document.getElementById("hanlde-change-force-state").click();
        /*   if(document.getElementById("handleCalulateAdjustedAmount")){
            document.getElementById("handleCalulateAdjustedAmount").click();
          } */
          _this.handleCalulateAdjustedAmount();
        }
      },100);
    }

    checkEmptyInvoiceList(e){
      let pb_list = document.querySelectorAll(".pb-list");
      let is_empty = true;
      for(let i=0;i<pb_list.length;i++){
          let elem = pb_list[i];
          if(elem.classList.contains('hide')){}else{
            is_empty = false;
          }
      }
      this.setState({is_empty_item:is_empty});
    }


    handleRefreshPendingBill(e){
      this.setState({pending_bills:[]});
      this.setState({pending_loader:'Loading...'});
      let customer = localStorage.getItem("selected_contact");
      let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              id : customer,
              active_tab : this.state.billing_type,
              pay_type_opt : this.state.pay_type_opt,
              screen : this.state.payment_target
           }
        let api = app_api+'/get_contact_pending_bills';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              this.setState({pending_bills:resp.pending_bills});
              this.setState({pending_payments_bills:resp.payments_data});
              if(resp.pending_bills.length===0){
                this.setState({pending_loader:'No Pending Invoice Found.'});
              }else{
                this.setState({is_empty_item:false});
              }
              if(this.state.invoice_payment!==''){
                if(document.getElementById('pending-bill-'+this.state.invoice_payment)){
                  document.getElementById('pending-bill-'+this.state.invoice_payment).click();
                }
                this.setState({invoice_payment:''});
              }
              setTimeout(function(){
                  let qs = document.querySelectorAll(".payment-item-row");
                  for(let i=0;i<qs.length;i++){
                      let did = qs[i].getAttribute('data_id');
                      let iname = document.getElementById("item-box-"+did).value;
                      if(document.getElementById("pending-bill-"+iname)){
                        document.getElementById("pending-bill-"+iname).classList.add('hide');
                      }
                  }
              },100);
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }
    handleCreateContact(e){
      var element = document.getElementById('add_contact');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("new_name").focus();
      let contact = document.getElementById("contact_box").value;
      if(contact!==""){
        if(isNaN(contact)){
          document.getElementById("new_name").value = contact;
        }else{
          document.getElementById("new_phone").value = contact;
        }
      }
    }

    handleRoundOffChange(e){
      if(document.getElementById("calculateTotal")){
        document.getElementById("calculateTotal").click();
      }
      return false;
      let round_off = document.getElementById("round_off").value;

      let round_off_opt = e.currentTarget.value;
      if(round_off==='-'){
        this.setState({round_off_opt:false});
        this.setState({round_off_opt2:true});
      }else{
        this.setState({round_off_opt:true});
        this.setState({round_off_opt2:false});
      }
      var item_final_total = document.querySelectorAll('.item-total'); 
      let ptyrow = document.getElementsByClassName("payment-type");
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
        let item_target =  document.getElementById("item-target-"+i).value;
        if(item_target==='payment' || item_target==='credit_note' || item_target==='debit_note'){
          item_total = item_total - parseFloat(item_final_total[i].value.replace(/,/g , ""));
        }else{
          item_total = item_total + parseFloat(item_final_total[i].value.replace(/,/g , ""));
        }
      } 

      let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
      let global_discount = document.getElementById("global_discount").value;
     if(global_discount===""){
      global_discount = 0;
     }
     if(discount_opt==="rs"){
        item_total = parseFloat(item_total)-global_discount;
     }else if(discount_opt==="per"){
        let gdisc = (parseFloat(item_total)*global_discount)/100;
        item_total = parseFloat(item_total)-gdisc;
     }


     let i_total = item_total;
      
      if(round_off_opt==='-'){
          item_total = Math.floor(item_total);
          round_off = parseFloat(i_total)-parseFloat(item_total);
      }else if(round_off_opt==='+'){
          item_total = Math.ceil(item_total);
          round_off = parseFloat(item_total)-parseFloat(i_total);
      }
      document.getElementById("round_off").value = this.floating_point(round_off);
      document.getElementById("total-amount").innerHTML = this.currency_format(item_total);
    }

    handleRoundOff(e){
      if(document.getElementById("calculateTotal")){
        document.getElementById("calculateTotal").click();
      }
      return false;
      let round_off = e.currentTarget.value;
      var item_final_total = document.querySelectorAll('.item-total'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!=''){
              total_unit++;
            }
           item_total = item_total + parseFloat((item_final_total[i].value));
      } 
      let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
      let global_discount = document.getElementById("global_discount").value;
       if(global_discount===""){
        global_discount = 0;
       }
       if(discount_opt==="rs"){
          item_total = parseFloat(item_total)-global_discount;
       }else if(discount_opt==="per"){
          let gdisc = (parseFloat(item_total)*global_discount)/100;
          item_total = parseFloat(item_total)-gdisc;
       }
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      if(round_off!=="" && round_off>0){
          if(round_off_opt==='-'){
              item_total = item_total-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = item_total+parseFloat(round_off);
          }
      }

      document.getElementById("total-amount").innerHTML = this.currency_format(item_total);
    }
      billingDate = date => {
        this.setState({
          billingDate: date
        });
      };
       handleChangeEvent(e){
        this.setState({on_load:false});
        
      }
    componentDidMount(){
      localStorage.removeItem('is_cheque');
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
      }
      localStorage.removeItem("p_mode");
      document.addEventListener('keydown', this.handleChangeEvent);
      window.onbeforeunload = function(e) {
        if(_this.state.on_load===false){
          return false;
        }
      };
      let curl = window.location.href;
      let invoice_for = curl.match(/new-payment\/([a-z_A-Z]+)\/(\d+$)/i);
      let payment_target = '';
      if(invoice_for===null){
        invoice_for = curl.match(/order\/([a-z_A-Z]+)\/(\d+$)/i);
        if(invoice_for){
          this.setState({payment_target:'order'});
          payment_target = 'order';
        }
      }

      let in_or_out = curl.match(/new-payment\/([all|in|out]+$)/i);
      if(in_or_out===null){
        in_or_out = curl.match(/order\/([all|in|out]+$)/i);
        if(in_or_out){
          this.setState({payment_target:'order'});
          payment_target = 'order';
        }
      }
      let multiple_payment = curl.match(/new-payment\/multiple\/(.*)/i);
      if(multiple_payment===null){
        multiple_payment = curl.match(/order\/multiple\/(.*)/i);
        if(multiple_payment){
          this.setState({payment_target:'order'});
          payment_target = 'order';
        }
      }
      let pids = "";
      if(multiple_payment && multiple_payment[1]){
        pids = decodeURIComponent(multiple_payment[1]);
      }

      let edit_payment = curl.match(/new-payment\/([edit]+)\/(\d+$)/i);
      if(edit_payment===null){
        edit_payment = curl.match(/order\/([edit]+)\/(\d+$)/i);
      }
      if(in_or_out){
        if(in_or_out[1]==='all' || in_or_out[1]==='in'){
           this.setState({billing_type:'IN'});
        }else if(in_or_out[1]==='out'){
           this.setState({billing_type:'OUT'});
        }
      }
      let pfor = "";
      let referer = "";
      if(invoice_for){
          let t = invoice_for[1];
          referer = t;
          if(t==='sales'){
            pfor = "New Sale";
          }else if(t==='purchase'){
            pfor = "New Purchase";
            this.setState({billing_type:'OUT'});
          }else if(t==='purchase_return'){
            pfor = "New Purchase Return";
            this.setState({billing_type:'IN'});
          }else if(t==='sales_return'){
            pfor = "New Sales Return";
            this.setState({billing_type:'OUT'});
          }
      }

      if(pids!==""){
          
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              bill_ids : pids,
              screen:payment_target
           }
        let api = app_api+'/payments/get_multiple_payment';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let data = resp.data;

              if(data.length===0){
                window.location.href = '/404';
              }
              this.setState({'is_contact_selected':'',pay_type_opt:'billwise',has_payment_items:true});
              if(document.getElementById("billwise")){
                  document.getElementById("billwise").click();
                  this.setState({has_payment_items:true});
              }
              let contact_info = data[0].contact_info;
              let credit_info = data[0].credit_data;
              this.setState({billing_type:resp.payment_type});
              let sequence = resp.payment_number;
              let sequence_no = resp.payment_number_sequence;
             
              this.setState({show_loader:false});
              this.setState({currrent_sequence:sequence});
              this.setState({currrent_sequence_no:sequence_no});

              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name;
                 contact_id = contact_info[0].contact_id;
                 localStorage.setItem('selected_contact',contact_id);
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 /*email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }

              previous_balance = credit_info.previous_balance
              credit_limit = credit_info.credit_limit

              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,previous_balance:previous_balance,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
              this.setState({contact_info:c_info});
              
              let total_amount = 0.0;
              let itotal = 0.0;
              let pending_amount = 0.0;
              let p_items = [];
              for(let pd=0;pd<data.length;pd++){
                 pending_amount = data[pd].pending_amount.replace(/,/g , "");;
                 itotal = data[pd].total.replace(/,/g , "");
                if(pending_amount!=='0' && pending_amount!==''){
                  itotal = pending_amount;
                }
                 let paid_amount = itotal.replace(/,/g , "");

        
                let item_info = {
                                  payment_type:'invoice',
                                  item_pk_id : 0,
                                  target:'',
                                  item_name:data[pd].number,
                                  item_id:data[pd].id,
                                  refrence_no:data[pd].refrence_no,
                                  item_total:paid_amount,
                                  pending_amount:pending_amount,
                                  payment_mode:'',
                                  old_amount:paid_amount
                                }

                              p_items.push(item_info);
             
              total_amount = total_amount+parseFloat(paid_amount);
              
              this.setState({total_balance_amt:0});
            }
            this.add_item_area.push(<AddPaymentItem payment_type={this.state.pay_type_opt} count={data.length} type="more" key={1}  index={1} billing_type={this.state.billing_type} invoice_info={p_items} items={[]}/>);
            this.setState({total_grand:this.currency_format(total_amount)});
            this.setState({payable_amount:this.currency_format(total_amount)});
            this.setState({add_item_area:this.add_item_area});
            if(document.getElementById("refresh_pending_bill")){
              this.setState({pending_bills:[]});
              setTimeout(function(){
                document.getElementById("refresh_pending_bill").click();
              },300);
            }

        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }else if(edit_payment){ // EDIT MODE
          const id = edit_payment[2];
          const type = edit_payment[1];
        
          this.setState({update_id:id});
          //this.setState({billing_type:t});
          this.setState({send_type:'update'});
         
          this.setState({save_button:'Update Payment'});
          this.setState({has_payment_items:true});
        
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              id : parseInt(id),
              screen : payment_target
           }
        let api = app_api+'/get_payment_on_id';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let data = resp.data[0];
              if(data===undefined){
                window.location.href = '/404';
              }
              this.setState({payment_data:data});
              this.setState({show_loader:false});
              localStorage.setItem('selected_contact',data.contact_id);
              this.setState({'is_contact_selected':''});
              this.setState({has_payment_items:true});
              if(document.getElementById("billwise")){
                  if(data.payment_option==='billwise' || data.payment_option===''|| data.payment_option===null){
                    this.setState({pay_type_opt:'billwise'});
                    //document.getElementById("onaccount-blk").classList.add('hide');
                    //document.getElementById("autobillwise-blk").classList.add('hide');
                    document.getElementById("billwise").click();
                    this.setState({has_payment_items:true});
                  }else if(data.payment_option==='onaccount'){
                    this.setState({pay_type_opt:'onaccount'});
                    document.getElementById("onaccount-blk").classList.remove('hide');
                    //document.getElementById("billwise-blk").classList.add('hide');
                    //document.getElementById("autobillwise-blk").classList.add('hide');
                    document.getElementById("onaccount").click();
                    this.setState({has_payment_items:true});
                  }else if(data.payment_option==='autobillwise'){
                    this.setState({pay_type_opt:'autobillwise'});
                    document.getElementById("autobillwise-blk").classList.remove('hide');
                    //document.getElementById("billwise-blk").classList.add('hide');
                    //document.getElementById("onaccount-blk").classList.add('hide');
                    document.getElementById("autobillwise").click();
                    this.setState({has_payment_items:true});
                  }else if(data.payment_option==='other'){
                    this.setState({pay_type_opt:'other'});
                    document.getElementById("other-blk").classList.remove('hide');
                    //document.getElementById("billwise-blk").classList.add('hide');
                    //document.getElementById("autobillwise-blk").classList.add('hide');
                    document.getElementById("other").click();
                    this.setState({has_payment_items:true});
                  }
              }
              
              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
           
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.payable_amount.replace(/,/g , ""));
                let disc = 0;
                if(gdisc!==undefined){
                  if(isNaN(gdisc)){
                    disc =parseFloat(gdisc.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(gdisc);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = sub_total*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(data.discount_amount)});
              }

              if(gdisc_sym==='rs'){
                if(document.getElementById("discount-rs")){
                  document.getElementById("discount-rs").checked=true;
                }
              }else{
                if(document.getElementById("discount-per")){
                  document.getElementById("discount-per").checked=true;
                }
              }
              
              if(rooff_sym==='-'){
                if(document.getElementById("minus")){
                  document.getElementById("minus").checked=true;
                }
              }else{
                if(document.getElementById("plus")){
                  document.getElementById("plus").checked=true;
                }
              }
              let paid_amount = data.paid_amount.replace(/,/g , "");
              let payable_amount = data.payable_amount.replace(/,/g , "");
              
              this.setState({paid_amount:this.currency_format(paid_amount)});
              this.setState({payable_amount:this.currency_format(payable_amount)});
              let pending_amount = data.pending_amount.replace(/,/g , "");
              let total_amount = parseFloat(paid_amount)+parseFloat(pending_amount);
              
              this.setState({total_balance_amt:this.currency_format(pending_amount)});
              this.setState({total_grand:this.currency_format(total_amount)});

              let billing_date = data.created_on;
              let billing_type = data.payment_type;
              
              this.setState({billing_type:billing_type});
              this.setState({billingDate:new Date(billing_date)});
             

              let contact_info = data.contact_info;
              let credit_info = data.credit_data;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name;
                 contact_id = contact_info[0].contact_id;
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 let discount = contact_info[0].discount;
                 localStorage.setItem('discount',discount);
                 /*email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }
              previous_balance = credit_info.previous_balance
              credit_limit = credit_info.credit_limit
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,previous_balance:previous_balance,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
              this.setState({contact_info:c_info});

              let sequence = data.payment_number;
              let sequence_no = data.payment_number_sequence;
             
              this.setState({currrent_sequence:sequence});
              this.setState({currrent_sequence_no:sequence_no});

              let list_items = data.payment_meta;
              let p_items = [];
              list_items.map((value, key)=>{
                let idx = key+1;

                if(value.is_old_balance==='Opening Balance' || value.is_old_balance==='Opening Balance Amount'){
                  value.bill_invoice_id = 'balance_amount';
                }

                let item_info = {
                                  schema:value.schema,
                                  payment_type:value.payment_type,
                                  item_pk_id:(value.payment_meta_id!==undefined)?value.payment_meta_id:value.order_payment_meta_id,
                                  target:value.target,
                                  action:(value.status===9 || value.status===10)?'payment':value.target,
                                  item_name:value.bill_invoice_no,
                                  item_id:value.bill_invoice_id,
                                  old_amount:value.old_amount,
                                  item_total:value.total_paid_amount,
                                  pending_amount:value.pending_amount,
                                  payment_mode:value.payment_mode_id,
                                  payment_mode_txt:value.payment_mode_txt,
                                  refrence_no:value.refrence_no,
                                  is_old_balance:(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'',
                                  cheque_bank_name:value.cheque_bank_name,
                                  cheque_no:value.cheque_no,
                                  cheque_date:value.cheque_date,
                                  is_reconciliation:value.is_reconciliation
                                }
                p_items.push(item_info);
              }); 
              this.add_item_area = [];
              this.setState({add_item_area:this.add_item_area});
              this.add_item_area.push(<AddPaymentItem payment_type={this.state.pay_type_opt}  count={list_items.length} type={'more'} key={1}  index={1} billing_type={billing_type} invoice_info={p_items} items={[]}/>);
              this.setState({add_item_area:this.add_item_area});
              if(document.getElementById("openOnAccountPayment")){
                document.getElementById("openOnAccountPayment").click();
              }
              if(document.getElementById("refresh_pending_bill")){
                this.setState({pending_bills:[]});
                setTimeout(function(){
                  if(document.getElementById("calculateTotal")){
                    document.getElementById("calculateTotal").click();
                  }
                  document.getElementById("refresh_pending_bill").click();
                },300);
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }else{

        if(invoice_for){
            let id = invoice_for[2];
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                type : pfor,
                id : parseInt(id),
             }
          let api = app_api+'/billings/get_billing';
          axios.post(api, body)
            .then(function (response) {
                
                let resp = response.data;
                let data = resp.data;
                this.setState({is_invoice_payment:true,'is_contact_selected':'',pay_type_opt:'billwise',has_payment_items:true});
               
                let contact_info = data.contact_info;
                let credit_info = data.credit_data;
                let contact_name = '';
                let contact_id = '';
                let address_1 =  '';
                let address_2 = '';
                let city = '';
                let credit_limit = '';
                let previous_balance = '';
                let email = '';
                let phone_1 = '';
                let phone_2 = '';
                let state = '';
                if(Object.keys(contact_info).length > 0) {
                   contact_name = contact_info[0].name;
                   contact_id = contact_info[0].contact_id;
                   localStorage.setItem('selected_contact',contact_id);
                   address_1 = contact_info[0].address_1;
                   address_2 = contact_info[0].address_2;
                   city = contact_info[0].city;
                   /*address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                   address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                   city = this.decrypt_sanitize(contact_info[0].city);*/
                   credit_limit = contact_info[0].credit_limit;
                   email = contact_info[0].email;
                   phone_1 = contact_info[0].phone_1;
                   phone_2 = contact_info[0].phone_2;
                   state = contact_info[0].state;
                   /*email = this.decrypt_sanitize(contact_info[0].email);
                   phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                   phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                   state = this.decrypt_sanitize(contact_info[0].state);*/
                }

                previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit
                let pending_amount = data.pending_amount

                let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,previous_balance:previous_balance,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
                this.setState({contact_info:c_info});
                let itotal = data.total.replace(',','');
                if(pending_amount!=='0' && pending_amount!==''){
                  itotal = pending_amount;
                }

               let paid_amount = itotal.replace(/,/g , "");
               let total_amount = parseFloat(paid_amount);
               this.setState({paid_amount:this.currency_format(total_amount)});
               this.setState({payable_amount:this.currency_format(total_amount)});
                let item_info = {
                                  schema:data.schema,
                                  payment_type:'invoice',
                                  item_pk_id : 0,
                                  target:referer,
                                  item_name:data.number,
                                  item_id:data.id,
                                  refrence_no:'',
                                  item_total:total_amount,
                                  pending_amount:pending_amount,
                                  payment_mode:'',
                                  old_amount:itotal
                                }
                this.setState({invoice_payment:data.number})
                this.setState({show_loader:false});
                if(document.getElementById("billwise")){
                    document.getElementById("billwise").click();
                    this.setState({has_payment_items:true});
                }
                if(document.getElementById("refresh_pending_bill")){
                 // this.setState({pending_bills:[]});
                  setTimeout(function(){
                   // document.getElementById("refresh_pending_bill").click();
                  },100);
                }
                /* this.add_item_area.push(<AddPaymentItem payment_type={this.state.pay_type_opt}  count={1} type="more" key={1}  index="1" billing_type={this.state.billing_type} invoice_info={[item_info]} items={[]}/>);
                this.setState({add_item_area:this.add_item_area});
//
              
              this.setState({total_balance_amt:0});
              this.setState({total_grand:this.currency_format(total_amount)});
              if(document.getElementById("refresh_pending_bill")){
                this.setState({pending_bills:[]});
                setTimeout(function(){
                  document.getElementById("refresh_pending_bill").click();
                },300);
              } */
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }else{
          this.setState({round_off_opt:true});
          this.setState({round_off_opt2:false});
          this.add_item_area.push(<AddPaymentItem payment_type={this.state.pay_type_opt} count={1} key={1} type="more" index="1" billing_type={this.state.billing_type}/>);
          this.setState({add_item_area:this.add_item_area});
          this.setState({show_loader:false});
        }
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
             }
          let api = app_api+'/get_sequence_number';
          axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
              let cheque_sequence = resp.cheque_sequence;
              this.setState({cheque_sequence:"#CHQ"+cheque_sequence});
              let in_sequence_no = resp.payment_in_sequence;
              let out_sequence_no = resp.payment_out_sequence;
              if(payment_target == 'order'){
                 in_sequence_no = resp.order_payment_in_sequence;
                 out_sequence_no = resp.order_payment_out_sequence;
              }
              let payment_in_sequence = "#PYI"+in_sequence_no;
              this.sequence["IN"] = payment_in_sequence;
              this.sequence["OUT"] = "#PYO"+out_sequence_no;
              
              this.sequence["IN No"] = in_sequence_no;
              this.sequence["OUT No"] = out_sequence_no;
              
              this.setState({currrent_sequence:payment_in_sequence});
              this.setState({currrent_sequence_no:in_sequence_no});
              if(this.state.billing_type==='OUT'){
                this.setState({currrent_sequence:this.sequence["OUT"]});
                this.setState({currrent_sequence_no:out_sequence_no});
              }else if(in_or_out[1]==='all' || in_or_out[1]==='in'){
                this.setState({currrent_sequence:payment_in_sequence});
                this.setState({currrent_sequence_no:in_sequence_no});
              }else if(in_or_out[1]==='out'){
                this.setState({currrent_sequence:this.sequence["OUT"]});
                this.setState({currrent_sequence_no:out_sequence_no});
              }
                    
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        
      }

      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'is_financial_year_closed','is_financial_year_active','financial_year'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
          let is_financial_year_active = false;
          if(resp.is_financial_year_active!==undefined){
            let ps = resp.is_financial_year_active;
            is_financial_year_active = ps;
          }else if(resp.is_financial_year_active===undefined){
            is_financial_year_active = true;
          }
          if(is_financial_year_active===true){
            if(resp.financial_year){
              let ps = resp.financial_year;
              let closing_date = new Date(ps.closing_date);
              let currentdate = new Date();
              if(currentdate>closing_date){
                this.setState({billingDate:closing_date});
              }
              this.setState({maxDate:closing_date});
            }
          }
        
      }.bind(this)).catch(function(res){
          
      }) 
    }

    
   
    operateCheck(event){
        let thisval = event.target.value;
        let prev_bal = 0;
        let cred_lim = 0;
        if(document.getElementById("previous_balance_txt")){
           prev_bal = document.getElementById("previous_balance_txt").innerHTML;
        }
        if(document.getElementById("credit_limit_txt")){
           cred_lim = document.getElementById("credit_limit_txt").innerHTML;
        }
        this.setState({billing_type:thisval});
        setTimeout(function(){
          if(document.getElementById("previous_balance_txt")){
           document.getElementById("previous_balance_txt").innerHTML = prev_bal;
          }
          if(document.getElementById("credit_limit_txt")){
            document.getElementById("credit_limit_txt").innerHTML = cred_lim;
          }
        },10);
        let tval = thisval.toLowerCase();
        window.history.pushState({},'','/new-payment/'+tval);
        if(document.getElementById("resetMenuState")){
          document.getElementById("resetMenuState").click();
        }
        this.setState({currrent_sequence_no:this.sequence[thisval+" No"]});
        this.setState({currrent_sequence:this.sequence[thisval]});

        this.add_item_area=[];
        this.setState({add_item_area:''});
        this.setState({has_payment_items:false});

        let this_ = this;
        //setTimeout(function(){
          this_.add_item_area.push(<AddPaymentItem payment_type={this.state.pay_type_opt}  count={1} key={1} type="more" index="1" billing_type={thisval}/>);
          this_.setState({add_item_area:this_.add_item_area});
         /* let total_amount = 0;
          let rows = document.getElementsByClassName("item-total");
          for(let i=0;i<rows.length;i++){
             let val = rows[i].value.replace(/,/g , "");
             if(val!==""){
                total_amount= total_amount+parseFloat(val);
             }
          }
           
           let old_total = 0;
          let prows = document.getElementsByClassName("item-total-old");
          for(let i=0;i<prows.length;i++){
             let pval = prows[i].value.replace(/,/g , "");
             if(pval!==""){
                old_total= old_total+parseFloat(pval);
             }
          }
          
          if(document.getElementById("total-amount")){
            document.getElementById("total-amount").innerHTML =this_.currency_format(total_amount);
          }
          if(document.getElementById("total-amount-txt")){
            document.getElementById("total-amount-txt").value = total_amount;
          }
          if(document.getElementById("total-grand")){
            document.getElementById("total-grand").innerHTML = this_.currency_format(old_total);
          }
          if(document.getElementById("total-grand")){
            let grand_total = document.getElementById("total-grand").innerHTML.replace(/,/g , "");

            let pending = parseFloat(grand_total)-total_amount;
            document.getElementById("total-balance").innerHTML =this_.currency_format(pending);
          }*/

            //document.getElementById("refresh_pending_bill").click();
          
        //},10);
        if(document.getElementById("minus")){
          document.getElementById("minus").click();
        }

        this.setState({is_payment_opt:false});
        this.setState({toggle_pending_invoice:false});
        this.setState({pay_type_opt:''});
        this.setState({has_payment_items:false});
        setTimeout(function(){
          if(document.getElementById("billwise")){
            document.getElementById("billwise").checked=false;
          } 
          if(document.getElementById("onaccount")){
            document.getElementById("onaccount").checked=false;
          }

            //document.getElementById("billwise").click();
            if(document.getElementById("hideOnAccountPayment")){
              document.getElementById("hideOnAccountPayment").click();
            }
        },100);
    }

    createPayment(event) {
        this.setState({on_load:true});
        let thisid = event.currentTarget.id;
        if(this.state.pay_type_opt==='billwise'){
          if(!document.getElementById("payment-cr-dr-list")){
            document.getElementById('iitem-total').focus();
            return false;
          }
          let qs = document.querySelectorAll(".item-total");
          for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            element.focus();
            element.blur();
            break;
          }
          let remaining_amt = document.getElementById("iitem-total").value;
          if(remaining_amt>0){
            if(document.getElementById("remaining-error")){
              document.getElementById("remaining-error").innerHTML = 'Please adjust remaining amt.';
            }
            setTimeout(() => {
              if(document.getElementById("remaining-error")){
                document.getElementById("remaining-error").innerHTML = '';
              }
            }, 3000);
            document.getElementById("iitem-total").focus();
            return false;
          }
        }
        let send_data = {};
        let customer = parseInt(document.getElementById("customer-dropdown").value);
        let customer_name = document.getElementById("contact_box").value;
        let date = this.state.billingDate;
        let notes = document.getElementById("pay-notes").value;
        let round_off = document.getElementById("round_off").value;
        let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
        let payment_mode = document.getElementById("payment-mode").value;
        let pm_element = document.getElementById("payment-mode");
        let payment_mode_txt = pm_element.options[pm_element.selectedIndex].text;

        let paid_amount = document.getElementById("total-amount").innerHTML;
        let pending_amount = document.getElementById("total-balance").innerHTML;
        var inputs = document.querySelectorAll('.items-row'); 
        if(inputs.length===0){
          toast("Sorry! You can not create a blank payment.");
          return false;
        }
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let payment_mode_arr = [];
        let payable_amount = 0;
        for (var i = 0; i < inputs.length; i++) { 
          let idx = inputs[i].getAttribute('data_id');
          if(document.getElementById("payment-type-"+idx)){
            

            let schema = document.getElementById("item-schema-"+idx).value;
            let item_payment_mode = document.getElementById("item-payment-mode-"+idx).value;
            let payment_mode_span = document.getElementById("payment-mode-span-"+idx).innerHTML;
            if(payment_mode_span!=='On Account'){
              payment_mode_arr.push(item_payment_mode);
            }
            let payment_type = document.getElementById("payment-type-"+idx).innerHTML;
            let item_name = document.getElementById("item-name-"+idx).value;
            let item_id = document.getElementById("item-id-"+idx).value;
            let item_target = document.getElementById("item-target-"+idx).value;
            let item_action = document.getElementById("item-action-"+idx).value;
            let item_refrence_no = document.getElementById("item-refrence-no-"+idx).value;
            let item_name_txt = document.getElementById("item-box-"+idx).value;
            let item_total = document.getElementById("item-total-"+idx).value.replace(/,/g , "");
            let item_total_old = document.getElementById("item-total-old-"+idx).value.replace(/,/g , "");
            let item_total_orig = document.getElementById("item-total-orig-"+idx).value.replace(/,/g , "");
            let is_item_old_balance = (document.getElementById("is-item-old-balance-"+idx))?document.getElementById("is-item-old-balance-"+idx).value.replace(/,/g , ""):'';

            let chq_bank_name = (document.getElementById("item-cheque-bank-name-"+idx))?document.getElementById("item-cheque-bank-name-"+idx).value:'';
            let chq_no = (document.getElementById("item-cheque-no-"+idx))?document.getElementById("item-cheque-no-"+idx).value:'';
            let chq_date = (document.getElementById("item-cheque-date-"+idx))?document.getElementById("item-cheque-date-"+idx).value:'';
            let is_reconcile = (document.getElementById("item-cheque-is-recocile-"+idx))?document.getElementById("item-cheque-is-recocile-"+idx).value:'';

            if(item_name==='balance_amount'){
              payment_type = 'general';
              is_item_old_balance = 'Opening Balance';
            }
            if(item_name==='advance_payment'){
              payment_type = 'general';
              is_item_old_balance = 'Advance Payment';
            }

            if(item_name==='payment_due'){
              payment_type = 'general';
              is_item_old_balance = 'Payment Due';
            }
            
            if(item_target!=='payment' && item_action!=='payment' && (item_target!=='general' || is_item_old_balance==='Opening Balance') && item_target!=='credit_note' && item_target!=='debit_note'){
              payable_amount = payable_amount + parseFloat(item_total);
            }

            let pending_amount = parseFloat(item_total_old)-parseFloat(item_total);
            this.send_data_items[i] = {item_schema:schema,payment_type:payment_type,item_id:item_id,item_refrence_no:item_refrence_no,item_target:item_target,item_action:item_action,item_name:item_name,item_name_txt:item_name_txt,item_total:item_total,item_total_old:item_total_old,pending_amount:pending_amount,payment_mode:item_payment_mode,is_item_old_balance:is_item_old_balance,item_total_orig:item_total_orig,cheque_bank_name:chq_bank_name,cheque_no:chq_no,cheque_date:chq_date,is_reconciliation:is_reconcile};
          }
        }
        let global_discount = document.getElementById("global_discount").value;
        let global_discount_opt = document.querySelector('input[name="global_discount"]:checked').value;

        let payment_option = document.querySelector('input[name="add-payemnt-option"]:checked').value;

        
        let items_to_delete = document.getElementById("delete-row").value;
        send_data = {
            screen : this.state.payment_target,
            update_id : this.state.update_id,
            type : this.state.billing_type,
            send_type : this.state.send_type,
            customer : customer,
            customer_name : customer_name,
            date : date,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            sequence_no : this.state.currrent_sequence_no,
            sequence : this.state.currrent_sequence,
            payment_mode : payment_mode_arr.toString(),
            payment_mode_txt : payment_mode_txt,
            round_off:round_off,
            round_off_opt:round_off_opt,
            global_discount:global_discount,
            global_discount_opt:global_discount_opt,
            payable_amount:payable_amount,
            paid_amount:paid_amount,
            pending_amount:pending_amount,
            notes : notes,
            items : this.send_data_items,
            payment_option : payment_option,
            items_to_delete : JSON.parse(items_to_delete)
        }
        
        let api = app_api+'/create_payment';
        axios.post(api, send_data)
          .then(function (response) {
            let last_id = response.data.response.insert_id;
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            if(this.state.payment_target==='order'){
              window.location.href = '/order/view-payment/'+last_id;
            }else{
              window.location.href = '/view-payment/'+last_id;
            }
        }.bind(this)).catch(function(res){
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
        })
    }

    handleCalulateAdjustedAmount = ()=>{
      let qs = document.querySelectorAll(".item-total");
      let bill_total = 0;
      for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        let data_action = element.getAttribute('data-action');
        let thisval = element.value;
        if(thisval===""){
          thisval = 0;
        }
        
        thisval = parseFloat(thisval);
        if(data_action!=='sale_return' && data_action!=='purchase_return' && data_action!=='general' && data_action!=='payment' && data_action!=='credit_note' && data_action!=='debit_note'){
          bill_total = bill_total + thisval;
        }
      }
      let previous_amount = this.state.previous_amount;
      let bitem_total = previous_amount - bill_total;
      this.setState({previous_amount_tmp:bitem_total});
    }

    render() {
      let round_off_sym = '-';
        if(this.state.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.state.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let global_discount_sym = 'per';
        if(this.state.discount_sym==='rs'){
          global_discount_sym = 'rs';
        }else if(this.state.discount_sym==='per'){
          global_discount_sym = 'per';
        }
        let isMobile = "row";
        if(this.state.isMobile){
          isMobile = "mobile";
        }
        return (
            <div className="d-flex payments-wrapper" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className={"navbar border-bottom "+this.state.billing_type.toLowerCase()}>
                  <div className="row">
                    <div className="col-md-4">
                      <Breadcrumbs link="/payments" title="Payments" sub_title="New Payment"/>
                    </div>
                    <div className="col-md-8 mrtop-10 new-billing-opt">
                        {(this.state.send_type==='save' && this.is_access('payments-in-create')) &&
                        <div className="form-check form-check-inline">
                          <input className="form-check-input payment-type-cb" type="checkbox" onChange={this.operateCheck} id="payin" value="IN" checked={this.state.billing_type==='IN'?true:false}/>
                          <label className="form-check-label" htmlFor="payin">
                            Pay IN(+) <br/>
                            <span className="pay-opt">Receive Payment</span>
                          </label>
                          <ShortcutHelp  arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+1']}/>
                        </div>
                        }
                        {(this.state.send_type==='save' && this.is_access('payments-out-create')) &&
                        <div className="form-check form-check-inline">
                          <input className="form-check-input  payment-type-cb" type="checkbox" onChange={this.operateCheck} id="payout" value="OUT"  checked={this.state.billing_type==='OUT'?true:false}/>
                          <label className="form-check-label" htmlFor="payout">
                            Pay OUT(-) <br/>
                            <span className="pay-opt" style={{width:'67px'}}>Make Payment</span>
                          </label>
                          <ShortcutHelp  arrow="top" target="new_bill_cb" shortcuts={['Ctrl+Alt+2']}/>
                        </div>
                        }
                    </div>
                  </div>
                </nav>

                <div className={isMobile} style={{margin:'0px'}}>
                  <div className={(this.state.is_payment_opt===true && isMobile==='row')?"pleftblk col-md-8":(isMobile==='row')?"pleftblk col-md-8":''}>
                    <div className="container-fluid">
                    <ServiceLoader show={this.state.show_loader}/>
                    <input type="text" className="hide" id="delete-row" defaultValue='[]'/>
                    <span id="handleEnableChequePayment" onClick={this.handleEnableChequePayment}></span>
                    <span id="handleDisableChequePayment" onClick={this.handleDisableChequePayment}></span>
                    <span id="handleCalulateAdjustedAmount" onClick={this.handleCalulateAdjustedAmount}></span>
                    <span id="handleShowPaymentOption" onClick={this.handleShowPaymentOption}></span>
                    <span id="handleHidePaymentOption" onClick={this.handleHidePaymentOption}></span>
                    <div className="content-area">
                      
                       <div className="content-head border-bottom" style={{paddingBottom:'0px'}}>
                         <div className="row mrbtm-12">
                          <div className="col-md-4">
                            <div className={((this.state.send_type==='update' || this.state.is_invoice_payment===true) && this.state.pay_type_opt==='billwise')?"hide":""}>
                              <input type="hidden" id="contact_add_type" />
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={this.state.contact_info} index="payments"/>
                              <span id="ContactSelected" onClick={this.handleContactChangeStatus}></span>
                              <span id="ContactRemoved" onClick={this.handleContactChangeStatus}></span>
                              <span onClick={this.handleRefreshPendingBill} id="refresh_pending_bill"></span>
                            </div>
                            <div className={(this.state.send_type==='update' && (this.state.pay_type_opt==='onaccount' || this.state.pay_type_opt==='autobillwise' || this.state.pay_type_opt==='other'))?'hide':"mrtop-5"}>
                              <label className="bil_no">
                               {this.state.contact_info.item_name}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                          
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text no-border-right">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </span>
                              <DatePicker className="form-control  no-border-left" selected={this.state.billingDate} onChange={this.billingDate} dateFormat="dd/MM/yyyy" maxDate={this.state.maxDate}/>
                            </div>
                          </div>
                          </div>
                            <div className="col-md-4 text-right  mrtop-5 bill-view">
                              {(this.state.is_cheque_payment===false) &&
                                <label className="bil_no">{this.state.currrent_sequence}</label>
                              }
                              {(this.state.is_cheque_payment===true) &&
                                <label className="bil_no">{this.state.cheque_sequence}</label>
                              }
                            </div>
                          </div>
                       </div>

                       
                        <div className={this.state.is_contact_selected}>
                        <p className="hide">
                          ₹<span id="previous_balance_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.previous_balance):0}</span>
                          <span id="credit_limit_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.credit_limit):0}</span>
                        </p>
                        {/*  <div className="main-content border-bottom">
                            <div className="content-head" style={{paddingTop:'10px',paddingBottom:'15px'}}>
                              {this.state.billing_type==='IN' &&
                                 <>
                                    <div className="row">
                                      
                                      <div className="col-md-4">
                                       
                                      </div>
                                      <div className="col-md-4">
                                        <span className="fe-title">PAYMENT MODE</span>
                                        <PaymentModes show="dropdown" name="payment_mode" data_id='0' id="payment-mode" default={(this.state.payment_data && this.state.payment_data.p_mode_id)?parseInt(this.state.payment_data.p_mode_id):''} target="payments" tabindex='0'/>
                                      </div> 
                                      <div className="col-md-4">
                                        <span className="fe-title">BALANCE AMOUNT</span>
                                        <p>
                                          ₹<span id="previous_balance_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.previous_balance):0}</span>
                                        </p>
                                      </div>
                                      <div className="col-md-3 mrtop-20 hide">
                                        <span>
                                          Credit Left : ₹<span id="credit_limit_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.credit_limit):0}</span>
                                        </span>
                                       
                                      </div> 
                                      
                                  </div>
                                 
                                </>
                              }
                             
                            </div>
                         </div> */}
                         {(this.state.is_contact_selected==='') &&
                         <>
                            {(this.state.is_show_payment_option===true) &&
                              <div className="content-head p-type-opt border-bottom" style={{paddingBottom:'16px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    
                                    <div className="form-check form-check-inline" id="onaccount-blk">
                                      <input className="form-check-input" type="radio" target="onaccount"  name="add-payemnt-option" id="onaccount" tabindex="2"  onChange={this.handleChangePaymentOption} value="onaccount"/>
                                      <label className="form-check-label acc-opt-lbl" htmlFor="onaccount">On Account / Direct Payment
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline" id="autobillwise-blk">
                                      <input className="form-check-input" type="radio" target="autobillwise"  name="add-payemnt-option" id="autobillwise" tabindex="2"  onChange={this.handleChangePaymentOption} value="autobillwise"/>
                                      <label className="form-check-label acc-opt-lbl" htmlFor="autobillwise">Auto Bill Selection
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline" id="billwise-blk">
                                      <input className="form-check-input" type="radio" id="billwise" target="billwise" name="add-payemnt-option" onChange={this.handleChangePaymentOption} value="billwise" tabindex="4"/>
                                      <label className="form-check-label acc-opt-lbl" htmlFor="billwise">
                                        Manual Bill Selection
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {(this.state.show_previous_amount)&&
                                <div className="row mrtop-10 border-top border-bottom">
                                  <div className="col-md-12">
                                    <h5 className="text-right" style={{marginTop:'9px'}}>
                                      Amount to be adjusted : {utils.currency_format(this.state.previous_amount_tmp)}</h5>
                                  </div>
                                </div> 
                                }
                              </div> 
                            }
                            {(this.state.is_show_payment_option===false) &&
                              <div className="content-head p-type-opt border-bottom hide" style={{paddingBottom:'16px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  
                                  <div className="form-check form-check-inline" id="other-blk">
                                    <input className="form-check-input" type="radio" target="other"  name="add-payemnt-option" id="other" tabindex="2"  onChange={this.handleChangePaymentOption} value="other"/>
                                    <label className="form-check-label acc-opt-lbl" htmlFor="other">Other
                                    </label>
                                  </div>
                                  
                                </div>
                              </div> 
                            </div> 
                            }
                            {(this.state.is_payment_opt===true) &&
                            <>
                            <div className="row">
                             {(this.state.toggle_pending_invoice===true && this.state.isMobile===true) &&
                              <>
                                <div className={(this.state.toggle_pending_invoice===true)?"col-md-12":"hide"} style={{paddingLeft:'-1px'}}>
                                  <div className="container-fluid">
                                    <div className="content-area">
                                    <div className="pay-pending-h-nor content-head border-bottom" style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5>Pending Invoices/Adv. Payment</h5>
                                        </div>
                                      </div> 
                                    </div>
                                    <div className="row pay-block">
                                       
                                        <span onClick={this.checkEmptyInvoiceList} id="hanlde-change-force-state"></span>
                                        <span onClick={this.handeEmptyInvoiceList} id="hanlde-force-empty-invoice-list"></span>
                                       
                                        <div className="col-md-12">
                                        <div className="table-responsive pen-inv-blk" style={{maxHeight:'350px'}}>
                                          <table className="table">
                                            <thead>
                                              <tr className="pay-pending-h-mob">
                                                <th colSpan="3" className="text-center">PENDING INVOICES/ADV. PAYMENT</th>
                                              </tr>
                                              <tr>
                                                <th style={{borderTop:'none'}}>Date</th>
                                                <th style={{borderTop:'none'}}>ID</th>
                                                <th style={{borderTop:'none'}}>Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                                 return (
                                                  <>
                                                  {(value.action!=='payment') &&
                                                    <tr key={ index } index={index} id={"pending-bill-"+value.value2} className={(value.is_order)?"pb-list pb-order-list":"pb-list"} target="pbills" data-id={"pending-bill-"+index} onClick={this.handlePendingBillSelect} onMouseUp={this.handleCheckIfMouse}>
                                                      <td>
                                                        <FontAwesomeIcon icon={faArrowLeft} className="pen-pay-ar-l"/> {value.date}
                                                      </td>
                                                      <td>
                                                        {value.value2}
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                          <>({value.dispatched_info.supplier_ref_no})</>
                                                        }
                                                        {(value.fin_year!==undefined) &&
                                                          <span className="fyinstrip">{value.fin_year}</span>
                                                        }
                                                        <input type="hidden" id={"pending-amt-"+index} value={value.grand_total} />
                                                      </td>
                                                      <td>
                                                        {value.grand_total} <FontAwesomeIcon icon={faArrowRight} className="pen-pay-ar-r text-right"/>
                                                      </td>
                                                    </tr>
                                                }
                                                  </>
                                                )
                                               }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }

                                               {(this.state.is_empty_item && this.state.pending_loader!=='No Pending Invoice Found.') && 
                                                  <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No More Pending Invoices</td></tr>
                                               }
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {(this.state.pending_payments_bills.length > 0) && 
                                      <div className="container-fluid">
                                        <div className="content-area">
                                        <div className="pay-pending-h-nor content-head border-bottom" style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                                          <div className="row">
                                            <div className="col-md-12">
                                              <h5>Pending Payments/CR-DR Note</h5>
                                            </div>
                                          </div> 
                                        </div>
                                        <div className="row pay-block">
                                            <div className="col-md-12">
                                            <div className="table-responsive pen-inv-blk"  style={{maxHeight:'350px'}}>
                                              <table className="table">
                                                <thead>
                                                  <tr className="pay-pending-h-mob">
                                                    <th colSpan="3" className="text-center">Pending Payments/CR-DR Note</th>
                                                  </tr>
                                                  <tr>
                                                    <th style={{borderTop:'none'}}>Date</th>
                                                    <th style={{borderTop:'none'}}>ID</th>
                                                    <th style={{borderTop:'none'}}>Amount</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                                    return (
                                                      <>
                                                      {(value.action==='payment') &&
                                                        <tr key={ (index) } index={(index)} id={"pending-bill-"+value.value2} className={(value.is_order)?"pb-list pb-order-list":"pb-list"} target="pbills" data-id={"pending-bill-"+(index)} onClick={this.handlePendingBillSelect} onMouseUp={this.handleCheckIfMouse}>
                                                          <td>
                                                            <FontAwesomeIcon icon={faArrowLeft} className="pen-pay-ar-l"/> {value.date}
                                                          </td>
                                                          <td>
                                                            {value.value2}
                                                            {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                              <>({value.dispatched_info.supplier_ref_no})</>
                                                            }
                                                            {(value.fin_year!==undefined) &&
                                                              <span className="fyinstrip">{value.fin_year}</span>
                                                            }
                                                            <input type="hidden" id={"pending-amt-"+(index)} value={value.grand_total} />
                                                          </td>
                                                          <td>
                                                            {value.grand_total} <FontAwesomeIcon icon={faArrowRight} className="pen-pay-ar-r text-right"/>
                                                          </td>
                                                        </tr>
                                                      }
                                                      </>
                                                    )
                                                  }) : '' }

                                                    {(this.state.pending_payments_bills.length === 0) && 
                                                      <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No More Pending Payments</td></tr>
                                                    }
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  
                                }
                              </div>
                             
                              </>
                              }
                              <div className="col-md-12">
                                {this.state.add_item_area}
                              </div>
                              <span id="handleChangeHasItems" onClick={this.handleChangeHasItems}></span>
                              {(this.state.pay_type_opt==='billwise' && this.state.has_payment_items===false && this.state.send_type!=='update') &&
                                <div className="col-md-12" style={{padding:'15px'}}>
                                  <p className="text-center">Select Pending Invoice(s) to make payment.</p>
                                </div>
                              }
                            </div> 
                            {(this.state.has_payment_items) &&
                              <div className="content-head" style={{marginBottom:'140px'}}>
                              <div className="row pay-total-blk">
                                  <div className="col-md-12">
                                    <div className="row">
                                    <div className="col-md-5 mrbtm-20">
                                      <textarea className="form-control text-notes" id="pay-notes" placeholder="Note" defaultValue={this.state.payment_data!=''?this.state.payment_data.notes:''}></textarea>
                                      <ShortcutHelp arrow="top" target="msg-to-customer" shortcuts={['Ctrl+M']}/>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-6">
                                    <div className="table-responsive">
                                    <table className="table billing-total">
                                      <tbody>
                                          <tr style={{display:'none'}}>
                                              <td><span className="sp-normal">Total Payable Amount</span></td>
                                              <td className="text-right">
                                                ₹<span className="sp-normal" id="total-grand">{(this.state.payable_amount!=='')?this.state.payable_amount:0}</span>
                                                
                                              </td>
                                          </tr>
                                          <tr style={{display:'none'}}>
                                              <td><span className="sp-normal">Balance Amount</span></td>
                                              <td className="text-right">
                                                ₹<span className="sp-normal" id="total-balance">{(this.state.total_balance_amt!=='')?this.state.total_balance_amt:0}</span>
                                               
                                              </td>
                                          </tr>
                                          <tr>
                                            <td><span className="sp-normal">Discount <span id="disc_on_payment">{(this.state.discount_sym==='per') &&
                                                <>
                                                   <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                                   </span>
                                                </>
                                              }</span></span>
                                            </td>
                                            <td className="tbl-in-bx">
                                              <>
                                               <input type="text" className="form-control w-100px pull-right" defaultValue= {this.state.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount}/>
                                               <div className="form-group round-blk">
                                                <div className="form-check">
                                                  <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange}/>
                                                  <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                    ₹
                                                  </label>
                                                </div>
                                                <div className="form-check">
                                                  <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                                  <label className="form-check-label" htmlFor="discount-per">
                                                    %
                                                  </label>
                                                </div>
                                                 
                                               </div>
                                              </>
                                            </td>
                                          </tr>
                                          <tr>
                                              <td><span className="sp-normal">Round Off</span></td>
                                              <td>
                                                <>
                                                 <input type="text" className="form-control w-100px pull-right" defaultValue= {this.state.round_off} id="round_off" onKeyUp={this.handleRoundOff}/>
                                                 <div className="form-group round-blk">
                                                  <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange}/>
                                                    <label className="form-check-label minus" htmlFor="minus">
                                                      -
                                                    </label>
                                                  </div>
                                                  <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                                    <label className="form-check-label plus" htmlFor="plus">
                                                      +
                                                    </label>
                                                  </div>
                                                   
                                                 </div>
                                                </>
                                               </td>
                                          </tr>
                                          <tr>
                                              <td><span className="sp-normal">Paid Amount</span></td>
                                              <td className="text-right">
                                                ₹<span className="sp-normal" id="total-amount">
                                                  {
                                                    (this.state.paid_amount!=='')?this.state.paid_amount:(this.state.total_grand!=='')?this.state.total_grand:0
                                                  }
                                                </span>
                                                <input type="hidden" id="total-amount-txt" defaultValue={(this.state.paid_amount!=='')?this.state.paid_amount:(this.state.total_grand!=='')?this.state.total_grand:0}/>
                                              </td>
                                          </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                              </div>
                         
                              <div className="border-top" style={{paddingTop:'16px'}}>
                                  <span id="resetPaymentOnContactChange" onClick={this.resetPaymentOnContactChange}></span>
                                  {(this.state.is_fy_closed===false) &&
                                    <>
                                      <button type="button" onClick={this.createPayment} id="save" className="btn btn-success btn-save-action">{this.state.save_button}
                                            <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/>
                                      </button>
                                      <button type="button" id="save-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    </>
                                  }
                                  {(this.state.is_fy_closed===true) &&
                                    <>
                                      <ClosedFYButton className="btn-save-action" id="save" label={this.state.save_button} type="button"/>
                                    </>
                                  }
                                  {(this.state.send_type!=='update') &&
                                    <button type="button" onClick={this.resetPayment} className="btn btn-secondary btn-save-action pull-right">
                                      Reset
                                    </button>
                                  }
                              </div>
                              </div>
                            }
                            </>
                          }
                          </>
                        }
                      </div>
                      
                    </div>
                   </div>
                  </div>
             
                  {(this.state.toggle_pending_invoice===true && this.state.isMobile===false) &&
                  <>
                    <div className={(this.state.toggle_pending_invoice===true)?"prightblk col-md-4":"hide"}>
                      <div className="container-fluid">
                        <div className="content-area">
                        <div className="pay-pending-h-nor content-head border-bottom" style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                          <div className="row">
                            <div className="col-md-12">
                              <h5>Pending Invoices/Adv. Payment</h5>
                            </div>
                          </div> 
                        </div>
                        <div className="row pay-block">
                            
                            <span onClick={this.checkEmptyInvoiceList} id="hanlde-change-force-state"></span>
                            <span onClick={this.handeEmptyInvoiceList} id="hanlde-force-empty-invoice-list"></span>
                           
                            <div className="col-md-12">
                            <div className="table-responsive pen-inv-blk"  style={{maxHeight:'350px'}}>
                              <table className="table">
                                <thead>
                                  <tr className="pay-pending-h-mob">
                                    <th colSpan="3" className="text-center">PENDING INVOICES/ADV. PAYMENT</th>
                                  </tr>
                                  <tr>
                                    <th style={{borderTop:'none'}}>Date</th>
                                    <th style={{borderTop:'none'}}>ID</th>
                                    <th style={{borderTop:'none'}}>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                     return (
                                      <>
                                      {(value.action!=='payment') &&
                                        <tr key={ index } index={index} id={"pending-bill-"+value.value2} className={(value.is_order)?"pb-list pb-order-list":"pb-list"} onClick={this.handlePendingBillSelect} onMouseUp={this.handleCheckIfMouse}>
                                          <td>
                                            <FontAwesomeIcon icon={faArrowLeft} className="pen-pay-ar-l"/> {value.date}
                                          
                                          </td>
                                          <td>
                                            {value.value2}
                                            {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                              <>({value.dispatched_info.supplier_ref_no})</>
                                            }
                                            {(value.fin_year!==undefined) &&
                                              <span className="fyinstrip">{value.fin_year}</span>
                                            }
                                          </td>
                                          <td>
                                            {value.grand_total} <FontAwesomeIcon icon={faArrowRight} className="pen-pay-ar-r text-right"/>
                                          </td>
                                        </tr>
                                      }
                                      </>
                                    )
                                   }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }

                                   {(this.state.is_empty_item && this.state.pending_loader!=='No Pending Invoice Found.') && 
                                      <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No More Pending Invoices</td></tr>
                                   }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.pending_payments_bills.length > 0) && 
                      <div className="container-fluid">
                          <div className="content-area">
                          <div className="pay-pending-h-nor content-head border-bottom" style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                            <div className="row">
                              <div className="col-md-12">
                                <h5>Pending Payments/CR-DR Note</h5>
                              </div>
                            </div> 
                          </div>
                          <div className="row pay-block">
                              
                              <div className="col-md-12">
                              <div className="table-responsive pen-inv-blk"  style={{maxHeight:'350px'}}>
                                <table className="table">
                                  <thead>
                                    <tr className="pay-pending-h-mob">
                                      <th colSpan="3" className="text-center">Pending Payments/CR-DR Note</th>
                                    </tr>
                                    <tr>
                                      <th style={{borderTop:'none'}}>Date</th>
                                      <th style={{borderTop:'none'}}>ID</th>
                                      <th style={{borderTop:'none'}}>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                      return (
                                        <>
                                        {(value.action==='payment') &&
                                            <tr key={ (index) } index={(index)} id={"pending-bill-"+value.value2} className={(value.is_order)?"pb-list pb-order-list":"pb-list"} onClick={this.handlePendingBillSelect} onMouseUp={this.handleCheckIfMouse}>
                                              <td>
                                                <FontAwesomeIcon icon={faArrowLeft} className="pen-pay-ar-l"/> {value.date}
                                              
                                              </td>
                                              <td>
                                                {value.value2}
                                                {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                  <>({value.dispatched_info.supplier_ref_no})</>
                                                }
                                                {(value.fin_year!==undefined) &&
                                                  <span className="fyinstrip">{value.fin_year}</span>
                                                }
                                              </td>
                                              <td>
                                                {value.grand_total} <FontAwesomeIcon icon={faArrowRight} className="pen-pay-ar-r text-right"/>
                                              </td>
                                            </tr>
                                        }
                                        </>
                                      )
                                    }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }

                                    {(this.state.pending_payments_bills.length === 0) && 
                                        <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No More Pending Payments</td></tr>
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                 
                  </>
                  }
                </div>
              </div>
              <ModalBox id="add_contact" title="CREATE CONTACT" data_id="Create Contact"/>

              
              {(this.state.is_bill_clicked===true) &&
                <div className="modal" id="cheque-info-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Enter Cheque Info</h5>
                      </div>
                      <div className="modal-body">
                          <ChequeInputInfo type="div"/>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseChequeInfoPopup}>Close</button>
                        <button type="button" className="btn btn-success pull-right" onClick={this.handleAddChequeInfo}>Done</button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
        );
    }

    handleAddChequeInfo = () =>{
      let cheque_date = document.getElementById("cheque_date").value;
      let cheque_no = document.getElementById("cheque_no").value;
      
      let qs = document.querySelectorAll(".pb-list");
      for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        let idx = element.getAttribute('index');
        if(idx===this.state.clicked_index){
          element.click();
          break;
        }
      }
      this.handleCloseChequeInfoPopup();
    }

    handleCloseChequeInfoPopup = ()=>{
      this.setState({is_bill_clicked:false})
    }
}