import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faPencilAlt,faCheckCircle,faTimes,faTrash,faEye, faSpinner, faThList} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import AddContact from './add-contact';
import ContactTypes from './contact-types';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let _this;
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
    dbReq.onupgradeneeded = function(event) {
        db = event.target.result;
    }
    dbReq.onsuccess = function(event) {
        db = event.target.result;
    }
}
export default class CreateContactOnFly extends Component {
    constructor() {
      super();
      this.state = {
        is_detail : false, 
        is_btn_visible : false, 
        info_body : {
            type : 1,
            name : '',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            email : '',
            phone_1 : '',
            phone_2 : '',
            address_1 : '',
            address_2 : '',
            city : '',
            state : '',
            pin : '',
            gst_number : '',
            pan_no : '',
            payment_mode : '',
            bank_name : '',
            account_no : '',
            ifsc_code : '',
            credit_limit : '',
            bank_address : '',
            credit_days : '',
            bank_city : '',
            notes : '',
            contact_type : 'Customer',
            discount : 0
        }    
      };
    }
    
    componentDidMount(){
      document.addEventListener('keydown', this.handleTriggerKeyEvent);
    }

    handleTriggerKeyEvent = (e) =>{
      if(e.which===13){
          if(document.getElementById("create-contact-on-fly")){
            let is_save_contact = document.getElementById("create-contact-on-fly").style.display;
            if(is_save_contact==="block"){
              if(document.getElementById("s-con-on-fly")){
                document.getElementById("s-con-on-fly").click();
              }
            }
          }
        }
        if(e.which===27){
          if(document.getElementById("create-contact-on-fly")){
            let is_save_contact = document.getElementById("create-contact-on-fly").style.display;
            if(is_save_contact==="block"){
              if(document.getElementById("close-contact")){
                document.getElementById("close-contact").click();
              }
              if(document.getElementById("handleCloseContact")){
                document.getElementById("handleCloseContact").click();
              }
            }
          }
        }
        if(e.which===13 && e.ctrlKey){
          if(document.getElementById("create-contact-on-fly")){
            let is_save_contact = document.getElementById("create-contact-on-fly").style.display;
            if(is_save_contact==="block"){
              //setTimeout(() => {
                if(document.getElementById("add-contact")){
                  document.getElementById("add-contact").click();
                }
              //}, 500);
            }
          }
        }
      
    }

    handleCreateContactOnFly = (e) =>{
        let data_index = localStorage.getItem('data_index');
        var element = document.getElementById('create-contact-on-fly');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("new_name").focus();
        let _th = this;
        setTimeout(() => {
          _th.setState({is_btn_visible:true});
        }, 100);
        let contact = '';
        if(document.getElementById("contact_box")){
          contact = document.getElementById("contact_box").value;
        }else if(document.getElementById("contact_box_"+data_index)){
          contact = document.getElementById("contact_box_"+data_index).value;
        }
        if(contact!==""){
          if(isNaN(contact)){
            document.getElementById("new_name").value = contact;
          }else{
            document.getElementById("new_phone").value = contact;
          }
        }
        let curl = window.location.href;
        const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
        const in_or_out = curl.match(/new-payment\/([all|in|out]+$)/i);
        
        const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
        let contact_type = '1';
        let contact_type_name = 'Customer';
        if(add_billing_type && (add_billing_type[1]==='1' || add_billing_type[1]==='3')){
          contact_type = 1;
          contact_type_name = 'Customer';
        }else if(add_billing_type && (add_billing_type[1]==='2' || add_billing_type[1]==='4')){
          contact_type = 2;
          contact_type_name = 'Supplier';
        }

        if(in_or_out && in_or_out[1]==='out'){
          contact_type = 2;
          contact_type_name = 'Supplier';
        }

        if(edit_billing && (edit_billing[2]==='sales' || edit_billing[2]==='sales_return')){
          contact_type = 1;
          contact_type_name = 'Customer';
        }else if(edit_billing && (edit_billing[2]==='purchase' || edit_billing[2]==='purchase_return')){
          contact_type = 2;
          contact_type_name = 'Supplier';
        }

        let info_body = this.state.info_body;

        info_body.type = contact_type;
        info_body.contact_type = contact_type_name;
        this.setState({info_body:info_body})
        if(document.getElementById("contact-type-select")){
          document.getElementById("contact-type-select").value = contact_type;
        }
      }

      closeModel = (e)=> {
        let data_index = localStorage.getItem('data_index');
        var element = document.getElementById('create-contact-on-fly');
        element.classList.add("fade");
        element.style.display="none"; 
        this.setState({is_btn_visible:false});
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").focus();
        }
        if(document.getElementById("contact_box_"+data_index)){
          document.getElementById("contact_box_"+data_index).focus();
        }
      }

      saveContact = (event) =>{
        let data_index = localStorage.getItem('data_index');
        let name = document.getElementById('new_name').value;
        if(name===""){
          document.getElementById('new_name').style.border='1px solid red';
          return false;
        }
        let id = event.currentTarget.id;
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");
        var e = document.getElementById("contact-type-select");
        var contact_type_name = e.options[e.selectedIndex].text;
        let type = e.value;
        let email = document.getElementById('new_email').value;
        let phone = document.getElementById('new_phone').value;
        let discount = document.getElementById('new_discount').value;
        
        localStorage.setItem('phone_1',phone);
        

        let info_body = {
                type : type,
                name : name,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
                email : email,
                phone_1 : phone,
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : contact_type_name,
                discount : discount
            };

        if(document.getElementById("phone_1")){
          document.getElementById("phone_1").value = phone;
        }
        if(document.getElementById("contact_add_type")){
          document.getElementById("contact_add_type").value="onthefly";
        }
        let api = app_api+'/create_contact';
        axios.post(api, info_body)
          .then(function (response) {
            let data = response.data;

            if(data.success === 1){
             let resp = response.data.response;
             let insert_id = parseInt(resp.insert_id);
             this.handleGetContactOnID(insert_id);
            if(document.getElementById("contact_box")){
              document.getElementById("contact_box").value = info_body.name;
            }
            if(document.getElementById("customer-dropdown")){
              document.getElementById("customer-dropdown").value = insert_id;
            }
            if(document.getElementById("contact_box_"+data_index)){
              document.getElementById("contact_box_"+data_index).value = info_body.name;
            }
            
            localStorage.setItem("selected_contact",insert_id);

            if(document.getElementById("customer-dropdown-"+data_index)){
              document.getElementById("customer-dropdown-"+data_index).value = insert_id;
              if(document.getElementById("handleMarkCheckContact")){
                document.getElementById("handleMarkCheckContact").click();
              }
            }
             let disc = 0;
             if(info_body.discount!==""){
              disc = info_body.discount;
             }
             localStorage.setItem('discount',disc);
             localStorage.setItem('state',"");
             localStorage.setItem('payment_mode','all');

            document.getElementById('new_name').value = "";
            document.getElementById('new_email').value = "";
            document.getElementById('new_phone').value = "";
            document.getElementById('new_discount').value = 0;

            document.getElementById("close-contact").click();
            if(document.getElementById("contact_box")){
              document.getElementById("contact_box").focus();
            }
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
            this.setState({is_btn_visible:false});
            try{
             let transaction = db.transaction("contacts", "readwrite");
             let contacts = transaction.objectStore("contacts");
             let contact = CryptoJS.AES.encrypt(JSON.stringify(info_body), enc_key).toString();
             let contact_type = info_body.type;
             let contact_data = {};
             contact_data['id'] = parseInt(insert_id);
             contact_data['type'] = parseInt(contact_type);
             contact_data['contact_type'] = contact_type_name;
             contact_data['data'] = contact;
             contacts.add(contact_data);
           }catch(error){
            //console.log(error);
           }
         }else{
            document.getElementById(id).classList.remove("hide");
              document.getElementById(id+'-btn-spinner').classList.add("hide");
            document.getElementById("name-exist").innerHTML = data.message;
         }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
    }

    handleGetContactOnID = (id)=>{
      let body = {};
      body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          id : id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
       }
      let api = app_api+'/get_contact_on_id';
      axios.post(api, body)
        .then(function (response) {
            let data = response.data;
            localStorage.setItem('contact_info',JSON.stringify(data[0]));
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
              }
          }
      })
    }

    openContactDetail = (e) =>{
        let name = document.getElementById('new_name').value;
        var e = document.getElementById("contact-type-select");
        var contact_type_name = e.options[e.selectedIndex].text;
        let type = e.value;
        let email = document.getElementById('new_email').value;
        let phone = document.getElementById('new_phone').value;
        let discount = document.getElementById('new_discount').value;
       
        let info_body = {
                type : type,
                name : name,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
                email : email,
                phone_1 : phone,
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : contact_type_name,
                discount : discount
            };
        this.setState({info_body:info_body,is_detail:true});
    }
    
    handleCloseContact = (e) =>{
        this.setState({is_detail:false});
    }

    render() {
        return (
            <>
                <div className="mrtop-5 create-contact hide">	
                    or <a className="new_contact_a" id="handleCreateContactOnFly" onClick={this.handleCreateContactOnFly}>create a new contact</a>	
                </div>	
                <div className="modal fade" id="create-contact-on-fly" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    {(this.state.is_detail===false) &&
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Create Contact</h5>
                                </div>
                                <div className="modal-body">
                                
                                    <div className="row">
                                    <div className="col-md-6">
                                        <label>TYPE <span className="red">*</span></label>
                                        <ContactTypes show="dropdown" name="new_contact_type" target="add-contact" default={this.state.info_body.type}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="new_name">NAME <span className="red">*</span></label>
                                        <input type="text" className="form-control" placeholder="" id="new_name" autoFocus defaultValue={(this.state.info_body.name!=="")?this.state.info_body.name:this.props.userInput}/>
                                        <p className="error" id="name-exist"></p>
                                    </div>
                                    </div>
                                    <div className="row mrtop-10">
                                    
                                    <div className="col-md-12">
                                        <label htmlFor="new_email">EMAIL</label>
                                        <input type="text" className="form-control" placeholder="" id="new_email" defaultValue={this.state.info_body.email}/>
                                    </div>
                                    </div>
                                    <div className="row mrtop-10">
                                      <div className="col-md-6">
                                          <label htmlFor="new_phone">PHONE</label>
                                          <input type="text" className="form-control" placeholder="" id="new_phone" defaultValue={this.state.info_body.phone_1}/>
                                      </div>
                                      <div className="col-md-6">
                                          <label htmlFor="new_discount">DISCOUNT %</label>
                                          <input type="text" className="form-control" id="new_discount" placeholder="0" defaultValue={this.state.info_body.discount}/>
                                      </div>
                                    </div>
                                    <div className="row mrtop-10">
                                      <div className="col-md-12 text-center">
                                        <span className="fyinstrip" style={{fontSize:'18px',cursor:'pointer'}} onClick={this.openContactDetail}>Add more contact info</span>
                                      </div>
                                    </div>
                                </div>

                                {(this.state.is_btn_visible===true) &&
                                  <div className="modal-footer">
                                      <button type="button" className="btn btn-secondary" onClick={this.closeModel} id="close-contact">Close</button>
                                      
                                      <button type="button" data-id={this.props.id} className="btn btn-success pull-right" id="s-con-on-fly" onClick={this.saveContact}>Save Contact</button>
                                      <button type="button" id="s-con-on-fly-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                  </div>
                                }
                            </div>
                        </div>
                    }
                    {(this.state.is_detail===true) &&
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <AddContact is_on_fly={true} handleCloseContact={this.handleCloseContact.bind(this)} info={this.state.info_body}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}