import React, {Component} from "react";
import axios from 'axios';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import encrypt_decrypt from './encrypt-decrypt.js';
let {app_api} = require('../library.js');
export default class UserVerify extends Component {
    constructor() {
        super();
        this.state = {
            data : [],
            is_set : false,
            
        }
        this.decrypt = encrypt_decrypt.decrypt.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }
    changePassword(event){
        let newpassword = document.getElementById("newpassword").value;
        let confirmpassword = document.getElementById("confirmpassword").value;
        if(newpassword!==confirmpassword){
          document.getElementById("error").innerHTML = "Password and Confirm Password did not match.";
        }else if(newpassword==='' || confirmpassword===''){
          document.getElementById("error").innerHTML = "Please enter a valid password.";
        }else{
          let id = event.currentTarget.id;
          document.getElementById(id).classList.add("hide");
          document.getElementById(id+'-btn-spinner').classList.remove("hide");
          var body = {
            password: confirmpassword,
            store_name : this.state.data.store_name,
            branch_id : this.state.data.branch_id,
            user_id : this.state.data.user_id,
            type : this.state.data.type,
          };

          axios.post(app_api+'/set_password', body)
            .then(function (response) {
              let resp = response.data;
              if(resp.success===1){
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                this.setState({is_set:true});
                document.getElementById("success-msg").innerHTML = resp.message;
              }else{
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                document.getElementById("error").innerHTML = resp.message;
              }
             //window.location = "/login";
          }.bind(this)).catch(function(res){
              
          })
        }
    }
    componentDidMount(){
      localStorage.removeItem('user');
      const urlParams = new URLSearchParams(window.location.search);
      const ct = decodeURIComponent(urlParams.get('k'));
      const iv = decodeURIComponent(urlParams.get('v'));
      const s = decodeURIComponent(urlParams.get('s'));
      let data = {ct:ct,iv:iv,s:s};
      data = this.decrypt(data);       
      this.setState({data:JSON.parse(data)});
      console.log(JSON.parse(data));
    }
    render() {
        return (
            <div className="user-verify-cont">
              <div className="verify-blk">
                  {!this.state.is_set &&
                    <>
                  <div className="icon-div"><FontAwesomeIcon icon={faKey} className="icon"/></div>
                  <h5 className="mrtop-30">Setup Your Password</h5>
                  <div className="form-group mrtop-20">
                      <label>New Password</label>
                      <input type="password" id="newpassword" className="form-control" placeholder="New Password" autoFocus/>
                  </div>
                  <div className="form-group mrtop-20">
                      <label>Confirm Password</label>
                      <input type="password" id="confirmpassword" className="form-control" placeholder="Confirm Password"/>
                      <p className="red confirm-error" id="error"></p>
                  </div>
                  <div className="form-group mrtop-30">
                   
                      <button type="button" id="set-pwd" onClick={this.changePassword} className="btn btn-danger btn-block">Set Password</button>
                      <button type="button" id="set-pwd-btn-spinner" className="hide btn btn-danger  btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      
                  </div>
                  </>
                  }
                  {this.state.is_set &&
                    <>
                      <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">Well done!</h4>
                      <p id="success-msg"></p>
                      </div>
                    </>
                  }
              </div>
          </div>
        );
    }
}