import React, { Component } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ContactsDropdown from "./contacts-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faPlus, faCalendarAlt, faSpinner, faTimesCircle,faCheckCircle,faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../utils.js';
import PaymentModes from "./payment-modes";
import SelectDropdown from "./select-dropdown";
import Autocomplete from "./autocomplete";
import AccountTypes from "./account-types";
import ExpenseTypes from "./expense-types";
let {enc_key,app_api,user_info,states} = require('../library.js');
export default class AddOldBills extends Component {
    constructor() {
        super();
        this.state = {
            selection_type : 'sale',
            invoice_list : [{date:new Date(),amount:'',payment_mode:1,note:''}],
            selected_index : 0,
            payment_modes : [],
            import_type : '',
            import_file : '',
            show_custom_headers : false,
            csv_headers : [],
            import_data : [],
            import_file : [],
            upload_preview_data : [],
            upload_processed_data : [],
            bill_import_headers : [],
            payment_import_headers : [],
            ob_processed_data : {'processed_contact':[],'not_processed_contact':[]},
            show_error : false,
            is_upload_previous : false,
            upload_selection_type : 'sale'
        }
    }
    handleGetHeaders = ()=>{
      let keys = "'bill_import_headers','payment_import_headers','upload_selection_type','upload_preview_data','upload_processed_data'";
      
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            keys:keys
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.bill_import_headers!==undefined){
              let bill_import_headers = resp.bill_import_headers;
              this.setState({bill_import_headers:bill_import_headers});
            }
            if(resp.payment_import_headers!==undefined){
              let payment_import_headers = resp.payment_import_headers;
              this.setState({payment_import_headers:payment_import_headers});
            }
          
            if(resp.upload_selection_type!==undefined){
              let upload_selection_type = resp.upload_selection_type;
              this.setState({upload_selection_type:upload_selection_type});
            }
            if(resp.upload_preview_data!==undefined){
              let upload_preview_data = resp.upload_preview_data;
              this.setState({upload_preview_data:upload_preview_data});
            }
            if(resp.upload_processed_data!==undefined){
              let upload_processed_data = resp.upload_processed_data;
              this.setState({upload_processed_data:upload_processed_data});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    componentDidMount(){
        let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
             }
          let api = app_api+'/get_payment_mode';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              let PaymentMode = [];
              cursor.map( (value, key) => {
                  let id = value.payment_mode_id;
                  let payment_mode = value.payment_mode;
                  if(this.props.target==='payments' && payment_mode!=='Credit'){
                    PaymentMode[id] = {payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay};
                  }else if(this.props.target!=='payments'){
                    PaymentMode[id] = {payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay};
                  }
              });
             
              this.setState({ 'payment_modes': PaymentMode }); 
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })
          this.handleGetHeaders();
      }
    handleChangeType = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({selection_type:thisval});
        localStorage.removeItem('import_contact_type');
        let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          name : 'upload_selection_type',
          value : thisval,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }

    handleAddMoreItem = (e) =>{
        let randval = Math.floor(Math.random() * 9000) + 1000;
        let invoice_list =  this.state.invoice_list;
        let new_field = {date:new Date(),amount:'',payment_mode:1,note:''};
        invoice_list.push(new_field);
        this.setState({invoice_list:invoice_list});
    }

    handleFieldKeydown = (e) =>{
      let key_code = e.which;
      let idx = e.currentTarget.getAttribute('data-id');
      let target = e.currentTarget.getAttribute('data-target');
     
      if(key_code===13){
        if(target==='note'){
            this.handleAddMoreItem();
        }else{
            if(target==='amount'){
                document.getElementById('aob-payment-mode-'+idx).focus();
            }else if(target==='payment_mode'){
                document.getElementById('aob-note-'+idx).focus();
            }
        }
      }
    }

    handleSetFieldData = (e) =>{
        if(e.currentTarget!==undefined){
            let thisvalue = e.currentTarget.value;
            let target = e.currentTarget.getAttribute('data-target');
            let idx = e.currentTarget.getAttribute('data-id');
            let invoice_list =  this.state.invoice_list;
           
            invoice_list[idx][target] = thisvalue;
            this.setState({invoice_list:invoice_list});
        }else{
            let si = this.state.selected_index;
            let invoice_list =  this.state.invoice_list;
            invoice_list[si]['date'] = e;
            this.setState({invoice_list:invoice_list});
            document.getElementById("aob-amount-"+si).focus();
        }
    }
    handleRemoveListItem = (e) =>{
        let idx = e.currentTarget.getAttribute('data-id');
        let invoice_list =  this.state.invoice_list;
        invoice_list.splice(idx,1);
        this.setState({invoice_list:invoice_list});
    }
    handleElementFocus = (e) =>{
        
        let idx = e.currentTarget.id;
        var matches = idx.match(/(\d+)/);
        let index = matches[0];
        this.setState({selected_index:index});

    }
    
    saveOldBill = (event) =>{
        let id = event.currentTarget.id;
        let type = this.state.selection_type;
        let contact = parseInt(document.getElementById("customer-dropdown").value);

        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");
        let api = app_api+'/create_old_bills';
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            type : type,
            contact : contact,
            data:this.state.invoice_list,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
        }
        axios.post(api, set_data)
        .then(function (response) {
            let data = response.data;
            
            if(type==='sale'){
                window.location.href = '/billings';
            }else if(type==='purchase'){
                window.location.href = '/billings/purchase';
            }else if(type==='sales_return'){
                window.location.href = '/billings/sales_return';
            }else if(type==='purchase_return'){
                window.location.href = '/billings/purchase_return';
            }else if(type==='payment_in'){
                window.location.href = '/payments/in';
            }else if(type==='payment_out'){
                window.location.href = '/payments/out';
            }
           
        }.bind(this)).catch(function(res){
            if(res.response){
            if(res.response.data){
                
            }
            }
        })
      
    }
    handleCloseOldBill = () =>{
        this.props.handleCloseOldBill();
    }
    handleChangeImportType = (e) =>{
        let thisvalue = e.currentTarget.value;
        this.setState({import_type:thisvalue});
    }
    handleOpenImport = (e) =>{
        if(document.getElementById("payment_mode")){
          let pmode = document.getElementById("payment_mode").value;
          if(pmode===""){
            document.getElementById("payment_mode").focus();
            return false;
          }
        }
        document.getElementById("import-data").click();
    }
    handleImportData = (e) =>{
      document.getElementById('imp-con').classList.add("hide");
      document.getElementById('imp-con-btn-spinner').classList.remove("hide");
      const files = e.target.files
      const formData = new FormData();
      let csv = files[0];
      this.setState({import_file:csv});
      formData.append('importFile', csv);
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);
      formData.append('type', 'process_data');
      formData.append('target', 'import_bills');
      
      let api = app_api+'/process_import_csv';
      axios.post(api, formData)
        .then(function (response) {
          let resp = response.data;
          document.getElementById('imp-con').classList.remove("hide");
          document.getElementById('imp-con-btn-spinner').classList.add("hide");
          this.setState({show_custom_headers:true,csv_headers:resp.headers,import_data:resp.import_data});
         // this.setState({import_data:response.data,show_import:true});
      }.bind(this)).catch(function(res){
          
      })
    }

    closedProcessModal = () =>{
      var element = document.getElementById('process-ob-list');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    handleBackToTypeSelection = () =>{
      this.setState({show_custom_headers:false})
    }
    handleResumePreviousData = () =>{
      if(document.getElementById("payment_mode")){
        let pmode = document.getElementById("payment_mode").value;
        if(pmode===""){
          document.getElementById("payment_mode").focus();
          return false;
        }
      }
      this.setState({current_screen:'preview_headers'});
      this.setState({is_upload_previous:true});
      
      let upload_preview_data = this.state.upload_preview_data;
      let preview_data = upload_preview_data.preview_data;
      
      this.setState({preview_data:preview_data});
      this.setState({preview_total_before_tax:upload_preview_data.before_tax_total});
      this.setState({preview_grand_total:upload_preview_data.total_amount});
      this.setState({preview_debit_total:upload_preview_data.debit_total});
      this.setState({preview_credit_total:upload_preview_data.credit_total});

      let upload_processed_data = this.state.upload_processed_data;
      this.setState({show_custom_headers:true,csv_headers:upload_processed_data.headers,import_data:upload_processed_data.import_data});
      setTimeout(() => {
        if(document.getElementById("set-custom-header")){
          document.getElementById("set-custom-header").click();
        }
      }, 100);
    }
    render() {
        return (
            <div className="main-content add-old-bills">
                <div className="content-head border-bottom">
                    <b>ADD/MIGRATE DATA</b><span className="pull-right"><FontAwesomeIcon icon={faTimesCircle} onClick={this.handleCloseOldBill} style={{cursor:'pointer'}}/></span>
                    <p className="info">Migrate/Add your old bills from here by choosing type and contact.</p>
                </div>
                {(this.state.show_custom_headers===true) &&
                    <ImportHeaderSettings is_upload_previous={this.state.is_upload_previous} headers={this.state.csv_headers} bill_import_headers={(this.state.selection_type!=='payment')?this.state.bill_import_headers:this.state.payment_import_headers} import_data={this.state.import_data} import_file={this.state.import_file} handleBackToTypeSelection={this.handleBackToTypeSelection} import_type={this.state.selection_type} upload_preview_data={this.state.upload_preview_data}/>
                }
                {(this.state.show_custom_headers===false) &&
                <>
                <div className="content-head border-bottom">
                    <div className="row mrbtm-10">
                        <div className="col-md-3">
                            <label>IMPORT TYPE</label>
                            <select className="form-control" onChange={this.handleChangeImportType} defaultValue={this.state.import_type}>
                                <option value="">Select Import Type</option>
                                <option value="csv">CSV</option>
                                <option value="manual">Manual</option>
                            </select>
                        </div>
                        <div className="col-md-7">

                        </div>
                    </div>
                </div>
                {(this.state.import_type==='manual') &&
                    <>
                        <div className="content-head border-bottom">
                            <div className="row mrbtm-10">
                            
                                <div className="col-md-3">
                                    <label>TYPE</label>
                                    <select className="form-control" onChange={this.handleChangeType} defaultValue={this.state.selection_type}>
                                        <option value="sale">Sale</option>
                                        <option value="purchase">Purchase</option>
                                        <option value="sales_return">Sales Return</option>
                                        <option value="purchase_return">Purchase Return</option>
                                    {/*  <option value="payment_in">Payment IN(+)</option>
                                        <option value="payment_out">Payment OUT(-)</option> */}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>CONTACT</label>
                                    <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box"  target="add-old-bill" contact_info={[]}/>
                                </div>
                                <div className="col-md-6">

                                </div>
                            </div>
                        </div>
                        <div className="content-head border-bottom">
                    
                            <div style={{marginLeft:'10px', width:'100%'}}>
                            <table className="table cftbl" style={{width:'100%'}}>
                            
                                <thead>
                                    <tr>  
                                        <th>DATE</th>
                                        <th>AMOUNT</th>
                                        {(this.state.selection_type!=='sales_return' && this.state.selection_type!=='purchase_return') &&
                                            <th>PAYMENT MODE</th>
                                        }
                                        <th>REFRENCE NOTE</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                {(this.state.invoice_list.length>0) &&
                                <>
                                {this.state.invoice_list.map((value,index) => {
                                    return (
                                    <tr key={'aob-'+index}>
                                        <td>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text no-border-right">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </span>
                                            <DatePicker className="form-control no-border-left" selected={value.date} data-id={index} data-target="date" onChange={this.handleSetFieldData} id={"aob-date-"+index} dateFormat="dd/MM/yyyy" onFocus={this.handleElementFocus}/>
                                            </div>
                                        </div>
                                        
                                        </td>
                                        <td style={{textAlign:'center'}}>
                                            <input type="text" className="form-control" value={value.amount} data-id={index} data-target="amount" onChange={this.handleSetFieldData} onKeyDown={this.handleFieldKeydown} id={"aob-amount-"+index} autoFocus={true}/>
                                        </td>
                                        {(this.state.selection_type==='sale' || this.state.selection_type==='purchase') &&
                                            <td style={{textAlign:'center'}}>
                                                <select className="form-control" defaultValue={value.payment_mode} data-id={index}  data-target="payment_mode" onChange={this.handleSetFieldData} onKeyDown={this.handleFieldKeydown} id={"aob-payment-mode-"+index}>
                                                    <option value="1">Payment</option>
                                                    <option value="2">Credit</option>
                                                </select>
                                            </td>
                                        }
                                        {(this.state.selection_type==='payment_in' || this.state.selection_type==='payment_out') &&
                                            <td style={{textAlign:'center'}}>
                                                <select className="form-control" defaultValue={value.payment_mode} data-id={index}  data-target="payment_mode" onChange={this.handleSetFieldData} onKeyDown={this.handleFieldKeydown} id={"aob-payment-mode-"+index}>
                                                {(this.state.payment_modes.length > 0) ? this.state.payment_modes.map( (value, index) => {
                                                    return (
                                                    <>
                                                        {(value.status===1 && index!==2) &&
                                                        <option value={index} key={index}>{value.payment_mode}</option>
                                                        }
                                                    </>
                                                    )
                                                }) : ''}
                                                </select>
                                            </td>
                                        }
                                        <td style={{textAlign:'center'}}>
                                            <input type="text" className="form-control" value={value.note} data-id={index} data-target="note" onChange={this.handleSetFieldData} onKeyDown={this.handleFieldKeydown} id={"aob-note-"+index}/>
                                        </td>
                                        <td>
                                            <button type="button" className="btn" data-id={index} onClick={this.handleRemoveListItem} style={{marginTop:'3px'}}>
                                                <FontAwesomeIcon icon={faTimes}  style={{marginRight:'0px'}}></FontAwesomeIcon>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={4} style={{textAlign:'center'}}>
                                    <button type="button" className="btn btn-outline-info btn-block" onClick={this.handleAddMoreItem} style={{marginTop:'3px',marginLeft:'3px'}}>
                                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                            </button>
                                    </td>
                                </tr>
                                </>
                                }
                                {(this.state.invoice_list.length===0) &&
                                <tr>
                                <td colSpan={4} className="text-center">
                                    <button type="button" className="btn btn-outline-info" onClick={this.handleAddMoreItem} style={{marginTop:'3px'}}>
                                    ADD OLD BILL(S)
                                    </button>
                                </td>
                                </tr>
                                }
                                </tbody>
                            </table>
                            </div>
                    
                        </div>
                    </>
                }
                {(this.state.import_type==='csv') &&
                    <>
                        <div className="content-head border-bottom">
                        {(this.state.upload_preview_data && this.state.upload_preview_data.preview_data && this.state.selection_type===this.state.upload_selection_type) &&
                            <div className="row mrbtm-10">
                              <div className="col-md-6" style={{marginTop:'30px'}}>
                                <button type="button" className="btn btn-outline-success btn-block" onClick={this.handleResumePreviousData}>Resume the previous uploaded data</button>
                              </div>
                            </div>
                          }
                            <div className="row mrbtm-10">

                                <div className="col-md-3">
                                    <label>TYPE</label>
                                    <select className="form-control" onChange={this.handleChangeType} defaultValue={this.state.selection_type}>
                                        <option value="sale">Sale</option>
                                        <option value="purchase">Purchase</option>
                                        <option value="sales_return">Sales Return</option>
                                        <option value="purchase_return">Purchase Return</option>
                                        <option value="payment">Payments/Expenses</option>
                                        {/*<option value="opening_balance">Opening Balance</option> */}
                                    </select>
                                </div>
                                {(this.state.selection_type==='payment') &&
                                    <div className="col-md-3">
                                    <label>ACCOUNT</label>
                                    <AccountTypes  show="dropdown" name="payment_mode" id="payment_mode"/>
                                </div>
                                }
                                {(this.state.selection_type==='opening_balance') &&
                                    <div className="col-md-3">
                                        <label>OPENING BALANCE TYPE</label>
                                        <select className="form-control" id="opening_balance_import_type">
                                            <option value="billing">Billings</option>
                                            <option value="orders">Orders</option>
                                        </select>
                                    </div>
                                }
                                <div className="col-md-3" style={{marginTop:'30px'}}>
                                    <input type="file" onChange={this.handleImportData} id="import-data" accept=".csv" style={{display:'none'}}/>
                                    <button type="button" className="btn btn-success" id="imp-con" onClick={this.handleOpenImport}>SELECT CSV TO IMPORT</button>
                                    <button type="button" id="imp-con-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                </div>
                            </div>
                       </div>
                        <div className="content-head border-bottom">
                    
                            <div style={{marginLeft:'10px', width:'100%'}}>
                         
                            </div>
                    
                        </div>
                    </>
                }
                {(this.state.import_type==='manual')&&
                <div className="content-head border-bottom text-right">
                    <button type="button" id="save-old-bill-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    <button type="button" id="save-old-bill" onClick={this.saveOldBill} className="btn btn-success btn-save-action">Save</button>
                </div>
                }

                <div className="modal fade" id="process-ob-list" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Opening Blanace Import</h5>
                      </div>
                      <div className="modal-body"  style={{overflowY:'auto',maxHeight:'500px'}}>
                        <div className="row">
                            <div className="col-md-6">
                                <h5 style={{color:'green',fontFamily: 'monospace'}}>Total Processed Contact : {this.state.ob_processed_data.processed_contact.length}</h5> 
                            </div>
                            <div className="col-md-6">
                                <h5 style={{color:'red',fontFamily: 'monospace'}}>Unprocessed Contact : {this.state.ob_processed_data.not_processed_contact.length}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p style={{color: 'gray',fontSize: '14px',fontFamily: 'monospace'}}>Unprocessed contact list is not available in the database, Once unprocessed list will be uploaded it will create a new contact.</p>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table no-block" id="unprocess-ob-list-table">
                                <thead>
                                    <th>DATE</th>
                                    <th>NAME</th>
                                    <th>DEBIT</th>
                                    <th>CREDIT</th>
                                </thead>
                                <tbody>
                                    {(this.state.ob_processed_data.not_processed_contact.map((value)=>{
                                        return(
                                            <tr>
                                                <td>{value.date}</td>
                                                <td>{value.name}</td>
                                                <td>{value.debit}</td>
                                                <td>{value.credit}</td>
                                            </tr>
                                        )
                                    }))}
                                </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="row">
                            <div className="col-md-2">
                                <button type="button" className="btn btn-secondary" onClick={this.closedProcessModal}>Close</button>
                            </div>
                            <div className="col-md-4">
                            <ReactHTMLTableToExcel
                                  id="donwload-unprocess-ob-list-xls-button"
                                  className="btn btn-success pull-right mrbtm-10"
                                  table="unprocess-ob-list-table"
                                  filename="unprocess-ob-list"
                                  sheet="unprocess-ob-list"
                                  buttonText="Download List"/>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-success" onClick={()=>this.hanldleUploadOpeningBalance('processed')} id="processed-ob-btn" style={{float:'right',marginLeft:'10px'}}>Upload Processed Only</button>
                                <button type="button" id="processed-ob-btn-spinner" className="hide btn btn-success" style={{float:'right',marginLeft:'10px'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                <button type="button" className="btn btn-success" onClick={()=>this.hanldleUploadOpeningBalance('all')} id="all-ob-btn" style={{float:'right'}}>Upload All</button>
                                <button type="button" id="all-ob-btn-spinner" className="hide btn btn-success" style={{float:'right'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                </>
              }
            </div>
        );
    }

    hanldleUploadOpeningBalance  = (type) =>{
        document.getElementById(type+'-ob-btn').classList.add("hide");
        document.getElementById(type+'-ob-btn-spinner').classList.remove("hide");
        let opening_balance_import_type = 'billing';
        if(document.getElementById("opening_balance_import_type")){
            opening_balance_import_type = document.getElementById("opening_balance_import_type").value;
        }

        
        let body = {};
        body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                data :this.state.ob_processed_data, 
                target :this.state.selection_type, 
                type :type, 
                opening_balance_import_type:opening_balance_import_type
           }
        let api = app_api+'/upload_opening_balance';
        axios.post(api, body)
          .then(function (response) {
            let resp = response.data;
            document.getElementById(type+'-ob-btn').classList.remove("hide");
            document.getElementById(type+'-ob-btn-spinner').classList.add("hide");
            window.location.href = '/contacts/1';
        }.bind(this)).catch(function(res){
            
        })
    }
}



class ImportHeaderSettings extends Component{
    constructor(props) {
      super(props)
      this.state = {
        headers : [],
        headers_list : [],
        current_screen : 'setup_headers',
        unmapped_headers : [],
        preview_data : [],
        preview_total_before_tax : 0,
        preview_grand_total : 0,
        preview_debit_total : 0,
        preview_credit_total : 0,
        offset : 0,
        progress : 0
      }
    }
  
    handleChangeHeader = e =>{
      let key = e.currentTarget.id;
      let value = e.currentTarget.value;
      let headers = this.state.headers;
      headers[key].default = value;
      this.setState({headers:headers});
    }
  
    closeHeaderSetting = e =>{
      /* if(document.getElementById("hideImportHeader")){
        document.getElementById("hideImportHeader").click();
      } */
      this.props.handleBackToTypeSelection()
    }

    handlePreviewData = () =>{
      this.setState({show_error:false});
      if(this.props.is_upload_previous===true){
        if(this.props.upload_preview_data && this.props.upload_preview_data.preview_data){
          let up_data = this.props.upload_preview_data;
          let preview_data = up_data.preview_data;
          this.setState({preview_data:preview_data});
          this.setState({preview_total_before_tax:up_data.before_tax_total});
          this.setState({preview_grand_total:up_data.total_amount});
          this.setState({preview_debit_total:up_data.debit_total});
          this.setState({preview_credit_total:up_data.credit_total});
          return false;
        }
      }
      const files = this.props.import_file;
      const formData = new FormData();
      formData.append('importFile', files);
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);
      formData.append('type', 'preview_data');
      formData.append('target', 'import_bills');
      formData.append('import_type', this.props.import_type);
      let api = app_api+'/process_import_csv';
      axios.post(api, formData)
        .then(function (response) {
          let resp = response.data;
          this.setState({preview_data:resp.preview_data});
          this.setState({preview_total_before_tax:resp.before_tax_total});
          this.setState({preview_grand_total:resp.total_amount});
          this.setState({preview_debit_total:resp.debit_total});
          this.setState({preview_credit_total:resp.credit_total});
          /* document.getElementById('imp-con').classList.remove("hide");
          document.getElementById('imp-con-btn-spinner').classList.add("hide"); */
          //this.setState({show_custom_headers:true,csv_headers:resp.headers,import_data:resp.import_data});
         // this.setState({import_data:response.data,show_import:true});
      }.bind(this)).catch(function(res){
          this.setState({show_error:true});
      }.bind(this))
    }

    handleSaveHeaders = e =>{
      let thisid = e.currentTarget.id;
      let header_key = 'bill_import_headers';
      if(this.props.import_type==='payment'){
        header_key = 'payment_import_headers';
      }
      let qs = document.querySelectorAll(".custom-header");
      let unmapped_headers = [];
      for (let index = 0; index < qs.length; index++) {
        const thisvalue = qs[index].value;
        const data_value = qs[index].getAttribute('data_value');
        if(thisvalue===""){
          unmapped_headers.push(data_value);
        }
      }
  
      this.setState({current_screen:'preview_headers',unmapped_headers:unmapped_headers});
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          name : header_key,
          value : this.state.headers
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          this.handlePreviewData();
      }.bind(this)).catch(function(res){
          
      })
    }
  
    componentDidMount(){
      this.setState({headers:this.props.bill_import_headers});
      let header = this.props.bill_import_headers;
      let headers_list = [];
      Object.keys(header).forEach(function(key) {
        headers_list.push(key);
      });
      this.setState({headers_list:headers_list})
    }
  
    moveBack = () =>{
      this.setState({show_error:false});
      this.setState({current_screen:'setup_headers'});
      this.setState({preview_data:[]})
    }
    handleImportData = (e) =>{
      let qs = document.querySelectorAll('.fa-exclamation-circle');
      if(qs.length>0){
        toast("Please fix the errors in preview data (change red exclamination to green check)");
        return false;
      }
      let qs1 = document.querySelectorAll('.import-exp-type');
      for (let index = 0; index < qs1.length; index++) {
        const element = qs1[index];
        let thisval = element.value;
        if(thisval===""){
          element.focus();
          toast("Please select expense type");
          return false;
        }
      }
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      var element = document.getElementById('process-csv-upload');
      element.classList.remove("fade");
      element.style.display="block"; 
      this.handleProcessUpload();
    }

    handleProcessUpload = () =>{
      let qs = document.querySelectorAll('.migrate-bills-row');
      
      let count = qs.length;
      let offset = this.state.offset;
      let imported_data = [];
      let limit = offset + 10;
      this.setState({offset:limit});
      let is_empty = true;
      for (let index = offset; index < limit; index++) {
        let key = index;
        const element = qs[index];
        
        if(element){
            let import_data = {};
            is_empty = false;
            let contact_id = 0;
            if(document.getElementById('import-type-'+key)){
              import_data['import_type'] = document.getElementById('import-type-'+key).value;
            }
            if(document.getElementById('customer-dropdown-'+key)){
              let contact_name = document.getElementById("contact_box_"+key).value;
              let old_contact_id = document.getElementById("old-customer-dropdown-"+key).value;
              let new_contact_id = document.getElementById("customer-dropdown-"+key).value;
              if(new_contact_id){
                contact_id = new_contact_id;
              }else if(old_contact_id>0){
                contact_id = old_contact_id;
              }
              import_data['contact_id'] = contact_id;
              import_data['contact_name'] = contact_name;
            }
            if(document.getElementById('total-before-tax-'+key)){
              import_data['total_before_tax'] = document.getElementById('total-before-tax-'+key).value;
            }
            if(document.getElementById('cgst-'+key)){
              import_data['cgst'] = document.getElementById('cgst-'+key).value;
            }
            if(document.getElementById('sgst-'+key)){
              import_data['sgst'] = document.getElementById('sgst-'+key).value;
            }
            if(document.getElementById('igst-'+key)){
              import_data['igst'] = document.getElementById('igst-'+key).value;
            }
            if(document.getElementById('tcs-'+key)){
              import_data['tcs'] = document.getElementById('tcs-'+key).value;
            }
            if(document.getElementById('total-'+key)){
              import_data['total'] = document.getElementById('total-'+key).value;
            }
            if(document.getElementById('date-'+key)){
              import_data['date'] = document.getElementById('date-'+key).value;
            }
            if(document.getElementById('bill-sequence-'+key)){
              import_data['bill_sequence'] = document.getElementById('bill-sequence-'+key).value;
            }
            if(document.getElementById('debit-'+key)){
              import_data['debit'] = document.getElementById('debit-'+key).value;
            }
            if(document.getElementById('credit-'+key)){
              import_data['credit'] = document.getElementById('credit-'+key).value;
            }
            if(document.getElementById('expense-type-'+key)){
              import_data['expense_type'] = document.getElementById('expense-type-'+key).value;
            }
            if(document.getElementById('reference-'+key)){
              import_data['refrence_no'] = document.getElementById('reference-'+key).value;
            }
            imported_data.push(import_data);
          }
        }
        
        if(imported_data.length>0){
          let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            type:'upload_bill_data',
            import_target:this.props.import_type,
            target:this.props.import_type,
            imported_data : imported_data,
            payment_mode : localStorage.getItem('import_contact_type')
        }
      
          let api = app_api+'/process_import_csv';
          axios.post(api, send_data)
            .then(function (response) {
              let data = response.data;
              let progress = (offset/count)*100;
              if(count<10){
                progress = 100;
              }
              if(progress>100){
                progress = 100;
              }
              this.setState({progress:Math.round(progress)});
              this.handleProcessUpload();
          }.bind(this)).catch(function(res){
              
          })
        }else{
          this.setState({progress:100});
          let _this = this;
          setTimeout(() => {
            var element = document.getElementById('process-csv-upload');
            element.classList.add("fade");
            element.style.display="none";
            _this.props.handleBackToTypeSelection();
            //window.location.reload();
            //window.location.href = '/contacts';
          }, 1500);
        }
    }

    handleChangeDate = (date,dateString,index)=>{
        let preview_data = this.state.preview_data;
        let prv_data = preview_data[index];
        prv_data.date = date;
        preview_data[index] = prv_data;
        this.setState({preview_data:preview_data});
        this.handleUpdatePreviewData(preview_data);
    }
    handleChangeListImportType = (e) =>{
      let thisvalue = e.currentTarget.value;
      let index = e.currentTarget.getAttribute('data-id');
      let preview_data = this.state.preview_data;
      let prv_data = preview_data[index];
      prv_data.import_type = thisvalue;
      preview_data[index] = prv_data;
      if(thisvalue!=='other'){
        this.handleChangeBillSequence(e);
      }else{
        prv_data.is_sequence_exist = false;
      }
      this.setState({preview_data:preview_data});
      this.handleUpdatePreviewData(preview_data);
    }

    handleChangeBillSequence = (e) =>{
      let preview_data = this.state.preview_data;
      let index = e.currentTarget.getAttribute('data-id');
      let thisvalue = document.getElementById('bill-sequence-'+index).value;
      let type = this.props.import_type;
      if(document.getElementById("import-type-"+index)){
        type = document.getElementById("import-type-"+index).value;
      }
      if(thisvalue!=""){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          sequence : thisvalue,
          type : type
      }

        let api = app_api+'/check_bill_sequence_existance';
        axios.post(api, send_data)
          .then(function (response) {
           let resp = response.data;
           let is_exist = resp.is_exist;
           let prv_data = preview_data[index];
           prv_data.bill_sequence = thisvalue;
           prv_data.is_sequence_exist = is_exist;
           preview_data[index] = prv_data;
           this.setState({preview_data:preview_data});
           this.handleUpdatePreviewData(preview_data);
        }.bind(this)).catch(function(res){
            
        })
      }
    }
    handleMarkCheckContact = () =>{
      let index = localStorage.getItem('data_index');
      let contact_name = document.getElementById("contact_box_"+index).value;
      let selected_contact = localStorage.getItem('selected_contact');
      let preview_data = this.state.preview_data;
      let prv_data = preview_data[index];
      prv_data.contact_id = selected_contact;
      prv_data.name = contact_name;
      prv_data.contact_count = 1;
      preview_data[index] = prv_data;
      this.setState({preview_data:preview_data});
      this.handleUpdatePreviewData(preview_data);
    }
    handleChangeData= (index,type,value) =>{
        let preview_data = this.state.preview_data;
        let prv_data = preview_data[index];
        prv_data[type] = value;
        preview_data[index] = prv_data;
        this.setState({preview_data:preview_data});
    }
    handleUpdatePreviewChangedData = ()=>{
      let preview_data = this.state.preview_data;
      this.handleUpdatePreviewData(preview_data);
    }
    handleUpdatePreviewData =(preview_data)=>{
      let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        preview_data : preview_data,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
      }
      let api = app_api+'/modify_uploaded_preview_data';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleRemoveItem = (idx)=>{
      let preview_data = this.state.preview_data;
      preview_data.splice(idx,1);
      let _this = this;
      setTimeout(() => {
        _this.setState({preview_data:preview_data});
        let total_debit_amount = 0;
        let total_credit_amount = 0;
        for (let index = 0; index < preview_data.length; index++) {
          const element = preview_data[index];
          total_debit_amount = total_debit_amount + parseFloat(element.debit);
          total_credit_amount = total_credit_amount + parseFloat(element.credit);
        }
        _this.setState({preview_credit_total:total_credit_amount});
        _this.setState({preview_debit_total:total_debit_amount});
        _this.handleUpdatePreviewData(preview_data);
      }, 100);
    }
    render() {
      return    <div className="row">
                  <div className="col-md-12">
                    <div className="box-container" style={{padding:'0px'}}>
                      <div className="box-head">
                        <label>MAP IMPORT FILE HEADERS</label>
                      </div>
                      <span id="handleMarkCheckContact" onClick={this.handleMarkCheckContact}></span>
                      {(this.state.current_screen==='setup_headers') &&
                        <>
                        <div className="box-body"> 
                          <div className="row">
                              
                              <div className="col-md-12">
                              <ul className="list-group">
                                {this.state.headers_list.map(items => (
                                  <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-md-6">{this.state.headers[items].label} : </div>
                                        <div className="col-md-6">
                                          <SelectDropdown options={this.props.headers} data_id={this.state.headers[items].data_id} default={this.state.headers[items].default} onChange={this.handleChangeHeader} data_value={this.state.headers[items].data_value}/>
                                        </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              </div>
                           
                          </div>
                        </div>
                        <div className="row"  style={{padding:'10px'}}>
                          
                          <div className="col-md-6">
                            <button type="button" className="btn btn-secondary" onClick={this.closeHeaderSetting}>Back</button>
                          </div>
                          <div className="col-md-6 text-right">
                          
                              <button type="button" className="btn btn-success" id="set-custom-header" onClick={this.handleSaveHeaders}>Next</button>
                              <button type="button" id="set-custom-header-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        
                          </div>
                         
                        </div>
                        </>
                      }
  
  
                      {(this.state.current_screen==='preview_headers') &&
                        <>
                        <div className="box-body"> 
                          <div className="row">
                        
                            <div className="col-md-12">
                              <ul className="list-group">
                                <li className="list-group-item">
                                    <h5>Total imported record(s) : <span style={{color:'#27b327'}}>{this.state.preview_data.length}</span></h5>
                                </li>
                                <li className="list-group-item">
                                    <h5>Unmapped headers</h5>
                                    {(this.state.unmapped_headers.length>0) &&
                                      <ul>
                                      {this.state.unmapped_headers.map((v) =>(
                                        <li>{v}</li>
                                      ))
                                      }
                                    </ul>
                                    }
                                    {(this.state.unmapped_headers.length===0) &&
                                      <>
                                      <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon> 
                                      All headers mapped
                                      </>
                                    }
                                </li>
                              </ul>
                            </div>
                           
                          </div>
                        </div>
                        <div className="content-head border-bottom">
                          <div className="row">
                            <div className="col-md-6">
                                <h4>
                                  Preview Data Before Upload
                                </h4>
                            </div>
                            <div className="col-md-6 text-right">
                              {(this.state.preview_total_before_tax>0) &&
                              <>
                               <p style={{margin:'0px'}}>
                                  TOTAL BEFORE TAX AMOUNT: {utils.currency_format(this.state.preview_total_before_tax)}
                                </p>
                               <p style={{margin:'0px'}}>
                                  TOTAL AMOUNT : {utils.currency_format(this.state.preview_grand_total)}
                                </p>
                                </>
                              }
                              {(this.state.preview_debit_total>0) &&
                              <>
                               <p style={{margin:'0px'}}>
                                  DEBIT AMOUNT: {utils.currency_format(this.state.preview_debit_total)}
                                </p>
                                </>
                              }
                              {(this.state.preview_credit_total>0) &&
                              <>
                               <p style={{margin:'0px'}}>
                                  CREDIT AMOUNT: {utils.currency_format(this.state.preview_credit_total)}
                                </p>
                                </>
                              }
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                           
                            <div className="col-md-12">
                               

                                {(this.state.preview_data.length>0) &&
                                  <>
                                  {(this.state.preview_data.map((value,index)=>{
                                    let import_date = '';
                                    if(value.date!==undefined){
                                      import_date = new Date(value.date);
                                    }
                                    return (
                                      <>
                                      <div className="row migrate-bills-row" data-id={index}>
                                      {(value.import_type!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"import-type-"+index}>IMPORT TYPE</label>
                                            <select defaultValue={value.import_type} className="form-control" id={"import-type-"+index} onChange={this.handleChangeListImportType} data-id={index}>
                                              <option value="in">PAYMENT IN</option>
                                              <option value="out">PAYMENT OUT</option>
                                              <option value="expense">EXPENSE</option>
                                              <option value="other">OTHER</option>
                                            </select>
                                          </div>
                                        }
                                      {(value.bill_sequence!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"bill-sequence-"+index}>BILL SEQUENCE</label>
                                            <div className="input-group">
                                              <input type="text" className="form-control" defaultValue={value.bill_sequence} key={value.bill_sequence} id={"bill-sequence-"+index} data-id={index} onChange={this.handleChangeBillSequence}/>
                                              <div className="input-group-append">
                                                  <span className="input-group-text" style={{background:'white'}}><FontAwesomeIcon icon={(value.is_sequence_exist===true)?faExclamationCircle:faCheckCircle}/></span>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        {(value.import_type==='expense') &&
                                        <div className="col-md-2">
                                          <label style={{margin:'0px'}} htmlFor={"expense-type-"+index}>EXPENSE TYPE</label>
                                          <ExpenseTypes show="dropdown" target="import" default={value.exp_id} tabindex="1" id={"expense-type-"+index} className="import-exp-type"  handleChangeData={this.handleChangeData} data_id='exp_id' data_index={index}/>

                                        </div>
                                        }
                                        {(value.name!==undefined) &&
                                          <div className="col-md-3">
                                            <label style={{margin:'0px'}} htmlFor={"name-"+index}>CONTACT</label>
                                           {/*  <input type="text" className="form-control" defaultValue={value.name} id={"name-"+index}/> */}
                                          
                                           <Autocomplete
                                            options={[]}
                                            title="Type to search contact"
                                            data_target="contact"
                                            id={"customer-dropdown-"+index}
                                            className="auto-comp-opt"
                                            data_id="add-old-bill"
                                            input_id={'contact_box_'+index}
                                            item_info={{item_name:value.name,item_id:value.contact_id}}
                                            contact_id={value.contact_id}
                                            contact_name={value.name}
                                            target="contact_box"
                                            tabindex='1'
                                            position = 'bottom'
                                            icon = {(value.contact_count===1)?faCheckCircle:faExclamationCircle}
                                            data_index = {index}
                                          />
                                            {/* {(value.contact_count===0) &&
                                              <span className="error" style={{fontSize:'12px',textAlign:'justify'}}><b>Contact not found</b> create a new contact to map</span>
                                            } */}
                                            {(value.contact_count>1) &&
                                              <span className="error" style={{fontSize:'12px',textAlign:'justify'}}><b>Multiple contact found</b> please verify before upload</span>
                                            }
                                          </div>
                                        }
                                        
                                        {(value.before_tax_amount!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"total-before-tax-"+index}>TOTAL BEFORE TAX</label>
                                            <input type="text" className="form-control" key={value.before_tax_amount} defaultValue={value.before_tax_amount} id={"total-before-tax-"+index} onChange={(event)=>this.handleChangeData(index,'before_tax_amount',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.cgst!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"cgst-"+index}>CGST</label>
                                            <input type="text" className="form-control" defaultValue={value.cgst} id={"cgst-"+index} key={value.cgst}  onChange={(event)=>this.handleChangeData(index,'cgst',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.sgst!==undefined) &&
                                          <div className="col-md-2">
                                           
                                            <label style={{margin:'0px'}} htmlFor={"sgst-"+index}>SGST</label>
                                            <input type="text" className="form-control" defaultValue={value.sgst} key={value.sgst} id={"sgst-"+index}  onChange={(event)=>this.handleChangeData(index,'sgst',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.igst!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"igst-"+index}>IGST</label>
                                            <input type="text" className="form-control" defaultValue={value.igst} key={value.igst} id={"igst-"+index} onChange={(event)=>this.handleChangeData(index,'igst',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.tcs!==undefined) &&
                                          <div className="col-md-2">
                                            
                                            <label style={{margin:'0px'}} htmlFor={"tcs-"+index}>TCS</label>
                                            <input type="text" className="form-control" defaultValue={value.tcs} key={value.tcs} id={"tcs-"+index}  onChange={(event)=>this.handleChangeData(index,'tcs',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.total!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"total-"+index}>TOTAL</label>
                                            <input type="text" className="form-control" defaultValue={value.total} key={value.total} id={"total-"+index}  onChange={(event)=>this.handleChangeData(index,'total',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.debit!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"debit-"+index}>DEBIT</label>
                                            <input type="text" className="form-control" defaultValue={value.debit} key={value.debit} id={"debit-"+index}  onChange={(event)=>this.handleChangeData(index,'debit',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.credit!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"credit-"+index}>CREDIT</label>
                                            <input type="text" className="form-control" defaultValue={value.credit} key={value.credit} id={"credit-"+index}  onChange={(event)=>this.handleChangeData(index,'credit',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.reference!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"reference-"+index}>REFERENCE</label>
                                            <input type="text" className="form-control" defaultValue={value.reference} key={value.reference} id={"reference-"+index}  onChange={(event)=>this.handleChangeData(index,'reference',event.target.value)} onBlur={this.handleUpdatePreviewChangedData}/>
                                          </div>
                                        }
                                        {(value.date!==undefined) &&
                                          <div className="col-md-2">
                                            <label style={{margin:'0px'}} htmlFor={"date-"+index}>DATE</label>
                                            {/* <input type="text" className="form-control" defaultValue={value.date} id={"date-"+index}/> */}
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text no-border-right">
                                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                                </span>
                                                <DatePicker className="form-control  no-border-left" selected={import_date} onChange={(date, dateString) => this.handleChangeDate(date, dateString, index)} dateFormat="dd/MMM/yyyy" id={"date-"+index} data-id={index}/>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        
                                          <div className="col-md-2" style={{marginTop:'28px'}}>
                                              <FontAwesomeIcon icon={faTimes} style={{color:'gray',fontSize:'20px',cursor:'pointer'}} onClick={()=>this.handleRemoveItem(index)}/>
                                          </div>
                                        
                                      </div>
                                      <hr />
                                      </>
                                    )
                                  }))}
                                  
                                  </>
                                }
                                {(this.state.preview_data.length===0) &&
                                <>
                                  {(this.state.show_error===false) &&
                                    <label>Loading Preview Please Wait...</label>
                                  }
                                  {(this.state.show_error===true) &&
                                    <p style={{color:'red',margin:'0px'}}>
                                      Unable to generate the preview. Please try once again.
                                    </p>
                                  }
                                </>
                                }
                              </div>
                          </div>
                          <div className="row"  style={{padding:'10px'}}>
                        
                            <div className="col-md-6">
                              <button type="button" className="btn btn-success" onClick={this.moveBack}>Previous</button>
                            </div>
                            
                            <div className="col-md-6 text-right">
                            {(this.state.preview_data.length>0) &&
                                <>
                                    <button type="button" className="btn btn-danger" id="import-data" onClick={this.handleImportData}>Import</button>
                                    <button type="button" id="import-data-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                </>
                            }
                            </div>
                        
                          </div>
                        </div>
                        </>
                      }
                    </div>
                  </div>
                  <div id="process-csv-upload" className="modal fade">
                    <div className="modal-dialog">
                      <div className="modal-content csv-load-blk">
                        {(this.state.progress<100) &&
                          <>
                            <div className="parent-bar">
                              <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                            </div>
                            <p>Please wait while import complete ({this.state.progress}%)</p>
                          </>
                        }
                        {(this.state.progress>=100) &&
                          <h4 style={{color:'green'}}><FontAwesomeIcon style={{color:'green'}} icon={faCheckCircle}></FontAwesomeIcon> Data Import Successful</h4>
                        }
                      </div>
                    </div>
                  </div>
                </div>
           
    }
  }