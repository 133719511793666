import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import ContactsDropdown from "./contacts-dropdown";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faArrowLeft,faArrowRight,faCheckCircle,faTimes,faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import ContactField from './contact-field';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import PaymentModes from "./payment-modes";
import ModalBox from "./modal-box";
import ExpenseTypes from "./expense-types";
import encrypt_decrypt from './encrypt-decrypt.js';
import ChequeInputInfo from "./cheque-input-info";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class NewExpense extends Component {
    constructor() {
        super();
        this.state = {
            save_button:'Save',
            items_list : [],
            edit_item_index : 'none',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  new Date(),
            contact_info : '',
            send_type:'save',
            update_id:0,
            expenses_data :[],
            on_load : true,
            isMobile : false,
            is_recurring : false,
            is_cheque_payment : false,
            final_total : 0,
            chequeDate:  new Date(),
            is_chq_in_list : false,
            is_reset_fields : true,
        };
        _this = this;
        this.items_list = [];
        this.sequence = [];
        this.send_data_items = [];
        this.remove_items=[];
        this.createExpense = this.createExpense.bind(this);
        this.resetExpense = this.resetExpense.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        
        this.addMoreItem = this.addMoreItem.bind(this);
        this.listItemRemove = this.listItemRemove.bind(this);
        this.confirmItemDelete = this.confirmItemDelete.bind(this);
        this.closeConfirmItemDel = this.closeConfirmItemDel.bind(this);
        this.listItemEdit = this.listItemEdit.bind(this);
        this.handleResetField = this.handleResetField.bind(this);
        this.handleCheckRecurring = this.handleCheckRecurring.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.handleCalculate = this.handleCalculate.bind(this);
        this.handleMoveNext = this.handleMoveNext.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.decrypt_sanitize = encrypt_decrypt.decrypt_sanitize.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }
    chequeDate = date => {
      this.setState({
        chequeDate: date
      });
    };
    handleEnableChequePayment = () =>{
      this.setState({is_cheque_payment:true});
    }
    handleDisableChequePayment = () =>{
      this.setState({is_cheque_payment:false});
    }
    handleMoveNext(e){
      if(e.which===13 && !e.ctrlKey){
        document.getElementById("iexp-description").focus();
      }
    }
    resetExpense(e){
      this.items_list = [];
      this.setState({items_list:this.items_list});
      /*let qs = document.querySelectorAll('input');
      for(let i=0;i<qs.length;i++){
        qs[i].value = '';
      }*/
    }
    handleCalculate(e){
      let amount = document.getElementById("iexp-amount").value;
      let tax = document.getElementById("iexp-tax").value;
      let total = document.getElementById("iexp-total").value;

      if(amount!==""){amount = parseFloat(amount);}else{amount = 0;}
      if(tax!==""){tax = parseFloat(tax);}else{ tax = 0;}
      
      let total_amount = ((amount*tax)/100)+amount;
      document.getElementById("iexp-total").value = total_amount;
    }
    handleCheckRecurring(e){
      let is_recurring = e.currentTarget.checked;
      this.setState({is_recurring:is_recurring});
    }
    handleResetField(e){
      localStorage.removeItem('p_mode');
        this.setState({is_reset_fields:false});
        let _this =this;
        setTimeout(function(){
          _this.setState({is_reset_fields:true});
        },10);
     /*  document.getElementById("new-item-form").reset();
    
  
        let sli = document.querySelectorAll(".exp-list-item");
        for(let i=0;i<sli.length;i++){
           sli[i].classList.remove('item-edit-mode');
        }
        this.setState({edit_item_index:'none'});
      
        let item_edit_mode = document.querySelectorAll(".item-mod-options");
        for(let i=0;i<item_edit_mode.length;i++){
          item_edit_mode[i].style.display = 'flex';
        }
      
       /* let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
        for(let i=0;i<inputs.length;i++){
          inputs[i].value = '';
        }*/
     
    }

    calculateTotal(){
      let tqs = document.querySelectorAll('.total-amount');
      let grand_total = 0;
      for(let i=0;i<tqs.length;i++){
        let tot = (tqs[i].value!=='')?parseFloat(tqs[i].value):0;
        grand_total = grand_total+tot;
      }
      this.setState({final_total:grand_total});
    }


    confirmItemDelete(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-litem-"+data_id).classList.remove('hide');
    }
    closeConfirmItemDel(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("rm-litem-"+data_id).classList.add('hide');
    }
    listItemRemove(e){
      let index = e.currentTarget.getAttribute('data-id');

      let item_id = document.getElementById("exp-list-item-id-"+index).value;
      let item = {id:item_id};
      this.remove_items.push(item);
      document.getElementById("delete-row").value = JSON.stringify(this.remove_items);

      this.items_list.splice(index,1);
      this.setState({items_list:this.items_list});
      setTimeout(function(){
        _this.calculateTotal();
      },100);
    }

    listItemEdit(e){
      let index = e.currentTarget.getAttribute('data-id');
      index = parseInt(index);
      let items_list = this.state.items_list;
      let is_cheque = (items_list.is_cheque && items_list.is_cheque===true)?true:false;
      if(is_cheque===true){
        this.setState({is_cheque_payment:true});
      }
      this.setState({edit_item_index:index});

      let item_edit_mode = document.querySelectorAll(".item-mod-options");

      for(let i=0;i<item_edit_mode.length;i++){
        item_edit_mode[i].style.display = 'none';
      }

      document.getElementById("expense_list_item_"+index).classList.add('item-edit-mode');
      let list_item_id = (document.getElementById("exp-list-item-id-"+index))?document.getElementById("exp-list-item-id-"+index).value:'';
     
     
      let contact= (document.getElementById("exp-contact-"+index))?document.getElementById("exp-contact-"+index).value:'';
      localStorage.setItem('selected_contact',contact);
      let contact_txt= (document.getElementById("exp-contact-txt-"+index))?document.getElementById("exp-contact-txt-"+index).value:'';
      let expense_type= (document.getElementById("exp-type-"+index))?document.getElementById("exp-type-"+index).value:'';
      let payment_mode= (document.getElementById("exp-payment-mode-"+index))?document.getElementById("exp-payment-mode-"+index).value:'';
      let bank_name= (document.getElementById("exp-cheque-bank-name-"+index))?document.getElementById("exp-cheque-bank-name-"+index).value:'';
      let cheque_no= (document.getElementById("exp-cheque-no-"+index))?document.getElementById("exp-cheque-no-"+index).value:'';
      let cheque_date= (document.getElementById("exp-cheque-date-"+index))?document.getElementById("exp-cheque-date-"+index).value:'';
      localStorage.setItem('p_mode',payment_mode);
      localStorage.setItem('exp_type',expense_type);
      if(document.getElementById("handleSetModeOfPayment")){
        document.getElementById("handleSetModeOfPayment").click();
      }
      if(document.getElementById("handleSetExpenseType")){
        document.getElementById("handleSetExpenseType").click();
      }
    /*   let exp_data = this.state.expenses_data;
      exp_data['exptid'] = expense_type;
      this.setState({expenses_data:exp_data}); */

      let desc= (document.getElementById("exp-desc-"+index))?document.getElementById("exp-desc-"+index).value:'';
      let ref_no= (document.getElementById("exp-ref-no-"+index))?document.getElementById("exp-ref-no-"+index).value:'';
      let amount= (document.getElementById("exp-amount-"+index))?document.getElementById("exp-amount-"+index).value:0;
     
      let tax= (document.getElementById("exp-tax-"+index))?document.getElementById("exp-tax-"+index).value:0;
      let total= (document.getElementById("exp-total-"+index))?document.getElementById("exp-total-"+index).value:0;
     
      if(document.getElementById("ref-list-id")){document.getElementById("ref-list-id").value = list_item_id};
      if(document.getElementById("customer-dropdown")){document.getElementById("customer-dropdown").value = contact};
      if(document.getElementById("contact_box")){document.getElementById("contact_box").value = contact_txt};
      if(document.getElementById("expense-type")){document.getElementById("expense-type").value = expense_type};
      if(document.getElementById("payment-mode")){document.getElementById("payment-mode").value = payment_mode};
      if(document.getElementById("exp-chq-bank-name")){document.getElementById("exp-chq-bank-name").value = bank_name};
      if(document.getElementById("exp-cheque-no")){document.getElementById("exp-cheque-no").value = cheque_no};
      if(cheque_date!==""){
        let chq_date = new Date(cheque_date);
        this.setState({chequeDate:chq_date})
      }
     
      if(document.getElementById("iexp-description")){
          document.getElementById("iexp-description").value = desc;
          //document.getElementById("iexp-description").focus();
      };
      if(document.getElementById("iexp-refrence-no")){document.getElementById("iexp-refrence-no").value = ref_no};
    
      if(document.getElementById("iexp-amount")){document.getElementById("iexp-amount").value = amount};
      if(document.getElementById("iexp-tax")){document.getElementById("iexp-tax").value = tax};
      if(document.getElementById("iexp-total")){document.getElementById("iexp-total").value = total};
    }

    addMoreItem(e){
      let list_item_id = (document.getElementById("ref-list-id"))?document.getElementById("ref-list-id").value:0;
      
      let customer = localStorage.getItem('selected_contact');
      let customer_txt = document.getElementById("contact_box").value;
      if(customer_txt===""){
        document.getElementById("contact_box").focus();
        return false;
      }
      let exp_type = document.getElementById("expense-type");
      let pm_element = document.getElementById("payment-mode");
      let expense_type = exp_type.value;
      let expense_type_txt = exp_type.options[exp_type.selectedIndex].text;
      let payment_mode = pm_element.value;
      let payment_mode_txt = pm_element.options[pm_element.selectedIndex].text;
      let bank_name = (document.getElementById("exp-chq-bank-name"))?document.getElementById("exp-chq-bank-name").value:'';
      let cheque_no = (document.getElementById("exp-cheque-no"))?document.getElementById("exp-cheque-no").value:'';
      let is_reconcile = (document.getElementById("exp-cheque-is-recon"))?document.getElementById("exp-cheque-is-recon").checked:'';
      let cheque_date = (document.getElementById("exp-cheque-date"))?document.getElementById("exp-cheque-date").value:'';
      let desc= (document.getElementById("iexp-description"))?document.getElementById("iexp-description").value:'';
      let refno= (document.getElementById("iexp-refrence-no"))?document.getElementById("iexp-refrence-no").value:'';
      let amount= (document.getElementById("iexp-amount"))?document.getElementById("iexp-amount").value:'';
      let tax= (document.getElementById("iexp-tax"))?document.getElementById("iexp-tax").value:0;
      let total= (document.getElementById("iexp-total"))?document.getElementById("iexp-total").value:0;
      
      if(total<=0){
        return false;
      }
      let is_cheque = false;
      if(cheque_no!==""){
        is_cheque = true;
      }
      let items = {
          customer : customer,
          customer_txt : customer_txt,
          payment_mode : payment_mode,
          payment_mode_txt : payment_mode_txt,
          expense_type : expense_type,
          expense_type_txt : expense_type_txt,
          bank_name : bank_name,
          is_cheque : is_cheque,
          cheque_no : cheque_no,
          is_reconcile : is_reconcile,
          cheque_date : cheque_date,
          list_item_id : list_item_id,
          description : desc,
          refrence_no : refno,
          amount : amount,
          tax : tax,
          total : total
      }
      if(this.state.edit_item_index!=='none'){
        let active_idx = this.state.edit_item_index;
        this.items_list[active_idx] = items;
        let item_edit_mode = document.querySelectorAll(".item-mod-options");
        for(let i=0;i<item_edit_mode.length;i++){
          item_edit_mode[i].style.display = '';
        }
        document.getElementById("expense_list_item_"+active_idx).classList.remove('item-edit-mode');
      }else{
        this.items_list.push(items);
      }
      this.setState({items_list:this.items_list});
      document.getElementById("new-item-form").reset();
      document.getElementById("iexp-description").focus();
      this.setState({is_recurring:false});
      let exp_li_items = this.items_list;
      for (let exi = 0; exi < exp_li_items.length; exi++) {
        const element = exp_li_items[exi];
        let cheque_no = element.cheque_no;
        if(cheque_no!==""){
          this.setState({is_chq_in_list:true});
        }
      }
      this.setState({is_reset_fields:false});
      setTimeout(function(){
        _this.setState({is_reset_fields:true});
      },10);
      setTimeout(function(){
        _this.calculateTotal();
        
      },100);
      localStorage.removeItem('p_mode');
      this.setState({is_cheque_payment:false});
    }
   

      billingDate = date => {
        this.setState({
          billingDate: date
        });
      };
       handleChangeEvent(e){
        this.setState({on_load:false});
        
      }
    componentDidMount(){
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
      }
      
      let curl = window.location.href;
      localStorage.removeItem("p_mode");
      const edit_expense = curl.match(/new-expense\/([edit]+)\/(\d+$)/i);
      if(edit_expense){ // EDIT MODE
          const id = edit_expense[2];
          const type = edit_expense[1];
        
          this.setState({update_id:id});
          this.setState({send_type:'update'});
         
          this.setState({save_button:'Update Expense'});
          this.setState({has_payment_items:true});
        
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              id : parseInt(id),
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_expense_on_id';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let data = resp.data[0];
              if(data===undefined){
                window.location.href = '/404';
              }
              this.setState({expenses_data:data});
              this.setState({final_total:data.grand_total});
              this.setState({currrent_sequence:data.expense_number});
              this.setState({billingDate:new Date(data.created_date)});
              if(data.cheque_no!==""){
                if(document.getElementById("handleEnableChequePayment")){
                  document.getElementById("handleEnableChequePayment").click();
                  if(document.getElementById("cheque_no")){
                    document.getElementById("cheque_no").value = data.cheque_no;
                  }
                  if(data.cheque_date!==""){
                    if(document.getElementById("cheque_date")){
                      document.getElementById("cheque_date").value = data.cheque_date;
                    }
                  }
                }
              }
              this.items_list = data.expense_meta;
              this.setState({items_list:this.items_list});
              
              let exp_li_items = this.items_list;
              for (let exi = 0; exi < exp_li_items.length; exi++) {
                const element = exp_li_items[exi];
                let is_cheque = element.is_cheque;
                if(is_cheque===true){
                  this.setState({is_chq_in_list:true});
                }
              }
              this.setState({is_cheque_payment:false})
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
      }else{
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
             }
          let api = app_api+'/get_sequence_number';
          axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
              let sequence_no = resp.expense_sequence;
              let expense_sequence = "#EXP"+sequence_no;
              this.setState({currrent_sequence:expense_sequence});
              this.setState({currrent_sequence_no:sequence_no});
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        
      }
    }

    createExpense(event) {
        this.setState({on_load:true});
        let thisid = event.currentTarget.id;
        
        let send_data = {};
       
        let date = this.state.billingDate;
        let notes = '';//document.getElementById("expense-note").value;
      
        let paid_amount = this.state.final_total;
       
        var inputs = document.querySelectorAll('.exp-list-item'); 
        if(inputs.length===0){
          toast("Sorry! You can not create a blank payment.");
          return false;
        }
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
       
        let items_to_delete = document.getElementById("delete-row").value;
        send_data = {
            update_id : this.state.update_id,
            send_type : this.state.send_type,
           
            date : date,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            sequence_no : this.state.currrent_sequence_no,
            sequence : this.state.currrent_sequence,
            paid_amount:paid_amount,
            notes : notes,
            items : this.state.items_list,
            items_to_delete : JSON.parse(items_to_delete)
        }
        
        let api = app_api+'/save_expense';
        axios.post(api, send_data)
          .then(function (response) {
             if(thisid==='save-and-new'){
                window.location.reload()
             }else{
                window.location.href = '/expenses';
             }
        }).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
    }
    render() {
        let isMobile = "row";
        if(this.state.isMobile){
          isMobile = "mobile";
        }
        let isSticky = '';
        let abfs = '';
        let isActionButton = '';
        if(this.state.isMobile===false){
            isSticky = 'sticky-add-item-panel';
            abfs = 'abfs';
            isActionButton = 'action-button';
        }
        return (
            <div className="d-flex expenses-wrapper" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className={"navbar border-bottom"}>
                  <div className="row">
                    <div className="col-md-4">
                      <Breadcrumbs link="/expenses" title="Expenses" sub_title="New Expense"/>
                    </div>
                    <div className="col-md-8 mrtop-10">
                        
                    </div>
                  </div>
                </nav>

              <div className="container-fluid" style={{marginBottom:'100px'}}>
                <div className={isMobile} style={{marginRight:'0px'}}>
                  <div className={(isMobile==='row')?"pleftblk col-md-12":(isMobile==='row')?"pleftblk col-md-12":''}>
                    <input type="text" className="hide" id="delete-row" defaultValue='[]'/>
                    <span id="handleEnableChequePayment" onClick={this.handleEnableChequePayment}></span>
                    <span id="handleDisableChequePayment" onClick={this.handleDisableChequePayment}></span>
                    <div className="content-area">
                       <div className="content-head border-bottom" style={{paddingBottom:'0px'}}>
                         <div className="row mrbtm-12">
                            <div className="col-md-3">
                              <span className="fe-title">DATE</span>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.billingDate} onChange={this.billingDate} dateFormat="dd/MM/yyyy"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-9 text-right bill-view" style={{marginTop:'25px'}}>
                                <label className="bil_no">{this.state.currrent_sequence}</label>
                            </div>
                          </div>
                       </div>
                        <div className="">
                         
                            <div className="row">
                              <div className="col-md-12">
                                {(this.state.is_reset_fields===true) &&
                                  <>
                                  <form id="new-item-form">
                                  <div className={"main-content border-bottom "+isSticky}>
                                    <div className="inner-content">
                                      <div className="row">
                                        <div className={"col-md-2 mrbtm-10 "+abfs}>
                                          <span className="fe-title">PAYEE</span>
                                          <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={(this.state.expenses_data && this.state.expenses_data.contact_info)?this.state.expenses_data.contact_info:[]} index="expenses" target={'expenses'} position='top'/>
                                        </div>
                                        <div className={"col-md-1 mrbtm-10 "+abfs}>
                                          <span className="fe-title">TYPE / ACCOUNT</span>
                                          <ExpenseTypes show="dropdown" id="expense-type" target="expense-type" default={(this.state.expenses_data && this.state.expenses_data.exptid)?this.state.expenses_data.exptid:''} tabindex="1"/>
                                        </div>
                                        <div className={"col-md-2 mrbtm-10 "+abfs}>
                                        <span className="fe-title">PAYMENT MODE</span>
                                          <PaymentModes show="dropdown" name="payment_mode" data_id='0' id="payment-mode" default={(this.state.expenses_data && this.state.expenses_data.payment_mode)?this.state.expenses_data.payment_mode:''} target="payments"  tabindex='2'/>
                                        {/*  {(this.state.is_cheque_payment===true) &&
                                            <ChequeInputInfo type="div" cheque_no={(this.state.expenses_data && this.state.expenses_data.cheque_no)?this.state.expenses_data.cheque_no:''} cheque_date={(this.state.expenses_data && this.state.expenses_data.cheque_date && this.state.expenses_data.cheque_date!=="")?this.state.expenses_data.cheque_date:null} is_reconcile={false}/>
                                          } */}
                                        </div>
                                        {(this.state.is_cheque_payment===true) &&
                                          <>
                                          <div className={"col-md-1 mrbtm-10 "+abfs}>
                                            <span className="fe-title">BANK NAME</span>
                                            <input type="text" className="form-control exp-chq-bank-name" placeholder="" id={"exp-chq-bank-name"} data-id={0}  data-target="exp-chq-bank-name"/>
                                          </div>
                                          <div className={"col-md-1 mrbtm-10 "+abfs}>
                                            <span className="fe-title">CHEQUE NO</span>
                                            <input type="text" className="form-control exp-cheque-no" placeholder="" id={"exp-cheque-no"} data-id={0}  data-target="exp-cheque-no"/>
                                            {/* <div className="form-check form-check-inline">
                                              <input className="form-check-input exp-cheque-is-recon" type="checkbox" data-id={0} id={'exp-cheque-is-recon'}/>
                                              <label className="form-check-label" htmlFor={'exp-cheque-is-recon'} style={{color:'#fff',fontSize:'11px'}}>
                                                Is Reconcile?
                                              </label>
                                            </div> */}
                                          </div>
                                          <div className={"col-md-1 mrbtm-10 "+abfs}>
                                            <span className="fe-title">CHEQUE DATE</span>
                                            <DatePicker className="form-control  exp-cheque-date"  dateFormat="dd/MM/yyyy" id={"exp-cheque-date"} data-id={0}  data-target="exp-cheque-date" selected={this.state.chequeDate} onChange={this.chequeDate}/>
                                          </div>
                                          </>
                                        }
                                        <div className={"col-md-1 mrbtm-10 "+abfs}>
                                          <span className="fe-title">DESCRIPTION</span>
                                          <input type="text" className="form-control iexp-description" placeholder="" id={"iexp-description"} data-id={1}  data-target="iexp-description" tabindex='4' target="refrence-no" />
                                          <input type="hidden" id="ref-list-id" />
                                        </div>
                                        <div className={"col-md-1 mrbtm-10 "+abfs}>
                                          <span className="fe-title">REFERENCE NO</span>
                                          <input type="text" className="form-control iexp-refrence-no" placeholder="" id={"iexp-refrence-no"} data-id={1}  data-target="iexp-refrence-no" tabindex='5' target="refrence-no" />
                                        </div>
                                      
                                        <div className={"mrbtm-10 col-md-1 "+abfs}>
                                        <span className="fe-title">AMOUNT</span>
                                        <input type="text" className="form-control iexp-amount" placeholder="" id={"iexp-amount"} data-id={1}  data-target="iexp-amount" tabindex='8' target="iexp-amount" onChange={this.handleCalculate}/>
                                        </div>
                                        <div className={"mrbtm-10 col-md-1 "+abfs}>
                                          <span className="fe-title">TAX</span>
                                          <div className="input-group">
                                            <div className="input-group-append exp-tax">
                                              <input type="text" className="form-control iexp-tax no-border-right" placeholder="" id={"iexp-tax"} data-id={1}  data-target="iexp-tax" tabindex='9' target="iexp-tax"  onChange={this.handleCalculate}/>
                                              <span className="input-group-text no-border-left">%</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={"mrbtm-10 col-md-1 "+abfs}>
                                        <span className="fe-title">TOTAL</span>
                                        <input type="text" className="form-control iexp-total" placeholder="" id={"iexp-total"} data-id={1}  data-target="iexp-total" tabindex='10' target="iexp-total"/>
                                        </div>
                                        <div className={"col-md-1 "+abfs+" "+isActionButton} style={{marginTop:'9px'}}>
                                            <button type="button" className="btn btn-primary" onClick={this.addMoreItem} style={{marginRight:'3px'}} id="add-item-to-list">
                                              <FontAwesomeIcon icon={faCheckCircle} style={{color:'white'}}/>
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={this.handleResetField}>
                                              <FontAwesomeIcon icon={faTimes} style={{color:'white',margin:'0px'}}/>
                                            </button>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  {(this.state.items_list.length>0) &&
                                    <div className="content-head" style={{padding:'0px'}}>
                                      <div className="table-responsive">
                                          <table className="table item-table border-bottom">
                                            <thead className="thead-light">
                                              <tr>
                                                <th scope="col">S.NO</th>
                                      
                                                <th scope="col">PAYEE</th>
                                                <th scope="col">EXP. TYPE</th>
                                                <th scope="col">PAYMENT MODE</th>
                                                
                                                {(this.state.is_chq_in_list===true) &&
                                                  <>
                                                    <th scope="col">BANK NAME</th>
                                                    <th scope="col">CHEQUE NO</th>
                                                    <th scope="col">CHEQUE DATE</th>
                                                  </>
                                                }
                                                <th scope="col">DESC.</th>
                                                <th scope="col">REFERENCE NO.</th>
                                                <th scope="col">AMOUNT</th>
                                                <th scope="col">TAX</th>
                                                <th scope="col">TOTAL</th>
                                                <th scope="col"></th>
                                              </tr>
                                            </thead>
                                            <tbody id="items-body">
                                              {(this.state.items_list.length > 0) ?this.state.items_list.map( (value, index) => {
                                                return (
                                                  <tr key={ index }  id={'expense_list_item_'+index} className="exp-list-item">
                                                    <td>
                                                      {index+1}
                                                    </td>
                                                    <td>
                                                      {value.customer_txt}
                                                      <input type="hidden" id={"exp-contact-"+index} defaultValue={value.customer} />
                                                      <input type="hidden" id={"exp-contact-txt-"+index} defaultValue={value.customer_txt} />
                                                    </td>
                                                    <td>
                                                      {value.expense_type_txt}
                                                      <input type="hidden" id={"exp-type-"+index} defaultValue={value.expense_type} />
                                                    </td>
                                                    <td>
                                                      {value.payment_mode_txt}
                                                      <input type="hidden" id={"exp-payment-mode-"+index} defaultValue={value.payment_mode} />
                                                    </td>
                                                  
                                                    {(this.state.is_chq_in_list) &&
                                                    <>
                                                      <td>
                                                        {value.bank_name}
                                                        <input type="hidden" id={"exp-cheque-bank-name-"+index} defaultValue={value.bank_name} />
                                                      </td>
                                                      <td>
                                                        <p style={{margin:'0px'}}>{value.cheque_no}</p>
                                                        {/* <p style={{margin:'0px'}}>{(value.is_reconcile)?'Reconcile':''}</p> */}
                                                        <input type="hidden" id={"exp-cheque-no-"+index} defaultValue={value.cheque_no} />
                                                      </td>
                                                      <td>
                                                        {value.cheque_date}
                                                        <input type="hidden" id={"exp-cheque-date-"+index} defaultValue={value.cheque_date} />
                                                      </td>
                                                    </>
                                                    }
                                                    <td>
                                                      {value.description}
                                                      <input type="hidden" id={"exp-desc-"+index} defaultValue={value.description} />
                                                      <input type="hidden" id={"exp-list-item-id-"+index} defaultValue={value.list_item_id} />
                                                    </td>
                                                    <td>
                                                      {value.refrence_no}
                                                      <input type="hidden" id={"exp-ref-no-"+index} defaultValue={value.refrence_no} />
                                                    </td>
                                                    <td>
                                                      ₹{this.currency_format(value.amount)}
                                                      <input type="hidden" id={"exp-amount-"+index} defaultValue={value.amount} />
                                                    </td>
                                                    <td>
                                                      {value.tax}
                                                      <input type="hidden" id={"exp-tax-"+index} defaultValue={value.tax} />
                                                    </td>
                                                    <td>
                                                      ₹{this.currency_format(value.total)}
                                                      <input type="hidden" id={"exp-total-"+index} defaultValue={value.total} className="total-amount"/>
                                                    </td>
                                                    <td className="item-rm">
                                                      <div className="item-mod-options" style={{display:'flex'}}>
                                                        <span className='item-remove' data-id={index} onClick={this.confirmItemDelete}><FontAwesomeIcon icon={faTimes} />
                                                        </span>
                                                        <span className="rm-litem hide" id={"rm-litem-"+index}>
                                                          <span className="rm-yes" data-id={index} onClick={this.listItemRemove}>
                                                            Yes
                                                          </span>
                                                          <span className="rm-no" data-id={index} onClick={this.closeConfirmItemDel}>
                                                            No
                                                          </span>
                                                        </span>
                                                        <span data-id={index} onClick={this.listItemEdit}><FontAwesomeIcon icon={faPencilAlt} /></span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              }) : <tr><td>No record(s) found</td></tr> }
                                            </tbody>
                                          </table>
                                        </div>
                                        
                                      </div>
                                  }
                                  </form>
                                  </>
                                }
                              </div>
                            </div> 
                            {(this.state.items_list.length>0) &&
                              <div className="content-head">
                                <div className="" style={{paddingTop:'16px'}}>
                                    <button type="button" onClick={this.createExpense} id="save" className="btn btn-success btn-save-action">{this.state.save_button}
                                          <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/>
                                    </button>
                                    <button type="button" id="save-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    {(this.state.send_type!=='update') &&
                                      <>
                                        <button type="button" onClick={this.createExpense} id="save-and-new" className="btn btn-success btn-save-action mrleft-10">Save & New
                                        </button>
                                        <button type="button" id="save-and-new-btn-spinner" className="hide btn btn-success btn-save-action mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                      </>
                                    }
                                    {(this.state.send_type!=='update') &&
                                      <button type="button" onClick={this.resetExpense} id="reset" className="btn btn-secondary btn-save-action pull-right">Reset</button>
                                    }
                                </div>
                              </div>
                            }
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
              
            </div>
        );
    }
}