import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faPencilAlt,faCheckCircle,faTimes,faTrash,faEye} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import ModalBox from "./modal-box";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let PaymentMode = [];
let _this;
let db;
export default class PaymentModes extends Component {
    constructor() {
      super();
      this.state = {
          payment_modes : [],
          modeDefault : 0,
          is_pay_mode : true,
          payment_mode_data : {},
      };
      _this = this;
      this.editMode = this.editMode.bind(this);
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.changeStatus = this.changeStatus.bind(this);
      this.handleSetModeOfPayment = this.handleSetModeOfPayment.bind(this);
      this.handleResetModeOfPayment = this.handleResetModeOfPayment.bind(this);
      this.handleRefreshPaymentMode = this.handleRefreshPaymentMode.bind(this);
      this.handleChangePMType = this.handleChangePMType.bind(this);
      this.is_access = check_access.is_access.bind(this);
      this.CHECK_CREDIT_LIMIT = utils.CHECK_CREDIT_LIMIT.bind(this);
    }
    handleSetModeOfPayment(){
        let p_mode = localStorage.getItem("p_mode");
        this.setState({modeDefault:p_mode});
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps.target!=='payments'){
        this.setState({modeDefault:nextProps.default});
      }
   }
    changeStatus(e){
        let id = e.currentTarget.id;
       document.getElementById("delete_payment_mode_"+id).classList.toggle('no-visible');
       let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            id : id,
        }
        
        let api = app_api+'/change_payment_mode_status';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }
    handleChangePMType(e){
       let id = e.currentTarget.getAttribute('data-id');
       let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            id : id,
        }
        
        let api = app_api+'/change_payment_mode_type';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }
    handleChangeIsCheque = (e)=>{
       let id = e.currentTarget.getAttribute('data-id');
       let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            id : id,
        }
        
        let api = app_api+'/change_is_cheque';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }
    handleResetModeOfPayment(e){
      this.setState({modeDefault:0});
    }

    handleChange(e){
        let target = this.props.target;
        let thisval = e.currentTarget.value; 
        if(thisval!=="all" && thisval!==""){
          let is_direct_pay = PaymentMode[thisval].direct_pay;
          if(is_direct_pay===true){
            if(document.getElementById("enableDirectPay")){
              document.getElementById("enableDirectPay").click();
            }
          }else if(is_direct_pay===false){
            if(document.getElementById("disableDirectPay")){
              document.getElementById("disableDirectPay").click();
            }
          }
          let is_cheque  = PaymentMode[thisval].is_cheque;
          localStorage.setItem('is_cheque',is_cheque);
          if(is_cheque===true || is_cheque==='1' || is_cheque===1){
            if(document.getElementById("handleEnableChequePayment")){
              document.getElementById("handleEnableChequePayment").click();
            }
            if(document.getElementById("EnableChequePayment")){
              document.getElementById("EnableChequePayment").click();
            }

            let qs = document.querySelectorAll('.pay-cheque-info');
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide')
            }

          }else{
            let qs = document.querySelectorAll('.pay-cheque-info');
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.add('hide')
            }
            if(document.getElementById("handleDisableChequePayment")){
              document.getElementById("handleDisableChequePayment").click();
            }
            if(document.getElementById("DisableChequePayment")){
              document.getElementById("DisableChequePayment").click();
            }
          }
        }
        if(thisval==="all"){
          if(document.getElementById("enableDirectPay")){
            document.getElementById("enableDirectPay").click();
          }
        }
       
        this.setState({modeDefault:thisval});
        localStorage.setItem('p_mode',thisval);
        if(target==='New Sale' || target==='New Purchase'){
          if(thisval!=='2'){
            localStorage.removeItem("use_buff_amount");
          }
          let is_credit_limit = this.CHECK_CREDIT_LIMIT('save','none');
       
          let pm_element = document.getElementById("mode_of_payment");
          let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
          localStorage.setItem('spm',ptxt);
          if(document.getElementById("handleChangePaymentMode")){
            setTimeout(function(){
              document.getElementById("handleChangePaymentMode").click();
            },100);
          }
          setTimeout(function(){
            if(document.getElementById("final-total-text")){
              let paid_amount = document.getElementById("final-total-text").value;
              if(document.getElementById("total-bill-amount")){
                document.getElementById("total-bill-amount").innerHTML = paid_amount;
              }
              if(document.getElementById("bill-pay-pop-title")){
                document.getElementById("bill-pay-pop-title").innerHTML = paid_amount;
              }
              if(document.getElementById("total-paid-amount")){
                let camt = document.getElementById("total-paid-amount").innerHTML;
                if(camt==="" || camt==="0"){
                  document.getElementById("total-paid-amount").innerHTML = paid_amount;
                }
              }
              if(document.getElementById("bill-pay-pop-title")){
                document.getElementById("bill-pay-pop-title").innerHTML = paid_amount;
              }
              if(document.getElementById("total-cash-amount")){
                let camt = document.getElementById("total-cash-amount").value;
                if(camt===""){
                  document.getElementById("total-cash-amount").value = paid_amount;
                }
              }else if(document.getElementById("total-online-payment-amount")){
                let camt = document.getElementById("total-online-payment-amount").value;
                if(camt===""){
                  document.getElementById("total-online-payment-amount").value = paid_amount;
                }
              }else if(document.getElementById("total-credit-amount")){
                let camt = document.getElementById("total-credit-amount").value;
                if(camt===""){
                  document.getElementById("total-credit-amount").value = paid_amount;
                }
              }
            }
          },110);
          if(ptxt==='payment' || ptxt==='cash'){
            if(document.getElementById("paid")){
              document.getElementById("paid").style.display = "none";
            }
          }else{
            if(document.getElementById("paid")){
               document.getElementById("paid").style.display = "block";
             }
          }
        }else if(target==='default_payment_mode_for_parchase' || target==='default_payment_mode_for_sale'){
          
          if(target==='default_payment_mode_for_sale'){
            if(document.getElementById("handleChangePaymentModeForSale")){
              document.getElementById("handleChangePaymentModeForSale").click();
            }
          }

          if(target==='default_payment_mode_for_parchase'){
            if(document.getElementById("handleChangePaymentModeForPurchase")){
              document.getElementById("handleChangePaymentModeForPurchase").click();
            }
          }
          let send_data = {
              store_name:user_info.store_name,
              branch_id:user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              name : target,
              value : thisval,
          }
          
          let api = app_api+'/save_option';
          axios.post(api, send_data)
            .then(function (response) {
          }.bind(this)).catch(function(res){
              
          })
        }
    }
    componentDidMount(){
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_payment_mode';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                if(this.props.target==='payments' && payment_mode!=='Credit'){
                  PaymentMode[id] = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
                }else if(this.props.target!=='payments'){
                  PaymentMode[id] = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
                }
            });
           
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })

    }

    handleRefreshPaymentMode(){
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_payment_mode';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            let pmode = localStorage.getItem("p_mode");
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                if(pmode!=="all" && id===parseInt(pmode)){
                  value.status = 1;
                }
                if(this.props.target==='payments' && payment_mode!=='Credit'){
                  PaymentMode[id] = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
                }else if(this.props.target!=='payments'){
                  PaymentMode[id] = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
                }
            });
           
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }


    editMode(event){ 
      event.preventDefault();
      this.setState({is_pay_mode:false});
      
      let id = event.currentTarget.id;
      let pay_mode_data = this.state.payment_modes[id];
      this.setState({payment_mode_data:pay_mode_data});

      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        let _this = this;
        setTimeout(() => {
          _this.setState({is_pay_mode:true});
          var element = document.getElementById(thisid);
          element.classList.remove("fade");
          element.style.display="block";
          if(pay_mode_data.is_cheque===true){
            document.getElementById("is_cheque").checked = true;
          }
          if(pay_mode_data.direct_pay===true){
            document.getElementById("is_direct_pay").checked = true;
          }
          let account_type = pay_mode_data.account_type;
          if(document.getElementById("account_type")){
            document.getElementById("account_type").value = account_type;
          }
        }, 100);
      }
     /*  var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);
      
      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show"); */
    }

    updateRow(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);
      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            payment_mode : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      let api = app_api+'/update_payment_mode';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
      
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
     deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
    
    handleClosePayMode = () =>{
      this.setState({is_pay_mode:false});
      this.setState({payment_mode_data:[]});
      let _this = this;
    
      setTimeout(() => {
        _this.setState({is_pay_mode:true});
        if(document.getElementById("is_cheque")){
          document.getElementById("is_cheque").checked = false;
          document.getElementById("is_cheque").removeAttribute('disabled');
        }
        if(document.getElementById("is_direct_pay")){
          document.getElementById("is_direct_pay").checked = false;
          document.getElementById("is_direct_pay").removeAttribute('disabled');
        }
        if(document.getElementById("account_type")){
          document.getElementById("account_type").value = '';
        }
      }, 100);
    }

    render() {
        return (
          <div>
            <span id="handleClosePayMode" onClick={this.handleClosePayMode}></span>
            {this.props.show==='list' &&
            <table className="table">
              <thead>
                <tr>
                  <th>PAYMENT MODE</th>
                  <th>Direct Payment?</th>
                  <th>Is Cheque?</th>
                </tr>
              </thead>
              <tbody>
                  {(this.state.payment_modes.length > 0) ?this.state.payment_modes.map( (value, index) => {
                   return (
                    <tr key={ index }  id={'delete_payment_mode_'+index} className={(value.status===2)?'no-visible':''}>
                      <td>
                        <span id={'pm-span-'+index } className="show l-span pay-mode-name">
                          { value.payment_mode }
                        </span>
                        <input type="text" defaultValue={value.payment_mode} className="form-control hide" id={'pm-txt-'+index}/>
                      </td>
                      <td>
                      {(value.payment_mode==='Cash' || value.payment_mode==='Credit') &&
                        <input type="checkbox" checked={(value.payment_mode==='Cash')?true:false} disabled={(value.payment_mode==='Cash')?false:true}/>
                      }
                      {(value.payment_mode!=='Cash' && value.payment_mode!=='Credit') &&
                        <input type="checkbox" onChange={this.handleChangePMType} data-id={index} defaultChecked={value.direct_pay}/>
                      }
                      
                      </td>
                      <td>
                        {(value.payment_mode!=='Cash' && value.payment_mode!=='Credit' && value.payment_mode!=='Online Payment') &&
                          <input type="checkbox" onChange={this.handleChangeIsCheque} data-id={index} defaultChecked={value.is_cheque}/>
                        }
                      </td>
                      <td>
                        <div id={'normal-mode-'+index} className="show">
                          <FontAwesomeIcon id={index} onClick={this.changeStatus} icon={faEye} className="pull-right fa-icon" style={{marginTop:'5px',marginLeft:'5px'}}/>
                          {this.is_access('settings-bill-payment-modify') && 
                            <>
                            {(value.payment_mode!=='Credit') &&
                              <FontAwesomeIcon id={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon" data_target="add_payment_mode"/>
                            }
                            </>
                          }
                          {this.is_access('settings-bill-payment-delete') && 
                          <>
                          {(value.payment_mode!=='Cash' && value.payment_mode!=='Credit' && value.payment_mode!=='Online Payment') &&
                            <FontAwesomeIcon id={index} onClick={this.deleteRow} data_target="delete_confirm" data_value={value} data_id="delete_payment_mode" icon={faTrash} className="pull-right fa-icon"/>
                          }
                          </>
                          }
                        </div>
                        <div id={'edit-mode-'+index} className="hide mrtop-10">
                          {this.is_access('settings-bill-payment-modify') && 
                          <>
                          <FontAwesomeIcon id={index} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                          <FontAwesomeIcon id={index} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                          </>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody> 
            </table>
            }       
            {this.props.show==='dropdown' &&   
              <>
              <span onClick={this.handleRefreshPaymentMode} id="refresh_mode_of_payment"></span>
              <span onClick={this.handleResetModeOfPayment} id="reset_mode_of_payment"></span>
              <span onClick={this.handleSetModeOfPayment} id="handleSetModeOfPayment"></span>
              <select target={this.props.target} tabindex={this.props.tabindex} data-id={this.props.data_id} className="form-control form-field" name={this.props.name}  value={(this.state.modeDefault || localStorage.getItem('p_mode') || this.props.default )} onChange={this.handleChange} id={this.props.id}>
                  <>
                  {(this.props.target && this.props.target==='contact') &&
                    <option value="all" key="all">All</option>
                  }
                  {(this.props.target && (this.props.target==='New Purchase' || this.props.target==='New Sale' || this.props.target==='empty' || this.props.target==='default_payment_mode_for_parchase'  || this.props.target==='default_payment_mode_for_sale')) &&
                    <option value="" key="none">Select Pay Mode</option>
                  }
                  </>
                  {(this.props.data_target!=='billing' && this.props.target!=='default_payment_mode_for_parchase' && this.props.target!=='default_payment_mode_for_sale') &&
                    <>
                    {(this.state.payment_modes.length > 0) ? this.state.payment_modes.map( (value, index) => {
                    return (
                      <>
                        {(value.status===1) &&
                          <option value={index} key={index}>{value.payment_mode}</option>
                        }
                      </>
                      )
                    }) : ''}
                    </>
                  }
                  {(this.props.data_target==='billing' || this.props.target==='default_payment_mode_for_parchase' || this.props.target==='default_payment_mode_for_sale') &&
                    <>
                      <option value={1} key={1}>Payment</option>
                      <option value={2} key={2}>Credit</option>
                    </>
                  }
              </select>
              </>
            }     
            {(this.state.is_pay_mode===true) &&
              <ModalBox id="add_payment_mode" title="ADD PAYMENT MODE" data_id="Payment Mode" payment_mode_data={this.state.payment_mode_data}/>
            }
            </div>
        );
    }
}