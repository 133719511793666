import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';
let _this;
export default class Pager extends Component {
    constructor() {
      super();
      this.state = {
          prev_link :'',
          current_page : '',
          total_page : '',
          next_link:''
      };
      _this = this;
    }
    handlePaginationShortcut = (e)=>{
      
      // 33 - page up
      // 34 - page down
      // 35 - end
      // 36 - home
      if(e.which===33){
        let prev_link = this.state.prev_link;
        
        var page = prev_link.match(/\d+$/g);
        if(page && page[0]){
          let comming_page = parseInt(page[0]);
          if(comming_page>0){
            e.preventDefault();
            window.location.href = prev_link;
          }
        }else{
          window.location.href = prev_link;
        }
      }else if(e.which===34){
        let next_link = this.state.next_link;
        let total_page = this.props.total_page;
        var page = next_link.match(/\d+$/g);
        if(page){
          let comming_page = parseInt(page[0]);
          if(comming_page<=total_page){
            e.preventDefault();
            window.location.href = this.state.next_link;
          }
        }
      }else if(e.which===35){
        if(this.props.total_page>1){
          e.preventDefault();
          let last_page = this.state.next_link.replace(/\d+$/g, this.props.total_page);
          window.location.href = last_page;
        }
      }else if(e.which===36){
        e.preventDefault();
        let first_page = this.state.next_link.replace(/\d+$/g, '1');
        window.location.href = first_page;
      }
    }
    componentDidMount(){
      document.addEventListener("keydown", this.handlePaginationShortcut);
      let curl = window.location.href;
      
      let cpage;
      
     
      const contacts = curl.match(/contacts$/i);
      const contacts_page = curl.match(/contacts\/page\/(\d+$)/i);
      const contacts_type = curl.match(/contacts\/(\d+$)/i);
      const contacts_type_page = curl.match(/contacts\/(\d+)\/page\/(\d+$)/i);
      if(contacts){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/contacts/page/2'});
      }else if(contacts_page){
        cpage = parseInt(contacts_page[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/contacts/page/'+next;
        let prev_link = '/contacts/page/'+prev;
        if(prev===1){
          prev_link = '/contacts';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }else if(contacts_type){
          let type = contacts_type[1];
          _this.setState({current_page:1});
          _this.setState({prev_link:'#'});
          _this.setState({next_link:'/contacts/'+type+'/page/2'});
      }else if(contacts_type_page){
          let type = parseInt(contacts_type_page[1]);
          cpage = parseInt(contacts_type_page[2]);
          let next = cpage+1;
          let prev = cpage-1;
          let next_link = '/contacts/'+type+'/page/'+next;
          let prev_link = '/contacts/'+type+'/page/'+prev;
          if(prev===1){
            prev_link = '/contacts/'+type;
          }
          _this.setState({current_page:cpage});
          _this.setState({prev_link:prev_link});
          _this.setState({next_link:next_link});
      }

      const root = window.location.pathname;

      const billings = curl.match(/billings$/i);
      const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
      const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);
      
      if(billings || root==='/'){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/billings/sales/2'});
      }else if(active_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/billings/'+active_tab[1]+'/2'});
      }else if(page_tab){
        cpage = parseInt(page_tab[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/billings/'+page_tab[1]+'/'+next;
        let prev_link = '/billings/'+page_tab[1]+'/'+prev;
        if(prev===1){
          prev_link = '/billings/'+page_tab[1];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
      if(trash_hold){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/billings/'+trash_hold[1]+'/'+trash_hold[2]+'/2'});
      }else if(trash_hold_page){
        cpage = parseInt(trash_hold_page[3]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/billings/'+trash_hold_page[1]+'/'+trash_hold_page[2]+'/'+next;
        let prev_link = '/billings/'+trash_hold_page[1]+'/'+trash_hold_page[2]+'/'+prev;
        if(prev===1){
          prev_link = '/billings/'+trash_hold_page[1]+'/'+trash_hold_page[2];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const orders = curl.match(/orders$/i);
      const order_active_tab = curl.match(/orders\/([a-z_A-Z]+$)/i);
      const order_page_tab = curl.match(/orders\/([a-z_A-Z]+)\/(\d+$)/i);
      
      if(orders || root==='/'){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/orders/sales/2'});
      }else if(order_active_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/orders/'+order_active_tab[1]+'/2'});
      }else if(order_page_tab){
        cpage = parseInt(order_page_tab[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/orders/'+order_page_tab[1]+'/'+next;
        let prev_link = '/orders/'+order_page_tab[1]+'/'+prev;
        if(prev===1){
          prev_link = '/orders/'+order_page_tab[1];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const order_trash_hold = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const order_trash_hold_page = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
      if(order_trash_hold){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/orders/'+order_trash_hold[1]+'/'+order_trash_hold[2]+'/2'});
      }else if(order_trash_hold_page){
        cpage = parseInt(order_trash_hold_page[3]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/orders/'+order_trash_hold_page[1]+'/'+order_trash_hold_page[2]+'/'+next;
        let prev_link = '/orders/'+order_trash_hold_page[1]+'/'+order_trash_hold_page[2]+'/'+prev;
        if(prev===1){
          prev_link = '/orders/'+order_trash_hold_page[1]+'/'+order_trash_hold_page[2];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const quotation = curl.match(/quotation$/i);
      const quotation_tab = curl.match(/quotation\/([a-z_A-Z]+$)/i);
      const quot_page_tab = curl.match(/quotation\/([a-z_A-Z]+)\/(\d+$)/i);
      
      if(quotation || root==='/'){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/quotation/sales/2'});
      }else if(quotation_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/quotation/'+quotation_tab[1]+'/2'});
      }else if(quot_page_tab){
        cpage = parseInt(quot_page_tab[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/quotation/'+quot_page_tab[1]+'/'+next;
        let prev_link = '/quotation/'+quot_page_tab[1]+'/'+prev;
        if(prev===1){
          prev_link = '/quotation/'+quot_page_tab[1];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const quot_trash_hold = curl.match(/quotation\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const quot_trash_hold_page = curl.match(/quotation\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
      if(quot_trash_hold){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/quotation/'+quot_trash_hold[1]+'/'+quot_trash_hold[2]+'/2'});
      }else if(quot_trash_hold_page){
        cpage = parseInt(quot_trash_hold_page[3]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/quotation/'+quot_trash_hold_page[1]+'/'+quot_trash_hold_page[2]+'/'+next;
        let prev_link = '/quotation/'+quot_trash_hold_page[1]+'/'+quot_trash_hold_page[2]+'/'+prev;
        if(prev===1){
          prev_link = '/quotation/'+quot_trash_hold_page[1]+'/'+quot_trash_hold_page[2];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const order_payments = curl.match(/order\/payments/i);
      const payments = curl.match(/payments$/i);
      const pay_active_tab = curl.match(/payments\/([a-z_A-Z]+$)/i);
      const pay_page_tab = curl.match(/payments\/([a-z_A-Z]+)\/(\d+$)/i);

      const pay_type_tab = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+$)/i);
      const pay_type_page_tab = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);

      let order_pay = '';
      if(order_payments){
        order_pay = '/order';
      }

      if(payments){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:order_pay+'/payments/all/2'});
      }else if(pay_active_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:order_pay+'/payments/'+pay_active_tab[1]+'/2'});
      }else if(pay_page_tab){
        cpage = parseInt(pay_page_tab[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = order_pay+'/payments/'+pay_page_tab[1]+'/'+next;
        let prev_link = order_pay+'/payments/'+pay_page_tab[1]+'/'+prev;
        if(prev===1){
          prev_link = order_pay+'/payments/'+pay_page_tab[1];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }else if(pay_type_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:order_pay+'/payments/'+pay_type_tab[1]+'/'+pay_type_tab[2]+'/2'});
      }else if(pay_type_page_tab){
        cpage = parseInt(pay_type_page_tab[3]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = order_pay+'/payments/'+pay_type_page_tab[1]+'/'+pay_type_page_tab[2]+'/'+next;
        let prev_link = order_pay+'/payments/'+pay_type_page_tab[1]+'/'+pay_type_page_tab[2]+'/'+prev;
        if(prev===1){
          prev_link = order_pay+'/payments/'+pay_type_page_tab[1]+'/'+pay_type_page_tab[2];
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const payment_filter = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+$)/i);
      let filter_page = 'all';
      if(payment_filter){
        let ptype = payment_filter[1];
        let filter_page = payment_filter[2];
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/payments/'+ptype+'/'+filter_page+'/2'});
      }
      const payment_filter_page = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);
      if(payment_filter_page){
        let ptype = payment_filter_page[1];
        let filter_page = payment_filter_page[2];
        let current_page = payment_filter_page[3];
        cpage = parseInt(current_page);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/payments/'+ptype+'/'+filter_page+'/'+next;
        let prev_link = '/payments/'+ptype+'/'+filter_page+'/'+prev;
        if(prev===1){
          prev_link = '/payments/'+ptype+'/'+filter_page;
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
      }


      const expenses = curl.match(/expenses$/i);
      
      const exp_page_tab = curl.match(/expenses\/page\/(\d+$)/i);
      const exp_trash_tab = curl.match(/expenses\/trash$/i);
      const exp_trash_page_tab = curl.match(/expenses\/trash\/page\/(\d+$)/i);
      if(expenses){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/expenses/page/2'});
      }else if(exp_page_tab){
        cpage = parseInt(exp_page_tab[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/expenses/page/'+next;
        let prev_link = '/expenses/page/'+prev;
        if(prev===1){
          prev_link = '/expenses';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }else if(exp_trash_tab){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/expenses/trash/page/2'});
      }else if(exp_trash_page_tab){
        cpage = parseInt(exp_trash_page_tab[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/expenses/trash/page/'+next;
        let prev_link = '/expenses/trash/page/'+prev;
        if(prev===1){
          prev_link = '/expenses/trash';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const transports = curl.match(/transports$/i);
      const transport_page_tab = curl.match(/transports\/page\/(\d+$)/i);
      if(transports){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/transports/page/2'});
      }else if(transport_page_tab){
        cpage = parseInt(transport_page_tab[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/transports/page/'+next;
        let prev_link = '/transports/page/'+prev;
        if(prev===1){
          prev_link = '/transports';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }
      const dr_cr_notes = curl.match(/credit-debit-note\/([a-z_A-Z]+)/i);
      const dr_cr_note_page_tab = curl.match(/credit-debit-note\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);
      if(dr_cr_note_page_tab){
        cpage = parseInt(dr_cr_note_page_tab[3]);
        let targetcpage = dr_cr_note_page_tab[1];
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/credit-debit-note/'+targetcpage+'/page/'+next;
        let prev_link = '/credit-debit-note/'+targetcpage+'/page/'+prev;
        if(prev===1){
          prev_link = '/credit-debit-note/'+targetcpage;
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }else if(dr_cr_notes){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/credit-debit-note/'+dr_cr_notes[1]+'/page/2'});
      }
      
      const stock_transfer = curl.match(/stock-transfer$/i);
      const stock_transfer_page_tab = curl.match(/stock-transfer\/page\/(\d+$)/i);
      if(stock_transfer){
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/settings/stock-transfer/page/2'});
      }else if(stock_transfer_page_tab){
        cpage = parseInt(stock_transfer_page_tab[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/settings/stock-transfer/page/'+next;
        let prev_link = '/settings/stock-transfer/page/'+prev;
        if(prev===1){
          prev_link = '/settings/stock-transfer';
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }

      const cheques = curl.match(/payments\/cheques\/([a-z_A-Z-]+)\/([all|in|out]+$)/i);
      const cheques_page_tab = curl.match(/payments\/cheques\/([a-z_A-Z]+)\/([all|in|out]+)\/(\d+$)/i);
      const cheque_type = curl.match(/cheques\/([received|sent]+$)/i);
      const cheque_type_page_tab = curl.match(/cheques\/([received|sent]+)\/(\d+$)/i);
      if(cheques){
        let type = cheques[1];
        let in_or_out = cheques[2];
        _this.setState({current_page:1});
        _this.setState({prev_link:'#'});
        _this.setState({next_link:'/payments/cheques/'+type+'/'+in_or_out+'/2'});
      }else if(cheques_page_tab){
        let type = cheques_page_tab[1];
        let in_or_out = cheques_page_tab[2];
        cpage = parseInt(cheques_page_tab[3]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/payments/cheques/'+type+'/'+in_or_out+'/'+next;
        let prev_link = '/payments/cheques/'+type+'/'+in_or_out+'/'+prev;
        if(prev===1){
          prev_link = '/payments/cheques/'+type+'/'+in_or_out;
        }
        _this.setState({current_page:cpage});
        _this.setState({prev_link:prev_link});
        _this.setState({next_link:next_link});
      }
    } 



    render() {
        return (
             <div className="page-nav">
                <div className="navd"><a href={_this.state.prev_link}><div className="navd"><FontAwesomeIcon className="cont" icon={faCaretLeft}/></div></a></div>
                <div className="navd center"><p className="msg">{_this.state.current_page} of {_this.props.total_page}</p></div>
                <div className="navd"><a href={(_this.state.current_page===_this.props.total_page?'#':_this.state.next_link)}><div className="navd"><FontAwesomeIcon  className="cont" icon={faCaretRight}/></div></a></div>
              </div>
        );
    }
}