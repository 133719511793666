import React, { Component } from "react";
import utils from '../utils.js';
import {Document, Page, View, Text, StyleSheet ,Font  } from '@react-pdf/renderer';
import PDFPoweredBy from "./pdf-powered-by.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class PendingInvoicePDF extends Component {
    constructor() {
      super();
      this.state = {
        
      };
      this.currency_format = utils.currency_format.bind(this);
    }
    render() {
    	
        return (
           <Document>
		    <Page>
			    <View style={styles.container}>
			      		<View style={styles.block}>
                          <Text style={styles.heading}>
                            	Pending Invoice
                          </Text>
	                    </View>
				       	{(this.props.pdf_data.contact_ids && this.props.pdf_data.contact_ids.length > 0) ?this.props.pdf_data.contact_ids.map( (val, ind) => {
				       		val = parseInt(val);
				       		return(
				            <View>
				            	<View style={styles.block}>
				            		
	                                  <Text style={styles.heading}>
	                                    {(this.props.pdf_data.contact_type==="1")?"Customer":"Supplier"}
	                                    {' Name: '+ this.props.pdf_data.contact_names[val]}
	                                  </Text>
	                              
	                            </View>
	                            <View style={styles.block}>
	                                <View style={styles.view}>
	                                  <Text style={styles.header}>SNO.</Text>
	                                  <Text style={styles.header}>Date</Text>
	                                  <Text style={styles.header}>Invoice</Text>
	                                  <Text style={styles.header}>Pending</Text>
	                                  <Text style={styles.header}>Days</Text>
	                                </View>
                                </View>
					          	<View style={styles.block}> 	
					            {(this.props.pdf_data.pending_invoice[val] && this.props.pdf_data.pending_invoice[val].length > 0) ?this.props.pdf_data.pending_invoice[val].map( (value, index) => {
					            	return (
					                <View style={styles.view} wrap={false}>
					                	<Text style={styles.text}>{index+1}</Text>
                                        <Text style={styles.text}>{value.created_date}</Text>
                                        <Text style={styles.text}>{value.invoice_number}</Text>
                                        <Text style={styles.text}>{this.currency_format(value.pending_amount)}</Text>
                                        <Text style={styles.text}>{value.age}</Text>
					                </View>
					                )
					           	}) : ''}
					        	</View>
					        	<View style={styles.block}>
						        	<View style={styles.view}>
	                                  <Text style={styles.header}></Text>
	                                  <Text style={styles.header}></Text>
	                                  <Text style={styles.header}></Text>
	                                  <Text style={styles.header}>{this.currency_format(this.props.pdf_data.total_pendings[val])}</Text>
	                                  <Text style={styles.header}></Text>
	                                </View>
                                </View>
				            </View>
				            )
				        }) : ''}
				       
		        </View>
				<PDFPoweredBy />
		    </Page>
		  </Document>
        );
    }
}
const styles = StyleSheet.create({
	container: {margin:'20pt'},
	heading: {width:'100%',padding:'4pt',textAlign:'center',fontWeight:'100'},
	header: {fontWeight:'bold',padding:'4pt',width:'100pt'},
	text: {padding:'4pt',width:'100pt'},
	view: {flexDirection:'row',borderBottom:'1pt solid #d7d7d7',width:'100%'},
	block: {fontSize:'12pt',borderLeft:'1pt solid #d7d7d7',borderTop:'1pt solid #d7d7d7',borderRight:'1pt solid #d7d7d7'},

});