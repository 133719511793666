import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import utils from '../utils.js';
import Link from "./link";
import DrawerLink from "./open-drawer-link";
import AdjustablePrint from "./adjustable-print";
export default class ViewCashEntry extends Component {
    constructor(props) {
        super();
        this.state = {
           is_print : false
        };
        this.isMobile = utils.isMobile.bind(this);      
    }


    componentDidMount = ()=>{
       
    }
    handlePrint = ()=>{
        this.setState({is_print:true});
        let _this = this;
        setTimeout(() => {
            _this.setState({is_print:false});
        }, 100);
    } 
    render() {
        return (
                <>
                    <div className="view-on-fly" id="page-content-wrapper">
                        <nav className="navbar border-bottom">
                            <div className="row">
                                <div className="col-md-6 mrtop-15 action-area">
                                   <h4>CASH ENTRY INFO</h4>
                                </div>
                                <div className="col-md-6 mrtop-15 action-area text-right">
                                    {/* <div className="e-p-p-opt div-display" style={{marginRight:'25px'}}>
                                        <div  onClick={this.handleEditCheque} id="edit-cheque" className="eppopt">
                                        <a id="edit-cheque-lbl">Edit</a>
                                        </div>
                                        <div  onClick={this.handlePrint} id="preview" className="eppopt">
                                            <a id="preview-lbl">Preview</a>
                                        </div>
                                        <div  onClick={this.handlePrint} id="print" className="eppopt">
                                        <a id="print-lbl">Print</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid page-break">
                            <div className="content-area">
                                <div className="content-head">
                                    <div className="row border-bottom">
                                        <div className="col-md-6">
                                            <h3 style={{color:'gray',margin:'0px'}}>₹{utils.currency_format(this.props.entry_data[0].camount)}</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <h3 style={{color:'gray',margin:'0px'}}>{this.props.entry_data[0].entry_sequence}</h3>
                                            <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>{this.props.entry_data[0].created_date}</p>
                                        </div>
                                    </div>
                                   <div className="row mrtop-30 border-bottom">
                                        <div className="col-md-12">
                                            <h4>ENTRY INFO</h4>
                                        </div>
                                   </div>
                                   <div className="row mrtop-10">
                                        <div className="col-md-6">
                                            <b>TO ACCOUNT</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Account</th>
                                                        <th>Description</th>
                                                        <th>Note</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.props.entry_data[0].from_account}</td>
                                                        <td>{this.props.entry_data[0].cdesc}</td>
                                                        <td>{this.props.entry_data[0].cnote}</td>
                                                        <td style={{color:"rgb(46, 195, 46)"}}>₹{utils.currency_format(this.props.entry_data[0].camount)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <b>FROM ACCOUNT</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Account</th>
                                                        <th>Description</th>
                                                        <th>Note</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.props.entry_data[1].from_account}</td>
                                                        <td>{this.props.entry_data[1].cdesc}</td>
                                                        <td>{this.props.entry_data[1].cnote}</td>
                                                        <td  style={{color:"red"}}>₹{utils.currency_format(this.props.entry_data[0].camount)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                   </div>

                                   {/* <div className="row mrtop-10">
                                        <div className="col-md-4">
                                            <b>BILLS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.entry_data.pending_bills.map((value,idx)=>{
                                                        total_pending = total_pending + parseFloat(value.adjusted_amount);
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    ₹{utils.currency_format(value.adjusted_amount)}</td>
                                                            </tr>
                                                        )
                                                   }))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_pending)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <b>RETURNS/DR-CR NOTES</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.entry_data.adjusted_bills && this.props.entry_data.adjusted_bills.others && this.props.entry_data.adjusted_bills.others.map((value,idx)=>{
                                                    total_others = total_others + parseFloat(value.adjusted_amount);
                                                        return (
                                                           
                                                                <tr>
                                                                    <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                </tr>
                                                          
                                                        )
                                                   }))}
                                                   {(this.props.entry_data.adjusted_bills && this.props.entry_data.adjusted_bills.others && this.props.entry_data.adjusted_bills.others.length===0) &&
                                                   <tr>
                                                        <td colSpan={2}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_others)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <b>PAYMENTS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Payment #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.entry_data.adjusted_bills && this.props.entry_data.adjusted_bills.payments && this.props.entry_data.adjusted_bills.payments.map((value,idx)=>{
                                                    total_payments = total_payments + parseFloat(value.adjusted_amount);
                                                        return (
                                                          
                                                                <tr>
                                                                    <td>
                                                                        <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.payment_mode}</span>
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                </tr>
                                                           
                                                        )
                                                   }))}
                                                    {(this.props.entry_data.adjusted_bills && this.props.entry_data.adjusted_bills.payments && this.props.entry_data.adjusted_bills.payments.length===0) &&
                                                   <tr>
                                                        <td colSpan={2}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_payments)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                   </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </>
        );
    }
   
}