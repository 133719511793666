import React, { Component } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretUp} from '@fortawesome/free-solid-svg-icons';
import utils from "../utils.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class KeyShortcut extends Component {
    constructor() {
        super();
        this.state = {
            active_tab : '',
            pay_key_count : 0,
            selected_staff_while_billing : false
        };
        this.tabsKey = [];
        this.handleBillingShortcut = this.handleBillingShortcut.bind(this);
        this.handlePaymentsShortcut = this.handlePaymentsShortcut.bind(this);
        this.handleExpenseShortcut = this.handleExpenseShortcut.bind(this);
        this.handleContactsShortcut = this.handleContactsShortcut.bind(this);
        this.handleProductShortcut = this.handleProductShortcut.bind(this);
        this.moveCusor = this.moveCusor.bind(this);
        this.handleHelp = this.handleHelp.bind(this);
    }
    componentWillUnmount() {
       document.removeEventListener("keydown", this.handleBillingShortcut);
       document.removeEventListener("keydown", this.handlePaymentsShortcut);
       document.removeEventListener("keydown", this.handleContactsShortcut);
       document.removeEventListener("keydown", this.handleExpenseShortcut);
       document.removeEventListener("keydown", this.handleProductShortcut);
       document.removeEventListener("keyup", this.handleHelp);
    }
    componentDidMount(){
        let curl = window.location.href;

        const billings = curl.match(/billings$/i);
        if(billings){
            this.setState({active_tab:'billings'});
        }
        const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
        if(active_tab){
            this.setState({active_tab:active_tab[1]});
        }
        const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);
        const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
        const create_quotation = curl.match(/create-quotation\/(\d+$)/i);

        const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
        const edit_quotation = curl.match(/create-quotation\/([edit]+)\/(\d+$)/i);
        if(add_billing_type || edit_billing){
            this.setState({active_tab:'new-billing'});
            let general_contact_bill_settings = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                keys:"'selected_staff_while_billing'"
            }
            let gc_api = app_api+'/get_settings';
            axios.post(gc_api, general_contact_bill_settings)
              .then(function (response) {
                let resp = response.data;
              
                if(resp.selected_staff_while_billing){
                  let pm = resp.selected_staff_while_billing;
                  this.setState({selected_staff_while_billing:pm});
                }
                
            }.bind(this)).catch(function(res){
                
            })
        }
        if(create_quotation || edit_quotation){
            this.setState({active_tab:'create-quotation'});
        }

        
        if(billings || active_tab || page_tab || add_billing_type || edit_billing || create_quotation || edit_quotation){
            
            document.addEventListener("keydown", this.handleBillingShortcut);
        }

        const orders = curl.match(/orders$/i);
        if(orders){
            this.setState({active_tab:'orders'});
        }
        const active_order_tab = curl.match(/orders\/([a-z_A-Z]+$)/i);
        if(active_order_tab){
            this.setState({active_tab:active_order_tab[1]});
        }
        const page_order_tab = curl.match(/orders\/([a-z_A-Z]+)\/(\d+$)/i);
        const add_order_type = curl.match(/new-order\/(\d+$)/i);
        const edit_order = curl.match(/new-order\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
        if(add_order_type || edit_order){
            this.setState({active_tab:'new-billing'});
        }
        if(orders || active_order_tab || page_order_tab || add_order_type || edit_order){
            document.addEventListener("keydown", this.handleBillingShortcut);
        }


        const payments = curl.match(/payments$/i);
        const payments_type = curl.match(/payments\/([a-z_A-Z]+$)/i);
        const invoice_for = curl.match(/new-payment\/([a-z_A-Z]+)\/(\d+$)/i);
        const in_or_out = curl.match(/new-payment\/([all|in|out]+$)/i);
        const edit_payment = curl.match(/new-payment\/([edit]+)\/(\d+$)/i);
        const multiple_payment = curl.match(/new-payment\/multiple\/(.*)/i);
        if(payments){
            this.setState({active_tab:'payments'});
        }else if(payments_type){
            this.setState({active_tab:payments_type[1]});
        }
        if(payments || payments_type || invoice_for || in_or_out || edit_payment || multiple_payment){
           
            document.addEventListener("keydown", this.handlePaymentsShortcut);
        }


        const new_expense = curl.match(/new-expense/i);
        const expense_edit = curl.match(/new-expense\/edit\/(\d+$)/i);
        if(new_expense || expense_edit){
           
            document.addEventListener("keydown", this.handleExpenseShortcut);
        }
        const transport = curl.match(/transports/i);
        
        if(transport){
           
            document.addEventListener("keydown", this.hanldeTransportShortcut);
        }
        const stock_calculator = curl.match(/stock-calculator/i);
        
        if(stock_calculator){
           
            document.addEventListener("keydown", this.hanldeStockCalculatorShortcut);
        }

        const contacts = curl.match(/contacts$/i);
        const contacts_type = curl.match(/contacts\/(\d+$)/i);
        const add_contact = curl.match(/add-contact$/i);
        const add_contact_tab = curl.match(/add-contact\/([a-z_A-Z]+$)/i);
        const add_contact_billing = curl.match(/add-contact\/billing\/(\d+$)/i);
        const edit_contact = curl.match(/edit-contact\/(\d+$)/i);
        if(contacts || contacts_type){
            this.setState({active_tab:'contacts'});
        }
        if(add_contact || add_contact_tab || add_contact_billing){
            this.setState({active_tab:'add_contact'});
        }
        if(edit_contact){
            this.setState({active_tab:'edit_contact'});
        }
        if(contacts || contacts_type || add_contact || add_contact_tab || add_contact_billing || edit_contact){
            document.addEventListener("keydown", this.handleContactsShortcut);
        }
        
        const add_product = curl.match(/products\/add/i);
        const edit_product = curl.match(/products\/edit\/(\d+$)/i);
        if(add_product){
            this.setState({active_tab:'add_product'});
        }
        if(edit_product){
            this.setState({active_tab:'edit_product'});
        }
        if(add_product || edit_product){
            document.addEventListener("keydown", this.handleProductShortcut);
        }


        document.addEventListener("keyup", this.handleHelp);
        document.addEventListener("keydown", this.handleMenuShortcut);
    }

    handleProductShortcut(e){
        /*let li = document.querySelectorAll(".nav-pills li");
        for(let i=0;i<li.length;i++){
            let key = li[i].getAttribute('data-id');
            this.tabsKey.push(key);
        }*/
        let active_tab = this.state.active_tab;
    
        //let target = document.activeElement.getAttribute('target');
        if((active_tab==='add_product' || active_tab==='edit_product') && (e.which===13 && !e.ctrlKey)){
            if(e.target.getAttribute('tabindex')){

                let tabindex = parseInt(e.target.getAttribute('tabindex'));
                this.moveCusor('next',tabindex);
            }
        }else if((active_tab==='add_product' || active_tab==='edit_product') && (e.which===27 && !e.ctrlKey)){
            if(e.target.getAttribute('tabindex')){
                let tabindex = parseInt(e.target.getAttribute('tabindex'));
                this.moveCusor('prev',tabindex);
            }
           // console.log((tabindex));
        }
    }

    handleMenuShortcut(e){
        let code = e.which;
        
        if (e.altKey && e.ctrlKey && e.shiftKey ) {
            let ks = document.querySelectorAll("#menu-items .key-shortcut");
            for(let i=0;i<ks.length;i++){
                ks[i].classList.remove('hide');
            }
            
            if(code===66){
                window.location.href = '/billings';
            }else if(code===80){
                window.location.href = '/payments';
            }else if(code===73){
                window.location.href = '/products';
            }else if(code===67){
                window.location.href = '/contacts';
            }else if(code===83){
                window.location.href = '/settings/company-details';
            }
        }
    }


    handleHelp(){
        let ks = document.querySelectorAll(".key-shortcut");
        for(let i=0;i<ks.length;i++){
            ks[i].classList.add('hide');
        }
    }
    handleBillingShortcut(e){
      
       var element = document.getElementById('print_bill');
       if(element!==null){
            let is_print = element.style.display;
            if(is_print==='block'){
                if(e.which===13 || e.which===89){
                    document.getElementById("yes-print").click();
                }else if(e.which===27 || e.which===78){
                    setTimeout(function(){
                        document.getElementById("no-print").click();
                    },100);
                }else if(e.which===69){
                    if(document.getElementById('share-email-block')){
                        let has_hide_class = document.getElementById('share-email-block').classList.contains('hide');
                        if(!has_hide_class){
                            let is_email_checked = document.getElementById('share-email').checked;
                            document.getElementById('share-email').checked = !is_email_checked;
                        }
                    }
                }else if(e.which===87){
                    if(document.getElementById('icons8-whatsapp')){
                        document.getElementById('icons8-whatsapp').click();
                    }
                }
            }
        }
      let active_tab = this.state.active_tab;
      let target = document.activeElement.getAttribute('target');
      if (e.altKey && e.ctrlKey  && !e.shiftKey ) {
        
        let ks = document.querySelectorAll(".key-shortcut");
        for(let i=0;i<ks.length;i++){
            ks[i].classList.remove('hide');
        }
        
        if((active_tab==='billings' || active_tab==='purchase' || active_tab==='sales_return' || active_tab==='purchase_return') && e.which === 49 ){
            window.location.href = '/billings';
        }else if((active_tab==='billings' || active_tab==='purchase' || active_tab==='sales_return' || active_tab==='purchase_return') && e.which === 50){
            window.location.href = '/billings/purchase';
        }else if((active_tab==='sales_return' || active_tab==='purchase' || active_tab==='billings' || active_tab==='purchase_return') && e.which === 51){
            window.location.href = '/billings/sales_return';
        }else if((active_tab==='purchase_return' || active_tab==='purchase' || active_tab==='billings' || active_tab==='sales_return') && e.which === 52){
            window.location.href = '/billings/purchase_return';
        }else if(active_tab==='billings' && e.which===78){
            window.location.href = '/new-billing/1';
        }else if(active_tab==='purchase' && e.which===78){
            window.location.href = '/new-billing/2';
        }else if(active_tab==='sales_return' && e.which===78){
            window.location.href = '/new-billing/3';
        }else if(active_tab==='purchase_return' && e.which===78){
            window.location.href = '/new-billing/4';
        }else if(active_tab==='new-billing' && e.which===49){
            window.location.href = '/new-billing/1';
        }else if(active_tab==='new-billing' && e.which===50){
            window.location.href = '/new-billing/2';
        }else if(active_tab==='new-billing' && e.which===51){
            window.location.href = '/new-billing/3';
        }else if(active_tab==='new-billing' && e.which===52){
            window.location.href = '/new-billing/4';
        }
      }

        let key_code = e.which;

        if((active_tab==='new-billing' || active_tab==='create-quotation') && (key_code===38 || key_code===40)){
            e.preventDefault();
            
            let data_target = e.target.getAttribute('data-target');
            if(data_target==='pay-box'){
                let eClassList = document.querySelectorAll('.total-bill-amount');
                if(key_code===38){
                    let pay_key_count =this.state.pay_key_count;
                    
                    pay_key_count--;
                    
                    if(eClassList[pay_key_count]){
                        this.setState({pay_key_count:pay_key_count});
                        eClassList[pay_key_count].focus();
                    }
                
                }else if(key_code===40){
                    let pay_key_count = parseInt(e.target.getAttribute('data-index'));
                    if(eClassList.length > pay_key_count){
                        if(eClassList[pay_key_count]){
                            this.setState({pay_key_count:pay_key_count});
                            eClassList[pay_key_count].focus();
                        }
                    }
                }
            }
        }
    
      if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.ctrlKey && !e.altKey && (key_code===67 || key_code===73 || key_code===68 || key_code===80 || key_code===83 || key_code===72))){
          
            e.preventDefault();
            if(key_code===67){
                if(document.getElementById("contact_box")){
                    document.getElementById("contact_box").focus();
                }
            }else if(key_code===73){
                if(document.getElementById("iitem-box")){
                    document.getElementById("iitem-box").focus();
                }
            }else if(key_code===72){
                if(document.getElementById("hold")){
                    document.getElementById("hold").click();
                }
            }else if(key_code===83){
                if(document.getElementById("staff-dropdown")){
                    document.getElementById("staff-dropdown").focus();
                }
            }else if(key_code===68){
                if(document.getElementById("global_discount")){
                    document.getElementById("global_discount").focus();
                }
            }else if(key_code===80){
                if(document.getElementById("mode_of_payment")){
                    let pmode = document.getElementById("mode_of_payment").value;
                    if(pmode==="1"){
                        if(document.getElementById("total-cash-amount")){
                            document.getElementById("total-cash-amount").focus();
                        }
                    }else{
                        document.getElementById("mode_of_payment").value = '1';
                        localStorage.setItem("p_mode",1);
                        localStorage.setItem('spm','payment');
                        if(document.getElementById("handleChangePaymentMode")){
                            setTimeout(function(){
                                document.getElementById("handleChangePaymentMode").click();
                                document.getElementById("handleSetModeOfPayment").click();
                                //document.getElementById("total-cash-amount").focus();
                                if(document.getElementById("moveToPayment")){
                                    document.getElementById("moveToPayment").click();
                                    }
                            },50);
                        }
                    }
                }
            }
            return false;
      }

        if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.ctrlKey && e.which===8)){

            let did = e.target.getAttribute('data-id');
            if(did===null){
               did =  e.target.getAttribute('data_id');
            }
            let item_remove = document.querySelectorAll(".item-remove");
            if(item_remove.length>1){
                for(let r=0;r<item_remove.length;r++){
                    let del = item_remove[r].id;
                    
                    if(did===del){
                        item_remove[r].click();
                        let default_focus = document.querySelectorAll(".item-tax");
                        let dflen = default_focus.length-1;
                        let tax = default_focus[dflen].value;
                      
                        default_focus[dflen].focus();
                        default_focus[dflen].select();
                        default_focus[dflen].value= tax;
                        break;
                    }
                }
            }
        }



        if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===13 && !e.ctrlKey)){
            if(document.getElementById("print_bill")){
                var element = document.getElementById('print_bill');
                let is_print = element.style.display;
                if(is_print==='block'){
                    document.getElementById("yes-print").click();
                }
            }
            

            let target = document.activeElement.getAttribute('target');
            if(target==='discount'){
                let data_id = document.activeElement.getAttribute('data-id');
                let notice_box = document.getElementById("notice_box").style.display;
                if(notice_box==='block'){
                    document.getElementById("close-notice").click();
                    setTimeout(function(){
                        document.getElementById("discount-"+data_id).focus();
                        document.getElementById("discount-"+data_id).select();
                    },100);
                }
            }
            if(target==="contact_box"){
                let ivalue = document.getElementById("customer-dropdown").value;
                if(ivalue==="" || ivalue==="0"){
                    return false;
                }
            }
            if(target==="iitem-box"){
                let ivalue = document.activeElement.value;
                let is_searching_item = document.getElementById('is_searching_item');
                if(ivalue==="" || is_searching_item!==null){
                    return false;
                }
                if(document.getElementById('iitem-type')){
                    let iitem_type = document.getElementById('iitem-type').value;
                    if(iitem_type==='product'){
                        let item_name = document.getElementById("iitem-name").value;
                        if(item_name==="" || item_name==="0"){
                            return false;
                        }
                    }
                }
            }
            if(target==='item-total-txt' ){
                let did = document.activeElement.getAttribute('data-id');
                let item_val = document.getElementById("item-box-"+did).value;
                let item_n = document.getElementById("item-name-"+did).value;
                if(item_val=="" || item_n==""){
                    /*document.getElementById("billing-notes").focus();
                    document.getElementById("billing-notes").select();*/
                    let item_remove = document.querySelectorAll(".item-remove");
                    if(item_remove.length>1){
                        for(let r=0;r<item_remove.length;r++){
                            let del = item_remove[r].id;
                            if(did===del){
                                item_remove[r].click();
                                break;
                            }
                        }
                    }
                }
            }
            if(target==='staff'){
                if(this.state.selected_staff_while_billing===true){
                    if(document.getElementById("staff-dropdown")){
                        let staff = document.getElementById("staff-dropdown").value;
                        if(staff===""){
                            document.getElementById("staff-dropdown").focus();
                            return false;
                        }
                    }
                }
            }
            this.moveCusor('next');
        }else if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===27 && !e.ctrlKey)){

            var element = document.getElementById('print_bill');
            let is_print = element.style.display;

            let is_inventory_popup = document.querySelectorAll(".option-active");

            if(is_print==='block'){
                setTimeout(function(){
                    document.getElementById("no-print").click();
                },100);
            }else if(is_inventory_popup.length>0){
               // HERE CLOSING INVENOTORY POPUP IF OPEN.
            }else{
             let target = document.activeElement.getAttribute('target');
            
            if(target==='item-box' ){
                let did = document.activeElement.getAttribute('data_id');
                let item_val = document.getElementById("item-box-"+did).value;
                let item_n = document.getElementById("item-name-"+did).value;
                if(item_val=="" || item_n==""){
                    let item_remove = document.querySelectorAll(".item-remove");
                    if(item_remove.length>1){
                        for(let r=0;r<item_remove.length;r++){
                            let del = item_remove[r].id;
                            if(did===del){
                                let prev = parseInt(did)-1;
                                document.getElementById("item-total-txt-"+prev).focus();
                                document.getElementById("item-total-txt-"+prev).select();
                                item_remove[r].click();
                            }
                        }
                    }
                }
            }
            this.moveCusor('prev');
        }
        }
       
        if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===77 && e.ctrlKey)){
            document.getElementById("billing-notes").focus();
            document.getElementById("billing-notes").select();
        }

        
        if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===13 && e.ctrlKey)){

            if(active_tab==='create-quotation'){
                document.getElementById("save").click();
            }else if(active_tab==='new-billing'){
                if(document.getElementById("bill-payment-popup")){
                    if(document.getElementById("bill-payment-popup").style){
                        let is_pay = document.getElementById("bill-payment-popup").style.display;
                        if(is_pay==='block'){
                            if(document.getElementById("handleSavePayment")){
                                document.getElementById("handleSavePayment").click();
                            }
                        }else{
                            
                            document.getElementById("save").click();
                        }
                    }else{
                        
                        document.getElementById("save").click();
                    }
                }else{
                    
                    document.getElementById("save").click();
                }
            }

        }else if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===80 && e.ctrlKey && e.altKey)){
            
            if(document.getElementById("make-payment")){
                let phref = document.getElementById("make-payment").getAttribute('href');
                window.location.href = phref;
            }else{
                document.getElementById("paid").click();
            }
        }

        if((active_tab==='new-billing' || active_tab==='create-quotation') && (e.which===67 && e.ctrlKey && e.altKey)){
            document.getElementById("custom").click();
        }
        /*let current_id = document.activeElement.id;
        if(active_tab==='new-billing' && e.which===13 && current_id==='billing-notes'){
            document.getElementById("save").click();
        }*/
    }
    handleContactsShortcut(e){
      let li = document.querySelectorAll(".nav-pills li");
      for(let i=0;i<li.length;i++){
        let key = li[i].getAttribute('data-id');
        this.tabsKey.push(key);
      }
      let active_tab = this.state.active_tab;
      let target = document.activeElement.getAttribute('target');
      if (e.altKey && e.ctrlKey && !e.shiftKey ) {
            let ks = document.querySelectorAll(".key-shortcut");
            for(let i=0;i<ks.length;i++){
                ks[i].classList.remove('hide');
            }
            //}else if(active_tab==='billings' && e.which===78){
            if(active_tab==='contacts'){
                if(e.which!==78){
                    
                    for(let j=0;j<this.tabsKey.length;j++){
                        let index = this.tabsKey[j];
                        let key = 49+j;
                        if(e.which === key ){
                            if(key===49){
                                window.location.href = '/contacts';
                            }else{
                                if(index!==null){
                                    window.location.href = '/contacts/'+index;
                                }
                            }
                        }
                    }
                }
                if(e.which===78){
                    window.location.href = '/add-contact';
                }
            }
        }

        if((active_tab==='add_contact' || active_tab==='edit_contact') && (e.which===13 && !e.ctrlKey)){
            console.log(e);
            let tabindex = parseInt(e.target.getAttribute('tabindex'));
            console.log(tabindex);
            this.moveCusor('next',tabindex);
            
        }else if((active_tab==='add_contact' || active_tab==='edit_contact') && (e.which===27 && !e.ctrlKey)){
            
            let tabindex = parseInt(e.target.getAttribute('tabindex'));
            this.moveCusor('prev',tabindex);
        }

        if((active_tab==='add_contact') && (e.which===13 && e.ctrlKey)){
            document.getElementById("add-contact").click();
        }
        if((active_tab==='edit_contact') && (e.which===13 && e.ctrlKey)){
            document.getElementById("update-contact").click();
        }
    }
    
    moveCusor(type,tabindex=''){
        
        let target = document.activeElement.getAttribute('target');
        if(tabindex===''){
         tabindex = parseInt(document.activeElement.getAttribute('tabindex'));

        }
        let qs = document.querySelectorAll("[tabindex]");
        let is_add_variant = '';
        if(document.getElementById("product-variation-add")){
            is_add_variant = document.getElementById("product-variation-add").style.display;
        }
        for(let i=0;i<qs.length;i++){
            let tindex = parseInt(qs[i].getAttribute('tabindex'));
            let textField = qs[i];
            let prevField = textField;
            let prevID = prevField.id;
            if(tindex!==null){
                if(tindex==tabindex){
                    if(type==='next'){
                        textField = qs[i+1];
                        /* if('iitem-alias-barcode' === prevID){
                            let cat_field = document.getElementById("tags-value-category").value;
                            if(cat_field!==""){
                                textField =  document.getElementById("iitem-total-txt");
                            }
                        } */
                        let selected_item_name = '';
                        if(textField!==undefined){
                            selected_item_name = textField.id;
                        }
                        if((textField===undefined && is_add_variant!=='block') || (selected_item_name==='selected_item_name' && is_add_variant!=='block')){
                            if(document.getElementById("add-item-to-list")){
                                document.getElementById("add-item-to-list").click();

                                if(selected_item_name==='selected_item_name'){
                                    setTimeout(() => {
                                        document.getElementById("tag-box-1").focus();
                                    }, 100);
                                }

                            }else{
                                if(document.getElementById("more")){
                                    document.getElementById("more").click();
                                }
                            }
                        }
                        if(textField===undefined && is_add_variant==='block'){
                            if(document.getElementById("create-variation-btn")){
                                document.getElementById("create-variation-btn").click();
                            }
                        }
                    }else if(type==='prev'){
                        textField = qs[i-1];
                        if(target==='item-box' || target==='contact_box'){
                            let trigger = document.querySelectorAll(".auto-complete");
                            for(let j=0;j<trigger.length;j++){
                                trigger[j].click();
                            }
                        }
                    }
                    try{
                        textField.focus();
                        textField.select();
                    }catch{}
                    break;
                }
            }
        }
    }

    handlePaymentsShortcut(e){
        let curl = window.location.href;
        const payments = curl.match(/payments$/i);
        const payments_type = curl.match(/payments\/([a-z_A-Z]+$)/i);
        const invoice_for = curl.match(/new-payment\/([a-z_A-Z]+)\/(\d+$)/i);
        const in_or_out = curl.match(/new-payment\/([all|in|out]+$)/i);
        const edit_payment = curl.match(/new-payment\/([edit]+)\/(\d+$)/i);
        const multiple_payment = curl.match(/new-payment\/multiple\/(.*)/i);

        let active_tab = '';
        let active_id = '';
        if(payments){
            active_tab = 'payments';
        }else if(payments_type){
            active_tab = payments_type[1];
        }else if(invoice_for){
            active_tab = 'new-payment';
            active_id = invoice_for[2];
        }else if(in_or_out  || multiple_payment || edit_payment){
            active_tab = 'new-payment';
        }
       
        if (e.altKey && e.ctrlKey) {
            let ks = document.querySelectorAll(".key-shortcut");
            for(let i=0;i<ks.length;i++){
                ks[i].classList.remove('hide');
            }
            if((active_tab==='payments' || active_tab==='in' || active_tab==='out') && e.which === 49 ){
                window.location.href = '/payments';
            }else if((active_tab==='payments' || active_tab==='in' || active_tab==='out') && e.which === 50){
                window.location.href = '/payments/in';
            }else if((active_tab==='payments' || active_tab==='in' || active_tab==='out') && e.which === 51){
                window.location.href = '/payments/out';
            }else if(active_tab==='payments' && e.which===78){
                window.location.href = '/new-payment/all';
            }else if(active_tab==='in' && e.which===78){
                window.location.href = '/new-payment/in';
            }else if(active_tab==='out' && e.which===78){
                window.location.href = '/new-payment/out';
            }else if(active_tab==='new-payment' && e.which===49){
                window.location.href = '/new-payment/in';
            }else if(active_tab==='new-payment' && e.which===50){
                window.location.href = '/new-payment/out';
            }
        }

        let key_code = e.which;
        if(active_tab==='new-payment' && (e.ctrlKey && (key_code===67))){
            e.preventDefault();
            if(key_code===67){
                if(document.getElementById("contact_box")){
                    document.getElementById("contact_box").focus();
                }
            }
        }

        /* if(active_tab==='new-payment' && (e.which===38 || w.which===40)){
            let pqs = document.querySelectorAll(".item-selected");
            if(pqs.length>0){
                pqs[0].classList.remove('item-selected');
                let pqs_idx = pqs[0].getAttribute('index');
                let prev_idx = parseInt(pqs_idx)-1;
                let nex_idx = parseInt(pqs_idx)+1;
                if(w.which===38){
                    document.querySelector('[data-targe="pending-bills-'+prev_idx+'"]').
                }else if(w.which===40){

                }
            }
        } */

        if(active_tab==='new-payment' && (e.ctrlKey && e.which===8)){

            let did = e.target.getAttribute('data-id');
            if(did===null){
               did =  e.target.getAttribute('data_id');
            }
            let item_remove = document.querySelectorAll(".item-remove");
            if(item_remove.length>1){
                for(let r=0;r<item_remove.length;r++){
                    let del = item_remove[r].id;
                    
                    if(did===del){
                        item_remove[r].click();
                        let default_focus = document.querySelectorAll(".item-tax");
                        let dflen = default_focus.length-1;
                        let tax = default_focus[dflen].value;
                      
                        default_focus[dflen].focus();
                        default_focus[dflen].select();
                        default_focus[dflen].value= tax;
                        break;
                    }
                }
            }
        }
        if(active_tab==='new-payment' && (e.which===13 && !e.ctrlKey)){
            let target = document.activeElement.getAttribute('target');
            /* if(target==='billwise'){
                if(document.activeElement.checked===true){
                    let pi_qs = document.querySelectorAll(".pb-list");
                    if(pi_qs.length>0){
                        pi_qs[0].classList.add('item-selected');
                        document.activeElement.blur();
                        return false;
                    }
                }
            } */

            if(target==='item-total'){
                let did = document.activeElement.getAttribute('data-id');
                let item_val = document.getElementById("item-box-"+did).value;
                let item_n = document.getElementById("item-name-"+did).value;
                if(item_val=="" || item_n==""){
                    document.getElementById("pay-notes").focus();
                    document.getElementById("pay-notes").select();
                    let item_remove = document.querySelectorAll(".item-remove");
                    if(item_remove.length>1){
                        for(let r=0;r<item_remove.length;r++){
                            let del = item_remove[r].id;
                            if(did===del){
                                item_remove[r].click();
                                break;
                            }
                        }
                    }
                }
            }
            this.moveCusor('next');
        }else if(active_tab==='new-payment' && (e.which===27 && !e.ctrlKey)){
            this.moveCusor('prev');
        }
       
        
        if(active_tab==='new-payment' && (e.which===77 && e.ctrlKey)){
            document.getElementById("pay-notes").focus();
            document.getElementById("pay-notes").select();
        }

        if(active_tab==='new-payment' && (e.which===13 && e.ctrlKey)){
            if(document.getElementById("save")){
                document.getElementById("save").click();
            }
        }

        if(active_tab==='new-payment' && (e.which===71 && e.ctrlKey && e.altKey)){
            document.getElementById("custom").click();
        }

    }

    handleExpenseShortcut(e){
        let curl = window.location.href;
        const new_expense = curl.match(/new-expense$/i);
        const edit_expense = curl.match(/new-expense\/edit\/(\d+$)/i);
      

        let active_tab = '';
        let active_id = '';
        if(new_expense){
            active_tab = 'new_expense';
        }else if(edit_expense){
            active_tab = 'new_expense';
        }
        if(active_tab==='new_expense' && (e.which===13 && !e.ctrlKey)){
            let target = document.activeElement.getAttribute('target');
            if(target==='expense-type'){
                document.getElementById("payment-mode").focus();
            }else{
                this.moveCusor('next');
            }
        }else if(active_tab==='new_expense' && (e.which===27 && !e.ctrlKey)){
            this.moveCusor('prev');
        }
        if(active_tab==='new_expense' && (e.which===77 && e.ctrlKey)){
           /* document.getElementById("pay-notes").focus();
            document.getElementById("pay-notes").select();*/
        }

        if(active_tab==='new-payment' && (e.which===13 && e.ctrlKey)){
            document.getElementById("save").click();
        }
    }
    hanldeTransportShortcut =(e) =>{
        let curl = window.location.href;
        const transport = curl.match(/transports$/i);
        if(transport){
            let active_tab = 'transport';
            if(active_tab==='transport' && (e.which===13 && !e.ctrlKey)){
                let target = document.activeElement.getAttribute('target');
                if(target==='tr-status'){
                    document.getElementById("save-transport").click();
                }else{
                    this.moveCusor('next');
                }
            }else if(active_tab==='transport' && (e.which===27 && !e.ctrlKey)){
                this.moveCusor('prev');
            }
        }
    }
    hanldeStockCalculatorShortcut =(e) =>{
        let curl = window.location.href;
        const stock_calculator = curl.match(/stock-calculator$/i);
        if(stock_calculator){
            if((e.which===13 && !e.ctrlKey)){
                let thisinput = document.getElementById("scan-inventory").value;
                if(thisinput!==''){
                    let target = document.activeElement.getAttribute('target');
                    if(target==='scan-inventory'){
                        let qs = document.querySelectorAll("#unscanned-list .active");
                        if(qs[0]!==undefined){
                            qs[0].click();
                        }
                    }
                    if(target==='scan-quantity'){
                        document.getElementById("scan-btn").click();
                    }else{
                        
                        document.getElementById("scan-quantity").value = 1  ;
                        this.moveCusor('next');
                    }
                }
            }else if((e.which===27 && !e.ctrlKey)){
                this.moveCusor('prev');
            }
        }
    }
   

    render() {
        return (
            <div></div>
        );
    }
}