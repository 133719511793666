import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/dancing-script";
import {QRCodeSVG} from 'qrcode.react';
import utils from '../utils.js';
import UPIQrCode from "./upi-qr-code.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class CustomPrintPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dispatched_info : [],
            item_page_count : 1,
        };
      
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }   

    componentDidMount(){
        let total_page = this.props.print_data.desing_data.number_of_items.length;
        let item_page_count = Math.ceil(this.props.print_data.list_item_rows.length/total_page);
        this.setState({item_page_count:item_page_count});
    }
    

    render() {
        let opening_balance = [];
        let opening_quantity = [];
        let calculate_grand_total =0;
        let total_quantity = 0;
        return (
            <>
             {Array.from(Array(this.state.item_page_count), (e, i) => {
                    let previous_ad = 0;
                    let page = i+1;
                    let items_per_page = this.props.print_data.desing_data.number_of_items.length;
                    let offset = (page - 1) * items_per_page;

                    let start_loop = offset;
                    let end_loop = (i * items_per_page)+1;
                    let calculate_total = 0;
                    return(
                    <div className="page-break" style={{margin:'0 auto',height:this.props.print_data.desing_data.page_height+'cm',width:this.props.print_data.desing_data.page_width+'cm',border:'none'}}>
                    {(this.props.print_data.desing_data.page_header_height!==undefined && this.props.print_data.desing_data.page_header_height!=="") &&
                        <div style={{position:'relative',height:this.props.print_data.desing_data.page_header_height+'cm',border:'none',marginTop:this.props.print_data.desing_data.page_header_margin_top+'cm',marginRight:this.props.print_data.desing_data.page_header_margin_right+'cm',marginBottom:this.props.print_data.desing_data.page_header_margin_bottom+'cm',marginLeft:this.props.print_data.desing_data.page_header_margin_left+'cm'}}>
                            {(this.props.print_data.desing_data.draggable_header.map((value,index)=>{
                                return(
                                    <div style={{position:'absolute',transform:'translate('+value.position.x+'px,'+value.position.y+'px)'}}>
                                        <div className="handle">
                                                {(value.key=='bno') &&
                                                    <label style={{fontSize:'12px'}}>
                                                        {this.props.print_data.currrent_sequence}
                                                    </label>
                                                }
                                                {(value.key=='contact') &&
                                                    <label style={{fontSize:'12px'}}>
                                                        {this.props.print_data.contact_info!=''?this.props.print_data.contact_info.item_name:''}
                                                    </label>
                                                }
                                                {(value.key=='date') &&
                                                    <label style={{fontSize:'12px'}}>
                                                        {this.props.print_data.billingDate.split(" ")[0]}
                                                    </label>
                                                }
                                                {(value.key=='sales_man') &&
                                                <>
                                                    {(this.props.print_data.assisted_by_txt!==undefined && this.props.print_data.assisted_by_txt!=="") &&
                                                        <label style={{fontSize:'12px'}}>
                                                           {this.props.print_data.assisted_by_txt}
                                                        </label>
                                                    }
                                                    {(this.props.print_data.assisted_by_txt===undefined || this.props.print_data.assisted_by_txt==="") &&
                                                        <label style={{fontSize:'12px'}}>
                                                            {this.props.print_data.assisted_by}
                                                        </label>
                                                    }
                                                </>
                                                }
                                        </div>
                                    </div>
                                )
                            }))}
                        </div>
                    }
                    {(this.props.print_data.desing_data.particulars_height!==undefined && this.props.print_data.desing_data.particulars_height!=="") &&
                        <div style={{height:this.props.print_data.desing_data.particulars_height+'cm',border:'none',marginTop:this.props.print_data.desing_data.particulars_margin_top+'cm',marginRight:this.props.print_data.desing_data.particulars_margin_right+'cm',marginBottom:this.props.print_data.desing_data.particulars_margin_bottom+'cm',marginLeft:this.props.print_data.desing_data.particulars_margin_left+'cm'}}>

                        {(this.props.print_data.desing_data.particulars_header!==undefined && this.props.print_data.desing_data.particulars_header.length>0) &&
                            <table className="print-template-table">
                                <thead>
                                <tr>
                                {(this.props.print_data.desing_data.particulars_header.map((value,index)=>{
                                return(
                                    <>
                                    {(value.is_checked===true) &&
                                    <th style={{textAlign:'center',color:'#fff !important',width:(value.key==='price' || value.key==='total')?'1.6cm':''}}>
                                        <span style={{visibility:'hidden'}}>{value.key}</span>
                                    </th>
                                    }
                                    </>
                                )
                                }))}
                                </tr>
                                </thead>
                                <tbody>
                                    {Array.from(Array(items_per_page), (ce, ci) => {

                                        let current_index = (end_loop+ci)-1;
                                        let list_item_rows = [];
                                        if(this.props.print_data.list_item_rows[current_index]!==undefined){
                                            list_item_rows = this.props.print_data.list_item_rows[current_index];
                                        }
                                        calculate_total = (list_item_rows.total!==undefined)?parseFloat(list_item_rows.total)+calculate_total:calculate_total+0;

                                        total_quantity = (list_item_rows.quantity!==undefined)?parseFloat(list_item_rows.quantity)+total_quantity:total_quantity+0;

                                        calculate_grand_total = (list_item_rows.total!==undefined)?parseFloat(list_item_rows.total)+calculate_grand_total:calculate_grand_total+0;;

                                        opening_balance[i] = calculate_total;
                                        opening_quantity[i] = total_quantity;
                                        return (
                                            <tr>
                                            {(this.props.print_data.desing_data.particulars_header.map((value,index)=>{
                                            return(
                                                <>
                                                {(value.key==='sno') &&
                                                    <td style={{textAlign:'center',fontSize:'13px',height:'25px'}}>{end_loop+ci}</td>
                                                }
                                                {(value.key==='qty') &&
                                                    <td style={{textAlign: 'center',padding:'0px 5px',fontSize:'12px',height:'25px'}}>
                                                        {(list_item_rows.quantity!==undefined)?list_item_rows.quantity:''}
                                                    </td>
                                                }
                                                {(value.key==='particulars') &&
                                                    <td style={{textAlign: 'left',padding:'0px',fontSize:'12px',height:'25px'}}>
                                                        {list_item_rows.item_name} {(list_item_rows.item_commission!=="" && parseFloat(list_item_rows.item_commission)>0) &&
                                                            <span style={{fontSize:'6px',border:'1px solid #333',padding:'1px 2px'}}>{list_item_rows.item_commission}</span>
                                                        }
                                                    </td>
                                                }
                                                {(value.key==='hsn') &&
                                                    <td style={{textAlign: 'left',padding:'0px',fontSize:'12px',height:'25px'}}>
                                                        {(list_item_rows.hsn!==undefined)?list_item_rows.hsn:''}
                                                    </td>
                                                }
                                                {(value.key==='price') &&
                                                    <td style={{textAlign: 'center',padding:'0px',fontSize:'12px',width:'1.6cm',height:'25px'}}>
                                                        {(list_item_rows.price!==undefined)?list_item_rows.price.split(".00")[0]:''}
                                                    </td>
                                                }
                                                {(value.key==='total') &&
                                                    <td style={{textAlign: 'center',padding:'0px',fontSize:'12px',width:'1.6cm',height:'25px'}}>
                                                        {(list_item_rows.total!==undefined)?list_item_rows.total.split(".00")[0]:''}
                                                    </td>
                                                }
                                                </>
                                            )
                                            }))}
                                            </tr>

                                        );
                                    })}
                                </tbody>
                                {(this.props.print_data.desing_data.number_of_items!==undefined && Array.isArray(this.props.print_data.desing_data.number_of_items) && this.props.print_data.desing_data.number_of_items.length>0) &&
                                    <tfoot>
                                        <tr>
                                            {(this.props.print_data.desing_data.particulars_header.map((value,index)=>{
                                                return(
                                                <>
                                                {(this.state.item_page_count===page) &&
                                                    <th style={{textAlign:'center',fontSize:'14px'}}>
                                                    {(value.key==='total')?calculate_grand_total:''}
                                                    </th>
                                                }
                                                {(this.state.item_page_count!==page) &&
                                                    <th style={{textAlign:'center',fontSize:'12px'}}>
                                                    {(value.key==='total')?'AT LAST':''}
                                                    </th>
                                                }
                                                </>
                                                )
                                            }))}
                                        </tr>
                                    </tfoot>
                                    }
                            </table>
                        }
                        {(this.state.item_page_count===page) &&
                        <div style={{width:'73px',float:'right'}}>
                            <p style={{margin:'0px',fontSize:'11px',textTransform:'capitalize'}}>
                                {this.props.print_data.billing_data.payments_comma_sep.split(",")[0]}
                            </p>
                            {(this.props.print_data.billing_data.payments_comma_sep.split(",")[1]) &&
                            <p style={{margin:'0px',fontSize:'11px',textTransform:'capitalize'}}>
                                {this.props.print_data.billing_data.payments_comma_sep.split(",")[1]}
                            </p>
                            }
                            {(this.props.print_data.billing_data.payments_comma_sep.split(",")[3]) &&
                            <p style={{margin:'0px',fontSize:'11px',textTransform:'capitalize'}}>
                                {this.props.print_data.billing_data.payments_comma_sep.split(",")[3]}
                            </p>
                            }
                            {(this.props.print_data.billing_data.payments_comma_sep.split(",")[4]) &&
                            <p style={{margin:'0px',fontSize:'11px',textTransform:'capitalize'}}>
                                {this.props.print_data.billing_data.payments_comma_sep.split(",")[4]}
                            </p>
                            }
                        </div>
                        }
                        </div>
                    }
                    </div>
                    )
            })}
            </>
        );
    }
}