import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import ContactsDropdown from "./contacts-dropdown";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faCalculator,faShoppingCart, faPlusCircle,faCheckCircle, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BillingItemList from './billing-item-list';
import ClosedFYButton from "./closed-fy-button";
import ModalBox from "./modal-box";
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import encrypt_decrypt from './encrypt-decrypt.js';
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import utils from '../utils.js';
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import AddQuotationItem from "./add-quotation-item";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}

let _this;
export default class CreateQuotation extends Component {
    constructor() {
        super();
        this.state = {
            response_id : 0,
            save_button:'Create Quotation',
            send_type : 'save',
            update_id : 0,
            more_rows : '',
            billing_type : 'New Sale',
            payment_mode : 'payment',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  new Date(),
            invoiceDate:  new Date(),
            contact_info : '',
            billing_data : '',
            target : 'sale',
            round_off_opt : true,
            round_off_opt2 : false,
            discount_opt : true,
            discount_opt2 : false,
            round_off : 0,
            round_off_sym :'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            on_load : true,
            lowest_selling_price_percent : '',            
            referer : 1, 
            staff_options:[], 
            agent_options:[], 
            assisted_by : '',  
            agent_name : '',  
            add_item_area :'',  
            is_reedeem:false,   
            show_toast:false,  
            created_variations : [], 
            credit_bill_general_contact : false,
            default_inventory_status : 'Draft',
            payment_modes:[],
            isMobile : false,
            redeem_error : '',
            note_type : '',
            is_resume_cancelled : false,
            buffer_amount_for_credit_limit : '',
            is_item_return : false,
            show_hold_button : true,
            contact_error : '',
            on_account_error : '',
            is_on_account_return : false,
            pending_bills : [],
            return_goods_bills : [],
            pending_loader:'No record(s) found.',
            is_direct_pay : false,
            customer_advance_payment : 0,
            show_loader:true,
            whatsapp_phone:'',
            whatsapp_msg : '',
            advance_sale_data : false,
            dispatched_info : '',
            is_fy_closed : false,
            change_pay_to_save : false,	
            is_block_visible : false,	
            return_type : '',	
            return_option : '',	
            is_contact_selected : false,	
            is_return_bill_search : false,	
            returned_invoice_id : 0,	
            searched_invoice_items : [],	
            inv_active:0,	
            check_global_disc : 0,
            global_discount_rs : 0,
            global_discount_per : 0,
            barcode_name_on_bill_view : false,
            item_name_on_bill_view : false,
           
        };
        _this = this;
        this.search_list_items = [];
        this.timeout = null;
        this.more_rows = [];
        this.add_item_area = [];
        this.variant_data = [];
        this.types = {sales:"New Sale"};
        this.sequence = [];
        this.send_data_items = [];
        this.send_return_data_items = [];
        this.saveBilling = this.saveBilling.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        
        this.handleRoundOff = this.handleRoundOff.bind(this);
        this.handleRoundOffChange = this.handleRoundOffChange.bind(this);
        this.handleGlobalDiscount = this.handleGlobalDiscount.bind(this);
        this.handleGlobalDiscountChange = this.handleGlobalDiscountChange.bind(this);
        this.handleChangeStaff = this.handleChangeStaff.bind(this);
        this.handleChangeAgent = this.handleChangeAgent.bind(this);
        this.addMoreItem = this.addMoreItem.bind(this);
        this.handleCreateContact = this.handleCreateContact.bind(this);
        
        this.IsJsonString = this.IsJsonString.bind(this);
        
        this.handlePrint = this.handlePrint.bind(this);
        
        
        this.is_access = check_access.is_access.bind(this);
        this.decrypt_sanitize = encrypt_decrypt.decrypt_sanitize.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.isMobile = utils.isMobile.bind(this);
        this.CHECK_CREDIT_LIMIT = utils.CHECK_CREDIT_LIMIT.bind(this);
        this.CHECK_INTERNET_CONNECTION = utils.CHECK_INTERNET_CONNECTION.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        localStorage.removeItem('item_options');
        localStorage.removeItem('purchase_item_options');
    }
    formatDate = (value) =>{
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '/' + month + '/' + year;
    }
    formatAMPM = (date) =>{
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
    handlePrint(){
      let id = localStorage.getItem('response_id');
      this.setState({response_id:parseInt(id)});
    }

    handleCreateContact(e){
      var element = document.getElementById('add_contact');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("new_name").focus();
      let contact = document.getElementById("contact_box").value;
      if(contact!==""){
        if(isNaN(contact)){
          document.getElementById("new_name").value = contact;
        }else{
          document.getElementById("new_phone").value = contact;
        }
      }
      if(document.getElementById("contact-type-select")){
        document.getElementById("contact-type-select").value = 1;
      }
    }

    handleChangeStaff(e){
      this.setState({assisted_by:e.currentTarget.value});
    }
    handleChangeAgent(e){
      this.setState({agent_name:e.currentTarget.value});
    }

    handleRoundOffChange(e){
      let round_off = document.getElementById("round_off").value;
      let round_off_temp = document.getElementById("round_off").value;
      let round_off_opt = e.currentTarget.value;

      if(round_off_opt==='-'){
        this.setState({round_off_opt:false});
        this.setState({round_off_opt2:true});
      }else{
        this.setState({round_off_opt:true});
        this.setState({round_off_opt2:false});
      }
      this.setState({round_off_sym:round_off_opt});
      let item_total = document.getElementById("s-total").innerHTML;
      item_total = parseFloat(item_total.toString().replace(/,/g , ""));
      let i_total = parseFloat(item_total.toString().replace(/,/g , ""));
     
      if(round_off_opt==='-'){
       
          item_total = Math.floor(item_total);
          round_off = parseFloat(i_total)-parseFloat(item_total);
          if(round_off===0){	
            item_total = parseFloat(i_total)-parseFloat(round_off_temp);	
            round_off = round_off_temp;	
          }
      }else if(round_off_opt==='+'){
        
          item_total = Math.ceil(item_total);
          round_off = parseFloat(item_total)-parseFloat(i_total);
          if(round_off===0){	
            item_total = parseFloat(i_total)+parseFloat(round_off_temp);	
            round_off = round_off_temp;	
          }
      }
      document.getElementById("round_off").value = this.floating_point(round_off);
      document.getElementById("round_off_lbl").innerHTML = this.floating_point(round_off);
      
      
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }

      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }

      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
/*      if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }

     /* if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      
    }

    handleRoundOff(e){
      let round_off = e.currentTarget.value;
      let item_total = document.getElementById("s-total").innerHTML;
      item_total = parseFloat(item_total.toString().replace(/,/g , ""));
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
      if(round_off!=="" && round_off>0){
          round_off = parseFloat(round_off);
          if(round_off_opt==='-'){
              item_total = item_total-round_off;
          }else if(round_off_opt==='+'){
              item_total = item_total+round_off;
          }
      }
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }
      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }
      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }

      /*if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      
    }
    handleCheckGDiscountAmount = (e) =>{	
      let thisvalue = e.currentTarget.value;	
      if(thisvalue===""){	
        thisvalue = 0;	
      }	
      this.setState({check_global_disc:thisvalue});	
    }	

    handleGlobalDiscount(e){
      let thisid = e.currentTarget.id;
      let global_discount = document.getElementById("global_discount").value;
      
      let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
      global_discount = global_discount.trim();
      
      if(discount_opt==='per'){
        if(global_discount!==""){
          global_discount = parseFloat(global_discount);
          if(global_discount>100){
              e.currentTarget.value = 100;
              global_discount = 100;
          }
        }
      }
      if(global_discount===""){
        global_discount = 0;
      }else{
        global_discount = parseFloat(global_discount);
      }
      if(thisid!=='global_discount'){
        if(discount_opt==='per'){
          if(this.state.global_discount_per>0){
            global_discount = this.state.global_discount_per;
            this.setState({global_discount:global_discount});
            document.getElementById("global_discount").value = global_discount;
          }
        }else if(discount_opt==='rs'){
          if(this.state.global_discount_rs>0){
            global_discount = this.state.global_discount_rs;
            this.setState({global_discount:global_discount});
            document.getElementById("global_discount").value = global_discount;
          }
        }
      }
      
      var item_final_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 
      var items_discount = document.querySelectorAll('.item-discount-hidden'); 
      var item_gst = document.querySelectorAll('.item-gst'); 
      let item_total = 0;
      let total_unit = 0;
      let gst_arr = [];
      let total_gst = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
        if(item_final_total[i].value!=='' && item_final_total[i].value!=="0" && item_final_total[i].value!==0){
          let did = item_final_total[i].getAttribute('data-id');
          let is_t_inc = "";
           if(document.getElementById("item-tax-inc-"+did)){
             is_t_inc = document.getElementById("item-tax-inc-"+did).value;
           }else if(document.getElementById("sp-item-tax-inc-"+did)){
             is_t_inc = document.getElementById("sp-item-tax-inc-"+did).value;
           }
           let is_i_gst = "";
           if(document.getElementById("item-gst-"+did)){
             is_i_gst = document.getElementById("item-gst-"+did).value;
           }else if(document.getElementById("item-sp-gst-"+did)){
             is_i_gst = document.getElementById("item-sp-gst-"+did).value;
           }
          total_unit++;
          let disc = (items_discount[i]!==undefined && items_discount[i].value!=="")?parseFloat(items_discount[i].value):0;
          let quan = item_quantity[i].value;
          quan = (quan==='')?0:parseInt(quan);
          let itot = parseFloat(item_final_total[i].value.replace(/,/g , ""));

          if(is_t_inc==='including' && is_i_gst!=="" && is_i_gst!=="0"){
            disc = 0;
          }

          let total_amount = (quan*itot)-disc;
        
          item_total = item_total + (quan*itot)-disc;
          if(discount_opt==="per"){
            let i_gst = parseFloat(item_gst[i].value);
            if(i_gst>0){
              let dis = total_amount*global_discount/100;
              total_amount = total_amount-dis;
              total_gst = total_gst+(total_amount*i_gst)/100;
            }
          }else if(discount_opt==="rs"){
            let sub_total = parseFloat(document.getElementById("sub-total").innerHTML.replace(/,/g , ""));
           
            let gd = global_discount/sub_total*100;
            
            let i_gst = parseFloat(item_gst[i].value);
            if(i_gst>0){
              let dis = total_amount*gd/100;
              total_amount = total_amount-dis;
              total_gst = total_gst+(total_amount*i_gst)/100;
            }
          }
        }
      }
      if(discount_opt==="rs"){
        let sub_total = parseFloat(document.getElementById("sub-total").innerHTML.replace(/,/g , ""));
         
          if(global_discount>sub_total){
            e.currentTarget.value = this.state.check_global_disc;	
            global_discount = this.state.check_global_disc;
          }
      }
    
      //if(discount_opt==="per"){
        

        let total_igst = document.getElementById("total-igst").innerHTML;
        let total_sgst = document.getElementById("total-sgst").innerHTML;
        let total_cgst = document.getElementById("total-cgst").innerHTML;

        if(total_igst!=="" && total_igst!=="0"){
          document.getElementById("total-igst").innerHTML = this.currency_format(total_gst);
        }else if(total_sgst!=="" && total_sgst!=="0" && total_sgst!=="" && total_sgst!=="0"){
          total_gst = this.floating_point(total_gst);
          let csgst = total_gst/2;
          csgst = this.floating_point(csgst);
          total_gst = csgst+csgst;
          document.getElementById("total-sgst").innerHTML = this.currency_format(csgst);
          document.getElementById("total-cgst").innerHTML = this.currency_format(csgst);
        }
        document.getElementById("total-after-tax").innerHTML = this.currency_format(total_gst);
        document.getElementById("total-tax").innerHTML = this.currency_format(total_gst);
      //}

      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
     
      let cont_disc = localStorage.getItem('discount');

       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }
       

       let gdisc = global_discount;
       
       if(discount_opt==="rs"){
          if(e.currentTarget.id==='global_discount'){
              this.setState({global_discount_rs:gdisc});
              let gper = (global_discount/item_total)*100;
              this.setState({global_discount_per:gper});
          }
          item_total = parseFloat(item_total)-global_discount;
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';

       }else if(discount_opt==="per"){
         gdisc = (parseFloat(item_total)*global_discount)/100;
         gdisc = this.floating_point(gdisc);
         item_total = parseFloat(item_total)-gdisc;
         document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
         document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
         if(e.currentTarget.id==='global_discount'){
           this.setState({global_discount_per:global_discount});
           this.setState({global_discount_rs:gdisc});
         }
       }
       document.getElementById("total-before-tax").innerHTML = this.currency_format(item_total);
       let item_tax=document.getElementById("total-tax").innerHTML;
       item_tax=parseFloat(item_tax.toString().replace(/,/g , ""));
       let total_after_tax = item_total+item_tax;
       item_total = item_total+item_tax;
       document.getElementById("s-total").innerHTML = this.currency_format(total_after_tax);

       let after_round = Math.round(item_total)-item_total;
      
      if(after_round>=0){
        after_round = Math.ceil(item_total)-item_total;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = item_total-Math.floor(item_total);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      if(round_off!=="" && round_off>0){
          item_total = parseFloat(item_total.toString().replace(/,/g , ""));
          if(round_off_opt==='-'){

              item_total = item_total-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = item_total+parseFloat(round_off);
          }
      }

      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }
      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.state.billing_type==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.state.billing_type==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }
      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = this.floating_point(item_total);
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/

      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      document.getElementById("global_discount_lbl").innerHTML = global_discount;
      

      /*if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }

      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
    }
  
    handleGlobalDiscountChange(e){
      
      let global_discount = document.getElementById("global_discount").value;
      if(global_discount==='rs'){
        this.setState({discount_opt:false});
        this.setState({discount_opt2:true});
      }else{
        this.setState({discount_opt:true});
        this.setState({discount_opt2:false});
      }
      var item_final_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
        if(item_final_total[i].value!=='' && item_final_total[i].value!=="0" && item_final_total[i].value!==0){
          let ftotal = item_final_total[i].value.replace(/,/g , "");
          let quan = item_quantity[i].value;
          quan = (quan==='')?0:parseInt(quan);
          item_total = item_total + (quan*parseFloat(ftotal));
        }
      } 
      
      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
     
      let item_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           item_discount = item_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
      } 

      item_total = (item_total)-item_discount;


       let cont_disc = localStorage.getItem('discount');

       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseFloat(cont_disc);
       }

       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;
       if(discount_opt==="rs"){
          let gdisc = (parseFloat(item_total)*cont_disc)/100;
          document.getElementById("global_discount").value = gdisc;
          document.getElementById("global_discount_lbl").innerHTML = gdisc;
          item_total = parseFloat(item_total)-gdisc;
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
       }else if(discount_opt==="per"){
          let gdisc = (parseFloat(item_total)*cont_disc)/100;
          item_total = parseFloat(item_total)-gdisc;
          gdisc = this.floating_point(gdisc);
          document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("global_discount").value = cont_disc;
          document.getElementById("global_discount_lbl").innerHTML = cont_disc;
       }
       this.handleGlobalDiscount(e);
    }

    
   
      billingDate = date => {
        this.setState({
          billingDate: date
        });
      }; 
      invoiceDate = date => {
        this.setState({
          invoiceDate: date
        });
      };
      handleChangeEvent(e){
      
     
        let key_code = e.which;
        
        
        if(e.ctrlKey && key_code===46){
            if(this.state.update_id===0){

              if(document.getElementById("contact_box")){
                document.getElementById("contact_box").value = '';
              }

              if(document.getElementById("customer-dropdown")){
                document.getElementById("customer-dropdown").value = '';
              }

              if(document.getElementById("old-customer-dropdown")){
                document.getElementById("old-customer-dropdown").value = '';
              }

              if(document.getElementById("staff-dropdown")){
                document.getElementById("staff-dropdown").value = '';
                this.setState({assisted_by:''});
              }

              if(document.getElementById("previous_balance_txt")){
                document.getElementById("cont-old-balance").classList.add('hide');
                document.getElementById("previous_balance_txt").innerHTML = 0;
              }

              if(document.getElementById("credit_limit_txt")){
                document.getElementById("cont-credit-limit").classList.add('hide');
                document.getElementById("credit_limit_txt").innerHTML = 0;
              }

              if(document.getElementById("mode_of_payment")){
                localStorage.setItem("p_mode",2);
                document.getElementById("handleSetModeOfPayment").click();
              }
              
              let payment_mode = {payment_mode:2};
              this.setState({payment_mode:'credit'});
              this.setState({billing_data:payment_mode});
              this.setState({customer_advance_payment:false});
              let list_items = document.querySelectorAll(".rm-yes");
              for(let i=0;i<list_items.length;i++){
                list_items[i].click();
              }
            }
        }

      }
    componentDidMount(){

      document.addEventListener('scroll', function(e) {
        let top = window.scrollY;
        if(top>=33){
          if(document.getElementById("another-fin-notice")){
            document.getElementById("another-fin-notice").classList.add("slide-up");
          }
        }else{
          if(document.getElementById("another-fin-notice")){
            document.getElementById("another-fin-notice").classList.remove("slide-up");
          }
        }
      });

      localStorage.removeItem('response_id');
      localStorage.removeItem('open_list_view');
      localStorage.removeItem('save_print');
      localStorage.removeItem('save_print_new');
      localStorage.removeItem('create_new_bill');
      localStorage.removeItem('open_new_bill');
      localStorage.removeItem('discount');
      var isMobile = this.isMobile();

      if(isMobile){
        this.setState({isMobile:true});
      }
      let curl = window.location.href;
      const add_billing_type = curl.match(/create-quotation\/(\d+$)/i);

      const resume_cancelled = curl.match(/create-quotation\/resume\/(\d+$)/i);

      const edit_quotaion = curl.match(/create-quotation\/([edit]+)\/(\d+$)/i);
      
     
        let general_contact_bill_settings = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            keys:"'credit_bill_general_contact','default_inventory_status','relevant_contact_type_ssr','relevant_contact_type_ppr','buffer_amount_for_credit_limit','advance_sale_data','is_financial_year_closed'"
        }
        let gc_api = app_api+'/get_settings';
        axios.post(gc_api, general_contact_bill_settings)
          .then(function (response) {
            let resp = response.data;
            if(resp.credit_bill_general_contact){
              let ps = resp.credit_bill_general_contact;
             
              this.setState({credit_bill_general_contact:ps});
            }
            if(resp.default_inventory_status){
              let ps = resp.default_inventory_status;
              this.setState({default_inventory_status:ps});
            }
            if(resp.barcode_name_on_bill_view){
              let ps = resp.barcode_name_on_bill_view;
              this.setState({barcode_name_on_bill_view:ps});
            }
            if(resp.item_name_on_bill_view){
              let ps = resp.item_name_on_bill_view;
              this.setState({item_name_on_bill_view:ps});
            }
            if(resp.advance_sale_data){
              let ps = resp.advance_sale_data;
              this.setState({advance_sale_data:ps});
            }
            if(resp.buffer_amount_for_credit_limit){
              let ps = resp.buffer_amount_for_credit_limit;
              this.setState({buffer_amount_for_credit_limit:ps});
            }
            if(resp.relevant_contact_type_ssr){
              let relevant_contact_type_ssr = resp.relevant_contact_type_ssr;
              localStorage.setItem("relevant_contact_type_ssr",relevant_contact_type_ssr);
            }else{
              localStorage.setItem("relevant_contact_type_ssr",'1');
            }
            if(resp.relevant_contact_type_ppr){
              let relevant_contact_type_ppr = resp.relevant_contact_type_ppr;
              localStorage.setItem("relevant_contact_type_ppr",relevant_contact_type_ppr);
            }else{
              localStorage.setItem("relevant_contact_type_ppr",'2');
            }
            if(resp.is_fy_closed){
              let is_fy_closed = resp.is_fy_closed;
              this.setState({is_fy_closed:is_fy_closed});
            }
        }.bind(this)).catch(function(res){
            
        }) 
      localStorage.removeItem('p_mode');
      if(user_info)  {      
        let version = localStorage.getItem('IdbVersion');
        version = parseInt(version);
        let dbReq = window.indexedDB.open('BaazarERP', version);
        dbReq.onupgradeneeded = function(event) {
          db = event.target.result;
        }
        
        dbReq.onsuccess = function(event) {
          db = event.target.result;
          try{
            let ContactList = [];  
            let ContactOptions = [];  
            let AgentOptions = [];  
            let transaction = db.transaction("contacts", "readwrite");
            let contacts = transaction.objectStore("contacts");
            let request = contacts.getAll();
            request.onsuccess = function(e) {
              let cursor =  e.target.result;
              cursor.map( (value, key) => {
                  let id = value.id;
                  let type = value.type;
                  let contact_type = value.contact_type;
                  let data = value.data;
                  let bytes = CryptoJS.AES.decrypt(data, enc_key);
                  let cval = bytes.toString(CryptoJS.enc.Utf8);
                  let con =   JSON.parse(cval);
                  let mobile = con.phone_1.toString();
                  let name = con.name;
                  if(contact_type==="Staff"){
                    ContactOptions[id]={id:value.id,name:name};
                  }
                  //if(contact_type==="Agent"){
                    AgentOptions[id]={id:value.id,name:name};
                  //}
              });
              db.close();
              _this.setState({ 'staff_options': ContactOptions }); 
              _this.setState({ 'agent_options': AgentOptions }); 
              
            }; 
          }catch{}
        }
      }


      document.addEventListener('keydown', this.handleChangeEvent);
      window.onbeforeunload = function(e) {
        if(_this.state.on_load===false){
          return false;
        }
      };

      
      if(edit_quotaion){ // EDIT MODE
          const id = edit_quotaion[2];
          const type = 'sales';
          let t = this.types[type];
          this.setState({update_id:id});
          this.setState({billing_type:t});
          this.setState({target:type});
          this.setState({send_type:'update'});
        
          this.setState({save_button:'Update Quotation',referer:1});
          
         
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              store_state : user_info.state,
              type : t,
              id : parseInt(id),
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/get_quotation';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              if(data.length===0){
                window.location.href = '/404';
              }

              this.variant_data = resp.variation_data;

              let note_type = data.cd_sequence;
              if(note_type===""){
                this.setState({note_type:'cash'});
              }
           
              _this.setState({billing_data:data, assisted_by:data.assisted_by,agent_name:data.agent_name,payment_mode:data.payment_mode_txt});
              let is_direct_pay = data.is_direct_pay;
            
 
              this.setState({show_loader:false});

              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
            
              setTimeout(() => {	
                this.setState({round_off:rooff});	
                if(document.getElementById("round_off")){	
                  document.getElementById("round_off").value = rooff;	
                }	
              }, 500);
              this.setState({round_off_sym:rooff_sym});
              
              if(rooff_sym==='-'){
                document.getElementById("minus").checked=true;
              }else{
                document.getElementById("plus").checked=true;
              }

              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
                let disc = 0;
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:this.floating_point(gamount)});
                disc = gdisc;
                localStorage.setItem('discount',gdisc);
                this.setState({global_discount_per:disc});
              }else{
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                  this.setState({global_discount_rs:disc});
                }
                let stotal = sub_total-disc;
                if(gdisc!==""){
                    disc = (gdisc/stotal)*100;
                    localStorage.setItem('discount',disc);
                }
              }

              if(gdisc_sym==='rs'){
                document.getElementById("discount-rs").checked=true;
              }else{
                document.getElementById("discount-per").checked=true;
              }

              let b_date = data.c_date;
              let billing_date = data.created_date;
              let inv_date = data.inv_date;
              let invoice_date = data.invoice_date;
              _this.setState({billingDate:new Date(b_date)});
              _this.setState({invoiceDate:new Date(inv_date)});

              let contact_info = data.contact_info;
              localStorage.setItem('contact_info',JSON.stringify(contact_info[0]));
              let credit_info = data.credit_data;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let previous_balance = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name;
                 contact_id = contact_info[0].contact_id;
                 localStorage.setItem("selected_contact",contact_id);
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 /*
                 address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                 address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                 city = this.decrypt_sanitize(contact_info[0].city);*/
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;

                 localStorage.setItem('credit_days', contact_info[0].credit_days);
                 localStorage.setItem('state', state);
                 localStorage.setItem('payment_mode', contact_info[0].payment_mode);
                 if(disc==0){
                    localStorage.setItem('discount', contact_info[0].discount);
                  }
                 /*
                 email = this.decrypt_sanitize(contact_info[0].email);
                 phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                 phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                 state = this.decrypt_sanitize(contact_info[0].state);*/
              }
              previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit
                let pending_amount = data.pending_amount
             
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
              this.setState({contact_info:c_info});

              let sequence = data.number;
              let sequence_no = data.number_sequence;
              _this.sequence[t] = sequence;
              _this.sequence[t+" No"] = sequence_no;
              _this.setState({currrent_sequence:sequence});
              _this.setState({currrent_sequence_no:sequence_no});

              let list_items = data.list_items;
              let return_data = [];
              if(data.return_data!==undefined){
                return_data =data.return_data; 
                if(return_data.length>0){
                  this.setState({is_item_return:true});
                }
              }

              this.add_item_area.push(<AddQuotationItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type} list_items={list_items} return_list_items={return_data} variation_data={resp.variation_data}/>);
              this.setState({add_item_area:this.add_item_area});

              setTimeout(function(){
                _this.ADJUST_BILL_SNO('regular');
                _this.ADJUST_BILL_SNO('return');
                document.querySelector('input[name="global_discount"]:checked').click();
              },100);
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })


      }else{
        this.setState({show_loader:false});
        this.setState({round_off_opt:true});
        this.setState({round_off_opt2:false});
        let a_tab = "New Sale";
        let thisval ="New Sale";
        if(add_billing_type!==null && add_billing_type[1]){
            this.setState({referer:add_billing_type[1]});
            if(add_billing_type[1]==="1"){
              a_tab="New Sale";
              this.setState({save_button:'Create Quotation'});
            }else if(add_billing_type[1]==="2"){
              a_tab="New Purchase";
              this.setState({save_button:'Create Purchase',payment_mode:''});
            }else if(add_billing_type[1]==="3"){
              a_tab="New Sales Return";
              this.setState({save_button:'Create Sales Return'});
            }else if(add_billing_type[1]==="4"){
              a_tab="New Purchase Return";
              this.setState({save_button:'Create Purchase Return'});
            }

            this.setState({billing_type:a_tab});
             thisval =a_tab;
            this.setState({billing_type:thisval});
        }
        //this.more_rows.push(<BillingItemList  key={1} count='1' type="more" index="1" billing_type={a_tab}/>);
        //this.setState({more_rows:this.more_rows});
        this.add_item_area.push(<AddQuotationItem key={1} count='1' type="more" index="1" billing_type={a_tab}/>);
        this.setState({add_item_area:this.add_item_area});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              target : 'quotation',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_sequence_number';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let sequence_no = resp.sequence;
             
              let current_sequence = "#QO"+sequence_no;
              this.sequence["New Sale"] = current_sequence;
            
              this.setState({currrent_sequence:current_sequence});
              this.setState({currrent_sequence_no:sequence_no});
              
              this.setState({currrent_sequence_no:this.sequence[thisval+" No"]});
              this.setState({currrent_sequence:_this.sequence[thisval]});
            
                  
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
        
        if(resume_cancelled){ // RESUME CANCELLED
            const id = resume_cancelled[1];
            const type = 'sales';
            let t = this.types[type];
            //this.setState({update_id:id});
            this.setState({billing_type:t});
            this.setState({target:type});
            this.setState({send_type:'save'});
            this.setState({is_resume_cancelled:true});
           
            this.setState({save_button:'Create Quotation',referer:1});
            
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                store_state : user_info.state,
                type : t,
                id : parseInt(id),
                action : 'resume_cancelled',
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
             }
          let api = app_api+'/billings/get_quotation';
          axios.post(api, body)
            .then(function (response) {
                let resp = response.data;
                
                let data = resp.data;
                if(data.length===0){
                  window.location.href = '/404';
                }

                this.variant_data = resp.variation_data;

                let note_type = data.cd_sequence;
                if(note_type===""){
                  this.setState({note_type:'cash'});
                }

                if(data.bill_status==='On Hold'){
                  this.setState({save_button:'Create Sale'});
                }

                this.setState({billing_data:data,assisted_by:data.assisted_by,agent_name:data.agent_name,payment_mode:data.payment_mode_txt});
                this.setState({show_loader:false});
                let rooff = data.round_off;
                let rooff_sym = data.round_off_sym;
              
                this.setState({round_off:rooff});
                this.setState({round_off_sym:rooff_sym});
                
                if(rooff_sym==='-'){
                  document.getElementById("minus").checked=true;
                }else{
                  document.getElementById("plus").checked=true;
                }

                let gdisc = data.global_discount;
                let gdisc_sym = data.global_discount_sym;
                this.setState({global_discount:gdisc});
                this.setState({discount_sym:gdisc_sym});
                
                if(gdisc_sym==='per'){
                  let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                  let disc = 0;
                  if(data.discount!==undefined){
                    if(isNaN(data.discount)){
                      disc =parseFloat(data.discount.replace(/,/g , ""));
                    }else{
                      disc =parseFloat(data.discount);
                    }
                  }
                  let stotal = sub_total-disc;
                  let gamount = stotal*gdisc/100;
                  this.setState({global_discount_amount:this.floating_point(gamount)});
                }

                if(gdisc_sym==='rs'){
                  document.getElementById("discount-rs").checked=true;
                }else{
                  document.getElementById("discount-per").checked=true;
                }

                let b_date = data.c_date;
                let billing_date = data.created_date;
                let inv_date = data.inv_date;
                let invoice_date = data.invoice_date;
                this.setState({billingDate:new Date(b_date)});
                this.setState({invoiceDate:new Date(inv_date)});

                let contact_info = data.contact_info;
                let credit_info = data.credit_data;
                let contact_name = '';
                let contact_id = '';
                let address_1 =  '';
                let address_2 = '';
                let city = '';
                let credit_limit = '';
                let previous_balance = '';
                let email = '';
                let phone_1 = '';
                let phone_2 = '';
                let state = '';
                if(Object.keys(contact_info).length > 0) {
                   contact_name = contact_info[0].name;
                   contact_id = contact_info[0].contact_id;
                   localStorage.setItem("selected_contact",contact_id);
                   address_1 = contact_info[0].address_1;
                   address_2 = contact_info[0].address_2;
                   city = contact_info[0].city;
                   /*
                   address_1 = this.decrypt_sanitize(contact_info[0].address_1);
                   address_2 = this.decrypt_sanitize(contact_info[0].address_2);
                   city = this.decrypt_sanitize(contact_info[0].city);*/
                   credit_limit = contact_info[0].credit_limit;
                   email = contact_info[0].email;
                   phone_1 = contact_info[0].phone_1;
                   phone_2 = contact_info[0].phone_2;
                   state = contact_info[0].state;

                   localStorage.setItem('credit_days', contact_info[0].credit_days);
                   localStorage.setItem('state', state);
                   localStorage.setItem('payment_mode', contact_info[0].payment_mode);
                   localStorage.setItem('discount', contact_info[0].discount);
                   /*
                   email = this.decrypt_sanitize(contact_info[0].email);
                   phone_1 = this.decrypt_sanitize(contact_info[0].phone_1);
                   phone_2 = this.decrypt_sanitize(contact_info[0].phone_2);
                   state = this.decrypt_sanitize(contact_info[0].state);*/
                }
                previous_balance = credit_info.previous_balance
                credit_limit = credit_info.credit_limit;
                let pending_amount = data.pending_amount;
               
                let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,original_credit_limit:contact_info[0].credit_limit,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,previous_balance:previous_balance};
                this.setState({contact_info:c_info});

                let sequence =this.sequence[t];
                let sequence_no = this.sequence[t+" No"];
                this.setState({currrent_sequence:sequence});
                this.setState({currrent_sequence_no:sequence_no});

                let list_items = data.list_items;
                
                this.add_item_area.push(<AddQuotationItem variations={this.state.created_variations} key={1} count='1' type="more" index="1" billing_type={this.state.billing_type} list_items={list_items} variation_data={resp.variation_data}/>);
                this.setState({add_item_area:this.add_item_area});

                setTimeout(function(){
                  _this.ADJUST_BILL_SNO('regular');
                  _this.ADJUST_BILL_SNO('return');
                  document.querySelector('input[name="global_discount"]:checked').click();
                },100);
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }




        setTimeout(function(){
        if(_this.state.billing_type==='New Sale' || _this.state.billing_type==='New Purchase'){
          if(document.getElementById("mode_of_payment")){
              let pm_element = document.getElementById("mode_of_payment");
              if(pm_element.selectedIndex>=0){
                let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                if(document.getElementById("paid")){
                  if(ptxt==='payment' || ptxt==='cash'){
                    document.getElementById("paid").classList.add('hide');
                  }else{
                    document.getElementById("paid").classList.remove('hide');
                  }
                }
              }
          }
        }
      },1500);

      }
      let PaymentMode = [];
      let pmbody = {};
        pmbody = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let pmapi = app_api+'/get_payment_mode';
        axios.post(pmapi, pmbody)
          .then(function (response) {
            let cursor = response.data;
            
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                PaymentMode[id] =  {payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay};
            });
           
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }

    addMoreItem(event){
      let type = event.currentTarget.id;
      let item_len = document.getElementsByClassName("item-name").length;
      let key = item_len+1;
      this.more_rows.push(<BillingItemList count={key} type={type} key={key} index={key} billing_type={this.state.billing_type}/>);
      this.setState({more_rows:this.more_rows});
      let rm = document.querySelectorAll(".item-remove");
      for(let i=0;i<rm.length;i++){
        rm[i].style.display = 'block';
      }
    }
    
    IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    saveBilling(event) {
        let is_online = this.CHECK_INTERNET_CONNECTION();
        if(!is_online){
          return false;
        }
        let clicked_on = event.currentTarget.id;	
        let bill_status = clicked_on;	
        if(clicked_on==='ts-save'){	
          bill_status = 'save';	
        }
        let sel_contact = document.getElementById("contact_box").value;
     
     
        let total_grand = document.getElementById("final-total").innerHTML;
      
        let inventory_status = this.state.default_inventory_status;
        this.setState({on_load:true});
        
        let click_id = bill_status;

          bill_status=1;
       
        let send_data = {};
        let customer = parseInt(document.getElementById("customer-dropdown").value);
        let selected_contact = localStorage.getItem("selected_contact");

        if((customer===null || customer==="" || customer===0 || isNaN(customer)) && (sel_contact!=="")){
            customer = selected_contact;
        }

        if(sel_contact!=="" && (customer==="" || customer==="0" || customer===null || isNaN(customer))){

            let cc_data = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                name : sel_contact,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            }
            let npcapi = app_api+'/check_contact_status';
            axios.post(npcapi, cc_data)
              .then(function (response) {
                let resp = response.data;
                let message = resp.message;
                document.getElementById("contact_box").focus();
                setTimeout(function(){
                  _this.setState({contact_error:''});
                },10000);
                this.setState({contact_error:message});
            }.bind(this)).catch(function(res){
            })

            document.getElementById("page-content-wrapper").scrollIntoView(true);
            
            return false;
        }

        let staff = 0;
        if(document.getElementById("staff-dropdown")){
          staff = parseInt(document.getElementById("staff-dropdown").value);
        }
        let agent_name = '';
        if(document.getElementById("agent-dropdown")){
          agent_name = document.getElementById("agent-dropdown").value;
        }
        let date = this.state.billingDate;
        let invoice_date = this.state.invoiceDate;
        let mode_of_payment = 1;
        let mode_of_payment_txt = 'Payment';
      
        
      
        let credit_days = localStorage.getItem("credit_days");
        let notes = document.getElementById("billing-notes").value;

        let total_unit = document.getElementById("total-unit").innerHTML;
        let total_sub = document.getElementById("sub-total").innerHTML;
        
        let total_discount = 0;
        if(document.getElementById("total-discount")){
         total_discount = document.getElementById("total-discount").innerHTML;
        }
        let round_off = document.getElementById("round_off").value;
        let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

        let global_discount = document.getElementById("global_discount").value;
        let global_discount_opt = document.querySelector('input[name="global_discount"]:checked').value;

        let total_tax = document.getElementById("total-tax").innerHTML;
        let total_igst = document.getElementById("total-igst").innerHTML;
        let total_sgst = document.getElementById("total-sgst").innerHTML;
        let total_cgst = document.getElementById("total-cgst").innerHTML;
        
        var inputs = document.querySelectorAll('.items-row'); 
      
        for (var i = 0; i < inputs.length; i++) { 
            let idx = inputs[i].getAttribute('data-id');
            let list_item_id = document.getElementById("item-list-id-"+idx).value;
            let item_text = document.getElementById("item-box-"+idx).value;
            let item_name = document.getElementById("item-name-"+idx).value;
            let old_item_name = document.getElementById("old-item-name-"+idx).value;
            let item_desc = document.getElementById("item-desc-"+idx).value;
            let item_hsn = document.getElementById("item-hsn-"+idx).value;
            let item_unit = document.getElementById("item-unit-"+idx).value;
            let item_unit_name = document.getElementById("item-unit-name-"+idx).value;
            let quantity = document.getElementById("item-quantity-"+idx).value;
            let barcode = '';
            if(document.getElementById("vitem-barcode-name-"+idx)){
              barcode = document.getElementById("vitem-barcode-name-"+idx).value;
            }
            let alias_barcode = '';
            if(document.getElementById("vitem-alias-barcode-name-"+idx)){
              alias_barcode = document.getElementById("vitem-alias-barcode-name-"+idx).value;
            }
             let category = '';
            if(document.getElementById("item-category-"+idx)){
              category = document.getElementById("item-category-"+idx).value;
            }
            

            let is_tax_inc = 'false';
            if(document.getElementById("item-tax-inc-"+idx)){
              is_tax_inc = document.getElementById("item-tax-inc-"+idx).value;
            }
            let price_hidden = 0;
            if(document.getElementById("price-hidden-"+idx)){
              price_hidden = document.getElementById("price-hidden-"+idx).value;
            }

            let purchase_price = 0;
            if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase' || this.state.billing_type==='New Purchase Return'){
              purchase_price = document.getElementById("price-"+idx).value;
            }

            let selling_price = 0;
            let sp_discount = 0 ;
            let sp_tax = 0 ;
            let sp_tax_type = 0 ;
            if(this.state.billing_type==='New Purchase'){
              selling_price = document.getElementById("item-selling-price-"+idx).value;
              sp_discount = document.getElementById("sp-discount-"+idx).value;
              if(sp_discount===""){
                sp_discount = 0;
              }
              sp_tax = document.getElementById("item-sp-gst-"+idx).value; ;
              sp_tax_type = document.getElementById("sp-item-tax-inc-"+idx).value; ;
            }
            if(this.state.billing_type==='New Sales Return'){
              selling_price = document.getElementById("price-"+idx).value;
            }
            if(quantity===""){
              quantity = 0;
            }
            let availability = quantity;
            if(this.state.billing_type==='New Sale'){
              availability = document.getElementById("item-availability-"+idx).innerHTML;
            }

            let discount = 0;
            if(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase'){
              discount = document.getElementById("discount-"+idx).value;
              if(discount===""){
                discount = 0;
              }
            }


            let gst = document.getElementById("item-gst-"+idx).value;
            let igst = document.getElementById("item-igst-"+idx).value;
            let cgst = document.getElementById("item-cgst-"+idx).value;
            let sgst = document.getElementById("item-sgst-"+idx).value;
            let total = document.getElementById("item-total-txt-"+idx).value;
            let list_item_type = '';
            if(document.getElementById("item-type-"+idx)){
              list_item_type = document.getElementById("item-type-"+idx).value;
            }
           
            this.send_data_items[i] = {
                                        item_type:list_item_type,
                                        list_item_id:list_item_id,
                                        bill_status:bill_status,
                                        item_text:item_text,
                                        item_name:item_name,
                                        old_item_name:old_item_name,
                                        item_desc:item_desc,
                                        hsn:item_hsn,
                                        quantity:quantity,
                                        product_unit_id:item_unit,
                                        item_unit_name:item_unit_name,
                                        availability:availability,
                                        purchase_price:purchase_price,
                                        selling_price:selling_price,
                                        discount:discount,
                                        igst:igst,
                                        cgst:cgst,
                                        sgst:sgst,
                                        tax:gst,
                                        total:total,
                                        price_hidden:price_hidden,
                                        is_tax_inc:is_tax_inc,
                                        sp_discount:sp_discount,
                                        sp_tax : sp_tax,
                                        sp_tax_type : sp_tax_type,
                                        inventory_status:inventory_status,
                                        barcode_name:barcode,
                                        alias_barcode:alias_barcode,
                                        category_id:category,
                                      };
               
        }
     

        let items_to_delete = document.getElementById("delete-row").value;
        if(this.IsJsonString(items_to_delete)){
          items_to_delete = JSON.parse(items_to_delete);
        }else{
          items_to_delete = [];
        }

       
        let b_target = "quotation";
       
        this.setState({target:b_target});
     
       
        if(this.send_data_items.length===0){
          toast("Sorry! You can not create a bill without the items.");
          return false;
        }
        document.getElementById(clicked_on).classList.add("hide");	
        document.getElementById(clicked_on+'-btn-spinner').classList.remove("hide");
       
    
        send_data = {
            update_id : this.state.update_id,
            send_type : this.state.send_type,
            bill_status : bill_status,
            type : this.state.billing_type,
            customer : customer,
            contact_name : sel_contact,
            selected_contact : selected_contact,
            staff : staff,
            credit_days : credit_days,
            date : date,
            invoice_date : invoice_date,
            payment_mode : mode_of_payment,
            payment_mode_txt : mode_of_payment_txt,
          
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            sequence_no : this.state.currrent_sequence_no,
            sequence : this.state.currrent_sequence,
            notes : notes,
            total_unit:total_unit,
            global_discount:global_discount,
            global_discount_opt:global_discount_opt,
            round_off:round_off,
            round_off_opt:round_off_opt,
            total_sub:total_sub,
            total_discount:total_discount,
            total_igst:total_igst,
            total_cgst:total_cgst,
            total_sgst:total_sgst,
         
            total_tax:total_tax,
            total_grand:total_grand,
            items : this.send_data_items,
           
            items_to_delete : items_to_delete,
         
            agent_name : agent_name,
            inventory_status : inventory_status,
            current_bill_status : (this.state.is_resume_cancelled===false && this.state.billing_data && this.state.billing_data.bill_status!==undefined)?this.state.billing_data.bill_status:'',
          
        }
        
        let api = app_api+'/create_quotation';
        axios.post(api, send_data)
          .then(function (response) {
             let resp = response.data;
             let id = resp.id;
             localStorage.removeItem('item_options');
             localStorage.removeItem('purchase_item_options');
          
            if(resp.success===1){
                document.getElementById(clicked_on).classList.remove("hide");	
                document.getElementById(clicked_on+'-btn-spinner').classList.add("hide");
                window.location.href = '/quotation';
            }
        }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
              document.getElementById(click_id).classList.remove("hide");
              document.getElementById(click_id+'-btn-spinner').classList.add("hide");
              _this.setState({show_toast:true});
              toast("Oops ! something went wrong. an error occured with status code "+res.response.status);
              setTimeout(() => {
                window.location.href = '/quotation';
              }, 1000);
            }
          }
        })

        
    }
 

    render() {

        let is_payment = false;
        let is_cd_note = false;
        let cd_type = 'Credit';
        if(this.state.billing_type==='New Purchase'){
          is_cd_note = true;
          cd_type = 'Debit';
          if(this.is_access('payments-out-create')){
            is_payment = true;
          }
        }
        if(this.state.billing_type==='New Sale'){
          is_cd_note = true;
          if(this.is_access('payments-in-create')){
            is_payment = true;
          }
        }
        let bstatus = this.state.billing_data.bill_status;
        if(bstatus==='Paid'){
          is_payment = false;
        }

        let round_off_sym = '-';
        if(this.state.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.state.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let global_discount_sym = 'per';
        if(this.state.discount_sym==='rs'){
          global_discount_sym = 'rs';
        }else if(this.state.discount_sym==='per'){
          global_discount_sym = 'per';
        }
        let new_purchase_info = 'new-purchase-info';
        if(this.isMobile()===true){
          new_purchase_info = '';
        }
        let is_update_date = true;
        if(this.state.send_type==='update' && this.is_access('billing-stop-editing-date')){
            is_update_date = false;
        }
        return (
            <div className="d-flex new-bill" id="wrapper" onClick={this.handleDivClick}>
              <Leftnav />
              
              <span id="handlePrint" onClick={this.handlePrint}></span>
              
              <div id="page-content-wrapper">
                
                
                 <nav className="nb navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">	
                        <Breadcrumbs link="/quotation" title="Quotation" sub_title="Create Quotation"/>	
                    </div>	
                    <div className={"col-md-6"} style={{textAlign:'right', paddingRight:'34px'}}>	
                       	
                        <div className={"top-mod-title"}>	
                        	
                          <label>Quantity : <span id="top-total-quantity">{(this.state.billing_data && this.state.billing_data.quantity)?this.state.billing_data.quantity:0}</span></label>	
                          <label className="mrleft-15">Total : <span className="rs-sym">₹</span><span id="top-final-total">{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span></label>	
                         
                          <span className={"top-btn-blk "+this.state.billing_type.replaceAll(" ",'-')}>	
                          {(this.state.payment_mode==='payment' || this.state.payment_mode==='cash') &&	
                            <>
                              {(this.state.is_fy_closed===false) &&
                              <>
                                <button  style={{padding:'2px 10px',marginTop:'-2px'}} type="button" onClick={this.saveBilling} id="ts-save" className="mrleft-10 btn btn-success">{this.state.save_button}	
                                <ShortcutHelp arrow="bottom" target="ts-save-bill" shortcuts={['Ctrl+Enter']}/></button>	
                                <button  style={{padding:'2px 10px'}} type="button" id="ts-save-btn-spinner" className="hide mrleft-10 btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>	
                                </>
                              }
                              {(this.state.is_fy_closed===true) &&
                                <ClosedFYButton className="mrleft-10" style={{padding:'2px 10px',marginTop:'-2px'}} label={this.state.save_button} type="button"/>
                              }
                            </>
                          }	
                          {(this.state.payment_mode!=='payment' && this.state.payment_mode!=='cash') &&	
                          <>	
                            {(this.state.is_fy_closed===false) &&
                              <>
                              <button  style={{padding:'2px 10px',marginTop:'-2px'}} type="button" onClick={this.saveBilling} id="ts-save" className="mrleft-10 btn btn-success">{this.state.save_button}	
                              <ShortcutHelp arrow="bottom" target="ts-save-bill" shortcuts={['Ctrl+Enter']}/></button>	
                              <button  style={{padding:'2px 10px'}} type="button" id="ts-save-btn-spinner" className="hide mrleft-10 btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>	
                              </>
                            }
                            {(this.state.is_fy_closed===true) &&
                              <ClosedFYButton className="mrleft-10" style={{padding:'2px 10px',marginTop:'-2px'}} label={this.state.save_button} type="button"/>
                            }
                          </>	
                          }	
                          </span>	
                          
                        </div>	
                      	
                    </div>
                  
                  </div>
                </nav>
                <div className="new-billing container-fluid mobile-view">
                <ServiceLoader show={this.state.show_loader}/>
                <input type="text" className="hide" id="returned_invoice_id" defaultValue={this.state.returned_invoice_id}/>
                <input type="hidden" id="payment_mode" value=""/>
                <input type="hidden" id="contact_state" value=""/>
                <input type="hidden" id="credit_days" value=""/>
                <input type="hidden" id="lsp-percent" defaultValue={this.state.lowest_selling_price_percent} />
                <input type="hidden" id="contact_discount" value="" />
               
                  <div className="content-area new-billing-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom">
                       <div className="row">
                        <div className="col-md-3 mrtop-5">
                         <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={this.state.contact_info}  target={this.state.billing_type}/>
                         
                         <input type="hidden" id="contact_add_type" />
                         <input className="hide" type="text" id="original_credit_limit_box" defaultValue={this.state.contact_info.original_credit_limit}/>
                         <input className="hide" type="text" id="buffer_amount_for_credit_limit" defaultValue={this.state.buffer_amount_for_credit_limit}/>
                         <input className="hide" type="text" id="credit_limit_box" defaultValue={this.state.contact_info.credit_limit}/>
                        
                        </div>
                        
                     
                        <div className="col-md-2 mrtop-5" style={{visibility:'hidden'}}>
                          <p className="cinfo hide" id="cont-credit-limit">Credit Limit: <span id="credit_limit_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.credit_limit): 0}</span></p>
                          <p className="cinfo hide" id="cont-old-balance">Old Balance: <span id="previous_balance_txt">{this.state.contact_info!=''?this.currency_format(this.state.contact_info.previous_balance): 0}</span></p>
                        </div>
                       
                        
                       
                        
                        
                        <div className="col-md-2 mrtop-5"> </div>
                        
                        {(this.state.staff_options.length>0) &&
                        <div className="col-md-2  mrtop-5">
                           <select className="form-control" tabindex={2} id="staff-dropdown" value={this.state.assisted_by} onChange={this.handleChangeStaff}>
                              <option value="">Select Staff</option>
                              {(this.state.staff_options.length > 0) ?this.state.staff_options.map( (value, index) => {
                               return (
                                  <option value={value.id} key={index}>{value.name}</option>
                                )
                              }) : ''}
                          </select>
                        </div>
                        }
                        {(this.state.staff_options.length===0) &&
                          <div className="col-md-2  mrtop-5"></div>
                        }
                        
                      
                        <div className="col-md-2 text-right  mrtop-5">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text no-border-right">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </span>
                              {(is_update_date===true) &&
                                <DatePicker className="form-control  no-border-left" selected={this.state.billingDate} onChange={this.billingDate} dateFormat="dd/MM/yyyy"/>
                              }
                              {(is_update_date===false) &&
                                <input type="text" className="form-control  no-border-left" defaultValue={(this.state.billing_data && this.state.billing_data.visible_created_date)?this.state.billing_data.visible_created_date:''} readOnly  style={{background:'white'}}/>
                              }
                            </div>
                          </div>
                        </div>
                      
                        <div className="col-md-1 text-right mrtop-5 bill-view">
                            <label className="bil_no">{this.state.currrent_sequence}</label>
                        </div>
                       </div>
                       {(this.state.contact_error!=="") &&
                         <div className="row">
                          <div className="col-md-12">
                            <p className="error" dangerouslySetInnerHTML={{ __html: this.state.contact_error }} />
                          </div>
                         </div>
                       }
                     </div>
                    
                   {(this.state.billing_type==='New Sale' && this.state.advance_sale_data===true) &&
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                          
                            <div className={"row "+new_purchase_info}>
                                <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">AGENT NAME</span>
                                    <input type="text" className="form-control" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} id="agent-dropdown"/>
                                  </div> 
                                 
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">DISPATCHED THROUGH</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-through" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_through)?this.state.dispatched_info.dispatched_through:''}/>
                                  </div> 
                           
                                  <div className="col-md-3 mrtop-5">
                                     <span className="fe-title">DISPATCHED DOCUMENT / LR NO</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="dispatched-doc" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_document)?this.state.dispatched_info.dispatched_document:''}/>
                                  </div> 
                                
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">SUPPLIER'S REFRENCE NO</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="supplier-ref-no" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.supplier_ref_no)?this.state.dispatched_info.supplier_ref_no:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                    <span className="fe-title">DELIVERY NOTE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="delivery-note" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.delivery_note)?this.state.dispatched_info.delivery_note:''}/>
                                  </div> 
                                  <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER NOTE FROM SUPPLIER</span>
                                  <input type="text" className="form-control  f-field" placeholder="" id="other-note"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_note)?this.state.dispatched_info.other_note:''}/>
                                    
                                </div>
                            
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">OTHER REFERENCE</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="other-refrence" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_refrence)?this.state.dispatched_info.other_refrence:''}/>
                                </div>
                                <div className="col-md-3 mrtop-5">
                                  <span className="fe-title">TERMS OF DELIVERY</span>
                                    <input type="text" className="form-control  f-field" placeholder="" id="terms-of-delivery"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.terms_of_delivery)?this.state.dispatched_info.terms_of_delivery:''}/>
                                </div>
                            </div>
                          
                        </div>
                     </div>
                   }
                     </form> 
                    
                    {(this.state.isMobile===true) &&
                      <div className={"content-head border-bottom add-item-mob "+this.state.billing_type.replaceAll(" ",'-')} style={{padding:'10px 14px 3px 12px', background:'#f8f8ff'}}>
                         <div className="row">
                          <div className="col-md-12">
                            <h5>Add Items</h5>
                          </div>
                        </div> 
                      </div> 
                    }
                 
                      <div className={(this.state.is_on_account_return===true)?'row':''}>
                        <div className={(this.state.is_on_account_return===true)?'col-md-8':''}>
                          {this.state.add_item_area}
                        </div>
                      </div>
                      <div className={"content-head nb-detailed-area "+this.state.billing_type.replaceAll(" ",'-')}>
                       <div className="row">
                          <div className="col-md-6">
                              <select class="form-control form-field hide" id="mode_of_payment"><option value="2">Credit</option></select>

                              <table className="table inline-table bill-type-table hide">
                                <tbody>
                                    <tr>
                                        <td><span className="sp-normal">Discount on Bill <span id="disc_on_bill">{(this.state.discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                               </span>
                                            </>
                                          }</span></span></td>
                                        <td className="tbl-in-bx">
                                          <>
                                          <input type="text" className="form-control w-100px pull-right" defaultValue={this.state.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount} onKeyDown={this.handleCheckGDiscountAmount}/>
                                           <div className="form-group round-blk">
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange}/>
                                              <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                ₹
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                              <label className="form-check-label" htmlFor="discount-per">
                                                %
                                              </label>
                                            </div>
                                             
                                           </div>
                                          </>
                                         </td>
                                    </tr>
                                    
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="tbl-in-bx">
                                          <>
                                           <input type="text" className="form-control w-100px pull-right" defaultValue= {this.state.round_off} id="round_off" onKeyUp={this.handleRoundOff}/>
                                           <div className="form-group round-blk">
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange}/>
                                              <label className="form-check-label minus" htmlFor="minus">
                                                -
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                              <label className="form-check-label plus" htmlFor="plus">
                                                +
                                              </label>
                                            </div>
                                             
                                           </div>
                                          </>
                                         </td>
                                    </tr>
                                    {(is_cd_note) &&
                                      <>
                                    <tr>
                                      <td>
                                        <span className="sp-normal">{cd_type} Note (if any)</span>
                                        <span className="error" style={{fontSize: '12px',marginLeft: '10px'}}>{this.state.redeem_error}</span>
                                      </td>
                                      <td className="text-right tbl-in-bx">
                                        <input type="text" className="form-control pull-right w-100px" id="ptype_note" defaultValue={(this.state.billing_data && this.state.billing_data.payment_note_number  )?this.state.billing_data.payment_note_number:''}/>
                                        <input type="text" className="form-control hide" id="redeem_original_amount" defaultValue={(this.state.billing_data && this.state.billing_data.note_info && this.state.billing_data.note_info.amount)?this.state.billing_data.note_info.amount:''}/>
                                      </td>
                                    </tr>
                                    </>
                                    }

                                   
                                   
                                   
                                </tbody>
                              </table>
                             
                          
                          <textarea className="form-control text-notes" id="billing-notes" placeholder="Note" defaultValue={(this.state.billing_data && this.state.billing_data.note)?this.state.billing_data.note:''}></textarea>
                              <ShortcutHelp arrow="top" target="msg-to-customer" shortcuts={['Ctrl+M']}/>
                          </div>
                         <div className="col-md-2"></div>
                          <div className="col-md-4">
                           
                              <table className="table billing-total">
                                <tbody>
                                    <tr>
                                        <td><span className="sp-normal">Total Units</span></td>
                                        <td className="text-right"><span className="sp-normal all-out" id="total-unit">{(this.state.billing_data && this.state.billing_data.total_unit)?this.state.billing_data.total_unit:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Total Quantity</span></td>
                                        <td className="text-right"><span className="sp-normal all-out" id="total-quantity">{(this.state.billing_data && this.state.billing_data.quantity)?this.state.billing_data.quantity:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Subtotal</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total">{(this.state.billing_data && this.state.billing_data.sub_total)?this.state.billing_data.sub_total:0}</span></td>
                                    </tr>
                                   
                                      <tr>
                                          <td><span className="sp-normal">Discount</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                      </tr> 
                                    
                                    
                                  
                                    <tr>
                                        <td><span className="sp-normal">Discount on Bill <span id="disc_on_bill_lbl">{(this.state.discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                               </span>
                                            </>
                                          }</span></span></td>
                                        <td className="text-right">
                                          <span className="sp-normal all-out" id="global_discount_lbl"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                      <td><span className="sp-normal">Total Before Tax</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-tax">{(this.state.billing_data && this.state.billing_data.total_before_tax)?this.currency_format(this.state.billing_data.total_before_tax):0}</span></td>
                                    </tr>
                                    <tr>
                                      <td><span className="sp-normal">Tax</span></td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-after-tax">{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Total</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}}  className="rs-sym">₹</span><span className="sp-normal" id="s-total">{(this.state.billing_data && this.state.billing_data.calculate_total)?this.currency_format(this.state.billing_data.calculate_total):0}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="text-right">{this.state.round_off_sym}<span className="sp-normal" id="round_off_lbl">{this.state.round_off}</span></td>
                                    </tr>
                                   
                                    <tr>
                                        <td><span className="sp-normal" style={{fontWeight:'bold'}}>Grand Total</span></td>
                                        <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="final-total"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span>
                                          <input type="text" className="hide" id="final-total-text" defaultValue={this.state.billing_data.total}/>
                                        </td>
                                    </tr>
                                </tbody>
                              </table>
                              
                              <table width="100%" className="table inline-table tax-table">
                                      <tbody>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">CGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-cgst">{(this.state.billing_data && this.state.billing_data.total_cgst)?this.state.billing_data.total_cgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">SGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-sgst">{(this.state.billing_data && this.state.billing_data.total_sgst)?this.state.billing_data.total_sgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">IGST</span>
                                      </td>
                                      <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-igst">{(this.state.billing_data && this.state.billing_data.total_igst)?this.state.billing_data.total_igst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th><span className="sp-normal" style={{fontWeight:'bold'}}>TOTAL TAX</span></th>
                                      <td className="text-right"><span className="rs-sym" style={{fontWeight:'bold'}}>₹</span><span className="sp-normal all-out" id="total-tax"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr> 
                                </tbody>
                              </table>
                          </div>
                       </div>
                     </div>
                     <div className={"content-head border-bottom border-top new-bill-btn-action "+this.state.billing_type.replaceAll(" ",'-')}>
                    <>
                      {(this.state.is_fy_closed===false) &&
                      <>
                        <button type="button" onClick={this.saveBilling} id="save" className="btn btn-success btn-save-action">{this.state.save_button}
                        <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/></button>
                        <button type="button" id="save-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      </>
                      }
                      {(this.state.is_fy_closed===true) &&
                        <>
                          <ClosedFYButton className="btn-save-action" id="save" label={this.state.save_button} type="button"/>
                        </>
                      }
                        {(this.state.billing_type==='New Sale' && this.state.send_type==='save' && this.state.isMobile===false) &&
                        <>
                         {(this.state.is_fy_closed===false) &&
                          <>
                            {/*<button type="button" onClick={this.saveBilling} id="save-print" className="mrleft-5 btn btn-success btn-save-action">Save + Print + New</button>
                            <button type="button" id="save-print-btn-spinner" className="mrleft-5 hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>*/}
                          </>
                         }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              {/*<ClosedFYButton className="mrleft-5 btn-save-action" id="save-print" label="Save + Print + New" type="button"/>*/}
                            </>
                          }
                        </>
                        }
                    </>
                      {(this.state.show_hold_button) &&
                      <>
                        {(this.state.is_fy_closed===false) &&
                          <>
                          {/* <button type="button" onClick={this.saveBilling} id="hold" className="btn btn-success btn-save-action pull-right">Hold this Bill <ShortcutHelp  arrow="bottom" target="hold-bill" shortcuts={['Ctrl+Alt+H']}/></button>
                          <button type="button" id="hold-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button> */}
                          </>
                        }
                        {(this.state.is_fy_closed===true) &&
                            <>
                              {/* <ClosedFYButton className="btn-save-action pull-right" id="hold" label="Hold this Bill" type="button"/> */}
                            </>
                        }
                      </>
                      }
                      <>
                        <input className="hide" type="text" id="delete-row" defaultValue='[]'/>
                        <input className="hide" type="text" id="delete-return-row" defaultValue='[]'/>
                        {(this.state.send_type==='update' && is_payment) &&
                        <>
                          {(this.state.is_fy_closed===false) &&
                            <>
                             {/*  <button type="button" onClick={this.saveBilling} className="btn btn-success btn-save-action pull-right mrright-20" id="paid">Make Payment <ShortcutHelp  arrow="bottom" target="pay-bill" shortcuts={['Ctrl+Alt+P']}/></button> */}
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <>
                             {/*  <ClosedFYButton className="btn-save-action pull-right mrright-20" id="paid" label="Make Payment" type="button"/> */}
                            </>
                          }
                        </>
                        }
                        {(this.state.send_type==='save' && is_payment) &&
                        <>
                         {(this.state.is_fy_closed===false) &&
                            <>
                              {/* <button type="button" onClick={this.saveBilling} className="btn btn-success btn-save-action pull-right mrright-20" id="paid">Make Payment <ShortcutHelp  arrow="bottom" target="pay-bill" shortcuts={['Ctrl+Alt+P']}/></button> */}
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              {/* <ClosedFYButton className="btn-save-action pull-right mrright-20" id="paid" label="Make Payment" type="button"/> */}
                            </>
                          }
                        </>
                      }
                       {(this.state.send_type==='save' && is_payment) &&
                        <>
                          {/* <button type="button" id="paid-btn-spinner" className="hide btn btn-success btn-save-action pull-right mrright-20"><FontAwesomeIcon icon={faSpinner} pulse/></button> */}
                        </>
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <ModalBox id="add_contact" title="CREATE CONTACT" data_id="Create Contact"/>
              <ModalBox id="notice_box" title="Notice" data_id="Notice"/>
              <ModalBox id="ok_cancel_poup" title="Notice" data_id="Notice"/>
              <ModalBox id="add_category" title="ADD CATEGORY"  data_id="billing"/>
              <ModalBox id="print_bill" title="Print Bill" data_id="PrintBill" whatsapp_data={{whatsapp_phone:utils.check_mobile_number(this.state.whatsapp_phone),whatsapp_msg:this.state.whatsapp_msg}}/>
              <ModalBox id="buffer_credit_limit" title="Credit Limit Bill" data_id="CreditLimitBill"/>
              <PrintBill type={this.state.referer} id={this.state.response_id} target="print" bill_view_response={this.state.print_data} screen="new_billing"/>
              <HoldBillPopup data={{screen:'billing',billing_type:this.state.billing_type,update_id:this.state.update_id}}/>	
            </div>
        );
    }
}