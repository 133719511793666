import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/dancing-script";
import axios from 'axios';
import {QRCodeSVG} from 'qrcode.react';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class UPIQrCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dispatched_info : [],
            upi_info:{merchant:'',upi:''}
        };
      
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }   
    componentDidMount(){
        this.handleGetUPISettings();
    }
    handleGetUPISettings = () =>{
        let keys = "'upi_info'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:1,
            uuid : utils.uuid(),
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
        .then(function (response) {
            let resp = response.data;
            if(resp.upi_info!==undefined){
            let ps = resp.upi_info;
            this.setState({upi_info:ps});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    render() {

        return (
            <>
            {(this.state.upi_info.merchant!=="" && this.state.upi_info.upi!=="")&&
            <>
               <QRCodeSVG value={"upi://pay?pn="+encodeURIComponent(this.state.upi_info.merchant)+"&pa="+encodeURIComponent(this.state.upi_info.upi)+"&am="+this.props.amount+"&url="+encodeURIComponent(app_api+"/upi_process/"+user_info.store_name+"/"+this.props.transid)} />
               <br />
               <p>Scan to Pay</p>
            </>
            }
            </>
            
        );
    }
}