import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import axios from 'axios';
import Barcode from 'react-barcode';
import utils from "../../utils";
import QRCode from "react-qr-code";
let {enc_key,app_api,user_info} = require('../../library.js');
export default class BarcodeTemplate extends Component {
     constructor() {
      super();
      this.state = {
       
      }
      
    }

    render() {
        return (
                  <div key={'bc-temp-'+this.props.id} style={{position:'relative',height:this.props.height,width:this.props.width,float:'left',background:'#fff'}} className="bcode-temp mrbtm-5 mrright-5">
                      {(this.props.tamplate_data.length > 0) ?this.props.tamplate_data.map( (value, index) => {
                       return (
                        <>
                          {(value.element_id==='barcode') &&
                            <div data_target={value.element_id} data_value={this.props.billing_data.item_name} className={"react-draggable "+value.position} style={{transform:'translate('+value.x+'px,'+value.y+'px)'}}>
                              <div className={"drag-inner "+value.position}>
                                {(this.props.barcode_template_data!==undefined && this.props.barcode_template_data[0]!==undefined && this.props.barcode_template_data[0].symbol_type==='barcode') &&
                                  <Barcode width={(value.width/100)} displayValue={false} value={(this.props.billing_data.barcode_name!=="")?this.props.billing_data.barcode_name:this.props.billing_data.item_name} height={parseFloat(value.height)} key="barcode"/>
                                }
                                 {(this.props.barcode_template_data!==undefined && this.props.barcode_template_data[0]!==undefined && this.props.barcode_template_data[0].symbol_type==='qr') &&
                                 <QRCode size={256} style={{ height: value.height, maxWidth: value.width, width: value.width }} value={(this.props.billing_data.barcode_name!=="")?this.props.billing_data.barcode_name:this.props.billing_data.item_name} viewBox={`0 0 256 256`} key="barcode"/>
                                }
                              </div>
                            </div>
                          }
                          {(value.element_id!=='barcode') &&
                            <div className={"react-draggable "+value.position} style={{transform:'translate('+value.x+'px,'+value.y+'px)'}}>
                              <div className={"drag-inner "+value.position}>
                                <label className="temp-elem-name" data-id={value.element_id} style={{fontSize:(value.font_size)?value.font_size+'px':'',fontWeight:(value.font_weight)?value.font_weight:'',width:(value.width!==undefined && value.width!=="")?value.width+'px':'',whiteSpace:(value.width!==undefined && value.width!=="")?'break-spaces':'',lineHeight:(value.width!==undefined && value.width!=="")?1.2:'',textAlign:(value.width!==undefined && value.width!=="")?value.position:''}}>
                                  
                                {(value.element_id === 'barcode-name') &&
                                  <>
                                    {this.props.billing_data.barcode_name}
                                  </>
                                }
                                {(value.element_id === 'category') &&
                                  <>
                                    {this.props.billing_data.category}
                                  </>
                                }

                                {(value.element_id === 'description') &&
                                  <>
                                    {this.props.billing_data.item_desc}
                                  </>
                                }
                                {(value.element_id === 'batch_number') &&
                                  <>
                                    {this.props.billing_data.batch_number}
                                  </>
                                }
                                {(value.element_id === 'manufacture_date') &&
                                  <>
                                    {this.props.billing_data.manufacture_date}
                                  </>
                                }


                                {(value.element_id === 'custom-text') &&
                                  <>
                                    {this.props.billing_data.custom_text}
                                  </>
                                }


                                {(value.element_id === 'company-name') &&
                                  <>
                                    {user_info.company_name}
                                  </>
                                }

                                {(value.element_id === 'company_sub_title') &&
                                  <>
                                    {user_info.company_sub_title}
                                  </>
                                }


                                {(value.element_id === 'price') &&
                                  <>
                                    {value.display_name} {this.props.billing_data.selling_price}/-
                                  </>
                                }


                                {(value.element_id === 'purchase-info') &&
                                  <>
                                    {this.props.billing_data.selling_price}
                                  </>
                                }

                                {(value.element_id === 'varient') &&
                                  <>
                                    {value.display_name} {this.props.billing_data.varient}
                                  </>
                                }
                                {(value.element_id === 'product-name') &&
                                  <>
                                    {this.props.billing_data.item_name}
                                  </>
                                }
                                {(value.element_id === 'size') &&
                                  <>
                                    {this.props.billing_data.size}
                                  </>
                                }

                                {(value.element_id === 'address') &&
                                  <>
                                    {user_info.address}
                                  </>
                                }

                                {(value.element_id === 'city') &&
                                  <>
                                    {this.props.billing_data.city}
                                  </>
                                }

                                {(value.element_id === 'state') &&
                                  <>
                                    {this.props.billing_data.state}
                                  </>
                                }

                                {(value.element_id === 'phone_number_1') &&
                                  <>
                                    {this.props.billing_data.phone1}
                                  </>
                                }
                                {(value.element_id === 'phone_number_2') &&
                                  <>
                                    {this.props.billing_data.phone2}
                                  </>
                                }
                                {(value.element_id === 'email') &&
                                  <>
                                    {this.props.billing_data.email}
                                  </>
                                }
                                {(value.element_id === 'encrypted_purchase_price') &&
                                  <>
                                    {this.props.billing_data.encrypted_purchase_price}
                                  </>
                                }
                                {(value.element_id === 'encrypted_selling_price') &&
                                  <>
                                    {this.props.billing_data.encrypted_selling_price}
                                  </>
                                }
                                {(value.element_id === 'supplier_short_name') &&
                                  <>
                                    {this.props.billing_data.short_name}
                                  </>
                                }
                                {(value.element_id === 'mixed' && this.props.billing_data.city!==undefined && this.props.billing_data.short_name!==undefined) &&
                                  <>
                                    {this.props.billing_data.city.charAt(0)+this.props.billing_data.short_name+utils.PREFIX_TO_NUMBER(this.props.billing_data.selling_price,5)}
                                  </>
                                }
                                {(value.element_id === 'alias_barcode') &&
                                  <>
                                    {this.props.billing_data.alias_barcode}
                                  </>
                                }
                                {(value.element_id.match(/cf\d+/)) &&
                                  <>
                                  {(value.show_label===true) &&
                                    <span style={{fontWeight:(value.label_bold===true)?'bold':''}}>{value.display_name}:</span> 
                                  }
                                    {this.props.billing_data[value.element_id]}
                                  </>
                                }
                                
                                {(value.element_id === 'company_name_seperator' || value.element_id === 'company_name_separator') &&
                                  <>
                                   |
                                  </>
                                }
                                </label>
                              </div>
                            </div>
                          }
                          </>
                       )
                     }) :''}
                  </div>                    
        );
    }
}