import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Leftnav from "./left-nav";
import Pager from "./pager";
import Tooltip from "./tooltip";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft,faCaretRight  } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

let {enc_key,app_api,admin_info} = require('../../library.js');
export default class AdminStores extends Component {
    constructor() {
        super();
        this.state = {
           store_info : '',
           loading_process:'Loading...',
           prev_link :'',
          next_link:'',
          current_page : '',
          total_page : '',
          active_tab:'',
          active_count : 0,
          inactive_count : 0,
        };
        this.store_info = [];
    }
  
    componentDidMount(){
        let curl = window.location.href;
        const store_tab = curl.match(/admin-area\/stores\/([a-z_A-Z]+$)/i);
        const store_page_tab = curl.match(/admin-area\/stores\/([a-z_A-Z]+)\/(\d+$)/i);
        const page_tab = curl.match(/admin-area\/stores\/(\d+$)/i);

        let page_type = 'active';
        let current_page = 1;

        if(store_tab){
          page_type = store_tab[1];
        }else if(store_page_tab){
          page_type = store_page_tab[1];
          current_page = store_page_tab[2];
        }
        if(page_tab && page_tab[1]){
          current_page = page_tab[1];
        }
        this.setState({active_tab:page_type});
        let send_data = {
            login_id : admin_info.login_id,
            page : current_page,
            page_type:page_type
        }
        let api = app_api+'/admin/get_stores';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            this.setState({active_count:resp.active_count});
            this.setState({inactive_count:resp.inactive_count});
            if(resp.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:resp.page_count});
              this.setState({store_info:resp.data});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title"><span>Stores</span></div>
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-3">
                      
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="nav-container">
                      <ul className="nav nav-pills">
                      
                        <li className={this.state.active_tab==='active'?'active':''}><a href="/admin-area/stores/active">Active ({this.state.active_count})</a></li>
                      
                      
                        <li className={this.state.active_tab==='inactive'?'active':''}><a href="/admin-area/stores/inactive">Inactive ({this.state.inactive_count})</a></li>
                      
                      </ul>
                  </div>
                  <div className="content-area">
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">STORE NAME</th>
                           
                            <th scope="col">EMAIL</th>
                            <th scope="col">REGISTRATION DATE</th>
                            <th scope="col">LAST LOGIN ON</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.store_info.length > 0) ?this.state.store_info.map( (value, index) => {
                           return (
                            <tr className="item-row" key={ index }>
                              <td>
                                <a href={"/admin-area/view-store/"+encodeURIComponent(value.store_name)}><span className="st-o-name">{value.name}</span></a>
                              </td>
                              <td><a href={"/admin-area/view-store/"+encodeURIComponent(value.store_name)}>{value.store_name}</a></td>
                              <td>{value.email}</td>
                              <td>{value.reg_date}</td>
                              <td>{value.last_login}</td>
                              <td>
                                <Tooltip index={index} view={"/admin-area/view-store/"+encodeURIComponent(value.store_name)}  item_count={this.state.store_info.length}/>
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan="7" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      <Pager total_page={this.state.total_page}/>
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}