import React, { Component } from "react";
export default class SelectDropdown extends Component {
    constructor(props) {
      super();
      this.state = {
        default : props.default,
      };
    }

    handleHeaderChange = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({default:thisval});
        if(this.props.onChange){
            this.props.onChange(e);
        }
    }

    render() {
        return (
           <select className="form-control custom-header" value={this.state.default} id={this.props.data_id} onChange={this.handleHeaderChange} data_value={this.props.data_value}>
               <option value="">Select Header</option>
               {(this.props.options!==undefined && this.props.options.length>0) &&
                    <>
                        {
                        this.props.options.map((v)=>(
                            <option value={v}>{v}</option>
                        ))
                        }
                    </>
               }
           </select>
        );
    }
}