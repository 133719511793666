import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import axios from 'axios';
import ModalBox from "../modal-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import AccountTypes from "../account-types";
import PaymentModes from "../payment-modes";
import utils from "../../utils";
import AccountGroups from "../account-groups";
import AccountSubGroups from "../account-sub-groups";
import AccountGroupLinkTypes from "../account-group-link-types";
let {enc_key,app_api,user_info,states} = require('../../library.js');
export default class SettingAccounts extends Component {
     constructor() {
      super();
      this.state = {
        more_rows : '',
        is_saving_upi : false,
        is_account_pop : false,
        is_account_group_pop : false,
        is_account_sub_group_pop : false,
        group_id : 0,
        link_id : 0,
        upi_info : {merchant:'',upi:''}
      }
      this.more_rows = [];
     
      
      
      this.is_access = check_access.is_access.bind(this);
    }
    handleSaveUPI = () =>{
        this.setState({is_saving_upi:true});
        let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          name : 'upi_info',
          value : this.state.upi_info,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
            this.setState({is_saving_upi:false});
        }.bind(this)).catch(function(res){
            
        })
    }
    handleChangeUPIInfo = (key,value) =>{
      let uinfo = this.state.upi_info;
      uinfo[key] = value;
      this.setState({upi_info:uinfo});
    }
    openModal = (e) => {
        let type = e.currentTarget.getAttribute('data_target')
        if(type==='add_account_types'){
          this.setState({is_account_pop:true});
        }else if(type==='add_account_group'){
          this.setState({is_account_group_pop:true});
        }else if(type==='add_account_sub_group'){
          this.setState({is_account_sub_group_pop:true});
        }
    }

    componentDidMount(){
      this.handleGetUPISettings();
    }

    handleGetUPISettings = () =>{
      let keys = "'upi_info'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.upi_info!==undefined){
              let ps = resp.upi_info;
              this.setState({upi_info:ps});
            }
        }.bind(this)).catch(function(res){
            
        })
    }

    handleSaveAccountSubGroup = (e) =>{
      let account_sub_group_name = document.getElementById("account_sub_group_name").value;
      let account_sub_group_id = document.getElementById("account_sub_group_id").value;
      let account_group_id = document.getElementById("account-group-select").value;
      let link_type = document.getElementById("link-type-select").value;
      let group_type = document.getElementById("group_type").value;
      if(group_type==""){
        document.getElementById("group_type").focus();
        return false;
      }
      if(account_sub_group_id==""){
        account_sub_group_id = 0;
      }
      let thisid = e.currentTarget.id;
      if(account_sub_group_name===""){
        document.getElementById("account_sub_group_name").focus();
        return false;
      }
     
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          sub_group_id : account_sub_group_id,
          group_id : account_group_id,
          name : account_sub_group_name,
          link_type : link_type,
          group_type : group_type,
          target_type : 'sub_group',
      }
      
      let api = app_api+'/save_account_group';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-spinner').classList.add("hide");
          this.handleClosePopup();
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveAccountGroup = (e) =>{
      let link_type = document.getElementById("link-type-select").value;
      let account_group_name = document.getElementById("account_group_name").value;
      let account_group_id = document.getElementById("account_group_id").value;
      let group_type = document.getElementById("group_type").value;
      if(group_type==""){
        document.getElementById("group_type").focus();
        return false;
      }
      if(account_group_id==""){
        account_group_id = 0;
      }
      let thisid = e.currentTarget.id;
      if(account_group_name===""){
        document.getElementById("account_group_name").focus();
        return false;
      }
     
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          group_id : account_group_id,
          name : account_group_name,
          link_type : link_type,
          group_type : group_type,
          target_type : 'group',
      }
      
      let api = app_api+'/save_account_group';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-spinner').classList.add("hide");
          this.handleClosePopup();
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveAccountType = (e) =>{
      let thisid = e.currentTarget.id;
      let id = document.getElementById("account_type_id").value;
      let account_name = document.getElementById("account_name").value;
      let linked_payment_mode = 0;//document.getElementById("linked-payment-mode").value;
      if(account_name===""){
        document.getElementById("account_name").focus();
        return false;
      }
      /* if(linked_payment_mode===""){
        document.getElementById("linked-payment-mode").focus();
        return false;
      } */
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          id : id,
          account_name : account_name,
          payment_mode : linked_payment_mode,
      }
      
      let api = app_api+'/save_account';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-spinner').classList.add("hide");
          this.handleClosePopup();
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    handleClosePopup = () =>{
      if(this.state.is_account_pop===true){
        this.setState({is_account_pop:false});
        localStorage.removeItem("p_mode");
      }else if(this.state.is_account_group_pop===true){
        this.setState({is_account_group_pop:false});
      }else if(this.state.is_account_sub_group_pop===true){
        this.setState({is_account_sub_group_pop:false});
      }
    }
    
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Bank Accounts</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                        <div className="row">
                             <div className="col-md-6">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>BANK ACCOUNTS</label>
                                  
                                <FontAwesomeIcon onClick={this.openModal} data_target="add_account_types"  icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                  
                                </div>
                                <div className="box-body">
                                  <AccountTypes show="list" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                            <div className="box-container">
                                <div className="box-head">
                                  <label>UPI SETTINGS</label>
                                </div>
                                <div className="box-body" style={{padding:'10px 15px'}}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>Marchant Name</label>
                                      <input type="text" className="form-control" placeholder="Enter Merchant Name" value={this.state.upi_info.merchant} onChange={(e)=>this.handleChangeUPIInfo('merchant',e.target.value)}/>
                                    </div>
                                    <div className="col-md-12 mrtop-10">
                                        <label>UPI ID</label>
                                        <input type="text" value={this.state.upi_info.upi} className="form-control" placeholder="Enter UPI ID" onChange={(e)=>this.handleChangeUPIInfo('upi',e.target.value)}/>
                                    </div>
                                    <div className="col-md-12 mrtop-10">
                                      {(this.state.is_saving_upi===false) &&
                                          <button type="button" id="save-upi-btn" className="btn btn-success pull-right" onClick={this.handleSaveUPI}>Save</button>
                                      }
                                        {(this.state.is_saving_upi===true) &&
                                        <button type="button" id="save-btn-spinner" className="btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="row">
                             <div className="col-md-6">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>ACCOUNT GROUPS</label>
                                  <FontAwesomeIcon onClick={this.openModal} data_target="add_account_group"  icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                </div>
                                <div className="box-body">
                                  <AccountGroups show="list" />
                                </div>
                              </div>
                            </div>
                             <div className="col-md-6">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>ACCOUNT SUB GROUPS</label>
                                  <FontAwesomeIcon onClick={this.openModal} data_target="add_account_sub_group"  icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                </div>
                                <div className="box-body">
                                  <AccountSubGroups show="list" />
                                </div>
                              </div>
                            </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.is_account_pop) &&
                <div className="modal" id="add_account_types" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">ADD ACCOUNT TYPE</h5>
                          </div>
                          <div className="modal-body">
                              <div className="row">
                                  <div className="col-md-12">
                                      <label for="account_name" style={{color:'rgb(100, 100, 100)'}}>
                                          Account Name
                                      </label>
                                      <input type="hidden" name="account_type_id" className="form-control" id="account_type_id" />
                                      <input type="text" name="account_name" className="form-control" placeholder="Please enter account name" id="account_name" />
                                      
                                  </div>
                                  {/* <div className="col-md-12 mrtop-10">
                                      <label for="linked-payment-mode" style={{color:'rgb(100, 100, 100)'}}>
                                          Payment Modes <span style={{fontSize: '12px',
      color: 'gray',
      fontFamily: 'monospace'}}>(Link account to payment mode)</span>
                                      </label>
                                      <PaymentModes show="dropdown" target="empty" id="linked-payment-mode"/>
                                  </div> */}
                              </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>Close</button>
                            <button type="button" data-id="add_account_types" id="save-btn" className="btn btn-success pull-right" onClick={this.handleSaveAccountType}>Save</button>
                            <button type="button" id="save-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                      </div>
                  </div>
                </div>
              }
              {(this.state.is_account_group_pop) &&
                <div className="modal" id="add_account_group" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">ADD ACCOUNT GROUP</h5>
                          </div>
                          <div className="modal-body">
                              <div className="row">
                                  <div className="col-md-12">
                                      <label for="account_group_name" style={{color:'rgb(100, 100, 100)'}}>
                                          GROUP NAME
                                      </label>
                                      <input type="hidden" name="account_group_id" className="form-control" id="account_group_id" />
                                      <input type="text" name="account_group_name" className="form-control" placeholder="Please enter account group name" id="account_group_name" />
                                  </div>
                                  <div className="col-md-12  mrtop-10">
                                  <label for="account_group_link_type" style={{color:'rgb(100, 100, 100)'}}>
                                      ACCOUNT TYPES
                                      </label>
                                    <AccountGroupLinkTypes />
                                  </div>
                                  <div className="col-md-12  mrtop-10">
                                  <label for="group_type" style={{color:'rgb(100, 100, 100)'}}>
                                      GROUP TYPE
                                      </label>
                                      <select className="form-control" id="group_type">
                                          <option value="">SELECT GROUP TYPE</option>
                                          <option value="credit">CREDIT</option>
                                          <option value="debit">DEBIT</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>Close</button>
                            <button type="button" data-id="add_account_group" id="save-account-group-btn" className="btn btn-success pull-right" onClick={this.handleSaveAccountGroup}>Save</button>
                            <button type="button" id="save-account-group-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                      </div>
                  </div>
                </div>
              }
              {(this.state.is_account_sub_group_pop) &&
                <div className="modal" id="add_account_sub_group" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">ADD ACCOUNT SUB GROUP</h5>
                          </div>
                          <div className="modal-body">
                              <div className="row">
                                  <div className="col-md-12">
                                      <label for="account_sub_group_name" style={{color:'rgb(100, 100, 100)'}}>
                                          SUB GROUP NAME
                                      </label>
                                      <input type="hidden" name="account_sub_group_id" className="form-control" id="account_sub_group_id" />
                                      <input type="text" name="account_sub_group_name" className="form-control" placeholder="Please enter sub account group name" id="account_sub_group_name" />
                                  </div>
                                  <div className="col-md-12 mrtop-10">
                                      <label for="account_group_name" style={{color:'rgb(100, 100, 100)'}}>
                                          GROUP NAME
                                      </label>
                                      <AccountGroups show="dropdown" default = {this.state.group_id}/>
                                  </div>
                                  <div className="col-md-12 mrtop-10">
                                  <label for="account_group_link_type" style={{color:'rgb(100, 100, 100)'}}>
                                      ACCOUNT TYPES
                                    </label>
                                    <AccountGroupLinkTypes  default = {this.state.link_id}/>
                                  </div>
                                  <div className="col-md-12  mrtop-10">
                                  <label for="group_type" style={{color:'rgb(100, 100, 100)'}}>
                                      GROUP TYPE
                                      </label>
                                      <select className="form-control" id="group_type">
                                          <option value="">SELECT GROUP TYPE</option>
                                          <option value="credit">CREDIT</option>
                                          <option value="debit">DEBIT</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.handleClosePopup}>Close</button>
                            <button type="button" data-id="add_account_sub_group" id="save-btn" className="btn btn-success pull-right" onClick={this.handleSaveAccountSubGroup}>Save</button>
                            <button type="button" id="save-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                      </div>
                  </div>
                </div>
              }
              <span id="openAddAccountModal" onClick={this.openModal} data_target="add_account_types"></span>
              <span id="openAddGroupModal" onClick={this.openModal} data_target="add_account_group"></span>
              <span id="openAddSubGroupModal" onClick={this.openModal} data_target="add_account_sub_group"></span>
              <span id="handleSetGroupID" onClick={this.handleSetGroupID}></span>
              <span id="handleSetLinkedID" onClick={this.handleSetLinkedID}></span>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
            </div>
        );
    }
    handleSetGroupID = () =>{
      let group_id = localStorage.getItem('group_id');
      this.setState({group_id:group_id});
      localStorage.removeItem('group_id');
    }
    handleSetLinkedID = () =>{
      let link_id = localStorage.getItem('link_id');
      this.setState({link_id:link_id});
      localStorage.removeItem('link_id');
    }
}