import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import ModalBox from "./modal-box";
import Breadcrumbs from "./breadcrumbs";
import ContactTypes from "./contact-types";
import PaymentModes from "./payment-modes";
import ContactGroups from "./contact-groups";
import ContactSelect from "./contact-select-on-type";
import TagFields from "./tag-field";
import ServiceLoader from './service-loader.js';
import axios from 'axios';
import utils from '../utils.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';
import ShortcutHelp from "./shortcut-help.js";
import AccountGroupsSubGroups from "./account-groups-sub-groups";
let {enc_key,app_api,user_info,states} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class EditContact extends Component {
    constructor() {
        super();
        this.state = {
            show_loader : true,
            notification:"0",
            contact_list:[],
            opening_balance_type : '',
            orders_opening_balance_type : '',
            current_id:'',
            selected_state:'',
            agent_toggle:'',
            info_body : {
                id : '',
                type : '',
                agent_name : '',
                name : '',
                email : '',
                phone_1 : '',
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                account_name : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : '',
                notification : '',
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
            },
            custom_fields_json : [],
            openingBalanceDate:  new Date(),
            ordersOpeningBalanceDate:  new Date(),
        };
        _this = this;
        this.saveContact = this.saveContact.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.changeNotification = this.changeNotification.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.openingBalanceDate = this.openingBalanceDate.bind(this);
        this.resetAgentToggle = this.resetAgentToggle.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
    }
    handleSetCustomField = (e) =>{
      let thisvalue = e.currentTarget.value;
      let target = e.currentTarget.getAttribute('data-target');
      let idx = e.currentTarget.getAttribute('data-id');
      let custom_fields_json =  this.state.custom_fields_json;
      custom_fields_json[idx][target] = thisvalue;
      this.setState({custom_fields_json:custom_fields_json});
  }
    handleChangeStatus(e){
      let contact_list = this.state.contact_list;
      let status = e.currentTarget.value;
      contact_list['status'] = status;
      this.setState({contact_list:contact_list});
    }
    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        if(document.getElementById("save-target-type")){
          document.getElementById("save-target-type").value = 'add_contact';
        }
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }
    resetAgentToggle(){
      let agent_toggle = localStorage.getItem('agent_toggle');
      this.setState({agent_toggle:agent_toggle});
      localStorage.removeItem('agent_toggle');
    }
    openingBalanceDate = date => {
      this.setState({
        openingBalanceDate: date
      });
    }; 
    ordersOpeningBalanceDate = date => {
      this.setState({
        ordersOpeningBalanceDate: date
      });
    }; 
    handleChangeState(e){
      this.setState({selected_state:e.currentTarget.value});
    }
    changeNotification(e){
      this.setState({notification:e.target.value})
    }
    componentDidMount(){
      localStorage.removeItem('p_mode');
      let curl = window.location.href;
      const get_id = curl.match(/edit-contact\/(\d+$)/i);
      const c_id = parseInt(get_id[1]);
      
      this.setState({current_id:c_id});
       let body = {};
      body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            id : c_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      let api = app_api+'/get_contact_on_id';
    
      axios.post(api, body)
        .then(function (response) {
          let resp = response.data;
          if(resp[0]!==undefined){

            let additional_data = resp[0].custom_data;

            if(additional_data!==undefined){
              this.setState({custom_fields_json:additional_data});
            }

            if(resp[0].agent_name!=="" && resp[0].agent_name!==null){
              this.setState({agent_toggle:''})
            }
            this.setState({ 'contact_list':  resp[0],selected_state:resp[0].selected_state,notification:resp[0].notification,opening_balance_type:resp[0].opening_balance_type,orders_opening_balance_type:resp[0].orders_opening_balance_type}); 
            this.setState({show_loader:false});
            if(resp[0].opening_balance_type==='debit'){
              document.getElementById("opening_balance_debit").checked = true;;
            }
            if(resp[0].orders_opening_balance_type==='debit'){
              document.getElementById("orders_opening_balance_debit").checked = true;;
            }

            if(resp[0].opening_balance_date!==null && resp[0].opening_balance_date!==''){
             this.setState({openingBalanceDate:new Date(resp[0].opening_balance_date)});
            }
            if(resp[0].orders_opening_balance_date!==null && resp[0].orders_opening_balance_date!==''){
             this.setState({ordersOpeningBalanceDate:new Date(resp[0].orders_opening_balance_date)});
            }
            setTimeout(function(){
              let contact_type = document.getElementById("contact-type-select");
              let contact_type_txt = contact_type.options[contact_type.selectedIndex].text;
              if(contact_type_txt==="Supplier"){
                 _this.setState({agent_toggle:''});
              }
            },100)

          }else{
            window.location.href = '/404';
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }
    saveContact(event) {
        let id = event.currentTarget.id;
        var e = document.getElementById("contact-type-select");
        var contact_type_name = e.options[e.selectedIndex].text;

        let classes = document.getElementsByClassName('validate');
        let is_valid = true;
        for(let i=0;i<classes.length;i++){
            let value = classes[i].value;
            if(value===''){
                classes[i].style.border = '1px solid red';
                is_valid = false;
            }
        } 
        const { info_body } = { ...this.state };
        const currentState = info_body;
        let form_fields = document.getElementsByClassName('form-field');
        currentState['id'] = this.state.current_id;
        currentState['contact_type'] = contact_type_name;
        for(let i=0;i<form_fields.length;i++){
            let value = form_fields[i].value;
            let key = form_fields[i].getAttribute('name');
            if(key==='credit_limit'){
              value =value.replace(/,/g , "");
            }
            currentState[key] = value;
        }
        if(document.getElementById("tags-value-groups")){
          currentState['groups'] = document.getElementById("tags-value-groups").value;
        }
        currentState['opening_balance_type'] = document.querySelector('input[name="opening_balance_type"]:checked').value;
        currentState['opening_balance_date'] = this.state.openingBalanceDate;
        currentState['orders_opening_balance_type'] = document.querySelector('input[name="orders_opening_balance_type"]:checked').value;
        currentState['orders_opening_balance_date'] = this.state.ordersOpeningBalanceDate;
        currentState['custom_data'] = this.state.custom_fields_json;
        currentState['uuid'] = utils.uuid();
        currentState['role_id'] = user_info.access_level;
        currentState['token'] = user_info.token;
        currentState['_schema'] = user_info._schema;
        let account_group = '';
        if(document.getElementById('account-group-select')){
          account_group = document.getElementById('account-group-select').value;
        }
        currentState['account_group'] = account_group;
        this.setState({ info_body: currentState });
        let contact_type_id = document.getElementById("contact-type-select").value;
        if(is_valid){
          document.getElementById(id).classList.add("hide");
          document.getElementById(id+'-btn-spinner').classList.remove("hide");
          let api = app_api+'/update_contact';
          let list_data = this.state.info_body;
          axios.post(api, list_data)
            .then(function (response) {
               let resp = response.data;
               if(resp.success===1){
                  if(resp.message===1){
                    try{
                     let transaction = db.transaction("contacts", "readwrite");
                     let contacts = transaction.objectStore("contacts");
                     let contact = CryptoJS.AES.encrypt(JSON.stringify(list_data), enc_key).toString();
                     let contact_type = list_data.type;
                     let contact_data = {};
                     contact_data['id'] = parseInt(_this.state.current_id);
                     contact_data['type'] = parseInt(contact_type);
                     contact_data['contact_type'] = contact_type_name;
                     contact_data['data'] = contact;
                     contacts.put(contact_data);
                   }catch(e){}
                 }else if(resp.message===10){
                    var deltrans = db.transaction("contacts", "readwrite");
                    var objectStore = deltrans.objectStore("contacts");
                    let contact_id = this.state.current_id;
                    var objectStoreRequest = objectStore.delete(contact_id);
                 }
                 window.location.href = "/contacts/"+contact_type_id;
               }
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
      }

    }
    handleChangeContactType = (e) =>{
        let thisvalue = e.currentTarget.value;
        let contact_list = this.state.contact_list;
        contact_list.type = thisvalue;
        this.setState({contact_list:contact_list});
    }
    render() {
        var notes = this.state.contact_list.notes;
        let is_opening_balance_change = false;
        if(this.state.contact_list.is_opening_balance_change==='yes'){
          is_opening_balance_change = true;
        }
        let is_orders_opening_balance_change = false;
        if(this.state.contact_list.is_orders_opening_balance_change==='yes'){
          is_orders_opening_balance_change = true;
        }
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">
                        <Breadcrumbs link="/contacts" title="Contacts" sub_title="Edit Contact"/>
                       
                    </div>
                     <div className="col-md-6">
                     
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                <ServiceLoader show={this.state.show_loader}/>
                  <div className="content-area">
                     <form id="contact-info-form">

                     <div className="content-head border-bottom">
                        <b>Basic Profile</b>
                        <p className="info">This information can be edited from your profile page.</p>
                     </div>
                     <div className="main-content border-bottom">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">TYPE <span className="red">*</span></label>
                                  </div>
                                  <div className="col-md-8">
                                    <ContactTypes show="dropdown" name="type" default={this.state.contact_list.type} target="add-contact" tabindex="1" onChange={this.handleChangeContactType}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3">
                                      <label className="lbl">ACCOUNT GROUP</label>
                                    </div>
                                    <div className="col-md-8">
                                      <AccountGroupsSubGroups  default={this.state.contact_list.account_group}/>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                
                              </div>
                           
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">NAME <span className="red">*</span></label>
                                  </div>
                                  <div className="col-md-8">
                                   <input type="text"  defaultValue={this.state.contact_list.name} name="name" className="form-field form-control validate"  placeholder="Enter name" tabindex="3"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                {(this.state.contact_list.type===4 || this.state.contact_list.type==='4') &&
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label className="lbl">LOCATION</label>
                                    </div>
                                    <div className="col-md-8">     
                                      <input type="text" name="location" className="form-field form-control"  placeholder="Enter Location"  defaultValue={this.state.contact_list.staff_location}/>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                }
                                {(this.state.contact_list.type===2 || this.state.contact_list.type==='2') &&
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label className="lbl">SHORT NAME</label>
                                    </div>
                                    <div className="col-md-8">     
                                      <input type="text" name="short_name" className="form-field form-control"  placeholder="Enter Short Name" defaultValue={this.state.contact_list.short_name}/>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                }
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ADDRESS LINE 1</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" name="address_1" className="form-field form-control" placeholder="Enter address line 1" defaultValue={this.state.contact_list.address_1} tabindex="2"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                            
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PHONE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.phone_1} name="phone_1" className="form-field form-control"  placeholder="+1-000-000-0000" tabindex="5"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ADDRESS LINE 2</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.address_2} name="address_2" className="form-field form-control"  placeholder="Enter address line 2" tabindex="4"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                            
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PHONE 2</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.phone_2} name="phone_2" className="form-field form-control" tabindex="7" placeholder="+1-000-000-0000"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CITY</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.city} name="city" className="form-field form-control" tabindex="6" placeholder="Enter city name"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                                
                              </div>
                          
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">EMAIL</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.email} name="email" className="form-field form-control" tabindex="9" placeholder="Enter email id"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6 mrtop-10">
                              <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">STATE</label>
                                  </div>
                                   <div className="col-md-8">
                                    <select onChange={this.handleChangeState} type="text" value={this.state.selected_state}  name="state" className="form-field form-control" tabindex="8"  placeholder="Enter state name">
                                      <option key="select_state" value="" >Select State</option>
                                    {(states.length > 0) ? states.map( (value, index) => {
                                     return (
                                        <option key={index} value={value}>{value}</option>
                                      )
                                    }) : ''}
                                    </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                               
                              </div>
                           
                              <span id="resetAgentToggle" onClick={this.resetAgentToggle}></span>
                              <div className={"mrtop-10 col-md-6 "+this.state.agent_toggle} id="agent-name-blk">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">AGENT NAME</label>
                                  </div>
                                  <div className="col-md-8">
                                   {/*  <input type="text" className="form-field form-control" placeholder="Enter Agent Name" name="agent_name"  defaultValue={this.state.contact_list.agent_name}/>
 */}
                                    <ContactSelect className="form-field" name="agent_name" defaultValue={(this.state.contact_list && this.state.contact_list.agent_id)?this.state.contact_list.agent_id:''} tabindex={7} label="Select Agent" type="5"/>

                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                               <div className="col-md-6 mrtop-10">
                               <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PIN CODE</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.pin} name="pin" className="form-field form-control" placeholder="Enter pin code" tabindex="10"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              
                              </div>
                          
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">STATUS</label>
                                  </div>
                                  <div className="col-md-8">
                                    <select className="form-field form-control" name="status"  value={this.state.contact_list.status} onChange={this.handleChangeStatus}>
                                      <option value="1">Active</option>
                                      <option value="10">Inactive</option>
                                    </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              
                              
                              <div className="col-md-6 mrtop-10">
                                 <div className="row">
                                  <div className='col-md-3'>
                                    <label className="lbl">GROUP</label>
                                  </div>
                                  <div className='col-md-8'>
                                    <TagFields target="groups" default={this.state.contact_list.group_name}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              
                              {(this.state.contact_list.type==='1' || this.state.contact_list.type===1) &&
                              <div className="col-md-6 mrtop-10">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CUSTOMER TYPE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <select className="form-field form-control" name="customer_type" defaultValue={this.state.contact_list.customer_type}>
                                      <option value="retail">Retail</option>
                                      <option value="wholesale">Wholesale</option>
                                    </select>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              }
                            </div>

                        </div>
                     </div>
                     <div className="content-head border-bottom">
                        <b>Additional Information</b>
                        <p className="info">This information can be edited from your profile page.</p>
                     </div>
                     <div className="main-content  border-bottom">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">GST NUMBER</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.gst_number} name="gst_number" className="form-field form-control" placeholder="Enter GST number" tabindex="11"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">BANK NAME</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text"  defaultValue={this.state.contact_list.bank_name} name="bank_name" className="form-field form-control" placeholder="Enter bank name" tabindex="12"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">PAN NUMBER</label>
                                  </div>
                                  <div className="col-md-8">
                                   <input type="text" name="pan_no"  defaultValue={this.state.contact_list.pan_no} className="form-field form-control"  placeholder="Enter PAN number" tabindex="13"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">A/C NAME</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" defaultValue={this.state.contact_list.account_name} tabindex="14" name="account_name" className="form-field form-control"  placeholder="Enter account name"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">MODE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <PaymentModes show="dropdown" name="payment_mode"  default={this.state.contact_list.payment_mode} tabindex="15"  target="contact"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">A/C NUMBER</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" name="account_no"  defaultValue={this.state.contact_list.account_no} className="form-field form-control"  placeholder="Enter account number" tabindex="16"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">OPENING BALANCE</label>
                                  </div>
                                   <div className="col-md-3">
                                    <div className="form-group" style={{margin: '-5px 0px 0px 0px'}}>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="opening_balance_type" id="opening_balance_credit" value="credit" defaultChecked={(this.state.opening_balance_type==='credit' || this.state.opening_balance_type=='' || this.state.opening_balance_type==null)?true:false} disabled={is_opening_balance_change}/>
                                        <label className="form-check-label" htmlFor="opening_balance_credit">
                                          Payable (-)
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="opening_balance_type" id="opening_balance_debit" value="debit" disabled={is_opening_balance_change}/>
                                        <label className="form-check-label" htmlFor="opening_balance_debit">
                                          Receivable (+)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <input type="text" name="opening_balance"  defaultValue={(this.state.contact_list && this.state.contact_list.opening_balance!=="")?this.state.contact_list.opening_balance:0} className="form-field form-control" placeholder="100,000,00" tabindex="17" disabled={is_opening_balance_change}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">IFSC CODE</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" name="ifsc_code"  defaultValue={this.state.contact_list.ifsc_code} className="form-field form-control"  placeholder="Enter ifsc code" tabindex="18"/>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">OPENING BALANCE DATE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="input-group">
                                      <div className="input-group-prepend" style={{width:'100%'}}>
                                        <span className="input-group-text no-border-right" disabled={is_opening_balance_change}>
                                          <FontAwesomeIcon icon={faCalendarAlt} />
                                        </span>
                                        <DatePicker className="form-control  no-border-left form-field" selected={this.state.openingBalanceDate} onChange={this.openingBalanceDate} tabindex="19" dateFormat="dd/MM/yyyy" name="opening_balance_date" disabled={is_opening_balance_change}/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                             
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">BRANCH ADDRESS</label>
                                  </div>
                                   <div className="col-md-8">
                                    <input type="text" name="bank_address"  defaultValue={this.state.contact_list.bank_address} className="form-field form-control" tabindex="20" placeholder="Enter branch address"/>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">ORDERS OPENING BALANCE</label>
                                  </div>
                                    <div className="col-md-3">
                                    <div className="form-group" style={{margin: '-5px 0px 0px 0px'}}>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="orders_opening_balance_type" id="orders_opening_balance_credit" value="credit" defaultChecked={(this.state.orders_opening_balance_type==='credit' || this.state.orders_opening_balance_type=='' || this.state.orders_opening_balance_type==null)?true:false} disabled={is_orders_opening_balance_change}/>
                                        <label className="form-check-label" htmlFor="orders_opening_balance_credit">
                                          Payable (-)
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="orders_opening_balance_type" id="orders_opening_balance_debit" value="debit" disabled={is_orders_opening_balance_change}/>
                                        <label className="form-check-label" htmlFor="orders_opening_balance_debit">
                                          Receivable (+)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <input type="text" name="orders_opening_balance"  defaultValue={(this.state.contact_list && this.state.contact_list.orders_opening_balance!=="")?this.state.contact_list.orders_opening_balance:0} className="form-field form-control" placeholder="100,000,00"  disabled={is_orders_opening_balance_change}/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">LIMIT</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" name="credit_limit"  defaultValue={(this.state.contact_list && this.state.contact_list.credit_limit!="")?this.state.contact_list.credit_limit:0} className="form-field form-control" placeholder="100,000,00"/>
                                  </div>
                                </div>
                              </div>                      
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="lbl">ORDERS OPENING BALANCE DATE</label>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="input-group">
                                        <div className="input-group-prepend" style={{width:'100%'}}>
                                          <span className="input-group-text no-border-right" disabled={is_opening_balance_change}>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                          </span>
                                          <DatePicker className="form-control  no-border-left form-field" selected={this.state.ordersOpeningBalanceDate} onChange={this.ordersOpeningBalanceDate}  dateFormat="dd/MM/yyyy" name="opening_balance_date"/>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                </div>
                                
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="lbl">CITY</label>
                                    </div>
                                      <div className="col-md-8">
                                      <input type="text" name="bank_city" className="form-field form-control" defaultValue={this.state.contact_list.bank_city} placeholder="Enter city"/>
                                    </div>
                                  </div>
                                </div>                    
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="lbl">CREDIT DATE</label>
                                  </div>
                                  <div className="col-md-8">
                                    <input type="text" name="credit_days"  defaultValue={this.state.contact_list.credit_days} className="form-field form-control" placeholder="45 Days" tabindex="23"/>
                                  </div>
                                  <div className="col-md-1"></div>
                                </div>
                              </div>
                              
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">DISCOUNT</label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="input-group">
                                      <input type="text" tabindex="24" name="discount" className="no-border-right form-field form-control" defaultValue={this.state.contact_list.discount} placeholder="Enter Discount"/>
                                      <div className="input-group-append">
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mrtop-30">
                              <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="lbl">NOTIFICATION</label>
                                    </div> 
                                    <div className="col-md-8">
                                     <select className="form-control form-field" onChange={this.changeNotification} name="notification" value={this.state.notification} tabindex="25">
                                       <option value="0">No</option>
                                       <option value="1">Yes</option>
                                     </select>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="lbl">NOTES</label>
                                  </div>
                                   <div className="col-md-8">
                                    <textarea name="notes" className="form-field form-control" defaultValue={notes} tabindex="26"></textarea>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                        </div>
                     </div>
                     {(this.state.custom_fields_json.length>0) &&
                       <>
                        <div className="content-head border-bottom">
                          <b>Custom Data</b>
                          <p className="info">Custom Data added from settings.</p>
                        </div>
                        <div className="main-content  border-bottom">
                            <div className="inner-content">
                                    <div className="row">
                                        {this.state.custom_fields_json.map((value,index) => {
                                            return (
                                                  <>
                                                    {((index+1)%2!==0) &&
                                                        <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                <label className="lbl" style={{textTransform:'uppercase'}}>{value.name}</label>
                                                                </div>
                                                                <div className="col-md-8">
                                                                <input type="text" tabindex={27+index} value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField} className="form-control" placeholder={"Enter "+value.name}/>
                                                                </div>
                                                                <div className="col-md-1"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {((index+1)%2===0) &&
                                                      <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                          <div className="row">
                                                              <div className="col-md-4">
                                                              <label className="lbl" style={{textTransform:'uppercase'}}>{value.name}</label>
                                                              </div>
                                                              <div className="col-md-8">
                                                              <input type="text" tabindex={27+index} value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField} className="form-control" placeholder={"Enter "+value.name}/>
                                                              </div>
                                                          </div>
                                                      </div>
                                                    }
                                                  </>
                                                )
                                        })}
                                    </div>
                            </div>
                        </div>
                      </>
                    }
                     </form>  
                    <div className="content-head border-bottom">
                        <button type="button" id="update-contact-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        <button type="button" onClick={this.saveContact} className="btn btn-success btn-save-action" id="update-contact">Update Contact
                          <ShortcutHelp arrow="bottom" target="save-bill" shortcuts={['Ctrl+Enter']}/></button>
                    </div>
                  </div>
                </div>
              </div>
              <ModalBox id="add_contact_group" title="ADD CONTACT GROUPS" data_id="Contact Croups"/> 
            </div>
        );
    }
}