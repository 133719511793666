import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faSpinner, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import ReactWhatsapp from "react-whatsapp";
import axios from 'axios';
import utils from '../utils.js';
let {app_api,user_info} = require('../library.js');
export default class ShareOptions extends Component {
    constructor() {
        super();
        this.state = {
            active_tab : '',
            is_share_email_clicked : false,
            is_email_success : 0,
        };
    }

    handleShareToggle = (e) =>{
        let id = e.currentTarget.id;
        document.getElementById("slis-"+id).classList.toggle('hide');
        this.setState({is_share_email_clicked:false});
        this.setState({is_email_success:0});
    }
    handleShareEmail = (e) =>{
        let id = this.props.share.id;
        let sharable_link = this.props.share.sharable_link;
        this.setState({is_share_email_clicked:true});
    
         if(id!=="" && id!==null){
            let body = {  
                id : id,
                sharable_link : sharable_link,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            }
            let api = app_api+'/share_bill_through_email';
            axios.post(api, body)
                .then(function (response) {
                    console.log(response);
                    let resp = response.data;
                    console.log(resp);
                    this.setState({is_share_email_clicked:false});
                    this.setState({is_email_success:resp.success});
                    setTimeout(() => {
                        document.getElementById(this.props.id).click();
                    },2000);
            }.bind(this)).catch(function(res){
            
            })
        }
    }

    render() {
        return (
            <div className="share-opt-blk">
                {(this.props.target==='dropdown') &&
                <div className="share-dd">
                    <div className="share-dd-clk" onClick={this.handleShareToggle} id={this.props.id}>{this.props.share.label}</div>
                    <ul className="list-group hide" id={'slis-'+this.props.id}>
                        {(this.props.share.whatsapp_phone!==false) &&
                            <li className="list-group-item"><ReactWhatsapp number={this.props.share.whatsapp_phone} message={this.props.share.whatsapp_msg} element="a">WhatsApp</ReactWhatsapp></li>
                        }
                       {/*  <li  className="list-group-item" onClick={this.handleShareEmail}>
                            {(this.state.is_share_email_clicked===false && this.state.is_email_success===0) &&
                                <span>Email</span>
                            }
                            {(this.state.is_share_email_clicked===true && this.state.is_email_success===0) &&
                                <FontAwesomeIcon icon={faSpinner} pulse/>
                            }
                            {(this.state.is_share_email_clicked===false && this.state.is_email_success===1) &&
                            <span>
                                <FontAwesomeIcon icon={faCheckCircle}/> Sent
                            </span>
                            }
                            {(this.state.is_share_email_clicked===false && this.state.is_email_success===10) &&
                                <span>
                                    <FontAwesomeIcon icon={faTimesCircle} style={{color:'red'}}/> Not Sent
                                </span>
                            }
                        </li> */}
                    </ul>
                </div>
                }
            </div>
        );
    }
}