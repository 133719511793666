import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import utils from '../utils.js';
import Link from "./link";
import DrawerLink from "./open-drawer-link";
import AdjustablePrint from "./adjustable-print";
export default class ViewAjustedInfo extends Component {
    constructor(props) {
        super();
        this.state = {
           is_print : false
        };
        this.isMobile = utils.isMobile.bind(this);      
    }


    componentDidMount = ()=>{
       
    }
    handlePrint = ()=>{
        this.setState({is_print:true});
        let _this = this;
        setTimeout(() => {
            _this.setState({is_print:false});
        }, 100);
    } 
    render() {
        let total_pending = 0;
        let total_payments = 0;
        let total_others =0;
        return (
                <>
                    <div className="view-on-fly" id="page-content-wrapper">
                        <nav className="navbar border-bottom">
                            <div className="row">
                                <div className="col-md-6 mrtop-15 action-area">
                                   <h4>ADJUSTED INFO</h4>
                                </div>
                                <div className="col-md-6 mrtop-15 action-area text-right">
                                    <div className="e-p-p-opt div-display" style={{marginRight:'25px'}}>
                                       {/*  <div  onClick={this.handleEditCheque} id="edit-cheque" className="eppopt">
                                        <a id="edit-cheque-lbl">Edit</a>
                                        </div>
                                        <div  onClick={this.handlePrint} id="preview" className="eppopt">
                                            <a id="preview-lbl">Preview</a>
                                        </div> */}
                                        <div  onClick={this.handlePrint} id="print" className="eppopt">
                                        <a id="print-lbl">Print</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid page-break">
                            <div className="content-area">
                                <div className="content-head">
                                    <div className="row border-bottom">
                                        <div className="col-md-6">
                                            <h3 style={{margin:'0px'}}>{this.props.adjusted_data.party_info.name}</h3>
                                            {(this.props.adjusted_data.party_info.phone_1!=="") &&
                                                <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>
                                                    {this.props.adjusted_data.party_info.phone_1}
                                                    {(this.props.adjusted_data.party_info.phone_1!=="" && this.props.adjusted_data.party_info.phone_2!=="" && this.props.adjusted_data.party_info.phone_2!==null) &&
                                                        <> | </>
                                                    }
                                                    {this.props.adjusted_data.party_info.phone_2}
                                                </p>
                                            }
                                            {(this.props.adjusted_data.party_info.email!=="") &&
                                                <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>
                                                    {this.props.adjusted_data.party_info.email}
                                                </p>
                                            }
                                            {(this.props.adjusted_data.party_info.address_1!=="") &&
                                                <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>
                                                    {this.props.adjusted_data.party_info.address_1}
                                                </p>
                                            }
                                            {(this.props.adjusted_data.party_info.address_2!=="") &&
                                                <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>
                                                    {this.props.adjusted_data.party_info.address_2}
                                                </p>
                                            }
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <h3 style={{color:'gray',margin:'0px'}}>{this.props.adjusted_data.adjusted_sequence}</h3>
                                            <p style={{margin:'0px',color:'gray',fontSize:'18px'}}>{this.props.adjusted_data.created_date}</p>
                                        </div>
                                    </div>
                                   {/* <div className="row text-center mrtop-10 border-bottom">
                                    <div className="col-md-3 border-right">
                                        <div>
                                            <b>PENDING AMOUNT</b>
                                            <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.pending_amount)}</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3 border-right">
                                        <div>
                                            <b>( - ) PAYMENTS</b>
                                            <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.payment_amount)}</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3 border-right">
                                        <div>
                                            <b>( - ) RTGS/CR-DR NOTE</b>
                                            <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.rtgs)}</h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3 ">
                                        <div>
                                            <b>( - ) OTHER CHARGES</b>
                                            <h4>₹{utils.currency_format(this.props.adjusted_data.meta_value.other_charges)}</h4>
                                        </div>
                                    </div>
                                   </div> */}
                                   <div className="row mrtop-30 border-bottom">
                                        <div className="col-md-12">
                                            <h4>BILL SUMMARY</h4>
                                        </div>
                                   </div>
                                   <div className="row mrtop-10">
                                        <div className="col-md-4">
                                            <b>BILLS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.adjusted_data.pending_bills.map((value,idx)=>{
                                                        total_pending = total_pending + parseFloat(value.adjusted_amount);
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                    <p style={{margin:'0px',color:'gray'}}>₹{utils.currency_format(value.grand_total)}</p>
                                                                </td>
                                                                <td>
                                                                    ₹{utils.currency_format(value.adjusted_amount)}</td>
                                                            </tr>
                                                        )
                                                   }))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_pending)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <b>RETURNS/DR-CR NOTES</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.others && this.props.adjusted_data.adjusted_bills.others.map((value,idx)=>{
                                                    total_others = total_others + parseFloat(value.adjusted_amount);
                                                        return (
                                                           
                                                                <tr>
                                                                    <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                </tr>
                                                          
                                                        )
                                                   }))}
                                                   {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.others && this.props.adjusted_data.adjusted_bills.others.length===0) &&
                                                   <tr>
                                                        <td colSpan={2}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_others)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <b>PAYMENTS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Payment #</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.payments && this.props.adjusted_data.adjusted_bills.payments.map((value,idx)=>{
                                                    total_payments = total_payments + parseFloat(value.adjusted_amount);
                                                        return (
                                                          
                                                                <tr>
                                                                    <td>
                                                                        <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.payment_mode}</span>
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                </tr>
                                                           
                                                        )
                                                   }))}
                                                    {(this.props.adjusted_data.adjusted_bills && this.props.adjusted_data.adjusted_bills.payments && this.props.adjusted_data.adjusted_bills.payments.length===0) &&
                                                   <tr>
                                                        <td colSpan={2}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_payments)}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.is_print) &&
                        <AdjustablePrint adjusted_data={this.props.adjusted_data}/>
                    }
                </>
        );
    }
   
}