import React, { Component } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class StockTransferNotification extends Component {
    constructor() {
        super();
        this.state = {
            notif_count :0,
            id :0
        };
    }
    componentDidMount(){
      this.handleGetNotification();
    }
    
  
      handleGetNotification = () =>{
        
         let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
        
          let api = app_api+'/stock_transfer_notification';
          axios.post(api, body)
            .then(function (response) {
                let notif_count = response.data.notif_count;
                let id = response.data.id;
                this.setState({id})
                this.setState({notif_count})
            }.bind(this)).catch(function(res){
            
          }.bind(this))
      }

      handleOpenStockTransferReqeust = () =>{
        let id= this.state.id;
        localStorage.setItem('stock_transfer_id',id);
        document.getElementById("handleOpenStockTransferReqeust").click();
      }

    render() {
        return (
            <>
            {(this.state.notif_count>0) &&
            <span style={{background:'orange',color:'#fff',padding:'5px',borderRadius:'5px',cursor:'pointer'}} onClick={this.handleOpenStockTransferReqeust} >
               <span>{this.state.notif_count}</span> <FontAwesomeIcon icon={faCloudDownloadAlt}/>
            </span>
            }
            </>
        );
    }
}