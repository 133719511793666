import React, { Component } from "react";
import DrawerPopup from './drawer.js';
import utils from '../utils.js';
export default class DrawerLink extends Component {
    constructor(props) {
      super();
      this.state = {
        drawer_data : '',
        drawer_width: (utils.isMobile()===true)?'100%':(props.width!==undefined)?props.width:'65%',
      };
    }
    handleClickOpenLink = e =>{
      let url = e.currentTarget.getAttribute("data-url");
      let schema = e.currentTarget.getAttribute("data-schema");
      let target = e.currentTarget.getAttribute("target");
      let info = this.props.info;
      let page_title = this.props.page_title;
      this.setState({drawer_data:{url:url,schema:schema,target:target,info:info,page_title:page_title}});
    }
    handleUnsetDrawerData = (e) =>{
      this.setState({drawer_data:''});
    }
    render() {
        return (
            <>
         
              <DrawerPopup position="right" width={this.state.drawer_width} data={this.state.drawer_data} unsetDrawerData={this.handleUnsetDrawerData}/>
              <a target={this.props.target} data-url={this.props.url} data-schema={this.props.schema} onClick={this.handleClickOpenLink} className="drawer-link" id={"drawer-link-"+this.props.name}>{this.props.name}</a>
           </>
        );
    }
}