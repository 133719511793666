import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight,faSpinner} from '@fortawesome/free-solid-svg-icons';
import Notification from "./notification.js";
import check_access from './access-level.js';
import Autocomplete from "./autocomplete";
import ProductUnits from "./product-units";
import ServiceLoader from './service-loader.js';
import ModalBox from "./modal-box";
import utils from '../utils.js';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
export default class EditVariant extends Component {
    constructor() {
      super();
      this.state = {
        show_loader : true,
        product_image : 'https://app.business-booster.net/app/uploads/no-image.png',
        profit:0,
        margin:0,
        inventory_item:'',
        variantList:[],
        inventory_status:'',
        category_name:'',
        active_variant : 0,
        variationData:[],
        is_option1 : false,
        is_option2 : false,
        is_option3 : false,
        product_id : 0,
        options_head : [],
        lspm : 0,
        show_inventory_custom_field : false,
        custom_fields_json:[]
      };
      _this = this;
      this.variantList = [];
      this.variationData = [];
      this.newVariantData = {
        availability: 0, barcode_name: '',alias_barcode: '', category_id: 0, cgst: '', custom_text: '', discount: '', hsn: '', igst: '', image: "https://app.business-booster.net/app/uploads/no-image.png", inventory_id: 0, inventory_status: "", item_desc: '', item_name: "", lowest_selling_price: 0, option1: '', option2: '', option3: "", option4: '', option5: '', pp_tax_type: '', product_unit_id: 0, purchase_id: null, purchase_price: "", quantity: 0, selling_price: "", sgst: '', sku: "", sp_discount: "", sp_tax_type: "", tax: '', total: '', value1: "", value2: "", value3: "", value4: '', value5: ''
      }
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
      this.handleCalculation = this.handleCalculation.bind(this);
      this.handleVariantNavigation = this.handleVariantNavigation.bind(this);
      this.onFileChange = this.onFileChange.bind(this);
      this.onFileClick = this.onFileClick.bind(this);
      this.handleVariationSave = this.handleVariationSave.bind(this);
      this.handleModifyData = this.handleModifyData.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleAutoMSPCalculate = this.handleAutoMSPCalculate.bind(this);
    }
    handleSetCustomField = (e) =>{
      let thisvalue = e.currentTarget.value;
      let target = e.currentTarget.getAttribute('data-target');
      let idx = e.currentTarget.getAttribute('data-id');
      let custom_fields_json =  this.state.custom_fields_json;
      custom_fields_json[idx][target] = thisvalue;
      this.setState({custom_fields_json:custom_fields_json});
  }
    handleAutoMSPCalculate(e){
      let lowest_sp = document.getElementById("hid-minimum-selling-price").value;
      let auto = document.getElementById("auto");
      if(auto.checked===true){
        document.getElementById("minimum-selling-price").setAttribute('disabled','true');
        let purchase_price = document.getElementById("purchase-price").value;
        let selling_price = document.getElementById("selling-price").value;
        let lspm =  this.state.lspm;

        if(selling_price===""){
          selling_price = 0;
        }

        if(purchase_price===""){
          purchase_price = 0;
        }else{
          purchase_price = parseFloat(purchase_price);
        }

        let cal = purchase_price*lspm/100;
        lowest_sp = purchase_price+cal;

        if(selling_price<lowest_sp){
          lowest_sp = selling_price;
        }
        this.state.variationData.is_auto_msp = 0;
      }else{
        document.getElementById("minimum-selling-price").removeAttribute('disabled');
        this.state.variationData.is_auto_msp = 1;
      }
      document.getElementById("minimum-selling-price").value =  this.floating_point(lowest_sp);
      this.state.variationData.lowest_selling_price = this.floating_point(lowest_sp);
    }
    deleteRow(event){
        var element = document.getElementById('delete_confirm');
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value='variant';
        document.getElementById("deleteIndex").value=this.state.active_variant;
        document.getElementById("dataIndex").value=this.state.product_id;
        document.getElementById("deleteTarget").value='delete_inventory';
    }

    handleModifyData(e){
      let value = e.currentTarget.value;
      let target = e.currentTarget.getAttribute('data-target');
      let active_variant_id = parseInt(this.state.active_variant);
      if(active_variant_id===0){
        let option1 = '';
        let value1 = '';
        if(document.getElementById("item-option1")){
          let v_option1 = document.getElementById("item-option1");
          option1 = v_option1.getAttribute('data-id');
          value1 = v_option1.value;
        }

        let option2 = '';
        let value2 = '';
        
        if(document.getElementById("item-option2")){
          let v_option2 = document.getElementById("item-option2");
          option2 = v_option2.getAttribute('data-id');
          value2 = v_option2.value;
        }

        let option3 = '';
        let value3 = '';
        
        if(document.getElementById("item-option3")){
          let v_option3 = document.getElementById("item-option3");
          option3 = v_option3.getAttribute('data-id');
          value3 = v_option3.value;
        }

        let v_item_name = value1+'/'+value2+'/'+value3;
        v_item_name = v_item_name.replace(/\/+$/g, '');
        this.variationData[active_variant_id][0].item_name = v_item_name;
      }



      this.variationData[active_variant_id][0][target] = value;
      

      this.setState({variationData:this.variationData[active_variant_id][0]});
    
      let is_exist = false;
      for(let i=0;i<this.variantList.length;i++){
          let inventory_id = this.variantList[i].inventory_id;
          if(inventory_id===active_variant_id){
              this.variantList[i][target] = this.variationData[active_variant_id][0][target];
              is_exist = true;
              break;
          }
      }
      if(!is_exist){
        this.variantList.push(this.variationData[active_variant_id][0]);
        this.setState({variantList:this.variantList});
      }else{
        this.setState({variantList:this.variantList});
      }
    }

    handleVariationSave(e){
      let id = e.currentTarget.id;
     
      let formData = new FormData();
      let upload_photo = document.getElementById("upload_photo").files[0];
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);

      formData.append('photo', upload_photo);
      formData.append('id', parseInt(this.state.product_id));
      formData.append('product_data', JSON.stringify(this.variantList));
      formData.append('parent_name', this.state.inventory_item.item_name);
      formData.append('custom_fields', JSON.stringify(this.state.custom_fields_json));
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let api = app_api+'/update_variation';
      axios.post(api, formData)
        .then(function (response) {
           let resp = response.data;
           let expires = resp.expire;
           localStorage.setItem('fetch_inv',expires);
           document.getElementById(id).classList.remove("hide");
           document.getElementById(id+'-btn-spinner').classList.add("hide");
           if(resp.success===1){
            window.location.href = "/inventories";
           }
      }).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }

    onFileChange(e){
      let id = parseInt(this.state.active_variant);
      this.setState({ selectedFile: e.target.files[0] });
      let output = URL.createObjectURL(e.target.files[0]);
      
      let v_data = this.variationData[id][0];
      v_data.image = output
      this.setState({variationData:v_data});
      if(id!==0){
        let photoData = new FormData();
        let inventory_ids = [];
        inventory_ids.push(id);
        let upload_photo = document.getElementById("upload_photo").files[0];
            
        photoData.append(`photo[${id}][0]`, upload_photo);
        photoData.append('store_name',user_info.store_name);
        photoData.append('branch_id',user_info.login_id);
        photoData.append('user_id',user_info.user_id);
        photoData.append('uuid', utils.uuid());
        photoData.append('role_id', user_info.access_level);
        photoData.append('token', user_info.token);
        photoData.append('_schema', user_info._schema);

        photoData.append('inventory_ids',inventory_ids);
        photoData.append('type', 'variation');
        photoData.append('deleted_images','');
        
        let api = app_api+'/upload_inventory_image';
        axios.post(api, photoData)
          .then(function (response) {
            document.getElementById('upload_photo').value='';
        }.bind(this)).catch(function(res){
            
        })
      }
    }
    onFileClick(e){
      document.getElementById("upload_photo").click();
    }


    handleVariantNavigation(e){
      let id = e.currentTarget.id;
      let did = e.currentTarget.getAttribute('data-id');
      let v_data = this.variationData[did][0];
      
      Object.keys(v_data).forEach(function(key) {
        if(document.querySelector("[data-target="+key+"]")){
          document.querySelector("[data-target="+key+"]").value= v_data[key];
        }
        //arr.push(json[key]);
      });

      this.setState({variationData:v_data});
      this.setState({active_variant:did});
      let list_items = document.querySelectorAll(".var-list");
      for(let i=0;i<list_items.length;i++){
        list_items[i].classList.remove('active');
      }
      document.getElementById(id).classList.add('active');
      let purchase_price = v_data.purchase_price;
      let selling_price = v_data.selling_price;
      let custom_fileds = v_data.custom_fileds.custom_fields;
      this.setState({custom_fields_json:custom_fileds});
      if(purchase_price!=="" && selling_price!==""){
        purchase_price = parseFloat(purchase_price);
        selling_price = parseFloat(selling_price);
        let profit = selling_price-purchase_price;
        let margin = (profit/selling_price)*100;
        this.setState({profit:profit,margin:margin});
      }
    }

    handleCalculation(e){
      let id = e.currentTarget.id;
      let purchase_price = document.getElementById("purchase-price").value;
      let selling_price = document.getElementById("selling-price").value;
      if(purchase_price!=="" && selling_price!==""){
        purchase_price = parseFloat(purchase_price);
        selling_price = parseFloat(selling_price);
        let profit = selling_price-purchase_price;
        let margin = (profit/selling_price)*100;
        this.setState({profit:profit,margin:margin});
      }
      if(id==='purchase-price'){
        this.handleAutoMSPCalculate(e);
      }
      this.handleModifyData(e);
    }

    componentDidMount(){
      let curl = window.location.href;
      const view_inventory = curl.match(/variant\/edit\/(\d+)\/(\d+$)/i);
      
      if(view_inventory){
        let product_id = parseInt(view_inventory[1]);
        let variant_id = parseInt(view_inventory[2]);
        this.setState({active_variant:variant_id,product_id:product_id})
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              id : product_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_product_on_id';
      
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;

              let varient = resp[0].varient;
              let inventory_status = resp[0].inventory_status;

              let variation_list = resp.inventory_meta;
              let options_head = resp.option_head;
              let additional_data = resp.additional_data;
              for (let index = 0; index < variation_list.length; index++) {
                const element = variation_list[index];
                let var_id = element.inventory_id;
                if(variant_id===var_id){
                  let custom_fileds = element.custom_fileds.custom_fields;
                  this.setState({custom_fields_json:custom_fileds});
                }
              }
              if(additional_data.custom_fields!==undefined){
                //this.setState({custom_fields_json:additional_data.custom_fields});
              }
              this.setState({product_image:resp.inventory_image,inventory_status:inventory_status,category_name:resp.category_name,options_head,options_head,lspm:resp.lspm});
              this.setState({show_loader:false});
              if(variant_id===0){
                if(this.variationData[variant_id]===undefined){
                  this.variationData[variant_id] = [];
                }
                this.newVariantData.category_id = resp[0].category_id;
                this.newVariantData.product_unit_id = resp[0].product_unit_id;
                this.newVariantData.purchase_id = resp[0].purchase_id;
                this.newVariantData.purchase_price = resp[0].purchase_price;
                this.newVariantData.purchase_price = resp[0].purchase_price;
                this.newVariantData.selling_price = resp[0].selling_price;
                this.newVariantData.lowest_selling_price = resp[0].lowest_selling_price;
                this.newVariantData.discount = resp[0].discount;
                this.newVariantData.sp_discount = resp[0].sp_discount;
                this.newVariantData.pp_tax_type = resp[0].pp_tax_type;
                this.newVariantData.sp_tax_type = resp[0].sp_tax_type;
                this.newVariantData.item_desc = resp[0].item_desc;
                this.newVariantData.inventory_status = resp[0].inventory_status;
                
                for(let i=0;i<options_head.length;i++){
                    if(i==0){
                      this.newVariantData.option1 = options_head[i];
                      this.setState({is_option1:true});
                    }else if(i==1){
                      this.newVariantData.option2 = options_head[i];
                      this.setState({is_option2:true});
                    }else if(i==3){
                      this.newVariantData.option3 = options_head[i];
                      this.setState({is_option3:true});
                    }else if(i==4){
                      this.newVariantData.option4 = options_head[i];
                    }else if(i==5){
                      this.newVariantData.option5 = options_head[i];
                    }
                }

                this.variationData[variant_id].push(this.newVariantData);
              }


              if(variation_list.length>0){
                for(let i=0;i<variation_list.length;i++){
                  if(i===0){
                    let selling_price = variation_list[i].selling_price;
                    let purchase_price = variation_list[i].purchase_price;

                    purchase_price = parseFloat(purchase_price);
                    selling_price = parseFloat(selling_price);
                    let profit = selling_price-purchase_price;
                    let margin = (profit/selling_price)*100;
                    this.setState({profit:profit,margin:margin});
                  }
                  this.variantList.push(variation_list[i]);
                  let vid = variation_list[i].inventory_id;
                  if(this.variationData[vid]===undefined){
                      this.variationData[vid] = [];
                  }
                  this.variationData[vid].push(variation_list[i]);
                }
              }
              this.setState({variantList:this.variantList});
            
              let var_data = this.variationData[variant_id][0];
              if(var_data.option1!=="" && var_data.option1!==null){
                this.setState({is_option1:true});
              } 
              if(var_data.option2!=="" && var_data.option2!==null){
                this.setState({is_option2:true});
              } 
              if(var_data.option3!=="" && var_data.option3!==null){
                this.setState({is_option3:true});
              }
              this.setState({variationData:var_data});
              
             if(resp[0]!==undefined){
              let selling_price = resp[0].selling_price;
              let purchase_price = resp[0].purchase_price;

              purchase_price = parseFloat(purchase_price);
              selling_price = parseFloat(selling_price);
              let profit = selling_price-purchase_price;
              let margin = (profit/selling_price)*100;
              
              //this.setState({profit:profit,margin:margin});

              this.setState({inventory_item:  resp[0]}); 
            }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }
      let set_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
          keys:"'show_inventory_custom_field'"
      }
      let opt_api = app_api+'/get_settings';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
          
          if(resp.show_inventory_custom_field!==undefined){
            let show_inventory_custom_field = resp.show_inventory_custom_field;
            this.setState({show_inventory_custom_field:show_inventory_custom_field});
          }
          //this.setState({settings:resp});
      }.bind(this)).catch(function(res){
          
      })
    }

    render() {
        return (
            <div className="d-flex view-variant" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                  <nav className="navbar border-bottom">
                    <div className="row">
                      <div className="col-md-12">
                        <Breadcrumbs link="/inventories" title="Innventory" sub_title="Edit Variant"/>
                      </div>
                    </div>
                  </nav>
                  <div className="row" style={{position:'relative'}}>
                    <ServiceLoader show={this.state.show_loader}/>
                    <div className="col-md-5">
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
            
                              <div style={{display:'flex'}}>
                                <div style={{width:'112px'}}>
                                  <div style={{height: '100px',width: '100px',border:'1px solid #d7d7d7'}}> 
                                    <div style={{backgroundImage:'url('+this.state.product_image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '80px',width: '80px',margin: '9px auto'}}></div>
                                  </div>
                                </div>
                                <div style={{width:'100%',marginLeft:'10px'}}>
                                    <h4 style={{color:'#656363'}}>
                                      {this.state.inventory_item.item_name}
                                      <span style={{fontSize: '16px',background:'#009688',padding: '5px',color: 'white',float: 'right',borderRadius: '5px'}}>{this.state.inventory_status}</span>
                                    </h4>
                                    <h5 style={{color:'gray',margin:'0px'}}>{this.state.category_name}</h5>
                                    <p style={{color:'gray',margin:'0px'}}>{(this.state.variantList && this.state.variantList.length>0)?this.state.variantList.length+' Variants':''}</p>
                                    <a style={{fontSize:'14px'}} href={"/products/view/"+this.state.product_id}>Back to Product</a>
                                </div> 
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid" style={{marginTop:'0px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px',borderBottom:'1px solid #d7d7d7'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4>Variants</h4>
                                </div>
                              </div>
                           </div>
                           <div className="main-content">
                              <div className="row">
                                <div className="col-md-12">
                                    <ul className="list-group var-list-item">
                                      {(this.state.variantList.length > 0) ? this.state.variantList.map( (value, index) => {
                                         return (
                                            <li className={(value.inventory_id===this.state.active_variant)?"list-group-item list-group-item-action var-list active":"list-group-item list-group-item-action var-list"} style={{paddingTop:'10px',paddingBottom:'10px',cursor:'pointer'}} id={"var-item-"+value.inventory_id}
                                              data-id={value.inventory_id} onClick={this.handleVariantNavigation}>
                                           
                                              <div style={{display:'contents'}}>
                                                <div style={{width:'48px',float:'left'}}>
                                                  <div style={{backgroundImage:'url('+value.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width: '40px',border:'1px solid #d7d7d7'}}></div>
                                                </div>
                                                <div>
                                                  <h5 style={{marginTop:'10px',color:'#676767'}}>
                                                    {value.item_name}
                                                    <FontAwesomeIcon icon={faChevronRight} style={{float:'right'}}/>
                                                  </h5>
                                                </div>
                                              </div>
                                             
                                            </li>
                                          )
                                        }) : ''}
                                      
                                    </ul>

                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="container-fluid" >
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4>Options</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-8">
                                    {(this.state.is_option1) &&
                                      <div className="row">
                                        <div className="col-md-12">
                                          <label htmlFor="item-option1">{(this.state.variationData.option1)?this.state.variationData.option1:''}</label>
                                          <input type="text" className="form-control" defaultValue={(this.state.variationData.value1)?this.state.variationData.value1:''} id="item-option1" data-id={(this.state.variationData.option1)?this.state.variationData.option1:''} onChange={this.handleModifyData} data-target="value1"/>
                                        </div>
                                      </div>
                                    }
                                    {(this.state.is_option2) &&
                                    <div className="row">
                                      <div className="col-md-12">
                                      <label htmlFor="item-option2">{(this.state.variationData.option2)?this.state.variationData.option2:''}</label>
                                      <input type="text" className="form-control" defaultValue={(this.state.variationData.value2)?this.state.variationData.value2:''} id="item-option2" data-id={(this.state.variationData.option2)?this.state.variationData.option2:''}  onChange={this.handleModifyData} data-target="value2"/>
                                      </div>
                                    </div>
                                    }
                                    {(this.state.is_option3) &&
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label htmlFor="item-option3">{(this.state.variationData.option3)?this.state.variationData.option3:''}</label>
                                        <input type="text" className="form-control" defaultValue={(this.state.variationData.value3)?this.state.variationData.value3:''} id="item-option3" data-id={(this.state.variationData.option3)?this.state.variationData.option3:''}  onChange={this.handleModifyData} data-target="value3"/>
                                      </div>
                                    </div>
                                    }
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div style={{width: '150px',height: '150px',border: '1px solid #d7d7d7',margin: '0 auto'}}>
                                          <div id="uploaded_photo" style={{backgroundImage:'url('+this.state.variationData.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height:'100%'}}></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mrtop-10">
                                        <input type="file" onChange={this.onFileChange} id="upload_photo" className="hide"/> 
                                        <button type="button" onClick={this.onFileClick}  className="btn btn-secondary" style={{width:'100%'}}>Add Image</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                       <div className="container-fluid" >
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4>Variant Info</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Variant Name</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="product-name" defaultValue={(this.state.variationData.item_name)?this.state.variationData.item_name:''}  onChange={this.handleModifyData} data-target="item_name"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Description</label>
                                </div>
                                <div className="col-md-5">
                                  <textarea id="product-description" className="form-control"  defaultValue={(this.state.variationData.item_desc)?this.state.variationData.item_desc:''}  onChange={this.handleModifyData} data-target="item_desc"></textarea>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>SKU</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="product-sku" defaultValue={(this.state.variationData.sku)?this.state.variationData.sku:''}  onChange={this.handleModifyData} data-target="sku"/>
                                </div> 
                                <div className="col-md-4">
                                 
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="product-barcode"  defaultValue={(this.state.variationData.barcode_name)?this.state.variationData.barcode_name:''} onChange={this.handleModifyData} data-target="barcode_name"/>
                                </div> 
                                <div className="col-md-4">
                                
                                </div>
                              </div> 
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Alias Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="alias-barcode"  defaultValue={(this.state.variationData.alias_barcode)?this.state.variationData.alias_barcode:''} onChange={this.handleModifyData} data-target="alias_barcode"/>
                                </div> 
                                <div className="col-md-4">
                                
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>HSN Code</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-hsn" className="form-control"  defaultValue={(this.state.variationData.hsn)?this.state.variationData.hsn:''} onChange={this.handleModifyData} data-target="hsn"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Part Name</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-partname" className="form-control"  defaultValue={(this.state.variationData.part_name)?this.state.variationData.part_name:''} onChange={this.handleModifyData} data-target="part_name"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div> 
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Custom Text</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="custom-text" className="form-control"  defaultValue={(this.state.variationData.custom_text)?this.state.variationData.custom_text:''} onChange={this.handleModifyData} data-target="custom_text"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Available</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="product-quantity" className="form-control" defaultValue={(this.state.variationData.availability)?this.state.variationData.availability:''} onChange={this.handleModifyData} data-target="availability"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid" style={{marginTop:'0px',marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4>Pricing</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Purchase Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="purchase-price"  onChange={this.handleCalculation} defaultValue={(this.state.variationData.purchase_price)?this.state.variationData.purchase_price:''} data-target="purchase_price"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Tax</label>
                                </div>
                                <div className="col-md-5">
                                  <input list="pp_tax" className="form-control" name="pp_tax" id="pp_tax_dd" defaultValue={(this.state.variationData.tax)?this.state.variationData.tax:''}  onChange={this.handleModifyData} data-target="tax"/>
                                  <datalist id="pp_tax">
                                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                       return (
                                        <option>{value.value}</option>
                                      )
                                     }) : '' }
                                  </datalist>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Tax Type</label>
                                </div>
                                <div className="col-md-5">
                                  <select className="form-control" id="pp-tax-type" value={(this.state.variationData.pp_tax_type)?this.state.variationData.pp_tax_type:''} onChange={this.handleModifyData} data-target="pp_tax_type">
                                    <option value="including">Including Tax</option>
                                    <option value="excluding">Excluding Tax</option>
                                  </select>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Discount</label>
                                </div>
                                <div className="col-md-5">
                                  <div className="input-group">
                                    <input type="text" className="form-control no-border-right" id="pp-discount" defaultValue={(this.state.variationData.discount)?this.state.variationData.discount:''} onChange={this.handleModifyData} data-target="discount"/>
                                    <div className="input-group-append">
                                      <span className="input-group-text pad-left-none">%</span>
                                    </div>
                                  </div>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div style={{marginTop:'10px',borderBottom:'2px dotted #d7d7d7'}}></div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Selling Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" className="form-control" id="selling-price" onChange={this.handleCalculation} defaultValue={(this.state.variationData.selling_price)?this.state.variationData.selling_price:''} data-target="selling_price"/>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Min. Selling Price</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" id="minimum-selling-price" className="form-control" defaultValue={(this.state.variationData.lowest_selling_price)?this.state.variationData.lowest_selling_price:''} onChange={this.handleModifyData} data-target="lowest_selling_price"/>

                                  <input type="hidden" id="hid-minimum-selling-price" className="form-control" defaultValue={(this.state.variationData.lowest_selling_price)?this.state.variationData.lowest_selling_price:''}/>
                                </div> 
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" name={"auto"} id={"auto"} value="auto"  onChange={this.handleAutoMSPCalculate} defaultChecked={this.state.variationData.is_auto_msp}/>
                                      <label className="form-check-label" htmlFor={"auto"}>
                                      Auto Calculate
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Tax</label>
                                </div>
                                <div className="col-md-5">
                                  <input list="sp_tax" className="form-control" name="sp_tax" id="sp_tax_dd" defaultValue={(this.state.variationData.sp_tax)?this.state.variationData.sp_tax:''} onChange={this.handleModifyData} data-target="sp_tax"/>
                                  <datalist id="sp_tax">
                                  
                                    {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                       return (
                                        <option>{value.value}</option>
                                      )
                                     }) : '' }

                                  </datalist>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Tax Type</label>
                                </div>
                                <div className="col-md-5">
                                  <select className="form-control" id="sp-tax-type" onChange={this.handleModifyData} value={(this.state.variationData.sp_tax_type)?this.state.variationData.sp_tax_type:''} data-target="sp_tax_type">
                                    <option value="including">Including Tax</option>
                                    <option value="excluding">Excluding Tax</option>
                                  </select>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label>Discount</label>
                                </div>
                                <div className="col-md-5">
                                  <div className="input-group">
                                    <input type="text" id="sp-discount" className="form-control no-border-right" defaultValue={(this.state.variationData.sp_discount)?this.state.variationData.sp_discount:''} onChange={this.handleModifyData} data-target="sp_discount"/>
                                    <div className="input-group-append">
                                      <span className="input-group-text pad-left-none">%</span>
                                    </div>
                                  </div>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row" style={{marginTop:'20px'}}>
                                <div className="col-md-6">
                                  <span>Margin : </span><span id="product-margin">{this.currency_format(this.state.margin)}%</span>
                                </div> 
                                <div className="col-md-6">
                                  <span>Profit : </span><span id="product-profit">₹{this.currency_format(this.state.profit)}</span>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      {(this.state.show_inventory_custom_field===true && this.state.custom_fields_json.length>0) &&
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Custom Fields</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <table className="table cftbl" style={{width:'100%'}}>
                               
                                      <thead>
                                        <tr>  
                                          <th>Name</th>
                                          <th>Value</th>
                                        </tr>
                                      </thead>
                                 
                                  <tbody>
                               
                                    {this.state.custom_fields_json.map((value,index) => {
                                      return (
                                        <>
                                        <tr key={'cfp-'+index}>
                                          <td>{value.name}</td>
                                          <td><input type="text" className="form-control" value={value.value} data-id={index} data-target="value" onChange={this.handleSetCustomField}/></td>
                                        </tr>
                                        </>
                                      )
                                    })}
                                  
                                 
                                  </tbody>
                                </table>
                              </div>
                           </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                  <div className="container-fluid" style={{width:'97%',marginTop:'0px'}}>
                    <div className="content-area">
                      <div className="main-content" style={{padding:'20px'}}>
                        <div className="row">
                          <div className="col-md-12">
                            {(this.state.active_variant!==0) &&
                            <button type="button"  className="btn btn-danger" onClick={this.deleteRow} >Delete</button>
                            }
                            <button type="button" id="save-product" className="btn btn-success pull-right" onClick={this.handleVariationSave} >Save Changes</button>
                            <button type="button" id="save-product-btn-spinner" className="pull-right hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalBox id="delete_confirm" title="Delete" data_id="Delete"/>
            </div>
        );
    }
}