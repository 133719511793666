import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
/*import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';*/
import * as serviceWorker from "./serviceWorker";
import KeyShortcut from "./components/key-shortcut.js";
let hostname = window.location.hostname;
if(hostname!=='localhost' && hostname!=='45.32.126.205'){
	Sentry.init({
		dsn: "https://8d62143325404b8fb20e526c0ff64bd9@o501492.ingest.sentry.io/5582677",
		autoSessionTracking: true,
		integrations: [
		new Integrations.BrowserTracing(),
		],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}
ReactDOM.render(
    <BrowserRouter>
    <>
        <App />
    	<KeyShortcut />
    </>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();