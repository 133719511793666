import React, {Component} from "react";
import axios from 'axios';
import encrypt_decrypt from './encrypt-decrypt.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
let {app_api} = require('../library.js');
export default class DemoLogin extends Component {

    constructor() {
        super();
        this.state = {
          Username: '',    
          Password: '',
        }
        
        this.Username = this.Username.bind(this);
        this.Password = this.Password.bind(this);
        this.encrypt = encrypt_decrypt.encrypt.bind(this);
        this.Login = this.Login.bind(this);
       
    }
    componentDidMount(){
      
    }
    Username(event) {
      this.setState({ Username: event.target.value })
    }
    Password(event) {
      this.setState({ Password: event.target.value })
    }
    
    Login(event) {
        let thisid = event.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let  username = this.state.Username;
        let  password = this.state.Password;
        if(username==='admin' && password==='magazine3'){
            let d = {username:'admin',password:'magazine3'};
            let login = this.encrypt(d);
            localStorage.setItem('is_auth',login);
            window.location.reload();
        }else{
          let thisid = event.currentTarget.id;
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
        }
    }
    handleKeyDown(e){
      if (e.keyCode === 13) {
        document.getElementById("login").click();
        
      }
    }
    render() {
        return (
            <form>
                <h3>Sign In Auth</h3>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text"  autoFocus onChange={this.Username}  className="form-control" placeholder="Enter Username" />
                    <p className="error" id="username"></p>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password"  onKeyDown={this.handleKeyDown} onChange={this.Password}  className="form-control" placeholder="Enter Password" />
                    <p className="error" id="password"></p>
                </div>
                <button type="button" id="login-btn-spinner" className="hide btn btn-primary btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                <button type="button" id="login" onClick={this.Login} className="btn btn-primary btn-block">Login</button>
             
            </form>
        );
    }
}