import React, {Component} from "react";
import axios from 'axios';
import ServiceLoader from './service-loader.js';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileDownload, faPrint} from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class PDFDownload extends Component {
    constructor() {
        super();
        this.state = {
            store_name : '',
            trasid : '',
            download_link :'',
            html :'',
            invoice_data : {},
            is_404: false,
            show_loader: true,
            is_view_clicked: false,
            upi_info:{merchant:'',upi:''}
        }
        this.isMobile = utils.isMobile.bind(this);
    }
   
      handleGetUPISettings = (store_name) =>{
        let keys = "'upi_info'";
          let set_data = {
              store_name:store_name,
              branch_id:1,
              uuid : utils.uuid(),
              keys:keys
          }
          let opt_api = app_api+'/get_settings';
          axios.post(opt_api, set_data)
            .then(function (response) {
              let resp = response.data;
              if(resp.upi_info!==undefined){
                let ps = resp.upi_info;
                this.setState({upi_info:ps});
              }
          }.bind(this)).catch(function(res){
              
          })
      }
    componentDidMount(){
        
        let curl = window.location.href;
        let curl_tmp = curl;
        curl = decodeURIComponent(curl)
        curl = curl.replace(' ','_');
        const pdf_link = curl.match(/pdf\/([a-z_A-Z_0-9]+)\/([a-z_A-Z_0-9-.pdf]+$)/i);
        if(pdf_link){
            let store_name = pdf_link[1].toLowerCase();
            this.setState({store_name:store_name})
            this.handleGetUPISettings(store_name);
            let file_name = pdf_link[2];
            this.setState({trasid:file_name});
            let body = {};
            body = {
                store_name : store_name,
                file_name : file_name,
                link : curl_tmp,
                branch_id :1
            }
            let api = app_api+'/generate_pdf';
            axios.post(api, body)
            .then(function (response) {
                this.setState({show_loader:false});
                if(response.data.download_link!==false){
                    this.setState({download_link:response.data.download_link});
                    this.setState({html:response.data.html});
                    this.setState({invoice_data:response.data.invoice_data});
                    setTimeout(() => {
                        if(document.getElementById("download-pdf")){
                            //document.getElementById("download-pdf").click();
                        }
                    }, 100);
                }else{
                    this.setState({is_404:true})
                }
                
            }.bind(this)).catch(function(res){
                if(res.response){
                    this.setState({loading_process:'Record(s) not found'});
                }
            })
        } 
    }
    handlePrintInvoice = (e) =>{
        var isMobile = this.isMobile();
        if(document.getElementById("invoice-block")){
            var divContents = document.getElementById("invoice-block").innerHTML; 
            try{
            var a = window.open('', '', 'height=1000,location=no,left=250px'); 
            a.document.open(); 
            a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

          
            a.document.write('<style type="text/css">@page{size:A4;}table{font-size:12px;}table{width:100%;margin-bottom:1rem;color:#212529 }table td,table th{padding:.75rem;vertical-align:top}table .item-head th {background-color: #D2691E;border-color: #D2691E;color: #ffffff;font-size: 12px;border-top: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;}.table tbody td{line-height: 0.9 !important;}</style>'); 
        
            a.document.write('</head>'); 
       
            if(isMobile){
                a.document.write('<body onload="window.print();">'); 
            }else{
                a.document.write('<body onload="window.print();" onafterprint="afterPrint()">'); 
                a.document.write('<script>function afterPrint()window.close();}</script>'); 
            }
            a.document.write(divContents); 
            a.document.write('</body></html>');
            a.document.close();
            
            }catch{}
        }
    } 
    handleViewInvoice = (e) =>{
        this.setState({is_view_clicked:true})
    }
    handleCloseView = (e) =>{
        this.setState({is_view_clicked:false})
    }
    render() {
        return (
           <>
           <ServiceLoader show={this.state.show_loader}/>
            {(this.state.is_404===true) &&
                <div id="notfound">
                <div className="notfound">
                  <div className="notfound-404">
                    <h1>Oops!</h1>
                    <h2>404 - The file can't be found</h2>
                  </div>
                  <a href="/">Go TO Homepage</a>
                </div>
              </div>
            }
            {(this.state.is_404===false) &&
            <>
                {(this.isMobile()===false || this.state.is_view_clicked===true) &&
                    <>
                        <div className="row" style={{background:'white',marginLeft:'0px',marginRight:'0px'}}>
                            
                            <div className="col-md-2" style={{padding:'0px'}}>
                                {(this.state.is_view_clicked===true) &&
                                    <nav class="navbar" style={{boxShadow:'4px 4px 4px rgb(0 0 0 / 3%)',display:'flex'}}>
                                   
                                            <div class="back-btn" style={{display: "flex",flexBasis: '10%',alignItems: 'center',justifyContent: 'center'}} onClick={this.handleCloseView}>
                                                <img style={{width:'30px'}} src="/back-arrow.png" />
                                            </div>
                                            <div style={{flexBasis: '90%',display: 'flex',justifyContent: 'center'}}>
                                                <h3 className="mrtop-15"><a href="https://bazaarerp.com/" style={{color:'#919191'}}><span style={{color:'black',fontWeight:'bold'}}>Bazaar</span><span style={{color:'#db631a',fontWeight:'bold'}}>ERP</span></a></h3>
                                            </div>
                                       
                                    </nav> 
                                }
                                {(this.state.is_view_clicked===false) &&
                                  
                                           
                                    
                                    <h3 className="mrtop-15 text-center"><a href="https://bazaarerp.com/" style={{color:'#919191'}}><span style={{color:'black',fontWeight:'bold'}}>Bazaar</span><span style={{color:'#db631a',fontWeight:'bold'}}>ERP</span></a></h3>
                                    
                                     
                                }
                            </div>
                            
                            <div className="col-md-8 mrtop-10">
                            {(this.state.is_view_clicked===false) &&
                                <div dangerouslySetInnerHTML={{__html: this.state.html}} style={{boxShadow:'0 2px 15px 3px rgb(0 0 0 / 31%)',overflow:'auto','padding':'30px',marginBottom:'150px'}} id="invoice-block"></div>
                            }
                            {(this.state.is_view_clicked===true) &&
                                <div dangerouslySetInnerHTML={{__html: this.state.html}} id="invoice-block" style={{zoom:'60%','padding':'30px',marginBottom:'150px'}}></div>
                            }
                            </div>
                            {(this.isMobile()===false) &&
                                <div className="col-md-2 mrtop-10">
                                    <a href={this.state.download_link} id="download-pdf" download><button type="button" className="btn btn-outline-secondary btn-block" style={{padding:'2px 5px'}}>Download PDF <FontAwesomeIcon icon={faFileDownload} /> </button></a>
                                    <button type="button" className="btn btn-outline-secondary mrtop-10 btn-block" onClick={this.handlePrintInvoice} style={{padding:'2px 5px'}}>Print <FontAwesomeIcon icon={faPrint} /> </button>
                                </div>
                            }
                        </div>
                        
                        {(this.state.is_view_clicked===false) &&
                        <div style={{width: '100%',position: 'fixed',bottom: '0',background: 'white',border: '1px solid #D9D4D4',boxShadow: '0px -4px 15px rgb(0 0 0 / 15%)'}}>
                            <div style={{display: 'flex',flexDirection: 'row',marginLeft: '20%',marginRight: '20%',height: '100px',justifyContent: 'space-between'}}>
                            <div style={{display: 'flex',flexDirection: 'column',flexBasis: '33.3%',justifyContent: 'center'}}>
                                {(this.state.invoice_data!==null && this.state.invoice_data!==undefined && this.state.invoice_data.total_pending_amount!==undefined) &&
                                    <>
                                    <div style={{color: '#687E96',fontSize: '14px'}}> Pending Amount </div>
                                    <div style={{color: '#28313B',fontSize: '16px'}}> ₹ {(this.state.invoice_data.total_pending_amount!==undefined)?this.state.invoice_data.total_pending_amount:0} </div>
                                   
                                    </>
                                }
                                {(this.state.invoice_data!==null && this.state.invoice_data!==undefined && this.state.invoice_data.total!==undefined) &&
                                <>
                                    <div style={{color: '#687E96',fontSize: '14px'}}> Invoice Amount </div>
                                    <div style={{color: '#28313B',fontSize: '16px'}}> ₹ {(this.state.invoice_data.total!==undefined)?this.state.invoice_data.total:0} </div>
                                </>
                                }
                            </div>
                           {/*  <div style={{width:'70%',textAlign:'right',paddingTop:'15px'}}>
                                    <QRCode
                                        size={70}
                                        style={{ height: "auto", maxWidth: "70", width: "70" }}
                                        value={`upi://pay?pn=wasim akhtar&pa=6300308905@ybl&am=1&url&pn=&tr=&tn=message`}
                                        viewBox={`0 0 70 70`}
                                    />
                            </div> */}
                            </div>
                        </div>
                        }
                        {(this.state.is_view_clicked===true) &&
                        <div style={{
                            marginTop: '15px',
                            background: 'white',
                            padding: '15px',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            position: 'fixed',
                            bottom: '0px',
                            left: '0px',
                            right: '0px',
                            minHeight: '7%',
                            boxShadow: '0px -1px 12px rgb(0 0 0 / 15%)'
                            }}>
                            <div style={{display: 'flex',flexDirection:'row',marginBottom:'15px'}}>
                                <div style={{width: '50%'}}>
                                    {(this.state.invoice_data.total_pending_amount) &&
                                    <>
                                        <div style={{color: '#687E96'}}> 
                                            <span>Pending Amount :</span>  
                                            <b style={{fontSize:'16px',color:'#333'}}>₹ {(this.state.invoice_data.total_pending_amount!==undefined)?this.state.invoice_data.total_pending_amount:0}</b>
                                        </div>
                                    </>
                                    }
                                    {(this.state.invoice_data.total) &&
                                    <>
                                        <div style={{color: '#687E96',fontSize: '14px'}}> Invoice Amount </div>
                                        <div style={{color: '#28313B',fontSize: '16px'}}> ₹ {(this.state.invoice_data.total!==undefined)?this.state.invoice_data.total:0} </div>
                                    </>
                                    }
                                </div>
                               
                                <div style={{width: '50%',textAlign:'right',display:'inline-flex',justifyContent:'right'}}>
                               
                                    <a href={this.state.download_link}>
                                        <img src="/download-icon.png" />
                                    </a>    
                                    {(this.state.invoice_data.total_pending_amount) &&
                                    
                                    <div style={{color: '#28313B'}}>
                                    {(this.state.upi_info.merchant!=="" && this.state.upi_info.upi!=="") &&
                                        <a href={"upi://pay?pn="+this.state.upi_info.merchant+"&pa="+this.state.upi_info.upi+"&am="+this.state.invoice_data.total_pending_amount+"&url&pn=&tr=&url=https://app.business-booster.net/app/api/upi_process/"+this.state.store_name+"/"+this.state.trasid} style={{marginLeft:'10px'}}><button type="button" className="btn btn-danger"  style={{backgroundColor:'rgb(219, 99, 26)'}}>Pay Now</button></a>
                                    }
                                    </div>
                                    }      
                                </div>
                            </div>
                        </div>
                        }
                         
                    </>
                }
                {(this.isMobile()===true && this.state.is_view_clicked===false) &&
                    <div style={{background:'#F7F7FE', height:'100%'}}>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <nav class="navbar" style={{boxShadow:'4px 4px 4px rgb(0 0 0 / 3%)'}}>
                                    <h3 className="mrtop-15">
                                        <a href="https://bazaarerp.com/" style={{color:'#919191'}}>
                                        <span style={{color:'black',fontWeight:'bold'}}>Bazaar</span><span style={{color:'#db631a',fontWeight:'bold'}}>ERP</span>
                                        </a>
                                    </h3>
                                </nav> 
                            </div>
                            <div className="col-md-12">
                                <div className="container-fluid" style={{width:'93%',padding:'10px'}}>
                                   
                                        <div style={{display:'flex',height:'50px'}}>
                                            <div style={{flexBasis: '90%'}}>
                                                <span style={{fontSize: '12px',fontWeight: 'bold'}}>Pay To</span><br/>
                                                <span style={{fontSize: '18px'}}>
                                                    {(this.state.invoice_data!==undefined && this.state.invoice_data!==null && this.state.invoice_data.contact_info!==undefined) &&
                                                        <>{this.state.invoice_data.contact_info[0].name}</>
                                                    }
                                                </span>
                                                
                                            </div>
                                            <div style={{flexBasis: '10%'}}>
                                                {(this.state.invoice_data.company_info!==undefined && this.state.invoice_data.company_info[0].phone!=="") &&
                                                    <a href={"tel:"+this.state.invoice_data.company_info[0].phone}>
                                                        <img style={{height:'65%'}} src="/phone-icon.png" />
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        {/* <div style={{display: 'flex',flexDirection: 'row',paddingTop: '5px',paddingBottom: '5px'}}>
                                            <div style={{flexBasis: '50%',color: '#767587',fontSize: '12px'}}>Invoice Number</div>
                                            <div style={{flexBasis: '50%',justifyContent: 'flex-end',display: 'flex',fontWeight: '600',fontSize: '14px'}}> 
                                                {(this.state.invoice_data.number!==undefined)?this.state.invoice_data.number:''}
                                            </div>
                                        </div>
                                        <div style={{display: 'flex',flexDirection: 'row',paddingTop: '5px',paddingBottom: '5px'}}>
                                            <div style={{flexBasis: '50%',color: '#767587',fontSize: '12px'}}>Invoice Date</div>
                                            <div style={{flexBasis: '50%',justifyContent: 'flex-end',display: 'flex',fontWeight: '600',fontSize: '14px'}}> 
                                                {(this.state.invoice_data.bill_date!==undefined)?this.state.invoice_data.bill_date:''}
                                            </div>
                                        </div> */}
                                        <div style={{display: 'flex',flexDirection: 'row',paddingTop: '5px',paddingBottom: '5px'}}>
                                        {(this.state.invoice_data.total_pending_amount) &&
                                        <>
                                        <div style={{flexBasis: '50%',color: '#767587',fontSize: '16px'}}>Total Pending Amount</div>
                                        <div style={{flexBasis: '50%',justifyContent: 'flex-end',display: 'flex',fontWeight: '600',fontSize: '20px'}}> 
                                                ₹ {(this.state.invoice_data.total_pending_amount!==undefined)?this.state.invoice_data.total_pending_amount:''}
                                        </div>
                                        
                                        </>
                                        }
                                        {(this.state.invoice_data.total) &&
                                        <>
                                            <div style={{flexBasis: '50%',color: '#767587',fontSize: '16px'}}>Total Invoice Amount</div>
                                            <div style={{flexBasis: '50%',justifyContent: 'flex-end',display: 'flex',fontWeight: '600',fontSize: '20px'}}> 
                                                ₹ {(this.state.invoice_data.total!==undefined)?this.state.invoice_data.total:''}
                                            </div>
                                        </>
                                        }
                                        </div>
                                        <div style={{display: 'flex',justifyContent: 'space-between',marginTop:'40px'}}>
                                            <div style={{flexBasis: '48%',
                                                            border: '1px solid #DCDCE2',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            color: '#4C3CCE',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            height: '46px'
                                                        }} onClick={this.handleViewInvoice}> View Invoice</div>
                                            <div style={{
                                                            flexBasis: '48%',
                                                            border: '1px solid #DCDCE2',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            color: '#4C3CCE',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            height: '46px'
                                                        }}><a href={this.state.download_link} id="download-pdf" download> Download Invoice</a></div>
                                        </div>
                                        <div style={{display:'flex', justifyContent:'center',marginTop:'15px'}}>
                                        <div style={{width:'100%',marginTop:'3px',marginBottom:'15px'}}>
                                            {(this.state.upi_info.merchant!=="" && this.state.upi_info.upi!=="") &&
                                                <a style={{display:'grid'}} href={"upi://pay?pn="+this.state.upi_info.merchant+"&pa="+this.state.upi_info.upi+"&am="+this.state.invoice_data.total_pending_amount+"&url&pn=&tr=&url=https://app.business-booster.net/app/api/upi_process/"+this.state.store_name+"/"+this.state.trasid}><button type="button" className="btn btn-danger" style={{height:'46px',backgroundColor: 'rgb(219, 99, 26)',borderColor: 'rgb(219, 99, 26)'}}>Pay Now</button></a>
                                            }
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    
                }
            </>
            }
           </>
        );
    }
}