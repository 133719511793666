import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import check_access from './access-level.js';
import ServiceLoader from "./service-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCarAlt, faCartPlus, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import ContactSelect from "./contact-select-on-type";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { QRCodeSVG } from "qrcode.react";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
export default class POSItemView extends Component {
    constructor(props) {
        super();
        this.state = {
           
        };
        this.isMobile = utils.isMobile.bind(this);
    }
    componentDidMount(){
        
    }
    
    render() {
        return (
            <div id="page-content-wrapper">
                <nav className="navbar border-bottom" style={{paddingLeft:'20px'}}>
                    <div className="row">
                        <div className="col-md-12 mrtop-10">
                        {(this.props.target==='product_list_info') &&
                            <h1>₹{utils.currency_format(this.props.page_title)}</h1>
                        }
                        {(this.props.target==='product_list_full_info' || this.props.target==='exchange_list_full_info') &&
                            <h1>{this.props.page_title}</h1>
                        }
                        {(this.props.target==='bill_summary') &&
                            <h1>Bill Summary</h1>
                        }
                        </div>
                    </div>
                </nav>
                {(this.props.target==='product_list_info') &&
                    <div className="container-fluid page-break">
                        <div className="content-area">
                            <div className="content-head border-bottom" style={{padding:'5px'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 style={{margin:'0px',background:'rgb(119, 158, 200)',padding:'10px'}}>
                                            <p style={{margin:'0px',color:'#fff'}}><FontAwesomeIcon icon={faCartPlus}/>{this.props.item_info.barcode} | {this.props.item_info.static_item_name} <b style={{color:'rgb(119, 158, 200)',background:'#fff',padding:'2px 5px',borderRadius:'5px',fontSize:'12px'}}>{(this.props.item_info.category_name!=='')?this.props.item_info.category_name:'N/A'}</b></p>
                                            <p style={{margin:'0px',fontSize:'14px',marginLeft:'36px',color:'#fff'}}>{this.props.item_info.item_desc}</p>
                                        </h4>
                                    </div>
                                    <div className="col-md-12">
                                        <hr style={{margin:'0px'}}/>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Quantity</p>
                                        <h5 style={{margin:'0px'}}> {this.props.item_info.quantity}{this.props.item_info.unit_name}</h5>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Selling Price</p>
                                        <h5 style={{margin:'0px'}}>₹{this.props.item_info.price}</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Discount</p>  
                                        <h5 style={{margin:'0px'}}>
                                            {(this.props.item_info.discount_type==='rs') &&
                                                <span>₹</span>
                                            }
                                            <span>{this.props.item_info.discount}</span>
                                            {(this.props.item_info.discount_type==='per') &&
                                                <>
                                                    <span>%</span>
                                                    <span>(₹{this.props.item_info.discount_amount})</span>
                                                </>
                                            }
                                        </h5>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Commission</p>
                                        <h5 style={{margin:'0px'}}>₹{this.props.item_info.item_commission}</h5>
                                        
                                    </div>
                                    <div className="col-md-12">
                                        <hr style={{margin:'5px'}}/>
                                    </div>
                                    <div className="col-md-12">
                                        <h6 style={{margin: '0',background: 'rgb(119, 158, 200)', padding: '5px',color: '#fff'}}>Tax Info</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <hr style={{margin:'5px'}}/>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Tax</p>
                                        <h5 style={{margin:'0px'}}>{this.props.item_info.sp_tax}%</h5>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>Tax Type</p>
                                        <h5 style={{margin:'0px',textTransform:'capitalize'}}>{this.props.item_info.sp_tax_type}</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-4" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>CGST</p>
                                        <h5 style={{margin:'0px',textTransform:'capitalize'}}>{this.props.item_info.cgst_amount}</h5>
                                    </div>
                                    <div className="col-md-4" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>SGST</p>
                                        <h5 style={{margin:'0px',textTransform:'capitalize'}}>{this.props.item_info.sgst_amount}</h5>
                                    </div>
                                    <div className="col-md-4" style={{textAlign:'center'}}>
                                        <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>IGST</p>
                                        <h5 style={{margin:'0px',textTransform:'capitalize'}}>{this.props.item_info.igst_amount}</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <hr style={{margin:'5px'}}/>
                                    </div>
                                    <div className="col-md-12">
                                        <h6 style={{margin: '0',background: 'rgb(119, 158, 200)', padding: '5px',color: '#fff'}}>Custom Data</h6>
                                    </div>
                                    <div className="col-md-12">
                                        <hr style={{margin:'5px'}}/>
                                    </div>
                                    {(this.props.item_info.custom_data!=='' && this.props.item_info.custom_data!==null && this.props.item_info.custom_data!==undefined && this.props.item_info.custom_data.length>0 ) &&
                                    <>
                                    {(this.props.item_info.custom_data.map((cval,cidx)=>{
                                        return(
                                            <div className="col-md-4" style={{textAlign:'center'}}>
                                                <p style={{margin:'0px',color:'#17a2b8',fontWeight:'bold',fontSize:'14px'}}>{cval.name}</p>
                                                <h5 style={{margin:'0px',textTransform:'capitalize'}}>{cval.value}</h5>
                                            </div> 
                                        )
                                    }))}
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.props.target==='bill_summary') &&
                    <div className="container-fluid page-break">
                        <div className="content-area">
                            <div className="content-head border-bottom" style={{padding:'5px'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                    <table className="table" style={{borderBottom:'1px solid #dee2e6'}}>
                                        <tbody>
                                            <tr>
                                                <td  style={{borderTop:'none'}}>Total Unit</td>
                                                <td  style={{borderTop:'none',textAlign:'right'}}>
                                                    <h5>{utils.currency_format(this.props.item_info.total_unit)}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total Quantity</td>
                                                <td style={{textAlign:'right'}}>
                                                    <h5>{utils.currency_format(this.props.item_info.total_quantity)}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Subtotal</td>
                                                <td style={{textAlign:'right'}}>
                                                    <h5>₹{utils.currency_format(this.props.item_info.sub_total)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td>Item Discount</td>
                                                <td style={{textAlign:'right'}}><h5>₹{utils.currency_format(this.props.item_info.total_discount)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td>Global Discount {(this.props.item_info.global_discount_type==='per')?'₹'+utils.currency_format(this.props.item_info.global_discount_amount):''}</td>
                                                <td style={{textAlign:'right'}}>
                                                    <h5>{(this.props.item_info.global_discount_type==='per')?this.props.item_info.global_discount+'%':'₹'+this.props.item_info.global_discount}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tax  <span style={{margin: '0px', fontSize: '11px'}}>(CGST : ₹{this.props.item_info.cgst_amount}, SGST : ₹{this.props.item_info.sgst_amount}, IGST : ₹{this.props.item_info.igst_amount})</span></td>
                                                <td style={{textAlign:'right'}}>
                                                    <h5>
                                                        ₹{utils.currency_format(this.props.item_info.gst_amount)}
                                                    </h5>
                                                   
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{borderBottom:'none'}}>Other Charges</td>
                                                <td style={{borderBottom:'none',textAlign:'right'}}>
                                                    <h5>₹{this.props.item_info.other_charge_amount}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{borderBottom:'none'}}>Round Off</td>
                                                <td style={{borderBottom:'none',textAlign:'right'}}>
                                                    <h5>₹{this.props.item_info.round_off_opt}{this.props.item_info.round_off}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{borderBottom:'none'}}>Commission</td>
                                                <td style={{borderBottom:'none',textAlign:'right'}}>
                                                <h5>₹{this.props.item_info.commission}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{borderBottom:'none'}}>Total</td>
                                                <td style={{borderBottom:'none',textAlign:'right'}}>
                                                <h4>₹{this.props.item_info.grand_total}</h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.props.target==='product_list_full_info') &&
                    <div className="container-fluid page-break">
                        <div className="content-area">
                            <div className="content-head border-bottom" style={{padding:'5px'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div style={{overflowX:'auto'}}>
                                        <table className="table" style={{borderBottom:'1px solid #dee2e6'}}>
                                        <thead className="thead-light">
                                            <tr>
                                            <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>SNo</th>
                                            <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Item Name</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>HSN</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Qty</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Selling Price</th>
                                            {(this.props.item_info.sale_field_hide!==undefined && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Discount</th>
                                            }
                                            
                                            <th style={{textAlign:'left',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Tax</th>
                                            
                                            {(this.props.item_info.is_item_commission===true) &&
                                                <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Commission</th>
                                            }
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Custom Data</th>
                                            <th style={{textAlign:'right',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Total</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.props.item_info.list_items.map((value,index)=>{
                                                return(
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        <p style={{margin:'0px'}}>{value.barcode} | {value.static_item_name} <b style={{color:'#fff',background:'rgb(119, 158, 200)',padding:'2px 5px',borderRadius:'5px',fontSize:'12px'}}>{(value.category_name!=='')?value.category_name:'N/A'}</b></p>
                                                        <p style={{margin:'0px',fontSize:'14px'}}>{value.item_desc}</p>
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                        {value.hsn}
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                        {value.quantity} {value.unit_name}
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                    ₹{utils.currency_format(value.price)}
                                                    </td>
                                                    {(this.props.item_info.sale_field_hide && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                    <td style={{textAlign:'center'}}>₹{value.discount_amount}</td>
                                                    }
                                                    <td style={{textAlign:'left'}}>
                                                    {(value.cgst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        CGST : {(value.cgst_amount>0)?'₹'+value.cgst_amount:'-'}{(value.cgst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    {(value.sgst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        SGST : {(value.sgst_amount>0)?'₹'+value.sgst_amount:'-'}{(value.sgst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    {(value.igst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        IGST : {(value.igst_amount>0)?'₹'+value.igst_amount:'-'}{(value.igst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    </td>
                                                    {(this.props.item_info.is_item_commission===true) &&
                                                    <td style={{textAlign:'center'}}>
                                                        ₹{value.item_commission}
                                                    </td>
                                                    }
                                                    <td>
                                                        {(value.custom_data!==null && value.custom_data!==undefined && value.custom_data!=="") &&
                                                            <>
                                                                {(value.custom_data.map((cval,cidx)=>{
                                                                    return(
                                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                                            {cval.name} : {(cval.value!=='')?cval.value:'-'}
                                                                        </p>
                                                                    )
                                                                }))}
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{textAlign:'right'}}>₹{utils.currency_format(value.total)}</td>
                                                   
                                                </tr>
                                                )
                                            }))}
                                        
                                        </tbody>
                                        {(this.props.item_info.list_items.length>0) &&
                                        <tfoot>
                                            <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style={{textAlign:'center'}}><h4>{this.props.item_info.total_quantity} item(s)</h4></th>
                                            <th></th>
                                            {(this.props.item_info.sale_field_hide!==undefined && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                <th></th>
                                            }
                                            <th></th>
                                            {(this.props.item_info.is_item_commission===true) &&
                                                <th></th>
                                            }
                                            <th></th>
                                            <th style={{textAlign:'right'}}><h4>₹{utils.currency_format(this.props.item_info.total_amount)}</h4></th>
                                           
                                            </tr>
                                        </tfoot>
                                        }
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.props.target==='exchange_list_full_info') &&
                    <div className="container-fluid page-break">
                        <div className="content-area">
                            <div className="content-head border-bottom" style={{padding:'5px'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div style={{overflowX:'auto'}}>
                                        <table className="table" style={{borderBottom:'1px solid #dee2e6'}}>
                                        <thead className="thead-light">
                                            <tr>
                                            <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>SNo</th>
                                            <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Item Name</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>HSN</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Qty</th>
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Selling Price</th>
                                            {(this.props.item_info.sale_field_hide!==undefined && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Discount</th>
                                            }
                                            
                                            <th style={{textAlign:'left',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Tax</th>
                                        
                                            <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Custom Data</th>
                                            <th style={{textAlign:'right',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Total</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.props.item_info.exchange_items.map((value,index)=>{
                                                return(
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        <p style={{margin:'0px'}}>{value.barcode} | {value.static_item_name} <b style={{color:'#fff',background:'rgb(119, 158, 200)',padding:'2px 5px',borderRadius:'5px',fontSize:'12px'}}>{(value.category_name!=='')?value.category_name:'N/A'}</b></p>
                                                        <p style={{margin:'0px',fontSize:'14px'}}>{value.item_desc}</p>
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                        {value.hsn}
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                        {value.quantity} {value.unit_name}
                                                    </td>
                                                    <td style={{textAlign:'center'}}>
                                                    ₹{utils.currency_format(value.price)}
                                                    </td>
                                                    {(this.props.item_info.sale_field_hide && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                    <td style={{textAlign:'center'}}>₹{value.discount_amount}</td>
                                                    }
                                                    <td style={{textAlign:'left'}}>
                                                    {(value.cgst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        CGST : {(value.cgst_amount>0)?'₹'+value.cgst_amount:'-'}{(value.cgst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    {(value.sgst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        SGST : {(value.sgst_amount>0)?'₹'+value.sgst_amount:'-'}{(value.sgst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    {(value.igst_amount>0) &&
                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                        IGST : {(value.igst_amount>0)?'₹'+value.igst_amount:'-'}{(value.igst_amount>0)?'('+((value.sp_tax)/2)+'%)':''}
                                                        </p>
                                                    }
                                                    </td>
                                                    
                                                    <td>
                                                        {(value.custom_data!==null && value.custom_data!==undefined && value.custom_data!=="") &&
                                                            <>
                                                                {(value.custom_data.map((cval,cidx)=>{
                                                                    return(
                                                                        <p style={{margin:'0px',fontSize:'14px'}}>
                                                                            {cval.name} : {(cval.value!=='')?cval.value:'-'}
                                                                        </p>
                                                                    )
                                                                }))}
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{textAlign:'right'}}>₹{utils.currency_format(value.total)}</td>
                                                   
                                                </tr>
                                                )
                                            }))}
                                        
                                        </tbody>
                                        {(this.props.item_info.exchange_items.length>0) &&
                                        <tfoot>
                                            <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style={{textAlign:'center'}}><h4>{this.props.item_info.total_exchange_quantity} item(s)</h4></th>
                                            <th></th>
                                            {(this.props.item_info.sale_field_hide!==undefined && this.props.item_info.sale_field_hide.indexOf('sdisc')<0) &&
                                                <th></th>
                                            }
                                            <th></th>
                                           
                                            <th></th>
                                            <th style={{textAlign:'right'}}><h4>₹{utils.currency_format(this.props.item_info.exchange_amount)}</h4></th>
                                           
                                            </tr>
                                        </tfoot>
                                        }
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}