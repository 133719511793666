import React, { Component } from "react";
import axios from 'axios';
import utils from '../utils.js';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ServiceLoader from './service-loader.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class DifferentBarcodeInventoryItems extends Component {
    constructor() {
        super();
        this.state = {
            barcode_inventory_items : [],
            temp_barcode_inventory_items : [],
            active_item :0,
            show_loader : true,
            search_text : '',
        };
        
    }
    componentWillUnmount() {
        
    }
    componentDidMount(){
        document.addEventListener("keydown", this.handleBarcodeInventoryListShortcut);
    }
    handleBarcodeInventoryListShortcut = (e)=>{
        let key_code = e.which;
     
        if(key_code===27){
          var element = document.getElementById('barcode-inventory-list');
          if(element.style.display==="block"){
            this.handleCloseInvetoryListPopup();
            document.getElementById("iitem-box").focus();
            document.getElementById("open_popup_on_focus").click();
          }
        }
        
        if(key_code===40){
          var element = document.getElementById('barcode-inventory-list');
          var inv_list = document.querySelectorAll(".bc-inv-list");
          if(element.style.display==="block"){
            let active_item = this.state.active_item;
            active_item++;
            if(inv_list.length>=(active_item+1)){
              this.setState({active_item:active_item});
            }
          }
        }
        
        if(key_code===38){
          var element = document.getElementById('barcode-inventory-list');
          var inv_list = document.querySelectorAll(".bc-inv-list");
          if(element.style.display==="block"){
            let active_item = this.state.active_item;
            active_item--;
            if(active_item>=0){
              this.setState({active_item:active_item});
            }
          }
        }
        
        if(key_code===13){
          var element = document.getElementById('barcode-inventory-list');
          var inv_list = document.querySelectorAll(".bc-inv-list");
          if(element.style.display==="block"){
            let active_item = this.state.active_item;
            if(inv_list[active_item]!==undefined){
                inv_list[active_item].click();
            }
          }
        }
    }
    handleSelectInventoryItem = (e) => {
        let target = e.currentTarget.getAttribute("target");
        let id = e.currentTarget.id;
        localStorage.setItem("selected_bc_inv_item",id);
        if(document.getElementById("hanldeSelectDifferentBarcodeItem")){
            document.getElementById("hanldeSelectDifferentBarcodeItem").click();
        }
        this.handleCloseInvetoryListPopup();
      }
  
      handleCloseInvetoryListPopup = ()  =>{
        var element = document.getElementById('barcode-inventory-list');
        element.classList.add("fade");
        element.style.display="none"; 
      }
      handleGetInvetoryList = () =>{
        this.setState({ barcode_inventory_items: [] });
        this.setState({ temp_barcode_inventory_items: [] });
        let item_it = localStorage.getItem('bcode_inventory_id');
        
        var element = document.getElementById('barcode-inventory-list');
        element.classList.remove("fade");
        element.style.display="block"; 
       
        document.getElementById("dif-bc-invi-search").focus();
            let qs = document.querySelectorAll(".iitem-ids");
            let d_item_arr = [];
            for (let ii = 0; ii < qs.length; ii++) {
              const did = qs[ii].getAttribute('data-id');
              const iquan = document.getElementById("item-quantity-"+did).value;
              const iid = qs[ii].value;
              if(d_item_arr[iid]===undefined){
                d_item_arr[iid] = [];
                d_item_arr[iid] = iquan;
              }else{
                d_item_arr[iid] =parseInt( d_item_arr[iid] )+parseInt(iquan);
              }
            }
            
            this.setState({show_loader:true});
            let body = {};
            body = {
                    store_name : user_info.store_name,
                    branch_id : user_info.login_id,
                    user_id : user_info.user_id,
                    id : item_it,
                    uuid : utils.uuid(),
                    role_id : user_info.access_level,
                    token : user_info.token,
                    _schema : user_info._schema,
                }
            let api = app_api+'/get_different_barcode_inventory_item';
            axios.post(api, body)
            .then(function (response) {
               let cursor = response.data;
               
               this.setState({show_loader:false});

               let store_purchase_item_options = [];
               let barcode_inventory_items = [];
               let purchase_item_options = JSON.parse(localStorage.getItem('purchase_item_options'));
              
               cursor.map( (value, key) => {
                   let id = value.inventory_id;

                   if(d_item_arr[id]!==undefined){
                    value.availability = parseInt(value.availability) - parseInt(d_item_arr[id]);
                   }

                   let stat_lbl = '';
                   if(value.inventory_status==='Draft'){
                     stat_lbl=' (Inactive)';
                   }
                   let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                   let static_item_name = value.item_name;
   
                   let sku   = value.sku; 
                   let total = value.total;
                   let bundle_text= value.bundle_text;
                   let barcode_name= value.barcode_name;
                   let alias_barcode= value.alias_barcode;
                   let btext = this.state.bundle_text;
                   if(btext===''){
                     this.setState({bundle_text:bundle_text});
                   }
                   if(sku===null){
                     sku = "none";
                   }
                   if(barcode_name===null){
                     barcode_name = "none";
                   }
   
                 
                   store_purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,inventory_id:value.inventory_id};
                 
                   purchase_item_options[id] = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,has_variation:value.has_variation,is_variation:value.is_variation,age:value.age,supplier:value.supplier,is_more_barcode:value.is_more_barcode,parent_inventory_id:value.parent_inventory_id,inventory_id:value.inventory_id};
   
                   barcode_inventory_items.push(value);

               });
               
               localStorage.setItem('diff_barcode_inv_item_options',JSON.stringify(store_purchase_item_options));
               localStorage.setItem('purchase_item_options',JSON.stringify(purchase_item_options));

               this.setState({ barcode_inventory_items: barcode_inventory_items });
               this.setState({ temp_barcode_inventory_items: barcode_inventory_items });
            }.bind(this)).catch(function(res){
                if(res.response){
                
                }
            })
        
      }
      handleSearch = (e) =>{
          let userInput = e.currentTarget.value;
          this.setState({search_text:userInput});
          let barcode_inventory_items = this.state.temp_barcode_inventory_items;
          let hblist = [];
          barcode_inventory_items.map( (value, index) => { 
            let value1 = value.barcode_name;
            if(value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                hblist.push(value);
            }
        });
        this.setState({barcode_inventory_items:hblist})
      }
      handleShowInventoryList = () =>{
        this.handleGetInvetoryList();
      }

      



    render() {
        return (
            <div className="modal fade" id="barcode-inventory-list" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <span id="handleShowInventoryList" onClick={this.handleShowInventoryList}></span>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title hbtitle">INVENTORY ITEMS</h5>
                  </div>
                  <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <input type="hidden" value={this.props.active_tab} id="current_active_tab"/>
                      {(this.state.show_loader==true) &&
                        <ServiceLoader show={this.state.show_loader}/>
                      } 
                      <input type="text" className="form-control" placeholder="Type to search" id="dif-bc-invi-search" onChange={this.handleSearch}/>
                      {(this.state.show_loader===false) &&
                        <>
                         <table className="table">
                            <thead>
                              <tr>
                                <th className="text-left">Item</th>
                                <th className="text-left">Barcode</th>
                                <th className="text-left">Availability</th>
                                <th className="text-left">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                            {(this.state.barcode_inventory_items.length > 0) ?this.state.barcode_inventory_items.map( (value, index) => {
                               return (
                                  <tr key={index} className={(this.state.active_item===index)?'active bc-inv-list':'bc-inv-list'} onClick={this.handleSelectInventoryItem} id={value.inventory_id} style={{cursor:'pointer'}}>
                                    <td className="text-left">{value.item_name}</td>
                                    <td className="text-left">{value.barcode_name} ({value.item_code})</td>
                                    <td className="text-left">
                                      {value.availability}
                                      <input type="hidden" value={value.availability} className="bc-inv-li-avail"/>
                                    </td>
                                    <td className="text-left">{value.selling_price}</td>
                                  </tr>
                                )
                              }) :  <tr><td colSpan={3} style={{textTransform:'capitalize'}}>Oops ! No item found</td></tr>
                            }
                            </tbody>
                         </table>
                         </>
                      }
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseInvetoryListPopup}>Close</button>
                  </div>
                </div>
              </div>
            </div> 
        );
    }
}