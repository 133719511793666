import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Leftnav from "./left-nav";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,admin_info} = require('../../library.js');

export default class QueryBuilder extends Component {
   constructor() {
      super();
      this.state = {
         
      };
      this.runQuery = this.runQuery.bind(this);
    }
    componentDidMount(){
     
    }
    runQuery(e){
      let thisid = e.currentTarget.id;
      let query = document.getElementById("query-box").value;
      if(query!==""){
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        var body = {
          login_id: admin_info.login_id,
          queries: query,
        };
        axios.post(app_api+'/admin/query_builder', body)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            
        }).catch(function(res){
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
           
        })
      }
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title"><span>Query Builder</span></div>
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-3">
                      
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     
                    <div className="content-head border-bottom">
                      <b>Query Builder</b>
                      <p className="info">Write Query to apply in all the stores database. All the query should be separated by semi colon(;)</p>
                    </div>
                    <div className="row mrtop-50">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <p className="error">Please put schema_name. keyword followed by table name</p>
                        <textarea className="form-control" placeholder="Write database query here..." rows="10" id="query-box"></textarea>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    <div className="content-foot">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-right">
                          <button type="button" id="run-query-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          <button type="button" onClick={this.runQuery} id="run-query" className="btn btn-success btn-save-action">Run Query</button>
                        </div>
                        <div className="col-md-2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}