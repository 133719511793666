import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
export default class VariationsOptions extends Component {
    constructor() {
      super();
      this.state = {
          tags: [],
          tags_name: '',
          tags_span : '',
          show_rm : false,
      };
      this.handleTag = this.handleTag.bind(this);
      this.removeTag = this.removeTag.bind(this);
      this.removeTagList = this.removeTagList.bind(this);
    }
    removeTagList(e){
        let id = e.currentTarget.id;
        this.setState({tags:[]});
        document.getElementById("v-opt-row-"+id).remove();
    }
    removeTag(e){
      let id = e.currentTarget.id;
      let tags = this.state.tags;
      tags.splice(id, 1);
      this.setState({tags:tags});
    }
    handleTag(e){
        let thisvalue = e.currentTarget.value;
        let data_id = e.currentTarget.getAttribute('data-id');
        if((e.keyCode===13 || e.keyCode===9) && thisvalue!==''){
            let tags = this.state.tags;
            let t_arr =thisvalue;
            tags.push(t_arr);
            this.setState({tags:tags});
            document.getElementById("tag-box-"+data_id).focus();
            e.currentTarget.value = '';
            e.currentTarget.placeholder = '';
        }
    }
    componentDidMount(){
        
      if(this.props.options){
          if(this.props.options[0]){
              this.props.options.map((value,key) =>{
                let tags_name = value.key;
                let tags = value.value.split(',');
                this.setState({tags:tags});
                this.setState({tags_name:tags_name});
              });
          }
      }
    }
    render() {

        return (
            <div className="row mrtop-10 v-opt-row" id={"v-opt-row-"+this.props.index} data-id={this.props.index}>
              <div className="col-md-4">
                <input type="text" className="form-control" placeholder="Ex : Size, Color" id={"variation-name-"+this.props.index} defaultValue={this.state.tags_name}/>
              </div>
              <div className="col-md-8">
                   <div className="input-group">
                    <div className="input-group-prepend tag-prepend">
                      <span className="input-group-text no-border-right igt">
                         {(this.state.tags.length > 0) ? this.state.tags.map( (value, index) => {
                           return (
                           <>
                              <span key={"t-"+index} className="v-tag" id={"v-tag-"+index}>
                                {value}
                                <span key={"tc-"+index} className="t-close" id={index} onClick={this.removeTag}><FontAwesomeIcon icon={faTimes} /></span>
                              </span>
                            </>
                            )
                          }):''}
                      </span>
                      <input type="text" id={"tag-box-"+this.props.index} data-id={this.props.index} className="form-control no-border-left tag-box" placeholder="Ex : Large, Red" onKeyDown={this.handleTag}/>
                      
                      <span className="rm-tag-li" id={this.props.index} onClick={this.removeTagList}><FontAwesomeIcon icon={faTimes} /></span>
                      
                      <input type="hidden" id={"variation-value-"+this.props.index} data-id={this.props.index} defaultValue={this.state.tags}/>
                    </div>
                  </div>
              </div>
            </div>
        );
    }
}