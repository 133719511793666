import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import ModalBox from "../modal-box";
import axios from 'axios';
import utils from '../../utils.js';
import ContactTypes from "../contact-types";
import ContactGroups from "../contact-groups";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPlus, faPlusSquare,faSpinner, faTimes  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import SelectDropdown from "../select-dropdown";
let {enc_key,app_api,user_info,states} = require('../../library.js');
export default class SettingsOther extends Component {
     constructor() {
      super();
      this.state = {
        show_customer_ltv:false,
        show_import:false,
        show_custom_headers:false,
        relevant_contact_type_ssr : 1,
        relevant_contact_type_ppr : 2,
        csv_headers : [],
        import_data : [],
        import_file : [],
        custom_fields_json : [],
        contact_import_headers : [],
        google_api_settings : {api_key : '',client_id:''}
      }
      this.handleExport = this.handleExport.bind(this);
      this.openModal = this.openModal.bind(this);
      this.handleOpenImport = this.handleOpenImport.bind(this);
      this.handleUploadContact = this.handleUploadContact.bind(this);
      this.handleImportChange = this.handleImportChange.bind(this);
      
      this.is_access = check_access.is_access.bind(this);
    }
    handleAddMoreCustomField = (e) =>{
      let randval = Math.floor(Math.random() * 9000) + 1000;
      let custom_fields_json =  this.state.custom_fields_json;
      let new_field = {'id':randval,'name':'','value':''};
      custom_fields_json.push(new_field);
      this.setState({custom_fields_json:custom_fields_json});
  }
    handleCustomFieldKeydown = (e) =>{
      let key_code = e.which;
      if(key_code===13){
        this.handleAddMoreCustomField();
      }
    }

    handleSaveCustomField = e =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    
      let cfields = this.state.custom_fields_json;

      let custom_fields = [];
      for (let index = 0; index < cfields.length; index++) {
        const element = cfields[index];
        if(element.name!==''){
          custom_fields.push(element);
        }
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'contact_custom_fields',
          value : custom_fields
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }

    handleSetCustomField = (e) =>{
        let thisvalue = e.currentTarget.value;
        let target = e.currentTarget.getAttribute('data-target');
        let idx = e.currentTarget.getAttribute('data-id');
        let custom_fields_json =  this.state.custom_fields_json;
        custom_fields_json[idx][target] = thisvalue;
        this.setState({custom_fields_json:custom_fields_json});
    }
    

    handleRemoveCustomField = (e) =>{
        let idx = e.currentTarget.getAttribute('data-id');
        let custom_fields_json =  this.state.custom_fields_json;
        custom_fields_json.splice(idx,1);
        this.setState({custom_fields_json:custom_fields_json});
    }

    handleExport(e){
      var element = document.getElementById('export_contact_csv');
      element.classList.remove("fade");
      element.style.display="block";
    }

    handleUploadContact(e){
      document.getElementById('up-con').classList.add("hide");
      document.getElementById('up-con-btn-spinner').classList.remove("hide");
   

      let contacts = document.querySelectorAll(".contact-row");
      let contacts_row = [];
      for(let i=0;i<contacts.length;i++){
         let id = contacts[i].id;
         let name = document.getElementById("name-"+id).value;
         let contact_type = document.getElementById("contact_type-"+id).value;
         let mobile = document.getElementById("mobile1-"+id).value;
         let mobile2 = document.getElementById("mobile2-"+id).value;
         let email = document.getElementById("email-"+id).value;
         let city = document.getElementById("city-"+id).value;
         let state = document.getElementById("state-"+id).value;
         let pincode = document.getElementById("pincode-"+id).value;
         let address = document.getElementById("address-"+id).value;
         let gst_number = document.getElementById("gst_number-"+id).value;
         let credit_days = document.getElementById("credit_days-"+id).value;
         let credit_limit = document.getElementById("credit_limit-"+id).value;
         let discount = document.getElementById("discount-"+id).value;
         let opening_balance = document.getElementById("opening_balance-"+id).value;
         let opening_balance_type = document.getElementById("opening_balance_type-"+id).value;
         let opening_balance_date = document.getElementById("opening_balance_date-"+id).value;
         let group = document.getElementById("group-"+id).value;
         let agent = document.getElementById("agent-"+id).value;
         let contact_name = document.getElementById("contact-name-"+id).value;
         let pan = document.getElementById("pan-"+id).value;

         let data = {name:name,contact_type:contact_type,mobile:mobile,mobile2:mobile2,email:email,city:city,state:state,pincode:pincode,address:address,gst_number:gst_number,credit_days:credit_days,credit_limit:credit_limit,discount:discount,opening_balance:opening_balance,opening_balance_type:opening_balance_type,opening_balance_date:opening_balance_date,group:group,agent:agent,contact_name:contact_name,pan:pan}
         contacts_row.push(data);
      }


      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          target : 'contact',
          contacts : contacts_row,
       }
     
      let api = app_api+'/upload_import_csv';
      axios.post(api, body)
        .then(function (response) {
          document.getElementById('up-con').classList.remove("hide");
          document.getElementById('up-con-btn-spinner').classList.add("hide");
          var request = window.indexedDB.deleteDatabase("ERP");
          request.onsuccess = function(e) {  };
          request.onblocked = function(e) {
            //window.location.href = '/';
          };
          request.onerror = function(e) { //console.log("error: " + e); 
          };
          window.location.href = '/contacts';
      }.bind(this)).catch(function(res){
          
      })
    }

    handleOpenImport(e){
      
      document.getElementById("import-contact").click();
    }

    handleImportChange(e){
      document.getElementById('imp-con').classList.add("hide");
      document.getElementById('imp-con-btn-spinner').classList.remove("hide");
      const files = e.target.files
      const formData = new FormData();
      let csv = files[0];
      this.setState({import_file:csv});
      formData.append('importFile', csv);
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);
      formData.append('type', 'process_data');
      formData.append('target', 'contact');
      let api = app_api+'/process_import_csv';
      axios.post(api, formData)
        .then(function (response) {
          let resp = response.data;
          document.getElementById('imp-con').classList.remove("hide");
          document.getElementById('imp-con-btn-spinner').classList.add("hide");
          this.setState({show_custom_headers:true,csv_headers:resp.headers,import_data:resp.import_data});
         // this.setState({import_data:response.data,show_import:true});
      }.bind(this)).catch(function(res){
          
      })
    }

    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        if(document.getElementById("save-target-type")){
          document.getElementById("save-target-type").value = thisid;
        }
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }


    componentDidMount(){
        
        let keys = "'relevant_contact_type_ssr','relevant_contact_type_ppr','contact_custom_fields','google_api_settings','show_customer_ltv'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.relevant_contact_type_ssr){
              let relevant_contact_type_ssr = resp.relevant_contact_type_ssr;
              this.setState({relevant_contact_type_ssr:relevant_contact_type_ssr});
            }
            if(resp.show_customer_ltv){
              let show_customer_ltv = resp.show_customer_ltv;
              this.setState({show_customer_ltv:show_customer_ltv});
            }
            if(resp.relevant_contact_type_ppr){
              let relevant_contact_type_ppr = resp.relevant_contact_type_ppr;
              this.setState({relevant_contact_type_ppr:relevant_contact_type_ppr});
            }
            if(resp.contact_custom_fields!==undefined){
              let contact_custom_fields = resp.contact_custom_fields;
              this.setState({custom_fields_json:contact_custom_fields});
            }
            if(resp.google_api_settings!==undefined){
              let google_api_settings = resp.google_api_settings;
              this.setState({google_api_settings:google_api_settings});
            }
        }.bind(this)).catch(function(res){
            
        })
        this.handleGetHeaders();
    }

    setupImportHeader = e =>{
      this.setState({show_custom_headers:true});
    }

    hideImportHeader = e =>{
      this.setState({show_custom_headers:false});
    }
    handleGetHeaders = ()=>{
      let keys = "'import_contact_headers_new'";
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            keys:keys
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.import_contact_headers_new!==undefined){
              let import_contact_headers_new = resp.import_contact_headers_new;
              this.setState({contact_import_headers:import_contact_headers_new});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    handleSaveGoogleSetting = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let api_key = document.getElementById("google-api-key").value;
      let client_id = document.getElementById("google-client-id").value;
      let google_data = {
        api_key : api_key,
        client_id : client_id,
      }
      
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'google_api_settings',
          value : google_data
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleShowCustomerLTV = e =>{
      let show_customer_ltv = e.target.checked;
      this.setState({show_customer_ltv})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'show_customer_ltv',
          value : show_customer_ltv
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Settings</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                     <span id="hideImportHeader" onClick={this.hideImportHeader}></span>
                      {(this.state.show_import===false && this.state.show_custom_headers===false) &&
                        <>
                          <div className="row">
                            
                            <div className="col-md-6">
                              <div className="row">
                              <div className="col-md-12">
                                <div className="box-container">
                                  <div className="box-head">
                                    <label>CONTACT TYPES</label>
                                    {this.is_access('settings-contacts-create') && 
                                    <FontAwesomeIcon onClick={this.openModal} data_target="add_contact_type" icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                    }
                                  </div>
                                  <div className="box-body">
                                      <ContactTypes show="list" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>CONTACT GROUPS</label>
                                  {this.is_access('settings-contacts-create') && 
                                  <FontAwesomeIcon onClick={this.openModal} data_target="add_contact_group" icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                  }
                                </div>
                                <div className="box-body">
                                    <ContactGroups show="list" />
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                             {/*  <div className="col-md-12">
                                <div className="box-container">
                                  <div className="box-head">
                                    <label>GOOGLE PEOPLE API INTEGRATION</label>
                                  </div>
                                  <div className="box-body">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          
                                          <td>
                                              <label htmlFor="google-api-key">GOOGLE API KEY</label>
                                              <input type="text" className="form-control" id="google-api-key" defaultValue={this.state.google_api_settings.api_key}/>
                                          </td>
                                        </tr> 
                                        <tr>
                                          
                                          <td>
                                              <label htmlFor="google-api-key">GOOGLE CLIENT ID</label>
                                              <input type="text" className="form-control" id="google-client-id"  defaultValue={this.state.google_api_settings.client_id}/>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                </div>
                                <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-google-setting" onClick={this.handleSaveGoogleSetting}>Save Settings</button>
                                <button type="button" id="save-google-setting-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                              </div>
                                </div> */}
                                <div className="col-md-12">
                                <div className="box-container">
                                  <div className="box-head">
                                    <label>OTHER SETTINGS</label>
                                  </div>
                                  <div className="box-body"  style={{padding:'10px 20px'}}>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="checkbox" id="show-customer-ltv" value="show-customer-ltv" checked={this.state.show_customer_ltv} onChange={this.handleShowCustomerLTV}/>
                                          <label className="form-check-label" style={{fontWeight:'bold'}} htmlFor="show-customer-ltv" id="lbl-show-customer-ltv">SHOW CUSTOMER LTV</label>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                                </div>
                                <div className="col-md-12">
                              <div className="box-container">
                                  <div className="box-head">
                                    <label>CONTACT IMPORT/EXPORT</label>
                                  </div>
                                  <div className="box-body  pad-15">
                             
                                      <div className="row">
                                        <div className="col-md-12 text-center">
                                            <a href="/contact-import.csv" download>Download contact import demo file</a>
                                        </div>
                                      </div>
                                      <div className="row mrtop-10">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 text-center" style={{display:'grid'}}>
                                        
                                        <input type="file" onChange={this.handleImportChange} id="import-contact" accept=".csv" style={{display:'none'}}/>
                                          <button type="button" className="btn btn-danger" id="imp-con" onClick={this.handleOpenImport}>IMPORT</button>
                                          <button type="button" id="imp-con-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        </div>
                                        <div className="col-md-3"></div>
                                      </div>
                                      <div className="row mrtop-10">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 text-center" style={{display:'grid'}}>
                                         
                                          <button type="button" className="btn btn-warning" onClick={this.handleExport}>EXPORT</button></div>
                                        <div className="col-md-3"></div>
                                        
                                      </div>
                                    
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>CUSTOM FIELDS</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    
                                      <div style={{marginLeft:'10px', width:'100%'}}>
                                        <table className="table cftbl" style={{width:'100%'}}>
                                          {(this.state.custom_fields_json.length>0) &&
                                              <thead>
                                                <tr>  
                                                  <th>Name</th>
                                                  <th></th>
                                                </tr>
                                              </thead>
                                          }
                                          <tbody>
                                          {(this.state.custom_fields_json.length>0) &&
                                            <>
                                            {this.state.custom_fields_json.map((value,index) => {
                                              return (
                                                <tr key={'cfp-'+index}>
                                                  <td><input type="text" className="form-control" value={value.name} data-id={index} data-target="name" onChange={this.handleSetCustomField} onKeyDown={this.handleCustomFieldKeydown} autoFocus={true} id={"cfj-name-"+index}/></td>
                                                  <td>
                                                    <button type="button" className="btn" data-id={index} onClick={this.handleRemoveCustomField} style={{marginTop:'3px'}}>
                                                      <FontAwesomeIcon icon={faTimes}  style={{marginRight:'0px'}}></FontAwesomeIcon>
                                                    </button>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                            <tr>
                                              <td colSpan={4} style={{textAlign:'center'}}>
                                                <button type="button" className="btn btn-outline-info btn-block" onClick={this.handleAddMoreCustomField} style={{marginTop:'3px',marginLeft:'3px'}}>
                                                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                </button>
                                              </td>
                                            </tr>
                                            </>
                                          }
                                          {(this.state.custom_fields_json.length===0) &&
                                            <tr>
                                            <td colSpan={3} className="text-center">
                                              <button type="button" className="btn btn-outline-info" onClick={this.handleAddMoreCustomField} style={{marginTop:'3px'}}>
                                                Add Custom Fields
                                              </button>
                                            </td>
                                          </tr>
                                          }
                                          </tbody>
                                        </table>
                                      </div>
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-custom-field" onClick={this.handleSaveCustomField}>Save Settings</button>
                                <button type="button" id="save-custom-field-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                              </div>
                            </div>


                           
                           



                          </div>
                         
                        </>
                        }
                      
                        {(this.state.show_custom_headers===true) &&
                          <ImportHeaderSettings headers={this.state.csv_headers} contact_import_headers={this.state.contact_import_headers} import_data={this.state.import_data} import_file={this.state.import_file}/>
                        }

                     </div>
                  </div>
                </div>
              </div>
              <ModalBox id="export_contact_csv" title="CSV EXPORT" data_id="CSV EXPORT"/>
              <ModalBox id="add_contact_group" title="ADD CONTACT GROUPS" data_id="Contact Croups"/>
              <ModalBox id="add_contact_type" title="ADD CONTACT TYPE" data_id="Contact Type"/>
              <ModalBox id="add_payment_mode" title="ADD PAYMENT MODE"  data_id="Payment Mode"/>
              <ModalBox id="add_category" title="ADD CATEGORY"  data_id="Category"/>
              <ModalBox id="add_unit" title="ADD UNIT"  data_id="Unit"/>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
            </div>
        );
    }
}

class ImportHeaderSettings extends Component{
  constructor(props) {
    super(props)
    this.state = {
      headers : [],
      headers_list : [],
      current_screen : 'setup_headers',
      unmapped_headers : [],
      offset : 0,
      progress : 0
    }
  }

  handleChangeHeader = e =>{
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    let headers = this.state.headers;
    headers[key].default = value;
    this.setState({headers:headers});
  }

  closeHeaderSetting = e =>{
    if(document.getElementById("hideImportHeader")){
      document.getElementById("hideImportHeader").click();
    }
  }
  handleSaveHeaders = e =>{
    let thisid = e.currentTarget.id;
    //document.getElementById(thisid).classList.add("hide");
    //document.getElementById(thisid+'-btn-spinner').classList.remove("hide");


    let qs = document.querySelectorAll(".custom-header");
    let unmapped_headers = [];
    for (let index = 0; index < qs.length; index++) {
      const thisvalue = qs[index].value;
      const data_value = qs[index].getAttribute('data_value');
      if(thisvalue===""){
        unmapped_headers.push(data_value);
      }
    }

    this.setState({current_screen:'preview_headers',unmapped_headers:unmapped_headers});
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
        name : 'import_contact_headers_new',
        value : this.state.headers
    }
    
    let api = app_api+'/save_option';
    axios.post(api, send_data)
      .then(function (response) {
        //document.getElementById(thisid).classList.remove("hide");
        //document.getElementById(thisid+'-btn-spinner').classList.add("hide");
    }.bind(this)).catch(function(res){
        
    })
  }

  componentDidMount(){
    this.setState({headers:this.props.contact_import_headers});
    let header = this.props.contact_import_headers;
    let headers_list = [];
    Object.keys(header).forEach(function(key) {
      headers_list.push(key);
    });
    this.setState({headers_list:headers_list})
  }

  moveBack = () =>{
    this.setState({current_screen:'setup_headers'});
  }
  handleImportData = (e) =>{
    let thisid = e.currentTarget.id;
    document.getElementById(thisid).classList.add("hide");
    document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    var element = document.getElementById('process-csv-upload');
    element.classList.remove("fade");
    element.style.display="block"; 
    this.handleProcessUpload();
  }
  handleProcessUpload = () =>{
    let count = this.props.import_data.length;
    const formData = new FormData();

    formData.append('importFile', this.props.import_file);
    formData.append('store_name', user_info.store_name);
    formData.append('branch_id', user_info.login_id);
    formData.append('user_id', user_info.user_id);
    formData.append('uuid', utils.uuid());
    formData.append('role_id', user_info.access_level);
    formData.append('token', user_info.token);
    formData.append('_schema', user_info._schema);
    formData.append('type', 'upload_data');
    formData.append('target', 'contact');
    formData.append('count', this.props.import_data.length);
    formData.append('offset', this.state.offset);
    let api = app_api+'/process_import_csv';
    axios.post(api, formData)
      .then(function (response) {
        let data = response.data;
        let offset = data.offset;
        let progress = (offset/count)*100;
        if(count<100){
          progress = 100;
        }
        if(progress>100){
          progress = 100;
        }
        this.setState({progress:Math.round(progress)});
        this.setState({offset:offset});
        if(data.is_over===false){
          this.handleProcessUpload();
        }else{
          setTimeout(() => {
            var element = document.getElementById('process-csv-upload');
            element.classList.add("fade");
            element.style.display="none"; 
            window.location.href = '/contacts';
          }, 500);
        }
    }.bind(this)).catch(function(res){
        
    })
  }
  render() {
    return    <div className="row">
                <div className="col-md-12">
                  <div className="box-container" style={{padding:'0px'}}>
                    <div className="box-head">
                      <label>MAP IMPORT FILE HEADERS</label>
                    </div>
                    {(this.state.current_screen==='setup_headers') &&
                      <>
                      <div className="box-body"> 
                        <div className="row">
                            
                            <div className="col-md-12">
                            <ul className="list-group">
                              {this.state.headers_list.map(items => (
                                <li className="list-group-item">
                                  <div className="row">
                                      <div className="col-md-6">{this.state.headers[items].label} : </div>
                                      <div className="col-md-6">
                                        <SelectDropdown options={this.props.headers} data_id={this.state.headers[items].data_id} default={this.state.headers[items].default} onChange={this.handleChangeHeader} data_value={this.state.headers[items].data_value}/>
                                      </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                            </div>
                         
                        </div>
                      </div>
                      <div className="row"  style={{padding:'10px'}}>
                        
                        <div className="col-md-6">
                          <button type="button" className="btn btn-secondary" onClick={this.closeHeaderSetting}>Close</button>
                        </div>
                        <div className="col-md-6 text-right">
                        
                            <button type="button" className="btn btn-success" id="set-custom-header" onClick={this.handleSaveHeaders}>Next</button>
                            <button type="button" id="set-custom-header-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      
                        </div>
                       
                      </div>
                      </>
                    }


                    {(this.state.current_screen==='preview_headers') &&
                      <>
                      <div className="box-body"> 
                        <div className="row">
                      
                          <div className="col-md-12">
                            <ul className="list-group">
                              <li className="list-group-item">
                                  <h5>Total imported record(s) : <span style={{color:'#27b327'}}>{this.props.import_data.length}</span></h5>
                              </li>
                              <li className="list-group-item">
                                  <h5>Unmapped headers</h5>
                                  {(this.state.unmapped_headers.length>0) &&
                                    <ul>
                                    {this.state.unmapped_headers.map((v) =>(
                                      <li>{v}</li>
                                    ))
                                    }
                                  </ul>
                                  }
                                  {(this.state.unmapped_headers.length===0) &&
                                    <>
                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon> 
                                    All headers mapped
                                    </>
                                  }
                              </li>
                            </ul>
                          </div>
                      
                        </div>
                      </div>
                      <div className="row"  style={{padding:'10px'}}>
                    
                        <div className="col-md-6">
                          <button type="button" className="btn btn-success" onClick={this.moveBack}>Previous</button>
                        </div>
                        <div className="col-md-6 text-right">
                            <button type="button" className="btn btn-danger" id="import-data" onClick={this.handleImportData}>Import</button>
                            <button type="button" id="import-data-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      
                        </div>
                    
                      </div>
                      </>
                    }
                  </div>
                </div>
                <div id="process-csv-upload" className="modal fade">
                  <div className="modal-dialog">
                    <div className="modal-content csv-load-blk">
                      <div className="parent-bar">
                        <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                      </div>
                      <p>Please wait while import complete ({this.state.progress}%)</p>
                    </div>
                  </div>
                </div>
              </div>
         
  }
}