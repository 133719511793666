import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
export default class ListCount extends Component {
    constructor() {
      super();
      this.state = {
        
      };
      
    }
   
    render() {
        return (
            <span style={{marginLeft:'15px',fontSize:'18px',padding:'5px 10px',background: '#f8f8f8'}}>
                {this.props.list.length} in List
            </span>
        );
    }
}