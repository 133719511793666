import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner,faPencilAlt,faTrash,faCheckCircle, faTimes, faArrowUp, faArrowRight, faArrowDown, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import Draggable from 'react-draggable';
import Notification from "../notification.js";


import axios from 'axios';
import utils from "../../utils.js";

let {enc_key,app_api,user_info} = require('../../library.js');
let _this;

export default class SettingsPrintTemplateDesign extends Component {
     constructor() {
      super();
      this.state = {
        desing_data : {design_name:'',particulars_header:[],draggable_header:[]},
        is_show_desing_area : false,
        is_saving : false,
        particular_header_tmp :[],
        particulars_list : [
                            {key:'sno',value:'SNO',is_checked:false,position:0},
                            {key:'qty',value:'QUANTITY',is_checked:false,position:0},
                            {key:'particulars',value:'PARTICULARS',is_checked:false,position:0},
                            {key:'hsn',value:'HSN',is_checked:false,position:0},
                            {key:'price',value:'PRICE',is_checked:false,position:0},
                            {key:'total',value:'TOTAL',is_checked:false,position:0}
                          ],
        draggable_list : [
          {key:'bno',value:'BILL NO',is_checked:false,position:{x:0,y:0},cssText:''},
          {key:'contact',value:'CONTACT NAME',is_checked:false,position:{x:0,y:0},cssText:''},
          {key:'date',value:'DATE',is_checked:false,position:{x:0,y:0},cssText:''},
          {key:'sales_man',value:'SALES MAN',is_checked:false,position:{x:0,y:0},cssText:''},
        ]
      }
    }
    
    componentDidMount = () =>{
      this.handleGetTemplateSettings();
    }

    handleGetTemplateSettings = () =>{
      let keys = "'print_template'";
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          keys:keys
      }
      let api = app_api+'/get_settings';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          
          if(resp.print_template!==undefined){
            let print_template = resp.print_template;
            this.setState({desing_data:print_template});
            this.setState({is_show_desing_area:true});
            let par_header = print_template.particulars_header;
            let drag_header = print_template.draggable_header;
            let par_tmp = [];
            for (let index = 0; index < par_header.length; index++) {
              const element = par_header[index];
              par_tmp.push(element.key);
            }
            let drag_tmp = [];
            for (let index = 0; index < drag_header.length; index++) {
              const element = drag_header[index];
              drag_tmp.push(element.key);
            }
            this.setState({particular_header_tmp:par_tmp});
            let particulars_list = this.state.particulars_list;
            for (let index = 0; index < particulars_list.length; index++) {
              const element = particulars_list[index];
              let idxOf = par_tmp.indexOf(element.key);
              
              if(idxOf>=0){
                let pos = par_header[idxOf].position;
                particulars_list[index].is_checked=true;
                particulars_list[index].position=pos;
              }
            }
            this.setState({particulars_list:particulars_list});

            let draggable_list = this.state.draggable_list;
            for (let index = 0; index < draggable_list.length; index++) {
              const element = draggable_list[index];
              let idxOf = drag_tmp.indexOf(element.key);
              
              if(idxOf>=0){
                let pos = drag_header[idxOf].position;
                draggable_list[index].is_checked=true;
                draggable_list[index].position=pos;
              }
            }
            this.setState({draggable_list:draggable_list});
          }
      }.bind(this)).catch(function(res){
          
      })
    }

    handleChangeDesignValue = (type,value) =>{
        let ddata = this.state.desing_data;
        if(type==='number_of_items'){
          let items = [];
          for (let index = 0; index < value; index++) {
            items.push(index);
          }
          value = items;
        }
        ddata[type] = value;
        this.setState({desing_data:ddata});
      }
      handleProceedForDesing = () =>{
        this.setState({is_show_desing_area:true});
      }
      handleCheckDraggableItems = (is_checked,name,idx) =>{
        let dr_list = this.state.draggable_list;
        dr_list[idx].is_checked=is_checked;
        this.setState({draggable_list:dr_list});
        let ddata = this.state.desing_data;
        let draggable_header = [];
        for (let index = 0; index < dr_list.length; index++) {
          const element = dr_list[index];
          if(element.is_checked===true){
            draggable_header.push(element);
          }
        }
        ddata.draggable_header = draggable_header;
        this.setState({desing_data:ddata});
      }

      handleCheckParticularHeader = (is_checked,name,idx) =>{
        let par_list = this.state.particulars_list;
        par_list[idx].is_checked=is_checked;
        this.setState({particulars_list:par_list});
        let ddata = this.state.desing_data;

        let particulars_header = [];
        for (let index = 0; index < par_list.length; index++) {
          const element = par_list[index];
          if(element.is_checked===true){
            particulars_header.push(element);
          }
        }
        ddata.particulars_header = particulars_header;
        this.setState({desing_data:ddata});
        let head_tmp = this.state.particular_header_tmp;
        let head_idx = head_tmp.indexOf(name);
        if(head_idx>=0){
          head_tmp.splice(head_idx,1);
        }else{
          head_tmp.push(name);
        }
        this.setState({particular_header_tmp:head_tmp});
       /*  if(ddata['particulars_header']===undefined){
          ddata['particulars_header'] = [];
        } */
       /*  let head_idx = ddata['particulars_header'].indexOf(name);
        if(is_checked===true){
          ddata['particulars_header'].push(name);
        }else{
          ddata['particulars_header'].splice(head_idx,1);
        } */
      }
     
      handleChangeItemPosition = (value,index) =>{
        let par_list = this.state.particulars_list;
        let ddata = this.state.desing_data;

        par_list[index].position=value;
        this.setState({particulars_list:par_list});
        
        let particulars_header = [];
        for (let index = 0; index < par_list.length; index++) {
          const element = par_list[index];
          if(element.is_checked===true){
            particulars_header.push(element);
          }
        }

        particulars_header.sort((a, b) => {
          if (a.position < b.position) return -1
          return a.position > b.position ? 1 : 0
        })

        ddata.particulars_header = particulars_header;
        this.setState({desing_data:ddata});
      }

      handleSavePrintTemplate = () =>{
        this.setState({is_saving:true});
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            name : 'print_template',
            value : this.state.desing_data
        }
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
            this.setState({is_saving:false});
        }.bind(this)).catch(function(res){
            
        })
      }
      handleStart = (e) =>{
        //console.log(e);
      }
      handleDrag = (e) =>{
        //console.log(e);
      }
      handleStop = (event, data) =>{
        let ddata = this.state.desing_data;
        let draggable_header = ddata.draggable_header;
        
        let idx = parseInt(data.node.id);
        
        let cssText = data.node.style.cssText;
        let x = data.x;
        let y = data.y;
        let dh = {x:x,y:y};
        draggable_header[idx].position = dh;
        draggable_header[idx].cssText = cssText;
        ddata.draggable_header=draggable_header;
        this.setState({desing_data:ddata});
      }
    render() {
      
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Print Template</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                 
                  <div className="content-area">
                    
                    <div className="main-content border-bottom comp-detail-info">
                      <div className="inner-content">
                      <div className="row">
                        <div className="col-md-4" style={{borderRight:'1px solid #d7d7d7'}}>
                            <div className="row">
                                <div className="col-md-9">
                                    <input type="text" className="form-control" placeholder="Enter Template Name" onChange={(e)=>this.handleChangeDesignValue('design_name',e.target.value)} defaultValue={this.state.desing_data.design_name}/>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-success btn-small" onClick={this.handleProceedForDesing}>Go</button>
                                </div>
                                <div className="col-md-12"><hr /></div>
                            </div>
                                {(this.state.is_show_desing_area===true) &&
                                  <div className="row">
                                      <div className="col-md-12">
                                        <h4 htmlFor="page_width">Page Dimension</h4>
                                      </div>
                                      <div className="col-md-6">
                                      <label>Page Width</label>
                                      <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Width" onChange={(e)=>this.handleChangeDesignValue('page_width',e.target.value)} defaultValue={this.state.desing_data.page_width} id="page_width"/>
                                        <div className="input-group-append">
                                          <span className="input-group-text">cm</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <label>Page Height</label>
                                      <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Height" onChange={(e)=>this.handleChangeDesignValue('page_height',e.target.value)} defaultValue={this.state.desing_data.page_height} id="page_height"/>
                                        <div className="input-group-append">
                                          <span className="input-group-text">cm</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <hr />
                                    </div>
                                  </div>
                                }
                                {(this.state.desing_data.page_height!==undefined && this.state.desing_data.page_height!=="" && this.state.desing_data.page_width!=="") &&
                                <div className="row">
                                <div className="col-md-12">
                                  <h4>Page Header Settings</h4>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="page_header_height">Header Height</label>
                                </div>
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Height" onChange={(e)=>this.handleChangeDesignValue('page_header_height',e.target.value)} defaultValue={this.state.desing_data.page_header_height} id="page_header_height"/>
                                    <div className="input-group-append">
                                      <span className="input-group-text">cm</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mrtop-20">
                                  <div className="row">
                                  <div className="col-md-12">
                                    <label>Header Margin</label>
                                  </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-12" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowUp}/> <input type="text" style={{width:'40px'}} onChange={(e)=>this.handleChangeDesignValue('page_header_margin_top',e.target.value)} defaultValue={this.state.desing_data.page_header_margin_top}/>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowLeft}/>
                                        <input type="text" style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('page_header_margin_left',e.target.value)} defaultValue={this.state.desing_data.page_header_margin_left}/>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <span>cm</span>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <input type="text"  style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('page_header_margin_right',e.target.value)} defaultValue={this.state.desing_data.page_header_margin_right}/>
                                        <FontAwesomeIcon icon={faArrowRight}/>
                                      </div>
                                      <div className="col-md-12" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        <input type="text"  style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('page_header_margin_bottom',e.target.value)} defaultValue={this.state.desing_data.page_header_margin_bottom}/>
                                      </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mrtop-20">
                                  <label>Choose the following to show in the header</label>
                                  <table className="table">
                                    <tbody>
                                      {(this.state.draggable_list.map((value,index)=>{
                                          return (
                                              <tr>
                                                <td>
                                                  <input className="form-check-input" type="checkbox" onChange={(e)=>this.handleCheckDraggableItems(e.target.checked,value.key,index)} id={"item-"+value.key} checked={value.is_checked}/>
                                                  <label className="form-check-label" htmlFor={"item-"+value.key}>{value.value}</label>
                                                </td>
                                              </tr>
                                          )
                                      }))}
                                    </tbody>
                                  </table>
                                </div>
                                </div>
                                }
                                {(this.state.desing_data.page_height!==undefined && this.state.desing_data.page_height!=="" && this.state.desing_data.page_width!=="") &&
                                <div className="row">
                                <div className="col-md-12">
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <h4>Particulars List Settings</h4>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="particulars_height">Box Height</label>
                                </div>
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Height" onChange={(e)=>this.handleChangeDesignValue('particulars_height',e.target.value)} defaultValue={this.state.desing_data.particulars_height} id="particulars_height"/>
                                    <div className="input-group-append">
                                      <span className="input-group-text">cm</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mrtop-20">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>Particulars Box Margin</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-12" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowUp}/> <input type="text" style={{width:'40px'}} onChange={(e)=>this.handleChangeDesignValue('particulars_margin_top',e.target.value)} defaultValue={this.state.desing_data.particulars_margin_top}/>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowLeft}/>
                                        <input type="text" style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('particulars_margin_left',e.target.value)} defaultValue={this.state.desing_data.particulars_margin_left}/>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <span>cm</span>
                                      </div>
                                      <div className="col-md-4" style={{textAlign:'center'}}>
                                        <input type="text"  style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('particulars_margin_right',e.target.value)} defaultValue={this.state.desing_data.particulars_margin_right}/>
                                        <FontAwesomeIcon icon={faArrowRight}/>
                                      </div>
                                      <div className="col-md-12" style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        <input type="text"  style={{width:'40px'}}  onChange={(e)=>this.handleChangeDesignValue('particulars_margin_bottom',e.target.value)} defaultValue={this.state.desing_data.particulars_margin_bottom}/>
                                      </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12 mrtop-20">
                                      <label>Add Particular headers to the Box</label>
                                    </div>
                                    <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                          <tr>
                                            <td>Headers</td>
                                            <td>Position</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(this.state.particulars_list.map((value,index)=>{
                                            return(
                                              <tr>
                                                <td>
                                                    <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" onChange={(e)=>this.handleCheckParticularHeader(e.target.checked,value.key,index)} id={"item-"+value.key} checked={value.is_checked}/>
                                                    <label className="form-check-label" htmlFor={"item-"+value.key}>{value.value}</label>
                                                  </div>
                                                </td>
                                                <td>
                                                  {(value.is_checked===true) &&
                                                    <input type="text" style={{width:'50px'}} value={value.position} onChange={(e)=>this.handleChangeItemPosition(e.target.value,index)}/>
                                                  }
                                                </td>
                                              </tr>
                                            )
                                          }))}
                                            
                                            
                                        </tbody>
                                    </table>
                                  
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="number_of_items">Number Of List Item In One Page</label>
                                </div>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" placeholder="Number Of Items in this list" onChange={(e)=>this.handleChangeDesignValue('number_of_items',e.target.value)} defaultValue={(this.state.desing_data.number_of_items!==undefined)?this.state.desing_data.number_of_items.length:''} id="number_of_items"/>
                                </div>
                                </div>
                                }
                        </div>
                        {(this.state.is_show_desing_area===true) &&
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12">
                                <h4 style={{textAlign:'center'}}>{this.state.desing_data.design_name} Print Design</h4>
                                <hr />
                              </div>
                            </div>
                            {(this.state.desing_data.page_height!==undefined && this.state.desing_data.page_height!=="" && this.state.desing_data.page_width!=="") &&
                            <div className="row">
                              <div className="col-md-12">
                                  <div style={{margin:'0 auto',height:this.state.desing_data.page_height+'cm',width:this.state.desing_data.page_width+'cm',border:'1px solid #d7d7d7'}}>
                                  {(this.state.desing_data.page_header_height!==undefined && this.state.desing_data.page_header_height!=="") &&
                                      <div style={{position:'relative',height:this.state.desing_data.page_header_height+'cm',border:'1px solid #d7d7d7',marginTop:this.state.desing_data.page_header_margin_top+'cm',marginRight:this.state.desing_data.page_header_margin_right+'cm',marginBottom:this.state.desing_data.page_header_margin_bottom+'cm',marginLeft:this.state.desing_data.page_header_margin_left+'cm'}}>
                                        {(this.state.desing_data.draggable_header!==undefined) && 
                                          <>
                                          {(this.state.desing_data.draggable_header.map((value,index)=>{
                                            return(
                                              <Draggable
                                                handle=".handle"
                                                position={null}
                                                defaultPosition={value.position}
                                                bounds="parent"
                                                grid={[1, 1]}
                                                scale={1}
                                                onStart={this.handleStart}
                                                onDrag={this.handleDrag}
                                                onStop={this.handleStop} key={value.key}>

                                                <div id={index}>
                                                  <div className="handle">
                                                      <label>{value.value}</label>
                                                  </div>
                                                </div>
                                              </Draggable>
                                            )
                                          }))}
                                            
                                          </>
                                        }
                                      </div>
                                  }
                                  {(this.state.desing_data.particulars_height!==undefined && this.state.desing_data.particulars_height!=="") &&
                                      <div style={{height:this.state.desing_data.particulars_height+'cm',border:(this.state.desing_data.particulars_header!==undefined && this.state.desing_data.particulars_header.length>0)?'none':'1px solid #d7d7d7',marginTop:this.state.desing_data.particulars_margin_top+'cm',marginRight:this.state.desing_data.particulars_margin_right+'cm',marginBottom:this.state.desing_data.particulars_margin_bottom+'cm',marginLeft:this.state.desing_data.particulars_margin_left+'cm'}}>

                                        {(this.state.desing_data.particulars_header!==undefined && this.state.desing_data.particulars_header.length>0) &&
                                            <table className="print-template-table">
                                              <thead>
                                              <tr>
                                              {(this.state.desing_data.particulars_header.map((value,index)=>{
                                                return(
                                                  <>
                                                  {(value.is_checked===true) &&
                                                    <th style={{textAlign:'center',width:(value.key==='sno' || value.key==='qty')?'40px':(value.key==='price')?'50px':(value.key==='total')?'60px':''}}>{value.key}</th>
                                                  }
                                                  </>
                                                )
                                              }))}
                                              </tr>
                                              </thead>
                                              {(this.state.desing_data.number_of_items!==undefined && Array.isArray(this.state.desing_data.number_of_items) && this.state.desing_data.number_of_items.length>0) &&
                                                <tbody>
                                                  {(this.state.desing_data.number_of_items.map((nitems,no)=>{
                                                    return(
                                                      <tr>
                                                        {(this.state.desing_data.particulars_header.map((value,index)=>{
                                                          return(
                                                            <>
                                                            
                                                              <th style={{textAlign:'center'}}>
                                                                {(value.key==='sno')?(no+1):''}
                                                              </th>
                                                            
                                                            </>
                                                          )
                                                        }))}
                                                      </tr>
                                                    )
                                                  }))}
                                                </tbody>
                                              }
                                              {(this.state.desing_data.number_of_items!==undefined && Array.isArray(this.state.desing_data.number_of_items) && this.state.desing_data.number_of_items.length>0) &&
                                                <tfoot>
                                                   <tr>
                                                        
                                                        {(this.state.desing_data.particulars_header.map((value,index)=>{
                                                          return(
                                                            <>
                                                              <th style={{textAlign:'right'}}>
                                                              {(value.key==='total')?'TOTAL':''}
                                                              </th>
                                                            </>
                                                          )
                                                        }))}
                                                      </tr>
                                                </tfoot>
                                              }
                                            </table>
                                        }
                                      </div>
                                  }
                                  </div>
                              </div>
                            </div>
                            }
                          </div>
                        }
                    </div>
                      </div>
                    </div>
                    
                    <div className="content-head border-bottom">
                      <div className="row mrbtm-10">
                        <div className="col-md-7">
                        </div>
                        {(this.state.is_show_desing_area===true) &&
                        <div className="col-md-5">
                          {(this.state.is_saving===false) &&
                          <button type="button" className="btn btn-success pull-right" onClick={this.handleSavePrintTemplate}>Save Template</button>
                          }
                          {(this.state.is_saving===true) &&
                            <button type="button" id="btn-spinner" className="btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          }
                        </div>
                        }
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>

            </div>
        );
    }
}
