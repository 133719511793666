import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faTrash  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import ClosedFYButton from "./closed-fy-button";
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import DrawerLink from "./open-drawer-link";
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
export default class Payments extends Component {
    constructor() {
        super();
        this.state = {
          is_order_pay : false,
          active_tab : 'all',
          FromDate:  new Date(),
          ToDate: new Date(),
          payments_list:[],
          payments_list_temp:[],
          trash_count : 0,
          loading_process:'Loading...',
          is_search:false,
          current_page:1,
          current_filter_payment:'all',
          is_fy_closed : false,
          screen : ''
        }

        this.refreshPaymentList = this.refreshPaymentList.bind(this);
        this.handlePaymentSearch = this.handlePaymentSearch.bind(this);
        this.handleChangeShowPayment = this.handleChangeShowPayment.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };
  handleChangeShowPayment(e){
    let current_page = this.state.current_page;
    let active_tab = this.state.active_tab;
    let page = e.currentTarget.value;
    this.setState({current_filter_payment:page});
    if(current_page!==1){
      current_page = '/'+current_page;
    }else{
      current_page = '';
    }
    if(this.state.is_order_pay){
      if(page!=='all'){
        window.location.href = '/order/payments/'+active_tab+'/'+page+current_page;
      }else{
        window.location.href = '/order/payments/'+active_tab+current_page;
      }
    }else{
      if(page!=='all'){
        window.location.href = '/payments/'+active_tab+'/'+page+current_page;
      }else{
        window.location.href = '/payments/'+active_tab+current_page;
      }
    }
  }
  handlePaymentSearch(e){
    
      let _this = this;
      let item_name = document.getElementById("payment-search-box").value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ payments_list: [],is_search:true });
        clearTimeout(this.timeout);
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              search : item_name,
              search_item : amount_item,
              screen:this.state.screen
           }
        let api = app_api+'/search_payment';
        _this.timeout = setTimeout(function () {
        axios.post(api, body)
          .then(function (response) {
               let page_count = response.data.number_of_page;
                let trash_count = response.data.trash_count;
                if(response.data.is_empty){
                  _this.setState({loading_process:'Record(s) not found'});
                  _this.setState({total_page:0});
                  _this.setState({trash_count:trash_count});
                }else{
                  _this.setState({total_page:page_count});
                  _this.setState({trash_count:trash_count});
                  _this.setState({ 'payments_list': response.data.data });
                }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          _this.refreshPaymentList();
          _this.setState({ is_search:false });
        }
      }
  }


  componentDidMount(){
    let curl = window.location.href;

    const order_payments_type = curl.match(/order\/payments/i);
    let screen = '';
    
    if(order_payments_type){
      this.setState({is_order_pay:true});
      screen = 'order';
      this.setState({screen:screen});
    }
    const payments_type = curl.match(/payments\/([a-z_A-Z]+$)/i);

    let ptype = 'all';
    if(payments_type){
        ptype = payments_type[1];
        if(ptype==='in' || ptype==='out' || ptype==='trash'){
            this.setState({active_tab:ptype});
        }
    }

    const page_tab = curl.match(/payments\/([a-z_A-Z]+)\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[2];
      ptype = page_tab[1];
      this.setState({active_tab: ptype,current_page:current_page});
    }

    const payment_filter = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+$)/i);
    let filter_page = 'all';
    if(payment_filter){
      ptype = payment_filter[1];
      filter_page = payment_filter[2];
      this.setState({active_tab: ptype,current_filter_payment:filter_page});
    }
    const payment_filter_page = curl.match(/payments\/([a-z_A-Z]+)\/([a-z_A-Z]+)\/(\d+$)/i);
    if(payment_filter_page){
      ptype = payment_filter_page[1];
      filter_page = payment_filter_page[2];
      current_page = payment_filter_page[3];
      this.setState({active_tab: ptype,current_page:current_page,current_filter_payment:filter_page});
    }

    let body = {};
        body = {
              screen :screen,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
              filter_page : filter_page
           }
        let api = app_api+'/get_payments';
        axios.post(api, body)
        .then(function (response) {
          
          let page_count = response.data.number_of_page;
          let trash_count = response.data.trash_count;
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            this.setState({trash_count:trash_count});
          }else{
            this.setState({total_page:page_count});
            this.setState({trash_count:trash_count});
            this.setState({ 'payments_list': response.data.data });
            this.setState({ 'payments_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })

      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'is_financial_year_closed'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      }) 
  }

  refreshPaymentList(){
    let curl = window.location.href;
    const order_payments_type = curl.match(/order\/payments\/([a-z_A-Z]+$)/i);
    let screen = '';
    if(order_payments_type){
      screen = 'order';
    }
    const payments_type = curl.match(/payments\/([a-z_A-Z]+$)/i);

    let ptype = 'all';
    if(payments_type){
        ptype = payments_type[1];
        if(ptype==='in' || ptype==='out' || ptype==='trash'){
            this.setState({active_tab:ptype});
        }
    }

    const page_tab = curl.match(/payments\/([a-z_A-Z]+)\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[2];
      ptype = page_tab[1];
      this.setState({active_tab: page_tab[1]});
    }

    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
              screen : screen
           }
        let api = app_api+'/get_payments';
        axios.post(api, body)
        .then(function (response) {
          
          let page_count = response.data.number_of_page;
          let trash_count = response.data.trash_count;
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            this.setState({trash_count:trash_count});
          }else{
            this.setState({total_page:page_count});
            this.setState({trash_count:trash_count});
            this.setState({ 'payments_list': response.data.data });
            this.setState({ 'payments_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Payment {this.state.active_tab} List</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onKeyUp={this.handlePaymentSearch} id="payment-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                          <Notification /> 
                          {(this.state.trash_count>0) &&
                            <a href={(this.state.is_order_pay===false)?"/payments/trash":"/order/payments/trash"} className="btrash">
                              <FontAwesomeIcon  icon={faTrash}  className=""/><span className="notif-count">{this.state.trash_count}</span>
                            </a>
                          }
                          {(this.is_access('payments-in-create') || this.is_access('payments-out-create')) &&
                          <>
                          {(this.state.is_fy_closed===false) &&
                          <>
                            {(this.state.is_order_pay===false) &&
                              <a href={(this.state.active_tab==='all' || this.state.active_tab==='in')?'/new-payment/in':'/new-payment/out'}><button type="button" className="btn btn-success pull-right nav-btn">New Payment</button></a>
                            }
                            {(this.state.is_order_pay===true) &&
                              <a href={(this.state.active_tab==='all' || this.state.active_tab==='in')?'/new-payment/order/in':'/new-payment/order/out'}><button type="button" className="btn btn-success pull-right nav-btn">New Payment</button></a>
                            }
                            <ShortcutHelp  arrow="top" target="new" shortcuts={['Ctrl+Alt+N']}/>
                          </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <>
                              <ClosedFYButton className="pull-right nav-btn" label="New Payment" type="button"/>
                            </>
                          }
                          </>
                          }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                {(this.state.active_tab!=='trash' && this.state.is_search===false) && 
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                    {(this.state.is_order_pay===true) &&
                    <>
                     {this.is_access('billing-sale') &&
                        <li className={this.state.active_tab==='sales'?'active':''}><a href="/orders">Sales</a></li>
                      }
                      {this.is_access('billing-purchase') &&
                        <li className={this.state.active_tab==='purchase'?'active':''}><a href="/orders/purchase">Purchase</a></li>
                      }
                      {this.is_access('billing-sales-return') &&
                        <li className={this.state.active_tab==='sales_return'?'active':''}><a href="/orders/sales_return">Sales Return</a></li>
                      }
                      {this.is_access('billing-purchase-return') &&
                        <li className={this.state.active_tab==='purchase_return'?'active':''}><a href="/orders/purchase_return">Purchase Return</a></li>
                      }
                      {this.is_access('payments-in') &&
                        <li className={this.state.active_tab==='in'?'active':''}><a href="/order/payments/in">Payment Received</a></li>
                      }
                      {this.is_access('payments-out') &&
                        <li className={this.state.active_tab==='out'?'active':''}><a href="/order/payments/out">Payment Sent</a></li>
                      }
                    </>
                    }
                    {(this.state.is_order_pay===false) &&
                      <>
                        {(this.is_access('payments-in') && this.is_access('payments-out')) &&
                          <li className={this.state.active_tab==='all'?'active':''}><a href="/payments">All Payments</a></li>
                        }
                        {this.is_access('payments-in') &&
                          <li className={this.state.active_tab==='in'?'active':''}><a href="/payments/in">Received</a></li>
                        }
                        {this.is_access('payments-out') &&
                          <li className={this.state.active_tab==='out'?'active':''}><a href="/payments/out">Sent</a></li>
                        }
                        {(localStorage.getItem('is_account_module')=='true') &&
                          <li className={this.state.active_tab==='cheques'?'active':''}><a href="/payments/cheques">Cheques</a></li>
                        }
                      </>
                    }
                    </ul>
                    <ShortcutHelp target="tabs"  arrow="top" shortcuts={['Ctrl+Alt+1','Ctrl+Alt+2','Ctrl+Alt+3']}/>
                  </div>
                }
                  <div className="content-area">
                    {(this.state.active_tab==='in') &&
                     <div className="content-filter" >
                        <div className="row">
                            <div className="col-md-4">
                                <label className="lbl">SHOW PAYMENTS</label>
                                <select className="form-control" onChange={this.handleChangeShowPayment} value={this.state.current_filter_payment}>
                                    <option value="all">All</option>
                                    <option value="new">New</option>
                                    <option value="old">Payment Recovery(Old)</option>
                                </select>
                            </div>

                          {/*<div className="col-md-4">
                            <div className="input-group mrtop-32">
                              <input type="text" className="form-control no-border-right" placeholder="Enter Payment #, Amount #" />
                              <div className="input-group-append">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>*/}
                        </div>
                     </div>
                   }
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">PAYMENT #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">NAME</th>
                            <th scope="col">TYPE</th>
                            <th scope="col">MODE OF PAYMENT</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.payments_list && this.state.payments_list.length > 0) ?this.state.payments_list.map( (value, index) => {
                          let payment_id = (value.payment_id!==undefined)?value.payment_id:value.order_payment_id;
                          let payment_link = (value.payment_id!==undefined)?'/view-payment/':'/order/view-payment/';
                          let edit_target = (value.payment_id!==undefined)?'/new-payment/edit/':'/new-payment/order/edit/';
                           return (
                            <tr key={ index } id={"billing-row-"+payment_id} className={(value.status!==6)?"contact-row":"contact-row strikeout"} data-id={7}>
                             
                              <td>
                                <Link link={payment_link+payment_id} name={value.payment_number} />
                                {(value.payments_meta.length>0) &&

                                
                                  <span style={{fontSize:'12px'}}>
                                    &nbsp;(
                                      {
                                        value.payments_meta.map((pmv) => (
                                          <>
                                          &nbsp;
                                          <DrawerLink target="billing" url={"/"+pmv.resource_url+"/"+pmv.target+"/"+pmv.id} schema={pmv.schema} name={pmv.sequence} />
                                          &nbsp;
                                          </>
                                        ))
                                      }
                                    )
                                  </span>
                                
                                
                                }
                              </td>
                              <td>{value.created_on}</td>
                              <td><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} /></td>
                              <td>{value.ptype}</td>
                              <td>{value.payment_mode}</td>            
                              <td>{this.currency_format(value.paid_amount.replace(/,/g ,''))}</td>
                              <td>
                                {(value.status!==6) &&
                                  <Tooltip opt={this.state.top_option} id={payment_id} index={index} view={payment_link+payment_id}  edit={edit_target+payment_id} delete={{active_tab:'payment',id:payment_id,data_value:'payment',delete_label:value.delete_label,is_order_pay:this.state.is_order_pay}} target={value.ptype} item_count={this.state.payments_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                }
                                {(value.status===6) &&
                                  <Tooltip opt={this.state.top_option} id={payment_id} index={index} view={payment_link+payment_id}  target={value.ptype} item_count={this.state.payments_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                }
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                           
                         
                        </tbody>
                      </table>
                      {(this.state.payments_list && this.state.payments_list.length > 0) &&
                        <ListCount list={this.state.payments_list}/>
                      }
                      {(this.state.is_search===false) &&
                        <Pager total_page={this.state.total_page}/>
                      }
                     </div>
                  </div>
                </div>
              </div>

            </div>
        );
    }
}