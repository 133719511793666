import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/dancing-script";
import {QRCodeSVG} from 'qrcode.react';
import utils from '../utils.js';
import UPIQrCode from "./upi-qr-code.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class A4Print extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dispatched_info : []
        };
      
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }   

    componentDidMount(){
        let dispatched_info = [];
        let is_pieces_in_parcel = false;
        let is_no_of_parcels = false;
        
        if(this.props.print_data.dispatched_info!==undefined){
            Object.entries(this.props.print_data.dispatched_info).forEach((entry) => {
                let [key, value] = entry;
              
                if(value!==""){
                    if(key === 'dispatched_through'){
                        key = 'transport';
                    }else if(key==='dispatched_document'){
                        key = 'lr no';
                    }else if(key==='agent_name'){
                        key = 'agent';
                    }else if(key==='other_refrence'){
                        key = 'other_reference';
                    }else if(key==='no_of_parcels'){
                        key = 'No Of Parcles/Bales';
                        is_no_of_parcels = true;
                    }else if(key==='pieces_in_parcel'){
                        is_pieces_in_parcel = true;
                    }
                    dispatched_info.push({key:key.replaceAll('_',' ') ,value:value})
                }
            });
        }
        if(this.props.print_data.billing_data.pieces_in_parcel!==undefined && this.props.print_data.billing_data.pieces_in_parcel!=="" && is_pieces_in_parcel===false){
            dispatched_info.push({key:'Pieces In Parcel' ,value:this.props.print_data.billing_data.pieces_in_parcel});
            
        }
        if(this.props.print_data.billing_data.no_of_parcels!==undefined && this.props.print_data.billing_data.no_of_parcels!=="" && is_no_of_parcels===false){
            dispatched_info.push({key:'No Of Parcles/Bales' ,value:this.props.print_data.billing_data.no_of_parcels});
        }
        if(this.props.print_data.billing_data.note!==undefined && this.props.print_data.billing_data.note!==""){
            dispatched_info.push({key:'Notes' ,value:this.props.print_data.billing_data.note});
        }
        
        this.setState({dispatched_info:dispatched_info});
    }
    

    render() {
        let opening_balance = [];
        let opening_quantity = [];
        let calculate_grand_total =0;
        let total_quantity = 0;
     
        let advance_data_count = Math.ceil(this.state.dispatched_info.length/3);
        
        let customer_address = '';
        if(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.city!=='' && this.props.print_data.contact_info.city!==null){
            customer_address = this.props.print_data.contact_info.city;
        }

        if(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.pin!=='' && this.props.print_data.contact_info.pin!==null){
            customer_address += '-'+this.props.print_data.contact_info.pin+', ';
        }

        if(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.display_state!=='' && this.props.print_data.contact_info.display_state!==null){
            customer_address += this.props.print_data.contact_info.display_state
        }

      

        if(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.phone_1!=='' && this.props.print_data.contact_info.phone_1!==null){
            customer_address += '</br>'+this.props.print_data.contact_info.phone_1+', ';
        }
        if(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.phone_2!=='' && this.props.print_data.contact_info.phone_2!==null){
            customer_address += this.props.print_data.contact_info.phone_2+', ';
        }
        customer_address = customer_address.replace(/,\s*$/, "");
        return (
            <div style={{padding:'10px'}}>
                {Array.from(Array(this.props.print_data.item_page_count), (e, i) => {
                    let previous_ad = 0;
                    let page = i+1;
                    let items_per_page = 18;
                    let offset = (page - 1) * items_per_page;

                    let start_loop = offset;
                    let end_loop = (i * 18)+1;
                    let calculate_total = 0;
                    
                    return  (
                    <table className="table page-break" style={{border:'1px solid #333'}}>
                    <tbody>
                        <tr>
                            <td style={{width: '33%',textAlign: 'left'}}>

                                {(user_info.store_name==='billing' || user_info.store_name==='hsuits' || user_info.store_name==='hbrothers' || user_info.store_name==='hassociates' || user_info.store_name==='haneef_associates') && 
                                <>
                                     <h4 style={{margin:'30px 0px 0px 0px',fontSize:'15px'}}>
                                        SAREES : +91-9059800300
                                     </h4>
                                     <h4 style={{margin:'0px',fontSize:'15px'}}>
                                        SUITS  : +91-7995005053
                                     </h4>
                                     <h4 style={{margin:'0px',fontSize:'15px'}}>
                                        OFFICE : +91-40-66332133
                                    </h4>
                                     <h4 style={{margin:'0px',fontSize:'15px'}}>
                                        ACCOUNT: +91-8374667566
                                    </h4>
                                </>
                                }
                                 {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && user_info.store_name!=='hsuits' && user_info.store_name!=='hbrothers' && user_info.store_name!=='hassociates' && user_info.store_name!=='haneef_associates') && 
                                <>
                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].phone!=='' && this.props.print_data.billing_data.company_info[0].phone!==null) &&
                                    <>
                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("mobile")<0) &&
                                    <p style={{margin:'0px'}}>
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info)?this.props.print_data.billing_data.company_info[0].phone:''
                                    }
                                    </p>
                                    }
                                    </>
                                }
                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].email!=='' && this.props.print_data.billing_data.company_info[0].email!==null) &&
                                    <>
                                    {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("email")<0) &&
                                    <p style={{margin:'0px'}}>
                                        {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info)?this.props.print_data.billing_data.company_info[0].email:''
                                        }
                                    
                                    </p>
                                    }
                                    </>
                                }
                                </>
                                }
                                {(this.props.header_content && this.props.header_content!=='<p></p>' && this.props.header_content!=='') &&  
                                    <span dangerouslySetInnerHTML={{__html: this.props.header_content}}></span>
                                }
                            </td>
                            <td style={{width: '33%',textAlign: 'center',padding:'0px'}}>
                                <h3 style={{margin:'0px',fontSize:'20px',textTransform:'uppercase',marginTop:'25px',marginBottom:'5px'}}>
                                    <u>
                                    {(this.props.print_data.billing_type==="New Sale") &&
                                        <>
                                            {(this.props.print_data.invoice_title.sales_invoice_title!=="") &&
                                            <>
                                                {this.props.print_data.invoice_title.sales_invoice_title}
                                            </>
                                            }
                                            {(this.props.print_data.invoice_title.sales_invoice_title==="") &&
                                            <>
                                                {this.props.print_data.billing_type.replace("New",'')} INVOICE
                                            </>
                                            }
                                        </>
                                        }
                                        {(this.props.print_data.billing_type==="New Purchase") &&
                                        <>
                                            {(this.props.print_data.invoice_title.purchase_invoice_title!=="") &&
                                            <>
                                                {this.props.print_data.invoice_title.purchase_invoice_title}
                                            </>
                                            }
                                            {(this.props.print_data.invoice_title.purchase_invoice_title==="") &&
                                            <>
                                                {this.props.print_data.billing_type.replace("New",'')} INVOICE
                                            </>
                                            }
                                            {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].gst_type==='composite' && this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("bill-of-supply")<0) &&
                                            <label style={{fontSize:'13px',marginTop:'5px'}}>BILL OF SUPPLY</label>
                                            }
                                        </>
                                        }
                                        {(this.props.print_data.billing_type==="New Purchase Return" || this.props.print_data.billing_type==="New Sales Return") &&
                                        <>
                                            {this.props.print_data.billing_type.replace("New",'')} INVOICE
                                        </>
                                        }
                                    </u>
                                </h3>
                            
                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("company-name")<0) &&
                                <>
                                <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'20px'}}>
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info)?this.props.print_data.billing_data.company_info[0].company_name:''
                                    }
                                    </h2>
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0]!==undefined && this.props.print_data.billing_data.company_info[0].company_sub_title!=="" && this.props.print_data.billing_data.company_info[0].company_sub_title!==null) &&
                                    <>
                                    <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.props.print_data.billing_data.company_info[0].company_sub_title}</p>
                                    </>
                                    }
                                </>
                                }
                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("company-address")<0) &&
                                <>
                                    <p style={{margin:'0px'}}>
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].address!=='' && this.props.print_data.billing_data.company_info[0].address!==null)?this.props.print_data.billing_data.company_info[0].address:''
                                    }
                                    </p>
                                    <p style={{margin:'0px'}}>
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].city!=='' && this.props.print_data.billing_data.company_info[0].city!==null)?this.props.print_data.billing_data.company_info[0].city:''
                                    }
                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].pin_code!=='' && this.props.print_data.billing_data.company_info[0].pin_code!==null )?'-'+this.props.print_data.billing_data.company_info[0].pin_code:''
                                    }
                                    </p>
                                </>
                                }
                            </td>
                            <td style={{width: '33%',textAlign:'right'}}>
                                {(this.props.print_data.billing_data.irn_data && this.props.print_data.billing_data.irn_data.length!==0 && this.props.print_data.billing_data.irn_data.all!==undefined) &&
                                    <QRCodeSVG value={JSON.stringify(this.props.print_data.billing_data.irn_data.qr)} />
                                }
                                {(this.props.print_data.billing_data.target==='sale') &&
                                <>
                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("pay-qr")<0) &&
                                    <UPIQrCode amount={this.props.print_data.billing_data.total} transid={this.props.print_data.billing_data.id}/>
                                }
                                </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4 style={{fontSize:'14px',fontWeight:'bold',marginBottom:'0px'}}>INVOICE NO: {this.props.print_data.currrent_sequence}</h4>
                            </td>
                            <td style={{textAlign: 'center'}}>

                            {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info[0].gst_number!=='' && this.props.print_data.billing_data.company_info[0].gst_number!==null) &&
                                    <>
                                
                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].gst_type!=='no-gst' && this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("tax-info")<0) &&

                                <h4 style={{fontSize:'14px',fontWeight:'bold',marginBottom:'0px'}}>{(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info[0].gst_number)?'GSTIN. '+this.props.print_data.billing_data.company_info[0].gst_number:''}
                                </h4>

                                }
                                </>
                            }
                            </td>
                            <td style={{textAlign: 'right'}}>
                                <h4 style={{fontSize:'14px',fontWeight:'bold',marginBottom:'0px'}}>DATE : {this.props.print_data.billingDate}</h4>
                            </td>
                        </tr>
                        {(this.props.print_data.billing_data.irn_data && this.props.print_data.billing_data.irn_data.length!==0 && this.props.print_data.billing_data.irn_data.all!==undefined) &&
                            <>
                                <tr>
                                    <td colSpan={3} style={{padding:'0px'}}>
                                        <table className="table cell-border" style={{border:'none',margin:'0px'}}>
                                            <tr>
                                                <td style={{border:'none'}}>
                                                    <p style={{fontSize:'11px',fontWeight:'bold',margin:'0px'}}>IRN.{this.props.print_data.billing_data.irn_data.all.response_data.Irn} </p>
                                                </td>
                                                <td style={{textAlign:'right',border:'none'}}>
                                                    <p style={{fontSize:'11px',fontWeight:'bold',margin:'0px'}}>ACK NO.{this.props.print_data.billing_data.irn_data.all.response_data.AckNo} </p>
                                                </td>
                                                <td style={{textAlign:'right',border:'none'}}>
                                                    <p style={{fontSize:'11px',fontWeight:'bold',margin:'0px'}}>ACK DATE.{this.props.print_data.billing_data.irn_data.all.response_data.AckDt} </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </>
                        }
                        {(this.props.print_data.billing_data.irn_data && this.props.print_data.billing_data.irn_data.cancelled!==undefined) &&
                            <>
                                <tr>
                                    <td style={{width:'30%'}} colSpan={2}>
                                        <h4 style={{fontSize:'12px',fontWeight:'bold',marginBottom:'0px'}}>IRN NO. {this.props.print_data.billing_data.irn_data.cancelled.Irn} </h4>
                                    </td>
                                    <td style={{textAlign:'right'}}>
                                        <h4 style={{fontSize:'12px',fontWeight:'bold',marginBottom:'0px'}}>CANCELLED DATE. {this.props.print_data.billing_data.irn_data.cancelled.CancelDate} </h4>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <td style={{paddingBottom:'0px',paddingTop:'5px'}}>
                                <h4 style={{fontSize:'14px',fontWeight:'bold'}}>{this.props.print_data.contact_info!=''?this.props.print_data.contact_info.item_name:''}</h4>
                                <p style={{margin:'0px',fontSize:'14px'}}>
                                    {(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.address_1!=='' && this.props.print_data.contact_info.address_1!==null)?this.props.print_data.contact_info.address_1:''}
                                </p>
                                <p style={{margin:'0px',fontSize:'14px'}} dangerouslySetInnerHTML={{__html: customer_address}}>
                                </p>
                                <h4 style={{fontSize:'14px',fontWeight:'bold'}}>
                                {(this.props.print_data.contact_info!=='' && this.props.print_data.contact_info.gst_number!=='' && this.props.print_data.contact_info.gst_number!==null)?"GSTIN :"+this.props.print_data.contact_info.gst_number:''}
                                </h4>
                            </td>
                        
                            <td colspan="2" style={{padding:'5px'}}>
                            <table className="table cell-border" style={{borderBottom: '1px solid #333',borderRight: '1px solid #333',marginBottom:'0px'}}>
                                <tbody>
                                    {Array.from(Array(advance_data_count), (ade, adi) => {
                                        let current = previous_ad;
                                        let next = previous_ad+1;
                                        let next_to_next = next+1;
                                        previous_ad = next_to_next+1;
                                        return (
                                        <tr>
                                            <td style={{width:'200px'}}>
                                                <span style={{textTransform:'capitalize'}}>{(this.state.dispatched_info[current]!==undefined)?this.state.dispatched_info[current].key+' : ':''} </span><span>{(this.state.dispatched_info[current]!==undefined)?this.state.dispatched_info[current].value:''}</span>
                                            </td>
                                            <td style={{width:'200px'}}>
                                                <span style={{textTransform:'capitalize'}}>{(this.state.dispatched_info[next]!==undefined)?this.state.dispatched_info[next].key+' : ':''}</span><span>{(this.state.dispatched_info[next]!==undefined)?this.state.dispatched_info[next].value:''}</span>
                                            </td>
                                            <td style={{width:'200px'}}>
                                                <span style={{textTransform:'capitalize'}}>{(this.state.dispatched_info[next_to_next]!==undefined)?this.state.dispatched_info[next_to_next].key+' : ':''}</span><span>{(this.state.dispatched_info[next_to_next]!==undefined)?this.state.dispatched_info[next_to_next].value:''}</span>
                                            </td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            </td>
            
                        </tr>
                   
                        <tr>
                            <td  colspan="3">
                                <table style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <td colSpan={3} style={{padding:'0px'}}>
                                                <table className="table cell-border" style={{borderTop: '1px solid #333',borderBottom: '1px solid #333',borderRight: '1px solid #333'}}>
                                                    <thead>
                                                        <tr>
                                                    
                                                            <th width="50"  style={{padding:'0px 5px',textAlign:'center'}}>S.No</th>
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("barcode-in-items")<0) &&
                                                            <th  style={{padding:'0px 5px'}}>Code</th>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")<0) &&
                                                            <th  style={{padding:'0px 5px'}}>Item Name</th>
                                                            }
                                                            
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-desc-in-items")<0) &&
                                                            <th style={{padding:'0px 5px'}}>Description</th>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("hsn-code")<0) &&
                                                            <th style={{padding:'0px 5px'}}>HSN/SAC</th>
                                                            }
                                                            <th style={{textAlign: 'right',padding:'0px 5px'}}>Qty</th>
                                                            <th style={{textAlign: 'right',padding:'0px 5px'}}>Rate</th>
                                                            <th style={{textAlign: 'right',padding:'0px 5px'}}>Disc %</th>
                                                            {(this.props.print_data.module!=='orders') &&
                                                            <th style={{textAlign: 'right',padding:'0px 5px'}}>GST %</th>
                                                            }
                                                            <th style={{textAlign: 'right',padding:'0px 5px'}}>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {(page>1) &&
                                                        <tr>
                                                            <td></td>
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")<0) &&
                                                            <td>Opening Balance</td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("barcode-in-items")<0) &&
                                                            <td>
                                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0) &&
                                                                <>
                                                                    Opening Balance
                                                                </>
                                                                }   
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-desc-in-items")<0) &&
                                                            <td>
                                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0 && this.props.print_data.print_settings.indexOf("barcode-in-items")>0) &&
                                                                <>
                                                                    Opening Balance
                                                                </>
                                                                }
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("hsn-code")<0) &&
                                                                <td>
                                                                    {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0 && this.props.print_data.print_settings.indexOf("barcode-in-items")>0 && this.props.print_data.print_settings.indexOf("item-desc-in-items")>0) &&
                                                                        <>
                                                                            Opening Balance
                                                                        </>
                                                                    }
                                                                </td>
                                                            }
                                                        
                                                            <td style={{textAlign: 'right'}}>{opening_quantity[i-1]}</td>
                                                            {(this.props.print_data.module!=='orders') &&
                                                            <td></td>
                                                            }
                                                            <td></td>
                                                            <td></td>
                                                            <td style={{textAlign: 'right'}}>₹{this.currency_format(opening_balance[i-1])}</td>
                                                        </tr>
                                                    }
                                                    {Array.from(Array(items_per_page), (ce, ci) => {

                                                        let current_index = (end_loop+ci)-1;
                                                        let list_item_rows = [];
                                                        if(this.props.print_data.list_item_rows[current_index]!==undefined){
                                                            list_item_rows = this.props.print_data.list_item_rows[current_index];
                                                        }
                                                        calculate_total = (list_item_rows.total!==undefined)?parseFloat(list_item_rows.total)+calculate_total:calculate_total+0;

                                                        total_quantity = (list_item_rows.quantity!==undefined)?parseFloat(list_item_rows.quantity)+total_quantity:total_quantity+0;

                                                        calculate_grand_total = (list_item_rows.total!==undefined)?parseFloat(list_item_rows.total)+calculate_grand_total:calculate_grand_total+0;;

                                                        opening_balance[i] = calculate_total;
                                                        opening_quantity[i] = total_quantity;
                                                        return (
                                                        <tr>
                                                        
                                                            <td style={{padding:'0px 5px',textAlign:'center'}}>{end_loop+ci}</td>
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("barcode-in-items")<0) &&
                                                            <td style={{padding:'0px 5px'}}>
                                                                {(list_item_rows.barcode!==undefined)?list_item_rows.barcode:''}
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")<0) &&
                                                            <td style={{padding:'0px 5px'}}>
                                                                {(list_item_rows.parent_id!==0 && list_item_rows.parent_id!=='0') &&
                                                                <span style={{margin:'0px',lineHeight:'1.2'}}>
                                                                    {list_item_rows.product_name} <span style={{fontWeight:'bold'}}>{list_item_rows.item_name}</span>
                                                                </span>
                                                                }
                                                                {(list_item_rows.parent_id===0 || list_item_rows.parent_id==='0') &&
                                                                <>
                                                                    <span className="li-item">{list_item_rows.item_name}</span>
                                                                </>
                                                                }
                                                            </td>
                                                            }
                                                            
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-desc-in-items")<0) &&
                                                            <td style={{padding:'0px 5px'}}>
                                                                {(list_item_rows.desc!==undefined)?list_item_rows.desc:''}
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("hsn-code")<0) &&
                                                            <td style={{padding:'0px 5px'}}>
                                                                {(list_item_rows.hsn!==undefined)?list_item_rows.hsn:''}
                                                            </td>
                                                            }
                                                            <td style={{textAlign: 'right',padding:'0px 5px'}}>
                                                                {(list_item_rows.quantity!==undefined)?list_item_rows.quantity:''}
                                                            </td>
                                                            <td style={{textAlign: 'right',padding:'0px 5px'}}>
                                                                {(list_item_rows.price!==undefined)?'₹'+list_item_rows.price:''}
                                                            </td>
                                                            <td style={{textAlign: 'right',padding:'0px 5px'}}>
                                                                {(list_item_rows.discount!==undefined)?list_item_rows.discount:''}
                                                            </td>
                                                            {(this.props.print_data.module!=='orders') &&
                                                            <td style={{textAlign: 'right',padding:'0px 5px'}}>
                                                                {(list_item_rows.tax!==undefined)?list_item_rows.tax:''}
                                                            </td>
                                                            }
                                                            <td style={{textAlign: 'right',padding:'0px 5px'}}>
                                                                {(list_item_rows.total!==undefined)?'₹'+list_item_rows.total:''}
                                                            </td>
                                                            
                                                        </tr>
                                                        );
                                                    })}
                                                        <tr>
                                                            <td>
                                                            
                                                            </td>
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")<0) &&
                                                            <td>
                                                                {(page===this.props.print_data.item_page_count) &&
                                                                    <>
                                                                        Total
                                                                    </>
                                                                }
                                                                {(page!==this.props.print_data.item_page_count) &&
                                                                    <>
                                                                        Closing Balance
                                                                    </>
                                                                }
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("barcode-in-items")<0) &&
                                                            <td>

                                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0) &&
                                                                <>
                                                                    {(page===this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Total
                                                                        </>
                                                                    }
                                                                    {(page!==this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Closing Balance
                                                                        </>
                                                                    }
                                                                </>
                                                                }   

                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-desc-in-items")<0) &&
                                                            <td>

                                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0 && this.props.print_data.print_settings.indexOf("barcode-in-items")>0) &&
                                                                <>
                                                                    {(page===this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Total
                                                                        </>
                                                                    }
                                                                    {(page!==this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Closing Balance
                                                                        </>
                                                                    }
                                                                </>
                                                                }
                                                            </td>
                                                            }
                                                            {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("hsn-code")<0) &&
                                                            <td>
                                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("item-name-in-items")>0 && this.props.print_data.print_settings.indexOf("barcode-in-items")>0 && this.props.print_data.print_settings.indexOf("item-desc-in-items")>0) &&
                                                                <>
                                                                    {(page===this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Total
                                                                        </>
                                                                    }
                                                                    {(page!==this.props.print_data.item_page_count) &&
                                                                        <>
                                                                            Closing Balance
                                                                        </>
                                                                    }
                                                                </>
                                                                }
                                                            </td>
                                                            }
                                                            <td style={{textAlign: 'right'}}>{total_quantity}</td>
                                                            {(this.props.print_data.module!=='orders') &&
                                                            <td></td>
                                                            }
                                                            <td></td>
                                                            <td></td>
                                                            <td style={{textAlign: 'right'}}>₹{this.currency_format(calculate_grand_total)}</td>
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{position:'relative',borderTop: 'none',paddingBottom:'0px',paddingTop:'0px'}}>
                                                
                                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].gst_type!=='composite' && this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("tax-info")<0 && this.props.print_data.module!=='orders') &&
                                                <div className="row" style={{marginTop:'5px',marginLeft:'0px',border:'1px solid #333',marginRight:'5px',marginBottom:'5px'}}>
                                                {(this.props.print_data.sgst_slab_key.length > 0) &&
                                                <>
                                                    <div className="col-md-6" style={{padding:'0',width:'50%'}}>
                                                        <div className="row" style={{marginLeft:'0px'}}>
                                                        {(this.props.print_data.sgst_slab_key.length > 0) ?this.props.print_data.sgst_slab_key.map( (value, index) => {
                                                            return (
                                                            <div className="col-md-12" style={{padding:'0'}}>
                                                            {(this.props.print_data.tax_slab['sgst'][value]>0) &&
                                                            <p style={{margin: '0px',fontSize:'14px',padding:'5px'}}>
                                                                SGST Amount @ {value}% 
                                                                &nbsp;<span>₹</span>{this.floating_point(this.props.print_data.tax_slab['sgst'][value])}
                                                            </p>
                                                            }
                                                            </div>
                                                            )
                                                            }) : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{padding:'0',width:'50%'}}>
                                                        <div className="row" style={{marginLeft:'0px'}}>
                                                            
                                                            {(this.props.print_data.cgst_slab_key.length > 0) ?this.props.print_data.cgst_slab_key.map( (value, index) => {
                                                                return (
                                                                    <div className="col-md-12" style={{padding:'0'}}>
                                                                {(this.props.print_data.tax_slab['cgst'][value]>0) &&
                                                                <p style={{margin: '0px',fontSize:'14px',padding:'5px'}}>
                                                                    CGST Amount @ {value}% 
                                                                    &nbsp;<span>₹</span>{this.floating_point(this.props.print_data.tax_slab['cgst'][value])}
                                                                </p>
                                                                }
                                                                </div>
                                                                )
                                                                }) : ''}                 
                                                            
                                                        </div>
                                                    </div>
                                                </>
                                                }
                                                
                                            
                                                {(this.props.print_data.igst_slab_key.length > 0) ?this.props.print_data.igst_slab_key.map( (value, index) => {
                                                    return (
                                                    <div className="col-md-12" style={{padding:'0'}}>
                                                    {(this.props.print_data.tax_slab['igst'][value]>0) &&
                                                    <p style={{margin: '0px',fontSize:'14px',padding:'5px'}}>
                                                        IGST Amount @ {value}% 
                                                        &nbsp;<span>₹</span>{this.floating_point(this.props.print_data.tax_slab['igst'][value])}
                                                    </p>
                                                    }
                                                    </div>
                                                    )
                                                    }) : ''}

                                                    </div>
                                                }
                                                {(this.props.print_data.print_settings && this.props.print_data.print_settings.indexOf("terms-and-conditions")<0) &&
                                                <>
                                                {(this.props.print_data.terms_and_conditions.length > 0) &&
                                                <p style={{margin:'0px',marginBottom:'20px'}}>
                                                    {(this.props.print_data.terms_and_conditions.length > 0) ?this.props.print_data.terms_and_conditions.map( (value, index) => {
                                                    return (
                                                        <span style={{fontSize:'14px'}}>
                                                            {index+1}) {value+" "} 
                                                        </span>
                                                    )
                                                    }) : ''}
                                                </p>     
                                                }
                                                </>
                                            }
                                            <p style={{margin:'0px',textTransform:'uppercase',fontSize:'14px',fontWeight:'bold',position: 'absolute',bottom: '0'}}>
                                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.total)?this.convert_number_to_words(this.props.print_data.billing_data.total.replace(/,/g , ""))+' Only':0}
                                                </p>
                                            </td>
                                            <td style={{borderTop: 'none',paddingBottom:'0px',paddingTop:'0px'}}>
                                                <table className="table" style={{margin:'0px',width:'200px',float:'right'}}>
                                                {(this.props.print_data.billing_data.total_disc!=="" && this.props.print_data.billing_data.total_disc!=="0" && parseFloat(this.props.print_data.billing_data.total_disc)>0) &&
                                                    <tr>
                                                        <td><b>Discount</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total_disc)?utils.currency_format(this.props.print_data.billing_data.total_disc):0}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                
                                                    <tr>
                                                        <td><b>Gross</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                            <>
                                                                <span>₹</span>
                                                                <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total_before_tax)?this.currency_format(this.props.print_data.billing_data.total_before_tax):0}</span>
                                                            </>
                                                        </td>
                                                    </tr>
                                                    {(this.props.print_data.billing_data && this.props.print_data.billing_data.total_cgst!==undefined && this.props.print_data.billing_data.total_cgst!=="" && this.props.print_data.billing_data.total_cgst!=="0" && this.props.print_data.billing_data.total_cgst>0 && this.props.print_data.module!=='orders') &&
                                                    <tr>
                                                        <td><b>CGST</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total_cgst)?this.currency_format(this.props.print_data.billing_data.total_cgst):0}</span>
                                                        </td>
                                                    </tr>
                                                    }
                                                     {(this.props.print_data.billing_data && this.props.print_data.billing_data.total_sgst!==undefined && this.props.print_data.billing_data.total_sgst!=="" && this.props.print_data.billing_data.total_sgst!=="0" && this.props.print_data.billing_data.total_sgst>0 && this.props.print_data.module!=='orders') &&
                                                    <tr>
                                                        <td><b>SGST</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total_sgst)?this.currency_format(this.props.print_data.billing_data.total_sgst):0}</span>
                                                        </td>
                                                    </tr>
                                                    }
                                                     {(this.props.print_data.billing_data && this.props.print_data.billing_data.total_igst!==undefined && this.props.print_data.billing_data.total_igst!=="" && this.props.print_data.billing_data.total_igst!=="0" && this.props.print_data.billing_data.total_igst>0 && this.props.print_data.module!=='orders') &&
                                                    <tr>
                                                        <td><b>IGST</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total_igst)?this.currency_format(this.props.print_data.billing_data.total_igst):0}</span>
                                                        </td>
                                                    </tr>
                                                    }
                                                    {(this.props.print_data.billing_data.tcs_percent!==undefined && this.props.print_data.billing_data.tcs_percent!==null && this.props.print_data.billing_data.tcs_percent!=="" && this.props.print_data.billing_data.tcs_percent!=="0" && this.props.print_data.billing_data.tcs_percent!==0 && this.props.print_data.module!=='orders' && this.props.print_data.billing_data.tcs_total!=="") &&
                                                    <tr>
                                                        <td><b>TCS {this.props.print_data.billing_data.tcs_percent}%</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.tcs_total)?this.currency_format(this.props.print_data.billing_data.tcs_total):''}</span>
                                                        </td>
                                                    </tr>
                                                    }
                                                    {(this.props.print_data.billing_data.round_off!=="" && this.props.print_data.billing_data.round_off!=="0" && this.props.print_data.billing_data.round_off!==0) &&
                                                    <tr>
                                                        <td><b>Round Off</b></td>
                                                        <td style={{textAlign: 'right'}}>
                                                        <span>₹</span>
                                                        <span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.round_off)?this.props.print_data.round_off_sym+''+this.props.print_data.billing_data.round_off:''}</span>
                                                        </td>
                                                    </tr>
                                                    }
{(this.props.print_data.billing_data && this.props.print_data.billing_data.other_charges!==undefined  && this.props.print_data.billing_data.other_charges!==null  && this.props.print_data.billing_data.other_charges>0)&&
                                                    <tr style={{borderBottom: '1px solid #333'}}>
                                                        <td><b>Trans./Oth. Charges</b></td>
                                                        <td style={{textAlign: 'right'}}><span>₹</span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.other_charges)?this.currency_format(this.props.print_data.billing_data.other_charges):0}</td>
                                                    </tr>
                }
                                                    <tr style={{borderBottom: '1px solid #333'}}>
                                                        <td><b>Total</b></td>
                                                        <td style={{textAlign: 'right'}}><b><span>₹</span>{(this.props.print_data.billing_data && this.props.print_data.billing_data.total)?this.currency_format(this.props.print_data.billing_data.total):0}</b></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>           
                        </tr>
                   
                   
                        <tr>
                            <td colspan="3">
                                <table className="table" style={{marginBottom: '0px'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{borderTop: 'none',padding:'0px'}}></td>
                                        <td style={{borderTop: 'none',padding:'0px'}}><p style={{margin:'0px',fontSize:'14px'}} className="signature">{this.props.print_data.billing_data.checked_by}</p></td>
                                        <td style={{borderTop: 'none',padding:'0px'}}><p style={{margin:'0px',fontSize:'14px'}} className="signature">{this.props.print_data.billing_data.packed_by}</p></td>
                                        <td style={{borderTop: 'none',padding:'0px'}}><p style={{margin:'0px',fontSize:'14px'}} className="signature">{this.props.print_data.assisted_by}</p></td>
                                        <td style={{borderTop: 'none',padding:'0px'}}><p style={{margin:'0px',fontSize:'14px'}} className="signature">{this.props.print_data.issued_by}</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderTop: 'none',padding:'0px'}}>
                                        
                                            <p style={{margin:'0px',fontSize:'14px'}}>Customer's Signature</p>
                                        </td>
                                        <td style={{borderTop: 'none',padding:'0px'}}>
                                            
                                            <p style={{margin:'0px',fontSize:'14px'}}>Checked By</p>
                                        </td>
                                        <td  style={{borderTop: 'none',padding:'0px'}}>
                                            
                                            <p style={{margin:'0px',fontSize:'14px'}}>Packed By</p>
                                        </td>
                                        <td  style={{borderTop: 'none',padding:'0px'}}>
                                           
                                            <p style={{margin:'0px',fontSize:'14px'}}>Through/Salesman</p>
                                        </td>
                                        <td  style={{borderTop: 'none',padding:'0px'}}>
                                            <p style={{margin:'0px',fontSize:'14px'}}>
                                                For&nbsp;
                                                {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info)?this.props.print_data.billing_data.company_info[0].company_name:''
                                                }
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </td>
                        </tr>
                        {(this.props.print_data.billing_data && this.props.print_data.billing_data.company_info && this.props.print_data.billing_data.company_info[0].company_name.match(/haneef associates/gi)) &&  
                        <tr>
                            <td colspan="3" style={{padding:'0px 5px'}}>
                                <p style={{margin:'0px',fontSize:'14px',fontWeight:'bold'}}>BANK NAME : KOTAK MAHINDRA BANK LTD, A/C NO:700011038622, IFSC CODE : KKBK0007456</p>
                                <p style={{margin:'0px',fontSize:'14px',fontWeight:'bold'}}>BANK NAME : AXIS BANK LTD, A/C NO:068010200022808, IFSC CODE : UTIB0000068</p>
                            </td>
                        </tr>
                        }
                        {(this.props.footer_content && this.props.footer_content!=='<p></p>' && this.props.footer_content!=='') &&  
                        <tr>
                            <td colspan="3" style={{padding:'0px 5px'}}>
                                <span dangerouslySetInnerHTML={{__html: this.props.footer_content}}></span>
                            </td>
                        </tr>
                        }
                     </tbody>
                </table>
                    );
                })}
            </div>
        );
    }
}