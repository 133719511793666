import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash, faPrint} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import ChequePrintSetting from "./settings/cheque-print-settings";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let PaymentMode = [];
let _this;
let db;
export default class AccountTypes extends Component {
    constructor() {
      super();
      this.state = {
          account_types : [],
          account_data : {},
          modeDefault : 0,
          is_cheque_print_setting : false,
      };
      _this = this;
      this.editMode = this.editMode.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }


    handleChange(e){
        let target = this.props.target;
        this.setState({modeDefault:e.currentTarget.value});
        localStorage.setItem('import_contact_type',e.currentTarget.value);
    }
    componentDidMount(){
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        let api = app_api+'/get_account_types';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            this.setState({ 'account_types': cursor });
            if(this.props.default!==""){
              this.setState({modeDefault:this.props.default});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })

    }
    editMode(event){ 
      document.getElementById("openAddAccountModal").click();
      let id = event.currentTarget.id;
      let account_name = document.getElementById('act-account-name-'+id).value;
      let payment_mode = document.getElementById('act-payment-mode-'+id).value;
      setTimeout(() => {
        document.getElementById("account_type_id").value = id;
        document.getElementById("account_name").value = account_name;
//        document.getElementById("linked-payment-mode").value = payment_mode;
    //    localStorage.setItem("p_mode",payment_mode);
       // document.getElementById("handleSetModeOfPayment").click();
      }, 100);
        /* var element = document.getElementById('add_account_types');
      element.classList.remove("fade");
      element.style.display="block"; */
    }

     deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
   
    handleOpenChequeSetting = (e) =>{
      let idx = e.currentTarget.getAttribute('data-id');
      let account_types = this.state.account_types;
      let account_data = account_types[idx];
      this.setState({account_data:account_data});
      this.setState({is_cheque_print_setting:true});
    }
    handleCloseChequeSettings = (e) =>{

      this.setState({account_data:{}});
      this.setState({is_cheque_print_setting:false});
    }

    render() {
        return (
          <div>
            {this.props.show==='list' &&
            <>
            {(this.state.is_cheque_print_setting===true) &&
              <>
              <span id="handleCloseChequeSettings" onClick={this.handleCloseChequeSettings}></span>
              <ChequePrintSetting account_data={this.state.account_data}/>
              </>
            }
              <table className="table">
                <tbody>
                    {(this.state.account_types.length > 0) ?this.state.account_types.map( (value, index) => {
                    return (
                      <tr key={ index }  id={'delete_expense_list_'+index}>
                        <td>
                          <span id={'pm-span-'+index } className="show l-span pay-mode-name">
                            <span id={"exp-type-"+index}>
                              {value.account_name}
                            </span>
                            {(value.payment_mode_name!=="") &&
                            <>
                              <span id={"acc-pmode-"+index} style={{fontSize:'12px',marginLeft:'5px'}}>({value.payment_mode_name})</span>
                            </>
                            }
                          </span>
                          <input type="hidden" value={value.payment_mode} id={"act-payment-mode-"+value.actid}/>
                          <input type="hidden" value={value.account_name} id={"act-account-name-"+value.actid}/>
                        </td>
                        <td>
                          <div id={'normal-mode-'+index} className="show">
                              <FontAwesomeIcon id={value.actid} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                              <FontAwesomeIcon id={value.actid} onClick={this.deleteRow} data_target="delete_confirm" data_value="account_type" data_id="delete_account_type" icon={faTrash} className="pull-right fa-icon"/>
                              <FontAwesomeIcon data-id={index} onClick={this.handleOpenChequeSetting} icon={faPrint} className="pull-right fa-icon mrtop-5" style={{marginRight:'10px'}}/>
                          </div>
                        </td>
                      </tr>
                    )
                  }) : <tr><td>No record(s) found</td></tr> }
                </tbody> 
              </table>
            </>
            }       
            {this.props.show==='dropdown' &&   
              <>
              <select target={this.props.target} tabindex={this.props.tabindex} data-id={this.props.data_id} className="form-control form-field" name={this.props.name}  value={(this.state.modeDefault)} onChange={this.handleChange} id={this.props.id}>
                  {(this.props.target==='report') &&
                    <option value="" key="exp">All</option>
                  }
                  {(this.props.target!=='report' && this.props.target!=='cash_entry') &&
                    <option value="" key="exp">Select Account</option>
                  }
                  {(this.state.account_types.length > 0) ? this.state.account_types.map( (value, index) => {
                   return (
                    <>
                    {(this.props.exlude!==value.actid) &&
                      <option value={value.actid} key={index}>{value.account_name}</option>
                    }
                    </>
                    )
                  }) : ''}
              </select>
              </>
            }     
            </div>
        );
    }
}