import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Leftnav from "./left-nav";
import moment from 'moment'
import Maintenance from "../maintenance";
import axios from 'axios';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner,faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,admin_info} = require('../../library.js');

export default class Settings extends Component {
   constructor() {
      super();
      this.state = {
         options : [],
         MaintenanceDate:  new Date(),
         maintenanceSettings:  [],
      };
      this.maintenanceSettings = [];
      this.handleSaveOption = this.handleSaveOption.bind(this);
      this.handleSaveMaintenanceMode = this.handleSaveMaintenanceMode.bind(this);
      this.handleCloseMaintenance = this.handleCloseMaintenance.bind(this);
    }
    handleCloseMaintenance(id){
      this.maintenanceSettings.splice(id, 1);
      this.setState({maintenanceSettings:this.maintenanceSettings});
      var body = {
        login_id: admin_info.login_id,
        name: 'maintenance_modes',
        value: this.maintenanceSettings,
      };
      axios.post(app_api+'/admin/save_option', body)
        .then(function (response) {
      }).catch(function(res){
         
      });
    }
    componentDidMount(){
       var body = {
        login_id: admin_info.login_id,
        keys: "'trial_period'",
      };
      axios.post(app_api+'/admin/get_option', body)
        .then(function (response) {
        if(response.data.trial_period){
          this.setState({options:response.data});
        }
       
      }.bind(this)).catch(function(res){
          
      })
      var body = {
        login_id: admin_info.login_id,
        key: "maintenance_modes",
        target: "admin",
      };
      axios.post(app_api+'/admin/get_option', body)
        .then(function (response) {
        if(response.data.maintenance_modes){
          this.maintenanceSettings = response.data.maintenance_modes;
          this.setState({maintenanceSettings:this.maintenanceSettings});
        }
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveOption(e){
      let thisid = e.currentTarget.id;
      let target = e.currentTarget.getAttribute('data-target');
      let name = e.currentTarget.getAttribute('data-id');
      let value  = document.getElementById(target).value;
      
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      var body = {
        login_id: admin_info.login_id,
        name: name,
        value: value,
      };
      axios.post(app_api+'/admin/save_option', body)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
          
      }).catch(function(res){
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
         
      })
    
    }
    handleSaveMaintenanceMode(e){
      let to_date = document.getElementById('to_date').value;
      let to_time = document.getElementById('to_time').value;
      let to_am_pm = document.getElementById('to_am_pm').value;

     
      var td = to_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1");
      var ampm = to_time+' '+to_am_pm;
      ampm = moment(ampm, ["h:mm A"]).format("HH:mm");

      let timer_date = td+' '+ampm;

      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");

      let settings = {};
      let qs = document.querySelectorAll(".maintenance-data");
      for(let i = 0;i<qs.length;i++){
        let data_id = qs[i].id;
        settings[data_id] = qs[i].value;
      }
      settings['timer_date'] = timer_date;
      settings['is_show_maintain_page'] = false;
      this.maintenanceSettings.push(settings);
      this.setState({maintenanceSettings:this.maintenanceSettings});
      var body = {
        login_id: admin_info.login_id,
        name: 'maintenance_modes',
        value: this.maintenanceSettings,
      };
      axios.post(app_api+'/admin/save_option', body)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }).catch(function(res){
         
      });
    }
    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title"><span>Settings</span></div>
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-3">
                      
                    </div>
                  </div>
                </nav>
                <div className="container-fluid" style={{padding:'20px'}}>
                  <div className="content-area">
                     <div className="main-content">
                        <div className="row">
                          <div className="col-md-4">
                              <label className="lable-for" for="trial-period">Trial Period</label>
                              <div className="input-group">
                                <input id="trial-period" type="text" className="form-control no-border-right" placeholder="30" defaultValue={(this.state.options.trial_period)?this.state.options.trial_period:''}/>
                                <div className="input-group-append">
                                  <span className="input-group-text pad-left-none">Days</span>
                                </div>
                                <button type="button" id="tp-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                <button type="button" id="tp" onClick={this.handleSaveOption} data-target="trial-period" data-id="trial_period" className="btn btn-primary mrleft-10">Save</button>
                              </div>
                           
                          </div> 
                        </div>
                        <div className="maintain-container">
                          <div className="row mrtop-20">
                            <div className="col-md-12">
                              <h4>Maintenance Mode</h4>
                            </div>
                          </div>
                          <div className="row mrbtm-20">
                            <div className="col-md-4">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left maintenance-data" id="from_date" selected={this.state.MaintenanceDate} dateFormat="dd/MM/yyyy"/>
                                  <span style={{margin:'5px'}}>@</span>
                                  <input type="hidden" className="maintenance-data" id="maintain_status" value="active"/>
                                  <input type="text" placeholder="00:00" className="form-control maintenance-data" id="from_time"/>
                                  <select className="form-control mrleft-5 maintenance-data" id="from_am_pm" style={{width:'100px'}}>
                                    <option value="PM">PM</option>
                                    <option value="AM">AM</option>
                                  </select>
                                </div>
                              </div>
                            </div> 
                            <div className="col-md-1 text-center">
                                <span style={{margin:'5px'}}>TO</span>
                            </div> 
                            <div className="col-md-4">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left maintenance-data" id="to_date" selected={this.state.MaintenanceDate} dateFormat="dd/MM/yyyy"/>
                                  <span style={{margin:'5px'}}>@</span>
                                  <input type="text" placeholder="00:00" className="form-control maintenance-data" id="to_time"/>
                                  <select className="form-control mrleft-5 maintenance-data" style={{width:'100px'}} id="to_am_pm">
                                    <option value="PM">PM</option>
                                    <option value="AM">AM</option>
                                  </select> 
                                </div>
                              </div>
                            </div> 
                            <div className="col-md-3">
                              <button type="button" className="btn btn-primary" onClick={this.handleSaveMaintenanceMode} id="maintain-save">Save</button>
                              <button type="button" id="maintain-save-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </div>
                          </div>

                          {(this.state.maintenanceSettings && this.state.maintenanceSettings.length > 0) ?this.state.maintenanceSettings.map( (value, index) => {
                             return (
                                <div className="row mrbtm-10">
                                  <div className="col-md-12">
                                  
                                  <Maintenance value={value} index={index} target="admin" handleCloseMaintenance={this.handleCloseMaintenance.bind(this)}/>
                                   
                                  </div>
                              </div>
                            ) 
                          }) : '' }
                        </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}