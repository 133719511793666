import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import ContactsDropdown from "./contacts-dropdown";
import VariationsOptions from "./variation-options";
import VariationsList from "./variation-list";
import ModalBox from "./modal-box";
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let _this;
export default class ViewInventory extends Component {
    constructor() {
        super();
        this.state = {
            active_tab:'info',
            category_name:'',
            unit_name:'',
            contact_info:[],
            inventory_item:[],
            category_item:[],
            inventory_images:[],
            show_inventory_images:[],
            current_id:'',
            purchase_id:'',
            parent_inventory_id:'',
            parent_id:'',
            auto_sku : true,
            multiple_variant : true,
            info_body : {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              
            },
            variation_options : [],
            inventory_meta : [],
            variation_list : [],
        };
        _this = this;
        this.variation_list=[];
        this.variation_options=[];
       
       
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.handlePreviewImage = this.handlePreviewImage.bind(this);
    }
    
    handlePreviewImage(e){
      let this_img = e.currentTarget.getAttribute('data-src');
      var element = document.getElementById('preview-image');
        element.classList.remove("fade");
        element.style.display="block";
      document.getElementById('preview-img-src').setAttribute('src',this_img);

    }
  
    componentDidMount(){
      let curl = window.location.href;
      const get_id = curl.match(/view-inventory\/(\d+$)/i);
      const page_tab = curl.match(/view-inventory\/([a-z_A-Z]+)\/(\d+$)/i);
      const variation = curl.match(/view-inventory\/(\d+)\/([var]+$)/i);
     
      let c_id = 0;
      if(page_tab){
          c_id = parseInt(page_tab[2]);
         this.setState({active_tab:page_tab[1]});
      }else if(variation){
         c_id = parseInt(variation[1]);

         this.setState({active_tab:variation[2]});
      }else{
        c_id =parseInt(get_id[1])
      }
      this.setState({current_id:c_id});
       let body = {};
      body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            id : c_id
         }
      let api = app_api+'/get_inventory_on_id';
    
      axios.post(api, body)
        .then(function (response) {
            let resp = response.data.data;
            if(resp.length===0){
              window.location.href = '/404';
            }
            let category_item = resp.categories;
          
            let contact_info = resp.contact_info;
            let inventory_meta = resp.inventory_meta;
            let category_name = resp.category_name;
            let unit_name = resp.unit_name;
            let inventory_images = resp.inventory_images;
            
            this.setState({inventory_images:inventory_images});
            this.setState({show_inventory_images:inventory_images[c_id]});

            this.setState({category_name:category_name});
            this.setState({unit_name:unit_name});
            let purchase_id = resp.purchase_id;
            let varient = resp[0].varient;
            if(varient==0){
              this.setState({multiple_variant:false});
            }else{
              this.setState({multiple_variant:true});
            }
            let parent_inventory_id = resp.parent_inventory_id;
            let parent_id = resp.parent_id;
            this.setState({parent_id:parent_id});
            this.setState({purchase_id:purchase_id});
            this.setState({inventory_meta:inventory_meta});
            this.setState({parent_inventory_id:parent_inventory_id});

            let contact_name = '';
            let contact_id = '';
            let address_1 =  '';
            let address_2 = '';
            let city = '';
            let credit_limit = '';
            let email = '';
            let phone_1 = '';
            let phone_2 = '';
            let state = '';
            if(Object.keys(contact_info).length > 0) {
               contact_name = contact_info[0].name;
               contact_id = contact_info[0].contact_id;
               address_1 = contact_info[0].address_1;
               address_2 = contact_info[0].address_2;
               city = contact_info[0].city;
               credit_limit = contact_info[0].credit_limit;
               email = contact_info[0].email;
               phone_1 = contact_info[0].phone_1;
               phone_2 = contact_info[0].phone_2;
               state = contact_info[0].state;
            }
            let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
            this.setState({contact_info:c_info});
            this.setState({category_item:category_item});
           if(resp[0]!==undefined){
            let item_sku = resp[0].sku;
            let item_name = resp[0].item_name;
            if(item_sku===null){
              let sku_auto = '';
              let iname = item_name.replace(" ",'');
              let n = iname.substring(0,4).toUpperCase();
              resp[0].sku = n+'01';
            }
            
            this.setState({ 'inventory_item':  resp[0]}); 

            let v_op = [];
            let v_k_n = [];
            for(let i=0;i<inventory_meta.length;i++){
                let var_options = inventory_meta[i].variation_options;
                
                var_options.map((value, index) =>{
                    if(v_op[value.key]){}else{
                      v_op[value.key]=[];
                    } 
                    if(v_k_n.indexOf(value.key)===-1){
                       v_k_n.push(value.key);
                    }
                    v_op[value.key].push(value.value);
                })
            }
            let var_items = [];
            for(let j=0;j<v_k_n.length;j++){
                let k = v_k_n[j];
                let vstr = v_op[k].toString();
                var_items.push({key:k,value:vstr});
            }
            this.setState({variation_list:<VariationsList target="view" var_items={var_items} inventory_item={this.state.inventory_item} variation_item={inventory_meta} type="old"  inventory_images={inventory_images}/>});
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }
    
    render() {
      let inventory_images = this.state.show_inventory_images;
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">
                        <Breadcrumbs link="/inventory" title="Products" sub_title="View Product"/>
                       
                    </div>

                     <div className="col-md-6 text-right mrtop-10">
                     {this.is_access('inventory-modify') &&
                       <>
                       {(this.state.active_tab==='info' || this.state.active_tab==='variations') &&
                          <a href={"/edit-inventory/"+_this.state.current_id}><button type="button" className="btn btn-success">Edit Inventory</button></a>
                       }
                       {(this.state.active_tab==='var') &&
                       <a href={"/edit-inventory/"+_this.state.current_id+"/var"}><button type="button" className="btn btn-success">Edit Variation</button></a>
                       }
                       </>
                     }
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                {(this.state.active_tab==='info' || this.state.active_tab==='variations') && 
                <div className="nav-container">
                    <ul className="nav nav-pills">
                      <li className={(this.state.active_tab==='info' || this.state.active_tab==='var')?'active':''}><a href={"/view-inventory/"+this.state.current_id}>Product Info</a></li>
                      
                      <li className={this.state.active_tab==='variations'?'active':''}><a href={"/view-inventory/variations/"+this.state.current_id}>Variations</a></li>
                    </ul>
                  </div>
                      }
                  <div className="content-area">
                     <div className="main-content border-bottom inventory-fields">
                        <div className="inner-content">

                        {(this.state.active_tab==='info' && inventory_images && inventory_images.length>0) &&
                          <div className="row">
                            <div className="col-md-12 pro-img-blk">
                                <ul className="pro-top-imgs">
                                   {inventory_images.map( (value, index) => {
                                    return (
                                    <li onClick={this.handlePreviewImage} data-src={value.image}><img src={value.image}/></li>
                                   
                                    )})}
                                </ul>
                            </div>
                          </div>
                        }


                        {(this.state.active_tab==='info' || this.state.active_tab==='var') && 
                        <>
                            <div className="row">
                                <div className="col-md-3 text-right"><label>Status</label></div>
                                <div className="col-md-9">
                                  <label>{this.state.inventory_item.inventory_status}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right"><label>Product Name</label></div>
                                 <div className="col-md-6">
                                  <input readOnly type="text" name="item_name" className="form-field form-control"  defaultValue={this.state.inventory_item.item_name} />
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>HSN/SAC</label></div>
                                 <div className="col-md-6">
                                  <input readOnly type="text" name="hsn" className="form-field form-control" id="item-hsn" defaultValue={this.state.inventory_item.hsn}  />
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div> 
                            <div className="row mrtop-15 hide">
                                <div className="col-md-3 text-right"><label>SKU</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="sku" className="form-field form-control" id="item-sku" defaultValue={this.state.inventory_item.sku} readOnly  />
                                </div>
                                <div className="col-md-3">
                                   
                                </div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Barcode Name</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="barcode_name" className="form-field form-control" id="barcode_name" defaultValue={this.state.inventory_item.barcode_name} readOnly  />
                                </div>
                                <div className="col-md-3">
                                   
                                </div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Custom Text</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="custom_text" className="form-field form-control" id="custom_text" defaultValue={this.state.inventory_item.custom_text} readOnly  />
                                </div>
                                <div className="col-md-3">
                                   
                                </div>
                            </div> 
                            {this.is_access('inventory-show-supplier') && 
                              <div className="row mrtop-15">
                                  <div className="col-md-3 text-right"><label>Supplier</label></div>
                                   <div className="col-md-6">
                                    <input type="text" name="" readOnly data-id="iq" id="discount-iq" className="form-field form-control" defaultValue={this.state.contact_info.item_name}  />
                                  </div>
                                  <div className="col-md-3"></div>
                              </div> 
                            }
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Category</label></div>
                                 <div className="col-md-6">
                                   <input type="text" name="" readOnly data-id="iq" id="discount-iq" className="form-field form-control"  defaultValue={this.state.category_name}  />
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Quantity</label></div>
                                 <div className="col-md-6">
                                     <input readOnly type="text" name="quantity" data-id="iq" id="item-quantity-iq" className="form-field form-control" defaultValue={this.state.inventory_item.quantity}  />
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            {this.is_access('inventory-show-purchase-price') && 
                              <div className="row mrtop-15">
                                  <div className="col-md-3 text-right"><label>Purchase Price</label></div>
                                   <div className="col-md-6">
                                       <input readOnly type="text"  data-id="iq" id="price-iq"  name="purchase_price" className="form-field form-control"  defaultValue={this.state.inventory_item.purchase_price}  />
                                  </div>
                                  <div className="col-md-3"></div>
                              </div> 
                            }
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Selling Price</label></div>
                                 <div className="col-md-6">
                                     <input readOnly type="text" name="selling_price" className="form-field form-control" defaultValue={this.state.inventory_item.selling_price}  />
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Lowest Selling Price</label></div>
                                 <div className="col-md-6">
                                     <input readOnly type="text" name="lowest_selling_price" className="form-field form-control"  defaultValue={(this.state.inventory_item.lowest_selling_price!==null)?this.state.inventory_item.lowest_selling_price:this.state.inventory_item.selling_price}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Unit</label></div>
                                 <div className="col-md-6">
                                     <input readOnly type="text" name="unit_name" className="form-field form-control"  defaultValue={this.state.unit_name}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label style={{marginTop:'35px'}}>Tax</label></div>
                                 <div className="col-md-2">
                                    <label htmlFor="cgst">CGST</label>
                                    <div className="input-group">
                                      <input readOnly type="text" className="form-control no-border-right" id="cgst" defaultValue={this.state.inventory_item.cgst}/>
                                      <div className="input-group-append" style={{background:'#e9ecef'}}>
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                     <label htmlFor="sgst">SGST</label>
                                     <div className="input-group">
                                      <input readOnly type="text" className="form-control no-border-right" id="sgst" defaultValue={this.state.inventory_item.sgst}/>
                                      <div className="input-group-append" style={{background:'#e9ecef'}}>
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                     <label htmlFor="igst">IGST</label>
                                     <div className="input-group">
                                      <input readOnly type="text" className="form-control no-border-right" id="igst" defaultValue={this.state.inventory_item.igst}/>
                                      <div className="input-group-append" style={{background:'#e9ecef'}}>
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                <div className="col-md-3"></div>
                            </div>

                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Discount</label></div>
                                 <div className="col-md-6">
                                     <input type="text" name="discount" readOnly data-id="iq" id="discount-iq" className="form-field form-control" defaultValue={this.state.inventory_item.discount}  />
                                    
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Bundle Quantity</label></div>
                                 <div className="col-md-6">
                                     <input type="text" name="bundle_quantity" readOnly data-id="iq" id="bundle_quantity-iq" className="form-field form-control" defaultValue={this.state.inventory_item.bundle_quantity}  />
                                    
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Description</label></div>
                                 <div className="col-md-6">
                                   <textarea readOnly className="form-control" rows="3" defaultValue={this.state.inventory_item.item_desc} name="item_desc"  ></textarea>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            </>
                          }
                            {this.state.active_tab==='variations' && 
                            <>
                            {this.state.multiple_variant===true && 
                              <>
                              {this.state.variation_list}
                              </>
                            }
                            </>
                          }
                        </div>
                     </div>
                   
                  </div>
                </div>
              </div>
              <ModalBox id="preview-image" title="Preview Image" data_id="Preview Image"/>
            </div>
        );
    }
}