import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let PaymentMode = [];
let _this;
let db;
export default class ExpenseTypes extends Component {
    constructor() {
      super();
      this.state = {
          expense_types : [],
          modeDefault : 0,
      };
      _this = this;
      this.editMode = this.editMode.bind(this);
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    handleSetExpenseType = () =>{
      let exp_type = localStorage.getItem('exp_type');
      this.setState({modeDefault:exp_type})
    }


    handleChange(e){
        let target = this.props.target;
        let value = e.currentTarget.value;
        this.setState({modeDefault:value});
        if(this.props.handleChangeData){
          let index = this.props.data_index;
          let data_id = this.props.data_id;
          this.props.handleChangeData(index,data_id,value);
        }
    }
    componentDidMount(){
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        let api = app_api+'/get_expense_types';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            this.setState({ 'expense_types': cursor });
            if(this.props.default!==""){
              this.setState({modeDefault:this.props.default});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })

    }
    editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show");
    }

    updateRow(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      let update_id = event.currentTarget.getAttribute('data-id');
      var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      let expnese_category = document.querySelector('input[name="expnese_category"]:checked').value;
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);
      
      if(expnese_category==='direct'){
        document.getElementById("exp-category-"+id).innerHTML = 'Direct Expense';
      }else{
        document.getElementById("exp-category-"+id).innerHTML = 'Indirect Expense';
      }

      let changed_val = text.value;
      document.getElementById("exp-type-"+id).innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : update_id,
            type : changed_val,
            category : expnese_category,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
         }
      let api = app_api+'/save_expense_type';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
      
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('pm-span-'+id);
      var text = document.getElementById('pm-txt-'+id);
      var normal = document.getElementById('normal-mode-'+id);
      var edit = document.getElementById('edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
     deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
   
    render() {
        return (
          <div>
            {this.props.show==='list' &&
            <table className="table">
              <tbody>
                  {(this.state.expense_types.length > 0) ?this.state.expense_types.map( (value, index) => {
                   return (
                    <tr key={ index }  id={'delete_expense_list_'+index}>
                      <td>
                        <span id={'pm-span-'+index } className="show l-span pay-mode-name">
                          <span id={"exp-type-"+index}>
                            {value.type}
                          </span>
                          (<span id={"exp-category-"+index}>{(value.category==='indirect'?'Indirect Expense':'Direct Expense')}</span>)
                        </span>
                        <input type="text" defaultValue={value.type} className="form-control hide" id={'pm-txt-'+index}/>
                      </td>
                      <td>
                        <div id={'normal-mode-'+index} className="show">
                          {this.is_access('settings-expense-modify') && 
                            <>
                            
                            <FontAwesomeIcon id={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                            
                            </>
                          }
                          {this.is_access('settings-expense-delete') && 
                          <>
                            <FontAwesomeIcon id={value.exptid} onClick={this.deleteRow} data_target="delete_confirm" data_value="expense_type" data_id="delete_expense_type" icon={faTrash} className="pull-right fa-icon"/>
                          </> 
                          }
                        </div>
                        <div id={'edit-mode-'+index} className="hide" style={{marginTop:'-5px'}}>
                          <div style={{width:'100px',float:'left'}}>
                            <div className="form-check form-check-inline" style={{display:'block'}}>
                              <input className="form-check-input" type="radio" id="expnese_category1" name="expnese_category" value="indirect"  defaultChecked={(value.category==='indirect')?true:false}/>
                              <label className="form-check-label" for="expnese_category1">Indirect Expense</label>
                            </div>
                            <div className="form-check form-check-inline" style={{display:'block'}}>
                              <input className="form-check-input" type="radio" id="expnese_category" name="expnese_category" value="direct" defaultChecked={(value.category==='direct')?true:false}/>
                              <label className="form-check-label" for="expnese_category">Direct Expense</label>
                            </div>
                          </div>
                          {this.is_access('settings-expense-modify') && 
                          <div style={{width:'50px',float:'right',marginTop:'17px'}}>
                            <FontAwesomeIcon id={index} data-id={value.exptid} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                            <FontAwesomeIcon id={index} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                          </div>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody> 
            </table>
            }       
            {this.props.show==='dropdown' &&   
              <>
              <span onClick={this.handleSetExpenseType} id="handleSetExpenseType"></span>
              <select target={this.props.target} tabindex={this.props.tabindex} data-id={this.props.data_id} className={"form-control form-field "+this.props.className} name={this.props.name}  value={(this.state.modeDefault)} data-index={this.props.data_index} onChange={this.handleChange} id={this.props.id}>
                  
                  {(this.props.target==='report') &&
                    <option value="" key="exp">All</option>
                  }
                  {(this.props.target==='import') &&
                    <option value="" key="exp">Select Expense Type</option>
                  }

                  {(this.state.expense_types.length > 0) ? this.state.expense_types.map( (value, index) => {
                   return (
                      <option value={value.exptid} key={index}>{value.type}</option>
                    )
                  }) : ''}
              </select>
              </>
            }     
            </div>
        );
    }
}