import React, { Component } from "react";
import Link from './link.js';

export default class DifferentBarcodeItems extends Component {
    constructor(props) {
      super();
      this.state = {
         active_tab : 'available'
      }
    }

    handleNavigateMenu = (type)=>{
      this.setState({active_tab:type});
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
             
              <div  id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12 mrtop-5">
                        <h2>{this.props.page_title}</h2>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
               
                    <div className="content-area">
                      <div className="main-content">
                      <div className="nav-container">
                      <ul className="nav nav-pills" style={{position:'relative'}}>
                      
                        <li className={(this.state.active_tab==='available')?'active':''} onClick={()=>this.handleNavigateMenu('available')} style={{cursor:'pointer'}}>Available</li>
                        <li className={(this.state.active_tab==='soldout')?'active':''}onClick={()=>this.handleNavigateMenu('soldout')} style={{cursor:'pointer'}}>Sold Out</li>
                      
                      </ul>
                    </div>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>

                            <th></th>
                            <th scope="col">BARCODE</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col">IN STOCK</th>
                            <th scope="col">PP</th>
                            <th scope="col">SP</th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.props.inventory_list!=='' && this.props.inventory_list.length > 0) ?this.props.inventory_list.map( (value, index) => {
                           return (
                            <>
                            {((this.state.active_tab==='available' && parseFloat(value.availability)!==0) || (this.state.active_tab==='soldout' && parseFloat(value.availability)===0)) &&
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                             
                              <td width="70">
                                <div style={{backgroundImage:'url('+value.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width: '40px',border:'1px solid #d7d7d7'}}></div>
                              </td>

                              <td><Link style={{fontSize:'14px',fontWeight:'bold'}} link={(value.url!==undefined)?value.url:'/products/view/'+value.inventory_id} name={value.barcode_name+' ('+value.item_code+')'} /></td>

                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category_name} style={{cursor:'pointer'}} dangerouslySetInnerHTML={{ __html: value.category_name}}>
                              </td>
                               
                              <td> 
                                  {value.availability}
                              </td>
                              <td>{value.purchase_price}</td>
                              <td>{value.selling_price}</td>
                            </tr>
                            }
                           
                            </>
                          )
                         }) : <tr><td colSpan="10" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                    
                     
                   
                     </div>
                  </div>
                </div>
              </div>
           
            </div>
        );
    }
}