import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Leftnav from "./left-nav";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner,faBars} from '@fortawesome/free-solid-svg-icons';
import utils from '../../utils.js';
import ModuleAccess from "./module-access";
let {enc_key,app_api,admin_info} = require('../../library.js');


export default class ViewStore extends Component {
   constructor() {
      super();
      this.state = {
         store_info : [],
         subscriptions : [],
         store_name : '',
         status : '',
         status_text : '',
         status_btn : '',
         trial_period : '',
         is_module_access : false,
         module_access : [],
         is_hamberger_dd_open : false
      };
      this.handleChangeStatus = this.handleChangeStatus.bind(this);
      this.handleExtendSubs = this.handleExtendSubs.bind(this);
      this.handleExtend = this.handleExtend.bind(this);
      this.closeSubs = this.closeSubs.bind(this);
      this.openCancelSubs = this.openCancelSubs.bind(this);
      this.handleCancelSubs = this.handleCancelSubs.bind(this);
      this.openCancelSubs = this.openCancelSubs.bind(this);
      this.handleCloseModel = this.handleCloseModel.bind(this);
      this.openDropdown = this.openDropdown.bind(this);
      this.handleCloseDropdown = this.handleCloseDropdown.bind(this);
      this.handleStoreLogin = this.handleStoreLogin.bind(this);
      this.uuid = utils.uuid.bind(this);
    }

    handleStoreLogin(e){
      localStorage.removeItem('bc_log');
      let uuid = this.uuid();
      var body = {
          store_name : this.state.store_name,
          uuid :uuid
        };
        axios.post(app_api+'/admin/store_login', body)
          .then(function (response) {
           
            localStorage.setItem('user', JSON.stringify(response.data));
            window.open("/billings", '_blank');

          
          
        }).catch(function(res){
           
        })
    }

    handleCloseDropdown(e){
      let parentElement = e.target.parentElement;
      if(e.target.getAttribute('data-id')!=='open-dd' && parentElement.getAttribute('data-id')!=='open-dd'){
        this.setState({is_hamberger_dd_open:false});
      } 
    }

    openDropdown(e){
      this.setState({is_hamberger_dd_open:!this.state.is_hamberger_dd_open});
    }

    openCancelSubs(event) {
      var element = document.getElementById("cancel-subscription");
      element.classList.remove("fade");
      element.style.display="block"; 
    }
   
    handleCancelSubs(e){
      let store_name = this.state.store_name;
      this.handleCloseModel(e);
      let body = {
              store_name : store_name,
              login_id : admin_info.login_id,
           }
      let api = app_api+'/admin/cancel_subscription';
      axios.post(api, body)
        .then(function (response) {
             window.location.reload(); 
      }.bind(this)).catch(function(res){
         
      })
    }

    handleCloseModel(e){
      var element = document.getElementById("cancel-subscription");
      element.classList.add("fade");
      element.style.display="none"; 
    }

    closeSubs(e){
        let data_id = e.currentTarget.getAttribute("data-id");
        
        document.getElementById(data_id+'-div').classList.toggle('hide');
    }

    handleExtend(e){
        let store_name = this.state.store_name;
        let thisid = e.currentTarget.id;
       
        let data_id = e.currentTarget.getAttribute("data-id");
        let trial_period = document.getElementById("trial-period").value;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");

       
        let body = {
                trial_period :trial_period,
                store_name : store_name,
                login_id : admin_info.login_id,
             }
        let api = app_api+'/admin/extend_subscription';
        axios.post(api, body)
          .then(function (response) {
             window.location.reload();
              
        }.bind(this)).catch(function(res){
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
        })

    }

    handleExtendSubs(e){
        this.handleCloseDropdown(e);
        let thisid = e.currentTarget.id;
       
        document.getElementById('extend-sub-div').classList.toggle('hide');
        document.getElementById("trial-period").focus();
        document.getElementById("trial-period").select();
    }

    handleChangeStatus(e){
       
        let status = this.state.status;

        let status_text = 'Make it Inactive';
        let status_btn = 'btn btn-danger';
        if(status===0){
          status_text = 'Make it Active';
          status_btn = 'btn btn-success';
        }
        this.setState({ 'status_text': status_text});
        this.setState({ 'status_btn': status_btn});
        let store_name = this.state.store_name;
        let body = {
                status :status,
                store_name : store_name,
                login_id : admin_info.login_id,
             }
        let api = app_api+'/admin/change_store_status';
        axios.post(api, body)
          .then(function (response) {
             window.location.reload();
        }.bind(this)).catch(function(res){
           
        })
    }
    componentWillUnmount(){
      document.removeEventListener("click", this.handleCloseDropdown);
    }
    componentDidMount(){
          document.addEventListener("click", this.handleCloseDropdown);
      let curl = window.location.href;

      const view_store = curl.match(/admin-area\/view-store\/(.*$)/i);
      
      if(view_store && view_store[1]){
        let store_name = decodeURIComponent(view_store[1]);
        
        this.setState({store_name:store_name});
        let body = {};
          body = {
                store_name :store_name,
                login_id : admin_info.login_id,
             }
        let api = app_api+'/admin/view_store';
        axios.post(api, body)
          .then(function (response) {
              let store_info = response.data.data[0];
              let mod_access=[];
              
              if(store_info.module_acess!==undefined && store_info.module_acess!==null){
                for (let index = 0; index < store_info.module_acess.length; index++) {
                  const element = store_info.module_acess[index];
                  if(mod_access[element]===undefined){
                    mod_access[element] = element;
                  }
                }
              }
              
              this.setState({module_access : mod_access});

              let subscriptions = response.data.subscriptions;
              let trial_period = response.data.trial_period;
              this.setState({trial_period:trial_period});
              let status = store_info.status;
              let status_text = 'Make it Inactive';
              let status_btn = 'btn btn-danger';
              if(status===0){
                status_text = 'Make it Active';
                status_btn = 'btn btn-success';
              }

              this.setState({ 'subscriptions': subscriptions});
              this.setState({ 'store_info': store_info});
              this.setState({ 'status': status});
              this.setState({ 'status_text': status_text});
              this.setState({ 'status_btn': status_btn});
        }.bind(this)).catch(function(res){
            if(res.response){
              
            }
        })
      }
    }

    handleClearStoreCache = () =>{
      let text = "Are you sure? you want to clear store cache";
      if (window.confirm(text) == true) {
        var body = {
          store_name : this.state.store_name,
        };
        axios.post(app_api+'/admin/clear_store_cache', body)
        .then(function (response) {
          window.location.reload()
        }).catch(function(res){
        })
      }
    }

    openActivateModule = () =>{
        this.setState({is_module_access:true})
        document.getElementById("action-dropdown").style.display='none';
    }
    handleCloseModule = ()=>{
      this.setState({is_module_access:false});
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title"><span>Stores</span></div>
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-3">
                      
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                    <div className="content-area">
                    <div className="content-head border-bottom">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <div style={{display:'flex'}}>
                              <img  src={(this.state.store_info && this.state.store_info.logo_url)?this.state.store_info.logo_url:'-'} style={{height:'54px'}} alt="Store Logo"/>
                              <div style={{paddingLeft: '5px',lineHeight: '0.5',marginTop: '5px'}}>
                                <label className="bil_no">
                                  {(this.state.store_info && this.state.store_info.company_name)?this.state.store_info.company_name:''}
                                </label><br/>
                                <label style={{color:'#9d9999'}}>{(this.state.store_info && this.state.store_info.store_name)?this.state.store_info.store_name:''}</label>
                                <br/>
                                <label style={{color:'#9d9999'}}>{(this.state.store_info && this.state.store_info.email)?this.state.store_info.email:''}</label>
                              </div>
                            </div>
                        </div>
                        <div className="col-md-8 mrtop-5 text-right">
                        <div className="row">
                          <div className="col-md-5"></div>
                          <div className="col-md-5">
                              <div className="input-group hide" id="extend-sub-div">
                              <input id="trial-period" type="text" className="form-control no-border-right" placeholder="30" defaultValue={this.state.trial_period}/>
                              <div className="input-group-append">
                                <span className="input-group-text pad-left-none">Days</span>
                              </div>
                              <button type="button" id="tp-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              <button type="button" id="tp" onClick={this.handleExtend} data-target="trial-period" data-id="extend-sub" className="btn btn-primary mrleft-10">Save</button>
                              <button type="button" onClick={this.closeSubs} data-target="trial-period" data-id="extend-sub" className="btn btn-secondary mrleft-10">Close</button>
                            </div>
                          </div>
                          <div className="col-md-2">
                              
                              <div className="admin-dd" data-id="open-dd">
                                <span onClick={this.openDropdown}  data-id="open-dd">
                                  <FontAwesomeIcon icon={faBars} data-id="open-dd"/>
                                </span>
                                {(this.state.is_hamberger_dd_open) &&
                                <div className="dropdown-content" id="action-dropdown" style={{display:'block'}}>
                                    <ul className="list-group">
                                        <li onClick={this.handleChangeStatus} className="list-group-item">{this.state.status_text}</li>
                                        <li className="list-group-item" onClick={this.openActivateModule}>Activate Module</li>
                                        <li className="list-group-item" onClick={this.openCancelSubs}>Cancel Subscription</li>
                                        <li className="list-group-item" id="extend-sub-li" onClick={this.handleExtendSubs}>Extend Subscription</li>
                                        <li className="list-group-item" id="extend-sub-li" onClick={this.handleStoreLogin}>Login to Store</li>
                                        <li className="list-group-item" id="extend-sub-li" onClick={this.handleClearStoreCache}>Clear Store Cache</li>
                                    </ul>
                                 
                                </div>
                              }
                              </div>

                            </div>
                            </div>
                        </div> 
                       </div>
                     </div>
                      <div className="main-content border-bottom store-info hide">
                        <div className="inner-content">
                          <div className="row">
                           
                            <div className="col-md-12 store-details">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">STORE NAME</label>
                                    <p>{(this.state.store_info && this.state.store_info.store_name)?this.state.store_info.store_name:'-'}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="lbl">EMAIL</label>
                                    <p>{(this.state.store_info && this.state.store_info.email)?this.state.store_info.email:'-'}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">PHONE NUMBER</label>
                                    <p>{(this.state.store_info && this.state.store_info.phone)?this.state.store_info.phone:'-'}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="lbl">PHONE NUMBER 2</label>
                                    <p>{(this.state.store_info && this.state.store_info.phone_2)?this.state.store_info.phone_2:'-'}</p>
                                  </div>
                                </div>
                                
                                <div className="row">
                                  
                                  <div className="col-md-6">
                                    <label className="lbl">ADDRESS</label>
                                    <p>{(this.state.store_info && this.state.store_info.address)?this.state.store_info.address:'-'}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="lbl">ADDRESS 2</label>
                                    <p>{(this.state.store_info && this.state.store_info.address_2)?this.state.store_info.address_2:'-'}</p>
                                  </div>
                                </div>
                               
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">PIN CODE</label>
                                    <p>{(this.state.store_info && this.state.store_info.pin_code)?this.state.store_info.pin_code:'-'}</p>
                                  </div>
                                 
                                  <div className="col-md-6">
                                    <label className="lbl">CITY</label>
                                    <p>{(this.state.store_info && this.state.store_info.city)?this.state.store_info.city:'-'}</p>
                                  </div>
                                </div> 
                              
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">STATE</label>
                                    <p>{(this.state.store_info && this.state.store_info.state)?this.state.store_info.state:'-'}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="lbl">COUNTRY</label>
                                    <p>{(this.state.store_info && this.state.store_info.country)?this.state.store_info.country:'-'}</p>
                                  </div>
                                </div>
                               
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">GST NUMBER</label>
                                    <p>{(this.state.store_info && this.state.store_info.gst_number)?this.state.store_info.gst_number:'-'}</p>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="lbl">PAN NUMBER</label>
                                    <p>{(this.state.store_info && this.state.store_info.pan_number)?this.state.store_info.pan_number:'-'}</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="lbl">TAX LABEL</label>
                                    <p>{(this.state.store_info && this.state.store_info.tax_label)?this.state.store_info.tax_label:'-'}</p>
                                  </div>
                                </div>

                            </div>
                          </div>
                        </div>
                      </div>  
                   
                      <div className="main-content border-bottom store-info">
                        <div className="inner-content">
                          <div className="row">
                            <div className="col-md-4">
                              <p style={{margin: '0',padding: '10px',border: '1px solid #007bff'}}>Login Logs (Last 10 records)</p>
                              {(this.state.store_info.login_info && this.state.store_info.login_info.length>0) &&
                                      <>
                                      <table className="table">
                                        <thead>
                                          
                                          <tr>
                                            <th scope="col">USER</th>
                                            <th scope="col">DATE</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(this.state.store_info.login_info.length > 0) ?this.state.store_info.login_info.map( (value, index) => {
                                           return (
                                            <tr className="item-row" key={ index }>
                                              <td style={{textTransform:'capitalize'}}>{value.username}</td>
                                              <td>{value.login_date}</td>
                                              
                                            </tr>
                                          )
                                         }) : <tr><td colSpan="3" className="text-center">No record found</td></tr> }
                                        </tbody>
                                      </table>
                                  </>
                                  }
                            </div>
                            <div className="col-md-4">
                              <p  style={{margin: '0',padding: '10px',border: '1px solid #007bff'}}>Subscriptions</p>
                              {(this.state.subscriptions && this.state.subscriptions.length>0) &&
                                    <table className="table" style={{display:'block',overflowX:'auto'}}>
                                      <thead>
                                        <tr>
                                          <th scope="col">START DATE</th>
                                          <th scope="col">END DATE</th>
                                          <th scope="col">ON TRIAL</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(this.state.subscriptions.length > 0) ?this.state.subscriptions.map( (value, index) => {
                                         return (
                                          <tr className="item-row" key={ index }>
                                            <td>{value.start_date}</td>
                                            <td>{value.end_date}</td>
                                            <td>{value.is_trial}</td>
                                          </tr>
                                        )
                                       }) : <tr><td colSpan="3" className="text-center">No record found</td></tr> }
                                      </tbody>
                                    </table>
                                  }
                            </div>
                            <div className="col-md-4">
                            <p  style={{margin: '0',padding: '10px',border: '1px solid #007bff'}}>Modules Access</p>
                              {(this.state.store_info.module_acess && this.state.store_info.module_acess.length>0) &&
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Module</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(this.state.store_info.module_acess.length > 0) ?this.state.store_info.module_acess.map( (value, index) => {
                                         return (
                                          <tr className="item-row" key={ value }>
                                            <td style={{textTransform:'uppercase'}}>{value.replace('_',' ')}</td>
                                          </tr>
                                        )
                                       }) : <tr><td className="text-center">No record found</td></tr> }
                                      </tbody>
                                    </table>
                                  }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
              <div id="cancel-subscription" className="modal fade">
                <div className="modal-dialog modal-confirm">
                  <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box">
                        <span className="material-icons">&times;</span>
                      </div>            
                      <h4 className="modal-title w-100">Are you sure?</h4>  
                      <input type="hidden" id="deleteValue" />
                      <input type="hidden" id="deleteIndex" />
                      <input type="hidden" id="deleteTarget" />
                    </div>
                    <div className="modal-body">
                      <p>Do you really want to cancel the subscription for this store?.</p>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button type="button" className="btn btn-secondary" onClick={this.handleCloseModel}>Close</button>
                      <button type="button" className="btn btn-danger" onClick={this.handleCancelSubs}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>  
              {(this.state.is_module_access===true) &&
                <ModuleAccess store_info={this.state.store_info} module_access={this.state.module_access} handleCloseModule={this.handleCloseModule.bind(this)}/> 
              }
            </div>
        );
    }
}