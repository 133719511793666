import React, { Component } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import ServiceLoader from './service-loader.js';
import DrawerLink from './open-drawer-link.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Tooltip from "./tooltip";
import utils from '../utils.js';
import Link from './link.js';
import { faSpinner,faCalendarAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import CreateCashEntry from "./create-cash-entry";
import AccountTypes from "./account-types";
let {app_api,user_info} = require('../library.js');
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class ViewAccounts extends Component {
   constructor() {
      super();
      this.state = {
         account_list : [],
         active_tab:'all',
         download_transaction_list : [],
         progress : 0,
         is_download : false,
         number_of_page : 0,
         show_loader : false,
         should_re_generate_account_balance : false,
         is_show_filter_option : false,
         account_value : {},
         payment_mode_data : {},
         account_id : '',
         page : 1,
         transaction_list:[],
         is_load_more : false,
         is_loading : false,
         is_open_account : false,
         is_open_add_cash_entry : false,
         is_transfer_account : false,
         data_info : {},
         FromDate:  new Date(),
          ToDate: new Date(),
          filterType : 'today'
      };
    }
    FromDate = date => {
      this.setState({
        FromDate: date
      });
    };
    ToDate = date => {
      this.setState({
        ToDate: date
      });
    };
    handleGetAccountTransaction = (account_id='') =>{
      this.setState({is_loading:true});
      if(isNaN(account_id)){
        account_id = this.state.account_id;
      }
      let transaction_list = this.state.transaction_list;
      let abody =  {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          active_tab : this.state.active_tab,
          payment_mode : account_id,
          page : this.state.page,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
      }
      let aapi = app_api+'/get_account_transactions';
      axios.post(aapi, abody)
      .then(function (response) {
          let cursor = response.data.data;
          for (let index = 0; index < cursor.length; index++) {
            const element = cursor[index];
            transaction_list.push(element);
          }
          let next_page = this.state.page+1;
          this.setState({page:next_page});
          this.setState({transaction_list:transaction_list});
          if(cursor.length>=25){
            this.setState({is_load_more:true});
          }else{
            this.setState({is_load_more:false});
          }
          this.setState({is_loading:false});
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                
              }
          }
      })
    }

    componentDidMount(){
        let curl = window.location.href;
        const acc_view = curl.match(/view-accounts\/([debit|credit]+)\/(\d+$)/i);
        let get_id = curl.match(/view-accounts\/(\d+$)/i);
        let act_t = 'all';
        let pm_id = 0;
        if (acc_view) {
          act_t = acc_view[1];
          pm_id = acc_view[2];
          get_id = true;
        }else if(get_id){
          pm_id = parseInt(get_id[1]);
        }
        this.setState({account_id:pm_id,active_tab:act_t});
        if(get_id){
                
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                id : pm_id,
                
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
            }
            let api = app_api+'/get_account_types';
            axios.post(api, body)
            .then(function (response) {
                let cursor = response.data;
                if(cursor[0]!==undefined){
                    this.setState({payment_mode_data:cursor[0]})
                }
              }.bind(this)).catch(function(res){
                if(res.response){
                  if(res.response.data){
                    
                  }
                }
              })
              let _this = this;
              setTimeout(() => {
                _this.handleGetAccountTransaction(pm_id);
                _this.handleGetAccounts();
              }, 500);
        }
        let keys = "'account_value','should_re_generate_account_balance'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.account_value){
              this.setState({account_value:resp.account_value});
            }
            if(resp.should_re_generate_account_balance!==undefined){
              let srgab = resp.should_re_generate_account_balance;
              if(srgab[pm_id]===undefined){
                this.setState({should_re_generate_account_balance:true});
                this.handleRecalculateAccountBalance(1);
              }else if(srgab[pm_id]===true){
                this.setState({should_re_generate_account_balance:true});
                this.handleRecalculateAccountBalance(1);
              }
            }else if(resp.should_re_generate_account_balance===undefined){
              this.setState({should_re_generate_account_balance:true});
              this.handleRecalculateAccountBalance(1);
            }
        }.bind(this)).catch(function(res){
            
        })
    }

    handleGetAccounts = () =>{
      let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
      }
      
      let api = app_api+'/get_accounting';
      axios.post(api, body)
        .then(function (response) {
        this.setState({'account_list': response.data.data });
      }.bind(this)).catch(function(res){
         
      })
    }

    handleSaveAccountOB = (e) =>{
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let ob = document.getElementById("opening_balance").value;
        let account_id = this.state.account_id;
        let account_value = this.state.account_value;
        account_value[account_id] = ob;
        if(ob!==""){
            let send_data = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                name : 'account_value',
                value : account_value
            }
            let api = app_api+'/save_option';
            axios.post(api, send_data)
            .then(function (response) {
                document.getElementById(thisid).classList.remove("hide");
                document.getElementById(thisid+'-btn-spinner').classList.add("hide");
                this.hanldeCloseAccontPop();
                window.location.reload();
            }.bind(this)).catch(function(res){
                
            })
        }
    }
    handleOpenAccountPop = () =>{
      this.setState({is_open_account:true});
    }
    hanldeCloseAccontPop = () =>{
      this.setState({is_open_account:false});
    }
    handleMoveToAnotherAccount = (e) =>{
      let id = e.currentTarget.id;
      let transaction_data = this.state.transaction_list[id];
      this.setState({is_transfer_account:true});
      this.setState({data_info:transaction_data});
    }
    handleShowTransaction = (e) =>{
      let id = e.currentTarget.id;
      this.setState({transaction_list:[]});
      this.setState({is_loading:true})
      document.getElementById(id).classList.add("hide");
      document.getElementById(id+'-btn-spinner').classList.remove("hide");
      let account_id = this.state.account_id;
      let abody =  {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          payment_mode : account_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          filterType : this.state.filterType,
          from_date : this.state.FromDate,
          to_date : this.state.ToDate,
          active_tab : this.state.active_tab,
      }
      let aapi = app_api+'/get_account_transactions';
      axios.post(aapi, abody)
      .then(function (response) {
          let cursor = response.data.data;
          this.setState({transaction_list:cursor});
          this.setState({is_loading:false});
          document.getElementById(id).classList.remove("hide");
          document.getElementById(id+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
              }
          }
      })
    }
    handleFilterTypeChange = (e) =>{
      let filterType= e.currentTarget.value;
      this.setState({filterType:filterType});
    }
    handleShowFilterOption = (e) =>{
      let is_show_filter_option = this.state.is_show_filter_option;
      this.setState({is_show_filter_option:!is_show_filter_option});
      if(is_show_filter_option===true){
        window.location.reload();
      }
    }

    handleRecalculateAccountBalance = (page) =>{
      let abody =  {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token, 
        _schema : user_info._schema,
        schema : user_info._schema,
        account_id : this.state.account_id,
        number_of_page : this.state.number_of_page,
        page : page,
      }
      let aapi = app_api+'/re_calculate_account_trans_balance';
      axios.post(aapi, abody)
      .then(function (response) {
        let number_of_page = response.data.number_of_page;
        let is_empty = response.data.is_empty;
        let progress = (page / number_of_page) * 100;
        progress = utils.currency_format(progress);
        progress = Math.round(progress);
        this.setState({number_of_page:number_of_page,progress:progress});
        let next_page = page+1;
        if(is_empty===false){
         this.handleRecalculateAccountBalance(next_page);
        }else{
            this.setState({progress:100});
            this.setState({should_re_generate_account_balance:false});
            window.location.reload();
          
        }
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                
              }
          }
      })
    }
    handleDownloadTransaction = () =>{
      this.setState({is_download:true});
      if(this.state.is_show_filter_option===false){
        this.handleProcessDownloadTransaction(1);
      }else{
        this.setState({download_transaction_list:this.state.transaction_list});
        let _this = this;
        setTimeout(() => {
          document.getElementById('transactions-table-xls-button').click(); 
          _this.setState({is_download:false});
        }, 500);
      }
    }
    handleProcessDownloadTransaction = (dpage) =>{
      let  account_id = this.state.account_id;
      let download_transaction_list = this.state.download_transaction_list;
      let abody =  {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          payment_mode : account_id,
          page : dpage,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          number_of_page : this.state.number_of_page,
          active_tab : this.state.active_tab,
      }
      let aapi = app_api+'/get_account_transactions';
      axios.post(aapi, abody)
      .then(function (response) {
          let cursor = response.data.data;
          let number_of_page = response.data.number_of_page;
          let progress = (dpage / number_of_page) * 100;
          progress = utils.currency_format(progress);
          progress = Math.round(progress);
          this.setState({number_of_page:number_of_page,progress:progress});

          for (let index = 0; index < cursor.length; index++) {
            const element = cursor[index];
            download_transaction_list.push(element);
          }
          let next_page = dpage+1;
          this.setState({download_transaction_list:download_transaction_list});
          if(cursor.length>0){
           this.handleProcessDownloadTransaction(next_page);
          }else{
            
            document.getElementById('transactions-table-xls-button').click();
            let _this = this;
            setTimeout(() => {
              _this.setState({download_transaction_list:[],number_of_page:0,progress:0});
            }, 500);
            this.setState({is_download:false});
          }
          
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                
              }
          }
      })
    }
    handleChangeAcountName = (e) =>{
      let tvalue = e.currentTarget.value;
      let type = this.state.active_tab;
      if(type==='all'){
        window.location.href = '/view-accounts/'+tvalue;
      }else{
        window.location.href = '/view-accounts/'+type+'/'+tvalue;
      }
    }
    handleChangeAccountView = (type) =>{
      this.setState({active_tab:type});
      if(type==='all'){
        window.location.href = '/view-accounts/'+this.state.account_id;
      }else{
        window.location.href = '/view-accounts/'+type+'/'+this.state.account_id;
      }
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-5">
                        <div className="mod-title">
                            <a href="/accounting"> <span>Accounts</span></a> / <b>{(this.state.payment_mode_data.account_name!==undefined)?this.state.payment_mode_data.account_name:''}</b>
                            <span className="vchtype"></span>
                        </div>
                    </div>
                      <div className="col-md-2 mrtop-10">
                       
                        
                      </div>
                    <div className="col-md-5 text-right">
                      <div className="inven-btn">
                     
                      {(this.state.transaction_list.length==0) &&
                        <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleOpenAccountPop}>Add Opening Balance</button>
                      }
                      {(this.state.transaction_list.length>0) &&
                        <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleOpenAccountPop}>Edit Account Balance</button>
                      } 
                      <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleAddEntry}>Add Cash Entry</button>
                      </div>
                    </div>
                  </div>  
                </nav>
                <div className="container-fluid">
                    <ServiceLoader show={this.state.show_loader}/>
                    <div className="content-area">
                      <div className="main-content pad-30">
                      <div className="row">
                          <div className="col-md-12 text-right mrtop-5">
                            <span onClick={this.handleShowFilterOption} style={{cursor:'pointer'}}>
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style={{height:'30px'}}><defs></defs><title/><g data-name="Layer 2" id="Layer_2"><path d="M28,9H11a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/><path d="M7,9H4A1,1,0,0,1,4,7H7A1,1,0,0,1,7,9Z"/><path d="M21,17H4a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"/><path d="M11,25H4a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z"/><path d="M9,11a3,3,0,1,1,3-3A3,3,0,0,1,9,11ZM9,7a1,1,0,1,0,1,1A1,1,0,0,0,9,7Z"/><path d="M23,19a3,3,0,1,1,3-3A3,3,0,0,1,23,19Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,23,15Z"/><path d="M13,27a3,3,0,1,1,3-3A3,3,0,0,1,13,27Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,13,23Z"/><path d="M28,17H25a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z"/><path d="M28,25H15a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z"/></g><g id="frame"><rect style={{fill:'none'}} height="32" width="32"/></g></svg>
                            </span>
                           
                          </div>
                        </div>
                      {(this.state.is_show_filter_option===true) &&
                          <div className="row" style={{padding: '10px 0px',borderTop: '1px solid #d7d7d7',borderBottom: '1px solid #d7d7d7'}}>
                            {(this.state.filterType!=='other') &&
                              <div className="col-md-8"></div>
                            }
                            {(this.state.filterType==='other') &&
                              <div className="col-md-2"></div>
                            }
                            <div className="col-md-3">
                              
                              <select className="form-control" onChange={this.handleFilterTypeChange} id="filter-type">
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="this_week">This Week</option>
                                <option value="last_week">Last Week</option>
                                <option value="last_30_days">Last 30 Days</option>
                                <option value="this_month">This Month</option>
                                <option value="last_month">Last Month</option>
                                <option value="this_quarter">This Quarter</option>
                                <option value="last_quarter">Last Quarter</option>
                                <option value="this_year">This Year</option>
                                <option value="other">Custom</option>
                              </select>
                            </div>
                            {(this.state.filterType==='other') &&
                            <>
                              <div className="col-md-3">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy" id="cal-from-date"/>
                                </div>
                              </div>
                              </div>
                              <div className="col-md-3">
                        
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="cal-from">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </span>
                                  <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy" id="cal-to-date"/>
                                </div>
                              </div>
                              </div>
                            </>
                            }
                            <div className="col-md-1 text-right">
                            <button type="button" className="btn btn-success" onClick={this.handleShowTransaction} id="view-report" data-target={this.state.active_tab}>VIEW</button>
                            <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                          </div>
                        }
                          <div className="row mrtop-10">
                                <div className="col-md-6">
                                    <label> Account Name : </label> 
                                    <select className="form-control" onChange={this.handleChangeAcountName} value={this.state.account_id}>
                                        {(this.state.account_list.map((value,index)=>{
                                          return(
                                            <option value={value.actid}>
                                              {value.account_name}
                                            </option>
                                          )
                                        }))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="text-right"> Current Balance : <span className="vc-info-spn">₹{utils.currency_format(this.state.account_value[this.state.account_id])}</span></h4>
                                </div>
                          </div>
                          {(this.state.should_re_generate_account_balance===true) &&
                          <div className="row mrtop-10">
                            <div className="col-md-12">
                                <div className="csv-load-blk" style={{paddingTop:'10px',paddingBottom:'0px'}}>
                                  <div className="parent-bar">
                                    <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                  </div>
                                  <p>Please wait while re calculating balance({this.state.progress}%)</p>
                                </div>
                            </div>
                          </div>
                          }
                          <div className="row" style={{marginTop:'20px'}}>
                            <div className="col-md-12">
                            <div className="nav-container">
                              <ul className="nav nav-pills">
                                <li className={(this.state.active_tab==='all')?'active':''} onClick={()=>this.handleChangeAccountView('all')}><a>All</a></li>
                                <li  className={(this.state.active_tab==='debit')?'active':''} onClick={()=>this.handleChangeAccountView('debit')}><a>Debit</a></li>
                                <li  className={(this.state.active_tab==='credit')?'active':''} onClick={()=>this.handleChangeAccountView('credit')}><a>Credit</a></li>
                               
                              </ul>
                            </div>
                            </div>
                         
                            {(this.state.transaction_list.length>0) &&
                            <>
                              <div className="col-md-9">
                                {(this.state.is_download===true) &&
                                  <div className="csv-load-blk" style={{paddingTop:'10px',paddingBottom:'0px'}}>
                                    <div className="parent-bar">
                                      <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                    </div>
                                    <p>Please wait while donwload account transaction ({this.state.progress}%)</p>
                                  </div>
                                }
                              </div>
                              <div className="col-md-3">
                                <ReactHTMLTableToExcel
                                  id="transactions-table-xls-button"
                                  className="btn btn-success pull-right  mrtop-10 mrright-5 hide"
                                  table="transactions-table"
                                  filename="transactions"
                                  sheet="transactions"
                                  buttonText="Download this Page"/>
                                {(this.state.is_download===false) &&
                                  <button className="btn btn-success pull-right  mrtop-10 mrright-5" onClick={this.handleDownloadTransaction}>Download as XLS</button>
                                }
                                {(this.state.is_download===true) &&
                                 <button type="button" className="btn btn-success pull-right  mrtop-10 mrright-5"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                }
                              </div>
                            </>
                            }
                            <div className="col-md-12" style={{overflow:'auto',marginTop:'10px'}}>
                              <table className="table hide" id="transactions-table">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Ref. No.</th>
                                    <th scope="col">Cheque No.</th>
                                    <th scope="col">Party Name</th>
                                    {(this.state.active_tab==='all') &&
                                    <>
                                      <th scope="col">Invoice</th>
                                      <th scope="col">Debit</th>
                                      <th scope="col">Credit</th>
                                      <th scope="col">Balance</th>
                                      <th scope="col">Action</th>
                                    </>
                                    }
                                    {(this.state.active_tab!=='all') &&
                                      <th scope="col">Amount</th>
                                    }
                                  </tr>
                                </thead>
                                {(this.state.download_transaction_list.length>0) &&
                                <tbody>
                                  {this.state.download_transaction_list.map((value,index) => {
                                      return (
                                          <tr>
                                            <td>{value.trans_date}</td>
                                            <td>{value.ref_no}</td>
                                            <td>{value.cheque_no}</td>
                                            <td>{value.party_name}</td>
                                            {(this.state.active_tab==='all') &&
                                              <>
                                                <td>{value.sequence}</td>
                                                <td style={{color:'#2ec32e'}}>{(value.credit!=='')?'₹'+utils.currency_format(value.credit):''}</td>
                                                <td style={{color:'red'}}>{(value.debit!=='')?'₹'+utils.currency_format(value.debit):''}</td>
                                                <td>{(value.balance_amount!=='')?'₹'+utils.currency_format(value.balance_amount):''}</td>
                                                <td>{value.message}</td>
                                              </>
                                            }
                                            {(this.state.active_tab!=='all') &&
                                              <td>
                                                {(value.credit!=='')?'₹'+utils.currency_format(value.credit):''}
                                                {(value.debit!=='')?'₹'+utils.currency_format(value.debit):''}
                                              </td>
                                            }
                                          </tr>
                                      )
                                  })} 
                                </tbody>
                                }
                              </table>
                              <table className="table">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Ref. No.</th>
                                    <th scope="col">Cheque No.</th>
                                    <th scope="col">Party Name</th>
                                    {(this.state.active_tab==='all') &&
                                    <>
                                    <th scope="col">Invoice</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Action</th>
                                    </>
                                    }
                                    {(this.state.active_tab!=='all') &&
                                    <th scope="col">Amount</th>
                                    }
                                    {/* <th scope="col">Clearance Date</th>
                                    <th scope="col">Status</th> */}
                                    <th></th>
                                  </tr>
                                </thead>
                                {(this.state.transaction_list.length>0) &&
                                <tbody>
                                  {this.state.transaction_list.map((value,index) => {
                                      return (
                                          <tr>
                                            <td>{value.trans_date}</td>
                                            <td>{value.ref_no}</td>
                                            <td>{value.cheque_no}</td>
                                            <td>{value.party_name}</td>
                                            {(this.state.active_tab==='all') &&
                                            <>
                                            <td>
                                            {(value.type==='IN' || value.type==='OUT') &&
                                                <DrawerLink target="payment" url={'/view-payment/'+value.trans_id} schema={value.schema} name={value.sequence}/>
                                            }
                                            {(value.type!=='cash_entry-' && value.type!=='cash_entry+' && value.type!=='IN' && value.type!=='OUT' && value.trans_id>0 && value.type!=='expenses' && value.type!=='cheque_entry-' && value.type!=='cheque_entry+') &&
                                                <DrawerLink  target="billing" url={'/view-billing/'+value.link+'/'+value.trans_id} schema={value.schema} name={value.sequence} />
                                            }
                                            {(value.type==='cash_entry-' || value.type==='cash_entry+') &&
                                                 <DrawerLink target="cash_entry" url="" schema="" name={value.sequence} info={value.bill_info} page_title={value.sequence+' Info'}/>
                                            }
                                            {( value.type==='expenses' && value.trans_id>0) &&
                                                <a href={'/view-expense/'+value.trans_id} target="_blank">{value.sequence}</a>
                                            }
                                            {(value.type==='cheque_entry-' || value.type==='cheque_entry+') &&
                                                <DrawerLink target="cheque" url={''} name={value.sequence} info={value.bill_info} width="85%"/>
                                            }
                                            {(value.trans_id===0) &&
                                              <span>{value.sequence}</span>
                                            }
                                            </td>
                                            <td style={{color:'#2ec32e'}}>{(value.credit!=='')?'₹'+utils.currency_format(value.credit):''}</td>
                                            <td style={{color:'red'}}>{(value.debit!=='')?'₹'+utils.currency_format(value.debit):''}</td>
                                            <td>{(value.balance_amount!=='')?'₹'+utils.currency_format(value.balance_amount):''}</td>
                                            <td>{value.message}</td>
                                            </>
                                            }
                                            {(this.state.active_tab!=='all') &&
                                            <td>
                                              {(value.credit!=='')?'₹'+utils.currency_format(value.credit):''}
                                              {(value.debit!=='')?'₹'+utils.currency_format(value.debit):''}
                                            </td>
                                            }
                                           {/*  <td>{value.clearance_date}</td>
                                            <td>{value.status}</td> */}
                                            <td>
                                              <div className="dropdown" style={{float:'right'}}>
                                                <span>...</span>
                                                <div className={"dropdown-content right"}>
                                                    <ul className="list-group">
                                                        <li className="list-group-item"><a onClick={this.handleMoveToAnotherAccount} id={index}>Move to another account</a></li>
                                                    </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                      )
                                  })} 
                                  {(this.state.is_load_more===true && this.state.is_show_filter_option===false) &&
                                    <tr>
                                      <td colSpan={(this.state.active_tab!=='all')?6:9} className="text-center">
                                        {(this.state.is_loading===false) &&
                                          <button type="button" className="btn btn-outline-info" onClick={this.handleGetAccountTransaction} id="load-more">Load More</button>
                                        }
                                        {(this.state.is_loading===true) &&
                                          <button type="button" className="btn btn-outline-info">Loading...</button>
                                        }
                                      </td>
                                    </tr>
                                  }
                                </tbody>
                                }
                                {(this.state.is_loading===true) &&
                                <tbody>
                                  <tr>
                                    <td colSpan={8}>
                                      <p style={{textAlign:'center'}}>Loading...</p>
                                    </td>
                                  </tr>
                                </tbody>
                                }
                                {(this.state.is_loading===false && this.state.transaction_list.length===0) &&
                                <tbody>
                                  <tr>
                                    <td colSpan={8}>
                                      <p style={{textAlign:'center'}}>No transaction found</p>
                                    </td>
                                  </tr>
                                </tbody>
                                }
                              </table>
                            </div>
                          </div>
                         
                      
                      </div>
                    </div>
                </div>
              </div>
              {(this.state.is_open_account===true) &&
                <div className="modal" id="account-value-popup"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Account Balance</h5>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                              <label htmlFor="new_name">Account Value</label>
                              <input type="text" className="form-control" id="opening_balance" placeholder="Account Opening Balance" defaultValue={this.state.account_value[this.state.account_id]}/>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.hanldeCloseAccontPop} id="close-account-ob">Close</button>
                        <button type="button" data-id={this.props.id} className="btn btn-success pull-right" id="save-account-ob" onClick={this.handleSaveAccountOB}>Save</button>
                        <button type="button" id="save-account-ob-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {(this.state.is_open_add_cash_entry===true) &&
                <CreateCashEntry handleCloseCashEntryModal = {this.handleCloseCashEntryModal.bind(this)} account_name={this.state.payment_mode_data.account_name} account_id={this.state.account_id}/>
              }
              {(this.state.is_transfer_account===true) &&
                <>
                  <TransferAccount account_id = {this.state.account_id} data_info={this.state.data_info} account_name={this.state.payment_mode_data.account_name}/>
                  <span id="handleCloseTransferAccountPopup" onClick={this.handleCloseTransferAccountPopup}></span>
                </>
              }
            </div>
        );
    }
    handleCloseTransferAccountPopup = () =>{
      this.setState({is_transfer_account:false});
    }
    handleAddEntry = () =>{
      this.setState({is_open_add_cash_entry:true});
    }
    handleCloseCashEntryModal = () =>{
      this.setState({is_open_add_cash_entry:false})
    }
    
}


class TransferAccount extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
  }

  

  render() {
    return(
      <div className="modal" id="transfer-stock-model"  role="dialog" aria-labelledby="transfer-stock-model" aria-hidden="true" style={{display:'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="add-category-title">Transfer from {this.props.account_name}</h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 border-bottom">
                      <p style={{fontSize: '18px',margin: '0',fontFamily: 'monospace'}}>Transferring {this.props.data_info.sequence} with amount {utils.currency_format(this.props.data_info.amount)}</p>
                    </div>
                    <div className="col-md-12 mrtop-10">
                        <label htmlFor="category">Transfer To</label>
                        <AccountTypes show="dropdown" id="account-type"/>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" id="close-save-trans-but" onClick={this.handleClose}>Close</button>
                  <button type="button" id="save-trans-btn" className="btn btn-success pull-right" onClick={this.handleSave}>Move</button>
                  <button type="button" id="save-trans-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                </div>
              </div>
            </div>
          </div>
    )
  }
  handleClose = () =>{
    if(document.getElementById("handleCloseTransferAccountPopup")){
      document.getElementById("handleCloseTransferAccountPopup").click();
    }
  }
  handleSave = (e) =>{
    let this_id = e.currentTarget.id;
    let account_type = document.getElementById("account-type").value;
    if(account_type==""){
      document.getElementById("account-type").focus();
      return false;
    }
    document.getElementById(this_id).classList.add("hide");
    document.getElementById(this_id+"-spinner").classList.remove("hide");
    let abody =  {
      store_name : user_info.store_name,
      branch_id : user_info.login_id,
      user_id : user_info.user_id,
      uuid : utils.uuid(),
      role_id : user_info.access_level,
      token : user_info.token, 
      _schema : user_info._schema,
      transfer_data : this.props.data_info,
      transfer_from : this.props.account_id,
      transfer_to : account_type,
    }
    let aapi = app_api+'/transfer_account_amount';
    axios.post(aapi, abody)
    .then(function (response) {
      document.getElementById(this_id).classList.remove("hide");
      document.getElementById(this_id+"-spinner").classList.add("hide");
      window.location.reload();
    }.bind(this)).catch(function(res){
        if(res.response){
            if(res.response.data){
              
            }
        }
    })
  }
}