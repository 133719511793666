import React, {Component} from "react";
import axios from 'axios';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faSpinner  } from '@fortawesome/free-solid-svg-icons';
let {app_api} = require('../library.js');
export default class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
          is_set:false,
        }
        this.sendEmailToResetPwd = this.sendEmailToResetPwd.bind(this);
    }
    sendEmailToResetPwd(event){
        let username = document.getElementById("username").value;
        let store_name = document.getElementById("store_name").value;
        let email = document.getElementById("email").value;
        if(username==='' || store_name==='' || email===''){
          document.getElementById("error").innerHTML = "Please enter valid information.";
        }else{
          let id = event.currentTarget.id;
          document.getElementById(id).classList.add("hide");
          document.getElementById(id+'-btn-spinner').classList.remove("hide");
          var body = {
            store_name : store_name,
            username : username,
            email : email,
          };

          axios.post(app_api+'/send_reset_password_link', body)
            .then(function (response) {
              let resp = response.data;
              if(resp.success===1){
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                this.setState({is_set:true});
                document.getElementById("success-msg").innerHTML = resp.message;
              }else{
                document.getElementById(id).classList.remove("hide");
                document.getElementById(id+'-btn-spinner').classList.add("hide");
                document.getElementById("error").innerHTML = resp.message;
              }
             //window.location = "/login";
          }.bind(this)).catch(function(res){
              
          })
        }
    }
    render() {
        return (
            <div className="user-verify-cont">
              <div className="verify-blk">
               {!this.state.is_set &&
                    <>
                  <div className="icon-div"><FontAwesomeIcon icon={faEnvelope} className="icon"/></div>
                  <h5 className="mrtop-30">Verify Your Account</h5>
                  <div className="form-group mrtop-20">
                      <label>User Name</label>
                      <input type="text" id="username" className="form-control" placeholder="Enter Your User Name" autoFocus/>
                  </div>
                  <div className="form-group mrtop-20">
                      <label>Store Name</label>
                      <input type="text" id="store_name" className="form-control" placeholder="Enter Your Store Name"/>
                     
                  </div> 
                  <div className="form-group mrtop-20">
                      <label>Email</label>
                      <input type="text" id="email" className="form-control" placeholder="Enter Your Registered Email ID"/>
                      <p className="red confirm-error" id="error"></p>
                  </div>
                  <div className="form-group mrtop-30">
                   
                      <button type="button" id="set-pwd" onClick={this.sendEmailToResetPwd} className="btn btn-danger btn-block">Send Reset Password Link</button>
                      <button type="button" id="set-pwd-btn-spinner" className="hide btn btn-danger  btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  </div>
                 
                  <div className="mrtop-20 text-right">
                    <a href="/">Back to login</a>
                  </div>
                  </>
                  }
                  {this.state.is_set &&
                    <>
                      <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">Well done!</h4>
                      <p id="success-msg"></p>
                      </div>
                    </>
                  }
              </div>
            </div>
        );
    }
}