import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import axios from 'axios';
import Draggable from 'react-draggable';

export default class BarcodeDraggable extends Component {
     constructor() {
      super();
      this.state = {
        fontSize : '11px',
        fontWeight : 'normal',
      }
      this.handleStart = this.handleStart.bind(this);
      this.handleDrag = this.handleDrag.bind(this);
      this.handleStop = this.handleStop.bind(this); 
    }
    handleStart(e){
      //console.log(e);
    }
    handleDrag(e){
      //console.log(e);
    }
    handleStop(event, data){
      //let id = event.path[1].getAttribute('data_target');
      //let style = event.path[2].style.cssText;
    }
    render() {

        return (

              <Draggable
                handle=".handle"
                defaultPosition={this.props.position}
                position={null}
                bounds="parent"
                grid={[1, 1]}
                scale={1}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop} key={this.props.target}>

                <div data_target={this.props.target} data_value={this.props.element}>
                  <div className="handle">
                    {(this.props.target==='barcode') &&
                    <label className={"temp-elem-name "+this.props.target} style={{fontSize:(this.props.lable_style && this.props.lable_style.fontSize)?this.props.lable_style.fontSize+'px':this.state.fontSize,fontWeight:(this.props.lable_style && this.props.lable_style.fontWeight)?this.props.lable_style.fontWeight:this.state.fontWeight,width:(this.props.lable_style && this.props.lable_style.width!==undefined && this.props.lable_style.width!=="" )?this.props.lable_style.width:'',whiteSpace:(this.props.lable_style && this.props.lable_style.whiteSpace!==undefined && this.props.lable_style.whiteSpace!=='')?this.props.lable_style.whiteSpace:'',lineHeight:(this.props.lable_style && this.props.lable_style.lineHeight!==undefined && this.props.lable_style.lineHeight!=='')?this.props.lable_style.lineHeight:''}} data-target={"lbl-"+this.props.target}>{(this.props.display_value)?this.props.display_value:this.props.element}</label>
                    }
                    {(this.props.target!=='barcode') &&
                      <label className={"temp-elem-name "+this.props.target} style={{fontSize:(this.props.lable_style && this.props.lable_style.fontSize)?this.props.lable_style.fontSize+'px':this.state.fontSize,fontWeight:(this.props.lable_style && this.props.lable_style.fontWeight)?this.props.lable_style.fontWeight:this.state.fontWeight,width:(this.props.lable_style && this.props.lable_style.width!==undefined && this.props.lable_style.width!=="" )?this.props.lable_style.width:'',whiteSpace:(this.props.lable_style && this.props.lable_style.whiteSpace!==undefined && this.props.lable_style.whiteSpace!=='')?this.props.lable_style.whiteSpace:'',lineHeight:(this.props.lable_style && this.props.lable_style.lineHeight!==undefined && this.props.lable_style.lineHeight!=='')?this.props.lable_style.lineHeight:''}} data-target={"lbl-"+this.props.target}  dangerouslySetInnerHTML={{__html: (this.props.display_value)?this.props.display_value:this.props.element}}></label>
                    }
                  </div>
                </div>
              </Draggable>
                                
        );
    }
}