import React, { Component } from "react";
import Link from './link.js';
import DrawerLink from "./open-drawer-link.js";
import ReactWhatsapp from "react-whatsapp";
import PendingInvoicePDF from "./pending-invoice-pdf.js";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import QRCode from "react-qr-code";
import { PDFDownloadLink } from "@react-pdf/renderer";
//import { jsPDF } from "jspdf";
import utils from "../utils.js";
export default class ContactPendingInvoiceList extends Component {
    constructor(props) {
      super();
      this.state = {
        FromDate:  new Date(),
        ToDate: new Date(),
        loading_process : 'Loading...',
        is_print : false,
      }
      this.currency_format = utils.currency_format.bind(this);
    }


    handleInventoryPrint = (e) =>{
      let _this = this;
      this.setState({is_print:true});
      
      setTimeout(function(){


      var divContents = document.getElementById("report-print").innerHTML; 

      var a = window.open('', '', 'height=1000,location=no,left=250px'); 
      a.document.write('<html><head>'); 
      a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
      a.document.write('<style type="text/css">@page{size:A4,margin:0}.table .thead-light th{font-size:12px}.table .thead-light th{color:#9a9494}.table .thead-light th{background-color:#e9ecef !important;border-color:#dee2e6}.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}.table th,.table tr td{white-space:nowrap}.table td,.table th{padding:.75rem;border-top:1px solid #dee2e6}.no-print{display:none}.print-block{display:block}.hide.dr-cr-child-report{display:revert !important}.pending-invoice{border-bottom:1px solid #dee2e6;border-top:1px solid #dee2e6;}</style>'); 
      a.document.write('</head>'); 

      a.document.write('<body onload="window.print();">'); 
      a.document.write(divContents); 
      a.document.write('</body></html>');
      a.document.close();
      a.focus();
      _this.setState({is_print:false});
      },500);
  }
  /* handleDownloadPDF = (e) =>{
    const doc = new jsPDF("portrait", 'pt', 'A4');
    let _this = this;
    var divContents = document.getElementById("report-print").innerHTML; 
    doc.setFontSize(5);
    doc.html(divContents, {
      callback: function (doc) {
        doc.save(_this.props.page_title+'.pdf');
      },
      
    });
  } */
    render() {
        return (
            <div className="d-flex" id="wrapper">
             
              <div  id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12 mrtop-5">
                        <h2>{this.props.page_title}</h2>
                    </div>
                  </div>
                </nav>

                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                      <div className="row">
                        <div className="col-md-4 mrtop-10">
                          <h4 className="mrleft-10">Pending Amount : ₹{this.currency_format(this.props.pending_invoice_list.pending_amount)}</h4>
                        </div>
                        <div className="col-md-8">
                        <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleInventoryPrint}>Print</button>
                        {/* <button type="button" className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10" onClick={this.handleDownloadPDF}>Download PDF</button> */}
                      <ReactHTMLTableToExcel
                      id="pending-invoice-report-table-xls-button"
                      className="btn btn-success pull-right  mrtop-10 mrright-5"
                      table="pending-invoice-report-table"
                      filename="pending_invoice"
                      sheet="pending_invoice"
                      buttonText="Download XLS"/>
{/* 
                      <PDFDownloadLink document={<PendingInvoicePDF pdf_data={{contact_ids:this.props.pending_invoice_list.contact_ids,contact_names:this.props.pending_invoice_list.contact_names,pending_invoice:this.props.pending_invoice_list.customer_pending_invoice_report,contact_type:this.props.pending_invoice_list.selected_pending_invoice_contact_type,total_pendings:this.props.pending_invoice_list.total_pendings}}/>} fileName="pending-invoice.pdf">
                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn btn-success pull-right  mrtop-10 mrright-5">Download PDF</button>)}
                      </PDFDownloadLink> */}

                      {(this.props.pending_invoice_list.share_data.length!==0 && this.props.pending_invoice_list.contact_ids.length===1) &&
                        <div className="share-blk">
                        <ReactWhatsapp number={this.props.pending_invoice_list.share_data.mobile} message={this.props.pending_invoice_list.share_data.message} element="div"><button type="button" style={{marginTop:'10px',marginRight:'5px'}} className="btn btn-success"><div className="icons8-whatsapp"></div> Whatsapp</button></ReactWhatsapp>
                        </div>
                      }
                        </div>
                      </div>
                     
                    
                      
                      <div id="report-print">
                   
                      <p className="text-right print-block" style={{fontWeight:'bold'}}>Print On : {this.state.FromDate.getDate()+'/'+(this.state.FromDate.getMonth()+1)+'/'+this.state.FromDate.getFullYear()}</p>
                      <h2 className="text-center print-block border-bottom">{this.props.pending_invoice_list.contact_names}'s Pending Amount : ₹{this.currency_format(this.props.pending_invoice_list.pending_amount)}</h2>
                     
                      <table id="pending-invoice-report-table" className="table pending-invoice">
                      {(this.props.pending_invoice_list.contact_ids && this.props.pending_invoice_list.contact_ids.length > 0) ?this.props.pending_invoice_list.contact_ids.map( (val, ind) => {
                           return (
                                <table className="table pending-invoice">
                                    <thead>
                                        <tr>
                                          <th>S.No.</th>
                                          <th>Date</th>
                                          <th>Invoice</th>
                                          <th className="text-right">Pending</th>
                                          <th className="text-right">Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.props.pending_invoice_list.invoices && this.props.pending_invoice_list.invoices.length > 0) ?this.props.pending_invoice_list.invoices.map( (value, index) => {
                                     return (
                                          <tr key={ index } className="contact-row">
                                            <td>{index+1}</td>
                                            <td>{value.created_date}</td>
                                            <td>
                                              <span className="no-print">
                                               <DrawerLink target="billing" url={value.url} name={value.invoice_number}  schema={value.schema}/>
                                               {(value.fin_year!==undefined) &&
                                                  <span className="fyinstrip">{value.fin_year}</span>
                                                }
                                               </span>
                                              {(this.state.is_print) &&
                                                <span className="print-block">{value.invoice_number}</span>
                                              }
                                            </td>
                                            <td className="text-right">₹{this.currency_format(value.pending_amount)}</td>
                                            <td className="text-right">{value.age}</td>
                                          </tr>
                                      )
                                   }) : <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr> }
                                  </tbody>
                                </table>
                             )
                      }) : <div className="row" style={{padding:'20px'}}><div className="col-md-12 text-center">{this.state.loading_process}</div></div> }
                      </table>
                  <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "200", width: "200" }}
                  value={`upi://pay?pn=wasim akhtar&pa=6300308905@ybl&am=1&url&pn=&tr=&tn=message`}
                  viewBox={`0 0 256 256`}
                  />
                     </div>
                     </div>
                  </div>
                </div>


              </div>
           
            </div>
        );
    }
}