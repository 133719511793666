import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner  } from '@fortawesome/free-solid-svg-icons';
import utils from '../../utils.js';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
let {enc_key,app_api,user_info} = require('../../library.js');
export default class StockCalculator extends Component {
    constructor() {
        super();
        this.state = {
           total_record : 0,
           is_loading : true,
           load_percent : 0,
           unscanned_list : [],
           scanned_list : [],
           scan_input : '',
           scanned_quantity : 0,
           scan_select_idx : -1,
           is_pause_scan : false,
           total_unscanned_item : 0,
           total_unscanned_quantity : 0,
           total_unscanned_price : 0,
           total_scanned_item : 0,
           total_scanned_quantity : 0,
           total_scanned_price : 0,
           error:''
        };
        this.total_price = 0;
        this.total_quantity = 0;
        this.total_items = 0;

        this.floating_point = utils.floating_point.bind(this);
    }
    
    handleLoadUnscannedProduct = (page) =>{
      
      let unscanned_list_len = this.state.unscanned_list.length;
      if(this.state.total_record>0){
        let cal_percent = (unscanned_list_len/this.state.total_record)*100;

        if(cal_percent>100){
          cal_percent = 100;
        }
        this.setState({load_percent:cal_percent});
      }

      let body = {
          page : page,
          target:'product',
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          total_record : this.state.total_record,
          type : 'active'
      }
      let api = app_api+'/get_unscanned_products';
        axios.post(api, body)
          .then(function (response) {
            let product_data = response.data.data;
            if(product_data.scanned_list!==undefined){
              this.setState({unscanned_list:  product_data.unscanned_list});
              this.setState({scanned_list:  product_data.scanned_list});

              this.setState({total_scanned_item:  product_data.total_scanned_item});
              this.setState({total_scanned_price:  product_data.total_scanned_price});
              this.setState({total_scanned_quantity:  product_data.total_scanned_quantity});

              this.setState({total_unscanned_item:  product_data.total_unscanned_item});
              this.setState({total_unscanned_price:  product_data.total_unscanned_price});
              this.setState({total_unscanned_quantity:  product_data.total_unscanned_quantity});
              this.setState({is_loading:false});
            }else{
              let total_record = response.data.total_record;
              this.setState({total_record:total_record});
              if(response.data.is_empty){
                this.setState({is_loading:false});
              }else{
                let unscanned_list = this.state.unscanned_list;
                
                product_data.forEach(element => {
                  

                  let sp = element.selling_price;
                  let avl = element.availability;
                  if(sp===""){
                    sp = 0;
                  }
                  if(avl===""){
                    avl = 0;
                  }
                  if(avl>0){
                    this.total_items++;
                    this.total_price = this.total_price+(parseFloat(avl)*parseFloat(sp));
                    this.total_quantity = this.total_quantity+parseFloat(avl);
                    unscanned_list.push(element);
                  }
                });
                this.setState({unscanned_list:  unscanned_list});
                this.setState({total_unscanned_item:  this.total_items});
                this.setState({total_unscanned_price:  this.total_price});
                this.setState({total_unscanned_quantity:  this.total_quantity});
                let next_page = page+1;
                //this.setState({is_loading:false});
                this.handleLoadUnscannedProduct(next_page);
              }
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               //this.setState({loading_process:'Record(s) not found'});
            }
        })
    }

    componentDidMount = () =>{
      this.handleLoadUnscannedProduct(1)
    }

    handleSaveStockToCache = (thisid) =>{
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          data : {unscanned_list : this.state.unscanned_list,scanned_list : this.state.scanned_list,total_scanned_item:this.state.total_scanned_item,total_scanned_price:this.state.total_scanned_price,total_scanned_quantity:this.state.total_scanned_quantity,total_unscanned_item:this.state.total_unscanned_item,total_unscanned_price:this.state.total_unscanned_price,total_unscanned_quantity:this.state.total_unscanned_quantity}
      }
      let api = app_api+'/save_stock_calculator_cache';
        axios.post(api, body)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            this.setState({is_pause_scan:false});
        }.bind(this)).catch(function(res){
            if(res.response){
              //this.setState({loading_process:'Record(s) not found'});
            }
        })
    }
    handeCompleteScan = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          data : {unscanned_list : this.state.unscanned_list,scanned_list : this.state.scanned_list}
      }
      let api = app_api+'/clear_stock_calculator_cache';
        axios.post(api, body)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            window.location.reload();
        }.bind(this)).catch(function(res){
            if(res.response){
              //this.setState({loading_process:'Record(s) not found'});
            }
        })
    }

    handleScanProduct = (e) =>{
      let key_code = e.which;
      
      let userInput = document.getElementById("scan-inventory").value;
      let scanned_quantity = document.getElementById("scan-quantity").value;
      scanned_quantity = parseFloat(scanned_quantity);
      if((key_code===13 && userInput!=="") || key_code===undefined){
        let qs = document.querySelectorAll("#unscanned-list .active")
        if(qs.length===0){
          let sinv_field = document.getElementById("scan-inventory");
          sinv_field.focus();
          sinv_field.select();
          this.setState({error:'Oops ! Product not found'});
          return false;
        }
        this.setState({scan_input:userInput});
        let lcount = 0;
        let options = this.state.unscanned_list;
        let scanned_list = this.state.scanned_list;
        let scan_select_idx = this.state.scan_select_idx;
        let availability = parseFloat(options[scan_select_idx].availability);

        let total_unscanned_item = this.state.total_unscanned_item;
        let total_unscanned_quantity = this.state.total_unscanned_quantity;
        let total_unscanned_price = this.state.total_unscanned_price;

        let total_scanned_item = this.state.total_scanned_item;
        let total_scanned_quantity = this.state.total_scanned_quantity;
        let total_scanned_price = this.state.total_scanned_price;

        if(availability>=scanned_quantity){
          if(scan_select_idx>=0){
            total_unscanned_quantity = total_unscanned_quantity-scanned_quantity;
            total_scanned_quantity = total_scanned_quantity+scanned_quantity;

            let scanned_price = parseFloat(options[scan_select_idx].selling_price) * scanned_quantity;
            total_unscanned_price = total_unscanned_price-scanned_price;
            total_scanned_price = total_scanned_price+scanned_price;
            if(scanned_list[scan_select_idx]!==undefined && scanned_list[scan_select_idx]!==null){
              scanned_list[scan_select_idx].quantity = parseFloat(scanned_list[scan_select_idx].quantity)+scanned_quantity;
              options[scan_select_idx].availability = parseFloat(options[scan_select_idx].availability)-scanned_quantity;

              if(parseFloat(options[scan_select_idx].availability)===0){
                total_unscanned_item = total_unscanned_item-1;
              }

            }else{
              scanned_list[scan_select_idx] = [];
              scanned_list[scan_select_idx] = options[scan_select_idx];
              scanned_list[scan_select_idx].quantity = scanned_quantity;
              options[scan_select_idx].availability = parseFloat(options[scan_select_idx].availability)-scanned_quantity;
              if(parseFloat(options[scan_select_idx].availability)===0){
                total_unscanned_item = total_unscanned_item-1;
              }
            }
          }
          this.setState({scanned_list:scanned_list});
          this.setState({unscanned_list:options});
          this.setState({is_pause_scan:true});
          this.setState({total_unscanned_item:total_unscanned_item});
          this.setState({total_unscanned_quantity:total_unscanned_quantity});
          this.setState({total_unscanned_price:total_unscanned_price});

          this.setState({total_scanned_quantity:total_scanned_quantity});
          this.setState({total_scanned_price:total_scanned_price});
          let scannned_count = 0;
          scanned_list.forEach(element => {
              if(element!==null && element.quantity!==undefined){
                scannned_count = scannned_count+1;
              }
          });

          this.setState({total_scanned_item:scannned_count});
          
          document.getElementById("scan-inventory").value = '';
          document.getElementById("scan-quantity").value = '';
          document.getElementById("scan-inventory").focus();
          this.setState({scan_input:''});
          let qs = document.querySelectorAll("#unscanned-list .active")
          qs.forEach(n => n.classList.remove('active'));
        }
      }
    }
    handleChangeName = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({scan_input:thisvalue});
      this.setState({error:''});
      if(thisvalue!==""){
        let qs = document.querySelectorAll("#unscanned-list .active")
        qs.forEach(n => n.classList.remove('active'));
        setTimeout(() => {
          if(document.getElementById('unscanned-list').firstChild){
            document.getElementById('unscanned-list').firstChild.className = "active";
          }else{
            this.setState({error:'Oops ! Product not found'});
          }
        }, 100);
      }else{
        let qs = document.querySelectorAll("#unscanned-list .active")
        qs.forEach(n => n.classList.remove('active'));
      }
    }
    handleSetProductOnField = (e)=>{
      let unscanned_list = this.state.unscanned_list;
      let idx = e.currentTarget.getAttribute('data-id');
      let item_name = unscanned_list[idx].item_name;
      document.getElementById("scan-inventory").value = item_name;
      document.getElementById("scan-quantity").value = 1;
      this.setState({scan_select_idx:idx});

      let qs = document.querySelectorAll("#unscanned-list .active")
      qs.forEach(n => n.classList.remove('active'));
      e.currentTarget.classList.add('active');
      //document.getElementById("scan-quantity").focus();
    }
    handlePauseScan = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      
      this.handleSaveStockToCache(thisid);
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
          
              <div id="page-content-wrapper">
                 <nav className="nb navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12">	
                        <div className="mod-title"><span>Stock Calculator</span></div>	
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                    <div className="content-head border-bottom" style={{paddingTop: '30px',paddingBottom: '30px'}}>
                      <div className="row">
                      {(this.state.is_loading===true) &&
                          <div className="col-md-12">
                            <div className="parent-progress" style={{width: '100%',height: '10px',background: '#d7d7d7'}}>
                                <div className="unscanned-progress" style={{width:this.state.load_percent+'%'}}></div>
                                <p className="text-right">{Math.ceil(this.state.load_percent)}%</p>
                            </div>
                          </div>
                      }
                        <div className="col-md-4">
                          {(this.state.is_loading===false) &&
                              <>
                              
                              <input type="text" className="form-control" id="scan-inventory" placeholder="Scan or type product here and press enter..." onChange={this.handleChangeName} tabindex={1} target="scan-inventory" autoComplete="off"/>

                              <p className="error">{this.state.error}</p>
                              </>
                          }
                          
                        </div>
                        <div className="col-md-2">
                        {(this.state.is_loading===false) &&
                            <input type="text" className="form-control" id="scan-quantity" placeholder="Enter Quantity" onKeyDown={this.handleScanProduct} tabindex={2} target="scan-quantity"/>
                        }
                        </div>
                        <div className="col-md-2">
                        {(this.state.is_loading===false) &&
                           <button type="button" className="btn btn-success btn-block" target="scan-btn" id="scan-btn" onClick={this.handleScanProduct}>Add</button>
                        }
                        </div>
                        <div className="col-md-2">
                          {(this.state.is_pause_scan===true) &&
                          <>
                            <button type="button" className="btn btn-outline-success btn-block" id="pause-scan" onClick={this.handlePauseScan}>Pause Scan</button>
                            <button type="button" id="pause-scan-btn-spinner"  style={{margin:'unset'}}  className="hide btn btn-outline-success btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </>
                          }
                        </div>
                        <div className="col-md-2">
                        {(this.state.is_loading===false && this.state.scanned_list.length>0) &&
                        <>
                          <button type="button" className="btn btn-outline-success btn-block" id="complete-scan" onClick={this.handeCompleteScan}>Finish Scan</button>
                          <button type="button" id="complete-scan-btn-spinner" style={{margin:'unset'}} className="hide btn btn-outline-success btn-block"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </>
                        }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-area">
                     <div className="content-head border-bottom">
                       <div className="row">
                        <div className="col-md-6 border-right">
                         
                          <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',borderRight: '2px solid #d7d7d7',fontSize:'12px'}}>NO. OF ITEMS : <span style={{fontWeight:'bold', fontSize:'14px'}}>{this.state.total_unscanned_item}</span></label>
                          <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',borderRight: '2px solid #d7d7d7',fontSize:'12px'}}>TOTAL PRICE : <span style={{fontWeight:'bold', fontSize:'14px'}}>₹{this.floating_point(this.state.total_unscanned_price)}</span></label>
                          <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',fontSize:'12px'}}>TOTAL STOCK : <span style={{fontWeight:'bold', fontSize:'14px'}}>{this.state.total_unscanned_quantity}</span></label>
                          
                          <div className="box-container" style={{paddingRight:'0px',paddingLeft:'0px'}}>
                            <div className="box-head">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>UNSCANNED PRODUCTS</label>
                                </div>
                                <div className="col-md-6 text-right">
                                  <ReactHTMLTableToExcel
                                    id="stock-calculator-unscanned-list-xls-button"
                                    className="btn btn-success btn-sm pull-right"
                                    table="stock-calculator-unscanned-list"
                                    filename="stock-calculator-unscanned-list"
                                    sheet="stock-calculator-unscanned-list"
                                    buttonText="Download as XLS"/>
                                </div>
                              </div>
                            </div>
                            <div className="box-body">
                              <table className="table" id="stock-calculator-unscanned-list">
                                <thead>
                                  <th>Item Name</th>
                                  <th className="text-center">Selling Price</th>
                                  <th className="text-right">In Stock</th>
                                </thead>
                                <tbody id="unscanned-list">
                                  {this.state.unscanned_list.map((value,key) =>(
                                    <>
                                    {(value!==null && value.availability!==undefined && value.availability>0) &&
                                    <>
                                    {(this.state.scan_input==='' || value.item_name.toLowerCase().indexOf(this.state.scan_input.toLowerCase())>=0  || value.barcode_name.toLowerCase().indexOf(this.state.scan_input.toLowerCase())>=0) &&
                                    <tr data-id={key} onClick={this.handleSetProductOnField} style={{cursor:'pointer'}}>
                                      <td><span>{value.item_name}</span><span style={{fontSize: '18px'}}> | </span><span style={{fontSize: '12px'}}>{value.barcode_name}</span></td>
                                      <td className="text-center">
                                        <span>₹{value.selling_price}</span>
                                      </td>
                                      <td className="text-right"><span>{value.availability}</span></td>
                                    </tr>
                                    }
                                    </>
                                    }
                                    </>
                                  ))}
                                  {(this.state.is_loading===true) &&
                                  <tr>
                                    <td className="text-center" colSpan={3}>
                                        Loading...
                                    </td>
                                  </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                         <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',borderRight: '2px solid #d7d7d7',fontSize:'12px'}}>NO. OF ITEMS : <span style={{fontWeight:'bold', fontSize:'14px'}}>{this.state.total_scanned_item}</span></label>
                          <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',borderRight: '2px solid #d7d7d7',fontSize:'12px'}}>TOTAL PRICE : <span style={{fontWeight:'bold', fontSize:'14px'}}>₹{this.floating_point(this.state.total_scanned_price)}</span></label>
                          <label style={{fontWeight:'bold',color:'gray',padding: '0 10px',fontSize:'12px'}}>TOTAL STOCK : <span style={{fontWeight:'bold', fontSize:'14px'}}>{this.state.total_scanned_quantity}</span></label>
                          <div className="box-container" style={{paddingRight:'0px',paddingLeft:'0px'}}>
                            <div className="box-head">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>SCANNED PRODUCTS</label>
                                </div>
                                <div className="col-md-6 text-right">
                                  <ReactHTMLTableToExcel
                                    id="stock-calculator-scanned-list-xls-button"
                                    className="btn btn-success btn-sm pull-right"
                                    table="stock-calculator-scanned-list"
                                    filename="stock-calculator-scanned-list"
                                    sheet="stock-calculator-scanned-list"
                                    buttonText="Download as XLS"/>
                                </div>
                              </div>
                            </div>
                            <div className="box-body">
                            <table className="table" id="stock-calculator-scanned-list">
                                <thead>
                                  <th>Item Name</th>
                                  <th className="text-center">Selling Price</th>
                                  <th className="text-right">Quantity</th>
                                </thead>
                                <tbody>
                                  {this.state.scanned_list.map((value,key) =>(
                                    <>
                                    {(value!==null && value.item_name!==undefined) &&
                                    <tr>
                                      <td><span>{value.item_name}</span><span style={{color: '#8f8f8f', fontSize: '18px'}}> | </span><span style={{fontSize: '12px'}}>{value.barcode_name}</span></td>
                                      <td className="text-center"> <span>₹{value.selling_price}</span></td>
                                      <td className="text-right"><span>{value.quantity}</span></td>
                                    </tr>
                                    }
                                    </>
                                  ))}
                                  {(this.state.scanned_list.length===0) &&
                                    <tr>
                                      <td className="text-center" colSpan={3}>Please Scan or choose item</td>
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}