import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/dancing-script";

import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class PaymentPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_cheque_payment : false            
        };
      
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }   

    componentDidMount(){
        if(this.props.print_data && this.props.print_data.payment_meta){
            let pay_meta = this.props.print_data.payment_meta;
            for (let index = 0; index < pay_meta.length; index++) {
                const element = pay_meta[index];
                if(element.cheque_no!==""){
                    this.setState({is_cheque_payment:true});
                }
                
            }
        }
        let _this = this;
        setTimeout(() => {
            _this.handlePrint();
        }, 100);
        

    }
    handlePrint(){
        if(document.getElementById("print-main-container")){
        var divContents = document.getElementById("print-main-container").innerHTML; 
        try{
            var a = window.open('', '', 'height=1000,location=no,left=250px'); 
            a.document.open(); 
            a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

            a.document.write('<style type="text/css">@page{size:A4,margin:5cm}.table{font-size:12px;font-family:initial}.table{width:100%;margin-bottom:1rem;color:#212529}.table .thead-light th{font-size:11px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table td,.table th{padding:.75rem;vertical-align:top !important}th{text-align:inherit}.table .thead-light th{white-space: nowrap;}.page-break{page-break-after: always;}.table.cell-border>tbody>tr>td, .table.cell-border>tbody>tr>th, .table.cell-border>tfoot>tr>td, .table.cell-border>tfoot>tr>th, .table.cell-border>thead>tr>td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td,.table>thead>tr>th {border-bottom: 1px solid #d7d7d7;}h4{margin-bottom:0px}p{font-weight:normal}table { page-break-inside:auto }tr{ page-break-inside:avoid; page-break-after:auto }thead { display:table-header-group }tfoot { display:table-footer-group }</style>'); 
            a.document.write('</head>'); 
            a.document.write('<body onload="window.print();">'); 
            a.document.write(divContents); 
            a.document.write('</body></html>');
            a.document.close();
        }catch{}
        this.handleClosePrint()
      }
    } 
    handleClosePrint = () =>{
        this.props.handleClosePrint();
    }
    render() {
        let total_bill_amount = 0;
        let note_amount = 0;
        let total_paid_amount = 0;
        let total_return_amount = 0;
        let total_cheque_amount = 0;
        return (
            <div id="print-main-container" style={{display:'none'}}>
                
                <div style={{padding:'10px'}}>
                    <table className="table page-break cell-border">
                        <thead>
                            <tr>
                                <th colSpan={2} style={{textTransform:'uppercase',textAlign:'center'}}>
                                    
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'25px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info)?this.props.print_data.company_info[0].company_name:''
                                        }
                                    </h2>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0]!==undefined && this.props.print_data.company_info[0].company_sub_title!=="" && this.props.print_data.company_info[0].company_sub_title!==null) &&
                                        <>
                                        <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.props.print_data.company_info[0].company_sub_title}</p>
                                        </>
                                        }
                                    
                                  
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].address!=='' && this.props.print_data.company_info[0].address!==null)?this.props.print_data.company_info[0].address:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].city!=='' && this.props.print_data.company_info[0].city!==null)?this.props.print_data.company_info[0].city:''
                                        }
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].pin_code!=='' && this.props.print_data.company_info[0].pin_code!==null )?'-'+this.props.print_data.company_info[0].pin_code:''
                                        }
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].state!=='' && this.props.print_data.company_info[0].state!==null )?' '+this.props.print_data.company_info[0].state:''
                                        }
                                        </p>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].phone!=='') &&
                                        <p style={{margin:'0px'}}>
                                       
                                            {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].phone!=="" && this.props.print_data.company_info[0].phone!==null)?this.props.print_data.company_info[0].phone:''
                                            }
                                            
                                        </p>
                                        }
                                        <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'16px',textTransform:'uppercase'}}>
                                            {(this.props.print_data && this.props.print_data.is_cheque && (this.props.print_data.is_cheque===false || this.props.print_data.is_cheque==='0')) &&
                                                <>
                                                    {this.props.print_data.payment_mode} Receipt
                                                </>
                                            }
                                            {(this.props.print_data && this.props.print_data.is_cheque && (this.props.print_data.is_cheque===true || this.props.print_data.is_cheque==='1')) &&
                                                <>PAYMENT VOUCHER</>
                                            }
                                        </h2>
                                </th>
                            </tr>
                            <tr>
                                <th style={{textTransform:'uppercase',textAlign:'left',width:'50%'}}>
                                    <h2 style={{margin:'0px',fontSize:'14px'}}>
                                    {(this.props.print_data && this.props.print_data.payment_type && (this.props.print_data.payment_type==='IN')) &&
                                        <>FROM :</>
                                    }
                                    {(this.props.print_data && this.props.print_data.payment_type && (this.props.print_data.payment_type==='OUT')) &&
                                        <>TO :</>
                                    }
                                    </h2>
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'14px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info)?this.props.print_data.contact_info[0].name:''
                                        }
                                    </h2>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].address_1!=='' && this.props.print_data.contact_info[0].address_1!==null)?this.props.print_data.contact_info[0].address_1:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].city!=='' && this.props.print_data.contact_info[0].city!==null)?this.props.print_data.contact_info[0].city:''
                                        }
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].pin!=='' && this.props.print_data.contact_info[0].pin!==null )?'-'+this.props.print_data.contact_info[0].pin:''
                                        }
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].state!=='' && this.props.print_data.contact_info[0].state!==null )?' '+this.props.print_data.contact_info[0].state:''
                                        }
                                        </p>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].gst_number!=='' && this.props.print_data.contact_info[0].gst_number!==null ) && 
                                            <p>GSTIN - {this.props.print_data.contact_info[0].gst_number}</p>
                                        }
                                </th>
                                <th>
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'14px'}}>
                                      DOC NO. {(this.props.print_data && this.props.print_data.payment_number)?this.props.print_data.payment_number:''
                                        }
                                    </h2>
                                    <p style={{margin:'0px'}}>
                                       ISSUED ON : {(this.props.print_data && this.props.print_data.payment_date)?this.props.print_data.payment_date:''
                                        }
                                    </p>
                                    <p style={{margin:'0px'}}>
                                       ISSUED BY : {(this.props.issued_by)?this.props.issued_by:''
                                        }
                                    </p>
                                    <h2 style={{margin:'0px',fontSize:'14px'}}>
                                        {(this.props.print_data && this.props.print_data.account_name && this.props.print_data.status!==6)?this.props.print_data.account_name:(this.props.print_data && this.props.print_data.account_name && this.props.print_data.status===6)?'Cancelled':'-'}
                                    </h2>
                                </th>
                            </tr>
                        </thead>
                       <tbody>
                       <tr>
                            <th colSpan={2} style={{textAlign:'center'}}>
                                <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                {(this.props.print_data && this.props.print_data.payment_type && (this.props.print_data.payment_type==='IN')) &&
                                    <>RECEIVED </>
                                }
                                {(this.props.print_data && this.props.print_data.payment_type && (this.props.print_data.payment_type==='OUT')) &&
                                    <>PAID </>
                                }
                                 AMOUNT : {(this.props.print_data && this.props.print_data.paid_amount)?this.currency_format(this.props.print_data.paid_amount.replace(/,/g , "")):0}
                                </h2>
                                <p style={{textTransform:'uppercase', margin:'0px'}}>
                                    {this.convert_number_to_words(this.props.print_data.paid_amount.replace(/,/g , ""))} only
                                </p>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2}>
                                <h2 style={{fontSize: '18px', fontWeight: 'bold', margin: '0'}}>Invoice List</h2>
                            </th>
                        </tr>
                        {(this.props.print_data.payment_option==='billwise') &&
                        <>
                        <tr>
                            <td colSpan={2} style={{borderBottom:'none'}}>
                            <table style={{width:'100%'}} className="table">
                                <thead>
                                <tr><th colSpan={4}>BILLS</th></tr>
                                <tr>
                                    <th scope="col">BILL NO</th>
                                    <th scope="col">REF. NO.</th>
                                   {/*  <th scope="col">REF. NO.</th>
                                    {(this.state.is_cheque_payment) &&
                                        <>
                                            <th scope="col">CHQ NO.</th>
                                            <th scope="col">CHQ DATE</th>
                                        </>
                                    } */}
                                    <th scope="col" style={{textAlign:'right'}} colSpan={2}>TOTAL</th>
                                    {/* <th scope="col">BALANCE</th> */}
                                </tr>
                                </thead>
                                <tbody>
                                {(this.props.print_data && this.props.print_data.payment_meta.length > 0) ?this.props.print_data.payment_meta.map( (value, index) => {
                                    if(value.target!=='payment' && value.status!==9 && value.status!==10 && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')){
                                        total_bill_amount = total_bill_amount + parseFloat(value.invoice_info.grand_total.replace(/,/g , ""));
                                       }
                                        return (
                                            <>
                                            {(value.target!=='payment' && value.status!==9 && value.status!==10 && value.target!=='credit_note' && value.target!=='debit_note' && (value.target!=='general' || value.is_old_balance==='Opening Balance')) &&
                                                <tr key={index} data-id={index}>
                                                    <td>
                                                    {(value.bill_invoice_id!==0) &&
                                                        <>
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="") &&
                                                            <>{value.bill_invoice_no}</>
                                                        }

                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                            <span>{value.dispatched_info.supplier_ref_no}</span>
                                                        }
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                                            <span>{value.invoice_info.supplier_ref_no}</span>
                                                        }
                                                        </>
                                                    } 
                                                    {(value.bill_invoice_id===0) &&
                                                        <>
                                                        {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                                        </>
                                                    }
                                                    
                                                    </td>
                                                    <td>
                                                        {value.refrence_no}
                                                       
                                                    </td> 
                                                {/*  {(this.state.is_cheque_payment) &&
                                                        <>
                                                            <td>
                                                                {value.cheque_no}
                                                            </td> 
                                                            <td>
                                                                {value.cheque_date}
                                                            </td> 
                                                        </>
                                                    } */}
                                                    <td  style={{textAlign:'right'}} colSpan={2}>
                                                    {(value.invoice_info && value.invoice_info.grand_total) &&
                                                            <p style={{margin: '0px'}}>
                                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                                            </p>
                                                        }
                                                    </td> 
                                                    {/* <td>
                                                    ₹{(value.pending_amount!==null)?
                                                        this.currency_format(value.pending_amount.replace(/,/g , ""))
                                                        :0
                                                        }
                                                    </td> */}
                                                </tr>
                                            }
                                            </>
                                        )
                                        }) : <tr><td colSpan="4" className="text-center"></td></tr> }
                                </tbody>
                                <tfoot>
                                <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th style={{textAlign:'right'}}>₹{utils.currency_format(total_bill_amount)}</th>
                                </tr>
                                </tfoot>
                            </table>
                            </td>
                        </tr>
                        {(this.props.print_data && this.props.print_data.dr_cr_info_arr && this.props.print_data.dr_cr_info_arr.length>0) &&
<tr>
                            <td colSpan={2} style={{borderBottom:'none',borderTop:'none'}}>
                               
                                <table style={{width:'100%'}} className="table">
                                    <thead>
                                        <tr>
                                            <th>Note Information</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">NOTE NO</th>
                                            <th scope="col">REASON</th>
                                            <th scope="col">DATE</th>
                                            <th scope="col" style={{textAlign:'right'}}>AMOUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.props.print_data && this.props.print_data.dr_cr_info_arr && this.props.print_data.dr_cr_info_arr.length>0) &&
                                        <>
                                            {(this.props.print_data.dr_cr_info_arr.map((value,idx)=>{
                                                note_amount = note_amount + parseFloat(value.amount);
                                                return (
                                                <tr>
                                                    <td>
                                                    <span>{value.sequence}</span>
                                          
                                                    {(value.bill_info && value.bill_info.bill_no) &&
                                                        <>
                                                        <span style={{color: '#a9a9a9'}}> (</span>
                                                        <span>{value.bill_info.bill_no} </span>

                                                        {(value.bill_info.dispatched_details && value.bill_info.dispatched_details.supplier_ref_no!=="") &&
                                                            <span style={{color: '#a9a9a9'}}> | {value.bill_info.dispatched_details.supplier_ref_no}</span>
                                                        }
                                                        <span style={{color: '#a9a9a9'}}>) </span>
                                                        </>
                                                    } 
                                                    </td>
                                                    <td>{value.reason}</td>
                                                    <td>{value.created_date}</td>
                                                    <td style={{textAlign:'right'}}>₹{this.currency_format(value.amount)}</td>
                                                </tr>
                                                )
                                            }))}
                                        </>
                                    }
                                    
                                    </tbody>
                                    {(note_amount>0) &&
                                        <tfoot>
                                            <tr>
                                                <th colSpan={4} style={{textAlign:'right'}}>₹{this.currency_format(note_amount)}</th>
                                            </tr>
                                        </tfoot>
                                    }
                                </table>
                                
                            </td>
                        </tr>
}
                        <tr>
                            <td colSpan={2} style={{borderBottom:'none',borderTop:'none'}}>
                               
                                <table style={{width:'100%'}} className="table">
                                    <thead>
                                        <tr>
                                            <th>Return Information</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">BILL NO</th>
                                            <th scope="col">DATE</th>
                                            <th scope="col" style={{textAlign:'right'}}>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.props.print_data && this.props.print_data.return_bill_data && this.props.print_data.return_bill_data.length>0) &&
                                        <>
                                            {(this.props.print_data.return_bill_data.map((value,idx)=>{
                                                total_return_amount = total_return_amount + parseFloat(value.paid_amount);
                                                return (
                                                <tr>
                                                    <td>
                                                    <span>{value.return_goods}</span>
                                          
                                                    {(value.bill_info && value.bill_info.bill_no) &&
                                                        <>
                                                        <span style={{color: '#a9a9a9'}}> (</span>
                                                        <span>{value.bill_info.bill_no} </span>

                                                        {(value.bill_info.dispatched_info && value.bill_info.dispatched_info.supplier_ref_no!=="") &&
                                                            <span style={{color: '#a9a9a9'}}> | {value.bill_info.dispatched_info.supplier_ref_no}</span>
                                                        }
                                                        <span style={{color: '#a9a9a9'}}>) </span>
                                                        </>
                                                    } 
                                                    </td>
                                                    <td>{value.created_on}</td>
                                                    <td style={{textAlign:'right'}}>₹{this.currency_format(value.paid_amount)}</td>
                                                </tr>
                                                )
                                            }))}
                                        </>
                                    }
                                    {(this.props.print_data && this.props.print_data.payment_meta) &&
                                    <>
                                        {(this.props.print_data.payment_meta.map((value,idx)=>{
                                            if(value.status===9 || value.status===10){
                                                total_return_amount = total_return_amount +  parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                            }
                                            return (
                                                <>
                                                    {(value.status===9 || value.status===10) &&
                                                        <tr>
                                                            <td>{value.bill_invoice_no}</td>
                                                            <td>{this.props.print_data.payment_date}</td>
                                                            <td style={{textAlign:'right'}}>₹{this.currency_format(value.total_paid_amount)}</td>
                                                        </tr>
                                                    }
                                                </>
                                            )
                                        }))}
                                    </>
                                    }
                                    {(total_return_amount===0) &&
                                        <tr>
                                            <th colSpan={3} style={{textAlign:'center'}}>No Return Information Found</th>
                                        </tr>
                                    }
                                    </tbody>
                                    {(total_return_amount>0) &&
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3} style={{textAlign:'right'}}>₹{this.currency_format(total_return_amount)}</th>
                                            </tr>
                                        </tfoot>
                                    }
                                </table>
                                
                            </td>
                        </tr>
                            <tr>
                            <td colSpan={2}  style={{borderBottom:'none',borderTop:'none'}}>
                            <table style={{width:'100%'}} className="table">
                                <thead>
                                <tr><th colSpan={4}>Payments</th></tr>
                                <tr>
                                    <th scope="col">VOUCHER NO</th>
                                    <th scope="col">REF NO</th>
                                    <th scope="col" style={{textAlign:'right'}} colSpan={2}>TOTAL</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                {(this.props.print_data && this.props.print_data.payment_meta.length > 0) ?this.props.print_data.payment_meta.map( (value, index) => {
                                    if(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance' && value.status!==9 && value.status!==10)){
                                        total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                       }
                                        return (
                                        <>
                                            {(value.target==='payment' || value.target==='credit_note' || value.target==='debit_note' || (value.target==='general' && value.is_old_balance!=='Opening Balance' && value.status!==9 && value.status!==10)) &&
                                                <tr key={index} data-id={index}>
                                                    <td>
                                                    {(value.bill_invoice_id!==0) &&
                                                        <>
                                                        {value.bill_invoice_no}
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                            <span> | {value.dispatched_info.supplier_ref_no}</span>
                                                        }
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                                            <span> | {value.invoice_info.supplier_ref_no}</span>
                                                        }
                                                        </>
                                                    } 
                                                    {(value.bill_invoice_id===0 && value.is_old_balance!=='Adjusted Payment') &&
                                                        <>
                                                        {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                                        </>
                                                    }
                                                    {(value.bill_invoice_id===0 && value.is_old_balance==='Adjusted Payment') &&
                                                        <>
                                                        {(this.props.print_data && this.props.print_data.payment_number)?this.props.print_data.payment_number:''}
                                                        </>
                                                    }
                                                    </td>
                                                    <td>
                                                        {value.refrence_no}
                                                    </td> 
                                                   
                                                    <td  style={{textAlign:'right'}} colSpan={2}>
                                                    ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                                    </td> 
                                                    {/* <td>
                                                    ₹{(value.pending_amount!==null)?
                                                        this.currency_format(value.pending_amount.replace(/,/g , ""))
                                                        :0
                                                        }
                                                    </td> */}
                                                </tr>
                                            }
                                        </>
                                        )
                                        }) : <tr><td colSpan="4" className="text-center"></td></tr> }
                                </tbody>
                                <tfoot>
                                    <tr>
                                    
                                    <th></th>
                                    <th></th>
                                    
                                    <th scope="col"></th>
                                    <th scope="col" style={{textAlign:'right'}}>₹{utils.currency_format(total_paid_amount)}</th>
                                    </tr>
                                </tfoot>
                            </table>
                            </td>
                        </tr>
                        </>
                        }
                        
                        {(this.props.print_data.payment_option!=='billwise') &&
                            <tr>
                            <td colSpan={2} style={{borderBottom:'none'}}>
                            <table style={{width:'100%'}} className="table">
                                <thead>
                                <tr><th colSpan={3}>Payments</th></tr>
                                <tr>
                                    <th scope="col">BILL NO</th>
                                    <th scope="col">BILL VALUE</th>
                                   
                                    <th scope="col" style={{textAlign:'right'}}>TOTAL</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                {(this.props.print_data && this.props.print_data.payment_meta.length > 0) ?this.props.print_data.payment_meta.map( (value, index) => {
                                   
                                        total_paid_amount = total_paid_amount + parseFloat(value.total_paid_amount.replace(/,/g , ""));
                                       
                                        return (
                                        <>
                                           
                                                <tr key={index} data-id={index}>
                                                    <td>
                                                    {(value.bill_invoice_id!==0) &&
                                                        <>
                                                        {value.bill_invoice_no}
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                            <span> | {value.dispatched_info.supplier_ref_no}</span>
                                                        }
                                                        {(value.dispatched_info && value.dispatched_info.supplier_ref_no==="" && value.invoice_info && value.invoice_info.supplier_ref_no && value.invoice_info.supplier_ref_no!=="") &&
                                                            <span> | {value.invoice_info.supplier_ref_no}</span>
                                                        }
                                                        </>
                                                    } 
                                                    {(value.bill_invoice_id===0) &&
                                                        <>
                                                        {(value.is_old_balance!==null && value.is_old_balance!=="")?value.is_old_balance:'Advance'}
                                                        </>
                                                    }
                                                    
                                                    </td>
                                                    <td>
                                                        {/* {value.refrence_no} */}
                                                        {(value.invoice_info && value.invoice_info.grand_total) &&
                                                            <p style={{margin: '0px'}}>
                                                            ₹{this.currency_format(value.invoice_info.grand_total.replace(/,/g , ""))}
                                                            </p>
                                                        }
                                                    </td> 
                                                   
                                                    <td  style={{textAlign:'right'}}>
                                                    ₹{this.currency_format(value.total_paid_amount.replace(/,/g , ""))}
                                                    </td> 
                                                    {/* <td>
                                                    ₹{(value.pending_amount!==null)?
                                                        this.currency_format(value.pending_amount.replace(/,/g , ""))
                                                        :0
                                                        }
                                                    </td> */}
                                                </tr>
                                            
                                        </>
                                        )
                                        }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                                </tbody>
                                <tfoot>
                                    <tr>
                                    
                                    <th></th>
                                    <th></th>
                                   
                                    <th scope="col" style={{textAlign:'right'}}>₹{utils.currency_format(total_paid_amount)}</th>
                                    <th scope="col"></th>
                                    </tr>
                                </tfoot>
                            </table>
                            </td>
                        </tr>
                        }
                        {(this.props.print_data.adjusted_data.length > 0) &&
                        <>
                        <tr>
                            <th colSpan={2}>
                                <h2 style={{fontSize: '18px', fontWeight: 'bold', margin: '0'}}>Adjusted in Bills</h2>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{borderBottom:'none'}}>
                                <table className="table" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                <th scope="col">INVOICE #</th>
                                                <th scope="col">ADJUSTED DATE</th>
                                                <th scope="col">AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody id="items-body">
                                                {(this.props.print_data.adjusted_data.length > 0) ?this.props.print_data.adjusted_data.map( (value, index) => {
                                                
                                                return (
                                                <tr>
                                                    <td>
                                                        {value.sequence}
                                                    </td>
                                                    <td>
                                                    {value.adjusted_date}
                                                    </td> 
                                                    <td>
                                                    ₹{this.currency_format(value.amount)}
                                                    </td>
                                                </tr>
                                                )
                                                }) : <tr><td colSpan="3" className="text-center"></td></tr> }
                                            </tbody>
                                </table>
                            </td>
                        </tr>
                        </>
                        }
                        <tr>
                            <td colSpan={2} style={{borderBottom:'none',borderTop:'none'}}>
                                {(this.props.print_data && this.props.print_data.cheque_info && this.props.print_data.cheque_info.length>0) &&
                                <table style={{width:'100%'}} className="table">
                                    <thead>
                                        <tr>
                                            <th>Cheque Information</th>
                                        </tr>
                                        <tr>
                                            <th>Cheque No</th>
                                            <th>Cheque Date</th>
                                            <th>Bank Name</th>
                                            <th style={{textAlign:'right'}}>Cheque Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.props.print_data.cheque_info.map((value,idx)=>{
                                            total_cheque_amount = total_cheque_amount + value.amount;
                                            return (
                                            <tr>
                                                <td>{value.cheque_no}</td>
                                                <td>{value.date}</td>
                                                <td>{value.cheque_bank_name}</td>
                                                <td style={{textAlign:'right'}}>₹{this.currency_format(value.amount)}</td>
                                            </tr>
                                            )
                                        }))}
                                    </tbody>
                                    <tfoot>
                                        <tr><th colSpan={4} style={{textAlign:'right'}}>₹{this.currency_format(total_cheque_amount)}</th></tr>
                                    </tfoot>
                                </table>
                                }
                            </td>
                        </tr>
                       
                        <tr>
                            <td style={{border:'none'}}>
                                {(this.props.print_data && this.props.print_data.notes)?this.props.print_data.notes:''}
                            </td>
                            <td  style={{borderBottom:'none',borderTop:'none'}}>
                            {/* <table className="table billing-total">
                              <tbody>
                                  <tr>
                                      <td><span className="sp-normal">Paid Amount</span></td>
                                      <td className="text-right">
                                        <span className="sp-normal" id="total-balance">₹{(this.props.print_data && this.props.print_data.paid_amount)?this.currency_format(this.props.print_data.paid_amount.replace(/,/g , "")):0}</span>
                                      </td>
                                  </tr>
                                  {(this.props.print_data && this.props.print_data.global_discount!==0 && this.props.print_data.global_discount!=="0" && this.props.print_data.global_discount!=="") &&
                                    <tr>
                                        <td>
                                          <span className="sp-normal">
                                          Discount
                                          {(this.props.print_data.global_discount_sym==='per') &&
                                            <>
                                               <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.props.global_discount_amount)}
                                               </span>
                                            </>
                                          }
                                          </span>
                                        </td>
                                        <td className="text-right">
                                           
                                          <span className="sp-normal">
                                            {(this.props.print_data && this.props.print_data.global_discount) &&
                                              <>
                                                {(this.props.print_data.global_discount_sym==='rs') &&
                                                <>
                                                <span className="rs-sym">₹</span>{this.props.print_data.global_discount}
                                                </>
                                                }
                                                {(this.props.print_data.global_discount_sym==='per') &&
                                                <>
                                                 {this.props.print_data.global_discount}%
                                                 </>
                                                }
                                              </>
                                            }
                                          </span>

                                        </td>
                                    </tr>
                                  }
                                  {(this.props.print_data.round_off>0) &&
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="text-right">
                                            <>
                                            {(this.props.print_data.round_off>0) &&
                                            <>
                                            {this.props.print_data.round_off_sym}{this.props.print_data.round_off}
                                            </>
                                            }
                                            {(this.props.print_data.round_off===0) &&
                                            0
                                            }
                                            </>
                                        </td>
                                    </tr>
                                    }
                              </tbody>
                            </table> */}
                            </td>
                        </tr>
                       </tbody>
                    </table>
                </div>
            </div>
        );
    }
}