import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ModalBox from "./modal-box";
import BarcodeTemplate from "./settings/barcode-template";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import ReactWhatsapp from 'react-whatsapp';
import ClosedFYButton from "./closed-fy-button";
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import utils from '../utils.js';
import check_access from './access-level.js';
import DrawerLink from "./open-drawer-link";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
export default class VeiwNoHSNData extends Component {
    constructor() {
        super();
        this.state = {
           
        };
       
        this.is_access = check_access.is_access.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }
    render() {
        
        return (
            <>
            <div>
              <div className="view-on-fly" id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                  </div>
                </nav>
                <div id="main-container">
                <div className="container-fluid">
                
                  <div className="content-area">
                     {(this.props.data.length > 0) &&
                     <div className="table-responsive">
                     <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NO HSN FOUND INVOICE(S)</th>
                          </tr>
                        </thead>
                        <tbody id="items-body">
                            {(this.props.data.map((value)=>{
                                return(
                                    <tr>
                                        <td>
                                          <DrawerLink target="billing" url={value.url} name={value.invoice_number} schema={value.sname}/>
                                        </td>
                                        
                                    </tr>
                                )
                            }))}
                        </tbody>
                      </table>
                      </div>
                    }
                     
                  </div>
                </div>
              </div>
              </div>
            </div>
          </>
        );
    }
}
