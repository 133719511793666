import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
export default class ServiceLoader extends Component {
    constructor() {
      super();
      this.state = {

      };
      
    }
    componentDidMount(){
      
    }
    render() {
        return (
          <>
          {(this.props.show===true) &&
            <div className="service-loader">
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
            </div>
          }
          </>
        );
    }
}