import React, {Component} from "react";
import axios from 'axios';
import utils from '../utils.js';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
export default class PurchaseCustomFields extends Component {
    constructor() {
        super();
        this.state = {
            show_inventory_custom_field : false,
            custom_fields_json : []
        }
    }
    componentDidMount(){
        let keys = "'show_inventory_custom_field','inventory_custom_fields'";
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            keys:keys
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.show_inventory_custom_field!==undefined){
              let show_inventory_custom_field = resp.show_inventory_custom_field;
              this.setState({show_inventory_custom_field:show_inventory_custom_field});
            }
            if(resp.inventory_custom_fields!==undefined){
              let inventory_custom_fields = resp.inventory_custom_fields;
              this.setState({custom_fields_json:inventory_custom_fields});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    render() {

        let custom_fields_json = [];
        if(this.props.custom_fields_json && this.props.custom_fields_json.length>0){
            let cf_data = this.props.custom_fields_json;
            for (let index = 0; index < cf_data.length; index++) {
                const element = cf_data[index];
                let id = element.id;
                custom_fields_json[id] = element;
            }
        }

        return (
            <>
                {(this.state.custom_fields_json.length>0 && this.state.show_inventory_custom_field===true) &&
                    <>
                        {this.state.custom_fields_json.map((value,index) => {
                            return (
                                <>
                                {((this.props.target==='New Purchase' && (value.is_purchase_active===true || value.is_purchase_active==="true"))) &&
                                    <div className={"col-md-1 w-50 "+this.props.cls}>
                                        <span className="fe-title">{value.name}</span>
                                        <input type="text" className={"form-control "+this.props.prefix+"-custom-field"} id={this.props.prefix+"-custom-field-"+value.id} data-id={index} data-target={this.props.prefix+"-custom-field"} tabindex={parseInt(this.props.tabindex)+index}/>

                                        <input type="hidden" value={value.name} id={this.props.prefix+"-cf-name-"+index}/>
                                        <input type="hidden" value={value.id} id={this.props.prefix+"-cf-id-"+index}/>
                                        <input type="hidden" value={value.is_sale_active} id={this.props.prefix+"-cf-is-sale-active-"+index}/>
                                        <input type="hidden" value={value.is_purchase_active} id={this.props.prefix+"-cf-is-purchase-active-"+index}/>
                                    </div>
                                }
                                {((this.props.target==='New Sale' && (value.is_sale_active===true || value.is_sale_active==="true"))) &&
                                    <>
                                        <div className={"col-md-1 w-50 "+this.props.cls}>
                                            <span className="fe-title">{value.name}</span>
                                            <input type="text" className={"form-control "+this.props.prefix+"-custom-field"} id={this.props.prefix+"-custom-field-"+value.id} data-id={index} data-target={this.props.prefix+"-custom-field"} tabindex={parseInt(this.props.tabindex)+index}/>

                                            <input type="hidden" value={value.name} id={this.props.prefix+"-cf-name-"+index}/>
                                            <input type="hidden" value={value.id} id={this.props.prefix+"-cf-id-"+index}/>
                                            <input type="hidden" value={value.is_sale_active} id={this.props.prefix+"-cf-is-sale-active-"+index}/>
                                            <input type="hidden" value={value.is_purchase_active} id={this.props.prefix+"-cf-is-purchase-active-"+index}/>
                                        </div>
                                    </>
                                }

                                {((this.props.target==='cf-th-headers' && (value.is_sale_active===true || value.is_sale_active==="true"))) &&
                                <>
                                    <th>{value.name}</th>
                                </>
                                }
                                    {((this.props.target==='cf-fields' && (value.is_sale_active===true || value.is_sale_active==="true"))) &&
                                    <>
                                        <td style={{minWidth:'130px'}}>
                                            <input type="text" className={"form-control "+this.props.prefix+"-custom-field-"+this.props.cls} id={this.props.prefix+"-custom-field-"+value.id} data-id={index} data-target={this.props.prefix+"-custom-field"} tabindex={parseInt(this.props.tabindex)+index} defaultValue={(custom_fields_json[value.id]!==undefined)?custom_fields_json[value.id].value:''}/>

                                            <input type="hidden" value={value.name} id={this.props.prefix+"-cf-name-"+index}/>
                                            <input type="hidden" value={value.id} id={this.props.prefix+"-cf-id-"+index}/>
                                            <input type="hidden" value={value.is_sale_active} id={this.props.prefix+"-cf-is-sale-active-"+index}/>
                                            <input type="hidden" value={value.is_purchase_active} id={this.props.prefix+"-cf-is-purchase-active-"+index}/>
                                        </td>
                                    </>
                                }
                                </>
                            )
                        })}
                    </>
                }
            </>
        );
    }
}