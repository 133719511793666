import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faSpinner, faTimes  } from '@fortawesome/free-solid-svg-icons';
import utils from '../../utils.js';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ContactsDropdown from "../contacts-dropdown";
import DrawerLink from "../open-drawer-link";
import PaymentPopup from "../payment-popup";
import Link from '../link.js';
import Pager from "../pager";
import Tooltip from "../tooltip";
let {enc_key,app_api,user_info} = require('../../library.js');
export default class AdjustUnpaidBills extends Component {
    constructor() {
        super();
        this.state = {
            update_id : 0,
            total_pending_amount : 0,
            total_adjusted_amount : 0,
            is_show_adjust_section:false,
            load_type : 'all',
            payments_data:[],
            other_data:[],
            pending_bills:[],
            pending_loader : 'Please select contact to view the unpaid bills to adjust',
            is_empty_item : false,
            is_pending_empty : false,
            is_pending_clicked : false,
            is_payments_clicked : false,
            is_payments_empty : false,
            is_others_clicked : false,
            is_others_empty : false,
            is_open_summary : false,
            all_page : 1,
            pending_page : 1,
            payments_page : 1,
            others_page : 1,
            selected_pending_bills : [],
            selected_adjustable_bills : [],
            total_pending_amount_selected : 0,
            total_payments_amount_selected : 0,
            total_other_amount_selected : 0,
            total_pending_payments : 0,
            total_pending_payments_others : 0,
            total_other_charges_amount : 0,
            adjusted_meta_data : [],
            adjusted_meta_data_tmp : [],
            adjusted_page : 1,
            is_show_payment : false,
            new_payments_data :{},
            contact_type : '',
            loading_process : 'Loading...',
            total_page : 1,
            is_more_pending : true,
            is_more_payments : true,
            is_more_others : true,
            is_edit : false,
            removedItem : [],
        };
     
        this.floating_point = utils.floating_point.bind(this);
    }

    componentDidMount(){
      let curl = window.location.href;
      const page_tab = curl.match(/payments\/adjust_unpaid_bills\/(\d+$)/i);
      let page = 1;
      if(page_tab && page_tab[1]!==undefined){
        page = page_tab[1];
      }
      this.hanldeLoadAdjustedList(page);
    }

    hanldeLoadAdjustedList = (page)=>{
      let adjmdata = this.state.adjusted_meta_data;
      let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : page,
           }
        let api = app_api+'/get_adjusted_list';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.adjusted_list;
              let total_page = response.data.number_of_page;
              this.setState({total_page:total_page});
              if(page==1 && resp.length===0){
                this.setState({loading_process:'Oops ! No record found'});
              }
              this.setState({adjusted_page:page+1});
              for (let index = 0; index < resp.length; index++) {
                const element = resp[index];
                adjmdata.push(element);
              }
              this.setState({adjusted_meta_data:adjmdata});
              this.setState({adjusted_meta_data_tmp:adjmdata});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }

    handleLoadAdjustableBills = (page,load_type,sub_type) =>{
      let customer = localStorage.getItem("selected_contact");
      let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              id : customer,
              page : page,
              load_type : load_type,
              sub_type : sub_type,
           }
        let api = app_api+'/get_adjustable_bills';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let contact_type = resp.contact_type;
              this.setState({contact_type:contact_type});
              if(resp.pending_bills){
                this.setState({pending_page : page+1});
               
                let pending_bills = resp.pending_bills;
                if(pending_bills.length<30){
                  this.setState({is_more_pending:false});
                }
                let pbill_arr = this.state.pending_bills;
                for (let index = 0; index < pending_bills.length; index++) {
                  const element = pending_bills[index];
                  pbill_arr.push(element);
                }
                this.setState({pending_bills:pbill_arr});
                if(pending_bills.length===0){
                  this.setState({is_pending_empty:true});
                }
                this.setState({is_pending_clicked:false});
              }

              if(resp.payments_data){
                this.setState({payments_page : page+1});
                let payments_data = resp.payments_data;
                if(payments_data.length<30){
                  this.setState({is_more_payments:false});
                }
                let payments_arr = this.state.payments_data;
                for (let index = 0; index < payments_data.length; index++) {
                  const element = payments_data[index];
                  payments_arr.push(element);
                }
                this.setState({payments_data:payments_arr});
                if(payments_data.length===0){
                  this.setState({is_payments_empty:true});
                }
                this.setState({is_payments_clicked:false});
              }
              if(resp.other_data){
                this.setState({others_page : page+1});
                let other_data = resp.other_data;
                if(other_data.length<30){
                  this.setState({is_more_others:false});
                }
                let others_arr = this.state.other_data;
                for (let index = 0; index < other_data.length; index++) {
                  const element = other_data[index];
                  others_arr.push(element);
                }
                this.setState({other_data:others_arr});
                if(other_data.length===0){
                  this.setState({is_others_empty:true});
                }
                this.setState({is_others_clicked:false});
              }
              
              if(load_type==='all'){
                if(resp.pending_bills.length===0){
                  this.setState({pending_loader:'No Pending Invoice Found.'});
                }else{
                  this.setState({is_empty_item:false});
                }
              }

              setTimeout(function(){
                  let qs = document.querySelectorAll(".payment-item-row");
                  for(let i=0;i<qs.length;i++){
                      let did = qs[i].getAttribute('data_id');
                      let iname = document.getElementById("item-box-"+did).value;
                      if(document.getElementById("pending-bill-"+iname)){
                        document.getElementById("pending-bill-"+iname).classList.add('hide');
                      }
                  }
              },100);
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }

    handleLoadMoreBills = (e) =>{
      let load_type = e.currentTarget.getAttribute('data-target');
      let sub_type = e.currentTarget.getAttribute('data-id');
      let page = 0;
      if(load_type==='pending'){
        page = this.state.pending_page;
        this.setState({is_pending_clicked:true});
      }else if(load_type==='adjustable'){
        if(sub_type=='payments'){
          page = this.state.payments_page;
          this.setState({is_payments_clicked:true});
        }else{
          page = this.state.others_page;
          this.setState({is_others_clicked:true});
        }
      }
      this.handleLoadAdjustableBills(page,load_type,sub_type);
    }

    handleLoadUnAdjustedBills = (e)=>{
        this.setState({payments_data:[]});
        this.setState({pending_bills:[]});
        this.setState({other_data:[]});
        this.setState({pending_loader:'Loading...'});
        this.setState({is_pending_empty:false});
        this.setState({is_pending_clicked:false});
        this.setState({is_payments_clicked:false});
        this.setState({is_payments_empty:false});
        this.setState({is_others_clicked:false});
        this.setState({is_others_empty:false});
        this.setState({all_page:false});
        this.setState({pending_page:1});
        this.setState({payments_page:1});
        this.setState({others_page:1});
        this.handleLoadAdjustableBills(1,'all','all');
      }
      handlePendingBillCheck = (e) =>{
        let pending_amount = this.state.total_pending_amount_selected;
        let pqs = document.querySelectorAll('.unpaid-check');
        
        for (let index = 0; index < pqs.length; index++) {
          const element = pqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            pending_amount = pending_amount + thisvalue;
          }
        }
        this.setState({total_pending_amount:pending_amount});
      }
      handleAdjustedBillCheck = (e) =>{
        let adjusted_amount = 0;
        let pqs = document.querySelectorAll('.payments-check');
        
        for (let index = 0; index < pqs.length; index++) {
          const element = pqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            adjusted_amount = adjusted_amount + thisvalue;
          }
        }
        let oqs = document.querySelectorAll('.others-check');
        
        for (let index = 0; index < oqs.length; index++) {
          const element = oqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            adjusted_amount = adjusted_amount + thisvalue;
          }
        }
        this.setState({total_adjusted_amount:adjusted_amount});
      }
      
      handleAdjustBill = (e) =>{
        let pending_bills = this.state.pending_bills;
        let payments_data = this.state.payments_data;
        let other_data = this.state.other_data;
        let thisid = e.currentTarget.id;
        let remaining_amount = this.state.total_pending_payments_others - this.state.total_other_charges_amount;
        if(remaining_amount>0 && this.state.is_show_payment===false){
          this.setState({is_show_payment:true});
          return false;
        }
        this.setState({is_show_payment:false});
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let penqs = document.querySelectorAll('.unpaid-check');
        let payqs = document.querySelectorAll('.payments-check');
        let otherqs = document.querySelectorAll('.others-check');
        let selected_pending_bills = [];
        for (let index = 0; index < penqs.length; index++) {
          const element = penqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let pbills = pending_bills[data_id];
            selected_pending_bills.push(pbills);
          }
        }

        let selected_adjustable_bills = [];
        for (let index = 0; index < payqs.length; index++) {
          const element = payqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let adbills = payments_data[data_id];
            selected_adjustable_bills.push(adbills);
          }
        }
        for (let index = 0; index < otherqs.length; index++) {
          const element = otherqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let adbills = other_data[data_id];
            selected_adjustable_bills.push(adbills);
          }
        }

        if(this.state.new_payments_data && this.state.new_payments_data.target!==undefined){
          let new_payment_data = this.state.new_payments_data;
          selected_adjustable_bills.push(new_payment_data);
        }

        let customer = localStorage.getItem("selected_contact");
        let send_data = {
          update_id :this.state.update_id,
          customer : customer,
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          pending_bills : selected_pending_bills,
          adjustable_bills : selected_adjustable_bills,
          total_pending_amount_selected : this.state.total_pending_amount,
          total_payments_amount_selected : this.state.total_payments_amount_selected,
          total_other_amount_selected : this.state.total_other_amount_selected,
          total_other_charges_amount : this.state.total_other_charges_amount,
          remaining_amount : remaining_amount,
          removedItem : this.state.removedItem,
        }
        
        let api = app_api+'/adjust_pending_bills';
        axios.post(api, send_data)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            window.location.reload();
        }).catch(function(res){
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
        })
      }
      handleChangeOtherCharges = (e) =>{
        let thisvalue = e.currentTarget.value;
        let other_amount = 0;
        if(thisvalue>0){
          other_amount = thisvalue;
        }
        this.setState({total_other_charges_amount:other_amount});
      }
      handleGotoNext = (e) =>{
        let pending_bills = this.state.pending_bills;
        let payments_data = this.state.payments_data;
        let other_data = this.state.other_data;
        let pending_amount = this.state.total_pending_amount_selected;
        let uqs = document.querySelectorAll('.unpaid-check');
        
        for (let index = 0; index < uqs.length; index++) {
          const element = uqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            pending_amount = pending_amount + thisvalue;
          }
        }
        this.setState({total_pending_amount_selected:pending_amount});
        let payments_amount = this.state.total_payments_amount_selected;
        let pqs = document.querySelectorAll('.payments-check');
        
        for (let index = 0; index < pqs.length; index++) {
          const element = pqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            payments_amount = payments_amount + thisvalue;
          }
        }
        this.setState({total_payments_amount_selected:payments_amount});
        this.setState({total_pending_payments:(pending_amount - payments_amount)});
        let others_amount = this.state.total_other_amount_selected;
        let oqs = document.querySelectorAll('.others-check');
        
        for (let index = 0; index < oqs.length; index++) {
          const element = oqs[index];
          let thisvalue = parseFloat(element.value);
          
          if(element.checked){
            others_amount = others_amount + thisvalue;
          }
        }
        this.setState({total_other_amount_selected:others_amount});
        this.setState({total_pending_payments_others:(pending_amount - payments_amount - others_amount)});
        /* let penqs = document.querySelectorAll('.unpaid-check');
        let payqs = document.querySelectorAll('.payments-check');
        let otherqs = document.querySelectorAll('.others-check');
        let selected_pending_bills = [];
        
        for (let index = 0; index < penqs.length; index++) {
          const element = penqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let pbills = pending_bills[data_id];
            selected_pending_bills.push(pbills);
          }
        }

        let selected_adjustable_bills = [];
        for (let index = 0; index < payqs.length; index++) {
          const element = payqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let adbills = payments_data[data_id];
            selected_adjustable_bills.push(adbills);
          }
        }
        for (let index = 0; index < otherqs.length; index++) {
          const element = otherqs[index];
          if(element.checked){
            let data_id = element.getAttribute('data-id');
            let adbills = other_data[data_id];
            selected_adjustable_bills.push(adbills);
          }
        }
        this.setState({selected_pending_bills:selected_pending_bills,selected_adjustable_bills:selected_adjustable_bills}) */
        this.setState({is_open_summary:true});
      }

      handleOpenAdjustBillSection = (e) =>{
        this.setState({is_show_adjust_section:true});
      }
      handleBackToList = (e) =>{
        this.setState({is_show_adjust_section:false});
        let admdata = this.state.adjusted_meta_data_tmp;
        this.setState({total_pending_amount : 0,
          total_adjusted_amount : 0,
          is_show_adjust_section:false,
          load_type : 'all',
          payments_data:[],
          other_data:[],
          pending_bills:[],
          pending_loader : 'Please select contact to view the unpaid bills to adjust',
          is_empty_item : false,
          is_pending_empty : false,
          is_pending_clicked : false,
          is_payments_clicked : false,
          is_payments_empty : false,
          is_others_clicked : false,
          is_others_empty : false,
          is_open_summary : false,
          all_page : 1,
          pending_page : 1,
          payments_page : 1,
          others_page : 1,
          selected_pending_bills : [],
          selected_adjustable_bills : [],
          total_pending_amount_selected : 0,
          total_payments_amount_selected : 0,
          total_other_amount_selected : 0,
          total_pending_payments : 0,
          total_pending_payments_others : 0,
          total_other_charges_amount : 0,
          adjusted_page : 1,
          is_show_payment : false,
          new_payments_data :{},
          contact_type : '',
          loading_process : 'Loading...',
          edit_adjusted_meta : [],
          contact_info : {},
          removedItem : [],
          update_id : 0,
          adjusted_meta_data : admdata
        })
      }
      handleOpenEditModel = (e) =>{
        let idx = e.currentTarget.getAttribute('index');
        let adjusted_meta = this.state.adjusted_meta_data[idx];
        let update_id = adjusted_meta.abmid;
        this.setState({update_id:update_id});
        this.setState({is_show_adjust_section:true});
        this.setState({is_edit:true});
        this.setState({edit_adjusted_meta:adjusted_meta});
        let party_info = adjusted_meta.party_info;
        let coninfo = {item_id:party_info.contact_id,item_name:party_info.name,contact_id:party_info.contact_id,contact_name:party_info.name};
        this.setState({contact_info:coninfo});

        let pending_bills = adjusted_meta.pending_bills;
        let adjusted_bills = adjusted_meta.adjusted_bills;
        let payments_data = adjusted_bills.payments;
        let others_data = adjusted_bills.others;
        let pending_amt = 0;
        for (let index = 0; index < pending_bills.length; index++) {
          const element = pending_bills[index];
          pending_amt = pending_amt + parseFloat(element.adjusted_amount);
        }
        let payment_amt = 0;
        for (let index = 0; index < payments_data.length; index++) {
          const element = payments_data[index];
          payment_amt = payment_amt + parseFloat(element.adjusted_amount);
        }
        let other_amt = 0;
        let other_charges_amt = 0;
        for (let index = 0; index < others_data.length; index++) {
          const element = others_data[index];
          let target = element.target;
          if(target!=='other_charges'){
            other_amt = other_amt + parseFloat(element.adjusted_amount);
          }else{
            other_charges_amt = other_charges_amt + parseFloat(element.adjusted_amount); 
          }
        }

        /* this.setState({total_pending_amount:pending_amt});
        this.setState({total_adjusted_amount:(other_charges_amt+other_amt+payment_amt)}); */

        this.setState({total_pending_amount:pending_amt});
        this.setState({total_pending_amount_selected:pending_amt});
        this.setState({total_payments_amount_selected:payment_amt});
        this.setState({total_other_amount_selected:other_amt});
        this.setState({total_other_charges_amount:other_charges_amt});

      }

      handleRemoveItem = (target,idx,id) =>{
        let adj_info = this.state.edit_adjusted_meta;
        let pending_bills = adj_info.pending_bills;
        let adjusted_bills = adj_info.adjusted_bills;
        if(target==='sales' || target==='purchase'){
          pending_bills.splice(idx,1);
          adj_info.pending_bills = pending_bills;
        }else{
          if(target==='payments'){
            adjusted_bills.payments.splice(idx,1);
          }else{
            adjusted_bills.others.splice(idx,1);
          }
          adj_info.adjusted_bills = adjusted_bills;
        }
        this.setState({edit_adjusted_meta:adj_info});
        let rmItem = this.state.removedItem;
        
        let rm = {target:target,id:id};
        rmItem.push(rm);
        this.setState({removedItem:rmItem});

        let payments_data = adjusted_bills.payments;
        let others_data = adjusted_bills.others;
        let pending_amt = 0;
        for (let index = 0; index < pending_bills.length; index++) {
          const element = pending_bills[index];
          pending_amt = pending_amt + parseFloat(element.adjusted_amount);
        }
        let payment_amt = 0;
        for (let index = 0; index < payments_data.length; index++) {
          const element = payments_data[index];
          payment_amt = payment_amt + parseFloat(element.adjusted_amount);
        }
        let other_amt = 0;
        let other_charges_amt = 0;
        for (let index = 0; index < others_data.length; index++) {
          const element = others_data[index];
          let target = element.target;
          if(target!=='other_charges'){
            other_amt = other_amt + parseFloat(element.adjusted_amount);
          }else{
            other_charges_amt = other_charges_amt + parseFloat(element.adjusted_amount); 
          }
        }

        /* this.setState({total_pending_amount:pending_amt});
        this.setState({total_adjusted_amount:(other_charges_amt+other_amt+payment_amt)}); */
        this.setState({total_pending_amount:pending_amt});
        this.setState({total_pending_amount_selected:pending_amt});
        this.setState({total_payments_amount_selected:payment_amt});
        this.setState({total_other_amount_selected:other_amt});
        this.setState({total_other_charges_amount:other_charges_amt});
      }
    render() {
      let total_pending = 0;
      let total_payments = 0;
      let total_others =0;
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
          
              <div id="page-content-wrapper">
                 <nav className="nb navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">	
                        <div className="mod-title"><span>ADJUST UNPAID BILLS</span></div>	
                    </div>
                    <div className="col-md-6 text-right">	
                      {(this.state.is_show_adjust_section===true) &&
                        <button className="btn btn-default" onClick={this.handleBackToList}>Back to list</button>
                      }
                    </div>
                  </div>
                </nav>
                {(this.state.is_show_adjust_section===false) &&
                  <div className="container-fluid">
                    <div className="content-area">
                      <div className="content-head border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                            <h3>Adjusted List</h3>
                          </div>
                          <div className="col-md-6 text-right">
                                <button className="btn btn-success"  onClick={this.handleOpenAdjustBillSection}>Adjust Bills</button>
                          </div>
                        </div>
                      </div>
                   
                      <div className="main-content" style={{overflow:'auto'}}>
                         
                        <div className="row">
                              
                                <div className="col-md-12">
                                  <table className="table">
                                    <thead className="thead-light">
                                      <tr>
                                        <th>ADJUSTED #</th>
                                        <th>DATE</th>
                                        <th>PARTY NAME</th>
                                        <th>ADJUSTABLE AMOUNT</th>
                                        <th>ADJUSTED AMOUNT</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {(this.state.adjusted_meta_data.map((value,idx)=>{
                                      return (
                                      <tr className="contact-row">
                                          <td>
                                          <DrawerLink target="adjusted" url="" schema="" name={value.adjusted_sequence} info={value} page_title={value.adjusted_sequence+' Info'}/>
                                          </td>
                                          <td>{value.created_date}</td>
                                          <td>
                                              <Link link={'/view-contact/'+value.party_info.contact_id} name={value.party_info.name} target='_blank'/>
                                          </td>
                                          
                                          <td>{utils.currency_format(value.meta_value.pending_amount)}</td>
                                          <td>{utils.currency_format(parseFloat(value.meta_value.payment_amount)+parseFloat(value.meta_value.other_charges)+parseFloat(value.meta_value.rtgs))}</td>
                                          <td>
                                          <Tooltip opt={[]} id={idx} index={idx} edit={1} handleOpenEditModel={this.handleOpenEditModel} target='edit_adjusted_bill'/>
                                          </td>
                                      </tr>
                                      )
                                    }))}
                                    
                                    {(this.state.adjusted_meta_data.length===0) &&
                                      <tr><td colSpan={5} className="text-center">{this.state.loading_process}</td></tr>
                                    }
                                    </tbody>
                                  </table>   
                                  <Pager total_page={this.state.total_page}/>
                                </div>
                              
                          </div>
                      </div>
                    </div>
                  </div>
                }
                {(this.state.is_show_adjust_section===true) &&
                  <div className="container-fluid">
                    <div className="content-area">
                      <div className="content-head border-bottom" style={{paddingTop: '30px',paddingBottom: '30px'}}>
                        <div className="row">
                          <div className="col-md-4">
                              <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={[this.state.contact_info]} index="adjust_unpaid_bills"/>
                          </div>
                          <div className="col-md-8 text-right">
                            {((this.state.total_pending_amount>0 && this.state.total_adjusted_amount>0) || this.state.update_id>0) &&
                              <>
                                <button className="btn btn-success"  onClick={this.handleGotoNext}>Next</button>
                              
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.is_edit===true) &&
                      <div className="content-area">
                        <div className="content-head border-bottom">
                        <div className="row mrtop-30 border-bottom">
                                        <div className="col-md-12">
                                            <h4>ADJUSTED SUMMARY</h4>
                                        </div>
                                   </div>
                                   <div className="row mrtop-10">
                                        <div className="col-md-4">
                                            <b>BILLS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.state.edit_adjusted_meta.pending_bills.map((value,idx)=>{
                                                        total_pending = total_pending + parseFloat(value.adjusted_amount);
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                    <p style={{margin:'0px',color:'gray'}}>₹{utils.currency_format(value.grand_total)}</p>
                                                                </td>
                                                                <td>
                                                                    ₹{utils.currency_format(value.adjusted_amount)}
                                                                </td>
                                                                <td>
                                                                  <FontAwesomeIcon style={{color:'#333',cursor:'pointer'}}  onClick={()=>this.handleRemoveItem(value.target,idx,value.id)} icon={faTimes}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                   }))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_pending)}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <b>RETURNS/DR-CR NOTES</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice #</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.state.edit_adjusted_meta.adjusted_bills && this.state.edit_adjusted_meta.adjusted_bills.others && this.state.edit_adjusted_meta.adjusted_bills.others.map((value,idx)=>{
                                                    total_others = total_others + parseFloat(value.adjusted_amount);
                                                        return (
                                                           
                                                                <tr>
                                                                    <td>
                                                                    <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                    {(value.dispatched_info && value.dispatched_info.supplier_ref_no && value.dispatched_info.supplier_ref_no!=="") &&
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.dispatched_info.supplier_ref_no}</span>
                                                                    }
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                    <td>
                                                                      <FontAwesomeIcon style={{color:'#333',cursor:'pointer'}} icon={faTimes}  onClick={()=>this.handleRemoveItem(value.target,idx,value.id)}></FontAwesomeIcon>
                                                                    </td>
                                                                </tr>
                                                          
                                                        )
                                                   }))}
                                                   {(this.state.edit_adjusted_meta.adjusted_bills && this.state.edit_adjusted_meta.adjusted_bills.others && this.state.edit_adjusted_meta.adjusted_bills.others.length===0) &&
                                                   <tr>
                                                        <td colSpan={3}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_others)}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="col-md-4">
                                            <b>PAYMENTS</b>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Payment #</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {(this.state.edit_adjusted_meta.adjusted_bills && this.state.edit_adjusted_meta.adjusted_bills.payments && this.state.edit_adjusted_meta.adjusted_bills.payments.map((value,idx)=>{
                                                    total_payments = total_payments + parseFloat(value.adjusted_amount);
                                                        return (
                                                          
                                                                <tr>
                                                                    <td>
                                                                        <p style={{margin:'0px'}}>{value.sequence}</p>
                                                                        <span style={{color:'gray',fontSize:'14px'}}>{value.payment_mode}</span>
                                                                    </td>
                                                                    <td>₹{utils.currency_format(value.adjusted_amount)}</td>
                                                                    <td>
                                                                      <FontAwesomeIcon style={{color:'#333',cursor:'pointer'}} icon={faTimes} onClick={()=>this.handleRemoveItem(value.target,idx,value.id)}></FontAwesomeIcon>
                                                                    </td>
                                                                </tr>
                                                           
                                                        )
                                                   }))}
                                                    {(this.state.edit_adjusted_meta.adjusted_bills && this.state.edit_adjusted_meta.adjusted_bills.payments && this.state.edit_adjusted_meta.adjusted_bills.payments.length===0) &&
                                                   <tr>
                                                        <td colSpan={3}>No Items found</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>₹{utils.currency_format(total_payments)}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                   </div>
                        </div>
                      </div>
                    }
                    <div className="content-area">
                      <div className="content-head border-bottom">
                          <div className="row">
                          
                            <div className="col-md-4 border-right">
                            {(this.state.pending_bills.length > 0) &&
                              <p>
                                TOTAL PENDING AMOUNT SELECTED : <b>₹{utils.currency_format(this.state.total_pending_amount)}</b>
                              </p>
                            }
                            </div>
                            
                            <div className="col-md-8">
                            {(this.state.payments_data.length > 0) &&
                              <p>
                                TOTAL ADJUSTED AMOUNT SELETED : <b>₹{utils.currency_format(this.state.total_adjusted_amount)}</b>
                              </p>
                            }
                            </div>
                          </div>
                        <div className="row">
                              <span onClick={this.handleLoadUnAdjustedBills} id="refresh_pending_bill"></span>
                            
                            

                              {(this.state.pending_bills.length===0) &&
                                <div className="col-md-12">
                                  <h4 style={{fontFamily:'monospace'}}>{this.state.pending_loader}</h4>
                                </div>
                              }
                            
                              <div className="col-md-4 border-right" style={{overflow:'auto'}}>
                              {(this.state.pending_bills.length > 0) &&
                                <table className="table">
                                    <thead>
                                        <tr>
                                          <th colSpan="4" className="text-center">PENDING INVOICES</th>
                                        </tr>
                                        <tr>
                                        <th style={{borderTop:'none'}}>Invoice #</th>
                                        <th style={{borderTop:'none'}}>Date</th>
                                        <th style={{borderTop:'none',textAlign:'right'}}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.pending_bills.length > 0) ?this.state.pending_bills.map( (value, index) => {
                                            return (
                                            <tr key={ index }  className="pblist"  index={index} id={"pending-bill-"+value.value2} target="pbills" data-id={"pending-bill-"+index}>
                                            <td>
                                              <div className="form-check form-check-inline">
                                                <input className="form-check-input payment-type-cb unpaid-check" type="checkbox" id={"unpaid-check-"+index} value={value.pending_amount} onChange={this.handlePendingBillCheck} data-id={index}/>
                                                <label className="form-check-label" htmlFor={"unpaid-check-"+index}>
                                                {value.value2}
                                                {(value.dispatched_info && value.dispatched_info.supplier_ref_no!=="") &&
                                                <>({value.dispatched_info.supplier_ref_no})</>
                                                }
                                                
                                                <input type="hidden" id={"pending-amt-"+index} value={value.pending_amount} />
                                                </label>
                                              </div>
                                              
                                              <p style={{margin:'0px',color:'gray'}}>₹{utils.currency_format(value.grand_total)}</p>
                                            </td>
                                            <td>
                                                <p style={{margin:'0px'}}>{value.date}</p>
                                                <span className="fyinstrip">{value.schema_lbl}</span>
                                            </td>
                                            
                                            <td style={{textAlign:'right'}}>
                                            ₹{utils.currency_format(value.pending_amount)}
                                            </td>
                                            </tr>
                                        )
                                        }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="4">{this.state.pending_loader}</td></tr> }
                                        {(this.state.is_more_pending) &&
                                          <tr>
                                            <td colSpan={4} style={{textAlign:'center'}}>
                                            {(this.state.is_pending_empty===false && this.state.is_pending_clicked===false) &&
                                              <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMoreBills} data-target="pending" data-id="pending">
                                                Load More
                                              </button>
                                            }
                                            {(this.state.is_pending_clicked===true) &&
                                              <button type="button" className="btn btn-outline-info">
                                                Loading...
                                              </button>
                                            }
                                            </td>
                                          </tr>
                                        }
                                        {(this.state.is_empty_item && this.state.pending_loader!=='No Pending Invoice Found.') && 
                                            <tr><td style={{borderTop:'none'}} className="text-center" colSpan="4">No More Pending Invoices</td></tr>
                                        }
                                    </tbody>
                                </table>
                              }
                              
                              </div>
                            
                              <div className="col-md-4  border-right" style={{overflow:'auto'}}>
                                {(this.state.payments_data.length > 0) &&
                                  <table className="table">
                                      <thead>
                                          <tr>
                                          <th colSpan="3" className="text-center">PAYMENTS</th>
                                          </tr>
                                          <tr>
                                          <th style={{borderTop:'none'}}>Invoice #</th>
                                          <th style={{borderTop:'none'}}>Date</th>
                                          <th style={{borderTop:'none',textAlign:'right'}}>Amount</th>
                                          
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {(this.state.payments_data.length > 0) ?this.state.payments_data.map( (value, index) => {
                                              return (
                                              <tr key={ index } className="pblist" index={index} id={"payments-list-"+value.value2} target="payments-list" data-id={"payments-list-"+index}>
                                              
                                              <td>
                                                <div className="form-check form-check-inline">
                                                  <input className="form-check-input payment-type-cb payments-check" type="checkbox" id={"payments-check-"+index} value={value.grand_total} onChange={this.handleAdjustedBillCheck} data-id={index}/>
                                                  <label className="form-check-label" htmlFor={"payments-check-"+index}>
                                                  {value.value2}
                                                  <input type="hidden" id={"payments-amt-"+index} value={value.grand_total} />
                                                  </label>
                                                </div>
                                              </td>
                                              <td>
                                                  {value.date}
                                              </td>
                                              
                                              <td style={{textAlign:'right'}}>
                                              ₹{utils.currency_format(value.grand_total)}
                                              </td>
                                            
                                              </tr>
                                          )
                                          }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }
                                          {(this.state.is_more_payments) &&
                                        <tr>
                                          <td colSpan={3} style={{textAlign:'center'}}>
                                          {(this.state.is_payments_empty===false && this.state.is_payments_clicked===false) &&
                                            <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMoreBills} data-target="adjustable" data-id="payments">
                                              Load More
                                            </button>
                                          }
                                          {(this.state.is_payments_clicked===true) &&
                                            <button type="button" className="btn btn-outline-info">
                                              Loading...
                                            </button>
                                          }
                                          </td>
                                        </tr>
                                        }
                                          {(this.state.is_empty_item && this.state.pending_loader!=='No Pending Invoice Found.') && 
                                              <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No more PAYMENTS found.</td></tr>
                                          }
                                      </tbody>
                                  </table>
                                }
                                {(this.state.pending_bills.length > 0 && this.state.payments_data.length===0) &&
                                  <p style={{textAlign:'center'}}>No more PAYMENTS found.</p>
                                }
                                
                              </div>
                              <div className="col-md-4" style={{overflow:'auto'}}>
                                {(this.state.other_data.length > 0) &&
                                  <table className="table">
                                      <thead>
                                          <tr>
                                          <th colSpan="3" className="text-center">RETURNS/NOTES</th>
                                          </tr>
                                          <tr>
                                          <th style={{borderTop:'none'}}>Invoice #</th>
                                          <th style={{borderTop:'none'}}>Date</th>
                                          <th style={{borderTop:'none',textAlign:'right'}}>Amount</th>
                                          
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {(this.state.other_data.length > 0) ?this.state.other_data.map( (value, index) => {
                                              return (
                                              <tr key={ index } className="pblist" index={index} id={"others-list-"+value.value2} target="others-list" data-id={"others-list-"+index}>
                                              
                                              <td>
                                                <div className="form-check form-check-inline">
                                                  <input className="form-check-input payment-type-cb others-check" type="checkbox" id={"others-check-"+index} value={value.grand_total} onChange={this.handleAdjustedBillCheck} data-id={index}/>
                                                  <label className="form-check-label" htmlFor={"others-check-"+index}>
                                                  {value.value2}
                                                  <input type="hidden" id={"others-amt-"+index} value={value.grand_total} />
                                                  </label>
                                                </div>
                                              
                                              </td>
                                              <td>
                                                  {value.date}
                                              </td>
                                              
                                              <td style={{textAlign:'right'}}>
                                              ₹{utils.currency_format(value.grand_total)}
                                              </td>
                                            
                                              </tr>
                                          )
                                          }) : <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">{this.state.pending_loader}</td></tr> }
                                          {(this.state.is_more_others) &&
                                        <tr>
                                          <td colSpan={3} style={{textAlign:'center'}}>
                                          {(this.state.is_others_empty===false && this.state.is_others_clicked===false) &&
                                            <button type="button" className="btn btn-outline-info" onClick={this.handleLoadMoreBills} data-target="adjustable" data-id="other">
                                              Load More
                                            </button>
                                          }
                                          {(this.state.is_others_clicked===true) &&
                                            <button type="button" className="btn btn-outline-info">
                                              Loading...
                                            </button>
                                          }
                                          </td>
                                        </tr>
                                        }
                                          {(this.state.is_empty_item && this.state.pending_loader!=='No Pending Invoice Found.') && 
                                              <tr><td style={{borderTop:'none'}} className="text-center" colSpan="3">No more RTGS/CR-DR Note found.</td></tr>
                                          }
                                      </tbody>
                                  </table>
                                }
                                {(this.state.pending_bills.length > 0 && this.state.other_data.length===0) &&
                                  <p style={{textAlign:'center'}}>No more RTGS/CR-DR Note found.</p>
                                }
                                
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {(this.state.is_show_payment===true) &&
                <PaymentPopup remaining_amount = {(this.state.total_pending_payments_others - this.state.total_other_charges_amount)} handleCreateRemainingPayment = {this.handleCreateRemainingPayment} handleClosePaymentPopup={this.handleClosePaymentPopup} target="adjust" payment_type={(this.state.contact_type===1 || this.state.contact_type==='1')?'IN':'OUT'}/>
              }
              {(this.state.is_open_summary) &&
                <div id="adjustable-bill-summary" className="modal" style={{display:'block'}}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header flex-column">        
                        <h4 className="modal-title w-100">Adjustable Summary</h4>  
                      </div>
                      <div className="modal-body">
                          <div className="row">
                            <div className="col-md-12">
                              <table className="table">
                                <tr>
                                  <td>PENDING AMOUNT</td>
                                  <td className="text-right">
                                    <h4>₹{utils.currency_format(this.state.total_pending_amount_selected)}</h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>( - ) PAYMENTS</td>
                                  <td className="text-right">
                                    <h4>₹{utils.currency_format(this.state.total_payments_amount_selected)}</h4>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>TOTAL</td>
                                  <td className="text-right">
                                  <h4>₹{utils.currency_format(this.state.total_pending_payments)}</h4>
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>( - ) RETURNS/NOTES</td>
                                  <td className="text-right">
                                  <h4>₹{utils.currency_format(this.state.total_other_amount_selected)}</h4>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>TOTAL</td>
                                  <td className="text-right">
                                  <h4>₹{utils.currency_format(this.state.total_pending_payments_others)}</h4>
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>( - ) OTHER CHARGES</td>
                                  <td className="text-right" style={{width:'150px'}}><input type="text" className="form-control w-100" onChange={this.handleChangeOtherCharges}/></td>
                                </tr>
                                <tr>
                                  <td>REMAINING</td>
                                  <td className="text-right">
                                    <h4>₹{utils.currency_format(this.state.total_pending_payments_others - this.state.total_other_charges_amount)}</h4>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <div className="row">
                          <div className="col-md-6 text-left">
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseSummary}>Close</button>
                          </div>
                          <div className="col-md-6 text-right">
                            <button type="button" className="btn btn-success"  id="adjust" onClick={this.handleAdjustBill}>Adjust</button>
                            <button type="button" id="adjust-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              }
            </div>
        );
    }
    handleCloseSummary = (e) =>{
      this.setState({is_open_summary:false});
      this.setState({selected_pending_bills:[],selected_adjustable_bills:[]});
      this.setState({total_pending_amount_selected:0});
      this.setState({total_payments_amount_selected:0});
      this.setState({total_pending_payments:0});
      this.setState({total_other_amount_selected:0});
      this.setState({total_pending_payments_others:0});
    }
    handleClosePaymentPopup = (e) =>{
      this.setState({is_show_payment:false});
    }
    handleCreateRemainingPayment = (payments_meta) =>{
      
      let paid_amount = payments_meta.data.paid_amount;
      let new_payment_data = {
        fin_year :'',
        date :'',
        id :0,
        value2 :'',
        value1 :'',
        target :'new_payment',
        bill_type :'New Payment',
        grand_total : paid_amount,
        payments_data : payments_meta
      };
      this.setState({new_payments_data:new_payment_data});
      setTimeout(() => {
        
        if(document.getElementById("adjust")){
          document.getElementById("adjust").click();
        }
      }, 100);
    }
}