import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner,faPencilAlt,faTrash,faCheckCircle, faTimes  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import axios from 'axios';
import utils from '../../utils.js';
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import ModalBox from "../modal-box";
import Autocomplete from "../autocomplete";
import BarcodeDraggable from './barcode-draggable';
import BarcodeTemplate from "./barcode-template";
let {enc_key,app_api,user_info} = require('../../library.js');
let _this;
export default class SettingsBarcode extends Component {
     constructor() {
      super();
      this.state = {
        sticker_height:0,
        sticker_width:0,
        sticker_sizes : {
                          small:{height:25,width:40},
                          medium:{height:25,width:50},
                          large:{height:60,width:100},
                        },
        barcode_template : [],
        show_save_btn : false,
        active_tab : 'list',
        barcode_list:[],
        defaultValue:'',
        custom_value:'',
        mode:'',
        is_default : false,
        sticker_unit:'mm',
        barcode_labels : {'price':'PRICE','barcode-name':'BARCODE NUMBER','product-name':'PRODUCT NAME','description':'DESCRIPTION','company-name':'COMPANY NAME','custom-text':'CUSTOM TEXT','varient':'VARIENT'},
        purchase_item_options:[],
        item_options:[],
        barcode_options : [],
        is_sequence_set : false,
        barcode_preview : '',
        default_barcode_template_data : [],
        is_auto_generate_barcode : false,
        barcode_sequence_settings : [],
        barcode_error_msg : "(It's recommended to use barcode name size less than 10 characters)",
        barcode_error : '',
        last_generated_barcode : '',
        short_name : '',
        mixed : '',
        symbol_type : 'barcode',
        is_loading : true
      }
      _this = this;
      this.template_items=['barcode'];
      this.barcode_template = [];
      this.handleChangeSize = this.handleChangeSize.bind(this);
      this.handleCheckDefault = this.handleCheckDefault.bind(this);
      this.handleTextChange = this.handleTextChange.bind(this);
      this.handleCustomTemplate = this.handleCustomTemplate.bind(this);
      this.handleCheckList = this.handleCheckList.bind(this);
      this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
      this.handleCustomUnitChange = this.handleCustomUnitChange.bind(this);
      this.handleRenameLabel = this.handleRenameLabel.bind(this);
      this.saveRenameLabel = this.saveRenameLabel.bind(this);
      this.preveiwBarcodeTempate = this.preveiwBarcodeTempate.bind(this);
      this.handleSettingsBarcodeChange = this.handleSettingsBarcodeChange.bind(this);
      this.handleBarcodeCheck = this.handleBarcodeCheck.bind(this);
      this.handleChangeSequence = this.handleChangeSequence.bind(this);
      this.handleSaveBarcodeSequence = this.handleSaveBarcodeSequence.bind(this);
      this.handleCheckAutoGenerateBarcode = this.handleCheckAutoGenerateBarcode.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    handleCheckAutoGenerateBarcode(e){
      let is_auto_generate_barcode = e.currentTarget.checked;
      this.setState({is_auto_generate_barcode:is_auto_generate_barcode});
    }
    handleSaveBarcodeSequence(e){
        let id = e.currentTarget.id;
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");

        let barcode_prefix = document.getElementById("barcode-prefix").value;
        let barcode_separator = document.getElementById("barcode-separator").value;
        let barcode_category_sequence = document.getElementById("barcode-category-sequence").value;
        let barcode_category_separator = document.getElementById("barcode-category-separator").value;
        let barcode_sequence_number = document.getElementById("barcode-sequence-number").value;
        let barcode_sequence_separator = document.getElementById("barcode-sequence-separator").value;
        let barcode_sufix = document.getElementById("barcode-sufix").value;

       
        let is_auto_generate_barcode = this.state.is_auto_generate_barcode;
        let preview = this.state.barcode_preview;

        let barcode_preview = {preview:preview,is_auto_generate_barcode:is_auto_generate_barcode,barcode_prefix:barcode_prefix,barcode_separator:barcode_separator,barcode_category_sequence:barcode_category_sequence,barcode_category_separator:barcode_category_separator,barcode_sequence_number:barcode_sequence_number,barcode_sequence_separator:barcode_sequence_separator,barcode_sufix:barcode_sufix};

        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            name : 'barcode_sequence_settings',
            value : barcode_preview,
        }
        
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
        }.bind(this)).catch(function(res){
            
        })
    }
    handleChangeSequence(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      let did = data_id.replaceAll('_','-');
      let data_value = document.getElementById(did).value;

      let barcode_prefix = document.getElementById("barcode-prefix").value;
      let barcode_separator = document.getElementById("barcode-separator").value;
      let barcode_category_sequence = document.getElementById("barcode-category-sequence").value;
      let barcode_category_separator = document.getElementById("barcode-category-separator").value;
      let barcode_sequence_number = document.getElementById("barcode-sequence-number").value;
      let barcode_sequence_separator = document.getElementById("barcode-sequence-separator").value;
      let barcode_sufix = document.getElementById("barcode-sufix").value;

      let barcode_sequence = this.state.barcode_sequence_settings;
      if(barcode_sequence[data_id]===undefined){
        barcode_sequence[data_id] = [];
      }
      barcode_sequence[data_id] = data_value;
      this.setState({barcode_sequence_settings:barcode_sequence});
   
      let preview = '';
      this.barcode_template = [];
      if(barcode_prefix!=="" || barcode_separator!=="" || barcode_category_sequence!=="" || barcode_category_separator!=="" || barcode_sequence_number!=="" || barcode_sequence_separator!=="" || barcode_sufix!==""){
          preview = barcode_prefix+barcode_separator+barcode_category_sequence+barcode_category_separator+barcode_sequence_number+barcode_sequence_separator+barcode_sufix;
          let preview_len = preview.length;
          if(preview_len>10){
            this.setState({barcode_error:this.state.barcode_error_msg});
          }else{
            this.setState({barcode_error:''});
          }
          this.setState({is_sequence_set:true,barcode_preview:preview});
          let height = this.state.sticker_height;
          let width = this.state.sticker_width;
          let values = this.state.default_barcode_template_data[0].value;
          let barcode_values = [];
          for(let i=0;i<values.length;i++){
              if(values[i].element_id==='barcode'){
                barcode_values.push(values[i]);
              }
          }
          let billing_data = {barcode_name:preview};
          this.barcode_template.push(<BarcodeTemplate  barcode_template_data={this.state.default_barcode_template_data} id={0} key={0} billing_data={billing_data} tamplate_data={barcode_values} height={height} width={width}/>);
          this.setState({barcode_template:this.barcode_template});
      }else{
          this.setState({is_sequence_set:false,barcode_preview:''});
      }
    }

    handleBarcodeCheck(e){
      let id = e.currentTarget.id;
      let qs = document.querySelectorAll("."+id+'-settings');
      if(e.currentTarget.checked){
         let height = document.getElementById("barcode-height").value;
         let width = document.getElementById("barcode-width").value;
         let position = {x:0,y:0};
         if(this.state.symbol_type==='barcode'){
          this.barcode_template.push(<BarcodeDraggable  position={position} key="barcode" target="barcode" element={<Barcode width={(width/100)} displayValue={false} value={"DEMO"} height={height}/>}/>);
         }else if(this.state.symbol_type=='qr'){
          this.barcode_template.push(<BarcodeDraggable  position={position} key="barcode" target="barcode" element={<QRCode size={256} style={{ height: height, maxWidth: width, width: width }} value={"DEMO"} viewBox={`0 0 256 256`} />}/>);
         }
          this.setState({barcode_template:this.barcode_template});
          for(let i=0;i<qs.length;i++){
            qs[i].classList.remove('hide');
          }

          setTimeout(function(){
            if(document.getElementById(id+'-position')){
               let elem_pos = document.getElementById(id+'-position').value;
              
               if(elem_pos==='center'){
                  //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                  document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'center';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");;
               }else if(elem_pos=='right'){
                   //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                   document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'right';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");;
               }else if(elem_pos=='left'){
                  //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                  document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'left';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");;
               }
            }
          },50);

      }else{
        for(let i =0 ; i < this.barcode_template.length; i++){
            let bk = this.barcode_template[i].key;
            if(bk==='barcode'){
                this.barcode_template.splice(i,1);
            }
        }
        for(let i=0;i<qs.length;i++){
          qs[i].classList.add('hide');
        }
      }
      this.setState({barcode_template:this.barcode_template});
    }
    handleSettingsBarcodeChange(e){
      let value = e.currentTarget.value;
      if(value!==''){
        value = parseFloat(value);
        for(let i =0 ; i < this.barcode_template.length; i++){
            let bk = this.barcode_template[i].key;
            let position = this.barcode_template[i].props.position;

            if(bk==='barcode'){
                let data_value = e.currentTarget.getAttribute('data-value');
                let b_height = document.getElementById('barcode-height').value;
                let b_width = document.getElementById('barcode-width').value;
                if(data_value==='barcode_height'){
                  b_height = value;
                }
                if(data_value==='barcode_width'){
                  b_width = value;
                }

                let barcode_name = this.barcode_template[i].props.element.props.value;
                if(this.state.symbol_type==='barcode'){

                  this.barcode_template[i] = <BarcodeDraggable key={'barcode'} position={position} target={'barcode'} element={<Barcode width={(b_width/100)} displayValue={false} key={'barcode'} value={barcode_name} height={b_height}/>}/>;
                }else if(this.state.symbol_type==='qr'){
                  this.barcode_template[i] = <BarcodeDraggable key={'barcode'} position={position} target={'barcode'} element={<QRCode size={256}  key={'barcode'} style={{ height: b_height, maxWidth: b_width, width: b_width }} value={barcode_name} viewBox={`0 0 256 256`} />}/>;
                }
                this.setState({barcode_template:[]});
            }
        }
        setTimeout(function(){
          _this.setState({barcode_template:this.barcode_template});
        },100);
      }
    }
    handleTextChange(e){
      let target = e.currentTarget.getAttribute('data-id');
      
    }

    saveRenameLabel(e){
        let data_id = e.currentTarget.getAttribute('data-id');
        if(document.getElementById(data_id+"-check-block")){
          document.getElementById(data_id+"-check-block").classList.remove('edit-mode');
        }
        if(document.getElementById(data_id+"-settings-blk")){
          if(document.getElementById(data_id) && document.getElementById(data_id).checked===true){
            document.getElementById(data_id+"-settings-blk").classList.remove('hide');
          }
        }

        let lbl = document.getElementById(data_id+"-txt").value;
        document.getElementById(data_id+"-lbl").innerHTML = lbl;

        document.getElementById(data_id+"-txt").classList.toggle('hide');
        e.currentTarget.classList.toggle('hide');

        document.getElementById(data_id+"-lbl").classList.toggle('hide');
        document.getElementById(data_id+"-lbl-edit").classList.toggle('hide');
        let barcode_labels = document.querySelectorAll(".temp-check");
        let labels =[];
        for(let i=0;i<barcode_labels.length;i++){
            let key = barcode_labels[i].id;
            let value = document.querySelector("[for="+key+"]").innerHTML;
            let data = {key:key,value,value} 
            labels.push(data);
        }


          let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
            name : 'barcode_labels',
            value : labels,
        }
        
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }
    handleRenameLabel(e){
        let data_id = e.currentTarget.getAttribute('data-id');
        document.getElementById(data_id+"-lbl").classList.toggle('hide');
        e.currentTarget.classList.toggle('hide');
        
        document.getElementById(data_id+"-txt").classList.toggle('hide');
        document.getElementById(data_id+"-lbl-save").classList.toggle('hide');

        let value = document.querySelector("[for="+data_id+"]").innerHTML;

        document.getElementById(data_id+"-txt").value = value;
        if(document.getElementById(data_id+"-check-block")){
          document.getElementById(data_id+"-check-block").classList.add('edit-mode');
        }
        if(document.getElementById(data_id+"-settings-blk")){
          if(document.getElementById(data_id) && document.getElementById(data_id).checked===true){
            document.getElementById(data_id+"-settings-blk").classList.add('hide');
          }
        }

    }
    handleCustomUnitChange(e){
      this.setState({sticker_unit:e.currentTarget.value});
      let height = document.getElementById("custom-height").value;
      let width = document.getElementById("custom-width").value;
      let unit = document.getElementById("temp-unit").value;
      this.setState({sticker_height:parseFloat(height)+''+unit,sticker_width:parseFloat(width)+''+unit});
    }
    handleCheckDefault(e){
      this.setState({is_default:e.currentTarget.checked});
    }
    handleCustomTemplate(e){
      let height = document.getElementById("custom-height").value;
      let width = document.getElementById("custom-width").value;
      let unit = document.getElementById("temp-unit").value;
      
     
      if((height!=="" && height!=="0") && (width!=="" && width!=="0")){
        this.setState({show_save_btn:true});
        this.setState({sticker_height:parseFloat(height)+''+unit,sticker_width:parseFloat(width)+''+unit});
        if(this.state.mode===''){
          this.barcode_template = [];
          let position = {x:0,y:0};
          if(this.state.symbol_type==='barcode'){

            this.barcode_template.push(<BarcodeDraggable key="barcode" position={position} target="barcode" element={<Barcode width={1} displayValue={false} value={"DEMO"} height={15}/>}/>);
          }else if(this.state.symbol_type==='qr'){
            this.barcode_template.push(<BarcodeDraggable key="barcode" position={position} target="barcode" element={<QRCode size={256} style={{ height: 100, maxWidth: 100, width: 100 }} value={"DEMO"} viewBox={`0 0 256 256`} />}/>);
          }
          this.setState({barcode_template:this.barcode_template});
          setTimeout(function(){
            if(document.getElementById("barcode")){
              document.getElementById("barcode").checked = true;
            }
            let qs = document.querySelectorAll('.barcode-settings');
            for(let i=0;i<qs.length;i++){
              qs[i].classList.remove('hide');
            }
            document.querySelector('[data_target=barcode]').style.transform = 'translate(0, 0)';
            document.querySelector('[data_target=barcode] .handle').style.textAlign = 'center';
            document.querySelector('[data_target=barcode]').style.setProperty("position", "relative", "important");
          },50);
        }
      }else{
        if(this.state.mode===''){
          if(document.getElementById("barcode")){
            document.getElementById("barcode").checked = false;
          }
          this.setState({show_save_btn:false});
          this.barcode_template = [];
          this.setState({sticker_height:0,sticker_width:0});
          this.setState({barcode_template:this.barcode_template});
          let qs = document.querySelectorAll('.barcode-settings');
          for(let i=0;i<qs.length;i++){
            qs[i].classList.add('hide');
          }
        }
      }
    }

    deleteModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      if(thisid!==null){
        document.getElementById("deleteValue").value = 'delete_barcode_template';
        document.getElementById("deleteIndex").value = id;
        document.getElementById("deleteTarget").value = 'delete_barcode_template';
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }
    handleSaveTemplate(e){
        let id = e.currentTarget.id;
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");

        let temp_name = document.getElementById("tsize").value;
        if(temp_name==='custom_barcode_template'){
          temp_name = document.getElementById("custom-name").value;
        }
        let default_template = document.getElementById("default-template");
        let is_default = 'no';
        if(default_template.checked){
          is_default = 'yes';
        }

        let draggable = document.querySelectorAll(".react-draggable");
        let element_arr = [];
        for(let i=0;i<draggable.length;i++){
          let style = draggable[i].style.cssText;
          var regex = /-?\d+/g;
          var string = style;
          var matches = string.match(regex);
           let x = matches[0];
           let y = matches[1];
           let target = draggable[i].getAttribute("data_target");

           let font_weight = '';
           let font_size = '';
           if(document.getElementById(target+"-font-weight")){
              if(document.getElementById(target+"-font-weight").checked){
                font_weight = 'bold';
              }else{
                font_weight = 'normal';
              }
           }
           if(document.getElementById(target+"-font-size")){
              let fsize = document.getElementById(target+"-font-size").value;
              if(fsize!==''){
                font_size = fsize;
              }else{
                font_size = 12;
              }
           }
           let height = '';
           if(document.getElementById(target+"-height")){
              let ht = document.getElementById(target+"-height").value;
              if(ht!==''){
                height = ht;
              }else{
                height = 15;
              }
           }
           let position = '';
           if(document.getElementById(target+"-position")){
              position = document.getElementById(target+"-position").value;
              position = position;
           }
           let width = '';
           if(document.getElementById(target+"-width")){
              width = document.getElementById(target+"-width").value;
              width = width;
           }
           let showLabel = '';
           if(document.getElementById(target+"-show-label")){
              showLabel = document.getElementById(target+"-show-label").checked;
              showLabel = showLabel;
           }
           let lableBold = '';
           if(document.getElementById(target+"-label-bold")){
              lableBold = document.getElementById(target+"-label-bold").checked;
              lableBold = lableBold;
           }

           let display_name = draggable[i].getAttribute("data_value");
           element_arr.push({element_id:target,display_name:display_name,style:style,x:x,y:y,font_weight:font_weight,font_size:font_size,height:height,position:position,width:width,show_label:showLabel, label_bold:lableBold});
        }
        let send_data = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            name : temp_name,
            height : this.state.sticker_height,
            width : this.state.sticker_width,
            value : element_arr,
            is_default : is_default,
            symbol_type : this.state.symbol_type,
        }
        
        let api = app_api+'/save_barcode_template';
        axios.post(api, send_data)
          .then(function (response) {
             document.getElementById(id).classList.remove("hide");
              document.getElementById(id+'-btn-spinner').classList.add("hide");
        }.bind(this)).catch(function(res){
            
        })
    }


    handleCheckList(e){
        let id = e.currentTarget.id;

        let qs = document.querySelectorAll("."+id+'-settings');
       
        if(e.currentTarget.checked){
          let placeholder = 'Enter '+id.replace("-"," ");
          this.template_items.push(id);
          let n = document.querySelector('[for='+id+']').innerHTML;
          
          if(id==='encrypted_purchase_price'){
            n = 'ENC. PP';
          }
          if(id==='encrypted_selling_price'){
            n = 'ENC. SP';
          }


          let name = id.replace("-"," ");

          let pos_left = 0;
          let pos_right = 0;

          

          let position = {x:0,y:0};
          let font_size = '';
          let font_weight = '';

          if(document.getElementById(id+'-font-weight')){
             let elem = document.getElementById(id+'-font-weight');
             if(elem.checked===true){
              font_weight = 'bold';
             }
          }
          if(document.getElementById(id+'-font-size')){
            let fsize = document.getElementById(id+'-font-size').value;
            if(fsize!==""){
              font_size = fsize;
            }else{
              font_size = 12;
            }
          }

          let lable_style = {fontSize:font_size,fontWeight:font_weight};
            
    
          this.barcode_template.push(<BarcodeDraggable lable_style={lable_style} key={id} position={position} target={id} element={n}/>);
          this.setState({barcode_template:this.barcode_template});
          for(let i=0;i<qs.length;i++){
            qs[i].classList.remove('hide');
          }
          setTimeout(function(){
            if(document.getElementById(id+'-position')){
               let elem_pos = document.getElementById(id+'-position').value;
               
               if(elem_pos==='center'){
                  //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                  document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'center';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");
               }else if(elem_pos=='right'){
                   //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                   document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'right';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");
               }else if(elem_pos=='left'){
                  //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                  document.querySelector('[data_target='+id+'] .handle').style.textAlign = 'left';
                  document.querySelector('[data_target='+id+']').style.setProperty("position", "relative", "important");
               }else if(elem_pos==='free'){
                //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                document.querySelector('[data_target='+id+']').style.left = '0';
              }else if(elem_pos==='free-right'){
                //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                document.querySelector('[data_target='+id+']').style.right = '0';
                document.querySelector('[data_target='+id+']').style.top = '0';
              }else if(elem_pos==='free-left'){
                //document.querySelector('[data_target='+id+']').style.transform = 'translate(0, 0)';
                document.querySelector('[data_target='+id+']').style.left = '0';
                document.querySelector('[data_target='+id+']').style.top = '0';
              }
            }
          },10);

        }else{
           let idx = this.template_items.indexOf(id);
           
           if(idx>=0){
              this.barcode_template.splice(idx, 1);
              this.template_items.splice(idx, 1);
              this.setState({barcode_template:this.barcode_template});
           }
            for(let i=0;i<qs.length;i++){
              qs[i].classList.add('hide');
            }
        }
    }
   
    handleChangeSize(e){
      let value = e.currentTarget.value;
      this.setState({defaultValue:value});
      this.barcode_template = [];
      this.template_items = ['barcode'];
      let cb = document.querySelectorAll(".temp-check");
      for(let i=0;i<cb.length;i++){
          if(cb[i].checked){
            cb[i].checked = false;
          }
      }
      
      if(value!=="" && value!=="custom_barcode_template"){
        this.setState({show_save_btn:true});
        
        let height = this.state.sticker_sizes[value].height;
        let width = this.state.sticker_sizes[value].width;
        this.setState({sticker_height:height+'mm',sticker_width:width+'mm'});
        let position = {x:0,y:0};
        if(this.state.symbol_type==='barcode'){

          this.barcode_template.push(<BarcodeDraggable key="barcode" position={position} target="barcode" element={<Barcode width={1} displayValue={false} value={"DEMO"} height={15}/>}/>);
        }else if(this.state.symbol_type==='qr'){
          this.barcode_template.push(<BarcodeDraggable key="barcode" position={position} target="barcode" element={<QRCode size={256} style={{ height: 50, maxWidth: 50, width: 50 }} value={"DEMO"} viewBox={`0 0 256 256`} />}/>);
        }
        this.setState({barcode_template:this.barcode_template});
        setTimeout(function(){
            if(document.getElementById("barcode")){
              document.getElementById("barcode").checked = true;
            }
            let qs = document.querySelectorAll('.barcode-settings');
            for(let i=0;i<qs.length;i++){
              qs[i].classList.remove('hide');
            }
            //document.querySelector('[data_target=barcode]').style.transform = 'translate(0, 0)';
            document.querySelector('[data_target=barcode] .handle').style.textAlign = 'center';
            document.querySelector('[data_target=barcode]').style.setProperty("position", "relative", "important");
          },50);

      }else if(value==="" || value==="custom_barcode_template"){
        if(document.getElementById("barcode")){
          document.getElementById("barcode").checked = false;
        }
        this.setState({show_save_btn:false});
        this.setState({barcode_template:this.barcode_template});
        this.setState({sticker_height:0,sticker_width:0});
        let qs = document.querySelectorAll('.barcode-settings');
        for(let i=0;i<qs.length;i++){
            qs[i].classList.add('hide');
        }
      }
    }
    
    setBarcodePreviewData = (preview_data) =>{
      
      let custom_fields_json = preview_data.custom_fields_json;
      
      if(custom_fields_json!==undefined){
        for (let i = 0; i < custom_fields_json.length; i++) {
          let cfid = 'cf'+custom_fields_json[i].id;
          if(preview_data[cfid]===undefined){
            preview_data[cfid] = [];
            preview_data[cfid] = custom_fields_json[i].value;
          }
        }
      }

      this.barcode_template = [];
      let draggable = document.querySelectorAll(".react-draggable");
      let element_arr = [];
      for(let i=0;i<draggable.length;i++){
         let style = draggable[i].style.cssText;
         var regex = /\d+/g;
        var string = style;
        var matches = string.match(regex);
         let x = matches[0];
         let y = matches[1];
         let target = draggable[i].getAttribute("data_target");

         let font_weight = '';
         let font_size = '';
         if(document.getElementById(target+"-font-weight")){
            if(document.getElementById(target+"-font-weight").checked){
              font_weight = 'bold';
            }else{
              font_weight = 'normal';
            }
         }
         if(document.getElementById(target+"-font-size")){
            let fsize = document.getElementById(target+"-font-size").value;
            if(fsize!==''){
              font_size = fsize;
            }else{
              font_size = 12;
            }
         }
         let height = '';
         if(document.getElementById(target+"-height")){
            let ht = document.getElementById(target+"-height").value;
            if(ht!==''){
              height = ht;
            }else{
              height = 15;
            }
         }
         let bc_width = 100;
         if(document.getElementById(target+"-width")){
            let wt = document.getElementById(target+"-width").value;
            if(wt!==''){
              bc_width = wt;
            }
         }
         let width = '';
         let whiteSpace = '';
         let lineHeight = '';
         if(document.getElementById(target+"-width")){
            let wdth = document.getElementById(target+"-width").value;
            if(wdth!==''){
              width = wdth+'px';
              whiteSpace = 'break-spaces';
              lineHeight = 1.2;
            }
         }

         let display_name = draggable[i].getAttribute("data_value");
         element_arr.push({element_id:target,display_name:display_name,style:style,x:x,y:y,font_weight:font_weight,font_size:font_size});
         
        let position = {x:parseFloat(x),y:parseFloat(y)}
        let lable_style = {fontSize:font_size,fontWeight:font_weight,height:height,width:width,whiteSpace:whiteSpace,lineHeight:lineHeight}

        if(target==='barcode'){
          let barcode_name = preview_data['barcode_name'];
          if(barcode_name===""){
            barcode_name = preview_data['product_name'];
          }
          if(this.state.symbol_type==='barcode'){
            this.barcode_template.push(<BarcodeDraggable key={target} position={position} target={target} element={<Barcode width={(bc_width/100)} displayValue={false} key={target} value={barcode_name} height={height}/>}/>);
          }else if(this.state.symbol_type==='qr'){
            this.barcode_template.push(<BarcodeDraggable key={target} position={position} target={target} element={<QRCode size={256}  key={target} style={{ height: height, maxWidth: bc_width, width: bc_width }} value={barcode_name} viewBox={`0 0 256 256`} />}/>);
            
          }
        }else{
          let name = display_name;
          let elem_key = target.replace(/\-+/, "_");
          if(elem_key==='phone_number_1'){
            elem_key = 'phone1';
          }else if(elem_key==='phone_number_2'){
            elem_key = 'phone2';
          }
          let display_value = preview_data[elem_key];
          
          if(elem_key==='price'){
            let price_lbl = '';
            if(document.getElementById("price-lbl")){
              price_lbl = document.getElementById("price-lbl").innerHTML;
            }
            display_value = price_lbl+' '+display_value+'/-';
          }else if(elem_key==='varient'){
            let varient_lbl = '';
            if(document.getElementById("varient-lbl")){
              varient_lbl = document.getElementById("varient-lbl").innerHTML;
            }
            if(display_value===""){
              display_value = 0;
            }
            display_value = varient_lbl+' '+display_value;
          }else if(elem_key==='supplier_short_name'){
            display_value = this.state.short_name;
          }else if(elem_key==='mixed'){
            display_value = this.state.mixed;
          }

          if(document.getElementById(target+"-show-label")){
            let cflbl = '';
            if(document.getElementById(target+"-lbl")){
              cflbl = document.getElementById(target+"-lbl").innerHTML;
            }
            if(document.getElementById(target+"-show-label").checked){
              let style = '';
              if(document.getElementById(target+'-label-bold').checked){
                style = 'style="font-weight:bold"';
              }
              display_value = '<span data-target="'+target+'-bc-lbl" '+style+'>'+cflbl+'</span> '+display_value;
            }
          }

          this.barcode_template.push(<BarcodeDraggable key={target} position={position} target={target} element={name} lable_style={lable_style} display_value={display_value}/>);
        }
      }
      this.setState({barcode_template:[]});
      setTimeout(function(){
        _this.setState({barcode_template:this.barcode_template});
      },100);
      localStorage.removeItem("barcode_preview_id");
    }

    preveiwBarcodeTempate(e){
      let id = localStorage.getItem("barcode_preview_id");
      if(id){
        //this.template_items = [];
        
        let preview_data = this.state.purchase_item_options[id];
        if(preview_data===undefined){
          let inv_json_data = JSON.parse(localStorage.getItem('purchase_item_options'));
          preview_data = inv_json_data[id];
        }
        if(preview_data.contact_id!==undefined && preview_data.contact_id>0){
          let body = {};
          body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
            id : preview_data.contact_id,
            get : 'short_name,city',
          }
          let api = app_api+'/get_contact_on_id';
          axios.post(api, body)
            .then(function (response) {
                let data = response.data;
                let short_name = '';
                if(data[0] !== undefined && data[0].short_name !== undefined && data[0].short_name !== null){
                  short_name = data[0].short_name;
                  this.setState({short_name : short_name});
                }
                let city = '';
                if(data[0] !== undefined && data[0].city !== undefined && data[0].city !== null && data[0].city !== ""){
                  city = data[0].city;
                  city = city.charAt(0);
                }
                
                let selling_price = preview_data.price;
                let sp_prefix = utils.PREFIX_TO_NUMBER(selling_price,5);
                
                let mixed = city+short_name+sp_prefix;
                this.setState({mixed:mixed});
                this.setBarcodePreviewData(preview_data);
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }else{
          this.setBarcodePreviewData(preview_data);
        }
      }
    }

    componentDidMount(){
      let curl = window.location.href;
      const settings_tab = curl.match(/settings\/barcode-management\/settings$/i);
      const list_tab = curl.match(/settings\/barcode-management$/i);
      const manager_tab = curl.match(/settings\/barcode-management\/manager$/i);
      const edit_template = curl.match(/settings\/barcode-management\/edit\/(\d+$)/i);
      
      if(manager_tab){
        this.setState({active_tab:'manager'});
      }
      if(settings_tab){
        this.setState({active_tab:'settings'});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
           }
          let api = app_api+'/get_default_barcode_template';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              this.setState({ 'default_barcode_template_data': cursor});
              let barcode_template_data = cursor;
              let height = barcode_template_data[0].height;
              let width = barcode_template_data[0].width; 
              this.setState({sticker_width: width,sticker_height:height});

               let bc_send_data = {
                  store_name:user_info.store_name,
                  branch_id:user_info.login_id,
                  user_id : user_info.user_id,
                  uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                  access_level : user_info.access_level,
                  key:'barcode_sequence_settings'
              }
              let bc_api = app_api+'/get_settings';
              axios.post(bc_api, bc_send_data)
                .then(function (response) {
                  let resp = response.data;
                  if(resp.barcode_sequence_settings){
                    let barcode_sequence_settings = resp.barcode_sequence_settings;
                    this.setState({barcode_sequence_settings:barcode_sequence_settings,is_sequence_set:true,barcode_preview:barcode_sequence_settings.preview,is_auto_generate_barcode:barcode_sequence_settings.is_auto_generate_barcode});

                    let height = this.state.sticker_height;
                    let width = this.state.sticker_width;
                    let values = this.state.default_barcode_template_data[0].value;
                    let barcode_values = [];
                    for(let i=0;i<values.length;i++){
                        if(values[i].element_id==='barcode'){
                          barcode_values.push(values[i]);
                        }
                    }
                    let billing_data = {barcode_name:barcode_sequence_settings.preview};

                    //this.barcode_template.push(<BarcodeTemplate id={0} key={0} billing_data={billing_data} tamplate_data={barcode_values} height={height} width={width}/>);
                    //this.setState({barcode_template:this.barcode_template});

                    if(barcode_sequence_settings.preview.length>10){
                      this.setState({barcode_error:this.state.barcode_error_msg});
                    }else{
                      this.setState({barcode_error:''});
                    }
                  }
                  if(resp.last_generated_barcode!==""){
                    this.setState({last_generated_barcode:resp.last_generated_barcode});
                  }
              }.bind(this)).catch(function(res){
                  if(res.response){
                    if(res.response.data){
                        
                    }
                  }
              })

          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })


      }
      if(list_tab || edit_template){
        if(edit_template){
          this.setState({mode:'edit'});
          this.setState({active_tab:'manager'});
        }else{
          this.setState({active_tab:'list'});
        }
      }

    if(settings_tab===null){

      let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
            access_level : user_info.access_level,
            key:'barcode_labels'
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.barcode_labels){
              let barcode_labels = resp.barcode_labels;
              this.setState({barcode_options:barcode_labels});
              let labels = [];
              for(let i=0;i<barcode_labels.length;i++){
                  let key = barcode_labels[i].key;
                  let value = barcode_labels[i].value;
                  labels[key]=[];
                  labels[key] = value;
                  //this.setState({barcode_labels[key]:labels});
              }
              this.setState({barcode_labels:labels});
            }
            
            if(list_tab || edit_template){
                    let id = 0;
                    if(edit_template){
                      id = edit_template[1];
                    }
                    let send_data = {
                          store_name : user_info.store_name,
                          branch_id : user_info.login_id,
                          user_id : user_info.user_id,
                          uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                          id:id
                      }
                      
                      let api = app_api+'/get_barcode_templates';
                      axios.post(api, send_data)
                        .then(function (response) {
                          let resp = response.data;
                        
                          this.setState({barcode_list:resp});
                          this.setState({show_save_btn:true});
                          this.setState({is_loading:false});
                          
                          if(edit_template){
                            let name = resp[0].name;
                            let is_default = resp[0].is_default;
                            let symbol_type = resp[0].symbol_type;
                            if(symbol_type===""){
                              symbol_type = 'barcode';
                            }
                            this.setState({symbol_type:symbol_type});
                            let height = resp[0].height;
                            let width = resp[0].width;
                            this.setState({sticker_unit:resp[0].unit_name});
                            if(name==='large' || name==='medium' || name==='small'){
                               height = this.state.sticker_sizes[name].height+'mm';
                               width = this.state.sticker_sizes[name].width+'mm';
                            }else{
                              this.setState({custom_value:name});
                              name = 'custom_barcode_template';

                            }
                            this.setState({sticker_height:height,sticker_width:width});
                            this.setState({defaultValue:name});
                            let cb = document.querySelectorAll(".temp-check");
                            for(let i=0;i<cb.length;i++){
                                let cbid = cb[i].id;
                                if(resp[0]['value'][cbid]){
                                  cb[i].checked = true;
                                  let lbl_name = resp[0]['value'][cbid].display_name;
                                  let font_size = resp[0]['value'][cbid].font_size;
                                  let font_weight = resp[0]['value'][cbid].font_weight;
                                  let element_height = resp[0]['value'][cbid].height;
                                  let position = resp[0]['value'][cbid].position;

                                  let show_label = resp[0]['value'][cbid].show_label;
                                  let label_bold = resp[0]['value'][cbid].label_bold;


                                  document.querySelector('[for='+cbid+']').innerHTML = lbl_name;
                                  document.querySelector("."+cbid+"-settings").classList.remove('hide');
                                  if(font_weight==='bold'){
                                    if(document.getElementById(cbid+"-font-weight")){
                                      document.getElementById(cbid+"-font-weight").checked = true;
                                    }
                                  }
                                  if(show_label===true){
                                    if(document.getElementById(cbid+"-show-label")){
                                      document.getElementById(cbid+"-show-label").checked = true;
                                    }
                                  }
                                  if(label_bold===true){
                                    if(document.getElementById(cbid+"-label-bold")){
                                      document.getElementById(cbid+"-label-bold").checked = true;
                                    }
                                  }
                                  if(position!==""){
                                    if(document.getElementById(cbid+"-position")){
                                      document.getElementById(cbid+"-position").value = position;
                                    }
                                  }
                                  if(font_size!=='' && font_size!==undefined){
                                    if(document.getElementById(cbid+"-font-size")){
                                      document.getElementById(cbid+"-font-size").value = font_size;
                                    }
                                  }
                                  if(element_height!=='' && element_height!==undefined){
                                    if(document.getElementById(cbid+"-height")){
                                      document.getElementById(cbid+"-height").value = element_height;
                                    }
                                  }
                                  if(resp[0]['value'][cbid].width!==undefined && resp[0]['value'][cbid].width!==''){
                                    if(document.getElementById(cbid+"-width")){
                                      document.getElementById(cbid+"-width").value = resp[0]['value'][cbid].width;
                                    }
                                  }
                                }
                            }

                            let template_items = resp[0].value;
                            for (var key of Object.keys(template_items)) {
                                
                                let position = {x:parseFloat(template_items[key].x),y:parseFloat(template_items[key].y)}
                                let lable_style = {fontSize:template_items[key].font_size,fontWeight:template_items[key].font_weight}

                                if(template_items[key].width!==undefined && template_items[key].width!==""){
                                  lable_style = {fontSize:template_items[key].font_size,fontWeight:template_items[key].font_weight,width:template_items[key].width,whiteSpace:'break-spaces',lineHeight:'1.2'}
                                }

                                let element_height = 15;
                                if(template_items[key].height!==undefined){
                                  element_height = template_items[key].height;
                                  if(element_height===""){
                                    element_height = 15;
                                  }
                                }
                                let element_width = 100;
                                if(template_items[key].width!==undefined){
                                  element_width = template_items[key].width;
                                  if(element_width===""){
                                    element_width = 100;
                                  }
                                }
                                if(key==='barcode'){
                                  if(symbol_type==='barcode' || symbol_type===""){
                                    this.barcode_template.push(<BarcodeDraggable key={key} position={position} target={key} element={<Barcode width={(element_width/100)} displayValue={false} key={key} value={"DEMO"} height={element_height}/>}/>);
                                  }else if(symbol_type==='qr'){
                                    this.barcode_template.push(<BarcodeDraggable key={key} position={position} target={key} element={<QRCode size={256} key={key} style={{ height: element_height, maxWidth: element_width, width: element_width }} value={"DEMO"} viewBox={`0 0 256 256`} />}/>);
                                  }
                                }else{
                                  this.template_items.push(key);
                                  let name = template_items[key].display_name;
                                  this.barcode_template.push(<BarcodeDraggable key={key} position={position} target={key} element={name} lable_style={lable_style}/>);
                                }
                            }
                            this.setState({barcode_template:this.barcode_template});
                            setTimeout(function(){
                              let draggable = document.querySelectorAll(".react-draggable");
                              for(let i=0;i<draggable.length;i++){
                                 let did = draggable[i].getAttribute('data_target');
                                 if( document.getElementById(did+"-position")){
                                  let position = document.getElementById(did+"-position").value;
                                  if(position==='right'){
                                    document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'right';
                                    document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
                                    }else if(position==='left'){
                                      document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'left';
                                      document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
                                    }else if(position==='center'){
                                      document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'center';
                                      document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
                                    }else if(position==='free'){
                                      //document.querySelector('[data_target='+did+']').style.transform = 'translate(0, 0)';
                                      document.querySelector('[data_target='+did+']').style.left = '0';
                                    }else if(position==='free-right'){
                                      //document.querySelector('[data_target='+did+']').style.transform = 'translate(0, 0)';
                                      document.querySelector('[data_target='+did+']').style.right = '0';
                                    }else if(position==='free-left'){
                                      //document.querySelector('[data_target='+did+']').style.transform = 'translate(0, 0)';
                                      document.querySelector('[data_target='+did+']').style.left = '0';
                                    }
                                  }
                              }
                            },50);
                          }
                      }.bind(this)).catch(function(res){
                          
                      })
                    }

            
            
        }.bind(this)).catch(function(res){
            
        })
      
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
              target : 'barcode_preview',
           }
        let prev_api = app_api+'/inventory/get_inventory_items';
        axios.post(prev_api, body)
          .then(function (response) {
            let cursor = response.data;
            let item_options = [];
            let barcode_preview_items = [];
            
            let purchase_item_options = [];
            cursor.map( (value, key) => {
                let id = value.inventory_id;
                let stat_lbl = '';
                if(value.inventory_status==='Draft'){
                  stat_lbl=' (Inactive)';
                }
                let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                let static_item_name  =   value.item_name;
                let barcode_name      =   value.barcode_name;
                if(barcode_name===null){
                  barcode_name = "@@@";
                }
                let sku = '@@@';
                if(value.sku!==null && value.sku!==""){
                  sku = value.sku;
                }
                let alias_barcode = '@@@';
                if(value.alias_barcode!==null && value.alias_barcode!==""){
                  alias_barcode = value.alias_barcode;
                }
                if(value.has_variation===false){
                    purchase_item_options[id] = {
                          value1:sku, 
                          value2:item_name,
                          value3:barcode_name,
                          value4:alias_barcode,
                          static_item_name:static_item_name,
                          company_name:user_info.company_name,
                          company_sub_title:user_info.company_sub_title,
                          address:user_info.address,
                          barcode_name:barcode_name,
                          product_name:static_item_name,
                          description:value.item_desc,
                          price:value.selling_price,
                          encrypted_purchase_price:value.enc_pp,
                          encrypted_selling_price:value.enc_sp,
                          category:value.category_name,
                          custom_text:value.custom_text,
                          varient:value.varient,
                          city:value.city,
                          state:value.state,
                          phone1:value.phone1,
                          phone2:value.phone2,
                          email:value.email,
                          contact_id:value.contact_id,
                          custom_fields_json:value.custom_fields_json,
                    };
                }
            });
            
            this.setState({ 'purchase_item_options': purchase_item_options}); 
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }
    }
    handleLableOption = (e) =>{
      let did = e.currentTarget.getAttribute('data-id');
      let type = e.currentTarget.type;
      let data_value = e.currentTarget.getAttribute('data-value');;
      let value = e.currentTarget.value;
      let qs = document.querySelectorAll('[data-target='+did+'-bc-lbl]');

      if(type==='checkbox'){
        if(e.currentTarget.checked){
          for(let i=0;i<qs.length;i++){
            if(data_value==='fontWeight'){
              qs[i].style.fontWeight = 'bold';
            }
            if(data_value==='display'){
              qs[i].style.display = '';
            }
          }
        }else{
         for(let i=0;i<qs.length;i++){
            if(data_value==='fontWeight'){
              qs[i].style.fontWeight = 'normal';
            }
            if(data_value==='display'){
              qs[i].style.display = 'none';
            }
          }
        }
      }
  }
    handleChangeSymbolType = (symbol_type) =>{
      this.setState({symbol_type:symbol_type});
      let temp = this.barcode_template;
      
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        let key = element.key;
        let position = element.props.position;
        
        if(key==='barcode'){
          if(symbol_type==='barcode'){
            this.barcode_template[index] = <BarcodeDraggable key={'barcode'} position={position} target={'barcode'} element={<Barcode width={1} displayValue={false} key={'barcode'} value={"DEMO"} height={15}/>}/>
          }else if(symbol_type==='qr'){
            this.barcode_template[index] = <BarcodeDraggable key={key} position={position} target={key} element={<QRCode size={256} key={key} style={{ height: 50, maxWidth: 50, width: 50 }} value={"DEMO"} viewBox={`0 0 256 256`} />}/>
          }
        }
      }
      this.setState({barcode_template:this.barcode_template});
    }
    render() {
        let is_disabled = false;
        if(this.state.mode==='edit'){
          is_disabled = true;
        }
        
        let icontent_pad = '30px 50px';
        if(this.state.active_tab==='manager'){
          icontent_pad = '0px 50px';
        }

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Barcode Management</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="nav-container">
                      <ul className="nav nav-pills" style={{position:'relative'}}>
                        <li className={this.state.active_tab==='settings'?'active':''}><a href="/settings/barcode-management/settings">Settings</a></li>
                        <li className={this.state.active_tab==='list'?'active':''}><a href="/settings/barcode-management">Barcode Designs</a></li>
                      
                        <li className={this.state.active_tab==='manager'?'active':''}><a href="/settings/barcode-management/manager">Designer Tool</a></li>
                        {(this.state.active_tab==='list') &&
                        <li className="create-new-bc">
                         <div className="inven-btn"><a href="/settings/barcode-management/manager"><button type="button" className="btn btn-success pull-right nav-btn">Create New Barcode</button></a>
                         </div>
                        </li>
                        }
                      </ul>
                  </div>
                  <div className="content-area">
                    
                    <div className="main-content border-bottom comp-detail-info">
                      <div className="inner-content" style={{padding: icontent_pad}}>
                      {this.state.active_tab==='manager' &&
                        <div className="row">
                            <div className="col-md-7" style={{borderRight:'1px solid #d7d7d7',padding:'0px'}}>
                              <div className="row">
                                <div className="col-md-12 barcode-item-list" style={{borderTop:'none'}}>
                                    
                                    <select id="tsize" className="form-control" onChange={this.handleChangeSize} value={this.state.defaultValue} disabled={is_disabled}>
                                      <option value="">Select Sticker Size</option>
                                      <option value="small">Small - {this.state.sticker_sizes.small.width}mm X {this.state.sticker_sizes.small.height}mm</option>
                                      <option value="medium">{this.state.sticker_sizes.medium.width} X {this.state.sticker_sizes.medium.height}</option>
                                      <option value="large">Large - {this.state.sticker_sizes.large.width}mm X {this.state.sticker_sizes.large.height}mm</option>
                                      <option value="custom_barcode_template">Custom</option>
                                    </select>
                                    {(this.state.defaultValue==='custom_barcode_template') &&
                                    <>
                                      <div className="row mrtop-10">
                                        <div className="col-md-12">
                                          <label htmlFor="custom-name">Name</label>
                                          <input type="text" className="form-control" id="custom-name" onChange={this.handleCustomTemplate} defaultValue={this.state.custom_value}/>
                                        </div>
                                      </div>
                                      <div className="row mrtop-10 mrbtm-10">
                                         <div className="col-md-4">
                                          <label htmlFor="custom-width">Width</label>
                                          <input type="text" className="form-control" id="custom-width"  onChange={this.handleCustomTemplate} defaultValue={this.state.sticker_width}/>
                                        </div>
                                        <div className="col-md-4">
                                          <label htmlFor="custom-height">Height</label>
                                          <input type="text" className="form-control" id="custom-height"  onChange={this.handleCustomTemplate} defaultValue={this.state.sticker_height}/>
                                        </div>
                                        <div className="col-md-4">
                                          <label htmlFor="custom-width">Unit</label>
                                          <select onChange={this.handleCustomUnitChange} className="form-control" id="temp-unit" defaultValue={this.state.sticker_unit}>
                                              <option value="mm">mm</option>
                                              <option value="cm">cm</option>
                                              <option value="px">px</option>
                                              <option value="in">inches</option>
                                              
                                          </select>
                                        </div>
                                      </div>
                                    </>
                                    }
                                </div>
                                {(this.state.show_save_btn===true) &&
                                  <>
                                <div className="col-md-12 barcode-item-list">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input temp-check" type="checkbox" id="barcode" onChange={this.handleBarcodeCheck}/>
                                      <label className="form-check-label chklbl" htmlFor="barcode">{(this.state.barcode_labels && this.state.barcode_labels['barcode'])?this.state.barcode_labels['barcode']:'Barcode'}</label> 
                                    </div>
                                    <LableSettings handleSettingsBarcodeChange = {this.handleSettingsBarcodeChange.bind(this)} settings={{target:'barcode',is_visible:false}}/>
                                </div>
                                {(this.state.barcode_options && this.state.barcode_options.length > 0) ?this.state.barcode_options.map( (value, index) => {
                                   return (
                                   <>
                                     {(value.is_visible) &&
                                        <div className="col-md-12  barcode-item-list">
                                          <div className="form-check form-check-inline" id={value.key+"-check-block"}>
                                            <input className="form-check-input temp-check" type="checkbox" id={value.key} onChange={this.handleCheckList} data-target={value.key}/>
                                            <label className="form-check-label chklbl" htmlFor={value.key} id={value.key+'-lbl'}>
                                                {value.value}
                                            </label>
                                            
                                            {(value.is_edit) &&
                                              <>
                                              <FontAwesomeIcon data-id={value.key} onClick={this.handleRenameLabel} icon={faPencilAlt} id={value.key+"-lbl-edit"} className="pull-right bar-lbl-rnm fa-icon"/>
                                             
                                              <input type="text" className="form-control hide" id={value.key+"-txt"} onChange={this.handleTextChange}/>
                                              <FontAwesomeIcon data-id={value.key} onClick={this.saveRenameLabel} id={value.key+"-lbl-save"} icon={faCheckCircle} className="hide pull-right fa-icon bar-lbl-save"/>
                                            
                                              </>
                                            }
                                          </div>
                                          {(value.small_txt!==undefined) &&
                                            <div>
                                              <span style={{fontSize: '10px',color: 'gray',fontStyle: 'italic'}}>({value.small_txt})</span>
                                            </div>
                                            }

                                            {(value.key.match(/cf\d+/)) &&
                                              <>
                                                <div className="col-md-3" style={{display:'contents',padding:'0px'}}>
                                                  <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" id={value.key+"-show-label"} defaultChecked={(value.show_label===true)?true:false}  data-id={value.key} data-value="display" onChange={this.handleLableOption}/>
                                                    <label className="form-check-label" htmlFor={value.key+"-show-label"} style={{fontSize:'12px'}}>
                                                        Show Label
                                                    </label>
                                                  </div>
                                                </div>         
                                                <div className="col-md-3" style={{display:'contents',padding:'0px'}}>
                                                  <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" id={value.key+"-label-bold"} defaultChecked={(value.label_bold===true)?true:false}  data-id={value.key} data-value="fontWeight" onChange={this.handleLableOption}/>
                                                    <label className="form-check-label" htmlFor={value.key+"-label-bold"} style={{fontSize:'12px'}}>
                                                        Label Bold
                                                    </label>
                                                  </div>
                                                </div>         
                                              </>
                                            }

                                          <LableSettings settings={{fontSize:'12',fontWeight:'normal',target:value.key,is_visible:false}}/>
                                      </div>
                                    }
                                    </>
                                  )
                                 }) : <div></div>}



                                
                                {/*
                                <div className="col-md-12 mrtop-10">
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input temp-check" type="checkbox" id="purchase-info" onChange={this.handleCheckList}/>
                                    <label className="form-check-label chklbl" htmlFor="purchase-info">{(this.state.barcode_labels && this.state.barcode_labels['purchase-info'])?this.state.barcode_labels['purchase-info']:'PURCHASE INFO'}</label>
                                    </div>
                                    <LableSettings settings={{fontSize:'12',fontWeight:'normal',target:'purchase-info',is_visible:false}}/>
                                </div>
                              */}
                                
                                {/*<div className="col-md-12 mrtop-10">
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input temp-check" type="checkbox" id="size" onChange={this.handleCheckList}/>
                                    <label className="form-check-label chklbl" htmlFor="size">{(this.state.barcode_labels && this.state.barcode_labels['size'])?this.state.barcode_labels['size']:'SIZE'}</label>
                                    </div>
                                    <LableSettings settings={{fontSize:'12',fontWeight:'normal',target:'size',is_visible:false}}/>
                                </div>*/}
                                {/*
                                <div className="col-md-12 mrtop-10">
                                   <label htmlFor="crypto-for-cost">Crypto for Cost</label>
                                   <input type="text" className="form-control" id="total-bp-foot" placeholder="Z-0,A-1,B-2 etc..."/>
                                </div>
                              */}
                              </>
                              }
                              </div>
                            </div>
                            <div className="col-md-5">
                                <>
                                <span id="preveiwBarcodeTempate" onClick={this.preveiwBarcodeTempate}></span>
                                {(this.state.show_save_btn===true) &&
                                
                                <div style={{maxWidth:'70%',margin:'15px auto'}}>
                                  <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="barcode_type" id="barcode_type1" defaultChecked={(this.state.symbol_type==='barcode')?true:false} onChange={(e)=>this.handleChangeSymbolType(e.target.value)} value={'barcode'}/>
                                  <label class="form-check-label" for="barcode_type1">
                                    Barcode
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="barcode_type" id="barcode_type2" defaultChecked={(this.state.symbol_type==='qr')?true:false} onChange={(e)=>this.handleChangeSymbolType(e.target.value)} value={'qr'}/>
                                  <label class="form-check-label" for="barcode_type2">
                                    QR Code
                                  </label>
                                </div>
                                  <Autocomplete
                                  options={this.state.purchase_item_options}
                                  data_target="barcode-preview"
                                  title="Start typing SKU or name to see the live preview..."  
                                  id={"iitem-name"}
                                  className="auto-comp-opt"
                                  data_id={1}
                                  active_tab={"barcode-preview"}
                                  input_id={"iitem-box"}
                                  item_info={this.props.items}
                                  target="iitem-box"
                                />
                                </div>
                                }
                                <div style={{height:this.state.sticker_height,width:this.state.sticker_width,margin:'40px auto',position:'relative'}}>
                                  <div id="sticker-box" className="sticker-box" style={{height:this.state.sticker_height,width:this.state.sticker_width}}>
                                    {this.barcode_template}
                                  </div>
                                  {(this.state.sticker_width!==0) &&
                                  <div style={{width:this.state.sticker_width}} className="sticker-width">{this.state.sticker_width}</div>
                                  }
                                  {(this.state.sticker_height!==0) &&
                                  <div style={{height:this.state.sticker_height,position:'absolute'}}  className="sticker-height"><div style={{position: 'absolute',top: '40%',transform: 'rotate(270deg)'}}>{this.state.sticker_height}</div>
                                  </div>
                                  }
                                </div>
                                </>
                            </div>
                        </div>
                      }
                      {this.state.active_tab==='list' &&
                          <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="box-container">
                                  <div className="box-head">
                                    <label>BARCODE TEMPLATES</label>
                                  </div>
                                  <div className="box-body">
                                      <table className="table">
                                        <tbody>
                                          {(this.state.is_loading===false) &&
                                            <>
                                              {(this.state.barcode_list.length > 0) ?this.state.barcode_list.map( (value, index) => {
                                              return (
                                                <tr key={ index }>
                                                  <td>
                                                    <span className="btempname">{value.name}</span>
                                                    {(value.is_default) &&
                                                      <i style={{fontSize:'12px'}}>  (Default)</i>
                                                    }
                                                  </td>
                                                  <td>
                                                      <a href={"/settings/barcode-management/edit/"+value.template_id}><FontAwesomeIcon id={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/></a>
                                                    
                                                      <FontAwesomeIcon onClick={this.deleteModal} id={value.template_id} data_target="delete_confirm" icon={faTrash} className="pull-right margin-5 fa-icon"/>
                                                  </td>
                                                </tr>
                                              )
                                            }) : <tr><td className="text-center">No record(s) found</td></tr> }
                                            </>
                                           }
                                          {(this.state.is_loading===true) &&
                                            <tr><td className="text-center">Loading...</td></tr>
                                          }
                                        </tbody>
                                      </table>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                      }
                      {this.state.active_tab==='settings' &&
                        <div>
                          <div style={{marginTop:'-30px'}}>
                            <div className="content-area">
                              <div className="content-head border-bottom" style={{paddingLeft:'0px'}}>
                                <div className="row">
                                  <div className="col-md-4">
                                    <b>Barcode Sequence</b>
                                    <p className="info">Generate Barcode Sequence</p>
                                  </div>
                                  <div className="col-md-8 text-right">
                                    <p className="info" style={{margin:'0px'}}>Last Generated Barcode</p>
                                    <h3>{this.state.last_generated_barcode}</h3>
                                  </div>
                                </div>
                              </div>
                                
                              <div className="row mrtop-10">
                                <div className="col-md-1">
                                  <label>Prefix</label>
                                  <input type="text" className="form-control barcode-sequence" id="barcode-prefix" data-id="barcode_prefix" placeholder="PF" onChange={this.handleChangeSequence} defaultValue={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_prefix)?this.state.barcode_sequence_settings.barcode_prefix:''}/>
                                </div>
                                <div className="col-md-2">
                                  <label>Separator</label>
                                  <select className="form-control barcode-sequence" data-id="barcode_separator" id="barcode-separator" onChange={this.handleChangeSequence}  value={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_separator)?this.state.barcode_sequence_settings.barcode_separator:''}>
                                    <option value=''>None</option>
                                    <option value='/'>/</option>
                                    <option value='.'>.</option>
                                    <option value='-'>-</option>
                                  </select>
                                </div>
                                <div className="col-md-2">
                                  <label>Category Sequence</label>
                                  <select className="form-control barcode-sequence" id="barcode-category-sequence" onChange={this.handleChangeSequence} data-id="barcode_category_sequence"  value={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_category_sequence)?this.state.barcode_sequence_settings.barcode_category_sequence:''}>
                                    <option value=''>None</option>
                                    <option value='1'>Category ID</option>
                                    <option value='CA'>First 2 letter of category</option>
                                    <option value='CSN'>Category Short Name</option>
                                  </select>
                                </div>
                                <div className="col-md-2">
                                  <label>Separator</label>
                                  <select className="form-control barcode-sequence" id="barcode-category-separator" onChange={this.handleChangeSequence} data-id="barcode_category_separator"  value={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_category_separator)?this.state.barcode_sequence_settings.barcode_category_separator:''}>
                                    <option value=''>None</option>
                                    <option value='/'>/</option>
                                    <option value='.'>.</option>
                                    <option value='-'>-</option>
                                  </select>
                                </div>
                                <div className="col-md-2">
                                  <label>Sequence Number</label>
                                  <input type="text" className="form-control barcode-sequence"  id="barcode-sequence-number" placeholder="001" onChange={this.handleChangeSequence} data-id="barcode_sequence_number" defaultValue={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_sequence_number)?this.state.barcode_sequence_settings.barcode_sequence_number:''}/>
                                </div>
                                <div className="col-md-2">
                                  <label>Separator</label>
                                  <select className="form-control barcode-sequence" id="barcode-sequence-separator" onChange={this.handleChangeSequence} data-id="barcode_sequence_separator"  value={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_sequence_separator)?this.state.barcode_sequence_settings.barcode_sequence_separator:''}>
                                    <option value=''>None</option>
                                    <option value='/'>/</option>
                                    <option value='.'>.</option>
                                    <option value='-'>-</option>
                                  </select>
                                </div>
                                <div className="col-md-1">
                                  <label>Sufix</label>
                                  <input type="text" className="form-control barcode-sequence"  id="barcode-sufix" placeholder="SF" onChange={this.handleChangeSequence} data-id="barcode_sufix" defaultValue={(this.state.barcode_sequence_settings && this.state.barcode_sequence_settings.barcode_sufix)?this.state.barcode_sequence_settings.barcode_sufix:''}/>
                                </div>
                              </div>
                           
                              {(this.state.is_sequence_set) && 
                              <div className="row mrtop-10 border-top">
                                <div className="col-md-12 mrtop-10">
                                  <label>Current Barcode Format</label>
                                  <h3 style={{display:'flex'}}>{this.state.barcode_preview} <p className="error" style={{fontSize:'16px',marginTop:'5px',marginLeft:'10px'}}>{this.state.barcode_error}</p></h3>
                                  
                                  {(this.barcode_template.length>0) &&
                                    <div id="sticker-box" className="sticker-box" style={{height:this.state.sticker_height,width:this.state.sticker_width,margin:'0px'}}>
                                      {this.barcode_template}
                                    </div>
                                  }
                                </div>
                              </div>
                              }
                              <div className="row mrtop-10">
                                <div className="col-md-12">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" onChange={this.handleCheckAutoGenerateBarcode} checked={this.state.is_auto_generate_barcode} id="auto-generate-barcode"/>
                                    <label className="form-check-label" htmlFor="auto-generate-barcode">Auto generate barcode while adding the inventory</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{marginTop:'30px',borderTop:'1px solid #d7d7d7'}}>
                            <div className="row" style={{marginTop:'20px'}}>
                              <div className="col-md-12">
                                <button type="button" id="save-barcode-sequence" onClick={this.handleSaveBarcodeSequence} className="btn btn-success pull-right">Save Sequence</button>
                                <button type="button" id="save-barcode-sequence-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      </div>
                    </div>
                    {(this.state.show_save_btn && this.state.active_tab==='manager') &&
                    <div className="content-head border-bottom">
                      <div className="row mrbtm-10">
                        <div className="col-md-7">
                        </div>
                        <div className="col-md-5">
                          <button type="button" id="save-barcode" onClick={this.handleSaveTemplate} className="btn btn-success pull-right">Save Template</button>
                          <button type="button" id="save-barcode-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                          <div className="form-check form-check-inline pull-right mrtop-5">
                            <input className="form-check-input" type="checkbox" onChange={this.handleCheckDefault} id="default-template" checked={this.state.is_default}/>
                            <label className="form-check-label" htmlFor="default-template">Make it default template</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  </div>
                </div>
              </div>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
            </div>
        );
    }
}

class LableSettings extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.handleSettingsOptChange = this.handleSettingsOptChange.bind(this);
      this.handleSettingsBarcodeChange = this.handleSettingsBarcodeChange.bind(this);
  }
  handleSettingsBarcodeChange(e){
    this.props.handleSettingsBarcodeChange(e);
  }
  handleSettingsOptChange(e){
      let did = e.currentTarget.getAttribute('data-id');
      let type = e.currentTarget.type;
      let data_value = e.currentTarget.getAttribute('data-value');;
      let value = e.currentTarget.value;
      let qs = document.querySelectorAll('[data-target=lbl-'+did+']');

      if(type==='checkbox'){
        if(e.currentTarget.checked){
          for(let i=0;i<qs.length;i++){
            if(data_value==='fontWeight'){
              qs[i].style.fontWeight = 'bold';
            }
          }
        }else{
         for(let i=0;i<qs.length;i++){
            if(data_value==='fontWeight'){
              qs[i].style.fontWeight = 'normal';
            }
          }
        }
      }else if(type==='text'){
        for(let i=0;i<qs.length;i++){
          if(data_value==='fontSize'){
            if(value!==''){
              qs[i].style.fontSize = value+'px';
            }else if(value===''){
              qs[i].style.fontSize = '12px';
            }
          }else if(data_value==='width'){
            if(value!==''){
              qs[i].style.width = value+'px';
              qs[i].style.whiteSpace = 'break-spaces';
              qs[i].style.lineHeight = 1.2;
            }else if(value===''){
              
            }
          }
        }
      }else if(type==='select-one'){
        let trans = document.querySelector('[data_target='+did+']').style.transform;
        
        for(let i=0;i<qs.length;i++){
          document.querySelector('[data_target='+did+']').removeAttribute('style');
          if(value==='right'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'right';
            document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
          }else if(value==='left'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'left';
            document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
          }else if(value==='center'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+'] .handle').style.textAlign = 'center';
            document.querySelector('[data_target='+did+']').style.setProperty("position", "relative", "important");;
          }else if(value==='free'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+']').style.left = '0';
          }else if(value==='free-right'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+']').style.right = '0';
            //document.querySelector('[data_target='+did+']').style.top = '0';
          }else if(value==='free-left'){
            document.querySelector('[data_target='+did+']').style.transform = trans;
            document.querySelector('[data_target='+did+']').style.left = '0';
            //document.querySelector('[data_target='+did+']').style.top = '0';
          }
        }
      }
  }
  render() {
    let is_bold = false;
    if(this.props.settings && this.props.settings.fontWeight==='bold'){
      is_bold = true;
    }
    let is_visible = '';
    if(this.props.settings && this.props.settings.is_visible===false){
      is_visible = 'hide';
    }
    return  <div className={this.props.settings.target+"-settings "+is_visible} key={this.props.settings.target} id={this.props.settings.target+"-settings-blk"}>
              {(this.props.settings.target!=='barcode') &&
                <>
              <div className="row item-child">
                            
                  {(this.props.settings.target==='description') &&
                    <>
                      <div className="col-md-3" style={{display:'contents',padding:'0px'}}>
                          <div style={{marginTop:'-2px'}} className="width"><label style={{fontSize:'12px'}}>Width</label></div>
                            <div className="input-group" style={{width:'60px',marginTop:'-2px',marginLeft:'5px'}}>
                              <input type="text" className="form-control" defaultValue={this.props.settings.width} style={{borderRight:'none',height:'27px',paddingLeft:'5px',paddingRight:'5px'}} id={this.props.settings.target+"-width"} data-id={this.props.settings.target} onKeyUp={this.handleSettingsOptChange}  data-value="width"/>
                              <div className="input-group-append" style={{height:'27px'}}>
                                <span className="input-group-text" style={{paddingLeft:'0px',paddingRight:'5px'}}>px</span>
                              </div>
                            </div>
                      </div>         
                    </>
                  }
                <div className={(this.props.settings.target==='description')?"description col-md-3":"col-md-4"} style={{padding:'0px'}}>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id={this.props.settings.target+"-font-weight"} defaultChecked={is_bold} onChange={this.handleSettingsOptChange} data-id={this.props.settings.target} data-value="fontWeight"/>
                    <label className="form-check-label" htmlFor={this.props.settings.target+"-font-weight"} style={{fontSize:'12px'}}>
                        Font bold
                    </label>
                  </div>
                </div>
                <div className={(this.props.settings.target==='description')?"col-md-3":"col-md-5"}  style={{display:'contents',padding:'0px'}}>

                      <div style={{marginTop:'-2px'}} className="font-size"><label style={{fontSize:'12px'}}>Font size </label></div>
                      <div className="input-group" style={{width:'60px',marginTop:'-2px',marginLeft:'5px'}}>
                        <input type="text" className="form-control" defaultValue={this.props.settings.fontSize} style={{borderRight:'none',height:'27px',paddingLeft:'5px'}} id={this.props.settings.target+"-font-size"} data-id={this.props.settings.target} onKeyUp={this.handleSettingsOptChange}  data-value="fontSize"/>
                        <div className="input-group-append" style={{height:'27px'}}>
                          <span className="input-group-text" style={{paddingLeft:'0px',paddingRight:'5px'}}>px</span>
                        </div>
                      </div>
                </div>
                <div className="col-md-3" style={{padding:'0px',borderLeft:'1px solid #d7d7d7',margin:'-4px 0px 0 10px'}}>

                  <select className="form-control lbl-pos" id={this.props.settings.target+"-position"} onChange={this.handleSettingsOptChange} data-id={this.props.settings.target} data-value="position" >
                    {(this.props.settings.target!=='barcode-name') &&
                      <>
                        <option value="free-right">Right</option>
                        <option value="center">Center</option>
                        <option value="free-left">Left</option>
                      </>
                    }
                    {(this.props.settings.target==='barcode-name') &&
                      <>
                        <option value="right">Right</option>
                        <option value="center">Center</option>
                        <option value="left">Left</option>
                      </>
                    }
                  </select>

                </div>
              </div>
              </>
            }
            {(this.props.settings.target==='barcode') &&
             <div className="row item-child">
                <div className="col-md-4">
              
                  <div style={{display: 'flex',width: '110px',marginLeft: '22px'}}>
                    <div style={{marginTop:'-2px'}}><label style={{fontSize:'12px'}}>Width </label></div>
                    <div style={{width:'68px',marginTop:'-2px'}}>
                      <input type="text" className="form-control" defaultValue={100} style={{height:'27px',float:'right',width:'60px'}} id={this.props.settings.target+"-width"} data-id={this.props.settings.target} onKeyUp={this.handleSettingsBarcodeChange}  data-value="barcode_width"/>
                    </div>
                  </div>
                
                </div>
                <div className="col-md-5" style={{display:'contents',padding:'0px'}}>
                  <div style={{display: 'flex',width: '110px',marginLeft: '22px'}}>
                    <div style={{marginTop:'-2px'}}><label style={{fontSize:'12px'}}>Height </label></div>
                    <div style={{width:'68px',marginTop:'-2px'}}>
                      <input type="text" className="form-control" defaultValue={15} style={{height:'27px',float:'right',width:'60px'}} id={this.props.settings.target+"-height"} data-id={this.props.settings.target} onKeyUp={this.handleSettingsBarcodeChange}  data-value="barcode_height"/>
                    </div>
                  </div>
                </div>
                <div className="col-md-3" style={{padding:'0px',borderLeft:'1px solid #d7d7d7',margin:'-4px 0px 0 3px'}}>
                  <select className="form-control lbl-pos" id={this.props.settings.target+"-position"} onChange={this.handleSettingsOptChange} data-id={this.props.settings.target} data-value="position">
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                      <option value="left">Left</option>
                  </select>
                </div>
              </div>
            }
            </div>
  }
}