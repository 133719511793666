import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let TypeList = [];
let _this;
let db;
export default class ContactTypes extends Component {
    constructor(props) {
      super(props);
      this.state = {
          type_list : [],
          delete_info : [],
          typeDefault : 0,
          
      };
      _this = this;
      this.helpOpt  = ['Ctrl+Alt+1'];
      this.editMode = this.editMode.bind(this);
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }
    handleChange(e){
        let contact_type = e.currentTarget.value;
        this.setState({typeDefault:contact_type});
        let ctype = document.getElementById("contact-type-select");
        if(ctype.selectedIndex>=0){
          let ptxt = ctype.options[ctype.selectedIndex].text.toLowerCase();
          if(ptxt==='supplier' || ptxt==='customer'){
            if(document.getElementById("agent-name-blk")){
              document.getElementById("agent-name-blk").classList.remove('hide');
              localStorage.setItem('agent_toggle','');
              setTimeout(function(){
                if(document.getElementById("resetAgentToggle")){
                  document.getElementById("resetAgentToggle").click();
                }
              },100);
            }
          }else{
            if(document.getElementById("agent-name-blk")){
              document.getElementById("agent-name-blk").classList.add('hide');
              document.querySelector("[name=agent_name]").value="";
              localStorage.setItem('agent_toggle','hide');
              setTimeout(function(){
                if(document.getElementById("resetAgentToggle")){
                  document.getElementById("resetAgentToggle").click();
                }
              },100);
            }
          }
        }

        let target = this.props.target;
        if(target==='relevant_contact_type_ssr' || target==='relevant_contact_type_ppr'){
            let send_data = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                name : target,
                value : contact_type,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            }
            
            let api = app_api+'/save_option';
            axios.post(api, send_data)
              .then(function (response) {
            }.bind(this)).catch(function(res){
                
            })
        }

        if(this.props.onChange){
            this.props.onChange(e);
        }
    }
    componentDidMount(){
      
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_contact_type';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            cursor.map( (value, key) => {
                let ki = key+2;
                let h = 'Ctrl+Alt+'+ki;
                this.helpOpt.push(h);
                let id = value.contact_type_id;
                let type = value.type;
                TypeList[id] = type;
            });
            this.setState({ 'type_list': TypeList });
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }



    editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('ct-span-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show");
    }

    updateRow(event){
      
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('ct-span-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);

      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            type : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      let api = app_api+'/update_contact_type';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('ct-span-'+id);
      var text = document.getElementById('ct-txt-'+id);
      var normal = document.getElementById('ct-normal-mode-'+id);
      var edit = document.getElementById('ct-edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
    
    deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
 
    render() {
        
        return (
          <div>
            {this.props.show==='list' &&
            
            <table className="table">
              <tbody>
                  {(this.state.type_list.length > 0) ?this.state.type_list.map( (value, index) => {
                   return (
                    <tr key={ index } id={'delete_contact_type_'+index}>
                      <td>
                        <span id={'ct-span-'+index } className="show l-span">
                          { value }
                        </span>
                        <input type="text" defaultValue={value} className="form-control hide" id={'ct-txt-'+index}/>
                      </td>
                      <td>
                        <div id={'ct-normal-mode-'+index} className="show">
                        {this.is_access('settings-contacts-modify') &&
                        <>
                        {(value!=='Supplier' && value!=='Customer') &&
                          <FontAwesomeIcon id={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                        }
                        </>
                        }
                        {this.is_access('settings-contacts-delete') &&
                        <>
                          {(value!=='Supplier' && value!=='Customer') &&
                            <FontAwesomeIcon id={index} onClick={this.deleteRow} data_target="delete_confirm" data_value={value} data_id="delete_contact_type" icon={faTrash} className="pull-right fa-icon"/>
                          }
                        </>
                        }
                        </div>
                        <div id={'ct-edit-mode-'+index} className="hide mrtop-10">
                          {this.is_access('settings-contacts-modify') &&
                          <>
                          <FontAwesomeIcon id={index} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                          <FontAwesomeIcon id={index} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                          </>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody>
            </table>
           
            }       

            {this.props.show==='dropdown' &&  
             
              <select onChange={this.handleChange} className="form-control validate form-field" id="contact-type-select" name={this.props.name} value={this.state.typeDefault||this.props.default} tabindex={this.props.tabindex} autoFocus>
                 
                  {(this.state.type_list.length > 0) ? this.state.type_list.map( (value, index) => {
                   return (
                      <option value={index} key={index}>{value}</option>
                    )
                  }) : ''}
              </select>
             
            }   
            {this.props.show==='nav-items' &&
              <>   
              <ul className="nav nav-pills" data-id={this.props.current_url}>
                {/* <li className={this.props.current_url==='contacts'?'active':''} key="/contacts" data-id={0}><a href="/contacts">All</a></li> */}
                {(this.props.is_accounting_module===true) &&
                  <li className={this.props.current_url==='accounting'?'active':''} key="/accounting" data-id={0}><a href="/accounting">Bank Accounts</a></li>
                }
                {/* {(this.props.is_accounting_module===true) &&
                  <li className={this.props.current_url==='accounts'?'active':''} key="/accounts" data-id={0}><a href="/accounts">Accounts</a></li>
                } */}
                {(this.state.type_list.length > 0) ? this.state.type_list.map( (value, index) => { 
               return (
                  <>
                  {(value.toLowerCase()!=='other' && value.toLowerCase()!=='others' ) &&
                    <li data-id={index} className={this.props.current_url==index?'active':''} key={'/contacts/'+index}><a href={'/contacts/'+index}>{value}</a></li>
                  }
                  {(value.toLowerCase()==='other' || value.toLowerCase()==='others' ) &&
                    <li data-id={index} className={this.props.current_url==index?'active':''} key={'/contacts/'+index}><a href={'/contacts/'+index}>Accounts</a></li>
                  }
                  </>
                )
              }) : ''}
              </ul>
              <ShortcutHelp target="contacts-tab"  arrow="top" shortcuts={this.helpOpt}/>
              </>
            }        
            </div>
        );
    }
}