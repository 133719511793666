import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import Leftnav from "../leftnav";
import axios from 'axios';
import ModalBox from "../modal-box";
import ExpenseTypes from "../expense-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
let {enc_key,app_api,user_info,states} = require('../../library.js');
export default class SettingExpenses extends Component {
     constructor() {
      super();
      this.state = {
        more_rows : '',
      }
      this.more_rows = [];
     
      this.openModal = this.openModal.bind(this);
      
      this.is_access = check_access.is_access.bind(this);
    }
    
    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }

    componentDidMount(){
    }


    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Expenses</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                        <div className="row">
                             <div className="col-md-6">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>EXPENSE TYPE</label>
                                  {this.is_access('settings-bill-payment-create') &&
                                    <FontAwesomeIcon onClick={this.openModal} data_target="add_expense_types"  icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                  }
                                </div>
                                <div className="box-body">
                                  <ExpenseTypes show="list" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                             
                            </div>
                        </div>
                     </div>
                  </div>
                </div>
              </div>
              <ModalBox id="add_expense_types" title="ADD EXPENSE TYPE" data_id="EXPENSE TYPE"/>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
            </div>
        );
    }
}