import React, {Component} from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/dancing-script";

import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class CRDRPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
      
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }   

    componentDidMount(){
        this.handlePrint();
    }
    handlePrint(){
        if(document.getElementById("print-main-container")){
        var divContents = document.getElementById("print-main-container").innerHTML; 
        try{
            var a = window.open('', '', 'height=1000,location=no,left=250px'); 
            a.document.open(); 
            a.document.write('<html><head> <meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge">'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 

            a.document.write('<style type="text/css">@page{size:A4,margin:5cm}.table{font-size:12px;font-family:initial}.table{width:100%;margin-bottom:1rem;color:#212529}.table .thead-light th{font-size:11px}.table .thead-light th{color:#9a9494}.table .thead-light th{color:#495057;background-color:#e9ecef !important;border-color:#dee2e6}.table td,.table th{padding:.75rem;vertical-align:top !important}th{text-align:inherit}.table .thead-light th{white-space: nowrap;}.page-break{page-break-after: always;}.table.cell-border>tbody>tr>td, .table.cell-border>tbody>tr>th, .table.cell-border>tfoot>tr>td, .table.cell-border>tfoot>tr>th, .table.cell-border>thead>tr>td, .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td,.table>thead>tr>th {border-bottom: 1px solid #333;}h4{margin-bottom:0px}p{font-weight:normal}</style>'); 
            a.document.write('</head>'); 
            a.document.write('<body onload="window.print();">'); 
            a.document.write(divContents); 
            a.document.write('</body></html>');
            a.document.close();
        }catch{}
        this.handleClosePrint()
      }
    } 
    handleClosePrint = () =>{
        this.props.handleClosePrint();
    }
    render() {
        return (
            <div id="print-main-container" style={{display:'none'}}>
                
                <div style={{padding:'10px'}}>
                    <table className="table page-break cell-border">
                        <thead>
                            <tr>
                                <th colSpan={2} style={{textTransform:'uppercase',textAlign:'center'}}>
                                    
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info)?this.props.print_data.company_info[0].company_name:''
                                        }
                                    </h2>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0]!==undefined && this.props.print_data.company_info[0].company_sub_title!=="" && this.props.print_data.company_info[0].company_sub_title!==null) &&
                                        <>
                                        <p style={{color:'gray',fontSize:'14px',margin:'0px'}}>{this.props.print_data.company_info[0].company_sub_title}</p>
                                        </>
                                        }
                                    
                                  
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].address!=='' && this.props.print_data.company_info[0].address!==null)?this.props.print_data.company_info[0].address:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].city!=='' && this.props.print_data.company_info[0].city!==null)?this.props.print_data.company_info[0].city:''
                                        }
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].pin_code!=='' && this.props.print_data.company_info[0].pin_code!==null )?'-'+this.props.print_data.company_info[0].pin_code:''
                                        }
                                        {(this.props.print_data && this.props.print_data.company_info && this.props.print_data.company_info[0].state!=='' && this.props.print_data.company_info[0].state!==null )?' '+this.props.print_data.company_info[0].state:''
                                        }
                                        </p>
                                        <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                           {this.props.type} (on {this.props.bill_type})
                                        </h2>
                                </th>
                            </tr>
                            <tr>
                                <th style={{textTransform:'uppercase',textAlign:'left'}}>
                                    
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info)?this.props.print_data.contact_info[0].name:''
                                        }
                                    </h2>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].address_1!=='' && this.props.print_data.contact_info[0].address_1!==null)?this.props.print_data.contact_info[0].address_1:''
                                        }
                                        </p>
                                        <p style={{margin:'0px'}}>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].city!=='' && this.props.print_data.contact_info[0].city!==null)?this.props.print_data.contact_info[0].city:''
                                        }
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].pin!=='' && this.props.print_data.contact_info[0].pin!==null )?'-'+this.props.print_data.contact_info[0].pin:''
                                        }
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].state!=='' && this.props.print_data.contact_info[0].state!==null )?' '+this.props.print_data.contact_info[0].state:''
                                        }
                                        </p>
                                        {(this.props.print_data && this.props.print_data.contact_info && this.props.print_data.contact_info[0].gst_number!=='' && this.props.print_data.contact_info[0].gst_number!==null ) && 
                                            <p>GSTIN - {this.props.print_data.contact_info[0].gst_number}</p>
                                        }
                                </th>
                                <th>
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                       SR. NO. {(this.props.print_data && this.props.print_data.sequence_number)?this.props.print_data.sequence_number:''
                                        }
                                    </h2>
                                    <p>
                                       DATE : {(this.props.print_data && this.props.print_data.created_date)?this.props.print_data.created_date:''
                                        }
                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <th colSpan={2} style={{textAlign:'center'}}>
                                    <h2 style={{margin:'0px',fontWeight:'bold',fontSize:'18px'}}>
                                    MEMO/NOTE AMOUNT : {(this.props.print_data && this.props.print_data.amount)?'₹'+this.currency_format(this.props.print_data.amount.replace(/,/g , "")):0}
                                    </h2>
                                    <p style={{textTransform:'uppercase'}}>
                                        {this.convert_number_to_words(this.props.print_data.amount.replace(/,/g , ""))} only
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <p>TERMS & CONDITIONS</p>
                                    <ol>
                                        <li>
                                            COMPLAINTS, IF ANY REGARDING THIS INVOICE MUST BE INFORMED IN WRITING WITHIN 24 HOURS
                                        </li>
                                        <li>
                                            WE ARE NOT RESPONSIBLE FOR LOSS OR DAMAGE DURING TRANSIT.
                                        </li>
                                    </ol>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table style={{width:'100%'}}>
                                        <tbody style={{textAlign:'center',fontSize:'12px'}}>
                                            <tr>
                                                <td style={{verticalAlign: 'bottom !important'}}>
                                                    <p>CHECKED BY</p>
                                                </td>
                                                <td style={{verticalAlign: 'bottom !important'}}>
                                                <p>RECEIVER'S SIGNATURE</p>
                                                </td>
                                                <td style={{verticalAlign: 'bottom !important'}}>
                                                <p>AUTH SIGNATORY</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}