import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faPencilAlt  } from '@fortawesome/free-solid-svg-icons';
let remove_items = [];
export default class PaymentItemRow extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            item_total : 0,
        };
        this.listItemRemove = this.listItemRemove.bind(this);
        this.listItemEdit = this.listItemEdit.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }

    listItemRemove(e){
      this.props.listItemRemove(e);
    }

    listItemEdit(e){
      this.props.listItemEdit(e);
    }
    handlePriceChange(e){
      let thisval = e.currentTarget.value;
      this.setState({item_total:thisval});
      let did = e.currentTarget.getAttribute('data-id');
      let payment_type = document.getElementById("payment-type-"+did).innerHTML;
      let is_old_balance = "";
      let is_general = false;
      if(document.getElementById("pay-bill-amt-"+did)){
        let bal_amt = document.getElementById("pay-bill-amt-"+did).value;
        
        if(bal_amt!=="" && thisval!==""){
           thisval = parseFloat(thisval);
           bal_amt = parseFloat(bal_amt);
           if(thisval>bal_amt){
            e.currentTarget.value = bal_amt; 
           }
        }
      }
      if(document.getElementById("is-item-old-balance-"+did)){
        is_old_balance = document.getElementById("is-item-old-balance-"+did).value;
        if(is_old_balance.indexOf('Opening Balance')>=0){
          is_general = true;
        }
      }
      if(payment_type==="general" && is_general===false){
        document.getElementById("item-total-old-"+did).value = thisval;
      }

      let qs = document.querySelectorAll(".item-total");
      let bill_total = 0;
      let payment_total = 0;
      for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        let data_action = element.getAttribute('data-action');
        let thisval = element.value;
        if(thisval===""){
          thisval = 0;
        }
        thisval = parseFloat(thisval);
        if(data_action!=='sale_return' && data_action!=='purchase_return' && data_action!=='general' && data_action!=='payment' && data_action!=='credit_note' && data_action!=='debit_note'){
          bill_total = bill_total + thisval;
        }
        if(data_action==='sale_return' || data_action==='purchase_return' || data_action==='payment' || data_action==='general' || data_action==='credit_note' || data_action==='debit_note'){
          payment_total = payment_total + thisval;
        }
      }
      if(document.getElementById("bill-item-total")){
        document.getElementById("bill-item-total").innerHTML = utils.currency_format(bill_total);
      }
      if(document.getElementById("pay-item-total")){

        document.getElementById("pay-item-total").innerHTML = utils.currency_format(payment_total);
      }
      let old_amount = document.getElementById("item-total-old-"+did).value;
      if(document.getElementById("calculateTotal")){
        document.getElementById("calculateTotal").click();
      }
      if(document.getElementById("handleCalulateAdjustedAmount")){
        document.getElementById("handleCalulateAdjustedAmount").click();
      }
    }
    componentDidMount(){

       let more_row_len = document.getElementsByClassName("items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
       }
       let total_bills = 0;
       let total_payments = 0;
       let qs = document.querySelectorAll('.item-total');
       for (let index = 0; index < qs.length; index++) {
        const element = qs[index];
        let thisval = element.value;
        if(thisval===""){
          thisval = 0;
        }
        thisval = parseFloat(thisval);
        let data_action = element.getAttribute('data-action');
        if(data_action==='payment' || data_action==='credit_note' || data_action==='debit_note'){
          total_payments = total_payments+thisval;
        }else{
          total_bills = total_bills+thisval;
        }
       }
        if(document.getElementById("pay-item-total")){
           document.getElementById("pay-item-total").innerHTML = utils.currency_format(total_payments);
        }
        if(document.getElementById("bill-item-total")){
          document.getElementById("bill-item-total").innerHTML = utils.currency_format(total_bills);
        }
    }
  
    render() {
        return (
          <tr className="items-row payment-item-row" data_id={this.props.index} id={"item-row-"+this.props.index} key={this.props.index}>
            <td className="item-name" style={{display:(this.props.payment_type==='billwise' && this.props.invoice_info.target!=='payment' && this.props.invoice_info.action!=='payment' && (this.props.invoice_info.target!=='general' || this.props.invoice_info.is_old_balance==='Opening Balance')  && this.props.invoice_info.target!=='credit_note' && this.props.invoice_info.target!=='debit_note')?'none':''}}>

              <span className="payment-mode" id={"payment-mode-span-"+this.props.index}>
                {(this.props.items && this.props.invoice_info.payment_mode && this.props.payment_type!=='billwise') &&
                  <>{this.props.invoice_info.payment_mode_txt}</>
                }
                {(this.props.items && this.props.invoice_info.payment_mode && this.props.payment_type==='billwise' && (this.props.invoice_info.target==='payment' || this.props.invoice_info.action==='payment' || this.props.invoice_info.target==='general' || this.props.invoice_info.target==='credit_note' || this.props.invoice_info.target==='debit_note')) &&
                  <>
                    {(this.props.invoice_info.target==='sale_return' || this.props.invoice_info.target==='purchase_return') &&
                      <>On Account</>
                    }
                    {(this.props.invoice_info.target!=='sale_return' && this.props.invoice_info.target!=='purchase_return') &&
                      <>
                        {this.props.invoice_info.payment_mode_txt}
                      </>
                    }
                  </>
                }
                {(this.props.items && this.props.invoice_info.payment_mode && this.props.payment_type==='billwise' && (this.props.invoice_info.target!=='payment' && this.props.invoice_info.action!=='payment' && this.props.invoice_info.target!=='general' && this.props.invoice_info.target!=='credit_note' && this.props.invoice_info.target!=='debit_note')) &&
                  <>Credit</>
                }
              </span>

              <input type="hidden" data_id={this.props.index} id={"item-payment-mode-"+this.props.index} target="item-payment-mode" defaultValue={(this.props.items && this.props.invoice_info.payment_mode)?this.props.invoice_info.payment_mode:0} className="p-item-payment-mode"/>

              <span className="payment-type hide" id={"payment-type-"+this.props.index}>{(this.props.items && this.props.invoice_info.payment_type)?this.props.invoice_info.payment_type:''}</span>
            </td>
            {(this.props.items && this.props.invoice_info.payment_type && this.props.invoice_info.payment_type==='invoice') &&
            <td className="item-name">
              <span>{(this.props.items && this.props.invoice_info.item_name)?this.props.invoice_info.item_name:''}</span><br />

               <input type="hidden" data_id={this.props.index} id={"item-schema-"+this.props.index} target="item-schema" defaultValue={(this.props.items && this.props.invoice_info.schema)?this.props.invoice_info.schema:''} className="p-item-schema"/>

               <input type="hidden" data_id={this.props.index} id={"item-box-"+this.props.index} target="item-box" defaultValue={(this.props.items && this.props.invoice_info.item_name)?this.props.invoice_info.item_name:''} className="p-item-box"/>

              <input type="hidden" data-id={this.props.index} id={"item-list-id-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.list_item_id)?this.props.invoice_info.list_item_id:''}/>
              <input type="hidden" className="item-ids" data-id={this.props.index} id={"item-name-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.item_id)?this.props.invoice_info.item_id:''}/>
              <input type="hidden" id={"old-item-name-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.item_id)?this.props.invoice_info.item_id:''}/>
              
              <input type="hidden" data_id={this.props.index} id={"is-item-old-balance-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.is_old_balance!==undefined && this.props.invoice_info.is_old_balance==='Direct / Advance Payment' || this.props.invoice_info.is_old_balance==='Opening Balance' || this.props.invoice_info.item_id==='balance_amount' || this.props.invoice_info.is_old_balance==='Advance Payment' || this.props.invoice_info.is_old_balance==='Payment Due')?this.props.invoice_info.is_old_balance:'Advance'}/>
            </td>
           }
            {(this.props.items && this.props.invoice_info.payment_type && this.props.invoice_info.payment_type==='general') &&
            <td>
              {(this.props.items && this.props.invoice_info.is_old_balance!==undefined && this.props.invoice_info.is_old_balance==='Direct / Advance Payment' || this.props.invoice_info.is_old_balance==='Opening Balance' || this.props.invoice_info.item_id==='balance_amount' || this.props.invoice_info.is_old_balance==='Advance Payment' || this.props.invoice_info.is_old_balance==='Payment Due' || this.props.invoice_info.target==='payment')?this.props.invoice_info.is_old_balance:'Advance'}

              <input type="hidden" data_id={this.props.index} id={"is-item-old-balance-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.is_old_balance!==undefined && this.props.invoice_info.is_old_balance==='Direct / Advance Payment' || this.props.invoice_info.is_old_balance==='Opening Balance' || this.props.invoice_info.item_id==='balance_amount' || this.props.invoice_info.is_old_balance==='Advance Payment'|| this.props.invoice_info.is_old_balance==='Payment Due' || this.props.invoice_info.target==='payment')?this.props.invoice_info.is_old_balance:'Advance'}/>

              <input type="hidden" data_id={this.props.index} id={"item-box-"+this.props.index} target="item-box"/>

              <input type="hidden" data_id={this.props.index} id={"item-schema-"+this.props.index} target="item-schema" className="p-item-schema"/>

              <input type="hidden" data-id={this.props.index} id={"item-list-id-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.item_pk_id)?this.props.invoice_info.item_pk_id:0}/>

              <input type="hidden" className="iitem-ids" data-id={this.props.index} id={"item-name-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.item_pk_id)?this.props.invoice_info.item_pk_id:0}/>

              <input type="hidden" id={"old-item-name-"+this.props.index} defaultValue={(this.props.items && this.props.invoice_info.item_pk_id)?this.props.invoice_info.item_pk_id:0}/>
            </td>
            }
            {(this.props.is_cheque_payment) && 
              <>
              {(this.props.invoice_info && this.props.invoice_info.cheque_no && this.props.invoice_info.cheque_no!=="") &&
              <>
              
                <td>
                  <input style={{width:'130px'}} type="text" className="form-control item-cheque-bank-name" placeholder="" id={"item-cheque-bank-name-"+this.props.index} data-id={this.props.index}  data-target="item-cheque-bank-name" defaultValue={(this.props.invoice_info?this.props.invoice_info.cheque_bank_name:'')}/>
                </td>
                <td>
                  <input style={{width:'130px'}} type="text" className="form-control item-cheque-no" placeholder="" id={"item-cheque-no-"+this.props.index} data-id={this.props.index}  data-target="item-cheque-no" defaultValue={(this.props.invoice_info?this.props.invoice_info.cheque_no:'')}/>
                </td>
                <td>
                  <input style={{width:'130px'}} type="text" className="form-control item-cheque-date" placeholder="dd/mm/yyyy" id={"item-cheque-date-"+this.props.index} data-id={this.props.index}  data-target="item-cheque-date" defaultValue={(this.props.invoice_info?this.props.invoice_info.cheque_date:'')}/>

                  <input style={{width:'130px'}} type="hidden" className="form-control item-cheque-is-recocile" id={"item-cheque-is-recocile-"+this.props.index} data-id={this.props.index}  data-target="item-cheque-is-recocile" defaultValue={(this.props.invoice_info?this.props.invoice_info.is_reconcile:false)}/>
                </td>
              </>
              }
              {(this.props.invoice_info && !this.props.invoice_info.cheque_no) &&
                <td colSpan={3}></td>
              }
              </>
            }
            <td>
                <span className="hide">{(this.props.items && this.props.invoice_info.refrence_no)?this.props.invoice_info.refrence_no:''}</span>
                <input style={{width:'130px'}} type="text" className="form-control item-refrence-no" placeholder="" id={"item-refrence-no-"+this.props.index} data-id={this.props.index}  data-target="item-refrence-no" defaultValue={(this.props.invoice_info?this.props.invoice_info.refrence_no:'')}/>
            </td>

            <td>
              {(this.props.items && this.props.invoice_info.payment_type && this.props.invoice_info.payment_type==='invoice' || this.props.invoice_info.item_id==='balance_amount') &&
                <>
                <span>₹{(this.props.invoice_info && this.props.invoice_info.old_amount)?this.props.invoice_info.old_amount:(this.props.invoice_info && this.props.invoice_info.item_total)?this.props.invoice_info.item_total:''}</span>
                <input type="hidden" value={(this.props.invoice_info && this.props.invoice_info.old_amount)?this.props.invoice_info.old_amount:(this.props.invoice_info && this.props.invoice_info.item_total)?this.props.invoice_info.item_total:''} id={"pay-bill-amt-"+this.props.index}/>
                </>
              }
              {(this.props.items && this.props.invoice_info.payment_type && this.props.invoice_info.payment_type==='general' && this.props.invoice_info.item_id!=='balance_amount') &&
                <span>-</span>
              }
            </td>
            
            <td>
                <span className="hide">₹{(this.props.items && this.props.invoice_info.item_total)?this.props.invoice_info.item_total:0}</span>

                <input type="text" className="form-control item-total" placeholder="" id={"item-total-"+this.props.index} data-id={this.props.index}  data-target="item-total" defaultValue={(this.state.item_total>0?this.state.item_total:this.props.invoice_info.item_total)} onChange={this.handlePriceChange} onBlur={this.handlePriceChange} disabled={(this.props.items && this.props.invoice_info.is_old_balance!==undefined && this.props.invoice_info.is_old_balance==='Advance Payment' && this.props.invoice_info.item_pk_id>0)?true:false} data-action={this.props.invoice_info.target}/>

                <input type="hidden" className="form-control item-total-old" id={"item-total-old-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.invoice_info && this.props.invoice_info.old_amount)?this.props.invoice_info.old_amount:(this.props.invoice_info && this.props.invoice_info.item_total)?this.props.invoice_info.item_total:''}/>
                <input type="hidden" className="form-control item-total-orig" id={"item-total-orig-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.invoice_info && this.props.invoice_info.old_amount)?this.props.invoice_info.old_amount:(this.props.invoice_info && this.props.invoice_info.item_total)?this.props.invoice_info.item_total:''}/>

                <input type="hidden" className="form-control item-target" placeholder="" id={"item-target-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.invoice_info?this.props.invoice_info.target:'')}/>
                <input type="hidden" className="form-control item-action" placeholder="" id={"item-action-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.invoice_info?this.props.invoice_info.action:'')}/>
 
                <input type="hidden" className="form-control" placeholder="" id={"item-id-"+this.props.index} data-id={this.props.index}  data-target="item-id" defaultValue={(this.props.invoice_info?this.props.invoice_info.item_pk_id:0)}/>
                
            </td>
             <td className="item-rm">
              <div className="item-mod-options" style={{display:'flex'}}>
                <span className={(this.props.index==1 && this.props.count<=1)?'item-remove ':'item-remove'} data-id={this.props.index} onClick={this.listItemRemove}><FontAwesomeIcon icon={faTimes} /></span>
                <span data-id={this.props.index} className="hide" onClick={this.listItemEdit}><FontAwesomeIcon icon={faPencilAlt} /></span>
              </div>
            </td>
          </tr>
        );
    }
}