import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import axios from 'axios';
import ModalBox from "../modal-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faTrash  } from '@fortawesome/free-solid-svg-icons';
import check_access from '../access-level.js';
let {enc_key,app_api,user_info,states} = require('../../library.js');
var CryptoJS = require("crypto-js");

export default class CompanyDetailsInfo extends Component {
    constructor() {
        super();
        this.state = {
          selectedFile : [],
          is_gst :'hide',
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileClick = this.onFileClick.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.handleGSTChange = this.handleGSTChange.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
    handleChangeState(e){
      let thisval = e.currentTarget.value;
      let id = parseInt(e.currentTarget.getAttribute('data-id'));
      let login_info = JSON.parse(localStorage.getItem('user'));
      console.log(id,user_info.login_id);
      if(user_info.login_id===id){
        login_info.response.state = thisval;
        localStorage.setItem('user', JSON.stringify(login_info));
      }
    }
    handleGSTChange(e){
      let index = e.currentTarget.getAttribute('data-id');
      let thisvalue = e.currentTarget.value;
      if(thisvalue==="" || thisvalue==="no-gst"){
        this.setState({is_gst:'hide'});
      }else{
        this.setState({is_gst:''});
      }
    }
    componentDidMount = () =>{
      let nextProps = this.props.company_info;
      
      if(nextProps!==undefined && nextProps.gst_type!=="" && nextProps.gst_type!=='no-gst'){
          this.setState({is_gst:''});
      }
    }

    deleteCompany(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      if(id!==""){
        if(target!==null){
          var element = document.getElementById('delete_confirm');
          element.classList.remove("fade");
          element.style.display="block";
          document.getElementById("deleteValue").value=data_value;
          document.getElementById("deleteIndex").value=id;
          document.getElementById("deleteTarget").value=data_id;
        }
      }else{
        document.getElementById("comp-detail-info-"+data_value).remove();
      }
    }
    onFileChange(e){
      let id = e.currentTarget.getAttribute('data-id');

      let logo_name = document.getElementById("Company_Name").innerHTML;
      let company_name = document.getElementById("company_name_hidden-"+id).value;
      this.setState({ selectedFile: e.target.files[0] });
      let output = URL.createObjectURL(e.target.files[0]);
      
      if(logo_name==company_name){
        document.getElementById("Company_logo").setAttribute('src',output);
        let user_info = JSON.parse(localStorage.getItem('user'));
        user_info.response.logo_url = output;
        user_info = JSON.stringify(user_info);
        localStorage.setItem('user', user_info);

      }
      document.getElementById("uploaded_photo-"+id).setAttribute('src',output);
      document.getElementById("photo-block-"+id).classList.remove('hide');
    }
    onFileClick(e){
      let id = e.currentTarget.getAttribute('data-id');
      document.getElementById("upload_photo-"+id).click();
    }
    render() {

        return (
          <>
        <div key={this.props.index} className="main-content border-bottom comp-detail-info" data-id={this.props.index} id={"comp-detail-info-"+this.props.index}>
          <div className="inner-content">
            <input type="hidden" id={"branch_id-"+this.props.index} defaultValue={(this.props.company_info && this.props.company_info.branch_id)?this.props.company_info.branch_id:''}/>
            <div className="row">
              <div className="col-md-4">
                  <label className="lbl">COMPANY NAME</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.company_name)?this.props.company_info.company_name:''} name="company_name" className="form-field form-control" placeholder="Company Name" id={"company_name-"+this.props.index}/>
                  <input type="hidden" defaultValue={(this.props.company_info && this.props.company_info.company_name)?this.props.company_info.company_name:''} name="company_name" className="form-field form-control" placeholder="Company Name" id={"company_name_hidden-"+this.props.index}/>
                  
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.company_sub_title)?this.props.company_info.company_sub_title:''} name="company_sub_title" className="form-field form-control company-sub-title" placeholder="Company sub title" id={"company_sub_title-"+this.props.index}/>

              </div>
              <div className="col-md-4">
                  <label className="lbl">BUSINESS TYPE</label>
                  <select className="form-control" name="business_type" id={"business_type-"+this.props.index} defaultValue={(this.props.company_info && this.props.company_info.business_type)?this.props.company_info.business_type:''}>
                      <option value="wholesale">Wholesale</option>
                      <option value="retail">Retail</option>
                      <option value="other">Other</option>
                  </select>
              </div>
              <div className="col-md-4 text-right">
                  <input type="file" onChange={this.onFileChange} data-id={this.props.index} id={"upload_photo-"+this.props.index} className="hide"/> 
                  <div className="row photo-block">
                    <div className="col-md-12 comp-logo-d">
                      <div className={(this.props.company_info && this.props.company_info.logo_url!=='')?"":"hide"} id={"photo-block-"+this.props.index}>

                        <img src={(this.props.company_info && this.props.company_info.logo_url)?this.props.company_info.logo_url:''} id={"uploaded_photo-"+this.props.index} className="comp-photo"/>
                      </div>
                    </div>
                    <div className="col-md-12 comp-logo-but-d">
                    {(this.is_access('settings-company-details-create') || this.is_access('settings-company-details-modify')) && 
                    <button type="button" className="btn btn-success mrtop-30 upload-com-photo" onClick={this.onFileClick} data-id={this.props.index}></button>
                    }
                    </div>
                  </div>
              </div>
            
            </div>
            <div className="row mrtop-30">
              <div className="col-md-4">
                
                  <label className="lbl">PHONE NUMBER</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.phone)?this.props.company_info.phone:''} name="phone_number" className="form-field form-control" placeholder="Phone Number"  id={"phone_1-"+this.props.index}/>
              </div>
              
              <div className="col-md-4">
                  <label className="lbl">PHONE NUMBER 2</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.phone_2)?this.props.company_info.phone_2:''} name="phone_number2" className="form-field form-control" placeholder="Phone Number 2"  id={"phone_2-"+this.props.index}/>
              </div>
            </div>
            <div className="row mrtop-30">
              <div className="col-md-4">
                  <label className="lbl">PIN CODE</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.pin_code)?this.props.company_info.pin_code:''} name="pincode" className="form-field form-control" placeholder="Pin Code"  id={"pincode-"+this.props.index}/>
              </div>
              <div className="col-md-4">
                  <label className="lbl">ADDRESS</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.address)?this.props.company_info.address:''}  name="address_1" className="form-field form-control" placeholder="Enter address line 1"  id={"address_1-"+this.props.index}/>
              </div>
              <div className="col-md-4">
                  <label className="lbl">ADDRESS 2</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.address_2)?this.props.company_info.address_2:''}  name="address_2" className="form-field form-control" placeholder="Addess 2"  id={"address_2-"+this.props.index}/>
              </div>
              
            </div>
            <div className="row mrtop-30">
              <div className="col-md-4">
                  <label className="lbl">CITY</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.city)?this.props.company_info.city:''}  name="city" className="form-field form-control" placeholder="City"  id={"city-"+this.props.index}/>
              </div>
              <div className="col-md-4">
                  <label className="lbl">GST TYPE</label>
                  <select data-id={this.props.index} onChange={this.handleGSTChange} className="form-control" name="gst_type" id={"gst_type-"+this.props.index} defaultValue={(this.props.company_info)?this.props.company_info.gst_type:""}>
                    <option value="">Select GST Type</option>
                    <option value="no-gst">No GST</option>
                    <option value="composite">Composite</option>
                    <option value="regular">Regular</option>
                  </select>
              </div>
              <div className={"col-md-4 "+this.state.is_gst}>
                  <label className="lbl">GST NUMBER</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.gst_number)?this.props.company_info.gst_number:''} name="gst_number" className="form-field form-control" placeholder="GST Number"  id={"gst_number-"+this.props.index}/>
              </div>
            </div>
            <div className="row mrtop-30">

              <div className="col-md-4">
                  <label className="lbl">STATE</label>
                 
                      <select className="form-control" defaultValue={(this.props.company_info && this.props.company_info.state)?this.props.company_info.state:''}  name="state"  id={"state-"+this.props.index} onChange={this.handleChangeState} data-id={(this.props.company_info && this.props.company_info.branch_id)?this.props.company_info.branch_id:''}>
                        <option key="select_state" value="" >Select State</option>
                      {(states.length > 0) ? states.map( (value, index) => {
                       return (
                          <option key={index} value={value}>{value}</option>
                        )
                      }) : ''}
                      </select>
                 
                 
              </div>
              <div className="col-md-4">
                  <label className="lbl">PAN NUMBER</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.pan_number)?this.props.company_info.pan_number:''} name="pan_no" className="form-field form-control" placeholder="PAN Number"  id={"pan_number-"+this.props.index}/>
              </div>
               <div className="col-md-4">
                  <label className="lbl">EMAIL</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.email)?this.props.company_info.email:''} name="email" className="form-field form-control" placeholder="Email"  id={"email-"+this.props.index}/>
              </div>
            </div> 
            <div className="row mrtop-30">
              <div className="col-md-4">
                  <label className="lbl">COUNTRY</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.country)?this.props.company_info.country:''}  name="country" className="form-field form-control" placeholder="Country" id={"country-"+this.props.index}/>
              </div>
              <div className="col-md-4">
                  <label className="lbl">TAX LABEL</label>
                  <input type="text" defaultValue={(this.props.company_info && this.props.company_info.tax_label)?this.props.company_info.tax_label:''} name="tax_label" className="form-field form-control" placeholder="Tax Label" id={"tax_label-"+this.props.index}/>
              </div>
            {this.is_access('settings-company-details-delete') && 
              <div className="col-md-4 text-right mrtop-30">
                {this.props.index!==0 &&
                  <FontAwesomeIcon id={(this.props.company_info && this.props.company_info.branch_id)?this.props.company_info.branch_id:''} onClick={this.deleteCompany} data_target="delete_confirm" data_value={this.props.index} data_id="delete_company" icon={faTrash} className="pull-right fa-icon"/>
                }
              </div>
            }
            </div>
            </div>
           </div>   
           <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
          </>
        );
    }
}