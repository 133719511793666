import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
export default class Toottip extends Component {
    constructor() {
      super();
      this.state = {
        active_type : 'regular'
      };
    }
    componentDidMount(){
     
    }
   
    render() {
        let view = true;
        let edit = true;
        let del = true;
        let direction_cls = '';
        if(this.props.item_count>=7 && this.props.index>=7){
            direction_cls = 'top-dir';
        }
        return (
           <>
            <div className="dropdown">
              <span>...</span>
              <div className={"dropdown-content "+direction_cls}>
                  {this.state.active_type==='regular' &&
                  <ul className="list-group">
                    {(this.props.view && view) &&
                      <li className="list-group-item"><a href={this.props.view}>View</a></li>
                    }
                    {(this.props.edit && edit) &&
                      <li className="list-group-item"><a href={this.props.edit}>Edit</a></li>
                    }
                    {(this.props.delete && del) &&
                      <li className="list-group-item" id={this.props.id} data_value={this.props.delete.data_value} onClick={this.deleteRow} data_target="delete_confirm" data_id={"delete_"+this.props.delete.active_tab} >Delete</li>
                    }
                  </ul>
                }
              </div>
            </div>
          </>
        );
    }
}