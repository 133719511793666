import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretUp,faCaretDown,faCaretLeft,faCaretRight} from '@fortawesome/free-solid-svg-icons';
export default class ShortcutHelp extends Component {
    constructor() {
        super();
        this.state = {
            active_tab : '',
           
        };
      
    }

    render() {
        let shortcuts = this.props.shortcuts;
        let cls = this.props.target==='new_bill_cb'?'new-bill-cb':'';
        if(this.props.target==='msg-to-customer'){
         cls = 'msg-to-customer';
        }else if(this.props.target==='save-bill'){
         cls = 'save-bill';
        }else if(this.props.target==='hold-bill'){
         cls = 'hold-bill';
        }else if(this.props.target==='pay-bill'){
         cls = 'pay-bill';
        }else if(this.props.target==='contacts-tab'){
         cls = 'c-tab-h';
        }
        let icon = faCaretUp;
        if(this.props.arrow==='bottom'){
            icon = faCaretDown;
        }else if(this.props.arrow==='left'){
            icon = faCaretLeft;
        }else if(this.props.arrow==='right'){
            icon = faCaretRight;
        }
        return (
            <div className={"key-shortcut hide "+cls}>
                <ul>
                {(shortcuts.length > 0) ?shortcuts.map( (value, index) => {
                   return (
                    <li className={this.props.target==='new'?'new-li':''}>
                        <FontAwesomeIcon icon={icon} />
                        {value}
                    </li>
                  )
                 }) : <li></li> }
                </ul>
            </div>
        );
    }
}