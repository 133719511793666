import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
export default class ClosedFYButton extends Component {
    constructor(props) {
      super(props);
      this.state = {
        
      };
    }
    handleOpenFYClosedNotice = () =>{
        toast.warn('You can\'t do this operation because this financial year has been closed', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }
    render() {
        return (
            <>
                {(this.props.type==='button') &&
                    <button type="button" className={"btn btn-secondary "+this.props.className} id={this.props.id} onClick={this.handleOpenFYClosedNotice} style={this.props.style}>{this.props.label}</button>
                }
                {(this.props.type==='div') &&
                    <div className={this.props.className} id={this.props.id} onClick={this.handleOpenFYClosedNotice} data_target="delete_confirm"  style={this.props.style}><a>{this.props.label}</a></div>
                }
            </>
        );
    }
}