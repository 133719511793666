import React, { Component } from "react";
export default class Maintenance extends Component {
    constructor(props) {
      super(props);
      this.state = {
         timer_date : this.props.value.timer_date,
         timer_start_date : this.props.value.timer_start_date,
         index : this.props.index,
         maintain_status : this.props.value.maintain_status,
         is_show_maintain_page : this.props.value.is_show_maintain_page,
         target : this.props.target,
      };
      this.timer = null;
      this.handleCloseMaintenance = this.handleCloseMaintenance.bind(this);
      this.padLeadingZeros = this.padLeadingZeros.bind(this);
    }
    handleCloseMaintenance(e){
      let id = e.currentTarget.getAttribute('data-id');
      this.props.handleCloseMaintenance(id);
      clearInterval(this.timer);
    }
    padLeadingZeros(num) {
      var s = num+"";
      while (s.length < 2) s = "0" + s;
      return s;
    }
    componentDidMount(){
      var countDownDate = new Date(this.state.timer_date).getTime();
      let _this = this;
      if(this.state.target==='user'){
        let timer_start_date = new Date(this.state.timer_start_date);

        _this.timer = setInterval(function() {
          var currentDate = new Date();

          if(currentDate>=timer_start_date){
            _this.setState({is_show_maintain_page:true});
          }
          var now = new Date().getTime();
          var distance = countDownDate - now;
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

           days = _this.padLeadingZeros(days);
           hours = _this.padLeadingZeros(hours);
           minutes = _this.padLeadingZeros(minutes);
           seconds = _this.padLeadingZeros(seconds);
           if(document.getElementById("timer-"+_this.state.index)){
              document.getElementById("timer-"+_this.state.index).innerHTML = hours + " : " + minutes + " : " + seconds;
           }
            
          if (distance < 0) {
            clearInterval(_this.timer);
            _this.setState({maintain_status:'invactive'});
            if(document.getElementById("timer-"+_this.state.index)){
              document.getElementById("timer-"+_this.state.index).innerHTML = "";
            }
            if(document.getElementById("maintain-page-"+_this.state.index)){
              document.getElementById("maintain-page-"+_this.state.index).remove();
            }
            if(document.querySelector(".maintenance-page-"+_this.state.index)){
              document.querySelector(".maintenance-page-"+_this.state.index).remove();
            }
          }
        }, 1000);
      }
    }

    render() {
        return (
          <>
          {(this.state.is_show_maintain_page===false || this.props.target==='admin') &&
          <div className={this.props.className} id={this.props.id}>
            <div className={(this.state.maintain_status==='active')?"alert alert-danger":"alert alert-secondary"}>
                {(this.props.target==='admin') &&
                  <a onClick={this.handleCloseMaintenance} className="close" data-dismiss="alert" aria-label="close" data-id={this.props.index}>&times;</a>
                }
                <p style={{margin:'0px',textAlign:'center'}}><strong>Maintenance!</strong> We are giving scheduled update between <strong>{this.props.value.from_date+' '+this.props.value.from_time+' '+this.props.value.from_am_pm}</strong> to <strong>{this.props.value.to_date+' '+this.props.value.to_time+' '+this.props.value.to_am_pm}.</strong> Please stop billing between mentioned timeslot.</p>
                {/*<p style={{margin:'0px',textAlign:'center'}}>You can resume you billing in &nbsp;
                                <strong id={"timer-"+this.props.index} style={{fontSize:'22px'}}></strong></p>*/}
             </div>
            </div>
           }
           {(this.state.is_show_maintain_page===true && this.props.target==='user') &&
              <div id="maintenance-page" className={"maintenance-page-"+this.props.index}>
                <div className="maintenance-page">
                  <div className="maintenance-page-404">
                    <h1 id={"timer-"+this.props.index}></h1>
                    <h2>We'll Be Right Back</h2>
                  </div>
                  <p style={{fontSize:'20px'}}>Relax ! We are down for scheduled update maintenace. We will get something new for you.</p>
                </div>
              </div>
           }
           </>
        );
    }
}