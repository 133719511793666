import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import utils from '../utils.js';


let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class DownloadUnpaidBills extends Component {
    constructor() {
        super();
        this.state = {
          billing_list : [],
          progress : 0,
          total_records : 0,
          total_loaded : 0
        }
        this.currency_format = utils.currency_format.bind(this);
    }
 
    handleProcessDownloadUnpaidBills = (page) =>{
      let total_loaded = this.state.total_loaded;
      let limit = 50;
      total_loaded = total_loaded+limit;
      let body = {
          page : page,
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          total_record:this.state.total_records,
          limit : limit
      }
      
      let api = app_api+'/download_unpaid_bills';
      axios.post(api, body)
        .then(function (response) {
          let data = response.data.data;
          let number_of_page = response.data.number_of_page;
          let total_record = response.data.total_record;
          this.setState({total_records:total_record});
          if(page<=number_of_page){
            let progress = (total_loaded / total_record) * 100;
            progress = utils.currency_format(progress);
            progress = Math.round(progress);
            if(progress>100){
              progress = 100;
            }
            this.setState({progress:progress});
            this.setState({total_loaded:total_loaded});
            let billing_list = this.state.billing_list;
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              billing_list.push(element);
            }
            
            page = page+1;
            this.handleProcessDownloadUnpaidBills(page);
          }else{
            this.setState({progress:100});
            if(document.getElementById("donwload-unpaid-bill-xls-button")){
              document.getElementById("donwload-unpaid-bill-xls-button").click();
            }
            if(document.getElementById("handleCloseDownloadUnpaidBills")){
              document.getElementById("handleCloseDownloadUnpaidBills").click();
            }
          }
      }.bind(this)).catch(function(res){
          
      })
    }

    componentDidMount = () =>{
      this.handleProcessDownloadUnpaidBills(1);
    }

    render() {
        
        return (
            <div className="d-flex" id="wrapper">
             
              <div id="page-content-wrapper">
                
                <div className="container-fluid">

                  <div className="content-area">
                    <div id="download-unpaid-bill-xls-progress" className="modal" style={{display:'block'}}>
                      <div className="modal-dialog">
                        <div className="modal-content csv-load-blk">
                          <div className="parent-bar">
                            <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                          </div>
                          <p>Please wait while process complete ({this.state.progress}%)</p>
                        </div>
                      </div>
                    </div>
                     <div className="main-content" style={{display:'none'}}>
                     <ReactHTMLTableToExcel
                            id="donwload-unpaid-bill-xls-button"
                            className="btn btn-success mrright-5 pull-right mrtop-10 mrbtm-10"
                            table="unpaid-bill-report-table"
                            filename="unpaid-bills-report"
                            sheet="unpaid-bills-report"
                            buttonText="Download as XLS"/>
                        <table className="table" id="unpaid-bill-report-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">SALES #</th>
                            <th scope="col">DATE</th>
                            <th scope="col">NAME</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">PENDING AMOUNT</th>
                          </tr>
                        </thead>
                         <tbody>
                           {(this.state.billing_list && this.state.billing_list.length > 0) ?this.state.billing_list.map( (value, index) => {
                           return (
                            <>
                            <tr key={ index }>
                              <td>
                                {value.number}
                              </td>
                              <td>{value.created_date}</td>
                              <td>{value.contact_name}</td>
                              <td>{value.total}</td>
                              <td>{value.pending_amount}</td>
                            </tr>
                          </>
                          )
                         }) : <tr><td className="text-center"></td></tr> }
                        </tbody>
                        </table>
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
   
}