import React, { Component } from "react";
import Link from './link.js';

export default class InventoryComissionList extends Component {
    constructor(props) {
      super();
      this.state = {
         
      }
    }
    render() {
        return (
            <div className="d-flex" id="wrapper">
             
              <div  id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-12 mrtop-5">
                        <h2>{this.props.page_title}</h2>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
               
                  <div className="content-area">
                     <div className="main-content">
                      
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Item Name</th>
                            <th scope="col">Comission</th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.props.inventory_list!=='' && this.props.inventory_list.length > 0) ?this.props.inventory_list.map( (value, index) => {
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                              <td><Link style={{fontSize:'14px',fontWeight:'bold'}} link={(value.url!==undefined)?value.url:'/products/view/'+value.id} name={value.item_name} /></td>
                              <td> 
                                ₹{value.comission}
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan="2" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                    
                     
                   
                     </div>
                  </div>
                </div>
              </div>
           
            </div>
        );
    }
}