import encrypt_decrypt from './encrypt-decrypt.js';
let {access_level,user_info} = require('../library.js');
const is_access = (key) => {
	if(user_info.access_level==="1" || user_info.access_level===1){
		if(key==='billing-stop-editing-date' || key==='billing-stop-editing-price'){
			return false;
		}
		return true;
	}
	let access = JSON.parse(encrypt_decrypt.decrypt(access_level));
	if(access.indexOf(key)>=0){
		return true;
	}
	return false;
}
export default {is_access}