import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner  } from '@fortawesome/free-solid-svg-icons';
import ContactsDropdown from "./contacts-dropdown";
import VariationsOptions from "./variation-options";
import VariationsList from "./variation-list";
import ModalBox from "./modal-box";
import ProductUnits from "./product-units";
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let _this;
export default class EditInventory extends Component {
    constructor() {
        super();
        this.state = {
            active_tab:'inventory',
            contact_info:[],
            inventory_item:[],
            inventory_images:[],
            show_inventory_images:[],
            
            category_item:[],
            current_id:'',
            purchase_id:'',
            parent_inventory_id:'',
            parent_id:'',
            auto_sku : true,
            multiple_variant : true,
            info_body : {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            },
            variation_options : [],
            inventory_meta : [],
            variation_list : [],
        };
        _this = this;
        this.variation_list=[];
        this.variation_options=[];
        this.updateInventory = this.updateInventory.bind(this);
        this.operateCheck = this.operateCheck.bind(this);
        this.handleVariant = this.handleVariant.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.moreVariationTag = this.moreVariationTag.bind(this);
        this.createVariation = this.createVariation.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.handleCalculation = this.handleCalculation.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.randomSKU = this.randomSKU.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.checkLowestSellingPrice = this.checkLowestSellingPrice.bind(this);
        this.showImages = this.showImages.bind(this);
        this.handlePreviewImage = this.handlePreviewImage.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }

    handlePreviewImage(e){
      let this_img = e.currentTarget.getAttribute('data-src');
      var element = document.getElementById('preview-image');
        element.classList.remove("fade");
        element.style.display="block";
      document.getElementById('preview-img-src').setAttribute('src',this_img);

    }

    showImages(e){
        var element = document.getElementById('product-images');
        element.classList.remove("fade");
        element.style.display="block";
    }

    checkLowestSellingPrice(e){
      
      let thisval=e.currentTarget.value;
      if(thisval!==""){
        thisval = parseFloat(thisval);
        let lsp = document.querySelector('[name=lowest_selling_price]').value;
        lsp = parseFloat(lsp);
        if(thisval<lsp){
          var element = document.getElementById('notice_box');
          let msg = "Sorry ! you are entering Selling Price lesser than Lowest Selling Price. The Lowest Selling Price is "+lsp;
          document.getElementById("notice_message").innerHTML = msg;

          element.classList.remove("fade");
          element.style.display="block";
          e.currentTarget.value = lsp;
        }
      }
    }
    randomSKU(str,length) {
       var result           = '';
       var characters       = str;
       characters = characters.replace(/[^a-zA-Z0-9]/g, "");
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }
    handleChange(e){
      let value = e.currentTarget.value;
      let name = e.currentTarget.getAttribute("name");
      let inv_item = this.state.inventory_item;
      inv_item[name] = value;
      this.setState({inventory_item:inv_item});
      
    }
    handleActive(e){
        let inv_item = this.state.inventory_item;
        let status = "Draft";
      if(e.currentTarget.checked){
        status = "Active";
      }
      inv_item.inventory_status = status;
      this.setState({inventory_item:inv_item});
    }
    createVariation(e){
      let id = e.currentTarget.id;
      let cls = document.getElementsByClassName("v-opt-row");
      let v_arr = [];
      let var_items = [];
      for(let i=0;i<cls.length;i++){
          let tid = cls[i].getAttribute('data-id');
          let variant_name = document.getElementById("variation-name-"+tid).value;
          if(var_items[variant_name]){}else{
            //var_items[variant_name] = [];
          }
          let variant_value = document.getElementById("variation-value-"+tid).value;
          var_items.push({key:variant_name,value:variant_value});
         
      }
      this.setState({variation_list:<VariationsList target="edit"  var_items={var_items} inventory_item={this.state.inventory_item} variation_item={this.state.inventory_meta} type="new"/>});
    }
    operateCheck(e){
      let is_checked = e.currentTarget.checked;
      this.setState({auto_sku:is_checked});
      if(!is_checked){
        document.getElementById("item-sku").removeAttribute('readonly');
      }else{
        document.getElementById("item-sku").setAttribute('readonly',true);
      }
    } 
    handleVariant(e){
      let is_checked = e.currentTarget.checked;
      this.setState({multiple_variant:is_checked});
      this.variation_options = [];
      this.variation_options.push(<VariationsOptions index={0}  key={0}  options={[]}/>);
      this.setState({variation_options:this.variation_options});
    }
    moreVariationTag(e){
      let clen = document.getElementsByClassName("v-opt-row").length;
      this.variation_options.push(<VariationsOptions index={clen}  key={clen}  options={[]}/>);
      this.setState({variation_options:this.variation_options});
    }
    componentDidMount(){
      
      
      let curl = window.location.href;
      const get_id = curl.match(/edit-inventory\/(\d+$)/i);
      const variation = curl.match(/edit-inventory\/(\d+)\/([var]+$)/i);
      let c_id = 0;
      if(get_id){
       c_id = parseInt(get_id[1]);
      }else if(variation){
        c_id = parseInt(variation[1]);
        this.setState({active_tab:'variation'});
      }
      
      this.setState({current_id:c_id});
       let body = {};
      body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            id : c_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      let api = app_api+'/get_inventory_on_id';
    
      axios.post(api, body)
        .then(function (response) {
            let resp = response.data.data;
            console.log(resp);
            let category_item = resp.categories;
          
            let contact_info = resp.contact_info;
            let inventory_meta = resp.inventory_meta;
            let inventory_images = resp.inventory_images;
            
            this.setState({inventory_images:inventory_images});
            this.setState({show_inventory_images:inventory_images[c_id]});

            let purchase_id = resp.purchase_id;
            let varient = resp[0].varient;
            if(varient==0){
              this.setState({multiple_variant:false});
            }else{
              this.setState({multiple_variant:true});
            }
            let parent_inventory_id = resp.parent_inventory_id;
            let parent_id = resp.parent_id;
            this.setState({parent_id:parent_id});
            this.setState({purchase_id:purchase_id});
            this.setState({parent_inventory_id:parent_inventory_id});
            this.setState({inventory_meta:inventory_meta});

            let contact_name = '';
            let contact_id = '';
            let address_1 =  '';
            let address_2 = '';
            let city = '';
            let credit_limit = '';
            let email = '';
            let phone_1 = '';
            let phone_2 = '';
            let state = '';
            if(Object.keys(contact_info).length > 0) {
               contact_name = contact_info[0].name;
               contact_id = contact_info[0].contact_id;
               address_1 = contact_info[0].address_1;
               address_2 = contact_info[0].address_2;
               city = contact_info[0].city;
               credit_limit = contact_info[0].credit_limit;
               email = contact_info[0].email;
               phone_1 = contact_info[0].phone_1;
               phone_2 = contact_info[0].phone_2;
               state = contact_info[0].state;
            }
            let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state};
            this.setState({contact_info:c_info});
            this.setState({category_item:category_item});
           if(resp[0]!==undefined){
            let item_sku = resp[0].sku;
            let item_name = resp[0].item_name;
            if(item_sku===null){
              let sku_auto = '';
              let iname = item_name.replace(" ",'');
              let n = iname.toUpperCase();
              resp[0].sku = this.randomSKU(n,6);
            }
            this.setState({ 'inventory_item':  resp[0]}); 

            let v_op = [];
            let v_k_n = [];
            for(let i=0;i<inventory_meta.length;i++){
                let var_options = inventory_meta[i].variation_options;
                
                var_options.map((value, index) =>{
                    if(v_op[value.key]){}else{
                      v_op[value.key]=[];
                    } 
                    if(v_k_n.indexOf(value.key)===-1){
                       v_k_n.push(value.key);
                    }
                    v_op[value.key].push(value.value);
                })
            }
            let var_items = [];
            for(let j=0;j<v_k_n.length;j++){
                let k = v_k_n[j];
                let vstr = v_op[k].toString();
                var_items.push({key:k,value:vstr});
                this.variation_options.push(<VariationsOptions index={j} key={j} options={var_items}/>);
                this.setState({variation_options:this.variation_options});
            }
            this.setState({variation_list:<VariationsList target="edit" var_items={var_items} inventory_item={this.state.inventory_item} variation_item={inventory_meta} type="old" inventory_images={inventory_images}/>});
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
    }
    calculateTotal(){
      var item_sub_total = document.querySelectorAll('.item-sub-total'); 
      let sub_total = 0;
      for (var i = 0; i < item_sub_total.length; i++) { 
           let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
           sub_total = sub_total + i_s_total;
      } 
      document.getElementById("sub-total").innerHTML = this.currency_format(sub_total); 


      let item_tax = 0;
      let cgst_total =0;
      let sgst_total =0;
      let igst_total =0;
      var item_total_tax = document.querySelectorAll('.item-cgst-total'); 
      for (var i = 0; i < item_total_tax.length; i++) { 
           let did = item_total_tax[i].getAttribute('data-id');
           let cgst = item_total_tax[i].value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
           cgst_total = cgst_total+cgst;
           sgst_total = sgst_total+sgst;
           igst_total = igst_total+igst;

           item_tax = item_tax + (cgst+sgst+igst);
      } 
      document.getElementById("total-cgst").innerHTML = this.currency_format(cgst_total);
      document.getElementById("total-sgst").innerHTML = this.currency_format(sgst_total);
      document.getElementById("total-igst").innerHTML = this.currency_format(igst_total);
      document.getElementById("total-tax").innerHTML = this.currency_format(item_tax);

      var item_final_total = document.querySelectorAll('.item-total-txt'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!=''){
              total_unit++;
            }
           item_total = item_total + (item_final_total[i].value);
      } 
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("total-unit").innerHTML = this.currency_format(total_unit);

      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
      let item_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           item_discount = item_discount + (item_total_discount[i].value!=''?(item_total_discount[i].value):0);
      } 
      if(document.getElementById("total-discount")){
        document.getElementById("total-discount").innerHTML = this.currency_format(item_discount);
      }
      
    }
    handleCalculation(e){
       let inv_item = this.state.inventory_item;
      let thisval = e.currentTarget.value;
     
      let data_id = e.currentTarget.getAttribute('data-id');
      let id = e.currentTarget.id;

  

      let quantity = 1;
      if(document.getElementById("item-quantity-"+data_id)){
        quantity = document.getElementById("item-quantity-"+data_id).value;
        quantity = (quantity!==''?parseFloat(quantity):1);
      }

      let price = 0;
      if(document.getElementById("price-"+data_id)){
        price = document.getElementById("price-"+data_id).value;
        price = (price!==''?parseFloat(price):0);
      }

      let discount = 0;
      if(document.getElementById("discount-"+data_id)){
        discount = document.getElementById("discount-"+data_id).value;
        discount = (discount!==''?parseFloat(discount):0);
      }
      let tax = 0;
      if(document.getElementById("tax-"+data_id)){
        tax = document.getElementById("tax-"+data_id).value;
        tax = (tax!==''?parseFloat(tax):0);
      }

      let cal_discount = ((price*quantity)*discount)/100;
      let item_grand_total = (price*quantity)-cal_discount;
      let cal_tax = (item_grand_total*tax)/100;

     /* if(document.getElementById("discount-"+data_id)){
        document.getElementById("discount-hidden-"+data_id).value = this.floating_point(cal_discount);
      }*/
      if(document.getElementById("tax-hidden-"+data_id)){
        document.getElementById("tax-hidden-"+data_id).value = this.floating_point(cal_tax);
      }

      let final_amount = item_grand_total+cal_tax;
      inv_item.total = this.currency_format(final_amount);
      this.setState({inventory_item:inv_item});

    }
    updateInventory(event) {
        let inv_item = this.state.inventory_item;
        let id = event.currentTarget.id;
        

        let item_name = document.querySelector('[name=item_name]').value;
        let hsn = document.querySelector('[name=hsn]').value;
        let sku = document.querySelector('[name=sku]').value;
        let barcode_name = document.querySelector('[name=barcode_name]').value;
        let custom_text = document.querySelector('[name=custom_text]').value;
        let contact_id = document.querySelector('[id=customer-dropdown]').value;
        let category_id = document.querySelector('[name=category_id]').value;
  
        let quantity = document.querySelector('[name=quantity]').value;
        let purchase_price = document.querySelector('[name=purchase_price]').value;
        let selling_price = document.querySelector('[name=selling_price]').value;
        let lowest_selling_price = document.querySelector('[name=lowest_selling_price]').value;
        let bundle_quantity = document.querySelector('[name=bundle_quantity]').value;
        let unit = document.querySelector('[id=item-unit]').value;
        let cgst = document.querySelector('[id=cgst]').value;
        let sgst = document.querySelector('[id=sgst]').value;
        let igst = document.querySelector('[id=igst]').value;
        let discount = document.querySelector('[name=discount]').value;
        let item_desc = document.querySelector('[name=item_desc]').value;

        inv_item.product_unit_id = unit;
        inv_item.item_name = item_name;
        inv_item.hsn = hsn;
        inv_item.sku = sku;
        inv_item.barcode_name = barcode_name;
        inv_item.custom_text = custom_text;
        inv_item.contact_id = contact_id;
        inv_item.category_id = category_id;
        inv_item.quantity = quantity;
        inv_item.purchase_price = purchase_price;
        inv_item.selling_price = selling_price;
        inv_item.lowest_selling_price = lowest_selling_price;
        inv_item.bundle_quantity = bundle_quantity;
        inv_item.cgst = cgst;
        inv_item.sgst = sgst;
        inv_item.igst = igst;
        inv_item.discount = discount;
        inv_item.item_desc = item_desc;
        this.setState({inventory_item:inv_item});

        let items_arr = [];
        let items = document.querySelectorAll(".var-list");
        for(let i = 0; i<items.length; i++){
         
            let thisid = items[i].id;
            let data_id = items[i].getAttribute('data-id');

            let i_id = document.getElementById("var-item-id-"+thisid).value;
            let i_name = document.getElementById("var-name-"+thisid).value;
            let i_desc = document.getElementById("var-desc-"+thisid).value;
            let i_sku = document.getElementById("var-sku-"+thisid).value;
            let i_hsn = document.getElementById("var-hsn-"+thisid).value;
            let i_quantity = document.getElementById("var-quantity-"+thisid).value;
            let i_purchase_price = document.getElementById("var-purchase-price-"+thisid).value;
            let i_selling_price = document.getElementById("var-selling-price-"+thisid).value;
            let i_lowest_selling_price = document.getElementById("var-lowest-selling-price-"+thisid).value;
         
            let sikey = '';
            let sival = '';
            
            let c_items = document.querySelectorAll("[data-id="+data_id+"] .var-opt-td");
            let variation_options = []; 
            for(let j=0;j<c_items.length;j++){
                let cid = c_items[j].getAttribute('data-id');
                sikey = document.getElementById("var-opt-name-"+cid).value;
                sival = document.getElementById("var-opt-value-"+cid).value;
                let v = {key:sikey,value:sival};
                variation_options.push(v);
            }
            let item = {insert_id:i_id,purchase_id:parseInt(this.state.purchase_id),parent_inventory_id:parseInt(this.state.parent_inventory_id),item_name:i_name,item_desc:i_desc,sku:i_sku,hsn:i_hsn,quantity:i_quantity,purchase_price:i_purchase_price,selling_price:i_selling_price,lowest_selling_price:i_lowest_selling_price,variation_options};
            items_arr.push(item);
        }

        let update_data = {};
        update_data = {
                id : this.state.current_id,
                parent_inventory_id : parseInt(this.state.parent_inventory_id),
                active_tab : this.state.active_tab,
                purchase_id : parseInt(this.state.purchase_id),
                multiple_variant :this.state.multiple_variant,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,  
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                product_data :this.state.inventory_item,        
                variations :items_arr,        
        }

        if(parseFloat(selling_price)>=parseFloat(lowest_selling_price)){
          document.getElementById(id).classList.add("hide");
          document.getElementById(id+'-btn-spinner').classList.remove("hide");
          let api = app_api+'/update_inventory';
          axios.post(api, update_data)
            .then(function (response) {
               let resp = response.data;
               if(resp.success===1){
                 window.location.href = "/inventory";
               }
          }).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
        }

    }
    render() {
      let hide_supplier = 'hide';
      let hide_purchase = 'hide';
    
      if(this.is_access('inventory-show-supplier')){
        hide_supplier = '';
      } 
      if(this.is_access('inventory-show-purchase-price')){
        hide_purchase = '';
      }
      let current_id = this.state.current_id;
      let inventory_images = this.state.show_inventory_images;
   
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">
                        <Breadcrumbs link="/inventory" title="Products" sub_title="Edit Product"/>
                       
                    </div>
                     <div className="col-md-6 text-right mrtop-10">

                        {this.state.active_tab==='inventory' && 
                        <a href={"/view-inventory/"+_this.state.current_id}><button type="button" className="btn btn-success">View Inventory</button></a>
                        }
                        {this.state.active_tab==='variation' && 
                        <a href={"/view-inventory/"+_this.state.current_id+"/var"}><button type="button" className="btn btn-success">View Variation</button></a>
                        }
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                    
                     <div className="main-content border-bottom inventory-fields">
                        <div className="inner-content">
                            {(inventory_images && inventory_images.length>0) &&
                              <div className="row">
                                <div className="col-md-12 pro-img-blk">
                                    <ul className="pro-top-imgs">
                                       {inventory_images.map( (value, index) => {
                                        return (
                                        <li onClick={this.handlePreviewImage} data-src={value.image}><img src={value.image}/></li>
                                       
                                        )})}
                                    </ul>
                                </div>
                              </div>
                            }
                            <div className="row">
                                <div className="col-md-3"></div>
                                  
                                 <div className="col-md-6">
                                 <span>Status : {this.state.inventory_item.inventory_status}</span>
                                  <label className="cb-container">
                                
                                    <input type="checkbox" onChange={this.handleActive} checked={this.state.inventory_item.inventory_status==='Active'?true:false}/>
                                    <span className="checkmark"></span>
                                    <span className="inv-stat-msg">Make this product {this.state.inventory_item.inventory_status==='Draft'?'Active':'Inactive'}</span>
                                  </label>
                                </div>
                                <div className="col-md-3"><button type="button" className="btn btn-success pull-right" onClick={this.showImages}>Add Images</button></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-right"><label>Product Name</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="item_name" className="form-field form-control" placeholder="Enter Product Name" defaultValue={this.state.inventory_item.item_name} onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>HSN/SAC</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="hsn" className="form-field form-control" placeholder="Enter HSN/SAC" id="item-hsn" defaultValue={this.state.inventory_item.hsn}  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>SKU</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="sku" className="form-field form-control" placeholder="Enter SKU" id="item-sku" defaultValue={this.state.inventory_item.sku} readOnly  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3">
                                   <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" onChange={this.operateCheck} id="auto-sku" value="New Sale" checked={this.state.auto_sku===true?true:false}/>
                                  <label className="form-check-label" htmlFor="auto-sku">Autogenerate SKU</label>
                                </div>
                                </div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Barcode Name</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="barcode_name" className="form-field form-control" placeholder="Enter Barcode Name" id="barcode_name" defaultValue={this.state.inventory_item.barcode_name}  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Custom Text</label></div>
                                 <div className="col-md-6">
                                  <input type="text" name="custom_text" className="form-field form-control" placeholder="Enter Custom Text" id="custom_text" defaultValue={this.state.inventory_item.custom_text}  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div> 
                            <div className={"row mrtop-15 "+hide_supplier}>
                                <div className="col-md-3 text-right"><label>Supplier</label></div>
                                 <div className="col-md-6">
                                  <ContactsDropdown show="dropdown" label="Type to select Supplier" input_id="contact_box" contact_info={this.state.contact_info}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                          
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Category</label></div>
                                 <div className="col-md-6">
                                    <select className="form-control" defaultValue={this.state.inventory_item.category_id} name="category_id"  onChange={this.handleChange}>
                                     {(this.state.category_item.length > 0) ? this.state.category_item.map( (value, index) => {
                                       return (
                                          <option value={value.category_id} key={index}>{value.category}</option>
                                        )
                                      }) : ''}
                                    </select>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Quantity</label></div>
                                 <div className="col-md-6">
                                     <input type="text" name="quantity" data-id="iq" id="item-quantity-iq" className="form-field form-control" placeholder="" defaultValue={this.state.inventory_item.quantity} onKeyUp={this.handleCalculation}  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                          
                            <div className={"row mrtop-15 "+hide_purchase}>
                                <div className="col-md-3 text-right"><label>Purchase Price</label></div>
                                 <div className="col-md-6">
                                     <input type="text"  data-id="iq" id="price-iq"  name="purchase_price" className="form-field form-control" placeholder="Enter Purchase Price" defaultValue={this.state.inventory_item.purchase_price} onKeyUp={this.handleCalculation}  onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                          
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Selling Price</label></div>
                                <div className="col-md-6">
                                  <input type="text" name="selling_price" className="form-field form-control" placeholder="Enter Selling Price" defaultValue={this.state.inventory_item.selling_price}  onChange={this.handleChange} onBlur={this.checkLowestSellingPrice}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Lowest Selling Price</label></div>
                                <div className="col-md-6">
                                  <input type="text" name="lowest_selling_price" className="form-field form-control" placeholder="Enter Lowest Selling Price" defaultValue={(this.state.inventory_item.lowest_selling_price!==null)?this.state.inventory_item.lowest_selling_price:this.state.inventory_item.selling_price}  onChange={this.handleChange}/>
                                    {(parseFloat(this.state.inventory_item.lowest_selling_price)>parseFloat(this.state.inventory_item.selling_price)) &&
                                      <p className="error">Lowest selling price is greater than selling price. Please modify it before updating.</p>
                                    }  
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Unit</label></div>
                                 <div className="col-md-6">
                                     <ProductUnits show="dropdown" data_id={-1} id='item-unit' default={(this.state.inventory_item.product_unit_id && this.state.inventory_item.product_unit_id)?this.state.inventory_item.product_unit_id:''} target="item-unit"/ >
                                      
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label style={{marginTop:'35px'}}>Tax</label></div>
                                 <div className="col-md-2">
                                    <label htmlFor="cgst">CGST</label>
                                    <div className="input-group">
                                      <input type="text" className="form-control no-border-right" id="cgst" defaultValue={this.state.inventory_item.cgst}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                     <label htmlFor="sgst">SGST</label>
                                     <div className="input-group">
                                      <input type="text" className="form-control no-border-right" id="sgst" defaultValue={this.state.inventory_item.sgst}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                 <div className="col-md-2">
                                     <label htmlFor="igst">IGST</label>
                                     <div className="input-group">
                                      <input type="text" className="form-control no-border-right" id="igst" defaultValue={this.state.inventory_item.igst}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text pad-left-none">%</span>
                                      </div>
                                    </div>
                                 </div>
                                <div className="col-md-3"></div>
                            </div>
                            
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Discount</label></div>
                                 <div className="col-md-6">
                                     <input type="text" name="discount"  data-id="iq" id="discount-iq" className="form-field form-control" placeholder="Enter Discount" defaultValue={this.state.inventory_item.discount} onKeyUp={this.handleCalculation}  onChange={this.handleChange}/>
                                     <input type="hidden" className="item-discount-hidden" id={"discount-hidden-iq"}  defaultValue={0}/>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Bundle Quantity</label></div>
                                 <div className="col-md-6">
                                     <input type="text" name="bundle_quantity"  data-id="iq" id="bundle_quantity-iq" className="form-field form-control" placeholder="Enter Bundle Quantity" defaultValue={this.state.inventory_item.bundle_quantity}  onChange={this.handleChange}/>
                                     
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"><label>Product Description</label></div>
                                 <div className="col-md-6">
                                   <textarea className="form-control" rows="3" defaultValue={this.state.inventory_item.item_desc} name="item_desc"  onChange={this.handleChange}></textarea>
                                </div>
                                <div className="col-md-3"></div>
                            </div> 
                            {this.state.active_tab==='inventory' && 
                            <>
                            <div className="row mrtop-15">
                                <div className="col-md-3 text-right"></div>
                                 <div className="col-md-6">
                                   <label className="cb-container"> This product has multiple varient
                                    <input type="checkbox" checked={this.state.multiple_variant} onChange={this.handleVariant}/>
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            {this.state.multiple_variant===true && 
                              <>
                              <div className="row mrtop-15">
                                  <div className="col-md-3"></div>
                                  <div className="col-md-6">
                                      {this.state.variation_options}
                                  </div>
                                  <div className="col-md-3"></div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <button type="button" onClick={this.moreVariationTag} className="btn btn-sm pull-right">Add More</button>
                                    <button type="button" className="btn btn-primary  btn-sm pull-right" onClick={this.createVariation}>Create Variant</button>
                                </div>
                                <div className="col-md-3"></div>
                              </div>
                              
                              </>
                            }
                            </>
                          }
                        </div>
                     </div>
                     {this.state.multiple_variant===true && 
                       <div className="border-bottom">{this.state.variation_list}</div>
                      }
                     {this.is_access('inventory-modify') && 
                    <div className="content-head border-bottom">

                        <button type="button" id="update-inventory-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        {this.state.active_tab==='inventory' && 
                        <button type="button" onClick={this.updateInventory} className="btn btn-success btn-save-action" id="update-inventory">Update Inventory</button>
                        }
                        {this.state.active_tab==='variation' && 
                        <button type="button" onClick={this.updateInventory} className="btn btn-success btn-save-action" id="update-inventory">Update Variation</button>

                        }
                    </div>
                  }
                  </div>
                </div>
              </div>
              <ModalBox id="preview-image" title="Preview Image" data_id="Preview Image"/>
              <ModalBox id="notice_box" title="Notice" data_id="Notice"/>
              <ModalBox id="product-images" inventory_item={this.state.inventory_item} inventory_meta={this.state.inventory_meta} inventory_images={this.state.inventory_images} title="Inventory Images" data_id="Inventory Images"/>
            </div>

        );
    }
}