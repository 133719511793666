import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import check_access from './access-level.js';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let productUnits = [];
let _this;
let db;
export default class ProductUnits extends Component {
    constructor() {
      super();
      this.state = {
          product_units : [],
          modeDefault : 0,
      };
      _this = this;
      this.editMode = this.editMode.bind(this);
      this.normalMode = this.normalMode.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.is_access = check_access.is_access.bind(this);
    }

    handChangeOnFly = ()=>{
      let unit = localStorage.getItem('spunit');
      this.setState({modeDefault:unit});
    }

    handleChange(e){
      let data_id = parseInt(e.currentTarget.getAttribute('data-id'));
      this.setState({modeDefault:e.target.value});
      var unit_name = e.target.options[e.target.selectedIndex].text;
      let un = unit_name.toLowerCase();
      if(data_id>=0){
        if(document.getElementById("iitem-unit-name")){
          let quantity = 1;
          if(document.getElementById("iitem-quantity")){
            quantity = document.getElementById("iitem-quantity").value;
            quantity = (quantity!==''?parseFloat(quantity):1);
          }
          let unitname = document.getElementById("iitem-unit-name").value;
          unitname = unitname.toLowerCase();
          let bundle = document.getElementById("iitem-bundle-quantity").value;

          let quan = quantity;
         
      
          
          if(unitname==='pcs' && un==='dzn'){
            quan = quan*12;
          }

          if(unitname==='dzn' && un==='pcs'){
            if(bundle>0){
              bundle = bundle*12;
            }
          }
          let bundle_quantity = 0;
          
          if(bundle<=quan && bundle>0){
              bundle_quantity = quan/bundle;
              if(!Number.isInteger(bundle_quantity)){
                  bundle_quantity = 0;
              }
          }

          let bundle_text = document.getElementById("iitem-bundle-text").value;

          if(bundle_quantity>0){
            document.getElementById('bundle_quantity').innerHTML = bundle_quantity+' '+bundle_text;
          }else{
            document.getElementById('bundle_quantity').innerHTML = '';
          }

          let iiunit = document.getElementById("iitem-unit");
          let item_unit_name = iiunit.options[iiunit.selectedIndex].text;
          document.getElementById("iitem-unit-name").value = item_unit_name;
        }
        if(this.props.handleChangeUnit){
          this.props.handleChangeUnit(e.target.value,unit_name);
        }
      }
    }
    componentDidMount(){
      _this = this;
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_units';
        axios.post(api, body).then(function (response) {
            let cursor = response.data;
            cursor.map( (value, key) => {
                let id = value.product_unit_id;
                let unit_name = value.unit_name;
                productUnits[id] = unit_name;
            });
            this.setState({ 'product_units': productUnits }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    editMode(event){ 
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('punit-span-'+id);
      var text = document.getElementById('punit-txt-'+id);
      var normal = document.getElementById('punit-normal-mode-'+id);
      var edit = document.getElementById('punit-edit-mode-'+id);

      span.classList.remove("show");
      span.classList.add("hide");

      text.classList.remove("hide");
      text.classList.add("show");

      normal.classList.remove("show");
      normal.classList.add("hide");

      edit.classList.remove("hide");
      edit.classList.add("show");
    }

    updateRow(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('punit-span-'+id);
      var text = document.getElementById('punit-txt-'+id);
      var normal = document.getElementById('punit-normal-mode-'+id);
      var edit = document.getElementById('punit-edit-mode-'+id);
      let oldValue = span.innerHTML;

      let changed_val = text.value;
      span.innerHTML = changed_val;

      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
      let body = {};
      body = {
            id : id,
            oldname : oldValue,
            unit_name : changed_val,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
         }
      let api = app_api+'/update_unit';
      axios.post(api, body)
        .then(function (response) {
        
      }).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
      
    }

    normalMode(event){
      event.preventDefault();
      let id = event.currentTarget.id;
      var span = document.getElementById('punit-span-'+id);
      var text = document.getElementById('punit-txt-'+id);
      var normal = document.getElementById('punit-normal-mode-'+id);
      var edit = document.getElementById('punit-edit-mode-'+id);
      let oldValue = span.innerHTML;
      text.value = oldValue;
      span.classList.remove("hide");
      span.classList.add("show");

      text.classList.remove("show");
      text.classList.add("hide");

      normal.classList.remove("hide");
      normal.classList.add("show");

      edit.classList.remove("show");
      edit.classList.add("hide");
    }
     deleteRow(event){
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      
      if(target!==null){
        var element = document.getElementById(target);
        element.classList.remove("fade");
        element.style.display="block";
        document.getElementById("deleteValue").value=data_value;
        document.getElementById("deleteIndex").value=id;
        document.getElementById("deleteTarget").value=data_id;
      }
    }
   
    render() {
        return (
          <div>
            {this.props.show==='list' &&
            <table className="table">
              <tbody>
                  {(this.state.product_units.length > 0) ?this.state.product_units.map( (value, index) => {
                   return (
                    <tr key={ index }  id={'delete_unit_'+index}>
                      <td>
                        <span id={'punit-span-'+index } className="show l-span p-unit-name">
                          { value }
                        </span>
                        <input type="text" defaultValue={value} className="form-control hide" id={'punit-txt-'+index}/>
                      </td>
                      <td>
                        <div id={'punit-normal-mode-'+index} className="show">
                        {this.is_access('settings-bill-payment-modify') && 
                          <FontAwesomeIcon id={index} onClick={this.editMode} icon={faPencilAlt} className="pull-right fa-icon"/>
                        }
                        {this.is_access('settings-bill-payment-delete') && 
                          <FontAwesomeIcon id={index} onClick={this.deleteRow} data_target="delete_confirm" data_value={value} data_id="delete_unit" icon={faTrash} className="pull-right fa-icon"/>
                        }
                        </div>
                        <div id={'punit-edit-mode-'+index} className="hide mrtop-10">
                        {this.is_access('settings-bill-payment-modify') && 
                        <>
                          <FontAwesomeIcon id={index} onClick={this.updateRow} icon={faCheckCircle} className="pull-right fa-icon"/>
                          <FontAwesomeIcon id={index} onClick={this.normalMode} icon={faTimes} className="pull-right fa-icon"/>
                        </>
                        }
                        </div>
                      </td>
                    </tr>
                  )
                 }) : <tr><td>No record(s) found</td></tr> }
              </tbody> 
            </table>
            }       
            {this.props.show==='dropdown' &&   
              <>
              <span id="handChangeOnFly" onClick={this.handChangeOnFly}></span>
              <select tabindex={this.props.tabindex} className="form-control form-field" data-id={this.props.data_id} target={this.props.target} id={this.props.id} name={this.props.name}  value={this.state.modeDefault||this.props.default} onChange={this.handleChange}>
                 
                  {(this.state.product_units.length > 0) ? this.state.product_units.map( (value, index) => {
                    return (
                      <option data-id={this.props.data_id} value={index} key={index}>{value}</option>
                      )
                    }) :<option value="">---</option>}
              </select>
              </>
            }     
            </div>
        );
    }
}