import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faTrash  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
export default class Expenses extends Component {
    constructor() {
        super();
        this.state = {
          active_tab : 'expense',
          FromDate:  new Date(),
          ToDate: new Date(),
          expenses_list:[],
          expenses_list_temp:[],
          trash_count : 0,
          loading_process:'Loading...',
          is_search:false,
        }

        this.refreshExpenseList = this.refreshExpenseList.bind(this);
        this.handleExpenseSearch = this.handleExpenseSearch.bind(this);
        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };

  handleExpenseSearch(e){
      let _this = this;
      let item_name = document.getElementById("expense-search-box").value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ expenses_list: [],is_search:true });
        clearTimeout(this.timeout);
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : item_name,
              search_item : amount_item,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        let api = app_api+'/search_expense';
        _this.timeout = setTimeout(function () {
        axios.post(api, body)
          .then(function (response) {
               let page_count = response.data.number_of_page;
                let trash_count = response.data.trash_count;
                if(response.data.is_empty){
                  _this.setState({loading_process:'Record(s) not found'});
                  _this.setState({total_page:0});
                  _this.setState({trash_count:trash_count});
                }else{
                  _this.setState({total_page:page_count});
                  _this.setState({trash_count:trash_count});
                  _this.setState({ 'expenses_list': response.data.data });
                }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          _this.refreshExpenseList();
          _this.setState({ is_search:false });
        }
      }
  }


  componentDidMount(){
    let curl = window.location.href;
    this.refreshExpenseList();
  }

  refreshExpenseList(){
    let curl = window.location.href;
    const expense_type = curl.match(/expenses\/trash/i);

    let ptype = 'all';
    if(expense_type){
      ptype = 'trash';
      this.setState({active_tab:'expense-trash'});
    }

    const page_tab = curl.match(/expenses\/page\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[1];
    }
    const trash_page = curl.match(/expenses\/trash\/page\/(\d+$)/i);
    if(trash_page && trash_page[1]){
      ptype = 'trash';
      current_page = trash_page[1];
    }

    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : ptype,
              uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
           }
        let api = app_api+'/get_expenses';
        axios.post(api, body)
        .then(function (response) {
          let page_count = response.data.number_of_page;
          let trash_count = response.data.trash_count;
          
          if(response.data.data.length==0){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
            this.setState({trash_count:trash_count});
          }else{
            this.setState({total_page:page_count});
            this.setState({trash_count:trash_count});
            this.setState({ 'expenses_list': response.data.data });
            this.setState({ 'expenses_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Expenses List</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onChange={this.handleExpenseSearch} id="expense-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                          <Notification /> 
                          {(this.state.trash_count>0) &&
                            <a href={"/expenses/trash"} className="btrash">
                              <FontAwesomeIcon  icon={faTrash}  className=""/><span className="notif-count">{this.state.trash_count}</span>
                            </a>
                          }
                          {(this.is_access('expense-create') || this.is_access('expense-create')) &&
                            <>
                              <a href="/new-expense"><button type="button" className="btn btn-success pull-right nav-btn">New Expenses</button></a>
                            </>
                          }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">EXPENSE #</th>
                            <th scope="col">DATE</th>
                            {/* <th scope="col">NAME</th>
                            <th scope="col">TYPE</th> */}
                            <th scope="col">AMOUNT</th>
                            {/* <th scope="col">MODE OF PAYMENT</th> */}
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.expenses_list.length > 0) ?this.state.expenses_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } id={"billing-row-"+value.expense_id} className={(value.exp_status!==6)?"contact-row":"contact-row strikeout"} data-id={7}>
                             
                              <td>
                              <Link link={'/view-expense/'+value.expense_id} name={value.expense_number} />
                              {(this.state.is_search===true) &&
                                  <>
                                    <i style={{marginLeft:'5px'}} className={value.color}>({value.current_status})</i>
                                  </>
                              }
                              </td>
                              <td>{value.created_date}</td>
                              {/* <td><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} /></td>
                              <td>{value.expense_type_name}</td> */}
                              <td>₹{this.currency_format(value.grand_total.replace(/,/g ,''))}</td>
                             {/*  <td>{value.payment_mode}</td>   */}          
                              <td>
                                {(value.exp_status!==6) &&
                                  <Tooltip opt={this.state.top_option} id={value.expense_id} index={index} view={"/view-expense/"+value.expense_id}  edit={"/new-expense/edit/"+value.expense_id} delete={{active_tab:'expense',id:value.expense_id,data_value:this.state.active_tab,delete_label:value.delete_label}} target="expenses" item_count={this.state.expenses_list.length}/>
                                }
                                {(value.exp_status===6) &&
                                  <Tooltip opt={this.state.top_option} id={value.expense_id} index={index} view={"/view-expense/"+value.expense_id}  target="expenses" item_count={this.state.expenses_list.length}/>
                                }
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={3} className="text-center">{this.state.loading_process}</td></tr> }
                           
                         
                        </tbody>
                      </table>
                      {(this.state.expenses_list && this.state.expenses_list.length > 0) &&
                        <ListCount list={this.state.expenses_list}/>
                      }
                      {(this.state.is_search===false) &&
                        <Pager total_page={this.state.total_page}/>
                      }
                     </div>
                  </div>
                </div>
              </div>

            </div>
        );
    }
}