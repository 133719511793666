import React, { Component } from "react";
export default class Link extends Component {
    constructor() {
      super();
      this.state = {
        
      };
    }
    render() {
        return (
           <a href={this.props.link} style={this.props.style} target={this.props.target}>{this.props.name}</a>
        );
    }
}