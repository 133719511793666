import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ModalBox from "./modal-box";
import axios from 'axios';
import utils from '../utils.js';
import check_access from './access-level.js';
import ClosedFYButton from "./closed-fy-button";
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class Toottip extends Component {
    constructor() {
      super();
      this.state = {
        active_type : 'regular',
        delete_data : {}
      };
      this.deleteRow = this.deleteRow.bind(this);
      this.handleRestore = this.handleRestore.bind(this);
      this.is_access = check_access.is_access.bind(this);
      _this = this;
    }
    componentDidMount(){
      let curl = window.location.href;
      const trash_expense = curl.match(/expenses\/trash+$/i);
      const trash_payment = curl.match(/payments\/trash+$/i);
      const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);

      const orders_trash_hold = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
      const orders_trash_hold_page = curl.match(/orders\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);

      const quot_trash_hold = curl.match(/quotation\/([hold|trash]+$)/i);
      

      let type = 'regular';
      if(trash_hold){
         type = trash_hold[2];
      }else if(trash_hold_page){
         type = trash_hold_page[2];
      }else  if(orders_trash_hold){
        type = orders_trash_hold[2];
     }else if(orders_trash_hold_page){
        type = orders_trash_hold_page[2];
     }else if(trash_payment){
         type = 'trash';
      }else if(quot_trash_hold){
        type = 'trash';
      }else if(trash_expense){
         type = 'expense-trash';
      }
      this.setState({active_type:type});
    }
    deleteRow(event){
      let bill_sequence = '';
      if(this.props.data){
        bill_sequence = this.props.data.number;
      }
      let target = event.currentTarget.getAttribute('data_target');
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      let del_option = event.currentTarget.innerHTML;
      del_option = del_option.toLowerCase();
      document.getElementById("deleteSequence").value=bill_sequence;
      if(this.props.delete && this.props.delete.is_order_pay){
        document.getElementById("deleteScreen").value='order';
      }
      if(target!==null){
        var element = document.getElementById('delete_confirm');
        if(element){
          
          element.classList.remove("fade");
          element.style.display="block";
          document.getElementById("deleteValue").value=data_value;
          document.getElementById("deleteIndex").value=id;
          document.getElementById("deleteTarget").value=data_id;
          document.getElementById("deleteAction").value=del_option;
          let delete_data = this.props.delete;
          if(document.getElementById("cancel-e-inv-block")){
            document.getElementById("cancel-e-inv-block").classList.add("hide");
          }
          if(document.getElementById('without-inv-blck')){
            document.getElementById('without-inv-blck').classList.remove('hide');
          }
          if(delete_data!==undefined && delete_data.is_e_invoice!==undefined && delete_data.is_e_invoice===true){
            if(document.getElementById("cancel-e-inv-block")){
              document.getElementById("cancel-e-inv-block").classList.remove("hide");
            }
            if(document.getElementById('without-inv-blck')){
              
                document.getElementById('without-inv-blck').classList.add('hide');
              
            }
          }
          if(target==='cancelled'){
            if(document.getElementById('without-inv-blck')){
              setTimeout(() => {
                document.getElementById('without-inv-blck').classList.add('hide');
              }, 100);
            }
          }

          if(data_value==='expense-trash' || data_value==='expense' || data_value==='billing' || data_value==='payment' || (data_id==='delete_payment' && data_value==='billing-trash')){
            document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';     
          }
        }
      }
    }
    handleRestore(e){
        let data_id = e.currentTarget.getAttribute('data-id');
        let data_target = e.currentTarget.getAttribute('data-target');
        let element = document.getElementById("billing-row-"+data_id);
        let col_span = element.getAttribute('data-id');
        
        let screen = '';
        if(this.props.delete.is_order_pay){
          screen = 'order';
        }
        let str = '<td class="row-restore" colspan="'+col_span+'">Restored Successful</td>';
        element.innerHTML = str;
        let body = {  
            id : data_id,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            screen : screen
         }
        let api = app_api+'/'+data_target;
        axios.post(api, body)
          .then(function (response) {
           
              window.location.reload();
            
        }).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    handleOpenEditModel = (e) =>{
      this.props.handleOpenEditModel(e)
    }
    render() {
        let view = true;
        let edit = true;
        let del = true;
        if(this.props.target){
            let target = this.props.target;

            if(target==='sales'){
              if(!this.is_access('billing-sale-view')){
                view = false;
              }
              if(!this.is_access('billing-sale-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sale-delete')){
                del = false;
              }
            }else if(target==='quotation'){
              if(!this.is_access('billing-quotation-view')){
                view = false;
              }
              if(!this.is_access('billing-quotation-modify')){
                edit = false;
              }
              if(!this.is_access('billing-quotation-delete')){
                del = false;
              }
            }else if(target==='purchase'){
              if(!this.is_access('billing-purchase-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-delete')){
                del = false;
              }
            }else if(target==='sales_return'){
              if(!this.is_access('billing-sales-return-view')){
                view = false;
              }
              if(!this.is_access('billing-sales-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sales-return-delete')){
                del = false;
              }
            }else if(target==='purchase_return'){
              if(!this.is_access('billing-purchase-return-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-return-delete')){
                del = false;
              }
            }else if(target==='IN(+)'){
              if(!this.is_access('payments-in-view')){
                view = false;
              }
              if(!this.is_access('payments-in-modify')){
                edit = false;
              }
              if(!this.is_access('payments-in-delete')){
                del = false;
              }
            }else if(target==='IN(-)'){
              if(!this.is_access('payments-out-view')){
                view = false;
              }
              if(!this.is_access('payments-out-modify')){
                edit = false;
              }
              if(!this.is_access('payments-out-delete')){
                del = false;
              }
            }else if(target==='inventory'){
              if(!this.is_access('inventory-view')){
                view = false;
              }
              if(!this.is_access('inventory-modify')){
                edit = false;
              }
              if(!this.is_access('inventory-delete')){
                del = false;
              }
            }else if(target==='contacts'){
              if(!this.is_access('contacts-view')){
                view = false;
              }
              if(!this.is_access('contacts-modify')){
                edit = false;
              }
              if(!this.is_access('contacts-delete')){
                del = false;
              }
            }else if(target==='users'){
              if(!this.is_access('settings-user-management-view')){
                view = false;
              }
              if(!this.is_access('settings-user-management-modify')){
                edit = false;
              }
              if(!this.is_access('settings-user-management-delete')){
                del = false;
              }
            }else if(target==='transport'){
              if(!this.is_access('transport-view')){
                view = false;
              }
              if(!this.is_access('transport-modify')){
                edit = false;
              }
              if(!this.is_access('transport-delete')){
                del = false;
              }
            }
        }
        let direction_cls = '';
        if(this.props.item_count>=7 && this.props.index>=7){
            direction_cls = 'top-dir';
        }
        return (
           <>
            <div className="dropdown" style={{float:'right'}}>
              <span>...</span>
              <div className={"dropdown-content "+direction_cls}>
                  {this.state.active_type==='regular' &&
                  <ul className="list-group">
                    {(this.props.view && view) &&
                      <li className="list-group-item"><a href={this.props.view}>View</a></li>
                    }
                    {(this.props.resume) &&
                    <>
                      <li className="list-group-item"><a href={this.props.resume}>Restart</a></li>
                      {(this.props.is_delete_cancelled_bill && this.props.is_delete_cancelled_bill.is_delete_cancelled_bill===true) &&
                        <>
                          <li className="list-group-item" id={this.props.id} data_value={this.props.is_delete_cancelled_bill.data_value} onClick={this.deleteRow} data_target="cancelled"  data_id={"delete_"+this.props.is_delete_cancelled_bill.active_tab}>
                            {(this.props.is_delete_cancelled_bill.delete_label)?this.props.is_delete_cancelled_bill.delete_label:'Delete'}
                          </li>
                        </>
                      }
                    </>
                    }
                    {(this.props.convert_to_sale) &&
                      <li className="list-group-item"><a href={this.props.convert_to_sale}>Convert to Sale</a></li>
                    }
                    {(this.props.edit && edit) &&
                      <li className="list-group-item">
                        {(this.props.is_fy_closed!==true) &&
                          <>
                          {(!isNaN(this.props.edit)) &&
                            <a onClick={this.handleOpenEditModel} target={this.props.target} index={this.props.index}>Edit</a>
                          }
                          {(isNaN(this.props.edit)) &&
                            <a href={this.props.edit}>Edit</a>
                          }
                          </>
                        }
                        {(this.props.is_fy_closed===true) &&
                          <>
                            <ClosedFYButton label="Edit" type="div"/>
                          </>
                        }
                      </li>
                    }
                    {(this.props.delete && del) &&
                    <>
                     {(this.props.is_fy_closed!==true) &&
                      <>
                        <li className="list-group-item" id={this.props.id} data_value={this.props.delete.data_value} onClick={this.deleteRow} data_target="delete_confirm" data_id={"delete_"+this.props.delete.active_tab} >{(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'}</li>
                      </>
                     }
                      {(this.props.is_fy_closed===true) &&
                        <>
                         <li className="list-group-item">
                            <ClosedFYButton label={(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'} type="div"/>
                         </li>
                        </>
                      }
                     </>
                    }
                    {(this.props.close) &&
                      <>
                        {(this.props.is_fy_closed!==true) &&
                          <li className="list-group-item" id={this.props.id} data_value={this.props.delete.data_value} onClick={this.deleteRow} data_target="delete_confirm" data_id={"delete_"+this.props.delete.active_tab} >Close</li>
                        }
                        {(this.props.is_fy_closed===true) &&
                          <>
                            <li className="list-group-item">
                              <ClosedFYButton label="Close" type="div"/>
                            </li>
                          </>
                        }
                      </>
                    }
                  </ul>
                }
                {this.state.active_type==='hold' &&
                  <ul className="list-group">
                    {(this.props.is_fy_closed!==true) &&
                      <li className="list-group-item"><a href={this.props.edit}>Resume</a></li>
                    }
                    {(this.props.is_fy_closed===true) &&
                      <>
                        <li className="list-group-item">
                          <ClosedFYButton label="Resume" type="div"/>
                        </li>
                      </>
                    }
                    {(this.props.delete && del) &&
                      <>
                        {(this.props.is_fy_closed!==true) &&
                          <li className="list-group-item" id={this.props.id} data_value={this.props.delete.data_value} onClick={this.deleteRow} data_target="delete_confirm" data_id={"delete_"+this.props.delete.active_tab} >{(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'}</li>
                        }
                        {(this.props.is_fy_closed===true) &&
                          <>
                            <li className="list-group-item">
                              <ClosedFYButton label={(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'} type="div"/>
                            </li>
                          </>
                        }
                      </>
                    }
                  </ul>
                }
                {(this.props.target==='cheque') &&
                  <ul className="list-group">
                    {(this.props.opt==0) &&
                      <li className="list-group-item" onClick={this.handleChequeAction} data-target="reconciliation" data-status={this.props.opt} data-id={this.props.id}>Send to Bank for Reconciliation</li>
                    }
                    {(this.props.opt==0 || this.props.opt==1) &&
                      <li className="list-group-item" onClick={this.handleChequeAction} data-target="reject" data-id={this.props.id} data-status={this.props.opt}>Reject</li>
                    }
                    {(this.props.opt==1) &&
                      <li className="list-group-item" onClick={this.handleChequeAction} data-target="payment-received" data-status={this.props.opt} data-id={this.props.id}>Payment Received</li>
                    }
                  </ul>
                }
                {(this.state.active_type==='trash' || this.state.active_type==='expense-trash') &&
                  <ul className="list-group"> 
                    {(this.props.edit && edit) &&
                      <>
                        {(this.props.is_fy_closed!==true) &&
                          <li className="list-group-item" onClick={this.handleRestore} data-index={this.props.index} data-id={this.props.id} data-target={"restore_"+this.props.delete.active_tab}>Restore</li>
                        }
                        {(this.props.is_fy_closed===true) &&
                          <>
                            <li className="list-group-item">
                              <ClosedFYButton label="Restore" type="div"/>
                            </li>
                          </>
                        }
                      </>
                    }
                      {(this.props.delete && del) &&
                      <>               
                       {(this.props.is_fy_closed!==true) &&         
                        <li className="list-group-item" id={this.props.id} data_value={(this.state.active_type==='trash')?"billing-trash":this.state.active_type} onClick={this.deleteRow} data_target="delete_confirm" data_id={"delete_"+this.props.delete.active_tab}>{(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'}</li>
                       }
                       {(this.props.is_fy_closed===true) &&
                          <>
                            <li className="list-group-item">
                              <ClosedFYButton label={(this.props.delete.delete_label)?this.props.delete.delete_label:'Delete'} type="div"/>
                            </li>
                          </>
                        }
                      </>
                      }
                  </ul>
                }
              </div>
            </div>
            {(this.props.index===0) &&
              <ModalBox id="delete_confirm" target={this.props.target} active_type={this.state.active_type} title="Delete" data_id="Delete" delete_message={(this.props.target==='sales' || this.props.target==='purchase' || this.props.target==='sales_return' || this.props.target==='purchase_return')?'Do you really want to delete this bill?':'Do you really want to delete these records? This process cannot be undone.'}/>
            }
          </>
        );
    }

    handleChequeAction = (e)=>{
      this.props.handleChequeAction(e);
    }

}