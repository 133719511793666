import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import utils from '../utils.js';
import ServiceLoader from "./service-loader.js";
let {enc_key,app_api,user_info} = require('../library.js');
export default class CreditDebitNoteLi extends Component {
    constructor() {
      super();
      this.state = {
        is_get_click : false,
        is_loading : false,
        notes :[],
        temp_notes :[],
        active_note :0,
      };
    }

    handleNotesShortcut = (e)=>{
        let key_code = e.which;
        if(key_code===27){
          if(this.state.is_get_click===true){
            this.handleCloseNotePopup();
          }
        }
        
        if(key_code===40){
          
          var notes_list = document.querySelectorAll(".notes-list");
          if(this.state.is_get_click===true){
            let active_note = this.state.active_note;
            active_note++;
            if(notes_list.length>=(active_note+1)){
              this.setState({active_note:active_note});
            }
          }
        }
        
        if(key_code===38){
          var notes_list = document.querySelectorAll(".notes-list");
          if(this.state.is_get_click===true){
            let active_note = this.state.active_note;
            active_note--;
            if(active_note>=0){
              this.setState({active_note:active_note});
            }
          }
        }
        if(key_code===13){
          var notes_list = document.querySelectorAll(".notes-list");
          if(this.state.is_get_click===true){
            let active_note = this.state.active_note;
            notes_list[active_note].click();
          }
        }
    }

    handleGetCDNote = () =>{
        let is_get_click = !this.state.is_get_click;
        this.setState({is_get_click:is_get_click});
        this.setState({notes:[],temp_notes:[],active_note:0});
        this.setState({is_loading:true});
        if(is_get_click===true){
            
            document.addEventListener("keydown", this.handleNotesShortcut);
            
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                contact_id : this.props.data.contact_id,
                target : this.props.data.billing_type,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
             }
          let api = app_api+'/get_contact_debit_credit_note';
          axios.post(api, body)
            .then(function (response) {
              let resp = response.data;
              this.setState({notes:resp.data,temp_notes:resp.data,is_loading:false});
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                        
                    }
                }
            })
        }
    }
    handleCloseNotePopup = () =>{
        this.setState({is_get_click:false});
        document.removeEventListener("keydown", this.handleNotesShortcut);
    }
    handleSearch = (e) =>{
        let userInput = e.currentTarget.value;
        let notes = this.state.temp_notes;
        let notes_list = [];
        notes.map( (value, index) => { 
          let value1 = value.sequence_number;
          let value2 = value.amount;

          if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
              notes_list.push(value);
          }
      });
      this.setState({notes:notes_list})
    }
    handleSelectNote = (e) => {
        let target = e.currentTarget.getAttribute("target");
        let id = e.currentTarget.id;
        document.getElementById("ptype_note").value = id;
        this.props.handleRedeemNote();
        this.handleCloseNotePopup();
    }
    render() {

        return (
            <div style={{display: 'inline',marginLeft: '5px',position:'relative',top:'2px'}}>
                <span onClick={this.handleGetCDNote} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faPlusCircle}/> </span>
                {(this.state.is_get_click===true) &&

                    <div className="modal" style={{display:'block'}} id="contact-cd-note-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header" style={{background: '#03a9f4',color: 'white'}}>
                            <h5 className="modal-title hbtitle">
                                {this.props.data.contact_name}'s {(this.props.data.billing_type==='New Sale')?'Credit Notes':'Debit Notes'}
                            </h5>
                        </div>
                        <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                            <div className="row">
                            <div className="col-md-12 text-center">
                            {(this.state.is_loading==true) &&
                                <ServiceLoader show={this.state.is_loading}/>
                            } 
                            {(this.state.is_loading===false) &&
                                <>
                                <input type="text" className="form-control" placeholder="Type to search" onChange={this.handleSearch} autoFocus/>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th className="text-left">Created Date</th>
                                        <th className="text-left">Note #</th>
                                        <th className="text-right">Redeem Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(this.state.notes.length > 0) ?this.state.notes.map( (value, index) => {
                                    return (
                                        <tr key={index} className={(this.state.active_note===index)?'active notes-list':'notes-list'} target={value.type} id={value.sequence_number} onClick={this.handleSelectNote} style={{cursor:'pointer'}}>
                                            <td className="text-left" style={{padding:'.75rem'}}>{value.created_date}</td>
                                            <td className="text-left" style={{padding:'.75rem'}}>{value.sequence_number}</td>
                                            <td className="text-right" style={{padding:'.75rem'}}>₹{value.amount}</td>
                                        </tr>
                                        )
                                    }) :  <tr><td colSpan={3} style={{textTransform:'capitalize',padding:'.75rem'}}>No Record Found.</td></tr>
                                    }
                                    </tbody>
                                </table>
                                </>
                            }
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer text-left">
                            <button type="button" className="btn btn-secondary" onClick={this.handleCloseNotePopup}>Close</button>
                        </div>
                        </div>
                        </div>
                    </div> 
                }
            </div>
        );
    }
}