import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch, faTrash,faCaretRight,faCaretDown, faShoppingCart,faBell, faSort  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import ClosedFYButton from "./closed-fy-button";
import Pager from "./pager";
import Tooltip from "./tooltip";
import BulkAction from "./bulk-action";
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import Link from './link.js';
import utils from '../utils.js';
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
let _this;
export default class Quotation extends Component {
    constructor() {
        super();
        this.state = {
          FromDate:  new Date(),
          ToDate: new Date(),
          active_tab:'sales',
          loading_process :'Loading...',
          billing_list : [],
          billing_list_temp : [],
          total_page : '',
          trash_count : 0,
          hold_count : 0,
          top_option:'',
          create_button : 'New Quotation',
          button_ref : 1,
          is_search : false,
          sort_type : '',
          is_fy_closed : false,
        }
        _this = this;
        this.timeout = null;
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleBillSearch = this.handleBillSearch.bind(this);
        this.handleShowPayment = this.handleShowPayment.bind(this);
        this.handleSortList = this.handleSortList.bind(this);
        this.refreshBillList = this.refreshBillList.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };

  handleSortList(e){
    let sort_on = e.currentTarget.getAttribute("data-id");
    this.setState({sort_type:sort_on});
    let curl = window.location.href;
    const billings = curl.match(/billings$/i);
    const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(active_tab && active_tab[1]){
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }
    const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold[2];
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
      let a_tab = trash_hold_page[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold_page[2];
      current_page = trash_hold_page[3];
    }
    this.setState({loading_process:'Loading...'});
    this.setState({ billing_list: [] });
    this.setState({ billing_list_temp: [] });
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : top_option,
              sort_on : sort_on,
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
    
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }

  handleBillSearch(e){
      let item_name = e.currentTarget.value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ billing_list: [],is_search:true });
        clearTimeout(this.timeout);
        let api = app_api+'/search_bill';
        _this.timeout = setTimeout(function () {
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              search : item_name,
              search_item : amount_item,
              active_tab : _this.state.active_tab
           }
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
              let trash_count = response.data.trash_count;
              let hold_count = response.data.hold_count;
            
            if(response.data.is_empty){
              _this.setState({loading_process:'Record(s) not found'});
            }else{
              _this.setState({total_page:page_count});
              _this.setState({ billing_list: response.data.data });
            }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          this.refreshBillList();
          this.setState({is_search:false });
        }
      }
  }

  handleShowPayment(e){
    let id = e.currentTarget.id;
    if(document.querySelectorAll(".payment-row-"+id)){
      let elem = document.querySelectorAll(".payment-row-"+id);
      for(let i=0;i<elem.length;i++){
        elem[i].classList.toggle("hide");
      }
    }
    if(document.getElementById("caret-right-"+id)){
      document.getElementById("caret-right-"+id).classList.toggle("hide");
      document.getElementById("caret-down-"+id).classList.toggle("hide");
    }
  }

  handleCheckAll(e){
      let checked = e.currentTarget.checked;
      var cb = document.querySelectorAll('.sub-checkbox'); 
      for (var i = 0; i < cb.length; i++) { 
          cb[i].checked = checked; 
      }
  }

  componentDidMount(){
    localStorage.removeItem('response_id');
    localStorage.removeItem('open_list_view');
    localStorage.removeItem('save_print');
    localStorage.removeItem('open_new_bill');
    let curl = window.location.href;
    const billings = curl.match(/quotation$/i);
    const active_tab = curl.match(/quotation\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/quotation\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'quotation';
    if(billings){
     this.setState({active_tab:'quotation'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'quotation'});
    }
    const trash_hold = curl.match(/quotation\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/quotation\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[1]==='hold' || trash_hold[1]==='trash')){
      let a_tab = 'quotation';
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[1];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[1]==='hold' || trash_hold_page[1]==='trash')){
        let a_tab = 'quotation';
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[1];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[2];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Quotation';
      let button_ref = 1;
     
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
      let _t = this;
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : top_option
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;
            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:page_count});
              this.setState({ billing_list: response.data.data });
              this.setState({ billing_list_temp: response.data.data });
            }
            this.setState({ is_fy_closed: response.data.is_fy_closed });
      }.bind(this)).catch(function(res){
          if(res.response){
            _t.setState({loading_process:'Record(s) not found'});
          }
      })
  }

  refreshBillList(){
    let curl = window.location.href;
    const billings = curl.match(/quotation$/i);
    const active_tab = curl.match(/quotation\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/quotation\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'quotation';
    if(billings){
     this.setState({active_tab:'quotation'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'quotation'});
    }
    const trash_hold = curl.match(/quotation\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/quotation\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[2];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
        let a_tab = trash_hold_page[1];
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[2];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[3];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Sale';
      let button_ref = 1;
      if(current_tab==='purchase'){
          button ='New Purchase';
          button_ref = 2;
      }else if(current_tab==='purchase_return'){
        button = 'New Purchase Return';
        button_ref = 4;
      }else if(current_tab==='sales_return'){
        button = 'New Sales Return';
        button_ref = 3;
      }
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : top_option
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;
            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }
  handleShowHoldBill = (e) =>{
      if(document.getElementById("handleShowHoldBill")){
        document.getElementById("handleShowHoldBill").click();
      }
  }
    render() {
        let data_value = 'billing';
        if(this.state.top_option==='hold'){
          data_value = 'hold_bill';
        }
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Quotation List</b>
                        </div>
                    </div>
                    
                    <div className="col-md-8">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onChange={this.handleBillSearch} id="bill-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                        {(this.is_access('billing-quotation-create')) &&
                        <div className="pull-right">

                          {(this.state.is_fy_closed===false) &&
                          <>
                            <a href={"/create-quotation/"+this.state.button_ref}><button type="button" className="btn btn-success pull-right nav-btn">{this.state.create_button}</button></a>
                            <ShortcutHelp  arrow="top" target="new" shortcuts={['Ctrl+Alt+N']}/>
                          </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <ClosedFYButton className="pull-right nav-btn" label={this.state.create_button} type="button"/>
                          }
                        </div>
                        }
                        <div className="pull-right">

                         <Notification /> 

                        {this.state.trash_count>0 &&
                          <a href={"quotation/trash"} className="btrash">
                            <FontAwesomeIcon  icon={faTrash}  className=""/><span className="notif-count">{this.state.trash_count}</span>
                          </a>
                        }
                       
                        </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  {this.state.is_search===false && this.state.top_option==='' &&
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      {this.is_access('billing-sale') &&
                        <li className={this.state.active_tab==='sales'?'active':''}><a href="/billings">Sales</a></li>
                      }
                      {this.is_access('billing-purchase') &&
                        <li className={this.state.active_tab==='purchase'?'active':''}><a href="/billings/purchase">Purchase</a></li>
                      }
                      {this.is_access('billing-sales-return') &&
                        <li className={this.state.active_tab==='sales_return'?'active':''}><a href="/billings/sales_return">Sales Return</a></li>
                      }
                      {this.is_access('billing-purchase-return') &&
                        <li className={this.state.active_tab==='purchase_return'?'active':''}><a href="/billings/purchase_return">Purchase Return</a></li>
                      }
                      {this.is_access('billing-quotation') &&
                        <li className={this.state.active_tab==='quotation'?'active':''}><a href="/quotation">Quotation</a></li>
                      }
                      
                      {(this.state.hold_count>0 && utils.isMobile()===false) &&
                        <li title="Alt + H" className={this.state.active_tab==='hold'?'active hold_title':'hold_title'}>
                          <a onClick={this.handleShowHoldBill}> <FontAwesomeIcon  icon={faShoppingCart} className=""/> On Hold {this.state.active_tab.replace("_"," ")}({this.state.hold_count})</a>
                        </li>
                      }
                    </ul>
                  <ShortcutHelp target="tabs"  arrow="top" shortcuts={['Ctrl+Alt+1','Ctrl+Alt+2','Ctrl+Alt+3','Ctrl+Alt+4']}/>

                  </div>
                  }
                  <div className="content-area">
                   {this.state.top_option==='' &&
                     <div className="content-filter hide">
                        <div className="row">
                          <div className="col-md-4">
                              <label className="lbl">SHOW SALES</label>
                              <select className="form-control">
                                  <option value="">All</option>
                              </select>
                          </div>
                          <div className="col-md-4">
                            <div className="input-group mrtop-32">
                              <input type="text" className="form-control no-border-right" placeholder="Enter Sales #, Amount #, Balance #, Status #" />
                              <div className="input-group-append">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker  className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                   }
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                          {this.state.top_option==='trash' &&
                            <th scope="col" width="54">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" onChange={this.handleCheckAll}/>
                              </div>
                            </th>
                          } 
                          {(this.state.active_tab==='quotation' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">QUOTATION # <span className="hide"><FontAwesomeIcon icon={faSort} className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='sales_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">SALES RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.is_search===true) &&
                            <th>INVOICE #</th>
                          }
                            <th scope="col" onClick={this.handleSortList} data-id="date" className="sort-head">DATE <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='date')?'sort-on-date':''}/></span></th>
                            <th scope="col">NAME</th>
                            <th scope="col">AMOUNT</th>
                            {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                              <th scope="col">PENDING AMOUNT</th>
                            }
                            {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                              <th scope="col">MODE OF PAYMENT</th>
                            }
                            <th scope="col">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                         <tbody>
                           {(this.state.billing_list && this.state.billing_list.length > 0) ?this.state.billing_list.map( (value, index) => {
                           
                            
                           return (
                            <>
                            <tr key={ index } id={"billing-row-"+value.id} className="contact-row" data-id={this.state.top_option==='trash'?10:9}>
                              {this.state.top_option==='trash' &&
                                 <td>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input sub-checkbox" type="checkbox" value={value.id}/>
                                  </div>
                                </td>
                              }
                              <td onClick={this.handleShowPayment} id={value.id}>
                              <Link link={"/view-quotation/"+value.id} name={value.number} />
                              {(value.is_online===true) &&
                                <span className="online-strip">Online</span>
                              }
                              </td>
                              <td>{value.created_date}</td>
                              <td><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} /></td>
                              <td>{value.total}</td>
                              <td>{value.bill_status}</td>
                              <td>
                                {(value.is_converted!==4 && value.is_converted!==7) && 
                                <>
                                  {(value.bill_status==='Cancelled')?
                                  <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-quotation/"+value.id} resume={"/create-quotation/resume/"+value.id} target={this.state.active_tab} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                  :
                                  <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-quotation/"+value.id} edit={"/create-quotation/edit/"+value.id} delete={{active_tab:this.state.active_tab,id:value.id,data_value:data_value,delete_label:(value.is_current?'Delete':'Cancel')}} target={this.state.active_tab} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} convert_to_sale={"/new-billing/convert-to-sale/"+value.id} close={value.id}/>
                                }
                                </>
                              }
                               {(value.is_converted===4 || value.is_converted===7) && 
                                <>
                                <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-quotation/"+value.id} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                </>
                               }
                                </td>
                            </tr>
                          </>
                          )
                         }) : <tr><td colSpan={this.state.top_option==='trash'?9:8} className="text-center">{_this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {this.state.top_option==='trash' &&
                        <BulkAction data_target={this.state.active_tab} />
                      }
                      {(this.state.billing_list && this.state.billing_list.length > 0) &&
                        <ListCount list={this.state.billing_list}/>
                      }
                      {(this.state.is_search===false) &&
                        <Pager total_page={this.state.total_page}/>
                      }
                     </div>
                  </div>
                </div>
              </div>
              <HoldBillPopup data={{screen:'billing',billing_type:this.state.active_tab,update_id:0}}/>
            </div>
        );
    }
}