import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Pager from "./pager";
import Tooltip from "./tooltip";
import utils from '../utils.js';
import Notification from "./notification.js";
import check_access from './access-level.js';
import DrawerLink from "./open-drawer-link";
import Link from './link.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faFilter, faPencilAlt, faSpinner, faTimes, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from "./autocomplete";
let {enc_key,app_api,user_info} = require('../library.js');
export default class StockTransfer extends Component {
    constructor() {
        super();
        this.state = {
          active_tab : 'stock_transfer',
          FromDate:  new Date(),
          ToDate: new Date(),
          transfer_list:[],
          transfer_list_temp:[],
          trash_count : 0,
          loading_process:'Loading...',
          is_search:false,
          is_transfer_stock:false,
          is_view:false,
          transfer_data : [],
          linked_stores : [],
          stock_transfer : [],
          is_item_edit : '',
          stock_edit_id : '',
        }

        this.currency_format = utils.currency_format.bind(this);
        this.is_access = check_access.is_access.bind(this);
    }
  


  componentDidMount(){
    
    this.getTransferedList();
  }

  getTransferedList = ()=>{
    let curl = window.location.href;
    let ptype = 'all';

    const page_tab = curl.match(/stock-transfer\/page\/(\d+$)/i);
    let current_page = 1;
    if(page_tab && page_tab[1]){
      current_page = page_tab[1];
    }


    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              page : current_page,
              show : ptype,
           }
        let api = app_api+'/get_stock_transfer_list';
        axios.post(api, body)
        .then(function (response) {
          let page_count = response.data.number_of_page;
          if(response.data.data.length==0){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ 'transfer_list': response.data.data });
            this.setState({ 'transfer_list_temp': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })
  }
  

    handleNewStockTransfer = ()=>{
       this.setState({is_transfer_stock:true,transfer_data:[]})
       let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            store_state : user_info.state,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            target : 'stock_transfer',
        }
        let api = app_api+'/get_linked_stores';
        axios.post(api, body)
        .then(function (response) {
            let resp = response.data;
            this.setState({linked_stores:resp.data});
            this.setState({is_child_stroe:resp.is_child});
        }.bind(this)).catch(function(res){
            
        })
    }

    handleOpenEditModel = (e)=>{
      this.handleNewStockTransfer();
      let index = e.currentTarget.getAttribute('index');
      let transfer_list = this.state.transfer_list;
      let transfer_data = transfer_list[index];
      let stock_transfer_id = transfer_data.stock_transfer_id;
      this.setState({stock_edit_id:stock_transfer_id});
      let stock_transfer = transfer_data.stock_transfer_meta;
      
      this.setState({stock_transfer:stock_transfer});
  }

    handleOpenViewModel = (e)=>{
      let index = e.currentTarget.getAttribute('index');
      let transfer_list = this.state.transfer_list;
      let transfer_data = transfer_list[index];
      let stock_transfer_id = transfer_data.stock_transfer_id;
      this.setState({stock_edit_id:stock_transfer_id});
      let stock_transfer = transfer_data.stock_transfer_meta;
      this.setState({is_transfer_stock:true,is_view:true,stock_transfer:stock_transfer});
    }
   

    render() {

        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">Stock Transfer</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pull-right">
                          <Notification /> 
                          {(this.is_access('transport-create') || this.is_access('transport-create')) &&
                            <>
                              <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleNewStockTransfer}>Transfer Stock</button>
                            </>
                          }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content" style={{overflow:'auto'}} id="transport-list-block">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">TRANSFER ID</th>
                            <th scope="col">TRANSFER DATE</th>
                            <th scope="col">FROM</th>
                            <th scope="col">TRANSFERED BY</th>
                            <th scope="col">ACCEPTED BY</th>
                            <th scope="col">STATUS</th>
                            <th scope="col" className="no-print"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.transfer_list.length > 0) ?this.state.transfer_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } id={"billing-row-"+value.transport_id} className={"contact-row"}>
                              <td>
                                <a style={{color:'#9999ff'}} index={index} onClick={this.handleOpenViewModel} id={value.stock_transfer_id}>#{value.stock_transfer_id}</a>
                              </td>
                              <td>{value.transfer_date}</td>
                              <td>{value.store_name}</td>
                              <td>{value.created_by}</td>
                              
                              <td>{value.accepted_by}</td>
                              <td>{value.status}</td>
                              <td className="no-print">
                                {(value.status==='Pending' && value.transfer_type==='out') &&
                                 <Tooltip opt={''} id={value.stock_transfer_id} index={index} handleOpenEditModel={this.handleOpenEditModel} edit={value.stock_transfer_id} delete={{active_tab:'stock_transfer',id:value.stock_transfer_id,data_value:'stock_transfer',delete_label:'Delete'}} target="stock_transfer"/>
                                }
                                {(value.status==='Pending' && value.transfer_type==='in') &&
                                  <a style={{color:'#9999ff'}} onClick={this.handleViewPopup} id={value.stock_transfer_id}>View</a>
                                }
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={9} className="text-center">{this.state.loading_process}</td></tr> }
                           
                         
                        </tbody>
                      </table>
                      {(this.state.is_search===false) &&
                          <Pager total_page={this.state.total_page}/>
                      }
                      
                     </div>
                  </div>
                </div>
              </div>
              {(this.state.is_transfer_stock===true) &&
                <div className="modal" id="transfer-stock-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflow:'auto',display:'block'}}>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title hbtitle">Stock Transfer</h5>
                            </div>
                            <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                                {(this.state.is_view===false) &&
                                <div className="content-head border-bottom">
                                    <b>Stock Transfer</b>
                                    <p className="info">Transfer stock to linked store</p>
                                </div>
                                }
                                {(this.state.is_view===true) &&
                                <div className="content-head border-bottom">
                                    <b>Stock Transfer List</b>
                                    <p className="info">Here, you can find all stock transfer list.</p>
                                </div>
                                }
                                <div className="main-content">
                                    <div className="inner-content">
                                        {(this.state.is_view===false) &&
                                          <div className="row">
                                              <div className="col-md-3 w-50">
                                                  <label htmlFor="transfer-product">PRODUCT</label>
                                                  <Autocomplete
                                                    options={[]}
                                                    data_target="billing"
                                                    title="Start typing SKU or name..."  
                                                    id={"iitem-name"}
                                                    className="auto-comp-opt"
                                                    data_id={1}
                                                    active_tab={'New Purchase'}
                                                    input_id={"iitem-box"}
                                                    item_info={[]}
                                                    target="iitem-box"
                                                    tabindex={1}
                                                    position='bottom'
                                                    is_transfer_stock ={true}
                                                  />
                                              </div>
                                              <div className="col-md-2 w-50">
                                                  <label htmlFor="trans-quantity">QUANTITY</label>
                                                  <input type="text" className="form-control tr-fields" target="trans-quantity" id="trans-quantity" defaultValue={this.state.transport_from}/>
                                              </div>
                                              <div className="col-md-3 w-50">
                                                  <label htmlFor="trans-store">TRANSFER TO STORE</label>
                                                  <select className="form-control" id="trans-store">
                                                      <option value="">SELECT STORE</option>
                                                      {this.state.linked_stores.map((value)=>{
                                                          return (
                                                              <option value={value.linked_store_name}>{value.linked_store_name}</option>
                                                          )
                                                      })}
                                                  </select>
                                              </div>
                                              <div className="col-md-2 w-50">
                                                <button type="button" className="btn btn-success" onClick={this.handleAddStockToList} style={{marginTop:'32px'}}>Add to list</button>
                                              </div>
                                          </div>
                                        }
                                        <div className="row mrtop-10">
                                          <div className="col-md-12">
                                                <table className="table">
                                                    <thead>
                                                      <tr>
                                                        <th>S.No</th>
                                                        <th>PRODUCT</th>
                                                        <th>QUANTITY</th>
                                                        <th>TRANSFER TO STORE</th>
                                                        {(this.state.is_view===false) &&
                                                        <th></th>
                                                     }
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.state.stock_transfer.map((value,index)=>{
                                                        return(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>{value.item_name}</td>
                                                            <td>{value.quantity}</td>
                                                            <td>{value.store}</td>
                                                            {(this.state.is_view===false) &&
                                                            <td className="text-right">
                                                              <FontAwesomeIcon icon={faTimes} onClick={this.handleRemoveStockFromList} data-id={index}/>
                                                              <FontAwesomeIcon icon={faPencilAlt} onClick={this.handleEditStockItem} data-id={index}/>
                                                            </td>
                                                            }
                                                        </tr>
                                                        )
                                                      })}

                                                      {(this.state.stock_transfer.length===0) &&
                                                        <tr>
                                                          <td colSpan={5} className="text-center">Please choose an item to transfer stock</td>
                                                      </tr>
                                                      }
                                                    </tbody>
                                                </table>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6 w-50">
                                    <button type="button" className="btn btn-secondary btn-save-action" onClick={this.handleCloseSaveTransfer}>Close</button>
                                    </div>
                                    {(this.state.is_view===false && this.state.stock_transfer.length>0)&&
                                      <div className="col-md-6 w-50">
                                          <button type="button" id="save-transfer-btn-spinner" className="hide btn btn-success btn-save-action pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                          <button type="button" id="save-transfer" onClick={this.handleSaveTransfer} className="btn btn-success btn-save-action pull-right">Transfer Stock</button>
                                      </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
              }
              
            </div>
        );
    }
    handleCloseSaveTransfer = ()=>{
        this.setState({is_transfer_stock:false,is_view:false});
        this.setState({is_item_edit:''});
        this.setState({stock_edit_id:''});
        this.setState({stock_transfer:[]});
    }
    handleSaveTransfer = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let body = {  
          id : this.state.stock_edit_id,
          stock_transfer : this.state.stock_transfer,
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let api = app_api+'/transfer_stock';
      axios.post(api, body)
          .then(function (response) {
            document.getElementById(thisid).classList.remove("hide");
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            this.handleCloseSaveTransfer();
            window.location.reload();
      }.bind(this)).catch(function(res){
      
      })
    }
    handleAddStockToList = ()=>{
        let is_item_edit = this.state.is_item_edit;
        let item_name = document.getElementById("iitem-box").value;
        let item_id = document.getElementById("iitem-name").value;
        let quantity = document.getElementById("trans-quantity").value;
        let store = document.getElementById("trans-store").value;
        if(store==="" || quantity==="" || item_name===""){
          return false;
        }
       
        let stock_transfer = this.state.stock_transfer;
        let st_list = {item_name:item_name,item_id:item_id,quantity:quantity,store:store};

        if(is_item_edit!==''){
          this.setState({is_item_edit:''});
          stock_transfer[is_item_edit] = st_list;
        }else{
          stock_transfer.push(st_list);
        }
        this.setState({stock_transfer:stock_transfer});
        document.getElementById("iitem-box").value = '';
        document.getElementById("iitem-name").value = '';
        document.getElementById("trans-quantity").value = '';
    }
    handleRemoveStockFromList = (e) =>{
      let id = e.currentTarget.getAttribute('data-id');
      let stock_transfer = this.state.stock_transfer;
      stock_transfer.splice(id,1);
      setTimeout(() => {
        this.setState({stock_transfer:stock_transfer});
      }, 100);
    }
    handleEditStockItem = (e) =>{
      let id = e.currentTarget.getAttribute('data-id');
      let stock_transfer = this.state.stock_transfer[id];
      let item_id = stock_transfer.item_id;
      let item_name = stock_transfer.item_name;
      let item_quantity = stock_transfer.quantity;
      let item_store = stock_transfer.store;
      document.getElementById("iitem-box").value = item_name;
      document.getElementById("iitem-name").value = item_id;
      document.getElementById("old-iitem-name").value = item_id;
      document.getElementById("trans-quantity").value = item_quantity;
      document.getElementById("trans-store").value = item_store;
      this.setState({is_item_edit:id});
    }
    handleViewPopup = (e) =>{
      let id= e.currentTarget.id;
      localStorage.setItem('stock_transfer_id',id);
      document.getElementById("handleOpenStockTransferReqeust").click();
    }
}