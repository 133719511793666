import React, { Component } from "react";
import axios from 'axios';
import utils from '../../utils.js';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
let {enc_key,app_api,user_info} = require('../../library.js');
export default class ResetStoreData extends Component {
    constructor() {
        super();
        this.state = {
            is_check_restore : false,
            step : 1,
            list_items : '',
            is_store_entered : '',
            removed_items : [],
            rm_pwd :'',
            delete_success : false,
            is_contact_clear : false,
            error_msg : ''
        };
        this.resetList = ['sales','purchase','sales_return','purchase_return','payments','inventory','expenses','quotations','credit_debit_note','contact','contact_payment_due','categories'];
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.is_reset!==undefined && nextProps.is_reset===true){
           this.handleOpenResetPopup();
        }
    }

    componentWillUnmount() {
        
    }
    componentDidMount(){
       
    }
    
 
    handleOpenResetPopup = ()  =>{
        var element = document.getElementById('reset-store-popup');
        element.classList.remove("fade");
        element.style.display="block"; 
    }
    
 
    handleCloseResetPopup = ()  =>{
        var element = document.getElementById('reset-store-popup');
        element.classList.add("fade");
        element.style.display="none"; 
        this.setState({is_check_restore:false});
        this.setState({list_items:''});
        this.setState({removed_items:[]});
        this.setState({is_store_entered:''});
    }
     
    handleCheckRestore = (e) =>{
        let eCheck = e.currentTarget.checked;
        this.setState({is_check_restore:eCheck});
        
    }
    handleMoveSteps = (e) =>{
        let id = e.currentTarget.id;
        let step = this.state.step;
        
        if(id==='next'){
            if(step<3){
                let next = step+1;
                if(next===3){
                    let qs = document.querySelectorAll(".reset-i-cb:checked");
                    let list_items =[];
                    let removed_items =[];
                    if(qs.length>0){
                        this.setState({step:next});
                        for (let index = 0; index < qs.length; index++) {
                            const items = qs[index].getAttribute('data-value');
                            let rmitem = qs[index].value;
                            if(rmitem==='contact'){
                                this.setState({is_contact_clear:true})
                            }
                            removed_items.push(rmitem);
                            list_items.push(items);
                        }
                        let litemstr = list_items.toString();
                        litemstr = litemstr.replaceAll(",",', ');
                        this.setState({list_items:litemstr});
                        this.setState({removed_items:removed_items});

                    }
                }else{
                    this.setState({step:next});
                }

            }
        }else if(id==='prev'){
            if(step>1){
                let prev = step-1;
                this.setState({step:prev});
            }
        }else if(id==='done'){
            this.setState({delete_success:true});
            let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                removed_items : this.state.removed_items,
                rms : this.state.is_store_entered,
                rmp : this.state.rm_pwd,
             }
            let api = app_api+'/reset_store_data';
            axios.post(api, body)
                .then(function (response) {
                    let data = response.data;
                    if(data.success===1){

                        try {
                            if(this.state.is_contact_clear===true){
                                var request = window.indexedDB.deleteDatabase("ERP");
                                request.onsuccess = function(e) {  };
                                request.onblocked = function(e) {
                                };
                            }
                        } catch (error) {
                            
                        }

                        document.getElementById("success-circle-loader").classList.add('load-complete');
                        document.getElementById("checkmark-success").style.display ='block';
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 500);
                    }else{
                        let error_msg = data.message;
                        this.setState({delete_success:false,error_msg:error_msg});
                    }
            }.bind(this)).catch(function(res){
                this.setState({delete_success:false});
            })
        }
    }
    handleEnterStoreName = (e) =>{
        let eVal = e.currentTarget.value;
        let store = user_info.store_name;
        if(eVal===store){
            this.setState({is_store_entered:eVal});
        }else{
            this.setState({is_store_entered:''});
        }
    }
    handlePwdFocus = (e) =>{
        e.currentTarget.type = 'password';
    }
    
    handleEnterStorePwd = (e) =>{
        let ePwd = e.currentTarget.value;
        this.setState({rm_pwd:ePwd});
    }
    render() {
        return (
            <div className="modal fade" id="reset-store-popup" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflowY:'auto'}}>
              <div className="modal-dialog modal-confirm modal-lg" style={{width:'auto'}}>
              <div className="modal-content" style={{paddingTop:'5px'}}>
                <div className="modal-header flex-column">
                    {(this.state.step===1)&&
                        <div id="step-1">
                            <div className="icon-box icon-print">
                                <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                            </div>            
                            <h5 className="modal-title w-100 mrtop-10" >
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="reset-confirm" value="reset" onChange={this.handleCheckRestore} checked={this.state.is_check_restore}/>
                                    <label className="form-check-label" for="reset-confirm">Are you sure you want to reset your store data?</label>
                                </div>
                            </h5>  
                        </div>
                    }
                    {(this.state.step===2)&&
                        <div id="step-2">
                            <h5 className="modal-title w-100" style={{background: 'rgb(40 167 69)',color: 'white',padding:'5px 0px'}}>Please select the item from the list to reset</h5>  
                            <table className="table text-left">
                                <tbody>
                                    {this.resetList.map((value,index) => (
                                        <tr>
                                            <td>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input reset-i-cb" type="checkbox" id={'rl'+value} value={value} data-value={value.replaceAll('_',' ')} onChange={this.handleCheckItemRestore} defaultChecked={(this.state.removed_items.includes(value))?true:false}/>
                                                    <label className="form-check-label reset-i-lbl" for={'rl'+value}>
                                                        {value.replaceAll('_',' ')}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                    {(this.state.step===3)&&
                        <div id="step-3"> 
                            {(this.state.delete_success===false) &&
                            <>
                                <div className="icon-box icon-print">
                                    <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                                </div>            
                                <h5 className="modal-title w-100 mrtop-10" style={{fontSize:'18px'}}>
                                All your <b style={{textTransform:'capitalize'}}>{this.state.list_items}</b> data will remove parmanently, You can't recover/restore the removed data.
                                </h5>  
                                
                                <div className="row text-left">
                                    <div className="col-md-12">
                                        <label htmlFor="store-name">Enter Store Name</label>
                                        <input type="text" id="store-name" className="form-control" placeholder="Please enter store name to reset data" onChange={this.handleEnterStoreName} defaultValue={this.state.is_store_entered}/>
                                    </div>
                                    <div className="col-md-12 mrtop-10">
                                        <label htmlFor="store-pwd">Enter Store Password</label>
                                        <input type="text" id="store-pwd" className="form-control" placeholder="Please enter store password to reset data" onChange={this.handleEnterStorePwd} onFocus={this.handlePwdFocus}/>
                                    </div>
                                    {(this.state.error_msg!=='') &&
                                    <div className="col-md-12 mrtop-10">
                                       <p className="error">{this.state.error_msg}</p>
                                    </div>
                                    }
                                </div>
                            </>
                            }
                            {(this.state.delete_success===true) &&
                                <div className="success-circle-loader" id="success-circle-loader">
                                <div className="checkmark-success draw" id="checkmark-success"></div>
                              </div>
                            }
                        </div>
                    }
                </div>
                {(this.state.delete_success===false) &&
                    <div className="modal-footer justify-content-center" style={{padding: '10px 5px 25px'}}>
                    {(this.state.step===1)&&
                    <button type="button" className="btn btn-secondary pull-left" onClick={this.handleCloseResetPopup}>Close</button>
                    }
                    {(this.state.step>1)&&
                    <button type="button" id="prev" className="btn btn-success pull-left" onClick={this.handleMoveSteps}>Prev</button>
                    }
                    {(this.state.is_check_restore && this.state.step<3)&&
                        <button type="button" id="next" className="btn btn-success pull-right" onClick={this.handleMoveSteps}>Next</button>
                    }
                    {(this.state.step==3 && this.state.is_store_entered!=="")&&
                        <>
                            <button type="button" id="done" className="btn btn-success pull-right" onClick={this.handleMoveSteps}>Done</button>
                            <button type="button" id="done-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </>
                    }
                    
                    {(this.state.step==3 && this.state.is_store_entered==="")&&
                        <button type="button" id="done" className="btn btn-secondary pull-right">Done</button>
                    }
                    </div>
                }
              </div>
            </div>
            </div> 
        );
    }
}