import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faCalculator,faShoppingCart, faPlusCircle,faCheckCircle, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import check_access from './access-level.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let BusinessTypes = [];
let _this;
let db;
export default class ChequeInputInfo extends Component {
    constructor() {
      super();
      this.state = {
          business_types : [],
          modeDefault : 0,
          chequeDate:  null,
      };
      _this = this;
      this.is_access = check_access.is_access.bind(this);
    }
    chequeDate = date => {
        this.setState({
          chequeDate: date
        });
    };
   
    componentDidMount = () =>{
        if(this.props.cheque_date && this.props.cheque_date!=="" && this.props.cheque_date!==null){
            this.setState({
                chequeDate: new Date(this.props.cheque_date)
            });
        }
    }

    render() {
        return (
            <>
            {(this.props.type==='div') &&
                <div className="row">
                    <div className="col-md-12">
                        <label>Cheque Bank Name.</label>
                        <input type="text" placeholder="Cheque Bank Name." className="form-control" id="cheque_bank_name"/>
                    </div>
                    <div className="col-md-6">
                        <label>Cheque No.</label>
                        <input type="text" placeholder="Cheque No." className="form-control" id="cheque_no"/>
                    </div>
                    <div className="col-md-6">
                        <label className="">Cheque Date</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                            <span className="input-group-text no-border-right">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </span>
                            <DatePicker className="form-control  no-border-left" selected={this.state.chequeDate} onChange={this.chequeDate} dateFormat="dd/MM/yyyy" id="cheque_date"/>
                            </div>
                        </div>
                    </div>
                    {(this.props.is_reconcile===undefined || this.props.is_reconcile===true) &&
                    <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id={'send-for-recon'}/>
                          <label className="form-check-label" htmlFor={'send-for-recon'}>
                            Send for Reconciliation
                          </label>
                        </div>
                    </div>
                    }
                </div>
            }
            {(this.props.type==='td') &&
                <>
                <tr>
                    <td style={{paddingRight:'10px'}}>
                        <label>Cheque Bank Name.</label>
                        <input type="text" placeholder="Cheque Bank Name" className="form-control chq-bank-name" id={'chq-bank-name-'+this.props.id.replaceAll(' ','-').toLowerCase()}/>
                    </td>
                    <td style={{paddingRight:'10px'}}>
                        <label>Cheque No.</label>
                        <input type="text" placeholder="Cheque No." className="form-control chq-no" id={'chq-no-'+this.props.id.replaceAll(' ','-').toLowerCase()}/>
                    </td>
                    <td>
                        <label className="">Cheque Date</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker className="form-control no-border-left chq-date" selected={this.state.chequeDate} onChange={this.chequeDate} dateFormat="dd/MM/yyyy" id={'chq-date-'+this.props.id.replaceAll(' ','-').toLowerCase()}/>
                                </div>
                            </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                    <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id={'send-for-recon-'+this.props.id.replaceAll(' ','-').toLowerCase()} defaultChecked={true}/>
                          <label className="form-check-label" htmlFor={'send-for-recon-'+this.props.id.replaceAll(' ','-').toLowerCase()}>
                            Send for Reconciliation
                          </label>
                        </div>
                    </td>
                </tr>
                </>
            }
            </>
        );
    }
}