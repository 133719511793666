import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
let {enc_key,app_api,user_info} = require('./library.js');


const dashboard_widgets1 = {total_sale:'Total Sale',total_sale_bills:'Total Sale Bills',total_sale_orders:'Total Sale Orders',sale_order_return:'Sales Order Return',avg_bill_value:'Avg Bill Value',avg_units_per_bill:'Avg Units Per Bill',total_payment_in:'Payment IN',expenses:'Expenses',payment_out:'Payment OUT',sales_return:'Sales Return',payment_mode_graph:'Payment Mode (Graph)',payment_mode_data:'Payment Mode (Data)'};

const currency_format = (number) => {
    if(number===''  || number===undefined || number===null){
        return 0;
    }
    number = number.toString();
    if(number.indexOf(",")){
        number = number.replaceAll(',','');
    }
    var multiplier = Math.pow(10, 2 || 0);
    number =  Math.round(number * multiplier) / multiplier;
	//number = parseFloat(number).toFixed(2);
    number = prepend_decimal_point(number);
    number = new Intl.NumberFormat('en-IN',{
        //style: 'currency',
        //currency: 'INR',
        minimumFractionDigits: 2,
      }).format(number);
    //console.log(number);
    return number;
}
const floating_point = (number) => {
    number = number.toString();
    if(number.indexOf(",")){
        number = number.replaceAll(',','');
    }
    var multiplier = Math.pow(10, 2 || 0);
    number =  Math.round(number * multiplier) / multiplier;
    number = prepend_decimal_point(number);
	//number = parseFloat(number).toFixed(2);
    //return number.replace('.00','');
    return number;
}
const prepend_decimal_point = (num)  =>{
    // Cast as number
    var num = Number(num);
    // If not a number, return 0
    if (isNaN(num)) {
        return num;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length<=2 ){
        num = num.toFixed(2);
    }
    // Return the number
    return parseFloat(num);
}
const handleShare = (data) =>{
    if (navigator.share) {
        navigator.share(data)
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    }else{
        console.log("not supporting");
    }
}

const uuid =()=>{
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
}
const ADJUST_BILL_SNO = (type) => {
    if(type==='regular'){
        let sno = document.querySelectorAll(".b-item-sno");
        let k=1;
        let item_total = 0;
        for(let i=0;i<sno.length;i++){
            let did = sno[i].getAttribute('data-id');
            let data_target = sno[i].getAttribute('data-target');
            
            let has_variant = document.getElementById("item-has-variant-"+did).value;
            let is_variant = document.getElementById("item-is-variant-"+did).value;

            if(is_variant!=='true'){
                sno[i].innerHTML = k;
                k++;
                if(has_variant==='true'){
                   item_total = 0;
                }
            }else{
                let item_total_text = parseFloat(document.getElementById("item-total-txt-"+did).value);
                item_total = item_total+item_total_text;
                sno[i].innerHTML = '';
                if(document.getElementById(data_target)){
                 document.getElementById(data_target).innerHTML = '₹'+currency_format(item_total);
                }
            }
        }
    }else if(type==='return'){
        let sno = document.querySelectorAll(".ret-b-item-sno");
        let k=1;
        for(let i=0;i<sno.length;i++){
            let did = sno[i].getAttribute('data-id');
            sno[i].innerHTML = k;
            k++;
        }
    }
}
const CHECK_INTERNET_CONNECTION = () => {
    var ifConnected = window.navigator.onLine;
    if (ifConnected) {
        return true;
    } else {
        toast("Please check your internet connection");
        return false;
    }
}
const isMobile = () => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
}

const GENERATE_BARCODE = (category_id = 1,is_clicked=false) => {
    /*if(document.getElementById("product-barcode")){
        document.getElementById("product-barcode").value = 'Auto Generate';
    }*/
    let barcode_sequence = '';
    if(!is_clicked && document.getElementById("barcode-sequence")){
        barcode_sequence = document.getElementById("barcode-sequence").value;
    }
  let body = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        category_id : category_id,
        uuid : uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
   }

  let api = app_api+'/auto_generate_barcode';
  axios.post(api, body)
    .then(function (response) {
    let resp = response.data;
    if(document.getElementById("product-barcode")){
        document.getElementById("product-barcode").value = resp.barcode_name;
    }
    if(document.getElementById("barcode-sequence")){
        document.getElementById("barcode-sequence").value = resp.barcode_sequence;
    }
  }.bind(this)).catch(function(res){
      if(res.response){
        if(res.response.data){
            
        }
      }
  })
}
const convert_number_to_words =(amount)=> {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    let value = "";
    if (n_length <= 9) {
        var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}

const shareBillMessage = (data) => {
    let sharable_link = data.sharable_link;
    let title = data.title.replace("New",'')+ ' Invoice';
    title = title.toUpperCase();
    let company_name = user_info.company_name.toUpperCase()
    let txt_message = '';
    if(data.target==='new'){
        txt_message += 'Thank you very much '+data.contact_name+' for shopping with us ('+company_name+')';
         txt_message +='\n';
        for(let i=0;i<60;i++){
          txt_message +='-';
        }
    }
    if(data.target==='view'){
        txt_message += title.trim()+'\n';
        txt_message += company_name;
        if(user_info.company_sub_title!=="" && user_info.company_sub_title!==null){
          txt_message += ' | '+user_info.company_sub_title.toUpperCase();
        }
        txt_message +='\n';
        for(let i=0;i<60;i++){
          txt_message +='-';
        }
    }
    txt_message +='\n';
    txt_message += 'You can download your pdf invoice by cilcking on the below link';
    txt_message +='\n\n';
    txt_message +=sharable_link;
    return txt_message;
}

const check_mobile_number = (mobile) => {
    if(mobile!=="" && mobile!==null && mobile!==undefined && mobile.length>9){
        mobile = mobile.substr(mobile.length - 10);
        mobile = 91+''+mobile;
    }else{
        return false;
    }
    if(mobile!=="" && mobile!==null && mobile!==undefined && mobile.length==12){
        return mobile;
    }else{
        return false;
    }
}

const get_diff_barcode_latest_id = (billing_type,options) => {

    let search_type = localStorage.getItem('search_type');
    if(search_type==='barcode'){
        return -1;
    }
    if(billing_type==='New Sale' || billing_type==='New Sales Return' || billing_type==='sales_return'){
        let is_more_barcode = options.is_more_barcode;  
        let avl = 0;
        if(is_more_barcode>0){
            let bcinvliavail = document.querySelectorAll(".bc-inv-list");
            if(bcinvliavail.length>0){
                return bcinvliavail[0].id;
            }
        }
        return -1;
    }else{
        return -1;
    }
    
}

const get_diff_barcode_inv_availability = (billing_type,options) => {
    
    if(billing_type==='New Sale' || billing_type==='New Sales Return' || billing_type==='sales_return'){
        let search_type = localStorage.getItem('search_type');
        if(search_type==='barcode'){
            return -1;
        }
        
        let is_more_barcode = options.is_more_barcode;  
        let avl = 0;
        if(is_more_barcode>0){
            let bcinvliavail = document.querySelectorAll(".bc-inv-li-avail");
            let toval = 0 ;
            for (let bci = 0; bci < bcinvliavail.length; bci++) {
                let bcavl = bcinvliavail[bci].value;
                toval = toval+parseInt(bcavl);
            }
            avl = toval;
            return avl;
        }
        return -1;
    }else{
        return -1;
    }
}

const CHECK_CREDIT_LIMIT = (target,selected_item) => {
    let total= 0;
    let curl = window.location.href;
    const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
    if(target==='item'){
        total= (document.getElementById("iitem-total-txt"))?document.getElementById("iitem-total-txt").value:0;
    }
    if(target==='save' && localStorage.getItem("use_buff_amount")==='yes'){
        localStorage.removeItem("use_buff_amount");
        return true;
    }
    if(localStorage.getItem("use_cl_buff")!=='yes'){
        let credit_limit_box = (document.getElementById("credit_limit_box"))?document.getElementById("credit_limit_box").value:'';
        
        let original_credit_limit_box = (document.getElementById("original_credit_limit_box"))?document.getElementById("original_credit_limit_box").value:'';
        let buffer_amount_for_credit_limit = (document.getElementById("buffer_amount_for_credit_limit"))?document.getElementById("buffer_amount_for_credit_limit").value:'';
        if(document.getElementById("mode_of_payment")){

            
            let mode_of_payment = document.getElementById("mode_of_payment").value;
            if(mode_of_payment==='2'){
                let total_count = 0;
                if(target==='item'){
                    let item_total_text = document.querySelectorAll(".item-total-txt");
                    for(let i=0;i<item_total_text.length;i++){
                        let did = item_total_text[i].getAttribute('data-id');
                        if(selected_item==='none'){
                            total_count = total_count+parseFloat(item_total_text[i].value);
                        }else{
                            if(selected_item!==parseInt(did)){
                                total_count = total_count+parseFloat(item_total_text[i].value);
                            }
                        }
                    }
                    total_count = total_count+parseFloat(total);
                }
                if(target==='save'){
                    total_count = (document.getElementById("final-total-text"))?document.getElementById("final-total-text").value:0;
                    total_count = parseFloat(total_count);
                }
                if(original_credit_limit_box!==""){
                    original_credit_limit_box = parseFloat(original_credit_limit_box);
                    credit_limit_box = parseFloat(credit_limit_box);
                    if(edit_billing){
                        credit_limit_box = credit_limit_box+total_count;
                    }
                    if(buffer_amount_for_credit_limit===""){
                    buffer_amount_for_credit_limit = 0;
                    }else{
                    buffer_amount_for_credit_limit = parseFloat(buffer_amount_for_credit_limit);
                    }
                    
                    if(original_credit_limit_box>0){
                        let buffer_amount = original_credit_limit_box*buffer_amount_for_credit_limit/100;
                        let climit = credit_limit_box+buffer_amount;

                        if(total_count>credit_limit_box){
                            var element = document.getElementById('buffer_credit_limit');
                            element.classList.remove("fade");
                            element.style.display="block";
                            document.getElementById("close-use-buffer-amount").innerHTML = 'Stop Billing';
                            if(buffer_amount>0 && total_count<climit){
                                if(target==='item'){
                                    document.getElementById("buffer-amount-msg").innerHTML = 'Do you want to continue with credit limit buffer amount?';
                                    document.getElementById("use-buffer-amount").classList.remove('hide');
                                }else if(target==='save'){
                                    document.getElementById("buffer-amount-msg").innerHTML = 'Please try adusting item amount';
                                    document.getElementById("use-buffer-amount").classList.add('hide');
                                    document.getElementById("close-use-buffer-amount").innerHTML = 'Ok';
                                }
                            }
                            setTimeout(function(){
                                document.getElementById("use-buffer-amount").focus();
                            },500);
                            return false;
                        }
                    }
                }
            }
        }
    }
    return true;
}

const PREFIX_TO_NUMBER = (num, size) => {
    if(num===null || num===""){
        return num;
    }
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
const CALCULATE_ENCRYPTED_PRICE = (price,enc) => {
    price = price.toString();
    let enc_price = '';
    for(let i=0;i<price.length;i++){
        let p = price[i];
        if(p!="."){
            enc_price += enc[p];
        }else{
            enc_price += '.';
        }
    }
    price = enc_price;
    return price;
}
export default {currency_format,floating_point,convert_number_to_words,GENERATE_BARCODE,isMobile,ADJUST_BILL_SNO,CHECK_INTERNET_CONNECTION,CHECK_CREDIT_LIMIT,uuid,handleShare,check_mobile_number,shareBillMessage,dashboard_widgets1,get_diff_barcode_inv_availability,get_diff_barcode_latest_id,PREFIX_TO_NUMBER,CALCULATE_ENCRYPTED_PRICE}