import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faPencilAlt, faSpinner} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import utils from '../utils.js';
import ContactsDropdown from "./contacts-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let PaymentMode = [];
let _this;
let db;
export default class CreateJournal extends Component {
    constructor() {
      super();
      this.state = {
        journal_symbol : '+',
        journal_list :[],
        total_debit : 0,
        total_credit : 0,
        journalDate:  new Date(),
      };
      _this = this;
      this.is_access = check_access.is_access.bind(this);
    }
    handleChangeJournalDate = date => {
        this.setState({
          journalDate: date
        });
      };
    componentDidMount = () =>{
        localStorage.removeItem('is_bank_account');
    }

    handSaveJournal = (e) =>{
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                data : this.state.journal_list,
             }
          let api = app_api+'/create_journal';
          axios.post(api, body)
            .then(function (response) {
                    window.location.reload();
                   // window.location.href = '/view-accounts/'+account_id;
               
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
    }
    handleCloseJournalModal = () =>{
        this.props.handleCloseJournalModal();
    }
    handleChangeSymOpt = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({journal_symbol:thisval})
    }
    handleAddToList = (e) =>{
        let remarks = document.getElementById("journal-remarks").value;
        let amount = document.getElementById("journal-amount").value;
        let account_id = document.getElementById("customer-dropdown").value;
        let account_name = document.getElementById("contact_box").value;
        let is_bank_account = (localStorage.getItem("is_bank_account"))?localStorage.getItem("is_bank_account"):'false';
        let symboll = this.state.journal_symbol;
        amount = parseFloat(amount);
        let total_debit = parseFloat(this.state.total_debit);
        let total_credit = parseFloat(this.state.total_credit);
        if(symboll==='+'){
            total_credit  = total_credit + amount;
        }else if(symboll==='-'){
            total_debit  = total_debit + amount;
        }

        this.setState({total_debit:total_debit,total_credit:total_credit})
        
        let meta_data = {
            journal_date : this.state.journalDate,
            remarks : remarks,
            amount : amount,
            account_id : account_id,
            account_name : account_name.replace('(Account)',''),
            is_bank_account : is_bank_account,
            symboll : symboll
        }
        let journal_list = this.state.journal_list;
        journal_list.push(meta_data);


        document.getElementById("journal-remarks").value = '';
        document.getElementById("journal-amount").value = '';
        document.getElementById("customer-dropdown").value  = '';
        document.getElementById("contact_box").value  = '';
        localStorage.removeItem("is_bank_account");
        this.setState({journal_symbol:'+'})
    }

    handleRemoveItem = (idx) =>{
        let journal_list = this.state.journal_list;
        journal_list.splice(idx,1);
        this.setState({journal_list});
        let total_debit = 0;
        let total_credit = 0;
        for (let index = 0; index < journal_list.length; index++) {
            const element = journal_list[index];
            let amount = element.amount;
            let symboll = element.symboll;
            if(symboll==='+'){
                total_credit  = total_credit + amount;
            }else if(symboll==='-'){
                total_debit  = total_debit + amount;
            }
        }
        this.setState({total_debit:total_debit,total_credit:total_credit});
    }
    render() {
        return (
            <div className="modal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Create Journal</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text no-border-right">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    
                                    <DatePicker className="form-control  no-border-left" selected={this.state.journalDate} onChange={this.handleChangeJournalDate} dateFormat="dd/MM/yyyy"/>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-3">
                                <label>ACCOUNT <span className="red">*</span></label>
                                <ContactsDropdown show="dropdown" label="Type to select account" input_id="contact_box" contact_info={[]} index="journal"/>
                            </div>
                          
                            <div className="col-md-2">
                                <label htmlFor="journal-amount">Amount</label>
                                <input type="text" className="form-control" id="journal-amount"/>
                            </div>
                            
                            <div className="col-md-2">
                                <div className="form-check" style={{marginTop:'25px'}}>
                                    <input className="form-check-input" type="radio" name="journal-opt" id="journal-opt1" checked={(this.state.journal_symbol==='+')?true:false} value='+' onChange={this.handleChangeSymOpt}/>
                                    <label className="form-check-label" for="journal-opt1">
                                        Credit ( + )
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="journal-opt" id="journal-opt2"  checked={(this.state.journal_symbol==='-')?true:false} value='-' onChange={this.handleChangeSymOpt}/>
                                    <label className="form-check-label" for="journal-opt2">
                                        Debit ( - )
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="journal-remarks">Remarks</label>
                                <input type="text" className="form-control" id="journal-remarks"/>
                            </div>
                            <div className="col-md-2" style={{marginTop:'30px'}}>
                                <button type="button" className="btn btn-success" onClick={this.handleAddToList}>Add</button>
                            </div>
                        </div>
                        {(this.state.journal_list.length>0) &&
                        <>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Account</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                                <th>Remarks</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.journal_list.map((value,index)=>{
                                                return(
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{value.account_name}</td>
                                                        <td>
                                                            {(value.symboll==='-')&&
                                                                <>₹{utils.currency_format(value.amount)}</>
                                                            }
                                                        </td>
                                                        <td>
                                                            {(value.symboll==='+')&&
                                                                <>₹{utils.currency_format(value.amount)}</>
                                                            }
                                                        </td>
                                                        <td>{value.remarks}</td>
                                                        <td><span style={{cursor:'pointer'}} onClick={() => this.handleRemoveItem(index)} date-id={index}><FontAwesomeIcon icon={faPencilAlt}/></span></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={2} className="text-right">Total</th>
                                                <th>₹{utils.currency_format(this.state.total_debit)}</th>
                                                <th>₹{utils.currency_format(this.state.total_credit)}</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseJournalModal} id="close-journal">Close</button>
                    {(this.state.journal_list.length>0 && (this.state.total_credit===this.state.total_debit)) &&
                        <>
                            <button type="button" data-id="create-journal" className="btn btn-success pull-right" id="create-journal" onClick={this.handSaveJournal}>Save Journal</button>
                            <button type="button" id="create-journal-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </>
                    }
                    {(this.state.journal_list===0 || (this.state.total_credit!==this.state.total_debit)) &&
                        <button type="button" className="btn btn-secondary pull-right">Save Journal</button>
                    }
                    </div>
                </div>
                </div>
            </div>
        );
    }
}