import check_access from './access-level.js';
const redirect = () => {
	  let curl = window.location.href;
	  const billings = curl.match(/billings$/i);
	  const billings1 = curl.match(/billings\/$/i);
	  const purchase = curl.match(/billings\/purchase$/i);
	  const purchase1 = curl.match(/billings\/purchase\/$/i);
	  const sales_return = curl.match(/billings\/sales_return$/i);
	  const sales_return1 = curl.match(/billings\/sales_return\/$/i);
	  const purchase_return = curl.match(/billings\/purchase_return$/i);
	  const purchase_return1 = curl.match(/billings\/purchase_return\/$/i);
	  const view_billing = curl.match(/view-billing\/([a-z_A-Z]+)\/(\d+$)/i);
	  const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
	  const new_sale = curl.match(/new-billing$/i);
	  const new_sale1 = curl.match(/new-billing\/$/i);
	  const add_billing_type = curl.match(/new-billing\/(\d+$)/i);

	  const payments = curl.match(/payments$/i);
	  const payments1 = curl.match(/payments\/$/i);
	  const payment_in = curl.match(/payments\/in$/i);
	  const payment_in1 = curl.match(/payments\/in\/$/i);
	  const payment_out = curl.match(/payments\/out$/i);
	  const payment_out1 = curl.match(/payments\/out\/$/i);

	  const new_payment = curl.match(/new-payment$/i);
	  const new_payment1 = curl.match(/new-payment\/$/i);
	  const new_payment_in = curl.match(/new-payment\/in$/i);
	  const new_payment_in1 = curl.match(/new-payment\/in\/$/i);
	  const new_payment_out = curl.match(/new-payment\/out$/i);
	  const new_payment_out1 = curl.match(/new-payment\/out\/$/i);

	  const inventory = curl.match(/products$/i);
	  const inventory_draft = curl.match(/products\/draft$/i);
	  const inventory_add = curl.match(/products\/add$/i);
	  const inventory_print_barcode = curl.match(/products\/print-barcode$/i);
	  const inventory1 = curl.match(/products\/$/i);
	  const edit_inventory = curl.match(/products\/edit\/(\d+$)/i);
	  const view_inventory = curl.match(/products\/view\/(\d+$)/i);

	  const inventories = curl.match(/inventories$/i);
	  const inventories1 = curl.match(/inventories\/$/i);
	  const edit_inventories = curl.match(/variant\/edit\/(\d+$)\/(\d+$)/i);
	  const view_inventories = curl.match(/variant\/view\/(\d+$)\/(\d+$)/i);

	  const company_details = curl.match(/settings\/company-details$/i);
	  const company_details1 = curl.match(/settings\/company-details\/$/i);

	  const bill_payments = curl.match(/settings\/billings-and-payments$/i);
	  const bill_payments1 = curl.match(/settings\/billings-and-payments\/$/i);

	  const settings_inventory = curl.match(/settings\/inventory$/i);
	  const settings_inventory1 = curl.match(/settings\/inventory\/$/i);

	  const settings_contacts = curl.match(/settings\/contacts$/i);
	  const settings_contacts1 = curl.match(/settings\/contacts\/$/i);

	  const settings_user_management = curl.match(/settings\/user-management$/i);
	  const settings_user_management1 = curl.match(/settings\/user-management\/$/i);

	  const settings_user_management_roles = curl.match(/settings\/user-management\/roles$/i);
	  const settings_user_management_roles1 = curl.match(/settings\/user-management\/roles\/$/i);

	  const settings_user_management_user_add = curl.match(/settings\/user-management\/users\/add$/i);
	  const settings_user_management_user_add1 = curl.match(/settings\/user-management\/users\/add\/$/i);

	  const settings_user_management_user_view = curl.match(/settings\/user-management\/users\/view\/(\d+$)/i);

	  const settings_barcode = curl.match(/settings\/barcode-management$/i);
	  const settings_barcode1 = curl.match(/settings\/barcode-management\/$/i);
	 
	  const settings_barcode_manager = curl.match(/settings\/barcode-management\/manager$/i);
	  const settings_barcode_manager1 = curl.match(/settings\/barcode-management\/manager\/$/i);
	 
	  const settings_barcode_manager_edit = curl.match(/settings\/barcode-management\/edit\/(\d+$)/i);
	 

	  const add_contact = curl.match(/add-contact$/i);
	  const add_contact1 = curl.match(/add-contact\/$/i);
	  const edit_contact = curl.match(/edit-contact\/(\d+$)/i);
	  const view_contact = curl.match(/view-contact\/(\d+$)/i);
	  const contacts = curl.match(/contacts$/i);
	  const contacts1 = curl.match(/contacts\/$/i);
      const contacts_type = curl.match(/contacts\/(\d+$)/i);
      const contacts_page = curl.match(/contacts\/page\/(\d+$)/i);
      const contacts_type_page = curl.match(/contacts\/(\d+)\/page\/(\d+$)/i);

	  const reports = curl.match(/reports$/i);
	  const reports1 = curl.match(/reports\/$/i);
	  const reports_transactions = curl.match(/reports\/transactions$/i);
	  const reports_transactions1 = curl.match(/reports\/transactions\/$/i);
	  const reports_daily_sales_report = curl.match(/reports\/daily-sales-report$/i);
	  const reports_daily_sales_report1 = curl.match(/reports\/daily-sales-report\/$/i);


	  const debit_credit_note = curl.match(/credit-debit-note$/i);
	  const debit_credit_note1 = curl.match(/credit-debit-note\/$/i);
      
      const debit_credit_note_debit = curl.match(/credit-debit-note\/debit$/i);
      const debit_credit_note_credit = curl.match(/credit-debit-note\/credit$/i);
      const debit_credit_note_debit1 = curl.match(/credit-debit-note\/debit\/$/i);
      const debit_credit_note_credit1 = curl.match(/credit-debit-note\/credit\/$/i);
      


	  let is_404 = false;
	  if(debit_credit_note_credit1||debit_credit_note_debit1||debit_credit_note1||inventories1||settings_barcode1||settings_barcode_manager1||reports1||reports_transactions1||reports_daily_sales_report1||settings_user_management_user_add1||settings_user_management_roles1||settings_user_management1||settings_contacts1 || settings_inventory1 || bill_payments1 || company_details1 || add_contact1 || contacts1 || inventory1 || billings1 || purchase1 || sales_return1 || purchase_return1 || payments1 || payment_in1 || new_payment1 || new_payment_in1 || new_payment_out1){
	  	is_404 = true;
	  }


	  if((debit_credit_note || debit_credit_note_debit || debit_credit_note_credit) && !check_access.is_access('debit_credit_note')){
	      is_404 = true;
	  }

	  if((settings_barcode || settings_barcode_manager || settings_barcode_manager_edit) && !check_access.is_access('settings-barcode')){
	      is_404 = true;
	  }

	  if((reports || reports_transactions || reports_daily_sales_report) && !check_access.is_access('reports')){
	      is_404 = true;
	  }

	  if((company_details1 || contacts || contacts_type || contacts_page || contacts_type_page) && !check_access.is_access('contacts')){
	      is_404 = true;
	  }

	  if((settings_user_management_user_view) && !check_access.is_access('settings-user-management-view')){
	      is_404 = true;
	  }
	  if((settings_user_management_roles || settings_user_management) && !check_access.is_access('settings-user-management')){
	      is_404 = true;
	  }
	  if((settings_user_management_user_add) && !check_access.is_access('settings-user-management-create')){
	      is_404 = true;
	  }
	  if(settings_contacts && !check_access.is_access('settings-contacts')){
	      is_404 = true;
	  }

	  if(settings_inventory && !check_access.is_access('settings-inventory')){
	      is_404 = true;
	  }

	  if(bill_payments && !check_access.is_access('settings-bill-payment')){
	      is_404 = true;
	  }

	  if(add_contact && !check_access.is_access('contacts-create')){
	      is_404 = true;
	  }
	  if(company_details && !check_access.is_access('settings-company-details')){
	      is_404 = true;
	  }
	  if(edit_contact && !check_access.is_access('contacts-modify')){
	      is_404 = true;
	  }
	  if(view_contact && !check_access.is_access('contacts-view')){
	      is_404 = true;
	  }
	
	  if((view_inventory||view_inventories) && !check_access.is_access('inventory-view')){
	      is_404 = true;
	  }
	  if((edit_inventory||edit_inventories) && !check_access.is_access('inventory-modify')){
	      is_404 = true;
	  }

	  if((inventory || inventories || inventory_draft || inventory_print_barcode || inventory_add) && !check_access.is_access('inventory')){
	      is_404 = true;
	  }
	  
	  if((payments) && (!check_access.is_access('payments-in') || !check_access.is_access('payments-out'))){
	      is_404 = true;
	  }
	  if((payment_in)&& !check_access.is_access('payments-in')){
	      is_404 = true;
	  }
	  if((payment_out) && !check_access.is_access('payments-out')){
	      is_404 = true;
	  }

	  if((new_payment) && !check_access.is_access('payments-in')){
	      is_404 = true;
	  }
	  if((new_payment_in) && !check_access.is_access('payments-in')){
	      is_404 = true;
	  }
	  if((new_payment_out) && !check_access.is_access('payments-out')){
	      is_404 = true;
	  }

	  if((billings) && !check_access.is_access('billing-sale')){
	      is_404 = true;
	  }
	  if((purchase) && !check_access.is_access('billing-purchase')){
	      is_404 = true;
	  }
	  if((sales_return) && !check_access.is_access('billing-sales-return')){
	      is_404 = true;
	  }
	  if((purchase_return) && !check_access.is_access('billing-purchase-return')){
	      is_404 = true;
	  }

	  if((view_billing && view_billing[1]==='sales') && !check_access.is_access('billing-sale-view')){
	      is_404 = true;
	  }if((view_billing && view_billing[1]==='purchase') && !check_access.is_access('billing-purchase-view')){
	      is_404 = true;
	  }if((view_billing && view_billing[1]==='sales_return') && !check_access.is_access('billing-sales-return-view')){
	      is_404 = true;
	  }if((view_billing && view_billing[1]==='purchase_return') && !check_access.is_access('billing-purchase-return-view')){
	      is_404 = true;
	  }
	  let referer = document.referrer;
	  const is_hold = referer.match(/hold/i);
	  const is_view_billing = referer.match(/view-billing/i);
	  if((edit_billing && edit_billing[2]==='sales') && !check_access.is_access('billing-sale-modify')){
	      is_404 = true;
	  }if((edit_billing && edit_billing[2]==='purchase') && !check_access.is_access('billing-purchase-modify')){
	      is_404 = true;
	  }if((edit_billing && edit_billing[2]==='sales_return') && !check_access.is_access('billing-sales-return-modify')){
	      is_404 = true;
	  }if((edit_billing && edit_billing[2]==='purchase_return') && !check_access.is_access('billing-purchase-return-modify')){
	      is_404 = true;
	  }
	  if(is_hold || (is_view_billing && edit_billing)){
	  	is_404 = false;
	  }

	  if((new_sale) && !check_access.is_access('billing-sale-create')){
	      is_404 = true;
	  }
	  if((add_billing_type && add_billing_type[1]==='1') && !check_access.is_access('billing-sale-create')){
	      is_404 = true;
	  }
	  if((add_billing_type && add_billing_type[1]==='2') && !check_access.is_access('billing-purchase-create')){
	      is_404 = true;
	  }
	  if((add_billing_type && add_billing_type[1]==='3') && !check_access.is_access('billing-sales-return-create')){
	      is_404 = true;
	  }
	  if((add_billing_type && add_billing_type[1]==='4') && !check_access.is_access('billing-purchase-return-create')){
	      is_404 = true;
	  }

	  if(is_404){
	    window.location.href = '/404';
	  }
}
export default {redirect}