import React, { Component } from "react";
import utils from '../utils.js';
import {Document, Page, View,Text,StyleSheet,Font  } from '@react-pdf/renderer';
import PDFPoweredBy from './pdf-powered-by';
let {enc_key,app_api,user_info} = require('../library.js');
export default class ReportPDF extends Component {
    constructor() {
      super();
      this.state = {
        
      };
      this.currency_format = utils.currency_format.bind(this);
    }
    render() {
    	let pdata = [];
    	let k=0;
    	let total_debit=0;
    	let total_credit=0;
    	let total_balance=0;
    	let total_count = [];
    	Object.keys(this.props.pdf_report).map((key, rd) => {
    		total_debit=0
    		total_credit=0
    		total_balance=0
            this.props.pdf_report[key].map( (value, index) => {
            	total_debit= total_debit+value.debit;
            	total_credit= total_credit+value.credit;
            	total_balance= total_balance+value.balance;
            })	
          	if(total_count[key]===undefined){
          		total_count[key] = [];
          		total_count[key]['debit'] = [];
          		total_count[key]['credit'] = [];
          		total_count[key]['balance'] = [];
          	}
            total_count[key]['debit'] = total_debit;
            total_count[key]['credit'] = total_credit;
            total_count[key]['balance'] = total_balance;
        })
       

        return (
           <Document>
		    <Page size="A4">
			    <View>
			    	<View style={{textAlign:'center',paddingTop:'10pt'}}>
			    		<Text>{user_info.company_name}</Text>
			    	</View>
			    	{(this.props.contact_info && this.props.contact_info.name!==undefined) &&
				    	<View style={{textAlign:'center',padding:'5pt'}}>
				    		<Text style={{fontSize:'14pt'}}>{this.props.contact_info.name}</Text>
				    		{(this.props.contact_info.address_1!=="")&&
				    			<Text style={{fontSize:'12pt'}}>{this.props.contact_info.address_1}</Text>
				    		}
				    		<Text style={{fontSize:'12pt'}}>
				    			{this.props.contact_info.city} 
				    			{(this.props.contact_info.state!=="")?', '+this.props.contact_info.state.replace(/[-0-9]/g, ''):''}
				    			{(this.props.contact_info.pin!=="")?', '+this.props.contact_info.pin:''}
				    			</Text>
				    		<Text style={{fontSize:'12pt'}}>
				    			{(this.props.contact_info.email!=="")?'Email : '+this.props.contact_info.email:''}
				    			{(this.props.contact_info.phone_1!=="")?' Mobile : '+this.props.contact_info.phone_1+" ":' '}
				    		</Text>
				    		
				    	</View>
			    	}
			        <View style={styles.container}>
			        	<View style={{marginBottom:'10pt',padding:'20pt',flexDirection:'row', border:'1pt solid #d7d7d7',borderRadius:'5pt'}}>
			        		<View style={{width:'170pt',textAlign:'center',padding:'10pt',borderRight:'1pt solid #d7d7d7'}}>
			        			<View>
			        				<Text style={{fontSize:'12pt'}}>Total Debit(-)</Text>
			        			</View>
			        			<View style={{marginTop:'10pt'}}>
			        				<Text style={{fontWeight:'bold',fontSize:'12pt'}}>{this.currency_format(this.props.total_pdf_debit)}</Text>
			        			</View>
			        		</View>
			        		<View style={{width:'170pt',textAlign:'center',padding:'10pt',borderRight:'1pt solid #d7d7d7'}}>
			        			<View>
			        				<Text style={{fontSize:'12pt'}}>Total Credit(+)</Text>
			        			</View>
			        			<View style={{marginTop:'10pt'}}>
			        				<Text style={{fontWeight:'bold',fontSize:'12pt'}}>{this.currency_format(this.props.total_pdf_credit)}</Text>
			        			</View>
			        		</View>
			        		<View style={{width:'170pt',textAlign:'center',padding:'10pt'}}>
			        			<View>
			        				<Text style={{fontSize:'12pt'}}>Net Balance</Text>
			        			</View>
			        			<View style={{marginTop:'10pt'}}>
			        				<Text style={{color:(this.props.msg4==='Dr')?'red':'green',fontWeight:'bold',fontSize:'12pt'}}>
			        					{this.currency_format(this.props.total_pdf_balance)} {this.props.msg4}
			        				</Text>
			        				<Text style={{marginTop:'5pt',fontSize:'10pt'}}>
			        					{this.props.msg3} 
			        				</Text>
			        			</View>
			        		</View>
			        	</View>
			        	<View style={styles.heading}>
			        		<Text style={styles.headTxt1}>Date</Text>
			        		<Text style={styles.headTxt}>Debit(-)</Text>
			        		<Text style={styles.headTxt}>Credit(+)</Text>
			        		<Text style={styles.headTxt}>Balance</Text>
			        	</View>
				       	{Object.keys(this.props.pdf_report).map((key, rd) => (
				            <View>
				            	<View style={styles.month}><Text>{key}</Text></View>
					          	<View style={styles.block}> 	
					            {this.props.pdf_report[key].map( (value, index) => (
					            	
					                <View style={styles.view}>
					                	<Text style={styles.date}>{value.date}</Text>
					                	<Text style={styles.debit}>{(value.debit!==0)?this.currency_format(value.debit):'-'}</Text>
					                	<Text style={styles.credit}>{(value.credit!==0)?this.currency_format(value.credit):'-'}</Text>
					                	<Text style={{color:(value.symbol==='Dr')?'red':'green',borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'}}>{this.currency_format(value.balance)} {value.symbol}</Text>
					                </View>
					            ))
					        	}
					        	</View>
					        	<View style={styles.block}>
						        	<View style={styles.view}>
						        		<Text style={{width:'140pt',padding:'10pt'}}>{key.replace(/[-0-9]/g, '')} Total</Text>
						        		<Text style={{borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'}}>{this.currency_format(total_count[key]['debit'])}</Text>
						        		<Text style={{borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'}}>{this.currency_format(total_count[key]['credit'])}</Text>
						        		<Text style={{borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'}}></Text>
						        	</View>
					        	</View>
				            </View>
				        ))
				       }
				       <View style={{marginTop:'10pt',fontSize:'14pt',flexDirection:'row',border:'1pt solid #d7d7d7',backgroundColor:'#e9ecef'}}>
			        		<Text style={styles.headTxt1}>Grand Total</Text>
			        		<Text style={styles.headTxt}>{this.currency_format(this.props.total_pdf_debit)}</Text>
			        		<Text style={styles.headTxt}>{this.currency_format(this.props.total_pdf_credit)}</Text>
			        		<Text style={{color:(this.props.msg4==='Dr')?'red':'green',borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'}}>{this.currency_format(this.props.total_pdf_balance)} {this.props.msg4}</Text>
			        	</View>
			        </View>
		        </View>
				<PDFPoweredBy/>
		    </Page>
		  </Document>
        );
    }
}
const styles = StyleSheet.create({
	container: {margin:'20pt'},
	view: {flexDirection:'row',borderBottom:'1pt solid #d7d7d7'},
	month: {fontSize:'14pt',flexDirection:'row',padding:'6pt'},
	block: {fontSize:'12pt',borderLeft:'1pt solid #d7d7d7',borderTop:'1pt solid #d7d7d7',borderRight:'1pt solid #d7d7d7'},
	heading: {fontSize:'14pt',flexDirection:'row',border:'1pt solid #d7d7d7',backgroundColor:'#e9ecef'},
	credit : {backgroundColor:'#e4ffe5',borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'},
	balance : {borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'},
	debit : {backgroundColor:'#ffefef',borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'},
	date : {padding:'10pt',width:'140pt'},

	headTxt : {borderLeft:'1pt solid #d7d7d7',width:'140pt',padding:'10pt'},
	headTxt1 : {padding:'10pt',width:'140pt'},
	
});