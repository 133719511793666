import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from '../utils.js';
import ShortcutHelp from "./shortcut-help.js";
import check_access from './access-level.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let TypeList = [];
let _this;
let db;
export default class AccountGroupsSubGroups extends Component {
    constructor(props) {
      super(props);
      this.state = {
          group_list : [],
          sub_group_list : [],
          delete_info : [],
          typeDefault : 0,
      };
      _this = this;
      
      this.is_access = check_access.is_access.bind(this);
    }
    handleChange = (e) =>{
        let contact_type = e.currentTarget.value;
        this.setState({typeDefault:contact_type});
    }
    componentDidMount(){
      
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              type : 'group'
           }
        let api = app_api+'/get_account_groups';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data.response;
            this.setState({ 'group_list': cursor });
        }.bind(this)).catch(function(res){
           
        })
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            type : 'sub_group'
         }
       api = app_api+'/get_account_groups';
      axios.post(api, body)
        .then(function (response) {
          let cursor = response.data.response;
          this.setState({ 'sub_group_list': cursor });
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }


    render() {
        
        return (
        
            <select onChange={this.handleChange} className="form-control validate form-field" id="account-group-select" name={this.props.name} value={this.state.typeDefault || this.props.default} tabindex={this.props.tabindex}>
                <optgroup label="Account Groups">
                {(this.state.group_list.length > 0) ? this.state.group_list.map( (value, index) => {
                return (
                    <option value={'group_'+value.id} key={value.id}>{value.name}</option>
                )
                }) : ''}
                </optgroup>
                <optgroup label="Account Sub Groups">
                {(this.state.sub_group_list.length > 0) ? this.state.sub_group_list.map( (value, index) => {
                return (
                    <option value={'sub_group_'+value.id} key={value.id}>{value.name}</option>
                )
                }) : ''}
                </optgroup>
            </select>
        
        );
    }
}