import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import ProductUnits from "./product-units";
import utils from '../utils.js';
import Autocomplete from "./autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faPencilAlt  } from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
let _this;
let remove_items = [];
let auto_generate_barcode = '';

export default class ReturnBillingItemList extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
            item_options : [],
            purchase_item_options : [],
            bundle_text : '',
            is_tax_inc : 'false',
        };
        
        
        
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.ADJUST_BILL_SNO = utils.ADJUST_BILL_SNO.bind(this);
        this.listItemRemove = this.listItemRemove.bind(this);
        this.confirmItemDelete = this.confirmItemDelete.bind(this);
        this.closeConfirmItemDel = this.closeConfirmItemDel.bind(this);
        this.listItemEdit = this.listItemEdit.bind(this);
      
        _this = this;
    }
    confirmItemDelete(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("ret-rm-litem-"+data_id).classList.remove('hide');
    }
    closeConfirmItemDel(e){
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("ret-rm-litem-"+data_id).classList.add('hide');
    }
    listItemRemove(e){
      this.props.listItemRemove(e);
      setTimeout(function(){
        _this.ADJUST_BILL_SNO();
      },100);
    }

    listItemEdit(e){
      this.props.listItemEdit(e);
    }
    
    componentDidMount(){
      if(localStorage.getItem('is_auto_generate_barcode')=="true"){
        auto_generate_barcode = 'Auto Generate';
      }
       let more_row_len = document.getElementsByClassName("return-items-row").length;
       if(more_row_len==1){
          this.setState({key:1});
       }else{
          let next = more_row_len+1;
          this.setState({key:next});
       }
        
    }
    
    render() {
        let is_variant = '';
        if(this.props.items && this.props.items.is_variation){
          is_variant = 'is_variant';
        }
        return (
          <tr className={this.props.items.product_key+" return-items-row return-billing-item-row "+is_variant} key={this.props.index} id={"return-item-row-"+this.props.index} data-id={this.props.index}>
            <td className="item-serial">
              <span className="ret-b-item-sno" data-id={this.props.index}>{this.props.index}</span>
            </td>
            <td className="item-name">
              {(this.props.items && this.props.items.item_name_on_bill_view===true) && 
               <span>{(this.props.items && this.props.items.item_name)?this.props.items.item_name:''}</span>
              }
               <input
                type="hidden"
                className="search-box form-control"
                placeholder=""
                data_id={this.props.index}
                id={"ret-item-box-"+this.props.index}
                target="ret-item-box"
                autoFocus
                defaultValue={(this.props.items && this.props.items.item_name)?this.props.items.item_name:''}
              />
              {(this.props.items.barcode!=="" && this.props.items.barcode!==null && this.props.items.barcode!=='none') &&
                <>
              
                  <span className="bcode-sep"> | </span>
                
                <span className="bcode-name"> {this.props.items.barcode} </span>
                </>
              }
              <br />
              <input type="text" data-id={this.props.index} id={"ret-item-type-"+this.props.index} defaultValue={(this.props.items && this.props.items.iitem_type)?this.props.items.iitem_type:''} className="hide"/>
              <input type="hidden" data-id={this.props.index} id={"ret-item-list-id-"+this.props.index} defaultValue={(this.props.items && this.props.items.irdid)?this.props.items.irdid:''}/>
              <input type="hidden" className="ret-iitem-ids" data-id={this.props.index} id={"ret-item-name-"+this.props.index} defaultValue={(this.props.items && this.props.items.inventory_id)?this.props.items.inventory_id:(this.props.items && this.props.items.item_id)?this.props.items.item_id:''}/>
              <input type="hidden" id={"ret-old-item-name-"+this.props.index} defaultValue={(this.props.items && this.props.items.inventory_id)?this.props.items.inventory_id:(this.props.items && this.props.items.item_id)?this.props.items.item_id:''}/>
              <i className="ret-item-desc-txt" id={"ret-iitem-desc-txt-"+this.props.index}>{(this.props.items && this.props.items.desc)?this.props.items.desc:''}</i>
            </td>
            <td className="hide">
                <input type="hidden" className="form-control" placeholder="" data-id={this.props.index} id={"ret-item-desc-"+this.props.index} defaultValue={(this.props.items && this.props.items.desc)?this.props.items.desc:''} target="ret-item-desc"/>
           
                <span>{(this.props.items && this.props.items.hsn)?this.props.items.hsn:''}</span>
                <input type="hidden" className="form-control mob-item" placeholder="" id={"ret-item-hsn-"+this.props.index} data-id={this.props.index} defaultValue={(this.props.items && this.props.items.hsn)?this.props.items.hsn:''} target="ret-item-hsn"/>
            </td>
            <td className="text-center">
                <span>{(this.props.items && this.props.items.quantity)?this.props.items.quantity:''}</span>
                <input type="hidden" className="form-control ret-item-quantity w-55  mob-item" id={"ret-item-quantity-"+this.props.index} placeholder=""   data-id={this.props.index} data-target="ret-item-quantity" defaultValue={(this.props.items && this.props.items.quantity)?this.props.items.quantity:''} target="ret-item-quantity" />
            </td> 
            <td className="units-dd text-center">
                <input type="hidden" className="form-control ret-item-unit-name" placeholder=""  id={"ret-item-unit-name-"+this.props.index} data-id={this.props.index} data-target="ret-item-unit-name" defaultValue={(this.props.items && this.props.items.unit_name)?this.props.items.unit_name:''}/>

                <input type="hidden" className="form-control ret-item-unit" placeholder=""  id={"ret-item-unit-"+this.props.index} data-id={this.props.index} data-target="ret-item-unit" defaultValue={(this.props.items && this.props.items.unit_id)?this.props.items.unit_id:''}/>
              
                <span>{(this.props.items && this.props.items.unit_name)?this.props.items.unit_name:''}</span>
                
            </td>
         
           {this.props.billing_type==='New Sale' &&
              <>
                <td className="text-center">
                    <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                    <input type="hidden" className="form-control ret-item-sub-total  mob-item" placeholder=""  id={"ret-price-"+this.props.index} data-id={this.props.index} data-target="ret-price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="ret-price"/>

                    <input type="hidden" className="form-control ret-item-lowest-selling-price" placeholder=""  id={"ret-item-lowest-selling-price-"+this.props.index} data-id={this.props.index} data-target="ret-item-lowest-selling-price" defaultValue={(this.props.items && this.props.items.lowest_selling_price)?this.props.items.lowest_selling_price:''}/>
                </td>
                <td className="text-center">
                    <span>{(this.props.items && this.props.items.discount)?this.props.items.discount:''}</span>
                    <input type="hidden" className="form-control ret-item-discount w-55 mob-item" placeholder="%"  id={"ret-item-discount-"+this.props.index} data-id={this.props.index} data-target="ret-item-discount" defaultValue={(this.props.items && this.props.items.discount)?this.props.items.discount:0} target="ret-item-discount"/>
                 
                    <input type="hidden" className="ret-item-discount-hidden" data-id={this.props.index} id={"ret-discount-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_discount)?this.props.items.total_discount:''}/>
                </td>
              </>
            }
            {this.props.billing_type==='New Purchase' &&
               <td className="text-center">
                  <span>₹{(this.props.items && this.props.items.price)?this.props.items.price:''}</span>
                  <input type="hidden" className="ret-total-avl-out-hidden" id={"ret-total-avl-out-hidden-"+this.props.index} defaultValue={(this.props.items && this.props.items.total_avl)?this.props.items.total_avl:0}/>
                  <input type="hidden" className="form-control ret-item-sub-total  mob-item" placeholder=""  id={"ret-price-"+this.props.index} data-id={this.props.index} data-target="ret-price" defaultValue={(this.props.items && this.props.items.price)?this.props.items.price:''} target="ret-price"/>
              </td> 
            }
           
            
            <td className="text-center">
               
              
                
                
             
              <span>{(this.props.items && this.props.items.tax)?this.props.items.tax:''}</span>
              
              <input type="hidden" className="form-control ret-item-gst mob-item" placeholder="%" id={"ret-item-gst-"+this.props.index} data-id={this.props.index}  data-target="ret-item-gst" defaultValue={(this.props.items && this.props.items.tax)?this.props.items.tax:''}  target="gst"/>
              
              <input type="hidden" className="form-control ret-item-cgst" placeholder="%" id={"ret-item-cgst-"+this.props.index} data-id={this.props.index}  data-target="ret-item-cgst" defaultValue={(this.props.items && this.props.items.cgst)?this.props.items.cgst:''}  target="cgst" />
              <input type="hidden" id={"ret-item-cgst-hidden-"+this.props.index} data-id={this.props.index} className="ret-item-cgst-hidden" data-target="ret-item-cgst-hidden" defaultValue={(this.props.items && this.props.items.hidden_cgst)?this.props.items.hidden_cgst:0}/>

              <input type="hidden" id={"ret-item-cgst-total-"+this.props.index} data-id={this.props.index} className="ret-item-cgst-total" data-target="ret-item-cgst-total" defaultValue={(this.props.items && this.props.items.total_cgst)?this.props.items.total_cgst:0}/>
           
              <input type="hidden" className="form-control ret-item-sgst" placeholder="%" id={"ret-item-sgst-"+this.props.index} data-id={this.props.index}  data-target="ret-item-sgst" defaultValue={(this.props.items && this.props.items.sgst)?this.props.items.sgst:''}  target="sgst" />
              <input type="hidden" className="ret-item-sgst-hidden" id={"ret-item-sgst-hidden-"+this.props.index} data-id={this.props.index}  data-target="ret-item-sgst-hidden" defaultValue={(this.props.items && this.props.items.hidden_sgst)?this.props.items.hidden_sgst:0}/>

              <input type="hidden" className="ret-item-sgst-total" id={"ret-item-sgst-total-"+this.props.index} data-id={this.props.index}  data-target="ret-item-sgst-total" defaultValue={(this.props.items && this.props.items.total_sgst)?this.props.items.total_sgst:0}/>
          
              <input type="hidden" className="form-control ret-item-igst" placeholder="%" id={"ret-item-igst-"+this.props.index} data-id={this.props.index}  data-target="ret-item-igst" defaultValue={(this.props.items && this.props.items.igst)?this.props.items.igst:''}  target="igst" />
              <input type="hidden" className="ret-item-igst-hidden" id={"ret-item-igst-hidden-"+this.props.index} data-id={this.props.index}  data-target="ret-item-igst-hidden" defaultValue={(this.props.items && this.props.items.hidden_igst)?this.props.items.hidden_igst:0}/>

              <input type="hidden" className="ret-item-igst-total" id={"ret-item-igst-total-"+this.props.index} data-id={this.props.index}  data-target="ret-item-igst-total" defaultValue={(this.props.items && this.props.items.total_igst)?this.props.items.total_igst:0}/>

             
            </td>
          
            <td>
          
                <span className="ret-item-total" id={"ret-item-total-"+this.props.index} data-id={this.props.index}  data-target="ret-item-total">₹{(this.props.items && this.props.items.total)?this.currency_format(this.props.items.total):0}</span>
              
                <input type="hidden" className="form-control ret-item-total-txt mob-item" id={"ret-item-total-txt-"+this.props.index} data-id={this.props.index}  data-target="ret-item-total-txt" defaultValue={(this.props.items && this.props.items.total)?this.props.items.total:0} />
            </td>
            <td className="item-rm">
              
                  <div className="ret-item-mod-options" style={{display:'flex'}}>
                    <span className={(this.props.index==1 && this.props.count<=1)?'ret-item-remove ':'ret-item-remove'} data-id={this.props.index} onClick={this.confirmItemDelete}><FontAwesomeIcon icon={faTimes} />
                    </span>
                    <span className="ret-rm-litem hide" id={"ret-rm-litem-"+this.props.index}>
                      <span className="rm-yes" data-id={this.props.index} onClick={this.listItemRemove}>
                        Yes
                      </span>
                      <span className="rm-no" data-id={this.props.index} onClick={this.closeConfirmItemDel}>
                        No
                      </span>
                    </span>
                    <span data-id={this.props.index} onClick={this.listItemEdit}><FontAwesomeIcon icon={faPencilAlt} /></span>
                  </div>
              
            </td>
          </tr>
        );
    }
}