import React, { Component } from "react";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faExclamationTriangle, faSpinner,faCheckCircle  } from '@fortawesome/free-solid-svg-icons';
let {app_api,user_info} = require('../library.js');
export default class FinancialYear extends Component {
    constructor(props) {
      super(props);
      this.state = {
        is_financial_close : true,
        is_financial_processing : false,
        processing_timer : 'Processing',
        financial_year_list : [],
        is_financial_year_active : false,
        is_another_fin_year : false,
        is_financial_year_closed : false,
        current_schema_lbl : '',
        is_closing_success : false,
      };
     
    }
   
    handleCloseFinacialYear = e =>{
      let is_financial_close = new Date();
      localStorage.setItem('is_financial_close',is_financial_close);
      this.setState({is_financial_close:true});
    }
    
    handleChangeFinancialYear = e =>{
      let thisval = e.currentTarget.value;
     
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          financial_year : thisval,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let api = app_api+'/change_financial_year';
      axios.post(api, send_data)
          .then(function (response) {
            let log_info = JSON.parse(localStorage.getItem('user'));
            log_info.response._schema = thisval;
            log_info = JSON.stringify(log_info);
            localStorage.setItem('user', log_info); 
            window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    handleProcessFinancialYear = e =>{
        this.setState({is_financial_processing:true});
        this.setState({is_financial_close:true});
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
        }
        let api = app_api+'/process_switching_to_financial_year';
        axios.post(api, send_data)
            .then(function (response) {
                window.location.href = '/billings';
        }.bind(this)).catch(function(res){
            
        })
    }
    componentDidMount(){
        let is_financial_close = localStorage.getItem('is_financial_close');
        if(is_financial_close){
            let d = new Date(is_financial_close);
            let day =  d.getDate();
            let month =  d.getMonth();
            let year =  d.getFullYear();

            let closed_on =  day+'/'+month+'/'+year;
           
            let new_day = new Date();
            let day2 =  new_day.getDate();
            let month2 =  new_day.getMonth();
            let year2 =  new_day.getFullYear();

            let next_day =  day2+'/'+month2+'/'+year2;
            if(closed_on<next_day){
                this.setState({is_financial_close:false});
                localStorage.removeItem('is_financial_close');
            }
        }

        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            keys:"'financial_year','is_financial_processed','is_financial_year_active'"
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.is_financial_processed===true){
                this.setState({is_financial_processing:false});
                this.setState({is_financial_close:resp.is_financial_processed});
            }else if(resp.is_financial_processed===false){
              this.setState({is_financial_close:false});
            }
            if(resp.is_financial_year_active!==undefined){
              let ps = resp.is_financial_year_active;
              this.setState({is_financial_year_active:ps});
            }else if(resp.is_financial_year_active===undefined){
              this.setState({is_financial_year_active:true});
            }
        }.bind(this)).catch(function(res){
            
        })
        this.handleGetFinancialYearList();
    }

    handleGetFinancialYearList = () =>{
      let fldata = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let fl_api = app_api+'/get_financial_year_list';
      axios.post(fl_api, fldata)
        .then(function (response) {
          let resp = response.data;
          this.setState({financial_year_list:resp.list});
          if(resp.active_schema!==resp.current_schema){
            this.setState({is_another_fin_year:true});
          }
          localStorage.setItem('_current',resp.active_schema);
          this.setState({current_schema_lbl:resp.active_schema_lbl});
          this.setState({is_financial_year_closed:resp.is_financial_year_closed});
      }.bind(this)).catch(function(res){
          
      })
    }
    
    handleVisibileFinancialYearList = (e) =>{
      let id = e.currentTarget.getAttribute('data-id');
      if(id==='show'){
        this.handleGetFinancialYearList();
        this.setState({is_financial_year_active:true});
      }else{
        this.setState({is_financial_year_active:false});
      }
    }

    handleCloseFYPop = (e) =>{
      var element = document.getElementById('close-financial-year');
      element.classList.remove("fade");
      element.style.display="block"; 
    }

    handleCloseFYConfirmation = (e) =>{
      var element = document.getElementById('close-financial-year');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    
    handleProcessFYClosing = (e) =>{
      document.getElementById('process-fy-closing').classList.add("hide");
      document.getElementById('process-fy-closing-btn-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let api = app_api+'/close_financial_year';
      axios.post(api, send_data)
          .then(function (response) {
            document.getElementById('process-fy-closing').classList.add("hide");
            document.getElementById('process-fy-closing-btn-spinner').classList.remove("hide");
            this.setState({is_closing_success:true});
            setTimeout(function(){
              window.location.reload();
            },2200);
      }.bind(this)).catch(function(res){
        
      })
    }

    render() {
        return (
          <>
          <span className="handleShowFinancialYearList" data-id="show" onClick={this.handleVisibileFinancialYearList}></span>
          <span className="handleHideFinancialYearList" data-id="hide" onClick={this.handleVisibileFinancialYearList}></span>
          {(this.state.is_financial_year_active===true) &&
          <>
            {(this.state.is_financial_close===false && this.props.target==='strip') &&
                <div className={this.props.className} id={this.props.id}>
                    <div className="alert alert-success">
                        <a onClick={this.handleCloseFinacialYear} className="close" data-dismiss="alert" aria-label="close" data-id={this.props.index}>&times;</a>
                        <p style={{margin:'0px',textAlign:'center',fontSize:'13px'}}><strong>Financial Year Started !</strong> Hey, You have entered in a new financial year as per your financial year <a href="/settings/billings-and-payments" target="_blank">Settings</a>. If you want to switch to <strong>New Financial Year</strong> please <button className="btn btn-success" style={{padding: '0px 10px 2px',fontSize:'12px'}} onClick={this.handleProcessFinancialYear}>Click Here</button></p>
                    </div>
                </div>
            }
            {(this.state.is_financial_close===true && this.state.is_financial_processing===true && this.props.target==='strip') &&
                <div id="maintenance-page" className={"maintenance-page-"+this.props.index}>
                <div className="maintenance-page">
                  <div className="maintenance-page-404">
                    <h1>
                        <div class="spinner-grow text-success" role="status" style={{width:'3rem',height:'3rem'}}>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </h1>
                    <h2 style={{background:'#155724'}}>We'll Be Right Back</h2>
                  </div>
                  <p style={{fontSize:'20px'}}>Relax ! We are working on switching to new Financial Year. We will get something new for you.</p>
                </div>
              </div>
            }

            {(this.props.target==='dropdown-menu' && this.state.financial_year_list.length>1) &&
              <>
                <div className="list-group list-group-flush menu-logout main-menu-item " style={{paddingTop:'0px',paddingBottom:'0px'}}>
                  <a className={"list-group-item list-group-item-action main-menu-item "}>
                    <label htmlFor="fin-year" className="fin-year">Current Financial Year</label>
                    <div className="fin-cal">
                      <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                    </div>
                    <select id="fin-year" className="fin-year form-control" onChange={this.handleChangeFinancialYear}>
                      {this.state.financial_year_list.map((value,index) => (
                        <option value={value.value} selected={value.is_active}>{value.label}</option>
                      ))}
                    </select>
                  </a>
                </div>
              </>
            }
            {(this.props.target==='dropdown') &&
              <>
               
                    <label htmlFor="fin-year" className="fin-year">Current Financial Year</label>
                    <div className="fin-cal">
                      <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                    </div>
                    <select id="fin-year" className="fin-year form-control" onChange={this.handleChangeFinancialYear}>
                      {this.state.financial_year_list.map((value,index) => (
                        <option value={value.value} selected={value.is_active}>{value.label}</option>
                      ))}
                    </select>
                
              </>
            }
            {(this.state.is_another_fin_year===true && this.state.is_financial_year_active===true && this.props.target==='strip') &&
            <>
              <div className="another-fin-notice" id="another-fin-notice">
                  <div className="alert alert-danger">
                  {(this.state.is_financial_year_closed===false) &&
                    <>
                      <p style={{margin:'0px',textAlign:'center',fontSize:'13px'}}><strong>Financial Year Alert !</strong> You are currently in the financial year {this.state.current_schema_lbl} 
                      {(user_info.access_level==="1" || user_info.access_level===1) &&
                      <button style={{padding: '0px 5px',fontSize: '11px',marginTop: '-4px'}} type="button" className="btn btn-danger" onClick={this.handleCloseFYPop}>Close this financial year</button>
                      }
                    </p>
                    </>
                  }
                  {(this.state.is_financial_year_closed===true) &&
                    <>
                      <p style={{margin:'0px',textAlign:'center',fontSize:'13px'}}><strong>Financial Year Alert !</strong> You are currently in the financial year {this.state.current_schema_lbl} and this financial year has been closed.</p>
                    </>
                  }
                  </div>
              </div>
              {(this.state.is_financial_year_closed===false) &&
                <div id='close-financial-year' className="modal fade">
                  <div className="modal-dialog modal-confirm" style={{width:'auto'}}>
                    {(this.state.is_closing_success===false) &&
                    <>
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box icon-print">
                          <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                        </div>            
                        <h5 className="modal-title w-100">
                            Are you sure you want to close the financial year({this.state.current_schema_lbl})?  
                        </h5>
                        <p className="text-left mrtop-10">After closing this financial year you can't do the following operations for the year({this.state.current_schema_lbl}):</p>
                        <ul className="text-left">
                          <li>Create/Modify/Delete Sales, Purchase, Sales Return, Purchase Return.</li>
                          <li>Create/Modify/Delete Payments.</li>
                          <li>Create/Modify/Delete Credit/Debit Note.</li>
                          <li>Create/Modify/Delete Expenses.</li>
                        </ul>
                      </div>
                    
                        <div className="modal-footer justify-content-center">
                          <button type="button" id="close-popup" className="btn btn-secondary pull-left" onClick={this.handleCloseFYConfirmation}>Close</button>
                            <button type="button" id="process-fy-closing" className="btn btn-danger pull-right" onClick={this.handleProcessFYClosing}>Continue</button>

                            <button type="button" id="process-fy-closing-btn-spinner" className="hide btn btn-danger pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </div>
                      
                    </div>
                    </>
                    }
                    {(this.state.is_closing_success===true) &&
                        <div className="alert alert-success" role="alert">
                          <div className="icon-box icon-print">
                            <span className="material-icons"><FontAwesomeIcon icon={faCheckCircle}/></span>
                          </div> 
                          <p style={{textAlign:'center'}}>Financial year({this.state.current_schema_lbl}) has been closed successfully.</p>
                        </div>
                    }
                  </div>
                </div>  
              }
            </>
            }
           </>
          }
           </>
        );
    }
}