import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faSpinner} from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import check_access from './access-level.js';
import utils from '../utils.js';
import ContactsDropdown from "./contacts-dropdown";
let {enc_key,app_api,user_info} = require('../library.js');
let _this;

export default class EditCheque extends Component {
    constructor() {
      super();
      this.state = {
        chequeDate:  null,
      };
      _this = this;
      this.is_access = check_access.is_access.bind(this);
    }
    chequeDate = date => {
        this.setState({
          chequeDate: date
        });
    };    
    componentDidMount = () =>{
        let cheque_date = new Date(this.props.cheque_data.cheque_date);
        this.setState({chequeDate:cheque_date});
    }

    handSaveCheque = (e) =>{
        localStorage.setItem('drawer-click','drawer-link-'+this.props.cheque_data.cheque_sequence)
        let thisid = e.currentTarget.id;
        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let contact = document.getElementById("customer-dropdown").value;
        let cheque_no = document.getElementById("cheque-no").value;
        let amount = document.getElementById("cheque-amount").value;
        let cheque_date = this.state.chequeDate;
        let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                update_id : this.props.cheque_data.cheque_management_id,
                contact : contact,
                cheque_no : cheque_no,
                cheque_date : cheque_date,
                amount : amount,
             }
          let api = app_api+'/update_cheque';
          axios.post(api, body)
            .then(function (response) {
               window.location.reload();
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
    }
    handleCloseChequeModal = () =>{
        this.props.handleCloseChequeModal();
    }
   
    render() {
        return (
            <div className="modal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Update Cheque</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <label>PARTY NAME</label>
                                <ContactsDropdown show="dropdown" label="Type to select account" input_id="contact_box" contact_info={this.props.contact_info} index="cheque"/>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="cheque-no">Cheque No.</label>
                                <input type="text" className="form-control" id="cheque-no" defaultValue={this.props.cheque_data.cheque_no}/>
                            </div>
                            <div className="col-md-4">
                                <label>Cheque Date</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text no-border-right">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    <DatePicker className="form-control  no-border-left" selected={this.state.chequeDate} onChange={this.chequeDate} dateFormat="dd/MM/yyyy" id="cheque_date"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="cheque-no">Cheque Amount.</label>
                                <input type="text" className="form-control" id="cheque-amount" defaultValue={this.props.cheque_data.amount}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseChequeModal} id="close-cheque">Close</button>
                    <button type="button" data-id="create-cheque" className="btn btn-success pull-right" id="create-cheque" onClick={this.handSaveCheque}>Save Cheque</button>
                    <button type="button" id="create-cheque-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}