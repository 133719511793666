import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRupeeSign, faTimesCircle, faArrowAltCircleRight, faInfoCircle, faInfo, faExclamation, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import utils from "../utils";
import PaymentModes from "./payment-modes";
import check_access from './access-level.js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import CreditDebitNoteLi from "./credit-debit-note-li";
import ChequeInputInfo from "./cheque-input-info";
let {enc_key,app_api,user_info} = require('../library.js');
function redirectToSettings(){
  window.location.href = '/settings/billings-and-payments';
}
export default class BillPaymentPopup extends Component {
    constructor() {
      super();
      this.state = {
        payment_mode : '',
        payment_mode_fixed : '',
        is_direct_pay : false,
        enable_tcs : false,
        enable_contact_tcs : false,
        billing_data : [],
        current_focused : 0,
        bill_popup_data : [],
        pending_return_opt : ''
      };
      this.is_access = check_access.is_access.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
    }
    handleDefaultPaymentMode = () =>{
      this.props.handleDefaultPaymentMode();
    }
    handleResetDataOnFly = () =>{
      this.setState({bill_popup_data:this.props.data});
      this.setState({payment_mode:this.props.data.payment_mode})
      this.setState({is_direct_pay:this.props.is_direct_pay})
      let billing_data = this.props.data.billing_data;
      
      if(billing_data===''){
        billing_data = {payment_mode:this.props.data.default_payment_mode}
      }else{
        billing_data.payment_mode = this.props.data.default_payment_mode;
      }
      let pmode = '';
      let contact_pay_mode = localStorage.getItem('p_mode');
      if(contact_pay_mode===1 || contact_pay_mode==='1' || this.props.data.default_payment_mode==='1' || this.props.data.default_payment_mode===1){
        pmode = 'payment';
      }else if(contact_pay_mode===2 || contact_pay_mode==='2' || this.props.data.default_payment_mode==='2' || this.props.data.default_payment_mode===2){
        pmode = 'credit';
      }
      this.setState({payment_mode:pmode});
      this.setState({billing_data:billing_data});
    }
    
    componentDidMount(){
        if(localStorage.getItem('bpmode')===null){
            localStorage.setItem('bpmode',this.props.data.payment_mode);
        }
        this.setState({bill_popup_data:this.props.data});
        this.setState({payment_mode:this.props.data.payment_mode})
        this.setState({is_direct_pay:this.props.is_direct_pay})
        let billing_data = this.props.data.billing_data;
        if(this.props.data.billing_type==='New Sales Return'){
          this.setState({pending_return_opt:'credit'});
        }else if(this.props.data.billing_type==='New Purchase Return'){
          this.setState({pending_return_opt:'debit'});
        }
        if(this.props.data.pending_ret_adj_opt && this.props.data.pending_ret_adj_opt!==""){
          this.setState({pending_return_opt:this.props.data.pending_ret_adj_opt});
        }
        if(billing_data===''){
          billing_data = {payment_mode:this.props.data.default_payment_mode}
        }else{
          billing_data.payment_mode = this.props.data.default_payment_mode;
        }
        let pmode = '';
        let contact_pay_mode = localStorage.getItem('p_mode');
        if(this.props.data.update_id>0){
          contact_pay_mode = this.props.data.default_payment_mode;
        }
        if(contact_pay_mode===1 || contact_pay_mode==='1' || this.props.data.default_payment_mode==='1' || this.props.data.default_payment_mode===1){
          pmode = 'payment';
        }else if(contact_pay_mode===2 || contact_pay_mode==='2' || this.props.data.default_payment_mode==='2' || this.props.data.default_payment_mode===2){
          pmode = 'credit';
        }
        this.setState({payment_mode:pmode});
        this.setState({billing_data:billing_data});
       
        localStorage.setItem("p_mode",this.props.data.default_payment_mode);
        if(document.getElementById("handleSetModeOfPayment")){
            document.getElementById("handleSetModeOfPayment").click();
        }
        document.addEventListener('keydown', this.handleKeyMovement);
        document.addEventListener("keydown", this.handleBillPaymentShortcut);


        let keys = "'enable_tcs'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.enable_tcs){
              let ps = resp.enable_tcs;
              this.setState({enable_tcs:ps});
            }
            
        }.bind(this)).catch(function(res){
            
        })
    }

    handleManageTCSTransaction = () =>{
        let _this = this;
        setTimeout(() => {
          let contact_info = _this.props.data.selected_contact_info;
          let is_tcs_enabled = false;
          if(contact_info && contact_info.is_tcs_enabled){
            is_tcs_enabled = contact_info.is_tcs_enabled;
          }
          _this.setState({enable_contact_tcs:is_tcs_enabled});
          
          if(is_tcs_enabled===true){
              if(document.getElementById("handleEnableTCS")){
                document.getElementById("handleEnableTCS").click();
                document.querySelector('input[name="global_discount"]:checked').click();
              }
          }else if(is_tcs_enabled===false){
            if(document.getElementById("handleDisableTCS")){
              document.getElementById("handleDisableTCS").click();
            }
          }
        }, 100);
    }

    componentWillUnmount(){
        this.setState({payment_mode:''})
        this.setState({is_direct_pay:false})
        this.setState({billing_data:[]});
        this.setState({bill_popup_data:[]});
        document.removeEventListener('keydown', this.handleKeyMovement);
        document.removeEventListener("keydown", this.handleBillPaymentShortcut);
    }
    handleKeyMovement = (e) =>{
        if(document.getElementById('contact-cd-note-popup')){
          return false;
        }
        if(document.getElementById("bill-payment-popup").style){
            let is_print = document.getElementById("bill-payment-popup").style.display;
            if(is_print==='block'){
                let id = document.activeElement.id;
                let data_target = document.activeElement.getAttribute('data-target');
                if(data_target==='redeem'){
                  return false;
                }

                let qs = document.querySelectorAll('.total-bill-amount');
                let qs_len = qs.length;
                let KEY_CODE = e.which;
                if(KEY_CODE===13){
                    if(id==='mode_of_payment'){
                        if(qs_len>0){
                            qs[0].focus();
                            this.setState({current_focused:0})
                        }
                    }else if(qs_len>0){
                        let current_focused = this.state.current_focused;
                        let next_focused = current_focused+1;
                        if(qs[next_focused]!==undefined){
                            qs[next_focused].focus();
                            this.setState({current_focused:next_focused})
                        }else{

                            let tbamt = document.getElementById("total-bill-amount").innerHTML;	
                            let ramt = tbamt.replaceAll(",",'');	
                            
                            let is_full_paid = false;
                            if(ramt!==""){
                                ramt = parseFloat(ramt);
                                let tpamt =0;
                                for (let index = 0; index < qs.length; index++) {
                                    const e_val = qs[index].value;
                                    if(e_val!==""){
                                        tpamt = tpamt+parseFloat(e_val);
                                    }
                                }
                                if(tpamt>=ramt){
                                    if(document.getElementById("handleSavePayment")){
                                        //document.getElementById("handleSavePayment").click();
                                    }
                                }else{
                                    qs[0].focus();
                                    this.setState({current_focused:0})
                                }
                            }
                        }
                    }
                }else if(KEY_CODE===38){
                    let current_focused = this.state.current_focused;
                    let next_focused = current_focused-1;
                    if(qs[next_focused]!==undefined){
                        qs[next_focused].focus();
                        this.setState({current_focused:next_focused})
                    }
                }else if(KEY_CODE===40){
                    let current_focused = this.state.current_focused;
                    let next_focused = current_focused+1;
                    if(qs[next_focused]!==undefined){
                        qs[next_focused].focus();
                        this.setState({current_focused:next_focused})
                    }
                }else if(KEY_CODE===27){
                    this.setState({current_focused:0});
                    let is_cd_note_popup = document.getElementById('contact-cd-note-popup');
                    if(is_cd_note_popup===undefined || is_cd_note_popup===null){
                      this.handleClosePopup(true);
                    }
                }
            }
        }
    }
    handleClosePopup = (is_refresh) => {
        var element = document.getElementById('bill-payment-popup');
        element.classList.add("fade");
        element.style.display="none"; 
        if(is_refresh!==false){
          this.props.handleRefreshPaymentPopup(this.state.bill_popup_data);
        }
        localStorage.removeItem('bptotal');
    }
    handleCreateBillFlag = ()=>{
        this.props.handleCreateBillFlag();
    }
    handleSavePayment = (event)=> {
      let _this = this;
      if(document.getElementById("bill-payment-popup") && document.getElementById("bill-payment-popup").style && document.getElementById("bill-payment-popup").style.display==='block'){
        let contact_payment_mode = localStorage.getItem("payment_mode");
        if( document.getElementById("mode_of_payment")){
          let pmode_element = document.getElementById("mode_of_payment");
          let pmode_txt = pmode_element.options[pmode_element.selectedIndex].text;
          let sel_contact = document.getElementById("contact_box").value;
          if(this.props.data.billing_type==='New Purchase' && (sel_contact==='' || sel_contact==='General' || sel_contact==='General Sale')){
            toast("Sorry ! You can't create Purchase association with "+sel_contact+" contact");
            return false;
          }

          if(this.props.data.credit_bill_general_contact===false && (sel_contact==='General' || sel_contact==='General Sale' || sel_contact==='') && pmode_txt==='Credit'){
            
            let toas_cls = document.querySelector(".Toastify");
            toas_cls.setAttribute("id","toastify-block");
            toas_cls.parentElement.setAttribute('href','/settings/billings-and-payments');
            toast("Credit bill cannot be created for General Contact. If you want to allow Click here to go to settings and change CREDIT BILL TO GENERAL CONTACT Settings.");
            document.querySelector("#toastify-block").addEventListener("click", redirectToSettings);
            return false;
          }

          if(sel_contact!=="" && contact_payment_mode!=="" && contact_payment_mode!=='all'){
            
            var options = pmode_element.options;
            let idx = '';
            for(var i = 0; i < options.length; i++) {
                if(parseInt(options[i].value) === parseInt(contact_payment_mode)) {
                    idx = i;
                    break;
                }
            }
            try{
              if(idx!==''){
                let con_pmode = pmode_element.options[idx].text;
                if((con_pmode==='Payment' || con_pmode==='Cash') && pmode_txt==='Credit'){
                    _this.setState({show_toast:true});
                    toast("This contact is not allowed for Credit payment. Please check the payment mode of this contact");
                    return false;
                }
              }
            }catch(e){}
          }
        }
        let thisid = event.currentTarget.id;
        if(document.getElementById("mode_of_payment")){
          let mode_of_payment = document.getElementById("mode_of_payment").value;
          if(mode_of_payment==='1'){
              let tba = '';
              if(document.getElementById("total-bill-amount")){
                tba = document.getElementById("total-bill-amount").innerHTML.replaceAll(',','');
              }
              let tpa = '';
              if(document.getElementById("total-paid-amount")){
                tpa = document.getElementById("total-paid-amount").innerHTML.replaceAll(',','');
              }
              let tra = '';
              if(document.getElementById("total-return-amount")){
                tra = document.getElementById("total-return-amount").innerHTML.replaceAll(',','');
              }
            
              let g_total = document.getElementById("final-total-text").value;
              if(tpa==="" || (tpa==="0" && g_total!=="0")){	
                if(document.getElementById("total-cash-amount")){	
                  if(document.getElementById("total-cash-amount")){	
                    document.getElementById("total-cash-amount").focus();	
                  }else if(document.getElementById("total-online-payment-amount")){	
                    document.getElementById("total-online-payment-amount").focus();	
                  }	
                  if(document.getElementById("bill-save-error")){	
                    document.getElementById("bill-save-error").innerHTML = 'Please enter amount to pay.';	
                    return false;	
                  }	
                }	
              }

              if(tra!=="" && parseFloat(tra)<0){
                if(document.getElementById("bill-save-error")){
                  document.getElementById("bill-save-error").innerHTML = 'Please adjust balance amount.';
                  return false;
                }
              }else if(parseFloat(tba)<parseFloat(tpa) && parseFloat(tra)<=0){
                if(document.getElementById("bill-save-error")){
                  document.getElementById("bill-save-error").innerHTML = 'Please adjust balance amount.';
                  return false;
                }
              }
          }else if(mode_of_payment==="" && thisid!=='handleHoldBill'){
            document.getElementById("mode_of_payment").focus();
            return false;
          }
        }
        console.log(thisid);
        
        setTimeout(() => {
          
          _this.handleCreateBillFlag();
            if(thisid==='handleSavePayment'){
                if(document.getElementById('ts-save')){
                    console.log('ts-save');
                    document.getElementById('ts-save').click();
                }
            }else if(thisid==='handleSavePrint'){
                if(document.getElementById('save-print')){
                  console.log('save-print');
                    document.getElementById('save-print').click();
                }
            }else if(thisid==='handleHoldBill'){
                if(document.getElementById('hold')){
                    document.getElementById('hold').click();
                }
            }
            _this.handleClosePopup(false);
        }, 100);
      }
    }

    handleResetPrefilledAmount = (e) =>{
      let total_grand = document.getElementById("final-total-text").value;
      if(localStorage.getItem('bptotal')){
        total_grand = localStorage.getItem('bptotal');
      }
      total_grand = parseFloat(total_grand.toString().replace(/,/g , ""));
      if(this.props.data.update_id>0){
      }else{
        if(document.getElementById("total-cash-amount")){
          //document.getElementById("total-cash-amount").value = total_grand;
        }else if(document.getElementById("total-credit-amount")){
          document.getElementById("total-credit-amount").value = total_grand;
        }
      }
      let tpa = document.querySelectorAll(".total-bill-amount");
      let total_paid_amount = 0;
      let total_credit_amount = 0;
      for(let i=0;i<tpa.length;i++){
        let amt =tpa[i].value.toString().replace(/,/g , "");
        let did = tpa[i].getAttribute('data-id');
        if(amt!=='' && did!=='Credit'){
          total_paid_amount = total_paid_amount+parseFloat(amt);
        }else if(amt!=='' && did==='Credit'){
          total_credit_amount = total_credit_amount+parseFloat(amt);
        }
      }
      if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = total_paid_amount;
      }
    }

    handleCalculateReturnAmount = () =>{
      let total_grand = document.getElementById("final-total-text").value;
        total_grand = parseFloat(total_grand.toString().replace(/,/g , ""));
        let tpa = document.querySelectorAll(".total-bill-amount");
        let total_paid_amount = 0;
        let total_credit_amount = 0;
        for(let i=0;i<tpa.length;i++){
          let amt =tpa[i].value.toString().replace(/,/g , "");
          let did = tpa[i].getAttribute('data-id');
          if(amt!=='' && did!=='Credit'){
            total_paid_amount = total_paid_amount+parseFloat(amt);
          }else if(amt!=='' && did==='Credit'){
            total_credit_amount = total_credit_amount+parseFloat(amt);
          }
        }
        let total_return = (total_paid_amount - total_grand)+total_credit_amount;
  
        if(total_return<0){
          if(document.getElementById("total-return-amount")){
            document.getElementById("total-return-amount").style.color='red';
            document.getElementById("return-lbl").innerHTML = 'Balance';
          }
        }else if(total_return>0){
          if(document.getElementById("total-return-amount")){
            document.getElementById("total-return-amount").style.color='green';
            document.getElementById("return-lbl").innerHTML = 'Return';
          }
        }else{
          if(document.getElementById("total-return-amount")){
            document.getElementById("total-return-amount").style.color='#212529';
            document.getElementById("return-lbl").innerHTML = 'Return';
          }
        }
        if(document.getElementById("total-return-amount")){
          document.getElementById("total-return-amount").innerHTML = this.currency_format(total_return);
        }
        if(document.getElementById("bill-save-error")){
          document.getElementById("bill-save-error").innerHTML = '';
        }
        if(total_return>=0){	
            this.handleChangePayBtnVisibility(true);
        }else{	
            this.handleChangePayBtnVisibility(false);
        }
    }

    handleAmountChange = (e)=>{
        let id = e.currentTarget.id;
        let thisvalue = e.currentTarget.value;
        let data_id = e.currentTarget.getAttribute('data-id');
        let total_grand = document.getElementById("final-total-text").value;
        total_grand = parseFloat(total_grand.toString().replace(/,/g , ""));
        let tpa = document.querySelectorAll(".total-bill-amount");
        let total_paid_amount = 0;
        let total_credit_amount = 0;
        for(let i=0;i<tpa.length;i++){
          let amt =tpa[i].value.toString().replace(/,/g , "");
          let did = tpa[i].getAttribute('data-id');
          if(amt!=='' && did!=='Credit'){
            total_paid_amount = total_paid_amount+parseFloat(amt);
          }else if(amt!=='' && did==='Credit'){
            total_credit_amount = total_credit_amount+parseFloat(amt);
          }
        }
  
        let tot_cr_amt = total_grand-total_paid_amount;
  
        /*if(document.getElementById("total-credit-amount")){
          if(tot_cr_amt>0){
            document.getElementById("total-credit-amount").value = tot_cr_amt;
          }else{
            document.getElementById("total-credit-amount").value = 0;
          }
        }*/
        
        if(id=='customer-advance-amount'){
          let cad_pay = parseFloat(this.props.data.customer_advance_payment);
          if(thisvalue!==""){
            thisvalue = parseFloat(thisvalue);
          }else{
            thisvalue = 0;
          }
          if(cad_pay>0 && thisvalue!=="" && thisvalue>cad_pay){
              e.currentTarget.value = cad_pay;
              return false;
          }
        }
  
  
        if(data_id!=='Credit'){
          document.getElementById("total-paid-amount").innerHTML = this.currency_format(total_paid_amount);
        }
  
  
        let total_return = (total_paid_amount - total_grand)+total_credit_amount;
  
        if(total_return<0){
          document.getElementById("total-return-amount").style.color='red';
          document.getElementById("return-lbl").innerHTML = 'Balance';
        }else if(total_return>0){
          document.getElementById("total-return-amount").style.color='green';
          document.getElementById("return-lbl").innerHTML = 'Return';
        }else{
          document.getElementById("total-return-amount").style.color='#212529';
          document.getElementById("return-lbl").innerHTML = 'Return';
        }
        document.getElementById("total-return-amount").innerHTML = this.currency_format(total_return);
        if(document.getElementById("bill-save-error")){
          document.getElementById("bill-save-error").innerHTML = '';
        }
        if(total_return>=0){	
            this.handleChangePayBtnVisibility(true);
        }else{	
            this.handleChangePayBtnVisibility(false);
        }
        
      }
      handleChangePayBtnVisibility = (flag)=>{
        this.props.handleChangePayBtnVisibility(flag);
      }
      handleTriggerOnPaymentModeChange = ()=>{
        this.props.handleTriggerOnPaymentModeChange();
      }
      
      handleChangePaymentMode = (e) =>{
        let payment_mode = localStorage.getItem("spm");
        if(payment_mode==='select pay mode'){
          payment_mode = '';
        }
        this.setState({payment_mode:payment_mode});
        let bpm = localStorage.getItem("p_mode");
        
        if(bpm!=null && bpm!='null'){
            let billing_data = this.state.billing_data;
            if(billing_data.payment_mode!==undefined){
                billing_data.payment_mode = bpm;
            }else{
                billing_data = {payment_mode:bpm};
            }
            
            this.setState({billing_data:billing_data});
        }
        this.handleTriggerOnPaymentModeChange();
        if(payment_mode!==''){	
            this.handleChangePayBtnVisibility(true);
        }else{	
            this.handleChangePayBtnVisibility(false);
        }
        localStorage.removeItem("spm");
  
        let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                id : localStorage.getItem('p_mode'),
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
             }
          let api = app_api+'/get_payment_mode';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              
              cursor.map( (value, key) => {
                  let id = value.payment_mode_id;
                  let payment_mode = value.payment_mode;
                  let is_direct_pay = value.direct_pay;
                  this.setState({is_direct_pay:is_direct_pay});
              });
             
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })
      }
      handleGlobalDiscount = (e) =>{
        this.props.handleGlobalDiscount(e);
        this.handleResetPrefilledAmount(e);
        this.handleCalculateReturnAmount();
      }
      handleCheckGDiscountAmount = (e) =>{
        this.props.handleCheckGDiscountAmount(e);
      }
      handleGlobalDiscountChange = (e) =>{
        this.props.handleGlobalDiscountChange(e);
        let _this=this;
        setTimeout(() => {
          _this.handleResetPrefilledAmount(e);
          _this.handleCalculateReturnAmount();
        }, 550);
      }
      handleRoundOff = (e) =>{
        this.props.handleRoundOff(e);
        this.handleResetPrefilledAmount(e);
        this.handleCalculateReturnAmount();
      }
      handleRoundOffChange = (e) =>{
        this.props.handleRoundOffChange(e);
        this.handleResetPrefilledAmount(e);
        this.handleCalculateReturnAmount();
      }
      handleRedeemNote = (e) =>{
        if(e===undefined){
          this.props.handleRedeemNote(e);
            return false;
        }
        if(e.which!==undefined && e.which>0){
          let thisval = document.getElementById("ptype_note").value;
          if(thisval===""){
            this.props.handleRedeemNote(e);
            return false;
          }
        }
        if(e.which!==undefined && e.which===13){
          this.props.handleRedeemNote(e);
        }else if(e.which===undefined){
          this.props.handleRedeemNote(e);
        }
      }
      handleCheckAdvancePayment = (e) =>{
        this.props.handleCheckAdvancePayment(e);
      }
      handleTCSPercent = () =>{
        document.querySelector('input[name="global_discount"]:checked').click();
      }
      
      handleOtherCharges = () =>{
        document.querySelector('input[name="global_discount"]:checked').click();
      }
      
      handleAdjustReturnAmount = (e) =>{
        let thisval = e.currentTarget.value;
        this.setState({pending_return_opt : thisval});
      }
      handlePrintFromPopup=()=>{
        if(document.getElementById("handlePrintOnFlyClick")){
          document.getElementById("handlePrintOnFlyClick").click();
        }
      }
      handlePayModeBtnClick = (e) =>{
        let data_id = e.currentTarget.getAttribute('data-id');
        let bill_amount = document.getElementById("bill-pay-pop-title").innerHTML;
        bill_amount = bill_amount.replaceAll(',','');
        if(document.getElementById(data_id)){
          let thisvalue = document.getElementById(data_id).value;
          let qs = document.querySelectorAll('.total-bill-amount');
          //if(thisvalue==="" || thisvalue==="0"){
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.value = '';
            }
            document.getElementById(data_id).value = bill_amount;
          //}
          document.getElementById(data_id).focus();
          document.getElementById(data_id).select();
          document.querySelector('input[name="global_discount"]:checked').click();
        }
      }
      handleBillPaymentShortcut = (e) =>{
        let fkey_arr = [112,113,114,115,116,117,118,119,120,121,122,123];
        let is_block = document.getElementById("bill-payment-popup").style.display;
        if(is_block==='block'){
          let key_pressed = e.which;
          
          let keyIdx = fkey_arr.indexOf(key_pressed);
          if(keyIdx>=0){
            e.preventDefault();
            keyIdx = keyIdx+1;
            if(document.getElementById("handlePayModeBtnClick-"+keyIdx)){
              document.getElementById("handlePayModeBtnClick-"+keyIdx).click();
            }
          }
          if(e.altKey && key_pressed===68){
            e.preventDefault();
            //discount
            document.getElementById("global_discount").focus();
            document.getElementById("global_discount").select();
          }else if(e.altKey && key_pressed===82){
            e.preventDefault();
            //round off
            document.getElementById("round_off").focus();
            document.getElementById("round_off").select();
          }else if(e.altKey && key_pressed===67){
            e.preventDefault();
            //credit note
            document.getElementById("ptype_note").focus();
            document.getElementById("ptype_note").select();
          }else if(e.altKey && key_pressed===84){
            e.preventDefault();
            //transportation
            document.getElementById("other-charges").focus();
            document.getElementById("other-charges").select();
          }else if(e.altKey && key_pressed===80){
            e.preventDefault();
            //print
            document.getElementById("handlePrintFromPopup").click();
          }else if(e.altKey && key_pressed===78){
            e.preventDefault();
            //save print new
            document.getElementById("handleSavePrint").click();
          }else if(e.altKey && key_pressed===83){
            e.preventDefault();
            document.getElementById("handleSavePayment").click();
          }
        }
    }
    render() {
      
        let is_cd_note = false;
        let cd_type = 'Credit';
        if(this.props.data.billing_type==='New Purchase'){
          is_cd_note = true;
          cd_type = 'Debit';
        }
        if(this.props.data.billing_type==='New Sale'){
          is_cd_note = true;
        
        }
        let round_off_sym = '-';
        if(this.props.data.round_off_sym==='+'){
          round_off_sym = '+';
        }else if(this.props.data.round_off_sym==='-'){
          round_off_sym = '-';
        }
        let global_discount_sym = 'per';
        if(this.props.data.discount_sym==='rs'){
          global_discount_sym = 'rs';
        }else if(this.props.data.discount_sym==='per'){
          global_discount_sym = 'per';
        }
        let is_visbile_block = '';
        if(this.props.data.billing_type==='New Sales Return' || this.props.data.billing_type==='New Purchase Return'){
          is_visbile_block = 'hide';
        }

        let pmindx = 0;
        return (
            <div id='bill-payment-popup' className="modal fade" style={{overflowY:'scroll'}}>
                <div className="modal-dialog modal-confirm modal-lg" style={{width:'unset',paddingBottom:'0px'}}>
                    <div className="modal-content" style={{paddingTop:'5px'}}>
                    <span onClick={this.handleResetDataOnFly} id="handleResetDataOnFly"></span>
                    <span onClick={this.handleResetPrefilledAmount} id="handleResetPrefilledAmount"></span>
                    <span onClick={this.handleDefaultPaymentMode} id="handleDefaultPaymentMode"></span>
                    <span onClick={this.handleManageTCSTransaction} id="handleManageTCSTransaction"></span>
                        <div className="modal-body border-bottom" style={{padding:'15px 15px 0px 15px'}}>
                            <div className="row">
                            {(this.props.data.isMobile===true) &&
                                <div className="col-md-6 text-center">
                                    <FontAwesomeIcon icon={faRupeeSign} style={{fontSize:'45px'}}/>
                                    <span id="bill-pay-pop-title" style={{color: '#333',fontWeight: 'bold',fontSize:'50px'}}></span>
                                </div>
                              }
                              {(is_visbile_block==='') &&
                                <div className={(this.props.data.isMobile===true)?"mrbtm-10 col-md-6 text-left mrtop-20":"col-md-6 text-left mrtop-20"}>
                                  <span onClick={this.handleChangePaymentMode} id="handleChangePaymentMode"></span>
                                    <span onClick={this.enableDirectPay} id="enableDirectPay"></span>
                                    <span onClick={this.disableDirectPay} id="disableDirectPay"></span>
                                    <div className="row">
                                      <div className="col-md-4">
                                      <label htmlFor="mode_of_payment">BILL TYPE :</label>
                                      </div>
                                      <div className="col-md-8">
                                        <PaymentModes show="dropdown" id="mode_of_payment" default={(this.state.billing_data && this.state.billing_data.payment_mode!==undefined)?this.state.billing_data.payment_mode:''} target={this.props.data.billing_type} data_target="billing"/>
                                      </div>
                                    </div>
                                    
                                    <span id="handleCheckAdvancePayment" onClick={this.handleCheckAdvancePayment}></span>
                                </div>
                                }
                              {(this.props.data.isMobile===false) &&
                                <>
                                  <div className={(is_visbile_block==='')?"col-md-6 text-right":"col-md-12 text-right"}>
                                      {(this.props.data.billing_type==='New Sales Return') &&
                                        <span style={{float: 'left',
                                          fontSize: '28px',
                                          color: '#333',
                                          fontWeight: 'bold',
                                          marginTop: '15px'}}>Sales Return Value</span>
                                      }
                                      {(this.props.data.billing_type==='New Purchase Return') &&
                                        <span style={{float: 'left',
                                        fontSize: '28px',
                                        color: '#333',
                                        fontWeight: 'bold',
                                        marginTop: '15px'}}>Purchase Return Value</span>
                                      }
                                      <FontAwesomeIcon icon={faRupeeSign} style={{fontSize:'45px'}}/>
                                      <span id="bill-pay-pop-title" style={{color: '#333',fontWeight: 'bold',fontSize:'50px'}}></span>
                                  </div>
                                </>
                              }
                              <div style={{position: 'absolute',right: '-10px', top: '0',fontSize: '20px'}}onClick={this.handleClosePopup}><FontAwesomeIcon icon={faTimesCircle}/></div>
                            </div>
                            <hr style={{marginTop:'0px'}}/>
                            {(this.props.data.isMobile===false) &&
                              <>
                                  {((this.props.data.billing_type==='New Sales Return' || this.props.data.billing_type==='New Purchase Return') && this.props.data.is_remaining_amount===true) &&
                                  <>
                                    <div className="row">
                                      <div className="col-md-6">
                                  
                                      <p style={{color: '#333',
      fontSize: '16px',
      margin: '0',textAlign:'left'}}><span style={{color:'red'}}>₹{this.props.data.remaining_amount}</span> is pending to adjust, please choose an option to adjust the amount.</p>
                                      </div>
                                      <div className="col-md-6">
                                        <select className="form-control" id="re-adjust-pending-return-opt" onChange={this.handleAdjustReturnAmount} value={this.state.pending_return_opt}>
                                            
                                            {(this.props.data.billing_type==='New Sales Return') &&	
                                              <>
                                                <option value="credit">Credit Note</option>	
                                              </>
                                            }
                                            {(this.props.data.billing_type==='New Purchase Return') &&	
                                              <>	
                                                <option value="debit">Debit Note</option>	
                                              </>
                                            }
                                            <option value="cash">Cash Return</option>
                                            <option value="on_account">On Account</option>
                                        </select>
                                        <p style={{margin:'0px',color:'#333',marginTop: '10px',textAlign:'left'}}>
                                          <span style={{border: '1px solid #333',padding: '5px 10px',borderRadius: '50%'}}><FontAwesomeIcon icon={faExclamation} style={{color:'gray'}}/></span>
                                          {(this.state.pending_return_opt=='credit' || this.state.pending_return_opt=='debit') &&
                                            <span className="adj-info">
                                             <b>₹{this.props.data.remaining_amount}</b> {this.state.pending_return_opt} note will be created and customer can redeem the amount on next purchase
                                            </span>
                                          }
                                          {(this.state.pending_return_opt=='cash') &&
                                            <span className="adj-info">
                                             <b>₹{this.props.data.remaining_amount}</b> {this.state.pending_return_opt} should be given back to customer as Cash & Payment {(this.props.data.billing_type==='New Sales Return')?'out':'in'} entry will be automatically created
                                            </span>
                                          }
                                          {(this.state.pending_return_opt=='on_account') &&
                                            <span className="adj-info">
                                             <b>₹{this.props.data.remaining_amount}</b> {this.state.pending_return_opt} will be added as advance in the account of {(this.props.data.billing_type==='New Sales Return')?'customer':'supplier'} and can be adjusted against old bill
                                            </span>
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <hr/>
                                  </>
                                }
                              </>
                            }
                            <div className="row">
                            {(this.props.data.isMobile===true) &&
                            <>
                            <div className="col-md-12">
                                <table className="table inline-table bill-type-table text-left">
                                  <tbody>
                                      <tr>
                                          <td><span className="sp-normal">Discount on Bill <span id="disc_on_bill">{(this.props.data.discount_sym==='per') &&
                                              <>
                                                <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.props.data.global_discount_amount)}
                                                </span>
                                              </>
                                            }</span></span></td>
                                          <td className="tbl-in-bx">
                                            {(this.is_access('billing-bgdiscount')===true) &&
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue={this.props.data.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount} onKeyDown={this.handleCheckGDiscountAmount}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange}/>
                                                <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                  ₹
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                                <label className="form-check-label" htmlFor="discount-per">
                                                  %
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                            }
                                            {(this.is_access('billing-bgdiscount')!==true) &&
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue={this.props.data.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount} onKeyDown={this.handleCheckGDiscountAmount} disabled/>
                                            <div className="form-group round-blk">
                                              <div  className={(global_discount_sym==='rs')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange} />
                                                <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                  ₹
                                                </label>
                                              </div>
                                              <div  className={(global_discount_sym==='per')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                                <label className="form-check-label" htmlFor="discount-per">
                                                  %
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                            }
                                          
                                          </td>
                                      </tr>
                                      
                                      <tr>
                                          <td><span className="sp-normal">Round Off</span></td>
                                          <td className="tbl-in-bx">
                                          {(this.is_access('billing-broundoff')===true) &&
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue= {this.props.data.round_off} id="round_off" onKeyUp={this.handleRoundOff}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange}/>
                                                <label className="form-check-label minus" htmlFor="minus">
                                                  -
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                                <label className="form-check-label plus" htmlFor="plus">
                                                  +
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          }
                                          {(this.is_access('billing-broundoff')!==true) &&
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue= {this.props.data.round_off} id="round_off" onKeyUp={this.handleRoundOff} disabled/>
                                            <div className="form-group round-blk">
                                              <div  className={(round_off_sym==='-')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange} />
                                                <label className="form-check-label minus" htmlFor="minus">
                                                  -
                                                </label>
                                              </div>
                                              <div  className={(round_off_sym==='+')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                                <label className="form-check-label plus" htmlFor="plus">
                                                  +
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          }
                                          
                                          </td>
                                      </tr>
                                      {(this.props.data.billing_type==='New Sale' || this.props.data.billing_type==='New Purchase') &&
                                   <>
                                      {(this.state.enable_contact_tcs===true && this.props.data.module==='billing') &&
                                        <tr>
                                            <td><span className="sp-normal">TCS</span></td>
                                            <td className="tbl-in-bx">
                                              <div className="input-group w-100px pull-right">
                                                  <input type="text" className="form-control no-border-right" defaultValue={(this.props.data.tcs_percent)?this.props.data.tcs_percent:0} id="tcs-percent" onChange={this.handleTCSPercent}/>
                                                  <div className="input-group-append">
                                                    <span className="input-group-text pad-left-none">%</span>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                      }
                                      </>
                                      }
                                      {(is_cd_note) &&
                                        <>
                                      <tr>
                                        <td>
                                          <span className="sp-normal">{cd_type} Note (if any)</span>
                                          <CreditDebitNoteLi data={{contact_id:this.props.data.contact_id,contact_name:this.props.data.contact_name,billing_type:this.props.data.billing_type}} handleRedeemNote={this.handleRedeemNote.bind(this)}/>
                                          <br/>
                                          <span className="error" style={{fontSize: '12px'}}>{this.props.data.redeem_error}</span>
                                        </td>
                                        <td className="text-right tbl-in-bx a">
                                        
                                          <input type="text" className="form-control pull-right w-100px" id="ptype_note" data-target="redeem" onKeyUp={this.handleRedeemNote} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payment_note_number  )?this.props.data.billing_data.payment_note_number:''}/>
                                          <span style={{fontSize: '18px',
    float: 'right',
    marginTop: '6px',
    marginLeft: '10px',
    marginRight: '10px',cursor:'pointer'}} onClick={this.handleRedeemNote} id="handleRedeemNoteIcon"><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                                          <input type="text" className="form-control hide" id="redeem_original_amount" defaultValue={(this.props.data.billing_data && this.props.data.billing_data.note_info && this.props.data.billing_data.note_info.amount)?this.props.data.billing_data.note_info.amount:''}/>

                                          <span id="handleCalculateReturnAmount" onClick={this.handleCalculateReturnAmount}></span>
                                        </td>
                                      </tr>
                                      </>
                                      }

                                      <tr>
                                            <td><span className="sp-normal">Transportation/Other Charges</span></td>
                                            <td className="text-right tbl-in-bx a">
                                              <div className="input-group mb-3 w-100px pull-right">
                                                <div className="input-group-prepend no-width">
                                                  <span className="input-group-text">₹</span>
                                                </div>
                                                <input type="text" className="form-control  no-border-left" defaultValue={(this.props.data.other_charges)?this.props.data.other_charges:0}  id="other-charges" onChange={this.handleOtherCharges}/>
                                              </div>
                                            </td>
                                        </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-12" style={{padding:'0px'}}>
                              <hr style={{margin:'8px'}}/>
                              </div>
                              </>
                            }
                                <div className={(this.props.data.isMobile===false)?"col-md-6 text-left border-right "+is_visbile_block:'col-md-6 text-left '+is_visbile_block}>
                                    {(this.props.data.billing_type==='New Sale' || this.props.data.billing_type==='New Purchase') &&
                                    <>
                                        <table width="100%" className="table inline-table bill-type-table">
                                        <tbody>
                                         
                                            {(this.state.payment_mode==='payment' || this.state.payment_mode==='cash' ) &&
                                            <>
                                            {((this.state.payment_mode==='payment' || this.state.payment_mode==='cash') && this.props.data.payment_modes.length > 0) ?this.props.data.payment_modes.map( (value, index) => {
                                              pmindx = pmindx+1;
                                                return (
                                                <>
                                                {(value.status===1) &&
                                                    <>
                                                    {(this.props.data.billing_type!=='New Sale' && value!=='Credit') &&
                                                    <>
                                                    <tr key={ index }>
                                                    <td>
                                                      <span style={{margin:'0px'}}>{value.payment_mode}:</span>
                                                      <span style={{fontSize:'10px'}}> (F{pmindx})</span>
                                                    </td>
                                                    <td style={{width:'40px',textAlign:'right'}}>
                                                     <button className="btn btn-success btn-sm"  style={{minHeight:'35px',minWidth:'35px',backgroundColor: '#218838',borderColor: '#1e7e34'}} onClick={this.handlePayModeBtnClick} data-id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} id={"handlePayModeBtnClick-"+pmindx}>
                                                      Add <FontAwesomeIcon icon={faAngleDoubleRight} />
                                                     </button>
                                                    </td>
                                                    <td className="bttitem">
                                                   
                                                    <input type="number" className="form-control total-bill-amount" placeholder="0" data-index={index} data-id={value.payment_mode} data-target="pay-box" id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} onChange={this.handleAmountChange} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types && this.props.data.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']!=="")?this.props.data.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:'-'}/>
                                                    </td>
                                                    </tr>
                                                    {(value.is_cheque===true || value.is_cheque===1 || value.is_cheque==='1') &&
                                                    
                                                      <ChequeInputInfo type="td" id={value.payment_mode}/>
                                                    
                                                    }
                                                    </>
                                                    }
                                                    {(this.props.data.billing_type==='New Sale') &&
                                                    <>
                                                    <tr key={ index }>
                                                    <td>
                                                      <span style={{margin:'0px'}}>{value.payment_mode}:</span>
                                                      <span style={{fontSize:'10px'}}> (F{pmindx})</span>
                                                    </td>
                                                    <td style={{width:'40px',textAlign:'right'}}>
                                                      <button className="btn btn-success btn-sm" style={{minHeight:'35px',minWidth:'35px',backgroundColor: '#218838',borderColor: '#1e7e34'}}  data-id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} onClick={this.handlePayModeBtnClick}  id={"handlePayModeBtnClick-"+pmindx}>Add <FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                                                    </td>
                                                    <td className="bttitem">
                                                    
                                                    <input type="number" className="form-control total-bill-amount" placeholder="0" data-index={index} data-id={value.payment_mode}  data-target="pay-box" id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} onChange={this.handleAmountChange} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:''}/>
                                                    </td>
                                                    </tr>
                                                     {(value.is_cheque===true || value.is_cheque===1 || value.is_cheque==='1') &&
                                                      <ChequeInputInfo  type="td" id={value.payment_mode}/>
                                                     }
                                                     </>
                                                    }
                                                </>
                                                }
                                                </>
                                                )
                                            }) : '' }
                                            
                                            {(this.state.is_direct_pay===true && this.state.payment_mode!=='payment' && this.state.payment_mode!=='select pay mode' && this.state.payment_mode!=='cash' && this.state.payment_mode!=='credit') &&
                                            <>
                                                <tr style={{lineHeight:'.5'}}>
                                                <td>Amount to Pay</td>
                                                <td className="bttitem text-right">
                                                    <input type="number" className="form-control total-bill-amount" placeholder="" id={"total-online-payment-amount"} data-target="pay-box" onChange={this.handleAmountChange} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['total_online_payment_amount']:''}/>
                                                </td>
                                                </tr>

                                                <tr style={{lineHeight:'.5'}}>
                                                <td>Credit</td>
                                                <td className="bttitem text-right">
                                                    <input type="number" className="form-control total-bill-amount" placeholder="" id={"total-online-credit-amount"} data-target="pay-box" onChange={this.handleAmountChange} data-id="Credit" defaultValue={(this.props.data.billing_data  && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['total_online_credit_amount']:''}/>
                                                </td>
                                                </tr>
                                                
                                                <tr style={{lineHeight:'.5'}}>
                                                <td>Reference No</td>
                                                <td className="bttitem text-right">
                                                    <input type="text" className="form-control total-bill-amount" placeholder="" id={"online-ref-no"} data-target="pay-box" defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['online_ref_no']:''}/>
                                                </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                <td>Payment Note</td>
                                                <td className="bttitem text-right">
                                                    <input type="text" className="form-control total-bill-amount" placeholder="" id={"online-ref-note"} data-target="pay-box" defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['online_ref_note']:''}/>
                                                </td>
                                                </tr>
                                                </>
                                            }
                                            {(this.props.data.customer_advance_payment>0) &&
                                                <tr>
                                                <td>Advance <span>(₹{this.currency_format(this.props.data.customer_advance_payment)})</span></td>
                                                <td></td>
                                                <td className="bttitem text-right">
                                                    <input type="number" className="form-control total-bill-amount" data-target="pay-box" placeholder="" id={"customer-advance-amount"} onChange={this.handleAmountChange} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types)?this.props.data.billing_data.payments_meta_data.bill_types['customer_advance_amount']:''}/>
                                                </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td colSpan={2}>
                                                    <p className="error" style={{margin:'0px'}} id="bill-save-error"></p>
                                                </td>
                                            </tr>
                                            </>
                                            }
                                            {(this.state.payment_mode==='credit') &&
                                            <>
                                              <tr key={ "cr-0"}>
                                                <td>Credit:</td>
                                                <td className="bttitem">
                                                <input type="number" className="form-control total-bill-amount" placeholder="0" data-index={0} data-id={'Credit'} data-target="pay-box" id={"total-credit-amount"} onChange={this.handleAmountChange} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payments_meta_data && this.props.data.billing_data.payments_meta_data.bill_types && this.props.data.billing_data.payments_meta_data.bill_types['total_credit_amount']!=="")?this.props.data.billing_data.payments_meta_data.bill_types['total_credit_amount']:'-'} readOnly={true}/>
                                                </td>
                                              </tr>
                                               <tr>
                                                  <td colSpan={2}>
                                                      <p className="error" style={{margin:'0px'}} id="bill-save-error"></p>
                                                  </td>
                                              </tr>
                                              </>
                                          }
                                        </tbody>
                                        </table>
                                    </>
                                    }
                                </div>
                                {(this.props.data.isMobile===true) &&
                                <div className="col-md-12" style={{marginTop:'-37px'}}>
                                  <hr/>
                                </div>
                                }
                                
                                <div className="col-md-6">
                                {(this.props.data.isMobile===false) &&
                                <table className="table inline-table bill-type-table text-left">
                                  <tbody>
                                  {(this.is_access('billing-bgdiscount')===true) &&
                                      <tr>
                                          <td><span className="sp-normal">Discount on Bill <span style={{fontSize:'9px'}}>(Alt+D)</span><span id="disc_on_bill">{(this.props.data.discount_sym==='per') &&
                                              <>
                                                <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.props.data.global_discount_amount)}
                                                </span>
                                              </>
                                            }</span></span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue={this.props.data.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount} onKeyDown={this.handleCheckGDiscountAmount}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange}/>
                                                <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                  ₹
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                                <label className="form-check-label" htmlFor="discount-per">
                                                  %
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                  {(this.is_access('billing-bgdiscount')!==true) &&
                                      <tr>
                                          <td><span className="sp-normal">Discount on Bill <span id="disc_on_bill">{(this.props.data.discount_sym==='per') &&
                                              <>
                                                <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.props.data.global_discount_amount)}
                                                </span>
                                              </>
                                            }</span></span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue={this.props.data.global_discount} id="global_discount" onKeyUp={this.handleGlobalDiscount} onKeyDown={this.handleCheckGDiscountAmount} disabled/>
                                            <div className="form-group round-blk">
                                              <div className={(global_discount_sym==='rs')?"form-check":'form-check hide'}>
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-rs" value="rs" defaultChecked={(global_discount_sym==='rs')?true:false} onClick={this.handleGlobalDiscountChange} />
                                                <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                  ₹
                                                </label>
                                              </div>
                                              <div className={(global_discount_sym==='per')?"form-check":'form-check hide'}>
                                                <input className="form-check-input" type="radio" name="global_discount" id="discount-per" value="per"  onClick={this.handleGlobalDiscountChange} defaultChecked={(global_discount_sym==='per')?true:false} />
                                                <label className="form-check-label" htmlFor="discount-per">
                                                  %
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                 
                                  {(this.is_access('billing-broundoff')===true) &&
                                      <tr>
                                          <td><span className="sp-normal">Round Off </span> <span style={{fontSize:'9px'}}>(Alt+R)</span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue= {this.props.data.round_off} id="round_off" onKeyUp={this.handleRoundOff}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange}/>
                                                <label className="form-check-label minus" htmlFor="minus">
                                                  -
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                                <label className="form-check-label plus" htmlFor="plus">
                                                  +
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                  {(this.is_access('billing-broundoff')!==true) &&
                                      <tr>
                                          <td><span className="sp-normal">Round Off</span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue= {this.props.data.round_off} id="round_off" onKeyUp={this.handleRoundOff} disabled/>
                                            <div className="form-group round-blk">
                                              <div className={(round_off_sym==='-')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(round_off_sym==='-')?true:false} onChange={this.handleRoundOffChange} />
                                                <label className="form-check-label minus" htmlFor="minus">
                                                  -
                                                </label>
                                              </div>
                                              <div  className={(round_off_sym==='+')?"form-check":"form-check hide"}>
                                                <input className="form-check-input" type="radio" name="round_off" id="plus" value="+"  onChange={this.handleRoundOffChange} defaultChecked={(round_off_sym==='+')?true:false} />
                                                <label className="form-check-label plus" htmlFor="plus">
                                                  +
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                   {(this.props.data.billing_type==='New Sale' || this.props.data.billing_type==='New Purchase') &&
                                   <>
                                    {(this.state.enable_contact_tcs===true && this.props.data.module==='billing') &&
                                      <tr>
                                          <td><span className="sp-normal">TCS</span></td>
                                          <td className="tbl-in-bx">
                                            <div className="input-group w-100px pull-right">
                                                <input type="text" className="form-control no-border-right" defaultValue={(this.props.data.tcs_percent)?this.props.data.tcs_percent:0}  id="tcs-percent" onChange={this.handleTCSPercent}/>
                                                <div className="input-group-append">
                                                  <span className="input-group-text pad-left-none">%</span>
                                                </div>
                                            </div>
                                          </td>
                                      </tr>
                                    }
                                    </>
                                    }
                                      {(is_cd_note) &&
                                        <>
                                      <tr>
                                        <td>
                                          <span className="sp-normal">{cd_type} Note (if any) </span><span style={{fontSize:'9px'}}>(Alt+C)</span>
                                          <CreditDebitNoteLi data={{contact_id:this.props.data.contact_id,contact_name:this.props.data.contact_name,billing_type:this.props.data.billing_type}} handleRedeemNote={this.handleRedeemNote.bind(this)}/>
                                          <br/>
                                          <span className="error" style={{fontSize: '12px'}}>{this.props.data.redeem_error}</span>
                                        </td>
                                        <td className="text-right tbl-in-bx b">
                                         
                                          
                                          <input type="text" className="form-control pull-right w-100px" id="ptype_note" onKeyUp={this.handleRedeemNote} defaultValue={(this.props.data.billing_data && this.props.data.billing_data.payment_note_number  )?this.props.data.billing_data.payment_note_number:''} data-target="redeem"/>
                                          <span style={{fontSize: '18px',
    float: 'right',
    marginTop: '6px',
    marginLeft: '10px',
    marginRight: '10px',cursor:'pointer'}} onClick={this.handleRedeemNote} id="handleRedeemNoteIcon"><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                                          <input type="text" className="form-control hide" id="redeem_original_amount" defaultValue={(this.props.data.billing_data && this.props.data.billing_data.note_info && this.props.data.billing_data.note_info.amount)?this.props.data.billing_data.note_info.amount:''}/>

                                          <span id="handleCalculateReturnAmount" onClick={this.handleCalculateReturnAmount}></span>
                                        </td>
                                      </tr>
                                      </>
                                      }
                                      <tr>
                                          <td><span className="sp-normal">Transportation/Other Charges</span><span style={{fontSize:'9px'}}> (Alt+T)</span></td>
                                          <td className="text-right tbl-in-bx b">
                                            <div className="input-group w-100px pull-right">
                                              <div className="input-group-prepend no-width">
                                                <span className="input-group-text">₹</span>
                                              </div>
                                              <input type="text" className="form-control no-border-left" defaultValue={(this.props.data.other_charges)?this.props.data.other_charges:0}  id="other-charges" onChange={this.handleOtherCharges}/>
                                            </div>
                                          </td>
                                      </tr>
                                  </tbody>
                                </table>
                                }
                                {(this.props.data.billing_type==='New Sale' || this.props.data.billing_type==='New Purchase') &&
                                    <>
                                     {(this.state.payment_mode==='payment' || this.state.payment_mode==='cash' ) &&
                                            <>
                                        <table width="100%" className="table inline-table bill-type-table">
                                            <tbody>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Bill Amount:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label id="total-bill-amount" className="btype-lbl">{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_amount)?this.state.billing_data.payments_meta_data.bill_amount:0}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left">
                                                      <label className="btype-lbl">Amount Paid:</label>
                                                      <span className="error" style={{fontSize:'12px'}} id="amount-paid-desc"></span>
                                                    </td>
                                                    <td className="bttitem text-right">
                                                    <label id="total-paid-amount" className="btype-lbl">{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.paid_amount)?this.state.billing_data.payments_meta_data.paid_amount:0}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl" id="return-lbl">Return:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label id="total-return-amount" className="btype-lbl">{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.return_amount)?this.state.billing_data.payments_meta_data.return_amount:0}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                    }

                                    {(this.state.payment_mode==='credit') &&
                                            <>
                                        <table width="100%" className="table inline-table bill-type-table">
                                            <tbody>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Bill Amount:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label id="total-bill-amount" className="btype-lbl">{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_amount)?this.state.billing_data.payments_meta_data.bill_amount:0}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Bill Type:</label></td>
                                                    <td className="bttitem text-right">
                                                      <label className="btype-lbl">
                                                      {(this.props.data.billing_type==='New Sale')?'Credit Sale':'Credit Purchase'}
                                                      </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                    }
                                    </>
                                    }
                                </div>
                                {((this.props.data.billing_type==='New Sales Return' || this.props.data.billing_type==='New Purchase Return') && this.props.data.is_remaining_amount===true) &&
                                  <div className="col-md-6" style={{borderLeft:'1px solid #d7d7d7'}}>
                                      <table width="100%" className="table inline-table bill-type-table">
                                            <tbody>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Total {(this.props.data.billing_type==='New Sales Return')?'Sales Return':'Purchase Return'}:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label className="btype-lbl">{this.props.data.bill_popup_total}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Amount Adjusted:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label className="btype-lbl">{this.props.data.bill_popup_total-this.props.data.remaining_amount}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Remaining Balance to Adjust:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label className="btype-lbl" style={{color:'red'}}>₹{this.props.data.remaining_amount}</label>
                                                    </td>
                                                </tr>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Amount Ajusted Through:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label className="btype-lbl" style={{textTransform:'capitalize'}}>{this.state.pending_return_opt.replace('_',' ')}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                  </div>
                                }
                            </div>
                          
                        </div>
                        <div className="modal-footer justify-content-center" style={{padding:'10px 0px 0px'}}>
                            <div className="row">
                                {(this.props.data.isMobile===false) &&
                                <>
                                <div className="col-md-3 text-center" style={{paddingLeft:'5px'}}>
                                     
                                     <button type="button" id="handleClosePopup" className="btn btn-secondary" onClick={this.handleClosePopup} style={{cursor:'pointer'}}>
                                      <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Close</p>
                                      <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Esc)</p>
                                    </button>
                                </div>
                                <div className="col-md-9">
                                  <div style={{display:'flex',float:'right'}}>
                                    <div>
                                        
                                        <button type="button" id="handlePrintFromPopup" className="btn btn-success" onClick={this.handlePrintFromPopup}  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>
                                          <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Print</p>
                                          <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+P)</p>
                                        </button>
                                    </div>
                                    <div>
                                      {(this.props.data.billing_type==='New Sale' && this.props.data.send_type==='save' && this.props.data.isMobile===false) &&
                                            <>
                                                
                                                <button type="button" onClick={this.handleSavePayment} id="handleSavePrint" className="btn btn-success" style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>
                                                  <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Save + Print + New</p>
                                                  <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+N)</p>
                                                </button>
                                            </>
                                        }
                                    </div>
                                    <div>
                                        
                                        <button type="button" id="handleSavePayment" className="btn btn-success" onClick={this.handleSavePayment}  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>
                                          <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>{this.props.data.save_button}</p>
                                          <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+S)</p>
                                        </button>
                                    </div>
                                  </div>
                                </div>
                                </>
                                }
                                 {(this.props.data.isMobile===true) &&
                                <>
                                <div className="col-md-12" style={{padding:'0px'}}>
                                    <button type="button" id="handleSavePayment" className="btn btn-success btn-block" onClick={this.handleSavePayment}  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>{this.props.data.save_button}</button>
                                </div>
                                {(this.props.data.show_hold_button) &&
                                <div className="col-md-12 mrtop-5" style={{padding:'0px'}}>
                                    <button type="button" id="handleHoldBill" className="btn btn-success btn-block" onClick={this.handleSavePayment}>Hold this Bill</button>
                                </div>
                                }
                                <div className="col-md-12 mrtop-5" style={{padding:'0px'}}>
                                     <button type="button" id="handleClosePopup" className="btn btn-secondary btn-block"  style={{cursor:'pointer'}} onClick={this.handleClosePopup}>Close</button>
                                </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}