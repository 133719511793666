import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Pager from "./pager";
import Breadcrumbs from "./breadcrumbs";
import ContactTypes from "./contact-types";
import Tooltip from "./tooltip";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faSort} from '@fortawesome/free-solid-svg-icons';
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import ListCount from "./list-count";
import AddAccount from "./add-account";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
let cancelToken;;
export default class Contacts extends Component {
    constructor() {
      super();
      this.state = {
          loading_process :'Loading...',
          total_page : '',
          active_tab : 'contacts',
          contact_list : [],
          search_contact_list : [],
          contact_on_type_list : [],
          is_searching : false,
          show_customer_ltv : false,
          is_accounting_module : false,
          is_add_account : false,
          selected_contact_type : '',
      };
      _this = this;
      this.timeout = null;
      this.is_access = check_access.is_access.bind(this);
      this.handleContactSearch = this.handleContactSearch.bind(this);
    
    }
    handleContactSearch(e){
      if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
          
          //return false;
      }
      cancelToken = axios.CancelToken.source();
      let cancelledToken = cancelToken.token;
      let http_header = {cancelToken:cancelledToken};
      let userInput  = e.currentTarget.value;
      let this_ = this;
      if(userInput!==""){
      //clearTimeout(this.timeout);
      this.setState({is_searching:true});
      this.setState({loading_process:'Loading...'});
      let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : userInput,
              target : 'contact_search',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/search_contact';
        //this.timeout = setTimeout(function () {
          axios.post(api, body,http_header)
            .then(function (response) {
                let data = response.data;
                if(response.data.data.length>0){
                  this_.setState({ search_contact_list: response.data.data });
                }else{
                  this_.setState({ search_contact_list: [] });
                  this_.setState({loading_process:'Record(s) not found'});
                }
          }.bind(this_)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        //}, 500);
      }else{
        this.setState({is_searching:false});
        //clearTimeout(this.timeout);
        this.setState({ search_contact_list: [] });
      }
    }
    getSettings = () =>{
      let keys = "'show_customer_ltv'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
          
            if(resp.show_customer_ltv){
              let show_customer_ltv = resp.show_customer_ltv;
              this.setState({show_customer_ltv:show_customer_ltv});
            }
        }.bind(this)).catch(function(res){
            
        })
    }
    componentDidMount(){
      localStorage.removeItem('p_mode');
      let curl = window.location.href;
      let cpage;
      let body = {};
      const contacts = curl.match(/contacts$/i);
      const contacts_type = curl.match(/contacts\/(\d+$)/i);
      const contacts_page = curl.match(/contacts\/page\/(\d+$)/i);
      const contacts_type_page = curl.match(/contacts\/(\d+)\/page\/(\d+$)/i);
      if(contacts){
        cpage = 1;
        body = {
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            
         }
      }else if(contacts_page){
        cpage = parseInt(contacts_page[1]);
        body = {
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      }else if(contacts_type){
          let type = contacts_type[1];
          _this.setState({active_tab:type});

          let ctypebody = {};
          ctypebody = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                id : type,
                _schema : user_info._schema,
            }
          let ctypeapi = app_api+'/get_contact_type';
          axios.post(ctypeapi, ctypebody)
            .then(function (response) {
              let cursor = response.data;
              if(cursor[0]!==undefined){
                let ctype = cursor[0].type;
                this.setState({selected_contact_type:ctype})
              }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })

          cpage =1;
          body = {
            type : type,
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
      }else if(contacts_type_page){
          let type = parseInt(contacts_type_page[1]);
          let ctypebody = {};
          ctypebody = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                id : type,
                _schema : user_info._schema,
            }
          let ctypeapi = app_api+'/get_contact_type';
          axios.post(ctypeapi, ctypebody)
            .then(function (response) {
              let cursor = response.data;
              if(cursor[0]!==undefined){
                let ctype = cursor[0].type;
                this.setState({selected_contact_type:ctype})
              }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })
          _this.setState({active_tab:type});
          cpage = parseInt(contacts_type_page[2]);
          body = {
            type : type,
            page : cpage,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
      }
      let api = app_api+'/contacts/page';
      axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let is_accounting_module = response.data.is_accounting_module;
            this.setState({is_accounting_module:is_accounting_module});
          if(response.data.is_empty){
            _this.setState({loading_process:'Record(s) not found'});
            _this.setState({total_page:0});
          }else{
            _this.setState({total_page:page_count});
            _this.setState({ 'contact_list': response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
             _this.setState({loading_process:'Record(s) not found'});
          }
      })
      this.getSettings();
    } 

    handleAddAccount = () =>{
      this.setState({is_add_account:true});
    }
    handleCloseAddAccount = () =>{
      this.setState({is_add_account:false});
    }
    handleSortLTV = ()=>{
      let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        name : 'sort_contact_on_ltv',
      }
      let npcapi = app_api+'/save_option';
      axios.post(npcapi, send_data)
        .then(function (response) {
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    render() {
        return (
            <div className="d-flex contacts-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="inv-opt-block bl1">
                      <Breadcrumbs link="/contacts" title="Contacts" sub_title="List"/>
                    </div>
                     <div className="inv-opt-block bl3 inventory-search">
                      
                      <div className="mrtop-10">
                        <input type="text" onChange={this.handleContactSearch} id="contact-search-box" className="form-control" placeholder="Type to search..."/>
                      </div>
                      
                    </div>
                    <div className="inv-opt-block bl4">
                      
                        <Notification />
                      
                    </div>
                    <div className="inv-opt-block bl5 inv-bl5">
                      <div className="inven-btn">
                        {this.is_access('contacts-create') && 
                          <>
                          {(this.state.selected_contact_type.toLowerCase()!=='other' && this.state.selected_contact_type.toLowerCase()!=='others') &&
                          <>
                            <a href="/add-contact"><button type="button" className="btn btn-success pull-right nav-btn">Add Contact</button></a>
                            <ShortcutHelp  arrow="top" target="new" shortcuts={['Ctrl+Alt+N']}/>
                          </>
                          }
                          {(this.state.selected_contact_type.toLowerCase()=='other' || this.state.selected_contact_type.toLowerCase()==='others') &&
                          <>
                            <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleAddAccount}>Add Account</button>
                            {(this.state.is_add_account) &&
                              <AddAccount contact_type_name={this.state.selected_contact_type} contact_type_id={this.state.active_tab}/>
                            }
                            <span id="handleCloseAddAccount" onClick={this.handleCloseAddAccount}></span>
                          </>
                          }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                {(!this.state.is_searching) &&
                  <>
                    <div className="nav-container">
                      <ContactTypes show="nav-items" name="type" current_url={this.state.active_tab} is_accounting_module={this.state.is_accounting_module}/>
                    </div>
                    <div className="content-area">
                       <div className="main-content">
                          <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">NAME</th>
                              {(this.state.selected_contact_type.toLowerCase()!=='other' && this.state.selected_contact_type.toLowerCase()!=='others') &&
                          <>
                              <th scope="col">TYPE</th>
                          </>
    }
                              <th scope="col">PHONE</th>
                              {(this.state.selected_contact_type.toLowerCase()!=='other' && this.state.selected_contact_type.toLowerCase()!=='others') &&
                                <>
                                    <th scope="col">CITY</th>
                                    <th scope="col">OPENING BALANCE</th>
                                    </>
                              }
                              {(parseInt(this.state.active_tab)===1 && this.state.show_customer_ltv===true) &&
                                <th scope="col" onClick={this.handleSortLTV} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faSort} className='sort-on-margin'/> LTV</th>
                              }
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                             {(_this.state.contact_list.length > 0) ?_this.state.contact_list.map( (value, index) => {
                            
                             return (
                              <>
                              {(value.contact_id!==undefined) &&
                                <tr key={ index } className="contact-row" id={"contact-row-"+value.contact_id}>
                                  <td><Link link={'/view-contact/'+value.contact_id} name={value.name} /></td>
                                  {(this.state.selected_contact_type.toLowerCase()!=='other' && this.state.selected_contact_type.toLowerCase()!=='others') &&
                          <>
                                  <td>{value.contact_type}</td>
                          </>
                             }
                                  <td>{value.phone_1}</td>
                                  {(this.state.selected_contact_type.toLowerCase()!=='other' && this.state.selected_contact_type.toLowerCase()!=='others') &&
                          <>
                                  <td>{value.city}</td>
                                  <td>
                                    {(value.opening_balance_info && value.opening_balance_info.opening_balance && value.opening_balance_info.opening_balance>0)?utils.currency_format(value.opening_balance_info.opening_balance):'-'}
                                  </td>
                                </>
                             }
                             {(parseInt(this.state.active_tab)===1 && this.state.show_customer_ltv===true) &&
                              <td>{utils.currency_format(value.ltv)}</td>
                             }
                                  <td>
                                      {(value.contact_id>1) &&
                                          <Tooltip target="contacts" id={value.contact_id} index={index} view={"/view-contact/"+value.contact_id} edit={"/edit-contact/"+value.contact_id}  delete={{active_tab:'contact',id:value.contact_id,data_value:'contact'}}  item_count={_this.state.contact_list.length}/>
                                      }
                                      
                                  </td>
                                </tr>
                              }
                              {(value.actid!==undefined) &&
                                <tr key={ index } className="contact-row" id={"contact-row-"+value.actid}>
                                  <td><Link link={'/view-accounts/'+value.actid} name={value.account_name} /></td>
                                  <td>Accounting</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              }
                              </>
                            )
                           }) : <tr><td colSpan="5" className="text-center">{_this.state.loading_process}</td></tr> }
                          </tbody>
                        </table>
                        {(this.state.contact_list && this.state.contact_list.length > 0) &&
                        <ListCount list={this.state.contact_list}/>
                      }
                          <Pager total_page={this.state.total_page}/>
                     
                       </div>
                    </div>
                    </>
                  }
                  {(this.state.is_searching) &&
                  <div className="content-area">
                     <div className="main-content">
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">TYPE</th>
                            <th scope="col">PHONE</th>
                            <th scope="col">CITY</th>
                            <th scope="col">OPENING BALANCE</th>
                            {(parseInt(this.state.active_tab)===1 && this.state.show_customer_ltv===true) &&
                                <th scope="col">LTV</th>
                              }
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.search_contact_list.length > 0) ?this.state.search_contact_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row" id={"contact-row-"+value.contact_id}>
                              <td><Link link={'/view-contact/'+value.contact_id} name={value.name} /></td>
                              <td>{value.contact_type}</td>
                              <td>{value.phone_1}</td>
                              <td>{value.city}</td>
                              <td>
                                    {(value.opening_balance_info && value.opening_balance_info.opening_balance && value.opening_balance_info.opening_balance>0)?utils.currency_format(value.opening_balance_info.opening_balance):'-'}
                                  </td>
                              {(parseInt(this.state.active_tab)===1 && this.state.show_customer_ltv===true) &&
                              <td>{value.ltv}</td>
                             }
                              <td>
                             
                              {(value.contact_id>1) &&
                                  <Tooltip target="contacts" id={value.contact_id} index={index} view={"/view-contact/"+value.contact_id} edit={"/edit-contact/"+value.contact_id}  delete={{active_tab:'contact',id:value.contact_id,data_value:'contact'}}  item_count={_this.state.contact_list.length}/>
                              }
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan="5" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {(this.state.search_contact_list && this.state.search_contact_list.length > 0) &&
                        <ListCount list={this.state.search_contact_list}/>
                      }
                     </div>
                  </div>
                  }
                </div>
              </div>
            </div>
        );
    }
}