import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import utils from '../utils.js';
import ModalBox from "./modal-box";
import onScan from '../../node_modules/onscan.js/onscan.min.js';
import CreateContactOnFly from './create-contact-on-fly.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faCheckCircle, faExclamationCircle, faTimes  } from '@fortawesome/free-solid-svg-icons';
//import encrypt_decrypt from './encrypt-decrypt.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
let scan_target = '';
let curl = window.location.href;
let add_billing_type = curl.match(/new-billing\/(\d+$)/i);
let stock_calculator = curl.match(/stock-calculator/i);
let edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
if(add_billing_type || edit_billing || stock_calculator){
  onScan.attachTo(document, {
      minLength : 3, 
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      reactToPaste: false, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      avgTimeByChar : 500,
      onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
          //console.log('Scanned: ' + iQty + 'x ' + sCode + ' target: '+scan_target);
          console.log(scan_target);
          if(scan_target==='iitem-box'){
              if(document.querySelector(".option-active[target=iitem-box]")){
                let barcode = document.querySelector(".option-active[target=iitem-box]").getAttribute("barcode");

                let alias_barcode = document.querySelector(".option-active[target=iitem-box]").getAttribute("alias_barcode");
                
                let sabarcode = alias_barcode.replaceAll("/","");
                
                let sbcode = barcode.replaceAll("/","");

                sCode = sCode.toLowerCase();
                sbcode = sbcode.toLowerCase();
                sabarcode = sabarcode.toLowerCase();
                alias_barcode = alias_barcode.toLowerCase();
                if(barcode===sCode || sbcode===sCode || alias_barcode===sCode || sabarcode===sCode){
                  if(document.getElementById("no-item-found-error")){
                    document.getElementById("no-item-found-error").innerHTML = '';
                  }
                  document.querySelector(".option-active[target=iitem-box]").click();
                  if(document.getElementById("add-item-to-list")){
                    document.getElementById("add-item-to-list").click();
                  }
                }
              }else{
                  localStorage.setItem('scan_item',sCode);
              }
          }else if(scan_target==='scan-inventory'){
            setTimeout(() => {
              let qs = document.querySelectorAll("#unscanned-list .active");
              
              if(qs[0]!==undefined){
                qs[0].click();
                if(document.getElementById("scan-quantity")){
                  document.getElementById("scan-quantity").value = 1;
                  document.getElementById("scan-btn").click();
                }
              }
            }, 150);
          }else if(scan_target==='ptype_note'){
            
            setTimeout(() => {
              if(document.getElementById("handleRedeemNoteIcon")){
                document.getElementById("handleRedeemNoteIcon").click();
              }
            }, 150);
          }
      },
      onKeyDetect: function(iKeyCode,event){ 
          //console.log('Pressed: ' + iKeyCode);
          scan_target = event.target.id;
      
      },
      onPaste: function(iKeyCode,event){ 
      
      },onScanError: function(oDebug) { 
        //console.log(oDebug);
      }, 
  });
}
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
window.remove_barcode_item = function(event){
  let thisid = event.currentTarget.id;
  let did = document.getElementById("barcode-list-"+thisid).getAttribute('data-target');
  localStorage.setItem('bcode_par_item',did);
  localStorage.setItem('bcode_item',thisid);
  document.getElementById("barcode-list-"+thisid).remove();
  let cls = document.querySelectorAll('.no-of-print');
  let total = 0;
  for(let i=0;i<cls.length;i++){
    total = total+parseInt(cls[i].value);
  }
  document.getElementById("barcode-print-total").innerHTML = total;
  document.getElementById('handleInventoryHideShowOnRemove').click();
}
window.cal_bc_print = function(event){
  let tv = event.currentTarget.value;
  let tid = event.currentTarget.id;
  document.getElementById(tid).defaultValue = tv;
  let cls = document.querySelectorAll('.no-of-print');
  let total = 0;
  for(let i=0;i<cls.length;i++){
    let cvalue = cls[i].value;
    if(cvalue===""){
      cvalue =0;
    }
    total = total+parseInt(cvalue);
  }
  document.getElementById("barcode-print-total").innerHTML = total;
}
let cancelToken;
export class Autocomplete extends Component {
  constructor() {
    super();
    this.contactInput = React.createRef();
    this.search_list_items = [];
    this.handleCloseListOnOutSide = this.handleCloseListOnOutSide.bind(this);
    _this =this;
  }
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired
  };
  state = {
    is_searching : false,
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: '',
    userInputIndex: '',
    activeIndex : 0,
    old_id: 0,
    options:[],
    is_key_press : false,
    active_id : '',
    active_number : '',
    active_list_title : '',
    is_search_contact : false,
    timeout : null,
    is_first_loaded_inventory : false,
    search_type : 'item_name',
    tag_list : []
  };

  componentWillReceiveProps = (nextProps) =>{
    
      if(nextProps.item_info!==undefined && nextProps.item_info!=='' && nextProps.item_info.item_id){
        if(this.state.is_key_press===false){
          this.setState({old_id:nextProps.item_info?nextProps.item_info.item_id:0});
          this.setState({userInputIndex:nextProps.item_info?nextProps.item_info.item_id:0});
          this.setState({userInput:nextProps.item_info?nextProps.item_info.item_name:''});
        }
      }
      let data_target = nextProps.data_target;
      if(data_target==='contact'){
        let contact_name = nextProps.contact_name;
        let contact_id = nextProps.contact_id;
        if(contact_name!==undefined){
          this.setState({
            filteredOptions: [],
            showOptions: false,
          });
         // document.getElementById('contact_box').select();
        }
      }
  }
  
  onFocus = (e) => {
    let curl = window.location.href;
    const is_order = curl.match(/order/i);
    let screen = '';
    if(is_order){
      screen = 'order';
    }
    if(this.props.data_target!=='payment'  && this.props.data_id!=='add-old-bill' && this.props.data_target!=='previous_invoice' && this.props.data_id!=='note'){
      let { options } = this.props;
      if(this.props.data_target==='contact' && options.length>0){
          let con_name = document.getElementById("contact_box").value;
          if(con_name!==""){
            let con_id = parseInt(document.getElementById("customer-dropdown").value);
            if(con_id!=="" && con_id!=='0' && con_id!==0){
              if(options[con_id]===undefined){
                options[con_id] = [];
                options[con_id] = {value1:"",value2:con_name};
              }
            }else if(this.props.data_id==="reports"){
              options[0] = [];
              options[0] = {value1:"",value2:'All'};
            }else if(this.props.data_id==="inventory-reports"){
              options[0] = [];
              options[0] = {value1:"",value2:'All'};
              options[1] = [];
              options[1] = {value1:"",value2:'No Customer'};
            }
          }else if(this.props.data_id==="reports"){
            options[0] = [];
            options[0] = {value1:"",value2:'All'};
          }else if(this.props.data_id==="inventory-reports"){
            options[0] = [];
            options[0] = {value1:"",value2:'All'};
            options[1] = [];
            options[1] = {value1:"",value2:'No Customer'};
          }
      }

      if(this.props.data_target!=="barcode" && this.props.active_tab!=="New Purchase" && this.props.active_tab!=="New Sale" && this.props.active_tab!=="New Sales Return" && this.props.active_tab!=="New Purchase Return"){
        const userInput = e.currentTarget.value;
        let this_id = e.currentTarget.id;
        let tval = document.getElementById(this_id).value;
        let userInputIndex = 0;
        let filteredOptions = [];
        let activeOption = 0;

        let index = e.currentTarget.getAttribute('data_id');
        let lcount = 0;
        let _this = this;
        options.map( (value, index) => { 
          if(lcount<10){
          lcount++;
          if(value!==null){
              let value1 = value.value1;
              let value2 = value.value2;
              if(value1!==undefined && value2!==undefined){
                if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                  filteredOptions[index] = value2;
                  let c = 0;
                  filteredOptions.map( (v, i) => {
                    if(userInput!==''){
                
                      let pattern = "/"+userInput+"/";

                      if(value2.search(pattern)){
                          c++;
                          activeOption = i;
                          userInputIndex = i;
                      }
                    }else{
                      if(c==0){
                          c++;
                          activeOption = i;
                          //userInputIndex = i;
                      }
                    }
                  });
                }
              }
            }
           
          }else{
            _this.setState({
              activeOption,
              filteredOptions,
              showOptions: true,
              userInput: e.currentTarget.value,
              userInputIndex: userInputIndex,
              activeIndex:parseInt(index)
            });
            return false
          }
        });
        if(e.currentTarget.value!==""){
            if(document.getElementById("item-name-"+index)){
                userInputIndex = document.getElementById("item-name-"+index).value;
            }
        }
        if(filteredOptions.length>0){
          filteredOptions = this.addNewPurchaseItem(filteredOptions,activeOption,'');
        }
        this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
          userInput: e.currentTarget.value,
          userInputIndex: userInputIndex,
          activeIndex:parseInt(index)
        });
      }
    }

    if(this.props.data_target==='previous_invoice'){
      //this.handleInvoiceSearch('',0);
    }

    if(this.props.data_target==='payment'){
      let item_id = '';
      if(document.getElementById("iitem-id")){
        item_id =document.getElementById("iitem-id").value;
      }
      let idx = e.currentTarget.getAttribute('data_id');
      let search = document.getElementById("iitem-box").value;
        if(search===''){
            this.setState({
              activeOption : 0,
              filteredOptions : [],
              showOptions: false,
              userInput: '',
              userInputIndex: 0,
            });
        }
        let filteredOptions = [];
        if(search.length>=1){
          let invoice_ids = [];
          let item_ids = document.querySelectorAll(".payment-item-row .item-ids");
          for(let i=0;i<item_ids.length;i++){
              let iid = item_ids[i].value;
              if(localStorage.getItem('item-row-edit')!==iid){
                invoice_ids.push(iid);
              }
          }
          let customer_id = document.getElementById("customer-dropdown").value;
            let body = {};
            var active_tab = document.querySelector('.payment-type-cb:checked').value;
              body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  search : search,
                  customer_id : customer_id,
                  active_tab : active_tab,
                  invoice_ids : invoice_ids,
                  item_id:item_id,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
                  screen : screen
               }
            let api = app_api+'/search_invoice_number';
            axios.post(api, body)
              .then(function (response) {
                  let resp = response.data;
                  let data = resp.data;
                  filteredOptions = [];
                  let options = data;
                  this.setState({options:options});
                  let userInputIndex = 0;
                  
                  let activeOption = 0;
                  if(options.length>0){
                  options.map( (value, index) => { 
                      let value1 = value.value1;
                      let value2 = value.value2;
                      if((value2.toLowerCase().indexOf(search.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(search.toLowerCase()) > -1)){
                          filteredOptions[index] = value2;
                          let c = 0;
                          filteredOptions.map( (v, i) => {
                            if(c==0){
                                c++;
                                activeOption = i;
                                userInputIndex = i;
                            }
                          });
                          
                           this.setState({
                            activeOption,
                            filteredOptions,
                            showOptions: true,
                            userInput: search,
                            activeIndex:parseInt(idx)
                          });
                      }
                  });
              }else{
                 this.setState({
                      activeOption:0,
                      filteredOptions:[],
                      showOptions: false,
                      userInput: search,
                      activeIndex:0
                    });
              }
                 
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                    }
                }
            })
        //}.bind(this),1000);
      }
    }
  }

  open_popup_on_focus = () =>{
    let userInput = document.getElementById("iitem-box").value;
  
    if(userInput!==""){
      this.setState({search_type:'item_name'});
      localStorage.setItem('search_type','item_name');
      
     /*  const  options  = JSON.parse(localStorage.getItem('purchase_item_options'));
      let userInputIndex = 0;
      let filteredOptions = [];
      let activeOption = 0;
      let lcount = 0;
      let _this = this;
      if(options.length>0){
        if(userInput===""){
           this.setState({
            filteredOptions: [],
            showOptions: false,
          });
        }
        let search_index = 0;
        options.map( (value, index) => {
          
          if(value){
            let value1 = value.value1;
            let value2 = value.value2;
            let value3 = "";
            if(value.value3!==undefined && value.value3!==null){
              value3 = value.value3;
             
            }
            let value4 = "";
            if(value.value4!==undefined && value.value4!==null){
              value4 = value.value4;
            }
            try{
              if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1)|| (value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                  if(search_index>=20){
                    return false;
                  }
                  filteredOptions[index] = value2;
                  if(search_index===0){
                    activeOption = index;
                    userInputIndex = index;
                  }
                  search_index++;
              }
            }catch(error){

            }
          }
        });
        this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
          userInputIndex: userInputIndex,
        });
      } */
    }
  }

  addContactToIDB = (contacts) =>{
    let resp_data = contacts;
    for(let i=0;i<resp_data.length;i++){
      let id = resp_data[i].contact_id;
      if(id!=''){
          try{
           let transaction = db.transaction("contacts", "readwrite");
           let contacts = transaction.objectStore("contacts");
           let request = contacts.get(id);
           let _this = this;
           request.onsuccess = function(e) {
              let value = e.target.result;
              if(value===undefined){
                  let key  = resp_data[i].contact_id;
                  let type = resp_data[i].type;
                  let contact_type = resp_data[i].contact_type;
                  let value= resp_data[i];                
                  value = CryptoJS.AES.encrypt(JSON.stringify(value), enc_key).toString();
                  let trans = db.transaction("contacts", "readwrite");
                  let ctype = trans.objectStore("contacts");
                  let contact_value = {};
                  contact_value['id'] = key;
                  contact_value['recent_flag'] = i;
                  contact_value['type'] = type;
                  contact_value['contact_type'] = contact_type;
                  contact_value['data'] = value;
                  ctype.add(contact_value);
              }
           }
         }catch(error){

         }
      }
    }
  }

  searchItemIfNotExist = (userInput)=>{
    clearTimeout(this.state.timeout);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source();
    let { options } = this.props;
    let userInputIndex = 0;
    let filteredOptions = [];
    let activeOption = 0;
    this.setState({
      filteredOptions : []
    });
    
    if(this.props.data_target==='contact'){
      localStorage.removeItem('search_contact');
      localStorage.removeItem('is_bank_account');
      if(userInput!=="" && userInput.length>=2){
        let curl = window.location.href;
        const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
        const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);
        const customer_pending_inv = curl.match(/reports\/pending-invoice/i);
        let contact_type = "all";
        if(add_billing_type){
          if(add_billing_type[1]==="1" || add_billing_type[1]==="3"){
            if(localStorage.getItem('relevant_contact_type_ssr')){
              contact_type = localStorage.getItem('relevant_contact_type_ssr');
            }
          }else if(add_billing_type[1]==="2" || add_billing_type[1]==="3"){
            if(localStorage.getItem('relevant_contact_type_ppr')){
              contact_type = localStorage.getItem('relevant_contact_type_ppr');
            }
          }
        }
        if(edit_billing){
          if(edit_billing[2]==="sales" || edit_billing[2]==="sales_return"){
            if(localStorage.getItem('relevant_contact_type_ssr')){
              contact_type = localStorage.getItem('relevant_contact_type_ssr');
            }
          }else if(edit_billing[2]==="purchase" || edit_billing[2]==="purchase_return"){
            if(localStorage.getItem('relevant_contact_type_ppr')){
              contact_type = localStorage.getItem('relevant_contact_type_ppr');
            }
          }
        }
        if(customer_pending_inv){
          if(document.getElementById("contact_type")){
            contact_type = document.getElementById("contact_type").value;
          }
        }
        clearTimeout(this.state.timeout);
        let is_ledger = false;
        if(document.getElementById("type-ledger")){
          is_ledger = true;
        }
        if(this.props.data_id==='journal'){
          is_ledger = true;
        }
        let _this = this;
        let body = {};
          body = {
            is_ledger:is_ledger,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : userInput,
              target : 'autocomplete',
              contact_type : contact_type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/search_contact';
        this.state.timeout = setTimeout(function () {
          axios.post(api, body)
            .then(function (response) {
                let data = response.data;
                let options = [];
                for(let i=0;i<data.length;i++){
                    let contact_id = data[i].contact_id;
                    let mobile = data[i].phone_1;
                    let name = data[i].name;
                    let discount = data[i].discount;
                    let state = data[i].state;
                    let payment_mode = data[i].payment_mode;
                    let contact_type = data[i].contact_type;
                    options[contact_id] = {contact_id:contact_id,recent_flag:i,value1:mobile,value2:name,discount:discount,state:state,payment_mode:payment_mode,contact_type:contact_type};
                    
                    if(contact_type!=='bank account' && contact_type!=='payment mode'){
                      _this.addContactToIDB(data);
                    }else if(contact_type==='bank account'){
                      localStorage.setItem('is_bank_account', 'true');
                    }else if(contact_type==='payment mode'){
                      localStorage.setItem('is_bank_account', 'payment_mode');
                    }
                }
                if(this.props.load_target==='New Sale'){
                  options.sort((a, b) => b.recent_flag - a.recent_flag);
                }
                if(options.length!==0){
                  localStorage.setItem('search_contact',JSON.stringify(options));
                }
                
                options.map( (value, index) => { 
                  if(value){
                  let value1 = value.value1;
                  let value2 = value.value2;
                  let value3 = "";
                  if(value.value3!==undefined && value.value3!==null){
                    value3 = value.value3;
                  }
                  let value4 = "";
                  if(value.value4!==undefined && value.value4!==null){
                    value4 = value.value4;
                  }
                  
                
                  if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                      filteredOptions[index] = value2;

                      let c = 0;
                      filteredOptions.map( (v, i) => {
                        if(c==0){
                            c++;
                            activeOption = i;
                            userInputIndex = i;
                        }
                      });
                  }
                  
                }
              });
              
              if(filteredOptions.length===0){
                filteredOptions[0] = ' + Add New';
              }

              _this.setState({
                activeOption,
                filteredOptions,
                showOptions: true,
                userInputIndex: userInputIndex,
              });
              _this.setState({is_searching:false});
          }.bind(_this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }, 300);
      }else{
        clearTimeout(this.state.timeout);
      }
    }else if(this.props.data_target==='billing' || this.props.data_target==='search_view_product' || this.props.data_target==='barcode' || this.props.data_target==='inventory-bulk-edit'  || this.props.data_target==='add-direct-product'){
      if(userInput!=="" && userInput.length>=2){
        let item_type_select = '';
        if(document.getElementById("iitem-type")){
          item_type_select = document.getElementById("iitem-type").value;
        }
        let body = {};
        let api = app_api+'/inventory/search_inventory_items';
        if(this.props.data_target==='billing' || this.props.data_target==='search_view_product' || this.props.data_target==='inventory-bulk-edit' || this.props.data_target==='add-direct-product'){
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                search : userInput,
                active_tab : this.props.active_tab,
                target : 'product',
                item_type_select : item_type_select
             }
        }else if(this.props.data_target==='barcode'){
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                search : userInput,
                target : 'barcode',
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
             }
           api = app_api+'/inventory/get_barcode_products';
        }
        let _this = this;
        this.state.timeout = setTimeout(function () {
            axios.post(api, body,{ cancelToken: cancelToken.token })
              .then(function (response) {
                let cursor = response.data;
                localStorage.setItem('inventory_data',JSON.stringify(cursor));
                
                if(this.props.data_target==='barcode'){
                  cursor = response.data.data;
                }
                
                if(cursor.length===0){
                  if(localStorage.getItem('scan_item')!==null){
                    localStorage.removeItem('scan_item');
                    document.getElementById("no-item-found-error").innerHTML = 'Oops! No item found';
                  }

                  let iitem_type = '';
                  if(document.getElementById("iitem-type")){
                     iitem_type = document.getElementById("iitem-type").value;
                  }
                  let is_list_item_edit = localStorage.getItem('is_list_item_edit');
                  
                  if(document.getElementById("handleResetField") && (iitem_type==='product' || iitem_type==='single') && is_list_item_edit===null){
                    let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
                    for(let i=0;i<inputs.length;i++){
                      if(inputs[i].id==='iitem-box'){
                      }else if(inputs[i].id==='iitem-barcode'){
                        let bcode_chk = document.getElementById("iitem-barcode-check").checked;
                        if(bcode_chk){
                          inputs[i].value = 'Auto Generate';
                        }
                      }else{
                        inputs[i].value = '';
                      }
                    }
                  }
                }
                
                let item_options = [];
                let purchase_item_options = [];
                if(cursor.length>0 && this.props.data_target==='barcode'){
                  let getSaleItem = localStorage.getItem('item_options');
                  if(getSaleItem){
                    getSaleItem = JSON.parse(getSaleItem);
                  }else{
                    getSaleItem = [];
                  }
                  let child_data = response.data.child_data;
                  cursor.map( (value, key) => {
                      let id = value.inventory_id;
                      let status = value.inventory_status;
                      let stat_txt = '';
                      if(status==='Draft'){
                        stat_txt = ' (Inactive)';
                      }
                      let item_name = value.item_name+" - Qty:"+value.availability+stat_txt;
                      if(value.is_parent===true){
                        item_name = value.item_name+stat_txt;
                      }
                      let sku   = value.sku; 
                      let category = value.category;
                    
                      if(sku===null){
                        sku = "";
                      }
                      let barcode_name = value.barcode_name;
                      if(barcode_name===null){
                          barcode_name = '';
                      }
                      let alias_barcode = value.alias_barcode;
                      if(alias_barcode===null){
                          alias_barcode = '';
                      }
                      let batch_number = value.batch_number;
                      if(batch_number===null){
                          batch_number = '';
                      }
                      let manufacture_date = value.manufacture_date;
                      if(manufacture_date===null){
                          manufacture_date = '';
                      }

                      let temp_opt = {value1:sku,value2:item_name,value3:barcode_name,value4:alias_barcode,alias_barcode:alias_barcode,item_name:value.item_name,sku:sku,category:category,availability:value.availability,varient:value.varient,inventory_status:value.inventory_status,item_desc:value.item_desc,purchase_price:value.purchase_price,selling_price:value.selling_price,custom_text:value.custom_text,barcode_name:barcode_name,parent_id:value.parent_id,parent_name:value.parent_name,size:value.size,city:value.city,state:value.state,phone1:value.phone1,phone2:value.phone2,email:value.email,encrypted_purchase_price:value.enc_pp,encrypted_selling_price:value.enc_sp,inventory_id:id,is_more_barcode:value.is_more_barcode,comission:value.comission,comission_type:value.comission_type,batch_number:batch_number,manufacture_date,manufacture_date,supplier:value.supplier};

                      if(value.custom_fields_json){
                        let custom_field_json = value.custom_fields_json;
                        for (let cfi = 0; cfi < custom_field_json.length; cfi++) {
                          const cf_element = custom_field_json[cfi];
                          if(cf_element.is_barcode_active!==undefined && cf_element.is_barcode_active===true){
                            let cfid = 'cf'+cf_element.id;
                            let cfvalue = cf_element.value;
                            temp_opt[cfid] = cfvalue;
                          }
                        }
                      }
                      item_options[id] = temp_opt;

                      options = item_options;
                      getSaleItem[id] = item_options[id];
                      localStorage.setItem('item_options',JSON.stringify(getSaleItem));
                  });
                  
                  localStorage.setItem('child_item_options',JSON.stringify(child_data));
                  _this.setState({options:options});
                }else if(cursor.length>0){
                    let getSaleItem = localStorage.getItem('item_options');
                    if(getSaleItem){
                      getSaleItem = JSON.parse(getSaleItem);
                    }else{
                      getSaleItem = [];
                    }
                    
                    let getPurchaseItem = localStorage.getItem('purchase_item_options');
                    if(getPurchaseItem){
                      getPurchaseItem = JSON.parse(getPurchaseItem);
                    }else{
                      getPurchaseItem = [];
                    }
                    
                    for(let i=0;i<cursor.length;i++){
                      let value = cursor[i];
                      let barcode_name= value.barcode_name;
                      if(barcode_name===null){
                        barcode_name = "";
                      }
                      let bi_quan = 0;
                      
                      if(barcode_name!==""){
                        let barcode_rep = barcode_name.replaceAll("/",'');
                        let bi_qs = document.querySelectorAll('.birow-barcode-'+barcode_rep);
                        for (let bi = 0; bi < bi_qs.length; bi++) {
                          const telem = bi_qs[bi];
                          let ti_did = telem.getAttribute('data-id');
                          let bq = document.getElementById("item-quantity-"+ti_did).value;
                          let is_add = false;
                          if(document.getElementById("item-list-id-"+ti_did)){
                            let iid = document.getElementById("item-list-id-"+ti_did).value;
                            if(iid=="" || iid===0){
                              is_add = true;
                            }
                          }
                          if(is_add===true){
                            bi_quan = bi_quan+parseFloat(bq);
                          }
                        } 
                        if(this.props.active_tab!=="New Purchase" ){
                          value.availability = parseFloat(value.availability)-bi_quan;
                        }
                      }

                      let id = value.inventory_id;
                      if(_this.props.is_transfer_stock){
                        if(value.latest_inventory_id){
                          id = value.latest_inventory_id;
                        }
                      }
                      let stat_lbl = '';
                      if(value.inventory_status==='Draft'){
                        stat_lbl=' (Inactive)';
                      }
                      let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                      let static_item_name = value.item_name;

                      let sku   = value.sku; 
                      let total = value.total;
                      let bundle_text= value.bundle_text;
                      
                      
                      if(sku===null){
                        sku = "";
                      }
                      
                      let alias_barcode= value.alias_barcode;
                      if(alias_barcode===null){
                        alias_barcode = "";
                      }
                      let custom_data = value.custom_fields_json;
                      
                      if(value.inventory_status==='Active'){
                        
                        let temp_opt = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,alias_barcode:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,custom_data:custom_data,is_more_barcode:value.is_more_barcode,custom_text:value.custom_text,barcode_name:barcode_name,reorder_quantity:value.reorder_quantity,opening_stock:value.opening_stock,part_name:value.part_name,inventory_id:id,child_barcode_info:value.child_barcode_info,comission:value.comission,comission_type:value.comission_type,supplier:value.supplier};

                        if(value.custom_fields_json){
                          let custom_field_json = value.custom_fields_json;
                          for (let cfi = 0; cfi < custom_field_json.length; cfi++) {
                            const cf_element = custom_field_json[cfi];
                            if(cf_element.is_barcode_active!==undefined && cf_element.is_barcode_active===true){
                              let cfid = 'cf'+cf_element.id;
                              let cfvalue = cf_element.value;
                              temp_opt[cfid] = cfvalue;
                            }
                          }
                        }
                        item_options[id] = temp_opt;
                         /* if(getSaleItem!==null && (getSaleItem[id]!==undefined && getSaleItem[id]!==null)){
                            getSaleItem[id]=item_options[id];
                          }else if(getSaleItem===null){
                            getSaleItem[id] = item_options[id];
                          }else if(getSaleItem!==null && (getSaleItem[id]===undefined ||getSaleItem[id]===null)){*/
                            getSaleItem[id] = item_options[id];
                          //}
                      }

                      

                      let temp_opt2 = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,alias_barcode:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,custom_data:custom_data,is_more_barcode:value.is_more_barcode,comission:value.comission,comission_type:value.comission_type,supplier:value.supplier};
                     
                      if(value.custom_fields_json){
                        let custom_field_json = value.custom_fields_json;
                        for (let cfi = 0; cfi < custom_field_json.length; cfi++) {
                          const cf_element = custom_field_json[cfi];
                          if(cf_element.is_barcode_active!==undefined && cf_element.is_barcode_active===true){
                            let cfid = 'cf'+cf_element.id;
                            let cfvalue = cf_element.value;
                            temp_opt2[cfid] = cfvalue;
                          }
                        }
                      }
                      purchase_item_options[id] = temp_opt2;
                      /*if(getPurchaseItem!==null && (getPurchaseItem[id]!==undefined && getPurchaseItem[id]!==null)){
                        getPurchaseItem[id]=item_options[id];
                      }else if(getPurchaseItem===null){
                        getPurchaseItem[id] = item_options[id];
                      }else if(getPurchaseItem!==null && (getPurchaseItem[id]===undefined || getPurchaseItem[id]===null)){*/
                        getPurchaseItem[id] = item_options[id];
                      //}
                      localStorage.setItem('item_options',JSON.stringify(getSaleItem));
                    
                      localStorage.setItem('purchase_item_options',JSON.stringify(getPurchaseItem));
                    }

                    if(this.props.active_tab==="New Sale" || this.props.active_tab==="New Sales Return"){
                      options = item_options;
                    }else{
                      options = purchase_item_options;
                    }
                  
                    this.setState({options:options});
                }
                 
                 options.map( (value, index) => { 
                  if(value){
                  let value1 = value.value1;
                  let value2 = value.value2;
                  let value3 = "";
                  if(value.value3!==undefined && value.value3!==null){
                    value3 = value.value3;
                  }
                  let value4 = "";
                  if(value.value4!==undefined && value.value4!==null){
                    value4 = value.value4;
                  }
                  
                 
                  if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                      let bcode_val = value.value3;
                      let new_i_val = '';
                      if(bcode_val!==undefined && bcode_val!=="" && bcode_val!==null){
                        new_i_val = ' | '+bcode_val+' - Qty:';
                      }
                        value2 = value2.replace('- Qty:',new_i_val);
                        filteredOptions[index] = value2;

                        let c = 0;
                        filteredOptions.map( (v, i) => {
                          if(c==0){
                              c++;
                              activeOption = i;
                              userInputIndex = i;
                          }
                        });
                  }
                  
                }
              });
              filteredOptions = this.addNewPurchaseItem(filteredOptions,activeOption,userInput);
              this.setState({
                activeOption,
                filteredOptions,
                showOptions: true,
                userInputIndex: userInputIndex,
              });
              this.setState({is_searching:false});
              setTimeout(function(){
                if(localStorage.getItem('scan_item')!==null){
                  localStorage.removeItem('scan_item');
                  if(document.querySelector(".option-active")){
                    if(document.getElementById("no-item-found-error")){
                      document.getElementById("no-item-found-error").innerHTML = '';
                    }
                    document.querySelector(".option-active").click();
                    if(document.getElementById("add-item-to-list")){
                      document.getElementById("add-item-to-list").click();
                    }
                  }
                }
              },100);
            }.bind(this)).catch(function(res){
                if(res.response){
                  if(res.response.data){
                      
                  }
                }
            })
        }.bind(this), 350);
      }else{
        clearTimeout(this.state.timeout);
      }
    }else if(this.props.data_target==='barcode-preview'){
      if(userInput!=="" && userInput.length>=2){
        clearTimeout(this.state.timeout);
        let body = {};
        let api = app_api+'/inventory/search_inventory_items';
    
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            search : userInput,
            active_tab : this.props.active_tab,
            target : 'product'
          }
        
        let _this = this;
        this.state.timeout = setTimeout(function () {
            axios.post(api, body)
              .then(function (response) {
                let cursor = response.data;
                localStorage.setItem('inventory_data',JSON.stringify(cursor));
                
                if(cursor.length===0){
                  if(localStorage.getItem('scan_item')!==null){
                    localStorage.removeItem('scan_item');
                    document.getElementById("no-item-found-error").innerHTML = 'Oops! No item found';
                  }

                  let iitem_type = '';
                  if(document.getElementById("iitem-type")){
                     iitem_type = document.getElementById("iitem-type").value;
                  }
                  let is_list_item_edit = localStorage.getItem('is_list_item_edit');
                  
                  if(document.getElementById("handleResetField") && (iitem_type==='product' || iitem_type==='single') && is_list_item_edit===null){
                    let inputs = document.querySelectorAll('#list-item-add-blk input[type=text]');
                    for(let i=0;i<inputs.length;i++){
                      if(inputs[i].id==='iitem-box'){
                      }else if(inputs[i].id==='iitem-barcode'){
                        let bcode_chk = document.getElementById("iitem-barcode-check").checked;
                        if(bcode_chk){
                          inputs[i].value = 'Auto Generate';
                        }
                      }else{
                        inputs[i].value = '';
                      }
                    }
                  }
                }
                
                    let item_options = [];
                    let purchase_item_options = [];
                    
                    
                    
                    cursor.map( (value, key) => {
                        let id = value.inventory_id;
                        let stat_lbl = '';
                        if(value.inventory_status==='Draft'){
                          stat_lbl=' (Inactive)';
                        }
                        let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
                        let static_item_name  =   value.item_name;
                        let barcode_name      =   value.barcode_name;
                        if(barcode_name===null){
                          barcode_name = "@@@";
                        }
                        let sku = '@@@';
                        if(value.sku!==null && value.sku!==""){
                          sku = value.sku;
                        }
                        let alias_barcode = '@@@';
                        if(value.alias_barcode!==null && value.alias_barcode!==""){
                          alias_barcode = value.alias_barcode;
                        }
                        if(value.has_variation===false){
                            purchase_item_options[id] = {
                                  value1:sku, 
                                  value2:item_name,
                                  value3:barcode_name,
                                  value4:alias_barcode,
                                  alias_barcode:alias_barcode,
                                  static_item_name:static_item_name,
                                  company_name:user_info.company_name,
                                  company_sub_title:user_info.company_sub_title,
                                  address:user_info.address,
                                  barcode_name:barcode_name,
                                  product_name:static_item_name,
                                  description:value.item_desc,
                                  price:value.selling_price,
                                  encrypted_purchase_price:value.enc_pp,
                                  encrypted_selling_price:value.enc_sp,
                                  category:value.category_name,
                                  custom_text:value.custom_text,
                                  varient:value.varient,
                                  city:value.city,
                                  state:value.state,
                                  phone1:value.phone1,
                                  phone2:value.phone2,
                                  email:value.email,
                                  contact_id:value.contact_id,
                                  custom_fields_json:value.custom_fields_json,
                                  supplier:value.supplier,
                            };
                        }
                    });

                    localStorage.setItem('purchase_item_options',JSON.stringify(purchase_item_options));
                    options  = purchase_item_options;
                    this.setState({options:purchase_item_options});
                
                    options.map( (value, index) => { 
                        if(value){
                        let value1 = value.value1;
                        let value2 = value.value2;
                        let value3 = "";
                        if(value.value3!==undefined && value.value3!==null){
                          value3 = value.value3;
                        }
                        let value4 = "";
                        if(value.value4!==undefined && value.value4!==null){
                          value4 = value.value4;
                        }
                        
                      
                        if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                            let bcode_val = value.value3;
                            let new_i_val = '';
                            if(bcode_val!==undefined && bcode_val!=="" && bcode_val!==null){
                              new_i_val = ' | '+bcode_val+' - Qty:';
                            }
                              value2 = value2.replace('- Qty:',new_i_val);
                              filteredOptions[index] = value2;

                              let c = 0;
                              filteredOptions.map( (v, i) => {
                                if(c==0){
                                    c++;
                                    activeOption = i;
                                    userInputIndex = i;
                                }
                              });
                        }
                        
                      }
                    });

              this.setState({
                activeOption,
                filteredOptions,
                showOptions: true,
                userInputIndex: userInputIndex,
              });
              this.setState({is_searching:false});
              setTimeout(function(){
                if(localStorage.getItem('scan_item')!==null){
                  localStorage.removeItem('scan_item');
                  if(document.querySelector(".option-active")){
                    if(document.getElementById("no-item-found-error")){
                      document.getElementById("no-item-found-error").innerHTML = '';
                    }
                    document.querySelector(".option-active").click();
                    if(document.getElementById("add-item-to-list")){
                      document.getElementById("add-item-to-list").click();
                    }
                  }
                }
              },100);
            }.bind(this)).catch(function(res){
                if(res.response){
                  if(res.response.data){
                      
                  }
                }
            })
        }.bind(this), 350);
      }else{
        clearTimeout(this.state.timeout);
      }
    }
  }


  addNewPurchaseItem = (filteredOptions,index,userInput) =>{
   
    if(this.props.active_tab==="New Purchase" || this.props.data_target==='add-direct-product'){
        let flen = filteredOptions.length;
        if(flen>0 ){ // && userInput.length>=3
        filteredOptions[index-1] = ' + Add as new item';
         // filteredOptions.splice(0, 0, ' + Add as new item');
          //this.setState({activeOption:1,userInputIndex:1});
        }
    }
    return filteredOptions;
  }

  onChange = (e) => {
    let data_index = 0;
    if(e.currentTarget.getAttribute('data-index')){
      data_index = e.currentTarget.getAttribute('data-index');
      localStorage.setItem('data_index',data_index);
    }
    const userInput = e.currentTarget.value;
    if(this.props.data_id==='add-old-bill' && userInput===""){
      this.setState({
        activeOption:0,
        filteredOptions:[],
        showOptions: false,
        userInputIndex: 0,
      });
      return false;
    }
    localStorage.removeItem('is_bank_account');
    if(userInput.length>1){
      this.setState({is_searching:true});
    }
    if(userInput===""){
      this.setState({is_searching:false});
    }
    if(this.props.data_target==='billing' && userInput===""){
      if(document.getElementById("handleResetField")){
        document.getElementById("handleResetField").click();
      }
      if( document.getElementById("no-item-found-error")){
        document.getElementById("no-item-found-error").innerHTML = '';
      }
      
    }
    if(this.props.data_target!=='payment' && this.props.data_target!=='previous_invoice'){
      let { options } = this.props;
      let userInputIndex = 0;
      let filteredOptions = [];
      let activeOption = 0;

      if(this.props.data_target==='contact'){
          if(document.getElementById("previous_balance_txt")){
            document.getElementById("previous_balance_txt").innerHTML = 0;
          }
          if(document.getElementById("cont-old-balance")){
            document.getElementById("cont-old-balance").classList.add('hide');
          }
          if(document.getElementById("cont-credit-limit")){
            document.getElementById("cont-credit-limit").classList.add('hide');
          }
          if(document.getElementById("credit_limit_txt")){
            
            document.getElementById("credit_limit_txt").innerHTML = 0;
          }
          let con_name = "";
          if(document.getElementById("contact_box")){
            con_name = document.getElementById("contact_box").value;
          }else if(document.getElementById("contact_box_"+data_index)){
            con_name = document.getElementById("contact_box_"+data_index)
          }
          if(con_name===""){
            if(document.getElementById("hanldeContactSelection")){
              document.getElementById("hanldeContactSelection").click();
            }
          }
          if(con_name!==""){
            let con_id = "";
            if(document.getElementById("customer-dropdown")){
              con_id = parseInt(document.getElementById("customer-dropdown").value);
            }else if(document.getElementById("customer-dropdown-"+data_index)){
              con_id = parseInt(document.getElementById("customer-dropdown-"+data_index).value);
            }
            if(con_id!=="" && con_id!=='0' && con_id!==0){
              if(options[con_id]===undefined){
                options[con_id] = [];
                options[con_id] = {value1:"",value2:con_name};
              }
            }else if(this.props.data_id==="reports"){
              options[0] = [];
              options[0] = {value1:"",value2:'All'};
            }
          }else if(this.props.data_id==="reports"){
            options[0] = [];
            options[0] = {value1:"",value2:'All'};
          }

          let is_load_contact = false;
          if(this.props.data_id==="reports" && (options.length===1 || this.state.is_search_contact)){
            is_load_contact = true;
          }else if(options.length===0 || this.state.is_search_contact){
            is_load_contact = true;
          }

          if(is_load_contact && userInput!==""){
            this.setState({is_search_contact:true});
            this.searchItemIfNotExist(userInput);
          }else if(is_load_contact && userInput===""){
            options = [];
          }
      }
      if(this.props.data_id==='reports'){
        options = [];
      }
      if(options.length>0){
        
        if(userInput===""){
           this.setState({
            filteredOptions: [],
            showOptions: false,
          });
        }
        let search_index = 0;
        options.map( (value, index) => {
            if(value){
            let value1 = value.value1;
            let value2 = value.value2;
            let value3 = "";
            if(value.value3!==undefined && value.value3!==null){
              value3 = value.value3;
              if(this.props.data_target==='billing'){
                if(value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                  this.setState({search_type:'barcode'});
                  localStorage.setItem('search_type','barcode');
                }else if(value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                  this.setState({search_type:'item_name'});
                  localStorage.setItem('search_type','item_name');
                }
                let bcode_val = value.value3;
                let new_i_val = '';
                if(bcode_val!==undefined && bcode_val!=="" && bcode_val!==null){
                  new_i_val = ' | '+bcode_val+' - Qty:';
                }
                value2 = value2.replace('- Qty:',new_i_val);
              }
            }
            let value4 = "";
            if(value.value4!==undefined && value.value4!==null){
              value4 = value.value4;
              if(this.props.data_target==='billing'){
                if(value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                  this.setState({search_type:'barcode'});
                  localStorage.setItem('search_type','barcode');
                }else if(value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                  this.setState({search_type:'item_name'});
                  localStorage.setItem('search_type','item_name');
                }
              }
            }
            try{
              if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1)|| (value4.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                
                  if(search_index>=20){
                    return false;
                  }
                  filteredOptions[index] = value2;
                  if(search_index===0){
                    activeOption = index;
                    userInputIndex = index;
                  }
                  search_index++;
              }
            }catch(error){

            }
          }
        });
        if(filteredOptions.length===0){
          this.searchItemIfNotExist(userInput);
        }else{
          filteredOptions = this.addNewPurchaseItem(filteredOptions,activeOption,userInput);
          this.setState({is_searching:false});
        }
        this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
          userInputIndex: userInputIndex,
        });
        
      }else{
        if(userInput!=="") {
         this.searchItemIfNotExist(userInput);
        }else{
          this.setState({
            activeOption :0,
            filteredOptions :[],
            showOptions: false,
            userInputIndex: 0,
          });
        }
      }
    }
    if(this.props.data_target==='bill_search_list' || this.props.data_target.match(/bill_search_list/)){
      let filteredOptions = [];
      if(userInput.length>=1){
          

          //clearTimeout(this.state.timeout);
          //this.state.timeout = setTimeout(function () {
              this.handleInvoiceSearch(userInput,0);
          //}.bind(this), 350);
        }else{
          
          clearTimeout(this.state.timeout);
        }
    }
  };

  handleCloseListOnOutSide(){
    this.setState({
      showOptions: false,
    });
  }

  closeList = (e) => {
    this.setState({
      filteredOptions: [],
      showOptions: false,
    });
  }
  onClick = (e) => {
    let data_index = 0;
    if(localStorage.getItem('data_index')){
      data_index = localStorage.getItem('data_index');
    }
    
    let curl = window.location.href;
    if(this.props.data_target==='search_view_product'){
      let product_id = e.currentTarget.getAttribute('data-id');
      window.location.href = '/products/view/'+product_id;
      return false;
    }
    if(this.props.data_target==='contact'){
      let is_add_new = e.currentTarget.innerText;
      if(is_add_new==='+ Add New'){
        if(document.getElementById("handleCreateContactOnFly")){
          document.getElementById("handleCreateContactOnFly").click()
        }
        this.setState({
          showOptions: false
        });
        return false;
      }
      if(document.getElementById("ContactSelected")){
        document.getElementById("ContactSelected").click();
      }
      this.getContact(e.currentTarget.getAttribute('data-id'));
      if(this.props.is_enable_tag){
        let tag_list = this.state.tag_list;
        let tag_item = {id:e.currentTarget.getAttribute('data-id'),name:e.currentTarget.innerText}
        tag_list.push(tag_item);
        this.setState({tag_list:tag_list});
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").value = '';
        }else if(document.getElementById("contact_box_"+data_index)){
          document.getElementById("contact_box_"+data_index).value = '';
        }
      }else{
        if(document.getElementById("contact_box")){
          if(this.props.load_target==='New Sale'){
            if(this.props.options[e.currentTarget.getAttribute('data-id')] && this.props.options[e.currentTarget.getAttribute('data-id')].value2!==undefined){
              document.getElementById("contact_box").value = this.props.options[e.currentTarget.getAttribute('data-id')].value2;
            }else{
              let search_contact = localStorage.getItem('search_contact');
              if(search_contact){
                let joptions = JSON.parse(search_contact);
                document.getElementById("contact_box").value = joptions[e.currentTarget.getAttribute('data-id')].value2;
              }
            }
          }else{
            document.getElementById("contact_box").value = e.currentTarget.innerText;
          }
        }else if(document.getElementById("contact_box_"+data_index)){
          if(this.props.load_target==='New Sale'){
            if(this.props.options[e.currentTarget.getAttribute('data-id')] && this.props.options[e.currentTarget.getAttribute('data-id')].value2!==undefined){
              document.getElementById("contact_box_"+data_index).value = this.props.options[e.currentTarget.getAttribute('data-id')].value2;
            }else{
              let search_contact = localStorage.getItem('search_contact');
              if(search_contact){
                let joptions = JSON.parse(search_contact);
                document.getElementById("contact_box_"+data_index).value = joptions[e.currentTarget.getAttribute('data-id')].value2;
              }
            }
          }else{
            document.getElementById("contact_box_"+data_index).value = e.currentTarget.innerText;
          }
        }
      }

      const new_payment = curl.match(/new-payment\/([a-z_A-Z]+)\/(\d+$)/i);
      const new_payment1 = curl.match(/new-payment\/([all|in|out]+$)/i);
      const new_payment2 = curl.match(/new-payment\/multiple\/(.*)/i);
      const new_payment3 = curl.match(/new-payment\/([edit]+)\/(\d+$)/i);

      const add_billing_type = curl.match(/new-billing\/(\d+$)/i);
      const edit_billing = curl.match(/new-billing\/([edit]+)\/([a-z_A-Z]+)\/(\d+$)/i);

      if(new_payment||new_payment1||new_payment2||new_payment3){
        if( document.getElementById("payment-mode")){
          document.getElementById("payment-mode").focus();
        } 
      }else if(add_billing_type||edit_billing){
        if(document.getElementById("staff-dropdown")){
          document.getElementById("staff-dropdown").focus();
        }else if(document.getElementById("iitem-box")){
          document.getElementById("iitem-box").focus();
        }
      }else if(this.props.data_id==='expenses'){
        document.getElementById("customer-dropdown").value = e.currentTarget.getAttribute('data-id');
        if(document.getElementById("expense-type")){
          document.getElementById("expense-type").focus();
        }
      }else if(this.props.data_id==='reports'){
        document.getElementById("customer-dropdown").value = e.currentTarget.getAttribute('data-id');
      }else{
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").focus();
        }else if(document.getElementById("contact_box_"+data_index)){
          document.getElementById("contact_box_"+data_index).focus();
        }
      }
    }
    if(this.props.data_target==='billing'){
      let data_id = parseInt(this.state.activeIndex);
      let is_new_item = e.currentTarget.innerHTML;
   
      if(is_new_item===' + Add as new item'){
        let nitem_id =  document.getElementById("options-li-0").getAttribute('data-id');
        nitem_id = parseInt(nitem_id);
        let filteredOptions = this.state.filteredOptions;
        let it_name =  filteredOptions[nitem_id].replace(/\s\-\sQty(.*)/g , "");
        it_name = it_name.replace(/\s+\|\s(.*)/g , "");
        setTimeout(() => {
          //document.getElementById("iitem-box").value = it_name;
          document.getElementById("iitem-name").value =  0;
          document.getElementById("old-iitem-name").value =  0;
          if(document.getElementById("iitem-desc")){
            document.getElementById("iitem-desc").focus();
          }
        }, 10);
        if(document.getElementById("trans-quantity")){
          document.getElementById("trans-quantity").focus();
        }
        this.setState({
          filteredOptions: [],
          showOptions: false,
          userInput: '',
          userInputIndex: 0
        });
        return false;
      }
      this.getItemInfo(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
      let it_name =  e.currentTarget.innerText.replace(/\s\-\sQty(.*)/g , "");
      it_name = it_name.replace(/\s+\|\s(.*)/g , "");
      document.getElementById("iitem-box").value =  it_name;
      document.getElementById("iitem-box").focus();
    }
     if(this.props.data_target==='barcode'){
      let data_id = parseInt(this.state.activeIndex);
      let it_name =  e.currentTarget.innerText.replace(/\s\-\sQty(.*)/g , "");
      it_name = it_name.replace(/\s+\|\s(.*)/g , "");
      document.getElementById("item-box-"+data_id).value =  it_name;
      document.getElementById("item-box-"+data_id).focus();
      this.getBarcodeItems(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
     if(this.props.data_target==='barcode-preview'){
      let data_id = parseInt(this.state.activeIndex);
      let it_name =  e.currentTarget.innerText.replace(/\s\-\sQty(.*)/g , "");
      it_name = it_name.replace(/\s+\|\s(.*)/g , "");
      document.getElementById("iitem-box").value =  it_name;
      document.getElementById("iitem-box").focus();
      this.setBarcodePreview(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
     if(this.props.data_target==='add-direct-product'){
      let item_n = document.getElementById("item-box-0").value;
     
      let is_new_item = e.currentTarget.innerHTML;
      if(is_new_item===' + Add as new item'){
        let nitem_id =  document.getElementById("options-li-0").getAttribute('data-id');
        nitem_id = parseInt(nitem_id);
        let filteredOptions = this.state.filteredOptions;
        let it_name =  filteredOptions[nitem_id].replace(/\s\-\sQty(.*)/g , "");
        it_name = it_name.replace(/\s+\|\s(.*)/g , "");
        setTimeout(() => {
          document.getElementById("item-box-0").value = item_n;
          document.getElementById("item-name-0").value =  0;
          document.getElementById("old-item-name-0").value =  0;
          if(document.getElementById("product-description")){
            document.getElementById("product-description").focus();
          }
        }, 10);
        this.setState({
          filteredOptions: [],
          showOptions: false,
          userInput: item_n,
          userInputIndex: 0
        });
        return false;
      }
      let data_id = parseInt(this.state.activeIndex);
      let it_name =  e.currentTarget.innerText.replace(/\s\-\sQty(.*)/g , "");
      it_name = it_name.replace(/\s+\|\s(.*)/g , "");
      document.getElementById("item-box-"+data_id).value =  it_name;
      document.getElementById("item-box-"+data_id).focus();
      this.addDirectProduct(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
     if(this.props.data_target==='inventory-bulk-edit'){
      let data_id = parseInt(this.state.activeIndex);
     /* document.getElementById("item-box").value =  e.currentTarget.innerText.replace(/\s\-\sQty(.*)/g , "");
      document.getElementById("item-box").focus();*/
      this.handleInventoryList(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
    if(this.props.data_target==='payment'){
        let data_id = parseInt(this.state.activeIndex);
        this.setPaymentOption(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
        if(document.getElementById("iitem-refrence-no")){
          document.getElementById("iitem-refrence-no").focus();
        }
    }
    if(this.props.data_target==='bill_search_list'){
      let data_id = parseInt(this.state.activeIndex);
      this.setSearchedBillOption(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }else if(this.props.data_target.match(/bill_search_list/)){
      let data_id = parseInt(this.state.activeIndex);
      this.setSearchedBillOption(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
    if(this.props.data_target==='previous_invoice'){
        let data_id = parseInt(e.currentTarget.getAttribute('data_id'));
        this.goToPreviousInvoice(parseInt(e.currentTarget.getAttribute('data-id')),data_id);
    }
    if(this.props.data_target!=='payment'  && this.props.data_target!=='previous_invoice'){
      this.setState({
        filteredOptions: [],
        showOptions: false,
        userInput: e.currentTarget.innerText,
        userInputIndex: e.currentTarget.getAttribute('data-id')
      });
    }
  };

  onBlur = (e) => {
    if(this.props.data_target==='billing'){
      this.setState({
        //showOptions: false
      });
    }
  };
  
  updateContactRecentFlag = (id) =>{
    if(this.props.options[id]===undefined || this.props.options[id]===null || this.props.options[id].contact_id===undefined){
      return false;
    }
    id = this.props.options[id].contact_id;
    try{
      id = parseInt(id);
      let transaction = db.transaction("contacts", "readwrite");
      let contacts = transaction.objectStore("contacts");
      let request = contacts.get(id);
      let _this = this;
     /*  db.transaction("contacts").objectStore("contacts").get(id).onsuccess = function(event) {
        let resp_data = event.target;
        let get_result = resp_data.result;
        if(get_result!==undefined && get_result.id!==undefined){
          let recent_flag  = localStorage.getItem('max_recent');
           recent_flag = parseInt(recent_flag) + 1;
           
           get_result.recent_flag = recent_flag;
           localStorage.setItem('max_recent',recent_flag);
           var res = get_result.update(get_result);
           res.onsuccess = function(e){
            console.log("update success!!");
            }
            res.onerror = function(e){
                console.log("update failed!!");
            }
        }
      }; */
      request.onsuccess = function(e) {
         let value = e.target.result;
        console.log(value);
        
         if(value!==undefined && value.id!==undefined){
           let id    = value.id;
           let type  = value.type;
           let recent_flag  = localStorage.getItem('max_recent');
           recent_flag = parseInt(recent_flag) + 1;
           value.recent_flag = recent_flag;
           var res = contacts.put(value);
           res.onsuccess = function(e){
            console.log("update success!!");
            }
            res.onerror = function(e){
                console.log("update failed!!");
            }
         }
      }
    }catch(error){

    }
  }

  getContact = (id)=>{
    if(this.props.load_target==='New Sale'){
      this.updateContactRecentFlag(id);
    }
     if(document.getElementById("showNoteAdditionalField")){
      document.getElementById("showNoteAdditionalField").click();
     }
      let { options } = this.props;
      if(options[id]!==undefined && options[id].discount!==undefined){
        localStorage.setItem('discount',options[id].discount);
        localStorage.setItem('state',options[id].state);
        localStorage.setItem('payment_mode',options[id].payment_mode);
      }
      if(this.props.load_target==='New Sale'){
        if(options[id] && options[id].contact_id!==undefined){
          id = options[id].contact_id;
        }else{
          let search_contact = localStorage.getItem('search_contact');
          if(search_contact){
            options = JSON.parse(search_contact);
            id = options[id].contact_id;
          }
        }
      }
       id= parseInt(id);
       localStorage.setItem('selected_contact',id);
       setTimeout(() => {
        if(document.getElementById("handleSetSelectedContact")){
          document.getElementById("handleSetSelectedContact").click();
        }
       }, 100);
       if(document.getElementById("handleMarkCheckContact")){
        document.getElementById("handleMarkCheckContact").click();
       }
       if(document.getElementById("handleGetPartyInvoice")){
          document.getElementById("handleGetPartyInvoice").click();
        }
      
       if(id!=''){
        localStorage.removeItem('ledger_contact_type');
        if(document.getElementById("ledger_contact_type")){
          let search_contact = localStorage.getItem('search_contact');
          
          if(search_contact){
            search_contact = JSON.parse(search_contact);
            let contact_type = (search_contact[id]!==undefined && search_contact[id]!==null && search_contact[id].contact_type!==undefined)?search_contact[id].contact_type:'';
            let is_not_contact = false;
            
            if(contact_type==='bank account'){
              contact_type = 'bank account';
              is_not_contact = true;
            }else if(contact_type=='payment mode'){
              contact_type = 'payment mode';
              is_not_contact = true;
            }
            localStorage.setItem('ledger_contact_type',contact_type);
            if(document.getElementById("ledger_contact_type")){
              document.getElementById("ledger_contact_type").innerHTML = ' of a '+contact_type;
            }
            if(is_not_contact===true){
              return false;
            }
          }
        }

        try{
         let transaction = db.transaction("contacts", "readwrite");
         let contacts = transaction.objectStore("contacts");
         let request = contacts.get(id);
         let _this = this;
         request.onsuccess = function(e) {
            let value = e.target.result;

            if(value!==undefined && value.id!==undefined){
              let id    = value.id;
              let type  = value.type;
              let contact_type  = value.contact_type;
              //if(localStorage.getItem('ledger_contact_type')===null){
                if(document.getElementById("ledger_contact_type")){
                  localStorage.setItem('ledger_contact_type',contact_type);
                  document.getElementById("ledger_contact_type").innerHTML = ' of a '+contact_type;
                }
              //}
              
              if(contact_type.toLowerCase()==='other' || contact_type==='others'){
                if(document.getElementById("handleHidePaymentOption")){
                  document.getElementById("handleHidePaymentOption").click();
                  setTimeout(() => {
                    document.querySelector('input[name="add-payemnt-option"]').click();
                  }, 100);

                }
              }else{
                if(document.getElementById("handleShowPaymentOption")){
                  document.getElementById("handleShowPaymentOption").click();
                }
              }
              if(_this.props.data_id==='reports' ){
                return false;
              }
              let data  = value.data;
              let bytes = CryptoJS.AES.decrypt(data, enc_key);
              let cval = bytes.toString(CryptoJS.enc.Utf8);
              cval = JSON.parse(cval);
             for (var key in cval) {
                let v = cval[key];

                if(document.getElementById(key)){
                  if(key!=='credit_limit' && key!=='discount'){
                    document.getElementById(key).value=v;
                  }
                }
                
                if(key==='agent_name'){
                  if(document.getElementById('agent-dropdown')){
                    document.getElementById('agent-dropdown').value = v;
                  }
                }
                if(key==='discount'){
                  if(document.getElementById("global_discount")){
                      document.getElementById("global_discount").value = v;
                      if(document.getElementById("global_discount_lbl")){
                        document.getElementById("global_discount_lbl").innerHTML = v;
                      }
                      setTimeout(function(){
                        document.querySelector('input[name="global_discount"]:checked').click();
                      },100);
                  }
                }

               /* if(key==='phone_1'){
                  localStorage.setItem(key,v);
                }*/

                if(key==='discount' || key==='payment_mode' || key==='state' || key==='credit_days'){
                  localStorage.setItem(key,v);

                  if(key==='payment_mode' && (v==='all' || v===1)){
                    if(document.getElementById("enableDirectPay")){
                      document.getElementById("enableDirectPay").click();
                    }
                  }

                  if(key==='payment_mode' && v!=='all' && v!=='' && v!==null){
                    if(document.getElementById("mode_of_payment")){
                      document.getElementById("mode_of_payment").value = v;
                      localStorage.setItem('p_mode',v);
                      if(document.getElementById("reset_mode_of_payment")){
                        document.getElementById("reset_mode_of_payment").click();
                      }
                      if(document.getElementById("refresh_mode_of_payment")){
                        document.getElementById("refresh_mode_of_payment").click();
                      }
                      if(document.getElementById("handleChangePaymentMode")){
                        let pm_element = document.getElementById("mode_of_payment");
                        let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                        localStorage.setItem('spm',ptxt);
                        document.getElementById("handleChangePaymentMode").click();
                      }
                    }
                  }
                  if(document.getElementById("contact_discount") && key==='discount'){
                    document.getElementById("contact_discount").value=v;
                  }
                }

                if(key==='state'){

                  if(user_info.state!==null && v!==null && v!==""){
                    var contact_state = v.match(/(\d+)/); 
                    var company_state = user_info.state.match(/(\d+)/); 
                    if(contact_state!==null && company_state!==null){
                      let con_state_code = contact_state[0];
                      let comp_state_code = company_state[0];
                      if(document.getElementById("contact_state")){
                        document.getElementById("contact_state").value = v;
                        if(con_state_code===comp_state_code){
                            //_this.setTaxItems('equal');
                        }else{
                            //_this.setTaxItems('different');
                        }
                      }
                    }else{
                     // _this.setTaxItems('equal');
                    }
                  }else{
                    if(document.getElementById("contact_state")){
                     // _this.setTaxItems('equal');
                    }
                  }
                }
              }
            }
         }
       }catch(error){

       }
       }else{
        var inputs = document.querySelectorAll('.f-field'); 
        for (var i = 0; i < inputs.length; i++) { 
            inputs[i].value = ''; 
        }
       }

        if(document.getElementById("refresh_pending_bill")){
          document.getElementById("refresh_pending_bill").click();
        }
       
       if((document.getElementById("previous_balance") && document.getElementById("credit_limit")) || (document.getElementById("previous_balance_txt") && document.getElementById("credit_limit_txt")) || document.getElementById("credit_limit_box") || document.getElementById("handleGetPartyInvoice") || this.props.handleSelectCustomer!==undefined){
         if(localStorage.getItem('is_bank_account')==='true' || localStorage.getItem('is_bank_account')==='payment_mode'){
          return false;
         }

        let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                id : id,
                uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
             }
          let api = app_api+'/get_contact_on_id';
          axios.post(api, body)
            .then(function (response) {
                let data = response.data;
                let contact_type = data[0].contact_type;
                if(contact_type.toLowerCase()==='other' || contact_type==='others'){
                  if(document.getElementById("handleHidePaymentOption")){
                    document.getElementById("handleHidePaymentOption").click();
                    setTimeout(() => {
                      document.querySelector('input[name="add-payemnt-option"]').click();
                    }, 100);
                  }
                }else{
                  if(document.getElementById("handleShowPaymentOption")){
                    document.getElementById("handleShowPaymentOption").click();
                  }
                }
                if(this.props.handleSelectCustomer){
                  this.props.handleSelectCustomer(data[0]);
                }
                localStorage.setItem('contact_info',JSON.stringify(data[0]));
                setTimeout(() => {
                  if(document.getElementById("handleManageTCSTransaction")){
                    document.getElementById("handleManageTCSTransaction").click();
                  }
                  if(document.getElementById("handleSetSelectedContactInfo")){
                    document.getElementById("handleSetSelectedContactInfo").click();
                  }
                  if(document.getElementById("handleLoadUnlinkTransports")){
                    document.getElementById("handleLoadUnlinkTransports").click();
                  }
                }, 100);
                if(document.getElementById("handleGetPartyInvoice")){
                  if(data[0].city){
                    if(document.getElementById("transport_from")){
                      document.getElementById("transport_from").value = data[0].city;
                    }
                  }

                }
                let credit_data = data.credit_data;
                let credit_limit = credit_data.credit_limit;
                let previous_balance = credit_data.previous_balance;
                let previous_balance_type = credit_data.previous_balance_type;
                if(document.getElementById('credit_limit')){
                  document.getElementById('credit_limit').value=utils.currency_format(credit_limit);
                }
                if(document.getElementById('previous_balance')){
                  document.getElementById('previous_balance').value=utils.currency_format(previous_balance);
                }
                if(document.getElementById('credit_limit_txt')){
                  if(credit_limit>0){
                    if(document.getElementById("cont-credit-limit")){
                      document.getElementById("cont-credit-limit").classList.remove('hide');
                    }
                    document.getElementById('credit_limit_txt').innerHTML=utils.currency_format(credit_limit);
                  }
                }
                if(document.getElementById('credit_limit_box')){
                  document.getElementById('credit_limit_box').value=credit_limit;
                }
                if(document.getElementById('original_credit_limit_box')){
                  document.getElementById('original_credit_limit_box').value=data[0].credit_limit;
                }
                if(document.getElementById('previous_balance_txt')){
                 if(previous_balance>0){
                  if(document.getElementById("cont-old-balance")){
                    document.getElementById("cont-old-balance").classList.remove('hide');
                  }
                   document.getElementById('previous_balance_txt').innerHTML=utils.currency_format(previous_balance)+' '+previous_balance_type;
                   
                  }
                }

                let coninfo = data[0];
                let conpmode = coninfo.payment_mode;
                let agent_name = coninfo.agent_name;
                if(document.getElementById('handleCheckIsStore')){
                  let is_store = coninfo.is_store;
                  localStorage.setItem('is_store',is_store);
                  setTimeout(() => {
                    document.getElementById('handleCheckIsStore').click();
                  }, 500);
                }
                  if(document.getElementById('agent-dropdown')){
                  document.getElementById('agent-dropdown').value = agent_name;
                }
                let is_direct_pay = coninfo.is_direct_pay;
                let tcs_percent = coninfo.tcs_percent;
                let is_tcs_enabled = coninfo.is_tcs_enabled;
                let contact_id = coninfo.contact_id;
                
                if(is_tcs_enabled===true){
                  if(document.getElementById("tcs-percent")){
                    document.getElementById("tcs-percent").value = tcs_percent;
                  }
                }else{
                  if(document.getElementById("tcs-percent")){
                    document.getElementById("tcs-percent").value = 0;
                  }
                }
                localStorage.setItem('phone_1',coninfo.phone_1);
                if(document.getElementById("handleBusinessType")){
                  localStorage.setItem('con_gst_number',coninfo.gst_number);
                  document.getElementById("handleBusinessType").click();
                  localStorage.removeItem('con_gst_number');
                }
                if(is_direct_pay===true){
                  if(document.getElementById("enableDirectPay")){
                    document.getElementById("enableDirectPay").click();
                  }
                }
                localStorage.removeItem('p_mode');
                /* if(conpmode!=='all' && conpmode!==''  && conpmode!==null){
                  if(document.getElementById("mode_of_payment")){
                    document.getElementById("mode_of_payment").value = conpmode;
                    localStorage.setItem('p_mode',conpmode);
                    if(document.getElementById("reset_mode_of_payment")){
                      document.getElementById("reset_mode_of_payment").click();
                    }
                    if(document.getElementById("refresh_mode_of_payment")){
                      document.getElementById("refresh_mode_of_payment").click();
                    }
                    if(document.getElementById("handleChangePaymentMode")){
                      let pm_element = document.getElementById("mode_of_payment");
                      let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                      localStorage.setItem('spm',ptxt);
                      document.getElementById("handleChangePaymentMode").click();
                    }
                  }
                }
                if(document.getElementById("handleDefaultPaymentMode")){
                  document.getElementById("handleDefaultPaymentMode").click();
                } */
                /* if((conpmode==='all' || conpmode==='') && is_direct_pay===true){
                  if(document.getElementById("mode_of_payment")){
                    document.getElementById("mode_of_payment").value = 1;
                    localStorage.setItem('p_mode',conpmode);
                    if(document.getElementById("reset_mode_of_payment")){
                      document.getElementById("reset_mode_of_payment").click();
                    }
                    if(document.getElementById("refresh_mode_of_payment")){
                      document.getElementById("refresh_mode_of_payment").click();
                    }
                    if(document.getElementById("handleChangePaymentMode")){
                      let pm_element = document.getElementById("mode_of_payment");
                      let ptxt = pm_element.options[pm_element.selectedIndex].text.toLowerCase();
                      localStorage.setItem('spm',ptxt);
                      document.getElementById("handleChangePaymentMode").click();
                    }
                  }
                } */
               
                localStorage.setItem('adv_pay',coninfo.advance_payment);
                if(document.getElementById("handleCheckAdvancePayment")){
                  document.getElementById("handleCheckAdvancePayment").click();
                }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
        }
        setTimeout(() => {
          if(document.getElementById("hanldeContactSelection")){
            document.getElementById("hanldeContactSelection").click();
          }
        }, 50);

    }


  currency_format = (number) =>{
      return utils.currency_format(number);
  }

  floating_point = (number) =>{
      return utils.floating_point(number);
  }
  setPaymentOption = (id,index) =>{
      let invoices = this.state.options[id];
      let item_id = invoices.id;
      let target = invoices.target;
      let name = invoices.value2;
      let total = invoices.grand_total;
      if(isNaN(total)){
        total = invoices.grand_total.replace(/,/g , "");
      }
      document.getElementById("iitem-box").value = name; 
      document.getElementById("iitem-total").value = total;
      document.getElementById("iitem-total-old").value = total;
      document.getElementById("iitem-name").value = item_id;
      document.getElementById("iitem-target").value = target;
      document.getElementById("old-iitem-name").value = item_id;


      this.setState({
        userInputIndex: item_id
      });
      
  }
  setSearchedBillOption = (id,index) =>{
      let invoices = this.state.options[id];
      let item_id = invoices.id;
      let target = invoices.target;
      let name = invoices.value2;
      let total = invoices.grand_total;
      if(isNaN(total)){
        total = invoices.grand_total.replace(/,/g , "");
      }

      /* if(invoices.dispatched_info){
        let dispatched_info = invoices.dispatched_info;
        let lr_no = dispatched_info.dispatched_document;
        let no_of_bale = dispatched_info.no_of_parcels;
        let no_of_parcels = dispatched_info.pieces_in_parcel;
        let supplier_ref_no = dispatched_info.supplier_ref_no;

        if(document.getElementById("lr_no")){
          document.getElementById("lr_no").value = lr_no;
        }
        if(document.getElementById("bale")){
          document.getElementById("bale").value = no_of_bale;
        }
        if(document.getElementById("no_of_parcel_received")){
          document.getElementById("no_of_parcel_received").value = no_of_parcels;
        }
        if(document.getElementById("b_no")){
          document.getElementById("b_no").value = supplier_ref_no;
        }
      } */
      
      if(document.getElementById("bill_search_list_box")){
        document.getElementById("bill_search_list_box").value = name; 
        document.getElementById("bill_search_list").value = item_id;
        document.getElementById("old-bill_search_list").value = item_id;
      }else if(document.getElementById("bill_search_list_box-"+this.props.data_id)){
        
        document.getElementById("bill_search_list_box-"+this.props.data_id).value = name; 
        document.getElementById("bill_search_list-"+this.props.data_id).value = item_id;
        document.getElementById("old-bill_search_list-"+this.props.data_id).value = item_id;
      }
      if(this.props.handleChangeInvoice){
        this.props.handleChangeInvoice(this.props.data_id,name,item_id);
      }
      this.setState({
        userInputIndex: item_id
      });
  }

  addDirectProduct = (id,index)=>{
    let { options } = this.props;
    if(document.querySelector(".option-active")){
      let is_new_item = document.querySelector(".option-active").innerHTML;
      if(is_new_item===' + Add as new item'){
        let nitem_id =  document.getElementById("options-li-0").getAttribute('data-id');
        nitem_id = parseInt(nitem_id);
        let filteredOptions = this.state.filteredOptions;
        setTimeout(() => {
          let it_name =  filteredOptions[nitem_id].replace(/\s\-\sQty(.*)/g , "");
          it_name = it_name.replace(/\s+\|\s(.*)/g , "");
          //document.getElementById("item-box-0").value = it_name;
          document.getElementById("item-name-0").value =  0;
          document.getElementById("old-item-name-0").value =  0;
          if(document.getElementById("product-description")){
            document.getElementById("product-description").focus();
          }
        }, 10);
      
        return false;
      }
    }
    if(id>0){
      if(options[id]===undefined){
        if(localStorage.getItem("purchase_item_options")){
          options = JSON.parse(localStorage.getItem('purchase_item_options'));
        }
      }
      let varient = options[id].varient;
      let inventory_status = options[id].inventory_status;
      let item_name = options[id].item_name;
      let item_desc = options[id].item_desc;
      let category = options[id].category_id;
      let category_name = options[id].category_name;
      let sku = options[id].sku;
      let barcode_name = options[id].barcode_name;
      let hsn = options[id].hsn;
      let part_name = options[id].part_name;
      let purchase_price = options[id].purchase_price;
      let availability = options[id].availability;
      let product_unit_id = options[id].product_unit_id;
      let pp_tax = options[id].tax;
      let pp_tax_type = options[id].pp_tax_type;
      let pp_discount = options[id].discount;

      let selling_price = options[id].selling_price;
      let lowest_selling_price = options[id].lowest_selling_price;
      let sp_tax = options[id].sp_tax;
      let sp_tax_type = options[id].sp_tax_type;
      let sp_discount = options[id].sp_discount;
      let bundle_quantity = options[id].bundle_quantity;
      let custom_text = options[id].custom_text;
      let reorder_quantity = options[id].reorder_quantity;
      let opening_stock = options[id].opening_stock;

      if(purchase_price!=="" && selling_price!==""){
        purchase_price = parseFloat(purchase_price);
        selling_price = parseFloat(selling_price);
        let profit = selling_price-purchase_price;
        let margin = (profit/selling_price)*100;
        margin = this.floating_point(margin);
        profit = this.currency_format(profit);
        document.getElementById("product-margin").innerHTML = margin+'%';
        document.getElementById("product-profit").innerHTML = '₹'+profit;
      }

      if(document.getElementById("product-description")){
        document.getElementById("product-description").value = item_desc;
      }

      if(document.querySelector('[name=product-category]')){
        localStorage.setItem('selected_product',category);
        localStorage.setItem('category_id',category);
        localStorage.setItem('category_name',category_name);
        if(document.getElementById("setProductState")){
          document.getElementById("setProductState").click();
        }
        if(document.getElementById("handleSetCategory")){
          document.getElementById("handleSetCategory").click();
          //document.querySelector('[name=product-category]').value = category_name;
        }else{
          document.querySelector('[name=product-category]').value = category;
        }
      }

      if(document.getElementById("product-sku")){
        document.getElementById("product-sku").value = sku;
      }

      if(document.getElementById("product-barcode")){
        document.getElementById("product-barcode").value = barcode_name;
      }

      if(document.getElementById("product-hsn")){
        document.getElementById("product-hsn").value = hsn;
      }

      if(document.getElementById("product-partname")){
        document.getElementById("product-partname").value = part_name;
      }

      if(document.getElementById("purchase-price")){
        document.getElementById("purchase-price").value = purchase_price;
      }

      if(document.getElementById("avail-quan")){
        document.getElementById("avail-quan").innerHTML = 'Available : '+availability;
        document.getElementById("avail-quan-hidden").value = availability;
      }

      if(document.getElementById("product-unit")){
        document.getElementById("product-unit").value = product_unit_id;
      }

      if(document.getElementById("pp_tax_dd")){
        document.getElementById("pp_tax_dd").value = pp_tax;
      }

      if(document.getElementById("pp-tax-type")){
        document.getElementById("pp-tax-type").value = pp_tax_type;
      }

      if(document.getElementById("pp-discount")){
        document.getElementById("pp-discount").value = pp_discount;
      }

      if(document.getElementById("selling-price")){
        document.getElementById("selling-price").value = selling_price;
      }

      if(document.getElementById("minimum-selling-price")){
        document.getElementById("minimum-selling-price").value = lowest_selling_price;
      }

      if(document.getElementById("sp_tax_dd")){
        document.getElementById("sp_tax_dd").value = sp_tax;
      }

      if(document.getElementById("sp-tax-type")){
        document.getElementById("sp-tax-type").value = sp_tax_type;
      }

      if(document.getElementById("sp-discount")){
        document.getElementById("sp-discount").value = sp_discount;
      }

      if(document.getElementById("sp-discount")){
        document.getElementById("sp-discount").value = sp_discount;
      }

      if(document.getElementById("bundle-quantity")){
        document.getElementById("bundle-quantity").value = bundle_quantity;
      }

      if(document.getElementById("custom-text")){
        document.getElementById("custom-text").value = custom_text;
      }

      if(document.getElementById("reorder-quantity")){
        document.getElementById("reorder-quantity").value = reorder_quantity;
      }

      if(document.getElementById("opening-stock")){
        document.getElementById("opening-stock").value = opening_stock;
      }
      document.getElementById("product-quantity").focus();
    }
  }

  getBarcodeItems = (id,index)=>{
     let { options } = this.props;
     if(id>0 && options[id]===undefined && options[id]===null){
        options = this.state.options;
     }
     
     if(id>0 && (options[id]===undefined || options[id]===null)){
        let opt_item = JSON.parse(localStorage.getItem('item_options'));
        if(opt_item[id]!==undefined && opt_item[id]!==null){
          if(options[id]===undefined){
            options[id] = [];
          }
          options[id] = opt_item[id];
        }
     }

     let child_item_options = JSON.parse(localStorage.getItem('child_item_options'));
     if(child_item_options[id]!==undefined && child_item_options[id]!==null){
        localStorage.setItem('bcode_par_item',id);
        let c_options = child_item_options[id];
        c_options.map( (value, index) => { 
          let inventory_id = value.inventory_id;
          let item_name = value.item_name;
          let category = value.category;
          let sku = (value.sku!==null)?value.sku:'';
          let availability = value.availability;
          let varient = value.varient;
          let inventory_status = value.inventory_status;
          if(inventory_status==='Draft'){
            inventory_status = 'Inactive';
          }
          let cls_len = document.querySelectorAll('.barcode-list').length;

          if(document.getElementById("barcode-list-"+inventory_id)){
            //continue;
          }else{

            let no_of_print = availability;
            if(document.getElementById("no-of-print-"+inventory_id)){
              let noprint = document.getElementById("no-of-print-"+inventory_id).value;
              if(noprint!==""){
                no_of_print = noprint;
              }
            }
           
            
            let row_items = '<tr class="contact-row product-row barcode-list" id="barcode-list-'+inventory_id+'" data-id="'+inventory_id+'" data-target="'+id+'"><td>'+item_name+'</td><td>'+category+'</td><td>'+availability+'</td><td>'+varient+'</td><td>'+inventory_status+'</td><td width="100"><input type="text" class="form-control no-of-print" onkeyup="cal_bc_print(event)" id="no-of-print-'+inventory_id+'" value="'+no_of_print+'"/></td><td class="rm-bitems" id="'+inventory_id+'" onclick="remove_barcode_item(event)">x</td></tr>';
            let div_row = document.getElementById("barcode-print-list");
            if(cls_len===0){
              div_row.innerHTML = row_items;
              document.getElementById("show-template-btn").classList.remove('hide');
            }else{
              div_row.innerHTML += row_items;
            }
            document.getElementById("item-box-0").value = "";
            
            document.getElementById("total-bp-foot").classList.remove('hide');

            this.setState({activeOption:0});
            let cls = document.querySelectorAll('.no-of-print');
            let total = 0;
            for(let i=0;i<cls.length;i++){
              total = total+parseInt(cls[i].value);
            }
            document.getElementById("barcode-print-total").innerHTML = total;
          }
        });
        document.getElementById("handleInventoryHideShow").click();
     }else if(id>0 && options[id]!==undefined && options[id]!==null){
        let item_name = options[id].item_name;
        let category = options[id].category;
        let sku = options[id].sku;
        let availability = options[id].availability;
        let varient = options[id].varient;
        let inventory_status = options[id].inventory_status;
        if(inventory_status==='Draft'){
          inventory_status = 'Inactive';
        }
        let cls_len = document.querySelectorAll('.barcode-list').length;
        if(document.getElementById("barcode-list-"+id)){
            //continue;
        }else{
          let no_of_print = availability;
          if(document.getElementById("no-of-print-"+id)){
            let noprint = document.getElementById("no-of-print-"+id).value;
            if(noprint!==""){
              no_of_print = noprint;
            }
          }
          
          let row_items = '<tr class="contact-row product-row barcode-list" id="barcode-list-'+id+'" data-id="'+id+'"><td>'+item_name+'</td><td>'+category+'</td><td>'+availability+'</td><td>'+varient+'</td><td>'+inventory_status+'</td><td width="100"><input type="text" class="form-control no-of-print" onkeyup="cal_bc_print(event)" id="no-of-print-'+id+'" value="'+no_of_print+'"/></td><td class="rm-bitems" id="'+id+'" onclick="remove_barcode_item(event)">x</td></tr>';
          let div_row = document.getElementById("barcode-print-list");
          if(cls_len===0){
            div_row.innerHTML = row_items;
            document.getElementById("show-template-btn").classList.remove('hide');
          }else{
            div_row.innerHTML += row_items;
          }
          document.getElementById("item-box-0").value = "";
          
          document.getElementById("total-bp-foot").classList.remove('hide');

          this.setState({activeOption:0});
          let cls = document.querySelectorAll('.no-of-print');
          let total = 0;
          for(let i=0;i<cls.length;i++){
            total = total+parseInt(cls[i].value);
          }
          document.getElementById("barcode-print-total").innerHTML = total;
        }
        document.getElementById("handleInventoryHideShow").click();
    }
  }
  setBarcodePreview = (id,index)=>{
     const { options } = this.props;

     if(id>0){
      let preview_data = options[id];
      localStorage.setItem("barcode_preview_id",id);
      setTimeout(function(){
        document.getElementById("preveiwBarcodeTempate").click();
      },100);
    }
  }
  handleInventoryList = (id,index)=>{
    const { options } = this.props;
    if(id>0){
      localStorage.setItem("selected_inventory_id",id);
      //setTimeout(function(){
        document.getElementById("handleInventoryList").click();
      //},8);
    }
  }
  
  setTaxItems = (type) =>{
      if(type==='equal'){
        let igst = document.querySelectorAll(".item-igst");
        for(let i=0;i<igst.length;i++){
           igst[i].value = 0;
        }
        let cgst = document.querySelectorAll(".item-cgst-hidden");
        for(let i=0;i<cgst.length;i++){
           let val = cgst[i].value;
           if(val!==""){
             let data_id = cgst[i].getAttribute('data-id');
             document.getElementById("item-cgst-"+data_id).value = val;
           }
        }
        let sgst = document.querySelectorAll(".item-sgst-hidden");
        for(let i=0;i<sgst.length;i++){
           let val = sgst[i].value;
           if(val!==""){
             let data_id = sgst[i].getAttribute('data-id');
             document.getElementById("item-sgst-"+data_id).value = val;
           }
        }
      }else{
        let igst = document.querySelectorAll(".item-igst-hidden");
        for(let i=0;i<igst.length;i++){
           let val = igst[i].value;
           if(val!==""){
             let data_id = igst[i].getAttribute('data-id');
             document.getElementById("item-igst-"+data_id).value = val;
           }
        }
        let sgst = document.querySelectorAll(".item-sgst");
        for(let i=0;i<sgst.length;i++){
           sgst[i].value = 0;
        }
        let cgst = document.querySelectorAll(".item-cgst");
        for(let i=0;i<cgst.length;i++){
           cgst[i].value = 0;
        }
      }

      let quant_field = document.querySelectorAll(".item-quantity");
      let total_quantity = 0;
      for(let i=0;i<quant_field.length;i++){
          if(quant_field[i].value!==''){
            total_quantity = total_quantity+parseInt(quant_field[i].value);
          }
          let data_id = quant_field[i].getAttribute("data-id");
          let item_name = document.getElementById("item-box-"+data_id).value;
          if(item_name!==""){
          let quantity = parseInt(quant_field[i].value);
          if(quantity!=="" && quantity!=='0'){
              let discount = 0;
              if(document.getElementById('discount-'+data_id)){
                discount = parseInt(document.getElementById('discount-'+data_id).value);
              }
              let price = parseFloat(document.getElementById('price-'+data_id).value);
              let cgst = parseInt(document.getElementById('item-cgst-'+data_id).value);
              let sgst = parseInt(document.getElementById('item-sgst-'+data_id).value);
              let igst = parseInt(document.getElementById('item-igst-'+data_id).value);
              let tax = cgst+sgst+igst;
            
              let cal_discount = ((price*quantity)*discount)/100;
              let item_grand_total = (price*quantity)-cal_discount;
              let cgst_tax = (item_grand_total*cgst)/100;
              let sgst_tax = (item_grand_total*sgst)/100;
              let igst_tax = (item_grand_total*igst)/100;
              document.getElementById("item-igst-total-"+data_id).value = igst_tax;
              document.getElementById("item-sgst-total-"+data_id).value = sgst_tax;
              document.getElementById("item-cgst-total-"+data_id).value = cgst_tax;

              let cal_tax = (item_grand_total*tax)/100;
              if(document.getElementById("discount-"+data_id)){
                document.getElementById("discount-hidden-"+data_id).value = cal_discount;
              }

              
              let final_amount = item_grand_total+cal_tax;
              document.getElementById("item-total-"+data_id).innerHTML = this.currency_format(final_amount);
              document.getElementById("item-total-txt-"+data_id).value = final_amount;
              if(document.getElementById("discount-"+data_id)){
                var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
                let item_discount = 0;
                for (var j = 0; j < item_total_discount.length; j++) { 
                     item_discount = item_discount + parseFloat((item_total_discount[j].value!=''?(item_total_discount[j].value):0)); 

                } 
                document.getElementById("total-discount").innerHTML = this.currency_format(item_discount);
              }
          }
        }
      }
      let item_tax = 0;
      var item_total_tax = document.querySelectorAll('.item-cgst-total'); 
      let cgst_total =0;
      let sgst_total =0;
      let igst_total =0;
      for (var i = 0; i < item_total_tax.length; i++) { 
           let did = item_total_tax[i].getAttribute('data-id');
           let cgst = item_total_tax[i].value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));

           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }

           cgst_total = cgst_total+cgst;
           sgst_total = sgst_total+sgst;
           igst_total = igst_total+igst;

           item_tax = item_tax + (cgst+sgst+igst);
      } 
      document.getElementById("total-cgst").innerHTML = this.currency_format(cgst_total);
      document.getElementById("total-sgst").innerHTML = this.currency_format(sgst_total);
      document.getElementById("total-igst").innerHTML = this.currency_format(igst_total);
      document.getElementById("total-tax").innerHTML = this.currency_format(item_tax);

      var item_final_total = document.querySelectorAll('.item-total-txt'); 
      let item_total = 0.0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!=''){
              total_unit++;
            }
           item_total = item_total + parseFloat((item_final_total[i].value!=''?(item_final_total[i].value):0)); 

      } 

      document.getElementById("s-total").innerHTML = this.currency_format(item_total);

      let after_round = Math.round(item_total)-item_total;
      
      if(after_round>=0){
        after_round = Math.ceil(item_total)-item_total;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = item_total-Math.floor(item_total);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;
      
      if(round_off!==""){
          if(round_off_opt==='-'){
              item_total = parseFloat(item_total)-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = parseFloat(item_total)+parseFloat(round_off);
          }
      }

      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }

      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.props.active_tab==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.props.active_tab==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }
      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = item_total;
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/
      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
          document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
        }
/*
      if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }
        
      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      document.getElementById("total-unit").innerHTML = this.currency_format(total_unit);
      document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
      document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
      
  }

  handleIncludeTaxCalculation = (id)=>{
        let is_checked = document.getElementById("iitem-tax-inc").value;

        let cgst = document.getElementById("iitem-cgst").value;
        let sgst = document.getElementById("iitem-sgst").value;
        let igst = document.getElementById("iitem-igst").value;
        let quantity = document.getElementById("iitem-quantity").value.replace(/,/g , "");
        if(quantity===""){
          quantity = 1;
        }else{
          quantity = parseInt(quantity);
        }
        
        if(is_checked==='including'){  
        let price = document.getElementById("price-hidden").value;
        if(price===""){
          price = document.getElementById("price").value;
          document.getElementById("price-hidden").value = price;
        }
        let discount = 0;
        if(document.getElementById("discount-hidden")){
          discount = document.getElementById("discount-hidden").value;
          if(discount===""){
            discount=0;
          }
          discount = parseFloat(discount);
        }



        let tax = 0;
        if((cgst!=="" && cgst!=="0")  && (sgst!=="" && sgst!=="0")){
          
          cgst = parseFloat(cgst);
          sgst = parseFloat(sgst);
          let total_cgst = 0;
          let total_sgst =0;
        
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + (sgst+cgst) ) ) );

          GST = price-GST;

          GST = parseFloat(this.floating_point(GST));

          let gross_amount = GST-(discount/quantity);
          


          let TOTAL_GST = price-GST;

          total_cgst = TOTAL_GST/2;
          total_sgst = TOTAL_GST/2;

          total_cgst = (quantity*total_sgst);
          total_sgst = (quantity*total_sgst);
          document.getElementById("iitem-cgst-total").value = this.floating_point(total_cgst);
          document.getElementById("iitem-sgst-total").value = this.floating_point(total_sgst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        }else if(igst!=="" && igst!=="0"){
          
          igst = parseFloat(igst);
          let GST = parseFloat(price) - (parseFloat(price) * (100 / (100 + igst ) ) );
          
          GST = price-GST;
          GST = parseFloat(this.floating_point(GST));
         
          let gross_amount = GST-(discount/quantity);
          let TOTAL_GST = price-GST;
          let total_igst = TOTAL_GST;
          total_igst = (quantity*total_igst);
          document.getElementById("iitem-igst-total").value = this.floating_point(total_igst);
          document.getElementById("price").value = this.floating_point(gross_amount);
          price = (quantity*price)-discount;
          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value =this.floating_point(price);
        } 
      }else{
          
          if(((cgst!=="" && cgst!=="0") && (sgst!=="" && sgst!=="0")) || (igst!=="" && igst!=="0")){
            
          let price = document.getElementById("price-hidden").value;
          if(price===""){
            price = document.getElementById("price").value;
            document.getElementById("price-hidden").value = price;
          }
          price = price.replace(/,/g , "");
          
          document.getElementById("price").value = this.floating_point(price);
          
          price = parseFloat(price);

          let disc = 0;
          let total_discount=0;
          if(document.getElementById("discount-hidden")){
            disc = document.getElementById("discount").value;
            if(disc===""){
              disc = 0;
            }
            disc = parseFloat(disc);
            
            total_discount = price*disc/100;
            //document.getElementById("discount-hidden").value = total_discount;
          }

          price = (quantity*price)-total_discount;
          let cgst = document.getElementById("iitem-cgst").value;
          let sgst = document.getElementById("iitem-sgst").value;
          let igst = document.getElementById("iitem-igst").value;

          let t_cgst = (price)*cgst/100;
          let t_sgst = (price)*sgst/100;
          let t_igst = (price)*igst/100;

          
          document.getElementById("iitem-cgst-total").value = t_cgst;
          document.getElementById("iitem-sgst-total").value = t_sgst;
          document.getElementById("iitem-igst-total").value = t_igst;

          price = price+t_cgst+t_sgst+t_igst;

          document.getElementById("iitem-total").innerHTML = this.currency_format(price);
          document.getElementById("iitem-total-txt").value = this.floating_point(price);
        }
      }
      //this.calculateTotal();
    }

  calculateTotal = ()=>{
      var item_sub_total = document.querySelectorAll('.item-sub-total'); 
      var item_quantity = document.querySelectorAll('.item-quantity'); 
      let sub_total = 0;
      for (var i = 0; i < item_sub_total.length; i++) { 
          if(item_sub_total[i].value!==''){
            let quan = parseInt(item_quantity[i].value);
             let i_s_total = (item_sub_total[i].value!=''?(item_sub_total[i].value):0);
             sub_total = sub_total + (quan*parseFloat(i_s_total));
           }
      } 

       

      let quantity = parseInt(document.getElementById("total-quantity").innerHTML.replace(/,/g , ""));
      document.getElementById("sub-total").innerHTML = this.currency_format(sub_total); 


      let item_tax = 0;
      let cgst_total =0;
      let sgst_total =0;
      let igst_total =0;
      var item_total_tax = document.querySelectorAll('.item-cgst-total'); 
      for (var i = 0; i < item_total_tax.length; i++) { 
           let did = item_total_tax[i].getAttribute('data-id');
           let cgst = item_total_tax[i].value;
           let sgst = document.getElementById("item-sgst-total-"+did).value;
           let igst = document.getElementById("item-igst-total-"+did).value;
          
           cgst = parseFloat((cgst!=''?(cgst):0));
           sgst = parseFloat((sgst!=''?(sgst):0));
           igst = parseFloat((igst!=''?(igst):0));
           let iigst = document.getElementById("item-igst-"+did).value;
           let isgst = document.getElementById("item-sgst-"+did).value;
           let icgst = document.getElementById("item-cgst-"+did).value;

           if(iigst==="" || iigst===0 || iigst==='0'){
            igst = 0;
           }
           if(isgst==="" || isgst===0 || isgst==='0'){
            sgst = 0;
           }
           if(icgst==="" || icgst===0 || icgst==='0'){
            cgst = 0;
           }
           cgst_total = cgst_total+cgst;
           sgst_total = sgst_total+sgst;
           igst_total = igst_total+igst;

           item_tax = item_tax + (cgst+sgst+igst);
      } 
      document.getElementById("total-cgst").innerHTML = this.currency_format(cgst_total);
      document.getElementById("total-sgst").innerHTML = this.currency_format(sgst_total);
      document.getElementById("total-igst").innerHTML = this.currency_format(igst_total);
      document.getElementById("total-tax").innerHTML = this.currency_format(item_tax);
      document.getElementById("total-after-tax").innerHTML = this.currency_format(item_tax);

      var item_final_total = document.querySelectorAll('.item-total-txt'); 
      let item_total = 0;
      let total_unit = 0;
      for (var i = 0; i < item_final_total.length; i++) { 
            if(item_final_total[i].value!==''){
              total_unit++;
              item_total = item_total +item_final_total[i].value;
            }
      } 
      //document.getElementById("s-total").innerHTML = this.currency_format(item_total);
      
       let cont_disc = localStorage.getItem('discount');
       if(cont_disc==="" || cont_disc===null || cont_disc==='null'){
         cont_disc = 0;
       }else{
         cont_disc = parseInt(cont_disc);
       }


      var item_total_discount = document.querySelectorAll('.item-discount-hidden'); 
      let item_discount = 0;
      for (var i = 0; i < item_total_discount.length; i++) { 
           item_discount = item_discount + parseFloat((item_total_discount[i].value!==''?(item_total_discount[i].value):0));
      } 
      if(document.getElementById("total-discount")){
        document.getElementById("total-discount").innerHTML = this.currency_format(item_discount);
      }

       let discount_opt = document.querySelector('input[name="global_discount"]:checked').value;

       

       let discount_on_bill = (sub_total)-item_discount;
        let gdisc =0;
       if(discount_opt==="rs"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("global_discount").value = this.floating_point(gdisc);
          document.getElementById("global_discount_lbl").innerHTML = this.floating_point(gdisc);
          document.getElementById("disc_on_bill").innerHTML = '';
          document.getElementById("disc_on_bill_lbl").innerHTML = '';
         // item_total = parseFloat(discount_on_bill)-gdisc;
       }else if(discount_opt==="per"){
           gdisc = (parseFloat(discount_on_bill)*cont_disc)/100;
          document.getElementById("disc_on_bill").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("disc_on_bill_lbl").innerHTML = '<span class="rs-sym" style="font-weight:bold"> : ₹'+gdisc+'</span>';
          document.getElementById("global_discount").value = cont_disc;
          document.getElementById("global_discount_lbl").innerHTML = cont_disc;
       }
      
       let total_before_tax = discount_on_bill-gdisc;
       document.getElementById("total-before-tax").innerHTML = this.currency_format(total_before_tax);

       let total_after_tax = total_before_tax+item_tax;
      document.getElementById("s-total").innerHTML = this.currency_format(total_after_tax);



      let after_round = Math.round(total_after_tax)-total_after_tax;
      
      if(after_round>=0){
        after_round = Math.ceil(total_after_tax)-total_after_tax;
        after_round = this.currency_format(after_round);
        document.getElementById("plus").click();
      }else{
        after_round = total_after_tax-Math.floor(total_after_tax);
        after_round = this.currency_format(after_round);
        document.getElementById("minus").click();
      }
      document.getElementById("round_off").value = after_round;
      document.getElementById("round_off_lbl").innerHTML = after_round;

      let round_off = document.getElementById("round_off").value;
      let round_off_opt = document.querySelector('input[name="round_off"]:checked').value;

      
      if(round_off!==""){
          if(round_off_opt==='-'){
              item_total = parseFloat(total_after_tax)-parseFloat(round_off);
          }else if(round_off_opt==='+'){
              item_total = parseFloat(total_after_tax)+parseFloat(round_off);
          }
      }
      if( document.getElementById("before-redeem-total")){
        document.getElementById("before-redeem-total").innerHTML = this.currency_format(item_total);
      }
      let redeem_total = 0;
      if(document.getElementById("redeem-total")){
        redeem_total = document.getElementById("redeem-total").innerHTML;
        redeem_total = redeem_total.replace(/,/g , "");
        redeem_total = parseFloat(redeem_total);
        if(this.props.active_tab==="New Sale"){
          item_total = item_total - redeem_total;
        }else if(this.props.active_tab==="New Purchase"){
          item_total = item_total - redeem_total;
        }
      }
      document.getElementById("top-final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total").innerHTML = this.currency_format(item_total);
      document.getElementById("final-total-text").value = item_total;
      /*if(document.getElementById("total-cash-amount")){
        document.getElementById("total-cash-amount").value = item_total;
      }*/
      if(document.getElementById("total-bill-amount")){
        document.getElementById("total-bill-amount").innerHTML = this.currency_format(item_total);
      }
      if(document.getElementById("bill-pay-pop-title")){
        document.getElementById("bill-pay-pop-title").innerHTML = this.currency_format(item_total);
      }
      /*if(document.getElementById("total-paid-amount")){
        document.getElementById("total-paid-amount").innerHTML = this.currency_format(item_total);
      }
      
      if(document.getElementById("total-return-amount")){
        document.getElementById("total-return-amount").innerHTML = 0;
      }*/
      document.getElementById("total-unit").innerHTML = this.currency_format(total_unit);
       let quant_field = document.querySelectorAll(".item-quantity");
      let total_quantity = 0;
      
      for(let i=0;i<quant_field.length;i++){
          if(quant_field[i].value!==''){
            total_quantity = total_quantity+parseInt(quant_field[i].value);
          }
      }
      
      document.getElementById("total-quantity").innerHTML = this.currency_format(total_quantity);
      document.getElementById("top-total-quantity").innerHTML = this.currency_format(total_quantity);
        
      
    }

    getItemInfo = (id,index)=>{
      if(document.getElementById("trans-quantity")){
        document.getElementById("trans-quantity").focus();
      }
      let { options } = this.props;
      let purchase_item_options = JSON.parse(localStorage.getItem("purchase_item_options"));
      if(purchase_item_options!==null && purchase_item_options[id]!==null && purchase_item_options[id]!==undefined){
       options = purchase_item_options;
      }else if(options[id]===undefined || options[id]===null){
       options = this.state.options;
      }
      localStorage.setItem('item_select_on_fly','true');
      if(document.querySelector(".option-active")){
        let is_new_item = document.querySelector(".option-active").innerHTML;
        if(is_new_item===' + Add as new item'){
          let nitem_id =  document.getElementById("options-li-0").getAttribute('data-id');
          nitem_id = parseInt(nitem_id);
          let filteredOptions = this.state.filteredOptions;
          setTimeout(() => {
            let it_name =  filteredOptions[nitem_id].replace(/\s\-\sQty(.*)/g , "");
            it_name = it_name.replace(/\s+\|\s(.*)/g , "");
            //document.getElementById("iitem-box").value = it_name;
            document.getElementById("iitem-name").value =  0;
            document.getElementById("old-iitem-name").value =  0;
          }, 10);
          this.setState({
            activeOption: 0,
            userInputIndex: 0,
          });
          return false;
        }
      }

      if(id>0){
        if(options[id]===undefined || options[id]===null){
          return false;
        }
        if(this.props.handleSelectItem){
          this.setState({
            userInput: '',
            userInputIndex: '',
            activeIndex:'',
            old_id : 0,
          });
          this.props.handleSelectItem(options[id]);
          return false;
        }
        if(options[id].custom_data!==undefined && options[id].custom_data.length>0){
            let custom_data = options[id].custom_data;
            custom_data.forEach(element => {
                let cf_id = element.id;
                if(document.getElementById("iitem-custom-field-"+cf_id)){
                  const cf_element = document.getElementById("iitem-custom-field-"+cf_id);
                  cf_element.value = element.value;
                }
            });
        }


        let is_more_barcode = options[id].is_more_barcode;
        let parent_inventory_id = options[id].parent_inventory_id;
       
        if(this.props.active_tab==='New Sale' && this.state.search_type==='item_name'){
          
          if(is_more_barcode>0){
            if(parent_inventory_id>0){
              id = parent_inventory_id;
            }
            localStorage.setItem('bcode_inventory_id', id);
            document.getElementById("iitem-name").value = id;
            if(document.getElementById("handleShowInventoryList")){
              document.getElementById("handleShowInventoryList").click();
              return false;
            }
          }
      }
      this.handleInventoryItemOnSelect(id,index,'direct');
     }
   }

  hanldeSelectDifferentBarcodeItem = () =>{
    let dbcitem = localStorage.getItem('selected_bc_inv_item')
    _this.handleInventoryItemOnSelect(dbcitem,0,'diff_barcode');
    if(document.getElementById("iitem-quantity")){
      document.getElementById("iitem-quantity").focus();
    }
  }

  handleInventoryItemOnSelect = (id,index,type)=>{
    let { options } = this.props;
    if(type==='direct'){
      let purchase_item_options = JSON.parse(localStorage.getItem("purchase_item_options"));
      if(purchase_item_options!==null && purchase_item_options[id]!==null && purchase_item_options[id]!==undefined){
        options = purchase_item_options;
      }else if(options[id]===undefined || options[id]===null){
        options = this.state.options;
      }
    }else if(type==='diff_barcode'){
      options = JSON.parse(localStorage.getItem("diff_barcode_inv_item_options"));
    }
     if(id>0){
     let desc = options[id].item_desc;
     let hsn = options[id].hsn;
     
     let bundle_quantity = options[id].bundle_quantity;
     let bundle_text = options[id].bundle_text;
     let unit_name = options[id].unit_name;
     let quantity = 0;
     let commission = options[id].comission;
     let commission_type = options[id].comission_type;
     if(commission!=="" && commission!==null && commission!==undefined){
      if(document.getElementById("iitem-total-commission")){
        localStorage.setItem('commission_type',commission_type);
        document.getElementById("iitem-total-commission").value = commission;
      }
     }
     if(this.props.active_tab==='New Purchase' || this.props.active_tab==='New Purchase Return' || this.props.active_tab==='New Sales Return'){
        quantity = 1;
     }
     let product_unit_id = options[id].product_unit_id;
     
     if(product_unit_id==="" || product_unit_id===null){
        product_unit_id = 1;
     }else{
        product_unit_id = parseInt(product_unit_id);
     }

     let availability = options[id].availability;
     let old_available = options[id].old_available;

     let get_diff_barcode_inv_availability = utils.get_diff_barcode_inv_availability(this.props.active_tab,options[id])
     if(get_diff_barcode_inv_availability>=0){
      old_available = get_diff_barcode_inv_availability;
     }

     if(availability>=1){
        quantity = 1;
     }


     let item_purchase_price = options[id].purchase_price;
     let lowest_selling_price = options[id].lowest_selling_price;
     
     if( document.getElementById("iitem-lowest-selling-price")){
       document.getElementById("iitem-lowest-selling-price").value = lowest_selling_price;
     }
     if( document.getElementById("iitem-purchase-price")){
       document.getElementById("iitem-purchase-price").value = item_purchase_price;
     }
     if( document.getElementById("iitem-barcode")){
       document.getElementById("iitem-barcode").value = options[id].barcode;
     }
     if( document.getElementById("iitem-alias-barcode")){
       document.getElementById("iitem-alias-barcode").value = options[id].value4;
     }
     if(document.getElementById("bundle_quantity")){
       document.getElementById("bundle_quantity").innerHTML = '';
     }
     if( document.getElementById("iitem-bundle-quantity")){
       document.getElementById("iitem-bundle-quantity").value = bundle_quantity;
     }
     if( document.getElementById("iitem-bundle-text")){
       document.getElementById("iitem-bundle-text").value = bundle_text;
     }
     let purchase_price = 0;
     let contact_info = localStorage.getItem('contact_info');
     let customer_type = '';
     if(contact_info!==null){
       contact_info = JSON.parse(contact_info);
       customer_type = contact_info.customer_type;
     }
     if(this.props.active_tab==='New Sale'){
        purchase_price = options[id].selling_price.replace(/,/g , "");
        if(customer_type==='wholesale'){
          let wholesale_price = options[id].wholesale_price;
          if(wholesale_price!==null && wholesale_price!==undefined && wholesale_price.trim()!==""){
            wholesale_price = wholesale_price.replace(/,/g , "");
            if(parseFloat(wholesale_price)>0){
              purchase_price = wholesale_price;
            }
          }
        }
     }else if(this.props.active_tab==='New Sales Return'){
        purchase_price = options[id].selling_price.replace(/,/g , "");
        if(customer_type==='wholesale'){
          let wholesale_price = options[id].wholesale_price;
          if(wholesale_price!==null && wholesale_price!==undefined && wholesale_price.trim()!==""){
            wholesale_price = wholesale_price.replace(/,/g , "");
            if(parseFloat(wholesale_price)>0){
              purchase_price = wholesale_price;
            }
          }
        }
     }else{
        purchase_price = (options[id].price_hidden!==null && options[id].price_hidden!=="")?options[id].price_hidden.replace(/,/g , ""):options[id].purchase_price.replace(/,/g , "");
     }
     let selling_price = options[id].selling_price.replace(/,/g , "");

     let discount = 0;
     let sp_discount = options[id].sp_discount;
    if(this.props.active_tab==='New Purchase'){
      discount = options[id].discount;
    }
    if(this.props.active_tab==='New Sale'){
      discount = sp_discount;
    } 
    if(discount===""){
      discount = 0;
    }
     let contact_stat = localStorage.getItem('state');
     
     let pp_tax = options[id].tax;
     let sp_tax = options[id].sp_tax;
     let sp_tax_type = options[id].sp_tax_type;
     let pp_tax_type = options[id].pp_tax_type;

    if(pp_tax_type==="" || pp_tax_type===null){
      pp_tax_type = 'excluding';
    }

    if(sp_tax_type==="" || sp_tax_type===null){
      sp_tax_type = 'excluding';
    }
    if(this.props.active_tab==='New Sale'){
      //sp_tax_type = 'including';
    }

    if(this.props.active_tab==='New Sale' || this.props.active_tab==='New Sales Return'){
      pp_tax = sp_tax;
      pp_tax_type = sp_tax_type;
    }

    if(document.getElementById("iitem-tax-inc")){
      document.getElementById("iitem-tax-inc").value = pp_tax_type;
    }

    if(this.props.active_tab==='New Purchase'){
      if(document.getElementById("sp-discount")){
        document.getElementById("sp-discount").value = sp_discount;
      }
      if(document.getElementById("iitem-sp-gst")){
        document.getElementById("iitem-sp-gst").value = sp_tax;
      }
      if(document.getElementById("sp-tax-type")){
        document.getElementById("sp-tax-type").value = sp_tax_type;
      }
    }

     if(pp_tax==="" || pp_tax===null){
       pp_tax = 0;
     }else{
       pp_tax = parseFloat(pp_tax);
     }



     let cgst = options[id].cgst;
     let igst = options[id].igst;
     let sgst = options[id].sgst;
     let total = options[id].total;

    

     if(cgst==="" || cgst===null){
      cgst = 0;
     }
     if(igst==="" || igst===null){
      igst = 0;
     }
     if(sgst==="" || sgst===null){
      sgst = 0;
     }


     if(pp_tax>0){
       if(contact_stat===""){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }else if(contact_stat!==user_info.state){
          cgst = 0;
          sgst = 0;
          igst = pp_tax;
       }else if(contact_stat===user_info.state){
          cgst = pp_tax/2;
          sgst = pp_tax/2;
          igst = 0;
       }
     }else{
        cgst = 0;
        sgst = 0;
        igst = 0;
     }

    if(document.getElementById("iitem-sgst")){
      document.getElementById("iitem-sgst").value = sgst;
    }
    if(document.getElementById("iitem-cgst")){
      document.getElementById("iitem-cgst").value = cgst;
    }
    if(document.getElementById("iitem-igst")){
      document.getElementById("iitem-igst").value = igst;
    }
    if(document.getElementById("iitem-sgst-hidden")){
      document.getElementById("iitem-sgst-hidden").value = sgst;
    }
    if(document.getElementById("iitem-cgst-hidden")){
      document.getElementById("iitem-cgst-hidden").value = cgst;
    }
    if(document.getElementById("iitem-igst-hidden")){
      document.getElementById("iitem-igst-hidden").value = igst;
    }

    if(document.getElementById("iitem-gst")){
      document.getElementById("iitem-gst").value = pp_tax;
    }
     let tax = 0;

     if(contact_stat===""){
        tax = parseInt(cgst)+parseInt(sgst);
        if(document.getElementById("iitem-igst")){
          document.getElementById("iitem-igst").value = 0;
        }
        
     }else if(contact_stat!==user_info.state){
      
       
        if(document.getElementById("iitem-sgst")){
          document.getElementById("iitem-sgst").value = 0;
        }
        if(document.getElementById("iitem-cgst")){
          document.getElementById("iitem-cgst").value = 0;
        }
        if(document.getElementById("iitem-igst")){
          document.getElementById("iitem-igst").value = igst;
        }
       
     }else if(contact_stat===user_info.state){
      
        tax = parseInt(cgst)+parseInt(sgst);
        if(document.getElementById("iitem-igst")){
          document.getElementById("iitem-igst").value = 0;
        }
        
     }


      let unitItems = document.getElementById("iitem-unit");
      let sunit_item = 0;
      let is_unit_matched = false;
      if(unitItems){
        for(var i = 0; i < unitItems.length; i++) {
          let uiname = parseInt(unitItems[i].value);
          if(uiname===product_unit_id){
              is_unit_matched = true;
          }
        }  
      }

      if(is_unit_matched===false && unitItems){
        product_unit_id = unitItems[0].value;
      }
      
      localStorage.setItem('spunit',product_unit_id);

      if(document.getElementById("handChangeOnFly")){
        document.getElementById("handChangeOnFly").click();
      }
    if(document.getElementById("iitem-desc")){
      if(document.getElementById("hanldeSetItemDescription")){
        localStorage.setItem('item_desc',desc);
        document.getElementById("hanldeSetItemDescription").click();
      }
      document.getElementById("iitem-desc").value = desc;
    }
    if(document.getElementById("iitem-desc-txt")){
       document.getElementById("iitem-desc-txt").innerHTML = desc;
     }
     if(document.getElementById("iitem-hsn")){
      document.getElementById("iitem-hsn").value = (hsn==0)?'':hsn;
    }
    if(document.getElementById("iitem-quantity")){
     document.getElementById("iitem-quantity").value = quantity;
   }
   if(document.getElementById("iitem-unit")){
     document.getElementById("iitem-unit").value = product_unit_id;
   }
     
    let li_item_ids = [];
    let item_ids = document.querySelectorAll(".billing-item-row .iitem-ids");
    for(let i=0;i<item_ids.length;i++){
        let iid = item_ids[i].value;
        let did = item_ids[i].getAttribute('data-id');
        
        let i_quantity = document.getElementById("item-quantity-"+did).value;

        if(li_item_ids[iid]===undefined){
          li_item_ids[iid] = [];
        }
        if(i_quantity!==""){
          i_quantity = parseInt(i_quantity);
          li_item_ids[iid].push(i_quantity);
        }
    }
    
    let item_available = li_item_ids[id];
    if(item_available!==undefined){
      let i_total_quan = 0;
      for(let i=0;i<item_available.length;i++){
        i_total_quan = i_total_quan+item_available[i];
      }
      availability = old_available-i_total_quan;
      let option_available = JSON.parse(localStorage.getItem("item_options"));
      let purchase_option_available = JSON.parse(localStorage.getItem("purchase_item_options"));
     
      if(option_available[id]!==undefined && option_available[id]!==null){
        option_available[id].availability = availability;
      }
      if(purchase_option_available[id]!==undefined){
        purchase_option_available[id].availability = availability;
      }
      let static_item_name = options[id].static_item_name;
      let inventory_status = options[id].inventory_status;
      let stat_lbl = '';
      if(inventory_status==='Draft'){
        stat_lbl=' (Inactive)';
      }
      let new_item_name = static_item_name+" - Qty:"+availability+stat_lbl;
      if(option_available[id]!==undefined && option_available[id]!==null){
        option_available[id].value2 = new_item_name;
        //localStorage.setItem("item_options",JSON.stringify(option_available));
      }
      purchase_option_available[id].value2 = new_item_name;
      //localStorage.setItem("purchase_item_options",JSON.stringify(purchase_option_available));
    }

    if(document.getElementById("tag-box-category")){
      
      let iitem_category=options[id].category_id;
      let category_name=options[id].category_name;
      localStorage.setItem('selected_product',iitem_category);
      localStorage.setItem('item_category',iitem_category);
      localStorage.setItem('item_category_name',category_name);
      if(document.getElementById("setProductState")){
        document.getElementById("setProductState").click();
      }

      if(document.getElementById("handleSetDefaultCategory")){
        document.getElementById("handleSetDefaultCategory").click();
      }




    } 

    if(document.getElementById("current-available")){
      document.getElementById("current-available").value = availability;
     }
      if(document.getElementById("iitem-availability")){
         document.getElementById("iitem-availability").innerHTML = this.currency_format(availability);
      }

     

      if(document.getElementById("price")){
        document.getElementById("price").value = purchase_price;
      }
      if(document.getElementById("price-hidden")){
        document.getElementById("price-hidden").value = purchase_price;
      }
      if(document.getElementById("iitem-selling-price")){
        document.getElementById("iitem-selling-price").value = selling_price;
      }
      if(document.getElementById("discount")){
        document.getElementById("discount").value = discount;
      }
      if(document.getElementById("iitem-unit-name")){
        document.getElementById("iitem-unit-name").value = unit_name;
      }
      

      let data_id = index;
      let price = purchase_price;

      let cal_discount = ((price*quantity)*discount)/100;
      
      let item_grand_total = (price*quantity)-cal_discount;
      
      let cal_tax = (item_grand_total*tax)/100;
      let cgst_tax = (item_grand_total*cgst)/100;
      let sgst_tax = (item_grand_total*sgst)/100;
      let igst_tax = (item_grand_total*igst)/100;
      if(document.getElementById("iitem-igst-total")){
        document.getElementById("iitem-igst-total").value = igst_tax;
      }
      if(document.getElementById("iitem-sgst-total")){
        document.getElementById("iitem-sgst-total").value = sgst_tax;
      }
      if(document.getElementById("iitem-cgst-total")){
        document.getElementById("iitem-cgst-total").value = cgst_tax;
      }
      if(document.getElementById("discount-hidden")){
        document.getElementById("discount-hidden").value = cal_discount;
      }
      


      let final_amount = item_grand_total+cal_tax;
      if(document.getElementById("iitem-total")){
        document.getElementById("iitem-total").innerHTML = this.currency_format(final_amount);
      }
      
      if(document.getElementById("iitem-total-txt")){
        document.getElementById("iitem-total-txt").value = final_amount;
      }

      this.setState({userInputIndex:id});

      if(document.getElementById("iitem-name")){
        document.getElementById("iitem-name").value = id;
      }
      if(document.getElementById("old-iitem-name")){
        document.getElementById("old-iitem-name").value = id;
      }
      let item_name = document.getElementById("iitem-name").value;
      if((this.props.active_tab==="New Purchase" || this.props.active_tab==="New Sale" || this.props.active_tab==="New Sales Return" || this.props.active_tab==="New Purchase Return") && document.getElementById('iitem-tax-inc')){
        this.handleIncludeTaxCalculation(data_id);
      }else{
        //this.calculateTotal();
      }
      if(document.getElementById("handleCalculationOnTrigger")){
        document.getElementById("handleCalculationOnTrigger").click();
      }
      this.setState({activeOption:0});
    }
  }
  handleDocumentClick = (e)=>{
    let target = e.target.classList.value;
    if(target==='isearch-overlay'){
        this.setState({
        filteredOptions: [],
        showOptions: false
      });
    }
  }
  handleCloseList = (e)=>{
    this.setState({is_key_press:true});
    if(e.which===27){
        this.setState({
        filteredOptions: [],
        showOptions: false
      });
    }
  }
  componentWillUnmount(){
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener("keydown", this.handleCloseList);
  }
  componentDidMount=(e)=>{
    document.addEventListener("click", this.handleDocumentClick);
    document.addEventListener("keydown", this.handleCloseList);
    let curl = window.location.href;
    const expenses = curl.match(/reports\/expenses$/i);
    
    if(this.props.data_id==='add-old-bill'){
        this.setState({
          userInput : this.props.contact_name,
          userInputIndex : this.props.contact_id,
          old_id : this.props.contact_id,
        })
    }
    if(this.props.target==='note'){
      if(this.props.item_info){
        let inv_name = this.props.item_info.item_name;
        let inv_id = this.props.item_info.item_id;
         this.setState({
          userInput : inv_name,
          userInputIndex :inv_id,
          old_id :inv_id,
        });
      }
    }
    if(this.props.data_id==='adjust_unpaid_bills'){
      
      if(this.props.item_info && this.props.item_info[0]){
        let contact_name = this.props.item_info[0].item_name;
        let contact_id = this.props.item_info[0].item_id;
        localStorage.setItem("selected_contact",contact_id);
         this.setState({
          userInput : contact_name,
          userInputIndex :contact_id,
          old_id :contact_id,
        })
        if(document.getElementById("refresh_pending_bill")){
          document.getElementById("refresh_pending_bill").click();
        }
      }
    }
    if(this.props.data_target==='contact' && this.props.is_pos!==true && expenses===null && this.props.data_id!=='transport'  && this.props.data_id!=='journal' && this.props.data_id!=='cheque' && this.props.data_id!=='adjust_unpaid_bills' && this.props.data_id!=='expenses' && this.props.data_id!=='add-old-bill'){
      setTimeout(function(){
        if(document.getElementById("contact_box")){
          document.getElementById("contact_box").focus();
          document.getElementById("contact_box").select();
        }
      },1000);
    }
    if(this.props.is_auto_focus===true){
      document.getElementById("iitem-box").focus();
    }
  }

  handleReturnBill = (e)=>{
    document.getElementById("closeListButton").click();
    let qs = document.querySelectorAll(".prev-item-cb");
    let contact_id = '';
    let contact_name = '';
    let contact_discount = '';
    let contact_state = '';
    let contact_payment_mode = '';
    for(let i=0;i<qs.length;i++){
      let is_checked = qs[i].checked;
      localStorage.setItem('return_bill_count',i);
      if(is_checked){

        let data_id = qs[i].getAttribute('data-id');
        let item_id = qs[i].value;
        let item_data = _this.search_list_items[data_id];
        let availability = item_data.availability;
        let lowest_selling_price = item_data.lowest_selling_price;
        let bundle = item_data.bundle;
        let bundle_quantity = item_data.bundle_quantity;
        let bundle_text = item_data.bundle_text;
        let cgst = item_data.cgst;
        let desc = item_data.desc;
        let discount = item_data.discount;
        let hidden_cgst = item_data.hidden_cgst;
        let hidden_igst = item_data.hidden_igst;
        let hidden_sgst = item_data.hidden_sgst;
        let hsn = item_data.hsn;
        let igst = item_data.igst;
        let is_tax_inc = item_data.is_tax_inc;

        let price = item_data.price;
        let price_hidden = item_data.price_hidden;
        let product_name = item_data.product_name;
        let quantity = item_data.quantity;
        let sgst = item_data.sgst;
        let tax = item_data.tax;
        let total = item_data.total;
        let total_cgst = item_data.total_cgst;
        let total_discount = item_data.total_discount;
        let total_igst = item_data.total_igst;
        let total_sgst = item_data.total_sgst;
        let total_tax = item_data.total_tax;
        let product_unit_id = item_data.unit_id;
        let unit_name = item_data.unit_name;
        let unitname = item_data.unitname;
         contact_id = item_data.contact_id;
         contact_name = item_data.contact_name;
         contact_discount = item_data.contact_discount;
         contact_state = item_data.contact_state;
         contact_payment_mode = item_data.contact_payment_mode;

        localStorage.setItem('discount',contact_discount);
        localStorage.setItem('state',contact_state);
        localStorage.setItem('payment_mode',contact_payment_mode);

        let item_name = item_data.item_name;
        document.getElementById("iitem-box").value = item_name;
        document.getElementById("iitem-name").value = item_id;
        document.getElementById("old-iitem-name").value = item_id;

        if( document.getElementById("iitem-lowest-selling-price")){
          document.getElementById("iitem-lowest-selling-price").value = lowest_selling_price;
        }
        if( document.getElementById("iitem-barcode")){
         document.getElementById("iitem-barcode").value = item_data.barcode;
        }
        if( document.getElementById("iitem-bundle-quantity")){
          document.getElementById("iitem-bundle-quantity").value = bundle_quantity;
        }
        if( document.getElementById("iitem-bundle-text")){
          document.getElementById("iitem-bundle-text").value = bundle_text;
        }

        if(document.getElementById("iitem-tax-inc")){
          document.getElementById("iitem-tax-inc").value = is_tax_inc;
        }
        if(document.getElementById("iitem-gst")){
          document.getElementById("iitem-gst").value = tax;
        }

        if(document.getElementById("iitem-sgst")){
          document.getElementById("iitem-sgst").value = sgst;
        }
        if(document.getElementById("iitem-cgst")){
          document.getElementById("iitem-cgst").value = cgst;
        }
        if(document.getElementById("iitem-igst")){
          document.getElementById("iitem-igst").value = igst;
        }
        if(document.getElementById("discount")){
          document.getElementById("discount").value = discount;
        }
        if(document.getElementById("discount-hidden")){
          document.getElementById("discount-hidden").value = total_discount;
        }

        if(discount==='' || discount===null){
          discount = 0;
        }else{
          discount = parseFloat(discount);
        }

        let cal_discount = ((price_hidden*quantity)*discount)/100;
        let item_grand_total = (price*quantity)-cal_discount;
        let cal_tax = (item_grand_total*tax)/100;
        hidden_sgst = (item_grand_total*cgst)/100;
        hidden_cgst = (item_grand_total*sgst)/100;
        hidden_igst = (item_grand_total*igst)/100;
        document.getElementById("iitem-igst-total").value = this.floating_point(hidden_igst);
        document.getElementById("iitem-sgst-total").value = this.floating_point(hidden_sgst);
        document.getElementById("iitem-cgst-total").value = this.floating_point(hidden_cgst);

        let final_amount = item_grand_total+cal_tax;
        document.getElementById("iitem-total").innerHTML =final_amount;
        document.getElementById("iitem-total-txt").value = final_amount;

        if(document.getElementById("iitem-sgst-hidden")){
          document.getElementById("iitem-sgst-hidden").value = hidden_sgst;
        }
        if(document.getElementById("iitem-cgst-hidden")){
          document.getElementById("iitem-cgst-hidden").value = hidden_cgst;
        }
        if(document.getElementById("iitem-igst-hidden")){
          document.getElementById("iitem-igst-hidden").value = hidden_igst;
        }
        document.getElementById("iitem-desc").value = desc;
        document.getElementById("iitem-desc-txt").innerHTML = desc;
        document.getElementById("iitem-hsn").value = (hsn==0)?'':hsn;
        document.getElementById("iitem-quantity").value = quantity;
        document.getElementById("iitem-unit").value = product_unit_id;
        document.getElementById("current-available").value = availability;

        if(document.getElementById("iitem-availability")){
          document.getElementById("iitem-availability").innerHTML = (availability);
        }
        if(document.getElementById("price")){
          document.getElementById("price").value = price;
        }
        if(document.getElementById("price-hidden")){
          document.getElementById("price-hidden").value = price;
        }

        if(document.getElementById("iitem-unit-name")){
          document.getElementById("iitem-unit-name").value = unit_name;
        }
       
        document.getElementById("add-item-to-list").click();
      }
      /* document.getElementById("contact_box").value = contact_name;
      document.getElementById("customer-dropdown").value = contact_id;
      document.getElementById("old-customer-dropdown").value = contact_id; */
      
    }
  }

  goToPreviousInvoice = (id,index) =>{
    let is_valid = document.getElementById("returned_invoice_id");
    if(is_valid===null){
      return false;
    }
    if(document.getElementById('return-invoices')){
      var element = document.getElementById('return-invoices');
      element.classList.add("fade");
      element.style.display="none"; 
    }
      let target = this.props.data_id;
      let invoices = this.state.options[id];
      let item_id = invoices.id;
      
      document.getElementById("returned_invoice_id").value = item_id;

      let t = invoices.target;
      let search_list_items = invoices.list_items;
      let invoice_number = (invoices.sales_number!==undefined)?invoices.sales_number:invoices.purchase_number;
      let pop_title = invoice_number+ " ITEMS";
      var element = document.getElementById('previous-list-items');
      element.classList.remove("fade");
      element.style.display="block";
      document.getElementById("list-item-title").innerHTML = pop_title;
     
      let str = '';
      
      for(let i=0;i<search_list_items.length;i++){
          let iid = search_list_items[i].item_id;
        
          if(_this.search_list_items[i]===undefined){
            _this.search_list_items[i] = [];
          }
          _this.search_list_items[i] = search_list_items[i];
        
          let product_name = search_list_items[i].product_name;
          let item_name = search_list_items[i].item_name;
         
          str += '<tr>'+
                    '<td>'+
                      '<div class="form-check form-check-inline">'+
                        '<input class="form-check-input prev-item-cb" id="pitem-'+i+'" type="checkbox" value="'+iid+'" data-id="'+i+'" checked> '+
                        '<label class="form-check-label" for="pitem-'+i+'"><b>'+
                          item_name+
                        '</b></label>'+
                    '</td>'+
                  '</tr>';
      }
      
      if(str===''){
        str = '<tr><td class="text-center">No item found to return</td></tr>';
      }

      document.getElementById("prev-list-item-block").innerHTML = str;
      setTimeout(function(){
        document.getElementById("doneListButton").focus();
      },500);
      //window.location.href = '/new-billing/edit/'+t+'/'+item_id;
  }
  
  onKeyDown = (e) => {
    let data_index = localStorage.getItem('data_index');
    let { activeOption, userInputIndex, filteredOptions } = this.state;
    if (e.keyCode === 13) {
      if(this.props.data_target==='search_view_product'){
        let product_id = this.state.activeOption;
        window.location.href = '/products/view/'+product_id;
        return false;
      }
      if(this.props.data_target==='contact'){

        let is_add_new = filteredOptions[this.state.activeOption];
        if(is_add_new===' + Add New'){
          if(document.getElementById("handleCreateContactOnFly")){
            
            document.getElementById("handleCreateContactOnFly").click()
          }
          this.setState({
            showOptions: false
          });
          return false;
        }
        if(document.getElementById("resetPaymentOnContactChange")){
          document.getElementById("resetPaymentOnContactChange").click();
        }
        if(filteredOptions[activeOption]!==undefined){
          if(document.getElementById("ContactSelected")){
            document.getElementById("ContactSelected").click();
          }
          this.getContact(this.state.activeOption);
          if(this.props.is_enable_tag){
            let tag_list = this.state.tag_list;
            let tag_item = {id:this.state.activeOption,name:filteredOptions[this.state.activeOption]}
            tag_list.push(tag_item);
            this.setState({tag_list:tag_list});
            document.getElementById("contact_box").value = '';
            document.getElementById('customer-dropdown').value  = '';
          }else{
            if(document.getElementById("contact_box")){
              if(this.props.load_target==='New Sale'){
                if(this.props.options[this.state.activeOption] && this.props.options[this.state.activeOption].value2!==undefined){
                  document.getElementById("contact_box").value = this.props.options[this.state.activeOption].value2;
                  document.getElementById('customer-dropdown').value  = this.props.options[this.state.activeOption].contact_id;
                }else{
                  let search_contact = localStorage.getItem('search_contact');
                  if(search_contact){
                    let joptions = JSON.parse(search_contact);
                    document.getElementById("contact_box").value = joptions[this.state.activeOption].value2;
                    document.getElementById('customer-dropdown').value  = joptions[this.state.activeOption].contact_id;
                  }
                }
              }else{
                document.getElementById("contact_box").value = filteredOptions[this.state.activeOption];
                document.getElementById('customer-dropdown').value  = this.state.activeOption;
              }
            }else if(document.getElementById("contact_box_"+data_index)){
              if(this.props.load_target==='New Sale'){
                if(this.props.options[this.state.activeOption] && this.props.options[this.state.activeOption].value2!==undefined){
                  document.getElementById("contact_box_"+data_index).value =  this.props.options[this.state.activeOption].value2;
                  document.getElementById('customer-dropdown-'+data_index).value  = this.props.options[this.state.activeOption].contact_id;
                }else{
                  let search_contact = localStorage.getItem('search_contact');
                  if(search_contact){
                    let joptions = JSON.parse(search_contact);
                    document.getElementById("contact_box_"+data_index).value  = joptions[this.state.activeOption].value2;
                    document.getElementById('customer-dropdown-'+data_index).value  = joptions[this.state.activeOption].contact_id;
                  }
                }
              }else{
                document.getElementById("contact_box_"+data_index).value = filteredOptions[this.state.activeOption];
                document.getElementById('customer-dropdown-'+data_index).value  = this.state.activeOption;
              }
            }
          }
        }else{
          if(document.getElementById("contact_box")){
            let contact_name = document.getElementById("contact_box").value;
            if(contact_name===''){
              setTimeout(function(){
                document.getElementById("contact_box").focus();
                document.getElementById("contact_box").select();
              },200);
            }
          }else if(document.getElementById("contact_box_"+data_index)){
            let contact_name = document.getElementById("contact_box_"+data_index).value;
            if(contact_name===''){
              setTimeout(function(){
                document.getElementById("contact_box_"+data_index).focus();
                document.getElementById("contact_box_"+data_index).select();
              },200);
            }
          }
        }
      }
      if(this.props.data_target==='billing'){
        let data_id = parseInt(this.state.activeIndex);
        let uinput = document.getElementById("iitem-box").value;
        
        if(activeOption>0 && uinput===""){
          this.getItemInfo(this.state.activeOption,data_id,this.state.search_type);
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("iitem-box").value = it_name;
              
            }

          }
          
        }else if(userInputIndex>0){
          this.getItemInfo(this.state.activeOption,data_id);
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("iitem-box").value = it_name;
            }
          }
        }else if(this.state.search_type === 'item_name'){
          this.getItemInfo(this.state.activeOption,data_id);
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("iitem-box").value = it_name;
            }
          }
        }
      }
      if(this.props.data_target==='barcode'){
        let data_id = parseInt(this.state.activeIndex);
        if(activeOption>0){
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("item-box-"+data_id).value = it_name;
            }
          }
          this.getBarcodeItems(this.state.activeOption,data_id);
        }
      }
      if(this.props.data_target==='barcode-preview'){
        let data_id = parseInt(this.state.activeIndex);
        if(activeOption>0){
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("iitem-box").value = it_name;
            }
          }
          this.setBarcodePreview(this.state.activeOption,data_id);
        }
      }
      if(this.props.data_target==='add-direct-product'){
        let data_id = parseInt(this.state.activeIndex);
        if(activeOption>0){
          if(filteredOptions[activeOption]!==undefined){
            let it_name =  filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
            
            if(it_name!==' + Add as new item'){
              it_name = it_name.replace(/\s+\|\s(.*)/g , "");
              document.getElementById("item-box-"+data_id).value = it_name;
            }
          }
          this.addDirectProduct(this.state.activeOption,data_id);
        }
      }
      if(this.props.data_target==='inventory-bulk-edit'){
        let data_id = parseInt(this.state.activeIndex);
        if(activeOption>0){
          /*if(filteredOptions[activeOption]!==undefined){
            document.getElementById("item-box").value = filteredOptions[activeOption].replace(/\s\-\sQty(.*)/g , "");
          }*/
          this.handleInventoryList(this.state.activeOption,data_id);
        }
      }
      if(this.props.data_target==='payment'){
        let data_id = parseInt(e.currentTarget.getAttribute('data_id'));
        this.setPaymentOption(this.state.activeOption,data_id);
      }
      if(this.props.data_target==='bill_search_list'){
        let data_id = parseInt(e.currentTarget.getAttribute('data_id'));
        this.setSearchedBillOption(this.state.activeOption,data_id);
      }else if(this.props.data_target.match(/bill_search_list/)){
        let data_id = parseInt(e.currentTarget.getAttribute('data_id'));
        this.setSearchedBillOption(this.state.activeOption,data_id);
      }
      if(this.props.data_target==='previous_invoice'){
        let data_id = parseInt(e.currentTarget.getAttribute('data_id'));
        this.goToPreviousInvoice(this.state.activeOption,data_id);
       
      }
      let inputval = filteredOptions[activeOption];
      let actopt = activeOption;
     
      if(this.props.data_target==='contact'){
          if(inputval===undefined){
            inputval = this.props.contact_name;
            actopt = this.props.contact_id;
          }
      }

      if(this.props.data_target==='billing'){
        actopt = userInputIndex
      }
      if(this.props.data_target!=='payment'  && this.props.data_target!=='previous_invoice'){
        this.setState({
          showOptions: false,
          userInput: inputval,
          userInputIndex: actopt,
        });
      }else{
         this.setState({
          showOptions: false,
          userInput: inputval,
        });
      }
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }

      let data_index = 0;
      let prev_data_index = 0;
      if(document.querySelector(".option-active")){
          let data_index = document.querySelector(".option-active").getAttribute('data-index');
          let data_id = document.querySelector(".option-active").getAttribute('data-id');
          data_index = parseInt(data_index);
          data_index = data_index-1;
          prev_data_index = data_index;
          let new_data_id = parseInt(data_id);
          if(document.getElementById("options-li-"+data_index)){
            new_data_id = document.getElementById("options-li-"+data_index).getAttribute('data-id');
            new_data_id = parseInt(new_data_id);
          }
          data_index = data_index;
          activeOption = new_data_id;
      }
     if(prev_data_index%7===0 || prev_data_index>=7){
        if(document.getElementById('auto-comp-opt')){
          document.getElementById('auto-comp-opt').scrollTop -= 45;
        }
      }
      this.setState({ activeOption: activeOption });
    } else if (e.keyCode === 40) {
      let data_index = 0;
      let next_data_index = 0;
      if(document.querySelector(".option-active")){
          let data_index = document.querySelector(".option-active").getAttribute('data-index');
          let data_id = document.querySelector(".option-active").getAttribute('data-id');
          data_index = parseInt(data_index);
          data_index = data_index+1;
          next_data_index = data_index;
          let new_data_id = parseInt(data_id);
          if(document.getElementById("options-li-"+data_index)){
            new_data_id = document.getElementById("options-li-"+data_index).getAttribute('data-id');
            new_data_id = parseInt(new_data_id);
          }
          data_index = data_index;
          activeOption = new_data_id;
      }
    
      if(next_data_index%7===0){
        if(document.getElementById('auto-comp-opt')){
          document.getElementById('auto-comp-opt').scrollTop += 340;
        }
      }
      
      if (activeOption === filteredOptions.length - 1) {
       // return;
      }
      
      this.setState({ activeOption: activeOption });
    }
   
  };

  handleInvoiceSearchOnClick = () =>{
    //this.handleInvoiceSearch('',0);
  }

  handleInvoiceSearch = (search,idx) =>{
        let target = this.props.target;
        let curl = window.location.href;
        const is_order = curl.match(/order/i);
        let screen = '';
        if(is_order){
          screen = 'order';
        }
    
        if(target===undefined || target==='contact_box'){
          if(document.getElementById("return-invoice-search")){
            document.getElementById("return-invoice-search").focus();
          }
          target = (document.getElementById("return-invoice-search"))?document.getElementById("return-invoice-search").getAttribute("data_id"):'';
          
        }
        let body = {};
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            search : search,
            contact_id : (document.getElementById("customer-dropdown"))?document.getElementById("customer-dropdown").value:0,
            target : target,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              screen:screen
        }
      let api = app_api+'/search_invoice_number';
      axios.post(api, body)
        .then(function (response) {

            if(document.getElementById("handleReturnBillStopSearch")){
              document.getElementById("handleReturnBillStopSearch").click();
            }

            let resp = response.data;
            let data = resp.data;
            
            let options = data; 
          
            this.setState({options:options});
            let userInputIndex = 0;
            let filteredOptions = [];
            let activeOption = 0;
            this.setState({is_searching:false});
            if(options.length>0){
            options.map( (value, index) => { 
                let value1 = value.value1;
                let value2 = value.value2;
                if((value2.toLowerCase().indexOf(search.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(search.toLowerCase()) > -1)){
                    filteredOptions[index] = value2;
                    let c = 0;
                    filteredOptions.map( (v, i) => {
                      if(c==0){
                          c++;
                          activeOption = i;
                          userInputIndex = i;
                      }
                    });
                    this.setState({
                      activeOption,
                      filteredOptions,
                      showOptions: true,
                      userInput: search,
                      activeIndex:parseInt(idx)
                    });
                }
            });
            }else{
          this.setState({
                activeOption:0,
                filteredOptions:[],
                showOptions: false,
                userInput: search,
                activeIndex:0
              });
        }
            
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
              }
          }
      })
  }

  onKeyUp = (e) => {
    let idx = e.currentTarget.getAttribute('data_id');
    let code = e.keyCode;

    let search = e.currentTarget.value;
    if(this.props.data_target==='contact'){
      let curl = window.location.href;
      const edit_payment = curl.match(/new-payment\/([edit]+)\/(\d+$)/i);
      if(edit_payment){}else{
        if(document.getElementById("hanlde-force-empty-invoice-list")){
          if(search===''){
              document.getElementById("hanlde-force-empty-invoice-list").click();
          }
        }
        if(document.getElementById("ContactRemoved")){
          if(search===''){
              document.getElementById("ContactRemoved").click();
          }
        }
      }
    }
    if(code===8){
      if(search===''){
            this.setState({
              activeOption : 0,
              filteredOptions : [],
              showOptions: false,
              userInput: '',
              userInputIndex: 0,
            });
        }
    }


    if(this.props.data_target==='payment' && ((code > 47 && code < 58) || (code > 64 && code < 91) || (code > 96 && code < 123) || code===8)){
        if(search===''){
            this.setState({
              activeOption : 0,
              filteredOptions : [],
              showOptions: false,
              userInput: '',
              userInputIndex: 0,
            });
        }
        let filteredOptions = [];
        if(search.length>=1){
          let item_id = '';
          if(document.getElementById("iitem-id")){
            item_id =document.getElementById("iitem-id").value;
          }
          let invoice_ids = [];
          let item_ids = document.querySelectorAll(".payment-item-row .item-ids");
          for(let i=0;i<item_ids.length;i++){
              let iid = item_ids[i].value;
              if(localStorage.getItem('item-row-edit')!==iid){
                invoice_ids.push(iid);
              }
          }
          let curl = window.location.href;
          const is_order = curl.match(/order/i);
          let screen = '';
          if(is_order){
            screen = 'order';
          }
          let customer_id = document.getElementById("customer-dropdown").value;
            let body = {};
            var active_tab = document.querySelector('.payment-type-cb:checked').value;
              body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  search : search,
                  customer_id : customer_id,
                  active_tab : active_tab,
                  invoice_ids : invoice_ids,
                  item_id:item_id,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
                  screen:screen
               }
            let api = app_api+'/search_invoice_number';
            axios.post(api, body)
              .then(function (response) {
                  let resp = response.data;
                  let data = resp.data;
                  filteredOptions = [];
                  let options = data;
                  this.setState({options:options});
                  let userInputIndex = 0;
                  
                  let activeOption = 0;
                  if(options.length>0){
                  options.map( (value, index) => { 
                      
                      let value1 = value.value1;
                      let value2 = value.value2;
                      if((value2.toLowerCase().indexOf(search.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(search.toLowerCase()) > -1)){
                          filteredOptions[index] = value2;
                          let c = 0;
                          filteredOptions.map( (v, i) => {
                            if(c==0){
                                c++;
                                activeOption = i;
                                userInputIndex = i;
                            }
                          });
                          
                           this.setState({
                            activeOption,
                            filteredOptions,
                            showOptions: true,
                            userInput: search,
                            activeIndex:parseInt(idx)
                          });
                      }
                  });
              }else{
                 this.setState({
                      activeOption:0,
                      filteredOptions:[],
                      showOptions: false,
                      userInput: search,
                      activeIndex:0
                    });
              }
                 
            }.bind(this)).catch(function(res){
                if(res.response){
                    if(res.response.data){
                    }
                }
            })
        //}.bind(this),1000);
      }
    }

    if((this.props.data_target==='previous_invoice') && ((code > 47 && code < 58) || (code > 64 && code < 91) || (code > 96 && code < 123) || code===8)){
        let filteredOptions = [];
        if(search.length>=1){
          clearTimeout(this.state.timeout);
          if(document.getElementById("handleReturnBillStartSearch")){
            document.getElementById("handleReturnBillStartSearch").click();
          }
          this.state.timeout = setTimeout(function () {
              this.handleInvoiceSearch(search,idx);
          }.bind(this), 200);
      }else{
        if(document.getElementById("handleReturnBillStopSearch")){
          document.getElementById("handleReturnBillStopSearch").click();
        }
        clearTimeout(this.state.timeout);
      }
    }
    const { options } = this.props;
    if((search==="" && this.props.data_target==="contact" && this.state.showOptions===false) && (code===38 || code===40)){
      let userInputIndex = 0;
      let filteredOptions = [];
      let activeOption = 0;
      let lcount = 0;
      let _this = this;
      options.map( (value, index) => { 
        if(lcount<20){
          lcount++;
          if(value){
            let value1 = value.value1;
            let value2 = value.value2;
            let value3 = "";
            if(value.value3!==undefined && value.value3!==null){
              value3 = value.value3;
            }
            let value4 = "";
            if(value.value4!==undefined && value.value4!==null){
              value4 = value.value4;
            }
          
        
            filteredOptions[index] = value2;

            let c = 0;
            filteredOptions.map( (v, i) => {
              if(c==0){
                  c++;
                  activeOption = i;
                  userInputIndex = i;
              }
            });
        }
      }else{
        _this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
        });
        return false
      }
    });

    }
    if((search==="" && (this.props.active_tab==="New Purchase" || this.props.active_tab==="New Sale" || this.props.active_tab==="New Sales Return" || this.props.active_tab==="New Purchase Return" || this.props.data_target==="barcode") && this.state.showOptions===false) && (code===38 || code===40)){
     
      this.setState({search_type:'item_name'});
      localStorage.setItem('search_type','item_name');
        let userInputIndex = 0;
        let filteredOptions = [];
        let activeOption = 0;
        let lcount = 0;
        let _this = this;
        options.map( (value, index) => { 
            if(lcount<20){
              lcount++;
              if(value){
                let value1 = value.value1;
                let value2 = value.value2;
                let bcode_val = value.value3;
                let new_i_val = '';
                if(bcode_val!==undefined && bcode_val!=="" && bcode_val!==null){
                  new_i_val = ' | '+bcode_val+' - Qty:';
                }
                value2 = value2.replace('- Qty:',new_i_val);
                filteredOptions[index] = value2;

                let c = 0;
                filteredOptions.map( (v, i) => {
                  if(c==0){
                      c++;
                      activeOption = i;
                      userInputIndex = i;
                  }
                });
                
              }
            }else{
              _this.setState({
                activeOption,
                filteredOptions,
                showOptions: true,
              });
              return false
            }
        });
        if(filteredOptions.length>0){
          filteredOptions = this.addNewPurchaseItem(filteredOptions,activeOption,'');
        }
        this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
        });
    }


    if((search==="" && this.props.active_tab!=="New Purchase"  && this.props.active_tab!=="New Sale" && this.props.active_tab!=="New Sales Return" && this.props.active_tab!=="New Purchase Return" && this.props.data_target!=="barcode") && (code===8 || code===46 || code===13)){
      if(this.props.data_target!=='payment' && this.props.data_target!=='previous_invoice' && this.props.data_id!=='add-old-bill'){
        const { options } = this.props;
        let userInputIndex = 0;
        let filteredOptions = [];
        let activeOption = 0;
        let search_index = 0;
      
        options.map( (value, index) => { 
            if(value){
              let value1 = value.value1;
              let value2 = value.value2;
              if(search_index>=20){
                return false;
              }
              filteredOptions[index] = value2;
              if(search_index===0){
                activeOption = index;
                userInputIndex = index;
              }
              search_index++;
            }
        });
        if(filteredOptions.length>0){
          filteredOptions = this.addNewPurchaseItem(filteredOptions,activeOption,'');
        }
        this.setState({
          activeOption,
          filteredOptions,
          showOptions: true,
          //userInputIndex: userInputIndex,
        });
      
      }
    }
   
    if(this.props.data_target==="billing" && this.props.target==='item-box'){
      if(search===""){
        document.getElementById("item-name-"+idx).value = '';
        document.getElementById("item-hsn-"+idx).value = '';
        document.getElementById("item-quantity-"+idx).value = '';
        document.getElementById("current-available-"+idx).value = '';
        if(document.getElementById("price-"+idx)){
          document.getElementById("price-"+idx).value = '';
        }
        if(document.getElementById("price-hidden-"+idx)){
          document.getElementById("price-hidden-"+idx).value = 0;
        }
        if(document.getElementById("item-selling-price-"+idx)){
          document.getElementById("item-selling-price-"+idx).value = 0;
        }
        if(document.getElementById("item-lowest-selling-price-"+idx)){
          document.getElementById("item-lowest-selling-price-"+idx).value = 0;
        }
        if(document.getElementById("discount-"+idx)){
          document.getElementById("discount-"+idx).value = 0;
        }
        if(document.getElementById("discount-hidden-"+idx)){
          document.getElementById("discount-hidden-"+idx).value = 0;
        }

        if(document.getElementById("item-cgst-"+idx)){
          document.getElementById("item-cgst-"+idx).value = 0;
          document.getElementById("item-cgst-hidden-"+idx).value = 0;
          document.getElementById("item-cgst-total-"+idx).value = 0;
        }
        if(document.getElementById("item-sgst-"+idx)){
          document.getElementById("item-sgst-"+idx).value = 0;
          document.getElementById("item-sgst-hidden-"+idx).value = 0;
          document.getElementById("item-sgst-total-"+idx).value = 0;
        }
        if(document.getElementById("item-igst-"+idx)){
          document.getElementById("item-igst-"+idx).value = 0;
          document.getElementById("item-igst-hidden-"+idx).value = 0;
          document.getElementById("item-igst-total-"+idx).value = 0;
        }
        document.getElementById("item-total-txt-"+idx).value = 0;
        document.getElementById("item-total-"+idx).innerHTML = 0;

        this.calculateTotal();
      }
    }

  };

  handleCloseAutocompleteList = (e) =>{
    
  }
  handleRemoveTag = (e) =>{
    let idx = e.currentTarget.getAttribute('data-index');
    let tag_list = this.state.tag_list;
    tag_list.splice(idx,1);
    this.setState({tag_list})
  }
  render() {
    let key = -1;
    const {
      onFocus,
      onBlur,
      onChange,
      onClick,
      onKeyDown,
      onKeyUp,
      closeList,
      state: { activeOption, filteredOptions, showOptions, userInput, userInputIndex,old_id,active_list_title }
    } = this;
    let optionList;
    if (showOptions) {
      if (filteredOptions.length) {
        optionList = (
          <div className="auto-complete" id="auto-complete">
          <ul className={'options  list-group '+this.props.className+' '+this.props.position} id="auto-comp-opt">

            {filteredOptions.map((optionName, index) => {
              let className;
              key++;
              if (index === activeOption) {
                className = 'option-active';
              }

              let child_item_cls = '';
              let child_item_options = JSON.parse(localStorage.getItem('item_options'));
              
              let barcode = '';
              let alias_barcode = '';
              if(child_item_options!==null && child_item_options[index]!==null && child_item_options[index]!==undefined){
                barcode = child_item_options[index].barcode;
                alias_barcode = child_item_options[index].value4;
                if(alias_barcode===undefined || alias_barcode===null){
                  alias_barcode = 'none';
                }
              }
              if(this.props.data_target==='barcode' && child_item_options[index]!==null && child_item_options[index]!==undefined && child_item_options[index].parent_id>0){
                child_item_cls = 'child_item_cls';
              }

              return (
                <li id={"options-li-"+key} className={'list-group-item '+className+' '+child_item_cls} data-index={key} key={index} data-id={index} barcode={barcode} alias_barcode={alias_barcode} target={this.props.target} onClick={onClick}>
                  {optionName} {(this.props.load_target==='New Sale' && this.props.options[index]!==undefined && this.props.data_target==='contact' && this.props.options[index].value1!==undefined)?this.props.options[index].value1:''}
                </li>
              );
            })}
          </ul>
          </div>
        );
      }
    }
    return (
      <React.Fragment>
         {(showOptions) &&
          <div className='isearch-overlay' onClick={this.handleCloseAutocompleteList}></div>
        }
        <div className="search">
          <span onClick={this.handleInvoiceSearchOnClick} id="handleInvoiceSearchOnClick"></span>
          <span onClick={this.handleCloseListOnOutSide} id="handleCloseListOnOutSide"></span>
          <span onClick={this.hanldeSelectDifferentBarcodeItem} id="hanldeSelectDifferentBarcodeItem"></span>
          <span onClick={this.open_popup_on_focus} id="open_popup_on_focus"></span>
          
          <div className="input-group">
          
          <input
            type="text"
            className={"search-box form-control "+this.props.clsName}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            defaultValue={userInput}
            placeholder={this.props.title}
            data_id={this.props.data_id}
            id={this.props.input_id}
            autoComplete = "off"
            ref={this.contactInput}
            target={this.props.target}
            tabIndex = {this.props.tabindex}
            style = {this.props.style}
            data-index={this.props.data_index}
          />
            {(this.state.is_searching===true) &&
            <div className="input-group-append" id="is_searching_item">
                <span className="input-group-text" style={{background:'white'}}><FontAwesomeIcon icon={faSpinner} pulse/></span>
            </div>
            }
            {(this.state.is_searching===false && this.props.icon && this.props.icon!=='') &&
              <div className="input-group-append" id="is_searching_item">
                  <span className="input-group-text" style={{background:'white'}}><FontAwesomeIcon icon={this.props.icon}/></span>
              </div>
            }
            {(this.state.is_searching===false && this.props.icon_label && this.props.icon_label!=='') &&
              <div className="input-group-append" id="is_searching_item">
                  <span className="input-group-text" style={{background:'#fff'}}>{this.props.icon_label}</span>
              </div>
            }
          </div>
          {(this.props.is_enable_tag && this.state.tag_list.length>0) &&
            <div>
              <input type="hidden" id={'tag-'+this.props.id} value={JSON.stringify(this.state.tag_list)}/>
              {(this.state.tag_list.map((titem,idx)=>{
                return (
                  <span key={"t-"+idx} className="v-tag" id={"v-tag-"+idx}>
                  {titem.name}
                  <span key={"tc-"+idx} className="t-close" data-index={idx} onClick={this.handleRemoveTag}><FontAwesomeIcon icon={faTimes} /></span>
                  </span>
                )
              }))}
              
            </div>
          }
          <input type="text" id={this.props.id} className="hide item-ids" data-id={this.props.data_id} defaultValue={(this.props.load_target==='New Sale' && this.props.options[userInputIndex]!==undefined && this.props.options[userInputIndex].contact_id!==undefined)?this.props.options[userInputIndex].contact_id:userInputIndex}/>
          <input type="text" id={"old-"+this.props.id} defaultValue={(this.props.load_target==='New Sale' && this.props.options[old_id]!==undefined && this.props.options[old_id].contact_id!==undefined)?this.props.options[old_id].contact_id:old_id} className="hide"/>
        </div>
        {optionList}
        <>
        <ModalBox id="previous-list-items" title={this.state.active_list_title} data_id="list_items" handleReturnBill={this.handleReturnBill.bind(this)}/>
        {(this.props.data_target==='contact' && this.props.data_index===undefined) &&
          <CreateContactOnFly userInput={userInput}/>
        }
        {(this.props.data_target==='contact' && this.props.data_index!=undefined && this.props.data_index===0) &&
          <CreateContactOnFly userInput={userInput}/>
        }
        </>
       
      </React.Fragment>
    );
  }
}

export default Autocomplete;
